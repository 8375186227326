/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PhidgetChannel } from '../Phidget';
import { Channel } from '../Channel';
import { ErrorCode, ChannelClass } from '../Enumerations.gen';
import * as Enum from '../Enumerations.gen';
import * as SEnum from '../SupportedEnum.gen';
import { PhidgetError } from '../PhidgetError';
import { BridgePacket, PUNK } from '../BridgePacket';
import { BP } from '../BridgePackets.gen';
import { logEventException } from '../Logging';
import { DeviceChannelUID } from '../Devices.gen';

/** @internal */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ResistanceInputData {
	dataInterval: number,
	maxDataInterval: number,
	minDataRate: number,
	maxDataRate: number,
	maxResistance: number,
	maxResistanceChangeTrigger: number,
	minDataInterval: number,
	minResistance: number,
	minResistanceChangeTrigger: number,
	resistance: number,
	resistanceChangeTrigger: number,
	RTDWireSetup: Enum.RTDWireSetup | PUNK.ENUM,
}

abstract class ResistanceInputBase extends PhidgetChannel {
	/** @internal */
	data: ResistanceInputData;
	/**
	 * **ResistanceChange** event
	 *  * `resistance` - The resistance value
	 * ---
	 * The most recent resistance value the channel has measured will be reported in this event, which occurs when the `dataInterval` has elapsed.
	 * 
	 * *   If a `resistanceChangeTrigger` has been set to a non-zero value, the `ResistanceChange` event will not occur until the resistance has changed by at least the `resistanceChangeTrigger` value.
	 */
	onResistanceChange: ((resistance: number) => void) | null = null;
	/** @internal */
	_gotResistanceChangeErrorEvent?: boolean;

	/**
	 * The Resistance Input class measures the resistance of a circuit connected to the Phidget, which is used to read resistance-based sensors such as platinum RTDs.
	 * @public
	 */
	constructor();
	/** @internal */
	constructor(ch?: Channel);
	constructor(ch?: Channel) {
		super(ch);
		this._class = ChannelClass.RESISTANCE_INPUT;
		this.name = "ResistanceInput";
		this.data = this._initData();
	}

	/** @internal */
	_bridgeInput(bp: BridgePacket) {

		switch(bp.vpkt) {
		case BP.SETDATAINTERVAL:
			if (bp.entryCount > 1)
				this.data.dataInterval = bp.entries[1].v as number;
			else
				this.data.dataInterval = bp.entries[0].v as number;
			this._FIREPropertyChange('DataInterval', bp);
			this._FIREPropertyChange('DataRate', bp);
			break;
		case BP.SETCHANGETRIGGER:
			this.data.resistanceChangeTrigger = bp.entries[0].v as number;
			this._FIREPropertyChange('ResistanceChangeTrigger', bp);
			break;
		case BP.SETRTDWIRESETUP:
			this.data.RTDWireSetup = bp.entries[0].v as Enum.RTDWireSetup;
			this._FIREPropertyChange('RTDWireSetup', bp);
			break;
		case BP.RESISTANCECHANGE: {
			this.data.resistance = bp.entries[0].v as number;
			if (this._isAttachedDone && this.onResistanceChange) {
				try {
					this.onResistanceChange(this.data.resistance);
				} catch (err) { logEventException(err); }
			}
			break;
		}
		default:
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			throw new PhidgetError(ErrorCode.INVALID_PACKET, "Unsupported bridge packet: 0x" + bp.vpkt!.toString(16));
		}
	}

	/** @internal */
	_initData(): ResistanceInputData {
		return {
			dataInterval: PUNK.DBL,
			maxDataInterval: PUNK.UINT32,
			minDataRate: PUNK.DBL,
			maxDataRate: PUNK.DBL,
			maxResistance: PUNK.DBL,
			maxResistanceChangeTrigger: PUNK.DBL,
			minDataInterval: PUNK.UINT32,
			minResistance: PUNK.DBL,
			minResistanceChangeTrigger: PUNK.DBL,
			resistance: PUNK.DBL,
			resistanceChangeTrigger: PUNK.DBL,
			RTDWireSetup: PUNK.ENUM,
		}
	}

	/** @internal */
	_initAfterOpen() {
		this.data = this._initData();

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._TMP1200_RESISTANCEINPUT_100:
			this.data.dataInterval = 250;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 4;
			this.data.maxResistance = 50000;
			this.data.maxResistanceChangeTrigger = 19000;
			this.data.minDataInterval = 250;
			this.data.minResistance = 0;
			this.data.minResistanceChangeTrigger = 0;
			this.data.resistanceChangeTrigger = 0;
			this.data.RTDWireSetup = Enum.RTDWireSetup.WIRES_4;
			break;
		case DeviceChannelUID._TMP1200_RESISTANCEINPUT_300:
			this.data.dataInterval = 333;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 3.003003003003003;
			this.data.maxResistance = 5000;
			this.data.maxResistanceChangeTrigger = 5000;
			this.data.minDataInterval = 333;
			this.data.minResistance = 0;
			this.data.minResistanceChangeTrigger = 0;
			this.data.resistanceChangeTrigger = 0;
			this.data.RTDWireSetup = Enum.RTDWireSetup.WIRES_4;
			break;
		case DeviceChannelUID._TMP1200_RESISTANCEINPUT_400:
			this.data.dataInterval = 100;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 20;
			this.data.maxResistance = 5000;
			this.data.maxResistanceChangeTrigger = 5000;
			this.data.minDataInterval = 50;
			this.data.minResistance = 0;
			this.data.minResistanceChangeTrigger = 0;
			this.data.resistanceChangeTrigger = 0;
			this.data.RTDWireSetup = Enum.RTDWireSetup.WIRES_4;
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	// eslint-disable-next-line require-await
	async _setDefaults() {
		let bp;

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._TMP1200_RESISTANCEINPUT_100:
		case DeviceChannelUID._TMP1200_RESISTANCEINPUT_300:
		case DeviceChannelUID._TMP1200_RESISTANCEINPUT_400:
			bp = new BridgePacket();
			bp.set({ name: "0", type: "u", value: Math.round(this.data.dataInterval) });
			await bp.send(this._ch, BP.SETDATAINTERVAL);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.resistanceChangeTrigger });
			await bp.send(this._ch, BP.SETCHANGETRIGGER);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "d", value: this.data.RTDWireSetup });
			await bp.send(this._ch, BP.SETRTDWIRESETUP);
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	_hasInitialState() {

		if ((this.data.resistance == PUNK.DBL)
			&& ! this._gotResistanceChangeErrorEvent)
			return false;

		return true;
	}

	/** @internal */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	_fireInitialEvents() {

		if(this.data.resistance != PUNK.DBL)
			if (this.onResistanceChange)
				try {
					this.onResistanceChange(this.data.resistance);
				} catch (err) { logEventException(err); }

	}

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `ResistanceChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * *   The timing between `ResistanceChange` events can also be affected by the `resistanceChangeTrigger`.
	 * @throws {@link PhidgetError}
	 */
	get dataInterval() { return this.getDataInterval(); }
	/**
	 * The minimum value that `dataInterval` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDataInterval() { return this.getMinDataInterval(); }
	/**
	 * The maximum value that `dataInterval` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDataInterval() { return this.getMaxDataInterval(); }
	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @throws {@link PhidgetError}
	 */
	get dataRate() { return this.getDataRate(); }
	/**
	 * The minimum value that `dataRate` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDataRate() { return this.getMinDataRate(); }
	/**
	 * The maximum value that `dataRate` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDataRate() { return this.getMaxDataRate(); }
	/**
	 * The most recent resistance value that the channel has reported.
	 * 
	 * *   This value will always be between `minResistance` and `maxResistance`.
	 * *   The `resistance` value will change when the device is also being used as a temperature sensor. This is a side effect of increasing accuracy on the temperature channel.
	 * @throws {@link PhidgetError}
	 */
	get resistance() { return this.getResistance(); }
	/**
	 * The minimum value the `ResistanceChange` event will report.
	 * 
	 * *   When the device is also being used as a TemperatureSensor the `minResistance` and `maxResistance` will not represent the true input range. This is a side effect of increasing accuracy on the temperature channel.
	 * @throws {@link PhidgetError}
	 */
	get minResistance() { return this.getMinResistance(); }
	/**
	 * The maximum value the `ResistanceChange` event will report.
	 * @throws {@link PhidgetError}
	 */
	get maxResistance() { return this.getMaxResistance(); }
	/**
	 * The channel will not issue a `ResistanceChange` event until the resistance value has changed by the amount specified by the `resistanceChangeTrigger`.
	 * 
	 * *   Setting the `resistanceChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering
	 * @throws {@link PhidgetError}
	 */
	get resistanceChangeTrigger() { return this.getResistanceChangeTrigger(); }
	/**
	 * The minimum value that the `resistanceChangeTrigger` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minResistanceChangeTrigger() { return this.getMinResistanceChangeTrigger(); }
	/**
	 * The maximum value that `resistanceChangeTrigger` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxResistanceChangeTrigger() { return this.getMaxResistanceChangeTrigger(); }
	/**
	 * Select the RTD wiring configuration.
	 * 
	 * *   More information about RTD wiring can be found in the user guide.
	 * @throws {@link PhidgetError}
	 */
	get RTDWireSetup() { return this.getRTDWireSetup(); }

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `ResistanceChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * *   The timing between `ResistanceChange` events can also be affected by the `resistanceChangeTrigger`.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getDataInterval(): number {
		this._assertOpen();

		if (this.data.dataInterval === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return this.data.dataInterval;
	}

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `ResistanceChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * *   The timing between `ResistanceChange` events can also be affected by the `resistanceChangeTrigger`.
	 * @throws {@link PhidgetError}
	 * @param dataInterval - The data interval value
	 */
	async setDataInterval(dataInterval: number): Promise<void> {
		this._assertOpen();

		if (dataInterval < this.data.minDataInterval || dataInterval > this.data.maxDataInterval)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDataInterval + " - " + this.data.maxDataInterval + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: dataInterval });
		await bp.send(this._ch, BP.SETDATAINTERVAL);
	}

	/**
	 * The minimum value that `dataInterval` can be set to.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getMinDataInterval(): number {
		this._assertOpen();

		if (this.data.minDataInterval === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDataInterval);
	}

	/**
	 * The maximum value that `dataInterval` can be set to.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getMaxDataInterval(): number {
		this._assertOpen();

		if (this.data.maxDataInterval === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDataInterval);
	}

	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @returns The data rate for the channel
	 * @throws {@link PhidgetError}
	 */
	getDataRate(): number {
		this._assertOpen();

		if (this.data.dataInterval === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (1000.0 / this.data.dataInterval);
	}

	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @throws {@link PhidgetError}
	 * @param dataRate - The data rate for the channel
	 */
	async setDataRate(dataRate: number): Promise<void> {
		this._assertOpen();

		if (dataRate < this.data.minDataRate || dataRate > this.data.maxDataRate)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDataRate + " - " + this.data.maxDataRate + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: Math.round(1000.0 / dataRate) });
		bp.set({ name: "1", type: "g", value: (1000.0 / dataRate) });
		await bp.send(this._ch, BP.SETDATAINTERVAL);
	}

	/**
	 * The minimum value that `dataRate` can be set to.
	 * @returns The data rate value
	 * @throws {@link PhidgetError}
	 */
	getMinDataRate(): number {
		this._assertOpen();

		if (this.data.minDataRate === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDataRate);
	}

	/**
	 * The maximum value that `dataRate` can be set to.
	 * @returns The data rate value
	 * @throws {@link PhidgetError}
	 */
	getMaxDataRate(): number {
		this._assertOpen();

		if (this.data.maxDataRate === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDataRate);
	}

	/**
	 * The most recent resistance value that the channel has reported.
	 * 
	 * *   This value will always be between `minResistance` and `maxResistance`.
	 * *   The `resistance` value will change when the device is also being used as a temperature sensor. This is a side effect of increasing accuracy on the temperature channel.
	 * @returns The resistance value
	 * @throws {@link PhidgetError}
	 */
	getResistance(): number {
		this._assertOpen();

		if (this.data.resistance === PUNK.DBL || Number.isNaN(this.data.resistance))
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);
		if (this.data.resistance > this.data.maxResistance)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE_HIGH);
		if (this.data.resistance < this.data.minResistance)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE_LOW);

		return (this.data.resistance);
	}

	/**
	 * The minimum value the `ResistanceChange` event will report.
	 * 
	 * *   When the device is also being used as a TemperatureSensor the `minResistance` and `maxResistance` will not represent the true input range. This is a side effect of increasing accuracy on the temperature channel.
	 * @returns The minimum resistance
	 * @throws {@link PhidgetError}
	 */
	getMinResistance(): number {
		this._assertOpen();

		if (this.data.minResistance === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minResistance);
	}

	/**
	 * The maximum value the `ResistanceChange` event will report.
	 * @returns The resistance value
	 * @throws {@link PhidgetError}
	 */
	getMaxResistance(): number {
		this._assertOpen();

		if (this.data.maxResistance === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxResistance);
	}

	/**
	 * The channel will not issue a `ResistanceChange` event until the resistance value has changed by the amount specified by the `resistanceChangeTrigger`.
	 * 
	 * *   Setting the `resistanceChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering
	 * @returns The change trigger value
	 * @throws {@link PhidgetError}
	 */
	getResistanceChangeTrigger(): number {
		this._assertOpen();

		if (this.data.resistanceChangeTrigger === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.resistanceChangeTrigger);
	}

	/**
	 * The channel will not issue a `ResistanceChange` event until the resistance value has changed by the amount specified by the `resistanceChangeTrigger`.
	 * 
	 * *   Setting the `resistanceChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering
	 * @throws {@link PhidgetError}
	 * @param resistanceChangeTrigger - The change trigger value
	 */
	async setResistanceChangeTrigger(resistanceChangeTrigger: number): Promise<void> {
		this._assertOpen();

		if (resistanceChangeTrigger < this.data.minResistanceChangeTrigger || resistanceChangeTrigger > this.data.maxResistanceChangeTrigger)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minResistanceChangeTrigger + " - " + this.data.maxResistanceChangeTrigger + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: resistanceChangeTrigger });
		await bp.send(this._ch, BP.SETCHANGETRIGGER);
	}

	/**
	 * The minimum value that the `resistanceChangeTrigger` can be set to.
	 * @returns The change trigger value
	 * @throws {@link PhidgetError}
	 */
	getMinResistanceChangeTrigger(): number {
		this._assertOpen();

		if (this.data.minResistanceChangeTrigger === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minResistanceChangeTrigger);
	}

	/**
	 * The maximum value that `resistanceChangeTrigger` can be set to.
	 * @returns The change trigger value
	 * @throws {@link PhidgetError}
	 */
	getMaxResistanceChangeTrigger(): number {
		this._assertOpen();

		if (this.data.maxResistanceChangeTrigger === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxResistanceChangeTrigger);
	}

	/**
	 * Select the RTD wiring configuration.
	 * 
	 * *   More information about RTD wiring can be found in the user guide.
	 * @returns Wire setup value
	 * @throws {@link PhidgetError}
	 */
	getRTDWireSetup(): Enum.RTDWireSetup {
		this._assertOpen();

		if (this.data.RTDWireSetup === PUNK.ENUM)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.RTDWireSetup);
	}

	/**
	 * Select the RTD wiring configuration.
	 * 
	 * *   More information about RTD wiring can be found in the user guide.
	 * @throws {@link PhidgetError}
	 * @param RTDWireSetup - Wire setup value
	 */
	async setRTDWireSetup(RTDWireSetup: Enum.RTDWireSetup): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (!SEnum.supportedRTDWireSetup(this._ch!, RTDWireSetup))
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Specified RTDWireSetup is unsupported by this device.");

		bp.set({ name: "0", type: "d", value: RTDWireSetup });
		await bp.send(this._ch, BP.SETRTDWIRESETUP);
	}

}
export { ResistanceInputBase };
