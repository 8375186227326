/** @internal */
export const ChannelClassName = {
	0: 'PhidgetNone',
	1: 'PhidgetAccelerometer',
	35: 'PhidgetBLDCMotor',
	14: 'PhidgetCapacitiveTouch',
	2: 'PhidgetCurrentInput',
	38: 'PhidgetCurrentOutput',
	3: 'PhidgetDataAdapter',
	4: 'PhidgetDCMotor',
	36: 'PhidgetDictionary',
	5: 'PhidgetDigitalInput',
	6: 'PhidgetDigitalOutput',
	7: 'PhidgetDistanceSensor',
	8: 'PhidgetEncoder',
	32: 'PhidgetFirmwareUpgrade',
	9: 'PhidgetFrequencyCounter',
	33: 'PhidgetGeneric',
	10: 'PhidgetGPS',
	12: 'PhidgetGyroscope',
	13: 'PhidgetHub',
	15: 'PhidgetHumiditySensor',
	16: 'PhidgetIR',
	11: 'PhidgetLCD',
	17: 'PhidgetLightSensor',
	18: 'PhidgetMagnetometer',
	19: 'PhidgetMeshDongle',
	34: 'PhidgetMotorPositionController',
	39: 'PhidgetMotorVelocityController',
	37: 'PhidgetPHSensor',
	20: 'PhidgetPowerGuard',
	21: 'PhidgetPressureSensor',
	22: 'PhidgetRCServo',
	23: 'PhidgetResistanceInput',
	24: 'PhidgetRFID',
	25: 'PhidgetSoundSensor',
	26: 'PhidgetSpatial',
	27: 'PhidgetStepper',
	28: 'PhidgetTemperatureSensor',
	29: 'PhidgetVoltageInput',
	30: 'PhidgetVoltageOutput',
	31: 'PhidgetVoltageRatioInput',
}

