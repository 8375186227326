﻿import { PhidgetConnection, ConnectionOptions, ConnType } from '../Connection';
import { ErrorCode, DeviceClass } from '../Enumerations.gen';
import { PhidgetError } from '../PhidgetError';
import { logerr, logverbose, logwarn, logdebug } from '../Logging';
import { PhidgetUSBDevice } from './PhidgetUSBDevice';
import { LocalChannel } from './LocalChannel';
import { type ChannelData } from '../Channel';
import { LocalDevice } from './LocalDevice';
import { CreateUSBDevice } from './CreateUSBDevice';
import { HubDevice } from './device/HubDevice';
import { PhidgetUniqueChannel } from '../Devices.gen';

let USBConnectionCnt = 0;

export const enum USBConnType { 
	Web, Node
}

export abstract class USBConnectionBase extends PhidgetConnection {
	/** @internal */
	declare _channels: Map<string, LocalChannel>;
	/** @internal */
	declare _devices: Map<string, LocalDevice>;
	/** @internal */
	protected _webusb: USB;
	/** @internal */
	private _boundondisconnect?: (event: USBConnectionEvent) => void;
	/** @internal */
	readonly _type: ConnType;
	/** @internal */
	readonly _usbType!: USBConnType;

	/** @internal */
	private _scanTimeout: NodeJS.Timer | undefined;

	/** @internal */
	protected abstract _trackUSBDevice(dev: USBDevice): boolean;
	/** @internal */
	protected abstract _untrackUSBDevice(dev: USBDevice): void;
	/** @internal */
	protected abstract _getPUSBDevice(dev: USBDevice): PhidgetUSBDevice | undefined;
	/** @internal */
	protected abstract _addPUSBDevice(dev: PhidgetUSBDevice): void;
	/** @internal */
	protected abstract _deletePUSBDevice(dev: PhidgetUSBDevice): void;
	/** @internal */
	abstract _setEpTimeout(dev: USBDevice, ep: number, timeout: number): void;
	/** @internal */
	protected abstract _pUSBDevices: Map<unknown, PhidgetUSBDevice>;
	/** @internal */
	protected abstract _getDevices(): Promise<USBDevice[]>;

	/** @internal */
	private _nextScanDelay: number;


	/**
	 * Grant access to a USB device in the browser.
	 * This function can only be called from a user-interactable object, such as a button.
	 */
	abstract requestWebUSBDeviceAccess(): Promise<void>;

	/** @internal */
	constructor(webusb: USB, usbtype: USBConnType, opts?: ConnectionOptions) {
		if (USBConnectionCnt >= 1)
			throw new PhidgetError(ErrorCode.DUPLICATE, "Only one USB Connection may be active at a time.");
		USBConnectionCnt++;

		const options: ConnectionOptions = {};
		if (opts != undefined) {
			if (opts.onError)
				options.onError = opts.onError;
			if (opts.name)
				options.name = opts.name;
			else
				options.name = 'webusb';
		}

		super(options);
		this._type = ConnType.LOCAL;
		this._usbType = usbtype;
		this._webusb = webusb;
		this._nextScanDelay = 0;
	}

	delete() {
		super.delete();
		USBConnectionCnt--;
	}

	/**
	 * Stops USB scanning
	 */
	close() {
		if (this.connected !== true)
			return;

		if (this._boundondisconnect)
			this._webusb.removeEventListener('disconnect', this._boundondisconnect);
		delete this._boundondisconnect;

		if (this._scanTimeout != undefined) {
			clearTimeout(this._scanTimeout);
			delete this._scanTimeout;
		}

		this._detachAllDevices();

		this.connected = false;
	}

	/**
	 * Connects the the USB subsystem and starts scanning for USB devices
	 */
	// eslint-disable-next-line require-await
	async connect() {
		if (this.connected === true)
			return;

		this.connected = true;
		this._boundondisconnect = this._ondisconnect.bind(this);
		this._webusb.addEventListener('disconnect', this._boundondisconnect);

		// start scanning
		this.connected === true;

		const again = () => {
			// if still connected, scan again in (250+)ms
			if (this.connected) {
				logverbose('Next scan in: ' + (250 + this._nextScanDelay));
				this._scanTimeout = setTimeout(scan, 250 + this._nextScanDelay);
			}
		};

		const scan = () => {
			delete this._scanTimeout;
			this._scanWebUSBDevices().then(() => {
				again();
			}).catch(err => {
				logerr("Error during USB scan", err);
				// If a scan failed, we add a random delay before the next scan, because failure is generally caused by 2 instances interfering
				this._nextScanDelay = (Math.random() * 100);
				again();
			});
		}

		scan();
	}

	//////////////
	// Internal //
	//////////////

	/** @internal */
	_getDevice(phid: string) {
		return super._getDevice(phid) as LocalDevice;
	}

	//Scan for devices that have had access granted, but have not already been added to the internal Channel and Device lists
	/** @internal */
	async _scanWebUSBDevices() {

		if (!this.connected)
			throw new PhidgetError(ErrorCode.NOT_ATTACHED, "Not connected");

		const usbDevices = await this._getDevices();

		this._nextScanDelay = 0;
		await Promise.all(usbDevices.map(async (usbDevice) => {

			const isNewDevice = this._trackUSBDevice(usbDevice);
			let dev = this._getPUSBDevice(usbDevice);

			// New Device
			if (dev == undefined) {
				try {
					dev = await CreateUSBDevice(this, usbDevice);
					this._addPUSBDevice(dev);
					this._attachLocalDevice(dev);
				} catch (err) {
					// Something went wrong with this device - swallow the error and move on
					(isNewDevice ? logwarn : logverbose)("Error scanning device", err);
					// If a scan failed, we add a random delay before the next scan, because failure is generally caused by 2 instances interfering
					this._nextScanDelay = (Math.random() * 100);
					return;
				}
			}

			// Continuously check VINT HUBs for added/removed VINT devices
			if (dev instanceof HubDevice) {
				try {
					await dev.lock();
					let opened = false;
					try {
						await dev.open(false);
						opened = true;
						await dev.scanVINTDevices();
					} finally {
						if (opened)
							await dev.close(false, false);
					}
					dev.scanError = 0;
				} catch (err) {
					// Something went wrong with this device - swallow the error and move on
					(dev.scanError == 0 ? logwarn : logverbose)("Error scanning device", err);
					dev.scanError++;
					// If a scan failed, we add a random delay before the next scan, because failure is generally caused by 2 instances interfering
					this._nextScanDelay = (Math.random() * 100);
					return;
				} finally {
					dev.unlock();
				}
			}
		}));
	}

	/** @internal */
	_ondisconnect(event: USBConnectionEvent) {

		logdebug("USB Device disconnect: " + event.device.productName);
		this._untrackUSBDevice(event.device);
		const pUsbDevice = this._getPUSBDevice(event.device);
		if (pUsbDevice == undefined) {
			logdebug("Device is not known. Moving on.");
			return;
		}

		// Detach associated devices/channels
		// Find the top-level device associated with this USB Device (there should only be one!)
		const dev = Array.from(this._devices.values()).find(dev => dev === pUsbDevice && dev.parent == undefined);
		if (dev == undefined) {
			logdebug("couldn't find detaching device in Devices list!");
			return;
		}

		// Find devices with this device as their parent (VINT devices) and detach them 1st
		const children = Array.from(this._devices.values()).filter(d => d.parent === dev);
		for (const child of children)
			this._deviceDetach(child);

		// Detach main device
		this._deviceDetach(dev);

		// Remove USB device from array
		this._deletePUSBDevice(pUsbDevice);
	}

	/** @internal */
	_attachLocalDevice(device: LocalDevice) {
		this._deviceAttach(device);
		let channelCnt = 0;
		for (const chDef of <Required<PhidgetUniqueChannel>[]>device.devDef.ch) {
			for (let index = 0; index < (chDef.n ?? 1); index++, channelCnt++) {
				// Just needs to be a unique string representing this channel
				const id = (chDef.c + '_'
					+ device.serialNumber + '_'
					+ device.hubPort + '_'
					+ channelCnt + '_'
					+ (device.class === DeviceClass.VINT ? '1' : '0') + '_'
					+ (device.isHubPort ? '1' : '0'));

				const chData: ChannelData = {
					id: id,
					chDef: chDef,
					uniqueIndex: channelCnt,
					index: index + chDef.i
				};
				const ch = new LocalChannel(this, device, chData);
				device.channels[channelCnt] = ch;
				this._channelAttach(ch);
			}
		}
	}

	/** @internal */
	_deviceDetach(dev: LocalDevice) {
		super._deviceDetach(dev);

		dev.lock().then(() => {
			return dev.close(true);
		}).then(() => {
			dev.unlock();
		}).catch(err => {
			logwarn("Error closing during detach", err);
			dev.unlock();
		});
	}

	/** @internal */
	_usbErrorDetach(dev: PhidgetUSBDevice) {

		logwarn("Detaching device because of USB error.");

		// Find devices with this device as their parent (VINT devices) and detach them 1st
		const children = Array.from(this._devices.values()).filter(d => d.parent === dev);
		for (const child of children)
			this._deviceDetach(child);

		// Detach main device
		this._deviceDetach(dev);

		// Remove USB device from array
		this._deletePUSBDevice(dev);
	}

}