import { VoltageOutputBase } from './VoltageOutput.gen';
import { VoltageOutputRange } from '../Enumerations.gen';
import { BP } from '../BridgePackets.gen';
import { BridgePacket } from '../BridgePacket';

/** @public */
class VoltageOutput extends VoltageOutputBase {

	/** @internal */
	_bridgeInput(bp: BridgePacket) {
		switch (bp.vpkt) {
			case BP.SETVOLTAGERANGE:
				super._bridgeInput(bp);
				switch (this.data.voltageOutputRange) {
					case VoltageOutputRange.VOLTS_10:
						this.data.minVoltage = -10;
						this.data.maxVoltage = 10;
						break;
					case VoltageOutputRange.VOLTS_5:
						this.data.minVoltage = 0;
						this.data.maxVoltage = 5;
						break;
				}
				this._FIREPropertyChange('MinVoltage', bp);
				this._FIREPropertyChange('MaxVoltage', bp);
				break;
			default:
				super._bridgeInput(bp);
				break;
		}
	}
}

export { VoltageOutput };