import { EncoderBase } from './Encoder.gen';
import { BP } from '../BridgePackets.gen';
import { BridgePacket, PUNK } from '../BridgePacket';
import { logEventException } from '../Logging';

/** @public */
class Encoder extends EncoderBase {

	/** @internal */
	_bridgeInput(bp: BridgePacket) {
		switch (bp.vpkt) {
			case BP.DATAINTERVALCHANGE: {
				if (bp.entryCount > 1)
					this.data.dataInterval = bp.getNumber(1);
				else
					this.data.dataInterval = bp.getNumber(0);

				this._FIREPropertyChange('DataInterval');
				this._FIREPropertyChange('DataRate');
				break;
			}
			case BP.POSITIONCHANGE: {
				const positionChange = bp.getNumber(0);
				const timeChange = bp.getNumber(1);
				const indexTriggered = bp.getBoolean(2) as boolean;
				let indexPosition = 0;
				if (indexTriggered) {
					indexPosition = bp.getNumber(3);
					this.data.indexPosition = this.data.position + indexPosition;
				}
				this.data.position += positionChange;
				if (this._isAttachedDone && this.onPositionChange) {
					try {
						this.onPositionChange(positionChange, timeChange, indexTriggered)
					} catch (err) { logEventException(err); }
				}
				break;
			}
			default:
				super._bridgeInput(bp);
				break;
		}
	}

	setPosition(position: number) {
		this._assertOpen();

		if (this.data.indexPosition != PUNK.INT64)
			this.data.indexPosition += (position - this.data.position);
		this.data.position = position;
	}
}

export { Encoder };