﻿import { ErrorCode } from './Enumerations.gen';
import { PhidgetError } from './PhidgetError';
import { loginfo, logverbose, logerr } from './Logging';
import { Channel } from './Channel';
import { PhidgetChannel } from './Phidget';
import {
	Connections, scanUserPhidgets,
	managerDeviceAttach, managerChannelDetach, managerDeviceDetach, managerChannelAttach
} from './phidget22';
import { Device } from './Device';

type ConnectionHandler = (conn: PhidgetConnection) => void;

let _onConnectionRemoved: ConnectionHandler | null = null;
let _onConnectionAdded: ConnectionHandler | null = null;
function onConnectionRemoved(conn: PhidgetConnection) {
	if (_onConnectionRemoved)
		_onConnectionRemoved(conn);
}
function onConnectionAdded(conn: PhidgetConnection) {
	if (_onConnectionAdded)
		_onConnectionAdded(conn);
}

type ConnectionErrorHandler = (this: PhidgetConnection, code: ErrorCode, msg: string) => void;

let ConnectionID = 0;

export interface ConnectionOptions { name?: string; onError?: ConnectionErrorHandler; }

/** @internal */
export const enum ConnType {
	LOCAL = 0,
	REMOTE = 1
}

/** @public */
export abstract class PhidgetConnection {
	/** @internal */
	_channels: Map<string, Channel>;
	/** @internal */
	_devices: Map<string, Device>;
	/** @internal */
	_id: number;

	/** @internal */
	readonly abstract _type: ConnType;

	/** Connects */
	protected abstract connect(): Promise<void>;
	/** Closes the connection */
	protected abstract close(): void;

	/** Connection status */
	public connected: boolean;
	/** Name assigned to this connection via constructor options */
	public readonly name: string;
	/**
	* **Error** event
	*  * `code` - The error code
	*  * `msg` - The error description
	* ---
	* `Error` is called when an error condition has been detected on the connection.
	*/
	public onError: ConnectionErrorHandler | null = null;

	/** Sets the global connection removed event handler */
	static setOnConnectionRemoved(func: ConnectionHandler) { _onConnectionRemoved = func; }
	/** Sets the global connection added event handler */
	static setOnConnectionAdded(func: ConnectionHandler) { _onConnectionAdded = func; }

	/** @internal */
	constructor(opts?: ConnectionOptions) {

		if (opts != undefined && typeof opts !== 'object')
			throw new Error('Options argument must be an object');

		// NOTE: This is solely here for the control panel
		this._id = ConnectionID++;

		this.connected = false;	/* currently connected */

		this._channels = new Map();
		this._devices = new Map();

		this.name = '';

		/*
		 * Handle user config after basic setup.
		 */
		if (opts != undefined) {

			if (opts.name != undefined && typeof opts.name === 'string')
				this.name = opts.name;

			if (opts.onError != undefined && typeof opts.onError === 'function')
				this.onError = opts.onError;
		}

		if (this.onError === undefined) {
			this.onError = function (code, msg) {
				logerr("Connection error: " + msg + ':0x' + code.toString(16));
			};
		}

		Connections.push(this);
		onConnectionAdded(this);
	}

	/** Removes this connection from the internal Connection list, and cleans up Connection resources */
	delete() {

		if (this.connected)
			throw (new PhidgetError(ErrorCode.BUSY, 'close connection before deleting'));

		onConnectionRemoved(this);

		if (Connections.includes(this))
			Connections.splice(Connections.indexOf(this), 1);
	}

	/** A unique key representing this connection */
	getKey() { return this._id; }
	/** A unique key representing this connection */
	get key() { return this._id; }

	//////////////
	// Internal //
	//////////////

	/** @internal */
	get _isLocal() { return this._type === ConnType.LOCAL; }
	/** @internal */
	get _isRemote() { return this._type === ConnType.REMOTE; }

	/** @internal */
	_getChannel(id: string) {
		const ch = this._channels.get(id);
		if (ch == undefined)
			throw (new PhidgetError(ErrorCode.UNEXPECTED, 'invalid channel id:' + id));
		return (ch);
	}

	/** @internal */
	_getDevice(id: string) {
		const dev = this._devices.get(id);
		if (dev == undefined)
			return (null);
		return (dev);
	}

	/** @internal */
	protected _detachAllDevices() {

		while (this._devices.size > 0) {
			// Remove in reverse order so we aren't removing a hub before it's devices, etc.
			const last = Array.from(this._devices.values())[this._devices.size - 1];
			// NOTE: This removes that device from the list, so we can't just iterate
			try {
				this._deviceDetach(last);
			} catch (err) {
				logerr("Error while detaching all devices", err);
			}
		}
	}

	/** @internal */
	protected _deviceAttach(dev: Device) {
		if (this._devices.has(dev.id))
			throw new PhidgetError(ErrorCode.DUPLICATE, 'duplicate device:' + dev);

		(dev.isHubPort ? logverbose : loginfo)("Device Attach: " + dev);
		this._devices.set(dev.id, dev);
		managerDeviceAttach(dev);
	}

	/** @internal */
	_deviceDetach(dev: Device) {
		if (!(this._devices.has(dev.id)))
			throw new PhidgetError(ErrorCode.NO_SUCH_ENTITY, 'no such device:' + dev);

		for (const ch of this._channels.entries()) {
			if (ch[1].parent === dev) {
				logverbose("Channel Detach: " + ch[1]);
				managerChannelDetach(ch[1]);
				ch[1].detach();
				this._channels.delete(ch[0]);
			}
		}

		(dev.isHubPort ? logverbose : loginfo)("Device Detach: " + dev);
		managerDeviceDetach(dev);
		this._devices.delete(dev.id);
	}

	/** @internal */
	protected _channelAttach(ch: Channel) {
		if (this._channels.has(ch.id))
			throw new PhidgetError(ErrorCode.DUPLICATE, 'duplicate channel:' + ch);

		logverbose("Channel Attach: " + ch);
		this._channels.set(ch.id, ch);
		managerChannelAttach(ch);
		scanUserPhidgets(ch).catch(err => {
			logerr("Error scanning user phidgets", err);
		});
	}

	/** @internal */
	async _match(userphid: PhidgetChannel) {

		for (const ch of this._channels.values()) {
			if (await ch.tryMatchOpen(userphid))
				return true;
		}
		return false;
	}
}