﻿import { ErrorCode, ChannelClass, ErrorEventCode, SpatialAlgorithm, SpatialPrecision } from '../../Enumerations.gen';
import { PhidgetError } from '../../PhidgetError';
import { PhidgetUSBRequestType } from '../USB';
import { BridgePacket, PUNK } from '../../BridgePacket';
import { BP } from '../../BridgePackets.gen';
import { UpperPowerOfTwo, RoundDouble } from '../../Utils';
import { USBConnectionBase } from '../USBConnection';
import { LocalChannel } from '../LocalChannel';
import { PhidgetUSBDevice, PhidgetUSBDeviceData } from '../PhidgetUSBDevice';
import { DeviceUID, DeviceChannelUID } from '../../Devices.gen';
import { SpatialQuaternion } from '../../Structs.gen';
import * as VintPacketType from '../VintPacketType';
import * as UserPhidgetClass from '../../Classes.gen';

const enum SpatialPacketType {
	//IN
	DATA = 0x00,
	CALIB = 0x80,
	//OUT
	READCALIB = 0x01,
	SET_POLLING_TYPE = 0x02,
	ZERO_GYRO = 0x03,
	UNZERO_GYRO = 0x04,
	ZERO_AHRS = 0x05,
	CONFIGURE_AHRS = 0x06,
	RESET_AHRS = 0x07,
	MAGNETOMETER_RESET_CORRECTION_PARAMETERS = 0x08,
	MAGNETOMETER_SAVE_CORRECTION_PARAMETERS = 0x09,
	MAGNETOMETER_SET_CORRECTION_PARAMETERS = 0x10,
	RESET = 0x11,
	HEATING = 0x12
}

const ZERO_GYRO_TIME = 2000;
const MIN_DATA_RATE = 1000;

const ACCEL_CHANNEL = 0;
const GYRO_CHANNEL = 1;
const MAG_CHANNEL = 2;
const SPT_CHANNEL = 3;
const TMP_CHANNEL = 4;

const ACCEL_SEND_FLAG = (1 << ACCEL_CHANNEL);
const GYRO_SEND_FLAG = (1 << GYRO_CHANNEL);
const MAG_SEND_FLAG = (1 << MAG_CHANNEL);
const SPT_SEND_FLAG = (1 << SPT_CHANNEL);
const TMP_SEND_FLAG = (1 << TMP_CHANNEL);

interface DataBufferEntry {
	acceleration: number[],
	angularRate: number[],
	magneticField: number[],
	quaternion: number[],
	timestamp: number,
	temperature: number
}

interface SpatialDeviceData {
	// Public members
	timestamp: number[],
	acceleration: number[][],
	angularRate: number[][],
	magneticField: number[][],
	temperature: number[],
	quaternion: Array<SpatialQuaternion | null>,
	// Private members
	dataInterval: number,
	temperatureDataInterval: number,
	accelerationChangeTrigger: number,
	magneticFieldChangeTrigger: number,
	temperatureChangeTrigger: number,
	lastTimeCounter?: number,
	latestDataTime: number,
	dataRateMax: number,
	dataRateMin: number,
	accelAxisLastTrigger: number[],
	gyroCorrection: number[],
	magAxisLastTrigger: number[],
	lastEventTime: number,
	doZeroGyro: boolean,
	accelerationMax: number,
	accelerationMin: number,
	interruptRate: number,
	angularRateMax: number,
	angularRateMin: number,
	magneticFieldMax: number,
	magneticFieldMin: number,
	userMagField: number,
	calDataValid: boolean,
	AHRSMagGain: number,
	algorithm: SpatialAlgorithm,
	temperatureMax: number,
	temperatureMin: number,
	lastTemperatureEventTime: number,
	temperatureLastTrigger: number,
	dataBuffer: DataBufferEntry[],
	heatingEnabled: boolean | PUNK.BOOL,
}

class SpatialDevice extends PhidgetUSBDevice {
	data: SpatialDeviceData;

	constructor(conn: USBConnectionBase, data: PhidgetUSBDeviceData, usbDev: USBDevice) {
		super(conn, data, usbDev);

		// Ensure that we actually support this device
		switch (this.devDef.uid) {
			/*START_UNRELEASED*/
			case DeviceUID._MOT0108:
				break;
			/*END_UNRELEASED*/
			case DeviceUID._MOT0109:
			case DeviceUID._MOT0110_USB:
				break;
			default:
				throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}

		this.data = this.initData();
	}

	// Define getters based on devices.h Attr structs in C library
	get numAccelAxes() { return this.devDef.cn[0]; }
	get numGyroAxes() { return this.devDef.cn[1]; }
	get numCompassAxes() { return this.devDef.cn[2]; }
	get numSpatialInputs() { return this.devDef.cn[3]; }
	get numTempInputs() { return this.devDef.cn[4]; }

	updateTimestamp(time: number) {
		if (this.data.lastTimeCounter != undefined) {
			const timechange = (time - this.data.lastTimeCounter) & 0xFFFF;
			this.data.timestamp[0] += timechange;
		}

		this.data.lastTimeCounter = time;
	}

	updateLatestDataTime(i: number) {
		this.data.latestDataTime = this.data.timestamp[0] + (i + 1) * this.data.dataRateMax;
	}

	timestampDiff(time1: number, time2: number) {
		return (time1 - time2);
	}

	initData() {
		const data: SpatialDeviceData = {

			// Public members
			timestamp: [0],
			acceleration: [new Array(this.numAccelAxes).fill(PUNK.DBL)],
			angularRate: [new Array(this.numGyroAxes).fill(PUNK.DBL)],
			magneticField: [new Array(this.numCompassAxes).fill(PUNK.DBL)],
			temperature: new Array(this.numTempInputs).fill(PUNK.DBL),
			quaternion: [null],

			// Private members
			dataInterval: 0,
			temperatureDataInterval: 0,
			accelerationChangeTrigger: 0,
			magneticFieldChangeTrigger: 0,
			temperatureChangeTrigger: 0,
			accelAxisLastTrigger: new Array(this.numAccelAxes).fill(PUNK.DBL),
			gyroCorrection: new Array(this.numGyroAxes).fill(0),
			magAxisLastTrigger: new Array(this.numCompassAxes).fill(PUNK.DBL),
			lastEventTime: 0,
			latestDataTime: 0,
			doZeroGyro: false,
			dataBuffer: [],
			accelerationMax: 0,
			accelerationMin: 0,
			interruptRate: 0,
			dataRateMax: 0,
			dataRateMin: 0,
			angularRateMax: 0,
			angularRateMin: 0,
			magneticFieldMax: 0,
			magneticFieldMin: 0,
			AHRSMagGain: 0,
			userMagField: 0,
			calDataValid: false,
			algorithm: 0,
			temperatureMax: 0,
			temperatureMin: 0,
			lastTemperatureEventTime: 0,
			temperatureLastTrigger: PUNK.DBL,
			heatingEnabled: false,
		};
		return data;
	}

	// eslint-disable-next-line require-await
	async initAfterOpen() {
		this.data = this.initData();

		this.data.accelerationMax = 8;
		this.data.accelerationMin = -8;
		this.data.interruptRate = 4;
		this.data.dataRateMin = MIN_DATA_RATE;
		this.data.dataInterval = 8;
		this.data.temperatureDataInterval = 100;
		this.data.dataRateMax = 4; //actual data rate
		this.data.angularRateMax = 2000;
		this.data.angularRateMin = -2000;
		this.data.magneticFieldMax = 5000;
		this.data.magneticFieldMin = -5000;
		this.data.userMagField = 1.0;
		this.data.calDataValid = false;
		this.data.AHRSMagGain = 0.005;
		this.data.algorithm = SpatialAlgorithm.AHRS;
		this.data.temperatureMax = 85;
		this.data.temperatureMin = -40;
	}

	async bridgeInput(channel: LocalChannel, bp: BridgePacket) {
		let buffer;
		const buf = new DataView(new ArrayBuffer(24));
		let userphid;

		switch (this.devDef.uid) {
			case DeviceUID._MOT0109:
				switch (channel.class) {

					case ChannelClass.ACCELEROMETER:
						if (channel.index !== 0)
							throw new PhidgetError(ErrorCode.INVALID);
						switch (bp.vpkt) {
							case BP.SETDATAINTERVAL:
								this.setDataRate(channel, bp.getNumber(0));
								break;
							case BP.SETCHANGETRIGGER:
								this.data.accelerationChangeTrigger = bp.getNumber(0);
								break;
							case BP.SETSPATIALPRECISION:
								await this.setPrecision(bp.getNumber(0) as SpatialPrecision);
								break;
							case BP.SETHEATINGENABLED:
								buffer = new Uint8Array([bp.getNumber(0)]);
								await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, SpatialPacketType.HEATING, channel.uniqueIndex, buffer);
								break;
							case BP.OPENRESET:
							case BP.CLOSERESET:
								await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, SpatialPacketType.RESET, channel.uniqueIndex);
								break;
							case BP.ENABLE:
								break;
							default:
								throw new PhidgetError(ErrorCode.UNEXPECTED);
						}
						break;

					case ChannelClass.GYROSCOPE:
						if (channel.index !== 0)
							throw new PhidgetError(ErrorCode.INVALID);
						switch (bp.vpkt) {
							case BP.SETDATAINTERVAL:
								this.setDataRate(channel, bp.getNumber(0));
								break;
							case BP.ZERO:
								await this.zeroGyro();
								break;
							case BP.SETSPATIALPRECISION:
								await this.setPrecision(bp.getNumber(0) as SpatialPrecision);
								break;
							case BP.SETHEATINGENABLED:
								buffer = new Uint8Array([bp.getNumber(0)]);
								await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, SpatialPacketType.HEATING, channel.uniqueIndex, buffer);
								break;
							case BP.OPENRESET:
							case BP.CLOSERESET:
								await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, SpatialPacketType.RESET, channel.uniqueIndex);
								break;
							case BP.ENABLE:
								break;
							default:
								throw new PhidgetError(ErrorCode.UNEXPECTED);
						}
						break;

					case ChannelClass.MAGNETOMETER:
						if (channel.index !== 0)
							throw new PhidgetError(ErrorCode.INVALID);
						switch (bp.vpkt) {
							case BP.SETDATAINTERVAL:
								this.setDataRate(channel, bp.getNumber(0));
								break;
							case BP.SETCHANGETRIGGER:
								this.data.magneticFieldChangeTrigger = bp.getNumber(0);
								break;
							case BP.SAVECORRECTIONPARAMETERS:
								await this.saveCompassCorrectionParameters();
								break;
							case BP.RESETCORRECTIONPARAMETERS:
								await this.resetCompassCorrectionParameters();
								break;
							case BP.SETCORRECTIONPARAMETERS: {
								const corrParams = {
									magField: bp.getNumber(0),
									offset0: bp.getNumber(1),
									offset1: bp.getNumber(2),
									offset2: bp.getNumber(3),
									gain0: bp.getNumber(4),
									gain1: bp.getNumber(5),
									gain2: bp.getNumber(6),
									T0: bp.getNumber(7),
									T1: bp.getNumber(8),
									T2: bp.getNumber(9),
									T3: bp.getNumber(10),
									T4: bp.getNumber(11),
									T5: bp.getNumber(12)
								}
								await (this.setCompassCorrectionParameters(corrParams));
								break;
							}
							case BP.SETHEATINGENABLED:
								buffer = new Uint8Array([bp.getNumber(0)]);
								await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, SpatialPacketType.HEATING, channel.uniqueIndex, buffer);
								break;
							case BP.OPENRESET:
							case BP.CLOSERESET:
								await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, SpatialPacketType.RESET, channel.uniqueIndex);
								break;
							case BP.ENABLE:
								break;
							default:
								throw new PhidgetError(ErrorCode.UNEXPECTED);
						}
						break;

					case ChannelClass.SPATIAL:
						if (channel.index !== 0)
							throw new PhidgetError(ErrorCode.INVALID);
						switch (bp.vpkt) {
							case BP.SETDATAINTERVAL:
								this.setDataRate(channel, bp.getNumber(0));
								break;
							case BP.ZERO:
								await this.zeroGyro();
								break;
							case BP.SAVECORRECTIONPARAMETERS:
								await this.saveCompassCorrectionParameters();
								break;
							case BP.RESETCORRECTIONPARAMETERS:
								await this.resetCompassCorrectionParameters();
								break;
							case BP.SETCORRECTIONPARAMETERS: {
								const corrParams = {
									magField: bp.getNumber(0),
									offset0: bp.getNumber(1),
									offset1: bp.getNumber(2),
									offset2: bp.getNumber(3),
									gain0: bp.getNumber(4),
									gain1: bp.getNumber(5),
									gain2: bp.getNumber(6),
									T0: bp.getNumber(7),
									T1: bp.getNumber(8),
									T2: bp.getNumber(9),
									T3: bp.getNumber(10),
									T4: bp.getNumber(11),
									T5: bp.getNumber(12)
								}
								await this.setCompassCorrectionParameters(corrParams);
								break;
							}
							case BP.SETSPATIALPRECISION:
								await this.setPrecision(bp.getNumber(0) as SpatialPrecision);
								break;
							case BP.ZEROSPATIALALGORITHM:
								await this.zeroAHRS();
								break;
							case BP.SETSPATIALALGORITHM:
								this.data.algorithm = bp.getNumber(0) as SpatialAlgorithm;
								await this.configureAlgorithm();
								break;
							case BP.SETSPATIALALGORITHMMAGGAIN:
								this.data.AHRSMagGain = bp.getNumber(0);
								await this.configureAlgorithm();
								break;
							case BP.SETHEATINGENABLED:
								buffer = new Uint8Array([bp.getNumber(0)]);
								await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, SpatialPacketType.HEATING, channel.uniqueIndex, buffer);
								break;
							case BP.SETAHRSPARAMETERS: {
								const buf = new ArrayBuffer(24);
								buffer = new DataView(buf);
								buffer.setFloat32(0, bp.getNumber(0), true);
								buffer.setFloat32(4, bp.getNumber(1), true);
								buffer.setFloat32(8, bp.getNumber(2), true);
								buffer.setFloat32(12, bp.getNumber(3), true);
								buffer.setFloat32(16, bp.getNumber(4), true);
								buffer.setFloat32(20, bp.getNumber(5), true);
								await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, SpatialPacketType.HEATING, channel.uniqueIndex, buffer);
								break;
							}
							case BP.OPENRESET:
							case BP.CLOSERESET:
								await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, SpatialPacketType.RESET, channel.uniqueIndex);
								break;
							case BP.ENABLE:
								break;
							default:
								throw new PhidgetError(ErrorCode.UNEXPECTED);
						}
						break;

					case ChannelClass.TEMPERATURE_SENSOR:
						if (channel.index !== 0)
							throw new PhidgetError(ErrorCode.INVALID);
						switch (bp.vpkt) {
							case BP.SETDATAINTERVAL:
								this.setTemperatureDataRate(bp.getNumber(0));
								break;
							case BP.SETCHANGETRIGGER:
								this.data.temperatureChangeTrigger = bp.getNumber(0);
								break;
							case BP.OPENRESET:
							case BP.CLOSERESET:
								await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, SpatialPacketType.RESET, channel.uniqueIndex);
								break;
							case BP.ENABLE:
								break;
							default:
								throw new PhidgetError(ErrorCode.UNEXPECTED);
						}
						break;

					default:
						throw new PhidgetError(ErrorCode.UNEXPECTED);
				}
				break;
			case DeviceUID._MOT0110_USB:
				switch (channel.chDef.uid) {
					case DeviceChannelUID._MOT0110_SPATIAL_100_USB:
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						userphid = (channel.userphid! as unknown as UserPhidgetClass.Spatial);
						await userphid._transactionLock.acquire();
						try {
							switch (bp.vpkt) {
								case BP.SETDATAINTERVAL:
									buf.setUint16(0, this._handleDataIntervalPacket(bp, 1));
									await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, channel.uniqueIndex, new Uint8Array(buf.buffer, 0, 2));
									break;
								case BP.ZERO:
									await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.GyroscopePacket.ZERO, channel.uniqueIndex);
									break;
								case BP.RESETCORRECTIONPARAMETERS:
									await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.MagnetometerPacket.RESET_CORRECTION_PARAMETERS, channel.uniqueIndex);
									break;
								case BP.SAVECORRECTIONPARAMETERS:
									await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.MagnetometerPacket.SAVECORRECTIONPARAMETERS, channel.uniqueIndex);
									break;
								case BP.SETCORRECTIONPARAMETERS: {
									const corrParams = {
										magField: bp.getNumber(0),
										offset0: bp.getNumber(1),
										offset1: bp.getNumber(2),
										offset2: bp.getNumber(3),
										gain0: bp.getNumber(4),
										gain1: bp.getNumber(5),
										gain2: bp.getNumber(6),
										T0: bp.getNumber(7),
										T1: bp.getNumber(8),
										T2: bp.getNumber(9),
										T3: bp.getNumber(10),
										T4: bp.getNumber(11),
										T5: bp.getNumber(12)
									}
									await this.setCompassCorrectionParameters(corrParams);
								}
									break;
								case BP.ZEROSPATIALALGORITHM:
									await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.SpatialPacket.ZERO_AHRS, channel.uniqueIndex);
									break;
								case BP.SETSPATIALALGORITHM:
									this.data.algorithm = bp.getNumber(0) as SpatialAlgorithm;
									buf.setUint8(0, bp.getNumber(0));
									await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.SpatialPacket.AHRS_ALGORITHM, channel.uniqueIndex, new Uint8Array(buf.buffer, 0, 1));
									break;
								case BP.SETSPATIALALGORITHMMAGGAIN:
									this.data.AHRSMagGain = bp.getNumber(0);
									buf.setFloat32(0, bp.getNumber(0), true);
									await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.SpatialPacket.AHRS_MAG_GAIN, channel.uniqueIndex, new Uint8Array(buf.buffer, 0, 4));
									break;
								case BP.SETAHRSPARAMETERS:
									buf.setFloat32(0, bp.getNumber(0), true);
									buf.setFloat32(4, bp.getNumber(1), true);
									buf.setFloat32(8, bp.getNumber(2), true);
									buf.setFloat32(12, bp.getNumber(3), true);
									buf.setFloat32(16, bp.getNumber(4), true);
									buf.setFloat32(20, bp.getNumber(5), true);

									await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.SpatialPacket.SET_AHRS_PARAMS, channel.uniqueIndex, buf)
									break;
								case BP.SETHEATINGENABLED:
									this.data.heatingEnabled = bp.getBoolean(0);
									buf.setUint8(0, bp.getNumber(0));
									await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.SpatialPacket.SET_HEATING, channel.uniqueIndex, new Uint8Array(buf.buffer, 0, 1));
									break;
								case BP.OPENRESET:
								case BP.CLOSERESET:
									await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.GenericPacket.PHIDGET_RESET, channel.uniqueIndex);
									break;
								case BP.ENABLE:
									await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.GenericPacket.PHIDGET_ENABLE, channel.uniqueIndex);
									break;
								default:
									throw new PhidgetError(ErrorCode.UNEXPECTED);
							}
						}
						finally {
							userphid._transactionLock.release();
						}
						break;
					case DeviceChannelUID._MOT0110_MAGNETOMETER_100_USB:
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						userphid = (channel.userphid! as unknown as UserPhidgetClass.Magnetometer);
						await userphid.transactionLock.acquire();
						try {
							switch (bp.vpkt) {
								case BP.SETDATAINTERVAL:
									buf.setUint16(0, this._handleDataIntervalPacket(bp, 1));
									await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, channel.uniqueIndex, new Uint8Array(buf.buffer, 0, 2));
									break;
								case BP.SETCHANGETRIGGER:
									this.data.magneticFieldChangeTrigger = bp.getNumber(0);
									buf.setFloat32(0, bp.getNumber(0), true);
									await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.GenericPacket.AXES_SETAXISCHANGETRIGGER, channel.uniqueIndex, new Uint8Array(buf.buffer, 0, 4));
									break;
								case BP.RESETCORRECTIONPARAMETERS:
									await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.MagnetometerPacket.RESET_CORRECTION_PARAMETERS, channel.uniqueIndex);
									break;
								case BP.SAVECORRECTIONPARAMETERS:
									await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.MagnetometerPacket.SAVECORRECTIONPARAMETERS, channel.uniqueIndex);
									break;
								case BP.SETCORRECTIONPARAMETERS: {
									const corrParams = {
										magField: bp.getNumber(0),
										offset0: bp.getNumber(1),
										offset1: bp.getNumber(2),
										offset2: bp.getNumber(3),
										gain0: bp.getNumber(4),
										gain1: bp.getNumber(5),
										gain2: bp.getNumber(6),
										T0: bp.getNumber(7),
										T1: bp.getNumber(8),
										T2: bp.getNumber(9),
										T3: bp.getNumber(10),
										T4: bp.getNumber(11),
										T5: bp.getNumber(12)
									}
									await this.setCompassCorrectionParameters(corrParams);
								}
									break;

								case BP.SETHEATINGENABLED:
									this.data.heatingEnabled = bp.getBoolean(0);
									buf.setUint8(0, bp.getNumber(0));
									await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.SpatialPacket.SET_HEATING, channel.uniqueIndex, new Uint8Array(buf.buffer, 0, 1));
									break;
								case BP.OPENRESET:
								case BP.CLOSERESET:
									await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.GenericPacket.PHIDGET_RESET, channel.uniqueIndex);
									break;
								case BP.ENABLE:
									await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.GenericPacket.PHIDGET_ENABLE, channel.uniqueIndex);
									break;
								default:
									throw new PhidgetError(ErrorCode.UNEXPECTED);
							}
						}
						finally {
							userphid.transactionLock.release();
						}
						break;
					case DeviceChannelUID._MOT0110_GYROSCOPE_100_USB:
						switch (bp.vpkt) {
							case BP.SETDATAINTERVAL:
								buf.setUint16(0, this._handleDataIntervalPacket(bp, 1));
								await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, channel.uniqueIndex, new Uint8Array(buf.buffer, 0, 2));
								break;
							case BP.ZERO:
								await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.GyroscopePacket.ZERO, channel.uniqueIndex);
								break;
							case BP.SETHEATINGENABLED:
								this.data.heatingEnabled = bp.getBoolean(0);
								buf.setUint8(0, bp.getNumber(0));
								await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.SpatialPacket.SET_HEATING, channel.uniqueIndex, new Uint8Array(buf.buffer, 0, 1));
								break;
							case BP.OPENRESET:
							case BP.CLOSERESET:
								await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.GenericPacket.PHIDGET_RESET, channel.uniqueIndex);
								break;
							case BP.ENABLE:
								await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.GenericPacket.PHIDGET_ENABLE, channel.uniqueIndex);
								break;
							default:
								throw new PhidgetError(ErrorCode.UNEXPECTED);
						}
						break;
					case DeviceChannelUID._MOT0110_ACCELEROMETER_100_USB:
						switch (bp.vpkt) {
							case BP.SETDATAINTERVAL:
								buf.setUint16(0, this._handleDataIntervalPacket(bp, 1));
								await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, channel.uniqueIndex, new Uint8Array(buf.buffer, 0, 2));
								break;
							case BP.SETCHANGETRIGGER:
								this.data.accelerationChangeTrigger = bp.getNumber(0);
								buf.setFloat32(0, bp.getNumber(0), true);
								await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.GenericPacket.AXES_SETAXISCHANGETRIGGER, channel.uniqueIndex, new Uint8Array(buf.buffer, 0, 4));
								break;
							case BP.SETHEATINGENABLED:
								this.data.heatingEnabled = bp.getBoolean(0);
								buf.setUint8(0, bp.getNumber(0));
								await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.SpatialPacket.SET_HEATING, channel.uniqueIndex, new Uint8Array(buf.buffer, 0, 1));
								break;
							case BP.OPENRESET:
							case BP.CLOSERESET:
								await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.GenericPacket.PHIDGET_RESET, channel.uniqueIndex);
								break;
							case BP.ENABLE:
								await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.GenericPacket.PHIDGET_ENABLE, channel.uniqueIndex);
								break;
							default:
								throw new PhidgetError(ErrorCode.UNEXPECTED);
						}
						break;
					case DeviceChannelUID._MOT0110_TEMPERATURESENSOR_100_USB:
						switch (bp.vpkt) {
							case BP.SETDATAINTERVAL:
								buf.setUint16(0, this._handleDataIntervalPacket(bp, 1));
								await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, channel.uniqueIndex, new Uint8Array(buf.buffer, 0, 2));
								break;
							case BP.SETCHANGETRIGGER:
								this.data.temperatureChangeTrigger = bp.getNumber(0);
								buf.setFloat32(0, bp.getNumber(0), true);
								await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.TemperatureSensorPacket.SETTEMPERATURECHANGETRIGGER, channel.uniqueIndex, new Uint8Array(buf.buffer, 0, 4));
								break;
							case BP.OPENRESET:
							case BP.CLOSERESET:
								await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.GenericPacket.PHIDGET_RESET, channel.uniqueIndex);
								break;
							case BP.ENABLE:
								await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.GenericPacket.PHIDGET_ENABLE, channel.uniqueIndex);
								break;
							default:
								throw new PhidgetError(ErrorCode.UNEXPECTED);
						}
						break;
					default:
						throw new PhidgetError(ErrorCode.UNEXPECTED);
				}
				break;
			default:
				throw new PhidgetError(ErrorCode.UNEXPECTED);

		}
	}

	dataInput(buffer: DataView) {
		let channel;
		let j = 0;
		let doneGyroZero = false;
		const accelAvg = [0, 0, 0];
		const angularRateAvg = [0, 0, 0];
		const magneticFieldAvg = [0, 0, 0];
		const quaternion = [0, 0, 0, 0];

		const eventData = [];
		let fireSaturation;
		let fireEvent;

		const dataRate = this.data.dataInterval;
		let temperature = PUNK.DBL;

		//Parse device packets
		const incData: DataBufferEntry = {
			acceleration: [],
			angularRate: [],
			magneticField: [],
			quaternion: [],
			timestamp: 0,
			temperature: 0
		};

		switch (this.devDef.uid) {
			case DeviceUID._MOT0110_USB: {
				let dataIndex = 1;
				const channelFlags = buffer.getUint8(0);
				const evData: DataBufferEntry = {
					acceleration: [],
					angularRate: [],
					magneticField: [],
					quaternion: [],
					timestamp: 0,
					temperature: 0
				};

				evData.timestamp = buffer.getUint32(1);
				dataIndex += 4;

				//add data to the data buffer
				if ((channelFlags & ACCEL_SEND_FLAG) || (channelFlags & SPT_SEND_FLAG)) {
					for (let j = 0; j < 3; j++) {
						evData.acceleration[j] = buffer.getFloat32(dataIndex + (j * 4), true);
						if (!Number.isNaN(evData.acceleration[j])) {
							evData.acceleration[j] = RoundDouble(evData.acceleration[j], 6);
						}
					}
					dataIndex += 12;
				}
				if ((channelFlags & GYRO_SEND_FLAG) || (channelFlags & SPT_SEND_FLAG)) {
					for (let j = 0; j < 3; j++) {
						evData.angularRate[j] = buffer.getFloat32(dataIndex + (j * 4), true);
						if (!Number.isNaN(evData.angularRate[j])) {
							evData.angularRate[j] = RoundDouble(evData.angularRate[j], 6);
						}
					}
					dataIndex += 12;
				}
				if ((channelFlags & MAG_SEND_FLAG) || (channelFlags & SPT_SEND_FLAG)) {
					for (let j = 0; j < 3; j++) {
						evData.magneticField[j] = buffer.getFloat32(dataIndex + (j * 4), true);
						if (!Number.isNaN(evData.magneticField[j])) {
							evData.magneticField[j] = RoundDouble(evData.magneticField[j], 6);
						}
					}
					dataIndex += 12;
				}
				if (channelFlags & SPT_SEND_FLAG) {
					// Quaternion
					evData.quaternion[0] = buffer.getFloat32(dataIndex, true);
					evData.quaternion[1] = buffer.getFloat32(dataIndex + 4, true);
					evData.quaternion[2] = buffer.getFloat32(dataIndex + 8, true);
					evData.quaternion[3] = buffer.getFloat32(dataIndex + 12, true);
					dataIndex += 16;
				}
				if (channelFlags & TMP_SEND_FLAG) {
					evData.temperature = buffer.getFloat32(dataIndex, true);
					if (!Number.isNaN(evData.temperature)) {
						evData.temperature = RoundDouble(evData.temperature, 3);
					}
					dataIndex += 4;
				}

				for (let i = 0; i < 5; i++) {
					if ((channelFlags & (1 << i)) === 0) {
						continue;
					}
					if ((channel = this.getChannel(i)) !== null) {
						switch (channel.chDef.uid) {
							case DeviceChannelUID._MOT0110_SPATIAL_100_USB: {
								if (buffer.byteLength > dataIndex) {
									if (buffer.getUint8(dataIndex) & 0x20) {
										const bp = new BridgePacket();
										bp.set({ name: "0", type: "d", value: ErrorEventCode.OUT_OF_RANGE });
										bp.set({ name: "1", type: "s", value: "One or more spatial readings is out of range." });
										channel.sendErrorEvent(bp);
									}
								}
								let bp = new BridgePacket();
								bp.set({ name: "0", type: "G", value: evData.acceleration });
								bp.set({ name: "1", type: "G", value: evData.angularRate });
								bp.set({ name: "2", type: "G", value: evData.magneticField });
								bp.set({ name: "3", type: "g", value: evData.timestamp });
								bp.sendToChannel(channel, BP.SPATIALDATA);

								bp = new BridgePacket();
								bp.set({ name: "0", type: "G", value: evData.quaternion });
								bp.set({ name: "1", type: "g", value: evData.timestamp });
								bp.sendToChannel(channel, BP.SPATIALALGDATA);
							}
								break;
							case DeviceChannelUID._MOT0110_MAGNETOMETER_100_USB: {
								if (buffer.byteLength > dataIndex) {
									if (buffer.getUint8(dataIndex) & 0x04) {
										const bp = new BridgePacket();
										bp.set({ name: "0", type: "d", value: ErrorEventCode.OUT_OF_RANGE });
										bp.set({ name: "1", type: "s", value: "One or more magnetometer readings is out of range." });
										channel.sendErrorEvent(bp);
									}
								}
								const bp = new BridgePacket();
								bp.set({ name: "0", type: "G", value: evData.magneticField });
								bp.set({ name: "1", type: "g", value: evData.timestamp });
								bp.sendToChannel(channel, BP.FIELDSTRENGTHCHANGE);
							}
								break;
							case DeviceChannelUID._MOT0110_GYROSCOPE_100_USB: {
								if (buffer.byteLength > dataIndex) {
									if (buffer.getUint8(dataIndex) & 0x02) {
										const bp = new BridgePacket();
										bp.set({ name: "0", type: "d", value: ErrorEventCode.OUT_OF_RANGE });
										bp.set({ name: "1", type: "s", value: "One or more gyroscope readings is out of range." });
										channel.sendErrorEvent(bp);
									}
								}
								const bp = new BridgePacket();
								bp.set({ name: "0", type: "G", value: evData.angularRate });
								bp.set({ name: "1", type: "g", value: evData.timestamp });
								bp.sendToChannel(channel, BP.ANGULARRATEUPDATE);
							}
								break;
							case DeviceChannelUID._MOT0110_ACCELEROMETER_100_USB: {
								if (buffer.byteLength > dataIndex) {
									if (buffer.getUint8(dataIndex) & 0x01) {
										const bp = new BridgePacket();
										bp.set({ name: "0", type: "d", value: ErrorEventCode.OUT_OF_RANGE });
										bp.set({ name: "1", type: "s", value: "One or more accelerometer readings is out of range." });
										channel.sendErrorEvent(bp);
									}
								}
								const bp = new BridgePacket();
								bp.set({ name: "0", type: "G", value: evData.acceleration });
								bp.set({ name: "1", type: "g", value: evData.timestamp });
								bp.sendToChannel(channel, BP.ACCELERATIONCHANGE);
							}
								break;
							case DeviceChannelUID._MOT0110_TEMPERATURESENSOR_100_USB: {
								if (buffer.byteLength > dataIndex) {
									const bp = new BridgePacket();
									switch (buffer.getUint8(dataIndex) & 0x18) {
										case 0x08:
											bp.set({ name: "0", type: "d", value: ErrorEventCode.OUT_OF_RANGE_LOW_CONDITION });
											bp.set({ name: "1", type: "s", value: "Temperature is too low to be accurately measured." });
											break;
										case 0x10:
											bp.set({ name: "0", type: "d", value: ErrorEventCode.OUT_OF_RANGE_HIGH_CONDITION });
											bp.set({ name: "1", type: "s", value: "Temperature is too high to be accurately measured." });
											break;
										case 0x18:
											bp.set({ name: "0", type: "d", value: ErrorEventCode.OUT_OF_RANGE });
											bp.set({ name: "1", type: "s", value: "Temperature is unknown." });
											break;
									}
									channel.sendErrorEvent(bp);
								}
								const bp = new BridgePacket();
								bp.set({ name: "0", type: "g", value: evData.temperature });
								bp.sendToChannel(channel, BP.TEMPERATURECHANGE);
							}
						}
					}
				}

			}
				break;
			case DeviceUID._MOT0109: {
				//this timestamp is for the latest data
				const time = buffer.getUint32(1, true);
				this.updateTimestamp(time & 0xFFFF);

				//add data to databuffer
				for (j = 0; j < 3; j++) {
					const indexOffset = j * 4;
					incData.acceleration.push(buffer.getFloat32(5 + indexOffset, true));
					incData.angularRate.push(buffer.getFloat32(17 + indexOffset, true));
					incData.magneticField.push(buffer.getFloat32(29 + indexOffset, true));
				}

				//Quaternion
				incData.quaternion.push(buffer.getFloat32(41, true));
				incData.quaternion.push(buffer.getFloat32(45, true));
				incData.quaternion.push(buffer.getFloat32(49, true));
				incData.quaternion.push(buffer.getFloat32(53, true));

				incData.temperature = buffer.getFloat32(57, true);

				this.updateLatestDataTime(0);

				incData.timestamp = this.data.latestDataTime;

				//pushing to the array will mimic phid->bufferWritePtr++
				this.data.dataBuffer.push(incData);

				if (this.data.doZeroGyro) {
					if (this.timestampDiff(this.data.latestDataTime, this.data.dataBuffer[0].timestamp) >= ZERO_GYRO_TIME) {
						const gyroCorrectionTemp = [0, 0, 0];

						this.data.dataBuffer.forEach((v) => {
							gyroCorrectionTemp.map((_value, index, arary) => {
								arary[index] += v.angularRate[index];
							});
						});

						this.data.gyroCorrection.forEach((_value, index, arary) => {
							arary[index] = gyroCorrectionTemp[index] / this.data.dataBuffer.length;
						});

						doneGyroZero = true;
					}
				}

				//see if it's time for an event
				if (this.timestampDiff(this.data.latestDataTime, this.data.lastEventTime) >= dataRate) {
					let tempTime = 0;
					let accelCounter = [0, 0, 0];
					let angularRateCounter = [0, 0, 0];
					let magneticFieldCounter = [0, 0, 0];

					let dataPerEvent = 0;
					let multipleDataPerEvent = false;

					if (dataRate < this.data.interruptRate)
						multipleDataPerEvent = true;

					for (let j = 0; ; j++) {
						if (this.data.dataBuffer.length === 0 || j >= 16) {
							dataPerEvent = j;
							break;
						}

						tempTime = this.data.dataBuffer[0].timestamp;

						//average data for each stage
						//forEach will mimic phid->bufferReadPtr++
						this.data.dataBuffer.forEach((v) => {
							if (!multipleDataPerEvent || this.timestampDiff(v.timestamp, tempTime) < dataRate) {
								accelAvg.map((_value, index, array) => {
									if (v.acceleration[index] !== PUNK.DBL) {
										array[index] += v.acceleration[index];
										accelCounter[index]++;
									}
								});
								angularRateAvg.map((_value, index, array) => {
									if (v.angularRate[index] !== PUNK.DBL) {
										const rate = v.angularRate[index] - this.data.gyroCorrection[index];
										array[index] += rate;
										angularRateCounter[index]++;
									}
								});
								magneticFieldAvg.map((_value, index, array) => {
									if (v.magneticField[index] !== PUNK.DBL) {
										array[index] += v.magneticField[index];
										magneticFieldCounter[index]++;
									}
								});

								// NOTE: we are NOT averaging the incoming quaternion, just getting the latest one
								quaternion.forEach((_value, index, array) => {
									array[index] = v.quaternion[index];
								});

								temperature = v.temperature;
							}
						});

						this.data.dataBuffer = [];  // mimics bufferReadPtr = bufferWritePtr


						const evData: DataBufferEntry = {
							acceleration: [],
							angularRate: [],
							magneticField: [],
							quaternion: [],
							timestamp: 0,
							temperature: 0
						};

						accelAvg.map((value, index, array) => {
							if (accelCounter[index] > 0)
								evData.acceleration.push(value / accelCounter[index]);
							else
								evData.acceleration.push(PUNK.DBL);

							array[index] = 0;
						});
						angularRateAvg.map((value, index, array) => {
							if (angularRateCounter[index] > 0) {
								if (this.data.doZeroGyro && !doneGyroZero)
									evData.angularRate.push(0);
								else
									evData.angularRate.push(value / angularRateCounter[index]);
							}
							else
								evData.angularRate.push(PUNK.DBL);
							array[index] = 0;
						});
						magneticFieldAvg.map((value, index, array) => {
							if (magneticFieldCounter[index] > 0)
								evData.magneticField.push(value / magneticFieldCounter[index]);
							else
								evData.magneticField.push(PUNK.DBL);
							array[index] = 0;
						});

						evData.quaternion = quaternion;

						evData.timestamp = tempTime;
						evData.temperature = temperature;

						eventData.push(evData);
					} //for(j = 0; ; j++)

					//store to local structure
					accelCounter = [0, 0, 0];
					angularRateCounter = [0, 0, 0];
					magneticFieldCounter = [0, 0, 0];
					for (j = 0; j < dataPerEvent; j++) {
						for (let i = 0; i < this.numAccelAxes; i++)
							if (eventData[j].acceleration[i] !== PUNK.DBL) {
								accelAvg[i] += eventData[j].acceleration[i];
								accelCounter[i]++;
							}

						for (let i = 0; i < this.numGyroAxes; i++)
							if (eventData[j].angularRate[i] !== PUNK.DBL) {
								angularRateAvg[i] += eventData[j].angularRate[i];
								angularRateCounter[i]++;
							}

						for (let i = 0; i < this.numCompassAxes; i++)
							if (eventData[j].magneticField[i] !== PUNK.DBL) {
								magneticFieldAvg[i] += eventData[j].magneticField[i];
								magneticFieldCounter[i]++;
							}

						eventData[j].quaternion.forEach((value, index) => {
							quaternion[index] = value;
						});

						temperature = eventData[j].temperature;
					}

					//set local getData to averages
					for (let i = 0; i < this.numAccelAxes; i++) {
						if (accelCounter[i] > 0)
							this.data.acceleration[0][i] = accelAvg[i] / accelCounter[i];
						else
							this.data.acceleration[0][i] = PUNK.DBL;
					}
					for (let i = 0; i < this.numGyroAxes; i++) {
						if (angularRateCounter[i] > 0) {
							if (this.data.doZeroGyro && !doneGyroZero)
								this.data.angularRate[0][i] = 0;
							else
								this.data.angularRate[0][i] = angularRateAvg[i] / angularRateCounter[i];
						} else
							this.data.angularRate[0][i] = PUNK.DBL;
					}
					for (let i = 0; i < this.numCompassAxes; i++) {
						if (magneticFieldCounter[i] > 0)
							this.data.magneticField[0][i] = magneticFieldAvg[i] / magneticFieldCounter[i];
						else
							this.data.magneticField[0][i] = PUNK.DBL;
					}
					this.data.quaternion[0] = {
						x: quaternion[0],
						y: quaternion[1],
						z: quaternion[2],
						w: quaternion[3]
					};

					this.data.temperature[0] = temperature;

					//send out any events
					// if (this.ch)    // this only exists when opening a channel, and not the base device directly
					for (let j = 0; j < dataPerEvent; j++) {
						fireSaturation = 0;
						if (this.numSpatialInputs) {
							if ((channel = this.getChannel(3)) !== null) {
								for (let i = 0; i < this.numAccelAxes; i++) {
									if (eventData[j].acceleration[i] > this.data.accelerationMax || eventData[j].acceleration[i] < this.data.accelerationMin)
										fireSaturation |= 0x01;

								}
								for (let i = 0; i < this.numGyroAxes; i++) {
									if (eventData[j].angularRate[i] > this.data.angularRateMax || eventData[j].angularRate[i] < this.data.angularRateMin)
										fireSaturation |= 0x02;

								}
								for (let i = 0; i < this.numCompassAxes; i++) {
									if (eventData[j].magneticField[i] === PUNK.DBL)
										continue;
									if (eventData[j].magneticField[i] > this.data.magneticFieldMax || eventData[j].magneticField[i] < this.data.magneticFieldMin)
										fireSaturation |= 0x04;

								}

								if (fireSaturation === 0) {
									let bp = new BridgePacket();
									bp.set({ name: "0", type: "G", value: eventData[j].acceleration });
									bp.set({ name: "1", type: "G", value: eventData[j].angularRate });
									bp.set({ name: "2", type: "G", value: eventData[j].magneticField });
									bp.set({ name: "3", type: "g", value: eventData[j].timestamp });
									bp.sendToChannel(channel, BP.SPATIALDATA);

									bp = new BridgePacket();
									bp.set({ name: "0", type: "G", value: eventData[j].quaternion });
									bp.set({ name: "1", type: "g", value: eventData[j].timestamp });
									bp.sendToChannel(channel, BP.SPATIALALGDATA);
								} else {
									if (fireSaturation & 0x01) {
										const bp = new BridgePacket();
										bp.set({ name: "0", type: "d", value: ErrorEventCode.SATURATION });
										bp.set({ name: "1", type: "s", value: "Accelerometer Saturation Detected." });
										channel.sendErrorEvent(bp);
									}
									if (fireSaturation & 0x02) {
										const bp = new BridgePacket();
										bp.set({ name: "0", type: "d", value: ErrorEventCode.SATURATION });
										bp.set({ name: "1", type: "s", value: "Gyroscope Saturation Detected." });
										channel.sendErrorEvent(bp);
									}
									if (fireSaturation & 0x04) {
										const bp = new BridgePacket();
										bp.set({ name: "0", type: "d", value: ErrorEventCode.SATURATION });
										bp.set({ name: "1", type: "s", value: "Magnetometer Saturation Detected." });
										channel.sendErrorEvent(bp);
									}

								}
							}
						}
						if (this.numAccelAxes) {
							const chIndex = 0;
							if ((channel = this.getChannel(chIndex)) !== null) {
								fireSaturation = false;
								fireEvent = false;
								for (let i = 0; i < this.numAccelAxes; i++) {
									if (eventData[j].acceleration[i] !== PUNK.DBL) {
										if (Math.abs(eventData[j].acceleration[i] - this.data.accelAxisLastTrigger[i]) >= this.data.accelerationChangeTrigger || this.data.accelAxisLastTrigger[i] === PUNK.DBL)
											fireEvent = true;
										if (eventData[j].acceleration[i] > this.data.accelerationMax || eventData[j].acceleration[i] < this.data.accelerationMin)
											fireSaturation = true;
									}
								}

								if (fireSaturation) {
									const bp = new BridgePacket();
									bp.set({ name: "0", type: "d", value: ErrorEventCode.SATURATION });
									bp.set({ name: "1", type: "s", value: "Accelerometer Saturation Detected." });
									channel.sendErrorEvent(bp);
								}
								if (fireEvent) {
									const bp = new BridgePacket();
									bp.set({ name: "0", type: "G", value: eventData[j].acceleration });
									bp.set({ name: "1", type: "g", value: eventData[j].timestamp });
									bp.sendToChannel(channel, BP.ACCELERATIONCHANGE);

									for (let i = 0; i < this.numAccelAxes; i++)
										this.data.accelAxisLastTrigger[i] = eventData[j].acceleration[i];
								}
							}
						}
						if (this.numGyroAxes) {
							const chIndex = 1;
							if ((channel = this.getChannel(chIndex)) !== null) {
								fireSaturation = false;
								fireEvent = false;
								for (let i = 0; i < this.numGyroAxes; i++) {
									if (eventData[j].angularRate[i] !== PUNK.DBL) {
										fireEvent = true;
										if (eventData[j].angularRate[i] > this.data.angularRateMax || eventData[j].angularRate[i] < this.data.angularRateMin)
											fireSaturation = true;
									}
								}

								if (fireSaturation) {
									const bp = new BridgePacket();
									bp.set({ name: "0", type: "d", value: ErrorEventCode.SATURATION });
									bp.set({ name: "1", type: "s", value: "Gyroscope Saturation Detected." });
									channel.sendErrorEvent(bp);
								}
								if (fireEvent) {
									const bp = new BridgePacket();
									bp.set({ name: "0", type: "G", value: eventData[j].angularRate });
									bp.set({ name: "1", type: "g", value: eventData[j].timestamp });
									bp.sendToChannel(channel, BP.ANGULARRATEUPDATE);
								}
							}
						}
						if (this.numCompassAxes) {
							const chIndex = 2;
							if ((channel = this.getChannel(chIndex)) !== null) {
								fireSaturation = false;
								fireEvent = false;
								for (let i = 0; i < this.numCompassAxes; i++) {
									if (eventData[j].magneticField[i] !== PUNK.DBL) {
										if (Math.abs(eventData[j].magneticField[i] - this.data.magAxisLastTrigger[i]) >= this.data.magneticFieldChangeTrigger || this.data.magAxisLastTrigger[i] === PUNK.DBL)
											fireEvent = true;
										if (eventData[j].magneticField[i] > this.data.magneticFieldMax || eventData[j].magneticField[i] < this.data.magneticFieldMin)
											fireSaturation = true;
									}
								}

								if (fireSaturation) {
									const bp = new BridgePacket();
									bp.set({ name: "0", type: "d", value: ErrorEventCode.SATURATION });
									bp.set({ name: "1", type: "s", value: "Magnetometer Saturation Detected." });
									channel.sendErrorEvent(bp);
								}
								if (fireEvent) {
									const bp = new BridgePacket();
									bp.set({ name: "0", type: "G", value: eventData[j].magneticField });
									bp.set({ name: "1", type: "g", value: eventData[j].timestamp });
									bp.sendToChannel(channel, BP.FIELDSTRENGTHCHANGE);

									for (let i = 0; i < this.numCompassAxes; i++)
										this.data.magAxisLastTrigger[i] = eventData[j].magneticField[i];
								}
							}
						}

					}

					this.data.lastEventTime = this.data.latestDataTime;
				}

				//see if it's time for a temperature sensor event
				if (this.timestampDiff(this.data.latestDataTime, this.data.lastTemperatureEventTime) >= this.data.temperatureDataInterval) {
					//Temperature is capture above


					if (this.numTempInputs) {
						const chIndex = 4;
						if ((channel = this.getChannel(chIndex)) !== null) {
							fireSaturation = false;
							fireEvent = false;
							if (this.data.temperature[0] !== PUNK.DBL) {
								if (this.data.temperature[0] > this.data.temperatureMax || this.data.temperature[0] < this.data.temperatureMin) {
									fireSaturation = true;
									this.data.temperature[0] = PUNK.DBL;
								}
								if (Math.abs(this.data.temperature[0] - this.data.temperatureLastTrigger) >= this.data.temperatureChangeTrigger || this.data.temperatureLastTrigger === PUNK.DBL)
									fireEvent = true;
							}
							if (fireSaturation) {
								const bp = new BridgePacket();
								bp.set({ name: "0", type: "d", value: ErrorEventCode.SATURATION });
								bp.set({ name: "1", type: "s", value: "Temperature Saturation Detected." });
								channel.sendErrorEvent(bp);
							}
							if (fireEvent) {
								const bp = new BridgePacket();
								bp.set({ name: "0", type: "g", value: this.data.temperature[0] });
								bp.sendToChannel(channel, BP.TEMPERATURECHANGE);
								this.data.temperatureLastTrigger = this.data.temperature[0];
							}
						}
					}

					this.data.lastTemperatureEventTime = this.data.latestDataTime;
				}

				//this will signal the zero function to return
				if (doneGyroZero)
					this.data.doZeroGyro = false;
			}
				break;
			default:
				throw new PhidgetError(ErrorCode.UNEXPECTED);
		}
	}

	setDataRate(channelHandle: LocalChannel, newVal: number) {
		//make sure it's a power of 2, or 1
		if (newVal < this.data.interruptRate)
			newVal = UpperPowerOfTwo(newVal);
		else
			newVal = Math.round(newVal / this.data.interruptRate) * this.data.interruptRate;

		this.data.dataInterval = newVal;

		let numChannels = 0;
		if (this.numAccelAxes > 0)
			numChannels++;
		if (this.numGyroAxes > 0)
			numChannels++;
		if (this.numCompassAxes > 0)
			numChannels++;
		if (this.numSpatialInputs)
			numChannels++;
		if (this.numTempInputs)
			numChannels++;

		for (let i = 0; i < numChannels; i++) {
			const channel = this.getChannel(i);
			if (channel !== null && channel !== channelHandle) {
				const bp = new BridgePacket();
				bp.set({ name: "0", type: "u", value: this.data.dataInterval });
				bp.sendToChannel(channel, BP.DATAINTERVALCHANGE);
			}
		}
	}

	setTemperatureDataRate(newVal: number) {

		//make sure it's a power of 2, or 1
		if (newVal < this.data.interruptRate)
			newVal = UpperPowerOfTwo(newVal);
		else
			newVal = Math.round(newVal / this.data.interruptRate) * this.data.interruptRate;

		this.data.temperatureDataInterval = newVal;
	}

	async zeroGyro() {
		await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_DEVICE_WRITE, SpatialPacketType.ZERO_GYRO, 0);
	}

	async zeroAHRS() {
		await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_DEVICE_WRITE, SpatialPacketType.ZERO_AHRS, 0);
	}

	async resetAHRS() {
		await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_DEVICE_WRITE, SpatialPacketType.RESET_AHRS, 0);
	}

	async configureAlgorithm() {
		const buf = new ArrayBuffer(6);
		const buffer = new DataView(buf);
		buffer.setUint8(0, this.data.algorithm);
		buffer.setFloat32(1, this.data.AHRSMagGain, true);
		await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_DEVICE_WRITE, SpatialPacketType.CONFIGURE_AHRS, 0, buffer);
	}

	async setPrecision(spatialPrecision: SpatialPrecision) {
		const buffer = new Uint8Array([spatialPrecision]);
		await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_DEVICE_WRITE, SpatialPacketType.SET_POLLING_TYPE, 0, buffer);
	}

	async resetCompassCorrectionParameters() {
		await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_DEVICE_WRITE, SpatialPacketType.MAGNETOMETER_RESET_CORRECTION_PARAMETERS, 0);
	}

	async saveCompassCorrectionParameters() {
		await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_DEVICE_WRITE, SpatialPacketType.MAGNETOMETER_SAVE_CORRECTION_PARAMETERS, 0);
	}

	async setCompassCorrectionParameters(corrParams: {
		magField: number; offset0: number; offset1: number; offset2: number;
		gain0: number; gain1: number; gain2: number; T0: number; T1: number;
		T2: number; T3: number; T4: number; T5: number;
	}) {
		const buf = new ArrayBuffer(52);
		const buffer = new DataView(buf);

		if (corrParams.magField < 0.1 || corrParams.magField > 1000)
			throw (new PhidgetError(ErrorCode.INVALID_ARGUMENT, "magField must be between 0.1 and 1000."));
		if (corrParams.offset0 < -5 || corrParams.offset0 > 5)
			throw (new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Offset must be between -5 and 5."));
		if (corrParams.offset1 < -5 || corrParams.offset1 > 5)
			throw (new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Offset must be between -5 and 5."));
		if (corrParams.offset2 < -5 || corrParams.offset2 > 5)
			throw (new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Offset must be between -5 and 5."));
		if (corrParams.gain0 < 0 || corrParams.gain0 > 15)
			throw (new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Gain must be between 0 and 15."));
		if (corrParams.gain1 < 0 || corrParams.gain1 > 15)
			throw (new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Gain must be between 0 and 15."));
		if (corrParams.gain2 < 0 || corrParams.gain2 > 15)
			throw (new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Gain must be between 0 and 15."));
		if (corrParams.T0 < -5 || corrParams.T0 > 5)
			throw (new PhidgetError(ErrorCode.INVALID_ARGUMENT, "T0 must be between -5 and 5."));
		if (corrParams.T1 < -5 || corrParams.T1 > 5)
			throw (new PhidgetError(ErrorCode.INVALID_ARGUMENT, "T1 must be between -5 and 5."));
		if (corrParams.T2 < -5 || corrParams.T2 > 5)
			throw (new PhidgetError(ErrorCode.INVALID_ARGUMENT, "T2 must be between -5 and 5."));
		if (corrParams.T3 < -5 || corrParams.T3 > 5)
			throw (new PhidgetError(ErrorCode.INVALID_ARGUMENT, "T3 must be between -5 and 5."));
		if (corrParams.T4 < -5 || corrParams.T4 > 5)
			throw (new PhidgetError(ErrorCode.INVALID_ARGUMENT, "T4 must be between -5 and 5."));
		if (corrParams.T5 < -5 || corrParams.T5 > 5)
			throw (new PhidgetError(ErrorCode.INVALID_ARGUMENT, "T5 must be between -5 and 5."));

		buffer.setFloat32(0, corrParams.magField, true);
		buffer.setFloat32(4, corrParams.offset0, true);
		buffer.setFloat32(8, corrParams.offset1, true);
		buffer.setFloat32(12, corrParams.offset2, true);
		buffer.setFloat32(16, corrParams.gain0, true);
		buffer.setFloat32(20, corrParams.gain1, true);
		buffer.setFloat32(24, corrParams.gain2, true);
		buffer.setFloat32(28, corrParams.T0, true);
		buffer.setFloat32(32, corrParams.T1, true);
		buffer.setFloat32(36, corrParams.T2, true);
		buffer.setFloat32(40, corrParams.T3, true);
		buffer.setFloat32(44, corrParams.T4, true);
		buffer.setFloat32(48, corrParams.T5, true);
		await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_DEVICE_WRITE, SpatialPacketType.MAGNETOMETER_SET_CORRECTION_PARAMETERS, 0, buffer);

	}
}

export { SpatialDevice };
