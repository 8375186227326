/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PhidgetChannel } from '../Phidget';
import { Channel } from '../Channel';
import { ErrorCode, ChannelClass } from '../Enumerations.gen';
import * as Enum from '../Enumerations.gen';
import * as SEnum from '../SupportedEnum.gen';
import { PhidgetError } from '../PhidgetError';
import { BridgePacket, PUNK } from '../BridgePacket';
import { BP } from '../BridgePackets.gen';
import { logEventException } from '../Logging';
import { DeviceChannelUID } from '../Devices.gen';

/** @internal */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface TemperatureSensorData {
	dataInterval: number,
	maxDataInterval: number,
	minDataRate: number,
	maxDataRate: number,
	maxTemperature: number,
	maxTemperatureChangeTrigger: number,
	minDataInterval: number,
	minTemperature: number,
	minTemperatureChangeTrigger: number,
	temperature: number,
	temperatureChangeTrigger: number,
	RTDType: Enum.RTDType | PUNK.ENUM,
	RTDWireSetup: Enum.RTDWireSetup | PUNK.ENUM,
	thermocoupleType: Enum.ThermocoupleType | PUNK.ENUM,
}

abstract class TemperatureSensorBase extends PhidgetChannel {
	/** @internal */
	data: TemperatureSensorData;
	/**
	 * **TemperatureChange** event
	 *  * `temperature` - The temperature
	 * ---
	 * The most recent temperature value the channel has measured will be reported in this event, which occurs when the `dataInterval` has elapsed.
	 * 
	 * *   If a `temperatureChangeTrigger` has been set to a non-zero value, the `TemperatureChange` event will not occur until the temperature has changed by at least the `temperatureChangeTrigger` value.
	 */
	onTemperatureChange: ((temperature: number) => void) | null = null;
	/** @internal */
	_gotTemperatureChangeErrorEvent?: boolean;

	/**
	 * The Temperature Sensor class gathers data from the temperature sensor on a Phidget board. This includes on-board ambient temperature sensors, connected thermocouples or platinum RTDs, and IR temperature sensors. This class is also used to measure the temperature on some high-power Phidget boards such as motor controllers for safety reasons.
	 * 
	 * If you're using a simple 0-5V sensor that does not have its own firmware, use the VoltageInput or VoltageRatioInput class instead, as specified for your device.
	 * @public
	 */
	constructor();
	/** @internal */
	constructor(ch?: Channel);
	constructor(ch?: Channel) {
		super(ch);
		this._class = ChannelClass.TEMPERATURE_SENSOR;
		this.name = "TemperatureSensor";
		this.data = this._initData();
	}

	/** @internal */
	_bridgeInput(bp: BridgePacket) {

		switch(bp.vpkt) {
		case BP.SETDATAINTERVAL:
			if (bp.entryCount > 1)
				this.data.dataInterval = bp.entries[1].v as number;
			else
				this.data.dataInterval = bp.entries[0].v as number;
			this._FIREPropertyChange('DataInterval', bp);
			this._FIREPropertyChange('DataRate', bp);
			break;
		case BP.SETRTDTYPE:
			this.data.RTDType = bp.entries[0].v as Enum.RTDType;
			this._FIREPropertyChange('RTDType', bp);
			break;
		case BP.SETRTDWIRESETUP:
			this.data.RTDWireSetup = bp.entries[0].v as Enum.RTDWireSetup;
			this._FIREPropertyChange('RTDWireSetup', bp);
			break;
		case BP.SETCHANGETRIGGER:
			this.data.temperatureChangeTrigger = bp.entries[0].v as number;
			this._FIREPropertyChange('TemperatureChangeTrigger', bp);
			break;
		case BP.SETTHERMOCOUPLETYPE:
			this.data.thermocoupleType = bp.entries[0].v as Enum.ThermocoupleType;
			this._FIREPropertyChange('ThermocoupleType', bp);
			break;
		case BP.TEMPERATURECHANGE: {
			this.data.temperature = bp.entries[0].v as number;
			if (this._isAttachedDone && this.onTemperatureChange) {
				try {
					this.onTemperatureChange(this.data.temperature);
				} catch (err) { logEventException(err); }
			}
			break;
		}
		default:
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			throw new PhidgetError(ErrorCode.INVALID_PACKET, "Unsupported bridge packet: 0x" + bp.vpkt!.toString(16));
		}
	}

	/** @internal */
	_initData(): TemperatureSensorData {
		return {
			dataInterval: PUNK.DBL,
			maxDataInterval: PUNK.UINT32,
			minDataRate: PUNK.DBL,
			maxDataRate: PUNK.DBL,
			maxTemperature: PUNK.DBL,
			maxTemperatureChangeTrigger: PUNK.DBL,
			minDataInterval: PUNK.UINT32,
			minTemperature: PUNK.DBL,
			minTemperatureChangeTrigger: PUNK.DBL,
			temperature: PUNK.DBL,
			temperatureChangeTrigger: PUNK.DBL,
			RTDType: PUNK.ENUM,
			RTDWireSetup: PUNK.ENUM,
			thermocoupleType: PUNK.ENUM,
		}
	}

	/** @internal */
	_initAfterOpen() {
		this.data = this._initData();

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._1048_TEMPERATURESENSOR_THERMOCOUPLE_200:
			this.data.dataInterval = 256;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 31.25;
			this.data.maxTemperature = (<TemperatureSensorDevice>this._ch!.parent).data.maxTemperature[this._ch!.index];
			this.data.maxTemperatureChangeTrigger = 1000;
			this.data.minDataInterval = 32;
			this.data.minTemperature = (<TemperatureSensorDevice>this._ch!.parent).data.minTemperature[this._ch!.index];
			this.data.minTemperatureChangeTrigger = 0;
			this.data.temperature = (<TemperatureSensorDevice>this._ch!.parent).data.temperature[this._ch!.index];
			this.data.temperatureChangeTrigger = 0;
			this.data.thermocoupleType = Enum.ThermocoupleType.K;
			break;
		case DeviceChannelUID._1048_TEMPERATURESENSOR_IC_200:
			this.data.dataInterval = 256;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 31.25;
			this.data.maxTemperature = 150;
			this.data.maxTemperatureChangeTrigger = 100;
			this.data.minDataInterval = 32;
			this.data.minTemperature = -55;
			this.data.minTemperatureChangeTrigger = 0;
			this.data.temperature = (<TemperatureSensorDevice>this._ch!.parent).data.temperature[this._ch!.index];
			this.data.temperatureChangeTrigger = 0;
			break;
		case DeviceChannelUID._DCC1000_TEMPERATURESENSOR_IC_100:
		case DeviceChannelUID._DCC1000_TEMPERATURESENSOR_IC_200:
		case DeviceChannelUID._DCC1000_TEMPERATURESENSOR_IC_210:
			this.data.dataInterval = 500;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 2;
			this.data.maxTemperature = 125;
			this.data.maxTemperatureChangeTrigger = 165;
			this.data.minDataInterval = 500;
			this.data.minTemperature = -40;
			this.data.minTemperatureChangeTrigger = 0;
			this.data.temperatureChangeTrigger = 0;
			break;
		case DeviceChannelUID._DCC1100_TEMPERATURESENSOR_IC_100:
		case DeviceChannelUID._DCC1100_TEMPERATURESENSOR_IC_120:
		case DeviceChannelUID._SAF1000_TEMPERATURESENSOR_IC_100:
		case DeviceChannelUID._SAF1000_TEMPERATURESENSOR_IC_110:
			this.data.dataInterval = 250;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 10;
			this.data.maxTemperature = 125;
			this.data.maxTemperatureChangeTrigger = 165;
			this.data.minDataInterval = 100;
			this.data.minTemperature = -40;
			this.data.minTemperatureChangeTrigger = 0;
			this.data.temperatureChangeTrigger = 0;
			break;
		case DeviceChannelUID._HUM1000_TEMPERATURESENSOR_IC_100:
		case DeviceChannelUID._TMP1000_TEMPERATURESENSOR_IC_100:
			this.data.dataInterval = 500;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 2;
			this.data.maxTemperature = 85;
			this.data.maxTemperatureChangeTrigger = 125;
			this.data.minDataInterval = 500;
			this.data.minTemperature = -40;
			this.data.minTemperatureChangeTrigger = 0;
			this.data.temperatureChangeTrigger = 0;
			break;
		case DeviceChannelUID._HUM1001_TEMPERATURESENSOR_IC_100:
			this.data.dataInterval = 250;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 20;
			this.data.maxTemperature = 85;
			this.data.maxTemperatureChangeTrigger = 125;
			this.data.minDataInterval = 50;
			this.data.minTemperature = -40;
			this.data.minTemperatureChangeTrigger = 0;
			this.data.temperatureChangeTrigger = 0;
			break;
		case DeviceChannelUID._MOT0109_TEMPERATURESENSOR_100:
			this.data.dataInterval = 256;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 250;
			this.data.maxTemperature = 85;
			this.data.maxTemperatureChangeTrigger = 125;
			this.data.minDataInterval = 4;
			this.data.minTemperature = -40;
			this.data.minTemperatureChangeTrigger = 0;
			this.data.temperature = (<SpatialDevice>this._ch!.parent).data.temperature[this._ch!.index];
			this.data.temperatureChangeTrigger = 0;
			break;
		case DeviceChannelUID._MOT0110_TEMPERATURESENSOR_100_USB:
			this.data.dataInterval = 250;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 100;
			this.data.maxTemperature = 85;
			this.data.maxTemperatureChangeTrigger = 125;
			this.data.minDataInterval = 10;
			this.data.minTemperature = -40;
			this.data.minTemperatureChangeTrigger = 0;
			this.data.temperature = (<SpatialDevice>this._ch!.parent).data.temperature[this._ch!.index];
			this.data.temperatureChangeTrigger = 0;
			break;
		case DeviceChannelUID._MOT0110_TEMPERATURESENSOR_100_VINT:
			this.data.dataInterval = 250;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 100;
			this.data.maxTemperature = 85;
			this.data.maxTemperatureChangeTrigger = 125;
			this.data.minDataInterval = 10;
			this.data.minTemperature = -40;
			this.data.minTemperatureChangeTrigger = 0;
			this.data.temperatureChangeTrigger = 0;
			break;
		case DeviceChannelUID._TMP1100_TEMPERATURESENSOR_THERMOCOUPLE_100:
		case DeviceChannelUID._TMP1101_TEMPERATURESENSOR_THERMOCOUPLE_100:
		case DeviceChannelUID._TMP1101_TEMPERATURESENSOR_THERMOCOUPLE_200:
			this.data.dataInterval = 250;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 50;
			this.data.maxTemperature = 1370;
			this.data.maxTemperatureChangeTrigger = 1640;
			this.data.minDataInterval = 20;
			this.data.minTemperature = -270;
			this.data.minTemperatureChangeTrigger = 0;
			this.data.temperatureChangeTrigger = 0;
			this.data.thermocoupleType = Enum.ThermocoupleType.K;
			break;
		case DeviceChannelUID._TMP1100_TEMPERATURESENSOR_IC_100:
		case DeviceChannelUID._TMP1101_TEMPERATURESENSOR_IC_100:
		case DeviceChannelUID._TMP1101_TEMPERATURESENSOR_IC_200:
			this.data.dataInterval = 500;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 3.3333333333333335;
			this.data.maxTemperature = 85;
			this.data.maxTemperatureChangeTrigger = 125;
			this.data.minDataInterval = 300;
			this.data.minTemperature = -40;
			this.data.minTemperatureChangeTrigger = 0;
			this.data.temperatureChangeTrigger = 0;
			break;
		case DeviceChannelUID._TMP1200_TEMPERATURESENSOR_RTD_100:
			this.data.dataInterval = 250;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 4;
			this.data.maxTemperature = 850;
			this.data.maxTemperatureChangeTrigger = 1050;
			this.data.minDataInterval = 250;
			this.data.minTemperature = -200;
			this.data.minTemperatureChangeTrigger = 0;
			this.data.temperatureChangeTrigger = 0;
			this.data.RTDType = Enum.RTDType.PT1000_3850;
			this.data.RTDWireSetup = Enum.RTDWireSetup.WIRES_4;
			break;
		case DeviceChannelUID._TMP1200_TEMPERATURESENSOR_RTD_300:
			this.data.dataInterval = 333;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 3.003003003003003;
			this.data.maxTemperature = 850;
			this.data.maxTemperatureChangeTrigger = 1050;
			this.data.minDataInterval = 333;
			this.data.minTemperature = -200;
			this.data.minTemperatureChangeTrigger = 0;
			this.data.temperatureChangeTrigger = 0;
			this.data.RTDType = Enum.RTDType.PT1000_3850;
			this.data.RTDWireSetup = Enum.RTDWireSetup.WIRES_4;
			break;
		case DeviceChannelUID._TMP1200_TEMPERATURESENSOR_RTD_400:
			this.data.dataInterval = 100;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 20;
			this.data.maxTemperature = 850;
			this.data.maxTemperatureChangeTrigger = 1050;
			this.data.minDataInterval = 50;
			this.data.minTemperature = -200;
			this.data.minTemperatureChangeTrigger = 0;
			this.data.temperatureChangeTrigger = 0;
			this.data.RTDType = Enum.RTDType.PT1000_3850;
			this.data.RTDWireSetup = Enum.RTDWireSetup.WIRES_4;
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	// eslint-disable-next-line require-await
	async _setDefaults() {
		let bp;

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._1048_TEMPERATURESENSOR_THERMOCOUPLE_200:
		case DeviceChannelUID._TMP1100_TEMPERATURESENSOR_THERMOCOUPLE_100:
		case DeviceChannelUID._TMP1101_TEMPERATURESENSOR_THERMOCOUPLE_100:
		case DeviceChannelUID._TMP1101_TEMPERATURESENSOR_THERMOCOUPLE_200:
			bp = new BridgePacket();
			bp.set({ name: "0", type: "u", value: Math.round(this.data.dataInterval) });
			await bp.send(this._ch, BP.SETDATAINTERVAL);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.temperatureChangeTrigger });
			await bp.send(this._ch, BP.SETCHANGETRIGGER);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "d", value: this.data.thermocoupleType });
			await bp.send(this._ch, BP.SETTHERMOCOUPLETYPE);
			break;
		case DeviceChannelUID._1048_TEMPERATURESENSOR_IC_200:
		case DeviceChannelUID._DCC1100_TEMPERATURESENSOR_IC_100:
		case DeviceChannelUID._DCC1100_TEMPERATURESENSOR_IC_120:
		case DeviceChannelUID._HUM1000_TEMPERATURESENSOR_IC_100:
		case DeviceChannelUID._HUM1001_TEMPERATURESENSOR_IC_100:
		case DeviceChannelUID._MOT0109_TEMPERATURESENSOR_100:
		case DeviceChannelUID._MOT0110_TEMPERATURESENSOR_100_USB:
		case DeviceChannelUID._MOT0110_TEMPERATURESENSOR_100_VINT:
		case DeviceChannelUID._SAF1000_TEMPERATURESENSOR_IC_100:
		case DeviceChannelUID._SAF1000_TEMPERATURESENSOR_IC_110:
		case DeviceChannelUID._TMP1100_TEMPERATURESENSOR_IC_100:
		case DeviceChannelUID._TMP1101_TEMPERATURESENSOR_IC_100:
		case DeviceChannelUID._TMP1101_TEMPERATURESENSOR_IC_200:
			bp = new BridgePacket();
			bp.set({ name: "0", type: "u", value: Math.round(this.data.dataInterval) });
			await bp.send(this._ch, BP.SETDATAINTERVAL);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.temperatureChangeTrigger });
			await bp.send(this._ch, BP.SETCHANGETRIGGER);
			break;
		case DeviceChannelUID._DCC1000_TEMPERATURESENSOR_IC_100:
		case DeviceChannelUID._DCC1000_TEMPERATURESENSOR_IC_200:
		case DeviceChannelUID._DCC1000_TEMPERATURESENSOR_IC_210:
		case DeviceChannelUID._TMP1000_TEMPERATURESENSOR_IC_100:
			break;
		case DeviceChannelUID._TMP1200_TEMPERATURESENSOR_RTD_100:
		case DeviceChannelUID._TMP1200_TEMPERATURESENSOR_RTD_300:
		case DeviceChannelUID._TMP1200_TEMPERATURESENSOR_RTD_400:
			bp = new BridgePacket();
			bp.set({ name: "0", type: "u", value: Math.round(this.data.dataInterval) });
			await bp.send(this._ch, BP.SETDATAINTERVAL);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.temperatureChangeTrigger });
			await bp.send(this._ch, BP.SETCHANGETRIGGER);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "d", value: this.data.RTDType });
			await bp.send(this._ch, BP.SETRTDTYPE);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "d", value: this.data.RTDWireSetup });
			await bp.send(this._ch, BP.SETRTDWIRESETUP);
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	_hasInitialState() {

		if ((this.data.temperature == PUNK.DBL)
			&& ! this._gotTemperatureChangeErrorEvent)
			return false;

		return true;
	}

	/** @internal */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	_fireInitialEvents() {

		if(this.data.temperature != PUNK.DBL)
			if (this.onTemperatureChange)
				try {
					this.onTemperatureChange(this.data.temperature);
				} catch (err) { logEventException(err); }

	}

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `TemperatureChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * *   The timing between `TemperatureChange` events can also be affected by the `temperatureChangeTrigger`.
	 * @throws {@link PhidgetError}
	 */
	get dataInterval() { return this.getDataInterval(); }
	/**
	 * The minimum value that `dataInterval` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDataInterval() { return this.getMinDataInterval(); }
	/**
	 * The maximum value that `dataInterval` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDataInterval() { return this.getMaxDataInterval(); }
	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @throws {@link PhidgetError}
	 */
	get dataRate() { return this.getDataRate(); }
	/**
	 * The minimum value that `dataRate` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDataRate() { return this.getMinDataRate(); }
	/**
	 * The maximum value that `dataRate` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDataRate() { return this.getMaxDataRate(); }
	/**
	 * The `RTDType` must correspond to the RTD type you are using in your application.
	 * 
	 * *   If you are unsure which `RTDType` to use, visit your device's User Guide for more information.
	 * @throws {@link PhidgetError}
	 */
	get RTDType() { return this.getRTDType(); }
	/**
	 * The `RTDWireSetup` must correspond to the wire configuration you are using in your application.
	 * 
	 * *   If you are unsure which `RTDWireSetup` to use, visit your device's User Guide for more information.
	 * @throws {@link PhidgetError}
	 */
	get RTDWireSetup() { return this.getRTDWireSetup(); }
	/**
	 * The most recent temperature value that the channel has reported.
	 * 
	 * *   This value will always be between `minTemperature` and `maxTemperature`.
	 * @throws {@link PhidgetError}
	 */
	get temperature() { return this.getTemperature(); }
	/**
	 * The minimum value the `TemperatureChange` event will report.
	 * @throws {@link PhidgetError}
	 */
	get minTemperature() { return this.getMinTemperature(); }
	/**
	 * The maximum value the `TemperatureChange` event will report.
	 * @throws {@link PhidgetError}
	 */
	get maxTemperature() { return this.getMaxTemperature(); }
	/**
	 * The channel will not issue a `TemperatureChange` event until the temperature value has changed by the amount specified by the `temperatureChangeTrigger`.
	 * 
	 * *   Setting the `temperatureChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering
	 * @throws {@link PhidgetError}
	 */
	get temperatureChangeTrigger() { return this.getTemperatureChangeTrigger(); }
	/**
	 * The minimum value that `temperatureChangeTrigger` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minTemperatureChangeTrigger() { return this.getMinTemperatureChangeTrigger(); }
	/**
	 * The maximum value that `temperatureChangeTrigger` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxTemperatureChangeTrigger() { return this.getMaxTemperatureChangeTrigger(); }
	/**
	 * The `thermocoupleType` must correspond to the thermocouple type you are using in your application.
	 * 
	 * *   If you are unsure which `thermocoupleType` to use, visit the [Thermocouple Primer](https://www.phidgets.com/docs/Thermocouple_Primer) for more information.
	 * @throws {@link PhidgetError}
	 */
	get thermocoupleType() { return this.getThermocoupleType(); }

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `TemperatureChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * *   The timing between `TemperatureChange` events can also be affected by the `temperatureChangeTrigger`.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getDataInterval(): number {
		this._assertOpen();

		if (this.data.dataInterval === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return this.data.dataInterval;
	}

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `TemperatureChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * *   The timing between `TemperatureChange` events can also be affected by the `temperatureChangeTrigger`.
	 * @throws {@link PhidgetError}
	 * @param dataInterval - The data interval value
	 */
	async setDataInterval(dataInterval: number): Promise<void> {
		this._assertOpen();

		if (dataInterval < this.data.minDataInterval || dataInterval > this.data.maxDataInterval)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDataInterval + " - " + this.data.maxDataInterval + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: dataInterval });
		await bp.send(this._ch, BP.SETDATAINTERVAL);
	}

	/**
	 * The minimum value that `dataInterval` can be set to.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getMinDataInterval(): number {
		this._assertOpen();

		if (this.data.minDataInterval === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDataInterval);
	}

	/**
	 * The maximum value that `dataInterval` can be set to.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getMaxDataInterval(): number {
		this._assertOpen();

		if (this.data.maxDataInterval === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDataInterval);
	}

	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @returns The data rate for the channel
	 * @throws {@link PhidgetError}
	 */
	getDataRate(): number {
		this._assertOpen();

		if (this.data.dataInterval === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (1000.0 / this.data.dataInterval);
	}

	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @throws {@link PhidgetError}
	 * @param dataRate - The data rate for the channel
	 */
	async setDataRate(dataRate: number): Promise<void> {
		this._assertOpen();

		if (dataRate < this.data.minDataRate || dataRate > this.data.maxDataRate)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDataRate + " - " + this.data.maxDataRate + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: Math.round(1000.0 / dataRate) });
		bp.set({ name: "1", type: "g", value: (1000.0 / dataRate) });
		await bp.send(this._ch, BP.SETDATAINTERVAL);
	}

	/**
	 * The minimum value that `dataRate` can be set to.
	 * @returns The data rate value
	 * @throws {@link PhidgetError}
	 */
	getMinDataRate(): number {
		this._assertOpen();

		if (this.data.minDataRate === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDataRate);
	}

	/**
	 * The maximum value that `dataRate` can be set to.
	 * @returns The data rate value
	 * @throws {@link PhidgetError}
	 */
	getMaxDataRate(): number {
		this._assertOpen();

		if (this.data.maxDataRate === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDataRate);
	}

	/**
	 * The `RTDType` must correspond to the RTD type you are using in your application.
	 * 
	 * *   If you are unsure which `RTDType` to use, visit your device's User Guide for more information.
	 * @returns The RTD type
	 * @throws {@link PhidgetError}
	 */
	getRTDType(): Enum.RTDType {
		this._assertOpen();

		if (this.data.RTDType === PUNK.ENUM)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.RTDType);
	}

	/**
	 * The `RTDType` must correspond to the RTD type you are using in your application.
	 * 
	 * *   If you are unsure which `RTDType` to use, visit your device's User Guide for more information.
	 * @throws {@link PhidgetError}
	 * @param RTDType - The RTD type
	 */
	async setRTDType(RTDType: Enum.RTDType): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (!SEnum.supportedRTDType(this._ch!, RTDType))
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Specified RTDType is unsupported by this device.");

		bp.set({ name: "0", type: "d", value: RTDType });
		await bp.send(this._ch, BP.SETRTDTYPE);
	}

	/**
	 * The `RTDWireSetup` must correspond to the wire configuration you are using in your application.
	 * 
	 * *   If you are unsure which `RTDWireSetup` to use, visit your device's User Guide for more information.
	 * @returns The RTD wire setup
	 * @throws {@link PhidgetError}
	 */
	getRTDWireSetup(): Enum.RTDWireSetup {
		this._assertOpen();

		if (this.data.RTDWireSetup === PUNK.ENUM)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.RTDWireSetup);
	}

	/**
	 * The `RTDWireSetup` must correspond to the wire configuration you are using in your application.
	 * 
	 * *   If you are unsure which `RTDWireSetup` to use, visit your device's User Guide for more information.
	 * @throws {@link PhidgetError}
	 * @param RTDWireSetup - The RTD wire setup
	 */
	async setRTDWireSetup(RTDWireSetup: Enum.RTDWireSetup): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (!SEnum.supportedRTDWireSetup(this._ch!, RTDWireSetup))
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Specified RTDWireSetup is unsupported by this device.");

		bp.set({ name: "0", type: "d", value: RTDWireSetup });
		await bp.send(this._ch, BP.SETRTDWIRESETUP);
	}

	/**
	 * The most recent temperature value that the channel has reported.
	 * 
	 * *   This value will always be between `minTemperature` and `maxTemperature`.
	 * @returns The temperature value
	 * @throws {@link PhidgetError}
	 */
	getTemperature(): number {
		this._assertOpen();

		if (this.data.temperature === PUNK.DBL || Number.isNaN(this.data.temperature))
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);
		if (this.data.temperature > this.data.maxTemperature)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE_HIGH);
		if (this.data.temperature < this.data.minTemperature)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE_LOW);

		return (this.data.temperature);
	}

	/**
	 * The minimum value the `TemperatureChange` event will report.
	 * @returns The temperature value
	 * @throws {@link PhidgetError}
	 */
	getMinTemperature(): number {
		this._assertOpen();

		if (this.data.minTemperature === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minTemperature);
	}

	/**
	 * The maximum value the `TemperatureChange` event will report.
	 * @returns The temperature value
	 * @throws {@link PhidgetError}
	 */
	getMaxTemperature(): number {
		this._assertOpen();

		if (this.data.maxTemperature === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxTemperature);
	}

	/**
	 * The channel will not issue a `TemperatureChange` event until the temperature value has changed by the amount specified by the `temperatureChangeTrigger`.
	 * 
	 * *   Setting the `temperatureChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering
	 * @returns The change trigger value
	 * @throws {@link PhidgetError}
	 */
	getTemperatureChangeTrigger(): number {
		this._assertOpen();

		if (this.data.temperatureChangeTrigger === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.temperatureChangeTrigger);
	}

	/**
	 * The channel will not issue a `TemperatureChange` event until the temperature value has changed by the amount specified by the `temperatureChangeTrigger`.
	 * 
	 * *   Setting the `temperatureChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering
	 * @throws {@link PhidgetError}
	 * @param temperatureChangeTrigger - The change trigger value
	 */
	async setTemperatureChangeTrigger(temperatureChangeTrigger: number): Promise<void> {
		this._assertOpen();

		if (temperatureChangeTrigger < this.data.minTemperatureChangeTrigger || temperatureChangeTrigger > this.data.maxTemperatureChangeTrigger)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minTemperatureChangeTrigger + " - " + this.data.maxTemperatureChangeTrigger + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: temperatureChangeTrigger });
		await bp.send(this._ch, BP.SETCHANGETRIGGER);
	}

	/**
	 * The minimum value that `temperatureChangeTrigger` can be set to.
	 * @returns The change trigger value
	 * @throws {@link PhidgetError}
	 */
	getMinTemperatureChangeTrigger(): number {
		this._assertOpen();

		if (this.data.minTemperatureChangeTrigger === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minTemperatureChangeTrigger);
	}

	/**
	 * The maximum value that `temperatureChangeTrigger` can be set to.
	 * @returns The change trigger value
	 * @throws {@link PhidgetError}
	 */
	getMaxTemperatureChangeTrigger(): number {
		this._assertOpen();

		if (this.data.maxTemperatureChangeTrigger === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxTemperatureChangeTrigger);
	}

	/**
	 * The `thermocoupleType` must correspond to the thermocouple type you are using in your application.
	 * 
	 * *   If you are unsure which `thermocoupleType` to use, visit the [Thermocouple Primer](https://www.phidgets.com/docs/Thermocouple_Primer) for more information.
	 * @returns The thermocouple type
	 * @throws {@link PhidgetError}
	 */
	getThermocoupleType(): Enum.ThermocoupleType {
		this._assertOpen();

		if (this.data.thermocoupleType === PUNK.ENUM)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.thermocoupleType);
	}

	/**
	 * The `thermocoupleType` must correspond to the thermocouple type you are using in your application.
	 * 
	 * *   If you are unsure which `thermocoupleType` to use, visit the [Thermocouple Primer](https://www.phidgets.com/docs/Thermocouple_Primer) for more information.
	 * @throws {@link PhidgetError}
	 * @param thermocoupleType - The thermocouple type
	 */
	async setThermocoupleType(thermocoupleType: Enum.ThermocoupleType): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (!SEnum.supportedThermocoupleType(this._ch!, thermocoupleType))
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Specified ThermocoupleType is unsupported by this device.");

		bp.set({ name: "0", type: "d", value: thermocoupleType });
		await bp.send(this._ch, BP.SETTHERMOCOUPLETYPE);
	}

}
import { type TemperatureSensorDevice } from '../usb/device/TemperatureSensorDevice';
import { type SpatialDevice } from '../usb/device/SpatialDevice';
export { TemperatureSensorBase };
