﻿
import { ErrorCode } from '../Enumerations.gen';
import { PhidgetError } from '../PhidgetError';
import { PhidgetSleep } from '../Utils';
import { logverbose, logwarn } from '../Logging';
import { HubDevice } from './device/HubDevice';

const MAX_PACKET_IDS = 128;

const PACKETTRACKER_INUSE = 0x01;
const PACKETTRACKER_SIGNALLED = 0x02;
const PACKETTRACKER_SENT = 0x04;
const PACKETTRACKER_ABANDONNED = 0x08;

class PacketTrackers {
	packetTracker: Map<number, PacketTracker>;
	counter: number[];

	constructor() {
		this.packetTracker = new Map<number, PacketTracker>();
		this.counter = [];
	}

	setPacketsReturnCode(child: number, res: ErrorCode) {
		for (const pt of this.packetTracker.values()) {
			if (!(pt.state & PACKETTRACKER_INUSE))
				return;
			if (!(pt.state & PACKETTRACKER_SENT))
				return;
			if (pt.childIndex !== child)
				return;

			if (pt.state & PACKETTRACKER_ABANDONNED) {
				pt.state = 0;
			} else {
				pt.returnCode = res;
				pt.state |= PACKETTRACKER_SIGNALLED;
				if (pt.signal)
					pt.signal();
			}
		}
	}

	async getPacketTrackerWait(device: HubDevice, min: number, max: number, childIndex: number, timeout: number) {
		const tm = Date.now() + timeout;

		for (; ;) {
			const tracker = this.getPacketTracker(device, min, max, childIndex);
			if (tracker !== null)
				return tracker;

			if (Date.now() > tm)
				throw new PhidgetError(ErrorCode.TIMEOUT);

			await PhidgetSleep(2);
			logverbose("getPacketTrackerWait tm after sleep: " + Date.now());
		}
	}

	getPacketTracker(device: HubDevice, min: number, max: number, childIndex: number) {
		let i, j;

		if (device === null)
			throw new PhidgetError(ErrorCode.NOT_ATTACHED);
		if (device.packetTrackers === null)
			throw new PhidgetError(ErrorCode.UNEXPECTED);
		if (min < 0)
			throw new PhidgetError(ErrorCode.UNEXPECTED);
		if (max >= MAX_PACKET_IDS)
			throw new PhidgetError(ErrorCode.UNEXPECTED);

		if (device.packetTrackers.counter[childIndex] == undefined)
			device.packetTrackers.counter[childIndex] = 0;

		for (j = min; j <= max; j++) {
			// cycle through the valid packet IDs
			i = j + device.packetTrackers.counter[childIndex];
			if (i > max)
				i -= (max - min);
			if (i < min || i > max)
				throw new PhidgetError(ErrorCode.INVALID, "Calculated an invalid packetTracker");

			let pt = device.packetTrackers.packetTracker.get(i);
			if (pt == undefined) {
				pt = new PacketTracker();
				device.packetTrackers.packetTracker.set(i, pt);
			}

			if (pt.state & PACKETTRACKER_INUSE)
				continue;

			pt.state |= PACKETTRACKER_INUSE;
			pt.returnCode = ErrorCode.UNKNOWN_VALUE;
			pt.len = 0;
			pt.childIndex = childIndex;
			device.packetTrackers.counter[childIndex]++;
			if (device.packetTrackers.counter[childIndex] > (max - min))
				device.packetTrackers.counter[childIndex] = 0;

			return { pt: pt, id: i };
		}

		//All trackers in use - wait
		return null;
	}

	async waitForPendingPackets(child: number) {
		let stillSomeLeft;
		const timetm = Date.now() + 10;

		do {
			stillSomeLeft = 0;
			for (const pt of this.packetTracker.values()) {
				if (pt.childIndex !== child)
					return;
				if (!(pt.state & PACKETTRACKER_INUSE))
					return;
				if (!(pt.state & PACKETTRACKER_SIGNALLED))
					return;
				stillSomeLeft++;
			}
			if (stillSomeLeft) {
				if (Date.now() > timetm) {
					break;
				}
				await PhidgetSleep(10);
			}
		} while (stillSomeLeft);
	}
}

class PacketTracker {
	state: number;
	returnCode: ErrorCode;
	childIndex: number;
	len: number;
	signal?: () => void;

	constructor() {
		this.state = 0;
		this.returnCode = ErrorCode.SUCCESS;
		this.childIndex = 0;
		this.len = 0;
	}

	setPacketLength(len: number) {
		this.len = len;
	}

	setPacketReturnCode(res: ErrorCode) {
		if ((this.state & PACKETTRACKER_INUSE) === 0 || this.state & PACKETTRACKER_SIGNALLED)
			throw new PhidgetError(ErrorCode.INVALID);
		this.returnCode = res;
		this.state |= PACKETTRACKER_SIGNALLED;
		if (this.signal)
			this.signal();
	}

	get signalled() {
		return (this.state & PACKETTRACKER_SIGNALLED ? true : false);
	}

	set sent(val: boolean) {
		if (val)
			this.state |= PACKETTRACKER_SENT;
		else
			this.state &= ~PACKETTRACKER_SENT;
	}

	async waitForPendingPacket(timeout: number) {

		if (!(this.state & PACKETTRACKER_INUSE))
			throw new PhidgetError(ErrorCode.UNEXPECTED, "PacketTracker not INUSE");

		if (this.state & PACKETTRACKER_SIGNALLED)
			return this.returnCode;

		if (timeout === 0)
			throw new PhidgetError(ErrorCode.TIMEOUT);

		logverbose("waiting for PendingPacket: " + timeout + "ms...");

		await new Promise<void>((resolve, reject) => {

			const timer = setTimeout(() => {
				logwarn("PacketTracker " + this.childIndex + " waitForPendingPacket timeout (" + timeout + "ms).");
				delete this.signal;
				reject(new PhidgetError(ErrorCode.TIMEOUT));
			}, timeout);

			this.signal = () => {
				clearTimeout(timer);
				delete this.signal;
				resolve();
			};
		});

		logverbose("no longer waiting for PendingPacket");

		// Return the result
		return this.returnCode;
	}

	releasePacketTracker(force: boolean) {
		if (force) {
			this.state = 0;
		} else {
			//Don't release a packet tracker if it's sent and not signalled
			if ((this.state & (PACKETTRACKER_INUSE | PACKETTRACKER_SIGNALLED | PACKETTRACKER_SENT)) !== (PACKETTRACKER_INUSE | PACKETTRACKER_SENT)) {
				this.state = 0;
			} else {
				logverbose("Refusing to release sent but non-signalled packet tracker, Port " + this.childIndex);
				//Abandon - this can be released later
				this.state |= PACKETTRACKER_ABANDONNED;
			}
		}
	}
}

export { PacketTracker, PacketTrackers };