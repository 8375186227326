import { PhidgetLock } from '../PhidgetLock';
import { GetDescriptor, PhidgetUSBEndpointType, PhidgetUSBRequestType, USBDescriptorType } from './USB';
import { PhidgetError } from '../PhidgetError';
import { ErrorCode } from '../Enumerations.gen';
import { logbuffer, logdebug, logerr, loginfo, logwarn } from '../Logging';
import { StringToWordByteArray } from '../Utils';
import { LocalDevice } from './LocalDevice';
import { USBConnectionBase, USBConnType } from './USBConnection';
import { DeviceData } from '../Device';
import { BridgePacket } from '../BridgePacket';

const enum PHID_GENERAL_PACKET {
	REBOOT_FIRMWARE_UPGRADE = 0x01,
	OPEN_RESET = 0x20,
	CLOSE_RESET = 0x21,
	FIRMWARE_UPGRADE_WRITE = 0x22,
	FIRMWARE_UPGRADE_DONE = 0x23,
	WRITE_LABEL = 0x24
}

/** @internal */
export interface PUSBParams {
	maxPacketEP0: number;
	ep1type: PhidgetUSBEndpointType;
	maxPacketEP1: number;
	ep2type: PhidgetUSBEndpointType;
	maxPacketEP2: number;
	wMaxPacketSizeEP1: number;
	labelIndex: number;
	skuIndex: number;
}

/** @internal */
export interface PhidgetUSBData {
	version: number,
	serialNumber: number,
	productID: number,
	vendorID: number,
	interfaceNum: number,
	fwstr: string,
	label: string,
	pusbParams: PUSBParams
}

/** @internal */
export type PhidgetUSBDeviceData = PhidgetUSBData & DeviceData;

/** @internal */
abstract class PhidgetUSBDevice extends LocalDevice {
	private usbDevice: USBDevice;
	private phidlock: PhidgetLock;
	private readlock: PhidgetLock;
	private writelock: PhidgetLock;
	private openCnt: number;
	private interfaceNum: number;
	protected pusbParams: PUSBParams;
	private closing?: boolean;

	abstract initAfterOpen(): Promise<void>;

	constructor(conn: USBConnectionBase, data: PhidgetUSBDeviceData, usbDev: USBDevice) {
		super(conn, data);

		this.usbDevice = usbDev;
		this.phidlock = new PhidgetLock();
		this.readlock = new PhidgetLock();
		this.writelock = new PhidgetLock();
		this.openCnt = 0;
		this.interfaceNum = data.interfaceNum;
		this.pusbParams = data.pusbParams;
		this.sku
	}

	protected get opened() { return this.usbDevice.opened; }
	private get claimed(): boolean { return this.usbDevice.configuration?.interfaces[0].claimed ?? false }

	// Assumes we are locked
	async open(fullOpen = true) {

		if (!this.phidlock.locked)
			throw new Error("Device MUST be locked before calling open");

		this.closing = false;

		if (!this.opened) {
			try {
				this.openCnt = 0;
				await this.usbDevice.open();
				if (this.usbDevice.configuration === null)
					await this.usbDevice.selectConfiguration(1);
			} catch (err) {
				this.closing = true;
				await this.usbDevice.close();
				this.openCnt = 0;
				throw new PhidgetError(ErrorCode.IO, "Error during USB open", err)
			}
		}
		this.openCnt++;

		if (fullOpen && !this.claimed) {

			try {
				await this.usbDevice.claimInterface(0);
			} catch (err) {
				this.closing = true;
				await this.usbDevice.close();
				this.openCnt = 0;
				throw new PhidgetError(ErrorCode.IO, "Failed to claim interface: ", err);
			}

			try {
				await this.openReset();
				await this.initAfterOpen();
			} catch (err) {
				this.closing = true;
				await this.usbDevice.close();
				this.openCnt = 0;
				logerr("Device Initialization failed", err);
				if (err instanceof PhidgetError && err.errorCode === ErrorCode.BAD_VERSION)
					logwarn("This Phidget requires a new library - please upgrade.");
				throw err;
			}

			loginfo("Opened USB Phidget: " + this);

			// For Node, we need to set a timeout, or we can't ever close the device.
			if (this.conn._usbType === USBConnType.Node)
				this.conn._setEpTimeout(this.usbDevice, 0x81, 500);

			// Start polling USB
			this.pollUSBData().catch(err => {
				// We can't just leak exceptions because nobody is listening
				//  If the device is no longer open, we expect this and don't log anything
				if (this.closing)
					return;

				logerr("Error polling USB Data", err);
				// We need to close the device, as it's not usefull anymore without the read thread
				this.conn._usbErrorDetach(this);
			});
		}
	}

	private async pollUSBData() {
		while (this.opened && !this.closing) {
			try {
				this.dataInput(await this.readPacket());
			} catch (err) {
				// In the case of a timeout, this is fine, just keep reading
				if ((err as PhidgetError).errorCode !== ErrorCode.TIMEOUT)
					throw err;
			}
		}
		throw new PhidgetError(ErrorCode.NOT_ATTACHED);
	}

	// Assumes we are locked
	async close(force = false, fullClose = true) {

		if (!this.phidlock.locked)
			throw new Error("Device MUST be locked before calling close");

		if (!this.opened)
			return;

		if (force)
			this.openCnt = 1;

		this.openCnt--;
		if (this.openCnt > 0) {
			logdebug("Leaving USB device open, as open count is: " + this.openCnt);
			return;
		}

		if (fullClose) {
			if (!this.claimed)
				throw new PhidgetError(ErrorCode.UNEXPECTED, "USB Interface is already released");
			try {
				this.closing = true;
				await this.closeReset();
				try {
					// For Node, we must synchronize with read/write so there aren't any outstanding transfers when we call close
					if (this.conn._usbType === USBConnType.Node) {
						await this.readlock.acquire();
						await this.writelock.acquire();
					}
					this.openCnt = 0;
					await this.usbDevice.releaseInterface(0);
					await this.usbDevice.close();
				} finally {
					if (this.conn._usbType === USBConnType.Node) {
						this.readlock.release();
						this.writelock.release();
					}
				}
				loginfo("Closed USB Phidget: " + this);
			} catch (err) {
				throw new PhidgetError(ErrorCode.IO, 'Failure during USB close', err);
			}
		} else {
			try {
				this.closing = true;
				this.openCnt = 0;
				await this.usbDevice.close();
			} catch (err) {
				throw new PhidgetError(ErrorCode.IO, "Failed to close USB handle", err);
			}
		}
	}

	async transferPacket(transferType: PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_READ | PhidgetUSBRequestType.PHIDGETUSB_REQ_DEVICE_READ | PhidgetUSBRequestType.PHIDGETUSB_REQ_GPP_READ, packetType: number, index: number, readLen: number): Promise<DataView>;
	async transferPacket(transferType: PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE | PhidgetUSBRequestType.PHIDGETUSB_REQ_DEVICE_WRITE | PhidgetUSBRequestType.PHIDGETUSB_REQ_GPP_WRITE, packetType: number, index: number, buffer?: BufferSource): Promise<void>;
	async transferPacket(transferType: PhidgetUSBRequestType.PHIDGETUSB_REQ_BULK_WRITE, packetType: number, index: number, buffer: BufferSource): Promise<void>;
	async transferPacket(transferType: PhidgetUSBRequestType, packetType: number, index: number, bufferOrReadLen?: BufferSource | number): Promise<DataView | void> {

		switch (transferType) {
			case PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE:
			case PhidgetUSBRequestType.PHIDGETUSB_REQ_DEVICE_WRITE:
			case PhidgetUSBRequestType.PHIDGETUSB_REQ_GPP_WRITE:
			case PhidgetUSBRequestType.PHIDGETUSB_REQ_BULK_WRITE: {
				let result;
				if (transferType === PhidgetUSBRequestType.PHIDGETUSB_REQ_BULK_WRITE) {
					if (bufferOrReadLen == undefined || typeof bufferOrReadLen !== 'object')
						throw new PhidgetError(ErrorCode.INVALID_ARGUMENT);

					if (bufferOrReadLen.byteLength > this.pusbParams.maxPacketEP2)
						throw new PhidgetError(ErrorCode.INVALID_ARGUMENT);

					if (this.pusbParams.ep2type !== PhidgetUSBEndpointType.PHID_EP_BULK)
						throw new PhidgetError(ErrorCode.UNSUPPORTED);

					logbuffer("USB Bulk OUT Packet", bufferOrReadLen);
					try {
						if (this.conn._usbType === USBConnType.Node)
							await this.writelock.acquire();
						result = await this.usbDevice.transferOut(2, bufferOrReadLen);
					} catch (err) {
						throw new PhidgetError(ErrorCode.IO, "USB Bulk transfer failed", err);
					} finally {
						if (this.conn._usbType === USBConnType.Node)
							this.writelock.release();
					}
				} else {
					const controlTransferParams: USBControlTransferParameters = {
						requestType: "vendor",
						recipient: 'interface',
						request: transferType,
						value: (packetType << 8 | index),
						index: this.interfaceNum,
					};

					if (bufferOrReadLen != undefined && typeof bufferOrReadLen === 'object') {
						//we can't write more than the max packet size.  Don't try to read more than the max packet size
						if (bufferOrReadLen.byteLength > this.pusbParams.maxPacketEP0)
							throw new PhidgetError(ErrorCode.INVALID_ARGUMENT);

						logbuffer("USB Control OUT Packet", bufferOrReadLen);
						try {
							if (this.conn._usbType === USBConnType.Node)
								await this.writelock.acquire();
							result = await this.usbDevice.controlTransferOut(controlTransferParams, bufferOrReadLen);
						} catch (err) {
							throw new PhidgetError(ErrorCode.IO, "USB Control OUT transfer failed", err);
						} finally {
							if (this.conn._usbType === USBConnType.Node)
								this.writelock.release();
						}
					} else {
						// Control OUT with no data stage
						try {
							if (this.conn._usbType === USBConnType.Node)
								await this.writelock.acquire();
							result = await this.usbDevice.controlTransferOut(controlTransferParams);
						} catch (err) {
							throw new PhidgetError(ErrorCode.IO, "USB Control OUT transfer failed", err);
						} finally {
							if (this.conn._usbType === USBConnType.Node)
								this.writelock.release();
						}
					}
				}

				if (result.status !== 'ok')
					throw new PhidgetError(ErrorCode.IO, "controlTransferOut error. Status: " + result.status);
				if (bufferOrReadLen != undefined && typeof bufferOrReadLen === 'object' && result.bytesWritten !== bufferOrReadLen.byteLength)
					throw new PhidgetError(ErrorCode.UNEXPECTED, "USB send failed to write expected number of bytes.");

				break;
			}
			case PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_READ:
			case PhidgetUSBRequestType.PHIDGETUSB_REQ_DEVICE_READ:
			case PhidgetUSBRequestType.PHIDGETUSB_REQ_GPP_READ: {
				if (!(typeof bufferOrReadLen === 'number'))
					throw new PhidgetError(ErrorCode.INVALID_ARGUMENT);

				// Limit read length
				if (bufferOrReadLen > this.pusbParams.maxPacketEP0)
					bufferOrReadLen = this.pusbParams.maxPacketEP0;

				const controlTransferParams: USBControlTransferParameters = {
					requestType: "vendor",
					recipient: 'interface',
					request: transferType,
					value: (packetType << 8 | index),
					index: this.interfaceNum,
				};

				let descReq;
				try {
					if (this.conn._usbType === USBConnType.Node)
						await this.writelock.acquire();
					descReq = await this.usbDevice.controlTransferIn(controlTransferParams, bufferOrReadLen);
				} catch (err) {
					throw new PhidgetError(ErrorCode.IO, "USB Control IN transfer failed", err);
				} finally {
					if (this.conn._usbType === USBConnType.Node)
						this.writelock.release();
				}
				if (descReq.status !== 'ok')
					throw new PhidgetError(ErrorCode.IO, "USB Control Transfer failure: " + descReq.status);

				if (descReq.data == undefined)
					throw new PhidgetError(ErrorCode.IO, "USB Control IN failed to read data");

				logbuffer("USB Control IN Packet", descReq.data);

				return descReq.data;
			}
			default:
				throw new PhidgetError(ErrorCode.UNEXPECTED);
		}
	}

	private async readPacket(): Promise<DataView> {
		let xfer;
		if (this.conn._usbType === USBConnType.Node) {
			try {
				await this.readlock.acquire();
				xfer = await this.usbDevice.transferIn(1, this.pusbParams.maxPacketEP1);
			} catch (err) {
				if (err instanceof Error && err.message.includes('LIBUSB_TRANSFER_TIMED_OUT'))
					throw new PhidgetError(ErrorCode.TIMEOUT, "Read timed out", err);
				throw new PhidgetError(ErrorCode.IO, "Error reading USB packet", err);
			}
			finally {
				this.readlock.release();
			}
		} else {
			// For Web - we cannot set a timeout and so we cannot lock		
			try {
				xfer = await this.usbDevice.transferIn(1, this.pusbParams.maxPacketEP1);
			} catch (err) {
				throw new PhidgetError(ErrorCode.IO, "Error reading USB packet", err);
			}
		}
		if (xfer.status !== 'ok')
			throw new PhidgetError(ErrorCode.IO, "USB IN Transfer failure: " + xfer.status);

		if (xfer.data == undefined)
			throw new PhidgetError(ErrorCode.IO, "USB IN Transfer failed to read data");

		logbuffer("Received USB Packet", xfer.data);
		return xfer.data;
	}

	protected async readDescriptor(type: number, index: number): Promise<DataView> {
		let desc;
		try {
			if (this.conn._usbType === USBConnType.Node)
				await this.writelock.acquire();
			desc = await GetDescriptor(this.usbDevice, type, index, 0);
		} finally {
			if (this.conn._usbType === USBConnType.Node)
				this.writelock.release();
		}
		return desc;
	}

	protected getMaxOutPacketSize() {
		if (this.pusbParams.ep2type !== PhidgetUSBEndpointType.PHID_EP_UNAVAILABLE)
			return this.pusbParams.maxPacketEP2;
		return this.pusbParams.maxPacketEP0; //EP0 (control)
	}

	async writeLabel(label: string) {
		if (label.length > 10)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Label is too long. Max 10 characters.")
		if (label !== undefined || label !== null) {
			const bufftemp = new Uint8Array(StringToWordByteArray(label).buffer);
			const buffer = [0, USBDescriptorType.USB_STRING_DESCRIPTOR_TYPE, ...(bufftemp.values())];
			buffer[0] = buffer.length;
			await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_GPP_WRITE, PHID_GENERAL_PACKET.WRITE_LABEL, 0, new Uint8Array(buffer));
			this.label = label;
		}
	}

	private async closeReset() {
		await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_GPP_WRITE, PHID_GENERAL_PACKET.CLOSE_RESET, 0);
	}
	private async openReset() {
		await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_GPP_WRITE, PHID_GENERAL_PACKET.OPEN_RESET, 0);
	}
	async rebootFirmwareUpgrade() {
		await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_GPP_WRITE, PHID_GENERAL_PACKET.REBOOT_FIRMWARE_UPGRADE, 0);
	}

	async lock() {
		await this.phidlock.acquire();
	}
	unlock() {
		this.phidlock.release();
	}

	_handleDataIntervalPacket(bp: BridgePacket, interruptRate: number): number {
		let __di = bp.getNumber(0);
		if (__di % interruptRate !== 0) {
			__di = ((__di / interruptRate) + 1) * interruptRate;
			bp.remove("0");
			bp.set({ name: "0", type: "u", value: __di });
		}

		// if the bridge packet also contains a floating point interval, that needs to be updated to match the int version
		if (bp.entryCount > 1)
			bp.set({ name: "1", type: "g", value: __di });

		return __di;
	}
}

export { PhidgetUSBDevice };