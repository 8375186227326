/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PhidgetChannel } from '../Phidget';
import { Channel } from '../Channel';
import { ErrorCode, ChannelClass } from '../Enumerations.gen';
import * as Enum from '../Enumerations.gen';
import * as SEnum from '../SupportedEnum.gen';
import * as Struct from '../Structs.gen';
import { PhidgetError } from '../PhidgetError';
import { BridgePacket, PUNK } from '../BridgePacket';
import { BP } from '../BridgePackets.gen';
import { logEventException } from '../Logging';
import { DeviceChannelUID } from '../Devices.gen';

/** @internal */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface VoltageInputData {
	dataInterval: number,
	minDataInterval: number,
	maxDataInterval: number,
	minDataRate: number,
	maxDataRate: number,
	powerSupply: Enum.PowerSupply | PUNK.ENUM,
	sensorType: Enum.VoltageSensorType | PUNK.ENUM,
	sensorUnit: Struct.UnitInfo | null,
	sensorValue: number,
	voltage: number,
	minVoltage: number,
	maxVoltage: number,
	voltageChangeTrigger: number,
	minVoltageChangeTrigger: number,
	maxVoltageChangeTrigger: number,
	voltageRange: Enum.VoltageRange | PUNK.ENUM,
	sensorValueChangeTrigger: number,
}

abstract class VoltageInputBase extends PhidgetChannel {
	/** @internal */
	data: VoltageInputData;
	/**
	 * **VoltageChange** event
	 *  * `voltage` - Measured voltage
	 * ---
	 * The most recent voltage value the channel has measured will be reported in this event, which occurs when the `dataInterval` has elapsed.
	 * 
	 * *   If a `voltageChangeTrigger` has been set to a non-zero value, the `VoltageChange` event will not occur until the voltage has changed by at least the `voltageChangeTrigger` value.
	 * *   If `sensorType` is supported and set to anything other then `phidget22.VoltageSensorType.VOLTAGE`, this event will not fire.
	 */
	onVoltageChange: ((voltage: number) => void) | null = null;
	/** @internal */
	_gotVoltageChangeErrorEvent?: boolean;
	/**
	 * **SensorChange** event
	 *  * `sensorValue` - The sensor value
	 *  * `sensorUnit` - The sensor unit information corresponding to the sensor value.

*   Helps keep track of the type of information being calculated from the voltage input.
	 * ---
	 * The most recent sensor value the channel has measured will be reported in this event, which occurs when the `dataInterval` has elapsed.
	 * 
	 * *   If a `sensorValueChangeTrigger` has been set to a non-zero value, the `SensorChange` event will not occur until the sensor value has changed by at least the `sensorValueChangeTrigger` value.
	 * *   This event only fires when `sensorType` is not set to `phidget22.VoltageSensorType.VOLTAGE`
	 */
	onSensorChange: ((sensorValue: number, sensorUnit: Struct.UnitInfo) => void) | null = null;
	/** @internal */
	_gotSensorChangeErrorEvent?: boolean;

	/**
	 * The Voltage Input class measures the voltage across the input of a Phidget with a voltage input. This may be a sensor designed to measure voltage directly, or it could be an input designed to interface with 0-5V sensors.
	 * 
	 * For 0-5V sensors, this class supports conversion to sensor data with units specific to the Phidget sensor being used, to make reading these sensors easy.
	 * @public
	 */
	constructor();
	/** @internal */
	constructor(ch?: Channel);
	constructor(ch?: Channel) {
		super(ch);
		this._class = ChannelClass.VOLTAGE_INPUT;
		this.name = "VoltageInput";
		this.data = this._initData();
	}

	/** @internal */
	_bridgeInput(bp: BridgePacket) {

		switch(bp.vpkt) {
		case BP.SETDATAINTERVAL:
			if (bp.entryCount > 1)
				this.data.dataInterval = bp.entries[1].v as number;
			else
				this.data.dataInterval = bp.entries[0].v as number;
			this._FIREPropertyChange('DataInterval', bp);
			this._FIREPropertyChange('DataRate', bp);
			break;
		case BP.SETPOWERSUPPLY:
			this.data.powerSupply = bp.entries[0].v as Enum.PowerSupply;
			this._FIREPropertyChange('PowerSupply', bp);
			break;
		case BP.SETSENSORTYPE:
			this.data.sensorType = bp.entries[0].v as Enum.VoltageSensorType;
			this._FIREPropertyChange('SensorType', bp);
			break;
		case BP.SETSENSORVALUECHANGETRIGGER:
			this.data.sensorValueChangeTrigger = bp.entries[0].v as number;
			this._FIREPropertyChange('SensorValueChangeTrigger', bp);
			break;
		case BP.SETCHANGETRIGGER:
			this.data.voltageChangeTrigger = bp.entries[0].v as number;
			this._FIREPropertyChange('VoltageChangeTrigger', bp);
			break;
		case BP.SETVOLTAGERANGE:
			this.data.voltageRange = bp.entries[0].v as Enum.VoltageRange;
			this._FIREPropertyChange('VoltageRange', bp);
			break;
		case BP.SENSORCHANGE: {
			this.data.sensorValue = bp.entries[0].v as number;
			const sensorUnit: Struct.UnitInfo = {
				unit: bp.entries["UnitInfo.unit"].v as Enum.Unit,
				name: bp.entries["UnitInfo.name"].v as string,
				symbol: bp.entries["UnitInfo.symbol"].v as string,
			}
			this.data.sensorUnit = sensorUnit;
			if (this._isAttachedDone && this.onSensorChange) {
				try {
					this.onSensorChange(this.data.sensorValue, this.data.sensorUnit);
				} catch (err) { logEventException(err); }
			}
			break;
		}
		default:
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			throw new PhidgetError(ErrorCode.INVALID_PACKET, "Unsupported bridge packet: 0x" + bp.vpkt!.toString(16));
		}
	}

	/** @internal */
	_initData(): VoltageInputData {
		return {
			dataInterval: PUNK.DBL,
			minDataInterval: PUNK.UINT32,
			maxDataInterval: PUNK.UINT32,
			minDataRate: PUNK.DBL,
			maxDataRate: PUNK.DBL,
			powerSupply: PUNK.ENUM,
			sensorType: PUNK.ENUM,
			sensorUnit: null,
			sensorValue: PUNK.DBL,
			voltage: PUNK.DBL,
			minVoltage: PUNK.DBL,
			maxVoltage: PUNK.DBL,
			voltageChangeTrigger: PUNK.DBL,
			minVoltageChangeTrigger: PUNK.DBL,
			maxVoltageChangeTrigger: PUNK.DBL,
			voltageRange: PUNK.ENUM,
			sensorValueChangeTrigger: PUNK.DBL,
		}
	}

	/** @internal */
	_initAfterOpen() {
		this.data = this._initData();

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._1018_VOLTAGEINPUT_1000:
			this.data.dataInterval = 256;
			this.data.minDataInterval = 1;
			this.data.maxDataInterval = 1000;
			this.data.minDataRate = 1;
			this.data.maxDataRate = 1000;
			this.data.sensorType = Enum.VoltageSensorType.VOLTAGE;
			this.data.voltage = (<InterfaceKitDevice>this._ch!.parent).data.voltage[this._ch!.index];
			this.data.minVoltage = 0;
			this.data.maxVoltage = 5.456;
			this.data.voltageChangeTrigger = 0;
			this.data.minVoltageChangeTrigger = 0;
			this.data.maxVoltageChangeTrigger = 5;
			this.data.sensorValueChangeTrigger = 0;
			break;
		case DeviceChannelUID._1048_VOLTAGEINPUT_200:
			this.data.dataInterval = 256;
			this.data.minDataInterval = 32;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 31.25;
			this.data.voltage = (<TemperatureSensorDevice>this._ch!.parent).data.voltage[this._ch!.index];
			this.data.minVoltage = -0.01001;
			this.data.maxVoltage = 0.07741;
			this.data.voltageChangeTrigger = 0;
			this.data.minVoltageChangeTrigger = 0;
			this.data.maxVoltageChangeTrigger = 0.08742;
			break;
		case DeviceChannelUID._HUB_VOLTAGEINPUT_100:
			this.data.dataInterval = 250;
			this.data.minDataInterval = 1;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 1000;
			this.data.sensorType = Enum.VoltageSensorType.VOLTAGE;
			this.data.minVoltage = 0;
			this.data.maxVoltage = 5;
			this.data.voltageChangeTrigger = 0;
			this.data.minVoltageChangeTrigger = 0;
			this.data.maxVoltageChangeTrigger = 5;
			this.data.sensorValueChangeTrigger = 0;
			break;
		case DeviceChannelUID._HUB_VOLTAGEINPUT_110:
		case DeviceChannelUID._HUB_VOLTAGEINPUT_200:
			this.data.dataInterval = 250;
			this.data.minDataInterval = 1;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 1000;
			this.data.sensorType = Enum.VoltageSensorType.VOLTAGE;
			this.data.minVoltage = 0;
			this.data.maxVoltage = 5.25;
			this.data.voltageChangeTrigger = 0;
			this.data.minVoltageChangeTrigger = 0;
			this.data.maxVoltageChangeTrigger = 5;
			this.data.sensorValueChangeTrigger = 0;
			break;
		case DeviceChannelUID._ADP1000_VOLTAGEINPUT_100:
			this.data.dataInterval = 250;
			this.data.minDataInterval = 50;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 20;
			this.data.sensorType = Enum.VoltageSensorType.VOLTAGE;
			this.data.minVoltage = -2;
			this.data.maxVoltage = 2;
			this.data.voltageChangeTrigger = 0;
			this.data.minVoltageChangeTrigger = 0;
			this.data.maxVoltageChangeTrigger = 4;
			this.data.voltageRange = Enum.VoltageRange.MILLIVOLTS_400;
			this.data.sensorValueChangeTrigger = 0;
			break;
		case DeviceChannelUID._DAQ1000_VOLTAGEINPUT_100:
			this.data.dataInterval = 250;
			this.data.minDataInterval = 20;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 50;
			this.data.sensorType = Enum.VoltageSensorType.VOLTAGE;
			this.data.minVoltage = 0;
			this.data.maxVoltage = 5;
			this.data.voltageChangeTrigger = 0;
			this.data.minVoltageChangeTrigger = 0;
			this.data.maxVoltageChangeTrigger = 5;
			this.data.sensorValueChangeTrigger = 0;
			break;
		case DeviceChannelUID._DAQ1000_VOLTAGEINPUT_110:
			this.data.dataInterval = 250;
			this.data.minDataInterval = 20;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 50;
			this.data.sensorType = Enum.VoltageSensorType.VOLTAGE;
			this.data.minVoltage = 0;
			this.data.maxVoltage = 5.25;
			this.data.voltageChangeTrigger = 0;
			this.data.minVoltageChangeTrigger = 0;
			this.data.maxVoltageChangeTrigger = 5;
			this.data.sensorValueChangeTrigger = 0;
			break;
		case DeviceChannelUID._DAQ1400_VOLTAGEINPUT_100:
			this.data.dataInterval = 250;
			this.data.minDataInterval = 20;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 50;
			this.data.powerSupply = Enum.PowerSupply.VOLTS_12;
			this.data.sensorType = Enum.VoltageSensorType.VOLTAGE;
			this.data.minVoltage = 0;
			this.data.maxVoltage = 5;
			this.data.voltageChangeTrigger = 0;
			this.data.minVoltageChangeTrigger = 0;
			this.data.maxVoltageChangeTrigger = 5;
			this.data.sensorValueChangeTrigger = 0;
			break;
		case DeviceChannelUID._DAQ1400_VOLTAGEINPUT_120:
			this.data.dataInterval = 250;
			this.data.minDataInterval = 20;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 50;
			this.data.powerSupply = Enum.PowerSupply.VOLTS_12;
			this.data.sensorType = Enum.VoltageSensorType.VOLTAGE;
			this.data.minVoltage = 0;
			this.data.maxVoltage = 5.25;
			this.data.voltageChangeTrigger = 0;
			this.data.minVoltageChangeTrigger = 0;
			this.data.maxVoltageChangeTrigger = 5;
			this.data.sensorValueChangeTrigger = 0;
			break;
		case DeviceChannelUID._SAF1000_VOLTAGEINPUT_100:
		case DeviceChannelUID._SAF1000_VOLTAGEINPUT_110:
			this.data.dataInterval = 250;
			this.data.minDataInterval = 100;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 10;
			this.data.minVoltage = 0;
			this.data.maxVoltage = 40;
			this.data.voltageChangeTrigger = 0;
			this.data.minVoltageChangeTrigger = 0;
			this.data.maxVoltageChangeTrigger = 32;
			break;
		case DeviceChannelUID._STC1003_VOLTAGEINPUT_200:
		case DeviceChannelUID._STC1005_VOLTAGEINPUT_100:
			this.data.dataInterval = 250;
			this.data.minDataInterval = 100;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 10;
			this.data.minVoltage = 0;
			this.data.maxVoltage = 40;
			this.data.voltageChangeTrigger = 0;
			this.data.minVoltageChangeTrigger = 0;
			this.data.maxVoltageChangeTrigger = 20;
			break;
		case DeviceChannelUID._STC1005_VOLTAGEINPUT_110:
			this.data.dataInterval = 250;
			this.data.minDataInterval = 10;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 100;
			this.data.minVoltage = 0;
			this.data.maxVoltage = 40;
			this.data.voltageChangeTrigger = 0;
			this.data.minVoltageChangeTrigger = 0;
			this.data.maxVoltageChangeTrigger = 20;
			break;
		case DeviceChannelUID._TMP1100_VOLTAGEINPUT_100:
		case DeviceChannelUID._TMP1101_VOLTAGEINPUT_100:
		case DeviceChannelUID._TMP1101_VOLTAGEINPUT_200:
			this.data.dataInterval = 250;
			this.data.minDataInterval = 20;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 50;
			this.data.minVoltage = -0.011;
			this.data.maxVoltage = 0.079;
			this.data.voltageChangeTrigger = 0;
			this.data.minVoltageChangeTrigger = 0;
			this.data.maxVoltageChangeTrigger = 0.09;
			break;
		case DeviceChannelUID._VCP1000_VOLTAGEINPUT_100:
			this.data.dataInterval = 250;
			this.data.minDataInterval = 100;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 10;
			this.data.voltageChangeTrigger = 0;
			this.data.minVoltageChangeTrigger = 0;
			this.data.maxVoltageChangeTrigger = 80;
			this.data.voltageRange = Enum.VoltageRange.VOLTS_40;
			break;
		case DeviceChannelUID._VCP1001_VOLTAGEINPUT_100:
			this.data.dataInterval = 250;
			this.data.minDataInterval = 40;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 25;
			this.data.voltageChangeTrigger = 0;
			this.data.minVoltageChangeTrigger = 0;
			this.data.maxVoltageChangeTrigger = 80;
			this.data.voltageRange = Enum.VoltageRange.AUTO;
			break;
		case DeviceChannelUID._VCP1001_VOLTAGEINPUT_110:
			this.data.dataInterval = 250;
			this.data.minDataInterval = 12;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 83.33333333333333;
			this.data.voltageChangeTrigger = 0;
			this.data.minVoltageChangeTrigger = 0;
			this.data.maxVoltageChangeTrigger = 80;
			this.data.voltageRange = Enum.VoltageRange.AUTO;
			break;
		case DeviceChannelUID._VCP1002_VOLTAGEINPUT_100:
			this.data.dataInterval = 250;
			this.data.minDataInterval = 40;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 25;
			this.data.voltageChangeTrigger = 0;
			this.data.minVoltageChangeTrigger = 0;
			this.data.maxVoltageChangeTrigger = 2;
			this.data.voltageRange = Enum.VoltageRange.AUTO;
			break;
		case DeviceChannelUID._VCP1002_VOLTAGEINPUT_110:
			this.data.dataInterval = 250;
			this.data.minDataInterval = 12;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 83.33333333333333;
			this.data.voltageChangeTrigger = 0;
			this.data.minVoltageChangeTrigger = 0;
			this.data.maxVoltageChangeTrigger = 2;
			this.data.voltageRange = Enum.VoltageRange.AUTO;
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	// eslint-disable-next-line require-await
	async _setDefaults() {
		let bp;

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._1018_VOLTAGEINPUT_1000:
		case DeviceChannelUID._HUB_VOLTAGEINPUT_100:
		case DeviceChannelUID._HUB_VOLTAGEINPUT_110:
		case DeviceChannelUID._HUB_VOLTAGEINPUT_200:
		case DeviceChannelUID._DAQ1000_VOLTAGEINPUT_100:
		case DeviceChannelUID._DAQ1000_VOLTAGEINPUT_110:
			bp = new BridgePacket();
			bp.set({ name: "0", type: "u", value: Math.round(this.data.dataInterval) });
			await bp.send(this._ch, BP.SETDATAINTERVAL);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "d", value: this.data.sensorType });
			await bp.send(this._ch, BP.SETSENSORTYPE);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.voltageChangeTrigger });
			await bp.send(this._ch, BP.SETCHANGETRIGGER);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.sensorValueChangeTrigger });
			await bp.send(this._ch, BP.SETSENSORVALUECHANGETRIGGER);
			break;
		case DeviceChannelUID._1048_VOLTAGEINPUT_200:
		case DeviceChannelUID._SAF1000_VOLTAGEINPUT_100:
		case DeviceChannelUID._SAF1000_VOLTAGEINPUT_110:
		case DeviceChannelUID._STC1003_VOLTAGEINPUT_200:
		case DeviceChannelUID._STC1005_VOLTAGEINPUT_100:
		case DeviceChannelUID._STC1005_VOLTAGEINPUT_110:
		case DeviceChannelUID._TMP1100_VOLTAGEINPUT_100:
		case DeviceChannelUID._TMP1101_VOLTAGEINPUT_100:
		case DeviceChannelUID._TMP1101_VOLTAGEINPUT_200:
			bp = new BridgePacket();
			bp.set({ name: "0", type: "u", value: Math.round(this.data.dataInterval) });
			await bp.send(this._ch, BP.SETDATAINTERVAL);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.voltageChangeTrigger });
			await bp.send(this._ch, BP.SETCHANGETRIGGER);
			break;
		case DeviceChannelUID._ADP1000_VOLTAGEINPUT_100:
			bp = new BridgePacket();
			bp.set({ name: "0", type: "u", value: Math.round(this.data.dataInterval) });
			await bp.send(this._ch, BP.SETDATAINTERVAL);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "d", value: this.data.sensorType });
			await bp.send(this._ch, BP.SETSENSORTYPE);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.voltageChangeTrigger });
			await bp.send(this._ch, BP.SETCHANGETRIGGER);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "d", value: this.data.voltageRange });
			await bp.send(this._ch, BP.SETVOLTAGERANGE);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.sensorValueChangeTrigger });
			await bp.send(this._ch, BP.SETSENSORVALUECHANGETRIGGER);
			break;
		case DeviceChannelUID._DAQ1400_VOLTAGEINPUT_100:
		case DeviceChannelUID._DAQ1400_VOLTAGEINPUT_120:
			bp = new BridgePacket();
			bp.set({ name: "0", type: "u", value: Math.round(this.data.dataInterval) });
			await bp.send(this._ch, BP.SETDATAINTERVAL);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "d", value: this.data.powerSupply });
			await bp.send(this._ch, BP.SETPOWERSUPPLY);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "d", value: this.data.sensorType });
			await bp.send(this._ch, BP.SETSENSORTYPE);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.voltageChangeTrigger });
			await bp.send(this._ch, BP.SETCHANGETRIGGER);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.sensorValueChangeTrigger });
			await bp.send(this._ch, BP.SETSENSORVALUECHANGETRIGGER);
			break;
		case DeviceChannelUID._VCP1000_VOLTAGEINPUT_100:
		case DeviceChannelUID._VCP1001_VOLTAGEINPUT_100:
		case DeviceChannelUID._VCP1001_VOLTAGEINPUT_110:
		case DeviceChannelUID._VCP1002_VOLTAGEINPUT_100:
		case DeviceChannelUID._VCP1002_VOLTAGEINPUT_110:
			bp = new BridgePacket();
			bp.set({ name: "0", type: "u", value: Math.round(this.data.dataInterval) });
			await bp.send(this._ch, BP.SETDATAINTERVAL);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.voltageChangeTrigger });
			await bp.send(this._ch, BP.SETCHANGETRIGGER);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "d", value: this.data.voltageRange });
			await bp.send(this._ch, BP.SETVOLTAGERANGE);
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	_hasInitialState() {


		return true;
	}

	/** @internal */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	_fireInitialEvents() {

	}

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * *   The timing between events can also be affected by the change trigger.
	 * @throws {@link PhidgetError}
	 */
	get dataInterval() { return this.getDataInterval(); }
	/**
	 * The minimum value that `dataInterval` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDataInterval() { return this.getMinDataInterval(); }
	/**
	 * The maximum value that `dataInterval` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDataInterval() { return this.getMaxDataInterval(); }
	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @throws {@link PhidgetError}
	 */
	get dataRate() { return this.getDataRate(); }
	/**
	 * The minimum value that `dataRate` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDataRate() { return this.getMinDataRate(); }
	/**
	 * The maximum value that `dataRate` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDataRate() { return this.getMaxDataRate(); }
	/**
	 * Choose the power supply voltage.
	 * 
	 * *   Set this to the voltage specified in the attached sensor's data sheet to power it.
	 * 
	 * *   Set to `phidget22.PowerSupply.OFF` to turn off the supply to save power.
	 * @throws {@link PhidgetError}
	 */
	get powerSupply() { return this.getPowerSupply(); }
	/**
	 * We sell a variety of analog sensors that do not have their own API, they simply output a voltage that can be converted to a digital value using a specific formula. By matching the `sensorType` to your analog sensor, the correct formula will automatically be applied to data when you get the `sensorValue` or subscribe to the `SensorChange` event.
	 * 
	 * *   The `SensorChange` event has its own change trigger associated with it: `sensorValueChangeTrigger`.
	 * *   Any data from getting the `sensorValue` or subscribing to the `SensorChange` event will have a `sensorUnit` associated with it.
	 * 
	 * **Note:** Unlike other properties such as `Phidget.deviceSerialNumber` or `Phidget.channel`, `sensorType` is set after the device is opened, not before.
	 * @throws {@link PhidgetError}
	 */
	get sensorType() { return this.getSensorType(); }
	/**
	 * The unit of measurement that applies to the sensor values of the `sensorType` that has been selected.
	 * 
	 * *   Helps keep track of the type of information being calculated from the voltage input.
	 * @throws {@link PhidgetError}
	 */
	get sensorUnit() { return this.getSensorUnit(); }
	/**
	 * The most recent sensor value that the channel has reported.
	 * 
	 * *   Use `sensorUnit` to get the measurement units that are associated with the `sensorValue`
	 * @throws {@link PhidgetError}
	 */
	get sensorValue() { return this.getSensorValue(); }
	/**
	 * The channel will not issue a `SensorChange` event until the sensor value has changed by the amount specified by the `sensorValueChangeTrigger`.
	 * 
	 * *   Setting the `sensorValueChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering
	 * @throws {@link PhidgetError}
	 */
	get sensorValueChangeTrigger() { return this.getSensorValueChangeTrigger(); }
	/**
	 * The most recent voltage value that the channel has reported.
	 * 
	 * *   This value will always be between `minVoltage` and `maxVoltage`.
	 * @throws {@link PhidgetError}
	 */
	get voltage() { return this.getVoltage(); }
	/**
	 * The minimum value the `VoltageChange` event will report.
	 * @throws {@link PhidgetError}
	 */
	get minVoltage() { return this.getMinVoltage(); }
	/**
	 * The maximum value the `VoltageChange` event will report.
	 * @throws {@link PhidgetError}
	 */
	get maxVoltage() { return this.getMaxVoltage(); }
	/**
	 * The channel will not issue a `VoltageChange` event until the voltage value has changed by the amount specified by the `voltageChangeTrigger`.
	 * 
	 * *   Setting the `voltageChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering
	 * @throws {@link PhidgetError}
	 */
	get voltageChangeTrigger() { return this.getVoltageChangeTrigger(); }
	/**
	 * The minimum value that `voltageChangeTrigger` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minVoltageChangeTrigger() { return this.getMinVoltageChangeTrigger(); }
	/**
	 * The maximum value that `voltageChangeTrigger` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxVoltageChangeTrigger() { return this.getMaxVoltageChangeTrigger(); }
	/**
	 * The voltage range you choose should allow you to measure the full range of your input signal.
	 * 
	 * *   A larger `voltageRange` equates to less resolution.
	 * *   If a `Saturation` event occurs, increase the voltage range.
	 * @throws {@link PhidgetError}
	 */
	get voltageRange() { return this.getVoltageRange(); }

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * *   The timing between events can also be affected by the change trigger.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getDataInterval(): number {
		this._assertOpen();

		if (this.data.dataInterval === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return this.data.dataInterval;
	}

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * *   The timing between events can also be affected by the change trigger.
	 * @throws {@link PhidgetError}
	 * @param dataInterval - The data interval value
	 */
	async setDataInterval(dataInterval: number): Promise<void> {
		this._assertOpen();

		if (dataInterval < this.data.minDataInterval || dataInterval > this.data.maxDataInterval)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDataInterval + " - " + this.data.maxDataInterval + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: dataInterval });
		await bp.send(this._ch, BP.SETDATAINTERVAL);
	}

	/**
	 * The minimum value that `dataInterval` can be set to.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getMinDataInterval(): number {
		this._assertOpen();

		if (this.data.minDataInterval === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDataInterval);
	}

	/**
	 * The maximum value that `dataInterval` can be set to.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getMaxDataInterval(): number {
		this._assertOpen();

		if (this.data.maxDataInterval === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDataInterval);
	}

	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @returns The data rate for the channel
	 * @throws {@link PhidgetError}
	 */
	getDataRate(): number {
		this._assertOpen();

		if (this.data.dataInterval === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (1000.0 / this.data.dataInterval);
	}

	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @throws {@link PhidgetError}
	 * @param dataRate - The data rate for the channel
	 */
	async setDataRate(dataRate: number): Promise<void> {
		this._assertOpen();

		if (dataRate < this.data.minDataRate || dataRate > this.data.maxDataRate)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDataRate + " - " + this.data.maxDataRate + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: Math.round(1000.0 / dataRate) });
		bp.set({ name: "1", type: "g", value: (1000.0 / dataRate) });
		await bp.send(this._ch, BP.SETDATAINTERVAL);
	}

	/**
	 * The minimum value that `dataRate` can be set to.
	 * @returns The data rate value
	 * @throws {@link PhidgetError}
	 */
	getMinDataRate(): number {
		this._assertOpen();

		if (this.data.minDataRate === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDataRate);
	}

	/**
	 * The maximum value that `dataRate` can be set to.
	 * @returns The data rate value
	 * @throws {@link PhidgetError}
	 */
	getMaxDataRate(): number {
		this._assertOpen();

		if (this.data.maxDataRate === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDataRate);
	}

	/**
	 * Choose the power supply voltage.
	 * 
	 * *   Set this to the voltage specified in the attached sensor's data sheet to power it.
	 * 
	 * *   Set to `phidget22.PowerSupply.OFF` to turn off the supply to save power.
	 * @returns The power supply value
	 * @throws {@link PhidgetError}
	 */
	getPowerSupply(): Enum.PowerSupply {
		this._assertOpen();

		if (this.data.powerSupply === PUNK.ENUM)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.powerSupply);
	}

	/**
	 * Choose the power supply voltage.
	 * 
	 * *   Set this to the voltage specified in the attached sensor's data sheet to power it.
	 * 
	 * *   Set to `phidget22.PowerSupply.OFF` to turn off the supply to save power.
	 * @throws {@link PhidgetError}
	 * @param powerSupply - The power supply value
	 */
	async setPowerSupply(powerSupply: Enum.PowerSupply): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (!SEnum.supportedPowerSupply(this._ch!, powerSupply))
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Specified PowerSupply is unsupported by this device.");

		bp.set({ name: "0", type: "d", value: powerSupply });
		await bp.send(this._ch, BP.SETPOWERSUPPLY);
	}

	/**
	 * We sell a variety of analog sensors that do not have their own API, they simply output a voltage that can be converted to a digital value using a specific formula. By matching the `sensorType` to your analog sensor, the correct formula will automatically be applied to data when you get the `sensorValue` or subscribe to the `SensorChange` event.
	 * 
	 * *   The `SensorChange` event has its own change trigger associated with it: `sensorValueChangeTrigger`.
	 * *   Any data from getting the `sensorValue` or subscribing to the `SensorChange` event will have a `sensorUnit` associated with it.
	 * 
	 * **Note:** Unlike other properties such as `Phidget.deviceSerialNumber` or `Phidget.channel`, `sensorType` is set after the device is opened, not before.
	 * @returns The sensor type value
	 * @throws {@link PhidgetError}
	 */
	getSensorType(): Enum.VoltageSensorType {
		this._assertOpen();

		if (this.data.sensorType === PUNK.ENUM)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.sensorType);
	}

	/**
	 * We sell a variety of analog sensors that do not have their own API, they simply output a voltage that can be converted to a digital value using a specific formula. By matching the `sensorType` to your analog sensor, the correct formula will automatically be applied to data when you get the `sensorValue` or subscribe to the `SensorChange` event.
	 * 
	 * *   The `SensorChange` event has its own change trigger associated with it: `sensorValueChangeTrigger`.
	 * *   Any data from getting the `sensorValue` or subscribing to the `SensorChange` event will have a `sensorUnit` associated with it.
	 * 
	 * **Note:** Unlike other properties such as `Phidget.deviceSerialNumber` or `Phidget.channel`, `sensorType` is set after the device is opened, not before.
	 * @throws {@link PhidgetError}
	 * @param sensorType - The sensor type value
	 */
	async setSensorType(sensorType: Enum.VoltageSensorType): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (!SEnum.supportedVoltageSensorType(this._ch!, sensorType))
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Specified VoltageSensorType is unsupported by this device.");

		bp.set({ name: "0", type: "d", value: sensorType });
		await bp.send(this._ch, BP.SETSENSORTYPE);
	}

	/**
	 * The unit of measurement that applies to the sensor values of the `sensorType` that has been selected.
	 * 
	 * *   Helps keep track of the type of information being calculated from the voltage input.
	 * @returns The sensor unit information corresponding to the `sensorValue`.
	 * @throws {@link PhidgetError}
	 */
	getSensorUnit(): Struct.UnitInfo {
		this._assertOpen();

		if (this.data.sensorUnit === null)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.sensorUnit);
	}

	/**
	 * The most recent sensor value that the channel has reported.
	 * 
	 * *   Use `sensorUnit` to get the measurement units that are associated with the `sensorValue`
	 * @returns The sensor value
	 * @throws {@link PhidgetError}
	 */
	getSensorValue(): number {
		this._assertOpen();

		if (this.data.sensorValue === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.sensorValue);
	}

	/**
	 * The channel will not issue a `SensorChange` event until the sensor value has changed by the amount specified by the `sensorValueChangeTrigger`.
	 * 
	 * *   Setting the `sensorValueChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering
	 * @returns The change trigger value
	 * @throws {@link PhidgetError}
	 */
	getSensorValueChangeTrigger(): number {
		this._assertOpen();

		if (this.data.sensorValueChangeTrigger === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.sensorValueChangeTrigger);
	}

	/**
	 * The channel will not issue a `SensorChange` event until the sensor value has changed by the amount specified by the `sensorValueChangeTrigger`.
	 * 
	 * *   Setting the `sensorValueChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering
	 * @throws {@link PhidgetError}
	 * @param sensorValueChangeTrigger - The change trigger value
	 */
	async setSensorValueChangeTrigger(sensorValueChangeTrigger: number): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: sensorValueChangeTrigger });
		await bp.send(this._ch, BP.SETSENSORVALUECHANGETRIGGER);
	}

	/**
	 * The most recent voltage value that the channel has reported.
	 * 
	 * *   This value will always be between `minVoltage` and `maxVoltage`.
	 * @returns The voltage value
	 * @throws {@link PhidgetError}
	 */
	getVoltage(): number {
		this._assertOpen();

		if (this.data.voltage === PUNK.DBL || Number.isNaN(this.data.voltage))
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);
		if (this.data.voltage > this.data.maxVoltage)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE_HIGH);
		if (this.data.voltage < this.data.minVoltage)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE_LOW);

		return (this.data.voltage);
	}

	/**
	 * The minimum value the `VoltageChange` event will report.
	 * @returns The voltage value
	 * @throws {@link PhidgetError}
	 */
	getMinVoltage(): number {
		this._assertOpen();

		if (this.data.minVoltage === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minVoltage);
	}

	/**
	 * The maximum value the `VoltageChange` event will report.
	 * @returns The voltage value
	 * @throws {@link PhidgetError}
	 */
	getMaxVoltage(): number {
		this._assertOpen();

		if (this.data.maxVoltage === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxVoltage);
	}

	/**
	 * The channel will not issue a `VoltageChange` event until the voltage value has changed by the amount specified by the `voltageChangeTrigger`.
	 * 
	 * *   Setting the `voltageChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering
	 * @returns The change trigger value
	 * @throws {@link PhidgetError}
	 */
	getVoltageChangeTrigger(): number {
		this._assertOpen();

		if (this.data.voltageChangeTrigger === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.voltageChangeTrigger);
	}

	/**
	 * The channel will not issue a `VoltageChange` event until the voltage value has changed by the amount specified by the `voltageChangeTrigger`.
	 * 
	 * *   Setting the `voltageChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering
	 * @throws {@link PhidgetError}
	 * @param voltageChangeTrigger - The change trigger value
	 */
	async setVoltageChangeTrigger(voltageChangeTrigger: number): Promise<void> {
		this._assertOpen();

		if (voltageChangeTrigger < this.data.minVoltageChangeTrigger || voltageChangeTrigger > this.data.maxVoltageChangeTrigger)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minVoltageChangeTrigger + " - " + this.data.maxVoltageChangeTrigger + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: voltageChangeTrigger });
		await bp.send(this._ch, BP.SETCHANGETRIGGER);
	}

	/**
	 * The minimum value that `voltageChangeTrigger` can be set to.
	 * @returns The change trigger value
	 * @throws {@link PhidgetError}
	 */
	getMinVoltageChangeTrigger(): number {
		this._assertOpen();

		if (this.data.minVoltageChangeTrigger === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minVoltageChangeTrigger);
	}

	/**
	 * The maximum value that `voltageChangeTrigger` can be set to.
	 * @returns The change trigger value
	 * @throws {@link PhidgetError}
	 */
	getMaxVoltageChangeTrigger(): number {
		this._assertOpen();

		if (this.data.maxVoltageChangeTrigger === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxVoltageChangeTrigger);
	}

	/**
	 * The voltage range you choose should allow you to measure the full range of your input signal.
	 * 
	 * *   A larger `voltageRange` equates to less resolution.
	 * *   If a `Saturation` event occurs, increase the voltage range.
	 * @returns The voltage range value
	 * @throws {@link PhidgetError}
	 */
	getVoltageRange(): Enum.VoltageRange {
		this._assertOpen();

		if (this.data.voltageRange === PUNK.ENUM)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.voltageRange);
	}

	/**
	 * The voltage range you choose should allow you to measure the full range of your input signal.
	 * 
	 * *   A larger `voltageRange` equates to less resolution.
	 * *   If a `Saturation` event occurs, increase the voltage range.
	 * @throws {@link PhidgetError}
	 * @param voltageRange - The voltage range value
	 */
	async setVoltageRange(voltageRange: Enum.VoltageRange): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (!SEnum.supportedVoltageRange(this._ch!, voltageRange))
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Specified VoltageRange is unsupported by this device.");

		bp.set({ name: "0", type: "d", value: voltageRange });
		await bp.send(this._ch, BP.SETVOLTAGERANGE);
	}

}
import { type InterfaceKitDevice } from '../usb/device/InterfaceKitDevice';
import { type TemperatureSensorDevice } from '../usb/device/TemperatureSensorDevice';
export { VoltageInputBase };
