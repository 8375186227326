/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { BridgePacket, PUNK } from "../../BridgePacket";
import { BP } from "../../BridgePackets.gen";
import { DeviceUID } from "../../Devices.gen";
import { BridgeGain, ErrorCode, ErrorEventCode } from "../../Enumerations.gen";
import { PhidgetError } from "../../PhidgetError";
import { RoundDouble } from "../../Utils";
import { LocalChannel } from "../LocalChannel";
import { PhidgetUSBDevice, PhidgetUSBDeviceData } from "../PhidgetUSBDevice";
import { PhidgetUSBRequestType } from "../USB";
import { USBConnectionBase } from "../USBConnection";
import * as VintPacketType from '../VintPacketType';

interface BridgeDeviceData {
	// public members
	voltageRatio: number[],

	// private members
	dataInterval: number[],
	voltageRatioChangeTrigger: number[],

	enabled: boolean[],
	gain: BridgeGain[],
	dataRate: number,

	enabledEcho: boolean[],
	gainEcho: BridgeGain[],
	bridgeLastTrigger: number[],

	dataRateMin: number,
	dataRateMax: number,
	bridgeMin: number[],
	bridgeMax: number[],

	outOfRange: boolean[],

	// Firmware bug handling
	chEnabledBugNotValid: boolean[],
	ch0EnableOverride: boolean,
}

export class BridgeDevice extends PhidgetUSBDevice {

	data: BridgeDeviceData;

	constructor(conn: USBConnectionBase, data: PhidgetUSBDeviceData, usbDev: USBDevice) {
		super(conn, data, usbDev);

		// Ensure that we actually support this device
		switch (this.devDef.uid) {
			case DeviceUID._1046_1:
				break;
			default:
				throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}

		this.data = this.initData();
	}

	// Define getters based on devices.h Attr structs in C library
	get numBridgeInputs() { return this.devDef.cn[0]; }

	async updateBridgeIntervals(ch: LocalChannel, bp: BridgePacket) {
		let integerInterval;
		let interval;

		const len = (4 * this.numBridgeInputs) + 1;
		const buffer = await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_DEVICE_READ, 0, 0, len);

		for (let i = 0; i < this.numBridgeInputs; i++) {
			this.data.enabled[i] = (buffer.getUint8(0) & (1 << i)) !== 0;
			interval = buffer.getFloat32((4 * i) + 1, true);
			this.data.dataInterval[i] = interval <= 60000 ? interval : PUNK.DBL;
		}

		for (let i = 0; i < this.numBridgeInputs; i++) {
			interval = this.data.dataInterval[i];

			if (interval === PUNK.DBL) continue;

			integerInterval = RoundDouble(interval > 0 ? interval : 1, 0);

			if (i == ch.index) {
				if (bp.vpkt === BP.CLOSERESET) continue;
				if (bp.vpkt !== BP.SETDATAINTERVAL)
					bp.add({ name: "", type: "g", value: interval });
				else {
					bp.remove("0");
					bp.set({ name: "0", type: "u", value: integerInterval });
					if (bp.entryCount > 1) {
						bp.remove("1");
						bp.set({ name: "1", type: "g", value: interval });
					} else {
						bp.add({ name: "", type: "g", value: interval });
					}
				}
			}

			const channel = this.getChannel(i);
			if (channel !== null) {
				const bp_s = new BridgePacket();
				bp_s.set({ name: "0", type: "u", value: integerInterval });
				bp_s.set({ name: "1", type: "g", value: interval });
				bp_s.sendToChannel(channel, BP.DATAINTERVALCHANGE);
			}
		}
	}

	initData() {
		const data = {
			// public members
			voltageRatio: new Array(this.numBridgeInputs).fill(PUNK.DBL),

			// private members
			dataInterval: new Array(this.numBridgeInputs).fill(PUNK.DBL),
			voltageRatioChangeTrigger: new Array(this.numBridgeInputs).fill(PUNK.DBL),

			enabled: new Array(this.numBridgeInputs).fill(false),
			gain: new Array(this.numBridgeInputs).fill(PUNK.ENUM),
			dataRate: PUNK.UINT32,

			enabledEcho: new Array(this.numBridgeInputs).fill(false),
			gainEcho: new Array(this.numBridgeInputs).fill(PUNK.ENUM),
			bridgeLastTrigger: new Array(this.numBridgeInputs).fill(PUNK.DBL),

			dataRateMin: PUNK.DBL,
			dataRateMax: PUNK.DBL,
			bridgeMin: new Array(this.numBridgeInputs).fill(PUNK.DBL),
			bridgeMax: new Array(this.numBridgeInputs).fill(PUNK.DBL),

			outOfRange: new Array(this.numBridgeInputs).fill(false),

			// Firmware bug handling
			chEnabledBugNotValid: new Array(this.numBridgeInputs).fill(false),
			ch0EnableOverride: false,
		};
		return data;
	}

	// eslint-disable-next-line require-await
	async initAfterOpen() {
		let len;
		let buffer;
		this.data = this.initData();

		switch (this.devDef.uid) {
			case DeviceUID._1046_1:
				this.data.dataRateMin = 1000;
				this.data.dataRateMax = 1;

				len = (4 * this.numBridgeInputs) + 1;
				buffer = await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_DEVICE_READ, 0, 0, len);

				for (let i = 0; i < this.numBridgeInputs; i++) {
					this.data.bridgeMax[i] = 1.000;
					this.data.bridgeMin[i] = -1.000;

					this.data.enabled[i] = (buffer.getUint8(0) & (1 << i)) !== 0;

					const interval = buffer.getFloat32((4 * i) + 1, true);
					this.data.dataInterval[i] = (interval <= 60000) ? interval : PUNK.DBL;
				}

				break;
			default:
				throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected device");
		}

		for (let i = 0; i < this.numBridgeInputs; i++) {
			this.data.voltageRatioChangeTrigger[i] = 0.001;
		}
	}

	dataInput(buffer: DataView) {
		let chIndex;
		let channel;
		let bp;

		switch (this.devDef.uid) {
			case DeviceUID._1046_1:
				chIndex = buffer.getUint8(1);
				switch (buffer.getUint8(0)) {
					case VintPacketType.VoltageRatioInputPacket.VOLTAGERATIOCHANGE:
						this.data.voltageRatio[chIndex] = buffer.getFloat32(2, true);
						channel = this.getChannel(chIndex);
						if (channel !== null) {
							// Send out events
							if (buffer.byteLength > 6) {
								switch (buffer.getInt8(6)) {
									case 1:
										bp = new BridgePacket();
										bp.set({ name: "0", type: "d", value: ErrorEventCode.OUT_OF_RANGE_HIGH_CONDITION });
										bp.set({ name: "1", type: "s", value: "The sensor reading is too high for the selected gain mode." });
										channel.sendErrorEvent(bp);
										break;
									case -1:
										bp = new BridgePacket();
										bp.set({ name: "0", type: "d", value: ErrorEventCode.OUT_OF_RANGE_LOW_CONDITION });
										bp.set({ name: "1", type: "s", value: "The sensor reading is too low for the selected gain mode." });
										channel.sendErrorEvent(bp);
										break;
									default:
										bp = new BridgePacket();
										bp.set({ name: "0", type: "d", value: ErrorEventCode.OUT_OF_RANGE });
										bp.set({ name: "1", type: "s", value: "Value is unknown." });
										channel.sendErrorEvent(bp);
										break;
								}
							}

							bp = new BridgePacket();
							bp.set({ name: "0", type: "g", value: this.data.voltageRatio[chIndex] });
							bp.sendToChannel(channel, BP.VOLTAGERATIOCHANGE);
						}
						return;
					default:
						throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type");
				}
			default:
				throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected device");

		}
	}

	async bridgeInput(ch: LocalChannel, bp: BridgePacket) {
		let buffer;

		switch (this.devDef.uid) {
			case DeviceUID._1046_1:
				switch (bp.vpkt) {
					case BP.SETENABLED:
						buffer = new Uint8Array([bp.getNumber(0) ? 0xFF : 0x00]);
						await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.VoltageRatioInputPacket.BRIDGEENABLED, ch.uniqueIndex, buffer);
						this.data.enabled[ch.index] = buffer[0] !== 0;
						await this.updateBridgeIntervals(ch, bp);
						return;
					case BP.SETDATAINTERVAL:
						buffer = new DataView(new ArrayBuffer(4));
						if (bp.entryCount > 1)
							buffer.setFloat32(0, bp.getNumber(1), true);
						else
							buffer.setFloat32(0, bp.getNumber(0), true);
						await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, ch.uniqueIndex, buffer);
						buffer = await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_READ, VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, ch.uniqueIndex, 4);

						this.data.dataInterval[ch.index] = buffer.getFloat32(0, true);
						await this.updateBridgeIntervals(ch, bp);
						return;
					case BP.SETCHANGETRIGGER:
						buffer = new DataView(new ArrayBuffer(4));
						buffer.setUint32(0, bp.getNumber(0) * 0x80000000);
						await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.VoltageRatioInputPacket.SETVOLTAGERATIOCHANGETRIGGER, ch.uniqueIndex, buffer);
						return;
					case BP.SETBRIDGEGAIN:
						buffer = new Uint8Array([bp.getNumber(0)]);
						await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.VoltageRatioInputPacket.BRIDGEGAIN, ch.uniqueIndex, buffer);
						return;
					case BP.OPENRESET:
					case BP.CLOSERESET:
						this.data.enabled[ch.index] = false;
						await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.GenericPacket.PHIDGET_RESET, ch.uniqueIndex);
						await this.updateBridgeIntervals(ch, bp);
						return;
					case BP.ENABLE:
						await this.updateBridgeIntervals(ch, bp);
						return;
					default:
						throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
				}
			default:
				throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected device");
		}
	}
}