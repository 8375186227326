import { FrequencyCounterBase } from './FrequencyCounter.gen';
import { ErrorCode } from '../Enumerations.gen';
import { PhidgetError } from '../PhidgetError';
import { BP } from '../BridgePackets.gen';
import { BridgePacket, PUNK } from '../BridgePacket';
import { RoundDouble } from '../Utils';
import { logEventException } from '../Logging';
import * as Enum from '../Enumerations.gen';

/** @public */
class FrequencyCounter extends FrequencyCounterBase {

	/** @internal */
	_bridgeInput(bp: BridgePacket) {
		switch (bp.vpkt) {
			case BP.FREQUENCYDATA: {
				const ticks = bp.getNumber(0);
				const counts = bp.getNumber(1);
				const ticksAtLastCount = bp.getNumber(2);
				let cutoffTime;
				let precision;
				let d;

				this.data.timeElapsed += ticks;
				this.data.count += counts;

				if (counts == 0) {
					if (Number.isNaN(this.data.totalTicksSinceLastCount))
						return;

					this.data.totalTicksSinceLastCount += ticks;
					//only accumulate counts up to cutoff
					cutoffTime = Math.round(1000 / this.data.frequencyCutoff);

					if (this.data.totalTicksSinceLastCount > cutoffTime) {
						this.data.frequency = 0;

						//Fire one event with 0 counts to indicate that the Timeout has elapsed and frequency is now 0

						if (this._isAttachedDone && this.onCountChange) {
							try {
								this.onCountChange(0, this.data.totalTicksSinceLastCount);
							} catch (err) { logEventException(err); }
						}
						if (this._isAttachedDone && this.onFrequencyChange) {
							try {
								this.onFrequencyChange(this.data.frequency);
							} catch (err) { logEventException(err); }
						}

						this.data.totalTicksSinceLastCount = Number.NaN;
					}
					return;
				}

				// 1st count(s) since a timeout (or 1st read packet since opening)
				// don't try to calculate frequency because we don't know the 'ticks at first count'
				if (Number.isNaN(this.data.totalTicksSinceLastCount)) {
					this.data.totalTicksSinceLastCount = ticks - ticksAtLastCount;
					return;
				}

				const countTimeSpan = this.data.totalTicksSinceLastCount + ticksAtLastCount; //in ms
				this.data.totalTicksSinceLastCount = ticks - ticksAtLastCount;

				d = this.data.frequencyCutoff;
				precision = 2;
				while (d < 1) {
					precision++;
					d *= 10;
				}

				this.data.frequency = RoundDouble((counts / (countTimeSpan / 1000.0)), precision);

				if (this._isAttachedDone && this.onCountChange) {
					try {
						this.onCountChange(counts, countTimeSpan);
					} catch (err) { logEventException(err); }
				}
				if (this._isAttachedDone && this.onFrequencyChange) {
					try {
						this.onFrequencyChange(this.data.frequency);
					} catch (err) { logEventException(err); }
				}
				break;
			}
			case BP.SETENABLED:
				if (!bp.getBoolean(0))
					this.data.frequency = PUNK.DBL;
				super._bridgeInput(bp);
				break;

			case BP.POWERSUPPLYCHANGE:
				this.data.powerSupply = bp.entries[0].v as Enum.PowerSupply;
				this._FIREPropertyChange('PowerSupply', bp);
				break;
			case BP.INPUTMODECHANGE:
				this.data.inputMode = bp.entries[0].v as Enum.InputMode;
				this._FIREPropertyChange('InputMode', bp);
				break;

			default:
				super._bridgeInput(bp);
				break;
		}
	}

	setFrequencyCutoff(frequencyCutoff: number) {
		this._assertOpen();

		if (frequencyCutoff < this.data.minFrequencyCutoff || frequencyCutoff > this.data.maxFrequencyCutoff)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minFrequencyCutoff + " - " + this.data.maxFrequencyCutoff + ".");

		this.data.frequencyCutoff = frequencyCutoff;
	}

	reset() {
		this._assertOpen();

		this.data.count = 0;
		this.data.timeElapsed = 0;
		this.data.frequency = PUNK.DBL;
	}
}

export { FrequencyCounter };