﻿import { PhidgetErrorDescription } from './ErrorStrings.gen';
import { ErrorCode } from './Enumerations.gen';

/** @public */
class PhidgetError extends Error {
	/** 
	 * The Error Code
	 */
	errorCode: ErrorCode;
	innerException?: Error;

	constructor(code: ErrorCode, message?: string, innerException?: unknown) {
		let msg = (message || PhidgetErrorDescription[code]);
		if (innerException != undefined) {
			let innerMsg: string;
			if (innerException instanceof Error) {
				innerMsg = innerException.message;
			} else if (typeof innerException === 'string') {
				innerMsg = innerException;
			} else {
				innerMsg = JSON.stringify(innerException);
			}
			if (innerMsg.length > 0)
				msg += ' - ' + innerMsg;
		}
		super(msg);

		if (innerException instanceof Error)
			this.innerException = innerException;

		// Maintains proper stack trace for where our error was thrown (only available on V8)
		if (Error.captureStackTrace)
			Error.captureStackTrace(this, PhidgetError)

		this.name = "PhidgetError";
		this.errorCode = code;
	}

	get stacks() {
		let _stacks = this.stack
		if (this.innerException && this.innerException.stack)
			_stacks += '\n<- ' + this.innerException.stack;
		return _stacks;
	}
}
export { PhidgetError };