import { Device, DeviceData } from "../Device";
import { DeviceID, ErrorCode } from "../Enumerations.gen";
import { PhidgetError } from "../PhidgetError";
import { NetworkConnectionBase } from "./NetworkConnection";

/** @internal */
export type NetworkDeviceData = DeviceData & {
	deviceID: DeviceID;
	name: string;
}

/** @internal */
class NetworkDevice extends Device {
	declare conn: NetworkConnectionBase;
	deviceID: DeviceID;
	name: string;

	constructor(conn: NetworkConnectionBase, data: NetworkDeviceData) {
		super(conn, data);
		this.deviceID = data.deviceID;
		this.name = data.name;
	}

	findPhidgetUniqueChannel(uniqueIndex: number) {

		let index = 0;
		for (const c of this.devDef.ch) {
			if (uniqueIndex < index + (c.n ?? 1))
				return c;
			index += (c.n ?? 1);
		}
		throw (new PhidgetError(ErrorCode.UNEXPECTED, "Couldn't find channel in channel list!!"));
	}
}

export { NetworkDevice };