/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PhidgetChannel } from '../Phidget';
import { Channel } from '../Channel';
import { ErrorCode, ChannelClass } from '../Enumerations.gen';
import { PhidgetError } from '../PhidgetError';
import { BridgePacket } from '../BridgePacket';
import { BP } from '../BridgePackets.gen';
import { logEventException } from '../Logging';

/** @internal */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DictionaryData {
}

abstract class DictionaryBase extends PhidgetChannel {
	/** @internal */
	data: DictionaryData;
	/**
	 * **Add** event
	 *  * `key` - The key that was added
	 *  * `value` - The value of the new key
	 * ---
	 * Occurs when a new key value pair is added to the dictionary.
	 */
	onAdd: ((key: string, value: string) => void) | null = null;
	/** @internal */
	_gotAddErrorEvent?: boolean;
	/**
	 * **Update** event
	 *  * `key` - The key whose value was updated
	 *  * `value` - The new value
	 * ---
	 * Occurs when a change is made to a key value pair in the dictionary.
	 */
	onUpdate: ((key: string, value: string) => void) | null = null;
	/** @internal */
	_gotUpdateErrorEvent?: boolean;
	/**
	 * **Remove** event
	 *  * `key` - The key that was removed
	 * ---
	 * Occurs when a key is removed from the dictionary.
	 */
	onRemove: ((key: string) => void) | null = null;
	/** @internal */
	_gotRemoveErrorEvent?: boolean;

	/**
	 * Dictionaries are useful for passing information between multiple programs using Phidgets. A common example would be to have one program controlling your application that receives commands sent via a Phidget dictionary from a web interface, as outlined in many of our [articles](/?view=articles).
	 * 
	 * Keys can be thought of as being similar to variable names, with their values as their associated value. Phidget dictionaries contain groups of related key-value pairs, and are stored on a central [Phigdet Network Server](/docs/Phidget_Network_Server). Dictionaries, and the key-value pairs within may be accessed from programs that have access to the [Phigdet Network Server](/docs/Phidget_Network_Server).
	 * 
	 * The Dictionary API supports connecting to a dictionary on the server, managing key-value pairs, and monitoring changes made to the dictionary.
	 * 
	 * More information on Phidget Dictionaries can be found on the [Phidget Dictionary](/docs/Phidget_Dictionary) support page.
	 * @public
	 */
	constructor();
	/** @internal */
	constructor(ch?: Channel);
	constructor(ch?: Channel) {
		super(ch);
		this._class = ChannelClass.DICTIONARY;
		this.name = "Dictionary";
		this.data = this._initData();
	}

	/** @internal */
	_bridgeInput(bp: BridgePacket) {

		switch(bp.vpkt) {
		case BP.DICTIONARYADD:
			break;
		case BP.DICTIONARYREMOVEALL:
			break;
		case BP.DICTIONARYGET:
			break;
		case BP.DICTIONARYREMOVE:
			break;
		case BP.DICTIONARYSCAN:
			break;
		case BP.DICTIONARYSET:
			break;
		case BP.DICTIONARYUPDATE:
			break;
		case BP.DICTIONARYADDED: {
			if (this._isAttachedDone && this.onAdd) {
				try {
					this.onAdd(bp.entries[0].v as string, bp.entries[1].v as string);
				} catch (err) { logEventException(err); }
			}
			break;
		}
		case BP.DICTIONARYREMOVED: {
			if (this._isAttachedDone && this.onRemove) {
				try {
					this.onRemove(bp.entries[0].v as string);
				} catch (err) { logEventException(err); }
			}
			break;
		}
		case BP.DICTIONARYUPDATED: {
			if (this._isAttachedDone && this.onUpdate) {
				try {
					this.onUpdate(bp.entries[0].v as string, bp.entries[1].v as string);
				} catch (err) { logEventException(err); }
			}
			break;
		}
		default:
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			throw new PhidgetError(ErrorCode.INVALID_PACKET, "Unsupported bridge packet: 0x" + bp.vpkt!.toString(16));
		}
	}

	/** @internal */
	_initData(): DictionaryData {
		return {
		}
	}

	/** @internal */
	_initAfterOpen() {
		// This should never be called as no USB Phidgets that use this calls are supported
		throw new PhidgetError(ErrorCode.UNEXPECTED);
	}

	/** @internal */
	// eslint-disable-next-line require-await
	async _setDefaults() {
		// This should never be called as no USB Phidgets that use this calls are supported
		throw new PhidgetError(ErrorCode.UNEXPECTED);
	}

	/** @internal */
	_hasInitialState() {


		return true;
	}

	/** @internal */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	_fireInitialEvents() {

	}


	/**
	 * Adds a new key value pair to the dictionary. It is an error if the key already exits.
	 * @throws {@link PhidgetError}
	 * @param key - The key to add
	 * @param value - The value to add
	 */
	async add(key: string, value: string): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "s", value: key });
		bp.set({ name: "1", type: "s", value: value });
		await bp.send(this._ch, BP.DICTIONARYADD);
	}

	/**
	 * Removes every key from the dictionary
	 * @throws {@link PhidgetError}
	 */
	async removeAll(): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		await bp.send(this._ch, BP.DICTIONARYREMOVEALL);
	}

	/**
	 * Gets the value associated with the given key from the dictionary
	 * @returns The value
	 * @throws {@link PhidgetError}
	 * @param key - The key whose value is desired
	 */
	abstract get(key: string): Promise<(string | null)>;
	/**
	 * Gets the value associated with the given key from the dictionary
	 * @returns The value
	 * @throws {@link PhidgetError}
	 * @param key - The key whose value is desired
	 * @param def - default value if the key isn't found
	 */
	abstract get(key: string, def: string): Promise<(string | null)>;
	/**
	 * Removes the key from the dictionary
	 * @throws {@link PhidgetError}
	 * @param key - The key to remove
	 */
	async remove(key: string): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "s", value: key });
		await bp.send(this._ch, BP.DICTIONARYREMOVE);
	}

	/**
	 * Scans the keys in the dictionary, indexed by `start` or the first key in the dictionary if start is `NULL` or an empty String.  
	 * 
	 * *   The result is formated as a newline seperated list of keys
	 * *   The list begins at the key following the start key
	 * *   The list might not contain all of the keys in the dictionary
	 * *   To continue scanning, call the method again with the last entry from the previous result
	 * *   When all of the keys have been scanned, a zero length string is returned
	 * *   Keys added during the scan may be missed, and keys deleted during the scan may be included
	 * @returns The list of keys
	 * @throws {@link PhidgetError}
	 * @param start - The key to start the scan from
	 */
	abstract scan(start?: string): Promise<readonly string[]>;
	/**
	 * Sets the value of a key, or creates the key value pair if the key does not already exist.
	 * @throws {@link PhidgetError}
	 * @param key - The key to set
	 * @param value - The value to set
	 */
	async set(key: string, value: string): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "s", value: key });
		bp.set({ name: "1", type: "s", value: value });
		await bp.send(this._ch, BP.DICTIONARYSET);
	}

	/**
	 * Updates a key value pair in the dictionary. It is an error if the key does not exist.
	 * @throws {@link PhidgetError}
	 * @param key - The key to update
	 * @param value - The value to set
	 */
	async update(key: string, value: string): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "s", value: key });
		bp.set({ name: "1", type: "s", value: value });
		await bp.send(this._ch, BP.DICTIONARYUPDATE);
	}

}
export { DictionaryBase };
