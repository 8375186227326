/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { StepperBase } from './Stepper.gen';
import { ErrorCode, StepperControlMode } from '../Enumerations.gen';
import { BP } from '../BridgePackets.gen';
import { PhidgetError } from '../PhidgetError';
import { BridgePacket, PUNK } from '../BridgePacket';
import { logEventException } from '../Logging';

/** @public */
class Stepper extends StepperBase {

	/** @internal */
	_bridgeInput(bp: BridgePacket) {
		switch (bp.vpkt) {
			case BP.POSITIONCHANGE:
				this.data.position = bp.getNumber(0);
				if (this._isAttachedDone && this.onPositionChange) {
					try {
						this.onPositionChange((this.data.position + this.data.positionOffset) * this.data.rescaleFactor)
					} catch (err) { logEventException(err); }
				}
				break;

			case BP.VELOCITYCHANGE:
				this.data.velocity = bp.getNumber(0);
				if (this._isAttachedDone && this.onVelocityChange) {
					try {
						this.onVelocityChange(this.data.velocity * this.data.rescaleFactor);
					} catch (err) { logEventException(err); }
				}
				break;

			case BP.SETCONTROLMODE:
				switch (bp.getNumber(0)) {
					case StepperControlMode.STEP:
						this.data.minVelocityLimit = 0;
						break;
					case StepperControlMode.RUN:
						this.data.minVelocityLimit = -this.data.maxVelocityLimit;
						break;
				}
				super._bridgeInput(bp);
				break;

			case BP.STOPPED:
				this.data.isMoving = 0;
				super._bridgeInput(bp);
				break;

			case BP.SETENGAGED:
				if (bp.getBoolean(0) == true && this.data.velocityLimit !== 0 && this.data.position !== this.data.targetPosition)
					this.data.isMoving = 1;
				super._bridgeInput(bp);
				break;

			case BP.SETVELOCITYLIMIT:
				if (this.data.engaged && bp.getNumber(0) !== 0 && this.data.position !== this.data.targetPosition)
					this.data.isMoving = 1;
				super._bridgeInput(bp);
				break;

			case BP.SETTARGETPOSITION:
				if (this.data.engaged && this.data.velocityLimit !== 0 && this.data.position !== bp.getNumber(0))
					this.data.isMoving = 1;
				super._bridgeInput(bp);
				break;

			default:
				super._bridgeInput(bp);
				break;
		}
	}

	/** @internal */
	_initAfterOpen() {
		super._initAfterOpen();

		// Make sure these are always readable from the user
		//  We have sent the reset packet, so we know these are all 0, even if we haven't heard from firmware
		//  Some devices NEVER send back status if these aren't changed from 0, which violates POLA in the user API
		if (this.data.position == PUNK.INT64)
			this.data.position = 0;
		if (this.data.velocity == PUNK.DBL)
			this.data.velocity = 0;
		if (this.data.isMoving == PUNK.BOOL)
			this.data.isMoving = 0;
	}

	getAcceleration() {
		this._assertOpen();

		if (this.data.acceleration === PUNK.DBL)
			throw (new PhidgetError(ErrorCode.UNKNOWN_VALUE));

		return (this.data.acceleration * this.data.rescaleFactor);
	}

	async setAcceleration(acceleration: number) {
		this._assertOpen();

		const calcAccel = acceleration / this.data.rescaleFactor;
		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: calcAccel });
		await bp.send(this._ch, BP.SETACCELERATION);
	}

	getMinAcceleration() {
		this._assertOpen();

		if (this.data.minAcceleration === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minAcceleration * this.data.rescaleFactor);
	}

	getMaxAcceleration() {
		this._assertOpen();

		if (this.data.maxAcceleration === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxAcceleration * this.data.rescaleFactor);
	}

	getPosition() {
		this._assertOpen();

		if (this.data.position === PUNK.INT64)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return ((this.data.position + this.data.positionOffset) * this.data.rescaleFactor);
	}

	getMinPosition() {
		this._assertOpen();

		if (this.data.minPosition === PUNK.INT64)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return ((this.data.minPosition + this.data.positionOffset) * this.data.rescaleFactor);
	}

	getMaxPosition() {
		this._assertOpen();

		if (this.data.maxPosition === PUNK.INT64)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return ((this.data.maxPosition + this.data.positionOffset) * this.data.rescaleFactor);
	}

	setRescaleFactor(rescaleFactor: number) {
		this._assertOpen();

		this.data.rescaleFactor = rescaleFactor;
	}

	getTargetPosition() {
		this._assertOpen();

		if (this.data.targetPosition === PUNK.INT64)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return ((this.data.targetPosition + this.data.positionOffset) * this.data.rescaleFactor);
	}

	async setTargetPosition(targetPosition: number) {
		this._assertOpen();

		const calcPosition = (targetPosition / this.data.rescaleFactor) - this.data.positionOffset;

		if (calcPosition < this.data.minPosition || calcPosition > this.data.maxPosition)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.minPosition + " - " + this.maxPosition + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "l", value: calcPosition });
		await bp.send(this._ch, BP.SETTARGETPOSITION);
	}

	getVelocity() {
		this._assertOpen();

		if (this.data.velocity === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.velocity * this.data.rescaleFactor);
	}

	getVelocityLimit() {
		this._assertOpen();

		if (this.data.velocityLimit === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.velocityLimit * this.data.rescaleFactor);
	}

	async setVelocityLimit(velocityLimit: number) {
		this._assertOpen();

		const calcLimit = velocityLimit / this.data.rescaleFactor;

		if (calcLimit < this.data.minVelocityLimit || calcLimit > this.data.maxVelocityLimit)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.minVelocityLimit + " - " + this.maxVelocityLimit + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: calcLimit });
		await bp.send(this._ch, BP.SETVELOCITYLIMIT);
	}

	getMaxVelocityLimit() {
		this._assertOpen();

		if (this.data.maxVelocityLimit === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxVelocityLimit * this.data.rescaleFactor);
	}

	getMinVelocityLimit() {
		this._assertOpen();

		if (this.data.minVelocityLimit === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minVelocityLimit * this.data.rescaleFactor);
	}

	addPositionOffset(positionOffset: number) {
		this._assertOpen();

		this.data.positionOffset += (positionOffset / this.data.rescaleFactor);
	}
}

export { Stepper };