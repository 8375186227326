import { TemperatureSensorBase } from './TemperatureSensor.gen';
import { ThermocoupleType, ErrorEventCode, RTDWireSetup } from '../Enumerations.gen';
import { BP } from '../BridgePackets.gen';
import { BridgePacket, PUNK } from '../BridgePacket';

/** @public */
class TemperatureSensor extends TemperatureSensorBase {

	/** @internal */
	_bridgeInput(bp: BridgePacket) {
		switch (bp.vpkt) {
			case BP.DATAINTERVALCHANGE:
				if (bp.entryCount > 1)
					this.data.dataInterval = bp.getNumber(1);
				else
					this.data.dataInterval = bp.getNumber(0);
				this._FIREPropertyChange('DataInterval');
				this._FIREPropertyChange('DataRate');
				break;

			/*
			 * Set the temperature to unknown if the RTD or thermocouple type is changed.
			 * For thermocouples, reset the max/min range based on thermocouple type.
			 */
			case BP.SETRTDTYPE:
				this.data.temperature = PUNK.DBL;
				super._bridgeInput(bp);
				break;

			case BP.SETTHERMOCOUPLETYPE:
				super._bridgeInput(bp);
				this.data.temperature = PUNK.DBL;
				switch (this.data.thermocoupleType) {
					case ThermocoupleType.J:
						this.data.minTemperature = 0;
						this.data.maxTemperature = 750;
						break;
					case ThermocoupleType.K:
						this.data.minTemperature = -200;
						this.data.maxTemperature = 1250;
						break;
					case ThermocoupleType.E:
						this.data.minTemperature = -200;
						this.data.maxTemperature = 900;
						break;
					case ThermocoupleType.T:
						this.data.minTemperature = -200;
						this.data.maxTemperature = 350;
						break;
				}
				this._FIREPropertyChange('MinTemperature', bp);
				this._FIREPropertyChange('MaxTemperature', bp);
				break;
				
			case BP.RTDWIRESETUPCHANGE:
				this.data.RTDWireSetup = bp.entries[0].v as RTDWireSetup;
				this._FIREPropertyChange('RTDWireSetup', bp);
				break;

			default:
				super._bridgeInput(bp);
				break;
		}
	}

	/** @internal */
	_errorHandler(code: ErrorEventCode) {
		switch (code) {
			case ErrorEventCode.SATURATION:
			case ErrorEventCode.OUT_OF_RANGE:
				this.data.temperature = PUNK.DBL;
				this._gotTemperatureChangeErrorEvent = true;
				break;
		}
	}
}

export { TemperatureSensor };