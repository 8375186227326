import { DigitalInputBase } from './DigitalInput.gen';
import { BridgePacket } from '../BridgePacket';
import { BP } from '../BridgePackets.gen';
import * as Enum from '../Enumerations.gen';

/** @public */
class DigitalInput extends DigitalInputBase {

	/** @internal */
	_bridgeInput(bp: BridgePacket) {
		switch (bp.vpkt) {
			case BP.POWERSUPPLYCHANGE:
				this.data.powerSupply = bp.entries[0].v as Enum.PowerSupply;
				this._FIREPropertyChange('PowerSupply', bp);
				break;
			case BP.INPUTMODECHANGE:
				this.data.inputMode = bp.entries[0].v as Enum.InputMode;
				this._FIREPropertyChange('InputMode', bp);
				break;
			default:
				super._bridgeInput(bp);
				break;
		}
	}
}
export { DigitalInput };