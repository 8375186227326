/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PhidgetChannel } from '../Phidget';
import { Channel } from '../Channel';
import { ErrorCode, ChannelClass } from '../Enumerations.gen';
import { PhidgetError } from '../PhidgetError';
import { BridgePacket, PUNK } from '../BridgePacket';
import { BP } from '../BridgePackets.gen';
import { logEventException } from '../Logging';
import { DeviceChannelUID } from '../Devices.gen';

/** @internal */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface HumiditySensorData {
	dataInterval: number,
	maxDataInterval: number,
	minDataRate: number,
	maxDataRate: number,
	maxHumidity: number,
	maxHumidityChangeTrigger: number,
	minDataInterval: number,
	minHumidity: number,
	minHumidityChangeTrigger: number,
	humidity: number,
	humidityChangeTrigger: number,
}

abstract class HumiditySensorBase extends PhidgetChannel {
	/** @internal */
	data: HumiditySensorData;
	/**
	 * **HumidityChange** event
	 *  * `humidity` - The ambient relative humidity
	 * ---
	 * The most recent humidity value the channel has measured will be reported in this event, which occurs when the `dataInterval` has elapsed.
	 * 
	 * *   If a `humidityChangeTrigger` has been set to a non-zero value, the `HumidityChange` event will not occur until the humidity has changed by at least the `humidityChangeTrigger` value.
	 */
	onHumidityChange: ((humidity: number) => void) | null = null;
	/** @internal */
	_gotHumidityChangeErrorEvent?: boolean;

	/**
	 * The Humidity Sensor class gathers relative humidity data from the Phidget and makes it available to your code.
	 * 
	 * If you're using a simple 0-5V sensor that does not have its own firmware, use the VoltageInput or VoltageRatioInput class instead, as specified for your device.
	 * @public
	 */
	constructor();
	/** @internal */
	constructor(ch?: Channel);
	constructor(ch?: Channel) {
		super(ch);
		this._class = ChannelClass.HUMIDITY_SENSOR;
		this.name = "HumiditySensor";
		this.data = this._initData();
	}

	/** @internal */
	_bridgeInput(bp: BridgePacket) {

		switch(bp.vpkt) {
		case BP.SETDATAINTERVAL:
			if (bp.entryCount > 1)
				this.data.dataInterval = bp.entries[1].v as number;
			else
				this.data.dataInterval = bp.entries[0].v as number;
			this._FIREPropertyChange('DataInterval', bp);
			this._FIREPropertyChange('DataRate', bp);
			break;
		case BP.SETCHANGETRIGGER:
			this.data.humidityChangeTrigger = bp.entries[0].v as number;
			this._FIREPropertyChange('HumidityChangeTrigger', bp);
			break;
		case BP.HUMIDITYCHANGE: {
			this.data.humidity = bp.entries[0].v as number;
			if (this._isAttachedDone && this.onHumidityChange) {
				try {
					this.onHumidityChange(this.data.humidity);
				} catch (err) { logEventException(err); }
			}
			break;
		}
		default:
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			throw new PhidgetError(ErrorCode.INVALID_PACKET, "Unsupported bridge packet: 0x" + bp.vpkt!.toString(16));
		}
	}

	/** @internal */
	_initData(): HumiditySensorData {
		return {
			dataInterval: PUNK.DBL,
			maxDataInterval: PUNK.UINT32,
			minDataRate: PUNK.DBL,
			maxDataRate: PUNK.DBL,
			maxHumidity: PUNK.DBL,
			maxHumidityChangeTrigger: PUNK.DBL,
			minDataInterval: PUNK.UINT32,
			minHumidity: PUNK.DBL,
			minHumidityChangeTrigger: PUNK.DBL,
			humidity: PUNK.DBL,
			humidityChangeTrigger: PUNK.DBL,
		}
	}

	/** @internal */
	_initAfterOpen() {
		this.data = this._initData();

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._HUM1000_HUMIDITYSENSOR_100:
			this.data.dataInterval = 500;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 2;
			this.data.maxHumidity = 100;
			this.data.maxHumidityChangeTrigger = 100;
			this.data.minDataInterval = 500;
			this.data.minHumidity = 0;
			this.data.minHumidityChangeTrigger = 0;
			this.data.humidityChangeTrigger = 0;
			break;
		case DeviceChannelUID._HUM1001_HUMIDITYSENSOR_100:
			this.data.dataInterval = 250;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 20;
			this.data.maxHumidity = 100;
			this.data.maxHumidityChangeTrigger = 100;
			this.data.minDataInterval = 50;
			this.data.minHumidity = 0;
			this.data.minHumidityChangeTrigger = 0;
			this.data.humidityChangeTrigger = 0;
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	// eslint-disable-next-line require-await
	async _setDefaults() {
		let bp;

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._HUM1000_HUMIDITYSENSOR_100:
		case DeviceChannelUID._HUM1001_HUMIDITYSENSOR_100:
			bp = new BridgePacket();
			bp.set({ name: "0", type: "u", value: Math.round(this.data.dataInterval) });
			await bp.send(this._ch, BP.SETDATAINTERVAL);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.humidityChangeTrigger });
			await bp.send(this._ch, BP.SETCHANGETRIGGER);
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	_hasInitialState() {

		if ((this.data.humidity == PUNK.DBL)
			&& ! this._gotHumidityChangeErrorEvent)
			return false;

		return true;
	}

	/** @internal */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	_fireInitialEvents() {

		if(this.data.humidity != PUNK.DBL)
			if (this.onHumidityChange)
				try {
					this.onHumidityChange(this.data.humidity);
				} catch (err) { logEventException(err); }

	}

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `HumidityChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * *   The timing between `HumidityChange` events can also be affected by the `humidityChangeTrigger`.
	 * @throws {@link PhidgetError}
	 */
	get dataInterval() { return this.getDataInterval(); }
	/**
	 * The minimum value that `dataInterval` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDataInterval() { return this.getMinDataInterval(); }
	/**
	 * The maximum value that `dataInterval` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDataInterval() { return this.getMaxDataInterval(); }
	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @throws {@link PhidgetError}
	 */
	get dataRate() { return this.getDataRate(); }
	/**
	 * The minimum value that `dataRate` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDataRate() { return this.getMinDataRate(); }
	/**
	 * The maximum value that `dataRate` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDataRate() { return this.getMaxDataRate(); }
	/**
	 * The most recent humidity value that the channel has reported.
	 * 
	 * *   This value will always be between `minHumidity` and `maxHumidity`.
	 * @throws {@link PhidgetError}
	 */
	get humidity() { return this.getHumidity(); }
	/**
	 * The minimum value that the `HumidityChange` event will report.
	 * @throws {@link PhidgetError}
	 */
	get minHumidity() { return this.getMinHumidity(); }
	/**
	 * The maximum value that the `HumidityChange` event will report.
	 * @throws {@link PhidgetError}
	 */
	get maxHumidity() { return this.getMaxHumidity(); }
	/**
	 * The channel will not issue a `HumidityChange` event until the humidity value has changed by the amount specified by the `humidityChangeTrigger`.
	 * 
	 * *   Setting the `humidityChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering.
	 * @throws {@link PhidgetError}
	 */
	get humidityChangeTrigger() { return this.getHumidityChangeTrigger(); }
	/**
	 * The minimum value that `humidityChangeTrigger` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minHumidityChangeTrigger() { return this.getMinHumidityChangeTrigger(); }
	/**
	 * The maximum value that `humidityChangeTrigger` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxHumidityChangeTrigger() { return this.getMaxHumidityChangeTrigger(); }

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `HumidityChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * *   The timing between `HumidityChange` events can also be affected by the `humidityChangeTrigger`.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getDataInterval(): number {
		this._assertOpen();

		if (this.data.dataInterval === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return this.data.dataInterval;
	}

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `HumidityChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * *   The timing between `HumidityChange` events can also be affected by the `humidityChangeTrigger`.
	 * @throws {@link PhidgetError}
	 * @param dataInterval - The data interval value
	 */
	async setDataInterval(dataInterval: number): Promise<void> {
		this._assertOpen();

		if (dataInterval < this.data.minDataInterval || dataInterval > this.data.maxDataInterval)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDataInterval + " - " + this.data.maxDataInterval + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: dataInterval });
		await bp.send(this._ch, BP.SETDATAINTERVAL);
	}

	/**
	 * The minimum value that `dataInterval` can be set to.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getMinDataInterval(): number {
		this._assertOpen();

		if (this.data.minDataInterval === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDataInterval);
	}

	/**
	 * The maximum value that `dataInterval` can be set to.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getMaxDataInterval(): number {
		this._assertOpen();

		if (this.data.maxDataInterval === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDataInterval);
	}

	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @returns The data rate for the channel
	 * @throws {@link PhidgetError}
	 */
	getDataRate(): number {
		this._assertOpen();

		if (this.data.dataInterval === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (1000.0 / this.data.dataInterval);
	}

	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @throws {@link PhidgetError}
	 * @param dataRate - The data rate for the channel
	 */
	async setDataRate(dataRate: number): Promise<void> {
		this._assertOpen();

		if (dataRate < this.data.minDataRate || dataRate > this.data.maxDataRate)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDataRate + " - " + this.data.maxDataRate + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: Math.round(1000.0 / dataRate) });
		bp.set({ name: "1", type: "g", value: (1000.0 / dataRate) });
		await bp.send(this._ch, BP.SETDATAINTERVAL);
	}

	/**
	 * The minimum value that `dataRate` can be set to.
	 * @returns The data rate value
	 * @throws {@link PhidgetError}
	 */
	getMinDataRate(): number {
		this._assertOpen();

		if (this.data.minDataRate === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDataRate);
	}

	/**
	 * The maximum value that `dataRate` can be set to.
	 * @returns The data rate value
	 * @throws {@link PhidgetError}
	 */
	getMaxDataRate(): number {
		this._assertOpen();

		if (this.data.maxDataRate === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDataRate);
	}

	/**
	 * The most recent humidity value that the channel has reported.
	 * 
	 * *   This value will always be between `minHumidity` and `maxHumidity`.
	 * @returns The humidity value
	 * @throws {@link PhidgetError}
	 */
	getHumidity(): number {
		this._assertOpen();

		if (this.data.humidity === PUNK.DBL || Number.isNaN(this.data.humidity))
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);
		if (this.data.humidity > this.data.maxHumidity)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE_HIGH);
		if (this.data.humidity < this.data.minHumidity)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE_LOW);

		return (this.data.humidity);
	}

	/**
	 * The minimum value that the `HumidityChange` event will report.
	 * @returns The humidity value
	 * @throws {@link PhidgetError}
	 */
	getMinHumidity(): number {
		this._assertOpen();

		if (this.data.minHumidity === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minHumidity);
	}

	/**
	 * The maximum value that the `HumidityChange` event will report.
	 * @returns The humidity value
	 * @throws {@link PhidgetError}
	 */
	getMaxHumidity(): number {
		this._assertOpen();

		if (this.data.maxHumidity === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxHumidity);
	}

	/**
	 * The channel will not issue a `HumidityChange` event until the humidity value has changed by the amount specified by the `humidityChangeTrigger`.
	 * 
	 * *   Setting the `humidityChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering.
	 * @returns The change trigger value
	 * @throws {@link PhidgetError}
	 */
	getHumidityChangeTrigger(): number {
		this._assertOpen();

		if (this.data.humidityChangeTrigger === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.humidityChangeTrigger);
	}

	/**
	 * The channel will not issue a `HumidityChange` event until the humidity value has changed by the amount specified by the `humidityChangeTrigger`.
	 * 
	 * *   Setting the `humidityChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering.
	 * @throws {@link PhidgetError}
	 * @param humidityChangeTrigger - The change trigger value
	 */
	async setHumidityChangeTrigger(humidityChangeTrigger: number): Promise<void> {
		this._assertOpen();

		if (humidityChangeTrigger < this.data.minHumidityChangeTrigger || humidityChangeTrigger > this.data.maxHumidityChangeTrigger)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minHumidityChangeTrigger + " - " + this.data.maxHumidityChangeTrigger + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: humidityChangeTrigger });
		await bp.send(this._ch, BP.SETCHANGETRIGGER);
	}

	/**
	 * The minimum value that `humidityChangeTrigger` can be set to.
	 * @returns The change trigger value
	 * @throws {@link PhidgetError}
	 */
	getMinHumidityChangeTrigger(): number {
		this._assertOpen();

		if (this.data.minHumidityChangeTrigger === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minHumidityChangeTrigger);
	}

	/**
	 * The maximum value that `humidityChangeTrigger` can be set to.
	 * @returns The change trigger value
	 * @throws {@link PhidgetError}
	 */
	getMaxHumidityChangeTrigger(): number {
		this._assertOpen();

		if (this.data.maxHumidityChangeTrigger === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxHumidityChangeTrigger);
	}

}
export { HumiditySensorBase };
