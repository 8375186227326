import { CapacitiveTouchBase } from './CapacitiveTouch.gen';
import { BP } from '../BridgePackets.gen';
import { BridgePacket } from '../BridgePacket';

/** @public */
class CapacitiveTouch extends CapacitiveTouchBase {

	/** @internal */
	_bridgeInput(bp: BridgePacket) {
		switch (bp.vpkt) {
			case BP.TOUCHINPUTVALUECHANGE:
				this.data.isTouched = 1;
				super._bridgeInput(bp);
				break;
			case BP.TOUCHINPUTEND:
				this.data.isTouched = 0;
				super._bridgeInput(bp);
				break;
			default:
				super._bridgeInput(bp);
				break;
		}
	}
}

export { CapacitiveTouch };