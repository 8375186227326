﻿export function GetBigInt64(view: DataView, offset: number) {
	if ("getBigInt64" in DataView.prototype) {
		return Number(view.getBigInt64(offset));
	} else {
		const isNegative = (view.getUint8(offset) & 0x80) > 0;
		let uint64 = 0;
		let carrying = true;
		for (let i = 0; i < 2; i++) {
			let uint32 = view.getUint32(offset + (4 - (i * 4)));
			if (isNegative) {
				if (carrying) {
					if (uint32 !== 0x00000000) {
						uint32 = (~(uint32 - 1)) & 0xFFFFFFFF;
						carrying = false;
					}
				} else {
					uint32 = (~uint32) & 0xFFFFFFFF;
				}
			}
			uint64 += uint32 * (2 ** (8 * i));
		}
		return (isNegative ? -uint64 : uint64);
	}
}

export function SetBigInt64(view: DataView, offset: number, value: number) {
	//this oddly seems necessary, otherwise (temp >> 32) === temp somehow when used with setInt32()...
	const temp = value >> 2;
	view.setInt32(offset, temp >> 30);
	view.setUint32(offset + 4, value & 0xFFFFFFFF);
}

export function StringToWordByteArray(buff: string) {
	const wordarray = new Uint16Array(buff.length);
	let i = 0;
	for (i = 0; i < buff.length; i++) {
		wordarray[i] = buff.charCodeAt(i);
	}
	return wordarray;
}

export function WordByteArrayToString(bytearray: Uint16Array) {
	return String.fromCharCode(...bytearray);
}

export function RoundDouble(num: number, decimals: number) {
	return Math.round(num * Math.pow(10, decimals)) / Math.pow(10, decimals);
}

export function UpperPowerOfTwo(v: number) {
	v--;
	v |= v >> 1;
	v |= v >> 2;
	v |= v >> 4;
	v |= v >> 8;
	v |= v >> 16;
	v++;
	return v;
}

export const PhidgetSleep = (delay: number) => new Promise((resolve) => setTimeout(resolve, delay));