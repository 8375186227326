import { DigitalOutputBase } from './DigitalOutput.gen';
import { ErrorEventCode } from '../Enumerations.gen';
import { BP } from '../BridgePackets.gen';
import { BridgePacket, PUNK } from '../BridgePacket';

/** @public */
class DigitalOutput extends DigitalOutputBase {

	/** @internal */
	_bridgeInput(bp: BridgePacket) {
		switch (bp.vpkt) {
			case BP.SETDUTYCYCLE:
				this.data.state = (bp.getNumber(0) ? 1 : 0);
				super._bridgeInput(bp);
				break;
			case BP.SETSTATE:
				this.data.dutyCycle = (bp.getBoolean(0) ? 1 : 0);
				super._bridgeInput(bp);
				break;
			default:
				super._bridgeInput(bp);
				break;
		}
	}

	/** @internal */
	_errorHandler(code: ErrorEventCode) {
		switch (code) {
			case ErrorEventCode.FAILSAFE_CONDITION:
				this.data.state = 0;
				this.data.dutyCycle = 0;
				break;
			case ErrorEventCode.INVALID_STATE_CONDITION:
				this.data.state = PUNK.BOOL;
				this.data.dutyCycle = PUNK.DBL;
				break;
		}
	}
}

export { DigitalOutput };