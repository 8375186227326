/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PhidgetChannel } from '../Phidget';
import { Channel } from '../Channel';
import { ErrorCode, ChannelClass } from '../Enumerations.gen';
import * as Enum from '../Enumerations.gen';
import * as SEnum from '../SupportedEnum.gen';
import { PhidgetError } from '../PhidgetError';
import { BridgePacket, PUNK } from '../BridgePacket';
import { BP } from '../BridgePackets.gen';
import { logEventException } from '../Logging';
import { DeviceChannelUID } from '../Devices.gen';

/** @internal */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface CurrentInputData {
	dataInterval: number,
	minDataInterval: number,
	maxDataInterval: number,
	minDataRate: number,
	maxDataRate: number,
	maxCurrent: number,
	maxCurrentChangeTrigger: number,
	minCurrent: number,
	minCurrentChangeTrigger: number,
	current: number,
	currentChangeTrigger: number,
	powerSupply: Enum.PowerSupply | PUNK.ENUM,
}

abstract class CurrentInputBase extends PhidgetChannel {
	/** @internal */
	data: CurrentInputData;
	/**
	 * **CurrentChange** event
	 *  * `current` - The current value
	 * ---
	 * The most recent current value the channel has measured will be reported in this event, which occurs when the `dataInterval` has elapsed.
	 * 
	 * *   If a `currentChangeTrigger` has been set to a non-zero value, the `CurrentChange` event will not occur until the current value has changed by at least the `currentChangeTrigger` value.
	 */
	onCurrentChange: ((current: number) => void) | null = null;
	/** @internal */
	_gotCurrentChangeErrorEvent?: boolean;

	/**
	 * The Current Input class is used to measure current flowing through the Phidget from outside sources.
	 * 
	 * This class may be used on a simple current sensor, or sometimes on a more complex Phidget that measures the amount of current flowing through an attached device, such as a motor controller, for diagnostic or control purposes.
	 * @public
	 */
	constructor();
	/** @internal */
	constructor(ch?: Channel);
	constructor(ch?: Channel) {
		super(ch);
		this._class = ChannelClass.CURRENT_INPUT;
		this.name = "CurrentInput";
		this.data = this._initData();
	}

	/** @internal */
	_bridgeInput(bp: BridgePacket) {

		switch(bp.vpkt) {
		case BP.SETCHANGETRIGGER:
			this.data.currentChangeTrigger = bp.entries[0].v as number;
			this._FIREPropertyChange('CurrentChangeTrigger', bp);
			break;
		case BP.SETDATAINTERVAL:
			if (bp.entryCount > 1)
				this.data.dataInterval = bp.entries[1].v as number;
			else
				this.data.dataInterval = bp.entries[0].v as number;
			this._FIREPropertyChange('DataInterval', bp);
			this._FIREPropertyChange('DataRate', bp);
			break;
		case BP.SETPOWERSUPPLY:
			this.data.powerSupply = bp.entries[0].v as Enum.PowerSupply;
			this._FIREPropertyChange('PowerSupply', bp);
			break;
		case BP.CURRENTCHANGE: {
			this.data.current = bp.entries[0].v as number;
			if (this._isAttachedDone && this.onCurrentChange) {
				try {
					this.onCurrentChange(this.data.current);
				} catch (err) { logEventException(err); }
			}
			break;
		}
		default:
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			throw new PhidgetError(ErrorCode.INVALID_PACKET, "Unsupported bridge packet: 0x" + bp.vpkt!.toString(16));
		}
	}

	/** @internal */
	_initData(): CurrentInputData {
		return {
			dataInterval: PUNK.DBL,
			minDataInterval: PUNK.UINT32,
			maxDataInterval: PUNK.UINT32,
			minDataRate: PUNK.DBL,
			maxDataRate: PUNK.DBL,
			maxCurrent: PUNK.DBL,
			maxCurrentChangeTrigger: PUNK.DBL,
			minCurrent: PUNK.DBL,
			minCurrentChangeTrigger: PUNK.DBL,
			current: PUNK.DBL,
			currentChangeTrigger: PUNK.DBL,
			powerSupply: PUNK.ENUM,
		}
	}

	/** @internal */
	_initAfterOpen() {
		this.data = this._initData();

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._DAQ1400_CURRENTINPUT_100:
		case DeviceChannelUID._DAQ1400_CURRENTINPUT_120:
			this.data.dataInterval = 250;
			this.data.minDataInterval = 20;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 50;
			this.data.maxCurrent = 0.02;
			this.data.maxCurrentChangeTrigger = 0.016;
			this.data.minCurrent = 0.0005;
			this.data.minCurrentChangeTrigger = 0;
			this.data.currentChangeTrigger = 0;
			this.data.powerSupply = Enum.PowerSupply.VOLTS_12;
			break;
		case DeviceChannelUID._VCP1100_CURRENTINPUT_100:
			this.data.dataInterval = 250;
			this.data.minDataInterval = 20;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 50;
			this.data.maxCurrent = 30;
			this.data.maxCurrentChangeTrigger = 60;
			this.data.minCurrent = -30;
			this.data.minCurrentChangeTrigger = 0;
			this.data.currentChangeTrigger = 0;
			break;
		case DeviceChannelUID._DCC1000_CURRENTINPUT_100:
		case DeviceChannelUID._DCC1000_CURRENTINPUT_200:
		case DeviceChannelUID._DCC1000_CURRENTINPUT_210:
			this.data.dataInterval = 250;
			this.data.minDataInterval = 100;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 10;
			this.data.maxCurrent = 25;
			this.data.maxCurrentChangeTrigger = 25;
			this.data.minCurrent = -25;
			this.data.minCurrentChangeTrigger = 0;
			this.data.currentChangeTrigger = 0;
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	// eslint-disable-next-line require-await
	async _setDefaults() {
		let bp;

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._DAQ1400_CURRENTINPUT_100:
		case DeviceChannelUID._DAQ1400_CURRENTINPUT_120:
			bp = new BridgePacket();
			bp.set({ name: "0", type: "u", value: Math.round(this.data.dataInterval) });
			await bp.send(this._ch, BP.SETDATAINTERVAL);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.currentChangeTrigger });
			await bp.send(this._ch, BP.SETCHANGETRIGGER);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "d", value: this.data.powerSupply });
			await bp.send(this._ch, BP.SETPOWERSUPPLY);
			break;
		case DeviceChannelUID._VCP1100_CURRENTINPUT_100:
			bp = new BridgePacket();
			bp.set({ name: "0", type: "u", value: Math.round(this.data.dataInterval) });
			await bp.send(this._ch, BP.SETDATAINTERVAL);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.currentChangeTrigger });
			await bp.send(this._ch, BP.SETCHANGETRIGGER);
			break;
		case DeviceChannelUID._DCC1000_CURRENTINPUT_100:
		case DeviceChannelUID._DCC1000_CURRENTINPUT_200:
		case DeviceChannelUID._DCC1000_CURRENTINPUT_210:
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	_hasInitialState() {

		if ((this.data.current == PUNK.DBL)
			&& ! this._gotCurrentChangeErrorEvent)
			return false;

		return true;
	}

	/** @internal */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	_fireInitialEvents() {

		if(this.data.current != PUNK.DBL)
			if (this.onCurrentChange)
				try {
					this.onCurrentChange(this.data.current);
				} catch (err) { logEventException(err); }

	}

	/**
	 * The most recent current value that the channel has reported.
	 * 
	 * *   This value will always be between `minCurrent` and `maxCurrent`.
	 * @throws {@link PhidgetError}
	 */
	get current() { return this.getCurrent(); }
	/**
	 * The minimum value the `CurrentChange` event will report.
	 * @throws {@link PhidgetError}
	 */
	get minCurrent() { return this.getMinCurrent(); }
	/**
	 * The maximum value the `CurrentChange` event will report.
	 * @throws {@link PhidgetError}
	 */
	get maxCurrent() { return this.getMaxCurrent(); }
	/**
	 * The channel will not issue a `CurrentChange` event until the current value has changed by the amount specified by the `currentChangeTrigger`.
	 * 
	 * *   Setting the `currentChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering
	 * @throws {@link PhidgetError}
	 */
	get currentChangeTrigger() { return this.getCurrentChangeTrigger(); }
	/**
	 * The minimum value that `currentChangeTrigger` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minCurrentChangeTrigger() { return this.getMinCurrentChangeTrigger(); }
	/**
	 * The maximum value that `currentChangeTrigger` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxCurrentChangeTrigger() { return this.getMaxCurrentChangeTrigger(); }
	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `CurrentChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * *   The timing between `CurrentChange` events can also be affected by the `currentChangeTrigger`.
	 * @throws {@link PhidgetError}
	 */
	get dataInterval() { return this.getDataInterval(); }
	/**
	 * The minimum value that `dataInterval` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDataInterval() { return this.getMinDataInterval(); }
	/**
	 * The maximum value that `dataInterval` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDataInterval() { return this.getMaxDataInterval(); }
	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @throws {@link PhidgetError}
	 */
	get dataRate() { return this.getDataRate(); }
	/**
	 * The minimum value that `dataRate` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDataRate() { return this.getMinDataRate(); }
	/**
	 * The maximum value that `dataRate` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDataRate() { return this.getMaxDataRate(); }
	/**
	 * Choose the power supply voltage.
	 * 
	 * *   Set this to the voltage specified in the attached sensor's data sheet to power it.
	 * 
	 * *   Set to `phidget22.PowerSupply.OFF` to turn off the supply to save power.
	 * @throws {@link PhidgetError}
	 */
	get powerSupply() { return this.getPowerSupply(); }

	/**
	 * The most recent current value that the channel has reported.
	 * 
	 * *   This value will always be between `minCurrent` and `maxCurrent`.
	 * @returns The current value
	 * @throws {@link PhidgetError}
	 */
	getCurrent(): number {
		this._assertOpen();

		if (this.data.current === PUNK.DBL || Number.isNaN(this.data.current))
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);
		if (this.data.current > this.data.maxCurrent)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE_HIGH);
		if (this.data.current < this.data.minCurrent)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE_LOW);

		return (this.data.current);
	}

	/**
	 * The minimum value the `CurrentChange` event will report.
	 * @returns The current value
	 * @throws {@link PhidgetError}
	 */
	getMinCurrent(): number {
		this._assertOpen();

		if (this.data.minCurrent === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minCurrent);
	}

	/**
	 * The maximum value the `CurrentChange` event will report.
	 * @returns The current value
	 * @throws {@link PhidgetError}
	 */
	getMaxCurrent(): number {
		this._assertOpen();

		if (this.data.maxCurrent === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxCurrent);
	}

	/**
	 * The channel will not issue a `CurrentChange` event until the current value has changed by the amount specified by the `currentChangeTrigger`.
	 * 
	 * *   Setting the `currentChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering
	 * @returns The change trigger value
	 * @throws {@link PhidgetError}
	 */
	getCurrentChangeTrigger(): number {
		this._assertOpen();

		if (this.data.currentChangeTrigger === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.currentChangeTrigger);
	}

	/**
	 * The channel will not issue a `CurrentChange` event until the current value has changed by the amount specified by the `currentChangeTrigger`.
	 * 
	 * *   Setting the `currentChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering
	 * @throws {@link PhidgetError}
	 * @param currentChangeTrigger - The change trigger value
	 */
	async setCurrentChangeTrigger(currentChangeTrigger: number): Promise<void> {
		this._assertOpen();

		if (currentChangeTrigger < this.data.minCurrentChangeTrigger || currentChangeTrigger > this.data.maxCurrentChangeTrigger)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minCurrentChangeTrigger + " - " + this.data.maxCurrentChangeTrigger + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: currentChangeTrigger });
		await bp.send(this._ch, BP.SETCHANGETRIGGER);
	}

	/**
	 * The minimum value that `currentChangeTrigger` can be set to.
	 * @returns The change trigger value
	 * @throws {@link PhidgetError}
	 */
	getMinCurrentChangeTrigger(): number {
		this._assertOpen();

		if (this.data.minCurrentChangeTrigger === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minCurrentChangeTrigger);
	}

	/**
	 * The maximum value that `currentChangeTrigger` can be set to.
	 * @returns The change trigger value
	 * @throws {@link PhidgetError}
	 */
	getMaxCurrentChangeTrigger(): number {
		this._assertOpen();

		if (this.data.maxCurrentChangeTrigger === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxCurrentChangeTrigger);
	}

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `CurrentChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * *   The timing between `CurrentChange` events can also be affected by the `currentChangeTrigger`.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getDataInterval(): number {
		this._assertOpen();

		if (this.data.dataInterval === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return this.data.dataInterval;
	}

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `CurrentChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * *   The timing between `CurrentChange` events can also be affected by the `currentChangeTrigger`.
	 * @throws {@link PhidgetError}
	 * @param dataInterval - The data interval value
	 */
	async setDataInterval(dataInterval: number): Promise<void> {
		this._assertOpen();

		if (dataInterval < this.data.minDataInterval || dataInterval > this.data.maxDataInterval)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDataInterval + " - " + this.data.maxDataInterval + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: dataInterval });
		await bp.send(this._ch, BP.SETDATAINTERVAL);
	}

	/**
	 * The minimum value that `dataInterval` can be set to.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getMinDataInterval(): number {
		this._assertOpen();

		if (this.data.minDataInterval === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDataInterval);
	}

	/**
	 * The maximum value that `dataInterval` can be set to.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getMaxDataInterval(): number {
		this._assertOpen();

		if (this.data.maxDataInterval === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDataInterval);
	}

	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @returns The data rate for the channel
	 * @throws {@link PhidgetError}
	 */
	getDataRate(): number {
		this._assertOpen();

		if (this.data.dataInterval === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (1000.0 / this.data.dataInterval);
	}

	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @throws {@link PhidgetError}
	 * @param dataRate - The data rate for the channel
	 */
	async setDataRate(dataRate: number): Promise<void> {
		this._assertOpen();

		if (dataRate < this.data.minDataRate || dataRate > this.data.maxDataRate)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDataRate + " - " + this.data.maxDataRate + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: Math.round(1000.0 / dataRate) });
		bp.set({ name: "1", type: "g", value: (1000.0 / dataRate) });
		await bp.send(this._ch, BP.SETDATAINTERVAL);
	}

	/**
	 * The minimum value that `dataRate` can be set to.
	 * @returns The data rate value
	 * @throws {@link PhidgetError}
	 */
	getMinDataRate(): number {
		this._assertOpen();

		if (this.data.minDataRate === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDataRate);
	}

	/**
	 * The maximum value that `dataRate` can be set to.
	 * @returns The data rate value
	 * @throws {@link PhidgetError}
	 */
	getMaxDataRate(): number {
		this._assertOpen();

		if (this.data.maxDataRate === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDataRate);
	}

	/**
	 * Choose the power supply voltage.
	 * 
	 * *   Set this to the voltage specified in the attached sensor's data sheet to power it.
	 * 
	 * *   Set to `phidget22.PowerSupply.OFF` to turn off the supply to save power.
	 * @returns The power supply value
	 * @throws {@link PhidgetError}
	 */
	getPowerSupply(): Enum.PowerSupply {
		this._assertOpen();

		if (this.data.powerSupply === PUNK.ENUM)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.powerSupply);
	}

	/**
	 * Choose the power supply voltage.
	 * 
	 * *   Set this to the voltage specified in the attached sensor's data sheet to power it.
	 * 
	 * *   Set to `phidget22.PowerSupply.OFF` to turn off the supply to save power.
	 * @throws {@link PhidgetError}
	 * @param powerSupply - The power supply value
	 */
	async setPowerSupply(powerSupply: Enum.PowerSupply): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (!SEnum.supportedPowerSupply(this._ch!, powerSupply))
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Specified PowerSupply is unsupported by this device.");

		bp.set({ name: "0", type: "d", value: powerSupply });
		await bp.send(this._ch, BP.SETPOWERSUPPLY);
	}

}
export { CurrentInputBase };
