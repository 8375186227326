/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PhidgetChannel } from '../Phidget';
import { Channel } from '../Channel';
import { ErrorCode, ChannelClass } from '../Enumerations.gen';
import * as Enum from '../Enumerations.gen';
import * as SEnum from '../SupportedEnum.gen';
import { PhidgetError } from '../PhidgetError';
import { BridgePacket, PUNK } from '../BridgePacket';
import { BP } from '../BridgePackets.gen';
import { DeviceChannelUID } from '../Devices.gen';

/** @internal */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface PowerGuardData {
	powerEnabled: number,
	overVoltage: number,
	maxOverVoltage: number,
	minOverVoltage: number,
	fanMode: Enum.FanMode | PUNK.ENUM,
	maxFailsafeTime: number,
	minFailsafeTime: number,
}

abstract class PowerGuardBase extends PhidgetChannel {
	/** @internal */
	data: PowerGuardData;

	/**
	 * The Power Guard class controls the safety features and thresholds of a programmable power guard Phidget board.
	 * @public
	 */
	constructor();
	/** @internal */
	constructor(ch?: Channel);
	constructor(ch?: Channel) {
		super(ch);
		this._class = ChannelClass.POWER_GUARD;
		this.name = "PowerGuard";
		this.data = this._initData();
	}

	/** @internal */
	_bridgeInput(bp: BridgePacket) {

		switch(bp.vpkt) {
		case BP.SETFAILSAFETIME:
			break;
		case BP.SETFANMODE:
			this.data.fanMode = bp.entries[0].v as Enum.FanMode;
			this._FIREPropertyChange('FanMode', bp);
			break;
		case BP.SETOVERVOLTAGE:
			this.data.overVoltage = bp.entries[0].v as number;
			this._FIREPropertyChange('OverVoltage', bp);
			break;
		case BP.SETENABLED:
			this.data.powerEnabled = bp.entries[0].v as number;
			this._FIREPropertyChange('PowerEnabled', bp);
			break;
		case BP.FAILSAFERESET:
			break;
		default:
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			throw new PhidgetError(ErrorCode.INVALID_PACKET, "Unsupported bridge packet: 0x" + bp.vpkt!.toString(16));
		}
	}

	/** @internal */
	_initData(): PowerGuardData {
		return {
			powerEnabled: PUNK.BOOL,
			overVoltage: PUNK.DBL,
			maxOverVoltage: PUNK.DBL,
			minOverVoltage: PUNK.DBL,
			fanMode: PUNK.ENUM,
			maxFailsafeTime: PUNK.UINT32,
			minFailsafeTime: PUNK.UINT32,
		}
	}

	/** @internal */
	_initAfterOpen() {
		this.data = this._initData();

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._SAF1000_POWERGUARD_100:
			this.data.powerEnabled = 0;
			this.data.maxOverVoltage = 33;
			this.data.minOverVoltage = 8;
			this.data.fanMode = Enum.FanMode.AUTO;
			break;
		case DeviceChannelUID._SAF1000_POWERGUARD_110:
			this.data.powerEnabled = 0;
			this.data.maxOverVoltage = 33;
			this.data.minOverVoltage = 8;
			this.data.fanMode = Enum.FanMode.AUTO;
			this.data.maxFailsafeTime = 30000;
			this.data.minFailsafeTime = 500;
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	// eslint-disable-next-line require-await
	async _setDefaults() {
		let bp;

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._SAF1000_POWERGUARD_100:
		case DeviceChannelUID._SAF1000_POWERGUARD_110:
			bp = new BridgePacket();
			bp.set({ name: "0", type: "d", value: this.data.powerEnabled });
			await bp.send(this._ch, BP.SETENABLED);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "d", value: this.data.fanMode });
			await bp.send(this._ch, BP.SETFANMODE);
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	_hasInitialState() {


		return true;
	}

	/** @internal */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	_fireInitialEvents() {

	}

	/**
	 * The minimum value that `failsafeTime` can be set to when calling `enableFailsafe()`.
	 * @throws {@link PhidgetError}
	 */
	get minFailsafeTime() { return this.getMinFailsafeTime(); }
	/**
	 * The maximum value that `failsafeTime` can be set to when calling `enableFailsafe()`.
	 * @throws {@link PhidgetError}
	 */
	get maxFailsafeTime() { return this.getMaxFailsafeTime(); }
	/**
	 * The `fanMode` dictates the operating condition of the fan.
	 * 
	 * *   Choose between on, off, or automatic (based on temperature).
	 * *   If the `fanMode` is set to automatic, the fan will turn on when the temperature reaches 70°C and it will remain on until the temperature falls below 55°C.
	 * *   If the `fanMode` is off, the device will still turn on the fan if the temperature reaches 85°C and it will remain on until it falls below 70°C.
	 * @throws {@link PhidgetError}
	 */
	get fanMode() { return this.getFanMode(); }
	/**
	 * The device constantly monitors the output voltage, and if it exceeds the `overVoltage` value, it will disconnect the input from the output.
	 * 
	 * *   This functionality is critical for protecting power supplies from regenerated voltage coming from motors. Many power supplies assume that a higher than output expected voltage is related to an internal failure to the power supply, and will permanently disable themselves to protect the system. A typical safe value is to set OverVoltage to 1-2 volts higher than the output voltage of the supply. For instance, a 12V supply would be protected by setting OverVoltage to 13V.
	 * *   The device will connect the input to the output again when the voltage drops to (`overVoltage` - 1V)
	 * @throws {@link PhidgetError}
	 */
	get overVoltage() { return this.getOverVoltage(); }
	/**
	 * The minimum value that `overVoltage` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minOverVoltage() { return this.getMinOverVoltage(); }
	/**
	 * The maximum value that `overVoltage` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxOverVoltage() { return this.getMaxOverVoltage(); }
	/**
	 * When `powerEnabled` is true, the device will connect the input to the output and begin monitoring.
	 * 
	 * *   The output voltage is constantly monitored and will be automatically disconnected from the input when the output exceeds the `overVoltage` value.
	 * *   `powerEnabled` allows the device to operate as a Solid State Relay, powering on or off all devices connected to the output.
	 * @throws {@link PhidgetError}
	 */
	get powerEnabled() { return this.getPowerEnabled(); }

	/**
	 * Enables the **failsafe** feature for the channel, with a given **failsafe time**.
	 * 
	 * The **failsafe** feature is intended for use in applications where it is important for the channel to enter a known _safe state_ if the program controlling it locks up or crashes. If you do not enable the failsafe feature, the channel will carry out whatever instructions it was last given until it is explicitly told to stop.
	 * 
	 * Enabling the failsafe feature starts a recurring **failsafe timer** for the channel. Once the failsafe timer is enabled, it must be reset within the specified time or the channel will enter a **failsafe state**. The failsafe timer may be reset by sending any valid command to the device\*. Resetting the failsafe timer will reload the timer with the specified _failsafe time_, starting when the message to reset the timer is received by the Phidget.
	 * 
	 * _\*(**get** requests do not typically send commands and won't reset the failsafe timer)_
	 * 
	 * For example: if the failsafe is enabled with a **failsafe time** of 1000ms, you will have 1000ms to reset the failsafe timer. Every time the failsafe timer is reset, you will have 1000ms from that time to reset the failsafe again.
	 * 
	 * If the failsafe timer is not reset before it runs out, the channel will enter a **failsafe state**. For Power Guard channels, this will turn off the output. Once the channel enters the **failsafe state**, it will reject any further input until the channel is reopened.
	 * 
	 * To prevent the channel from falsely entering the failsafe state, we recommend resetting the failsafe timer as frequently as is practical for your application. A good rule of thumb is to not let more than a third of the failsafe time pass before resetting the timer.
	 * 
	 * Once the failsafe timer has been set, it cannot be disabled by any means other than closing and reopening the channel.
	 * @throws {@link PhidgetError}
	 * @param failsafeTime - Failsafe timeout in milliseconds
	 */
	async enableFailsafe(failsafeTime: number): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: failsafeTime });
		await bp.send(this._ch, BP.SETFAILSAFETIME);
	}

	/**
	 * The minimum value that `failsafeTime` can be set to when calling `enableFailsafe()`.
	 * @returns The failsafe time
	 * @throws {@link PhidgetError}
	 */
	getMinFailsafeTime(): number {
		this._assertOpen();

		if (this.data.minFailsafeTime === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minFailsafeTime);
	}

	/**
	 * The maximum value that `failsafeTime` can be set to when calling `enableFailsafe()`.
	 * @returns The failsafe time
	 * @throws {@link PhidgetError}
	 */
	getMaxFailsafeTime(): number {
		this._assertOpen();

		if (this.data.maxFailsafeTime === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxFailsafeTime);
	}

	/**
	 * The `fanMode` dictates the operating condition of the fan.
	 * 
	 * *   Choose between on, off, or automatic (based on temperature).
	 * *   If the `fanMode` is set to automatic, the fan will turn on when the temperature reaches 70°C and it will remain on until the temperature falls below 55°C.
	 * *   If the `fanMode` is off, the device will still turn on the fan if the temperature reaches 85°C and it will remain on until it falls below 70°C.
	 * @returns The fan mode value
	 * @throws {@link PhidgetError}
	 */
	getFanMode(): Enum.FanMode {
		this._assertOpen();

		if (this.data.fanMode === PUNK.ENUM)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.fanMode);
	}

	/**
	 * The `fanMode` dictates the operating condition of the fan.
	 * 
	 * *   Choose between on, off, or automatic (based on temperature).
	 * *   If the `fanMode` is set to automatic, the fan will turn on when the temperature reaches 70°C and it will remain on until the temperature falls below 55°C.
	 * *   If the `fanMode` is off, the device will still turn on the fan if the temperature reaches 85°C and it will remain on until it falls below 70°C.
	 * @throws {@link PhidgetError}
	 * @param fanMode - The fan mode value
	 */
	async setFanMode(fanMode: Enum.FanMode): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (!SEnum.supportedFanMode(this._ch!, fanMode))
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Specified FanMode is unsupported by this device.");

		bp.set({ name: "0", type: "d", value: fanMode });
		await bp.send(this._ch, BP.SETFANMODE);
	}

	/**
	 * The device constantly monitors the output voltage, and if it exceeds the `overVoltage` value, it will disconnect the input from the output.
	 * 
	 * *   This functionality is critical for protecting power supplies from regenerated voltage coming from motors. Many power supplies assume that a higher than output expected voltage is related to an internal failure to the power supply, and will permanently disable themselves to protect the system. A typical safe value is to set OverVoltage to 1-2 volts higher than the output voltage of the supply. For instance, a 12V supply would be protected by setting OverVoltage to 13V.
	 * *   The device will connect the input to the output again when the voltage drops to (`overVoltage` - 1V)
	 * @returns The voltage value
	 * @throws {@link PhidgetError}
	 */
	getOverVoltage(): number {
		this._assertOpen();

		if (this.data.overVoltage === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.overVoltage);
	}

	/**
	 * The device constantly monitors the output voltage, and if it exceeds the `overVoltage` value, it will disconnect the input from the output.
	 * 
	 * *   This functionality is critical for protecting power supplies from regenerated voltage coming from motors. Many power supplies assume that a higher than output expected voltage is related to an internal failure to the power supply, and will permanently disable themselves to protect the system. A typical safe value is to set OverVoltage to 1-2 volts higher than the output voltage of the supply. For instance, a 12V supply would be protected by setting OverVoltage to 13V.
	 * *   The device will connect the input to the output again when the voltage drops to (`overVoltage` - 1V)
	 * @throws {@link PhidgetError}
	 * @param overVoltage - The voltage value
	 */
	async setOverVoltage(overVoltage: number): Promise<void> {
		this._assertOpen();

		if (overVoltage < this.data.minOverVoltage || overVoltage > this.data.maxOverVoltage)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minOverVoltage + " - " + this.data.maxOverVoltage + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: overVoltage });
		await bp.send(this._ch, BP.SETOVERVOLTAGE);
	}

	/**
	 * The minimum value that `overVoltage` can be set to.
	 * @returns The voltage value
	 * @throws {@link PhidgetError}
	 */
	getMinOverVoltage(): number {
		this._assertOpen();

		if (this.data.minOverVoltage === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minOverVoltage);
	}

	/**
	 * The maximum value that `overVoltage` can be set to.
	 * @returns The voltage value
	 * @throws {@link PhidgetError}
	 */
	getMaxOverVoltage(): number {
		this._assertOpen();

		if (this.data.maxOverVoltage === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxOverVoltage);
	}

	/**
	 * When `powerEnabled` is true, the device will connect the input to the output and begin monitoring.
	 * 
	 * *   The output voltage is constantly monitored and will be automatically disconnected from the input when the output exceeds the `overVoltage` value.
	 * *   `powerEnabled` allows the device to operate as a Solid State Relay, powering on or off all devices connected to the output.
	 * @returns The power enabled value.
	 * @throws {@link PhidgetError}
	 */
	getPowerEnabled(): boolean {
		this._assertOpen();

		if (this.data.powerEnabled === PUNK.BOOL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (!!this.data.powerEnabled);
	}

	/**
	 * When `powerEnabled` is true, the device will connect the input to the output and begin monitoring.
	 * 
	 * *   The output voltage is constantly monitored and will be automatically disconnected from the input when the output exceeds the `overVoltage` value.
	 * *   `powerEnabled` allows the device to operate as a Solid State Relay, powering on or off all devices connected to the output.
	 * @throws {@link PhidgetError}
	 * @param powerEnabled - The power enabled value.
	 */
	async setPowerEnabled(powerEnabled: boolean): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (powerEnabled !== false && powerEnabled !== true)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be a boolean.");

		bp.set({ name: "0", type: "d", value: (powerEnabled ? 1 : 0) });
		await bp.send(this._ch, BP.SETENABLED);
	}

	/**
	 * Resets the failsafe timer, if one has been set. See `enableFailsafe()` for details.
	 * 
	 * This function will fail if no failsafe timer has been set for the channel.
	 * @throws {@link PhidgetError}
	 */
	async resetFailsafe(): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		await bp.send(this._ch, BP.FAILSAFERESET);
	}

}
export { PowerGuardBase };
