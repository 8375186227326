/** @internal */
export const DeviceClassName = {
	0: 'PhidgetNone',
	1: 'PhidgetAccelerometer',
	2: 'PhidgetAdvancedServo',
	3: 'PhidgetAnalog',
	4: 'PhidgetBridge',
	25: 'PhidgetDataAdapter',
	24: 'PhidgetDictionary',
	5: 'PhidgetEncoder',
	23: 'PhidgetFirmwareUpgrade',
	6: 'PhidgetFrequencyCounter',
	22: 'PhidgetGeneric',
	7: 'PhidgetGPS',
	8: 'PhidgetHub',
	9: 'PhidgetInterfaceKit',
	10: 'PhidgetIR',
	11: 'PhidgetLED',
	12: 'PhidgetMeshDongle',
	13: 'PhidgetMotorControl',
	14: 'PhidgetPHSensor',
	15: 'PhidgetRFID',
	16: 'PhidgetServo',
	17: 'PhidgetSpatial',
	18: 'PhidgetStepper',
	19: 'PhidgetTemperatureSensor',
	20: 'PhidgetTextLCD',
	21: 'PhidgetVINT',
}

