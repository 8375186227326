/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PhidgetChannel } from '../Phidget';
import { Channel } from '../Channel';
import { ErrorCode, ChannelClass } from '../Enumerations.gen';
import { PhidgetError } from '../PhidgetError';
import { BridgePacket, PUNK } from '../BridgePacket';
import { BP } from '../BridgePackets.gen';
import { logEventException } from '../Logging';
import { DeviceChannelUID } from '../Devices.gen';

/** @internal */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LightSensorData {
	dataInterval: number,
	maxDataInterval: number,
	minDataRate: number,
	maxDataRate: number,
	maxIlluminance: number,
	maxIlluminanceChangeTrigger: number,
	minDataInterval: number,
	minIlluminance: number,
	minIlluminanceChangeTrigger: number,
	illuminance: number,
	illuminanceChangeTrigger: number,
}

abstract class LightSensorBase extends PhidgetChannel {
	/** @internal */
	data: LightSensorData;
	/**
	 * **IlluminanceChange** event
	 *  * `illuminance` - The current illuminance
	 * ---
	 * The most recent illuminance value the channel has measured will be reported in this event, which occurs when the `dataInterval` has elapsed.
	 * 
	 * *   If a `illuminanceChangeTrigger` has been set to a non-zero value, the `IlluminanceChange` event will not occur until the illuminance has changed by at least the `illuminanceChangeTrigger` value.
	 */
	onIlluminanceChange: ((illuminance: number) => void) | null = null;
	/** @internal */
	_gotIlluminanceChangeErrorEvent?: boolean;

	/**
	 * The Light Sensor class gathers data from the light sensor on a Phidget board.
	 * 
	 * If you're using a simple 0-5V sensor that does not have its own firmware, use the VoltageInput or VoltageRatioInput class instead, as specified for your device.
	 * @public
	 */
	constructor();
	/** @internal */
	constructor(ch?: Channel);
	constructor(ch?: Channel) {
		super(ch);
		this._class = ChannelClass.LIGHT_SENSOR;
		this.name = "LightSensor";
		this.data = this._initData();
	}

	/** @internal */
	_bridgeInput(bp: BridgePacket) {

		switch(bp.vpkt) {
		case BP.SETDATAINTERVAL:
			if (bp.entryCount > 1)
				this.data.dataInterval = bp.entries[1].v as number;
			else
				this.data.dataInterval = bp.entries[0].v as number;
			this._FIREPropertyChange('DataInterval', bp);
			this._FIREPropertyChange('DataRate', bp);
			break;
		case BP.SETCHANGETRIGGER:
			this.data.illuminanceChangeTrigger = bp.entries[0].v as number;
			this._FIREPropertyChange('IlluminanceChangeTrigger', bp);
			break;
		case BP.ILLUMINANCECHANGE: {
			this.data.illuminance = bp.entries[0].v as number;
			if (this._isAttachedDone && this.onIlluminanceChange) {
				try {
					this.onIlluminanceChange(this.data.illuminance);
				} catch (err) { logEventException(err); }
			}
			break;
		}
		default:
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			throw new PhidgetError(ErrorCode.INVALID_PACKET, "Unsupported bridge packet: 0x" + bp.vpkt!.toString(16));
		}
	}

	/** @internal */
	_initData(): LightSensorData {
		return {
			dataInterval: PUNK.DBL,
			maxDataInterval: PUNK.UINT32,
			minDataRate: PUNK.DBL,
			maxDataRate: PUNK.DBL,
			maxIlluminance: PUNK.DBL,
			maxIlluminanceChangeTrigger: PUNK.DBL,
			minDataInterval: PUNK.UINT32,
			minIlluminance: PUNK.DBL,
			minIlluminanceChangeTrigger: PUNK.DBL,
			illuminance: PUNK.DBL,
			illuminanceChangeTrigger: PUNK.DBL,
		}
	}

	/** @internal */
	_initAfterOpen() {
		this.data = this._initData();

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._LUX1000_LIGHTSENSOR_100:
			this.data.dataInterval = 250;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 8;
			this.data.maxIlluminance = 131072;
			this.data.maxIlluminanceChangeTrigger = 131072;
			this.data.minDataInterval = 125;
			this.data.minIlluminance = 0;
			this.data.minIlluminanceChangeTrigger = 0;
			this.data.illuminanceChangeTrigger = 0;
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	// eslint-disable-next-line require-await
	async _setDefaults() {
		let bp;

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._LUX1000_LIGHTSENSOR_100:
			bp = new BridgePacket();
			bp.set({ name: "0", type: "u", value: Math.round(this.data.dataInterval) });
			await bp.send(this._ch, BP.SETDATAINTERVAL);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.illuminanceChangeTrigger });
			await bp.send(this._ch, BP.SETCHANGETRIGGER);
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	_hasInitialState() {

		if ((this.data.illuminance == PUNK.DBL)
			&& ! this._gotIlluminanceChangeErrorEvent)
			return false;

		return true;
	}

	/** @internal */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	_fireInitialEvents() {

		if(this.data.illuminance != PUNK.DBL)
			if (this.onIlluminanceChange)
				try {
					this.onIlluminanceChange(this.data.illuminance);
				} catch (err) { logEventException(err); }

	}

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `IlluminanceChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * *   The timing between `IlluminanceChange` events can also be affected by the `illuminanceChangeTrigger`.
	 * @throws {@link PhidgetError}
	 */
	get dataInterval() { return this.getDataInterval(); }
	/**
	 * The minimum value that `dataInterval` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDataInterval() { return this.getMinDataInterval(); }
	/**
	 * The maximum value that `dataInterval` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDataInterval() { return this.getMaxDataInterval(); }
	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @throws {@link PhidgetError}
	 */
	get dataRate() { return this.getDataRate(); }
	/**
	 * The minimum value that `dataRate` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDataRate() { return this.getMinDataRate(); }
	/**
	 * The maximum value that `dataRate` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDataRate() { return this.getMaxDataRate(); }
	/**
	 * The most recent illuminance value that the channel has reported.
	 * 
	 * *   This value will always be between `minIlluminance` and `maxIlluminance`.
	 * @throws {@link PhidgetError}
	 */
	get illuminance() { return this.getIlluminance(); }
	/**
	 * The minimum value the `IlluminanceChange` event will report.
	 * @throws {@link PhidgetError}
	 */
	get minIlluminance() { return this.getMinIlluminance(); }
	/**
	 * The maximum value the `IlluminanceChange` event will report.
	 * @throws {@link PhidgetError}
	 */
	get maxIlluminance() { return this.getMaxIlluminance(); }
	/**
	 * The channel will not issue a `IlluminanceChange` event until the illuminance value has changed by the amount specified by the `illuminanceChangeTrigger`.
	 * 
	 * *   Setting the `illuminanceChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering
	 * @throws {@link PhidgetError}
	 */
	get illuminanceChangeTrigger() { return this.getIlluminanceChangeTrigger(); }
	/**
	 * The minimum value that `illuminanceChangeTrigger` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minIlluminanceChangeTrigger() { return this.getMinIlluminanceChangeTrigger(); }
	/**
	 * The maximum value that `illuminanceChangeTrigger` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxIlluminanceChangeTrigger() { return this.getMaxIlluminanceChangeTrigger(); }

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `IlluminanceChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * *   The timing between `IlluminanceChange` events can also be affected by the `illuminanceChangeTrigger`.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getDataInterval(): number {
		this._assertOpen();

		if (this.data.dataInterval === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return this.data.dataInterval;
	}

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `IlluminanceChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * *   The timing between `IlluminanceChange` events can also be affected by the `illuminanceChangeTrigger`.
	 * @throws {@link PhidgetError}
	 * @param dataInterval - The data interval value
	 */
	async setDataInterval(dataInterval: number): Promise<void> {
		this._assertOpen();

		if (dataInterval < this.data.minDataInterval || dataInterval > this.data.maxDataInterval)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDataInterval + " - " + this.data.maxDataInterval + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: dataInterval });
		await bp.send(this._ch, BP.SETDATAINTERVAL);
	}

	/**
	 * The minimum value that `dataInterval` can be set to.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getMinDataInterval(): number {
		this._assertOpen();

		if (this.data.minDataInterval === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDataInterval);
	}

	/**
	 * The maximum value that `dataInterval` can be set to.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getMaxDataInterval(): number {
		this._assertOpen();

		if (this.data.maxDataInterval === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDataInterval);
	}

	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @returns The data rate for the channel
	 * @throws {@link PhidgetError}
	 */
	getDataRate(): number {
		this._assertOpen();

		if (this.data.dataInterval === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (1000.0 / this.data.dataInterval);
	}

	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @throws {@link PhidgetError}
	 * @param dataRate - The data rate for the channel
	 */
	async setDataRate(dataRate: number): Promise<void> {
		this._assertOpen();

		if (dataRate < this.data.minDataRate || dataRate > this.data.maxDataRate)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDataRate + " - " + this.data.maxDataRate + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: Math.round(1000.0 / dataRate) });
		bp.set({ name: "1", type: "g", value: (1000.0 / dataRate) });
		await bp.send(this._ch, BP.SETDATAINTERVAL);
	}

	/**
	 * The minimum value that `dataRate` can be set to.
	 * @returns The data rate value
	 * @throws {@link PhidgetError}
	 */
	getMinDataRate(): number {
		this._assertOpen();

		if (this.data.minDataRate === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDataRate);
	}

	/**
	 * The maximum value that `dataRate` can be set to.
	 * @returns The data rate value
	 * @throws {@link PhidgetError}
	 */
	getMaxDataRate(): number {
		this._assertOpen();

		if (this.data.maxDataRate === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDataRate);
	}

	/**
	 * The most recent illuminance value that the channel has reported.
	 * 
	 * *   This value will always be between `minIlluminance` and `maxIlluminance`.
	 * @returns The illuminance value
	 * @throws {@link PhidgetError}
	 */
	getIlluminance(): number {
		this._assertOpen();

		if (this.data.illuminance === PUNK.DBL || Number.isNaN(this.data.illuminance))
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);
		if (this.data.illuminance > this.data.maxIlluminance)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE_HIGH);
		if (this.data.illuminance < this.data.minIlluminance)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE_LOW);

		return (this.data.illuminance);
	}

	/**
	 * The minimum value the `IlluminanceChange` event will report.
	 * @returns The illuminance value
	 * @throws {@link PhidgetError}
	 */
	getMinIlluminance(): number {
		this._assertOpen();

		if (this.data.minIlluminance === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minIlluminance);
	}

	/**
	 * The maximum value the `IlluminanceChange` event will report.
	 * @returns The illuminance value
	 * @throws {@link PhidgetError}
	 */
	getMaxIlluminance(): number {
		this._assertOpen();

		if (this.data.maxIlluminance === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxIlluminance);
	}

	/**
	 * The channel will not issue a `IlluminanceChange` event until the illuminance value has changed by the amount specified by the `illuminanceChangeTrigger`.
	 * 
	 * *   Setting the `illuminanceChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering
	 * @returns The change trigger value
	 * @throws {@link PhidgetError}
	 */
	getIlluminanceChangeTrigger(): number {
		this._assertOpen();

		if (this.data.illuminanceChangeTrigger === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.illuminanceChangeTrigger);
	}

	/**
	 * The channel will not issue a `IlluminanceChange` event until the illuminance value has changed by the amount specified by the `illuminanceChangeTrigger`.
	 * 
	 * *   Setting the `illuminanceChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering
	 * @throws {@link PhidgetError}
	 * @param illuminanceChangeTrigger - The change trigger value
	 */
	async setIlluminanceChangeTrigger(illuminanceChangeTrigger: number): Promise<void> {
		this._assertOpen();

		if (illuminanceChangeTrigger < this.data.minIlluminanceChangeTrigger || illuminanceChangeTrigger > this.data.maxIlluminanceChangeTrigger)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minIlluminanceChangeTrigger + " - " + this.data.maxIlluminanceChangeTrigger + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: illuminanceChangeTrigger });
		await bp.send(this._ch, BP.SETCHANGETRIGGER);
	}

	/**
	 * The minimum value that `illuminanceChangeTrigger` can be set to.
	 * @returns The change trigger value
	 * @throws {@link PhidgetError}
	 */
	getMinIlluminanceChangeTrigger(): number {
		this._assertOpen();

		if (this.data.minIlluminanceChangeTrigger === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minIlluminanceChangeTrigger);
	}

	/**
	 * The maximum value that `illuminanceChangeTrigger` can be set to.
	 * @returns The change trigger value
	 * @throws {@link PhidgetError}
	 */
	getMaxIlluminanceChangeTrigger(): number {
		this._assertOpen();

		if (this.data.maxIlluminanceChangeTrigger === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxIlluminanceChangeTrigger);
	}

}
export { LightSensorBase };
