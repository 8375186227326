/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PhidgetChannel } from '../Phidget';
import { Channel } from '../Channel';
import { ErrorCode, ChannelClass } from '../Enumerations.gen';
import * as Enum from '../Enumerations.gen';
import * as SEnum from '../SupportedEnum.gen';
import { PhidgetError } from '../PhidgetError';
import { BridgePacket } from '../BridgePacket';
import { BP } from '../BridgePackets.gen';
import { DeviceChannelUID } from '../Devices.gen';

/** @internal */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface HubData {
}

abstract class HubBase extends PhidgetChannel {
	/** @internal */
	data: HubData;

	/**
	 * The hub class allows you to control power to VINT hub ports.
	 * @public
	 */
	constructor();
	/** @internal */
	constructor(ch?: Channel);
	constructor(ch?: Channel) {
		super(ch);
		this._class = ChannelClass.HUB;
		this.name = "Hub";
		this.data = this._initData();
	}

	/** @internal */
	_bridgeInput(bp: BridgePacket) {

		switch(bp.vpkt) {
		case BP.SETFIRMWAREUPGRADEFLAG:
			break;
		case BP.SETPORTAUTOSETSPEED:
			break;
		case BP.SETPORTMODE:
			break;
		case BP.SETPORTPOWER:
			break;
		default:
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			throw new PhidgetError(ErrorCode.INVALID_PACKET, "Unsupported bridge packet: 0x" + bp.vpkt!.toString(16));
		}
	}

	/** @internal */
	_initData(): HubData {
		return {
		}
	}

	/** @internal */
	_initAfterOpen() {
		this.data = this._initData();

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._HUB0000_HUB_300:
		case DeviceChannelUID._HUB0000_HUB_400:
		case DeviceChannelUID._HUB0001_HUB_100:
		case DeviceChannelUID._HUB0001_HUB_115:
		case DeviceChannelUID._HUB0002_HUB_100:
		case DeviceChannelUID._HUB0007_HUB_100:
		case DeviceChannelUID._HUB5000_HUB_200:
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	// eslint-disable-next-line require-await
	async _setDefaults() {

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._HUB0000_HUB_300:
		case DeviceChannelUID._HUB0000_HUB_400:
		case DeviceChannelUID._HUB0001_HUB_100:
		case DeviceChannelUID._HUB0001_HUB_115:
		case DeviceChannelUID._HUB0002_HUB_100:
		case DeviceChannelUID._HUB0007_HUB_100:
		case DeviceChannelUID._HUB5000_HUB_200:
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	_hasInitialState() {


		return true;
	}

	/** @internal */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	_fireInitialEvents() {

	}


	/**
	 * Sets a flag on this hub port which forces the next VINT devices plugged in to stay in firmware upgrade mode.
	 * @throws {@link PhidgetError}
	 * @param port - The port the device is plugged into
	 * @param timeout - The time to leave the flag set for
	 * @internal
	 */
	async setFirmwareUpgradeFlag(port: number, timeout: number): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "d", value: port });
		bp.set({ name: "1", type: "u", value: timeout });
		await bp.send(this._ch, BP.SETFIRMWAREUPGRADEFLAG);
	}

	/**
	 * Enables / disables Auto Set Speed on the hub port. When enabled, and a supported VINT device is attached, the **HubPortSpeed** will automatically be set to the fastest reliable speed. This is enabled by default on supported VINT Hubs.
	 * @throws {@link PhidgetError}
	 * @param port - The Hub port
	 * @param state - The AutoSetSpeed state
	 */
	async setPortAutoSetSpeed(port: number, state: boolean): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "d", value: port });

		if (state !== false && state !== true)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be a boolean.");

		bp.set({ name: "1", type: "d", value: (state ? 1 : 0) });
		await bp.send(this._ch, BP.SETPORTAUTOSETSPEED);
	}

	/**
	 * The max communication speed of a high-speed capable VINT Port.
	 * @returns The max speed
	 * @throws {@link PhidgetError}
	 * @param port - The Hub port
	 */
	abstract getPortMaxSpeed(port: number): number;
	/**
	 * Gets the mode of the selected hub port. VINT devices will not show up when the port is in digital/analog mode.
	 * @returns The mode the port is in
	 * @throws {@link PhidgetError}
	 * @param port - The port being read
	 */
	abstract getPortMode(port: number): Promise<Enum.HubPortMode>;
	/**
	 * Sets the mode of the selected port. This could be used to set a port back to VINT mode if it was left in digital/analog mode.
	 * @throws {@link PhidgetError}
	 * @param port - The port being set
	 * @param mode - The mode the port is being set to
	 */
	async setPortMode(port: number, mode: Enum.HubPortMode): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "d", value: port });

		if (!SEnum.supportedHubPortMode(this._ch!, mode))
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Specified HubPortMode is unsupported by this device.");

		bp.set({ name: "1", type: "d", value: mode });
		await bp.send(this._ch, BP.SETPORTMODE);
	}

	/**
	 * Gets the VINT Hub Port power state
	 * @returns The power state
	 * @throws {@link PhidgetError}
	 * @param port - The Hub port
	 */
	abstract getPortPower(port: number): Promise<boolean>;
	/**
	 * Controls power to the VINT Hub Port.
	 * @throws {@link PhidgetError}
	 * @param port - The Hub port
	 * @param state - The power state
	 */
	async setPortPower(port: number, state: boolean): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "d", value: port });

		if (state !== false && state !== true)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be a boolean.");

		bp.set({ name: "1", type: "d", value: (state ? 1 : 0) });
		await bp.send(this._ch, BP.SETPORTPOWER);
	}

	/**
	 * Indicates that this VINT Port support Auto Set Speed.
	 * @returns The supported state
	 * @throws {@link PhidgetError}
	 * @param port - The Hub port
	 */
	abstract getPortSupportsAutoSetSpeed(port: number): boolean;
	/**
	 * Indicates that the communication speed of this VINT port can be set.
	 * @returns The supported state
	 * @throws {@link PhidgetError}
	 * @param port - The Hub port
	 */
	abstract getPortSupportsSetSpeed(port: number): boolean;
}
export { HubBase };
