import { CurrentInputBase } from './CurrentInput.gen';
import { BridgePacket, PUNK } from '../BridgePacket';
import { ErrorEventCode } from '../Enumerations.gen';
import { BP } from '../BridgePackets.gen';
import * as Enum from '../Enumerations.gen';

/** @public */
class CurrentInput extends CurrentInputBase {

	/** @internal */
	_errorHandler(code: ErrorEventCode) {
		switch (code) {
			case ErrorEventCode.SATURATION:
				this.data.current = PUNK.DBL;
				this._gotCurrentChangeErrorEvent = true;
				break;
		}
	}

	/** @internal */
	_bridgeInput(bp: BridgePacket) {
		switch (bp.vpkt) {
			case BP.POWERSUPPLYCHANGE:
				this.data.powerSupply = bp.entries[0].v as Enum.PowerSupply;
				this._FIREPropertyChange('PowerSupply', bp);
				break;
			default:
				super._bridgeInput(bp);
				break;
		}
	}
}

export { CurrentInput };