import { ChannelClass, ChannelSubclass, DeviceClass, DeviceID } from "./Enumerations.gen";
import { BP } from "./BridgePackets.gen";

/** @internal */
export const enum DeviceUID {
	_NOTHING = 0,
	_IFKIT488 = 1,
	_1000_OLD1 = 2,
	_1000_OLD2 = 3,
	_1000_NO_ECHO = 4,
	_1000 = 5,
	_1001_OLD1 = 6,
	_1001_OLD2 = 7,
	_1001_NO_ECHO = 8,
	_1001 = 9,
	_1002 = 10,
	_1008 = 11,
	_1011 = 12,
	_1012_NO_ECHO = 13,
	_1012_BITBUG = 14,
	_1012 = 15,
	_1013_NO_ECHO = 16,
	_1013 = 17,
	_1014_NO_ECHO = 18,
	_1014 = 19,
	_1014_3_USB = 20,
	_1014_3_VINT = 21,
	_1015 = 22,
	_1016 = 23,
	_1017 = 24,
	_1017_2_USB = 25,
	_1017_2_VINT = 26,
	_1018 = 27,
	_1018_3 = 28,
	_1023_OLD = 29,
	_1023 = 30,
	_1023_2OUTPUT_NO_ECHO = 31,
	_1023_2OUTPUT = 32,
	_1024 = 33,
	_1024_V2_USB_OBSOLETE = 34, // Unreleased
	_1024_V2_VINT_OBSOLETE = 35, // Unreleased
	_1024_V2_USB = 36, // Unreleased
	_1024_V2_VINT = 37, // Unreleased
	_1030 = 38,
	_1031 = 39,
	_1032 = 40,
	_1040 = 41,
	_1041 = 42,
	_1042 = 43,
	_1043 = 44,
	_1044 = 45,
	_1044_1 = 46,
	_1044_1_510 = 47,
	_1045 = 48,
	_1046_GAINBUG = 49,
	_1046 = 50,
	_1046_1 = 51,
	_1047 = 52,
	_1047_2 = 53,
	_1047_2_300 = 54,
	_1048 = 55,
	_1048_1_2 = 56,
	_1049 = 57,
	_1051_OLD = 58,
	_1051 = 59,
	_1051_AD22100 = 60,
	_1051_TERMINAL_BLOCKS = 61,
	_1052_OLD = 62,
	_1052_v1 = 63,
	_1052_v2 = 64,
	_1053 = 65,
	_1054 = 66,
	_1055 = 67,
	_1055_1_USB = 68,
	_1055_1_VINT = 69,
	_1056 = 70,
	_1056_NEG_GAIN = 71,
	_1057 = 72,
	_1057_3 = 73,
	_1058 = 74,
	_1059 = 75,
	_1060 = 76,
	_1061 = 77,
	_1061_PGOOD_FLAG = 78,
	_1061_CURSENSE_FIX = 79,
	_RCC0004 = 80,
	_1062 = 81,
	_1063 = 82,
	_1064 = 83,
	_1065 = 84,
	_1066 = 85,
	_1067 = 86,
	_1067_1 = 87,
	_1202_IFKIT_NO_ECHO = 88,
	_1202_IFKIT = 89,
	_1202_TEXTLCD = 90,
	_1202_IFKIT_FAST = 91,
	_1202_TEXTLCD_BRIGHTNESS = 92,
	_1204 = 93,
	_1215 = 94,
	_1219 = 95,
	_DIGITALINPUT_PORT = 96,
	_DIGITALOUTPUT_PORT = 97,
	_DIGITALOUTPUT_PORT_FAILSAFE = 98,
	_VOLTAGEINPUT_PORT = 99,
	_VOLTAGEINPUT_PORT_5V25 = 100,
	_VOLTAGEINPUT_PORT_V2 = 101,
	_VOLTAGERATIOINPUT_PORT = 102,
	_VOLTAGERATIOINPUT_PORT_V2 = 103,
	_ADP1000 = 104,
	_ADP1001_USB = 105, // Unreleased
	_ADP1001_VINT = 106, // Unreleased
	_ADP_RS485_422_USB = 107, // Unreleased
	_ADP_RS485_422_VINT = 108, // Unreleased
	_ADP_SERIAL_USB = 109, // Unreleased
	_ADP_SERIAL_VINT = 110, // Unreleased
	_DAQ1000 = 111,
	_DAQ1000_5V25 = 112,
	_OUT1000 = 113,
	_OUT1000_Failsafe = 114,
	_OUT1001 = 115,
	_OUT1001_Failsafe = 116,
	_OUT1002 = 117,
	_OUT1002_Failsafe = 118,
	_CURLOOP_OBSOLETE = 119, // Unreleased
	_CURLOOP = 120, // Unreleased
	_DAQ1200 = 121,
	_OUT1100 = 122,
	_OUT1100_Failsafe = 123,
	_OUT1100_Failsafe_Frequency = 124,
	_DAQ1300 = 125,
	_DAQ1301 = 126,
	_DAQ1400 = 127,
	_DAQ1400_120 = 128,
	_DAQ1500 = 129,
	_DAQ1501 = 130, // Unreleased
	_VCP1100 = 131,
	_VCP1100_1_OLDPROTO = 132, // Unreleased
	_DCC1000 = 133,
	_DCC1000_POSITIONCONTROL = 134,
	_DCC1000_POSITIONCONTROL_FAILSAFE = 135,
	_DCC1005_OBSOLETE = 136, // Unreleased
	_DCC1010 = 137, // Unreleased
	_DCC1020_OBSOLETE = 138, // Unreleased
	_DCC1020 = 139, // Unreleased
	_DCC1030 = 140, // Unreleased
	_DCC1001 = 141,
	_DCC1001_Failsafe = 142,
	_DCC1002 = 143,
	_DCC1002_Failsafe = 144,
	_DCC1003 = 145,
	_DCC1003_Failsafe = 146,
	_DCC1100 = 147,
	_DCC1100_Failsafe = 148,
	_DCC1110 = 149, // Unreleased
	_DCC1120 = 150, // Unreleased
	_DCC1130 = 151, // Unreleased
	_DST1000 = 152,
	_DST1001 = 153,
	_DST1002 = 154,
	_DST1200 = 155,
	_ENC1000 = 156,
	_ENC1001 = 157,
	_HIN1101 = 158,
	_HIN1000 = 159,
	_HIN1001 = 160,
	_HIN1100 = 161,
	_HUM1000 = 162,
	_HUM1001 = 163,
	_LCD1100 = 164,
	_LCD1100_1 = 165,
	_LED1000 = 166,
	_LUX1000 = 167,
	_HUM1100 = 168,
	_VINTACCEL = 169, // Unreleased
	_MOT1100_OLD = 170, // Unreleased
	_MOT1100 = 171,
	_MOT0100_USB = 172,
	_MOT0100_VINT = 173,
	_MOT1101 = 174,
	_MOT1102 = 175,
	_MOT1102_1 = 176,
	_MOT0108 = 177, // Unreleased
	_MOT0109 = 178,
	_MOT0110_USB = 179,
	_MOT0110_VINT = 180,
	_PRE1000 = 181,
	_RCC1000 = 182,
	_RCC1000_Failsafe = 183,
	_RCC1000_1 = 184,
	_REL1000 = 185,
	_REL1000_Failsafe = 186,
	_REL1100 = 187,
	_REL1100_Failsafe = 188,
	_REL1100_Failsafe_Frequency = 189,
	_REL1101 = 190,
	_REL1101_Failsafe = 191,
	_REL1101_1 = 192,
	_TP_DIAGNOSER_0 = 193, // Unreleased
	_SAF1000 = 194,
	_SAF1000_Failsafe = 195,
	_SND1000 = 196,
	_STC1000 = 197,
	_STC1000_Failsafe = 198,
	_STC1001 = 199,
	_STC1001_Failsafe = 200,
	_STC1002 = 201,
	_STC1002_Failsafe = 202,
	_STC1003 = 203,
	_STC1003_Failsafe = 204,
	_STC1003_1 = 205,
	_STC1005_0 = 206,
	_STC1005_0_110 = 207,
	_STC1004_0 = 208, // Unreleased
	_TMP1000 = 209,
	_TMP1100 = 210,
	_TMP1101 = 211,
	_TMP1101_1 = 212,
	_TMP1200 = 213,
	_TMP1200_1 = 214,
	_TMP1200_2 = 215,
	_TMP1300_OBSOLETE = 216, // Unreleased
	_TMP1300 = 217, // Unreleased
	_VCP1000 = 218,
	_VCP1001 = 219,
	_VCP1001_110 = 220,
	_VCP1002 = 221,
	_VCP1002_110 = 222,
	_HUB0000 = 223,
	_HUB0001_OLDPROTO = 224, // Unreleased
	_HUB0000_PHIDUSB = 225,
	_HUB0000_1 = 226,
	_HUB0001 = 227,
	_HUB0001_AUTOSETSPEED = 228,
	_HUB0002 = 229,
	_HUB0007 = 230,
	_MESHHUB = 231, // Unreleased
	_MESHDONGLE = 232, // Unreleased
	_HUB0004 = 233,
	_HUB0004_BADPORT = 234,
	_LIGHTNINGHUB = 235, // Unreleased
	_HUB5000 = 236,
	_HUB5000_PHIDUSB = 237,
	_FIRMWARE_UPGRADE_M3_USB = 238,
	_FIRMWARE_UPGRADE_STM32F_USB = 239,
	_FIRMWARE_UPGRADE_STM32G_USB = 240,
	_FIRMWARE_UPGRADE_STM32L_USB = 241,
	_FIRMWARE_UPGRADE_STM32F0 = 242,
	_FIRMWARE_UPGRADE_STM32G0_BLBug = 243,
	_FIRMWARE_UPGRADE_STM32G0 = 244,
	_FIRMWARE_UPGRADE_STM32F3 = 245,
	_FIRMWARE_UPGRADE_STM8S = 246,
	_FIRMWARE_UPGRADE_M3_SPI = 247,
	_GENERIC_HIDUSB = 248, // Unreleased
	_GENERIC_PHIDUSB = 249, // Unreleased
	_USBSWITCH = 250, // Unreleased
	_GENERICVINT = 251, // Unreleased
	_DICTIONARY = 252,
	_UNKNOWNUSB = 253,
	_UNKNOWNVINT = 254,
	_UNKNOWNSPI = 255,
	_TEXTLED_4x8 = 256, // Unreleased
	_TEXTLED_1x8 = 257, // Unreleased
	_INTERFACEKIT_2_8_8 = 258, // Unreleased
	_POWER = 259, // Unreleased
	_INTERFACEKIT_0_5_7_LCD = 260, // Unreleased
	_INTERFACEKIT_0_32_32 = 261, // Unreleased
	_WEIGHTSENSOR = 262, // Unreleased
}

/** @internal */
export const enum DeviceChannelUID {
	_NOTHING = 0,
	_ifkit488_VOLTAGERATIOINPUT_000 = 1,
	_ifkit488_DIGITALINPUT_000 = 2,
	_ifkit488_DIGITALOUTPUT_000 = 3,
	_1000_RCSERVO_OLD1_200 = 4,
	_1000_RCSERVO_OLD2_200 = 5,
	_1000_RCSERVO_300 = 6,
	_1000_RCSERVO_313 = 7,
	_1001_RCSERVO_OLD1_200 = 8,
	_1001_RCSERVO_OLD2_200 = 9,
	_1001_RCSERVO_313 = 10,
	_1001_RCSERVO_400 = 11,
	_1002_VOLTAGEOUTPUT_100 = 12,
	_1008_ACCELEROMETER_000 = 13,
	_1011_VOLTAGEINPUT_000 = 14,
	_1011_VOLTAGERATIOINPUT_000 = 15,
	_1011_DIGITALINPUT_000 = 16,
	_1011_DIGITALOUTPUT_000 = 17,
	_1012_DIGITALINPUT_000 = 18,
	_1012_DIGITALOUTPUT_000 = 19,
	_1012_DIGITALINPUT_601 = 20,
	_1012_DIGITALOUTPUT_601 = 21,
	_1012_DIGITALINPUT_602 = 22,
	_1012_DIGITALOUTPUT_602 = 23,
	_1013_VOLTAGEINPUT_000 = 24,
	_1013_VOLTAGERATIOINPUT_000 = 25,
	_1013_DIGITALINPUT_000 = 26,
	_1013_DIGITALOUTPUT_000 = 27,
	_1018_VOLTAGEINPUT_821 = 28,
	_1018_VOLTAGERATIOINPUT_821 = 29,
	_1018_DIGITALINPUT_821 = 30,
	_1018_DIGITALOUTPUT_821 = 31,
	_1014_DIGITALOUTPUT_000 = 32,
	_1014_DIGITALOUTPUT_704 = 33,
	_1014_DIGITALOUTPUT_800_USB = 34,
	_1014_DIGITALOUTPUT_800_VINT = 35,
	_1015_CAPACITIVETOUCH_000 = 36,
	_1016_CAPACITIVETOUCH_000 = 37,
	_1017_DIGITALOUTPUT_000 = 38,
	_1017_DIGITALOUTPUT_200_USB = 39,
	_1017_DIGITALOUTPUT_200_VINT = 40,
	_1018_VOLTAGEINPUT_900 = 41,
	_1018_VOLTAGERATIOINPUT_900 = 42,
	_1018_DIGITALINPUT_900 = 43,
	_1018_DIGITALOUTPUT_900 = 44,
	_1018_VOLTAGEINPUT_1000 = 45,
	_1018_VOLTAGERATIOINPUT_1000 = 46,
	_1018_DIGITALINPUT_1000 = 47,
	_1018_DIGITALOUTPUT_1000 = 48,
	_1023_RFID_000 = 49,
	_1023_RFID_104 = 50,
	_1023_RFID_200 = 51,
	_1023_DIGITALOUTPUT_5V_200 = 52,
	_1023_DIGITALOUTPUT_LED_200 = 53,
	_1023_DIGITALOUTPUT_ONBOARD_LED_200 = 54,
	_1023_RFID_201 = 55,
	_1023_DIGITALOUTPUT_5V_201 = 56,
	_1023_DIGITALOUTPUT_LED_201 = 57,
	_1023_DIGITALOUTPUT_ONBOARD_LED_201 = 58,
	_1024_RFID_100 = 59,
	_1024_DIGITALOUTPUT_5V_100 = 60,
	_1024_DIGITALOUTPUT_LED_100 = 61,
	_1024_DIGITALOUTPUT_ONBOARD_LED_100 = 62,
	_1024_V2_USB_OBSOLETE_200 = 63,
	_1024_V2_VINT_OBSOLETE_200 = 64,
	_1024_RFID_200_USB = 65,
	_1024_DIGITALOUTPUT_5V_200_USB = 66,
	_1024_DIGITALOUTPUT_LED_200_USB = 67,
	_1024_DIGITALOUTPUT_ONBOARD_LED_200_USB = 68,
	_1024_RFID_200_VINT = 69,
	_1024_DIGITALOUTPUT_5V_200_VINT = 70,
	_1024_DIGITALOUTPUT_LED_200_VINT = 71,
	_1024_DIGITALOUTPUT_ONBOARD_LED_200_VINT = 72,
	_1030_DIGITALOUTPUT_100 = 73,
	_1031_DIGITALOUTPUT_100 = 74,
	_1032_DIGITALOUTPUT_200 = 75,
	_1040_GPS_000 = 76,
	_1041_ACCELEROMETER_200 = 77,
	_1042_ACCELEROMETER_300 = 78,
	_1042_GYROSCOPE_300 = 79,
	_1042_MAGNETOMETER_300 = 80,
	_1042_SPATIAL_300 = 81,
	_1043_ACCELEROMETER_300 = 82,
	_1044_ACCELEROMETER_400 = 83,
	_1044_GYROSCOPE_400 = 84,
	_1044_MAGNETOMETER_400 = 85,
	_1044_SPATIAL_400 = 86,
	_1044_ACCELEROMETER_500 = 87,
	_1044_GYROSCOPE_500 = 88,
	_1044_MAGNETOMETER_500 = 89,
	_1044_SPATIAL_500 = 90,
	_1044_ACCELEROMETER_510 = 91,
	_1044_GYROSCOPE_510 = 92,
	_1044_MAGNETOMETER_510 = 93,
	_1044_SPATIAL_510 = 94,
	_1045_TEMPERATURESENSOR_IR_100 = 95,
	_1045_TEMPERATURESENSOR_IC_100 = 96,
	_1046_VOLTAGERATIOINPUT_100 = 97,
	_1046_VOLTAGERATIOINPUT_102 = 98,
	_1046_VOLTAGERATIOINPUT_200 = 99,
	_1047_ENCODER_100 = 100,
	_1047_DIGITALINPUT_100 = 101,
	_1047_ENCODER_200 = 102,
	_1047_DIGITALINPUT_200 = 103,
	_1047_ENCODER_300 = 104,
	_1047_DIGITALINPUT_300 = 105,
	_1048_TEMPERATURESENSOR_THERMOCOUPLE_100 = 106,
	_1048_TEMPERATURESENSOR_IC_100 = 107,
	_1048_VOLTAGEINPUT_100 = 108,
	_1048_TEMPERATURESENSOR_THERMOCOUPLE_200 = 109,
	_1048_TEMPERATURESENSOR_IC_200 = 110,
	_1048_VOLTAGEINPUT_200 = 111,
	_1049_ACCELEROMETER_000 = 112,
	_1051_TEMPERATURESENSOR_THERMOCOUPLE_000 = 113,
	_1051_TEMPERATURESENSOR_IC_000 = 114,
	_1051_TEMPERATURESENSOR_THERMOCOUPLE_200 = 115,
	_1051_TEMPERATURESENSOR_IC_200 = 116,
	_1051_VOLTAGEINPUT_200 = 117,
	_1051_TEMPERATURESENSOR_THERMOCOUPLE_300 = 118,
	_1051_TEMPERATURESENSOR_IC_300 = 119,
	_1051_VOLTAGEINPUT_300 = 120,
	_1051_TEMPERATURESENSOR_THERMOCOUPLE_400 = 121,
	_1051_TEMPERATURESENSOR_IC_400 = 122,
	_1051_VOLTAGEINPUT_400 = 123,
	_1052_ENCODER_000 = 124,
	_1052_DIGITALINPUT_000 = 125,
	_1052_ENCODER_101 = 126,
	_1052_DIGITALINPUT_101 = 127,
	_1052_ENCODER_110 = 128,
	_1052_DIGITALINPUT_110 = 129,
	_1053_ACCELEROMETER_300 = 130,
	_1054_FREQUENCYCOUNTER_000 = 131,
	_1055_IR_100 = 132,
	_1055_IR_200_USB = 133,
	_1055_IR_200_VINT = 134,
	_1056_ACCELEROMETER_000 = 135,
	_1056_GYROSCOPE_000 = 136,
	_1056_MAGNETOMETER_000 = 137,
	_1056_SPATIAL_000 = 138,
	_1056_ACCELEROMETER_200 = 139,
	_1056_GYROSCOPE_200 = 140,
	_1056_MAGNETOMETER_200 = 141,
	_1056_SPATIAL_200 = 142,
	_1057_ENCODER_300 = 143,
	_1057_ENCODER_400 = 144,
	_1058_VOLTAGEINPUT_100 = 145,
	_1058_PHADAPTER_100 = 146,
	_1059_ACCELEROMETER_400 = 147,
	_1060_DCMOTOR_100 = 148,
	_1060_DIGITALINPUT_100 = 149,
	_1061_RCSERVO_100 = 150,
	_1061_CURRENTINPUT_100 = 151,
	_1061_RCSERVO_200 = 152,
	_1061_CURRENTINPUT_200 = 153,
	_1061_RCSERVO_300 = 154,
	_1061_CURRENTINPUT_300 = 155,
	_RCC0004_RCSERVO_400 = 156,
	_1062_STEPPER_100 = 157,
	_1063_STEPPER_100 = 158,
	_1063_DIGITALINPUT_100 = 159,
	_1063_CURRENTINPUT_100 = 160,
	_1064_DCMOTOR_100 = 161,
	_1064_CURRENTINPUT_100 = 162,
	_1065_DCMOTOR_100 = 163,
	_1065_DIGITALINPUT_100 = 164,
	_1065_ENCODER_100 = 165,
	_1065_VOLTAGEINPUT_100 = 166,
	_1065_VOLTAGEINPUT_SUPPLY_100 = 167,
	_1065_VOLTAGERATIOINPUT_100 = 168,
	_1065_CURRENTINPUT_100 = 169,
	_1066_RCSERVO_100 = 170,
	_1066_CURRENTINPUT_100 = 171,
	_1067_STEPPER_200 = 172,
	_1067_STEPPER_300 = 173,
	_1202_VOLTAGEINPUT_000 = 174,
	_1202_VOLTAGERATIOINPUT_000 = 175,
	_1202_DIGITALINPUT_000 = 176,
	_1202_DIGITALOUTPUT_000 = 177,
	_1202_VOLTAGEINPUT_120 = 178,
	_1202_VOLTAGERATIOINPUT_120 = 179,
	_1202_DIGITALINPUT_120 = 180,
	_1202_DIGITALOUTPUT_120 = 181,
	_1202_TEXTLCD_000 = 182,
	_1202_VOLTAGEINPUT_300 = 183,
	_1202_VOLTAGERATIOINPUT_300 = 184,
	_1202_DIGITALINPUT_300 = 185,
	_1202_DIGITALOUTPUT_300 = 186,
	_1202_TEXTLCD_200 = 187,
	_1204_TEXTLCD_000 = 188,
	_1215_TEXTLCD_000 = 189,
	_1219_TEXTLCD_000 = 190,
	_1219_DIGITALINPUT_000 = 191,
	_1219_DIGITALOUTPUT_000 = 192,
	_HUB_DIGITALINPUT_100 = 193,
	_HUB_DIGITALOUTPUT_100 = 194,
	_HUB_DIGITALOUTPUT_110 = 195,
	_HUB_VOLTAGEINPUT_100 = 196,
	_HUB_VOLTAGEINPUT_110 = 197,
	_HUB_VOLTAGEINPUT_200 = 198,
	_HUB_VOLTAGERATIOINPUT_100 = 199,
	_HUB_VOLTAGERATIOINPUT_200 = 200,
	_ADP1000_PHSENSOR_100 = 201,
	_ADP1000_VOLTAGEINPUT_100 = 202,
	_ADP1001_DATAADAPTER_100_USB = 203,
	_ADP1001_DIGITALINPUT_DSR_100_USB = 204,
	_ADP1001_DIGITALINPUT_DCD_100_USB = 205,
	_ADP1001_DIGITALINPUT_RI_100_USB = 206,
	_ADP1001_DIGITALOUTPUT_DTR_100_USB = 207,
	_ADP1001_DATAADAPTER_100_VINT = 208,
	_ADP1001_DIGITALINPUT_DSR_100_VINT = 209,
	_ADP1001_DIGITALINPUT_DCD_100_VINT = 210,
	_ADP1001_DIGITALINPUT_RI_100_VINT = 211,
	_ADP1001_DIGITALOUTPUT_DTR_100_VINT = 212,
	_ADP_RS485_422_DATAADAPTER_100_USB = 213,
	_ADP_RS485_422_DATAADAPTER_100_VINT = 214,
	_ADP_SERIAL_DATAADAPTER_100_USB = 215,
	_ADP_SERIAL_DIGITALINPUT_100_USB = 216,
	_ADP_SERIAL_DIGITALOUTPUT_100_USB = 217,
	_ADP_SERIAL_DATAADAPTER_100_VINT = 218,
	_ADP_SERIAL_DIGITALINPUT_100_VINT = 219,
	_ADP_SERIAL_DIGITALOUTPUT_100_VINT = 220,
	_DAQ1000_VOLTAGERATIOINPUT_100 = 221,
	_DAQ1000_VOLTAGEINPUT_100 = 222,
	_DAQ1000_VOLTAGERATIOINPUT_110 = 223,
	_DAQ1000_VOLTAGEINPUT_110 = 224,
	_OUT1000_VOLTAGEOUTPUT_100 = 225,
	_OUT1000_VOLTAGEOUTPUT_110 = 226,
	_OUT1001_VOLTAGEOUTPUT_100 = 227,
	_OUT1001_VOLTAGEOUTPUT_110 = 228,
	_OUT1002_VOLTAGEOUTPUT_100 = 229,
	_OUT1002_VOLTAGEOUTPUT_110 = 230,
	_CURLOOP_CURRENTOUTPUT_100 = 231,
	_DAQ1200_DIGITALINPUT_100 = 232,
	_OUT1100_DIGITALOUTPUT_100 = 233,
	_OUT1100_DIGITALOUTPUT_110 = 234,
	_OUT1100_DIGITALOUTPUT_120 = 235,
	_DAQ1300_DIGITALINPUT_100 = 236,
	_DAQ1301_DIGITALINPUT_100 = 237,
	_DAQ1400_VOLTAGEINPUT_100 = 238,
	_DAQ1400_CURRENTINPUT_100 = 239,
	_DAQ1400_DIGITALINPUT_100 = 240,
	_DAQ1400_FREQUENCYCOUNTER_100 = 241,
	_DAQ1400_VOLTAGEINPUT_120 = 242,
	_DAQ1400_CURRENTINPUT_120 = 243,
	_DAQ1400_DIGITALINPUT_120 = 244,
	_DAQ1400_FREQUENCYCOUNTER_120 = 245,
	_DAQ1500_VOLTAGERATIOINPUT_100 = 246,
	_DAQ1501_VOLTAGERATIOINPUT_100 = 247,
	_VCP1100_CURRENTINPUT_100 = 248,
	_DCC1000_DCMOTOR_100 = 249,
	_DCC1000_ENCODER_100 = 250,
	_DCC1000_VOLTAGERATIOINPUT_100 = 251,
	_DCC1000_TEMPERATURESENSOR_IC_100 = 252,
	_DCC1000_CURRENTINPUT_100 = 253,
	_DCC1000_DCMOTOR_200 = 254,
	_DCC1000_ENCODER_200 = 255,
	_DCC1000_VOLTAGERATIOINPUT_200 = 256,
	_DCC1000_TEMPERATURESENSOR_IC_200 = 257,
	_DCC1000_CURRENTINPUT_200 = 258,
	_DCC1000_MOTORPOSITIONCONTROLLER_200 = 259,
	_DCC1000_DCMOTOR_210 = 260,
	_DCC1000_ENCODER_210 = 261,
	_DCC1000_VOLTAGERATIOINPUT_210 = 262,
	_DCC1000_TEMPERATURESENSOR_IC_210 = 263,
	_DCC1000_CURRENTINPUT_210 = 264,
	_DCC1000_MOTORPOSITIONCONTROLLER_210 = 265,
	_DCC1010_ENCODER_100 = 266,
	_DCC1010_DCMOTOR_100 = 267,
	_DCC1010_TEMPERATURESENSOR_IC_100 = 268,
	_DCC1010_CURRENTINPUT_100 = 269,
	_DCC1010_VOLTAGEINPUT_100 = 270,
	_DCC1010_MOTORPOSITIONCONTROLLER_100 = 271,
	_DCC1010_MOTORVELOCITYCONTROLLER_100 = 272,
	_DCC1020_OBSOLETE_100 = 273,
	_DCC1020_ENCODER_100 = 274,
	_DCC1020_DCMOTOR_100 = 275,
	_DCC1020_TEMPERATURESENSOR_IC_100 = 276,
	_DCC1020_CURRENTINPUT_100 = 277,
	_DCC1020_VOLTAGEINPUT_100 = 278,
	_DCC1020_MOTORPOSITIONCONTROLLER_100 = 279,
	_DCC1020_MOTORVELOCITYCONTROLLER_100 = 280,
	_DCC1030_ENCODER_100 = 281,
	_DCC1030_DCMOTOR_100 = 282,
	_DCC1030_TEMPERATURESENSOR_IC_100 = 283,
	_DCC1030_CURRENTINPUT_100 = 284,
	_DCC1030_VOLTAGEINPUT_100 = 285,
	_DCC1030_MOTORPOSITIONCONTROLLER_100 = 286,
	_DCC1030_MOTORVELOCITYCONTROLLER_100 = 287,
	_DCC1001_DCMOTOR_100 = 288,
	_DCC1001_ENCODER_100 = 289,
	_DCC1001_MOTORPOSITIONCONTROLLER_100 = 290,
	_DCC1001_DCMOTOR_120 = 291,
	_DCC1001_ENCODER_120 = 292,
	_DCC1001_MOTORPOSITIONCONTROLLER_120 = 293,
	_DCC1002_DCMOTOR_100 = 294,
	_DCC1002_ENCODER_100 = 295,
	_DCC1002_MOTORPOSITIONCONTROLLER_100 = 296,
	_DCC1002_DCMOTOR_110 = 297,
	_DCC1002_ENCODER_110 = 298,
	_DCC1002_MOTORPOSITIONCONTROLLER_110 = 299,
	_DCC1003_DCMOTOR_100 = 300,
	_DCC1003_DCMOTOR_110 = 301,
	_DCC1100_BLDCMOTOR_100 = 302,
	_DCC1100_TEMPERATURESENSOR_IC_100 = 303,
	_DCC1100_MOTORPOSITIONCONTROLLER_100 = 304,
	_DCC1100_BLDCMOTOR_120 = 305,
	_DCC1100_TEMPERATURESENSOR_IC_120 = 306,
	_DCC1100_MOTORPOSITIONCONTROLLER_120 = 307,
	_DCC1110_ENCODER_100 = 308,
	_DCC1110_BLDCMOTOR_100 = 309,
	_DCC1110_TEMPERATURESENSOR_IC_100 = 310,
	_DCC1110_CURRENTINPUT_100 = 311,
	_DCC1110_VOLTAGEINPUT_100 = 312,
	_DCC1110_MOTORPOSITIONCONTROLLER_100 = 313,
	_DCC1110_MOTORVELOCITYCONTROLLER_100 = 314,
	_DCC1120_ENCODER_100 = 315,
	_DCC1120_BLDCMOTOR_100 = 316,
	_DCC1120_TEMPERATURESENSOR_IC_100 = 317,
	_DCC1120_CURRENTINPUT_100 = 318,
	_DCC1120_VOLTAGEINPUT_100 = 319,
	_DCC1120_MOTORPOSITIONCONTROLLER_100 = 320,
	_DCC1120_MOTORVELOCITYCONTROLLER_100 = 321,
	_DCC1130_ENCODER_100 = 322,
	_DCC1130_BLDCMOTOR_100 = 323,
	_DCC1130_TEMPERATURESENSOR_IC_100 = 324,
	_DCC1130_CURRENTINPUT_100 = 325,
	_DCC1130_VOLTAGEINPUT_100 = 326,
	_DCC1130_MOTORPOSITIONCONTROLLER_100 = 327,
	_DCC1130_MOTORVELOCITYCONTROLLER_100 = 328,
	_DST1000_DISTANCESENSOR_100 = 329,
	_DST1001_DISTANCESENSOR_100 = 330,
	_DST1002_DISTANCESENSOR_100 = 331,
	_DST1200_DISTANCESENSOR_100 = 332,
	_ENC1000_ENCODER_100 = 333,
	_ENC1001_ENCODER_100 = 334,
	_HIN1101_ENCODER_100 = 335,
	_HIN1101_DIGITALINPUT_100 = 336,
	_HIN1000_CAPACITIVETOUCH_100 = 337,
	_HIN1001_CAPACITIVETOUCH_BUTTONS_100 = 338,
	_HIN1001_CAPACITIVETOUCH_WHEEL_100 = 339,
	_HIN1100_VOLTAGERATIOINPUT_100 = 340,
	_HIN1100_DIGITALINPUT_100 = 341,
	_HUM1000_HUMIDITYSENSOR_100 = 342,
	_HUM1000_TEMPERATURESENSOR_IC_100 = 343,
	_HUM1001_HUMIDITYSENSOR_100 = 344,
	_HUM1001_TEMPERATURESENSOR_IC_100 = 345,
	_LCD1100_LCD_100 = 346,
	_LCD1100_LCD_200 = 347,
	_LED1000_DIGITALOUTPUT_100 = 348,
	_LUX1000_LIGHTSENSOR_100 = 349,
	_HUM1100_VOLTAGERATIOINPUT_100 = 350,
	_MOT1100_ACCELEROMETER_200 = 351,
	_MOT0100_ACCELEROMETER_100_USB = 352,
	_MOT0100_ACCELEROMETER_100_VINT = 353,
	_MOT1101_ACCELEROMETER_100 = 354,
	_MOT1101_GYROSCOPE_100 = 355,
	_MOT1101_MAGNETOMETER_100 = 356,
	_MOT1101_SPATIAL_100 = 357,
	_MOT1102_ACCELEROMETER_200 = 358,
	_MOT1102_GYROSCOPE_200 = 359,
	_MOT1102_MAGNETOMETER_200 = 360,
	_MOT1102_SPATIAL_200 = 361,
	_MOT1102_ACCELEROMETER_300 = 362,
	_MOT1102_GYROSCOPE_300 = 363,
	_MOT1102_MAGNETOMETER_300 = 364,
	_MOT1102_SPATIAL_300 = 365,
	_MOT0108_ACCELEROMETER_100 = 366,
	_MOT0108_GYROSCOPE_100 = 367,
	_MOT0108_MAGNETOMETER_100 = 368,
	_MOT0108_SPATIAL_100 = 369,
	_MOT0108_TEMPERATURESENSOR_100 = 370,
	_MOT0109_ACCELEROMETER_100 = 371,
	_MOT0109_GYROSCOPE_100 = 372,
	_MOT0109_MAGNETOMETER_100 = 373,
	_MOT0109_SPATIAL_100 = 374,
	_MOT0109_TEMPERATURESENSOR_100 = 375,
	_MOT0110_ACCELEROMETER_100_USB = 376,
	_MOT0110_GYROSCOPE_100_USB = 377,
	_MOT0110_MAGNETOMETER_100_USB = 378,
	_MOT0110_SPATIAL_100_USB = 379,
	_MOT0110_TEMPERATURESENSOR_100_USB = 380,
	_MOT0110_ACCELEROMETER_100_VINT = 381,
	_MOT0110_GYROSCOPE_100_VINT = 382,
	_MOT0110_MAGNETOMETER_100_VINT = 383,
	_MOT0110_SPATIAL_100_VINT = 384,
	_MOT0110_TEMPERATURESENSOR_100_VINT = 385,
	_PRE1000_PRESSURESENSOR_100 = 386,
	_RCC1000_RCSERVO_100 = 387,
	_RCC1000_RCSERVO_110 = 388,
	_RCC1000_RCSERVO_200 = 389,
	_REL1000_DIGITALOUTPUT_100 = 390,
	_REL1000_DIGITALOUTPUT_110 = 391,
	_REL1100_DIGITALOUTPUT_100 = 392,
	_REL1100_DIGITALOUTPUT_110 = 393,
	_REL1100_DIGITALOUTPUT_120 = 394,
	_REL1101_DIGITALOUTPUT_100 = 395,
	_REL1101_DIGITALOUTPUT_110 = 396,
	_REL1101_DIGITALOUTPUT_FREQ_200 = 397,
	_REL1101_DIGITALOUTPUT_200 = 398,
	_TP_DIAGNOSER_RESISTANCEINPUT_100 = 399,
	_SAF1000_POWERGUARD_100 = 400,
	_SAF1000_VOLTAGEINPUT_100 = 401,
	_SAF1000_TEMPERATURESENSOR_IC_100 = 402,
	_SAF1000_POWERGUARD_110 = 403,
	_SAF1000_VOLTAGEINPUT_110 = 404,
	_SAF1000_TEMPERATURESENSOR_IC_110 = 405,
	_SND1000_SOUNDSENSOR_100 = 406,
	_STC1000_STEPPER_100 = 407,
	_STC1000_STEPPER_110 = 408,
	_STC1001_STEPPER_100 = 409,
	_STC1001_STEPPER_110 = 410,
	_STC1002_STEPPER_100 = 411,
	_STC1002_STEPPER_110 = 412,
	_STC1003_STEPPER_100 = 413,
	_STC1003_STEPPER_110 = 414,
	_STC1003_STEPPER_200 = 415,
	_STC1003_VOLTAGEINPUT_200 = 416,
	_STC1005_STEPPER_100 = 417,
	_STC1005_VOLTAGEINPUT_100 = 418,
	_STC1005_STEPPER_110 = 419,
	_STC1005_VOLTAGEINPUT_110 = 420,
	_STC1004_STEPPER_100 = 421,
	_TMP1000_TEMPERATURESENSOR_IC_100 = 422,
	_TMP1100_TEMPERATURESENSOR_THERMOCOUPLE_100 = 423,
	_TMP1100_TEMPERATURESENSOR_IC_100 = 424,
	_TMP1100_VOLTAGEINPUT_100 = 425,
	_TMP1101_TEMPERATURESENSOR_THERMOCOUPLE_100 = 426,
	_TMP1101_TEMPERATURESENSOR_IC_100 = 427,
	_TMP1101_VOLTAGEINPUT_100 = 428,
	_TMP1101_TEMPERATURESENSOR_THERMOCOUPLE_200 = 429,
	_TMP1101_TEMPERATURESENSOR_IC_200 = 430,
	_TMP1101_VOLTAGEINPUT_200 = 431,
	_TMP1200_TEMPERATURESENSOR_RTD_100 = 432,
	_TMP1200_RESISTANCEINPUT_100 = 433,
	_TMP1200_TEMPERATURESENSOR_RTD_300 = 434,
	_TMP1200_RESISTANCEINPUT_300 = 435,
	_TMP1200_TEMPERATURESENSOR_RTD_400 = 436,
	_TMP1200_RESISTANCEINPUT_400 = 437,
	_TMP1300_TEMPERATURESENSOR_IR_100 = 438,
	_TMP1300_TEMPERATURESENSOR_IC_100 = 439,
	_TMP1300_VOLTAGEINPUT_100 = 440,
	_VCP1000_VOLTAGEINPUT_100 = 441,
	_VCP1001_VOLTAGEINPUT_100 = 442,
	_VCP1001_VOLTAGEINPUT_110 = 443,
	_VCP1002_VOLTAGEINPUT_100 = 444,
	_VCP1002_VOLTAGEINPUT_110 = 445,
	_HUB0000_HUB_100 = 446,
	_HUB0000_HUB_300 = 447,
	_HUB0000_HUB_400 = 448,
	_HUB0001_HUB_100 = 449,
	_HUB0001_HUB_115 = 450,
	_HUB0002_HUB_100 = 451,
	_HUB0007_HUB_100 = 452,
	_MESHHUB_HUB_100 = 453,
	_MESHDONGLE_MESHDONGLE_100 = 454,
	_HUB0004_HUB_100 = 455,
	_HUB0004_HUB_200 = 456,
	_LIGHTNINGHUB_HUB_100 = 457,
	_HUB5000_HUB_100 = 458,
	_HUB5000_HUB_200 = 459,
	_M3_USB_FIRMWARE_UPGRADE_000 = 460,
	_STM32_USB_FIRMWARE_UPGRADE_100 = 461,
	_STM32_USB_FIRMWARE_UPGRADE_200 = 462,
	_STM32_USB_FIRMWARE_UPGRADE_300 = 463,
	_STM32F0_FIRMWARE_UPGRADE_100 = 464,
	_STM32G0_FIRMWARE_UPGRADE_110 = 465,
	_STM32G0_FIRMWARE_UPGRADE_114 = 466,
	_STM32F3_FIRMWARE_UPGRADE_120 = 467,
	_STM8S_FIRMWARE_UPGRADE_100 = 468,
	_M3_SPI_FIRMWARE_UPGRADE_000 = 469,
	_HIDUSB_GENERIC = 470,
	_PHIDUSB_GENERIC = 471,
	_USBSWITCH_DIGITALOUTPUT_IN1_100 = 472,
	_USBSWITCH_DIGITALOUTPUT_IN2_100 = 473,
	_USBSWITCH_DIGITALOUTPUT_IN3_100 = 474,
	_USBSWITCH_DIGITALOUTPUT_IN4_100 = 475,
	_USBSWITCH_DIGITALOUTPUT_A1_100 = 476,
	_USBSWITCH_DIGITALOUTPUT_A2_100 = 477,
	_USBSWITCH_DIGITALOUTPUT_A3_100 = 478,
	_USBSWITCH_DIGITALOUTPUT_A4_100 = 479,
	_VINT_GENERIC = 480,
	_DICTIONARY = 481,
	_USB_UNKNOWN = 482,
	_VINT_UNKNOWN = 483,
	_SPI_UNKNOWN = 484,
}

/** @internal */
export interface PhidgetUniqueDevice {
	uid?: DeviceUID, 		/* Device UID - Only in full gen */
	d?: DeviceID, 			/* Device ID - Only in full gen */
	c: DeviceClass,			/* Device Class */
	t?: string, 			/* Device Name - Only in full gen */
	s: string,				/* Device SKU */
	i: number,				/* Hardware ID */
	v: [number, number],	/* Version Range */
	cn?: number[]			/* USB Device Channel Counts - Only in full gen */
	n?: number,				/* USB Interface - Default is 0 */
	ch: PhidgetUniqueChannel[]
}

/** @internal */
export interface PhidgetUniqueChannel {
	uid?: DeviceChannelUID,	/* Channel UID - Only in full gen */
	t?: string, 			/* Channel Name - Only in full gen */
	c?: ChannelClass,		/* Channel Class - Only in full gen */
	s?: ChannelSubclass,	/* Channel Subclass - Default is NONE */
	n?: number,				/* Channel Count - Default is 1 */
	i?: number, 			/* Channel Index - Only in full gen */
	e?: number, 			/* Exclusive access indicator - Default is none */
	p?: BP[], 				/* Supported Bridge Packets - Only in full gen */
}

interface PhidgetUniqueDevices {
	VINT: PhidgetUniqueDevice[],
	USB: PhidgetUniqueDevice[],
	MESH: PhidgetUniqueDevice[],
	SPI: PhidgetUniqueDevice[],
	LIGHTNING: PhidgetUniqueDevice[],
	VIRTUAL: PhidgetUniqueDevice[],
}

/** @internal */
export const PhidgetDevices: PhidgetUniqueDevices = {
	VINT: [
		{
			uid: DeviceUID._1014_3_VINT,
			d: 9 /* DeviceID.PN_1014 */,
			c: 21 /* DeviceClass.VINT */,
			t: "PhidgetInterfaceKit 0/0/4",
			s: "1014",
			i: 64 /* VintID */,
			v: [800, 900],
			ch: [
				{
					uid: DeviceChannelUID._1014_DIGITALOUTPUT_800_VINT,
					t: "Power Relay",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					n: 4 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETSTATE,
						BP.SETDUTYCYCLE,
						BP.FAILSAFERESET,
						BP.SETFAILSAFETIME,
					]
				},
			]
		},
		{
			uid: DeviceUID._1017_2_VINT,
			d: 12 /* DeviceID.PN_1017 */,
			c: 21 /* DeviceClass.VINT */,
			t: "PhidgetInterfaceKit 0/0/8",
			s: "1017",
			i: 135 /* VintID */,
			v: [200, 300],
			ch: [
				{
					uid: DeviceChannelUID._1017_DIGITALOUTPUT_200_VINT,
					t: "Signal Relay",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETSTATE,
						BP.SETDUTYCYCLE,
						BP.FAILSAFERESET,
						BP.SETFAILSAFETIME,
					]
				},
			]
		},
		{
			uid: DeviceUID._1055_1_VINT,
			d: 32 /* DeviceID.PN_1055 */,
			c: 10 /* DeviceClass.IR */,
			t: "PhidgetIR",
			s: "1055",
			i: 77 /* VintID */,
			v: [200, 300],
			ch: [
				{
					uid: DeviceChannelUID._1055_IR_200_VINT,
					t: "IR Receiver Transmitter",
					c: 16 /* ChannelClass.IR */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.REPEAT,
						BP.TRANSMIT,
						BP.TRANSMITREPEAT,
						BP.TRANSMITRAW,
						BP.CODE,
						BP.LEARN,
						BP.RAWDATA,
					]
				},
			]
		},
		{
			uid: DeviceUID._DIGITALINPUT_PORT,
			d: 95 /* DeviceID.DIGITAL_INPUT_PORT */,
			c: 21 /* DeviceClass.VINT */,
			t: "Hub Port - Digital Input Mode",
			s: "DIGITALINPUT_PORT",
			i: 1 /* VintID */,
			v: [100, 200],
			ch: [
				{
					uid: DeviceChannelUID._HUB_DIGITALINPUT_100,
					t: "Digital Input",
					c: 5 /* ChannelClass.DIGITAL_INPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.STATECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._DIGITALOUTPUT_PORT,
			d: 96 /* DeviceID.DIGITAL_OUTPUT_PORT */,
			c: 21 /* DeviceClass.VINT */,
			t: "Hub Port - Digital Output Mode",
			s: "DIGITALOUTPUT_PORT",
			i: 2 /* VintID */,
			v: [100, 110],
			ch: [
				{
					uid: DeviceChannelUID._HUB_DIGITALOUTPUT_100,
					t: "Digital Output",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					s: 16 /* ChannelSubClass.DIGITAL_OUTPUT_DUTY_CYCLE */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDUTYCYCLE,
						BP.SETSTATE,
					]
				},
			]
		},
		{
			uid: DeviceUID._DIGITALOUTPUT_PORT_FAILSAFE,
			d: 96 /* DeviceID.DIGITAL_OUTPUT_PORT */,
			c: 21 /* DeviceClass.VINT */,
			t: "Hub Port - Digital Output Mode",
			s: "DIGITALOUTPUT_PORT",
			i: 2 /* VintID */,
			v: [110, 200],
			ch: [
				{
					uid: DeviceChannelUID._HUB_DIGITALOUTPUT_110,
					t: "Digital Output",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					s: 16 /* ChannelSubClass.DIGITAL_OUTPUT_DUTY_CYCLE */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDUTYCYCLE,
						BP.SETSTATE,
						BP.FAILSAFERESET,
						BP.SETFAILSAFETIME,
					]
				},
			]
		},
		{
			uid: DeviceUID._VOLTAGEINPUT_PORT,
			d: 97 /* DeviceID.VOLTAGE_INPUT_PORT */,
			c: 21 /* DeviceClass.VINT */,
			t: "Hub Port - Voltage Input Mode",
			s: "VOLTAGEINPUT_PORT",
			i: 3 /* VintID */,
			v: [100, 110],
			ch: [
				{
					uid: DeviceChannelUID._HUB_VOLTAGEINPUT_100,
					t: "Voltage Input",
					c: 29 /* ChannelClass.VOLTAGE_INPUT */,
					s: 48 /* ChannelSubClass.VOLTAGE_INPUT_SENSOR_PORT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETSENSORTYPE,
						BP.SETCHANGETRIGGER,
						BP.SETSENSORVALUECHANGETRIGGER,
						BP.VOLTAGECHANGE,
						BP.SENSORCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._VOLTAGEINPUT_PORT_5V25,
			d: 97 /* DeviceID.VOLTAGE_INPUT_PORT */,
			c: 21 /* DeviceClass.VINT */,
			t: "Hub Port - Voltage Input Mode",
			s: "VOLTAGEINPUT_PORT",
			i: 3 /* VintID */,
			v: [110, 200],
			ch: [
				{
					uid: DeviceChannelUID._HUB_VOLTAGEINPUT_110,
					t: "Voltage Input",
					c: 29 /* ChannelClass.VOLTAGE_INPUT */,
					s: 48 /* ChannelSubClass.VOLTAGE_INPUT_SENSOR_PORT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETSENSORTYPE,
						BP.SETCHANGETRIGGER,
						BP.SETSENSORVALUECHANGETRIGGER,
						BP.VOLTAGECHANGE,
						BP.SENSORCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._VOLTAGEINPUT_PORT_V2,
			d: 97 /* DeviceID.VOLTAGE_INPUT_PORT */,
			c: 21 /* DeviceClass.VINT */,
			t: "Hub Port - Voltage Input Mode",
			s: "VOLTAGEINPUT_PORT",
			i: 3 /* VintID */,
			v: [200, 300],
			ch: [
				{
					uid: DeviceChannelUID._HUB_VOLTAGEINPUT_200,
					t: "Voltage Input",
					c: 29 /* ChannelClass.VOLTAGE_INPUT */,
					s: 48 /* ChannelSubClass.VOLTAGE_INPUT_SENSOR_PORT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETSENSORTYPE,
						BP.SETCHANGETRIGGER,
						BP.SETSENSORVALUECHANGETRIGGER,
						BP.VOLTAGECHANGE,
						BP.SENSORCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._VOLTAGERATIOINPUT_PORT,
			d: 98 /* DeviceID.VOLTAGE_RATIO_INPUT_PORT */,
			c: 21 /* DeviceClass.VINT */,
			t: "Hub Port - Voltage Ratio Mode",
			s: "VOLTAGERATIOINPUT_PORT",
			i: 4 /* VintID */,
			v: [100, 200],
			ch: [
				{
					uid: DeviceChannelUID._HUB_VOLTAGERATIOINPUT_100,
					t: "Voltage Ratio Input",
					c: 31 /* ChannelClass.VOLTAGE_RATIO_INPUT */,
					s: 64 /* ChannelSubClass.VOLTAGE_RATIO_INPUT_SENSOR_PORT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETSENSORTYPE,
						BP.SETCHANGETRIGGER,
						BP.SETSENSORVALUECHANGETRIGGER,
						BP.VOLTAGERATIOCHANGE,
						BP.SENSORCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._VOLTAGERATIOINPUT_PORT_V2,
			d: 98 /* DeviceID.VOLTAGE_RATIO_INPUT_PORT */,
			c: 21 /* DeviceClass.VINT */,
			t: "Hub Port - Voltage Ratio Mode",
			s: "VOLTAGERATIOINPUT_PORT",
			i: 4 /* VintID */,
			v: [200, 300],
			ch: [
				{
					uid: DeviceChannelUID._HUB_VOLTAGERATIOINPUT_200,
					t: "Voltage Ratio Input",
					c: 31 /* ChannelClass.VOLTAGE_RATIO_INPUT */,
					s: 64 /* ChannelSubClass.VOLTAGE_RATIO_INPUT_SENSOR_PORT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETSENSORTYPE,
						BP.SETCHANGETRIGGER,
						BP.SETSENSORVALUECHANGETRIGGER,
						BP.VOLTAGERATIOCHANGE,
						BP.SENSORCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._ADP1000,
			d: 49 /* DeviceID.PN_ADP1000 */,
			c: 21 /* DeviceClass.VINT */,
			t: "pH Adapter Phidget",
			s: "ADP1000",
			i: 29 /* VintID */,
			v: [100, 200],
			ch: [
				{
					uid: DeviceChannelUID._ADP1000_PHSENSOR_100,
					t: "PH Sensor Input",
					c: 37 /* ChannelClass.PH_SENSOR */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETCORRECTIONTEMPERATURE,
						BP.PHCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._ADP1000_VOLTAGEINPUT_100,
					t: "Voltage Input",
					c: 29 /* ChannelClass.VOLTAGE_INPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETSENSORTYPE,
						BP.SETCHANGETRIGGER,
						BP.SETVOLTAGERANGE,
						BP.SETSENSORVALUECHANGETRIGGER,
						BP.VOLTAGECHANGE,
						BP.SENSORCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._DAQ1000,
			d: 51 /* DeviceID.PN_DAQ1000 */,
			c: 21 /* DeviceClass.VINT */,
			t: "8x Voltage Input Phidget",
			s: "DAQ1000",
			i: 50 /* VintID */,
			v: [100, 110],
			ch: [
				{
					uid: DeviceChannelUID._DAQ1000_VOLTAGERATIOINPUT_100,
					t: "Voltage Ratio Input",
					c: 31 /* ChannelClass.VOLTAGE_RATIO_INPUT */,
					s: 64 /* ChannelSubClass.VOLTAGE_RATIO_INPUT_SENSOR_PORT */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETSENSORTYPE,
						BP.SETCHANGETRIGGER,
						BP.SETSENSORVALUECHANGETRIGGER,
						BP.VOLTAGERATIOCHANGE,
						BP.SENSORCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._DAQ1000_VOLTAGEINPUT_100,
					t: "Voltage Input",
					c: 29 /* ChannelClass.VOLTAGE_INPUT */,
					s: 48 /* ChannelSubClass.VOLTAGE_INPUT_SENSOR_PORT */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETSENSORTYPE,
						BP.SETCHANGETRIGGER,
						BP.SETSENSORVALUECHANGETRIGGER,
						BP.VOLTAGECHANGE,
						BP.SENSORCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._DAQ1000_5V25,
			d: 51 /* DeviceID.PN_DAQ1000 */,
			c: 21 /* DeviceClass.VINT */,
			t: "8x Voltage Input Phidget",
			s: "DAQ1000",
			i: 50 /* VintID */,
			v: [110, 200],
			ch: [
				{
					uid: DeviceChannelUID._DAQ1000_VOLTAGERATIOINPUT_110,
					t: "Voltage Ratio Input",
					c: 31 /* ChannelClass.VOLTAGE_RATIO_INPUT */,
					s: 64 /* ChannelSubClass.VOLTAGE_RATIO_INPUT_SENSOR_PORT */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETSENSORTYPE,
						BP.SETCHANGETRIGGER,
						BP.SETSENSORVALUECHANGETRIGGER,
						BP.VOLTAGERATIOCHANGE,
						BP.SENSORCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._DAQ1000_VOLTAGEINPUT_110,
					t: "Voltage Input",
					c: 29 /* ChannelClass.VOLTAGE_INPUT */,
					s: 48 /* ChannelSubClass.VOLTAGE_INPUT_SENSOR_PORT */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETSENSORTYPE,
						BP.SETCHANGETRIGGER,
						BP.SETSENSORVALUECHANGETRIGGER,
						BP.VOLTAGECHANGE,
						BP.SENSORCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._OUT1000,
			d: 75 /* DeviceID.PN_OUT1000 */,
			c: 21 /* DeviceClass.VINT */,
			t: "12-bit Voltage Output Phidget",
			s: "OUT1000",
			i: 41 /* VintID */,
			v: [100, 110],
			ch: [
				{
					uid: DeviceChannelUID._OUT1000_VOLTAGEOUTPUT_100,
					t: "Voltage Output",
					c: 30 /* ChannelClass.VOLTAGE_OUTPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETENABLED,
						BP.SETVOLTAGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._OUT1000_Failsafe,
			d: 75 /* DeviceID.PN_OUT1000 */,
			c: 21 /* DeviceClass.VINT */,
			t: "12-bit Voltage Output Phidget",
			s: "OUT1000",
			i: 41 /* VintID */,
			v: [110, 200],
			ch: [
				{
					uid: DeviceChannelUID._OUT1000_VOLTAGEOUTPUT_110,
					t: "Voltage Output",
					c: 30 /* ChannelClass.VOLTAGE_OUTPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETENABLED,
						BP.SETVOLTAGE,
						BP.FAILSAFERESET,
						BP.SETFAILSAFETIME,
					]
				},
			]
		},
		{
			uid: DeviceUID._OUT1001,
			d: 76 /* DeviceID.PN_OUT1001 */,
			c: 21 /* DeviceClass.VINT */,
			t: "Isolated 12-bit Voltage Output Phidget",
			s: "OUT1001",
			i: 42 /* VintID */,
			v: [100, 110],
			ch: [
				{
					uid: DeviceChannelUID._OUT1001_VOLTAGEOUTPUT_100,
					t: "Voltage Output",
					c: 30 /* ChannelClass.VOLTAGE_OUTPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETVOLTAGE,
						BP.SETVOLTAGERANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._OUT1001_Failsafe,
			d: 76 /* DeviceID.PN_OUT1001 */,
			c: 21 /* DeviceClass.VINT */,
			t: "Isolated 12-bit Voltage Output Phidget",
			s: "OUT1001",
			i: 42 /* VintID */,
			v: [110, 200],
			ch: [
				{
					uid: DeviceChannelUID._OUT1001_VOLTAGEOUTPUT_110,
					t: "Voltage Output",
					c: 30 /* ChannelClass.VOLTAGE_OUTPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETVOLTAGE,
						BP.SETVOLTAGERANGE,
						BP.FAILSAFERESET,
						BP.SETFAILSAFETIME,
					]
				},
			]
		},
		{
			uid: DeviceUID._OUT1002,
			d: 77 /* DeviceID.PN_OUT1002 */,
			c: 21 /* DeviceClass.VINT */,
			t: "Isolated 16-bit Voltage Output Phidget",
			s: "OUT1002",
			i: 43 /* VintID */,
			v: [100, 110],
			ch: [
				{
					uid: DeviceChannelUID._OUT1002_VOLTAGEOUTPUT_100,
					t: "Voltage Output",
					c: 30 /* ChannelClass.VOLTAGE_OUTPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETVOLTAGE,
						BP.SETVOLTAGERANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._OUT1002_Failsafe,
			d: 77 /* DeviceID.PN_OUT1002 */,
			c: 21 /* DeviceClass.VINT */,
			t: "Isolated 16-bit Voltage Output Phidget",
			s: "OUT1002",
			i: 43 /* VintID */,
			v: [110, 200],
			ch: [
				{
					uid: DeviceChannelUID._OUT1002_VOLTAGEOUTPUT_110,
					t: "Voltage Output",
					c: 30 /* ChannelClass.VOLTAGE_OUTPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETVOLTAGE,
						BP.SETVOLTAGERANGE,
						BP.FAILSAFERESET,
						BP.SETFAILSAFETIME,
					]
				},
			]
		},
		{
			uid: DeviceUID._DAQ1200,
			d: 52 /* DeviceID.PN_DAQ1200 */,
			c: 21 /* DeviceClass.VINT */,
			t: "4x Digital Input Phidget",
			s: "DAQ1200",
			i: 28 /* VintID */,
			v: [100, 200],
			ch: [
				{
					uid: DeviceChannelUID._DAQ1200_DIGITALINPUT_100,
					t: "Digital Input",
					c: 5 /* ChannelClass.DIGITAL_INPUT */,
					n: 4 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.STATECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._OUT1100,
			d: 78 /* DeviceID.PN_OUT1100 */,
			c: 21 /* DeviceClass.VINT */,
			t: "4x Digital Output Phidget",
			s: "OUT1100",
			i: 25 /* VintID */,
			v: [100, 110],
			ch: [
				{
					uid: DeviceChannelUID._OUT1100_DIGITALOUTPUT_100,
					t: "Digital Output",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					s: 16 /* ChannelSubClass.DIGITAL_OUTPUT_DUTY_CYCLE */,
					n: 4 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDUTYCYCLE,
						BP.SETSTATE,
					]
				},
			]
		},
		{
			uid: DeviceUID._OUT1100_Failsafe,
			d: 78 /* DeviceID.PN_OUT1100 */,
			c: 21 /* DeviceClass.VINT */,
			t: "4x Digital Output Phidget",
			s: "OUT1100",
			i: 25 /* VintID */,
			v: [110, 120],
			ch: [
				{
					uid: DeviceChannelUID._OUT1100_DIGITALOUTPUT_110,
					t: "Digital Output",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					s: 16 /* ChannelSubClass.DIGITAL_OUTPUT_DUTY_CYCLE */,
					n: 4 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDUTYCYCLE,
						BP.SETSTATE,
						BP.FAILSAFERESET,
						BP.SETFAILSAFETIME,
					]
				},
			]
		},
		{
			uid: DeviceUID._OUT1100_Failsafe_Frequency,
			d: 78 /* DeviceID.PN_OUT1100 */,
			c: 21 /* DeviceClass.VINT */,
			t: "4x Digital Output Phidget",
			s: "OUT1100",
			i: 25 /* VintID */,
			v: [120, 200],
			ch: [
				{
					uid: DeviceChannelUID._OUT1100_DIGITALOUTPUT_120,
					t: "Digital Output",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					s: 18 /* ChannelSubClass.DIGITAL_OUTPUT_FREQUENCY */,
					n: 4 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDUTYCYCLE,
						BP.SETSTATE,
						BP.SETFREQUENCY,
						BP.FAILSAFERESET,
						BP.SETFAILSAFETIME,
					]
				},
			]
		},
		{
			uid: DeviceUID._DAQ1300,
			d: 53 /* DeviceID.PN_DAQ1300 */,
			c: 21 /* DeviceClass.VINT */,
			t: "4x Isolated Digital Input Phidget",
			s: "DAQ1300",
			i: 32 /* VintID */,
			v: [100, 200],
			ch: [
				{
					uid: DeviceChannelUID._DAQ1300_DIGITALINPUT_100,
					t: "Digital Input Isolated",
					c: 5 /* ChannelClass.DIGITAL_INPUT */,
					n: 4 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.STATECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._DAQ1301,
			d: 54 /* DeviceID.PN_DAQ1301 */,
			c: 21 /* DeviceClass.VINT */,
			t: "16x Isolated Digital Input Phidget",
			s: "DAQ1301",
			i: 54 /* VintID */,
			v: [100, 200],
			ch: [
				{
					uid: DeviceChannelUID._DAQ1301_DIGITALINPUT_100,
					t: "Digital Input Isolated",
					c: 5 /* ChannelClass.DIGITAL_INPUT */,
					n: 16 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.STATECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._DAQ1400,
			d: 55 /* DeviceID.PN_DAQ1400 */,
			c: 21 /* DeviceClass.VINT */,
			t: "Versatile Input Phidget",
			s: "DAQ1400",
			i: 34 /* VintID */,
			v: [100, 120],
			ch: [
				{
					uid: DeviceChannelUID._DAQ1400_VOLTAGEINPUT_100,
					t: "Voltage Input",
					c: 29 /* ChannelClass.VOLTAGE_INPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.POWERSUPPLYCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETPOWERSUPPLY,
						BP.SETSENSORTYPE,
						BP.SETCHANGETRIGGER,
						BP.SETSENSORVALUECHANGETRIGGER,
						BP.VOLTAGECHANGE,
						BP.SENSORCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._DAQ1400_CURRENTINPUT_100,
					t: "Current Input",
					c: 2 /* ChannelClass.CURRENT_INPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.POWERSUPPLYCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETPOWERSUPPLY,
						BP.CURRENTCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._DAQ1400_DIGITALINPUT_100,
					t: "Digital Input",
					c: 5 /* ChannelClass.DIGITAL_INPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.POWERSUPPLYCHANGE,
						BP.INPUTMODECHANGE,
						BP.SETINPUTMODE,
						BP.SETPOWERSUPPLY,
						BP.STATECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._DAQ1400_FREQUENCYCOUNTER_100,
					t: "Frequency Input",
					c: 9 /* ChannelClass.FREQUENCY_COUNTER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.POWERSUPPLYCHANGE,
						BP.INPUTMODECHANGE,
						BP.FREQUENCYDATA,
						BP.SETDATAINTERVAL,
						BP.SETINPUTMODE,
						BP.SETPOWERSUPPLY,
						BP.FREQUENCYCHANGE,
						BP.COUNTCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._DAQ1400_120,
			d: 55 /* DeviceID.PN_DAQ1400 */,
			c: 21 /* DeviceClass.VINT */,
			t: "Versatile Input Phidget",
			s: "DAQ1400",
			i: 34 /* VintID */,
			v: [120, 200],
			ch: [
				{
					uid: DeviceChannelUID._DAQ1400_VOLTAGEINPUT_120,
					t: "Voltage Input",
					c: 29 /* ChannelClass.VOLTAGE_INPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.POWERSUPPLYCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETPOWERSUPPLY,
						BP.SETSENSORTYPE,
						BP.SETCHANGETRIGGER,
						BP.SETSENSORVALUECHANGETRIGGER,
						BP.VOLTAGECHANGE,
						BP.SENSORCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._DAQ1400_CURRENTINPUT_120,
					t: "Current Input",
					c: 2 /* ChannelClass.CURRENT_INPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.POWERSUPPLYCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETPOWERSUPPLY,
						BP.CURRENTCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._DAQ1400_DIGITALINPUT_120,
					t: "Digital Input",
					c: 5 /* ChannelClass.DIGITAL_INPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.POWERSUPPLYCHANGE,
						BP.INPUTMODECHANGE,
						BP.SETINPUTMODE,
						BP.SETPOWERSUPPLY,
						BP.STATECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._DAQ1400_FREQUENCYCOUNTER_120,
					t: "Frequency Input",
					c: 9 /* ChannelClass.FREQUENCY_COUNTER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.POWERSUPPLYCHANGE,
						BP.INPUTMODECHANGE,
						BP.FREQUENCYDATA,
						BP.SETDATAINTERVAL,
						BP.SETINPUTMODE,
						BP.SETPOWERSUPPLY,
						BP.FREQUENCYCHANGE,
						BP.COUNTCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._DAQ1500,
			d: 56 /* DeviceID.PN_DAQ1500 */,
			c: 21 /* DeviceClass.VINT */,
			t: "Wheatstone Bridge Phidget",
			s: "DAQ1500",
			i: 24 /* VintID */,
			v: [100, 200],
			ch: [
				{
					uid: DeviceChannelUID._DAQ1500_VOLTAGERATIOINPUT_100,
					t: "Bridge Input",
					c: 31 /* ChannelClass.VOLTAGE_RATIO_INPUT */,
					s: 65 /* ChannelSubClass.VOLTAGE_RATIO_INPUT_BRIDGE */,
					n: 2 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETBRIDGEGAIN,
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETENABLED,
						BP.VOLTAGERATIOCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._VCP1100,
			d: 105 /* DeviceID.PN_VCP1100 */,
			c: 21 /* DeviceClass.VINT */,
			t: "30A Current Sensor Phidget",
			s: "VCP1100",
			i: 64 /* VintID */,
			v: [100, 200],
			ch: [
				{
					uid: DeviceChannelUID._VCP1100_CURRENTINPUT_100,
					t: "Current Input",
					c: 2 /* ChannelClass.CURRENT_INPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.CURRENTCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._DCC1000,
			d: 57 /* DeviceID.PN_DCC1000 */,
			c: 21 /* DeviceClass.VINT */,
			t: "DC Motor Phidget",
			s: "DCC1000",
			i: 47 /* VintID */,
			v: [100, 200],
			ch: [
				{
					uid: DeviceChannelUID._DCC1000_DCMOTOR_100,
					t: "DC Motor Controller",
					c: 4 /* ChannelClass.DC_MOTOR */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETACCELERATION,
						BP.SETBRAKINGDUTYCYCLE,
						BP.SETCURRENTLIMIT,
						BP.SETCURRENTREGULATORGAIN,
						BP.SETDUTYCYCLE,
						BP.SETFANMODE,
						BP.BRAKINGSTRENGTHCHANGE,
						BP.DUTYCYCLECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._DCC1000_ENCODER_100,
					t: "Encoder Input",
					c: 8 /* ChannelClass.ENCODER */,
					s: 96 /* ChannelSubClass.ENCODER_MODE_SETTABLE */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETIOMODE,
						BP.POSITIONCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._DCC1000_VOLTAGERATIOINPUT_100,
					t: "Voltage Ratio",
					c: 31 /* ChannelClass.VOLTAGE_RATIO_INPUT */,
					s: 64 /* ChannelSubClass.VOLTAGE_RATIO_INPUT_SENSOR_PORT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETSENSORTYPE,
						BP.SETSENSORVALUECHANGETRIGGER,
						BP.VOLTAGERATIOCHANGE,
						BP.SENSORCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._DCC1000_TEMPERATURESENSOR_IC_100,
					t: "Temperature Sensor",
					c: 28 /* ChannelClass.TEMPERATURE_SENSOR */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.TEMPERATURECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._DCC1000_CURRENTINPUT_100,
					t: "Current Sensor",
					c: 2 /* ChannelClass.CURRENT_INPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETDATAINTERVAL,
						BP.CURRENTCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._DCC1000_POSITIONCONTROL,
			d: 57 /* DeviceID.PN_DCC1000 */,
			c: 21 /* DeviceClass.VINT */,
			t: "DC Motor Phidget",
			s: "DCC1000",
			i: 47 /* VintID */,
			v: [200, 210],
			ch: [
				{
					uid: DeviceChannelUID._DCC1000_DCMOTOR_200,
					t: "DC Motor Controller",
					c: 4 /* ChannelClass.DC_MOTOR */,
					i: 0 /* Index */,
					e: 0 /* Exclusive */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETACCELERATION,
						BP.SETBRAKINGDUTYCYCLE,
						BP.SETCURRENTLIMIT,
						BP.SETCURRENTREGULATORGAIN,
						BP.SETDUTYCYCLE,
						BP.SETFANMODE,
						BP.BRAKINGSTRENGTHCHANGE,
						BP.DUTYCYCLECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._DCC1000_ENCODER_200,
					t: "Encoder Input",
					c: 8 /* ChannelClass.ENCODER */,
					s: 96 /* ChannelSubClass.ENCODER_MODE_SETTABLE */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETIOMODE,
						BP.POSITIONCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._DCC1000_VOLTAGERATIOINPUT_200,
					t: "Voltage Ratio",
					c: 31 /* ChannelClass.VOLTAGE_RATIO_INPUT */,
					s: 64 /* ChannelSubClass.VOLTAGE_RATIO_INPUT_SENSOR_PORT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETSENSORTYPE,
						BP.SETSENSORVALUECHANGETRIGGER,
						BP.VOLTAGERATIOCHANGE,
						BP.SENSORCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._DCC1000_TEMPERATURESENSOR_IC_200,
					t: "Temperature Sensor",
					c: 28 /* ChannelClass.TEMPERATURE_SENSOR */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.TEMPERATURECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._DCC1000_CURRENTINPUT_200,
					t: "Current Sensor",
					c: 2 /* ChannelClass.CURRENT_INPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETDATAINTERVAL,
						BP.CURRENTCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._DCC1000_MOTORPOSITIONCONTROLLER_200,
					t: "Position Controller",
					c: 34 /* ChannelClass.MOTOR_POSITION_CONTROLLER */,
					i: 0 /* Index */,
					e: 0 /* Exclusive */,
					p: /* Supported Packets */ [
						BP.SETACCELERATION,
						BP.SETDATAINTERVAL,
						BP.SETCURRENTLIMIT,
						BP.SETCURRENTREGULATORGAIN,
						BP.SETENGAGED,
						BP.SETDUTYCYCLE,
						BP.SETTARGETPOSITION,
						BP.SETFANMODE,
						BP.SETDEADBAND,
						BP.SETIOMODE,
						BP.SETKP,
						BP.SETKD,
						BP.SETKI,
						BP.FAILSAFERESET,
						BP.SETFAILSAFETIME,
						BP.POSITIONCHANGE,
						BP.DUTYCYCLECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._DCC1000_POSITIONCONTROL_FAILSAFE,
			d: 57 /* DeviceID.PN_DCC1000 */,
			c: 21 /* DeviceClass.VINT */,
			t: "DC Motor Phidget",
			s: "DCC1000",
			i: 47 /* VintID */,
			v: [210, 300],
			ch: [
				{
					uid: DeviceChannelUID._DCC1000_DCMOTOR_210,
					t: "DC Motor Controller",
					c: 4 /* ChannelClass.DC_MOTOR */,
					i: 0 /* Index */,
					e: 0 /* Exclusive */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETACCELERATION,
						BP.SETBRAKINGDUTYCYCLE,
						BP.SETCURRENTLIMIT,
						BP.SETCURRENTREGULATORGAIN,
						BP.SETDUTYCYCLE,
						BP.SETFANMODE,
						BP.FAILSAFERESET,
						BP.SETFAILSAFETIME,
						BP.BRAKINGSTRENGTHCHANGE,
						BP.DUTYCYCLECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._DCC1000_ENCODER_210,
					t: "Encoder Input",
					c: 8 /* ChannelClass.ENCODER */,
					s: 96 /* ChannelSubClass.ENCODER_MODE_SETTABLE */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETIOMODE,
						BP.POSITIONCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._DCC1000_VOLTAGERATIOINPUT_210,
					t: "Voltage Ratio",
					c: 31 /* ChannelClass.VOLTAGE_RATIO_INPUT */,
					s: 64 /* ChannelSubClass.VOLTAGE_RATIO_INPUT_SENSOR_PORT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETSENSORTYPE,
						BP.SETSENSORVALUECHANGETRIGGER,
						BP.VOLTAGERATIOCHANGE,
						BP.SENSORCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._DCC1000_TEMPERATURESENSOR_IC_210,
					t: "Temperature Sensor",
					c: 28 /* ChannelClass.TEMPERATURE_SENSOR */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.TEMPERATURECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._DCC1000_CURRENTINPUT_210,
					t: "Current Sensor",
					c: 2 /* ChannelClass.CURRENT_INPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETDATAINTERVAL,
						BP.CURRENTCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._DCC1000_MOTORPOSITIONCONTROLLER_210,
					t: "Position Controller",
					c: 34 /* ChannelClass.MOTOR_POSITION_CONTROLLER */,
					i: 0 /* Index */,
					e: 0 /* Exclusive */,
					p: /* Supported Packets */ [
						BP.SETACCELERATION,
						BP.SETDATAINTERVAL,
						BP.SETCURRENTLIMIT,
						BP.SETCURRENTREGULATORGAIN,
						BP.SETENGAGED,
						BP.SETDUTYCYCLE,
						BP.SETTARGETPOSITION,
						BP.SETFANMODE,
						BP.SETDEADBAND,
						BP.SETIOMODE,
						BP.SETKP,
						BP.SETKD,
						BP.SETKI,
						BP.FAILSAFERESET,
						BP.SETFAILSAFETIME,
						BP.POSITIONCHANGE,
						BP.DUTYCYCLECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._DCC1001,
			d: 110 /* DeviceID.PN_DCC1001 */,
			c: 21 /* DeviceClass.VINT */,
			t: "2A DC Motor Phidget",
			s: "DCC1001",
			i: 68 /* VintID */,
			v: [100, 120],
			ch: [
				{
					uid: DeviceChannelUID._DCC1001_DCMOTOR_100,
					t: "DC Motor Controller",
					c: 4 /* ChannelClass.DC_MOTOR */,
					i: 0 /* Index */,
					e: 0 /* Exclusive */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETACCELERATION,
						BP.SETBRAKINGDUTYCYCLE,
						BP.SETCURRENTLIMIT,
						BP.SETDUTYCYCLE,
						BP.BRAKINGSTRENGTHCHANGE,
						BP.DUTYCYCLECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._DCC1001_ENCODER_100,
					t: "Encoder Input",
					c: 8 /* ChannelClass.ENCODER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.POSITIONCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._DCC1001_MOTORPOSITIONCONTROLLER_100,
					t: "Position Controller",
					c: 34 /* ChannelClass.MOTOR_POSITION_CONTROLLER */,
					i: 0 /* Index */,
					e: 0 /* Exclusive */,
					p: /* Supported Packets */ [
						BP.SETACCELERATION,
						BP.SETDATAINTERVAL,
						BP.SETCURRENTLIMIT,
						BP.SETENGAGED,
						BP.SETDUTYCYCLE,
						BP.SETTARGETPOSITION,
						BP.SETDEADBAND,
						BP.SETKP,
						BP.SETKD,
						BP.SETKI,
						BP.POSITIONCHANGE,
						BP.DUTYCYCLECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._DCC1001_Failsafe,
			d: 110 /* DeviceID.PN_DCC1001 */,
			c: 21 /* DeviceClass.VINT */,
			t: "2A DC Motor Phidget",
			s: "DCC1001",
			i: 68 /* VintID */,
			v: [120, 200],
			ch: [
				{
					uid: DeviceChannelUID._DCC1001_DCMOTOR_120,
					t: "DC Motor Controller",
					c: 4 /* ChannelClass.DC_MOTOR */,
					i: 0 /* Index */,
					e: 0 /* Exclusive */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETACCELERATION,
						BP.SETBRAKINGDUTYCYCLE,
						BP.SETCURRENTLIMIT,
						BP.SETDUTYCYCLE,
						BP.FAILSAFERESET,
						BP.SETFAILSAFETIME,
						BP.BRAKINGSTRENGTHCHANGE,
						BP.DUTYCYCLECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._DCC1001_ENCODER_120,
					t: "Encoder Input",
					c: 8 /* ChannelClass.ENCODER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.POSITIONCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._DCC1001_MOTORPOSITIONCONTROLLER_120,
					t: "Position Controller",
					c: 34 /* ChannelClass.MOTOR_POSITION_CONTROLLER */,
					i: 0 /* Index */,
					e: 0 /* Exclusive */,
					p: /* Supported Packets */ [
						BP.SETACCELERATION,
						BP.SETDATAINTERVAL,
						BP.SETCURRENTLIMIT,
						BP.SETENGAGED,
						BP.SETDUTYCYCLE,
						BP.SETTARGETPOSITION,
						BP.SETDEADBAND,
						BP.SETKP,
						BP.SETKD,
						BP.SETKI,
						BP.FAILSAFERESET,
						BP.SETFAILSAFETIME,
						BP.POSITIONCHANGE,
						BP.DUTYCYCLECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._DCC1002,
			d: 117 /* DeviceID.PN_DCC1002 */,
			c: 21 /* DeviceClass.VINT */,
			t: "4A DC Motor Phidget",
			s: "DCC1002",
			i: 70 /* VintID */,
			v: [100, 110],
			ch: [
				{
					uid: DeviceChannelUID._DCC1002_DCMOTOR_100,
					t: "DC Motor Controller",
					c: 4 /* ChannelClass.DC_MOTOR */,
					i: 0 /* Index */,
					e: 0 /* Exclusive */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETACCELERATION,
						BP.SETBRAKINGDUTYCYCLE,
						BP.SETCURRENTLIMIT,
						BP.SETDUTYCYCLE,
						BP.BRAKINGSTRENGTHCHANGE,
						BP.DUTYCYCLECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._DCC1002_ENCODER_100,
					t: "Encoder Input",
					c: 8 /* ChannelClass.ENCODER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.POSITIONCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._DCC1002_MOTORPOSITIONCONTROLLER_100,
					t: "Position Controller",
					c: 34 /* ChannelClass.MOTOR_POSITION_CONTROLLER */,
					i: 0 /* Index */,
					e: 0 /* Exclusive */,
					p: /* Supported Packets */ [
						BP.SETACCELERATION,
						BP.SETDATAINTERVAL,
						BP.SETCURRENTLIMIT,
						BP.SETENGAGED,
						BP.SETDUTYCYCLE,
						BP.SETTARGETPOSITION,
						BP.SETDEADBAND,
						BP.SETKP,
						BP.SETKD,
						BP.SETKI,
						BP.POSITIONCHANGE,
						BP.DUTYCYCLECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._DCC1002_Failsafe,
			d: 117 /* DeviceID.PN_DCC1002 */,
			c: 21 /* DeviceClass.VINT */,
			t: "4A DC Motor Phidget",
			s: "DCC1002",
			i: 70 /* VintID */,
			v: [110, 200],
			ch: [
				{
					uid: DeviceChannelUID._DCC1002_DCMOTOR_110,
					t: "DC Motor Controller",
					c: 4 /* ChannelClass.DC_MOTOR */,
					i: 0 /* Index */,
					e: 0 /* Exclusive */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETACCELERATION,
						BP.SETBRAKINGDUTYCYCLE,
						BP.SETCURRENTLIMIT,
						BP.SETDUTYCYCLE,
						BP.FAILSAFERESET,
						BP.SETFAILSAFETIME,
						BP.BRAKINGSTRENGTHCHANGE,
						BP.DUTYCYCLECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._DCC1002_ENCODER_110,
					t: "Encoder Input",
					c: 8 /* ChannelClass.ENCODER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.POSITIONCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._DCC1002_MOTORPOSITIONCONTROLLER_110,
					t: "Position Controller",
					c: 34 /* ChannelClass.MOTOR_POSITION_CONTROLLER */,
					i: 0 /* Index */,
					e: 0 /* Exclusive */,
					p: /* Supported Packets */ [
						BP.SETACCELERATION,
						BP.SETDATAINTERVAL,
						BP.SETCURRENTLIMIT,
						BP.SETENGAGED,
						BP.SETDUTYCYCLE,
						BP.SETTARGETPOSITION,
						BP.SETDEADBAND,
						BP.SETKP,
						BP.SETKD,
						BP.SETKI,
						BP.FAILSAFERESET,
						BP.SETFAILSAFETIME,
						BP.POSITIONCHANGE,
						BP.DUTYCYCLECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._DCC1003,
			d: 120 /* DeviceID.PN_DCC1003 */,
			c: 21 /* DeviceClass.VINT */,
			t: "2x DC Motor Phidget",
			s: "DCC1003",
			i: 73 /* VintID */,
			v: [100, 110],
			ch: [
				{
					uid: DeviceChannelUID._DCC1003_DCMOTOR_100,
					t: "DC Motor Controller",
					c: 4 /* ChannelClass.DC_MOTOR */,
					n: 2 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETACCELERATION,
						BP.SETBRAKINGDUTYCYCLE,
						BP.SETCURRENTLIMIT,
						BP.SETDUTYCYCLE,
						BP.BRAKINGSTRENGTHCHANGE,
						BP.DUTYCYCLECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._DCC1003_Failsafe,
			d: 120 /* DeviceID.PN_DCC1003 */,
			c: 21 /* DeviceClass.VINT */,
			t: "2x DC Motor Phidget",
			s: "DCC1003",
			i: 73 /* VintID */,
			v: [110, 200],
			ch: [
				{
					uid: DeviceChannelUID._DCC1003_DCMOTOR_110,
					t: "DC Motor Controller",
					c: 4 /* ChannelClass.DC_MOTOR */,
					n: 2 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETACCELERATION,
						BP.SETBRAKINGDUTYCYCLE,
						BP.SETCURRENTLIMIT,
						BP.SETDUTYCYCLE,
						BP.FAILSAFERESET,
						BP.SETFAILSAFETIME,
						BP.BRAKINGSTRENGTHCHANGE,
						BP.DUTYCYCLECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._DCC1100,
			d: 108 /* DeviceID.PN_DCC1100 */,
			c: 21 /* DeviceClass.VINT */,
			t: "Brushless DC Motor Phidget",
			s: "DCC1100",
			i: 65 /* VintID */,
			v: [100, 120],
			ch: [
				{
					uid: DeviceChannelUID._DCC1100_BLDCMOTOR_100,
					t: "Brushless DC Motor Controller",
					c: 35 /* ChannelClass.BLDC_MOTOR */,
					i: 0 /* Index */,
					e: 0 /* Exclusive */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETACCELERATION,
						BP.SETBRAKINGDUTYCYCLE,
						BP.SETDUTYCYCLE,
						BP.SETSTALLVELOCITY,
						BP.BRAKINGSTRENGTHCHANGE,
						BP.POSITIONCHANGE,
						BP.DUTYCYCLECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._DCC1100_TEMPERATURESENSOR_IC_100,
					t: "Temperature Sensor",
					c: 28 /* ChannelClass.TEMPERATURE_SENSOR */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.TEMPERATURECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._DCC1100_MOTORPOSITIONCONTROLLER_100,
					t: "Position Controller",
					c: 34 /* ChannelClass.MOTOR_POSITION_CONTROLLER */,
					i: 0 /* Index */,
					e: 0 /* Exclusive */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETACCELERATION,
						BP.SETDUTYCYCLE,
						BP.SETENGAGED,
						BP.SETTARGETPOSITION,
						BP.SETDEADBAND,
						BP.SETKP,
						BP.SETKD,
						BP.SETKI,
						BP.SETSTALLVELOCITY,
						BP.FAILSAFERESET,
						BP.SETFAILSAFETIME,
						BP.POSITIONCHANGE,
						BP.DUTYCYCLECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._DCC1100_Failsafe,
			d: 108 /* DeviceID.PN_DCC1100 */,
			c: 21 /* DeviceClass.VINT */,
			t: "Brushless DC Motor Phidget",
			s: "DCC1100",
			i: 65 /* VintID */,
			v: [120, 200],
			ch: [
				{
					uid: DeviceChannelUID._DCC1100_BLDCMOTOR_120,
					t: "Brushless DC Motor Controller",
					c: 35 /* ChannelClass.BLDC_MOTOR */,
					i: 0 /* Index */,
					e: 0 /* Exclusive */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETACCELERATION,
						BP.SETBRAKINGDUTYCYCLE,
						BP.SETDUTYCYCLE,
						BP.SETSTALLVELOCITY,
						BP.FAILSAFERESET,
						BP.SETFAILSAFETIME,
						BP.BRAKINGSTRENGTHCHANGE,
						BP.POSITIONCHANGE,
						BP.DUTYCYCLECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._DCC1100_TEMPERATURESENSOR_IC_120,
					t: "Temperature Sensor",
					c: 28 /* ChannelClass.TEMPERATURE_SENSOR */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.TEMPERATURECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._DCC1100_MOTORPOSITIONCONTROLLER_120,
					t: "Position Controller",
					c: 34 /* ChannelClass.MOTOR_POSITION_CONTROLLER */,
					i: 0 /* Index */,
					e: 0 /* Exclusive */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETACCELERATION,
						BP.SETDUTYCYCLE,
						BP.SETENGAGED,
						BP.SETTARGETPOSITION,
						BP.SETDEADBAND,
						BP.SETKP,
						BP.SETKD,
						BP.SETKI,
						BP.SETSTALLVELOCITY,
						BP.FAILSAFERESET,
						BP.SETFAILSAFETIME,
						BP.POSITIONCHANGE,
						BP.DUTYCYCLECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._DST1000,
			d: 58 /* DeviceID.PN_DST1000 */,
			c: 21 /* DeviceClass.VINT */,
			t: "Distance Phidget",
			s: "DST1000",
			i: 45 /* VintID */,
			v: [100, 200],
			ch: [
				{
					uid: DeviceChannelUID._DST1000_DISTANCESENSOR_100,
					t: "Distance Sensor 200mm",
					c: 7 /* ChannelClass.DISTANCE_SENSOR */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.DISTANCECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._DST1001,
			d: 121 /* DeviceID.PN_DST1001 */,
			c: 21 /* DeviceClass.VINT */,
			t: "Distance Phidget 650mm",
			s: "DST1001",
			i: 121 /* VintID */,
			v: [100, 200],
			ch: [
				{
					uid: DeviceChannelUID._DST1001_DISTANCESENSOR_100,
					t: "Distance Sensor 650mm",
					c: 7 /* ChannelClass.DISTANCE_SENSOR */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.DISTANCECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._DST1002,
			d: 126 /* DeviceID.PN_DST1002 */,
			c: 21 /* DeviceClass.VINT */,
			t: "Distance Phidget 1300mm",
			s: "DST1002",
			i: 126 /* VintID */,
			v: [100, 200],
			ch: [
				{
					uid: DeviceChannelUID._DST1002_DISTANCESENSOR_100,
					t: "Distance Sensor 1300mm",
					c: 7 /* ChannelClass.DISTANCE_SENSOR */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.DISTANCECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._DST1200,
			d: 59 /* DeviceID.PN_DST1200 */,
			c: 21 /* DeviceClass.VINT */,
			t: "Sonar Phidget",
			s: "DST1200",
			i: 46 /* VintID */,
			v: [100, 200],
			ch: [
				{
					uid: DeviceChannelUID._DST1200_DISTANCESENSOR_100,
					t: "Sonar Distance Sensor",
					c: 7 /* ChannelClass.DISTANCE_SENSOR */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETSONARQUIETMODE,
						BP.DISTANCECHANGE,
						BP.SONARUPDATE,
					]
				},
			]
		},
		{
			uid: DeviceUID._ENC1000,
			d: 60 /* DeviceID.PN_ENC1000 */,
			c: 21 /* DeviceClass.VINT */,
			t: "Quadrature Encoder Phidget",
			s: "ENC1000",
			i: 18 /* VintID */,
			v: [100, 200],
			ch: [
				{
					uid: DeviceChannelUID._ENC1000_ENCODER_100,
					t: "Encoder Input",
					c: 8 /* ChannelClass.ENCODER */,
					s: 96 /* ChannelSubClass.ENCODER_MODE_SETTABLE */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETENABLED,
						BP.SETIOMODE,
						BP.POSITIONCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._ENC1001,
			d: 155 /* DeviceID.PN_ENC1001 */,
			c: 21 /* DeviceClass.VINT */,
			t: "Quadrature Encoder Phidget",
			s: "ENC1001",
			i: 75 /* VintID */,
			v: [100, 200],
			ch: [
				{
					uid: DeviceChannelUID._ENC1001_ENCODER_100,
					t: "Encoder Input",
					c: 8 /* ChannelClass.ENCODER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETENABLED,
						BP.POSITIONCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._HIN1101,
			d: 109 /* DeviceID.PN_HIN1101 */,
			c: 21 /* DeviceClass.VINT */,
			t: "Phidget Dial",
			s: "HIN1101",
			i: 67 /* VintID */,
			v: [100, 200],
			ch: [
				{
					uid: DeviceChannelUID._HIN1101_ENCODER_100,
					t: "Encoder Input",
					c: 8 /* ChannelClass.ENCODER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETENABLED,
						BP.POSITIONCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._HIN1101_DIGITALINPUT_100,
					t: "Digital Input",
					c: 5 /* ChannelClass.DIGITAL_INPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.STATECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._HIN1000,
			d: 61 /* DeviceID.PN_HIN1000 */,
			c: 21 /* DeviceClass.VINT */,
			t: "Touch Keypad Phidget",
			s: "HIN1000",
			i: 36 /* VintID */,
			v: [100, 200],
			ch: [
				{
					uid: DeviceChannelUID._HIN1000_CAPACITIVETOUCH_100,
					t: "Capacitive Touch Sensor",
					c: 14 /* ChannelClass.CAPACITIVE_TOUCH */,
					n: 7 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETDATAINTERVAL,
						BP.SETSENSITIVITY,
						BP.TOUCHINPUTVALUECHANGE,
						BP.TOUCHINPUTEND,
					]
				},
			]
		},
		{
			uid: DeviceUID._HIN1001,
			d: 62 /* DeviceID.PN_HIN1001 */,
			c: 21 /* DeviceClass.VINT */,
			t: "Touch Wheel Phidget",
			s: "HIN1001",
			i: 56 /* VintID */,
			v: [100, 200],
			ch: [
				{
					uid: DeviceChannelUID._HIN1001_CAPACITIVETOUCH_BUTTONS_100,
					t: "Capacitive Touch Sensor",
					c: 14 /* ChannelClass.CAPACITIVE_TOUCH */,
					n: 4 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETDATAINTERVAL,
						BP.SETSENSITIVITY,
						BP.TOUCHINPUTVALUECHANGE,
						BP.TOUCHINPUTEND,
					]
				},
				{
					uid: DeviceChannelUID._HIN1001_CAPACITIVETOUCH_WHEEL_100,
					t: "Capacitive Scroll Wheel",
					c: 14 /* ChannelClass.CAPACITIVE_TOUCH */,
					i: 4 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETDATAINTERVAL,
						BP.SETSENSITIVITY,
						BP.TOUCHINPUTVALUECHANGE,
						BP.TOUCHINPUTEND,
					]
				},
			]
		},
		{
			uid: DeviceUID._HIN1100,
			d: 63 /* DeviceID.PN_HIN1100 */,
			c: 21 /* DeviceClass.VINT */,
			t: "Thumbstick Phidget",
			s: "HIN1100",
			i: 37 /* VintID */,
			v: [100, 200],
			ch: [
				{
					uid: DeviceChannelUID._HIN1100_VOLTAGERATIOINPUT_100,
					t: "Thumbstick Axis",
					c: 31 /* ChannelClass.VOLTAGE_RATIO_INPUT */,
					n: 2 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.VOLTAGERATIOCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._HIN1100_DIGITALINPUT_100,
					t: "Thumbstick Button",
					c: 5 /* ChannelClass.DIGITAL_INPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.STATECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._HUM1000,
			d: 69 /* DeviceID.PN_HUM1000 */,
			c: 21 /* DeviceClass.VINT */,
			t: "Humidity Phidget",
			s: "HUM1000",
			i: 20 /* VintID */,
			v: [100, 200],
			ch: [
				{
					uid: DeviceChannelUID._HUM1000_HUMIDITYSENSOR_100,
					t: "Humidity Sensor",
					c: 15 /* ChannelClass.HUMIDITY_SENSOR */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.HUMIDITYCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._HUM1000_TEMPERATURESENSOR_IC_100,
					t: "Temperature Sensor",
					c: 28 /* ChannelClass.TEMPERATURE_SENSOR */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.TEMPERATURECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._HUM1001,
			d: 127 /* DeviceID.PN_HUM1001 */,
			c: 21 /* DeviceClass.VINT */,
			t: "Humidity Phidget",
			s: "HUM1001",
			i: 127 /* VintID */,
			v: [100, 200],
			ch: [
				{
					uid: DeviceChannelUID._HUM1001_HUMIDITYSENSOR_100,
					t: "Humidity Sensor",
					c: 15 /* ChannelClass.HUMIDITY_SENSOR */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.HUMIDITYCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._HUM1001_TEMPERATURESENSOR_IC_100,
					t: "Temperature Sensor",
					c: 28 /* ChannelClass.TEMPERATURE_SENSOR */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.TEMPERATURECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._LCD1100,
			d: 70 /* DeviceID.PN_LCD1100 */,
			c: 21 /* DeviceClass.VINT */,
			t: "Graphic LCD Phidget",
			s: "LCD1100",
			i: 40 /* VintID */,
			v: [100, 200],
			ch: [
				{
					uid: DeviceChannelUID._LCD1100_LCD_100,
					t: "Graphic LCD",
					c: 11 /* ChannelClass.LCD */,
					s: 80 /* ChannelSubClass.LCD_GRAPHIC */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETBACKLIGHT,
						BP.SETCONTRAST,
						BP.SETFRAMEBUFFER,
						BP.SETSLEEP,
						BP.SETCHARACTERBITMAP,
						BP.CLEAR,
						BP.COPY,
						BP.DRAWLINE,
						BP.DRAWPIXEL,
						BP.DRAWRECT,
						BP.FLUSH,
						BP.SETFONTSIZE,
						BP.SAVEFRAMEBUFFER,
						BP.WRITEBITMAP,
						BP.WRITETEXT,
					]
				},
			]
		},
		{
			uid: DeviceUID._LCD1100_1,
			d: 70 /* DeviceID.PN_LCD1100 */,
			c: 21 /* DeviceClass.VINT */,
			t: "Graphic LCD Phidget",
			s: "LCD1100",
			i: 23 /* VintID */,
			v: [200, 300],
			ch: [
				{
					uid: DeviceChannelUID._LCD1100_LCD_200,
					t: "Graphic LCD",
					c: 11 /* ChannelClass.LCD */,
					s: 80 /* ChannelSubClass.LCD_GRAPHIC */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETBACKLIGHT,
						BP.SETCONTRAST,
						BP.SETFRAMEBUFFER,
						BP.SETSLEEP,
						BP.SETCHARACTERBITMAP,
						BP.CLEAR,
						BP.COPY,
						BP.DRAWLINE,
						BP.DRAWPIXEL,
						BP.DRAWRECT,
						BP.FLUSH,
						BP.SETFONTSIZE,
						BP.SAVEFRAMEBUFFER,
						BP.WRITEBITMAP,
						BP.WRITETEXT,
					]
				},
			]
		},
		{
			uid: DeviceUID._LED1000,
			d: 71 /* DeviceID.PN_LED1000 */,
			c: 21 /* DeviceClass.VINT */,
			t: "32x Isolated LED Phidget",
			s: "LED1000",
			i: 39 /* VintID */,
			v: [100, 200],
			ch: [
				{
					uid: DeviceChannelUID._LED1000_DIGITALOUTPUT_100,
					t: "LED Driver",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					s: 17 /* ChannelSubClass.DIGITAL_OUTPUT_LEDDRIVER */,
					n: 32 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDUTYCYCLE,
						BP.SETLEDCURRENTLIMIT,
						BP.SETLEDFORWARDVOLTAGE,
						BP.SETSTATE,
					]
				},
			]
		},
		{
			uid: DeviceUID._LUX1000,
			d: 72 /* DeviceID.PN_LUX1000 */,
			c: 21 /* DeviceClass.VINT */,
			t: "Light Phidget",
			s: "LUX1000",
			i: 33 /* VintID */,
			v: [100, 200],
			ch: [
				{
					uid: DeviceChannelUID._LUX1000_LIGHTSENSOR_100,
					t: "Light Sensor",
					c: 17 /* ChannelClass.LIGHT_SENSOR */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.ILLUMINANCECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._HUM1100,
			d: 136 /* DeviceID.PN_HUM1100 */,
			c: 21 /* DeviceClass.VINT */,
			t: "Soil Moisture Phidget",
			s: "HUM1100",
			i: 136 /* VintID */,
			v: [100, 200],
			ch: [
				{
					uid: DeviceChannelUID._HUM1100_VOLTAGERATIOINPUT_100,
					t: "Moisture Sensor",
					c: 31 /* ChannelClass.VOLTAGE_RATIO_INPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.VOLTAGERATIOCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._MOT1100,
			d: 73 /* DeviceID.PN_MOT1100 */,
			c: 21 /* DeviceClass.VINT */,
			t: "Accelerometer Phidget",
			s: "MOT1100",
			i: 51 /* VintID */,
			v: [200, 300],
			ch: [
				{
					uid: DeviceChannelUID._MOT1100_ACCELEROMETER_200,
					t: "3-Axis Accelerometer",
					c: 1 /* ChannelClass.ACCELEROMETER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.ACCELERATIONCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._MOT0100_VINT,
			d: 146 /* DeviceID.PN_MOT0100 */,
			c: 21 /* DeviceClass.VINT */,
			t: "PhidgetAccelerometer",
			s: "MOT0100",
			i: 146 /* VintID */,
			v: [100, 200],
			ch: [
				{
					uid: DeviceChannelUID._MOT0100_ACCELEROMETER_100_VINT,
					t: "3-Axis Accelerometer",
					c: 1 /* ChannelClass.ACCELEROMETER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.ACCELERATIONCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._MOT1101,
			d: 74 /* DeviceID.PN_MOT1101 */,
			c: 21 /* DeviceClass.VINT */,
			t: "Spatial Phidget",
			s: "MOT1101",
			i: 52 /* VintID */,
			v: [100, 200],
			ch: [
				{
					uid: DeviceChannelUID._MOT1101_ACCELEROMETER_100,
					t: "3-Axis Accelerometer",
					c: 1 /* ChannelClass.ACCELEROMETER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.ACCELERATIONCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._MOT1101_GYROSCOPE_100,
					t: "3-Axis Gyroscope",
					c: 12 /* ChannelClass.GYROSCOPE */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.ZERO,
						BP.ANGULARRATEUPDATE,
					]
				},
				{
					uid: DeviceChannelUID._MOT1101_MAGNETOMETER_100,
					t: "3-Axis Magnetometer",
					c: 18 /* ChannelClass.MAGNETOMETER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETCORRECTIONPARAMETERS,
						BP.SAVECORRECTIONPARAMETERS,
						BP.RESETCORRECTIONPARAMETERS,
						BP.FIELDSTRENGTHCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._MOT1101_SPATIAL_100,
					t: "Spatial",
					c: 26 /* ChannelClass.SPATIAL */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.ZERO,
						BP.SETCORRECTIONPARAMETERS,
						BP.SAVECORRECTIONPARAMETERS,
						BP.RESETCORRECTIONPARAMETERS,
						BP.SPATIALDATA,
					]
				},
			]
		},
		{
			uid: DeviceUID._MOT1102,
			d: 137 /* DeviceID.PN_MOT1102 */,
			c: 21 /* DeviceClass.VINT */,
			t: "Spatial Phidget",
			s: "MOT1102",
			i: 137 /* VintID */,
			v: [200, 300],
			ch: [
				{
					uid: DeviceChannelUID._MOT1102_ACCELEROMETER_200,
					t: "3-Axis Accelerometer",
					c: 1 /* ChannelClass.ACCELEROMETER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.ACCELERATIONCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._MOT1102_GYROSCOPE_200,
					t: "3-Axis Gyroscope",
					c: 12 /* ChannelClass.GYROSCOPE */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.ZERO,
						BP.ANGULARRATEUPDATE,
					]
				},
				{
					uid: DeviceChannelUID._MOT1102_MAGNETOMETER_200,
					t: "3-Axis Magnetometer",
					c: 18 /* ChannelClass.MAGNETOMETER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETCORRECTIONPARAMETERS,
						BP.SAVECORRECTIONPARAMETERS,
						BP.RESETCORRECTIONPARAMETERS,
						BP.FIELDSTRENGTHCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._MOT1102_SPATIAL_200,
					t: "Spatial",
					c: 26 /* ChannelClass.SPATIAL */,
					s: 112 /* ChannelSubClass.SPATIAL_AHRS */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETSPATIALALGORITHM,
						BP.SETSPATIALALGORITHMMAGGAIN,
						BP.ZERO,
						BP.SETCORRECTIONPARAMETERS,
						BP.SAVECORRECTIONPARAMETERS,
						BP.RESETCORRECTIONPARAMETERS,
						BP.SETAHRSPARAMETERS,
						BP.ZEROSPATIALALGORITHM,
						BP.SPATIALDATA,
						BP.SPATIALALGDATA,
					]
				},
			]
		},
		{
			uid: DeviceUID._MOT1102_1,
			d: 137 /* DeviceID.PN_MOT1102 */,
			c: 21 /* DeviceClass.VINT */,
			t: "Spatial Phidget",
			s: "MOT1102",
			i: 140 /* VintID */,
			v: [300, 400],
			ch: [
				{
					uid: DeviceChannelUID._MOT1102_ACCELEROMETER_300,
					t: "3-Axis Accelerometer",
					c: 1 /* ChannelClass.ACCELEROMETER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.ACCELERATIONCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._MOT1102_GYROSCOPE_300,
					t: "3-Axis Gyroscope",
					c: 12 /* ChannelClass.GYROSCOPE */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.ZERO,
						BP.ANGULARRATEUPDATE,
					]
				},
				{
					uid: DeviceChannelUID._MOT1102_MAGNETOMETER_300,
					t: "3-Axis Magnetometer",
					c: 18 /* ChannelClass.MAGNETOMETER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETCORRECTIONPARAMETERS,
						BP.SAVECORRECTIONPARAMETERS,
						BP.RESETCORRECTIONPARAMETERS,
						BP.FIELDSTRENGTHCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._MOT1102_SPATIAL_300,
					t: "Spatial",
					c: 26 /* ChannelClass.SPATIAL */,
					s: 112 /* ChannelSubClass.SPATIAL_AHRS */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETSPATIALALGORITHM,
						BP.SETSPATIALALGORITHMMAGGAIN,
						BP.ZERO,
						BP.SETCORRECTIONPARAMETERS,
						BP.SAVECORRECTIONPARAMETERS,
						BP.RESETCORRECTIONPARAMETERS,
						BP.SETAHRSPARAMETERS,
						BP.ZEROSPATIALALGORITHM,
						BP.SPATIALDATA,
						BP.SPATIALALGDATA,
					]
				},
			]
		},
		{
			uid: DeviceUID._MOT0110_VINT,
			d: 141 /* DeviceID.PN_MOT0110 */,
			c: 21 /* DeviceClass.VINT */,
			t: "PhidgetSpatial Precision 3/3/3",
			s: "MOT0110",
			i: 141 /* VintID */,
			v: [100, 200],
			ch: [
				{
					uid: DeviceChannelUID._MOT0110_ACCELEROMETER_100_VINT,
					t: "3-Axis Accelerometer",
					c: 1 /* ChannelClass.ACCELEROMETER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.HEATINGENABLEDCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETHEATINGENABLED,
						BP.ACCELERATIONCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._MOT0110_GYROSCOPE_100_VINT,
					t: "3-Axis Gyroscope",
					c: 12 /* ChannelClass.GYROSCOPE */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.HEATINGENABLEDCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETHEATINGENABLED,
						BP.ZERO,
						BP.ANGULARRATEUPDATE,
					]
				},
				{
					uid: DeviceChannelUID._MOT0110_MAGNETOMETER_100_VINT,
					t: "3-Axis Magnetometer",
					c: 18 /* ChannelClass.MAGNETOMETER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.HEATINGENABLEDCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETHEATINGENABLED,
						BP.SETCORRECTIONPARAMETERS,
						BP.SAVECORRECTIONPARAMETERS,
						BP.RESETCORRECTIONPARAMETERS,
						BP.FIELDSTRENGTHCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._MOT0110_SPATIAL_100_VINT,
					t: "Spatial",
					c: 26 /* ChannelClass.SPATIAL */,
					s: 112 /* ChannelSubClass.SPATIAL_AHRS */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.HEATINGENABLEDCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETSPATIALALGORITHM,
						BP.SETHEATINGENABLED,
						BP.ZERO,
						BP.SETCORRECTIONPARAMETERS,
						BP.SAVECORRECTIONPARAMETERS,
						BP.RESETCORRECTIONPARAMETERS,
						BP.SETAHRSPARAMETERS,
						BP.ZEROSPATIALALGORITHM,
						BP.SPATIALDATA,
						BP.SPATIALALGDATA,
					]
				},
				{
					uid: DeviceChannelUID._MOT0110_TEMPERATURESENSOR_100_VINT,
					t: "Temperature Sensor",
					c: 28 /* ChannelClass.TEMPERATURE_SENSOR */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.TEMPERATURECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._PRE1000,
			d: 79 /* DeviceID.PN_PRE1000 */,
			c: 21 /* DeviceClass.VINT */,
			t: "Barometer Phidget",
			s: "PRE1000",
			i: 17 /* VintID */,
			v: [100, 200],
			ch: [
				{
					uid: DeviceChannelUID._PRE1000_PRESSURESENSOR_100,
					t: "Barometer",
					c: 21 /* ChannelClass.PRESSURE_SENSOR */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.PRESSURECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._RCC1000,
			d: 80 /* DeviceID.PN_RCC1000 */,
			c: 21 /* DeviceClass.VINT */,
			t: "16x RC Servo Phidget",
			s: "RCC1000",
			i: 49 /* VintID */,
			v: [100, 110],
			ch: [
				{
					uid: DeviceChannelUID._RCC1000_RCSERVO_100,
					t: "RC Servo Motor Controller",
					c: 22 /* ChannelClass.RC_SERVO */,
					n: 16 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETMAXPULSEWIDTH,
						BP.SETMINPULSEWIDTH,
						BP.SETTARGETPOSITION,
						BP.SETENGAGED,
						BP.SETVOLTAGE,
						BP.SETVELOCITYLIMIT,
						BP.SETACCELERATION,
						BP.SETSPEEDRAMPINGSTATE,
						BP.TARGETPOSITIONREACHED,
					]
				},
			]
		},
		{
			uid: DeviceUID._RCC1000_Failsafe,
			d: 80 /* DeviceID.PN_RCC1000 */,
			c: 21 /* DeviceClass.VINT */,
			t: "16x RC Servo Phidget",
			s: "RCC1000",
			i: 49 /* VintID */,
			v: [110, 200],
			ch: [
				{
					uid: DeviceChannelUID._RCC1000_RCSERVO_110,
					t: "RC Servo Motor Controller",
					c: 22 /* ChannelClass.RC_SERVO */,
					n: 16 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETMAXPULSEWIDTH,
						BP.SETMINPULSEWIDTH,
						BP.SETTARGETPOSITION,
						BP.SETENGAGED,
						BP.SETVOLTAGE,
						BP.SETVELOCITYLIMIT,
						BP.SETACCELERATION,
						BP.SETSPEEDRAMPINGSTATE,
						BP.FAILSAFERESET,
						BP.SETFAILSAFETIME,
						BP.TARGETPOSITIONREACHED,
					]
				},
			]
		},
		{
			uid: DeviceUID._RCC1000_1,
			d: 80 /* DeviceID.PN_RCC1000 */,
			c: 21 /* DeviceClass.VINT */,
			t: "16x RC Servo Phidget",
			s: "RCC1000",
			i: 58 /* VintID */,
			v: [200, 300],
			ch: [
				{
					uid: DeviceChannelUID._RCC1000_RCSERVO_200,
					t: "RC Servo Motor Controller",
					c: 22 /* ChannelClass.RC_SERVO */,
					n: 16 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETMAXPULSEWIDTH,
						BP.SETMINPULSEWIDTH,
						BP.SETTARGETPOSITION,
						BP.SETENGAGED,
						BP.SETVOLTAGE,
						BP.SETVELOCITYLIMIT,
						BP.SETACCELERATION,
						BP.SETSPEEDRAMPINGSTATE,
						BP.FAILSAFERESET,
						BP.SETFAILSAFETIME,
						BP.TARGETPOSITIONREACHED,
					]
				},
			]
		},
		{
			uid: DeviceUID._REL1000,
			d: 81 /* DeviceID.PN_REL1000 */,
			c: 21 /* DeviceClass.VINT */,
			t: "4x Relay Phidget",
			s: "REL1000",
			i: 44 /* VintID */,
			v: [100, 110],
			ch: [
				{
					uid: DeviceChannelUID._REL1000_DIGITALOUTPUT_100,
					t: "Power Relay",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					n: 4 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDUTYCYCLE,
						BP.SETSTATE,
					]
				},
			]
		},
		{
			uid: DeviceUID._REL1000_Failsafe,
			d: 81 /* DeviceID.PN_REL1000 */,
			c: 21 /* DeviceClass.VINT */,
			t: "4x Relay Phidget",
			s: "REL1000",
			i: 44 /* VintID */,
			v: [110, 200],
			ch: [
				{
					uid: DeviceChannelUID._REL1000_DIGITALOUTPUT_110,
					t: "Power Relay",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					n: 4 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDUTYCYCLE,
						BP.SETSTATE,
						BP.FAILSAFERESET,
						BP.SETFAILSAFETIME,
					]
				},
			]
		},
		{
			uid: DeviceUID._REL1100,
			d: 82 /* DeviceID.PN_REL1100 */,
			c: 21 /* DeviceClass.VINT */,
			t: "4x Isolated Solid State Relay Phidget",
			s: "REL1100",
			i: 26 /* VintID */,
			v: [100, 110],
			ch: [
				{
					uid: DeviceChannelUID._REL1100_DIGITALOUTPUT_100,
					t: "Digital Output Isolated",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					s: 16 /* ChannelSubClass.DIGITAL_OUTPUT_DUTY_CYCLE */,
					n: 4 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDUTYCYCLE,
						BP.SETSTATE,
					]
				},
			]
		},
		{
			uid: DeviceUID._REL1100_Failsafe,
			d: 82 /* DeviceID.PN_REL1100 */,
			c: 21 /* DeviceClass.VINT */,
			t: "4x Isolated Solid State Relay Phidget",
			s: "REL1100",
			i: 26 /* VintID */,
			v: [110, 120],
			ch: [
				{
					uid: DeviceChannelUID._REL1100_DIGITALOUTPUT_110,
					t: "Digital Output Isolated",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					s: 16 /* ChannelSubClass.DIGITAL_OUTPUT_DUTY_CYCLE */,
					n: 4 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDUTYCYCLE,
						BP.SETSTATE,
						BP.FAILSAFERESET,
						BP.SETFAILSAFETIME,
					]
				},
			]
		},
		{
			uid: DeviceUID._REL1100_Failsafe_Frequency,
			d: 82 /* DeviceID.PN_REL1100 */,
			c: 21 /* DeviceClass.VINT */,
			t: "4x Isolated Solid State Relay Phidget",
			s: "REL1100",
			i: 26 /* VintID */,
			v: [120, 200],
			ch: [
				{
					uid: DeviceChannelUID._REL1100_DIGITALOUTPUT_120,
					t: "Digital Output Isolated",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					s: 18 /* ChannelSubClass.DIGITAL_OUTPUT_FREQUENCY */,
					n: 4 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDUTYCYCLE,
						BP.SETSTATE,
						BP.SETFREQUENCY,
						BP.FAILSAFERESET,
						BP.SETFAILSAFETIME,
					]
				},
			]
		},
		{
			uid: DeviceUID._REL1101,
			d: 83 /* DeviceID.PN_REL1101 */,
			c: 21 /* DeviceClass.VINT */,
			t: "16x Isolated Solid State Relay Phidget",
			s: "REL1101",
			i: 27 /* VintID */,
			v: [100, 110],
			ch: [
				{
					uid: DeviceChannelUID._REL1101_DIGITALOUTPUT_100,
					t: "Digital Output Isolated",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					s: 16 /* ChannelSubClass.DIGITAL_OUTPUT_DUTY_CYCLE */,
					n: 16 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDUTYCYCLE,
						BP.SETSTATE,
					]
				},
			]
		},
		{
			uid: DeviceUID._REL1101_Failsafe,
			d: 83 /* DeviceID.PN_REL1101 */,
			c: 21 /* DeviceClass.VINT */,
			t: "16x Isolated Solid State Relay Phidget",
			s: "REL1101",
			i: 27 /* VintID */,
			v: [110, 200],
			ch: [
				{
					uid: DeviceChannelUID._REL1101_DIGITALOUTPUT_110,
					t: "Digital Output Isolated",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					s: 16 /* ChannelSubClass.DIGITAL_OUTPUT_DUTY_CYCLE */,
					n: 16 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDUTYCYCLE,
						BP.SETSTATE,
						BP.FAILSAFERESET,
						BP.SETFAILSAFETIME,
					]
				},
			]
		},
		{
			uid: DeviceUID._REL1101_1,
			d: 83 /* DeviceID.PN_REL1101 */,
			c: 21 /* DeviceClass.VINT */,
			t: "16x Isolated Solid State Relay Phidget",
			s: "REL1101",
			i: 130 /* VintID */,
			v: [100, 300],
			ch: [
				{
					uid: DeviceChannelUID._REL1101_DIGITALOUTPUT_FREQ_200,
					t: "Digital Output Isolated Frequency Control",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					s: 18 /* ChannelSubClass.DIGITAL_OUTPUT_FREQUENCY */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDUTYCYCLE,
						BP.SETSTATE,
						BP.SETFREQUENCY,
						BP.FAILSAFERESET,
						BP.SETFAILSAFETIME,
					]
				},
				{
					uid: DeviceChannelUID._REL1101_DIGITALOUTPUT_200,
					t: "Digital Output Isolated",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					s: 16 /* ChannelSubClass.DIGITAL_OUTPUT_DUTY_CYCLE */,
					n: 8 /* Count */,
					i: 8 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDUTYCYCLE,
						BP.SETSTATE,
						BP.FAILSAFERESET,
						BP.SETFAILSAFETIME,
					]
				},
			]
		},
		{
			uid: DeviceUID._SAF1000,
			d: 84 /* DeviceID.PN_SAF1000 */,
			c: 21 /* DeviceClass.VINT */,
			t: "Programmable Power Guard Phidget",
			s: "SAF1000",
			i: 38 /* VintID */,
			v: [100, 110],
			ch: [
				{
					uid: DeviceChannelUID._SAF1000_POWERGUARD_100,
					t: "Power Supply Protector",
					c: 20 /* ChannelClass.POWER_GUARD */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETENABLED,
						BP.SETOVERVOLTAGE,
						BP.SETFANMODE,
					]
				},
				{
					uid: DeviceChannelUID._SAF1000_VOLTAGEINPUT_100,
					t: "Voltage Sensor",
					c: 29 /* ChannelClass.VOLTAGE_INPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.VOLTAGECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._SAF1000_TEMPERATURESENSOR_IC_100,
					t: "Temperature Sensor",
					c: 28 /* ChannelClass.TEMPERATURE_SENSOR */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.TEMPERATURECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._SAF1000_Failsafe,
			d: 84 /* DeviceID.PN_SAF1000 */,
			c: 21 /* DeviceClass.VINT */,
			t: "Programmable Power Guard Phidget",
			s: "SAF1000",
			i: 38 /* VintID */,
			v: [110, 200],
			ch: [
				{
					uid: DeviceChannelUID._SAF1000_POWERGUARD_110,
					t: "Power Supply Protector",
					c: 20 /* ChannelClass.POWER_GUARD */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETENABLED,
						BP.SETOVERVOLTAGE,
						BP.SETFANMODE,
						BP.FAILSAFERESET,
						BP.SETFAILSAFETIME,
					]
				},
				{
					uid: DeviceChannelUID._SAF1000_VOLTAGEINPUT_110,
					t: "Voltage Sensor",
					c: 29 /* ChannelClass.VOLTAGE_INPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.VOLTAGECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._SAF1000_TEMPERATURESENSOR_IC_110,
					t: "Temperature Sensor",
					c: 28 /* ChannelClass.TEMPERATURE_SENSOR */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.TEMPERATURECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._SND1000,
			d: 85 /* DeviceID.PN_SND1000 */,
			c: 21 /* DeviceClass.VINT */,
			t: "Sound Phidget",
			s: "SND1000",
			i: 35 /* VintID */,
			v: [100, 200],
			ch: [
				{
					uid: DeviceChannelUID._SND1000_SOUNDSENSOR_100,
					t: "Sound Sensor",
					c: 25 /* ChannelClass.SOUND_SENSOR */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETSPLRANGE,
						BP.SETCHANGETRIGGER,
						BP.DBCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._STC1000,
			d: 86 /* DeviceID.PN_STC1000 */,
			c: 21 /* DeviceClass.VINT */,
			t: "Stepper Phidget",
			s: "STC1000",
			i: 48 /* VintID */,
			v: [100, 110],
			ch: [
				{
					uid: DeviceChannelUID._STC1000_STEPPER_100,
					t: "Bipolar Stepper Controller",
					c: 27 /* ChannelClass.STEPPER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETTARGETPOSITION,
						BP.SETVELOCITYLIMIT,
						BP.SETACCELERATION,
						BP.SETCURRENTLIMIT,
						BP.SETENGAGED,
						BP.SETCONTROLMODE,
						BP.SETHOLDINGCURRENTLIMIT,
						BP.POSITIONCHANGE,
						BP.VELOCITYCHANGE,
						BP.STOPPED,
					]
				},
			]
		},
		{
			uid: DeviceUID._STC1000_Failsafe,
			d: 86 /* DeviceID.PN_STC1000 */,
			c: 21 /* DeviceClass.VINT */,
			t: "Stepper Phidget",
			s: "STC1000",
			i: 48 /* VintID */,
			v: [110, 200],
			ch: [
				{
					uid: DeviceChannelUID._STC1000_STEPPER_110,
					t: "Bipolar Stepper Controller",
					c: 27 /* ChannelClass.STEPPER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETTARGETPOSITION,
						BP.SETVELOCITYLIMIT,
						BP.SETACCELERATION,
						BP.SETCURRENTLIMIT,
						BP.SETENGAGED,
						BP.SETCONTROLMODE,
						BP.SETHOLDINGCURRENTLIMIT,
						BP.FAILSAFERESET,
						BP.SETFAILSAFETIME,
						BP.POSITIONCHANGE,
						BP.VELOCITYCHANGE,
						BP.STOPPED,
					]
				},
			]
		},
		{
			uid: DeviceUID._STC1001,
			d: 115 /* DeviceID.PN_STC1001 */,
			c: 21 /* DeviceClass.VINT */,
			t: "2.5A Stepper Phidget",
			s: "STC1001",
			i: 69 /* VintID */,
			v: [100, 110],
			ch: [
				{
					uid: DeviceChannelUID._STC1001_STEPPER_100,
					t: "Bipolar Stepper Controller",
					c: 27 /* ChannelClass.STEPPER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETTARGETPOSITION,
						BP.SETVELOCITYLIMIT,
						BP.SETACCELERATION,
						BP.SETCURRENTLIMIT,
						BP.SETENGAGED,
						BP.SETCONTROLMODE,
						BP.SETHOLDINGCURRENTLIMIT,
						BP.POSITIONCHANGE,
						BP.VELOCITYCHANGE,
						BP.STOPPED,
					]
				},
			]
		},
		{
			uid: DeviceUID._STC1001_Failsafe,
			d: 115 /* DeviceID.PN_STC1001 */,
			c: 21 /* DeviceClass.VINT */,
			t: "2.5A Stepper Phidget",
			s: "STC1001",
			i: 69 /* VintID */,
			v: [110, 200],
			ch: [
				{
					uid: DeviceChannelUID._STC1001_STEPPER_110,
					t: "Bipolar Stepper Controller",
					c: 27 /* ChannelClass.STEPPER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETTARGETPOSITION,
						BP.SETVELOCITYLIMIT,
						BP.SETACCELERATION,
						BP.SETCURRENTLIMIT,
						BP.SETENGAGED,
						BP.SETCONTROLMODE,
						BP.SETHOLDINGCURRENTLIMIT,
						BP.FAILSAFERESET,
						BP.SETFAILSAFETIME,
						BP.POSITIONCHANGE,
						BP.VELOCITYCHANGE,
						BP.STOPPED,
					]
				},
			]
		},
		{
			uid: DeviceUID._STC1002,
			d: 118 /* DeviceID.PN_STC1002 */,
			c: 21 /* DeviceClass.VINT */,
			t: "8A Stepper Phidget",
			s: "STC1002",
			i: 71 /* VintID */,
			v: [100, 110],
			ch: [
				{
					uid: DeviceChannelUID._STC1002_STEPPER_100,
					t: "Bipolar Stepper Controller",
					c: 27 /* ChannelClass.STEPPER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETTARGETPOSITION,
						BP.SETVELOCITYLIMIT,
						BP.SETACCELERATION,
						BP.SETCURRENTLIMIT,
						BP.SETENGAGED,
						BP.SETCONTROLMODE,
						BP.SETHOLDINGCURRENTLIMIT,
						BP.POSITIONCHANGE,
						BP.VELOCITYCHANGE,
						BP.STOPPED,
					]
				},
			]
		},
		{
			uid: DeviceUID._STC1002_Failsafe,
			d: 118 /* DeviceID.PN_STC1002 */,
			c: 21 /* DeviceClass.VINT */,
			t: "8A Stepper Phidget",
			s: "STC1002",
			i: 71 /* VintID */,
			v: [110, 200],
			ch: [
				{
					uid: DeviceChannelUID._STC1002_STEPPER_110,
					t: "Bipolar Stepper Controller",
					c: 27 /* ChannelClass.STEPPER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETTARGETPOSITION,
						BP.SETVELOCITYLIMIT,
						BP.SETACCELERATION,
						BP.SETCURRENTLIMIT,
						BP.SETENGAGED,
						BP.SETCONTROLMODE,
						BP.SETHOLDINGCURRENTLIMIT,
						BP.FAILSAFERESET,
						BP.SETFAILSAFETIME,
						BP.POSITIONCHANGE,
						BP.VELOCITYCHANGE,
						BP.STOPPED,
					]
				},
			]
		},
		{
			uid: DeviceUID._STC1003,
			d: 119 /* DeviceID.PN_STC1003 */,
			c: 21 /* DeviceClass.VINT */,
			t: "4A Stepper Phidget",
			s: "STC1003",
			i: 72 /* VintID */,
			v: [100, 110],
			ch: [
				{
					uid: DeviceChannelUID._STC1003_STEPPER_100,
					t: "Bipolar Stepper Controller",
					c: 27 /* ChannelClass.STEPPER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETTARGETPOSITION,
						BP.SETVELOCITYLIMIT,
						BP.SETACCELERATION,
						BP.SETCURRENTLIMIT,
						BP.SETENGAGED,
						BP.SETCONTROLMODE,
						BP.SETHOLDINGCURRENTLIMIT,
						BP.POSITIONCHANGE,
						BP.VELOCITYCHANGE,
						BP.STOPPED,
					]
				},
			]
		},
		{
			uid: DeviceUID._STC1003_Failsafe,
			d: 119 /* DeviceID.PN_STC1003 */,
			c: 21 /* DeviceClass.VINT */,
			t: "4A Stepper Phidget",
			s: "STC1003",
			i: 72 /* VintID */,
			v: [110, 200],
			ch: [
				{
					uid: DeviceChannelUID._STC1003_STEPPER_110,
					t: "Bipolar Stepper Controller",
					c: 27 /* ChannelClass.STEPPER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETTARGETPOSITION,
						BP.SETVELOCITYLIMIT,
						BP.SETACCELERATION,
						BP.SETCURRENTLIMIT,
						BP.SETENGAGED,
						BP.SETCONTROLMODE,
						BP.SETHOLDINGCURRENTLIMIT,
						BP.FAILSAFERESET,
						BP.SETFAILSAFETIME,
						BP.POSITIONCHANGE,
						BP.VELOCITYCHANGE,
						BP.STOPPED,
					]
				},
			]
		},
		{
			uid: DeviceUID._STC1003_1,
			d: 119 /* DeviceID.PN_STC1003 */,
			c: 21 /* DeviceClass.VINT */,
			t: "4A Stepper Phidget",
			s: "STC1003",
			i: 142 /* VintID */,
			v: [200, 300],
			ch: [
				{
					uid: DeviceChannelUID._STC1003_STEPPER_200,
					t: "Bipolar Stepper Controller",
					c: 27 /* ChannelClass.STEPPER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETTARGETPOSITION,
						BP.SETVELOCITYLIMIT,
						BP.SETACCELERATION,
						BP.SETCURRENTLIMIT,
						BP.SETENGAGED,
						BP.SETCONTROLMODE,
						BP.SETHOLDINGCURRENTLIMIT,
						BP.FAILSAFERESET,
						BP.SETFAILSAFETIME,
						BP.POSITIONCHANGE,
						BP.VELOCITYCHANGE,
						BP.STOPPED,
					]
				},
				{
					uid: DeviceChannelUID._STC1003_VOLTAGEINPUT_200,
					t: "Voltage Input",
					c: 29 /* ChannelClass.VOLTAGE_INPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.VOLTAGECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._STC1005_0,
			d: 149 /* DeviceID.PN_STC1005 */,
			c: 21 /* DeviceClass.VINT */,
			t: "4A Stepper Phidget",
			s: "STC1005",
			i: 147 /* VintID */,
			v: [100, 110],
			ch: [
				{
					uid: DeviceChannelUID._STC1005_STEPPER_100,
					t: "Bipolar Stepper Controller",
					c: 27 /* ChannelClass.STEPPER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETTARGETPOSITION,
						BP.SETVELOCITYLIMIT,
						BP.SETACCELERATION,
						BP.SETCURRENTLIMIT,
						BP.SETENGAGED,
						BP.SETCONTROLMODE,
						BP.SETHOLDINGCURRENTLIMIT,
						BP.FAILSAFERESET,
						BP.SETFAILSAFETIME,
						BP.POSITIONCHANGE,
						BP.VELOCITYCHANGE,
						BP.STOPPED,
					]
				},
				{
					uid: DeviceChannelUID._STC1005_VOLTAGEINPUT_100,
					t: "Voltage Input",
					c: 29 /* ChannelClass.VOLTAGE_INPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.VOLTAGECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._STC1005_0_110,
			d: 149 /* DeviceID.PN_STC1005 */,
			c: 21 /* DeviceClass.VINT */,
			t: "4A Stepper Phidget",
			s: "STC1005",
			i: 147 /* VintID */,
			v: [110, 200],
			ch: [
				{
					uid: DeviceChannelUID._STC1005_STEPPER_110,
					t: "Bipolar Stepper Controller",
					c: 27 /* ChannelClass.STEPPER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETTARGETPOSITION,
						BP.SETVELOCITYLIMIT,
						BP.SETACCELERATION,
						BP.SETCURRENTLIMIT,
						BP.SETENGAGED,
						BP.SETCONTROLMODE,
						BP.SETHOLDINGCURRENTLIMIT,
						BP.FAILSAFERESET,
						BP.SETFAILSAFETIME,
						BP.POSITIONCHANGE,
						BP.VELOCITYCHANGE,
						BP.STOPPED,
					]
				},
				{
					uid: DeviceChannelUID._STC1005_VOLTAGEINPUT_110,
					t: "Voltage Input",
					c: 29 /* ChannelClass.VOLTAGE_INPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.VOLTAGECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._TMP1000,
			d: 87 /* DeviceID.PN_TMP1000 */,
			c: 21 /* DeviceClass.VINT */,
			t: "Temperature Phidget",
			s: "TMP1000",
			i: 19 /* VintID */,
			v: [100, 200],
			ch: [
				{
					uid: DeviceChannelUID._TMP1000_TEMPERATURESENSOR_IC_100,
					t: "Temperature Sensor (IC)",
					c: 28 /* ChannelClass.TEMPERATURE_SENSOR */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.TEMPERATURECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._TMP1100,
			d: 88 /* DeviceID.PN_TMP1100 */,
			c: 21 /* DeviceClass.VINT */,
			t: "Isolated Thermocouple Phidget",
			s: "TMP1100",
			i: 55 /* VintID */,
			v: [100, 200],
			ch: [
				{
					uid: DeviceChannelUID._TMP1100_TEMPERATURESENSOR_THERMOCOUPLE_100,
					t: "Thermocouple Input",
					c: 28 /* ChannelClass.TEMPERATURE_SENSOR */,
					s: 33 /* ChannelSubClass.TEMPERATURE_SENSOR_THERMOCOUPLE */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETTHERMOCOUPLETYPE,
						BP.TEMPERATURECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._TMP1100_TEMPERATURESENSOR_IC_100,
					t: "Temperature Sensor (IC)",
					c: 28 /* ChannelClass.TEMPERATURE_SENSOR */,
					i: 1 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.TEMPERATURECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._TMP1100_VOLTAGEINPUT_100,
					t: "Voltage Input",
					c: 29 /* ChannelClass.VOLTAGE_INPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.VOLTAGECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._TMP1101,
			d: 89 /* DeviceID.PN_TMP1101 */,
			c: 21 /* DeviceClass.VINT */,
			t: "4x Thermocouple Phidget",
			s: "TMP1101",
			i: 21 /* VintID */,
			v: [100, 200],
			ch: [
				{
					uid: DeviceChannelUID._TMP1101_TEMPERATURESENSOR_THERMOCOUPLE_100,
					t: "Thermocouple Input",
					c: 28 /* ChannelClass.TEMPERATURE_SENSOR */,
					s: 33 /* ChannelSubClass.TEMPERATURE_SENSOR_THERMOCOUPLE */,
					n: 4 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETTHERMOCOUPLETYPE,
						BP.TEMPERATURECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._TMP1101_TEMPERATURESENSOR_IC_100,
					t: "Temperature Sensor (IC)",
					c: 28 /* ChannelClass.TEMPERATURE_SENSOR */,
					i: 4 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.TEMPERATURECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._TMP1101_VOLTAGEINPUT_100,
					t: "Voltage Input",
					c: 29 /* ChannelClass.VOLTAGE_INPUT */,
					n: 4 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.VOLTAGECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._TMP1101_1,
			d: 89 /* DeviceID.PN_TMP1101 */,
			c: 21 /* DeviceClass.VINT */,
			t: "4x Thermocouple Phidget",
			s: "TMP1101",
			i: 143 /* VintID */,
			v: [200, 300],
			ch: [
				{
					uid: DeviceChannelUID._TMP1101_TEMPERATURESENSOR_THERMOCOUPLE_200,
					t: "Thermocouple Input",
					c: 28 /* ChannelClass.TEMPERATURE_SENSOR */,
					s: 33 /* ChannelSubClass.TEMPERATURE_SENSOR_THERMOCOUPLE */,
					n: 4 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETTHERMOCOUPLETYPE,
						BP.TEMPERATURECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._TMP1101_TEMPERATURESENSOR_IC_200,
					t: "Temperature Sensor (IC)",
					c: 28 /* ChannelClass.TEMPERATURE_SENSOR */,
					i: 4 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.TEMPERATURECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._TMP1101_VOLTAGEINPUT_200,
					t: "Voltage Input",
					c: 29 /* ChannelClass.VOLTAGE_INPUT */,
					n: 4 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.VOLTAGECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._TMP1200,
			d: 90 /* DeviceID.PN_TMP1200 */,
			c: 21 /* DeviceClass.VINT */,
			t: "RTD Phidget",
			s: "TMP1200",
			i: 16 /* VintID */,
			v: [100, 200],
			ch: [
				{
					uid: DeviceChannelUID._TMP1200_TEMPERATURESENSOR_RTD_100,
					t: "RTD input",
					c: 28 /* ChannelClass.TEMPERATURE_SENSOR */,
					s: 32 /* ChannelSubClass.TEMPERATURE_SENSOR_RTD */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.RTDWIRESETUPCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETRTDTYPE,
						BP.SETRTDWIRESETUP,
						BP.TEMPERATURECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._TMP1200_RESISTANCEINPUT_100,
					t: "Resistance Input",
					c: 23 /* ChannelClass.RESISTANCE_INPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.RTDWIRESETUPCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETRTDWIRESETUP,
						BP.RESISTANCECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._TMP1200_1,
			d: 90 /* DeviceID.PN_TMP1200 */,
			c: 21 /* DeviceClass.VINT */,
			t: "RTD Phidget",
			s: "TMP1200",
			i: 78 /* VintID */,
			v: [300, 400],
			ch: [
				{
					uid: DeviceChannelUID._TMP1200_TEMPERATURESENSOR_RTD_300,
					t: "RTD input",
					c: 28 /* ChannelClass.TEMPERATURE_SENSOR */,
					s: 32 /* ChannelSubClass.TEMPERATURE_SENSOR_RTD */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.RTDWIRESETUPCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETRTDTYPE,
						BP.SETRTDWIRESETUP,
						BP.TEMPERATURECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._TMP1200_RESISTANCEINPUT_300,
					t: "Resistance Input",
					c: 23 /* ChannelClass.RESISTANCE_INPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.RTDWIRESETUPCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETRTDWIRESETUP,
						BP.RESISTANCECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._TMP1200_2,
			d: 90 /* DeviceID.PN_TMP1200 */,
			c: 21 /* DeviceClass.VINT */,
			t: "RTD Phidget",
			s: "TMP1200",
			i: 79 /* VintID */,
			v: [400, 500],
			ch: [
				{
					uid: DeviceChannelUID._TMP1200_TEMPERATURESENSOR_RTD_400,
					t: "RTD input",
					c: 28 /* ChannelClass.TEMPERATURE_SENSOR */,
					s: 32 /* ChannelSubClass.TEMPERATURE_SENSOR_RTD */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.RTDWIRESETUPCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETRTDTYPE,
						BP.SETRTDWIRESETUP,
						BP.TEMPERATURECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._TMP1200_RESISTANCEINPUT_400,
					t: "Resistance Input",
					c: 23 /* ChannelClass.RESISTANCE_INPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.RTDWIRESETUPCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETRTDWIRESETUP,
						BP.RESISTANCECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._VCP1000,
			d: 92 /* DeviceID.PN_VCP1000 */,
			c: 21 /* DeviceClass.VINT */,
			t: "20-bit (+-40V) Voltage Input Phidget",
			s: "VCP1000",
			i: 53 /* VintID */,
			v: [100, 200],
			ch: [
				{
					uid: DeviceChannelUID._VCP1000_VOLTAGEINPUT_100,
					t: "Voltage Input",
					c: 29 /* ChannelClass.VOLTAGE_INPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETVOLTAGERANGE,
						BP.VOLTAGECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._VCP1001,
			d: 93 /* DeviceID.PN_VCP1001 */,
			c: 21 /* DeviceClass.VINT */,
			t: "10-bit (+-40V) Voltage Input Phidget",
			s: "VCP1001",
			i: 31 /* VintID */,
			v: [100, 110],
			ch: [
				{
					uid: DeviceChannelUID._VCP1001_VOLTAGEINPUT_100,
					t: "Voltage Input",
					c: 29 /* ChannelClass.VOLTAGE_INPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETVOLTAGERANGE,
						BP.VOLTAGECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._VCP1001_110,
			d: 93 /* DeviceID.PN_VCP1001 */,
			c: 21 /* DeviceClass.VINT */,
			t: "10-bit (+-40V) Voltage Input Phidget",
			s: "VCP1001",
			i: 31 /* VintID */,
			v: [110, 200],
			ch: [
				{
					uid: DeviceChannelUID._VCP1001_VOLTAGEINPUT_110,
					t: "Voltage Input",
					c: 29 /* ChannelClass.VOLTAGE_INPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETVOLTAGERANGE,
						BP.VOLTAGECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._VCP1002,
			d: 94 /* DeviceID.PN_VCP1002 */,
			c: 21 /* DeviceClass.VINT */,
			t: "10-bit (+-1V) Voltage Input Phidget",
			s: "VCP1002",
			i: 30 /* VintID */,
			v: [100, 110],
			ch: [
				{
					uid: DeviceChannelUID._VCP1002_VOLTAGEINPUT_100,
					t: "Voltage Input",
					c: 29 /* ChannelClass.VOLTAGE_INPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETVOLTAGERANGE,
						BP.VOLTAGECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._VCP1002_110,
			d: 94 /* DeviceID.PN_VCP1002 */,
			c: 21 /* DeviceClass.VINT */,
			t: "10-bit (+-1V) Voltage Input Phidget",
			s: "VCP1002",
			i: 30 /* VintID */,
			v: [110, 200],
			ch: [
				{
					uid: DeviceChannelUID._VCP1002_VOLTAGEINPUT_110,
					t: "Voltage Input",
					c: 29 /* ChannelClass.VOLTAGE_INPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETVOLTAGERANGE,
						BP.VOLTAGECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._FIRMWARE_UPGRADE_STM32F0,
			d: 102 /* DeviceID.FIRMWARE_UPGRADE_STM32F0 */,
			c: 21 /* DeviceClass.VINT */,
			t: "VINT Firmware Upgrade (STM32F0)",
			s: "FIRMWARE_UPGRADE_STM32F0",
			i: 4093 /* VintID */,
			v: [100, 110],
			ch: [
				{
					uid: DeviceChannelUID._STM32F0_FIRMWARE_UPGRADE_100,
					t: "Firmware Upgrade",
					c: 32 /* ChannelClass.FIRMWARE_UPGRADE */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DEVICEINFO,
						BP.SENDFIRMWARE,
						BP.PROGRESSCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._FIRMWARE_UPGRADE_STM32G0_BLBug,
			d: 143 /* DeviceID.FIRMWARE_UPGRADE_STM32G0 */,
			c: 21 /* DeviceClass.VINT */,
			t: "VINT Firmware Upgrade (STM32G0)",
			s: "FIRMWARE_UPGRADE_STM32G0",
			i: 4093 /* VintID */,
			v: [110, 114],
			ch: [
				{
					uid: DeviceChannelUID._STM32G0_FIRMWARE_UPGRADE_110,
					t: "Firmware Upgrade",
					c: 32 /* ChannelClass.FIRMWARE_UPGRADE */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DEVICEINFO,
						BP.SENDFIRMWARE,
						BP.PROGRESSCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._FIRMWARE_UPGRADE_STM32G0,
			d: 143 /* DeviceID.FIRMWARE_UPGRADE_STM32G0 */,
			c: 21 /* DeviceClass.VINT */,
			t: "VINT Firmware Upgrade (STM32G0)",
			s: "FIRMWARE_UPGRADE_STM32G0",
			i: 4093 /* VintID */,
			v: [114, 120],
			ch: [
				{
					uid: DeviceChannelUID._STM32G0_FIRMWARE_UPGRADE_114,
					t: "Firmware Upgrade",
					c: 32 /* ChannelClass.FIRMWARE_UPGRADE */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DEVICEINFO,
						BP.SENDFIRMWARE,
						BP.PROGRESSCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._FIRMWARE_UPGRADE_STM32F3,
			d: 145 /* DeviceID.FIRMWARE_UPGRADE_STM32F3 */,
			c: 21 /* DeviceClass.VINT */,
			t: "VINT Firmware Upgrade (STM32F3)",
			s: "FIRMWARE_UPGRADE_STM32F3",
			i: 4093 /* VintID */,
			v: [120, 200],
			ch: [
				{
					uid: DeviceChannelUID._STM32F3_FIRMWARE_UPGRADE_120,
					t: "Firmware Upgrade",
					c: 32 /* ChannelClass.FIRMWARE_UPGRADE */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DEVICEINFO,
						BP.SENDFIRMWARE,
						BP.PROGRESSCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._FIRMWARE_UPGRADE_STM8S,
			d: 103 /* DeviceID.FIRMWARE_UPGRADE_STM8S */,
			c: 21 /* DeviceClass.VINT */,
			t: "VINT Firmware Upgrade (STM8S)",
			s: "FIRMWARE_UPGRADE_STM8S",
			i: 4094 /* VintID */,
			v: [100, 200],
			ch: [
				{
					uid: DeviceChannelUID._STM8S_FIRMWARE_UPGRADE_100,
					t: "Firmware Upgrade",
					c: 32 /* ChannelClass.FIRMWARE_UPGRADE */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DEVICEINFO,
						BP.SENDFIRMWARE,
						BP.PROGRESSCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._UNKNOWNVINT,
			d: 125 /* DeviceID.UNKNOWN */,
			c: 21 /* DeviceClass.VINT */,
			t: "Unsupported VINT Phidget",
			s: " ",
			i: 4080 /* VintID */,
			v: [0, 9999],
			ch: [
				{
					uid: DeviceChannelUID._VINT_UNKNOWN,
					t: "Unsupported VINT Channel",
					c: 33 /* ChannelClass.GENERIC */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SENDPACKET,
						BP.SENDCHPACKET,
						BP.SENDDEVPACKET,
						BP.READCHPACKET,
						BP.READDEVPACKET,
						BP.PACKET,
					]
				},
			]
		},
	],
	USB: [
		{
			uid: DeviceUID._IFKIT488,
			d: 1 /* DeviceID.PN_INTERFACE_KIT488 */,
			c: 9 /* DeviceClass.INTERFACE_KIT */,
			t: "PhidgetInterfaceKit 4/8/8",
			s: "ifkit488",
			i: 33281 /* USB ProductID */,
			v: [0, 101],
			cn: [0,4,8,8] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._ifkit488_VOLTAGERATIOINPUT_000,
					t: "Voltage Ratio Input",
					c: 31 /* ChannelClass.VOLTAGE_RATIO_INPUT */,
					s: 64 /* ChannelSubClass.VOLTAGE_RATIO_INPUT_SENSOR_PORT */,
					n: 4 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.MINDATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETSENSORTYPE,
						BP.SETCHANGETRIGGER,
						BP.SETSENSORVALUECHANGETRIGGER,
						BP.VOLTAGERATIOCHANGE,
						BP.SENSORCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._ifkit488_DIGITALINPUT_000,
					t: "Digital Input",
					c: 5 /* ChannelClass.DIGITAL_INPUT */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.STATECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._ifkit488_DIGITALOUTPUT_000,
					t: "Digital Output",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETSTATE,
						BP.SETDUTYCYCLE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1000_OLD1,
			d: 2 /* DeviceID.PN_1000 */,
			c: 16 /* DeviceClass.SERVO */,
			t: "PhidgetServo 1-Motor",
			s: "1000",
			i: 33025 /* USB ProductID */,
			v: [200, 201],
			cn: [1] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1000_RCSERVO_OLD1_200,
					t: "RC Servo Motor Controller",
					c: 22 /* ChannelClass.RC_SERVO */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETMINPULSEWIDTH,
						BP.SETMAXPULSEWIDTH,
						BP.SETTARGETPOSITION,
						BP.SETENGAGED,
						BP.POSITIONCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1000_OLD2,
			d: 2 /* DeviceID.PN_1000 */,
			c: 16 /* DeviceClass.SERVO */,
			t: "PhidgetServo 1-Motor",
			s: "1000",
			i: 57 /* USB ProductID */,
			v: [200, 201],
			cn: [1] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1000_RCSERVO_OLD2_200,
					t: "RC Servo Motor Controller",
					c: 22 /* ChannelClass.RC_SERVO */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETMINPULSEWIDTH,
						BP.SETMAXPULSEWIDTH,
						BP.SETTARGETPOSITION,
						BP.SETENGAGED,
						BP.POSITIONCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1000_NO_ECHO,
			d: 2 /* DeviceID.PN_1000 */,
			c: 16 /* DeviceClass.SERVO */,
			t: "PhidgetServo 1-Motor",
			s: "1000",
			i: 57 /* USB ProductID */,
			v: [300, 313],
			cn: [1] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1000_RCSERVO_300,
					t: "RC Servo Motor Controller",
					c: 22 /* ChannelClass.RC_SERVO */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETMINPULSEWIDTH,
						BP.SETMAXPULSEWIDTH,
						BP.SETTARGETPOSITION,
						BP.SETENGAGED,
						BP.POSITIONCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1000,
			d: 2 /* DeviceID.PN_1000 */,
			c: 16 /* DeviceClass.SERVO */,
			t: "PhidgetServo 1-Motor",
			s: "1000",
			i: 57 /* USB ProductID */,
			v: [313, 400],
			cn: [1] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1000_RCSERVO_313,
					t: "RC Servo Motor Controller",
					c: 22 /* ChannelClass.RC_SERVO */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETMINPULSEWIDTH,
						BP.SETMAXPULSEWIDTH,
						BP.SETTARGETPOSITION,
						BP.SETENGAGED,
						BP.POSITIONCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1001_OLD1,
			d: 3 /* DeviceID.PN_1001 */,
			c: 16 /* DeviceClass.SERVO */,
			t: "PhidgetServo 4-Motor",
			s: "1001",
			i: 33028 /* USB ProductID */,
			v: [200, 201],
			cn: [4] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1001_RCSERVO_OLD1_200,
					t: "RC Servo Motor Controller",
					c: 22 /* ChannelClass.RC_SERVO */,
					n: 4 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETMINPULSEWIDTH,
						BP.SETMAXPULSEWIDTH,
						BP.SETTARGETPOSITION,
						BP.SETENGAGED,
						BP.POSITIONCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1001_OLD2,
			d: 3 /* DeviceID.PN_1001 */,
			c: 16 /* DeviceClass.SERVO */,
			t: "PhidgetServo 4-Motor",
			s: "1001",
			i: 56 /* USB ProductID */,
			v: [200, 201],
			cn: [4] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1001_RCSERVO_OLD2_200,
					t: "RC Servo Motor Controller",
					c: 22 /* ChannelClass.RC_SERVO */,
					n: 4 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETMINPULSEWIDTH,
						BP.SETMAXPULSEWIDTH,
						BP.SETTARGETPOSITION,
						BP.SETENGAGED,
						BP.POSITIONCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1001_NO_ECHO,
			d: 3 /* DeviceID.PN_1001 */,
			c: 16 /* DeviceClass.SERVO */,
			t: "PhidgetServo 4-Motor",
			s: "1001",
			i: 56 /* USB ProductID */,
			v: [300, 313],
			cn: [4] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1001_RCSERVO_313,
					t: "RC Servo Motor Controller",
					c: 22 /* ChannelClass.RC_SERVO */,
					n: 4 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETMINPULSEWIDTH,
						BP.SETMAXPULSEWIDTH,
						BP.SETTARGETPOSITION,
						BP.SETENGAGED,
						BP.POSITIONCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1001,
			d: 3 /* DeviceID.PN_1001 */,
			c: 16 /* DeviceClass.SERVO */,
			t: "PhidgetServo 4-Motor",
			s: "1001",
			i: 56 /* USB ProductID */,
			v: [313, 400],
			cn: [4] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1001_RCSERVO_400,
					t: "RC Servo Motor Controller",
					c: 22 /* ChannelClass.RC_SERVO */,
					n: 4 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETMINPULSEWIDTH,
						BP.SETMAXPULSEWIDTH,
						BP.SETTARGETPOSITION,
						BP.SETENGAGED,
						BP.POSITIONCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1002,
			d: 4 /* DeviceID.PN_1002 */,
			c: 3 /* DeviceClass.ANALOG */,
			t: "PhidgetAnalog 4-Output",
			s: "1002",
			i: 55 /* USB ProductID */,
			v: [100, 200],
			cn: [4] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1002_VOLTAGEOUTPUT_100,
					t: "Voltage Output",
					c: 30 /* ChannelClass.VOLTAGE_OUTPUT */,
					n: 4 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETENABLED,
						BP.SETVOLTAGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1008,
			d: 5 /* DeviceID.PN_1008 */,
			c: 1 /* DeviceClass.ACCELEROMETER */,
			t: "PhidgetAccelerometer 2-Axis",
			s: "1008",
			i: 113 /* USB ProductID */,
			v: [0, 200],
			cn: [2] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1008_ACCELEROMETER_000,
					t: "2-Axis Accelerometer",
					c: 1 /* ChannelClass.ACCELEROMETER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETDATAINTERVAL,
						BP.ACCELERATIONCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1011,
			d: 7 /* DeviceID.PN_1011 */,
			c: 9 /* DeviceClass.INTERFACE_KIT */,
			t: "PhidgetInterfaceKit 2/2/2",
			s: "1011",
			i: 54 /* USB ProductID */,
			v: [0, 200],
			cn: [2,2,2,2] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1011_VOLTAGEINPUT_000,
					t: "Voltage Input",
					c: 29 /* ChannelClass.VOLTAGE_INPUT */,
					s: 48 /* ChannelSubClass.VOLTAGE_INPUT_SENSOR_PORT */,
					n: 2 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.MINDATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETSENSORTYPE,
						BP.SETCHANGETRIGGER,
						BP.SETSENSORVALUECHANGETRIGGER,
						BP.VOLTAGECHANGE,
						BP.SENSORCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1011_VOLTAGERATIOINPUT_000,
					t: "Voltage Ratio Input",
					c: 31 /* ChannelClass.VOLTAGE_RATIO_INPUT */,
					s: 64 /* ChannelSubClass.VOLTAGE_RATIO_INPUT_SENSOR_PORT */,
					n: 2 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.MINDATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETSENSORTYPE,
						BP.SETCHANGETRIGGER,
						BP.SETSENSORVALUECHANGETRIGGER,
						BP.VOLTAGERATIOCHANGE,
						BP.SENSORCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1011_DIGITALINPUT_000,
					t: "Digital Input",
					c: 5 /* ChannelClass.DIGITAL_INPUT */,
					n: 2 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.STATECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1011_DIGITALOUTPUT_000,
					t: "Digital Output",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					n: 2 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETSTATE,
						BP.SETDUTYCYCLE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1012_NO_ECHO,
			d: 8 /* DeviceID.PN_1012 */,
			c: 9 /* DeviceClass.INTERFACE_KIT */,
			t: "PhidgetInterfaceKit 0/16/16",
			s: "1012",
			i: 68 /* USB ProductID */,
			v: [0, 601],
			cn: [0,0,16,16] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1012_DIGITALINPUT_000,
					t: "Digital Input",
					c: 5 /* ChannelClass.DIGITAL_INPUT */,
					n: 16 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.STATECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1012_DIGITALOUTPUT_000,
					t: "Digital Output",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					n: 16 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETSTATE,
						BP.SETDUTYCYCLE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1012_BITBUG,
			d: 8 /* DeviceID.PN_1012 */,
			c: 9 /* DeviceClass.INTERFACE_KIT */,
			t: "PhidgetInterfaceKit 0/16/16",
			s: "1012",
			i: 68 /* USB ProductID */,
			v: [601, 602],
			cn: [0,0,16,16] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1012_DIGITALINPUT_601,
					t: "Digital Input",
					c: 5 /* ChannelClass.DIGITAL_INPUT */,
					n: 16 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.STATECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1012_DIGITALOUTPUT_601,
					t: "Digital Output",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					n: 16 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETSTATE,
						BP.SETDUTYCYCLE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1012,
			d: 8 /* DeviceID.PN_1012 */,
			c: 9 /* DeviceClass.INTERFACE_KIT */,
			t: "PhidgetInterfaceKit 0/16/16",
			s: "1012",
			i: 68 /* USB ProductID */,
			v: [602, 700],
			cn: [0,0,16,16] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1012_DIGITALINPUT_602,
					t: "Digital Input",
					c: 5 /* ChannelClass.DIGITAL_INPUT */,
					n: 16 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.STATECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1012_DIGITALOUTPUT_602,
					t: "Digital Output",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					n: 16 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETSTATE,
						BP.SETDUTYCYCLE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1013_NO_ECHO,
			d: 6 /* DeviceID.PN_1010_1013_1018_1019 */,
			c: 9 /* DeviceClass.INTERFACE_KIT */,
			t: "PhidgetInterfaceKit 8/8/8",
			s: "1013",
			i: 69 /* USB ProductID */,
			v: [0, 821],
			cn: [8,8,8,8] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1013_VOLTAGEINPUT_000,
					t: "Voltage Input",
					c: 29 /* ChannelClass.VOLTAGE_INPUT */,
					s: 48 /* ChannelSubClass.VOLTAGE_INPUT_SENSOR_PORT */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.MINDATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETSENSORTYPE,
						BP.SETCHANGETRIGGER,
						BP.SETSENSORVALUECHANGETRIGGER,
						BP.VOLTAGECHANGE,
						BP.SENSORCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1013_VOLTAGERATIOINPUT_000,
					t: "Voltage Ratio Input",
					c: 31 /* ChannelClass.VOLTAGE_RATIO_INPUT */,
					s: 64 /* ChannelSubClass.VOLTAGE_RATIO_INPUT_SENSOR_PORT */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.MINDATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETSENSORTYPE,
						BP.SETCHANGETRIGGER,
						BP.SETSENSORVALUECHANGETRIGGER,
						BP.VOLTAGERATIOCHANGE,
						BP.SENSORCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1013_DIGITALINPUT_000,
					t: "Digital Input",
					c: 5 /* ChannelClass.DIGITAL_INPUT */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.STATECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1013_DIGITALOUTPUT_000,
					t: "Digital Output",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDUTYCYCLE,
						BP.SETSTATE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1013,
			d: 6 /* DeviceID.PN_1010_1013_1018_1019 */,
			c: 9 /* DeviceClass.INTERFACE_KIT */,
			t: "PhidgetInterfaceKit 8/8/8",
			s: "1013/1018/1019",
			i: 69 /* USB ProductID */,
			v: [821, 900],
			cn: [8,8,8,8] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1018_VOLTAGEINPUT_821,
					t: "Voltage Input",
					c: 29 /* ChannelClass.VOLTAGE_INPUT */,
					s: 48 /* ChannelSubClass.VOLTAGE_INPUT_SENSOR_PORT */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.MINDATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETSENSORTYPE,
						BP.SETCHANGETRIGGER,
						BP.SETSENSORVALUECHANGETRIGGER,
						BP.VOLTAGECHANGE,
						BP.SENSORCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1018_VOLTAGERATIOINPUT_821,
					t: "Voltage Ratio Input",
					c: 31 /* ChannelClass.VOLTAGE_RATIO_INPUT */,
					s: 64 /* ChannelSubClass.VOLTAGE_RATIO_INPUT_SENSOR_PORT */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.MINDATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETSENSORTYPE,
						BP.SETCHANGETRIGGER,
						BP.SETSENSORVALUECHANGETRIGGER,
						BP.VOLTAGERATIOCHANGE,
						BP.SENSORCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1018_DIGITALINPUT_821,
					t: "Digital Input",
					c: 5 /* ChannelClass.DIGITAL_INPUT */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.STATECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1018_DIGITALOUTPUT_821,
					t: "Digital Output",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETSTATE,
						BP.SETDUTYCYCLE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1014_NO_ECHO,
			d: 9 /* DeviceID.PN_1014 */,
			c: 9 /* DeviceClass.INTERFACE_KIT */,
			t: "PhidgetInterfaceKit 0/0/4",
			s: "1014",
			i: 64 /* USB ProductID */,
			v: [0, 704],
			cn: [0,0,0,4] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1014_DIGITALOUTPUT_000,
					t: "Power Relay",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					n: 4 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETSTATE,
						BP.SETDUTYCYCLE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1014,
			d: 9 /* DeviceID.PN_1014 */,
			c: 9 /* DeviceClass.INTERFACE_KIT */,
			t: "PhidgetInterfaceKit 0/0/4",
			s: "1014",
			i: 64 /* USB ProductID */,
			v: [704, 800],
			cn: [0,0,0,4] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1014_DIGITALOUTPUT_704,
					t: "Power Relay",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					n: 4 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETSTATE,
						BP.SETDUTYCYCLE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1014_3_USB,
			d: 9 /* DeviceID.PN_1014 */,
			c: 9 /* DeviceClass.INTERFACE_KIT */,
			t: "PhidgetInterfaceKit 0/0/4",
			s: "1014",
			i: 64 /* USB ProductID */,
			v: [800, 900],
			cn: [0,0,0,4] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1014_DIGITALOUTPUT_800_USB,
					t: "Power Relay",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					n: 4 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETSTATE,
						BP.SETDUTYCYCLE,
						BP.FAILSAFERESET,
						BP.SETFAILSAFETIME,
					]
				},
			]
		},
		{
			uid: DeviceUID._1015,
			d: 10 /* DeviceID.PN_1015 */,
			c: 9 /* DeviceClass.INTERFACE_KIT */,
			t: "PhidgetLinearTouch",
			s: "1015",
			i: 118 /* USB ProductID */,
			v: [0, 9999],
			cn: [0,0,0,0,1] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1015_CAPACITIVETOUCH_000,
					t: "Capacitive Slider Sensor",
					c: 14 /* ChannelClass.CAPACITIVE_TOUCH */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETDATAINTERVAL,
						BP.TOUCHINPUTVALUECHANGE,
						BP.TOUCHINPUTEND,
					]
				},
			]
		},
		{
			uid: DeviceUID._1016,
			d: 11 /* DeviceID.PN_1016 */,
			c: 9 /* DeviceClass.INTERFACE_KIT */,
			t: "PhidgetCircularTouch",
			s: "1016",
			i: 119 /* USB ProductID */,
			v: [0, 9999],
			cn: [0,0,0,0,1] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1016_CAPACITIVETOUCH_000,
					t: "Capacitive Scroll Sensor",
					c: 14 /* ChannelClass.CAPACITIVE_TOUCH */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETDATAINTERVAL,
						BP.TOUCHINPUTVALUECHANGE,
						BP.TOUCHINPUTEND,
					]
				},
			]
		},
		{
			uid: DeviceUID._1017,
			d: 12 /* DeviceID.PN_1017 */,
			c: 9 /* DeviceClass.INTERFACE_KIT */,
			t: "PhidgetInterfaceKit 0/0/8",
			s: "1017",
			i: 129 /* USB ProductID */,
			v: [0, 9999],
			cn: [0,0,0,8] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1017_DIGITALOUTPUT_000,
					t: "Signal Relay",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETSTATE,
						BP.SETDUTYCYCLE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1017_2_USB,
			d: 12 /* DeviceID.PN_1017 */,
			c: 9 /* DeviceClass.INTERFACE_KIT */,
			t: "PhidgetInterfaceKit 0/0/8",
			s: "1017",
			i: 135 /* USB ProductID */,
			v: [200, 300],
			cn: [0,0,0,8] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1017_DIGITALOUTPUT_200_USB,
					t: "Signal Relay",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETSTATE,
						BP.SETDUTYCYCLE,
						BP.FAILSAFERESET,
						BP.SETFAILSAFETIME,
					]
				},
			]
		},
		{
			uid: DeviceUID._1018,
			d: 6 /* DeviceID.PN_1010_1013_1018_1019 */,
			c: 9 /* DeviceClass.INTERFACE_KIT */,
			t: "PhidgetInterfaceKit 8/8/8",
			s: "1010/1018/1019",
			i: 69 /* USB ProductID */,
			v: [900, 1000],
			cn: [8,8,8,8] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1018_VOLTAGEINPUT_900,
					t: "Voltage Input",
					c: 29 /* ChannelClass.VOLTAGE_INPUT */,
					s: 48 /* ChannelSubClass.VOLTAGE_INPUT_SENSOR_PORT */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.MINDATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETSENSORTYPE,
						BP.SETCHANGETRIGGER,
						BP.SETSENSORVALUECHANGETRIGGER,
						BP.VOLTAGECHANGE,
						BP.SENSORCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1018_VOLTAGERATIOINPUT_900,
					t: "Voltage Ratio Input",
					c: 31 /* ChannelClass.VOLTAGE_RATIO_INPUT */,
					s: 64 /* ChannelSubClass.VOLTAGE_RATIO_INPUT_SENSOR_PORT */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.MINDATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETSENSORTYPE,
						BP.SETCHANGETRIGGER,
						BP.SETSENSORVALUECHANGETRIGGER,
						BP.VOLTAGERATIOCHANGE,
						BP.SENSORCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1018_DIGITALINPUT_900,
					t: "Digital Input",
					c: 5 /* ChannelClass.DIGITAL_INPUT */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.STATECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1018_DIGITALOUTPUT_900,
					t: "Digital Output",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETSTATE,
						BP.SETDUTYCYCLE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1018_3,
			d: 6 /* DeviceID.PN_1010_1013_1018_1019 */,
			c: 9 /* DeviceClass.INTERFACE_KIT */,
			t: "PhidgetInterfaceKit 8/8/8",
			s: "1010/1018/1019",
			i: 69 /* USB ProductID */,
			v: [1000, 1100],
			cn: [8,8,8,8] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1018_VOLTAGEINPUT_1000,
					t: "Voltage Input",
					c: 29 /* ChannelClass.VOLTAGE_INPUT */,
					s: 48 /* ChannelSubClass.VOLTAGE_INPUT_SENSOR_PORT */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.MINDATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETSENSORTYPE,
						BP.SETCHANGETRIGGER,
						BP.SETSENSORVALUECHANGETRIGGER,
						BP.VOLTAGECHANGE,
						BP.SENSORCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1018_VOLTAGERATIOINPUT_1000,
					t: "Voltage Ratio Input",
					c: 31 /* ChannelClass.VOLTAGE_RATIO_INPUT */,
					s: 64 /* ChannelSubClass.VOLTAGE_RATIO_INPUT_SENSOR_PORT */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.MINDATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETSENSORTYPE,
						BP.SETCHANGETRIGGER,
						BP.SETSENSORVALUECHANGETRIGGER,
						BP.VOLTAGERATIOCHANGE,
						BP.SENSORCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1018_DIGITALINPUT_1000,
					t: "Digital Input",
					c: 5 /* ChannelClass.DIGITAL_INPUT */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.STATECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1018_DIGITALOUTPUT_1000,
					t: "Digital Output",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETSTATE,
						BP.SETDUTYCYCLE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1023_OLD,
			d: 13 /* DeviceID.PN_1023 */,
			c: 15 /* DeviceClass.RFID */,
			t: "PhidgetRFID",
			s: "1023",
			i: 48 /* USB ProductID */,
			v: [0, 104],
			cn: [0] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1023_RFID_000,
					t: "RFID Reader",
					c: 24 /* ChannelClass.RFID */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.TAG,
						BP.TAGLOST,
					]
				},
			]
		},
		{
			uid: DeviceUID._1023,
			d: 13 /* DeviceID.PN_1023 */,
			c: 15 /* DeviceClass.RFID */,
			t: "PhidgetRFID",
			s: "1023",
			i: 48 /* USB ProductID */,
			v: [104, 200],
			cn: [0] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1023_RFID_104,
					t: "RFID Reader",
					c: 24 /* ChannelClass.RFID */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.TAG,
						BP.TAGLOST,
					]
				},
			]
		},
		{
			uid: DeviceUID._1023_2OUTPUT_NO_ECHO,
			d: 13 /* DeviceID.PN_1023 */,
			c: 15 /* DeviceClass.RFID */,
			t: "PhidgetRFID",
			s: "1023",
			i: 49 /* USB ProductID */,
			v: [200, 201],
			cn: [3] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1023_RFID_200,
					t: "RFID Reader",
					c: 24 /* ChannelClass.RFID */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETANTENNAON,
						BP.TAG,
						BP.TAGLOST,
					]
				},
				{
					uid: DeviceChannelUID._1023_DIGITALOUTPUT_5V_200,
					t: "Digital Output",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETSTATE,
						BP.SETDUTYCYCLE,
					]
				},
				{
					uid: DeviceChannelUID._1023_DIGITALOUTPUT_LED_200,
					t: "LED Driver",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					i: 1 /* Index */,
					p: /* Supported Packets */ [
						BP.SETSTATE,
						BP.SETDUTYCYCLE,
					]
				},
				{
					uid: DeviceChannelUID._1023_DIGITALOUTPUT_ONBOARD_LED_200,
					t: "Onboard LED",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					i: 2 /* Index */,
					p: /* Supported Packets */ [
						BP.SETSTATE,
						BP.SETDUTYCYCLE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1023_2OUTPUT,
			d: 13 /* DeviceID.PN_1023 */,
			c: 15 /* DeviceClass.RFID */,
			t: "PhidgetRFID",
			s: "1023",
			i: 49 /* USB ProductID */,
			v: [201, 300],
			cn: [3] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1023_RFID_201,
					t: "RFID Reader",
					c: 24 /* ChannelClass.RFID */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETANTENNAON,
						BP.TAG,
						BP.TAGLOST,
					]
				},
				{
					uid: DeviceChannelUID._1023_DIGITALOUTPUT_5V_201,
					t: "Digital Output",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETSTATE,
						BP.SETDUTYCYCLE,
					]
				},
				{
					uid: DeviceChannelUID._1023_DIGITALOUTPUT_LED_201,
					t: "LED Driver",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					i: 1 /* Index */,
					p: /* Supported Packets */ [
						BP.SETSTATE,
						BP.SETDUTYCYCLE,
					]
				},
				{
					uid: DeviceChannelUID._1023_DIGITALOUTPUT_ONBOARD_LED_201,
					t: "Onboard LED",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					i: 2 /* Index */,
					p: /* Supported Packets */ [
						BP.SETSTATE,
						BP.SETDUTYCYCLE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1024,
			d: 14 /* DeviceID.PN_1024 */,
			c: 15 /* DeviceClass.RFID */,
			t: "PhidgetRFID Read-Write",
			s: "1024",
			i: 52 /* USB ProductID */,
			v: [100, 200],
			cn: [3] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1024_RFID_100,
					t: "RFID Reader/Writer",
					c: 24 /* ChannelClass.RFID */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETANTENNAON,
						BP.WRITE,
						BP.TAG,
						BP.TAGLOST,
					]
				},
				{
					uid: DeviceChannelUID._1024_DIGITALOUTPUT_5V_100,
					t: "Digital Output",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETSTATE,
						BP.SETDUTYCYCLE,
					]
				},
				{
					uid: DeviceChannelUID._1024_DIGITALOUTPUT_LED_100,
					t: "LED Driver",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					i: 1 /* Index */,
					p: /* Supported Packets */ [
						BP.SETSTATE,
						BP.SETDUTYCYCLE,
					]
				},
				{
					uid: DeviceChannelUID._1024_DIGITALOUTPUT_ONBOARD_LED_100,
					t: "Onboard LED",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					i: 2 /* Index */,
					p: /* Supported Packets */ [
						BP.SETSTATE,
						BP.SETDUTYCYCLE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1030,
			d: 15 /* DeviceID.PN_1030 */,
			c: 11 /* DeviceClass.LED */,
			t: "PhidgetLED-64",
			s: "1030",
			i: 74 /* USB ProductID */,
			v: [100, 300],
			cn: [64] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1030_DIGITALOUTPUT_100,
					t: "LED Driver",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					s: 17 /* ChannelSubClass.DIGITAL_OUTPUT_LEDDRIVER */,
					n: 64 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDUTYCYCLE,
						BP.SETSTATE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1031,
			d: 16 /* DeviceID.PN_1031 */,
			c: 11 /* DeviceClass.LED */,
			t: "PhidgetLED-64 Advanced",
			s: "1031",
			i: 76 /* USB ProductID */,
			v: [100, 200],
			cn: [64] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1031_DIGITALOUTPUT_100,
					t: "LED Driver",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					s: 17 /* ChannelSubClass.DIGITAL_OUTPUT_LEDDRIVER */,
					n: 64 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDUTYCYCLE,
						BP.SETLEDCURRENTLIMIT,
						BP.SETLEDFORWARDVOLTAGE,
						BP.SETSTATE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1032,
			d: 17 /* DeviceID.PN_1032 */,
			c: 11 /* DeviceClass.LED */,
			t: "PhidgetLED-64 Advanced",
			s: "1032",
			i: 76 /* USB ProductID */,
			v: [200, 300],
			cn: [64] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1032_DIGITALOUTPUT_200,
					t: "LED Driver",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					s: 17 /* ChannelSubClass.DIGITAL_OUTPUT_LEDDRIVER */,
					n: 64 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDUTYCYCLE,
						BP.SETLEDCURRENTLIMIT,
						BP.SETLEDFORWARDVOLTAGE,
						BP.SETSTATE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1040,
			d: 18 /* DeviceID.PN_1040 */,
			c: 7 /* DeviceClass.GPS */,
			t: "PhidgetGPS",
			s: "1040",
			i: 121 /* USB ProductID */,
			v: [0, 9999],
			cn: [1] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1040_GPS_000,
					t: "GPS",
					c: 10 /* ChannelClass.GPS */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATA,
						BP.DATE,
						BP.TIME,
						BP.POSITIONCHANGE,
						BP.HEADINGCHANGE,
						BP.POSITIONFIXSTATUSCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1041,
			d: 19 /* DeviceID.PN_1041 */,
			c: 17 /* DeviceClass.SPATIAL */,
			t: "PhidgetSpatial 0/0/3 Basic",
			s: "1041",
			i: 127 /* USB ProductID */,
			v: [200, 300],
			cn: [3,0,0] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1041_ACCELEROMETER_200,
					t: "3-Axis Accelerometer",
					c: 1 /* ChannelClass.ACCELEROMETER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.ACCELERATIONCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1042,
			d: 20 /* DeviceID.PN_1042 */,
			c: 17 /* DeviceClass.SPATIAL */,
			t: "PhidgetSpatial 3/3/3 Basic",
			s: "1042",
			i: 51 /* USB ProductID */,
			v: [300, 400],
			cn: [3,3,3,1] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1042_ACCELEROMETER_300,
					t: "3-Axis Accelerometer",
					c: 1 /* ChannelClass.ACCELEROMETER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.ACCELERATIONCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1042_GYROSCOPE_300,
					t: "3-Axis Gyroscope",
					c: 12 /* ChannelClass.GYROSCOPE */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.ZERO,
						BP.ANGULARRATEUPDATE,
					]
				},
				{
					uid: DeviceChannelUID._1042_MAGNETOMETER_300,
					t: "3-Axis Magnetometer",
					c: 18 /* ChannelClass.MAGNETOMETER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETCORRECTIONPARAMETERS,
						BP.SAVECORRECTIONPARAMETERS,
						BP.RESETCORRECTIONPARAMETERS,
						BP.FIELDSTRENGTHCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1042_SPATIAL_300,
					t: "Spatial",
					c: 26 /* ChannelClass.SPATIAL */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.ZERO,
						BP.SETCORRECTIONPARAMETERS,
						BP.SAVECORRECTIONPARAMETERS,
						BP.RESETCORRECTIONPARAMETERS,
						BP.SPATIALDATA,
					]
				},
			]
		},
		{
			uid: DeviceUID._1043,
			d: 21 /* DeviceID.PN_1043 */,
			c: 17 /* DeviceClass.SPATIAL */,
			t: "PhidgetSpatial Precision 0/0/3 High Resolution",
			s: "1043",
			i: 127 /* USB ProductID */,
			v: [300, 400],
			cn: [3,0,0] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1043_ACCELEROMETER_300,
					t: "3-Axis Accelerometer",
					c: 1 /* ChannelClass.ACCELEROMETER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETSPATIALPRECISION,
						BP.ACCELERATIONCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1044,
			d: 22 /* DeviceID.PN_1044 */,
			c: 17 /* DeviceClass.SPATIAL */,
			t: "PhidgetSpatial Precision 3/3/3 High Resolution",
			s: "1044",
			i: 51 /* USB ProductID */,
			v: [400, 500],
			cn: [3,3,3,1] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1044_ACCELEROMETER_400,
					t: "3-Axis Accelerometer",
					c: 1 /* ChannelClass.ACCELEROMETER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETSPATIALPRECISION,
						BP.ACCELERATIONCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1044_GYROSCOPE_400,
					t: "3-Axis Gyroscope",
					c: 12 /* ChannelClass.GYROSCOPE */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETSPATIALPRECISION,
						BP.ZERO,
						BP.ANGULARRATEUPDATE,
					]
				},
				{
					uid: DeviceChannelUID._1044_MAGNETOMETER_400,
					t: "3-Axis Magnetometer",
					c: 18 /* ChannelClass.MAGNETOMETER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETCORRECTIONPARAMETERS,
						BP.SAVECORRECTIONPARAMETERS,
						BP.RESETCORRECTIONPARAMETERS,
						BP.FIELDSTRENGTHCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1044_SPATIAL_400,
					t: "Spatial",
					c: 26 /* ChannelClass.SPATIAL */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETSPATIALPRECISION,
						BP.ZERO,
						BP.SETCORRECTIONPARAMETERS,
						BP.SAVECORRECTIONPARAMETERS,
						BP.RESETCORRECTIONPARAMETERS,
						BP.SPATIALDATA,
					]
				},
			]
		},
		{
			uid: DeviceUID._1044_1,
			d: 22 /* DeviceID.PN_1044 */,
			c: 17 /* DeviceClass.SPATIAL */,
			t: "PhidgetSpatial Precision 3/3/3 High Resolution",
			s: "1044",
			i: 51 /* USB ProductID */,
			v: [500, 510],
			cn: [3,3,3,1] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1044_ACCELEROMETER_500,
					t: "3-Axis Accelerometer",
					c: 1 /* ChannelClass.ACCELEROMETER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETSPATIALPRECISION,
						BP.ACCELERATIONCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1044_GYROSCOPE_500,
					t: "3-Axis Gyroscope",
					c: 12 /* ChannelClass.GYROSCOPE */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETSPATIALPRECISION,
						BP.ZERO,
						BP.ANGULARRATEUPDATE,
					]
				},
				{
					uid: DeviceChannelUID._1044_MAGNETOMETER_500,
					t: "3-Axis Magnetometer",
					c: 18 /* ChannelClass.MAGNETOMETER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETCORRECTIONPARAMETERS,
						BP.SAVECORRECTIONPARAMETERS,
						BP.RESETCORRECTIONPARAMETERS,
						BP.FIELDSTRENGTHCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1044_SPATIAL_500,
					t: "Spatial",
					c: 26 /* ChannelClass.SPATIAL */,
					s: 112 /* ChannelSubClass.SPATIAL_AHRS */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETSPATIALPRECISION,
						BP.SETSPATIALALGORITHM,
						BP.SETSPATIALALGORITHMMAGGAIN,
						BP.ZERO,
						BP.SETCORRECTIONPARAMETERS,
						BP.SAVECORRECTIONPARAMETERS,
						BP.RESETCORRECTIONPARAMETERS,
						BP.ZEROSPATIALALGORITHM,
						BP.SPATIALDATA,
						BP.SPATIALALGDATA,
					]
				},
			]
		},
		{
			uid: DeviceUID._1044_1_510,
			d: 22 /* DeviceID.PN_1044 */,
			c: 17 /* DeviceClass.SPATIAL */,
			t: "PhidgetSpatial Precision 3/3/3 High Resolution",
			s: "1044",
			i: 51 /* USB ProductID */,
			v: [510, 600],
			cn: [3,3,3,1] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1044_ACCELEROMETER_510,
					t: "3-Axis Accelerometer",
					c: 1 /* ChannelClass.ACCELEROMETER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETSPATIALPRECISION,
						BP.ACCELERATIONCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1044_GYROSCOPE_510,
					t: "3-Axis Gyroscope",
					c: 12 /* ChannelClass.GYROSCOPE */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETSPATIALPRECISION,
						BP.ZERO,
						BP.ANGULARRATEUPDATE,
					]
				},
				{
					uid: DeviceChannelUID._1044_MAGNETOMETER_510,
					t: "3-Axis Magnetometer",
					c: 18 /* ChannelClass.MAGNETOMETER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETCORRECTIONPARAMETERS,
						BP.SAVECORRECTIONPARAMETERS,
						BP.RESETCORRECTIONPARAMETERS,
						BP.FIELDSTRENGTHCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1044_SPATIAL_510,
					t: "Spatial",
					c: 26 /* ChannelClass.SPATIAL */,
					s: 112 /* ChannelSubClass.SPATIAL_AHRS */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETSPATIALPRECISION,
						BP.SETSPATIALALGORITHM,
						BP.SETSPATIALALGORITHMMAGGAIN,
						BP.ZERO,
						BP.SETCORRECTIONPARAMETERS,
						BP.SAVECORRECTIONPARAMETERS,
						BP.RESETCORRECTIONPARAMETERS,
						BP.SETAHRSPARAMETERS,
						BP.ZEROSPATIALALGORITHM,
						BP.SPATIALDATA,
						BP.SPATIALALGDATA,
					]
				},
			]
		},
		{
			uid: DeviceUID._1045,
			d: 23 /* DeviceID.PN_1045 */,
			c: 19 /* DeviceClass.TEMPERATURE_SENSOR */,
			t: "PhidgetTemperatureSensor IR",
			s: "1045",
			i: 60 /* USB ProductID */,
			v: [100, 200],
			cn: [2,0] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1045_TEMPERATURESENSOR_IR_100,
					t: "Temperature Sensor (IR)",
					c: 28 /* ChannelClass.TEMPERATURE_SENSOR */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETDATAINTERVAL,
						BP.TEMPERATURECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1045_TEMPERATURESENSOR_IC_100,
					t: "Temperature Sensor (IC)",
					c: 28 /* ChannelClass.TEMPERATURE_SENSOR */,
					i: 1 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETDATAINTERVAL,
						BP.TEMPERATURECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1046_GAINBUG,
			d: 24 /* DeviceID.PN_1046 */,
			c: 4 /* DeviceClass.BRIDGE */,
			t: "PhidgetBridge 4-Input",
			s: "1046",
			i: 59 /* USB ProductID */,
			v: [100, 102],
			cn: [4] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1046_VOLTAGERATIOINPUT_100,
					t: "Bridge Input",
					c: 31 /* ChannelClass.VOLTAGE_RATIO_INPUT */,
					s: 65 /* ChannelSubClass.VOLTAGE_RATIO_INPUT_BRIDGE */,
					n: 4 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.SETENABLED,
						BP.SETBRIDGEGAIN,
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.VOLTAGERATIOCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1046,
			d: 24 /* DeviceID.PN_1046 */,
			c: 4 /* DeviceClass.BRIDGE */,
			t: "PhidgetBridge 4-Input",
			s: "1046",
			i: 59 /* USB ProductID */,
			v: [102, 200],
			cn: [4] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1046_VOLTAGERATIOINPUT_102,
					t: "Bridge Input",
					c: 31 /* ChannelClass.VOLTAGE_RATIO_INPUT */,
					s: 65 /* ChannelSubClass.VOLTAGE_RATIO_INPUT_BRIDGE */,
					n: 4 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.SETENABLED,
						BP.SETBRIDGEGAIN,
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.VOLTAGERATIOCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1046_1,
			d: 24 /* DeviceID.PN_1046 */,
			c: 4 /* DeviceClass.BRIDGE */,
			t: "PhidgetBridge 4-Input",
			s: "1046",
			i: 59 /* USB ProductID */,
			v: [200, 300],
			cn: [4] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1046_VOLTAGERATIOINPUT_200,
					t: "Bridge Input",
					c: 31 /* ChannelClass.VOLTAGE_RATIO_INPUT */,
					s: 65 /* ChannelSubClass.VOLTAGE_RATIO_INPUT_BRIDGE */,
					n: 4 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.SETENABLED,
						BP.SETBRIDGEGAIN,
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.VOLTAGERATIOCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1047,
			d: 25 /* DeviceID.PN_1047 */,
			c: 5 /* DeviceClass.ENCODER */,
			t: "PhidgetEncoder HighSpeed 4-Input",
			s: "1047",
			i: 79 /* USB ProductID */,
			v: [100, 200],
			cn: [4,4] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1047_ENCODER_100,
					t: "Encoder Input",
					c: 8 /* ChannelClass.ENCODER */,
					n: 4 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETENABLED,
						BP.SETDATAINTERVAL,
						BP.POSITIONCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1047_DIGITALINPUT_100,
					t: "Digital Input",
					c: 5 /* ChannelClass.DIGITAL_INPUT */,
					n: 4 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.STATECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1047_2,
			d: 25 /* DeviceID.PN_1047 */,
			c: 5 /* DeviceClass.ENCODER */,
			t: "PhidgetEncoder HighSpeed 4-Input",
			s: "1047",
			i: 79 /* USB ProductID */,
			v: [200, 300],
			cn: [4,4] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1047_ENCODER_200,
					t: "Encoder Input",
					c: 8 /* ChannelClass.ENCODER */,
					n: 4 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETENABLED,
						BP.SETDATAINTERVAL,
						BP.POSITIONCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1047_DIGITALINPUT_200,
					t: "Digital Input",
					c: 5 /* ChannelClass.DIGITAL_INPUT */,
					n: 4 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.STATECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1047_2_300,
			d: 25 /* DeviceID.PN_1047 */,
			c: 5 /* DeviceClass.ENCODER */,
			t: "PhidgetEncoder HighSpeed 4-Input",
			s: "1047",
			i: 79 /* USB ProductID */,
			v: [300, 400],
			cn: [4,4] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1047_ENCODER_300,
					t: "Encoder Input",
					c: 8 /* ChannelClass.ENCODER */,
					n: 4 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETENABLED,
						BP.SETDATAINTERVAL,
						BP.POSITIONCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1047_DIGITALINPUT_300,
					t: "Digital Input",
					c: 5 /* ChannelClass.DIGITAL_INPUT */,
					n: 4 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.STATECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1048,
			d: 26 /* DeviceID.PN_1048 */,
			c: 19 /* DeviceClass.TEMPERATURE_SENSOR */,
			t: "PhidgetTemperatureSensor 4-Input",
			s: "1048",
			i: 50 /* USB ProductID */,
			v: [100, 200],
			cn: [5,4] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1048_TEMPERATURESENSOR_THERMOCOUPLE_100,
					t: "Thermocouple Input",
					c: 28 /* ChannelClass.TEMPERATURE_SENSOR */,
					s: 33 /* ChannelSubClass.TEMPERATURE_SENSOR_THERMOCOUPLE */,
					n: 4 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETTHERMOCOUPLETYPE,
						BP.SETDATAINTERVAL,
						BP.TEMPERATURECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1048_TEMPERATURESENSOR_IC_100,
					t: "Temperature Sensor (IC)",
					c: 28 /* ChannelClass.TEMPERATURE_SENSOR */,
					i: 4 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETDATAINTERVAL,
						BP.TEMPERATURECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1048_VOLTAGEINPUT_100,
					t: "Voltage Input",
					c: 29 /* ChannelClass.VOLTAGE_INPUT */,
					n: 4 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETDATAINTERVAL,
						BP.VOLTAGECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1048_1_2,
			d: 26 /* DeviceID.PN_1048 */,
			c: 19 /* DeviceClass.TEMPERATURE_SENSOR */,
			t: "PhidgetTemperatureSensor 4-Input",
			s: "1048",
			i: 50 /* USB ProductID */,
			v: [200, 300],
			cn: [5,4] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1048_TEMPERATURESENSOR_THERMOCOUPLE_200,
					t: "Thermocouple Input",
					c: 28 /* ChannelClass.TEMPERATURE_SENSOR */,
					s: 33 /* ChannelSubClass.TEMPERATURE_SENSOR_THERMOCOUPLE */,
					n: 4 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETTHERMOCOUPLETYPE,
						BP.SETDATAINTERVAL,
						BP.TEMPERATURECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1048_TEMPERATURESENSOR_IC_200,
					t: "Temperature Sensor (IC)",
					c: 28 /* ChannelClass.TEMPERATURE_SENSOR */,
					i: 4 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETDATAINTERVAL,
						BP.TEMPERATURECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1048_VOLTAGEINPUT_200,
					t: "Voltage Input",
					c: 29 /* ChannelClass.VOLTAGE_INPUT */,
					n: 4 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETDATAINTERVAL,
						BP.VOLTAGECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1049,
			d: 27 /* DeviceID.PN_1049 */,
			c: 17 /* DeviceClass.SPATIAL */,
			t: "PhidgetSpatial 0/0/3",
			s: "1049",
			i: 127 /* USB ProductID */,
			v: [0, 200],
			cn: [3,0,0] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1049_ACCELEROMETER_000,
					t: "3-Axis Accelerometer",
					c: 1 /* ChannelClass.ACCELEROMETER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.ACCELERATIONCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1051_OLD,
			d: 28 /* DeviceID.PN_1051 */,
			c: 19 /* DeviceClass.TEMPERATURE_SENSOR */,
			t: "PhidgetTemperatureSensor 1-Input",
			s: "1051",
			i: 112 /* USB ProductID */,
			v: [0, 200],
			cn: [2,0] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1051_TEMPERATURESENSOR_THERMOCOUPLE_000,
					t: "Thermocouple Input",
					c: 28 /* ChannelClass.TEMPERATURE_SENSOR */,
					s: 33 /* ChannelSubClass.TEMPERATURE_SENSOR_THERMOCOUPLE */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETDATAINTERVAL,
						BP.TEMPERATURECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1051_TEMPERATURESENSOR_IC_000,
					t: "Temperature Sensor (IC)",
					c: 28 /* ChannelClass.TEMPERATURE_SENSOR */,
					i: 1 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETDATAINTERVAL,
						BP.TEMPERATURECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1051,
			d: 28 /* DeviceID.PN_1051 */,
			c: 19 /* DeviceClass.TEMPERATURE_SENSOR */,
			t: "PhidgetTemperatureSensor 1-Input",
			s: "1051",
			i: 112 /* USB ProductID */,
			v: [200, 300],
			cn: [2,1] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1051_TEMPERATURESENSOR_THERMOCOUPLE_200,
					t: "Thermocouple Input",
					c: 28 /* ChannelClass.TEMPERATURE_SENSOR */,
					s: 33 /* ChannelSubClass.TEMPERATURE_SENSOR_THERMOCOUPLE */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETTHERMOCOUPLETYPE,
						BP.SETDATAINTERVAL,
						BP.TEMPERATURECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1051_TEMPERATURESENSOR_IC_200,
					t: "Temperature Sensor (IC)",
					c: 28 /* ChannelClass.TEMPERATURE_SENSOR */,
					i: 1 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETDATAINTERVAL,
						BP.TEMPERATURECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1051_VOLTAGEINPUT_200,
					t: "Voltage Input",
					c: 29 /* ChannelClass.VOLTAGE_INPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETDATAINTERVAL,
						BP.VOLTAGECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1051_AD22100,
			d: 28 /* DeviceID.PN_1051 */,
			c: 19 /* DeviceClass.TEMPERATURE_SENSOR */,
			t: "PhidgetTemperatureSensor 1-Input",
			s: "1051",
			i: 112 /* USB ProductID */,
			v: [300, 400],
			cn: [2,1] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1051_TEMPERATURESENSOR_THERMOCOUPLE_300,
					t: "Thermocouple Input",
					c: 28 /* ChannelClass.TEMPERATURE_SENSOR */,
					s: 33 /* ChannelSubClass.TEMPERATURE_SENSOR_THERMOCOUPLE */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETTHERMOCOUPLETYPE,
						BP.SETDATAINTERVAL,
						BP.TEMPERATURECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1051_TEMPERATURESENSOR_IC_300,
					t: "Temperature Sensor (IC)",
					c: 28 /* ChannelClass.TEMPERATURE_SENSOR */,
					i: 1 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETDATAINTERVAL,
						BP.TEMPERATURECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1051_VOLTAGEINPUT_300,
					t: "Voltage Input",
					c: 29 /* ChannelClass.VOLTAGE_INPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETDATAINTERVAL,
						BP.VOLTAGECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1051_TERMINAL_BLOCKS,
			d: 28 /* DeviceID.PN_1051 */,
			c: 19 /* DeviceClass.TEMPERATURE_SENSOR */,
			t: "PhidgetTemperatureSensor 1-Input",
			s: "1051",
			i: 112 /* USB ProductID */,
			v: [400, 500],
			cn: [2,1] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1051_TEMPERATURESENSOR_THERMOCOUPLE_400,
					t: "Thermocouple Input",
					c: 28 /* ChannelClass.TEMPERATURE_SENSOR */,
					s: 33 /* ChannelSubClass.TEMPERATURE_SENSOR_THERMOCOUPLE */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETTHERMOCOUPLETYPE,
						BP.SETDATAINTERVAL,
						BP.TEMPERATURECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1051_TEMPERATURESENSOR_IC_400,
					t: "Temperature Sensor (IC)",
					c: 28 /* ChannelClass.TEMPERATURE_SENSOR */,
					i: 1 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETDATAINTERVAL,
						BP.TEMPERATURECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1051_VOLTAGEINPUT_400,
					t: "Voltage Input",
					c: 29 /* ChannelClass.VOLTAGE_INPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETDATAINTERVAL,
						BP.VOLTAGECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1052_OLD,
			d: 29 /* DeviceID.PN_1052 */,
			c: 5 /* DeviceClass.ENCODER */,
			t: "PhidgetEncoder",
			s: "1052",
			i: 75 /* USB ProductID */,
			v: [0, 101],
			cn: [1,1] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1052_ENCODER_000,
					t: "Encoder Input",
					c: 8 /* ChannelClass.ENCODER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETDATAINTERVAL,
						BP.POSITIONCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1052_DIGITALINPUT_000,
					t: "Digital Input",
					c: 5 /* ChannelClass.DIGITAL_INPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.STATECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1052_v1,
			d: 29 /* DeviceID.PN_1052 */,
			c: 5 /* DeviceClass.ENCODER */,
			t: "PhidgetEncoder",
			s: "1052",
			i: 75 /* USB ProductID */,
			v: [101, 110],
			cn: [1,1] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1052_ENCODER_101,
					t: "Encoder Input",
					c: 8 /* ChannelClass.ENCODER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETDATAINTERVAL,
						BP.POSITIONCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1052_DIGITALINPUT_101,
					t: "Digital Input",
					c: 5 /* ChannelClass.DIGITAL_INPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.STATECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1052_v2,
			d: 29 /* DeviceID.PN_1052 */,
			c: 5 /* DeviceClass.ENCODER */,
			t: "PhidgetEncoder",
			s: "1052",
			i: 75 /* USB ProductID */,
			v: [110, 300],
			cn: [1,1] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1052_ENCODER_110,
					t: "Encoder Input",
					c: 8 /* ChannelClass.ENCODER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETDATAINTERVAL,
						BP.POSITIONCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1052_DIGITALINPUT_110,
					t: "Digital Input",
					c: 5 /* ChannelClass.DIGITAL_INPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.STATECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1053,
			d: 30 /* DeviceID.PN_1053 */,
			c: 1 /* DeviceClass.ACCELEROMETER */,
			t: "PhidgetAccelerometer 2-Axis",
			s: "1053",
			i: 113 /* USB ProductID */,
			v: [300, 400],
			cn: [2] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1053_ACCELEROMETER_300,
					t: "2-Axis Accelerometer",
					c: 1 /* ChannelClass.ACCELEROMETER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETDATAINTERVAL,
						BP.ACCELERATIONCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1054,
			d: 31 /* DeviceID.PN_1054 */,
			c: 6 /* DeviceClass.FREQUENCY_COUNTER */,
			t: "PhidgetFrequencyCounter",
			s: "1054",
			i: 53 /* USB ProductID */,
			v: [0, 200],
			cn: [2] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1054_FREQUENCYCOUNTER_000,
					t: "Frequency Input",
					c: 9 /* ChannelClass.FREQUENCY_COUNTER */,
					n: 2 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.FREQUENCYDATA,
						BP.SETENABLED,
						BP.SETFILTERTYPE,
						BP.SETDATAINTERVAL,
						BP.FREQUENCYCHANGE,
						BP.COUNTCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1055,
			d: 32 /* DeviceID.PN_1055 */,
			c: 10 /* DeviceClass.IR */,
			t: "PhidgetIR",
			s: "1055",
			i: 77 /* USB ProductID */,
			v: [100, 200],
			cn: [1] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1055_IR_100,
					t: "IR Receiver Transmitter",
					c: 16 /* ChannelClass.IR */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.REPEAT,
						BP.TRANSMIT,
						BP.TRANSMITREPEAT,
						BP.TRANSMITRAW,
						BP.CODE,
						BP.LEARN,
						BP.RAWDATA,
					]
				},
			]
		},
		{
			uid: DeviceUID._1055_1_USB,
			d: 32 /* DeviceID.PN_1055 */,
			c: 10 /* DeviceClass.IR */,
			t: "PhidgetIR",
			s: "1055",
			i: 77 /* USB ProductID */,
			v: [200, 300],
			cn: [1] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1055_IR_200_USB,
					t: "IR Receiver Transmitter",
					c: 16 /* ChannelClass.IR */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.REPEAT,
						BP.TRANSMIT,
						BP.TRANSMITREPEAT,
						BP.TRANSMITRAW,
						BP.CODE,
						BP.LEARN,
						BP.RAWDATA,
					]
				},
			]
		},
		{
			uid: DeviceUID._1056,
			d: 33 /* DeviceID.PN_1056 */,
			c: 17 /* DeviceClass.SPATIAL */,
			t: "PhidgetSpatial 3/3/3",
			s: "1056",
			i: 51 /* USB ProductID */,
			v: [0, 200],
			cn: [3,3,3,1] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1056_ACCELEROMETER_000,
					t: "3-Axis Accelerometer",
					c: 1 /* ChannelClass.ACCELEROMETER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.ACCELERATIONCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1056_GYROSCOPE_000,
					t: "3-Axis Gyroscope",
					c: 12 /* ChannelClass.GYROSCOPE */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.ZERO,
						BP.ANGULARRATEUPDATE,
					]
				},
				{
					uid: DeviceChannelUID._1056_MAGNETOMETER_000,
					t: "3-Axis Magnetometer",
					c: 18 /* ChannelClass.MAGNETOMETER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETCORRECTIONPARAMETERS,
						BP.SAVECORRECTIONPARAMETERS,
						BP.RESETCORRECTIONPARAMETERS,
						BP.FIELDSTRENGTHCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1056_SPATIAL_000,
					t: "Spatial",
					c: 26 /* ChannelClass.SPATIAL */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.ZERO,
						BP.SETCORRECTIONPARAMETERS,
						BP.SAVECORRECTIONPARAMETERS,
						BP.RESETCORRECTIONPARAMETERS,
						BP.SPATIALDATA,
					]
				},
			]
		},
		{
			uid: DeviceUID._1056_NEG_GAIN,
			d: 33 /* DeviceID.PN_1056 */,
			c: 17 /* DeviceClass.SPATIAL */,
			t: "PhidgetSpatial 3/3/3",
			s: "1056",
			i: 51 /* USB ProductID */,
			v: [200, 300],
			cn: [3,3,3,1] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1056_ACCELEROMETER_200,
					t: "3-Axis Accelerometer",
					c: 1 /* ChannelClass.ACCELEROMETER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.ACCELERATIONCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1056_GYROSCOPE_200,
					t: "3-Axis Gyroscope",
					c: 12 /* ChannelClass.GYROSCOPE */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.ZERO,
						BP.ANGULARRATEUPDATE,
					]
				},
				{
					uid: DeviceChannelUID._1056_MAGNETOMETER_200,
					t: "3-Axis Magnetometer",
					c: 18 /* ChannelClass.MAGNETOMETER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETCORRECTIONPARAMETERS,
						BP.SAVECORRECTIONPARAMETERS,
						BP.RESETCORRECTIONPARAMETERS,
						BP.FIELDSTRENGTHCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1056_SPATIAL_200,
					t: "Spatial",
					c: 26 /* ChannelClass.SPATIAL */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.ZERO,
						BP.SETCORRECTIONPARAMETERS,
						BP.SAVECORRECTIONPARAMETERS,
						BP.RESETCORRECTIONPARAMETERS,
						BP.SPATIALDATA,
					]
				},
			]
		},
		{
			uid: DeviceUID._1057,
			d: 34 /* DeviceID.PN_1057 */,
			c: 5 /* DeviceClass.ENCODER */,
			t: "PhidgetEncoder HighSpeed",
			s: "1057",
			i: 128 /* USB ProductID */,
			v: [300, 400],
			cn: [1] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1057_ENCODER_300,
					t: "Encoder Input",
					c: 8 /* ChannelClass.ENCODER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETDATAINTERVAL,
						BP.POSITIONCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1057_3,
			d: 34 /* DeviceID.PN_1057 */,
			c: 5 /* DeviceClass.ENCODER */,
			t: "PhidgetEncoder HighSpeed",
			s: "1057",
			i: 128 /* USB ProductID */,
			v: [400, 500],
			cn: [1] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1057_ENCODER_400,
					t: "Encoder Input",
					c: 8 /* ChannelClass.ENCODER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETENABLED,
						BP.SETDATAINTERVAL,
						BP.SETIOMODE,
						BP.POSITIONCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1058,
			d: 35 /* DeviceID.PN_1058 */,
			c: 14 /* DeviceClass.PH_SENSOR */,
			t: "PhidgetPHSensor",
			s: "1058",
			i: 116 /* USB ProductID */,
			v: [100, 200],
			cn: [1,1] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1058_VOLTAGEINPUT_100,
					t: "Voltage Input",
					c: 29 /* ChannelClass.VOLTAGE_INPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETDATAINTERVAL,
						BP.VOLTAGECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1058_PHADAPTER_100,
					t: "PH Input",
					c: 37 /* ChannelClass.PH_SENSOR */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETCORRECTIONTEMPERATURE,
						BP.PHCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1059,
			d: 36 /* DeviceID.PN_1059 */,
			c: 1 /* DeviceClass.ACCELEROMETER */,
			t: "PhidgetAccelerometer 3-Axis",
			s: "1059",
			i: 126 /* USB ProductID */,
			v: [400, 500],
			cn: [3] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1059_ACCELEROMETER_400,
					t: "3-Axis Accelerometer",
					c: 1 /* ChannelClass.ACCELEROMETER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETDATAINTERVAL,
						BP.ACCELERATIONCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1060,
			d: 37 /* DeviceID.PN_1060 */,
			c: 13 /* DeviceClass.MOTOR_CONTROL */,
			t: "PhidgetMotorControl LV",
			s: "1060",
			i: 88 /* USB ProductID */,
			v: [100, 200],
			cn: [2,4,0,0,0,0] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1060_DCMOTOR_100,
					t: "DC Motor Controller",
					c: 4 /* ChannelClass.DC_MOTOR */,
					n: 2 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETDUTYCYCLE,
						BP.SETACCELERATION,
						BP.DUTYCYCLECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1060_DIGITALINPUT_100,
					t: "Digital Input",
					c: 5 /* ChannelClass.DIGITAL_INPUT */,
					n: 4 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.STATECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1061,
			d: 38 /* DeviceID.PN_1061 */,
			c: 2 /* DeviceClass.ADVANCED_SERVO */,
			t: "PhidgetAdvancedServo 8-Motor",
			s: "1061",
			i: 58 /* USB ProductID */,
			v: [100, 200],
			cn: [8,8] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1061_RCSERVO_100,
					t: "RC Servo Motor Controller",
					c: 22 /* ChannelClass.RC_SERVO */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETMINPULSEWIDTH,
						BP.SETMAXPULSEWIDTH,
						BP.SETVELOCITYLIMIT,
						BP.SETACCELERATION,
						BP.SETTARGETPOSITION,
						BP.SETENGAGED,
						BP.SETSPEEDRAMPINGSTATE,
						BP.SETDATAINTERVAL,
						BP.POSITIONCHANGE,
						BP.VELOCITYCHANGE,
						BP.TARGETPOSITIONREACHED,
					]
				},
				{
					uid: DeviceChannelUID._1061_CURRENTINPUT_100,
					t: "RC Servo Motor Current Sensor",
					c: 2 /* ChannelClass.CURRENT_INPUT */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETDATAINTERVAL,
						BP.CURRENTCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1061_PGOOD_FLAG,
			d: 38 /* DeviceID.PN_1061 */,
			c: 2 /* DeviceClass.ADVANCED_SERVO */,
			t: "PhidgetAdvancedServo 8-Motor",
			s: "1061",
			i: 58 /* USB ProductID */,
			v: [200, 300],
			cn: [8,8] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1061_RCSERVO_200,
					t: "RC Servo Motor Controller",
					c: 22 /* ChannelClass.RC_SERVO */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETMINPULSEWIDTH,
						BP.SETMAXPULSEWIDTH,
						BP.SETVELOCITYLIMIT,
						BP.SETACCELERATION,
						BP.SETTARGETPOSITION,
						BP.SETENGAGED,
						BP.SETSPEEDRAMPINGSTATE,
						BP.SETDATAINTERVAL,
						BP.POSITIONCHANGE,
						BP.VELOCITYCHANGE,
						BP.TARGETPOSITIONREACHED,
					]
				},
				{
					uid: DeviceChannelUID._1061_CURRENTINPUT_200,
					t: "RC Servo Motor Current Sensor",
					c: 2 /* ChannelClass.CURRENT_INPUT */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETDATAINTERVAL,
						BP.CURRENTCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1061_CURSENSE_FIX,
			d: 38 /* DeviceID.PN_1061 */,
			c: 2 /* DeviceClass.ADVANCED_SERVO */,
			t: "PhidgetAdvancedServo 8-Motor",
			s: "1061",
			i: 58 /* USB ProductID */,
			v: [300, 400],
			cn: [8,8] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1061_RCSERVO_300,
					t: "RC Servo Motor Controller",
					c: 22 /* ChannelClass.RC_SERVO */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETMINPULSEWIDTH,
						BP.SETMAXPULSEWIDTH,
						BP.SETVELOCITYLIMIT,
						BP.SETACCELERATION,
						BP.SETTARGETPOSITION,
						BP.SETENGAGED,
						BP.SETSPEEDRAMPINGSTATE,
						BP.SETDATAINTERVAL,
						BP.POSITIONCHANGE,
						BP.VELOCITYCHANGE,
						BP.TARGETPOSITIONREACHED,
					]
				},
				{
					uid: DeviceChannelUID._1061_CURRENTINPUT_300,
					t: "RC Servo Motor Current Sensor",
					c: 2 /* ChannelClass.CURRENT_INPUT */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETDATAINTERVAL,
						BP.CURRENTCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._RCC0004,
			d: 124 /* DeviceID.PN_RCC0004 */,
			c: 2 /* DeviceClass.ADVANCED_SERVO */,
			t: "PhidgetAdvancedServo 8-Motor",
			s: "RCC0004",
			i: 58 /* USB ProductID */,
			v: [400, 500],
			cn: [8,0] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._RCC0004_RCSERVO_400,
					t: "RC Servo Motor Controller",
					c: 22 /* ChannelClass.RC_SERVO */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETMINPULSEWIDTH,
						BP.SETMAXPULSEWIDTH,
						BP.SETVELOCITYLIMIT,
						BP.SETACCELERATION,
						BP.SETTARGETPOSITION,
						BP.SETENGAGED,
						BP.SETSPEEDRAMPINGSTATE,
						BP.SETVOLTAGE,
						BP.SETDATAINTERVAL,
						BP.POSITIONCHANGE,
						BP.VELOCITYCHANGE,
						BP.TARGETPOSITIONREACHED,
					]
				},
			]
		},
		{
			uid: DeviceUID._1062,
			d: 39 /* DeviceID.PN_1062 */,
			c: 18 /* DeviceClass.STEPPER */,
			t: "PhidgetStepper Unipolar 4-Motor",
			s: "1062",
			i: 122 /* USB ProductID */,
			v: [100, 200],
			cn: [4] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1062_STEPPER_100,
					t: "Unipolar Stepper Controller",
					c: 27 /* ChannelClass.STEPPER */,
					n: 4 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETTARGETPOSITION,
						BP.SETVELOCITYLIMIT,
						BP.SETACCELERATION,
						BP.SETENGAGED,
						BP.SETCONTROLMODE,
						BP.SETDATAINTERVAL,
						BP.POSITIONCHANGE,
						BP.VELOCITYCHANGE,
						BP.STOPPED,
					]
				},
			]
		},
		{
			uid: DeviceUID._1063,
			d: 40 /* DeviceID.PN_1063 */,
			c: 18 /* DeviceClass.STEPPER */,
			t: "PhidgetStepper Bipolar 1-Motor",
			s: "1063",
			i: 123 /* USB ProductID */,
			v: [100, 200],
			cn: [1,4,1] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1063_STEPPER_100,
					t: "Bipolar Stepper Controller",
					c: 27 /* ChannelClass.STEPPER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETTARGETPOSITION,
						BP.SETVELOCITYLIMIT,
						BP.SETACCELERATION,
						BP.SETCURRENTLIMIT,
						BP.SETENGAGED,
						BP.SETCONTROLMODE,
						BP.SETDATAINTERVAL,
						BP.POSITIONCHANGE,
						BP.VELOCITYCHANGE,
						BP.STOPPED,
					]
				},
				{
					uid: DeviceChannelUID._1063_DIGITALINPUT_100,
					t: "Digital Input",
					c: 5 /* ChannelClass.DIGITAL_INPUT */,
					n: 4 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.STATECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1063_CURRENTINPUT_100,
					t: "Bipolar Stepper Current Sensor",
					c: 2 /* ChannelClass.CURRENT_INPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETDATAINTERVAL,
						BP.CURRENTCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1064,
			d: 41 /* DeviceID.PN_1064 */,
			c: 13 /* DeviceClass.MOTOR_CONTROL */,
			t: "PhidgetMotorControl HC",
			s: "1064",
			i: 89 /* USB ProductID */,
			v: [100, 200],
			cn: [2,0,0,0,0,2] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1064_DCMOTOR_100,
					t: "DC Motor Controller",
					c: 4 /* ChannelClass.DC_MOTOR */,
					n: 2 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETDUTYCYCLE,
						BP.SETACCELERATION,
						BP.DUTYCYCLECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1064_CURRENTINPUT_100,
					t: "DC Motor Current Sensor",
					c: 2 /* ChannelClass.CURRENT_INPUT */,
					n: 2 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETDATAINTERVAL,
						BP.CURRENTCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1065,
			d: 42 /* DeviceID.PN_1065 */,
			c: 13 /* DeviceClass.MOTOR_CONTROL */,
			t: "PhidgetMotorControl 1-Motor",
			s: "1065",
			i: 62 /* USB ProductID */,
			v: [100, 200],
			cn: [1,2,1,3,2,1] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1065_DCMOTOR_100,
					t: "DC Motor Controller",
					c: 4 /* ChannelClass.DC_MOTOR */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETDUTYCYCLE,
						BP.SETACCELERATION,
						BP.SETBRAKINGDUTYCYCLE,
						BP.SETBACKEMFSENSINGSTATE,
						BP.DUTYCYCLECHANGE,
						BP.BACKEMFCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1065_DIGITALINPUT_100,
					t: "Digital Input",
					c: 5 /* ChannelClass.DIGITAL_INPUT */,
					n: 2 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.STATECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1065_ENCODER_100,
					t: "Encoder Input",
					c: 8 /* ChannelClass.ENCODER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETDATAINTERVAL,
						BP.POSITIONCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1065_VOLTAGEINPUT_100,
					t: "Voltage Input",
					c: 29 /* ChannelClass.VOLTAGE_INPUT */,
					s: 48 /* ChannelSubClass.VOLTAGE_INPUT_SENSOR_PORT */,
					n: 2 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETSENSORTYPE,
						BP.SETCHANGETRIGGER,
						BP.SETDATAINTERVAL,
						BP.SETSENSORVALUECHANGETRIGGER,
						BP.VOLTAGECHANGE,
						BP.SENSORCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1065_VOLTAGEINPUT_SUPPLY_100,
					t: "Supply Voltage Sensor",
					c: 29 /* ChannelClass.VOLTAGE_INPUT */,
					i: 2 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETDATAINTERVAL,
						BP.VOLTAGECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1065_VOLTAGERATIOINPUT_100,
					t: "Voltage Ratio Input",
					c: 31 /* ChannelClass.VOLTAGE_RATIO_INPUT */,
					s: 64 /* ChannelSubClass.VOLTAGE_RATIO_INPUT_SENSOR_PORT */,
					n: 2 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETSENSORTYPE,
						BP.SETCHANGETRIGGER,
						BP.SETDATAINTERVAL,
						BP.SETSENSORVALUECHANGETRIGGER,
						BP.VOLTAGERATIOCHANGE,
						BP.SENSORCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1065_CURRENTINPUT_100,
					t: "DC Motor Current Sensor",
					c: 2 /* ChannelClass.CURRENT_INPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETDATAINTERVAL,
						BP.CURRENTCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1066,
			d: 43 /* DeviceID.PN_1066 */,
			c: 2 /* DeviceClass.ADVANCED_SERVO */,
			t: "PhidgetAdvancedServo 1-Motor",
			s: "1066",
			i: 130 /* USB ProductID */,
			v: [100, 200],
			cn: [1,1] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1066_RCSERVO_100,
					t: "RC Servo Motor Controller",
					c: 22 /* ChannelClass.RC_SERVO */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETMINPULSEWIDTH,
						BP.SETMAXPULSEWIDTH,
						BP.SETVELOCITYLIMIT,
						BP.SETACCELERATION,
						BP.SETTARGETPOSITION,
						BP.SETENGAGED,
						BP.SETSPEEDRAMPINGSTATE,
						BP.SETDATAINTERVAL,
						BP.POSITIONCHANGE,
						BP.VELOCITYCHANGE,
						BP.TARGETPOSITIONREACHED,
					]
				},
				{
					uid: DeviceChannelUID._1066_CURRENTINPUT_100,
					t: "RC Servo Motor Current Sensor",
					c: 2 /* ChannelClass.CURRENT_INPUT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETCHANGETRIGGER,
						BP.SETDATAINTERVAL,
						BP.CURRENTCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1067,
			d: 44 /* DeviceID.PN_1067 */,
			c: 18 /* DeviceClass.STEPPER */,
			t: "PhidgetStepper Bipolar HC",
			s: "1067",
			i: 123 /* USB ProductID */,
			v: [200, 300],
			cn: [1] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1067_STEPPER_200,
					t: "Bipolar Stepper Controller",
					c: 27 /* ChannelClass.STEPPER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETTARGETPOSITION,
						BP.SETVELOCITYLIMIT,
						BP.SETACCELERATION,
						BP.SETCURRENTLIMIT,
						BP.SETENGAGED,
						BP.SETCONTROLMODE,
						BP.SETDATAINTERVAL,
						BP.POSITIONCHANGE,
						BP.VELOCITYCHANGE,
						BP.STOPPED,
					]
				},
			]
		},
		{
			uid: DeviceUID._1067_1,
			d: 44 /* DeviceID.PN_1067 */,
			c: 18 /* DeviceClass.STEPPER */,
			t: "PhidgetStepper Bipolar HC",
			s: "1067",
			i: 123 /* USB ProductID */,
			v: [300, 400],
			cn: [1] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1067_STEPPER_300,
					t: "Bipolar Stepper Controller",
					c: 27 /* ChannelClass.STEPPER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETTARGETPOSITION,
						BP.SETVELOCITYLIMIT,
						BP.SETACCELERATION,
						BP.SETCURRENTLIMIT,
						BP.SETENGAGED,
						BP.SETCONTROLMODE,
						BP.SETDATAINTERVAL,
						BP.POSITIONCHANGE,
						BP.VELOCITYCHANGE,
						BP.STOPPED,
					]
				},
			]
		},
		{
			uid: DeviceUID._1202_IFKIT_NO_ECHO,
			d: 45 /* DeviceID.PN_1202_1203 */,
			c: 9 /* DeviceClass.INTERFACE_KIT */,
			t: "PhidgetInterfaceKit 8/8/8",
			s: "1202/1203",
			i: 125 /* USB ProductID */,
			v: [0, 120],
			cn: [8,8,8,8] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1202_VOLTAGEINPUT_000,
					t: "Voltage Input",
					c: 29 /* ChannelClass.VOLTAGE_INPUT */,
					s: 48 /* ChannelSubClass.VOLTAGE_INPUT_SENSOR_PORT */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.MINDATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETSENSORTYPE,
						BP.SETCHANGETRIGGER,
						BP.SETSENSORVALUECHANGETRIGGER,
						BP.VOLTAGECHANGE,
						BP.SENSORCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1202_VOLTAGERATIOINPUT_000,
					t: "Voltage Ratio Input",
					c: 31 /* ChannelClass.VOLTAGE_RATIO_INPUT */,
					s: 64 /* ChannelSubClass.VOLTAGE_RATIO_INPUT_SENSOR_PORT */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.MINDATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETSENSORTYPE,
						BP.SETCHANGETRIGGER,
						BP.SETSENSORVALUECHANGETRIGGER,
						BP.VOLTAGERATIOCHANGE,
						BP.SENSORCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1202_DIGITALINPUT_000,
					t: "Digital Input",
					c: 5 /* ChannelClass.DIGITAL_INPUT */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.STATECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1202_DIGITALOUTPUT_000,
					t: "Digital Output",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETSTATE,
						BP.SETDUTYCYCLE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1202_IFKIT,
			d: 45 /* DeviceID.PN_1202_1203 */,
			c: 9 /* DeviceClass.INTERFACE_KIT */,
			t: "PhidgetInterfaceKit 8/8/8",
			s: "1202/1203",
			i: 125 /* USB ProductID */,
			v: [120, 200],
			cn: [8,8,8,8] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1202_VOLTAGEINPUT_120,
					t: "Voltage Input",
					c: 29 /* ChannelClass.VOLTAGE_INPUT */,
					s: 48 /* ChannelSubClass.VOLTAGE_INPUT_SENSOR_PORT */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.MINDATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETSENSORTYPE,
						BP.SETCHANGETRIGGER,
						BP.SETSENSORVALUECHANGETRIGGER,
						BP.VOLTAGECHANGE,
						BP.SENSORCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1202_VOLTAGERATIOINPUT_120,
					t: "Voltage Ratio Input",
					c: 31 /* ChannelClass.VOLTAGE_RATIO_INPUT */,
					s: 64 /* ChannelSubClass.VOLTAGE_RATIO_INPUT_SENSOR_PORT */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.MINDATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETSENSORTYPE,
						BP.SETCHANGETRIGGER,
						BP.SETSENSORVALUECHANGETRIGGER,
						BP.VOLTAGERATIOCHANGE,
						BP.SENSORCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1202_DIGITALINPUT_120,
					t: "Digital Input",
					c: 5 /* ChannelClass.DIGITAL_INPUT */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.STATECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1202_DIGITALOUTPUT_120,
					t: "Digital Output",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETSTATE,
						BP.SETDUTYCYCLE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1202_TEXTLCD,
			d: 45 /* DeviceID.PN_1202_1203 */,
			c: 20 /* DeviceClass.TEXT_LCD */,
			t: "PhidgetTextLCD 20X2",
			s: "1202/1203",
			i: 125 /* USB ProductID */,
			v: [0, 200],
			cn: [1] /* Channel Counts */,
			n: 1 /* USB Interface */,
			ch: [
				{
					uid: DeviceChannelUID._1202_TEXTLCD_000,
					t: "Text LCD",
					c: 11 /* ChannelClass.LCD */,
					s: 81 /* ChannelSubClass.LCD_TEXT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETBACKLIGHT,
						BP.SETCONTRAST,
						BP.SETCURSORBLINK,
						BP.SETCURSORON,
						BP.SETCHARACTERBITMAP,
						BP.CLEAR,
						BP.FLUSH,
						BP.WRITETEXT,
					]
				},
			]
		},
		{
			uid: DeviceUID._1202_IFKIT_FAST,
			d: 45 /* DeviceID.PN_1202_1203 */,
			c: 9 /* DeviceClass.INTERFACE_KIT */,
			t: "PhidgetInterfaceKit 8/8/8",
			s: "1202/1203",
			i: 125 /* USB ProductID */,
			v: [200, 300],
			cn: [8,8,8,8] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1202_VOLTAGEINPUT_300,
					t: "Voltage Input",
					c: 29 /* ChannelClass.VOLTAGE_INPUT */,
					s: 48 /* ChannelSubClass.VOLTAGE_INPUT_SENSOR_PORT */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.MINDATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETSENSORTYPE,
						BP.SETCHANGETRIGGER,
						BP.SETSENSORVALUECHANGETRIGGER,
						BP.VOLTAGECHANGE,
						BP.SENSORCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1202_VOLTAGERATIOINPUT_300,
					t: "Voltage Ratio Input",
					c: 31 /* ChannelClass.VOLTAGE_RATIO_INPUT */,
					s: 64 /* ChannelSubClass.VOLTAGE_RATIO_INPUT_SENSOR_PORT */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.MINDATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETSENSORTYPE,
						BP.SETCHANGETRIGGER,
						BP.SETSENSORVALUECHANGETRIGGER,
						BP.VOLTAGERATIOCHANGE,
						BP.SENSORCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1202_DIGITALINPUT_300,
					t: "Digital Input",
					c: 5 /* ChannelClass.DIGITAL_INPUT */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.STATECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1202_DIGITALOUTPUT_300,
					t: "Digital Output",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETSTATE,
						BP.SETDUTYCYCLE,
					]
				},
			]
		},
		{
			uid: DeviceUID._1202_TEXTLCD_BRIGHTNESS,
			d: 45 /* DeviceID.PN_1202_1203 */,
			c: 20 /* DeviceClass.TEXT_LCD */,
			t: "PhidgetTextLCD 20X2",
			s: "1202/1203",
			i: 125 /* USB ProductID */,
			v: [200, 9999],
			cn: [1] /* Channel Counts */,
			n: 1 /* USB Interface */,
			ch: [
				{
					uid: DeviceChannelUID._1202_TEXTLCD_200,
					t: "Text LCD",
					c: 11 /* ChannelClass.LCD */,
					s: 81 /* ChannelSubClass.LCD_TEXT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETBACKLIGHT,
						BP.SETCONTRAST,
						BP.SETCURSORBLINK,
						BP.SETCURSORON,
						BP.SETCHARACTERBITMAP,
						BP.CLEAR,
						BP.FLUSH,
						BP.WRITETEXT,
					]
				},
			]
		},
		{
			uid: DeviceUID._1204,
			d: 46 /* DeviceID.PN_1204 */,
			c: 20 /* DeviceClass.TEXT_LCD */,
			t: "PhidgetTextLCD Adapter",
			s: "1204",
			i: 61 /* USB ProductID */,
			v: [0, 9999],
			cn: [2] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1204_TEXTLCD_000,
					t: "Text LCD",
					c: 11 /* ChannelClass.LCD */,
					s: 81 /* ChannelSubClass.LCD_TEXT */,
					n: 2 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETBACKLIGHT,
						BP.SETCONTRAST,
						BP.SETCURSORBLINK,
						BP.SETCURSORON,
						BP.SETSCREENSIZE,
						BP.SETCHARACTERBITMAP,
						BP.CLEAR,
						BP.FLUSH,
						BP.INITIALIZE,
						BP.WRITETEXT,
					]
				},
			]
		},
		{
			uid: DeviceUID._1215,
			d: 47 /* DeviceID.PN_1215__1218 */,
			c: 20 /* DeviceClass.TEXT_LCD */,
			t: "PhidgetTextLCD 20X2",
			s: "1215/1216/1217/1218",
			i: 82 /* USB ProductID */,
			v: [0, 9999],
			cn: [1] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1215_TEXTLCD_000,
					t: "Text LCD",
					c: 11 /* ChannelClass.LCD */,
					s: 81 /* ChannelSubClass.LCD_TEXT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETBACKLIGHT,
						BP.SETCONTRAST,
						BP.SETCURSORBLINK,
						BP.SETCURSORON,
						BP.SETCHARACTERBITMAP,
						BP.CLEAR,
						BP.FLUSH,
						BP.WRITETEXT,
					]
				},
			]
		},
		{
			uid: DeviceUID._1219,
			d: 48 /* DeviceID.PN_1219__1222 */,
			c: 20 /* DeviceClass.TEXT_LCD */,
			t: "PhidgetTextLCD 20X2 with InterfaceKit 0/8/8",
			s: "1219/1220/1221/1222",
			i: 83 /* USB ProductID */,
			v: [0, 9999],
			cn: [1,8,8] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._1219_TEXTLCD_000,
					t: "Text LCD",
					c: 11 /* ChannelClass.LCD */,
					s: 81 /* ChannelSubClass.LCD_TEXT */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETBACKLIGHT,
						BP.SETCONTRAST,
						BP.SETCURSORBLINK,
						BP.SETCURSORON,
						BP.SETCHARACTERBITMAP,
						BP.CLEAR,
						BP.FLUSH,
						BP.WRITETEXT,
					]
				},
				{
					uid: DeviceChannelUID._1219_DIGITALINPUT_000,
					t: "Digital Input",
					c: 5 /* ChannelClass.DIGITAL_INPUT */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.STATECHANGE,
					]
				},
				{
					uid: DeviceChannelUID._1219_DIGITALOUTPUT_000,
					t: "Digital Output",
					c: 6 /* ChannelClass.DIGITAL_OUTPUT */,
					n: 8 /* Count */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETSTATE,
						BP.SETDUTYCYCLE,
					]
				},
			]
		},
		{
			uid: DeviceUID._MOT0100_USB,
			d: 146 /* DeviceID.PN_MOT0100 */,
			c: 1 /* DeviceClass.ACCELEROMETER */,
			t: "PhidgetAccelerometer",
			s: "MOT0100",
			i: 146 /* USB ProductID */,
			v: [100, 200],
			cn: [3] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._MOT0100_ACCELEROMETER_100_USB,
					t: "3-Axis Accelerometer",
					c: 1 /* ChannelClass.ACCELEROMETER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.ACCELERATIONCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._MOT0109,
			d: 140 /* DeviceID.PN_MOT0109 */,
			c: 17 /* DeviceClass.SPATIAL */,
			t: "PhidgetSpatial Precision 3/3/3",
			s: "MOT0109",
			i: 140 /* USB ProductID */,
			v: [100, 200],
			cn: [3,3,3,1,1] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._MOT0109_ACCELEROMETER_100,
					t: "3-Axis Accelerometer",
					c: 1 /* ChannelClass.ACCELEROMETER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.HEATINGENABLEDCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETSPATIALPRECISION,
						BP.SETHEATINGENABLED,
						BP.ACCELERATIONCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._MOT0109_GYROSCOPE_100,
					t: "3-Axis Gyroscope",
					c: 12 /* ChannelClass.GYROSCOPE */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.HEATINGENABLEDCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETSPATIALPRECISION,
						BP.SETHEATINGENABLED,
						BP.ZERO,
						BP.ANGULARRATEUPDATE,
					]
				},
				{
					uid: DeviceChannelUID._MOT0109_MAGNETOMETER_100,
					t: "3-Axis Magnetometer",
					c: 18 /* ChannelClass.MAGNETOMETER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.HEATINGENABLEDCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETHEATINGENABLED,
						BP.SETCORRECTIONPARAMETERS,
						BP.SAVECORRECTIONPARAMETERS,
						BP.RESETCORRECTIONPARAMETERS,
						BP.FIELDSTRENGTHCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._MOT0109_SPATIAL_100,
					t: "Spatial",
					c: 26 /* ChannelClass.SPATIAL */,
					s: 112 /* ChannelSubClass.SPATIAL_AHRS */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.HEATINGENABLEDCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETSPATIALPRECISION,
						BP.SETSPATIALALGORITHM,
						BP.SETSPATIALALGORITHMMAGGAIN,
						BP.SETHEATINGENABLED,
						BP.ZERO,
						BP.SETCORRECTIONPARAMETERS,
						BP.SAVECORRECTIONPARAMETERS,
						BP.RESETCORRECTIONPARAMETERS,
						BP.SETAHRSPARAMETERS,
						BP.ZEROSPATIALALGORITHM,
						BP.SPATIALDATA,
						BP.SPATIALALGDATA,
					]
				},
				{
					uid: DeviceChannelUID._MOT0109_TEMPERATURESENSOR_100,
					t: "Temperature Sensor",
					c: 28 /* ChannelClass.TEMPERATURE_SENSOR */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.TEMPERATURECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._MOT0110_USB,
			d: 141 /* DeviceID.PN_MOT0110 */,
			c: 17 /* DeviceClass.SPATIAL */,
			t: "PhidgetSpatial Precision 3/3/3",
			s: "MOT0110",
			i: 141 /* USB ProductID */,
			v: [100, 200],
			cn: [3,3,3,1,1] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._MOT0110_ACCELEROMETER_100_USB,
					t: "3-Axis Accelerometer",
					c: 1 /* ChannelClass.ACCELEROMETER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.HEATINGENABLEDCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETHEATINGENABLED,
						BP.ACCELERATIONCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._MOT0110_GYROSCOPE_100_USB,
					t: "3-Axis Gyroscope",
					c: 12 /* ChannelClass.GYROSCOPE */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.HEATINGENABLEDCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETHEATINGENABLED,
						BP.ZERO,
						BP.ANGULARRATEUPDATE,
					]
				},
				{
					uid: DeviceChannelUID._MOT0110_MAGNETOMETER_100_USB,
					t: "3-Axis Magnetometer",
					c: 18 /* ChannelClass.MAGNETOMETER */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.HEATINGENABLEDCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.SETHEATINGENABLED,
						BP.SETCORRECTIONPARAMETERS,
						BP.SAVECORRECTIONPARAMETERS,
						BP.RESETCORRECTIONPARAMETERS,
						BP.FIELDSTRENGTHCHANGE,
					]
				},
				{
					uid: DeviceChannelUID._MOT0110_SPATIAL_100_USB,
					t: "Spatial",
					c: 26 /* ChannelClass.SPATIAL */,
					s: 112 /* ChannelSubClass.SPATIAL_AHRS */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.HEATINGENABLEDCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETSPATIALALGORITHM,
						BP.SETHEATINGENABLED,
						BP.ZERO,
						BP.SETCORRECTIONPARAMETERS,
						BP.SAVECORRECTIONPARAMETERS,
						BP.RESETCORRECTIONPARAMETERS,
						BP.SETAHRSPARAMETERS,
						BP.ZEROSPATIALALGORITHM,
						BP.SPATIALDATA,
						BP.SPATIALALGDATA,
					]
				},
				{
					uid: DeviceChannelUID._MOT0110_TEMPERATURESENSOR_100_USB,
					t: "Temperature Sensor",
					c: 28 /* ChannelClass.TEMPERATURE_SENSOR */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DATAINTERVALCHANGE,
						BP.SETDATAINTERVAL,
						BP.SETCHANGETRIGGER,
						BP.TEMPERATURECHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._HUB0000,
			d: 64 /* DeviceID.PN_HUB0000 */,
			c: 8 /* DeviceClass.HUB */,
			t: "6-Port USB VINT Hub Phidget",
			s: "HUB0000",
			i: 63 /* USB ProductID */,
			v: [100, 200],
			cn: [6,4] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._HUB0000_HUB_100,
					t: "VINT Hub",
					c: 13 /* ChannelClass.HUB */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETFIRMWAREUPGRADEFLAG,
						BP.SETPORTMODE,
						BP.SETPORTPOWER,
						BP.SETCALIBRATIONVALUES,
					]
				},
			]
		},
		{
			uid: DeviceUID._HUB0000_PHIDUSB,
			d: 64 /* DeviceID.PN_HUB0000 */,
			c: 8 /* DeviceClass.HUB */,
			t: "6-Port USB VINT Hub Phidget",
			s: "HUB0000",
			i: 63 /* USB ProductID */,
			v: [300, 400],
			cn: [6,4] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._HUB0000_HUB_300,
					t: "VINT Hub",
					c: 13 /* ChannelClass.HUB */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETFIRMWAREUPGRADEFLAG,
						BP.SETPORTMODE,
						BP.SETPORTPOWER,
						BP.SETCALIBRATIONVALUES,
					]
				},
			]
		},
		{
			uid: DeviceUID._HUB0000_1,
			d: 64 /* DeviceID.PN_HUB0000 */,
			c: 8 /* DeviceClass.HUB */,
			t: "6-Port USB VINT Hub Phidget",
			s: "HUB0000",
			i: 63 /* USB ProductID */,
			v: [400, 500],
			cn: [6,4] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._HUB0000_HUB_400,
					t: "VINT Hub",
					c: 13 /* ChannelClass.HUB */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETFIRMWAREUPGRADEFLAG,
						BP.SETPORTMODE,
						BP.SETPORTPOWER,
						BP.SETCALIBRATIONVALUES2,
					]
				},
			]
		},
		{
			uid: DeviceUID._HUB0001,
			d: 142 /* DeviceID.PN_HUB0001 */,
			c: 8 /* DeviceClass.HUB */,
			t: "6-Port USB VINT Hub Phidget",
			s: "HUB0001",
			i: 67 /* USB ProductID */,
			v: [100, 115],
			cn: [6,4] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._HUB0001_HUB_100,
					t: "VINT Hub",
					c: 13 /* ChannelClass.HUB */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETFIRMWAREUPGRADEFLAG,
						BP.SETPORTMODE,
						BP.SETPORTPOWER,
						BP.SETCALIBRATIONVALUES,
					]
				},
			]
		},
		{
			uid: DeviceUID._HUB0001_AUTOSETSPEED,
			d: 142 /* DeviceID.PN_HUB0001 */,
			c: 8 /* DeviceClass.HUB */,
			t: "6-Port USB VINT Hub Phidget",
			s: "HUB0001",
			i: 67 /* USB ProductID */,
			v: [115, 200],
			cn: [6,4] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._HUB0001_HUB_115,
					t: "VINT Hub",
					c: 13 /* ChannelClass.HUB */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETFIRMWAREUPGRADEFLAG,
						BP.SETPORTMODE,
						BP.SETPORTPOWER,
						BP.SETPORTAUTOSETSPEED,
						BP.SETCALIBRATIONVALUES,
					]
				},
			]
		},
		{
			uid: DeviceUID._HUB0002,
			d: 147 /* DeviceID.PN_HUB0002 */,
			c: 8 /* DeviceClass.HUB */,
			t: "6-Port USB VINT Hub Phidget",
			s: "HUB0002",
			i: 70 /* USB ProductID */,
			v: [100, 200],
			cn: [6,4] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._HUB0002_HUB_100,
					t: "VINT Hub",
					c: 13 /* ChannelClass.HUB */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETFIRMWAREUPGRADEFLAG,
						BP.SETPORTMODE,
						BP.SETPORTPOWER,
						BP.SETPORTAUTOSETSPEED,
						BP.SETCALIBRATIONVALUES2,
					]
				},
			]
		},
		{
			uid: DeviceUID._HUB0007,
			d: 148 /* DeviceID.PN_HUB0007 */,
			c: 8 /* DeviceClass.HUB */,
			t: "1-Port USB VINT Hub Phidget",
			s: "HUB0007",
			i: 71 /* USB ProductID */,
			v: [100, 200],
			cn: [1,4] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._HUB0007_HUB_100,
					t: "VINT Hub",
					c: 13 /* ChannelClass.HUB */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETFIRMWAREUPGRADEFLAG,
						BP.SETPORTMODE,
						BP.SETPORTPOWER,
						BP.SETPORTAUTOSETSPEED,
						BP.SETCALIBRATIONVALUES2,
					]
				},
			]
		},
		{
			uid: DeviceUID._HUB5000,
			d: 123 /* DeviceID.PN_HUB5000 */,
			c: 8 /* DeviceClass.HUB */,
			t: "6-Port Network VINT Hub Phidget",
			s: "HUB5000",
			i: 66 /* USB ProductID */,
			v: [100, 200],
			cn: [6,4] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._HUB5000_HUB_100,
					t: "VINT Hub",
					c: 13 /* ChannelClass.HUB */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETFIRMWAREUPGRADEFLAG,
						BP.SETPORTMODE,
						BP.SETPORTPOWER,
						BP.SETCALIBRATIONVALUES,
					]
				},
			]
		},
		{
			uid: DeviceUID._HUB5000_PHIDUSB,
			d: 123 /* DeviceID.PN_HUB5000 */,
			c: 8 /* DeviceClass.HUB */,
			t: "6-Port Network VINT Hub Phidget",
			s: "HUB5000",
			i: 66 /* USB ProductID */,
			v: [200, 300],
			cn: [6,4] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._HUB5000_HUB_200,
					t: "VINT Hub",
					c: 13 /* ChannelClass.HUB */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETFIRMWAREUPGRADEFLAG,
						BP.SETPORTMODE,
						BP.SETPORTPOWER,
						BP.SETCALIBRATIONVALUES,
					]
				},
			]
		},
		{
			uid: DeviceUID._FIRMWARE_UPGRADE_M3_USB,
			d: 101 /* DeviceID.FIRMWARE_UPGRADE_USB */,
			c: 23 /* DeviceClass.FIRMWARE_UPGRADE */,
			t: "USB Firmware Upgrade (NXP M3)",
			s: "FIRMWARE_UPGRADE_M3",
			i: 152 /* USB ProductID */,
			v: [0, 9999],
			cn: [0,0,0] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._M3_USB_FIRMWARE_UPGRADE_000,
					t: "Firmware Upgrade",
					c: 32 /* ChannelClass.FIRMWARE_UPGRADE */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SENDFIRMWARE,
						BP.PROGRESSCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._FIRMWARE_UPGRADE_STM32F_USB,
			d: 101 /* DeviceID.FIRMWARE_UPGRADE_USB */,
			c: 23 /* DeviceClass.FIRMWARE_UPGRADE */,
			t: "USB Firmware Upgrade (STM32)",
			s: "FIRMWARE_UPGRADE_STM32_USB",
			i: 156 /* USB ProductID */,
			v: [100, 200],
			cn: [0,0,0] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._STM32_USB_FIRMWARE_UPGRADE_100,
					t: "Firmware Upgrade",
					c: 32 /* ChannelClass.FIRMWARE_UPGRADE */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SENDFIRMWARE,
						BP.PROGRESSCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._FIRMWARE_UPGRADE_STM32G_USB,
			d: 101 /* DeviceID.FIRMWARE_UPGRADE_USB */,
			c: 23 /* DeviceClass.FIRMWARE_UPGRADE */,
			t: "USB Firmware Upgrade (STM32G)",
			s: "FIRMWARE_UPGRADE_STM32G_USB",
			i: 156 /* USB ProductID */,
			v: [200, 300],
			cn: [0,0,0] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._STM32_USB_FIRMWARE_UPGRADE_200,
					t: "Firmware Upgrade",
					c: 32 /* ChannelClass.FIRMWARE_UPGRADE */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SENDFIRMWARE,
						BP.PROGRESSCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._FIRMWARE_UPGRADE_STM32L_USB,
			d: 101 /* DeviceID.FIRMWARE_UPGRADE_USB */,
			c: 23 /* DeviceClass.FIRMWARE_UPGRADE */,
			t: "USB Firmware Upgrade (STM32L)",
			s: "FIRMWARE_UPGRADE_STM32L_USB",
			i: 156 /* USB ProductID */,
			v: [300, 400],
			cn: [0,0,0] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._STM32_USB_FIRMWARE_UPGRADE_300,
					t: "Firmware Upgrade",
					c: 32 /* ChannelClass.FIRMWARE_UPGRADE */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SENDFIRMWARE,
						BP.PROGRESSCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._UNKNOWNUSB,
			d: 125 /* DeviceID.UNKNOWN */,
			c: 22 /* DeviceClass.GENERIC */,
			t: "Unsupported USB Phidget",
			s: " ",
			i: 175 /* USB ProductID */,
			v: [0, 9999],
			cn: [0,0,0] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._USB_UNKNOWN,
					t: "Unsupported USB Channel",
					c: 33 /* ChannelClass.GENERIC */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SENDPACKET,
						BP.SENDCHPACKET,
						BP.SENDDEVPACKET,
						BP.READCHPACKET,
						BP.READDEVPACKET,
						BP.PACKET,
					]
				},
			]
		},
	],
	MESH: [
	],
	SPI: [
		{
			uid: DeviceUID._HUB0004,
			d: 67 /* DeviceID.PN_HUB0004 */,
			c: 8 /* DeviceClass.HUB */,
			t: "6-Port PhidgetSBC VINT Hub Phidget",
			s: "HUB0004",
			i: 1 /* id */,
			v: [100, 200],
			cn: [6,4] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._HUB0004_HUB_100,
					t: "VINT Hub",
					c: 13 /* ChannelClass.HUB */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETFIRMWAREUPGRADEFLAG,
						BP.SETPORTMODE,
						BP.SETPORTPOWER,
						BP.SETCALIBRATIONVALUES,
					]
				},
			]
		},
		{
			uid: DeviceUID._HUB0004_BADPORT,
			d: 67 /* DeviceID.PN_HUB0004 */,
			c: 8 /* DeviceClass.HUB */,
			t: "6-Port PhidgetSBC VINT Hub Phidget",
			s: "HUB0004",
			i: 1 /* id */,
			v: [200, 300],
			cn: [6,4] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._HUB0004_HUB_200,
					t: "VINT Hub",
					c: 13 /* ChannelClass.HUB */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SETFIRMWAREUPGRADEFLAG,
						BP.SETPORTMODE,
						BP.SETPORTPOWER,
						BP.SETCALIBRATIONVALUES,
					]
				},
			]
		},
		{
			uid: DeviceUID._FIRMWARE_UPGRADE_M3_SPI,
			d: 104 /* DeviceID.FIRMWARE_UPGRADE_SPI */,
			c: 23 /* DeviceClass.FIRMWARE_UPGRADE */,
			t: "SPI Firmware Upgrade (M3)",
			s: "FIRMWARE_UPGRADE_M3",
			i: 2 /* id */,
			v: [0, 9999],
			cn: [0] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._M3_SPI_FIRMWARE_UPGRADE_000,
					t: "Firmware Upgrade",
					c: 32 /* ChannelClass.FIRMWARE_UPGRADE */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SENDFIRMWARE,
						BP.PROGRESSCHANGE,
					]
				},
			]
		},
		{
			uid: DeviceUID._UNKNOWNSPI,
			d: 125 /* DeviceID.UNKNOWN */,
			c: 22 /* DeviceClass.GENERIC */,
			t: "Unsupported SPI Phidget",
			s: " ",
			i: 65535 /* id */,
			v: [0, 9999],
			cn: [0,0,0] /* Channel Counts */,
			ch: [
				{
					uid: DeviceChannelUID._SPI_UNKNOWN,
					t: "Unsupported SPI Channel",
					c: 33 /* ChannelClass.GENERIC */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.SENDPACKET,
						BP.SENDCHPACKET,
						BP.SENDDEVPACKET,
						BP.READCHPACKET,
						BP.READDEVPACKET,
						BP.PACKET,
					]
				},
			]
		},
	],
	LIGHTNING: [
	],
	VIRTUAL: [
		{
			uid: DeviceUID._DICTIONARY,
			d: 111 /* DeviceID.PN_DICTIONARY */,
			c: 24 /* DeviceClass.DICTIONARY */,
			t: "Dictionary",
			s: "Dictionary",
			i: 0 /* id */,
			v: [100, 200],
			ch: [
				{
					uid: DeviceChannelUID._DICTIONARY,
					t: "Dictionary",
					c: 36 /* ChannelClass.DICTIONARY */,
					i: 0 /* Index */,
					p: /* Supported Packets */ [
						BP.DICTIONARYADD,
						BP.DICTIONARYUPDATE,
						BP.DICTIONARYSET,
						BP.DICTIONARYREMOVE,
						BP.DICTIONARYREMOVEALL,
						BP.DICTIONARYGET,
						BP.DICTIONARYSCAN,
						BP.DICTIONARYADDED,
						BP.DICTIONARYUPDATED,
						BP.DICTIONARYREMOVED,
					]
				},
			]
		},
	],
}

