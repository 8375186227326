/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { MotorPositionControllerBase } from './MotorPositionController.gen';
import { ErrorEventCode, ErrorCode, PositionType } from '../Enumerations.gen';
import { BP } from '../BridgePackets.gen';
import { PhidgetError } from '../PhidgetError';
import { BridgePacket, PUNK } from '../BridgePacket';
import { logEventException } from '../Logging';
import { DeviceChannelUID } from '../Devices.gen';

/** @public */
class MotorPositionController extends MotorPositionControllerBase {

	/** @internal */
	_bridgeInput(bp: BridgePacket) {
		switch (bp.vpkt) {
			case BP.POSITIONCHANGE:
				this.data.position = bp.getNumber(0);
				if (this._isAttachedDone && this.onPositionChange) {
					try {
						this.onPositionChange((this.data.position + this.data.positionOffset) * this.data.rescaleFactor);
					} catch (err) { logEventException(err); }
				}
				break;
			default:
				super._bridgeInput(bp);
				break;
		}
	}

	/** @internal */
	_errorHandler(code: ErrorEventCode) {
		switch (code) {
			case ErrorEventCode.MOTOR_STALL_CONDITION:
				this.data.engaged = 0;
				break;
		}
	}

	getAcceleration() {
		this._assertOpen();

		if (this.data.acceleration === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.acceleration * Math.abs(this.data.rescaleFactor));
	}

	async setAcceleration(acceleration: number) {
		this._assertOpen();

		const calcAccel = acceleration / Math.abs(this.data.rescaleFactor);

		if (calcAccel < this.data.minAcceleration || calcAccel > this.data.maxAcceleration)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.minAcceleration + " - " + this.maxAcceleration + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: calcAccel });
		await bp.send(this._ch, BP.SETACCELERATION);
	}

	getMinAcceleration() {
		this._assertOpen();

		if (this.data.minAcceleration === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minAcceleration * Math.abs(this.data.rescaleFactor));
	}

	getMaxAcceleration() {
		this._assertOpen();

		if (this.data.maxAcceleration === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxAcceleration * Math.abs(this.data.rescaleFactor));
	}

	addPositionOffset(positionOffset: number) {
		this._assertOpen();

		this.data.positionOffset += (positionOffset / this.data.rescaleFactor);
	}

	getDeadBand() {
		this._assertOpen();

		if (this.data.deadBand === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.deadBand * Math.abs(this.data.rescaleFactor));
	}

	async setDeadBand(deadBand: number) {
		this._assertOpen();

		const calcDeadband = deadBand / Math.abs(this.data.rescaleFactor);
		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: calcDeadband });
		await bp.send(this._ch, BP.SETDEADBAND);
	}

	getPosition() {
		this._assertOpen();

		if (this.data.position === PUNK.INT64)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return ((this.data.position + this.data.positionOffset) * this.data.rescaleFactor);
	}

	getMinPosition() {
		this._assertOpen();

		if (this.data.minPosition === PUNK.INT64)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return ((this.data.minPosition + this.data.positionOffset) * Math.abs(this.data.rescaleFactor));
	}

	getMaxPosition() {
		this._assertOpen();

		if (this.data.maxPosition === PUNK.INT64)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return ((this.data.maxPosition + this.data.positionOffset) * Math.abs(this.data.rescaleFactor));
	}

	setRescaleFactor(rescaleFactor: number) {
		this._assertOpen();

		this.data.rescaleFactor = rescaleFactor;
	}

	getTargetPosition() {
		this._assertOpen();

		if (this.data.targetPosition === PUNK.INT64)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return ((this.data.targetPosition + this.data.positionOffset) * this.data.rescaleFactor);
	}

	async setTargetPosition(targetPosition: number) {
		this._assertOpen();

		const calcPosition = (targetPosition / this.data.rescaleFactor) - this.data.positionOffset;

		if (calcPosition < this.data.minPosition || calcPosition > this.data.maxPosition)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.minPosition + " - " + this.maxPosition + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "l", value: calcPosition });
		await bp.send(this._ch, BP.SETTARGETPOSITION);
	}

	getVelocityLimit() {
		this._assertOpen();

		if (this.data.velocityLimit === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.velocityLimit * Math.abs(this.data.rescaleFactor));
	}

	getStallVelocity() {
		this._assertOpen();

		if (this.data.stallVelocity === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.stallVelocity * Math.abs(this.data.rescaleFactor));
	}

	async setStallVelocity(stallVelocity: number) {
		this._assertOpen();

		const calcVelocity = stallVelocity / Math.abs(this.data.rescaleFactor);

		if (calcVelocity < this.data.minStallVelocity || calcVelocity > this.data.maxStallVelocity)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.minStallVelocity + " - " + this.maxStallVelocity + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: calcVelocity });
		await bp.send(this._ch, BP.SETSTALLVELOCITY);
	}

	async setVelocityLimit(velocityLimit: number) {
		this._assertOpen();

		const calcLimit = velocityLimit / Math.abs(this.data.rescaleFactor);

		if (calcLimit < this.data.minVelocityLimit || calcLimit > this.data.maxVelocityLimit)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.minVelocityLimit + " - " + this.maxVelocityLimit + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: calcLimit });
		await bp.send(this._ch, BP.SETDUTYCYCLE);
	}

	getMinStallVelocity() {
		this._assertOpen();

		if (this.data.minStallVelocity === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minStallVelocity * Math.abs(this.data.rescaleFactor));
	}

	getMaxStallVelocity() {
		this._assertOpen();

		if (this.data.maxStallVelocity === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxStallVelocity * Math.abs(this.data.rescaleFactor));
	}

	getMinVelocityLimit() {
		this._assertOpen();

		if (this.data.minVelocityLimit === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minVelocityLimit * Math.abs(this.data.rescaleFactor));
	}

	getMaxVelocityLimit() {
		this._assertOpen();

		if (this.data.maxVelocityLimit === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxVelocityLimit * Math.abs(this.data.rescaleFactor));
	}

	getKd(): number {
		this._assertOpen();

		if (this.data.kd === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		switch (this._ch!.chDef.uid) {
			case DeviceChannelUID._DCC1020_MOTORPOSITIONCONTROLLER_100:
				return (this.data.kd / Math.abs(this.data.rescaleFactor));
			default:
				return (this.data.kd);
		}
	}

	async setKd(kd: number): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		switch (this._ch!.chDef.uid) {
			case DeviceChannelUID._DCC1020_MOTORPOSITIONCONTROLLER_100:
				bp.set({ name: "0", type: "g", value: (kd * Math.abs(this.data.rescaleFactor)) });
				break;
			default:
				bp.set({ name: "0", type: "g", value: kd });
				break;
		}
		await bp.send(this._ch, BP.SETKD);
	}

	getKi(): number {
		this._assertOpen();

		if (this.data.ki === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		switch (this._ch!.chDef.uid) {
			case DeviceChannelUID._DCC1020_MOTORPOSITIONCONTROLLER_100:
				return (this.data.ki / Math.abs(this.data.rescaleFactor));
			default:
				return (this.data.ki);
		}
	}

	async setKi(ki: number): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		switch (this._ch!.chDef.uid) {
			case DeviceChannelUID._DCC1020_MOTORPOSITIONCONTROLLER_100:
				bp.set({ name: "0", type: "g", value: (ki * Math.abs(this.data.rescaleFactor)) });
				break;
			default:
				bp.set({ name: "0", type: "g", value: ki });
				break;
		}
		await bp.send(this._ch, BP.SETKI);
	}

	getKp(): number {
		this._assertOpen();

		if (this.data.kp === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		switch (this._ch!.chDef.uid) {
			case DeviceChannelUID._DCC1020_MOTORPOSITIONCONTROLLER_100:
				return (this.data.kp / Math.abs(this.data.rescaleFactor));
			default:
				return (this.data.kp);
		}
	}

	async setKp(kp: number): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		switch (this._ch!.chDef.uid) {
			case DeviceChannelUID._DCC1020_MOTORPOSITIONCONTROLLER_100:
				bp.set({ name: "0", type: "g", value: (kp * Math.abs(this.data.rescaleFactor)) });
				break;
			default:
				bp.set({ name: "0", type: "g", value: kp });
				break;
		}
		await bp.send(this._ch, BP.SETKP);
	}
	
	/*START_UNRELEASED*/
	getExpectedPosition(): number {
		// XXX implement
		throw new Error('Method not implemented.');
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setNormalizePID(_normalizePID: boolean): void {
		// XXX implement
		throw new Error('Method not implemented.');
	}
	
	getPositionType(): PositionType {
		// XXX implement
		throw new Error('Method not implemented.');
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setPositionType(_positionType: PositionType): Promise<void> {
		// XXX implement
		throw new Error('Method not implemented.');
	} /*END_UNRELEASED*/
}

export { MotorPositionController };