/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PhidgetChannel } from '../Phidget';
import { Channel } from '../Channel';
import { ErrorCode, ChannelClass } from '../Enumerations.gen';
import * as Enum from '../Enumerations.gen';
import * as SEnum from '../SupportedEnum.gen';
import { PhidgetError } from '../PhidgetError';
import { BridgePacket, PUNK } from '../BridgePacket';
import { BP } from '../BridgePackets.gen';
import { DeviceChannelUID } from '../Devices.gen';

/** @internal */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DigitalOutputData {
	dutyCycle: number,
	maxDutyCycle: number,
	minDutyCycle: number,
	LEDCurrentLimit: number,
	minLEDCurrentLimit: number,
	maxLEDCurrentLimit: number,
	LEDForwardVoltage: Enum.LEDForwardVoltage | PUNK.ENUM,
	state: number,
	maxFailsafeTime: number,
	minFailsafeTime: number,
	frequency: number,
	maxFrequency: number,
	minFrequency: number,
}

abstract class DigitalOutputBase extends PhidgetChannel {
	/** @internal */
	data: DigitalOutputData;

	/**
	 * The Digital Output class is used to control digital logic outputs and LED outputs on Phidgets boards.
	 * @public
	 */
	constructor();
	/** @internal */
	constructor(ch?: Channel);
	constructor(ch?: Channel) {
		super(ch);
		this._class = ChannelClass.DIGITAL_OUTPUT;
		this.name = "DigitalOutput";
		this.data = this._initData();
	}

	/** @internal */
	_bridgeInput(bp: BridgePacket) {

		switch(bp.vpkt) {
		case BP.SETDUTYCYCLE:
			this.data.dutyCycle = bp.entries[0].v as number;
			this._FIREPropertyChange('DutyCycle', bp);
			break;
		case BP.SETFAILSAFETIME:
			break;
		case BP.SETFREQUENCY:
			this.data.frequency = bp.entries[0].v as number;
			this._FIREPropertyChange('Frequency', bp);
			break;
		case BP.SETLEDCURRENTLIMIT:
			this.data.LEDCurrentLimit = bp.entries[0].v as number;
			this._FIREPropertyChange('LEDCurrentLimit', bp);
			break;
		case BP.SETLEDFORWARDVOLTAGE:
			this.data.LEDForwardVoltage = bp.entries[0].v as Enum.LEDForwardVoltage;
			this._FIREPropertyChange('LEDForwardVoltage', bp);
			break;
		case BP.FAILSAFERESET:
			break;
		case BP.SETSTATE:
			this.data.state = bp.entries[0].v as number;
			this._FIREPropertyChange('State', bp);
			break;
		default:
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			throw new PhidgetError(ErrorCode.INVALID_PACKET, "Unsupported bridge packet: 0x" + bp.vpkt!.toString(16));
		}
	}

	/** @internal */
	_initData(): DigitalOutputData {
		return {
			dutyCycle: PUNK.DBL,
			maxDutyCycle: PUNK.DBL,
			minDutyCycle: PUNK.DBL,
			LEDCurrentLimit: PUNK.DBL,
			minLEDCurrentLimit: PUNK.DBL,
			maxLEDCurrentLimit: PUNK.DBL,
			LEDForwardVoltage: PUNK.ENUM,
			state: PUNK.BOOL,
			maxFailsafeTime: PUNK.UINT32,
			minFailsafeTime: PUNK.UINT32,
			frequency: PUNK.DBL,
			maxFrequency: PUNK.DBL,
			minFrequency: PUNK.DBL,
		}
	}

	/** @internal */
	_initAfterOpen() {
		this.data = this._initData();

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._1014_DIGITALOUTPUT_800_USB:
		case DeviceChannelUID._1014_DIGITALOUTPUT_800_VINT:
		case DeviceChannelUID._1017_DIGITALOUTPUT_200_USB:
		case DeviceChannelUID._1017_DIGITALOUTPUT_200_VINT:
		case DeviceChannelUID._OUT1100_DIGITALOUTPUT_110:
		case DeviceChannelUID._REL1000_DIGITALOUTPUT_110:
		case DeviceChannelUID._REL1100_DIGITALOUTPUT_110:
		case DeviceChannelUID._REL1101_DIGITALOUTPUT_110:
		case DeviceChannelUID._REL1101_DIGITALOUTPUT_200:
			this.data.dutyCycle = 0;
			this.data.maxDutyCycle = 1;
			this.data.minDutyCycle = 0;
			this.data.state = 0;
			this.data.maxFailsafeTime = 30000;
			this.data.minFailsafeTime = 500;
			break;
		case DeviceChannelUID._1018_DIGITALOUTPUT_1000:
		case DeviceChannelUID._HUB_DIGITALOUTPUT_100:
		case DeviceChannelUID._OUT1100_DIGITALOUTPUT_100:
		case DeviceChannelUID._REL1000_DIGITALOUTPUT_100:
		case DeviceChannelUID._REL1100_DIGITALOUTPUT_100:
		case DeviceChannelUID._REL1101_DIGITALOUTPUT_100:
			this.data.dutyCycle = 0;
			this.data.maxDutyCycle = 1;
			this.data.minDutyCycle = 0;
			this.data.state = 0;
			break;
		case DeviceChannelUID._HUB_DIGITALOUTPUT_110:
			this.data.dutyCycle = 0;
			this.data.maxDutyCycle = 1;
			this.data.minDutyCycle = 0;
			this.data.state = 0;
			this.data.maxFailsafeTime = 60000;
			this.data.minFailsafeTime = 100;
			break;
		case DeviceChannelUID._OUT1100_DIGITALOUTPUT_120:
		case DeviceChannelUID._REL1100_DIGITALOUTPUT_120:
			this.data.dutyCycle = 0;
			this.data.maxDutyCycle = 1;
			this.data.minDutyCycle = 0;
			this.data.state = 0;
			this.data.maxFailsafeTime = 30000;
			this.data.minFailsafeTime = 500;
			this.data.frequency = 15625;
			this.data.maxFrequency = 20000;
			this.data.minFrequency = 100;
			break;
		case DeviceChannelUID._LED1000_DIGITALOUTPUT_100:
			this.data.dutyCycle = 0;
			this.data.maxDutyCycle = 1;
			this.data.minDutyCycle = 0;
			this.data.LEDCurrentLimit = 0.02;
			this.data.minLEDCurrentLimit = 0;
			this.data.maxLEDCurrentLimit = 0.04;
			this.data.LEDForwardVoltage = Enum.LEDForwardVoltage.VOLTS_3_2;
			this.data.state = 0;
			break;
		case DeviceChannelUID._REL1101_DIGITALOUTPUT_FREQ_200:
			this.data.dutyCycle = 0;
			this.data.maxDutyCycle = 1;
			this.data.minDutyCycle = 0;
			this.data.state = 0;
			this.data.maxFailsafeTime = 30000;
			this.data.minFailsafeTime = 500;
			this.data.frequency = 20000;
			this.data.maxFrequency = 20000;
			this.data.minFrequency = 100;
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	// eslint-disable-next-line require-await
	async _setDefaults() {

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._1014_DIGITALOUTPUT_800_USB:
		case DeviceChannelUID._1014_DIGITALOUTPUT_800_VINT:
		case DeviceChannelUID._1017_DIGITALOUTPUT_200_USB:
		case DeviceChannelUID._1017_DIGITALOUTPUT_200_VINT:
		case DeviceChannelUID._1018_DIGITALOUTPUT_1000:
		case DeviceChannelUID._HUB_DIGITALOUTPUT_100:
		case DeviceChannelUID._HUB_DIGITALOUTPUT_110:
		case DeviceChannelUID._OUT1100_DIGITALOUTPUT_100:
		case DeviceChannelUID._OUT1100_DIGITALOUTPUT_110:
		case DeviceChannelUID._OUT1100_DIGITALOUTPUT_120:
		case DeviceChannelUID._LED1000_DIGITALOUTPUT_100:
		case DeviceChannelUID._REL1000_DIGITALOUTPUT_100:
		case DeviceChannelUID._REL1000_DIGITALOUTPUT_110:
		case DeviceChannelUID._REL1100_DIGITALOUTPUT_100:
		case DeviceChannelUID._REL1100_DIGITALOUTPUT_110:
		case DeviceChannelUID._REL1100_DIGITALOUTPUT_120:
		case DeviceChannelUID._REL1101_DIGITALOUTPUT_100:
		case DeviceChannelUID._REL1101_DIGITALOUTPUT_110:
		case DeviceChannelUID._REL1101_DIGITALOUTPUT_FREQ_200:
		case DeviceChannelUID._REL1101_DIGITALOUTPUT_200:
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	_hasInitialState() {


		return true;
	}

	/** @internal */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	_fireInitialEvents() {

	}

	/**
	 * The `dutyCycle` represents the fraction of time the output is on (high).
	 * 
	 * *   A `dutyCycle` of 1.0 translates to a high output, a `dutyCycle` of 0 translates to a low output.
	 * *   A `dutyCycle` of 0.5 translates to an output that is high half the time, which results in an average output voltage of (output voltage x 0.5)
	 * *   You can use the `dutyCycle` to create a dimming effect on LEDs.
	 * @throws {@link PhidgetError}
	 */
	get dutyCycle() { return this.getDutyCycle(); }
	/**
	 * The minimum value that `dutyCycle` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDutyCycle() { return this.getMinDutyCycle(); }
	/**
	 * The maximum value that `dutyCycle` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDutyCycle() { return this.getMaxDutyCycle(); }
	/**
	 * The minimum value that `failsafeTime` can be set to when calling `enableFailsafe()`.
	 * @throws {@link PhidgetError}
	 */
	get minFailsafeTime() { return this.getMinFailsafeTime(); }
	/**
	 * The maximum value that `failsafeTime` can be set to when calling `enableFailsafe()`.
	 * @throws {@link PhidgetError}
	 */
	get maxFailsafeTime() { return this.getMaxFailsafeTime(); }
	/**
	 * The `frequency` parameter sets the PWM frequency for all frequency-settable PWM outputs on the board.
	 * @throws {@link PhidgetError}
	 */
	get frequency() { return this.getFrequency(); }
	/**
	 * The minimum value that `frequency` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minFrequency() { return this.getMinFrequency(); }
	/**
	 * The maximum value that `frequency` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxFrequency() { return this.getMaxFrequency(); }
	/**
	 * The `LEDCurrentLimit` is the maximum amount of current that the controller will provide to the output.
	 * 
	 * *   Reference the data sheet of the LED you are using before setting this value.
	 * @throws {@link PhidgetError}
	 */
	get LEDCurrentLimit() { return this.getLEDCurrentLimit(); }
	/**
	 * The minimum value that `LEDCurrentLimit` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minLEDCurrentLimit() { return this.getMinLEDCurrentLimit(); }
	/**
	 * The maximum value that `LEDCurrentLimit` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxLEDCurrentLimit() { return this.getMaxLEDCurrentLimit(); }
	/**
	 * The `LEDForwardVoltage` is the voltage that will be available to your LED.
	 * 
	 * *   Reference the data sheet of the LED you are using before setting this value. Choose the `LEDForwardVoltage` that is closest to the forward voltage specified in the data sheet.
	 * *   This forward voltage is shared for all channels on this device. Setting the LEDForwardVoltage on any channel will set the LEDForwardVoltage for all channels on the device.
	 * @throws {@link PhidgetError}
	 */
	get LEDForwardVoltage() { return this.getLEDForwardVoltage(); }
	/**
	 * The `state` will indicate whether the output is high (TRUE) or low (FALSE).
	 * 
	 * *   If a `dutyCycle` has been set, the state will return as TRUE if the DutyCycle is above 0.5, or FALSE otherwise.
	 * @throws {@link PhidgetError}
	 */
	get state() { return this.getState(); }

	/**
	 * The `dutyCycle` represents the fraction of time the output is on (high).
	 * 
	 * *   A `dutyCycle` of 1.0 translates to a high output, a `dutyCycle` of 0 translates to a low output.
	 * *   A `dutyCycle` of 0.5 translates to an output that is high half the time, which results in an average output voltage of (output voltage x 0.5)
	 * *   You can use the `dutyCycle` to create a dimming effect on LEDs.
	 * @returns The duty cycle value
	 * @throws {@link PhidgetError}
	 */
	getDutyCycle(): number {
		this._assertOpen();

		if (this.data.dutyCycle === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.dutyCycle);
	}

	/**
	 * The `dutyCycle` represents the fraction of time the output is on (high).
	 * 
	 * *   A `dutyCycle` of 1.0 translates to a high output, a `dutyCycle` of 0 translates to a low output.
	 * *   A `dutyCycle` of 0.5 translates to an output that is high half the time, which results in an average output voltage of (output voltage x 0.5)
	 * *   You can use the `dutyCycle` to create a dimming effect on LEDs.
	 * @throws {@link PhidgetError}
	 * @param dutyCycle - The duty cycle value
	 */
	async setDutyCycle(dutyCycle: number): Promise<void> {
		this._assertOpen();

		if (dutyCycle < this.data.minDutyCycle || dutyCycle > this.data.maxDutyCycle)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDutyCycle + " - " + this.data.maxDutyCycle + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: dutyCycle });
		await bp.send(this._ch, BP.SETDUTYCYCLE);
	}

	/**
	 * The minimum value that `dutyCycle` can be set to.
	 * @returns The duty cycle value
	 * @throws {@link PhidgetError}
	 */
	getMinDutyCycle(): number {
		this._assertOpen();

		if (this.data.minDutyCycle === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDutyCycle);
	}

	/**
	 * The maximum value that `dutyCycle` can be set to.
	 * @returns The duty cycle value
	 * @throws {@link PhidgetError}
	 */
	getMaxDutyCycle(): number {
		this._assertOpen();

		if (this.data.maxDutyCycle === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDutyCycle);
	}

	/**
	 * Enables the **failsafe** feature for the channel, with a given **failsafe time**.
	 * 
	 * The **failsafe** feature is intended for use in applications where it is important for the channel to enter a known _safe state_ if the program controlling it locks up or crashes. If you do not enable the failsafe feature, the channel will carry out whatever instructions it was last given until it is explicitly told to stop.
	 * 
	 * Enabling the failsafe feature starts a recurring **failsafe timer** for the channel. Once the failsafe timer is enabled, it must be reset within the specified time or the channel will enter a **failsafe state**. The failsafe timer may be reset by sending any valid command to the device\*. Resetting the failsafe timer will reload the timer with the specified _failsafe time_, starting when the message to reset the timer is received by the Phidget.
	 * 
	 * _\*(**get** requests do not typically send commands and won't reset the failsafe timer)_
	 * 
	 * For example: if the failsafe is enabled with a **failsafe time** of 1000ms, you will have 1000ms to reset the failsafe timer. Every time the failsafe timer is reset, you will have 1000ms from that time to reset the failsafe again.
	 * 
	 * If the failsafe timer is not reset before it runs out, the channel will enter a **failsafe state**. For Digital Output channels, this will set the output State to FALSE. Once the channel enters the **failsafe state**, it will reject any further input until the channel is reopened.
	 * 
	 * To prevent the channel from falsely entering the failsafe state, we recommend resetting the failsafe timer as frequently as is practical for your application. A good rule of thumb is to not let more than a third of the failsafe time pass before resetting the timer.
	 * 
	 * Once the failsafe timer has been set, it cannot be disabled by any means other than closing and reopening the channel.
	 * @throws {@link PhidgetError}
	 * @param failsafeTime - Failsafe timeout in milliseconds
	 */
	async enableFailsafe(failsafeTime: number): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: failsafeTime });
		await bp.send(this._ch, BP.SETFAILSAFETIME);
	}

	/**
	 * The minimum value that `failsafeTime` can be set to when calling `enableFailsafe()`.
	 * @returns The failsafe time
	 * @throws {@link PhidgetError}
	 */
	getMinFailsafeTime(): number {
		this._assertOpen();

		if (this.data.minFailsafeTime === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minFailsafeTime);
	}

	/**
	 * The maximum value that `failsafeTime` can be set to when calling `enableFailsafe()`.
	 * @returns The failsafe time
	 * @throws {@link PhidgetError}
	 */
	getMaxFailsafeTime(): number {
		this._assertOpen();

		if (this.data.maxFailsafeTime === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxFailsafeTime);
	}

	/**
	 * The `frequency` parameter sets the PWM frequency for all frequency-settable PWM outputs on the board.
	 * @returns The PWM frequency
	 * @throws {@link PhidgetError}
	 */
	getFrequency(): number {
		this._assertOpen();

		if (this.data.frequency === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.frequency);
	}

	/**
	 * The `frequency` parameter sets the PWM frequency for all frequency-settable PWM outputs on the board.
	 * @throws {@link PhidgetError}
	 * @param frequency - The PWM frequency
	 */
	async setFrequency(frequency: number): Promise<void> {
		this._assertOpen();

		if (frequency < this.data.minFrequency || frequency > this.data.maxFrequency)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minFrequency + " - " + this.data.maxFrequency + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: frequency });
		await bp.send(this._ch, BP.SETFREQUENCY);
	}

	/**
	 * The minimum value that `frequency` can be set to.
	 * @returns The frequency
	 * @throws {@link PhidgetError}
	 */
	getMinFrequency(): number {
		this._assertOpen();

		if (this.data.minFrequency === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minFrequency);
	}

	/**
	 * The maximum value that `frequency` can be set to.
	 * @returns The frequency
	 * @throws {@link PhidgetError}
	 */
	getMaxFrequency(): number {
		this._assertOpen();

		if (this.data.maxFrequency === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxFrequency);
	}

	/**
	 * The `LEDCurrentLimit` is the maximum amount of current that the controller will provide to the output.
	 * 
	 * *   Reference the data sheet of the LED you are using before setting this value.
	 * @returns The current limit value
	 * @throws {@link PhidgetError}
	 */
	getLEDCurrentLimit(): number {
		this._assertOpen();

		if (this.data.LEDCurrentLimit === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.LEDCurrentLimit);
	}

	/**
	 * The `LEDCurrentLimit` is the maximum amount of current that the controller will provide to the output.
	 * 
	 * *   Reference the data sheet of the LED you are using before setting this value.
	 * @throws {@link PhidgetError}
	 * @param LEDCurrentLimit - The current limit value
	 */
	async setLEDCurrentLimit(LEDCurrentLimit: number): Promise<void> {
		this._assertOpen();

		if (LEDCurrentLimit < this.data.minLEDCurrentLimit || LEDCurrentLimit > this.data.maxLEDCurrentLimit)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minLEDCurrentLimit + " - " + this.data.maxLEDCurrentLimit + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: LEDCurrentLimit });
		await bp.send(this._ch, BP.SETLEDCURRENTLIMIT);
	}

	/**
	 * The minimum value that `LEDCurrentLimit` can be set to.
	 * @returns The current limit value
	 * @throws {@link PhidgetError}
	 */
	getMinLEDCurrentLimit(): number {
		this._assertOpen();

		if (this.data.minLEDCurrentLimit === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minLEDCurrentLimit);
	}

	/**
	 * The maximum value that `LEDCurrentLimit` can be set to.
	 * @returns The current limit value
	 * @throws {@link PhidgetError}
	 */
	getMaxLEDCurrentLimit(): number {
		this._assertOpen();

		if (this.data.maxLEDCurrentLimit === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxLEDCurrentLimit);
	}

	/**
	 * The `LEDForwardVoltage` is the voltage that will be available to your LED.
	 * 
	 * *   Reference the data sheet of the LED you are using before setting this value. Choose the `LEDForwardVoltage` that is closest to the forward voltage specified in the data sheet.
	 * *   This forward voltage is shared for all channels on this device. Setting the LEDForwardVoltage on any channel will set the LEDForwardVoltage for all channels on the device.
	 * @returns The forward voltage value
	 * @throws {@link PhidgetError}
	 */
	getLEDForwardVoltage(): Enum.LEDForwardVoltage {
		this._assertOpen();

		if (this.data.LEDForwardVoltage === PUNK.ENUM)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.LEDForwardVoltage);
	}

	/**
	 * The `LEDForwardVoltage` is the voltage that will be available to your LED.
	 * 
	 * *   Reference the data sheet of the LED you are using before setting this value. Choose the `LEDForwardVoltage` that is closest to the forward voltage specified in the data sheet.
	 * *   This forward voltage is shared for all channels on this device. Setting the LEDForwardVoltage on any channel will set the LEDForwardVoltage for all channels on the device.
	 * @throws {@link PhidgetError}
	 * @param LEDForwardVoltage - The forward voltage value
	 */
	async setLEDForwardVoltage(LEDForwardVoltage: Enum.LEDForwardVoltage): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (!SEnum.supportedLEDForwardVoltage(this._ch!, LEDForwardVoltage))
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Specified LEDForwardVoltage is unsupported by this device.");

		bp.set({ name: "0", type: "d", value: LEDForwardVoltage });
		await bp.send(this._ch, BP.SETLEDFORWARDVOLTAGE);
	}

	/**
	 * Resets the failsafe timer, if one has been set. See `enableFailsafe()` for details.
	 * 
	 * This function will fail if no failsafe timer has been set for the channel.
	 * @throws {@link PhidgetError}
	 */
	async resetFailsafe(): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		await bp.send(this._ch, BP.FAILSAFERESET);
	}

	/**
	 * The `state` will indicate whether the output is high (TRUE) or low (FALSE).
	 * 
	 * *   If a `dutyCycle` has been set, the state will return as TRUE if the DutyCycle is above 0.5, or FALSE otherwise.
	 * @returns The state value
	 * @throws {@link PhidgetError}
	 */
	getState(): boolean {
		this._assertOpen();

		if (this.data.state === PUNK.BOOL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (!!this.data.state);
	}

	/**
	 * The `state` will dictate whether the output is constantly high (TRUE) or low (FALSE).
	 * 
	 * *   This will override any `dutyCycle` that may have been set on the channel.
	 * *   Setting the `state` to TRUE is the same as setting `dutyCycle` to 1.0, and setting the `state` to FALSE is the same as setting a `dutyCycle` of 0.0.
	 * @throws {@link PhidgetError}
	 * @param state - The state value
	 */
	async setState(state: boolean): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (state !== false && state !== true)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be a boolean.");

		bp.set({ name: "0", type: "d", value: (state ? 1 : 0) });
		await bp.send(this._ch, BP.SETSTATE);
	}

}
export { DigitalOutputBase };
