﻿import { NR, P22MSG, P22SMSG } from './NetworkConnection';

/** @internal */
class Request {
	hdrlen: number;
	flgs!: number;
	len!: number;
	reqseq!: number;
	repseq!: number;
	type!: P22MSG;
	stype!: P22SMSG;

	/*
	 * Constructor takes either a buffer to parse, or the parameters to create a new request.
	 */
	constructor(dataOrLength: Uint8Array);
	constructor(dataOrLength: number, flags: number, reqseq: number, repseq: number, type: P22MSG, stype: P22SMSG);
	constructor(dataOrLength: number | Uint8Array, flgs?: number, reqseq?: number, repseq?: number, type?: P22MSG, stype?: P22SMSG) {

		if (typeof dataOrLength === 'number') {
			if (flgs === undefined || reqseq === undefined || repseq === undefined || type === undefined || stype === undefined)
				throw new Error("Invalid Input");
			this.len = dataOrLength;
			this.flgs = flgs;
			this.reqseq = reqseq;
			this.repseq = repseq;
			this.type = type;
			this.stype = stype;
			this.hdrlen = 16;
		} else {
			this.hdrlen = this.parse(dataOrLength);
		}
	}

	
	toString() {

		return ('{flags:0x' + this.flgs.toString(16) + ' len:' + this.len +
			' reqseq:' + this.reqseq + ' repseq:' + this.repseq +
			' type:' + this.type + ' subtype:' + this.stype + '}');
	}

	parse(buf: Uint8Array) {
		const magic = (buf[3] << 24) | (buf[2] << 16) | (buf[1] << 8) | buf[0];
		this.len = (buf[7] << 24) | (buf[6] << 16) | (buf[5] << 8) | buf[4];
		this.flgs = (buf[9] << 8) | buf[8];
		this.reqseq = (buf[11] << 8) | buf[10];
		this.repseq = (buf[13] << 8) | buf[12];
		this.type = buf[14];
		this.stype = buf[15];

		if (magic != NR.Magic)
			throw new Error("Bad Request Magic");

		return (16);
	}

	get buffer() {
		const array = new Uint8Array(16);

		array[3] = (NR.Magic >> 24) & 0xff;
		array[2] = (NR.Magic >> 16) & 0xff;
		array[1] = (NR.Magic >> 8) & 0xff;
		array[0] = NR.Magic & 0xff;
		array[7] = (this.len >> 24) & 0xff;
		array[6] = (this.len >> 16) & 0xff;
		array[5] = (this.len >> 8) & 0xff;
		array[4] = this.len & 0xff;
		array[9] = (this.flgs >> 8) & 0xff;
		array[8] = this.flgs & 0xff;
		array[11] = (this.reqseq >> 8) & 0xff;
		array[10] = this.reqseq & 0xff;
		array[13] = (this.repseq >> 8) & 0xff;
		array[12] = this.repseq & 0xff;
		array[14] = this.type;
		array[15] = this.stype;

		return (array);
	}
}

export { Request };