import * as Enum from './Enumerations.gen';
import { Phidget } from './Phidget';
import { PhidgetConnection } from './Connection';

/**
 * The core Phidget class deals with functionality common to all Phidgets, such as opening and closing them, or setting Attach, Detach and Event handlers.
 * 
 * This class is also used to specify the associations between the Phidget software objects and their corresponding physical devices, and makes it possible to determine which Phidget is which in cases where it might otherwise be ambiguous.
 * 
 * This class contains various functions such as **Release**, **Retain**, and **getParent** designed to be used with the **Phidget Manager**. These specialized functions may be safely ignored if your application does not require a **Manager**. You can check the **Manager API** for more information.
 * @public
 */
abstract class PhidgetBase {
	/**
	 * Pass to <code>setDeviceSerialNumber()</code> to open any serial number.
	 */
	static get ANY_SERIAL_NUMBER() { return -1; }
	/**
	 * Pass to <code>setHubPort()</code> to open any hub port.
	 */
	static get ANY_HUB_PORT() { return -1; }
	/**
	 * Pass to <code>setChannel()</code> to open any channel.
	 */
	static get ANY_CHANNEL() { return -1; }
	/**
	 * Pass to <code>setDeviceLabel()</code> to open any label.
	 */
	static get ANY_LABEL() { return null; }
	/**
	 * Pass to <code>open()</code> for an infinite timeout.
	 */
	static get INFINITE_TIMEOUT() { return 0; }
	/**
	 * Pass to <code>open()</code> for the default timeout.
	 */
	static get DEFAULT_TIMEOUT() { return 2500; }
	/**
	 * Pass to <code>setHubPortSpeed()</code> to set the Hub Port speed automatically when supported by both the hub port and the VINT device.
	 */
	static get AUTO_HUBPORTSPEED() { return 0; }
	/**
	 * Gets the attached status of channel. A Phidget is attached after it has been opened and the Phidget library finds and connects to the corresponding hardware device.
	 * 
	 * *   Most API calls are only valid on attached Phidgets.
	 * @throws {@link PhidgetError}
	 */
	get attached() { return this.getAttached(); }
	/**
	 * get: Gets the channel index of the channel on the device.  
	 *   
	 * set: Specifies the channel index to be opened. The default channel is 0. Set to `ANY_CHANNEL` to open any channel on the specified device.  
	 *   
	 * If setting this property, it must be set before the channel is opened. The behaviour of setting this property while the channel is open is undefined.
	 * @throws {@link PhidgetError}
	 */
	get channel() { return this.getChannel(); }
	set channel(channel: number) { this.setChannel(channel); }
	/**
	 * Returns true if this represents a channel, false if this represents a device. Mostly for use alongside `getParent()` to distinguish channel handles from device handles.
	 * @throws {@link PhidgetError}
	 */
	get isChannel() { return this.getIsChannel(); }
	/**
	 * Gets the channel class of the channel.
	 * @throws {@link PhidgetError}
	 */
	get channelClass() { return this.getChannelClass(); }
	/**
	 * Gets the name of the channel class the channel belongs to.
	 * @throws {@link PhidgetError}
	 */
	get channelClassName() { return this.getChannelClassName(); }
	/**
	 * Gets the channel's name. This name serves as a description of the specific nature of the channel.
	 * @throws {@link PhidgetError}
	 */
	get channelName() { return this.getChannelName(); }
	/**
	 * Gets the subclass for this channel. Allows for identifying channels with specific characteristics without needing to know the exact device and channel index.
	 * @throws {@link PhidgetError}
	 */
	get channelSubclass() { return this.getChannelSubclass(); }
	/**
	 * Gets the connection associated with this Phidget.
	 * @throws {@link PhidgetError}
	 */
	get connection() { return this.getConnection(); }
	/**
	 * Gets the device class for the Phidget which this channel is a part of.
	 * @throws {@link PhidgetError}
	 */
	get deviceClass() { return this.getDeviceClass(); }
	/**
	 * Gets the name of the device class for the Phidget which this channel is a part of.
	 * @throws {@link PhidgetError}
	 */
	get deviceClassName() { return this.getDeviceClassName(); }
	/**
	 * Gets the string which will match the filename of the firmware upgrade file
	 * @throws {@link PhidgetError}
	 * @internal
	 */
	get deviceFirmwareUpgradeString() { return this.getDeviceFirmwareUpgradeString(); }
	/**
	 * Gets the Device ID for the Phidget which this channel is a part of.
	 * @throws {@link PhidgetError}
	 */
	get deviceID() { return this.getDeviceID(); }
	/**
	 * get: Gets the label of the Phidget which this channel is a part of. A device label is a custom string used to more easily identify a Phidget. Labels are written to a Phidget using `writeDeviceLabel()`, or by right-clicking the device and setting a label in the Phidget Control Panel for Windows.  
	 *   
	 * set: Specifies the label of the Phidget to be opened. Leave un-set to open any label. A device label is a custom string used to more easily identify a Phidget. Labels are written to a Phidget using `writeDeviceLabel()`, or by right-clicking the device and setting a label in the Phidget Control Panel for Windows.  
	 *   
	 * If setting this property, it must be set before the channel is opened. The behaviour of setting this property while the channel is open is undefined.
	 * @throws {@link PhidgetError}
	 */
	get deviceLabel() { return this.getDeviceLabel(); }
	set deviceLabel(deviceLabel: string) { this.setDeviceLabel(deviceLabel); }
	/**
	 * Gets the name of the Phidget which this channel is a part of.
	 * @throws {@link PhidgetError}
	 */
	get deviceName() { return this.getDeviceName(); }
	/**
	 * get: Gets the serial number of the Phidget which this channel is a part of.  
	 * If the channel is part of a VINT device, this will be the serial number of the VINT Hub the device is attached to.  
	 *   
	 * set: Specifies the serial number of the Phidget to be opened. Leave un-set, or set to `ANY_SERIAL_NUMBER` to open any serial number.  
	 * If the channel is part of a VINT device, this will be the serial number of the VINT Hub the device is attached to.  
	 *   
	 * If setting this property, it must be set before the channel is opened. The behaviour of setting this property while the channel is open is undefined.
	 * @throws {@link PhidgetError}
	 */
	get deviceSerialNumber() { return this.getDeviceSerialNumber(); }
	set deviceSerialNumber(deviceSerialNumber: number) { this.setDeviceSerialNumber(deviceSerialNumber); }
	/**
	 * Gets the SKU (part number) of the Phidget which this channel is a part of.
	 * @throws {@link PhidgetError}
	 */
	get deviceSKU() { return this.getDeviceSKU(); }
	/**
	 * Gets the firmware version of the Phidget which this channel is a part of.
	 * @throws {@link PhidgetError}
	 */
	get deviceVersion() { return this.getDeviceVersion(); }
	/**
	 * Gets the vint id for the Phidget which this channel is a part of.
	 * @throws {@link PhidgetError}
	 * @internal
	 */
	get deviceVINTID() { return this.getDeviceVINTID(); }
	/**
	 * Gets the hub that this channel is attached to.
	 * @throws {@link PhidgetError}
	 */
	get hub() { return this.getHub(); }
	/**
	 * get: Gets the hub port index of the VINT Hub port that the channel is attached to.  
	 *   
	 * set: Specifies the hub port index of the VINT Hub port to open this channel on. Leave un-set, or set to `ANY_HUB_PORT` to open the channel on any VINT Hub port  
	 *   
	 * If setting this property, it must be set before the channel is opened. The behaviour of setting this property while the channel is open is undefined.
	 * @throws {@link PhidgetError}
	 */
	get hubPort() { return this.getHubPort(); }
	set hubPort(hubPort: number) { this.setHubPort(hubPort); }
	/**
	 * Gets the number of VINT ports present on the VINT Hub that the channel is attached to.
	 * @throws {@link PhidgetError}
	 */
	get hubPortCount() { return this.getHubPortCount(); }
	/**
	 * get: Gets whether this channel is a VINT Hub port channel, or part of a VINT device attached to a hub port.  
	 *   
	 * set: Specifies whether this channel should be opened on a VINT Hub port directly, or on a VINT device attached to a hub port.  
	 *   
	 * If setting this property, it must be set before the channel is opened. The behaviour of setting this property while the channel is open is undefined.
	 * @throws {@link PhidgetError}
	 */
	get isHubPortDevice() { return this.getIsHubPortDevice(); }
	set isHubPortDevice(isHubPortDevice: boolean) { this.setIsHubPortDevice(isHubPortDevice); }
	/**
	 * Configures the communication speed for this VINT device. Both the `hubPortSupportsSetSpeed` and `VINTDeviceSupportsSetSpeed` must be true in order to set the hub port speed.Available speeds are: 100000, 160000, 250000, 400000, 500000, 800000 and 1000000. Setting any other speed will select the nearest lower supported speed. The upper speed is bound by the lesser of `maxHubPortSpeed` and `maxVINTDeviceSpeed`. Set the speed to `AUTO_HUBPORTSPEED` to enable Auto Set Speed on Hubs that support it (enabled by default).
	 * @throws {@link PhidgetError}
	 */
	get hubPortSpeed() { return this.getHubPortSpeed(); }
	/**
	 * The max communication speed of a high-speed capable VINT Port.
	 * @throws {@link PhidgetError}
	 */
	get maxHubPortSpeed() { return this.getMaxHubPortSpeed(); }
	/**
	 * Indicates that the communication speed of this VINT port can be set automatically.
	 * @throws {@link PhidgetError}
	 */
	get hubPortSupportsAutoSetSpeed() { return this.getHubPortSupportsAutoSetSpeed(); }
	/**
	 * Indicates that the communication speed of this VINT port can be set.
	 * @throws {@link PhidgetError}
	 */
	get hubPortSupportsSetSpeed() { return this.getHubPortSupportsSetSpeed(); }
	/**
	 * Gets a key which identifies this device or channel uniquely. This can be useful for correlating attach/detach events from the **Manager**.
	 * @throws {@link PhidgetError}
	 */
	get key() { return this.getKey(); }
	/**
	 * get: Returns true when this channel is attached directly on the local machine, or false otherwise.  
	 *   
	 * set: Set to True if the channel is to be opened locally, and not over a network. If both this and `isRemote` are set to False (the default), the channel will be opened either locally or remotely, on whichever matching channel is found first.  
	 *   
	 * If setting this property, it must be set before the channel is opened. The behaviour of setting this property while the channel is open is undefined.
	 * @throws {@link PhidgetError}
	 */
	get isLocal() { return this.getIsLocal(); }
	set isLocal(isLocal: boolean) { this.setIsLocal(isLocal); }
	/**
	 * Returns true if `open()` has been called on this channel.
	 * @throws {@link PhidgetError}
	 */
	get isOpen() { return this.getIsOpen(); }
	/**
	 * Gets the handle of the parent device of the given Phidget handle.
	 * 
	 * For example, this would refer to the device the channel is a part of, or the Hub that a device is plugged into.
	 * 
	 * This is useful when used alongside a **Phidget Manager** to create device trees like the one in the Phidget Control Panel.
	 * 
	 * *   This can be used to travel up the device tree and get device information at each step.
	 * *   The root device will return a null handle
	 * *   Parent handles always refer to devices. See `getIsChannel()`
	 * @throws {@link PhidgetError}
	 */
	get parent() { return this.getParent(); }
	/**
	 * get: Returns true when this channel is attached via a Phidget network server, or false otherwise.  
	 *   
	 * set:
	 * 
	 * Set to True if the channel is to be opened remotely, rather than locally. If both this and `isLocal` are set to False (the default), the channel will be opened either locally or remotely, on whichever matching channel is found first.
	 * 
	 * If setting this property, it must be set before the channel is opened. The behaviour of setting this property while the channel is open is undefined.
	 * @throws {@link PhidgetError}
	 */
	get isRemote() { return this.getIsRemote(); }
	set isRemote(isRemote: boolean) { this.setIsRemote(isRemote); }
	/**
	 * The max communication speed of a high-speed capable VINT Device.
	 * @throws {@link PhidgetError}
	 */
	get maxVINTDeviceSpeed() { return this.getMaxVINTDeviceSpeed(); }
	/**
	 * Indicates that the communication speed of this VINT device can be set automatically.
	 * @throws {@link PhidgetError}
	 */
	get VINTDeviceSupportsAutoSetSpeed() { return this.getVINTDeviceSupportsAutoSetSpeed(); }
	/**
	 * Indicates that the communication speed of this VINT device can be set.
	 * @throws {@link PhidgetError}
	 */
	get VINTDeviceSupportsSetSpeed() { return this.getVINTDeviceSupportsSetSpeed(); }

	/**
	 * Gets the attached status of channel. A Phidget is attached after it has been opened and the Phidget library finds and connects to the corresponding hardware device.
	 * 
	 * *   Most API calls are only valid on attached Phidgets.
	 * @returns True if the channel is attached
	 * @throws {@link PhidgetError}
	 */
	abstract getAttached(): boolean;
	/**
	 * Gets the channel index of the channel on the device.
	 * @returns The channel index
	 * @throws {@link PhidgetError}
	 */
	abstract getChannel(): number;
	/**
	 * Specifies the channel index to be opened. The default channel is 0. Set to `ANY_CHANNEL` to open any channel on the specified device.  
	 *   
	 * If setting this property, it must be set before the channel is opened. The behaviour of setting this property while the channel is open is undefined.
	 * @throws {@link PhidgetError}
	 * @param channel - The channel index
	 */
	abstract setChannel(channel: number): void;
	/**
	 * Gets the channel class of the channel.
	 * @returns The channel class
	 * @throws {@link PhidgetError}
	 */
	abstract getChannelClass(): Enum.ChannelClass;
	/**
	 * Gets the name of the channel class the channel belongs to.
	 * @returns The name of the channel's class
	 * @throws {@link PhidgetError}
	 */
	abstract getChannelClassName(): string;
	/**
	 * Gets the channel's name. This name serves as a description of the specific nature of the channel.
	 * @returns The channel's name
	 * @throws {@link PhidgetError}
	 */
	abstract getChannelName(): string;
	/**
	 * Gets the subclass for this channel. Allows for identifying channels with specific characteristics without needing to know the exact device and channel index.
	 * @returns The channel's subclass
	 * @throws {@link PhidgetError}
	 */
	abstract getChannelSubclass(): Enum.ChannelSubclass;
	/**
	 * Closes a Phidget channel that has been opened.`close()` will release the channel on the Phidget device.
	 * @throws {@link PhidgetError}
	 */
	abstract close(): Promise<void>;
	/**
	 * Gets the connection associated with this Phidget.
	 * @returns The connection
	 * @throws {@link PhidgetError}
	 */
	abstract getConnection(): (PhidgetConnection | null);
	/**
	 * Gets the number of channels of the specified channel class on the device. Pass `phidget22.ChannelClass.NONE` to get the total number of channels.
	 * @returns The Channel Count
	 * @throws {@link PhidgetError}
	 * @param cls - The Channel Class
	 */
	abstract getDeviceChannelCount(cls: Enum.ChannelClass): number;
	/**
	 * Gets the device class for the Phidget which this channel is a part of.
	 * @returns The class of the device the channel is a part of.
	 * @throws {@link PhidgetError}
	 */
	abstract getDeviceClass(): Enum.DeviceClass;
	/**
	 * Gets the name of the device class for the Phidget which this channel is a part of.
	 * @returns The class name of the device the channel is a part of.
	 * @throws {@link PhidgetError}
	 */
	abstract getDeviceClassName(): string;
	/**
	 * Gets the string which will match the filename of the firmware upgrade file
	 * @returns The firmware upgrade string
	 * @throws {@link PhidgetError}
	 * @internal
	 */
	abstract getDeviceFirmwareUpgradeString(): string;
	/**
	 * Gets the Device ID for the Phidget which this channel is a part of.
	 * @returns The device id of the device the channel is a part of
	 * @throws {@link PhidgetError}
	 */
	abstract getDeviceID(): Enum.DeviceID;
	/**
	 * Gets the label of the Phidget which this channel is a part of. A device label is a custom string used to more easily identify a Phidget. Labels are written to a Phidget using `writeDeviceLabel()`, or by right-clicking the device and setting a label in the Phidget Control Panel for Windows.
	 * @returns The device label
	 * @throws {@link PhidgetError}
	 */
	abstract getDeviceLabel(): string;
	/**
	 * Specifies the label of the Phidget to be opened. Leave un-set to open any label. A device label is a custom string used to more easily identify a Phidget. Labels are written to a Phidget using `writeDeviceLabel()`, or by right-clicking the device and setting a label in the Phidget Control Panel for Windows.  
	 *   
	 * If setting this property, it must be set before the channel is opened. The behaviour of setting this property while the channel is open is undefined.
	 * @throws {@link PhidgetError}
	 * @param deviceLabel - The device label
	 */
	abstract setDeviceLabel(deviceLabel?: string): void;
	/**
	 * Gets the name of the Phidget which this channel is a part of.
	 * @returns The name of the device the channel is a part of
	 * @throws {@link PhidgetError}
	 */
	abstract getDeviceName(): string;
	/**
	 * Gets the serial number of the Phidget which this channel is a part of.  
	 * If the channel is part of a VINT device, this will be the serial number of the VINT Hub the device is attached to.
	 * @returns The device serial number
	 * @throws {@link PhidgetError}
	 */
	abstract getDeviceSerialNumber(): number;
	/**
	 * Specifies the serial number of the Phidget to be opened. Leave un-set, or set to `ANY_SERIAL_NUMBER` to open any serial number.  
	 * If the channel is part of a VINT device, this will be the serial number of the VINT Hub the device is attached to.  
	 *   
	 * If setting this property, it must be set before the channel is opened. The behaviour of setting this property while the channel is open is undefined.
	 * @throws {@link PhidgetError}
	 * @param deviceSerialNumber - The device serial number
	 */
	abstract setDeviceSerialNumber(deviceSerialNumber: number): void;
	/**
	 * Gets the SKU (part number) of the Phidget which this channel is a part of.
	 * @returns The SKU of the device the channel is a part of
	 * @throws {@link PhidgetError}
	 */
	abstract getDeviceSKU(): string;
	/**
	 * Gets the firmware version of the Phidget which this channel is a part of.
	 * @returns The version of the device the channel is a part of
	 * @throws {@link PhidgetError}
	 */
	abstract getDeviceVersion(): number;
	/**
	 * Gets the vint id for the Phidget which this channel is a part of.
	 * @returns The vint id of the channels device
	 * @throws {@link PhidgetError}
	 * @internal
	 */
	abstract getDeviceVINTID(): number;
	/**
	 * Gets the hub that this channel is attached to.
	 * @returns The hub the channels device is attached to
	 * @throws {@link PhidgetError}
	 */
	abstract getHub(): Phidget;
	/**
	 * Gets the hub port index of the VINT Hub port that the channel is attached to.
	 * @returns The hub port index
	 * @throws {@link PhidgetError}
	 */
	abstract getHubPort(): number;
	/**
	 * Specifies the hub port index of the VINT Hub port to open this channel on. Leave un-set, or set to `ANY_HUB_PORT` to open the channel on any VINT Hub port  
	 *   
	 * If setting this property, it must be set before the channel is opened. The behaviour of setting this property while the channel is open is undefined.
	 * @throws {@link PhidgetError}
	 * @param hubPort - The hub port index
	 */
	abstract setHubPort(hubPort: number): void;
	/**
	 * Gets the number of VINT ports present on the VINT Hub that the channel is attached to.
	 * @returns The number of ports on the VINT Hub
	 * @throws {@link PhidgetError}
	 */
	abstract getHubPortCount(): number;
	/**
	 * Configures the communication speed for this VINT device. Both the `hubPortSupportsSetSpeed` and `VINTDeviceSupportsSetSpeed` must be true in order to set the hub port speed.Available speeds are: 100000, 160000, 250000, 400000, 500000, 800000 and 1000000. Setting any other speed will select the nearest lower supported speed. The upper speed is bound by the lesser of `maxHubPortSpeed` and `maxVINTDeviceSpeed`. Set the speed to `AUTO_HUBPORTSPEED` to enable Auto Set Speed on Hubs that support it (enabled by default).
	 * @returns The VINT Device communication speed
	 * @throws {@link PhidgetError}
	 */
	abstract getHubPortSpeed(): number;
	/**
	 * Configures the communication speed for this VINT device. Both the `hubPortSupportsSetSpeed` and `VINTDeviceSupportsSetSpeed` must be true in order to set the hub port speed.Available speeds are: 100000, 160000, 250000, 400000, 500000, 800000 and 1000000. Setting any other speed will select the nearest lower supported speed. The upper speed is bound by the lesser of `maxHubPortSpeed` and `maxVINTDeviceSpeed`. Set the speed to `AUTO_HUBPORTSPEED` to enable Auto Set Speed on Hubs that support it (enabled by default).
	 * @throws {@link PhidgetError}
	 * @param hubPortSpeed - The VINT Device communication speed
	 */
	abstract setHubPortSpeed(hubPortSpeed: number): Promise<void>;
	/**
	 * The max communication speed of a high-speed capable VINT Port.
	 * @returns The VINT Port max speed
	 * @throws {@link PhidgetError}
	 */
	abstract getMaxHubPortSpeed(): number;
	/**
	 * Indicates that the communication speed of this VINT port can be set automatically.
	 * @returns The VINT Port supports auto set speed
	 * @throws {@link PhidgetError}
	 */
	abstract getHubPortSupportsAutoSetSpeed(): boolean;
	/**
	 * Indicates that the communication speed of this VINT port can be set.
	 * @returns The VINT Port supports set speed
	 * @throws {@link PhidgetError}
	 */
	abstract getHubPortSupportsSetSpeed(): boolean;
	/**
	 * Returns true if this represents a channel, false if this represents a device. Mostly for use alongside `getParent()` to distinguish channel handles from device handles.
	 * @returns True if the handle is for a channel.
	 * @throws {@link PhidgetError}
	 */
	abstract getIsChannel(): boolean;
	/**
	 * Gets whether this channel is a VINT Hub port channel, or part of a VINT device attached to a hub port.
	 * @returns The hub port mode (True if the channel is a hub port itself)
	 * @throws {@link PhidgetError}
	 */
	abstract getIsHubPortDevice(): boolean;
	/**
	 * Specifies whether this channel should be opened on a VINT Hub port directly, or on a VINT device attached to a hub port.  
	 *   
	 * If setting this property, it must be set before the channel is opened. The behaviour of setting this property while the channel is open is undefined.
	 * @throws {@link PhidgetError}
	 * @param isHubPortDevice - The hub port mode (True if the channel is a hub port itself)
	 */
	abstract setIsHubPortDevice(isHubPortDevice: boolean): void;
	/**
	 * Returns true when this channel is attached directly on the local machine, or false otherwise.
	 * @returns True if the channel is attached to a local device
	 * @throws {@link PhidgetError}
	 */
	abstract getIsLocal(): boolean;
	/**
	 * Set to True if the channel is to be opened locally, and not over a network. If both this and `isRemote` are set to False (the default), the channel will be opened either locally or remotely, on whichever matching channel is found first.  
	 *   
	 * If setting this property, it must be set before the channel is opened. The behaviour of setting this property while the channel is open is undefined.
	 * @throws {@link PhidgetError}
	 * @param isLocal - True if the channel is attached to a local device
	 */
	abstract setIsLocal(isLocal: boolean): void;
	/**
	 * Returns true if `open()` has been called on this channel.
	 * @returns True if the channel is opened.
	 * @throws {@link PhidgetError}
	 */
	abstract getIsOpen(): boolean;
	/**
	 * Returns true when this channel is attached via a Phidget network server, or false otherwise.
	 * @returns True if the channel is attached to a network device
	 * @throws {@link PhidgetError}
	 */
	abstract getIsRemote(): boolean;
	/**
	 * Set to True if the channel is to be opened remotely, rather than locally. If both this and `isLocal` are set to False (the default), the channel will be opened either locally or remotely, on whichever matching channel is found first.
	 * 
	 * If setting this property, it must be set before the channel is opened. The behaviour of setting this property while the channel is open is undefined.
	 * @throws {@link PhidgetError}
	 * @param isRemote - True if the channel is attached to a network device
	 */
	abstract setIsRemote(isRemote: boolean): void;
	/**
	 * Gets a key which identifies this device or channel uniquely. This can be useful for correlating attach/detach events from the **Manager**.
	 * @returns The key
	 * @throws {@link PhidgetError}
	 */
	abstract getKey(): string;
	/**
	 * Opens the Phidget channel. The specific channel to be opened can be specified by setting any of the following properties:
	 * 
	 * *   DeviceSerialNumber
	 * *   DeviceLabel
	 * *   Channel
	 * *   HubPort
	 * *   IsHubPortDevice
	 * *   ServerName
	 * *   IsLocal
	 * *   IsRemote
	 * 
	 * `open()` will return immediately, with the attachment process proceeding asynchronously. Use the Attach event or Attached property to determine when the channel is ready to use.
	 * @throws {@link PhidgetError}
	 */
	abstract open(): Promise<void>;
	/**
	 * Opens the Phidget channel and waits a defined amount of time for the device to attach.The specific channel to be opened can be specified by setting any of the following properties:
	 * 
	 * *   DeviceSerialNumber
	 * *   DeviceLabel
	 * *   Channel
	 * *   HubPort
	 * *   IsHubPortDevice
	 * *   ServerName
	 * *   IsLocal
	 * *   IsRemote
	 * 
	 * `open()` will block until the channel is attached or a timeout occurs. A timeout value of 0 will wait forever.
	 * @throws {@link PhidgetError}
	 * @param timeout - Timeout in milliseconds
	 */
	abstract open(timeout: number): Promise<void>;
	/**
	 * Gets the handle of the parent device of the given Phidget handle.
	 * 
	 * For example, this would refer to the device the channel is a part of, or the Hub that a device is plugged into.
	 * 
	 * This is useful when used alongside a **Phidget Manager** to create device trees like the one in the Phidget Control Panel.
	 * 
	 * *   This can be used to travel up the device tree and get device information at each step.
	 * *   The root device will return a null handle
	 * *   Parent handles always refer to devices. See `getIsChannel()`
	 * @returns The handle of the parent
	 * @throws {@link PhidgetError}
	 */
	abstract getParent(): (Phidget | null);
	/**
	 * Reboots the device into the regular firmware.
	 * @throws {@link PhidgetError}
	 * @internal
	 */
	abstract reboot(): Promise<void>;
	/**
	 * Reboots the device into firmware upgrade mode.
	 * @throws {@link PhidgetError}
	 * @param upgradeTimeout - The reboot timeout
	 * @internal
	 */
	abstract rebootFirmwareUpgrade(upgradeTimeout: number): Promise<void>;
	/**
	 * The max communication speed of a high-speed capable VINT Device.
	 * @returns The VINT Device max commuinication speed
	 * @throws {@link PhidgetError}
	 */
	abstract getMaxVINTDeviceSpeed(): number;
	/**
	 * Indicates that the communication speed of this VINT device can be set automatically.
	 * @returns The VINT Device supports auto set speed
	 * @throws {@link PhidgetError}
	 */
	abstract getVINTDeviceSupportsAutoSetSpeed(): boolean;
	/**
	 * Indicates that the communication speed of this VINT device can be set.
	 * @returns The VINT Device supports set speed
	 * @throws {@link PhidgetError}
	 */
	abstract getVINTDeviceSupportsSetSpeed(): boolean;
	/**
	 * Writes a label to the device in the form of a string in the device flash memory. This label can then be used to identify the device, and will persist across power cycles.  
	 *   
	 * The label can be at most 10 UTF-16 code units. Most unicode characters take up a single code unit, but some, such as emoji, can take several.  
	 *   
	 * Some older devices can not have their labels set from Windows. For these devices the label should be set from Linux or macOS.  
	 *   
	 * Note: You should be careful when writing labels in your code, because the label is stored in flash which can only be re-written around 10,000 times before it will no longer write. If your program is complex, be sure to test it thoroughly before using WriteLabel to avoid accidentally burning out the flash.
	 * @throws {@link PhidgetError}
	 * @param deviceLabel - The device label
	 */
	abstract writeDeviceLabel(deviceLabel: string): Promise<void>;
}
export { PhidgetBase };
