/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PhidgetChannel } from '../Phidget';
import { Channel } from '../Channel';
import { ErrorCode, ChannelClass } from '../Enumerations.gen';
import { PhidgetError } from '../PhidgetError';
import { BridgePacket, PUNK } from '../BridgePacket';
import { BP } from '../BridgePackets.gen';
import { logEventException } from '../Logging';
import { DeviceChannelUID } from '../Devices.gen';

/** @internal */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface BLDCMotorData {
	positionOffset: number, 
	dataInterval: number,
	minDataInterval: number,
	maxDataInterval: number,
	minDataRate: number,
	maxDataRate: number,
	currentLimit: number,
	maxCurrentLimit: number,
	minCurrentLimit: number,
	acceleration: number,
	targetBrakingStrength: number,
	maxAcceleration: number,
	maxBrakingStrength: number,
	maxVelocity: number,
	maxPosition: number,
	minVelocity: number,
	minAcceleration: number,
	minBrakingStrength: number,
	minPosition: number,
	position: number,
	rescaleFactor: number,
	targetVelocity: number,
	velocity: number,
	brakingStrength: number,
	stallVelocity: number,
	minStallVelocity: number,
	maxStallVelocity: number,
	maxFailsafeTime: number,
	minFailsafeTime: number,
}

abstract class BLDCMotorBase extends PhidgetChannel {
	/** @internal */
	data: BLDCMotorData;
	/**
	 * **BrakingStrengthChange** event
	 *  * `brakingStrength` - The braking strength value
	 * ---
	 * The most recent braking strength value will be reported in this event, which occurs when the `dataInterval` has elapsed.
	 * 
	 * *   Regardless of the `dataInterval`, this event will occur only when the braking strength value has changed from the previous value reported.
	 * *   Braking mode is enabled by setting the `velocity` to `minVelocity`
	 */
	onBrakingStrengthChange: ((brakingStrength: number) => void) | null = null;
	/** @internal */
	_gotBrakingStrengthChangeErrorEvent?: boolean;
	/**
	 * **PositionChange** event
	 *  * `position` - The position value
	 * ---
	 * The most recent position value will be reported in this event, which occurs when the `dataInterval` has elapsed.
	 * 
	 * *   Regardless of the `dataInterval`, this event will occur only when the position value has changed from the previous value reported.
	 * *   Position values are calculated using Hall Effect sensors mounted on the motor, therefore, the resolution of position depends on the motor you are using.
	 * *   Units for `position` can be set by the user through the `rescaleFactor`. The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees. For more information on how to apply the `rescaleFactor` to your application, see your controller's User Guide.
	 */
	onPositionChange: ((position: number) => void) | null = null;
	/** @internal */
	_gotPositionChangeErrorEvent?: boolean;
	/**
	 * **VelocityUpdate** event
	 *  * `velocity` - The velocity value
	 * ---
	 * The most recent velocity value will be reported in this event, which occurs when the `dataInterval` has elapsed.
	 * 
	 * *   This event will **always** occur when the `dataInterval` elapses. You can depend on this event for constant timing when implementing control loops in code. This is the last event to fire, giving you up-to-date access to all properties.
	 */
	onVelocityUpdate: ((velocity: number) => void) | null = null;
	/** @internal */
	_gotVelocityUpdateErrorEvent?: boolean;

	/**
	 * The BLDC Motor class controls the power applied to attached brushless DC motors to affect its speed and direction. It can also contain various other control and monitoring functions that aid in the control of brushless DC motors.
	 * @public
	 */
	constructor();
	/** @internal */
	constructor(ch?: Channel);
	constructor(ch?: Channel) {
		super(ch);
		this._class = ChannelClass.BLDC_MOTOR;
		this.name = "BLDCMotor";
		this.data = this._initData();
	}

	/** @internal */
	_bridgeInput(bp: BridgePacket) {

		switch(bp.vpkt) {
		case BP.SETACCELERATION:
			this.data.acceleration = bp.entries[0].v as number;
			this._FIREPropertyChange('Acceleration', bp);
			break;
		case BP.SETCURRENTLIMIT:
			this.data.currentLimit = bp.entries[0].v as number;
			this._FIREPropertyChange('CurrentLimit', bp);
			break;
		case BP.SETDATAINTERVAL:
			if (bp.entryCount > 1)
				this.data.dataInterval = bp.entries[1].v as number;
			else
				this.data.dataInterval = bp.entries[0].v as number;
			this._FIREPropertyChange('DataInterval', bp);
			this._FIREPropertyChange('DataRate', bp);
			break;
		case BP.SETFAILSAFETIME:
			break;
		case BP.FAILSAFERESET:
			break;
		case BP.SETSTALLVELOCITY:
			this.data.stallVelocity = bp.entries[0].v as number;
			this._FIREPropertyChange('StallVelocity', bp);
			break;
		case BP.SETBRAKINGDUTYCYCLE:
			this.data.targetBrakingStrength = bp.entries[0].v as number;
			this._FIREPropertyChange('TargetBrakingStrength', bp);
			break;
		case BP.SETDUTYCYCLE:
			this.data.targetVelocity = bp.entries[0].v as number;
			this._FIREPropertyChange('TargetVelocity', bp);
			break;
		case BP.BRAKINGSTRENGTHCHANGE: {
			this.data.brakingStrength = bp.entries[0].v as number;
			if (this._isAttachedDone && this.onBrakingStrengthChange) {
				try {
					this.onBrakingStrengthChange(this.data.brakingStrength);
				} catch (err) { logEventException(err); }
			}
			break;
		}
		case BP.DUTYCYCLECHANGE: {
			this.data.velocity = bp.entries[0].v as number;
			if (this._isAttachedDone && this.onVelocityUpdate) {
				try {
					this.onVelocityUpdate(this.data.velocity);
				} catch (err) { logEventException(err); }
			}
			break;
		}
		default:
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			throw new PhidgetError(ErrorCode.INVALID_PACKET, "Unsupported bridge packet: 0x" + bp.vpkt!.toString(16));
		}
	}

	/** @internal */
	_initData(): BLDCMotorData {
		return {
			positionOffset: 0, 
			dataInterval: PUNK.DBL,
			minDataInterval: PUNK.UINT32,
			maxDataInterval: PUNK.UINT32,
			minDataRate: PUNK.DBL,
			maxDataRate: PUNK.DBL,
			currentLimit: PUNK.DBL,
			maxCurrentLimit: PUNK.DBL,
			minCurrentLimit: PUNK.DBL,
			acceleration: PUNK.DBL,
			targetBrakingStrength: PUNK.DBL,
			maxAcceleration: PUNK.DBL,
			maxBrakingStrength: PUNK.DBL,
			maxVelocity: PUNK.DBL,
			maxPosition: PUNK.INT64,
			minVelocity: PUNK.DBL,
			minAcceleration: PUNK.DBL,
			minBrakingStrength: PUNK.DBL,
			minPosition: PUNK.INT64,
			position: PUNK.INT64,
			rescaleFactor: PUNK.DBL,
			targetVelocity: PUNK.DBL,
			velocity: PUNK.DBL,
			brakingStrength: PUNK.DBL,
			stallVelocity: PUNK.DBL,
			minStallVelocity: PUNK.DBL,
			maxStallVelocity: PUNK.DBL,
			maxFailsafeTime: PUNK.UINT32,
			minFailsafeTime: PUNK.UINT32,
		}
	}

	/** @internal */
	_initAfterOpen() {
		this.data = this._initData();

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._DCC1100_BLDCMOTOR_100:
			this.data.dataInterval = 250;
			this.data.minDataInterval = 100;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 10;
			this.data.acceleration = 1;
			this.data.targetBrakingStrength = 0;
			this.data.maxAcceleration = 100;
			this.data.maxBrakingStrength = 1;
			this.data.maxVelocity = 1;
			this.data.maxPosition = 1000000000000000;
			this.data.minVelocity = 0;
			this.data.minAcceleration = 0.1;
			this.data.minBrakingStrength = 0;
			this.data.minPosition = -1000000000000000;
			this.data.position = 0;
			this.data.rescaleFactor = 1;
			this.data.targetVelocity = 0;
			this.data.stallVelocity = 400;
			this.data.minStallVelocity = 0;
			this.data.maxStallVelocity = 2000;
			break;
		case DeviceChannelUID._DCC1100_BLDCMOTOR_120:
			this.data.dataInterval = 250;
			this.data.minDataInterval = 100;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 10;
			this.data.acceleration = 1;
			this.data.targetBrakingStrength = 0;
			this.data.maxAcceleration = 100;
			this.data.maxBrakingStrength = 1;
			this.data.maxVelocity = 1;
			this.data.maxPosition = 1000000000000000;
			this.data.minVelocity = 0;
			this.data.minAcceleration = 0.1;
			this.data.minBrakingStrength = 0;
			this.data.minPosition = -1000000000000000;
			this.data.position = 0;
			this.data.rescaleFactor = 1;
			this.data.targetVelocity = 0;
			this.data.stallVelocity = 400;
			this.data.minStallVelocity = 0;
			this.data.maxStallVelocity = 2000;
			this.data.maxFailsafeTime = 30000;
			this.data.minFailsafeTime = 500;
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	// eslint-disable-next-line require-await
	async _setDefaults() {
		let bp;

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._DCC1100_BLDCMOTOR_100:
		case DeviceChannelUID._DCC1100_BLDCMOTOR_120:
			bp = new BridgePacket();
			bp.set({ name: "0", type: "u", value: Math.round(this.data.dataInterval) });
			await bp.send(this._ch, BP.SETDATAINTERVAL);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.acceleration });
			await bp.send(this._ch, BP.SETACCELERATION);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.targetBrakingStrength });
			await bp.send(this._ch, BP.SETBRAKINGDUTYCYCLE);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.targetVelocity });
			await bp.send(this._ch, BP.SETDUTYCYCLE);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.stallVelocity });
			await bp.send(this._ch, BP.SETSTALLVELOCITY);
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	_hasInitialState() {

		if ((this.data.brakingStrength == PUNK.DBL)
			&& ! this._gotBrakingStrengthChangeErrorEvent)
			return false;
		if ((this.data.velocity == PUNK.DBL)
			&& ! this._gotVelocityUpdateErrorEvent)
			return false;

		return true;
	}

	/** @internal */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	_fireInitialEvents() {

		if(this.data.brakingStrength != PUNK.DBL)
			if (this.onBrakingStrengthChange)
				try {
					this.onBrakingStrengthChange(this.data.brakingStrength);
				} catch (err) { logEventException(err); }

		if(this.data.velocity != PUNK.DBL)
			if (this.onVelocityUpdate)
				try {
					this.onVelocityUpdate(this.data.velocity);
				} catch (err) { logEventException(err); }

	}

	/**
	 * The rate at which the controller can change the motor's `velocity`.
	 * 
	 * *   The acceleration is bounded by `minAcceleration` and `maxAcceleration`
	 * @throws {@link PhidgetError}
	 */
	get acceleration() { return this.getAcceleration(); }
	/**
	 * The minimum value that `acceleration` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minAcceleration() { return this.getMinAcceleration(); }
	/**
	 * The maximum value that `acceleration` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxAcceleration() { return this.getMaxAcceleration(); }
	/**
	 * The most recent braking strength value that the controller has reported.
	 * @throws {@link PhidgetError}
	 */
	get brakingStrength() { return this.getBrakingStrength(); }
	/**
	 * The minimum value that `targetBrakingStrength` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minBrakingStrength() { return this.getMinBrakingStrength(); }
	/**
	 * The maximum value that `targetBrakingStrength` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxBrakingStrength() { return this.getMaxBrakingStrength(); }
	/**
	 * The controller will limit the current through the motor to the `currentLimit` value.
	 * @throws {@link PhidgetError}
	 */
	get currentLimit() { return this.getCurrentLimit(); }
	/**
	 * The minimum value that `currentLimit` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minCurrentLimit() { return this.getMinCurrentLimit(); }
	/**
	 * The maximum value that `currentLimit` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxCurrentLimit() { return this.getMaxCurrentLimit(); }
	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `VelocityUpdate` / `PositionChange` / `BrakingStrengthChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * @throws {@link PhidgetError}
	 */
	get dataInterval() { return this.getDataInterval(); }
	/**
	 * The minimum value that `dataInterval` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDataInterval() { return this.getMinDataInterval(); }
	/**
	 * The maximum value that `dataInterval` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDataInterval() { return this.getMaxDataInterval(); }
	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @throws {@link PhidgetError}
	 */
	get dataRate() { return this.getDataRate(); }
	/**
	 * The minimum value that `dataRate` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDataRate() { return this.getMinDataRate(); }
	/**
	 * The maximum value that `dataRate` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDataRate() { return this.getMaxDataRate(); }
	/**
	 * The minimum value that `failsafeTime` can be set to when calling `enableFailsafe()`.
	 * @throws {@link PhidgetError}
	 */
	get minFailsafeTime() { return this.getMinFailsafeTime(); }
	/**
	 * The maximum value that `failsafeTime` can be set to when calling `enableFailsafe()`.
	 * @throws {@link PhidgetError}
	 */
	get maxFailsafeTime() { return this.getMaxFailsafeTime(); }
	/**
	 * The most recent position value that the controller has reported.
	 * 
	 * *   Position values are calculated using Hall Effect sensors mounted on the motor, therefore, the resolution of position depends on the motor you are using.
	 * *   Units for `position` can be set by the user through the `rescaleFactor`. The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees. For more information on how to apply the `rescaleFactor` to your application, see your controller's User Guide.
	 * @throws {@link PhidgetError}
	 */
	get position() { return this.getPosition(); }
	/**
	 * The lower bound of `position`.
	 * @throws {@link PhidgetError}
	 */
	get minPosition() { return this.getMinPosition(); }
	/**
	 * The upper bound of `position`.
	 * @throws {@link PhidgetError}
	 */
	get maxPosition() { return this.getMaxPosition(); }
	/**
	 * Change the units of your parameters so that your application is more intuitive.
	 * 
	 * *   Units for `position` can be set by the user through the `rescaleFactor`. The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees. For more information on how to apply the `rescaleFactor` to your application, see your controller's User Guide.
	 * @throws {@link PhidgetError}
	 */
	get rescaleFactor() { return this.getRescaleFactor(); }
	set rescaleFactor(rescaleFactor: number) { this.setRescaleFactor(rescaleFactor); }
	/**
	 * Before reading this description, it is important to note the difference between the units of `stallVelocity` and `velocity`.
	 * 
	 * *   `velocity` is a number between -1 and 1 with units of 'duty cycle'. It simply represents the average voltage across the motor.
	 * *   `stallVelocity` represents a real velocity (e.g. m/s, RPM, etc.) and the units are determined by the `rescaleFactor`. With a `rescaleFactor` of 1, the default units would be in commutations per second.
	 * 
	 * If the load on your motor is large, your motor may begin rotating more slowly, or even fully stall. Depending on the voltage across your motor, this may result in a large amount of current through both the controller and the motor. In order to prevent damage in these situations, you can use the `stallVelocity` property.  
	 *   
	 * The `stallVelocity` should be set to the lowest velocity you would expect from your motor. The controller will then monitor the motor's velocity, as well as the `velocity`, and prevent a 'dangerous stall' from occuring. If the controller detects a dangerous stall, it will immediately reduce the `velocity` (i.e. average voltage) to 0 and an error will be reported to your program.
	 * 
	 * *   A 'dangerous stall' will occur faster when the `velocity` is higher (i.e. when the average voltage across the motor is higher)
	 * *   A 'dangerous stall' will occur faster as (`stallVelocity` - motor velocity) becomes larger .
	 * 
	 * Setting `stallVelocity` to 0 will turn off stall protection functionality.
	 * @throws {@link PhidgetError}
	 */
	get stallVelocity() { return this.getStallVelocity(); }
	/**
	 * The lower bound of `stallVelocity`.
	 * @throws {@link PhidgetError}
	 */
	get minStallVelocity() { return this.getMinStallVelocity(); }
	/**
	 * The upper bound of `stallVelocity`.
	 * @throws {@link PhidgetError}
	 */
	get maxStallVelocity() { return this.getMaxStallVelocity(); }
	/**
	 * When a motor is not being actively driven forward or reverse, you can choose if the motor will be allowed to freely turn, or will resist being turned.
	 * 
	 * *   A low `targetBrakingStrength` value corresponds to free wheeling, this will have the following effects:
	 *     *   The motor will continue to rotate after the controller is no longer driving the motor (i.e. `velocity` is 0), due to inertia.
	 *     *   The motor shaft will provide little resistance to being turned when it is stopped.
	 * *   A higher `targetBrakingStrength` value will resist being turned, this will have the following effects:
	 *     *   The motor will more stop more quickly if it is in motion and braking has been requested. It will fight against the rotation of the shaft.
	 * *   Braking mode is enabled by setting the `velocity` to `minVelocity`
	 * @throws {@link PhidgetError}
	 */
	get targetBrakingStrength() { return this.getTargetBrakingStrength(); }
	/**
	 * The average voltage across the motor is based on the `targetVelocity` value.
	 * 
	 * *   At a constant load, increasing the target velocity will increase the speed of the motor.
	 * *   `targetVelocity` is bounded by -`maxVelocity` and +`maxVelocity`, where a sign change (±) is indicitave of a direction change.
	 * *   Setting `targetVelocity` to `minVelocity` will stop the motor. See `targetBrakingStrength` for more information on stopping the motor.
	 * *   The units of `targetVelocity` and `acceleration` refer to 'duty cycle'. This is because the controller must rapidly switch the power on/off (i.e. change the duty cycle) in order to manipulate the voltage across the motor.
	 * @throws {@link PhidgetError}
	 */
	get targetVelocity() { return this.getTargetVelocity(); }
	/**
	 * The most recent velocity value that the controller has reported.
	 * @throws {@link PhidgetError}
	 */
	get velocity() { return this.getVelocity(); }
	/**
	 * The minimum value that `targetVelocity` can be set to.
	 * 
	 * *   Set the `targetVelocity` to `minVelocity` to stop the motor. See `targetBrakingStrength` for more information on stopping the motor.
	 * *   `targetVelocity` is bounded by -`maxVelocity` and +`maxVelocity`, where a sign change (±) is indicitave of a direction change.
	 * @throws {@link PhidgetError}
	 */
	get minVelocity() { return this.getMinVelocity(); }
	/**
	 * The maximum value that `targetVelocity` can be set to.
	 * 
	 * *   `targetVelocity` is bounded by -`maxVelocity` and +`maxVelocity`, where a sign change (±) is indicitave of a direction change.
	 * @throws {@link PhidgetError}
	 */
	get maxVelocity() { return this.getMaxVelocity(); }

	/**
	 * The rate at which the controller can change the motor's `velocity`.
	 * 
	 * *   The acceleration is bounded by `minAcceleration` and `maxAcceleration`
	 * @returns The acceleration value
	 * @throws {@link PhidgetError}
	 */
	getAcceleration(): number {
		this._assertOpen();

		if (this.data.acceleration === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.acceleration);
	}

	/**
	 * The rate at which the controller can change the motor's `velocity`.
	 * 
	 * *   The acceleration is bounded by `minAcceleration` and `maxAcceleration`
	 * @throws {@link PhidgetError}
	 * @param acceleration - The acceleration value
	 */
	async setAcceleration(acceleration: number): Promise<void> {
		this._assertOpen();

		if (acceleration < this.data.minAcceleration || acceleration > this.data.maxAcceleration)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minAcceleration + " - " + this.data.maxAcceleration + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: acceleration });
		await bp.send(this._ch, BP.SETACCELERATION);
	}

	/**
	 * The minimum value that `acceleration` can be set to.
	 * @returns The acceleration value.
	 * @throws {@link PhidgetError}
	 */
	getMinAcceleration(): number {
		this._assertOpen();

		if (this.data.minAcceleration === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minAcceleration);
	}

	/**
	 * The maximum value that `acceleration` can be set to.
	 * @returns The acceleration value.
	 * @throws {@link PhidgetError}
	 */
	getMaxAcceleration(): number {
		this._assertOpen();

		if (this.data.maxAcceleration === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxAcceleration);
	}

	/**
	 * The most recent braking strength value that the controller has reported.
	 * @returns The braking strength value
	 * @throws {@link PhidgetError}
	 */
	getBrakingStrength(): number {
		this._assertOpen();

		if (this.data.brakingStrength === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.brakingStrength);
	}

	/**
	 * The minimum value that `targetBrakingStrength` can be set to.
	 * @returns The braking value
	 * @throws {@link PhidgetError}
	 */
	getMinBrakingStrength(): number {
		this._assertOpen();

		if (this.data.minBrakingStrength === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minBrakingStrength);
	}

	/**
	 * The maximum value that `targetBrakingStrength` can be set to.
	 * @returns The braking value
	 * @throws {@link PhidgetError}
	 */
	getMaxBrakingStrength(): number {
		this._assertOpen();

		if (this.data.maxBrakingStrength === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxBrakingStrength);
	}

	/**
	 * The controller will limit the current through the motor to the `currentLimit` value.
	 * @returns The current value
	 * @throws {@link PhidgetError}
	 */
	getCurrentLimit(): number {
		this._assertOpen();

		if (this.data.currentLimit === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.currentLimit);
	}

	/**
	 * The controller will limit the current through the motor to the `currentLimit` value.
	 * @throws {@link PhidgetError}
	 * @param currentLimit - The current value
	 */
	async setCurrentLimit(currentLimit: number): Promise<void> {
		this._assertOpen();

		if (currentLimit < this.data.minCurrentLimit || currentLimit > this.data.maxCurrentLimit)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minCurrentLimit + " - " + this.data.maxCurrentLimit + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: currentLimit });
		await bp.send(this._ch, BP.SETCURRENTLIMIT);
	}

	/**
	 * The minimum value that `currentLimit` can be set to.
	 * @returns The current value
	 * @throws {@link PhidgetError}
	 */
	getMinCurrentLimit(): number {
		this._assertOpen();

		if (this.data.minCurrentLimit === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minCurrentLimit);
	}

	/**
	 * The maximum value that `currentLimit` can be set to.
	 * @returns The current value
	 * @throws {@link PhidgetError}
	 */
	getMaxCurrentLimit(): number {
		this._assertOpen();

		if (this.data.maxCurrentLimit === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxCurrentLimit);
	}

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `VelocityUpdate` / `PositionChange` / `BrakingStrengthChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getDataInterval(): number {
		this._assertOpen();

		if (this.data.dataInterval === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return this.data.dataInterval;
	}

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `VelocityUpdate` / `PositionChange` / `BrakingStrengthChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * @throws {@link PhidgetError}
	 * @param dataInterval - The data interval value
	 */
	async setDataInterval(dataInterval: number): Promise<void> {
		this._assertOpen();

		if (dataInterval < this.data.minDataInterval || dataInterval > this.data.maxDataInterval)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDataInterval + " - " + this.data.maxDataInterval + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: dataInterval });
		await bp.send(this._ch, BP.SETDATAINTERVAL);
	}

	/**
	 * The minimum value that `dataInterval` can be set to.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getMinDataInterval(): number {
		this._assertOpen();

		if (this.data.minDataInterval === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDataInterval);
	}

	/**
	 * The maximum value that `dataInterval` can be set to.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getMaxDataInterval(): number {
		this._assertOpen();

		if (this.data.maxDataInterval === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDataInterval);
	}

	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @returns The data rate for the channel
	 * @throws {@link PhidgetError}
	 */
	getDataRate(): number {
		this._assertOpen();

		if (this.data.dataInterval === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (1000.0 / this.data.dataInterval);
	}

	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @throws {@link PhidgetError}
	 * @param dataRate - The data rate for the channel
	 */
	async setDataRate(dataRate: number): Promise<void> {
		this._assertOpen();

		if (dataRate < this.data.minDataRate || dataRate > this.data.maxDataRate)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDataRate + " - " + this.data.maxDataRate + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: Math.round(1000.0 / dataRate) });
		bp.set({ name: "1", type: "g", value: (1000.0 / dataRate) });
		await bp.send(this._ch, BP.SETDATAINTERVAL);
	}

	/**
	 * The minimum value that `dataRate` can be set to.
	 * @returns The data rate value
	 * @throws {@link PhidgetError}
	 */
	getMinDataRate(): number {
		this._assertOpen();

		if (this.data.minDataRate === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDataRate);
	}

	/**
	 * The maximum value that `dataRate` can be set to.
	 * @returns The data rate value
	 * @throws {@link PhidgetError}
	 */
	getMaxDataRate(): number {
		this._assertOpen();

		if (this.data.maxDataRate === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDataRate);
	}

	/**
	 * Enables the **failsafe** feature for the channel, with a given **failsafe time**.
	 * 
	 * The **failsafe** feature is intended for use in applications where it is important for the channel to enter a known _safe state_ if the program controlling it locks up or crashes. If you do not enable the failsafe feature, the channel will carry out whatever instructions it was last given until it is explicitly told to stop.
	 * 
	 * Enabling the failsafe feature starts a recurring **failsafe timer** for the channel. Once the failsafe timer is enabled, it must be reset within the specified time or the channel will enter a **failsafe state**. The failsafe timer may be reset by sending any valid command to the device\*. Resetting the failsafe timer will reload the timer with the specified _failsafe time_, starting when the message to reset the timer is received by the Phidget.
	 * 
	 * _\*(**get** requests do not typically send commands and won't reset the failsafe timer)_
	 * 
	 * For example: if the failsafe is enabled with a **failsafe time** of 1000ms, you will have 1000ms to reset the failsafe timer. Every time the failsafe timer is reset, you will have 1000ms from that time to reset the failsafe again.
	 * 
	 * If the failsafe timer is not reset before it runs out, the channel will enter a **failsafe state**. For BLDC Motor channels, this will disengage the motor. Once the channel enters the **failsafe state**, it will reject any further input until the channel is reopened.
	 * 
	 * To prevent the channel from falsely entering the failsafe state, we recommend resetting the failsafe timer as frequently as is practical for your application. A good rule of thumb is to not let more than a third of the failsafe time pass before resetting the timer.
	 * 
	 * Once the failsafe timer has been set, it cannot be disabled by any means other than closing and reopening the channel.
	 * @throws {@link PhidgetError}
	 * @param failsafeTime - Failsafe timeout in milliseconds
	 */
	async enableFailsafe(failsafeTime: number): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: failsafeTime });
		await bp.send(this._ch, BP.SETFAILSAFETIME);
	}

	/**
	 * The minimum value that `failsafeTime` can be set to when calling `enableFailsafe()`.
	 * @returns The failsafe time
	 * @throws {@link PhidgetError}
	 */
	getMinFailsafeTime(): number {
		this._assertOpen();

		if (this.data.minFailsafeTime === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minFailsafeTime);
	}

	/**
	 * The maximum value that `failsafeTime` can be set to when calling `enableFailsafe()`.
	 * @returns The failsafe time
	 * @throws {@link PhidgetError}
	 */
	getMaxFailsafeTime(): number {
		this._assertOpen();

		if (this.data.maxFailsafeTime === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxFailsafeTime);
	}

	/**
	 * The most recent position value that the controller has reported.
	 * 
	 * *   Position values are calculated using Hall Effect sensors mounted on the motor, therefore, the resolution of position depends on the motor you are using.
	 * *   Units for `position` can be set by the user through the `rescaleFactor`. The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees. For more information on how to apply the `rescaleFactor` to your application, see your controller's User Guide.
	 * @returns The position value
	 * @throws {@link PhidgetError}
	 */
	abstract getPosition(): number;
	/**
	 * The lower bound of `position`.
	 * @returns The position value
	 * @throws {@link PhidgetError}
	 */
	abstract getMinPosition(): number;
	/**
	 * The upper bound of `position`.
	 * @returns The position value
	 * @throws {@link PhidgetError}
	 */
	abstract getMaxPosition(): number;
	/**
	 * Adds an offset (positive or negative) to the current position.
	 * 
	 * *   This can be especially useful for zeroing position.
	 * @throws {@link PhidgetError}
	 * @param positionOffset - Amount to offset the position by
	 */
	abstract addPositionOffset(positionOffset: number): void;
	/**
	 * Change the units of your parameters so that your application is more intuitive.
	 * 
	 * *   Units for `position` can be set by the user through the `rescaleFactor`. The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees. For more information on how to apply the `rescaleFactor` to your application, see your controller's User Guide.
	 * @returns The rescale factor value
	 * @throws {@link PhidgetError}
	 */
	getRescaleFactor(): number {
		this._assertOpen();

		if (this.data.rescaleFactor === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.rescaleFactor);
	}

	/**
	 * Change the units of your parameters so that your application is more intuitive.
	 * 
	 * *   Units for `position` can be set by the user through the `rescaleFactor`. The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees. For more information on how to apply the `rescaleFactor` to your application, see your controller's User Guide.
	 * @throws {@link PhidgetError}
	 * @param rescaleFactor - The rescale factor value
	 */
	abstract setRescaleFactor(rescaleFactor: number): void;
	/**
	 * Resets the failsafe timer, if one has been set. See `enableFailsafe()` for details.
	 * 
	 * This function will fail if no failsafe timer has been set for the channel.
	 * @throws {@link PhidgetError}
	 */
	async resetFailsafe(): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		await bp.send(this._ch, BP.FAILSAFERESET);
	}

	/**
	 * Before reading this description, it is important to note the difference between the units of `stallVelocity` and `velocity`.
	 * 
	 * *   `velocity` is a number between -1 and 1 with units of 'duty cycle'. It simply represents the average voltage across the motor.
	 * *   `stallVelocity` represents a real velocity (e.g. m/s, RPM, etc.) and the units are determined by the `rescaleFactor`. With a `rescaleFactor` of 1, the default units would be in commutations per second.
	 * 
	 * If the load on your motor is large, your motor may begin rotating more slowly, or even fully stall. Depending on the voltage across your motor, this may result in a large amount of current through both the controller and the motor. In order to prevent damage in these situations, you can use the `stallVelocity` property.  
	 *   
	 * The `stallVelocity` should be set to the lowest velocity you would expect from your motor. The controller will then monitor the motor's velocity, as well as the `velocity`, and prevent a 'dangerous stall' from occuring. If the controller detects a dangerous stall, it will immediately reduce the `velocity` (i.e. average voltage) to 0 and an error will be reported to your program.
	 * 
	 * *   A 'dangerous stall' will occur faster when the `velocity` is higher (i.e. when the average voltage across the motor is higher)
	 * *   A 'dangerous stall' will occur faster as (`stallVelocity` - motor velocity) becomes larger .
	 * 
	 * Setting `stallVelocity` to 0 will turn off stall protection functionality.
	 * @returns The stall velocity value.
	 * @throws {@link PhidgetError}
	 */
	abstract getStallVelocity(): number;
	/**
	 * Before reading this description, it is important to note the difference between the units of `stallVelocity` and `velocity`.
	 * 
	 * *   `velocity` is a number between -1 and 1 with units of 'duty cycle'. It simply represents the average voltage across the motor.
	 * *   `stallVelocity` represents a real velocity (e.g. m/s, RPM, etc.) and the units are determined by the `rescaleFactor`. With a `rescaleFactor` of 1, the default units would be in commutations per second.
	 * 
	 * If the load on your motor is large, your motor may begin rotating more slowly, or even fully stall. Depending on the voltage across your motor, this may result in a large amount of current through both the controller and the motor. In order to prevent damage in these situations, you can use the `stallVelocity` property.  
	 *   
	 * The `stallVelocity` should be set to the lowest velocity you would expect from your motor. The controller will then monitor the motor's velocity, as well as the `velocity`, and prevent a 'dangerous stall' from occuring. If the controller detects a dangerous stall, it will immediately reduce the `velocity` (i.e. average voltage) to 0 and an error will be reported to your program.
	 * 
	 * *   A 'dangerous stall' will occur faster when the `velocity` is higher (i.e. when the average voltage across the motor is higher)
	 * *   A 'dangerous stall' will occur faster as (`stallVelocity` - motor velocity) becomes larger .
	 * 
	 * Setting `stallVelocity` to 0 will turn off stall protection functionality.
	 * @throws {@link PhidgetError}
	 * @param stallVelocity - The stall velocity value.
	 */
	abstract setStallVelocity(stallVelocity: number): Promise<void>;
	/**
	 * The lower bound of `stallVelocity`.
	 * @returns The velocity value
	 * @throws {@link PhidgetError}
	 */
	abstract getMinStallVelocity(): number;
	/**
	 * The upper bound of `stallVelocity`.
	 * @returns The velocity value
	 * @throws {@link PhidgetError}
	 */
	abstract getMaxStallVelocity(): number;
	/**
	 * When a motor is not being actively driven forward or reverse, you can choose if the motor will be allowed to freely turn, or will resist being turned.
	 * 
	 * *   A low `targetBrakingStrength` value corresponds to free wheeling, this will have the following effects:
	 *     *   The motor will continue to rotate after the controller is no longer driving the motor (i.e. `velocity` is 0), due to inertia.
	 *     *   The motor shaft will provide little resistance to being turned when it is stopped.
	 * *   A higher `targetBrakingStrength` value will resist being turned, this will have the following effects:
	 *     *   The motor will more stop more quickly if it is in motion and braking has been requested. It will fight against the rotation of the shaft.
	 * *   Braking mode is enabled by setting the `velocity` to `minVelocity`
	 * @returns The braking value
	 * @throws {@link PhidgetError}
	 */
	getTargetBrakingStrength(): number {
		this._assertOpen();

		if (this.data.targetBrakingStrength === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.targetBrakingStrength);
	}

	/**
	 * When a motor is not being actively driven forward or reverse, you can choose if the motor will be allowed to freely turn, or will resist being turned.
	 * 
	 * *   A low `targetBrakingStrength` value corresponds to free wheeling, this will have the following effects:
	 *     *   The motor will continue to rotate after the controller is no longer driving the motor (i.e. `velocity` is 0), due to inertia.
	 *     *   The motor shaft will provide little resistance to being turned when it is stopped.
	 * *   A higher `targetBrakingStrength` value will resist being turned, this will have the following effects:
	 *     *   The motor will more stop more quickly if it is in motion and braking has been requested. It will fight against the rotation of the shaft.
	 * *   Braking mode is enabled by setting the `velocity` to `minVelocity`
	 * @throws {@link PhidgetError}
	 * @param targetBrakingStrength - The braking value
	 */
	async setTargetBrakingStrength(targetBrakingStrength: number): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: targetBrakingStrength });
		await bp.send(this._ch, BP.SETBRAKINGDUTYCYCLE);
	}

	/**
	 * The average voltage across the motor is based on the `targetVelocity` value.
	 * 
	 * *   At a constant load, increasing the target velocity will increase the speed of the motor.
	 * *   `targetVelocity` is bounded by -`maxVelocity` and +`maxVelocity`, where a sign change (±) is indicitave of a direction change.
	 * *   Setting `targetVelocity` to `minVelocity` will stop the motor. See `targetBrakingStrength` for more information on stopping the motor.
	 * *   The units of `targetVelocity` and `acceleration` refer to 'duty cycle'. This is because the controller must rapidly switch the power on/off (i.e. change the duty cycle) in order to manipulate the voltage across the motor.
	 * @returns The velocity value
	 * @throws {@link PhidgetError}
	 */
	getTargetVelocity(): number {
		this._assertOpen();

		if (this.data.targetVelocity === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.targetVelocity);
	}

	/**
	 * The average voltage across the motor is based on the `targetVelocity` value.
	 * 
	 * *   At a constant load, increasing the target velocity will increase the speed of the motor.
	 * *   `targetVelocity` is bounded by -`maxVelocity` and +`maxVelocity`, where a sign change (±) is indicitave of a direction change.
	 * *   Setting `targetVelocity` to `minVelocity` will stop the motor. See `targetBrakingStrength` for more information on stopping the motor.
	 * *   The units of `targetVelocity` and `acceleration` refer to 'duty cycle'. This is because the controller must rapidly switch the power on/off (i.e. change the duty cycle) in order to manipulate the voltage across the motor.
	 * @throws {@link PhidgetError}
	 * @param targetVelocity - The velocity value
	 */
	async setTargetVelocity(targetVelocity: number): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: targetVelocity });
		await bp.send(this._ch, BP.SETDUTYCYCLE);
	}

	/**
	 * The most recent velocity value that the controller has reported.
	 * @returns The velocity value
	 * @throws {@link PhidgetError}
	 */
	getVelocity(): number {
		this._assertOpen();

		if (this.data.velocity === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.velocity);
	}

	/**
	 * The minimum value that `targetVelocity` can be set to.
	 * 
	 * *   Set the `targetVelocity` to `minVelocity` to stop the motor. See `targetBrakingStrength` for more information on stopping the motor.
	 * *   `targetVelocity` is bounded by -`maxVelocity` and +`maxVelocity`, where a sign change (±) is indicitave of a direction change.
	 * @returns The velocity value
	 * @throws {@link PhidgetError}
	 */
	getMinVelocity(): number {
		this._assertOpen();

		if (this.data.minVelocity === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minVelocity);
	}

	/**
	 * The maximum value that `targetVelocity` can be set to.
	 * 
	 * *   `targetVelocity` is bounded by -`maxVelocity` and +`maxVelocity`, where a sign change (±) is indicitave of a direction change.
	 * @returns The velocity value
	 * @throws {@link PhidgetError}
	 */
	getMaxVelocity(): number {
		this._assertOpen();

		if (this.data.maxVelocity === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxVelocity);
	}

}
export { BLDCMotorBase };
