import { logverbose } from "./Logging";

/** @internal */
class PhidgetLock {
	private _locked: boolean;
	private releaseEvents: Array<() => void>;

	constructor() {
		this._locked = false;
		this.releaseEvents = [];
	}

	private onRelease(listener: () => void) {
		this.releaseEvents.push(listener);
	}

	private removeReleaseListener(listener: () => void) {
		const idx = this.releaseEvents.indexOf(listener);
		if (idx > -1)
			this.releaseEvents.splice(idx, 1);
	}

	private emitRelease() {
		// Must create a copy because the winning tryAcquire() removes itself from the list
		const listeners = this.releaseEvents.slice();
		const length = listeners.length;
		for (let i = 0; i < length; i++)
			listeners[i]();
		logverbose('LOCK Released!');
	}

	acquire(): Promise<void> {
		logverbose('LOCK Aquiring..');
		return new Promise<void>(resolve => {
			if (!this._locked) {
				this._locked = true;
				return resolve();
			}

			const tryAcquire = () => {
				if (!this._locked) {
					this._locked = true;
					this.removeReleaseListener(tryAcquire);
					logverbose('LOCK Aquired!');
					return resolve();
				}
			};
			this.onRelease(tryAcquire);
		});
	}

	release() {
		this._locked = false;
		setTimeout(() => this.emitRelease());
	}

	get locked() { return this._locked; }
}

export { PhidgetLock };