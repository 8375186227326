﻿import { LogLevel } from './Enumerations.gen';

// Enable logging by default - user can disable if needed
let loglevel: LogLevel = LogLevel.WARNING;
let logsEnabled = true;

/**
 * The Logging API controls logs sent to the console. By default, warnings and errors are printed.
 * Verbosity of logs can be changed, or logging can be disabled entirely.
 * @public
 */
export class Log {

	/**
	 * Sets the log level (verbosity).
	 */
	static setLevel(level: LogLevel) {
		loglevel = level;
	}

	/**
	 * Gets the log level (verbosity).
	 */
	static getLevel() {
		return loglevel;
	}

	/**
	 * Enables logging within the Phidget library. Logs are sent to the console.
	 * @param level - The logging level
	 */
	static enable(level: LogLevel) {
		if (level !== undefined)
			loglevel = level;
		logsEnabled = true;
	}

	/**
	 * Disables logging within the Phidget library.
	 */
	static disable() {
		logsEnabled = false;
	}

	/**
	 * Writes a message to the Phidget library log.
	 * @param level - The logging level
	 * @param message - The message
	 */
	static log(level: LogLevel, message: string) {
		switch (level) {
			case LogLevel.CRITICAL:
				logcrit(message);
				break;
			case LogLevel.ERROR:
				logerr(message);
				break;
			case LogLevel.WARNING:
				logwarn(message);
				break;
			case LogLevel.INFO:
				loginfo(message);
				break;
			case LogLevel.DEBUG:
				logdebug(message);
				break;
			case LogLevel.VERBOSE:
				logverbose(message);
				break;
		}
	}
}

const colours = {
	red: '\x1B[31m',
	green: '\x1B[32m',
	yellow: '\x1B[33m',
	blue: '\x1B[34m',
	magenta: '\x1B[35m',
	cyan: '\x1B[36m',
	reset: '\x1B[0m',
}

let includeHeader = false;
let useColours = false;
let msDiff = true;
let timestamp = false;

let prevTime: number | null = null;

export interface LoggingOptions {
	useColours?: boolean;
	msDiff?: boolean;
	timestamp?: boolean;
	includeHeader?: boolean;
}

export function setLogOptions(opts: LoggingOptions) {
	if (opts.includeHeader !== undefined)
		includeHeader = opts.includeHeader;
	if (opts.useColours !== undefined)
		useColours = opts.useColours;
	if (opts.msDiff !== undefined)
		msDiff = opts.msDiff;
	if (opts.timestamp !== undefined)
		timestamp = opts.timestamp;
}

function colouredText(txt: string, colour: string) {
	if (!useColours)
		return txt;
	return colour + txt + colours.reset;
}

// eslint-disable-next-line @typescript-eslint/ban-types
function log(logger: Function, lvl: string, msg: string, obj?: unknown) {
	if (!logsEnabled)
		return;

	let msDiffTxt = '';

	if (msDiff) {
		const curr = Number(new Date());
		const ms = curr - (prevTime || curr);
		prevTime = curr;
		msDiffTxt = colouredText(' +' + ms + 'ms', colours.green);
	}

	const m = (timestamp ? new Date().toISOString() + ' ' : '')
		+ (includeHeader ? colouredText('phidget22', colours.blue) +  lvl + ' : ' : '')
		+ msg + msDiffTxt;
	if (obj)
		logger(m + ' -', obj);
	else
		logger(m);
}

export function logcrit(msg: string, obj?: unknown) {
	if (loglevel >= LogLevel.CRITICAL)
		log(console.error, ' <' + colouredText('CRIT', colours.red) + '>', msg, obj);
}
export function logerr(msg: string, obj?: unknown) {
	if (loglevel >= LogLevel.ERROR)
		log(console.error, '<' + colouredText('ERROR', colours.red) + '>', msg, obj);
}
export function logwarn(msg: string, obj?: unknown) {
	if (loglevel >= LogLevel.WARNING)
		log(console.warn, ' <' + colouredText('Warn', colours.yellow) + '>', msg, obj);
}
export function loginfo(msg: string, obj?: unknown) {
	if (loglevel >= LogLevel.INFO)
		log(console.log, ' <' + 'Info' + '>', msg, obj);
}
export function logdebug(msg: string, obj?: unknown) {
	if (loglevel >= LogLevel.DEBUG)
		log(console.debug, '<' + colouredText('Debug', colours.cyan) + '>', msg, obj);
}
export function logverbose(msg: string, obj?: unknown) {
	if (loglevel >= LogLevel.VERBOSE)
		log(console.debug, ' <' + colouredText('Verb', colours.blue) + '>', msg, obj);
}

export function logbuffer(message: string, buffer: BufferSource) {

	// Don't do any work if we aren't at VERBOSE loglevel
	if (loglevel < LogLevel.VERBOSE)
		return;

	let bytestring = " ";
	let view: DataView;

	if (ArrayBuffer.isView(buffer)) {
		// buffer is ArrayBufferView
		view = new DataView(buffer.buffer);
	} else {
		// buffer is ArrayBuffer
		view = new DataView(buffer);
	}

	for (let i = 0; i < view.byteLength; i++) {
		let by = view.getUint8(i).toString(16);
		if (by.length === 1)
			by = "0" + by;
		bytestring += "0x" + by + ", ";
	}

	bytestring = bytestring.slice(0, bytestring.length - 2);

	logverbose(message + " - " + bytestring);
}

export function logEventException(err: unknown) {
	logwarn("Unhandled exception in event handler", err);
}