/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PhidgetChannel } from '../Phidget';
import { Channel } from '../Channel';
import { ErrorCode, ChannelClass } from '../Enumerations.gen';
import * as Enum from '../Enumerations.gen';
import { PhidgetError } from '../PhidgetError';
import { BridgePacket, PUNK } from '../BridgePacket';
import { BP } from '../BridgePackets.gen';
import { logEventException } from '../Logging';
import { DeviceChannelUID } from '../Devices.gen';

/** @internal */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FirmwareUpgradeData {
	actualDeviceID: Enum.DeviceID | PUNK.ENUM,
	actualDeviceVINTID: number,
	actualDeviceSKU: string | null,
	actualDeviceVersion: number,
	actualDeviceName: string | null,
	progress: number,
}

abstract class FirmwareUpgradeBase extends PhidgetChannel {
	/** @internal */
	data: FirmwareUpgradeData;
	/**
	 * **ProgressChange** event
	 *  * `progress` - The progress, range is 0-1.
	 * ---
	 * Occurs on firmware upgrade progress.
	 * @internal
	 */
	onProgressChange: ((progress: number) => void) | null = null;
	/** @internal */
	_gotProgressChangeErrorEvent?: boolean;

	/**
	 * @internal
	 */
	constructor();
	/** @internal */
	constructor(ch?: Channel);
	constructor(ch?: Channel) {
		super(ch);
		this._class = ChannelClass.FIRMWARE_UPGRADE;
		this.name = "FirmwareUpgrade";
		this.data = this._initData();
	}

	/** @internal */
	_bridgeInput(bp: BridgePacket) {

		switch(bp.vpkt) {
		case BP.SENDFIRMWARE:
			break;
		case BP.PROGRESSCHANGE: {
			this.data.progress = bp.entries[0].v as number;
			if (this._isAttachedDone && this.onProgressChange) {
				try {
					this.onProgressChange(this.data.progress);
				} catch (err) { logEventException(err); }
			}
			break;
		}
		default:
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			throw new PhidgetError(ErrorCode.INVALID_PACKET, "Unsupported bridge packet: 0x" + bp.vpkt!.toString(16));
		}
	}

	/** @internal */
	_initData(): FirmwareUpgradeData {
		return {
			actualDeviceID: PUNK.ENUM,
			actualDeviceVINTID: PUNK.UINT32,
			actualDeviceSKU: null,
			actualDeviceVersion: PUNK.INT32,
			actualDeviceName: null,
			progress: PUNK.DBL,
		}
	}

	/** @internal */
	_initAfterOpen() {
		this.data = this._initData();

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._STM32_USB_FIRMWARE_UPGRADE_200:
		case DeviceChannelUID._STM32_USB_FIRMWARE_UPGRADE_300:
		case DeviceChannelUID._STM32F0_FIRMWARE_UPGRADE_100:
		case DeviceChannelUID._STM32G0_FIRMWARE_UPGRADE_110:
		case DeviceChannelUID._STM32G0_FIRMWARE_UPGRADE_114:
		case DeviceChannelUID._STM32F3_FIRMWARE_UPGRADE_120:
		case DeviceChannelUID._STM8S_FIRMWARE_UPGRADE_100:
			this.data.progress = 0;
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	// eslint-disable-next-line require-await
	async _setDefaults() {

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._STM32_USB_FIRMWARE_UPGRADE_200:
		case DeviceChannelUID._STM32_USB_FIRMWARE_UPGRADE_300:
		case DeviceChannelUID._STM32F0_FIRMWARE_UPGRADE_100:
		case DeviceChannelUID._STM32G0_FIRMWARE_UPGRADE_110:
		case DeviceChannelUID._STM32G0_FIRMWARE_UPGRADE_114:
		case DeviceChannelUID._STM32F3_FIRMWARE_UPGRADE_120:
		case DeviceChannelUID._STM8S_FIRMWARE_UPGRADE_100:
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	_hasInitialState() {

		if ((this.data.progress == PUNK.DBL)
			&& ! this._gotProgressChangeErrorEvent)
			return false;

		return true;
	}

	/** @internal */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	_fireInitialEvents() {

		if(this.data.progress != PUNK.DBL)
			if (this.onProgressChange)
				try {
					this.onProgressChange(this.data.progress);
				} catch (err) { logEventException(err); }

	}

	/**
	 * TODO: Text Here
	 * @throws {@link PhidgetError}
	 * @internal
	 */
	get actualDeviceID() { return this.getActualDeviceID(); }
	/**
	 * TODO: Text Here
	 * @throws {@link PhidgetError}
	 * @internal
	 */
	get actualDeviceName() { return this.getActualDeviceName(); }
	/**
	 * TODO: Text Here
	 * @throws {@link PhidgetError}
	 * @internal
	 */
	get actualDeviceSKU() { return this.getActualDeviceSKU(); }
	/**
	 * TODO: Text Here
	 * @throws {@link PhidgetError}
	 * @internal
	 */
	get actualDeviceVersion() { return this.getActualDeviceVersion(); }
	/**
	 * TODO: Text Here
	 * @throws {@link PhidgetError}
	 * @internal
	 */
	get actualDeviceVINTID() { return this.getActualDeviceVINTID(); }
	/**
	 * TODO: Text Here
	 * @throws {@link PhidgetError}
	 * @internal
	 */
	get progress() { return this.getProgress(); }

	/**
	 * TODO: Text Here
	 * @returns Device ID
	 * @throws {@link PhidgetError}
	 * @internal
	 */
	getActualDeviceID(): Enum.DeviceID {
		this._assertOpen();

		if (this.data.actualDeviceID === PUNK.ENUM)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.actualDeviceID);
	}

	/**
	 * TODO: Text Here
	 * @returns Name of the device
	 * @throws {@link PhidgetError}
	 * @internal
	 */
	getActualDeviceName(): string {
		this._assertOpen();

		if (this.data.actualDeviceName === null)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.actualDeviceName);
	}

	/**
	 * TODO: Text Here
	 * @returns Device SKU
	 * @throws {@link PhidgetError}
	 * @internal
	 */
	getActualDeviceSKU(): string {
		this._assertOpen();

		if (this.data.actualDeviceSKU === null)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.actualDeviceSKU);
	}

	/**
	 * TODO: Text Here
	 * @returns Firmware version
	 * @throws {@link PhidgetError}
	 * @internal
	 */
	getActualDeviceVersion(): number {
		this._assertOpen();

		if (this.data.actualDeviceVersion === PUNK.INT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.actualDeviceVersion);
	}

	/**
	 * TODO: Text Here
	 * @returns Device VINT ID
	 * @throws {@link PhidgetError}
	 * @internal
	 */
	getActualDeviceVINTID(): number {
		this._assertOpen();

		if (this.data.actualDeviceVINTID === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.actualDeviceVINTID);
	}

	/**
	 * TODO: Text Here
	 * @returns Firmware update progress
	 * @throws {@link PhidgetError}
	 * @internal
	 */
	getProgress(): number {
		this._assertOpen();

		if (this.data.progress === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.progress);
	}

	/**
	 * TODO
	 * @throws {@link PhidgetError}
	 * @param data - Data being sent in the firmware update
	 * @internal
	 */
	abstract sendFirmware(data: readonly number[]): Promise<void>;
}
export { FirmwareUpgradeBase };
