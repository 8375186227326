import { Channel } from './Channel';
import * as ChCls from './Classes.gen';
import { ChannelClass } from './Enumerations.gen';
import { PhidgetChannel } from './Phidget';

/** @internal */
export function CreatePhidgetChannel(ch: Channel): PhidgetChannel {
	switch (ch.class) {
		case ChannelClass.ACCELEROMETER:
			return new ChCls.Accelerometer(ch);
		case ChannelClass.BLDC_MOTOR:
			return new ChCls.BLDCMotor(ch);
		case ChannelClass.CAPACITIVE_TOUCH:
			return new ChCls.CapacitiveTouch(ch);
		case ChannelClass.CURRENT_INPUT:
			return new ChCls.CurrentInput(ch);
		case ChannelClass.DC_MOTOR:
			return new ChCls.DCMotor(ch);
		case ChannelClass.DICTIONARY:
			return new ChCls.Dictionary(ch);
		case ChannelClass.DIGITAL_INPUT:
			return new ChCls.DigitalInput(ch);
		case ChannelClass.DIGITAL_OUTPUT:
			return new ChCls.DigitalOutput(ch);
		case ChannelClass.DISTANCE_SENSOR:
			return new ChCls.DistanceSensor(ch);
		case ChannelClass.ENCODER:
			return new ChCls.Encoder(ch);
		case ChannelClass.FIRMWARE_UPGRADE:
			return new ChCls.FirmwareUpgrade(ch);
		case ChannelClass.FREQUENCY_COUNTER:
			return new ChCls.FrequencyCounter(ch);
		case ChannelClass.GENERIC:
			return new ChCls.Generic(ch);
		case ChannelClass.GPS:
			return new ChCls.GPS(ch);
		case ChannelClass.GYROSCOPE:
			return new ChCls.Gyroscope(ch);
		case ChannelClass.HUB:
			return new ChCls.Hub(ch);
		case ChannelClass.HUMIDITY_SENSOR:
			return new ChCls.HumiditySensor(ch);
		case ChannelClass.IR:
			return new ChCls.IR(ch);
		case ChannelClass.LCD:
			return new ChCls.LCD(ch);
		case ChannelClass.LIGHT_SENSOR:
			return new ChCls.LightSensor(ch);
		case ChannelClass.MAGNETOMETER:
			return new ChCls.Magnetometer(ch);
		case ChannelClass.MOTOR_POSITION_CONTROLLER:
			return new ChCls.MotorPositionController(ch);
		case ChannelClass.PH_SENSOR:
			return new ChCls.PHSensor(ch);
		case ChannelClass.POWER_GUARD:
			return new ChCls.PowerGuard(ch);
		case ChannelClass.PRESSURE_SENSOR:
			return new ChCls.PressureSensor(ch);
		case ChannelClass.RC_SERVO:
			return new ChCls.RCServo(ch);
		case ChannelClass.RESISTANCE_INPUT:
			return new ChCls.ResistanceInput(ch);
		case ChannelClass.RFID:
			return new ChCls.RFID(ch);
		case ChannelClass.SOUND_SENSOR:
			return new ChCls.SoundSensor(ch);
		case ChannelClass.SPATIAL:
			return new ChCls.Spatial(ch);
		case ChannelClass.STEPPER:
			return new ChCls.Stepper(ch);
		case ChannelClass.TEMPERATURE_SENSOR:
			return new ChCls.TemperatureSensor(ch);
		case ChannelClass.VOLTAGE_INPUT:
			return new ChCls.VoltageInput(ch);
		case ChannelClass.VOLTAGE_OUTPUT:
			return new ChCls.VoltageOutput(ch);
		case ChannelClass.VOLTAGE_RATIO_INPUT:
			return new ChCls.VoltageRatioInput(ch);
		default:
			throw new Error("Invalid Channel Class");
	}
}

