import { PhidgetConnection, ConnType } from '../Connection';
import { tm } from '../phidget22';
import { Request } from './Request';
import { PhidgetError } from '../PhidgetError';
import { ChannelClass, DeviceClass, DeviceID, ErrorCode } from '../Enumerations.gen';
import { logdebug, logerr, logEventException, loginfo, logwarn } from '../Logging';
import { type BPJsonIn, type BPJsonOut, BridgePacket, PUNK } from '../BridgePacket';
import { NetworkChannel, NetworkChannelData } from './NetworkChannel';
import { NetworkDevice, NetworkDeviceData } from './NetworkDevice';
import { findPhidgetUniqueDevice } from '../Device';
/*
 * 1.1 - added keepalive support.
 * 2.0 - changed bridge packet ID numbers. Method packets are not forwarded to all clients.
 * 2.1 - added fwstr to SMSG_DEVATTACH packet
 * 2.2 - stop requiring class version to match on channel open
 * 2.3 - support VINT2 hubs - extra fields in SMSG_DEVATTACH, etc.
 */
const NET_MAJOR = 2;
const NET_MINOR = 3;

const NET_IDENT = "phidgetclient";

/** @internal */
export const enum P22MSG {
	Connect = 10,
	Command = 20,
	Device = 30
}

/** @internal */
export const enum P22SMSG {
	/* Connect */
	HandShakeC0 = 10,
	HandShakeS0 = 11,
	AuthC0 = 30,
	AuthS0 = 31,
	AuthC1 = 32,
	AuthS1 = 33,
	/* Command */
	Reply = 40,
	KeepAlive = 41,
	/* Device */
	Attach = 50,
	Detach = 55,
	Open = 60,
	Close = 65,
	BridgePkt = 70,
	Channel = 80
}

/** @internal */
export const enum NR {
	Magic = 0x50484930,
	Request = 0x01,
	Reply = 0x02,
	Event = 0x04
}

/** @internal */
type NET_PROTOCOL = "www" | "www,nodejs" | "phid22device";

// P22SMSG.HandShakeC0 (MSG_CONNECT, SMSG_HANDSHAKEC0) client -> server
/** @internal */
interface P22SMSG_HandShakeC0_Data {
	type: NET_PROTOCOL,
	pmajor: number,
	pminor: number,

	// NOTE: these are used to set up a datagram port - not used by the JS library as of yet
	dgram?: number,
	port?: number
}
// P22SMSG.HandShakeS0 (MSG_CONNECT, SMSG_HANDSHAKES0) server -> client
/** @internal */
interface P22SMSG_HandShakeS0_Data {
	type: NET_PROTOCOL,
	pmajor: number,
	pminor: number,
	result: ErrorCode
}
// P22SMSG.AuthC0 (MSG_CONNECT, SMSG_AUTHC0) client -> server
/** @internal */
interface P22SMSG_AuthC0_Data {
	ident: string,
	nonceC: string
}
// P22SMSG.AuthS0 (MSG_CONNECT, SMSG_AUTHS0) server -> client
/** @internal */
interface P22SMSG_AuthS0_Data {
	srvname: string,
	nonceC: string,
	nonceS: string,
	salt: string,
	count: number,
	result: ErrorCode
}
// P22SMSG.AuthC1 (MSG_CONNECT, SMSG_AUTHC1) client -> server
/** @internal */
interface P22SMSG_AuthC1_Data {
	nonceC: string,
	nonceS: string,
	proof: string
}
// P22SMSG.AuthS1 (MSG_CONNECT, SMSG_AUTHS1) - NOTE: Unused
//interface P22SMSG_AuthS1_Data {}

// P22SMSG.Reply (MSG_COMMAND, SMSG_REPLY) server -> client
/** @internal */
interface P22SMSG_Reply_Data {
	E: ErrorCode,
	R?: string
}
// P22SMSG.KeepAlive (MSG_COMMAND, SMSG_KEEPALIVE) server <-> client
// No data is sent, just header

// P22SMSG.Attach (MSG_DEVICE, SMSG_DEVATTACH) server -> client
/** @internal */
export interface P22SMSG_Attach_Data {
	type: "USB" | "VINT" | "MESH" | "SPI" | "VIRTUAL",
	phid: string,	// NOTE: uint64 converted to string
	parent: string,	// NOTE: uint64 converted to string
	vendorID: number,
	productID: number,
	interfaceNum: number,
	version: number,
	serialNumber: number,
	label: string,
	index: number,
	deviceID: DeviceID,
	vintID: number,
	hubPort: number,
	isHubPort: number,
	name: string,
	desc: string,

	// Version 2.1+

	fwstr?: string,

	// Version 2.3+

	// PHIDCLASS_HUB specific
	hubPortsInfo?: {
		portProto: number[],
		portSuppSetSpeed: number[],
		portMaxSpeed: number[]
	},

	// PHIDTYPE_VINT specific
	vintProto?: number,
	suppSetSpeed?: number,
	maxSpeed?: number,
	commSpeed?: number
}

// P22SMSG.Detach (MSG_DEVICE, SMSG_DEVDETACH) server -> client
/** @internal */
interface P22SMSG_Detach_Data {
	phid: string,	// NOTE: uint64 converted to string
	parent: string	// NOTE: uint64 converted to string
}

// P22SMSG.Open (MSG_DEVICE, SMSG_DEVOPEN) client -> server
/** @internal */
interface P22SMSG_Open_Data {
	phid: string,		// NOTE: uint64 converted to string
	channel: string,	// NOTE: uint64 converted to string
	class: ChannelClass,
	index: number,
	version: number
}

// P22SMSG.Close (MSG_DEVICE, SMSG_DEVCLOSE) client -> server
/** @internal */
interface P22SMSG_Close_Data {
	phid: string,	// NOTE: uint64 converted to string
	index: number
}

// P22SMSG.BridgePkt (MSG_DEVICE, SMSG_DEVBRIDGEPKT) server <-> client
/** @internal */
type P22SMSG_BridgePacket_IN = BPJsonIn;
/** @internal */
type P22SMSG_BridgePacket_OUT = BPJsonOut;

// P22SMSG.Channel (MSG_DEVICE, SMSG_DEVCHANNEL) server -> client
/** @internal */
interface P22SMSG_Channel_Data {
	parent: string,
	chid: string,
	class: ChannelClass,
	uniqueIndex: number,
	index: number,
	version: number,
	name: string,	// unique channel name
	channelname: string // Channel class name
}

export interface NetworkConnectionOptions {
	hostname?: string,
	port?: number,
	name?: string,
	passwd?: string,
	onConnect?: () => void | Promise<void>,
	onDisconnect?: () => void | Promise<void>,
	onAuthenticationNeeded?: () => string,
	onError?: (code: ErrorCode, msg: string) => void | Promise<void>
}

type AuthHandler = ((data: object) => void);

export abstract class NetworkConnectionBase extends PhidgetConnection {
	/** @internal */
	declare _channels: Map<string, NetworkChannel>;
	/** @internal */
	declare _devices: Map<string, NetworkDevice>;
	/** @internal */
	protected _hostname: string;
	/** @internal */
	protected _port: number;
	/** @internal */
	protected _protocol!: NET_PROTOCOL;
	/** @internal */
	protected _uri: string;
	/** @internal */
	protected _generation;
	/** @internal */
	protected _timeout;
	/** @internal */
	private _nonceC?: string;
	/** @internal */
	private _passwd: string;
	/** @internal */
	private _reqseq;
	/** @internal */
	private _requests: Map<number, {
		generation: number,
		time: number,
		onReply: (res: P22SMSG_Reply_Data | P22SMSG_BridgePacket_IN) => void,
		onTimeout: () => void,
		onError: (code: ErrorCode, msg: string) => void
	}>;
	/** @internal */
	private _handleAbandonedRequestsInterval?: NodeJS.Timer;
	/** @internal */
	private _connectionMaintainer?: NodeJS.Timeout;
	/** @internal */
	private _keepAliveTimeout?: NodeJS.Timeout;
	/** @internal */
	private _onauthdata?: AuthHandler;
	/** @internal */
	readonly _type: ConnType;
	/** @internal */
	protected _opened: boolean;

	// override in subclass
	/** @internal */
	protected abstract _createSalt(len: number): string;
	/** @internal */
	protected abstract _closesocket(): void;
	/** @internal */
	protected abstract _send(req: Request, data: string): void;
	/** @internal */
	protected abstract _hash(challenge: string): string;

	/** @internal */
	protected _resolveConnect?: (() => void);

	// events

	/**
	 * **AuthenticationNeeded** event
	 * 
	 * ---
	 * Called when the server requires a password, and none has been provided, or the provided password is wrong.
	 * 
	 * Return the correct password to connect, or return null to cancel.
	 */
	public onAuthenticationNeeded: ((this: NetworkConnectionBase) => string) | null;
	/**
	 * **Connect** event
	 * 
	 * ---
	 * Called when connection to the server is established.
	 */
	public onConnect: ((this: NetworkConnectionBase) => void) | null;
	/**
	 * **Disconnect** event
	 * 
	 * ---
	 * Called when the connection to the server is closed.
	 */
	public onDisconnect: ((this: NetworkConnectionBase) => void) | null;

	/**
	 * The Connection object manages a connection to a Phidget Server.
	 *
	 * Once a connection has been successfully established via the connect() call, 
	 * this connection will be maintained until close() is called. If the underlying 
	 * connection is ever closed - because the server was shut down, or because of 
	 * network issue, the Connection object will try to re-establish the connection automatically.
	 * 
	 * @param options - The options parameter is optional, and supports the following properties:
	 * *   `hostname`: The server hostname or IP address. **Default**: `'localhost'`
	 * *   `port`: The server port. **Default**: `5661` (Node.js) / `8989` (Browser)
	 * *   `name`: A name for the connection. **Default**: Connection URI
	 * *   `passwd`: Password for the Phidget Server. **Default**: `''`
	 * *   `onConnect()`: Function that will be called on connection to server
	 * *   `onDisconnect()`: Function that will be called on disconnection from server
	 * *   `onAuthenticationNeeded()`: Function that will be called if a password is needed
	 * *   `onError(code, msg)`: Function that will be called if an error occurs
	 */
	constructor(options: NetworkConnectionOptions);
	/**
	 * The Connection object manages a connection to a Phidget Server.
	 *
	 * Once a connection has been successfully established via the connect() call, 
	 * this connection will be maintained until close() is called. If the underlying 
	 * connection is ever closed - because the server was shut down, or because of 
	 * network issue, the Connection object will try to re-establish the connection automatically.
	 * 
	 * @param uri - Connection URI
	 * @param options - The options parameter is optional, and supports the following properties:
	 * *   `name`: A name for the connection. **Default**: Connection URI
	 * *   `passwd`: Password for the Phidget Server. **Default**: `''`
	 * *   `onConnect()`: Function that will be called on connection to server
	 * *   `onDisconnect()`: Function that will be called on disconnection from server
	 * *   `onAuthenticationNeeded()`: Function that will be called if a password is needed
	 * *   `onError(code, msg)`: Function that will be called if an error occurs
	 */
	constructor(uri: string, options?: NetworkConnectionOptions);
	/**
	 * The Connection object manages a connection to a Phidget Server.
	 *
	 * Once a connection has been successfully established via the connect() call, 
	 * this connection will be maintained until close() is called. If the underlying 
	 * connection is ever closed - because the server was shut down, or because of 
	 * network issue, the Connection object will try to re-establish the connection automatically.
	 * 
	 * @param port - The server port.
	 * @param hostname - The server hostname or IP address.
	 * @param options - The options parameter is optional, and supports the following properties:
	 * *   `name`: A name for the connection. **Default**: Connection URI
	 * *   `passwd`: Password for the Phidget Server. **Default**: `''`
	 * *   `onConnect()`: Function that will be called on connection to server
	 * *   `onDisconnect()`: Function that will be called on disconnection from server
	 * *   `onAuthenticationNeeded()`: Function that will be called if a password is needed
	 * *   `onError(code, msg)`: Function that will be called if an error occurs
	 */
	constructor(port: number, hostname?: string, options?: NetworkConnectionOptions);
	/** @internal */
	constructor(optsOrUriOrPort?: NetworkConnectionOptions | string | number, optsOrHostname?: NetworkConnectionOptions | string, opts?: NetworkConnectionOptions);
	constructor(optsOrUriOrPort?: NetworkConnectionOptions | string | number, optsOrHostname?: NetworkConnectionOptions | string, opts?: NetworkConnectionOptions) {
		let options: NetworkConnectionOptions = {};
		let uri = '';
		let hostname = '';
		let port = 0;

		if (optsOrUriOrPort != undefined && typeof optsOrUriOrPort === 'object') {
			options = optsOrUriOrPort;
		} else if (optsOrUriOrPort != undefined && typeof optsOrUriOrPort === 'string') {
			uri = optsOrUriOrPort;
			if (optsOrHostname != undefined && typeof optsOrHostname === 'object')
				options = optsOrHostname;
		} else if (optsOrUriOrPort != undefined && typeof optsOrUriOrPort === 'number') {
			port = optsOrUriOrPort;
			if (optsOrHostname != undefined && typeof optsOrHostname === 'string') {
				hostname = optsOrHostname;
				if (opts != undefined && typeof opts === 'object')
					options = opts;
			}
		}

		if (!options.name)
			options.name = uri;

		super(options);
		this._type = ConnType.REMOTE;
		this._generation = 0;
		this._timeout = 8000;
		this._reqseq = 10;
		this._requests = new Map();

		this._opened = false;

		this._uri = uri;

		if (options.hostname && hostname === '') {
			if (typeof options.hostname === 'string')
				hostname = options.hostname;
		}
		if (hostname === '')
			hostname = 'localhost';
		this._hostname = hostname;

		if (options.port && port === 0) {
			if (typeof options.port === 'number')
				port = options.port;
			if (typeof options.port === 'string')
				port = Number(options.port);
		}
		this._port = port;

		if (options.onConnect && typeof options.onConnect === 'function')
			this.onConnect = options.onConnect;
		else
			this.onConnect = null;

		if (options.onDisconnect && typeof options.onDisconnect === 'function')
			this.onDisconnect = options.onDisconnect;
		else
			this.onDisconnect = null;

		if (options.onAuthenticationNeeded && typeof options.onAuthenticationNeeded === 'function')
			this.onAuthenticationNeeded = options.onAuthenticationNeeded;
		else
			this.onAuthenticationNeeded = null;

		if (options.passwd)
			this._passwd = options.passwd;
		else
			this._passwd = '';

		this._handleAbandonedRequestsInterval = setInterval(this._handleAbandonedRequests.bind(this), 2000);
	}

	delete() {

		// I'd rather do this in the close() function, but startInterval would need to move to connect()
		if (this._handleAbandonedRequestsInterval != undefined) {
			clearInterval(this._handleAbandonedRequestsInterval);
			delete this._handleAbandonedRequestsInterval;
		}

		super.delete();
	}

	/** Sets the keepalive timeout, in ms. Default is 8000. */
	public setKeepAlive(timeout: number) {

		if (typeof timeout !== 'number' || isNaN(timeout))
			throw 'invalid keep alive:' + timeout;

		this._timeout = timeout;
	}

	public close() {

		logdebug("Network Connection close() called");
		this._opened = false;
		if (this._connectionMaintainer != undefined)
			clearTimeout(this._connectionMaintainer);
		delete this._connectionMaintainer;
		this._detachAllDevices();
		this._closesocket();
	}

	//////////////
	// Internal //
	//////////////

	/*
	 * Creates the request if necessary, and routes the message to the correct handler.
	 */
	/** @internal */
	protected _onmessage(msg: string | undefined, req: Request) {
		let json;

		if (msg !== undefined) {
			/* Turn id terms into strings because otherwise the uint64s may be rounded */
			const tmp1 = msg.replace(/("[OI]"|"phid"|"chid"|"parent"):([0-9]+)/g, "$1:\"$2\"");
			/* Turn nan as produced by printf into "NaN" */
			const tmp2 = tmp1.replace(/([:[,])[-+]?nan(\([\w]*\))?/ig, "$1\"**NAN**\"");
			/* Turn nan as produced by printf into null */
			//const tmp2 = tmp1.replace(/([:[,])[-+]?nan(\([\w]*\))?/ig, "$1null");
			if (tmp1 !== tmp2) {
				json = JSON.parse(tmp2, (key, value) => {
					if (key == 'v') {
						if (value === "**NAN**")
							return NaN;
						if (Array.isArray(value))
							return value.map(val => {
								if (val === "**NAN**")
									return NaN;
								return val;
							});
					}
					return value;
				});
			} else {
				json = JSON.parse(tmp2);
			}
		}

		try {
			if (this.connected)
				this._ondatamessage(json, req);
			else
				this._onauthmessage(json);
		} catch (err) {
			// Any async errors are sent to the error event
			if (this.onError && err instanceof PhidgetError) {
				try {
					this.onError(err.errorCode, err.message);
				} catch (err) { logEventException(err); }
				loginfo("Error handling message from server", err);
			} else {
				logerr("Error handling message from server", err);
			}
		}
	}

	/*
	 * Try to handle getting no reply back from the server gracefully.
	 */
	/** @internal */
	private _handleAbandonedRequests() {
		for (const r of this._requests.entries()) {
			/* throw away requests left from a previous connection */
			if (r[1].generation != this._generation) {
				r[1].onError(ErrorCode.CONNECTION_RESET, "Connection Reset");
				this._requests.delete(r[0]);
				continue;
			}
			/* Timeout requests after 5 seconds */
			if (tm() - r[1].time > 5000) {
				r[1].onTimeout();
				this._requests.delete(r[0]);
			}
		}
	}

	/** @internal */
	private _getNextRequestSequence() {

		if (this._reqseq >= 65535)
			this._reqseq = 10;

		this._reqseq++;
		return (this._reqseq);
	}

	/** @internal */
	_sendRequest(flags: number, reqseq: number, repseq: number, type: P22MSG.Device, stype: P22SMSG.Open, data: P22SMSG_Open_Data): Promise<BPJsonIn>;
	/** @internal */
	_sendRequest(flags: number, reqseq: number, repseq: number, type: P22MSG.Device, stype: P22SMSG.BridgePkt, data: P22SMSG_BridgePacket_OUT): Promise<string | void>;
	/** @internal */
	_sendRequest(flags: number, reqseq: number, repseq: number, type: P22MSG.Device, stype: P22SMSG.Close, data: P22SMSG_Close_Data): Promise<void>;
	/** @internal */
	_sendRequest(flags: number, reqseq: number, repseq: number, type: P22MSG, stype: P22SMSG, data: P22SMSG_Open_Data | P22SMSG_BridgePacket_OUT | P22SMSG_Close_Data): Promise<BPJsonIn | string | void> {

		if (reqseq === 0)
			reqseq = this._getNextRequestSequence();

		return (new Promise((resolve, reject) => {
			this._requests.set(reqseq, {
				generation: this._generation,
				time: tm(),
				onReply: function (res: P22SMSG_Reply_Data | P22SMSG_BridgePacket_IN) {
					if ('E' in res) {
						if (res.E !== ErrorCode.SUCCESS) {
							if (res.R != undefined)
								reject(new PhidgetError(res.E, res.R));
							reject(new PhidgetError(res.E));
						} else {
							if (res.R != undefined)
								resolve(res.R);
							resolve();
						}
					} else {
						resolve(res as BPJsonIn);
					}
				},
				onTimeout: function () {
					reject(new PhidgetError(ErrorCode.TIMEOUT));
				},
				onError: function (code, msg) {
					reject(new PhidgetError(code, msg));
				}
			});

			/* de-quote the IDs so the C library can parse them as uint64s */
			const json = JSON.stringify(data);
			const data2 = json.replace(/("[OI]"|"phid"|"channel"):"([0-9]+)"/g, "$1:$2");

			const req = new Request(data2.length, flags, reqseq, repseq, type, stype);
			this._send(req, data2);
		}));
	}

	/** @internal */
	_sendReply(repseq: number, type: P22MSG, stype: P22SMSG, reply?: P22SMSG_Reply_Data) {
		const NRF_REPLY = 0x0002;
		const reqseq = this._getNextRequestSequence();

		let data = '';
		if (reply != undefined)
			data = JSON.stringify(reply);

		const req = new Request(data.length, NRF_REPLY, reqseq, repseq, type, stype);
		this._send(req, data);
	}

	/** @internal */
	protected _maintainConnection() {

		logdebug("Maintaining network connection..");

		const nextMaintainer = () => {
			// NOTE: connect() will have started up a new connection maintainer so we check for it here
			if (!this._connectionMaintainer && this._opened) {
				logdebug(".. Check again in 4 seconds.");
				this._connectionMaintainer = setTimeout(this._maintainConnection.bind(this), 4000);
			}
		}

		// Remove any existing maintainer
		if (this._connectionMaintainer)
			delete this._connectionMaintainer;

		if (this.connected) {
			logdebug(".. already connected ..");
			nextMaintainer();
		} else {
			logdebug(".. trying to connect ..");
			this.connect().then(() => {
				logdebug(".. connected!");
				nextMaintainer();
			}).catch(err => {
				if (this.onError && err instanceof PhidgetError) {
					try {
						this.onError(err.errorCode, err.message);
					} catch (err) { logEventException(err); }
				}
				logerr("Error connecting", err);
				logdebug(".. failed to connect! ..");
				nextMaintainer();
			});
		}

	}

	/** @internal */
	protected _doclose() {

		this._detachAllDevices();
		this._closesocket();
	}

	/** @internal */
	private _onauthmessage(data: Record<string, unknown>) {

		if (this._onauthdata == undefined)
			throw new Error('packet recieved while not connected and authdata is not defined');

		this._onauthdata(data);
	}

	// NOTE: This is async, but it sends any errors on to the onError event, so it should never reject
	/** @internal */
	private _ondatamessage(data: Record<string, unknown>, req: Request) {
		const request = this._requests.get(req.repseq);

		if (request) {
			this._requests.delete(req.repseq);
			request.onReply(data as unknown as P22SMSG_Reply_Data | P22SMSG_BridgePacket_IN);
		}

		/*
		 * Replies do not require additional processing, but there must have been a request
		 * object registered.
		 */
		if (req.flgs & NR.Reply) {
			// If the request object is missing, it was probably removed by the timeout handling.
			if (request === undefined)
				throw new PhidgetError(ErrorCode.UNEXPECTED, 'No handler registered for reply: ' + req);
			return;
		}

		switch (req.type) {
			case P22MSG.Command:
				this._handleCommand(req);
				break;
			case P22MSG.Device:
				this._handleDevice(req, data)
				break;
			default:
				throw new PhidgetError(ErrorCode.INVALID, 'Unknown request type:' + req.type);
		}
	}

	/** @internal */
	private _handleCommand(req: Request) {

		switch (req.stype) {
			case P22SMSG.KeepAlive:
				logdebug("Got a keepalive message");
				if (this._keepAliveTimeout != undefined) {
					logdebug("Cleaning previous keepalive timeout");
					clearTimeout(this._keepAliveTimeout);
				}
				this._keepAliveTimeout = setTimeout(() => {
					logdebug("Keepalive timeout passed");
					delete this._keepAliveTimeout;
					if (this.connected) {
						if (this.onError) {
							try {
								this.onError(ErrorCode.KEEP_ALIVE, "KeepAlive timeout. Closing connection to server.");
							} catch (err) { logEventException(err); }
						}
						logerr("KeepAlive timeout. Closing connection to server.");
						this._doclose();
					}
				}, this._timeout);
				this._sendReply(req.reqseq, P22MSG.Command, P22SMSG.KeepAlive);
				return;
			default:
				throw new PhidgetError(ErrorCode.UNEXPECTED, 'Unknown command subrequest:' + req.stype);
		}
	}

	/** @internal */
	private _handleDevice(req: Request, data: Record<string, unknown>) {

		switch (req.stype) {
			case P22SMSG.Attach:
				this._handleDeviceAttach(data as unknown as P22SMSG_Attach_Data);
				return;
			case P22SMSG.Detach:
				this._handleDeviceDetach(data as unknown as P22SMSG_Detach_Data);
				return;
			case P22SMSG.BridgePkt:
				this._handleBridgePacket(req, data as unknown as P22SMSG_BridgePacket_IN);
				return;
			case P22SMSG.Channel:
				this._handleChannel(data as unknown as P22SMSG_Channel_Data);
				return;
			default:
				throw new PhidgetError(ErrorCode.UNEXPECTED, 'Unknown device subrequest:' + req.stype);
		}
	}

	/** @internal */
	private _handleDeviceAttach(data: P22SMSG_Attach_Data) {
		const devDef = findPhidgetUniqueDevice(data);
		let devData: NetworkDeviceData;
		const baseData = {
			version: data.version,
			label: data.label,
			serialNumber: data.serialNumber,
			devDef: devDef,
			fwstr: data.fwstr ?? devDef.s, // fallback on SKU string for very old network server
			id: data.phid,
			parent: this._getDevice(data.parent) ?? undefined,
			deviceID: data.deviceID,
			name: data.name
		};

		if (data.type === 'VINT') {
			devData = {
				...baseData,
				type: 'VINT' as const,
				vintDeviceProps: {
					vintProto: data.vintProto ?? 1,
					suppSetSpeed: data.suppSetSpeed ? !!data.suppSetSpeed : false,
					// AutoSetSpeed support flag is not sent over the network - just allow it, and if not supported, we'll get that info from the server.
					suppAutoSetSpeed: data.suppSetSpeed ? !!data.suppSetSpeed : false,
					maxSpeed: data.maxSpeed ?? PUNK.UINT32,
					commSpeed: data.commSpeed ?? PUNK.UINT32,
					hubPort: data.hubPort,
					isHubPort: !!data.isHubPort,
					uniqueIndex: data.index
				}
			};
		} else if (devDef.c === DeviceClass.HUB) {

			let hubPortCnt;
			if (data.hubPortsInfo)
				hubPortCnt = data.hubPortsInfo.portProto.length;
			else
				hubPortCnt = devDef.cn ? devDef.cn[0] : 6; // Not ideal, but should be fine

			const hubPortProps = [];
			for (let i = 0; i < hubPortCnt; i++) {
				if (data.hubPortsInfo) {
					hubPortProps.push({
						portProto: data.hubPortsInfo.portProto[i],
						portSuppSetSpeed: !!data.hubPortsInfo.portSuppSetSpeed[i],
						// AutoSetSpeed support flag is not sent over the network - just allow it, and if not supported, we'll get that info from the server.
						portSuppAutoSetSpeed: !!data.hubPortsInfo.portSuppSetSpeed[i],
						portMaxSpeed: data.hubPortsInfo.portMaxSpeed[i],
					});
				} else {
					// Defaults if talking to an old server
					hubPortProps.push({
						portProto: 1,
						portSuppSetSpeed: false,
						portSuppAutoSetSpeed: false,
						portMaxSpeed: 100000,
					});
				}
			}
			devData = {
				...baseData,
				type: 'HUB' as const,
				hubPortProps: hubPortProps
			};
		} else {
			devData = {
				...baseData,
				type: 'OTHER' as const
			};
		}
		const dev = new NetworkDevice(this, devData);
		this._deviceAttach(dev);
	}

	/** @internal */
	private _handleDeviceDetach(data: P22SMSG_Detach_Data) {
		const dev = this._getDevice(data.phid);
		if (dev)
			this._deviceDetach(dev);
	}

	/** @internal */
	private _handleChannel(data: P22SMSG_Channel_Data) {

		const dev = this._devices.get(data.parent);
		if (!dev)
			throw new PhidgetError(ErrorCode.UNEXPECTED, 'missing channel parent');

		const chDef = dev.findPhidgetUniqueChannel(data.uniqueIndex);
		const chData: NetworkChannelData = {
			id: data.chid,
			chDef: chDef,
			uniqueIndex: data.uniqueIndex,
			index: data.index,
			cpversion: data.version,
			name: data.name,
			class: data.class
		};
		const ch = new NetworkChannel(this, dev, chData);
		this._channelAttach(ch);
	}

	/** @internal */
	private _handleBridgePacket(req: Request, data: P22SMSG_BridgePacket_IN) {
		const bp = new BridgePacket(this, data);
		let reply: P22SMSG_Reply_Data | undefined;
		try {
			bp.deliver();
		} catch (err) {
			if (err instanceof PhidgetError) {
				reply = {
					E: err.errorCode,
					R: err.message
				};
			} else {
				reply = {
					E: ErrorCode.UNEXPECTED,
					R: '' + err
				};
			}
		}
		/* Send a reply if this is not an event */
		if (!bp.isEvent())
			this._sendReply(req.reqseq, req.type, req.stype, reply);
	}

	/** @internal */
	protected _handshake(): Promise<void> {

		return (new Promise<void>((resolve, reject) => {

			const pkt: P22SMSG_HandShakeC0_Data = {
				type: this._protocol,
				pmajor: NET_MAJOR,
				pminor: NET_MINOR
			};
			const json = JSON.stringify(pkt);
			const req = new Request(json.length, 0, 0, 0, P22MSG.Connect, P22SMSG.HandShakeC0);
			this._send(req, json);

			this._onauthdata = <AuthHandler>HandShakeS0;

			function HandShakeS0(this: NetworkConnectionBase, data: P22SMSG_HandShakeS0_Data) {
				if (data.result !== ErrorCode.SUCCESS) {
					reject(new PhidgetError(data.result, 'server rejected handshake'));
					return;
				}

				/* start authentication */
				this._nonceC = this._createSalt(16);
				const pkt: P22SMSG_AuthC0_Data = {
					ident: NET_IDENT,
					nonceC: this._nonceC
				};
				const json = JSON.stringify(pkt);
				const req = new Request(json.length, 0, 0, 0, P22MSG.Connect, P22SMSG.AuthC0);

				try {
					this._send(req, json);
				} catch (err) {
					reject(err);
					return;
				}

				this._onauthdata = <AuthHandler>AuthS0;
			}

			function AuthS0(this: NetworkConnectionBase, data: P22SMSG_AuthS0_Data) {
				if (data.result !== ErrorCode.SUCCESS) {
					reject(new PhidgetError(data.result, 'authentication failed'));
					return;
				}

				if (this._nonceC != data.nonceC) {
					reject(new PhidgetError(ErrorCode.UNEXPECTED, 'Authentication Failure: nonce do not match (' +
						this._nonceC + ') vs (' + data.nonceC + ')'));
					return;
				}

				const challenge = NET_IDENT + this._passwd + this._nonceC + data.nonceS + data.salt;
				const proof = this._hash(challenge);
				const pkt: P22SMSG_AuthC1_Data = { nonceC: this._nonceC, nonceS: data.nonceS, proof: proof };
				const json = JSON.stringify(pkt);
				const req = new Request(json.length, 0, 0, 0, P22MSG.Connect, P22SMSG.AuthC1);

				try {
					this._send(req, json);
				} catch (err) {
					reject(err);
					return;
				}

				this._onauthdata = <AuthHandler>AuthReply;
			}

			function AuthReply(this: NetworkConnectionBase, data: P22SMSG_Reply_Data) {
				delete this._onauthdata;

				if (data.E != ErrorCode.SUCCESS) {
					if (this.onAuthenticationNeeded) {
						let pass;
						try {
							pass = this.onAuthenticationNeeded();
						} catch (err) { logEventException(err); }
						if (pass != undefined && typeof pass === 'string') {
							this._passwd = pass;
							// Run connect again, with the password now - but in it's own 'thread'
							setTimeout(() => {
								this.connect().then(() => resolve()).catch(err => reject(err));
							}, 10);
							return;
						}
					} else {
						logwarn("A password is required for this server. Handle the onAuthenticationNeeded event to return a password.");
					}

					reject(new PhidgetError(data.E, 'authentication failed: server rejected proof'));
					return;
				}

				this.connected = true; // prevent packets from being missed

				if (this.onConnect) {
					try {
						this.onConnect();
					} catch (err) { logEventException(err); }
				}

				// Ensure that no previous connection maintainer is running before we start a new one
				if (this._connectionMaintainer != undefined)
					clearTimeout(this._connectionMaintainer);
				delete this._connectionMaintainer;
				this._maintainConnection();

				// Connected!
				resolve();
			}
		}));
	}
}

export function DecodeUTF8(bytes: Uint8Array) {
	let s = '';
	let i = 0;
	while (i < bytes.length) {
		let c = bytes[i++];
		if (c > 127) {
			if (c > 191 && c < 224) {
				if (i >= bytes.length) throw 'UTF-8 decode: incomplete 2-byte sequence';
				c = (c & 31) << 6 | bytes[i] & 63;
			} else if (c > 223 && c < 240) {
				if (i + 1 >= bytes.length) throw 'UTF-8 decode: incomplete 3-byte sequence';
				c = (c & 15) << 12 | (bytes[i] & 63) << 6 | bytes[++i] & 63;
			} else if (c > 239 && c < 248) {
				if (i + 2 >= bytes.length) throw 'UTF-8 decode: incomplete 4-byte sequence';
				c = (c & 7) << 18 | (bytes[i] & 63) << 12 | (bytes[++i] & 63) << 6 | bytes[++i] & 63;
			} else throw 'UTF-8 decode: unknown multibyte start 0x' + c.toString(16) + ' at index ' + (i - 1);
			++i;
		}

		if (c <= 0xffff) s += String.fromCharCode(c);
		else if (c <= 0x10ffff) {
			c -= 0x10000;
			s += String.fromCharCode(c >> 10 | 0xd800)
			s += String.fromCharCode(c & 0x3FF | 0xdc00)
		} else throw 'UTF-8 decode: code point 0x' + c.toString(16) + ' exceeds UTF-16 reach';
	}
	return s;
}

export function EncodeUTF8(s: string) {
	let i = 0;
	const bytes = new Uint8Array(s.length * 4);
	for (let ci = 0; ci != s.length; ci++) {
		let c = s.charCodeAt(ci);
		if (c < 128) {
			bytes[i++] = c;
			continue;
		}
		if (c < 2048) {
			bytes[i++] = c >> 6 | 192;
		} else {
			if (c > 0xd7ff && c < 0xdc00) {
				if (++ci == s.length) throw 'UTF-8 encode: incomplete surrogate pair';
				const c2 = s.charCodeAt(ci);
				if (c2 < 0xdc00 || c2 > 0xdfff) throw 'UTF-8 encode: second char code 0x' + c2.toString(16) + ' at index ' + ci + ' in surrogate pair out of range';
				c = 0x10000 + ((c & 0x03ff) << 10) + (c2 & 0x03ff);
				bytes[i++] = c >> 18 | 240;
				bytes[i++] = c >> 12 & 63 | 128;
			} else { // c <= 0xffff
				bytes[i++] = c >> 12 | 224;
			}
			bytes[i++] = c >> 6 & 63 | 128;
		}
		bytes[i++] = c & 63 | 128;
	}
	return bytes.subarray(0, i);
}