﻿import { PhidgetError } from './PhidgetError';
import { ErrorCode } from './Enumerations.gen';
import { BP } from './BridgePackets.gen';
import { PhidgetConnection } from './Connection';
import { Channel } from './Channel';

/** @internal */
export const enum PUNK {
	BOOL = 0x02,
	INT8 = 127,
	UINT8 = 0xff,
	INT16 = 32767,
	UINT16 = 0xffff,
	INT32 = 2147483647,
	UINT32 = 0xffffffff,
	//NOTE: Value in C library is: 9223372036854775807 but JavaScript loses precision
	INT64 = 9223372036854776000,
	//NOTE: Value in C library is: 0xffffffffffffffff but JavaScript loses precision
	UINT64 = 18446744073709552000,
	DBL = 1e300,
	FLT = 1e30,
	ENUM = 2147483647,
	//NOTE: Value in C library is: 0xffffffffffffffff but JavaScript loses precision
	SIZE = 18446744073709552000
}

/** @internal */
const enum BPEntryType {
	UNEXPECTED = -1,
	NUMBER = 1,
	FLOAT = 2,
	STRING = 3,
	ARRAY = 4,
	JSON = 5
}

/** @internal */
const enum BPSource {
	BRIDGE = 1,
	JSON = 2
}

/** @internal */
const BPE_ISEVENT_FLAG = 0x01

/** @internal */
type BPJsonEntryValue = number | string | readonly number[] | Record<string, unknown>;
/** @internal */
interface BPJsonEntry {
	t: string,
	v: BPJsonEntryValue
}

/** @internal */
export interface BPJsonIn {
	v: number;
	s: BPSource;
	f: number;
	p: BP;
	I: string;
	O: string;
	X: number;
	c: number;
	e: Record<string, BPJsonEntry>;
}

/** @internal */
export interface BPJsonOut {
	v: number;
	s: BPSource;
	f: number;
	p: BP;
	I: string;
	X: number;
	c: number;
	e: Record<string, BPJsonEntry>;
}

/** @internal */
interface BPEntryString {
	name: string;
	type: 's';
	value: string;
}
/** @internal */
interface BPEntryNumber {
	name: string;
	type: 'c' | 'h' | 'uh' | 'd' | 'u' | 'l' | 'ul' | 'f' | 'g';
	value: number;
}
/** @internal */
interface BPEntryArray {
	name: string;
	type: 'R' | 'I' | 'G' | 'U' | 'H';
	value: readonly number[];
}
/** @internal */
interface BPEntryObject {
	name: string;
	type: 'J';
	value: Record<string, BPJsonEntryValue>;
}

/** @internal */
type BPEntry = BPEntryString | BPEntryNumber | BPEntryArray | BPEntryObject;

/** @internal */
class BridgePacket {
	private version: number;
	private source: BPSource;
	private flags: number;
	vpkt?: BP;
	private ch?: Channel;
	entryCount: number;
	entries: Record<string, BPJsonEntry>;
	local: boolean;

	constructor();
	constructor(conn: PhidgetConnection, data: BPJsonIn);
	constructor(conn?: PhidgetConnection, data?: BPJsonIn) {

		if (conn != undefined && data != undefined) {
			this.version = data.v;
			this.source = data.s;
			this.flags = data.f;
			this.vpkt = data.p;
			this.ch = conn._getChannel(data.O);
			this.entryCount = data.c;
			this.entries = data.e;
			this.local = false;
		} else {
			this.version = 0;
			this.source = BPSource.JSON;
			this.flags = 0;
			this.entryCount = 0;
			this.entries = {};
			this.local = true;
		}
	}

	isEvent() {

		if (this.flags & BPE_ISEVENT_FLAG)
			return (true);
		return (false);
	}

	private entryType(type: string): BPEntryType {

		switch (type) {
			case 'c':
			case 'h':
			case 'uh':
			case 'd':
			case 'u':
			case 'l':
			case 'ul':
				return (BPEntryType.NUMBER);
			case 'f':
			case 'g':
				return (BPEntryType.FLOAT);
			case 's':
				return (BPEntryType.STRING);
			case 'R':
			case 'I':
			case 'G':
			case 'U':
			case 'H':
				return (BPEntryType.ARRAY);
			case 'J':
				return (BPEntryType.JSON);
			default:
				return (BPEntryType.UNEXPECTED);
		}
	}

	private validate(e: BPEntry) {

		switch (this.entryType(e.type)) {
			case BPEntryType.NUMBER:
				// Be nice and try to convert to a number
				if (typeof e.value !== 'number') {
					const num = Number(e.value);
					if (Number.isNaN(num))
						throw new TypeError('Expected number but got ' + typeof e.value);
					e.value = num;
				}
				// Make sure it's a whole integer
				e.value = Math.round(e.value);
				return;
			case BPEntryType.FLOAT:
				// Be nice and try to convert to a number
				if (typeof e.value !== 'number') {
					const num = Number(e.value);
					if (Number.isNaN(num))
						throw new TypeError('Expected number but got ' + typeof e.value);
					e.value = num;
				}
				return;
			case BPEntryType.STRING:
				if (typeof e.value == 'string')
					return;
				throw new TypeError('Expected string but got ' + typeof e.value);
			case BPEntryType.JSON:
				if (typeof e.value === 'object')
					return;
				throw new TypeError('Expected object but got ' + typeof e.value);
			case BPEntryType.ARRAY:
				if (Array.isArray(e.value))
					return;
				throw new TypeError('Expected an Array but got ' + typeof e.value);
			default:
				throw new Error('Invalid entry type: ' + e.type);
		}
	}

	set(val: BPEntry) {

		this.validate(val);

		if (val.name in this.entries)
			throw new Error('value [' + val.name + '] already set');

		const e: BPJsonEntry = {
			t: val.type,
			v: val.value
		};

		this.entries[val.name] = e;
		this.entryCount++;
	}

	remove(name: string) {
		const entry: BPJsonEntry = {
			t: name,
			v: ""
		}
		if (!(entry.t in this.entries))
			return;

		const filteredEntries: Record<string, BPJsonEntry> = {};
		for (const e in this.entries) {
			if (e !== entry.t) {
				filteredEntries[e] = this.entries[e];
			}
		}
		this.entries = filteredEntries;
		this.entryCount--;
	}

	add(val: BPEntry) {

		val.name = this.entryCount.toString();
		this.set(val);
	}

	getJsonOut(ch: Channel) {

		if (this.vpkt == undefined)
			throw new Error('vpkt not set!');

		const bp: BPJsonOut = {
			v: this.version,
			s: this.source,
			f: this.flags,
			p: this.vpkt,
			I: ch.parent.id,
			X: ch.uniqueIndex,
			c: this.entryCount,
			e: this.entries
		}
		return bp;
	}

	async send(ch: Channel | undefined, vpkt: BP, callBridgeInput = true): Promise<string | void> {

		if (!ch || !ch.isopen)
			throw new PhidgetError(ErrorCode.NOT_ATTACHED);

		this.vpkt = vpkt;

		const response = await ch.send(this);

		if (callBridgeInput)
			ch.bridgeInput(this);

		return (response);
	}

	// NOTE: this is only for incoming network BridgePackets
	deliver() {

		if (!this.ch)
			throw new PhidgetError(ErrorCode.UNEXPECTED, 'Bridge packet missing channel');

		if (this.ch.isopen === false)
			return; /* this event was delivered, but we are not open to receive it */

		this.ch.bridgeInput(this);
	}

	// NOTE: this is for local device->channel packets
	sendToChannel(ch: Channel, vpkt: BP) {

		this.vpkt = vpkt;
		// We got a packet from a device that isn't an error - reset the error event state
		ch.clearErrorEvent();
		ch.bridgeInput(this);
	}

	getNumber(name: string | number): number {
		if (!(name in this.entries))
			throw new Error(`BP entry '${name}' does not exist.`);

		const type = this.entryType(this.entries[name].t);
		if (type != BPEntryType.FLOAT && type != BPEntryType.NUMBER)
			throw new Error(`BP entry '${name}' is not a number.`);

		if (typeof this.entries[name].v !== 'number')
			throw new Error(`BP entry '${name}' has an unexpected value!`);

		return (this.entries[name].v as number);
	}
	getArray(name: string | number): number[] {
		if (!(name in this.entries))
			throw new Error(`BP entry '${name}' does not exist.`);

		const type = this.entryType(this.entries[name].t);
		if (type != BPEntryType.ARRAY)
			throw new Error(`BP entry '${name}' is not an array.`);

		if (!Array.isArray(this.entries[name].v))
			throw new Error(`BP entry '${name}' has an unexpected value!`);

		return (this.entries[name].v as number[]);
	}
	getObject(name: string | number): Record<string, unknown> {
		if (!(name in this.entries))
			throw new Error(`BP entry '${name}' does not exist.`);

		const type = this.entryType(this.entries[name].t);
		if (type != BPEntryType.JSON)
			throw new Error(`BP entry '${name}' is not an object.`);

		if (typeof this.entries[name].v !== 'object')
			throw new Error(`BP entry '${name}' has an unexpected value!`);

		return (this.entries[name].v as Record<string, unknown>);
	}
	getString(name: string | number): string {
		if (!(name in this.entries))
			throw new Error(`BP entry '${name}' does not exist.`);

		const type = this.entryType(this.entries[name].t);
		if (type != BPEntryType.STRING)
			throw new Error(`BP entry '${name}' is not a string.`);

		if (this.entries[name].v === null)
			throw new Error(`BP entry '${name}' is null.`);

		if (typeof this.entries[name].v !== 'string')
			throw new Error(`BP entry '${name}' has an unexpected value!`);

		return (this.entries[name].v as string);
	}
	getBoolean(name: string | number): boolean | PUNK.BOOL {
		if (!(name in this.entries))
			throw new Error(`BP entry '${name}' does not exist.`);

		const type = this.entryType(this.entries[name].t);
		if (type != BPEntryType.NUMBER)
			throw new Error(`BP entry '${name}' is not a boolean.`);

		if (typeof this.entries[name].v !== 'number')
			throw new Error(`BP entry '${name}' has an unexpected value!`);

		if (this.entries[name].v === 0)
			return false;

		if (this.entries[name].v === 1)
			return true;

		if (this.entries[name].v === PUNK.BOOL)
			return PUNK.BOOL;

		throw new Error(`BP entry '${name}' has an unexpected value!`);
	}
}

export { BridgePacket };