/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PhidgetChannel } from '../Phidget';
import { Channel } from '../Channel';
import { ErrorCode, ChannelClass } from '../Enumerations.gen';
import * as Enum from '../Enumerations.gen';
import * as SEnum from '../SupportedEnum.gen';
import { PhidgetError } from '../PhidgetError';
import { BridgePacket, PUNK } from '../BridgePacket';
import { BP } from '../BridgePackets.gen';

/** @internal */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RFIDData {
	lastTagString: string | null,
	lastTagProtocol: Enum.RFIDProtocol | PUNK.ENUM, 
	antennaEnabled: number,
	tagPresent: number,
}

abstract class RFIDBase extends PhidgetChannel {
	/** @internal */
	data: RFIDData;
	/**
	 * **Tag** event
	 *  * `tag` - Data from the tag
	 *  * `protocol` - Communication protocol of the tag
	 * ---
	 * Occurs when an RFID tag is read.
	 */
	onTag: ((tag: string, protocol: Enum.RFIDProtocol) => void) | null = null;
	/** @internal */
	_gotTagErrorEvent?: boolean;
	/**
	 * **TagLost** event
	 *  * `tag` - Data from the lost tag
	 *  * `protocol` - Communication protocol of the lost tag
	 * ---
	 * Occurs when an RFID tag that was being read is no longer seen by the reader. Typically this indicates the tag has been removed from the read range, though it could also happen due to interference from multiple tags entering the read range at the same time.
	 */
	onTagLost: ((tag: string, protocol: Enum.RFIDProtocol) => void) | null = null;
	/** @internal */
	_gotTagLostErrorEvent?: boolean;

	/**
	 * The RFID class provides methods for Phidget RFID boards to read and write (if writing is supported) to RFID tags.
	 * @public
	 */
	constructor();
	/** @internal */
	constructor(ch?: Channel);
	constructor(ch?: Channel) {
		super(ch);
		this._class = ChannelClass.RFID;
		this.name = "RFID";
		this.data = this._initData();
	}

	/** @internal */
	_bridgeInput(bp: BridgePacket) {

		switch(bp.vpkt) {
		case BP.SETANTENNAON:
			this.data.antennaEnabled = bp.entries[0].v as number;
			this._FIREPropertyChange('AntennaEnabled', bp);
			break;
		case BP.WRITE:
			break;
		default:
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			throw new PhidgetError(ErrorCode.INVALID_PACKET, "Unsupported bridge packet: 0x" + bp.vpkt!.toString(16));
		}
	}

	/** @internal */
	_initData(): RFIDData {
		return {
			lastTagString: null,
			lastTagProtocol: 0, 
			antennaEnabled: PUNK.BOOL,
			tagPresent: PUNK.BOOL,
		}
	}

	/** @internal */
	_initAfterOpen() {
		// This should never be called as no USB Phidgets that use this calls are supported
		throw new PhidgetError(ErrorCode.UNEXPECTED);
	}

	/** @internal */
	// eslint-disable-next-line require-await
	async _setDefaults() {
		// This should never be called as no USB Phidgets that use this calls are supported
		throw new PhidgetError(ErrorCode.UNEXPECTED);
	}

	/** @internal */
	_hasInitialState() {


		return true;
	}

	/** @internal */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	_fireInitialEvents() {

	}

	/**
	 * The on/off state of the antenna.
	 * 
	 * *   You can turn the antenna off to save power.
	 * *   You must turn the antenna on in order to detect and read RFID tags.
	 * @throws {@link PhidgetError}
	 */
	get antennaEnabled() { return this.getAntennaEnabled(); }
	/**
	 * This property is true if a compatibile RFID tag is being read by the reader.
	 * 
	 * *   `TagPresent` will remain true until the tag is out of range and can no longer be read.
	 * @throws {@link PhidgetError}
	 */
	get tagPresent() { return this.getTagPresent(); }

	/**
	 * The on/off state of the antenna.
	 * 
	 * *   You can turn the antenna off to save power.
	 * *   You must turn the antenna on in order to detect and read RFID tags.
	 * @returns The state of the antenna
	 * @throws {@link PhidgetError}
	 */
	getAntennaEnabled(): boolean {
		this._assertOpen();

		if (this.data.antennaEnabled === PUNK.BOOL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (!!this.data.antennaEnabled);
	}

	/**
	 * The on/off state of the antenna.
	 * 
	 * *   You can turn the antenna off to save power.
	 * *   You must turn the antenna on in order to detect and read RFID tags.
	 * @throws {@link PhidgetError}
	 * @param antennaEnabled - The state of the antenna
	 */
	async setAntennaEnabled(antennaEnabled: boolean): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (antennaEnabled !== false && antennaEnabled !== true)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be a boolean.");

		bp.set({ name: "0", type: "d", value: (antennaEnabled ? 1 : 0) });
		await bp.send(this._ch, BP.SETANTENNAON);
	}

	/**
	 * Gets the most recently read tag's data, even if that tag is no longer within read range.
	 * 
	 * *   Only valid after at least one tag has been read.
	 * @returns
	 * 	- tagString: The data stored on the most recently read tag
	 * 	- protocol: Protocol of the most recently read tag
	 * @throws {@link PhidgetError}
	 */
	abstract getLastTag(): {tagString: string, protocol: Enum.RFIDProtocol};
	/**
	 * This property is true if a compatibile RFID tag is being read by the reader.
	 * 
	 * *   `TagPresent` will remain true until the tag is out of range and can no longer be read.
	 * @returns Tag is in range
	 * @throws {@link PhidgetError}
	 */
	getTagPresent(): boolean {
		this._assertOpen();

		if (this.data.tagPresent === PUNK.BOOL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (!!this.data.tagPresent);
	}

	/**
	 * Writes data to the tag being currently read by the reader.
	 * 
	 * *   You cannot write to a read-only or locked tag.
	 * @throws {@link PhidgetError}
	 * @param tagString - The data to write to the tag
	 * @param protocol - The communication protocol to use
	 * @param lockTag - If true, permanently locks the tag so that it cannot be re-written after this write.
	 */
	async write(tagString: string, protocol: Enum.RFIDProtocol, lockTag: boolean): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "s", value: tagString });

		if (!SEnum.supportedRFIDProtocol(this._ch!, protocol))
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Specified RFIDProtocol is unsupported by this device.");

		bp.set({ name: "1", type: "d", value: protocol });

		if (lockTag !== false && lockTag !== true)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be a boolean.");

		bp.set({ name: "2", type: "d", value: (lockTag ? 1 : 0) });
		await bp.send(this._ch, BP.WRITE);
	}

}
export { RFIDBase };
