import { Channel } from './Channel';
import { DeviceChannelUID } from './Devices.gen';
import * as Enum from './Enumerations.gen';
/** @internal */
export function supportedEncoderIOMode(ch: Channel, val: number) {

	if (val < Enum.EncoderIOMode.PUSH_PULL || val > Enum.EncoderIOMode.OPEN_COLLECTOR_10K)
		return false;

	switch(val) {
		case Enum.EncoderIOMode.PUSH_PULL:
		case Enum.EncoderIOMode.LINE_DRIVER_2K2:
		case Enum.EncoderIOMode.LINE_DRIVER_10K:
		case Enum.EncoderIOMode.OPEN_COLLECTOR_2K2:
		case Enum.EncoderIOMode.OPEN_COLLECTOR_10K:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._DCC1000_ENCODER_100:
				case DeviceChannelUID._DCC1000_ENCODER_200:
				case DeviceChannelUID._DCC1000_ENCODER_210:
				case DeviceChannelUID._DCC1000_MOTORPOSITIONCONTROLLER_200:
				case DeviceChannelUID._DCC1000_MOTORPOSITIONCONTROLLER_210:
				case DeviceChannelUID._ENC1000_ENCODER_100:
				case DeviceChannelUID._1057_ENCODER_400:
					return true;
				default:
					return false;
			}
		default:
			return true;
	}
}

/** @internal */
export function supportedErrorCode(_ch: Channel, val: number) {

	if (val < Enum.ErrorCode.SUCCESS || val > Enum.ErrorCode.BADCURRENT)
		return false;

	return true;
}

/** @internal */
export function supportedErrorEventCode(_ch: Channel, val: number) {

	if (val < Enum.ErrorEventCode.BAD_VERSION || val > Enum.ErrorEventCode.ESTOP)
		return false;

	return true;
}

/** @internal */
export function supportedDeviceID(ch: Channel, val: number) {

	if (val < Enum.DeviceID.NONE || val > Enum.DeviceID.PN_ENC1001)
		return false;

	switch(val) {
		case Enum.DeviceID.UNKNOWN:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._USB_UNKNOWN:
				case DeviceChannelUID._VINT_UNKNOWN:
				case DeviceChannelUID._SPI_UNKNOWN:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.DIGITAL_INPUT_PORT:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._HUB_DIGITALINPUT_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.DIGITAL_OUTPUT_PORT:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._HUB_DIGITALOUTPUT_100:
				case DeviceChannelUID._HUB_DIGITALOUTPUT_110:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.VOLTAGE_INPUT_PORT:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._HUB_VOLTAGEINPUT_100:
				case DeviceChannelUID._HUB_VOLTAGEINPUT_110:
				case DeviceChannelUID._HUB_VOLTAGEINPUT_200:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.VOLTAGE_RATIO_INPUT_PORT:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._HUB_VOLTAGERATIOINPUT_100:
				case DeviceChannelUID._HUB_VOLTAGERATIOINPUT_200:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_DICTIONARY:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._DICTIONARY:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1000:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1000_RCSERVO_OLD1_200:
				case DeviceChannelUID._1000_RCSERVO_OLD2_200:
				case DeviceChannelUID._1000_RCSERVO_300:
				case DeviceChannelUID._1000_RCSERVO_313:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1001:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1001_RCSERVO_OLD1_200:
				case DeviceChannelUID._1001_RCSERVO_OLD2_200:
				case DeviceChannelUID._1001_RCSERVO_313:
				case DeviceChannelUID._1001_RCSERVO_400:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1002:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1002_VOLTAGEOUTPUT_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1008:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1008_ACCELEROMETER_000:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1010_1013_1018_1019:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1013_VOLTAGEINPUT_000:
				case DeviceChannelUID._1013_VOLTAGERATIOINPUT_000:
				case DeviceChannelUID._1013_DIGITALINPUT_000:
				case DeviceChannelUID._1013_DIGITALOUTPUT_000:
				case DeviceChannelUID._1018_VOLTAGEINPUT_821:
				case DeviceChannelUID._1018_VOLTAGERATIOINPUT_821:
				case DeviceChannelUID._1018_DIGITALINPUT_821:
				case DeviceChannelUID._1018_DIGITALOUTPUT_821:
				case DeviceChannelUID._1018_VOLTAGEINPUT_900:
				case DeviceChannelUID._1018_VOLTAGERATIOINPUT_900:
				case DeviceChannelUID._1018_DIGITALINPUT_900:
				case DeviceChannelUID._1018_DIGITALOUTPUT_900:
				case DeviceChannelUID._1018_VOLTAGEINPUT_1000:
				case DeviceChannelUID._1018_VOLTAGERATIOINPUT_1000:
				case DeviceChannelUID._1018_DIGITALINPUT_1000:
				case DeviceChannelUID._1018_DIGITALOUTPUT_1000:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1011:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1011_VOLTAGEINPUT_000:
				case DeviceChannelUID._1011_VOLTAGERATIOINPUT_000:
				case DeviceChannelUID._1011_DIGITALINPUT_000:
				case DeviceChannelUID._1011_DIGITALOUTPUT_000:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1012:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1012_DIGITALINPUT_000:
				case DeviceChannelUID._1012_DIGITALOUTPUT_000:
				case DeviceChannelUID._1012_DIGITALINPUT_601:
				case DeviceChannelUID._1012_DIGITALOUTPUT_601:
				case DeviceChannelUID._1012_DIGITALINPUT_602:
				case DeviceChannelUID._1012_DIGITALOUTPUT_602:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1014:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1014_DIGITALOUTPUT_000:
				case DeviceChannelUID._1014_DIGITALOUTPUT_704:
				case DeviceChannelUID._1014_DIGITALOUTPUT_800_USB:
				case DeviceChannelUID._1014_DIGITALOUTPUT_800_VINT:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1015:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1015_CAPACITIVETOUCH_000:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1016:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1016_CAPACITIVETOUCH_000:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1017:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1017_DIGITALOUTPUT_000:
				case DeviceChannelUID._1017_DIGITALOUTPUT_200_USB:
				case DeviceChannelUID._1017_DIGITALOUTPUT_200_VINT:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1023:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1023_RFID_000:
				case DeviceChannelUID._1023_RFID_104:
				case DeviceChannelUID._1023_RFID_200:
				case DeviceChannelUID._1023_DIGITALOUTPUT_5V_200:
				case DeviceChannelUID._1023_DIGITALOUTPUT_LED_200:
				case DeviceChannelUID._1023_DIGITALOUTPUT_ONBOARD_LED_200:
				case DeviceChannelUID._1023_RFID_201:
				case DeviceChannelUID._1023_DIGITALOUTPUT_5V_201:
				case DeviceChannelUID._1023_DIGITALOUTPUT_LED_201:
				case DeviceChannelUID._1023_DIGITALOUTPUT_ONBOARD_LED_201:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1024:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1024_RFID_100:
				case DeviceChannelUID._1024_DIGITALOUTPUT_5V_100:
				case DeviceChannelUID._1024_DIGITALOUTPUT_LED_100:
				case DeviceChannelUID._1024_DIGITALOUTPUT_ONBOARD_LED_100:
				case DeviceChannelUID._1024_V2_USB_OBSOLETE_200:
				case DeviceChannelUID._1024_V2_VINT_OBSOLETE_200:
				case DeviceChannelUID._1024_RFID_200_USB:
				case DeviceChannelUID._1024_DIGITALOUTPUT_5V_200_USB:
				case DeviceChannelUID._1024_DIGITALOUTPUT_LED_200_USB:
				case DeviceChannelUID._1024_DIGITALOUTPUT_ONBOARD_LED_200_USB:
				case DeviceChannelUID._1024_RFID_200_VINT:
				case DeviceChannelUID._1024_DIGITALOUTPUT_5V_200_VINT:
				case DeviceChannelUID._1024_DIGITALOUTPUT_LED_200_VINT:
				case DeviceChannelUID._1024_DIGITALOUTPUT_ONBOARD_LED_200_VINT:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1030:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1030_DIGITALOUTPUT_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1031:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1031_DIGITALOUTPUT_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1032:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1032_DIGITALOUTPUT_200:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1040:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1040_GPS_000:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1041:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1041_ACCELEROMETER_200:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1042:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1042_ACCELEROMETER_300:
				case DeviceChannelUID._1042_GYROSCOPE_300:
				case DeviceChannelUID._1042_MAGNETOMETER_300:
				case DeviceChannelUID._1042_SPATIAL_300:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1043:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1043_ACCELEROMETER_300:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1044:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1044_ACCELEROMETER_400:
				case DeviceChannelUID._1044_GYROSCOPE_400:
				case DeviceChannelUID._1044_MAGNETOMETER_400:
				case DeviceChannelUID._1044_SPATIAL_400:
				case DeviceChannelUID._1044_ACCELEROMETER_500:
				case DeviceChannelUID._1044_GYROSCOPE_500:
				case DeviceChannelUID._1044_MAGNETOMETER_500:
				case DeviceChannelUID._1044_SPATIAL_500:
				case DeviceChannelUID._1044_ACCELEROMETER_510:
				case DeviceChannelUID._1044_GYROSCOPE_510:
				case DeviceChannelUID._1044_MAGNETOMETER_510:
				case DeviceChannelUID._1044_SPATIAL_510:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1045:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1045_TEMPERATURESENSOR_IR_100:
				case DeviceChannelUID._1045_TEMPERATURESENSOR_IC_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1046:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1046_VOLTAGERATIOINPUT_100:
				case DeviceChannelUID._1046_VOLTAGERATIOINPUT_102:
				case DeviceChannelUID._1046_VOLTAGERATIOINPUT_200:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1047:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1047_ENCODER_100:
				case DeviceChannelUID._1047_DIGITALINPUT_100:
				case DeviceChannelUID._1047_ENCODER_200:
				case DeviceChannelUID._1047_DIGITALINPUT_200:
				case DeviceChannelUID._1047_ENCODER_300:
				case DeviceChannelUID._1047_DIGITALINPUT_300:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1048:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1048_TEMPERATURESENSOR_THERMOCOUPLE_100:
				case DeviceChannelUID._1048_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._1048_VOLTAGEINPUT_100:
				case DeviceChannelUID._1048_TEMPERATURESENSOR_THERMOCOUPLE_200:
				case DeviceChannelUID._1048_TEMPERATURESENSOR_IC_200:
				case DeviceChannelUID._1048_VOLTAGEINPUT_200:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1049:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1049_ACCELEROMETER_000:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1051:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1051_TEMPERATURESENSOR_THERMOCOUPLE_000:
				case DeviceChannelUID._1051_TEMPERATURESENSOR_IC_000:
				case DeviceChannelUID._1051_TEMPERATURESENSOR_THERMOCOUPLE_200:
				case DeviceChannelUID._1051_TEMPERATURESENSOR_IC_200:
				case DeviceChannelUID._1051_VOLTAGEINPUT_200:
				case DeviceChannelUID._1051_TEMPERATURESENSOR_THERMOCOUPLE_300:
				case DeviceChannelUID._1051_TEMPERATURESENSOR_IC_300:
				case DeviceChannelUID._1051_VOLTAGEINPUT_300:
				case DeviceChannelUID._1051_TEMPERATURESENSOR_THERMOCOUPLE_400:
				case DeviceChannelUID._1051_TEMPERATURESENSOR_IC_400:
				case DeviceChannelUID._1051_VOLTAGEINPUT_400:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1052:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1052_ENCODER_000:
				case DeviceChannelUID._1052_DIGITALINPUT_000:
				case DeviceChannelUID._1052_ENCODER_101:
				case DeviceChannelUID._1052_DIGITALINPUT_101:
				case DeviceChannelUID._1052_ENCODER_110:
				case DeviceChannelUID._1052_DIGITALINPUT_110:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1053:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1053_ACCELEROMETER_300:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1054:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1054_FREQUENCYCOUNTER_000:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1055:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1055_IR_100:
				case DeviceChannelUID._1055_IR_200_USB:
				case DeviceChannelUID._1055_IR_200_VINT:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1056:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1056_ACCELEROMETER_000:
				case DeviceChannelUID._1056_GYROSCOPE_000:
				case DeviceChannelUID._1056_MAGNETOMETER_000:
				case DeviceChannelUID._1056_SPATIAL_000:
				case DeviceChannelUID._1056_ACCELEROMETER_200:
				case DeviceChannelUID._1056_GYROSCOPE_200:
				case DeviceChannelUID._1056_MAGNETOMETER_200:
				case DeviceChannelUID._1056_SPATIAL_200:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1057:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1057_ENCODER_300:
				case DeviceChannelUID._1057_ENCODER_400:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1058:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1058_VOLTAGEINPUT_100:
				case DeviceChannelUID._1058_PHADAPTER_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1059:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1059_ACCELEROMETER_400:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1060:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1060_DCMOTOR_100:
				case DeviceChannelUID._1060_DIGITALINPUT_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1061:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1061_RCSERVO_100:
				case DeviceChannelUID._1061_CURRENTINPUT_100:
				case DeviceChannelUID._1061_RCSERVO_200:
				case DeviceChannelUID._1061_CURRENTINPUT_200:
				case DeviceChannelUID._1061_RCSERVO_300:
				case DeviceChannelUID._1061_CURRENTINPUT_300:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1062:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1062_STEPPER_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1063:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1063_STEPPER_100:
				case DeviceChannelUID._1063_DIGITALINPUT_100:
				case DeviceChannelUID._1063_CURRENTINPUT_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1064:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1064_DCMOTOR_100:
				case DeviceChannelUID._1064_CURRENTINPUT_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1065:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1065_DCMOTOR_100:
				case DeviceChannelUID._1065_DIGITALINPUT_100:
				case DeviceChannelUID._1065_ENCODER_100:
				case DeviceChannelUID._1065_VOLTAGEINPUT_100:
				case DeviceChannelUID._1065_VOLTAGEINPUT_SUPPLY_100:
				case DeviceChannelUID._1065_VOLTAGERATIOINPUT_100:
				case DeviceChannelUID._1065_CURRENTINPUT_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1066:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1066_RCSERVO_100:
				case DeviceChannelUID._1066_CURRENTINPUT_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1067:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1067_STEPPER_200:
				case DeviceChannelUID._1067_STEPPER_300:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1202_1203:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1202_VOLTAGEINPUT_000:
				case DeviceChannelUID._1202_VOLTAGERATIOINPUT_000:
				case DeviceChannelUID._1202_DIGITALINPUT_000:
				case DeviceChannelUID._1202_DIGITALOUTPUT_000:
				case DeviceChannelUID._1202_VOLTAGEINPUT_120:
				case DeviceChannelUID._1202_VOLTAGERATIOINPUT_120:
				case DeviceChannelUID._1202_DIGITALINPUT_120:
				case DeviceChannelUID._1202_DIGITALOUTPUT_120:
				case DeviceChannelUID._1202_TEXTLCD_000:
				case DeviceChannelUID._1202_VOLTAGEINPUT_300:
				case DeviceChannelUID._1202_VOLTAGERATIOINPUT_300:
				case DeviceChannelUID._1202_DIGITALINPUT_300:
				case DeviceChannelUID._1202_DIGITALOUTPUT_300:
				case DeviceChannelUID._1202_TEXTLCD_200:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1204:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1204_TEXTLCD_000:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1215__1218:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1215_TEXTLCD_000:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_1219__1222:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1219_TEXTLCD_000:
				case DeviceChannelUID._1219_DIGITALINPUT_000:
				case DeviceChannelUID._1219_DIGITALOUTPUT_000:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_ADP1000:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._ADP1000_PHSENSOR_100:
				case DeviceChannelUID._ADP1000_VOLTAGEINPUT_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_DAQ1000:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._DAQ1000_VOLTAGERATIOINPUT_100:
				case DeviceChannelUID._DAQ1000_VOLTAGEINPUT_100:
				case DeviceChannelUID._DAQ1000_VOLTAGERATIOINPUT_110:
				case DeviceChannelUID._DAQ1000_VOLTAGEINPUT_110:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_DAQ1200:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._DAQ1200_DIGITALINPUT_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_DAQ1300:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._DAQ1300_DIGITALINPUT_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_DAQ1301:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._DAQ1301_DIGITALINPUT_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_DAQ1400:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._DAQ1400_VOLTAGEINPUT_100:
				case DeviceChannelUID._DAQ1400_CURRENTINPUT_100:
				case DeviceChannelUID._DAQ1400_DIGITALINPUT_100:
				case DeviceChannelUID._DAQ1400_FREQUENCYCOUNTER_100:
				case DeviceChannelUID._DAQ1400_VOLTAGEINPUT_120:
				case DeviceChannelUID._DAQ1400_CURRENTINPUT_120:
				case DeviceChannelUID._DAQ1400_DIGITALINPUT_120:
				case DeviceChannelUID._DAQ1400_FREQUENCYCOUNTER_120:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_DAQ1500:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._DAQ1500_VOLTAGERATIOINPUT_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_DCC1000:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._DCC1000_DCMOTOR_100:
				case DeviceChannelUID._DCC1000_ENCODER_100:
				case DeviceChannelUID._DCC1000_VOLTAGERATIOINPUT_100:
				case DeviceChannelUID._DCC1000_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._DCC1000_CURRENTINPUT_100:
				case DeviceChannelUID._DCC1000_DCMOTOR_200:
				case DeviceChannelUID._DCC1000_ENCODER_200:
				case DeviceChannelUID._DCC1000_VOLTAGERATIOINPUT_200:
				case DeviceChannelUID._DCC1000_TEMPERATURESENSOR_IC_200:
				case DeviceChannelUID._DCC1000_CURRENTINPUT_200:
				case DeviceChannelUID._DCC1000_MOTORPOSITIONCONTROLLER_200:
				case DeviceChannelUID._DCC1000_DCMOTOR_210:
				case DeviceChannelUID._DCC1000_ENCODER_210:
				case DeviceChannelUID._DCC1000_VOLTAGERATIOINPUT_210:
				case DeviceChannelUID._DCC1000_TEMPERATURESENSOR_IC_210:
				case DeviceChannelUID._DCC1000_CURRENTINPUT_210:
				case DeviceChannelUID._DCC1000_MOTORPOSITIONCONTROLLER_210:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_DCC1001:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._DCC1001_DCMOTOR_100:
				case DeviceChannelUID._DCC1001_ENCODER_100:
				case DeviceChannelUID._DCC1001_MOTORPOSITIONCONTROLLER_100:
				case DeviceChannelUID._DCC1001_DCMOTOR_120:
				case DeviceChannelUID._DCC1001_ENCODER_120:
				case DeviceChannelUID._DCC1001_MOTORPOSITIONCONTROLLER_120:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_DCC1002:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._DCC1002_DCMOTOR_100:
				case DeviceChannelUID._DCC1002_ENCODER_100:
				case DeviceChannelUID._DCC1002_MOTORPOSITIONCONTROLLER_100:
				case DeviceChannelUID._DCC1002_DCMOTOR_110:
				case DeviceChannelUID._DCC1002_ENCODER_110:
				case DeviceChannelUID._DCC1002_MOTORPOSITIONCONTROLLER_110:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_DCC1003:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._DCC1003_DCMOTOR_100:
				case DeviceChannelUID._DCC1003_DCMOTOR_110:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_DCC1100:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._DCC1100_BLDCMOTOR_100:
				case DeviceChannelUID._DCC1100_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._DCC1100_MOTORPOSITIONCONTROLLER_100:
				case DeviceChannelUID._DCC1100_BLDCMOTOR_120:
				case DeviceChannelUID._DCC1100_TEMPERATURESENSOR_IC_120:
				case DeviceChannelUID._DCC1100_MOTORPOSITIONCONTROLLER_120:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_DST1000:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._DST1000_DISTANCESENSOR_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_DST1001:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._DST1001_DISTANCESENSOR_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_DST1002:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._DST1002_DISTANCESENSOR_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_DST1200:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._DST1200_DISTANCESENSOR_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_ENC1000:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._ENC1000_ENCODER_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_ENC1001:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._ENC1001_ENCODER_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.FIRMWARE_UPGRADE_SPI:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._M3_SPI_FIRMWARE_UPGRADE_000:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.FIRMWARE_UPGRADE_STM32F0:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._STM32F0_FIRMWARE_UPGRADE_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.FIRMWARE_UPGRADE_STM32F3:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._STM32F3_FIRMWARE_UPGRADE_120:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.FIRMWARE_UPGRADE_STM32G0:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._STM32G0_FIRMWARE_UPGRADE_110:
				case DeviceChannelUID._STM32G0_FIRMWARE_UPGRADE_114:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.FIRMWARE_UPGRADE_STM8S:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._STM8S_FIRMWARE_UPGRADE_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.FIRMWARE_UPGRADE_USB:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._M3_USB_FIRMWARE_UPGRADE_000:
				case DeviceChannelUID._STM32_USB_FIRMWARE_UPGRADE_100:
				case DeviceChannelUID._STM32_USB_FIRMWARE_UPGRADE_200:
				case DeviceChannelUID._STM32_USB_FIRMWARE_UPGRADE_300:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_HIN1000:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._HIN1000_CAPACITIVETOUCH_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_HIN1001:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._HIN1001_CAPACITIVETOUCH_BUTTONS_100:
				case DeviceChannelUID._HIN1001_CAPACITIVETOUCH_WHEEL_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_HIN1100:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._HIN1100_VOLTAGERATIOINPUT_100:
				case DeviceChannelUID._HIN1100_DIGITALINPUT_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_HIN1101:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._HIN1101_ENCODER_100:
				case DeviceChannelUID._HIN1101_DIGITALINPUT_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_HUB0000:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._HUB0000_HUB_100:
				case DeviceChannelUID._HUB0000_HUB_300:
				case DeviceChannelUID._HUB0000_HUB_400:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_HUB0001:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._HUB0001_HUB_100:
				case DeviceChannelUID._HUB0001_HUB_115:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_HUB0002:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._HUB0002_HUB_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_HUB0004:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._HUB0004_HUB_100:
				case DeviceChannelUID._HUB0004_HUB_200:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_HUB0007:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._HUB0007_HUB_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_HUB5000:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._HUB5000_HUB_100:
				case DeviceChannelUID._HUB5000_HUB_200:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_HUM1000:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._HUM1000_HUMIDITYSENSOR_100:
				case DeviceChannelUID._HUM1000_TEMPERATURESENSOR_IC_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_HUM1001:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._HUM1001_HUMIDITYSENSOR_100:
				case DeviceChannelUID._HUM1001_TEMPERATURESENSOR_IC_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_HUM1100:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._HUM1100_VOLTAGERATIOINPUT_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_INTERFACE_KIT488:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._ifkit488_VOLTAGERATIOINPUT_000:
				case DeviceChannelUID._ifkit488_DIGITALINPUT_000:
				case DeviceChannelUID._ifkit488_DIGITALOUTPUT_000:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_LCD1100:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._LCD1100_LCD_100:
				case DeviceChannelUID._LCD1100_LCD_200:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_LED1000:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._LED1000_DIGITALOUTPUT_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_LUX1000:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._LUX1000_LIGHTSENSOR_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_MOT0100:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._MOT0100_ACCELEROMETER_100_USB:
				case DeviceChannelUID._MOT0100_ACCELEROMETER_100_VINT:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_MOT0109:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._MOT0109_ACCELEROMETER_100:
				case DeviceChannelUID._MOT0109_GYROSCOPE_100:
				case DeviceChannelUID._MOT0109_MAGNETOMETER_100:
				case DeviceChannelUID._MOT0109_SPATIAL_100:
				case DeviceChannelUID._MOT0109_TEMPERATURESENSOR_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_MOT0110:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._MOT0110_ACCELEROMETER_100_USB:
				case DeviceChannelUID._MOT0110_GYROSCOPE_100_USB:
				case DeviceChannelUID._MOT0110_MAGNETOMETER_100_USB:
				case DeviceChannelUID._MOT0110_SPATIAL_100_USB:
				case DeviceChannelUID._MOT0110_TEMPERATURESENSOR_100_USB:
				case DeviceChannelUID._MOT0110_ACCELEROMETER_100_VINT:
				case DeviceChannelUID._MOT0110_GYROSCOPE_100_VINT:
				case DeviceChannelUID._MOT0110_MAGNETOMETER_100_VINT:
				case DeviceChannelUID._MOT0110_SPATIAL_100_VINT:
				case DeviceChannelUID._MOT0110_TEMPERATURESENSOR_100_VINT:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_MOT1100:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._MOT1100_ACCELEROMETER_200:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_MOT1101:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._MOT1101_ACCELEROMETER_100:
				case DeviceChannelUID._MOT1101_GYROSCOPE_100:
				case DeviceChannelUID._MOT1101_MAGNETOMETER_100:
				case DeviceChannelUID._MOT1101_SPATIAL_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_MOT1102:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._MOT1102_ACCELEROMETER_200:
				case DeviceChannelUID._MOT1102_GYROSCOPE_200:
				case DeviceChannelUID._MOT1102_MAGNETOMETER_200:
				case DeviceChannelUID._MOT1102_SPATIAL_200:
				case DeviceChannelUID._MOT1102_ACCELEROMETER_300:
				case DeviceChannelUID._MOT1102_GYROSCOPE_300:
				case DeviceChannelUID._MOT1102_MAGNETOMETER_300:
				case DeviceChannelUID._MOT1102_SPATIAL_300:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_OUT1000:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._OUT1000_VOLTAGEOUTPUT_100:
				case DeviceChannelUID._OUT1000_VOLTAGEOUTPUT_110:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_OUT1001:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._OUT1001_VOLTAGEOUTPUT_100:
				case DeviceChannelUID._OUT1001_VOLTAGEOUTPUT_110:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_OUT1002:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._OUT1002_VOLTAGEOUTPUT_100:
				case DeviceChannelUID._OUT1002_VOLTAGEOUTPUT_110:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_OUT1100:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._OUT1100_DIGITALOUTPUT_100:
				case DeviceChannelUID._OUT1100_DIGITALOUTPUT_110:
				case DeviceChannelUID._OUT1100_DIGITALOUTPUT_120:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_PRE1000:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._PRE1000_PRESSURESENSOR_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_RCC0004:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._RCC0004_RCSERVO_400:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_RCC1000:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._RCC1000_RCSERVO_100:
				case DeviceChannelUID._RCC1000_RCSERVO_110:
				case DeviceChannelUID._RCC1000_RCSERVO_200:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_REL1000:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._REL1000_DIGITALOUTPUT_100:
				case DeviceChannelUID._REL1000_DIGITALOUTPUT_110:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_REL1100:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._REL1100_DIGITALOUTPUT_100:
				case DeviceChannelUID._REL1100_DIGITALOUTPUT_110:
				case DeviceChannelUID._REL1100_DIGITALOUTPUT_120:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_REL1101:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._REL1101_DIGITALOUTPUT_100:
				case DeviceChannelUID._REL1101_DIGITALOUTPUT_110:
				case DeviceChannelUID._REL1101_DIGITALOUTPUT_FREQ_200:
				case DeviceChannelUID._REL1101_DIGITALOUTPUT_200:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_SAF1000:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._SAF1000_POWERGUARD_100:
				case DeviceChannelUID._SAF1000_VOLTAGEINPUT_100:
				case DeviceChannelUID._SAF1000_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._SAF1000_POWERGUARD_110:
				case DeviceChannelUID._SAF1000_VOLTAGEINPUT_110:
				case DeviceChannelUID._SAF1000_TEMPERATURESENSOR_IC_110:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_SND1000:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._SND1000_SOUNDSENSOR_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_STC1000:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._STC1000_STEPPER_100:
				case DeviceChannelUID._STC1000_STEPPER_110:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_STC1001:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._STC1001_STEPPER_100:
				case DeviceChannelUID._STC1001_STEPPER_110:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_STC1002:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._STC1002_STEPPER_100:
				case DeviceChannelUID._STC1002_STEPPER_110:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_STC1003:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._STC1003_STEPPER_100:
				case DeviceChannelUID._STC1003_STEPPER_110:
				case DeviceChannelUID._STC1003_STEPPER_200:
				case DeviceChannelUID._STC1003_VOLTAGEINPUT_200:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_STC1005:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._STC1005_STEPPER_100:
				case DeviceChannelUID._STC1005_VOLTAGEINPUT_100:
				case DeviceChannelUID._STC1005_STEPPER_110:
				case DeviceChannelUID._STC1005_VOLTAGEINPUT_110:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_TMP1000:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._TMP1000_TEMPERATURESENSOR_IC_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_TMP1100:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._TMP1100_TEMPERATURESENSOR_THERMOCOUPLE_100:
				case DeviceChannelUID._TMP1100_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._TMP1100_VOLTAGEINPUT_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_TMP1101:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._TMP1101_TEMPERATURESENSOR_THERMOCOUPLE_100:
				case DeviceChannelUID._TMP1101_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._TMP1101_VOLTAGEINPUT_100:
				case DeviceChannelUID._TMP1101_TEMPERATURESENSOR_THERMOCOUPLE_200:
				case DeviceChannelUID._TMP1101_TEMPERATURESENSOR_IC_200:
				case DeviceChannelUID._TMP1101_VOLTAGEINPUT_200:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_TMP1200:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._TMP1200_TEMPERATURESENSOR_RTD_100:
				case DeviceChannelUID._TMP1200_RESISTANCEINPUT_100:
				case DeviceChannelUID._TMP1200_TEMPERATURESENSOR_RTD_300:
				case DeviceChannelUID._TMP1200_RESISTANCEINPUT_300:
				case DeviceChannelUID._TMP1200_TEMPERATURESENSOR_RTD_400:
				case DeviceChannelUID._TMP1200_RESISTANCEINPUT_400:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_VCP1000:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._VCP1000_VOLTAGEINPUT_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_VCP1001:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._VCP1001_VOLTAGEINPUT_100:
				case DeviceChannelUID._VCP1001_VOLTAGEINPUT_110:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_VCP1002:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._VCP1002_VOLTAGEINPUT_100:
				case DeviceChannelUID._VCP1002_VOLTAGEINPUT_110:
					return true;
				default:
					return false;
			}
		case Enum.DeviceID.PN_VCP1100:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._VCP1100_CURRENTINPUT_100:
					return true;
				default:
					return false;
			}
		default:
			return true;
	}
}

/** @internal */
export function supportedLogLevel(_ch: Channel, val: number) {

	if (val < Enum.LogLevel.CRITICAL || val > Enum.LogLevel.VERBOSE)
		return false;

	return true;
}

/** @internal */
export function supportedDeviceClass(ch: Channel, val: number) {

	if (val < Enum.DeviceClass.NONE || val > Enum.DeviceClass.DATA_ADAPTER)
		return false;

	switch(val) {
		case Enum.DeviceClass.ACCELEROMETER:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1008_ACCELEROMETER_000:
				case DeviceChannelUID._1053_ACCELEROMETER_300:
				case DeviceChannelUID._1059_ACCELEROMETER_400:
				case DeviceChannelUID._MOT0100_ACCELEROMETER_100_USB:
					return true;
				default:
					return false;
			}
		case Enum.DeviceClass.ADVANCED_SERVO:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1061_RCSERVO_100:
				case DeviceChannelUID._1061_CURRENTINPUT_100:
				case DeviceChannelUID._1061_RCSERVO_200:
				case DeviceChannelUID._1061_CURRENTINPUT_200:
				case DeviceChannelUID._1061_RCSERVO_300:
				case DeviceChannelUID._1061_CURRENTINPUT_300:
				case DeviceChannelUID._RCC0004_RCSERVO_400:
				case DeviceChannelUID._1066_RCSERVO_100:
				case DeviceChannelUID._1066_CURRENTINPUT_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceClass.ANALOG:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1002_VOLTAGEOUTPUT_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceClass.BRIDGE:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1046_VOLTAGERATIOINPUT_100:
				case DeviceChannelUID._1046_VOLTAGERATIOINPUT_102:
				case DeviceChannelUID._1046_VOLTAGERATIOINPUT_200:
					return true;
				default:
					return false;
			}
		case Enum.DeviceClass.DATA_ADAPTER:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._ADP1001_DATAADAPTER_100_USB:
				case DeviceChannelUID._ADP1001_DIGITALINPUT_DSR_100_USB:
				case DeviceChannelUID._ADP1001_DIGITALINPUT_DCD_100_USB:
				case DeviceChannelUID._ADP1001_DIGITALINPUT_RI_100_USB:
				case DeviceChannelUID._ADP1001_DIGITALOUTPUT_DTR_100_USB:
				case DeviceChannelUID._ADP1001_DATAADAPTER_100_VINT:
				case DeviceChannelUID._ADP1001_DIGITALINPUT_DSR_100_VINT:
				case DeviceChannelUID._ADP1001_DIGITALINPUT_DCD_100_VINT:
				case DeviceChannelUID._ADP1001_DIGITALINPUT_RI_100_VINT:
				case DeviceChannelUID._ADP1001_DIGITALOUTPUT_DTR_100_VINT:
				case DeviceChannelUID._ADP_RS485_422_DATAADAPTER_100_USB:
				case DeviceChannelUID._ADP_RS485_422_DATAADAPTER_100_VINT:
				case DeviceChannelUID._ADP_SERIAL_DATAADAPTER_100_USB:
				case DeviceChannelUID._ADP_SERIAL_DIGITALINPUT_100_USB:
				case DeviceChannelUID._ADP_SERIAL_DIGITALOUTPUT_100_USB:
				case DeviceChannelUID._ADP_SERIAL_DATAADAPTER_100_VINT:
				case DeviceChannelUID._ADP_SERIAL_DIGITALINPUT_100_VINT:
				case DeviceChannelUID._ADP_SERIAL_DIGITALOUTPUT_100_VINT:
					return true;
				default:
					return false;
			}
		case Enum.DeviceClass.DICTIONARY:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._DICTIONARY:
					return true;
				default:
					return false;
			}
		case Enum.DeviceClass.ENCODER:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1047_ENCODER_100:
				case DeviceChannelUID._1047_DIGITALINPUT_100:
				case DeviceChannelUID._1047_ENCODER_200:
				case DeviceChannelUID._1047_DIGITALINPUT_200:
				case DeviceChannelUID._1047_ENCODER_300:
				case DeviceChannelUID._1047_DIGITALINPUT_300:
				case DeviceChannelUID._1052_ENCODER_000:
				case DeviceChannelUID._1052_DIGITALINPUT_000:
				case DeviceChannelUID._1052_ENCODER_101:
				case DeviceChannelUID._1052_DIGITALINPUT_101:
				case DeviceChannelUID._1052_ENCODER_110:
				case DeviceChannelUID._1052_DIGITALINPUT_110:
				case DeviceChannelUID._1057_ENCODER_300:
				case DeviceChannelUID._1057_ENCODER_400:
					return true;
				default:
					return false;
			}
		case Enum.DeviceClass.FIRMWARE_UPGRADE:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._M3_USB_FIRMWARE_UPGRADE_000:
				case DeviceChannelUID._STM32_USB_FIRMWARE_UPGRADE_100:
				case DeviceChannelUID._STM32_USB_FIRMWARE_UPGRADE_200:
				case DeviceChannelUID._STM32_USB_FIRMWARE_UPGRADE_300:
				case DeviceChannelUID._M3_SPI_FIRMWARE_UPGRADE_000:
					return true;
				default:
					return false;
			}
		case Enum.DeviceClass.FREQUENCY_COUNTER:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1054_FREQUENCYCOUNTER_000:
					return true;
				default:
					return false;
			}
		case Enum.DeviceClass.GENERIC:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1024_V2_USB_OBSOLETE_200:
				case DeviceChannelUID._HIDUSB_GENERIC:
				case DeviceChannelUID._PHIDUSB_GENERIC:
				case DeviceChannelUID._USB_UNKNOWN:
				case DeviceChannelUID._SPI_UNKNOWN:
					return true;
				default:
					return false;
			}
		case Enum.DeviceClass.GPS:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1040_GPS_000:
					return true;
				default:
					return false;
			}
		case Enum.DeviceClass.HUB:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._HUB0000_HUB_100:
				case DeviceChannelUID._HUB0000_HUB_300:
				case DeviceChannelUID._HUB0000_HUB_400:
				case DeviceChannelUID._HUB0001_HUB_100:
				case DeviceChannelUID._HUB0001_HUB_115:
				case DeviceChannelUID._HUB0002_HUB_100:
				case DeviceChannelUID._HUB0007_HUB_100:
				case DeviceChannelUID._MESHHUB_HUB_100:
				case DeviceChannelUID._HUB0004_HUB_100:
				case DeviceChannelUID._HUB0004_HUB_200:
				case DeviceChannelUID._LIGHTNINGHUB_HUB_100:
				case DeviceChannelUID._HUB5000_HUB_100:
				case DeviceChannelUID._HUB5000_HUB_200:
					return true;
				default:
					return false;
			}
		case Enum.DeviceClass.INTERFACE_KIT:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._ifkit488_VOLTAGERATIOINPUT_000:
				case DeviceChannelUID._ifkit488_DIGITALINPUT_000:
				case DeviceChannelUID._ifkit488_DIGITALOUTPUT_000:
				case DeviceChannelUID._1011_VOLTAGEINPUT_000:
				case DeviceChannelUID._1011_VOLTAGERATIOINPUT_000:
				case DeviceChannelUID._1011_DIGITALINPUT_000:
				case DeviceChannelUID._1011_DIGITALOUTPUT_000:
				case DeviceChannelUID._1012_DIGITALINPUT_000:
				case DeviceChannelUID._1012_DIGITALOUTPUT_000:
				case DeviceChannelUID._1012_DIGITALINPUT_601:
				case DeviceChannelUID._1012_DIGITALOUTPUT_601:
				case DeviceChannelUID._1012_DIGITALINPUT_602:
				case DeviceChannelUID._1012_DIGITALOUTPUT_602:
				case DeviceChannelUID._1013_VOLTAGEINPUT_000:
				case DeviceChannelUID._1013_VOLTAGERATIOINPUT_000:
				case DeviceChannelUID._1013_DIGITALINPUT_000:
				case DeviceChannelUID._1013_DIGITALOUTPUT_000:
				case DeviceChannelUID._1018_VOLTAGEINPUT_821:
				case DeviceChannelUID._1018_VOLTAGERATIOINPUT_821:
				case DeviceChannelUID._1018_DIGITALINPUT_821:
				case DeviceChannelUID._1018_DIGITALOUTPUT_821:
				case DeviceChannelUID._1014_DIGITALOUTPUT_000:
				case DeviceChannelUID._1014_DIGITALOUTPUT_704:
				case DeviceChannelUID._1014_DIGITALOUTPUT_800_USB:
				case DeviceChannelUID._1015_CAPACITIVETOUCH_000:
				case DeviceChannelUID._1016_CAPACITIVETOUCH_000:
				case DeviceChannelUID._1017_DIGITALOUTPUT_000:
				case DeviceChannelUID._1017_DIGITALOUTPUT_200_USB:
				case DeviceChannelUID._1018_VOLTAGEINPUT_900:
				case DeviceChannelUID._1018_VOLTAGERATIOINPUT_900:
				case DeviceChannelUID._1018_DIGITALINPUT_900:
				case DeviceChannelUID._1018_DIGITALOUTPUT_900:
				case DeviceChannelUID._1018_VOLTAGEINPUT_1000:
				case DeviceChannelUID._1018_VOLTAGERATIOINPUT_1000:
				case DeviceChannelUID._1018_DIGITALINPUT_1000:
				case DeviceChannelUID._1018_DIGITALOUTPUT_1000:
				case DeviceChannelUID._1202_VOLTAGEINPUT_000:
				case DeviceChannelUID._1202_VOLTAGERATIOINPUT_000:
				case DeviceChannelUID._1202_DIGITALINPUT_000:
				case DeviceChannelUID._1202_DIGITALOUTPUT_000:
				case DeviceChannelUID._1202_VOLTAGEINPUT_120:
				case DeviceChannelUID._1202_VOLTAGERATIOINPUT_120:
				case DeviceChannelUID._1202_DIGITALINPUT_120:
				case DeviceChannelUID._1202_DIGITALOUTPUT_120:
				case DeviceChannelUID._1202_VOLTAGEINPUT_300:
				case DeviceChannelUID._1202_VOLTAGERATIOINPUT_300:
				case DeviceChannelUID._1202_DIGITALINPUT_300:
				case DeviceChannelUID._1202_DIGITALOUTPUT_300:
				case DeviceChannelUID._USBSWITCH_DIGITALOUTPUT_IN1_100:
				case DeviceChannelUID._USBSWITCH_DIGITALOUTPUT_IN2_100:
				case DeviceChannelUID._USBSWITCH_DIGITALOUTPUT_IN3_100:
				case DeviceChannelUID._USBSWITCH_DIGITALOUTPUT_IN4_100:
				case DeviceChannelUID._USBSWITCH_DIGITALOUTPUT_A1_100:
				case DeviceChannelUID._USBSWITCH_DIGITALOUTPUT_A2_100:
				case DeviceChannelUID._USBSWITCH_DIGITALOUTPUT_A3_100:
				case DeviceChannelUID._USBSWITCH_DIGITALOUTPUT_A4_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceClass.IR:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1055_IR_100:
				case DeviceChannelUID._1055_IR_200_USB:
				case DeviceChannelUID._1055_IR_200_VINT:
					return true;
				default:
					return false;
			}
		case Enum.DeviceClass.LED:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1030_DIGITALOUTPUT_100:
				case DeviceChannelUID._1031_DIGITALOUTPUT_100:
				case DeviceChannelUID._1032_DIGITALOUTPUT_200:
					return true;
				default:
					return false;
			}
		case Enum.DeviceClass.MESH_DONGLE:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._MESHDONGLE_MESHDONGLE_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceClass.MOTOR_CONTROL:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1060_DCMOTOR_100:
				case DeviceChannelUID._1060_DIGITALINPUT_100:
				case DeviceChannelUID._1064_DCMOTOR_100:
				case DeviceChannelUID._1064_CURRENTINPUT_100:
				case DeviceChannelUID._1065_DCMOTOR_100:
				case DeviceChannelUID._1065_DIGITALINPUT_100:
				case DeviceChannelUID._1065_ENCODER_100:
				case DeviceChannelUID._1065_VOLTAGEINPUT_100:
				case DeviceChannelUID._1065_VOLTAGEINPUT_SUPPLY_100:
				case DeviceChannelUID._1065_VOLTAGERATIOINPUT_100:
				case DeviceChannelUID._1065_CURRENTINPUT_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceClass.PH_SENSOR:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1058_VOLTAGEINPUT_100:
				case DeviceChannelUID._1058_PHADAPTER_100:
					return true;
				default:
					return false;
			}
		case Enum.DeviceClass.RFID:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1023_RFID_000:
				case DeviceChannelUID._1023_RFID_104:
				case DeviceChannelUID._1023_RFID_200:
				case DeviceChannelUID._1023_DIGITALOUTPUT_5V_200:
				case DeviceChannelUID._1023_DIGITALOUTPUT_LED_200:
				case DeviceChannelUID._1023_DIGITALOUTPUT_ONBOARD_LED_200:
				case DeviceChannelUID._1023_RFID_201:
				case DeviceChannelUID._1023_DIGITALOUTPUT_5V_201:
				case DeviceChannelUID._1023_DIGITALOUTPUT_LED_201:
				case DeviceChannelUID._1023_DIGITALOUTPUT_ONBOARD_LED_201:
				case DeviceChannelUID._1024_RFID_100:
				case DeviceChannelUID._1024_DIGITALOUTPUT_5V_100:
				case DeviceChannelUID._1024_DIGITALOUTPUT_LED_100:
				case DeviceChannelUID._1024_DIGITALOUTPUT_ONBOARD_LED_100:
				case DeviceChannelUID._1024_RFID_200_USB:
				case DeviceChannelUID._1024_DIGITALOUTPUT_5V_200_USB:
				case DeviceChannelUID._1024_DIGITALOUTPUT_LED_200_USB:
				case DeviceChannelUID._1024_DIGITALOUTPUT_ONBOARD_LED_200_USB:
					return true;
				default:
					return false;
			}
		case Enum.DeviceClass.SERVO:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1000_RCSERVO_OLD1_200:
				case DeviceChannelUID._1000_RCSERVO_OLD2_200:
				case DeviceChannelUID._1000_RCSERVO_300:
				case DeviceChannelUID._1000_RCSERVO_313:
				case DeviceChannelUID._1001_RCSERVO_OLD1_200:
				case DeviceChannelUID._1001_RCSERVO_OLD2_200:
				case DeviceChannelUID._1001_RCSERVO_313:
				case DeviceChannelUID._1001_RCSERVO_400:
					return true;
				default:
					return false;
			}
		case Enum.DeviceClass.SPATIAL:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1041_ACCELEROMETER_200:
				case DeviceChannelUID._1042_ACCELEROMETER_300:
				case DeviceChannelUID._1042_GYROSCOPE_300:
				case DeviceChannelUID._1042_MAGNETOMETER_300:
				case DeviceChannelUID._1042_SPATIAL_300:
				case DeviceChannelUID._1043_ACCELEROMETER_300:
				case DeviceChannelUID._1044_ACCELEROMETER_400:
				case DeviceChannelUID._1044_GYROSCOPE_400:
				case DeviceChannelUID._1044_MAGNETOMETER_400:
				case DeviceChannelUID._1044_SPATIAL_400:
				case DeviceChannelUID._1044_ACCELEROMETER_500:
				case DeviceChannelUID._1044_GYROSCOPE_500:
				case DeviceChannelUID._1044_MAGNETOMETER_500:
				case DeviceChannelUID._1044_SPATIAL_500:
				case DeviceChannelUID._1044_ACCELEROMETER_510:
				case DeviceChannelUID._1044_GYROSCOPE_510:
				case DeviceChannelUID._1044_MAGNETOMETER_510:
				case DeviceChannelUID._1044_SPATIAL_510:
				case DeviceChannelUID._1049_ACCELEROMETER_000:
				case DeviceChannelUID._1056_ACCELEROMETER_000:
				case DeviceChannelUID._1056_GYROSCOPE_000:
				case DeviceChannelUID._1056_MAGNETOMETER_000:
				case DeviceChannelUID._1056_SPATIAL_000:
				case DeviceChannelUID._1056_ACCELEROMETER_200:
				case DeviceChannelUID._1056_GYROSCOPE_200:
				case DeviceChannelUID._1056_MAGNETOMETER_200:
				case DeviceChannelUID._1056_SPATIAL_200:
				case DeviceChannelUID._MOT0108_ACCELEROMETER_100:
				case DeviceChannelUID._MOT0108_GYROSCOPE_100:
				case DeviceChannelUID._MOT0108_MAGNETOMETER_100:
				case DeviceChannelUID._MOT0108_SPATIAL_100:
				case DeviceChannelUID._MOT0108_TEMPERATURESENSOR_100:
				case DeviceChannelUID._MOT0109_ACCELEROMETER_100:
				case DeviceChannelUID._MOT0109_GYROSCOPE_100:
				case DeviceChannelUID._MOT0109_MAGNETOMETER_100:
				case DeviceChannelUID._MOT0109_SPATIAL_100:
				case DeviceChannelUID._MOT0109_TEMPERATURESENSOR_100:
				case DeviceChannelUID._MOT0110_ACCELEROMETER_100_USB:
				case DeviceChannelUID._MOT0110_GYROSCOPE_100_USB:
				case DeviceChannelUID._MOT0110_MAGNETOMETER_100_USB:
				case DeviceChannelUID._MOT0110_SPATIAL_100_USB:
				case DeviceChannelUID._MOT0110_TEMPERATURESENSOR_100_USB:
					return true;
				default:
					return false;
			}
		case Enum.DeviceClass.STEPPER:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1062_STEPPER_100:
				case DeviceChannelUID._1063_STEPPER_100:
				case DeviceChannelUID._1063_DIGITALINPUT_100:
				case DeviceChannelUID._1063_CURRENTINPUT_100:
				case DeviceChannelUID._1067_STEPPER_200:
				case DeviceChannelUID._1067_STEPPER_300:
					return true;
				default:
					return false;
			}
		case Enum.DeviceClass.TEMPERATURE_SENSOR:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1045_TEMPERATURESENSOR_IR_100:
				case DeviceChannelUID._1045_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._1048_TEMPERATURESENSOR_THERMOCOUPLE_100:
				case DeviceChannelUID._1048_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._1048_VOLTAGEINPUT_100:
				case DeviceChannelUID._1048_TEMPERATURESENSOR_THERMOCOUPLE_200:
				case DeviceChannelUID._1048_TEMPERATURESENSOR_IC_200:
				case DeviceChannelUID._1048_VOLTAGEINPUT_200:
				case DeviceChannelUID._1051_TEMPERATURESENSOR_THERMOCOUPLE_000:
				case DeviceChannelUID._1051_TEMPERATURESENSOR_IC_000:
				case DeviceChannelUID._1051_TEMPERATURESENSOR_THERMOCOUPLE_200:
				case DeviceChannelUID._1051_TEMPERATURESENSOR_IC_200:
				case DeviceChannelUID._1051_VOLTAGEINPUT_200:
				case DeviceChannelUID._1051_TEMPERATURESENSOR_THERMOCOUPLE_300:
				case DeviceChannelUID._1051_TEMPERATURESENSOR_IC_300:
				case DeviceChannelUID._1051_VOLTAGEINPUT_300:
				case DeviceChannelUID._1051_TEMPERATURESENSOR_THERMOCOUPLE_400:
				case DeviceChannelUID._1051_TEMPERATURESENSOR_IC_400:
				case DeviceChannelUID._1051_VOLTAGEINPUT_400:
					return true;
				default:
					return false;
			}
		case Enum.DeviceClass.TEXT_LCD:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1202_TEXTLCD_000:
				case DeviceChannelUID._1202_TEXTLCD_200:
				case DeviceChannelUID._1204_TEXTLCD_000:
				case DeviceChannelUID._1215_TEXTLCD_000:
				case DeviceChannelUID._1219_TEXTLCD_000:
				case DeviceChannelUID._1219_DIGITALINPUT_000:
				case DeviceChannelUID._1219_DIGITALOUTPUT_000:
					return true;
				default:
					return false;
			}
		case Enum.DeviceClass.VINT:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1014_DIGITALOUTPUT_800_VINT:
				case DeviceChannelUID._1017_DIGITALOUTPUT_200_VINT:
				case DeviceChannelUID._1024_V2_VINT_OBSOLETE_200:
				case DeviceChannelUID._1024_RFID_200_VINT:
				case DeviceChannelUID._1024_DIGITALOUTPUT_5V_200_VINT:
				case DeviceChannelUID._1024_DIGITALOUTPUT_LED_200_VINT:
				case DeviceChannelUID._1024_DIGITALOUTPUT_ONBOARD_LED_200_VINT:
				case DeviceChannelUID._HUB_DIGITALINPUT_100:
				case DeviceChannelUID._HUB_DIGITALOUTPUT_100:
				case DeviceChannelUID._HUB_DIGITALOUTPUT_110:
				case DeviceChannelUID._HUB_VOLTAGEINPUT_100:
				case DeviceChannelUID._HUB_VOLTAGEINPUT_110:
				case DeviceChannelUID._HUB_VOLTAGEINPUT_200:
				case DeviceChannelUID._HUB_VOLTAGERATIOINPUT_100:
				case DeviceChannelUID._HUB_VOLTAGERATIOINPUT_200:
				case DeviceChannelUID._ADP1000_PHSENSOR_100:
				case DeviceChannelUID._ADP1000_VOLTAGEINPUT_100:
				case DeviceChannelUID._DAQ1000_VOLTAGERATIOINPUT_100:
				case DeviceChannelUID._DAQ1000_VOLTAGEINPUT_100:
				case DeviceChannelUID._DAQ1000_VOLTAGERATIOINPUT_110:
				case DeviceChannelUID._DAQ1000_VOLTAGEINPUT_110:
				case DeviceChannelUID._OUT1000_VOLTAGEOUTPUT_100:
				case DeviceChannelUID._OUT1000_VOLTAGEOUTPUT_110:
				case DeviceChannelUID._OUT1001_VOLTAGEOUTPUT_100:
				case DeviceChannelUID._OUT1001_VOLTAGEOUTPUT_110:
				case DeviceChannelUID._OUT1002_VOLTAGEOUTPUT_100:
				case DeviceChannelUID._OUT1002_VOLTAGEOUTPUT_110:
				case DeviceChannelUID._CURLOOP_CURRENTOUTPUT_100:
				case DeviceChannelUID._DAQ1200_DIGITALINPUT_100:
				case DeviceChannelUID._OUT1100_DIGITALOUTPUT_100:
				case DeviceChannelUID._OUT1100_DIGITALOUTPUT_110:
				case DeviceChannelUID._OUT1100_DIGITALOUTPUT_120:
				case DeviceChannelUID._DAQ1300_DIGITALINPUT_100:
				case DeviceChannelUID._DAQ1301_DIGITALINPUT_100:
				case DeviceChannelUID._DAQ1400_VOLTAGEINPUT_100:
				case DeviceChannelUID._DAQ1400_CURRENTINPUT_100:
				case DeviceChannelUID._DAQ1400_DIGITALINPUT_100:
				case DeviceChannelUID._DAQ1400_FREQUENCYCOUNTER_100:
				case DeviceChannelUID._DAQ1400_VOLTAGEINPUT_120:
				case DeviceChannelUID._DAQ1400_CURRENTINPUT_120:
				case DeviceChannelUID._DAQ1400_DIGITALINPUT_120:
				case DeviceChannelUID._DAQ1400_FREQUENCYCOUNTER_120:
				case DeviceChannelUID._DAQ1500_VOLTAGERATIOINPUT_100:
				case DeviceChannelUID._DAQ1501_VOLTAGERATIOINPUT_100:
				case DeviceChannelUID._VCP1100_CURRENTINPUT_100:
				case DeviceChannelUID._DCC1000_DCMOTOR_100:
				case DeviceChannelUID._DCC1000_ENCODER_100:
				case DeviceChannelUID._DCC1000_VOLTAGERATIOINPUT_100:
				case DeviceChannelUID._DCC1000_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._DCC1000_CURRENTINPUT_100:
				case DeviceChannelUID._DCC1000_DCMOTOR_200:
				case DeviceChannelUID._DCC1000_ENCODER_200:
				case DeviceChannelUID._DCC1000_VOLTAGERATIOINPUT_200:
				case DeviceChannelUID._DCC1000_TEMPERATURESENSOR_IC_200:
				case DeviceChannelUID._DCC1000_CURRENTINPUT_200:
				case DeviceChannelUID._DCC1000_MOTORPOSITIONCONTROLLER_200:
				case DeviceChannelUID._DCC1000_DCMOTOR_210:
				case DeviceChannelUID._DCC1000_ENCODER_210:
				case DeviceChannelUID._DCC1000_VOLTAGERATIOINPUT_210:
				case DeviceChannelUID._DCC1000_TEMPERATURESENSOR_IC_210:
				case DeviceChannelUID._DCC1000_CURRENTINPUT_210:
				case DeviceChannelUID._DCC1000_MOTORPOSITIONCONTROLLER_210:
				case DeviceChannelUID._DCC1010_ENCODER_100:
				case DeviceChannelUID._DCC1010_DCMOTOR_100:
				case DeviceChannelUID._DCC1010_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._DCC1010_CURRENTINPUT_100:
				case DeviceChannelUID._DCC1010_VOLTAGEINPUT_100:
				case DeviceChannelUID._DCC1010_MOTORPOSITIONCONTROLLER_100:
				case DeviceChannelUID._DCC1010_MOTORVELOCITYCONTROLLER_100:
				case DeviceChannelUID._DCC1020_OBSOLETE_100:
				case DeviceChannelUID._DCC1020_ENCODER_100:
				case DeviceChannelUID._DCC1020_DCMOTOR_100:
				case DeviceChannelUID._DCC1020_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._DCC1020_CURRENTINPUT_100:
				case DeviceChannelUID._DCC1020_VOLTAGEINPUT_100:
				case DeviceChannelUID._DCC1020_MOTORPOSITIONCONTROLLER_100:
				case DeviceChannelUID._DCC1020_MOTORVELOCITYCONTROLLER_100:
				case DeviceChannelUID._DCC1030_ENCODER_100:
				case DeviceChannelUID._DCC1030_DCMOTOR_100:
				case DeviceChannelUID._DCC1030_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._DCC1030_CURRENTINPUT_100:
				case DeviceChannelUID._DCC1030_VOLTAGEINPUT_100:
				case DeviceChannelUID._DCC1030_MOTORPOSITIONCONTROLLER_100:
				case DeviceChannelUID._DCC1030_MOTORVELOCITYCONTROLLER_100:
				case DeviceChannelUID._DCC1001_DCMOTOR_100:
				case DeviceChannelUID._DCC1001_ENCODER_100:
				case DeviceChannelUID._DCC1001_MOTORPOSITIONCONTROLLER_100:
				case DeviceChannelUID._DCC1001_DCMOTOR_120:
				case DeviceChannelUID._DCC1001_ENCODER_120:
				case DeviceChannelUID._DCC1001_MOTORPOSITIONCONTROLLER_120:
				case DeviceChannelUID._DCC1002_DCMOTOR_100:
				case DeviceChannelUID._DCC1002_ENCODER_100:
				case DeviceChannelUID._DCC1002_MOTORPOSITIONCONTROLLER_100:
				case DeviceChannelUID._DCC1002_DCMOTOR_110:
				case DeviceChannelUID._DCC1002_ENCODER_110:
				case DeviceChannelUID._DCC1002_MOTORPOSITIONCONTROLLER_110:
				case DeviceChannelUID._DCC1003_DCMOTOR_100:
				case DeviceChannelUID._DCC1003_DCMOTOR_110:
				case DeviceChannelUID._DCC1100_BLDCMOTOR_100:
				case DeviceChannelUID._DCC1100_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._DCC1100_MOTORPOSITIONCONTROLLER_100:
				case DeviceChannelUID._DCC1100_BLDCMOTOR_120:
				case DeviceChannelUID._DCC1100_TEMPERATURESENSOR_IC_120:
				case DeviceChannelUID._DCC1100_MOTORPOSITIONCONTROLLER_120:
				case DeviceChannelUID._DCC1110_ENCODER_100:
				case DeviceChannelUID._DCC1110_BLDCMOTOR_100:
				case DeviceChannelUID._DCC1110_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._DCC1110_CURRENTINPUT_100:
				case DeviceChannelUID._DCC1110_VOLTAGEINPUT_100:
				case DeviceChannelUID._DCC1110_MOTORPOSITIONCONTROLLER_100:
				case DeviceChannelUID._DCC1110_MOTORVELOCITYCONTROLLER_100:
				case DeviceChannelUID._DCC1120_ENCODER_100:
				case DeviceChannelUID._DCC1120_BLDCMOTOR_100:
				case DeviceChannelUID._DCC1120_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._DCC1120_CURRENTINPUT_100:
				case DeviceChannelUID._DCC1120_VOLTAGEINPUT_100:
				case DeviceChannelUID._DCC1120_MOTORPOSITIONCONTROLLER_100:
				case DeviceChannelUID._DCC1120_MOTORVELOCITYCONTROLLER_100:
				case DeviceChannelUID._DCC1130_ENCODER_100:
				case DeviceChannelUID._DCC1130_BLDCMOTOR_100:
				case DeviceChannelUID._DCC1130_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._DCC1130_CURRENTINPUT_100:
				case DeviceChannelUID._DCC1130_VOLTAGEINPUT_100:
				case DeviceChannelUID._DCC1130_MOTORPOSITIONCONTROLLER_100:
				case DeviceChannelUID._DCC1130_MOTORVELOCITYCONTROLLER_100:
				case DeviceChannelUID._DST1000_DISTANCESENSOR_100:
				case DeviceChannelUID._DST1001_DISTANCESENSOR_100:
				case DeviceChannelUID._DST1002_DISTANCESENSOR_100:
				case DeviceChannelUID._DST1200_DISTANCESENSOR_100:
				case DeviceChannelUID._ENC1000_ENCODER_100:
				case DeviceChannelUID._ENC1001_ENCODER_100:
				case DeviceChannelUID._HIN1101_ENCODER_100:
				case DeviceChannelUID._HIN1101_DIGITALINPUT_100:
				case DeviceChannelUID._HIN1000_CAPACITIVETOUCH_100:
				case DeviceChannelUID._HIN1001_CAPACITIVETOUCH_BUTTONS_100:
				case DeviceChannelUID._HIN1001_CAPACITIVETOUCH_WHEEL_100:
				case DeviceChannelUID._HIN1100_VOLTAGERATIOINPUT_100:
				case DeviceChannelUID._HIN1100_DIGITALINPUT_100:
				case DeviceChannelUID._HUM1000_HUMIDITYSENSOR_100:
				case DeviceChannelUID._HUM1000_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._HUM1001_HUMIDITYSENSOR_100:
				case DeviceChannelUID._HUM1001_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._LCD1100_LCD_100:
				case DeviceChannelUID._LCD1100_LCD_200:
				case DeviceChannelUID._LED1000_DIGITALOUTPUT_100:
				case DeviceChannelUID._LUX1000_LIGHTSENSOR_100:
				case DeviceChannelUID._HUM1100_VOLTAGERATIOINPUT_100:
				case DeviceChannelUID._MOT1100_ACCELEROMETER_200:
				case DeviceChannelUID._MOT0100_ACCELEROMETER_100_VINT:
				case DeviceChannelUID._MOT1101_ACCELEROMETER_100:
				case DeviceChannelUID._MOT1101_GYROSCOPE_100:
				case DeviceChannelUID._MOT1101_MAGNETOMETER_100:
				case DeviceChannelUID._MOT1101_SPATIAL_100:
				case DeviceChannelUID._MOT1102_ACCELEROMETER_200:
				case DeviceChannelUID._MOT1102_GYROSCOPE_200:
				case DeviceChannelUID._MOT1102_MAGNETOMETER_200:
				case DeviceChannelUID._MOT1102_SPATIAL_200:
				case DeviceChannelUID._MOT1102_ACCELEROMETER_300:
				case DeviceChannelUID._MOT1102_GYROSCOPE_300:
				case DeviceChannelUID._MOT1102_MAGNETOMETER_300:
				case DeviceChannelUID._MOT1102_SPATIAL_300:
				case DeviceChannelUID._MOT0110_ACCELEROMETER_100_VINT:
				case DeviceChannelUID._MOT0110_GYROSCOPE_100_VINT:
				case DeviceChannelUID._MOT0110_MAGNETOMETER_100_VINT:
				case DeviceChannelUID._MOT0110_SPATIAL_100_VINT:
				case DeviceChannelUID._MOT0110_TEMPERATURESENSOR_100_VINT:
				case DeviceChannelUID._PRE1000_PRESSURESENSOR_100:
				case DeviceChannelUID._RCC1000_RCSERVO_100:
				case DeviceChannelUID._RCC1000_RCSERVO_110:
				case DeviceChannelUID._RCC1000_RCSERVO_200:
				case DeviceChannelUID._REL1000_DIGITALOUTPUT_100:
				case DeviceChannelUID._REL1000_DIGITALOUTPUT_110:
				case DeviceChannelUID._REL1100_DIGITALOUTPUT_100:
				case DeviceChannelUID._REL1100_DIGITALOUTPUT_110:
				case DeviceChannelUID._REL1100_DIGITALOUTPUT_120:
				case DeviceChannelUID._REL1101_DIGITALOUTPUT_100:
				case DeviceChannelUID._REL1101_DIGITALOUTPUT_110:
				case DeviceChannelUID._REL1101_DIGITALOUTPUT_FREQ_200:
				case DeviceChannelUID._REL1101_DIGITALOUTPUT_200:
				case DeviceChannelUID._TP_DIAGNOSER_RESISTANCEINPUT_100:
				case DeviceChannelUID._SAF1000_POWERGUARD_100:
				case DeviceChannelUID._SAF1000_VOLTAGEINPUT_100:
				case DeviceChannelUID._SAF1000_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._SAF1000_POWERGUARD_110:
				case DeviceChannelUID._SAF1000_VOLTAGEINPUT_110:
				case DeviceChannelUID._SAF1000_TEMPERATURESENSOR_IC_110:
				case DeviceChannelUID._SND1000_SOUNDSENSOR_100:
				case DeviceChannelUID._STC1000_STEPPER_100:
				case DeviceChannelUID._STC1000_STEPPER_110:
				case DeviceChannelUID._STC1001_STEPPER_100:
				case DeviceChannelUID._STC1001_STEPPER_110:
				case DeviceChannelUID._STC1002_STEPPER_100:
				case DeviceChannelUID._STC1002_STEPPER_110:
				case DeviceChannelUID._STC1003_STEPPER_100:
				case DeviceChannelUID._STC1003_STEPPER_110:
				case DeviceChannelUID._STC1003_STEPPER_200:
				case DeviceChannelUID._STC1003_VOLTAGEINPUT_200:
				case DeviceChannelUID._STC1005_STEPPER_100:
				case DeviceChannelUID._STC1005_VOLTAGEINPUT_100:
				case DeviceChannelUID._STC1005_STEPPER_110:
				case DeviceChannelUID._STC1005_VOLTAGEINPUT_110:
				case DeviceChannelUID._STC1004_STEPPER_100:
				case DeviceChannelUID._TMP1000_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._TMP1100_TEMPERATURESENSOR_THERMOCOUPLE_100:
				case DeviceChannelUID._TMP1100_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._TMP1100_VOLTAGEINPUT_100:
				case DeviceChannelUID._TMP1101_TEMPERATURESENSOR_THERMOCOUPLE_100:
				case DeviceChannelUID._TMP1101_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._TMP1101_VOLTAGEINPUT_100:
				case DeviceChannelUID._TMP1101_TEMPERATURESENSOR_THERMOCOUPLE_200:
				case DeviceChannelUID._TMP1101_TEMPERATURESENSOR_IC_200:
				case DeviceChannelUID._TMP1101_VOLTAGEINPUT_200:
				case DeviceChannelUID._TMP1200_TEMPERATURESENSOR_RTD_100:
				case DeviceChannelUID._TMP1200_RESISTANCEINPUT_100:
				case DeviceChannelUID._TMP1200_TEMPERATURESENSOR_RTD_300:
				case DeviceChannelUID._TMP1200_RESISTANCEINPUT_300:
				case DeviceChannelUID._TMP1200_TEMPERATURESENSOR_RTD_400:
				case DeviceChannelUID._TMP1200_RESISTANCEINPUT_400:
				case DeviceChannelUID._TMP1300_TEMPERATURESENSOR_IR_100:
				case DeviceChannelUID._TMP1300_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._TMP1300_VOLTAGEINPUT_100:
				case DeviceChannelUID._VCP1000_VOLTAGEINPUT_100:
				case DeviceChannelUID._VCP1001_VOLTAGEINPUT_100:
				case DeviceChannelUID._VCP1001_VOLTAGEINPUT_110:
				case DeviceChannelUID._VCP1002_VOLTAGEINPUT_100:
				case DeviceChannelUID._VCP1002_VOLTAGEINPUT_110:
				case DeviceChannelUID._STM32F0_FIRMWARE_UPGRADE_100:
				case DeviceChannelUID._STM32G0_FIRMWARE_UPGRADE_110:
				case DeviceChannelUID._STM32G0_FIRMWARE_UPGRADE_114:
				case DeviceChannelUID._STM32F3_FIRMWARE_UPGRADE_120:
				case DeviceChannelUID._STM8S_FIRMWARE_UPGRADE_100:
				case DeviceChannelUID._VINT_GENERIC:
				case DeviceChannelUID._VINT_UNKNOWN:
					return true;
				default:
					return false;
			}
		default:
			return true;
	}
}

/** @internal */
export function supportedChannelClass(ch: Channel, val: number) {

	if (val < Enum.ChannelClass.NONE || val > Enum.ChannelClass.MOTOR_VELOCITY_CONTROLLER)
		return false;

	switch(val) {
		case Enum.ChannelClass.ACCELEROMETER:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1008_ACCELEROMETER_000:
				case DeviceChannelUID._1041_ACCELEROMETER_200:
				case DeviceChannelUID._1042_ACCELEROMETER_300:
				case DeviceChannelUID._1043_ACCELEROMETER_300:
				case DeviceChannelUID._1044_ACCELEROMETER_400:
				case DeviceChannelUID._1044_ACCELEROMETER_500:
				case DeviceChannelUID._1044_ACCELEROMETER_510:
				case DeviceChannelUID._1049_ACCELEROMETER_000:
				case DeviceChannelUID._1053_ACCELEROMETER_300:
				case DeviceChannelUID._1056_ACCELEROMETER_000:
				case DeviceChannelUID._1056_ACCELEROMETER_200:
				case DeviceChannelUID._1059_ACCELEROMETER_400:
				case DeviceChannelUID._MOT1100_ACCELEROMETER_200:
				case DeviceChannelUID._MOT0100_ACCELEROMETER_100_USB:
				case DeviceChannelUID._MOT0100_ACCELEROMETER_100_VINT:
				case DeviceChannelUID._MOT1101_ACCELEROMETER_100:
				case DeviceChannelUID._MOT1102_ACCELEROMETER_200:
				case DeviceChannelUID._MOT1102_ACCELEROMETER_300:
				case DeviceChannelUID._MOT0108_ACCELEROMETER_100:
				case DeviceChannelUID._MOT0109_ACCELEROMETER_100:
				case DeviceChannelUID._MOT0110_ACCELEROMETER_100_USB:
				case DeviceChannelUID._MOT0110_ACCELEROMETER_100_VINT:
					return true;
				default:
					return false;
			}
		case Enum.ChannelClass.BLDC_MOTOR:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._DCC1100_BLDCMOTOR_100:
				case DeviceChannelUID._DCC1100_BLDCMOTOR_120:
				case DeviceChannelUID._DCC1110_BLDCMOTOR_100:
				case DeviceChannelUID._DCC1120_BLDCMOTOR_100:
				case DeviceChannelUID._DCC1130_BLDCMOTOR_100:
					return true;
				default:
					return false;
			}
		case Enum.ChannelClass.CAPACITIVE_TOUCH:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1015_CAPACITIVETOUCH_000:
				case DeviceChannelUID._1016_CAPACITIVETOUCH_000:
				case DeviceChannelUID._HIN1000_CAPACITIVETOUCH_100:
				case DeviceChannelUID._HIN1001_CAPACITIVETOUCH_BUTTONS_100:
				case DeviceChannelUID._HIN1001_CAPACITIVETOUCH_WHEEL_100:
					return true;
				default:
					return false;
			}
		case Enum.ChannelClass.CURRENT_INPUT:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1061_CURRENTINPUT_100:
				case DeviceChannelUID._1061_CURRENTINPUT_200:
				case DeviceChannelUID._1061_CURRENTINPUT_300:
				case DeviceChannelUID._1063_CURRENTINPUT_100:
				case DeviceChannelUID._1064_CURRENTINPUT_100:
				case DeviceChannelUID._1065_CURRENTINPUT_100:
				case DeviceChannelUID._1066_CURRENTINPUT_100:
				case DeviceChannelUID._DAQ1400_CURRENTINPUT_100:
				case DeviceChannelUID._DAQ1400_CURRENTINPUT_120:
				case DeviceChannelUID._VCP1100_CURRENTINPUT_100:
				case DeviceChannelUID._DCC1000_CURRENTINPUT_100:
				case DeviceChannelUID._DCC1000_CURRENTINPUT_200:
				case DeviceChannelUID._DCC1000_CURRENTINPUT_210:
				case DeviceChannelUID._DCC1010_CURRENTINPUT_100:
				case DeviceChannelUID._DCC1020_CURRENTINPUT_100:
				case DeviceChannelUID._DCC1030_CURRENTINPUT_100:
				case DeviceChannelUID._DCC1110_CURRENTINPUT_100:
				case DeviceChannelUID._DCC1120_CURRENTINPUT_100:
				case DeviceChannelUID._DCC1130_CURRENTINPUT_100:
					return true;
				default:
					return false;
			}
		case Enum.ChannelClass.CURRENT_OUTPUT:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._CURLOOP_CURRENTOUTPUT_100:
					return true;
				default:
					return false;
			}
		case Enum.ChannelClass.DATA_ADAPTER:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._ADP1001_DATAADAPTER_100_USB:
				case DeviceChannelUID._ADP1001_DATAADAPTER_100_VINT:
				case DeviceChannelUID._ADP_RS485_422_DATAADAPTER_100_USB:
				case DeviceChannelUID._ADP_RS485_422_DATAADAPTER_100_VINT:
				case DeviceChannelUID._ADP_SERIAL_DATAADAPTER_100_USB:
				case DeviceChannelUID._ADP_SERIAL_DATAADAPTER_100_VINT:
					return true;
				default:
					return false;
			}
		case Enum.ChannelClass.DC_MOTOR:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1060_DCMOTOR_100:
				case DeviceChannelUID._1064_DCMOTOR_100:
				case DeviceChannelUID._1065_DCMOTOR_100:
				case DeviceChannelUID._DCC1000_DCMOTOR_100:
				case DeviceChannelUID._DCC1000_DCMOTOR_200:
				case DeviceChannelUID._DCC1000_DCMOTOR_210:
				case DeviceChannelUID._DCC1010_DCMOTOR_100:
				case DeviceChannelUID._DCC1020_DCMOTOR_100:
				case DeviceChannelUID._DCC1030_DCMOTOR_100:
				case DeviceChannelUID._DCC1001_DCMOTOR_100:
				case DeviceChannelUID._DCC1001_DCMOTOR_120:
				case DeviceChannelUID._DCC1002_DCMOTOR_100:
				case DeviceChannelUID._DCC1002_DCMOTOR_110:
				case DeviceChannelUID._DCC1003_DCMOTOR_100:
				case DeviceChannelUID._DCC1003_DCMOTOR_110:
					return true;
				default:
					return false;
			}
		case Enum.ChannelClass.DICTIONARY:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._DICTIONARY:
					return true;
				default:
					return false;
			}
		case Enum.ChannelClass.DIGITAL_INPUT:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._ifkit488_DIGITALINPUT_000:
				case DeviceChannelUID._1011_DIGITALINPUT_000:
				case DeviceChannelUID._1012_DIGITALINPUT_000:
				case DeviceChannelUID._1012_DIGITALINPUT_601:
				case DeviceChannelUID._1012_DIGITALINPUT_602:
				case DeviceChannelUID._1013_DIGITALINPUT_000:
				case DeviceChannelUID._1018_DIGITALINPUT_821:
				case DeviceChannelUID._1018_DIGITALINPUT_900:
				case DeviceChannelUID._1018_DIGITALINPUT_1000:
				case DeviceChannelUID._1047_DIGITALINPUT_100:
				case DeviceChannelUID._1047_DIGITALINPUT_200:
				case DeviceChannelUID._1047_DIGITALINPUT_300:
				case DeviceChannelUID._1052_DIGITALINPUT_000:
				case DeviceChannelUID._1052_DIGITALINPUT_101:
				case DeviceChannelUID._1052_DIGITALINPUT_110:
				case DeviceChannelUID._1060_DIGITALINPUT_100:
				case DeviceChannelUID._1063_DIGITALINPUT_100:
				case DeviceChannelUID._1065_DIGITALINPUT_100:
				case DeviceChannelUID._1202_DIGITALINPUT_000:
				case DeviceChannelUID._1202_DIGITALINPUT_120:
				case DeviceChannelUID._1202_DIGITALINPUT_300:
				case DeviceChannelUID._1219_DIGITALINPUT_000:
				case DeviceChannelUID._HUB_DIGITALINPUT_100:
				case DeviceChannelUID._ADP1001_DIGITALINPUT_DSR_100_USB:
				case DeviceChannelUID._ADP1001_DIGITALINPUT_DCD_100_USB:
				case DeviceChannelUID._ADP1001_DIGITALINPUT_RI_100_USB:
				case DeviceChannelUID._ADP1001_DIGITALINPUT_DSR_100_VINT:
				case DeviceChannelUID._ADP1001_DIGITALINPUT_DCD_100_VINT:
				case DeviceChannelUID._ADP1001_DIGITALINPUT_RI_100_VINT:
				case DeviceChannelUID._ADP_SERIAL_DIGITALINPUT_100_USB:
				case DeviceChannelUID._ADP_SERIAL_DIGITALINPUT_100_VINT:
				case DeviceChannelUID._DAQ1200_DIGITALINPUT_100:
				case DeviceChannelUID._DAQ1300_DIGITALINPUT_100:
				case DeviceChannelUID._DAQ1301_DIGITALINPUT_100:
				case DeviceChannelUID._DAQ1400_DIGITALINPUT_100:
				case DeviceChannelUID._DAQ1400_DIGITALINPUT_120:
				case DeviceChannelUID._HIN1101_DIGITALINPUT_100:
				case DeviceChannelUID._HIN1100_DIGITALINPUT_100:
					return true;
				default:
					return false;
			}
		case Enum.ChannelClass.DIGITAL_OUTPUT:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._ifkit488_DIGITALOUTPUT_000:
				case DeviceChannelUID._1011_DIGITALOUTPUT_000:
				case DeviceChannelUID._1012_DIGITALOUTPUT_000:
				case DeviceChannelUID._1012_DIGITALOUTPUT_601:
				case DeviceChannelUID._1012_DIGITALOUTPUT_602:
				case DeviceChannelUID._1013_DIGITALOUTPUT_000:
				case DeviceChannelUID._1018_DIGITALOUTPUT_821:
				case DeviceChannelUID._1014_DIGITALOUTPUT_000:
				case DeviceChannelUID._1014_DIGITALOUTPUT_704:
				case DeviceChannelUID._1014_DIGITALOUTPUT_800_USB:
				case DeviceChannelUID._1014_DIGITALOUTPUT_800_VINT:
				case DeviceChannelUID._1017_DIGITALOUTPUT_000:
				case DeviceChannelUID._1017_DIGITALOUTPUT_200_USB:
				case DeviceChannelUID._1017_DIGITALOUTPUT_200_VINT:
				case DeviceChannelUID._1018_DIGITALOUTPUT_900:
				case DeviceChannelUID._1018_DIGITALOUTPUT_1000:
				case DeviceChannelUID._1023_DIGITALOUTPUT_5V_200:
				case DeviceChannelUID._1023_DIGITALOUTPUT_LED_200:
				case DeviceChannelUID._1023_DIGITALOUTPUT_ONBOARD_LED_200:
				case DeviceChannelUID._1023_DIGITALOUTPUT_5V_201:
				case DeviceChannelUID._1023_DIGITALOUTPUT_LED_201:
				case DeviceChannelUID._1023_DIGITALOUTPUT_ONBOARD_LED_201:
				case DeviceChannelUID._1024_DIGITALOUTPUT_5V_100:
				case DeviceChannelUID._1024_DIGITALOUTPUT_LED_100:
				case DeviceChannelUID._1024_DIGITALOUTPUT_ONBOARD_LED_100:
				case DeviceChannelUID._1024_DIGITALOUTPUT_5V_200_USB:
				case DeviceChannelUID._1024_DIGITALOUTPUT_LED_200_USB:
				case DeviceChannelUID._1024_DIGITALOUTPUT_ONBOARD_LED_200_USB:
				case DeviceChannelUID._1024_DIGITALOUTPUT_5V_200_VINT:
				case DeviceChannelUID._1024_DIGITALOUTPUT_LED_200_VINT:
				case DeviceChannelUID._1024_DIGITALOUTPUT_ONBOARD_LED_200_VINT:
				case DeviceChannelUID._1030_DIGITALOUTPUT_100:
				case DeviceChannelUID._1031_DIGITALOUTPUT_100:
				case DeviceChannelUID._1032_DIGITALOUTPUT_200:
				case DeviceChannelUID._1202_DIGITALOUTPUT_000:
				case DeviceChannelUID._1202_DIGITALOUTPUT_120:
				case DeviceChannelUID._1202_DIGITALOUTPUT_300:
				case DeviceChannelUID._1219_DIGITALOUTPUT_000:
				case DeviceChannelUID._HUB_DIGITALOUTPUT_100:
				case DeviceChannelUID._HUB_DIGITALOUTPUT_110:
				case DeviceChannelUID._ADP1001_DIGITALOUTPUT_DTR_100_USB:
				case DeviceChannelUID._ADP1001_DIGITALOUTPUT_DTR_100_VINT:
				case DeviceChannelUID._ADP_SERIAL_DIGITALOUTPUT_100_USB:
				case DeviceChannelUID._ADP_SERIAL_DIGITALOUTPUT_100_VINT:
				case DeviceChannelUID._OUT1100_DIGITALOUTPUT_100:
				case DeviceChannelUID._OUT1100_DIGITALOUTPUT_110:
				case DeviceChannelUID._OUT1100_DIGITALOUTPUT_120:
				case DeviceChannelUID._LED1000_DIGITALOUTPUT_100:
				case DeviceChannelUID._REL1000_DIGITALOUTPUT_100:
				case DeviceChannelUID._REL1000_DIGITALOUTPUT_110:
				case DeviceChannelUID._REL1100_DIGITALOUTPUT_100:
				case DeviceChannelUID._REL1100_DIGITALOUTPUT_110:
				case DeviceChannelUID._REL1100_DIGITALOUTPUT_120:
				case DeviceChannelUID._REL1101_DIGITALOUTPUT_100:
				case DeviceChannelUID._REL1101_DIGITALOUTPUT_110:
				case DeviceChannelUID._REL1101_DIGITALOUTPUT_FREQ_200:
				case DeviceChannelUID._REL1101_DIGITALOUTPUT_200:
				case DeviceChannelUID._USBSWITCH_DIGITALOUTPUT_IN1_100:
				case DeviceChannelUID._USBSWITCH_DIGITALOUTPUT_IN2_100:
				case DeviceChannelUID._USBSWITCH_DIGITALOUTPUT_IN3_100:
				case DeviceChannelUID._USBSWITCH_DIGITALOUTPUT_IN4_100:
				case DeviceChannelUID._USBSWITCH_DIGITALOUTPUT_A1_100:
				case DeviceChannelUID._USBSWITCH_DIGITALOUTPUT_A2_100:
				case DeviceChannelUID._USBSWITCH_DIGITALOUTPUT_A3_100:
				case DeviceChannelUID._USBSWITCH_DIGITALOUTPUT_A4_100:
					return true;
				default:
					return false;
			}
		case Enum.ChannelClass.DISTANCE_SENSOR:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._DST1000_DISTANCESENSOR_100:
				case DeviceChannelUID._DST1001_DISTANCESENSOR_100:
				case DeviceChannelUID._DST1002_DISTANCESENSOR_100:
				case DeviceChannelUID._DST1200_DISTANCESENSOR_100:
					return true;
				default:
					return false;
			}
		case Enum.ChannelClass.ENCODER:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1047_ENCODER_100:
				case DeviceChannelUID._1047_ENCODER_200:
				case DeviceChannelUID._1047_ENCODER_300:
				case DeviceChannelUID._1052_ENCODER_000:
				case DeviceChannelUID._1052_ENCODER_101:
				case DeviceChannelUID._1052_ENCODER_110:
				case DeviceChannelUID._1057_ENCODER_300:
				case DeviceChannelUID._1057_ENCODER_400:
				case DeviceChannelUID._1065_ENCODER_100:
				case DeviceChannelUID._DCC1000_ENCODER_100:
				case DeviceChannelUID._DCC1000_ENCODER_200:
				case DeviceChannelUID._DCC1000_ENCODER_210:
				case DeviceChannelUID._DCC1010_ENCODER_100:
				case DeviceChannelUID._DCC1020_ENCODER_100:
				case DeviceChannelUID._DCC1030_ENCODER_100:
				case DeviceChannelUID._DCC1001_ENCODER_100:
				case DeviceChannelUID._DCC1001_ENCODER_120:
				case DeviceChannelUID._DCC1002_ENCODER_100:
				case DeviceChannelUID._DCC1002_ENCODER_110:
				case DeviceChannelUID._DCC1110_ENCODER_100:
				case DeviceChannelUID._DCC1120_ENCODER_100:
				case DeviceChannelUID._DCC1130_ENCODER_100:
				case DeviceChannelUID._ENC1000_ENCODER_100:
				case DeviceChannelUID._ENC1001_ENCODER_100:
				case DeviceChannelUID._HIN1101_ENCODER_100:
					return true;
				default:
					return false;
			}
		case Enum.ChannelClass.FIRMWARE_UPGRADE:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._M3_USB_FIRMWARE_UPGRADE_000:
				case DeviceChannelUID._STM32_USB_FIRMWARE_UPGRADE_100:
				case DeviceChannelUID._STM32_USB_FIRMWARE_UPGRADE_200:
				case DeviceChannelUID._STM32_USB_FIRMWARE_UPGRADE_300:
				case DeviceChannelUID._STM32F0_FIRMWARE_UPGRADE_100:
				case DeviceChannelUID._STM32G0_FIRMWARE_UPGRADE_110:
				case DeviceChannelUID._STM32G0_FIRMWARE_UPGRADE_114:
				case DeviceChannelUID._STM32F3_FIRMWARE_UPGRADE_120:
				case DeviceChannelUID._STM8S_FIRMWARE_UPGRADE_100:
				case DeviceChannelUID._M3_SPI_FIRMWARE_UPGRADE_000:
					return true;
				default:
					return false;
			}
		case Enum.ChannelClass.FREQUENCY_COUNTER:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1054_FREQUENCYCOUNTER_000:
				case DeviceChannelUID._DAQ1400_FREQUENCYCOUNTER_100:
				case DeviceChannelUID._DAQ1400_FREQUENCYCOUNTER_120:
					return true;
				default:
					return false;
			}
		case Enum.ChannelClass.GENERIC:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1024_V2_USB_OBSOLETE_200:
				case DeviceChannelUID._1024_V2_VINT_OBSOLETE_200:
				case DeviceChannelUID._DCC1020_OBSOLETE_100:
				case DeviceChannelUID._HIDUSB_GENERIC:
				case DeviceChannelUID._PHIDUSB_GENERIC:
				case DeviceChannelUID._VINT_GENERIC:
				case DeviceChannelUID._USB_UNKNOWN:
				case DeviceChannelUID._VINT_UNKNOWN:
				case DeviceChannelUID._SPI_UNKNOWN:
					return true;
				default:
					return false;
			}
		case Enum.ChannelClass.GPS:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1040_GPS_000:
					return true;
				default:
					return false;
			}
		case Enum.ChannelClass.GYROSCOPE:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1042_GYROSCOPE_300:
				case DeviceChannelUID._1044_GYROSCOPE_400:
				case DeviceChannelUID._1044_GYROSCOPE_500:
				case DeviceChannelUID._1044_GYROSCOPE_510:
				case DeviceChannelUID._1056_GYROSCOPE_000:
				case DeviceChannelUID._1056_GYROSCOPE_200:
				case DeviceChannelUID._MOT1101_GYROSCOPE_100:
				case DeviceChannelUID._MOT1102_GYROSCOPE_200:
				case DeviceChannelUID._MOT1102_GYROSCOPE_300:
				case DeviceChannelUID._MOT0108_GYROSCOPE_100:
				case DeviceChannelUID._MOT0109_GYROSCOPE_100:
				case DeviceChannelUID._MOT0110_GYROSCOPE_100_USB:
				case DeviceChannelUID._MOT0110_GYROSCOPE_100_VINT:
					return true;
				default:
					return false;
			}
		case Enum.ChannelClass.HUB:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._HUB0000_HUB_100:
				case DeviceChannelUID._HUB0000_HUB_300:
				case DeviceChannelUID._HUB0000_HUB_400:
				case DeviceChannelUID._HUB0001_HUB_100:
				case DeviceChannelUID._HUB0001_HUB_115:
				case DeviceChannelUID._HUB0002_HUB_100:
				case DeviceChannelUID._HUB0007_HUB_100:
				case DeviceChannelUID._MESHHUB_HUB_100:
				case DeviceChannelUID._HUB0004_HUB_100:
				case DeviceChannelUID._HUB0004_HUB_200:
				case DeviceChannelUID._LIGHTNINGHUB_HUB_100:
				case DeviceChannelUID._HUB5000_HUB_100:
				case DeviceChannelUID._HUB5000_HUB_200:
					return true;
				default:
					return false;
			}
		case Enum.ChannelClass.HUMIDITY_SENSOR:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._HUM1000_HUMIDITYSENSOR_100:
				case DeviceChannelUID._HUM1001_HUMIDITYSENSOR_100:
					return true;
				default:
					return false;
			}
		case Enum.ChannelClass.IR:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1055_IR_100:
				case DeviceChannelUID._1055_IR_200_USB:
				case DeviceChannelUID._1055_IR_200_VINT:
					return true;
				default:
					return false;
			}
		case Enum.ChannelClass.LCD:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1202_TEXTLCD_000:
				case DeviceChannelUID._1202_TEXTLCD_200:
				case DeviceChannelUID._1204_TEXTLCD_000:
				case DeviceChannelUID._1215_TEXTLCD_000:
				case DeviceChannelUID._1219_TEXTLCD_000:
				case DeviceChannelUID._LCD1100_LCD_100:
				case DeviceChannelUID._LCD1100_LCD_200:
					return true;
				default:
					return false;
			}
		case Enum.ChannelClass.LIGHT_SENSOR:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._LUX1000_LIGHTSENSOR_100:
					return true;
				default:
					return false;
			}
		case Enum.ChannelClass.MAGNETOMETER:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1042_MAGNETOMETER_300:
				case DeviceChannelUID._1044_MAGNETOMETER_400:
				case DeviceChannelUID._1044_MAGNETOMETER_500:
				case DeviceChannelUID._1044_MAGNETOMETER_510:
				case DeviceChannelUID._1056_MAGNETOMETER_000:
				case DeviceChannelUID._1056_MAGNETOMETER_200:
				case DeviceChannelUID._MOT1101_MAGNETOMETER_100:
				case DeviceChannelUID._MOT1102_MAGNETOMETER_200:
				case DeviceChannelUID._MOT1102_MAGNETOMETER_300:
				case DeviceChannelUID._MOT0108_MAGNETOMETER_100:
				case DeviceChannelUID._MOT0109_MAGNETOMETER_100:
				case DeviceChannelUID._MOT0110_MAGNETOMETER_100_USB:
				case DeviceChannelUID._MOT0110_MAGNETOMETER_100_VINT:
					return true;
				default:
					return false;
			}
		case Enum.ChannelClass.MESH_DONGLE:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._MESHDONGLE_MESHDONGLE_100:
					return true;
				default:
					return false;
			}
		case Enum.ChannelClass.MOTOR_POSITION_CONTROLLER:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._DCC1000_MOTORPOSITIONCONTROLLER_200:
				case DeviceChannelUID._DCC1000_MOTORPOSITIONCONTROLLER_210:
				case DeviceChannelUID._DCC1010_MOTORPOSITIONCONTROLLER_100:
				case DeviceChannelUID._DCC1020_MOTORPOSITIONCONTROLLER_100:
				case DeviceChannelUID._DCC1030_MOTORPOSITIONCONTROLLER_100:
				case DeviceChannelUID._DCC1001_MOTORPOSITIONCONTROLLER_100:
				case DeviceChannelUID._DCC1001_MOTORPOSITIONCONTROLLER_120:
				case DeviceChannelUID._DCC1002_MOTORPOSITIONCONTROLLER_100:
				case DeviceChannelUID._DCC1002_MOTORPOSITIONCONTROLLER_110:
				case DeviceChannelUID._DCC1100_MOTORPOSITIONCONTROLLER_100:
				case DeviceChannelUID._DCC1100_MOTORPOSITIONCONTROLLER_120:
				case DeviceChannelUID._DCC1110_MOTORPOSITIONCONTROLLER_100:
				case DeviceChannelUID._DCC1120_MOTORPOSITIONCONTROLLER_100:
				case DeviceChannelUID._DCC1130_MOTORPOSITIONCONTROLLER_100:
					return true;
				default:
					return false;
			}
		case Enum.ChannelClass.MOTOR_VELOCITY_CONTROLLER:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._DCC1010_MOTORVELOCITYCONTROLLER_100:
				case DeviceChannelUID._DCC1020_MOTORVELOCITYCONTROLLER_100:
				case DeviceChannelUID._DCC1030_MOTORVELOCITYCONTROLLER_100:
				case DeviceChannelUID._DCC1110_MOTORVELOCITYCONTROLLER_100:
				case DeviceChannelUID._DCC1120_MOTORVELOCITYCONTROLLER_100:
				case DeviceChannelUID._DCC1130_MOTORVELOCITYCONTROLLER_100:
					return true;
				default:
					return false;
			}
		case Enum.ChannelClass.PH_SENSOR:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1058_PHADAPTER_100:
				case DeviceChannelUID._ADP1000_PHSENSOR_100:
					return true;
				default:
					return false;
			}
		case Enum.ChannelClass.POWER_GUARD:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._SAF1000_POWERGUARD_100:
				case DeviceChannelUID._SAF1000_POWERGUARD_110:
					return true;
				default:
					return false;
			}
		case Enum.ChannelClass.PRESSURE_SENSOR:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._PRE1000_PRESSURESENSOR_100:
					return true;
				default:
					return false;
			}
		case Enum.ChannelClass.RC_SERVO:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1000_RCSERVO_OLD1_200:
				case DeviceChannelUID._1000_RCSERVO_OLD2_200:
				case DeviceChannelUID._1000_RCSERVO_300:
				case DeviceChannelUID._1000_RCSERVO_313:
				case DeviceChannelUID._1001_RCSERVO_OLD1_200:
				case DeviceChannelUID._1001_RCSERVO_OLD2_200:
				case DeviceChannelUID._1001_RCSERVO_313:
				case DeviceChannelUID._1001_RCSERVO_400:
				case DeviceChannelUID._1061_RCSERVO_100:
				case DeviceChannelUID._1061_RCSERVO_200:
				case DeviceChannelUID._1061_RCSERVO_300:
				case DeviceChannelUID._RCC0004_RCSERVO_400:
				case DeviceChannelUID._1066_RCSERVO_100:
				case DeviceChannelUID._RCC1000_RCSERVO_100:
				case DeviceChannelUID._RCC1000_RCSERVO_110:
				case DeviceChannelUID._RCC1000_RCSERVO_200:
					return true;
				default:
					return false;
			}
		case Enum.ChannelClass.RESISTANCE_INPUT:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._TP_DIAGNOSER_RESISTANCEINPUT_100:
				case DeviceChannelUID._TMP1200_RESISTANCEINPUT_100:
				case DeviceChannelUID._TMP1200_RESISTANCEINPUT_300:
				case DeviceChannelUID._TMP1200_RESISTANCEINPUT_400:
					return true;
				default:
					return false;
			}
		case Enum.ChannelClass.RFID:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1023_RFID_000:
				case DeviceChannelUID._1023_RFID_104:
				case DeviceChannelUID._1023_RFID_200:
				case DeviceChannelUID._1023_RFID_201:
				case DeviceChannelUID._1024_RFID_100:
				case DeviceChannelUID._1024_RFID_200_USB:
				case DeviceChannelUID._1024_RFID_200_VINT:
					return true;
				default:
					return false;
			}
		case Enum.ChannelClass.SOUND_SENSOR:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._SND1000_SOUNDSENSOR_100:
					return true;
				default:
					return false;
			}
		case Enum.ChannelClass.SPATIAL:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1042_SPATIAL_300:
				case DeviceChannelUID._1044_SPATIAL_400:
				case DeviceChannelUID._1044_SPATIAL_500:
				case DeviceChannelUID._1044_SPATIAL_510:
				case DeviceChannelUID._1056_SPATIAL_000:
				case DeviceChannelUID._1056_SPATIAL_200:
				case DeviceChannelUID._MOT1101_SPATIAL_100:
				case DeviceChannelUID._MOT1102_SPATIAL_200:
				case DeviceChannelUID._MOT1102_SPATIAL_300:
				case DeviceChannelUID._MOT0108_SPATIAL_100:
				case DeviceChannelUID._MOT0109_SPATIAL_100:
				case DeviceChannelUID._MOT0110_SPATIAL_100_USB:
				case DeviceChannelUID._MOT0110_SPATIAL_100_VINT:
					return true;
				default:
					return false;
			}
		case Enum.ChannelClass.STEPPER:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1062_STEPPER_100:
				case DeviceChannelUID._1063_STEPPER_100:
				case DeviceChannelUID._1067_STEPPER_200:
				case DeviceChannelUID._1067_STEPPER_300:
				case DeviceChannelUID._STC1000_STEPPER_100:
				case DeviceChannelUID._STC1000_STEPPER_110:
				case DeviceChannelUID._STC1001_STEPPER_100:
				case DeviceChannelUID._STC1001_STEPPER_110:
				case DeviceChannelUID._STC1002_STEPPER_100:
				case DeviceChannelUID._STC1002_STEPPER_110:
				case DeviceChannelUID._STC1003_STEPPER_100:
				case DeviceChannelUID._STC1003_STEPPER_110:
				case DeviceChannelUID._STC1003_STEPPER_200:
				case DeviceChannelUID._STC1005_STEPPER_100:
				case DeviceChannelUID._STC1005_STEPPER_110:
				case DeviceChannelUID._STC1004_STEPPER_100:
					return true;
				default:
					return false;
			}
		case Enum.ChannelClass.TEMPERATURE_SENSOR:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1045_TEMPERATURESENSOR_IR_100:
				case DeviceChannelUID._1045_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._1048_TEMPERATURESENSOR_THERMOCOUPLE_100:
				case DeviceChannelUID._1048_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._1048_TEMPERATURESENSOR_THERMOCOUPLE_200:
				case DeviceChannelUID._1048_TEMPERATURESENSOR_IC_200:
				case DeviceChannelUID._1051_TEMPERATURESENSOR_THERMOCOUPLE_000:
				case DeviceChannelUID._1051_TEMPERATURESENSOR_IC_000:
				case DeviceChannelUID._1051_TEMPERATURESENSOR_THERMOCOUPLE_200:
				case DeviceChannelUID._1051_TEMPERATURESENSOR_IC_200:
				case DeviceChannelUID._1051_TEMPERATURESENSOR_THERMOCOUPLE_300:
				case DeviceChannelUID._1051_TEMPERATURESENSOR_IC_300:
				case DeviceChannelUID._1051_TEMPERATURESENSOR_THERMOCOUPLE_400:
				case DeviceChannelUID._1051_TEMPERATURESENSOR_IC_400:
				case DeviceChannelUID._DCC1000_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._DCC1000_TEMPERATURESENSOR_IC_200:
				case DeviceChannelUID._DCC1000_TEMPERATURESENSOR_IC_210:
				case DeviceChannelUID._DCC1010_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._DCC1020_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._DCC1030_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._DCC1100_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._DCC1100_TEMPERATURESENSOR_IC_120:
				case DeviceChannelUID._DCC1110_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._DCC1120_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._DCC1130_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._HUM1000_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._HUM1001_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._MOT0108_TEMPERATURESENSOR_100:
				case DeviceChannelUID._MOT0109_TEMPERATURESENSOR_100:
				case DeviceChannelUID._MOT0110_TEMPERATURESENSOR_100_USB:
				case DeviceChannelUID._MOT0110_TEMPERATURESENSOR_100_VINT:
				case DeviceChannelUID._SAF1000_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._SAF1000_TEMPERATURESENSOR_IC_110:
				case DeviceChannelUID._TMP1000_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._TMP1100_TEMPERATURESENSOR_THERMOCOUPLE_100:
				case DeviceChannelUID._TMP1100_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._TMP1101_TEMPERATURESENSOR_THERMOCOUPLE_100:
				case DeviceChannelUID._TMP1101_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._TMP1101_TEMPERATURESENSOR_THERMOCOUPLE_200:
				case DeviceChannelUID._TMP1101_TEMPERATURESENSOR_IC_200:
				case DeviceChannelUID._TMP1200_TEMPERATURESENSOR_RTD_100:
				case DeviceChannelUID._TMP1200_TEMPERATURESENSOR_RTD_300:
				case DeviceChannelUID._TMP1200_TEMPERATURESENSOR_RTD_400:
				case DeviceChannelUID._TMP1300_TEMPERATURESENSOR_IR_100:
				case DeviceChannelUID._TMP1300_TEMPERATURESENSOR_IC_100:
					return true;
				default:
					return false;
			}
		case Enum.ChannelClass.VOLTAGE_INPUT:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1011_VOLTAGEINPUT_000:
				case DeviceChannelUID._1013_VOLTAGEINPUT_000:
				case DeviceChannelUID._1018_VOLTAGEINPUT_821:
				case DeviceChannelUID._1018_VOLTAGEINPUT_900:
				case DeviceChannelUID._1018_VOLTAGEINPUT_1000:
				case DeviceChannelUID._1048_VOLTAGEINPUT_100:
				case DeviceChannelUID._1048_VOLTAGEINPUT_200:
				case DeviceChannelUID._1051_VOLTAGEINPUT_200:
				case DeviceChannelUID._1051_VOLTAGEINPUT_300:
				case DeviceChannelUID._1051_VOLTAGEINPUT_400:
				case DeviceChannelUID._1058_VOLTAGEINPUT_100:
				case DeviceChannelUID._1065_VOLTAGEINPUT_100:
				case DeviceChannelUID._1065_VOLTAGEINPUT_SUPPLY_100:
				case DeviceChannelUID._1202_VOLTAGEINPUT_000:
				case DeviceChannelUID._1202_VOLTAGEINPUT_120:
				case DeviceChannelUID._1202_VOLTAGEINPUT_300:
				case DeviceChannelUID._HUB_VOLTAGEINPUT_100:
				case DeviceChannelUID._HUB_VOLTAGEINPUT_110:
				case DeviceChannelUID._HUB_VOLTAGEINPUT_200:
				case DeviceChannelUID._ADP1000_VOLTAGEINPUT_100:
				case DeviceChannelUID._DAQ1000_VOLTAGEINPUT_100:
				case DeviceChannelUID._DAQ1000_VOLTAGEINPUT_110:
				case DeviceChannelUID._DAQ1400_VOLTAGEINPUT_100:
				case DeviceChannelUID._DAQ1400_VOLTAGEINPUT_120:
				case DeviceChannelUID._DCC1010_VOLTAGEINPUT_100:
				case DeviceChannelUID._DCC1020_VOLTAGEINPUT_100:
				case DeviceChannelUID._DCC1030_VOLTAGEINPUT_100:
				case DeviceChannelUID._DCC1110_VOLTAGEINPUT_100:
				case DeviceChannelUID._DCC1120_VOLTAGEINPUT_100:
				case DeviceChannelUID._DCC1130_VOLTAGEINPUT_100:
				case DeviceChannelUID._SAF1000_VOLTAGEINPUT_100:
				case DeviceChannelUID._SAF1000_VOLTAGEINPUT_110:
				case DeviceChannelUID._STC1003_VOLTAGEINPUT_200:
				case DeviceChannelUID._STC1005_VOLTAGEINPUT_100:
				case DeviceChannelUID._STC1005_VOLTAGEINPUT_110:
				case DeviceChannelUID._TMP1100_VOLTAGEINPUT_100:
				case DeviceChannelUID._TMP1101_VOLTAGEINPUT_100:
				case DeviceChannelUID._TMP1101_VOLTAGEINPUT_200:
				case DeviceChannelUID._TMP1300_VOLTAGEINPUT_100:
				case DeviceChannelUID._VCP1000_VOLTAGEINPUT_100:
				case DeviceChannelUID._VCP1001_VOLTAGEINPUT_100:
				case DeviceChannelUID._VCP1001_VOLTAGEINPUT_110:
				case DeviceChannelUID._VCP1002_VOLTAGEINPUT_100:
				case DeviceChannelUID._VCP1002_VOLTAGEINPUT_110:
					return true;
				default:
					return false;
			}
		case Enum.ChannelClass.VOLTAGE_OUTPUT:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1002_VOLTAGEOUTPUT_100:
				case DeviceChannelUID._OUT1000_VOLTAGEOUTPUT_100:
				case DeviceChannelUID._OUT1000_VOLTAGEOUTPUT_110:
				case DeviceChannelUID._OUT1001_VOLTAGEOUTPUT_100:
				case DeviceChannelUID._OUT1001_VOLTAGEOUTPUT_110:
				case DeviceChannelUID._OUT1002_VOLTAGEOUTPUT_100:
				case DeviceChannelUID._OUT1002_VOLTAGEOUTPUT_110:
					return true;
				default:
					return false;
			}
		case Enum.ChannelClass.VOLTAGE_RATIO_INPUT:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._ifkit488_VOLTAGERATIOINPUT_000:
				case DeviceChannelUID._1011_VOLTAGERATIOINPUT_000:
				case DeviceChannelUID._1013_VOLTAGERATIOINPUT_000:
				case DeviceChannelUID._1018_VOLTAGERATIOINPUT_821:
				case DeviceChannelUID._1018_VOLTAGERATIOINPUT_900:
				case DeviceChannelUID._1018_VOLTAGERATIOINPUT_1000:
				case DeviceChannelUID._1046_VOLTAGERATIOINPUT_100:
				case DeviceChannelUID._1046_VOLTAGERATIOINPUT_102:
				case DeviceChannelUID._1046_VOLTAGERATIOINPUT_200:
				case DeviceChannelUID._1065_VOLTAGERATIOINPUT_100:
				case DeviceChannelUID._1202_VOLTAGERATIOINPUT_000:
				case DeviceChannelUID._1202_VOLTAGERATIOINPUT_120:
				case DeviceChannelUID._1202_VOLTAGERATIOINPUT_300:
				case DeviceChannelUID._HUB_VOLTAGERATIOINPUT_100:
				case DeviceChannelUID._HUB_VOLTAGERATIOINPUT_200:
				case DeviceChannelUID._DAQ1000_VOLTAGERATIOINPUT_100:
				case DeviceChannelUID._DAQ1000_VOLTAGERATIOINPUT_110:
				case DeviceChannelUID._DAQ1500_VOLTAGERATIOINPUT_100:
				case DeviceChannelUID._DAQ1501_VOLTAGERATIOINPUT_100:
				case DeviceChannelUID._DCC1000_VOLTAGERATIOINPUT_100:
				case DeviceChannelUID._DCC1000_VOLTAGERATIOINPUT_200:
				case DeviceChannelUID._DCC1000_VOLTAGERATIOINPUT_210:
				case DeviceChannelUID._HIN1100_VOLTAGERATIOINPUT_100:
				case DeviceChannelUID._HUM1100_VOLTAGERATIOINPUT_100:
					return true;
				default:
					return false;
			}
		default:
			return true;
	}
}

/** @internal */
export function supportedChannelSubclass(ch: Channel, val: number) {

	if (val < Enum.ChannelSubclass.NONE || val > Enum.ChannelSubclass.SPATIAL_AHRS)
		return false;

	switch(val) {
		case Enum.ChannelSubclass.NONE:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._ifkit488_DIGITALINPUT_000:
				case DeviceChannelUID._ifkit488_DIGITALOUTPUT_000:
				case DeviceChannelUID._1000_RCSERVO_OLD1_200:
				case DeviceChannelUID._1000_RCSERVO_OLD2_200:
				case DeviceChannelUID._1000_RCSERVO_300:
				case DeviceChannelUID._1000_RCSERVO_313:
				case DeviceChannelUID._1001_RCSERVO_OLD1_200:
				case DeviceChannelUID._1001_RCSERVO_OLD2_200:
				case DeviceChannelUID._1001_RCSERVO_313:
				case DeviceChannelUID._1001_RCSERVO_400:
				case DeviceChannelUID._1002_VOLTAGEOUTPUT_100:
				case DeviceChannelUID._1008_ACCELEROMETER_000:
				case DeviceChannelUID._1011_DIGITALINPUT_000:
				case DeviceChannelUID._1011_DIGITALOUTPUT_000:
				case DeviceChannelUID._1012_DIGITALINPUT_000:
				case DeviceChannelUID._1012_DIGITALOUTPUT_000:
				case DeviceChannelUID._1012_DIGITALINPUT_601:
				case DeviceChannelUID._1012_DIGITALOUTPUT_601:
				case DeviceChannelUID._1012_DIGITALINPUT_602:
				case DeviceChannelUID._1012_DIGITALOUTPUT_602:
				case DeviceChannelUID._1013_DIGITALINPUT_000:
				case DeviceChannelUID._1013_DIGITALOUTPUT_000:
				case DeviceChannelUID._1018_DIGITALINPUT_821:
				case DeviceChannelUID._1018_DIGITALOUTPUT_821:
				case DeviceChannelUID._1014_DIGITALOUTPUT_000:
				case DeviceChannelUID._1014_DIGITALOUTPUT_704:
				case DeviceChannelUID._1014_DIGITALOUTPUT_800_USB:
				case DeviceChannelUID._1014_DIGITALOUTPUT_800_VINT:
				case DeviceChannelUID._1015_CAPACITIVETOUCH_000:
				case DeviceChannelUID._1016_CAPACITIVETOUCH_000:
				case DeviceChannelUID._1017_DIGITALOUTPUT_000:
				case DeviceChannelUID._1017_DIGITALOUTPUT_200_USB:
				case DeviceChannelUID._1017_DIGITALOUTPUT_200_VINT:
				case DeviceChannelUID._1018_DIGITALINPUT_900:
				case DeviceChannelUID._1018_DIGITALOUTPUT_900:
				case DeviceChannelUID._1018_DIGITALINPUT_1000:
				case DeviceChannelUID._1018_DIGITALOUTPUT_1000:
				case DeviceChannelUID._1023_RFID_000:
				case DeviceChannelUID._1023_RFID_104:
				case DeviceChannelUID._1023_RFID_200:
				case DeviceChannelUID._1023_DIGITALOUTPUT_5V_200:
				case DeviceChannelUID._1023_DIGITALOUTPUT_LED_200:
				case DeviceChannelUID._1023_DIGITALOUTPUT_ONBOARD_LED_200:
				case DeviceChannelUID._1023_RFID_201:
				case DeviceChannelUID._1023_DIGITALOUTPUT_5V_201:
				case DeviceChannelUID._1023_DIGITALOUTPUT_LED_201:
				case DeviceChannelUID._1023_DIGITALOUTPUT_ONBOARD_LED_201:
				case DeviceChannelUID._1024_RFID_100:
				case DeviceChannelUID._1024_DIGITALOUTPUT_5V_100:
				case DeviceChannelUID._1024_DIGITALOUTPUT_LED_100:
				case DeviceChannelUID._1024_DIGITALOUTPUT_ONBOARD_LED_100:
				case DeviceChannelUID._1024_V2_USB_OBSOLETE_200:
				case DeviceChannelUID._1024_V2_VINT_OBSOLETE_200:
				case DeviceChannelUID._1024_RFID_200_USB:
				case DeviceChannelUID._1024_DIGITALOUTPUT_5V_200_USB:
				case DeviceChannelUID._1024_DIGITALOUTPUT_LED_200_USB:
				case DeviceChannelUID._1024_DIGITALOUTPUT_ONBOARD_LED_200_USB:
				case DeviceChannelUID._1024_RFID_200_VINT:
				case DeviceChannelUID._1024_DIGITALOUTPUT_5V_200_VINT:
				case DeviceChannelUID._1024_DIGITALOUTPUT_LED_200_VINT:
				case DeviceChannelUID._1024_DIGITALOUTPUT_ONBOARD_LED_200_VINT:
				case DeviceChannelUID._1040_GPS_000:
				case DeviceChannelUID._1041_ACCELEROMETER_200:
				case DeviceChannelUID._1042_ACCELEROMETER_300:
				case DeviceChannelUID._1042_GYROSCOPE_300:
				case DeviceChannelUID._1042_MAGNETOMETER_300:
				case DeviceChannelUID._1042_SPATIAL_300:
				case DeviceChannelUID._1043_ACCELEROMETER_300:
				case DeviceChannelUID._1044_ACCELEROMETER_400:
				case DeviceChannelUID._1044_GYROSCOPE_400:
				case DeviceChannelUID._1044_MAGNETOMETER_400:
				case DeviceChannelUID._1044_SPATIAL_400:
				case DeviceChannelUID._1044_ACCELEROMETER_500:
				case DeviceChannelUID._1044_GYROSCOPE_500:
				case DeviceChannelUID._1044_MAGNETOMETER_500:
				case DeviceChannelUID._1044_ACCELEROMETER_510:
				case DeviceChannelUID._1044_GYROSCOPE_510:
				case DeviceChannelUID._1044_MAGNETOMETER_510:
				case DeviceChannelUID._1045_TEMPERATURESENSOR_IR_100:
				case DeviceChannelUID._1045_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._1047_ENCODER_100:
				case DeviceChannelUID._1047_DIGITALINPUT_100:
				case DeviceChannelUID._1047_ENCODER_200:
				case DeviceChannelUID._1047_DIGITALINPUT_200:
				case DeviceChannelUID._1047_ENCODER_300:
				case DeviceChannelUID._1047_DIGITALINPUT_300:
				case DeviceChannelUID._1048_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._1048_VOLTAGEINPUT_100:
				case DeviceChannelUID._1048_TEMPERATURESENSOR_IC_200:
				case DeviceChannelUID._1048_VOLTAGEINPUT_200:
				case DeviceChannelUID._1049_ACCELEROMETER_000:
				case DeviceChannelUID._1051_TEMPERATURESENSOR_IC_000:
				case DeviceChannelUID._1051_TEMPERATURESENSOR_IC_200:
				case DeviceChannelUID._1051_VOLTAGEINPUT_200:
				case DeviceChannelUID._1051_TEMPERATURESENSOR_IC_300:
				case DeviceChannelUID._1051_VOLTAGEINPUT_300:
				case DeviceChannelUID._1051_TEMPERATURESENSOR_IC_400:
				case DeviceChannelUID._1051_VOLTAGEINPUT_400:
				case DeviceChannelUID._1052_ENCODER_000:
				case DeviceChannelUID._1052_DIGITALINPUT_000:
				case DeviceChannelUID._1052_ENCODER_101:
				case DeviceChannelUID._1052_DIGITALINPUT_101:
				case DeviceChannelUID._1052_ENCODER_110:
				case DeviceChannelUID._1052_DIGITALINPUT_110:
				case DeviceChannelUID._1053_ACCELEROMETER_300:
				case DeviceChannelUID._1054_FREQUENCYCOUNTER_000:
				case DeviceChannelUID._1055_IR_100:
				case DeviceChannelUID._1055_IR_200_USB:
				case DeviceChannelUID._1055_IR_200_VINT:
				case DeviceChannelUID._1056_ACCELEROMETER_000:
				case DeviceChannelUID._1056_GYROSCOPE_000:
				case DeviceChannelUID._1056_MAGNETOMETER_000:
				case DeviceChannelUID._1056_SPATIAL_000:
				case DeviceChannelUID._1056_ACCELEROMETER_200:
				case DeviceChannelUID._1056_GYROSCOPE_200:
				case DeviceChannelUID._1056_MAGNETOMETER_200:
				case DeviceChannelUID._1056_SPATIAL_200:
				case DeviceChannelUID._1057_ENCODER_300:
				case DeviceChannelUID._1057_ENCODER_400:
				case DeviceChannelUID._1058_VOLTAGEINPUT_100:
				case DeviceChannelUID._1058_PHADAPTER_100:
				case DeviceChannelUID._1059_ACCELEROMETER_400:
				case DeviceChannelUID._1060_DCMOTOR_100:
				case DeviceChannelUID._1060_DIGITALINPUT_100:
				case DeviceChannelUID._1061_RCSERVO_100:
				case DeviceChannelUID._1061_CURRENTINPUT_100:
				case DeviceChannelUID._1061_RCSERVO_200:
				case DeviceChannelUID._1061_CURRENTINPUT_200:
				case DeviceChannelUID._1061_RCSERVO_300:
				case DeviceChannelUID._1061_CURRENTINPUT_300:
				case DeviceChannelUID._RCC0004_RCSERVO_400:
				case DeviceChannelUID._1062_STEPPER_100:
				case DeviceChannelUID._1063_STEPPER_100:
				case DeviceChannelUID._1063_DIGITALINPUT_100:
				case DeviceChannelUID._1063_CURRENTINPUT_100:
				case DeviceChannelUID._1064_DCMOTOR_100:
				case DeviceChannelUID._1064_CURRENTINPUT_100:
				case DeviceChannelUID._1065_DCMOTOR_100:
				case DeviceChannelUID._1065_DIGITALINPUT_100:
				case DeviceChannelUID._1065_ENCODER_100:
				case DeviceChannelUID._1065_VOLTAGEINPUT_SUPPLY_100:
				case DeviceChannelUID._1065_CURRENTINPUT_100:
				case DeviceChannelUID._1066_RCSERVO_100:
				case DeviceChannelUID._1066_CURRENTINPUT_100:
				case DeviceChannelUID._1067_STEPPER_200:
				case DeviceChannelUID._1067_STEPPER_300:
				case DeviceChannelUID._1202_DIGITALINPUT_000:
				case DeviceChannelUID._1202_DIGITALOUTPUT_000:
				case DeviceChannelUID._1202_DIGITALINPUT_120:
				case DeviceChannelUID._1202_DIGITALOUTPUT_120:
				case DeviceChannelUID._1202_DIGITALINPUT_300:
				case DeviceChannelUID._1202_DIGITALOUTPUT_300:
				case DeviceChannelUID._1219_DIGITALINPUT_000:
				case DeviceChannelUID._1219_DIGITALOUTPUT_000:
				case DeviceChannelUID._HUB_DIGITALINPUT_100:
				case DeviceChannelUID._ADP1000_PHSENSOR_100:
				case DeviceChannelUID._ADP1000_VOLTAGEINPUT_100:
				case DeviceChannelUID._ADP1001_DATAADAPTER_100_USB:
				case DeviceChannelUID._ADP1001_DIGITALINPUT_DSR_100_USB:
				case DeviceChannelUID._ADP1001_DIGITALINPUT_DCD_100_USB:
				case DeviceChannelUID._ADP1001_DIGITALINPUT_RI_100_USB:
				case DeviceChannelUID._ADP1001_DIGITALOUTPUT_DTR_100_USB:
				case DeviceChannelUID._ADP1001_DATAADAPTER_100_VINT:
				case DeviceChannelUID._ADP1001_DIGITALINPUT_DSR_100_VINT:
				case DeviceChannelUID._ADP1001_DIGITALINPUT_DCD_100_VINT:
				case DeviceChannelUID._ADP1001_DIGITALINPUT_RI_100_VINT:
				case DeviceChannelUID._ADP1001_DIGITALOUTPUT_DTR_100_VINT:
				case DeviceChannelUID._ADP_RS485_422_DATAADAPTER_100_USB:
				case DeviceChannelUID._ADP_RS485_422_DATAADAPTER_100_VINT:
				case DeviceChannelUID._ADP_SERIAL_DATAADAPTER_100_USB:
				case DeviceChannelUID._ADP_SERIAL_DIGITALINPUT_100_USB:
				case DeviceChannelUID._ADP_SERIAL_DIGITALOUTPUT_100_USB:
				case DeviceChannelUID._ADP_SERIAL_DATAADAPTER_100_VINT:
				case DeviceChannelUID._ADP_SERIAL_DIGITALINPUT_100_VINT:
				case DeviceChannelUID._ADP_SERIAL_DIGITALOUTPUT_100_VINT:
				case DeviceChannelUID._OUT1000_VOLTAGEOUTPUT_100:
				case DeviceChannelUID._OUT1000_VOLTAGEOUTPUT_110:
				case DeviceChannelUID._OUT1001_VOLTAGEOUTPUT_100:
				case DeviceChannelUID._OUT1001_VOLTAGEOUTPUT_110:
				case DeviceChannelUID._OUT1002_VOLTAGEOUTPUT_100:
				case DeviceChannelUID._OUT1002_VOLTAGEOUTPUT_110:
				case DeviceChannelUID._CURLOOP_CURRENTOUTPUT_100:
				case DeviceChannelUID._DAQ1200_DIGITALINPUT_100:
				case DeviceChannelUID._DAQ1300_DIGITALINPUT_100:
				case DeviceChannelUID._DAQ1301_DIGITALINPUT_100:
				case DeviceChannelUID._DAQ1400_VOLTAGEINPUT_100:
				case DeviceChannelUID._DAQ1400_CURRENTINPUT_100:
				case DeviceChannelUID._DAQ1400_DIGITALINPUT_100:
				case DeviceChannelUID._DAQ1400_FREQUENCYCOUNTER_100:
				case DeviceChannelUID._DAQ1400_VOLTAGEINPUT_120:
				case DeviceChannelUID._DAQ1400_CURRENTINPUT_120:
				case DeviceChannelUID._DAQ1400_DIGITALINPUT_120:
				case DeviceChannelUID._DAQ1400_FREQUENCYCOUNTER_120:
				case DeviceChannelUID._VCP1100_CURRENTINPUT_100:
				case DeviceChannelUID._DCC1000_DCMOTOR_100:
				case DeviceChannelUID._DCC1000_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._DCC1000_CURRENTINPUT_100:
				case DeviceChannelUID._DCC1000_DCMOTOR_200:
				case DeviceChannelUID._DCC1000_TEMPERATURESENSOR_IC_200:
				case DeviceChannelUID._DCC1000_CURRENTINPUT_200:
				case DeviceChannelUID._DCC1000_MOTORPOSITIONCONTROLLER_200:
				case DeviceChannelUID._DCC1000_DCMOTOR_210:
				case DeviceChannelUID._DCC1000_TEMPERATURESENSOR_IC_210:
				case DeviceChannelUID._DCC1000_CURRENTINPUT_210:
				case DeviceChannelUID._DCC1000_MOTORPOSITIONCONTROLLER_210:
				case DeviceChannelUID._DCC1010_ENCODER_100:
				case DeviceChannelUID._DCC1010_DCMOTOR_100:
				case DeviceChannelUID._DCC1010_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._DCC1010_CURRENTINPUT_100:
				case DeviceChannelUID._DCC1010_VOLTAGEINPUT_100:
				case DeviceChannelUID._DCC1010_MOTORPOSITIONCONTROLLER_100:
				case DeviceChannelUID._DCC1010_MOTORVELOCITYCONTROLLER_100:
				case DeviceChannelUID._DCC1020_OBSOLETE_100:
				case DeviceChannelUID._DCC1020_ENCODER_100:
				case DeviceChannelUID._DCC1020_DCMOTOR_100:
				case DeviceChannelUID._DCC1020_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._DCC1020_CURRENTINPUT_100:
				case DeviceChannelUID._DCC1020_VOLTAGEINPUT_100:
				case DeviceChannelUID._DCC1020_MOTORPOSITIONCONTROLLER_100:
				case DeviceChannelUID._DCC1020_MOTORVELOCITYCONTROLLER_100:
				case DeviceChannelUID._DCC1030_ENCODER_100:
				case DeviceChannelUID._DCC1030_DCMOTOR_100:
				case DeviceChannelUID._DCC1030_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._DCC1030_CURRENTINPUT_100:
				case DeviceChannelUID._DCC1030_VOLTAGEINPUT_100:
				case DeviceChannelUID._DCC1030_MOTORPOSITIONCONTROLLER_100:
				case DeviceChannelUID._DCC1030_MOTORVELOCITYCONTROLLER_100:
				case DeviceChannelUID._DCC1001_DCMOTOR_100:
				case DeviceChannelUID._DCC1001_ENCODER_100:
				case DeviceChannelUID._DCC1001_MOTORPOSITIONCONTROLLER_100:
				case DeviceChannelUID._DCC1001_DCMOTOR_120:
				case DeviceChannelUID._DCC1001_ENCODER_120:
				case DeviceChannelUID._DCC1001_MOTORPOSITIONCONTROLLER_120:
				case DeviceChannelUID._DCC1002_DCMOTOR_100:
				case DeviceChannelUID._DCC1002_ENCODER_100:
				case DeviceChannelUID._DCC1002_MOTORPOSITIONCONTROLLER_100:
				case DeviceChannelUID._DCC1002_DCMOTOR_110:
				case DeviceChannelUID._DCC1002_ENCODER_110:
				case DeviceChannelUID._DCC1002_MOTORPOSITIONCONTROLLER_110:
				case DeviceChannelUID._DCC1003_DCMOTOR_100:
				case DeviceChannelUID._DCC1003_DCMOTOR_110:
				case DeviceChannelUID._DCC1100_BLDCMOTOR_100:
				case DeviceChannelUID._DCC1100_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._DCC1100_MOTORPOSITIONCONTROLLER_100:
				case DeviceChannelUID._DCC1100_BLDCMOTOR_120:
				case DeviceChannelUID._DCC1100_TEMPERATURESENSOR_IC_120:
				case DeviceChannelUID._DCC1100_MOTORPOSITIONCONTROLLER_120:
				case DeviceChannelUID._DCC1110_ENCODER_100:
				case DeviceChannelUID._DCC1110_BLDCMOTOR_100:
				case DeviceChannelUID._DCC1110_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._DCC1110_CURRENTINPUT_100:
				case DeviceChannelUID._DCC1110_VOLTAGEINPUT_100:
				case DeviceChannelUID._DCC1110_MOTORPOSITIONCONTROLLER_100:
				case DeviceChannelUID._DCC1110_MOTORVELOCITYCONTROLLER_100:
				case DeviceChannelUID._DCC1120_ENCODER_100:
				case DeviceChannelUID._DCC1120_BLDCMOTOR_100:
				case DeviceChannelUID._DCC1120_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._DCC1120_CURRENTINPUT_100:
				case DeviceChannelUID._DCC1120_VOLTAGEINPUT_100:
				case DeviceChannelUID._DCC1120_MOTORPOSITIONCONTROLLER_100:
				case DeviceChannelUID._DCC1120_MOTORVELOCITYCONTROLLER_100:
				case DeviceChannelUID._DCC1130_ENCODER_100:
				case DeviceChannelUID._DCC1130_BLDCMOTOR_100:
				case DeviceChannelUID._DCC1130_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._DCC1130_CURRENTINPUT_100:
				case DeviceChannelUID._DCC1130_VOLTAGEINPUT_100:
				case DeviceChannelUID._DCC1130_MOTORPOSITIONCONTROLLER_100:
				case DeviceChannelUID._DCC1130_MOTORVELOCITYCONTROLLER_100:
				case DeviceChannelUID._DST1000_DISTANCESENSOR_100:
				case DeviceChannelUID._DST1001_DISTANCESENSOR_100:
				case DeviceChannelUID._DST1002_DISTANCESENSOR_100:
				case DeviceChannelUID._DST1200_DISTANCESENSOR_100:
				case DeviceChannelUID._ENC1001_ENCODER_100:
				case DeviceChannelUID._HIN1101_ENCODER_100:
				case DeviceChannelUID._HIN1101_DIGITALINPUT_100:
				case DeviceChannelUID._HIN1000_CAPACITIVETOUCH_100:
				case DeviceChannelUID._HIN1001_CAPACITIVETOUCH_BUTTONS_100:
				case DeviceChannelUID._HIN1001_CAPACITIVETOUCH_WHEEL_100:
				case DeviceChannelUID._HIN1100_VOLTAGERATIOINPUT_100:
				case DeviceChannelUID._HIN1100_DIGITALINPUT_100:
				case DeviceChannelUID._HUM1000_HUMIDITYSENSOR_100:
				case DeviceChannelUID._HUM1000_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._HUM1001_HUMIDITYSENSOR_100:
				case DeviceChannelUID._HUM1001_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._LUX1000_LIGHTSENSOR_100:
				case DeviceChannelUID._HUM1100_VOLTAGERATIOINPUT_100:
				case DeviceChannelUID._MOT1100_ACCELEROMETER_200:
				case DeviceChannelUID._MOT0100_ACCELEROMETER_100_USB:
				case DeviceChannelUID._MOT0100_ACCELEROMETER_100_VINT:
				case DeviceChannelUID._MOT1101_ACCELEROMETER_100:
				case DeviceChannelUID._MOT1101_GYROSCOPE_100:
				case DeviceChannelUID._MOT1101_MAGNETOMETER_100:
				case DeviceChannelUID._MOT1101_SPATIAL_100:
				case DeviceChannelUID._MOT1102_ACCELEROMETER_200:
				case DeviceChannelUID._MOT1102_GYROSCOPE_200:
				case DeviceChannelUID._MOT1102_MAGNETOMETER_200:
				case DeviceChannelUID._MOT1102_ACCELEROMETER_300:
				case DeviceChannelUID._MOT1102_GYROSCOPE_300:
				case DeviceChannelUID._MOT1102_MAGNETOMETER_300:
				case DeviceChannelUID._MOT0108_ACCELEROMETER_100:
				case DeviceChannelUID._MOT0108_GYROSCOPE_100:
				case DeviceChannelUID._MOT0108_MAGNETOMETER_100:
				case DeviceChannelUID._MOT0108_TEMPERATURESENSOR_100:
				case DeviceChannelUID._MOT0109_ACCELEROMETER_100:
				case DeviceChannelUID._MOT0109_GYROSCOPE_100:
				case DeviceChannelUID._MOT0109_MAGNETOMETER_100:
				case DeviceChannelUID._MOT0109_TEMPERATURESENSOR_100:
				case DeviceChannelUID._MOT0110_ACCELEROMETER_100_USB:
				case DeviceChannelUID._MOT0110_GYROSCOPE_100_USB:
				case DeviceChannelUID._MOT0110_MAGNETOMETER_100_USB:
				case DeviceChannelUID._MOT0110_TEMPERATURESENSOR_100_USB:
				case DeviceChannelUID._MOT0110_ACCELEROMETER_100_VINT:
				case DeviceChannelUID._MOT0110_GYROSCOPE_100_VINT:
				case DeviceChannelUID._MOT0110_MAGNETOMETER_100_VINT:
				case DeviceChannelUID._MOT0110_TEMPERATURESENSOR_100_VINT:
				case DeviceChannelUID._PRE1000_PRESSURESENSOR_100:
				case DeviceChannelUID._RCC1000_RCSERVO_100:
				case DeviceChannelUID._RCC1000_RCSERVO_110:
				case DeviceChannelUID._RCC1000_RCSERVO_200:
				case DeviceChannelUID._REL1000_DIGITALOUTPUT_100:
				case DeviceChannelUID._REL1000_DIGITALOUTPUT_110:
				case DeviceChannelUID._TP_DIAGNOSER_RESISTANCEINPUT_100:
				case DeviceChannelUID._SAF1000_POWERGUARD_100:
				case DeviceChannelUID._SAF1000_VOLTAGEINPUT_100:
				case DeviceChannelUID._SAF1000_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._SAF1000_POWERGUARD_110:
				case DeviceChannelUID._SAF1000_VOLTAGEINPUT_110:
				case DeviceChannelUID._SAF1000_TEMPERATURESENSOR_IC_110:
				case DeviceChannelUID._SND1000_SOUNDSENSOR_100:
				case DeviceChannelUID._STC1000_STEPPER_100:
				case DeviceChannelUID._STC1000_STEPPER_110:
				case DeviceChannelUID._STC1001_STEPPER_100:
				case DeviceChannelUID._STC1001_STEPPER_110:
				case DeviceChannelUID._STC1002_STEPPER_100:
				case DeviceChannelUID._STC1002_STEPPER_110:
				case DeviceChannelUID._STC1003_STEPPER_100:
				case DeviceChannelUID._STC1003_STEPPER_110:
				case DeviceChannelUID._STC1003_STEPPER_200:
				case DeviceChannelUID._STC1003_VOLTAGEINPUT_200:
				case DeviceChannelUID._STC1005_STEPPER_100:
				case DeviceChannelUID._STC1005_VOLTAGEINPUT_100:
				case DeviceChannelUID._STC1005_STEPPER_110:
				case DeviceChannelUID._STC1005_VOLTAGEINPUT_110:
				case DeviceChannelUID._STC1004_STEPPER_100:
				case DeviceChannelUID._TMP1000_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._TMP1100_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._TMP1100_VOLTAGEINPUT_100:
				case DeviceChannelUID._TMP1101_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._TMP1101_VOLTAGEINPUT_100:
				case DeviceChannelUID._TMP1101_TEMPERATURESENSOR_IC_200:
				case DeviceChannelUID._TMP1101_VOLTAGEINPUT_200:
				case DeviceChannelUID._TMP1200_RESISTANCEINPUT_100:
				case DeviceChannelUID._TMP1200_RESISTANCEINPUT_300:
				case DeviceChannelUID._TMP1200_RESISTANCEINPUT_400:
				case DeviceChannelUID._TMP1300_TEMPERATURESENSOR_IR_100:
				case DeviceChannelUID._TMP1300_TEMPERATURESENSOR_IC_100:
				case DeviceChannelUID._TMP1300_VOLTAGEINPUT_100:
				case DeviceChannelUID._VCP1000_VOLTAGEINPUT_100:
				case DeviceChannelUID._VCP1001_VOLTAGEINPUT_100:
				case DeviceChannelUID._VCP1001_VOLTAGEINPUT_110:
				case DeviceChannelUID._VCP1002_VOLTAGEINPUT_100:
				case DeviceChannelUID._VCP1002_VOLTAGEINPUT_110:
				case DeviceChannelUID._HUB0000_HUB_100:
				case DeviceChannelUID._HUB0000_HUB_300:
				case DeviceChannelUID._HUB0000_HUB_400:
				case DeviceChannelUID._HUB0001_HUB_100:
				case DeviceChannelUID._HUB0001_HUB_115:
				case DeviceChannelUID._HUB0002_HUB_100:
				case DeviceChannelUID._HUB0007_HUB_100:
				case DeviceChannelUID._MESHHUB_HUB_100:
				case DeviceChannelUID._MESHDONGLE_MESHDONGLE_100:
				case DeviceChannelUID._HUB0004_HUB_100:
				case DeviceChannelUID._HUB0004_HUB_200:
				case DeviceChannelUID._LIGHTNINGHUB_HUB_100:
				case DeviceChannelUID._HUB5000_HUB_100:
				case DeviceChannelUID._HUB5000_HUB_200:
				case DeviceChannelUID._M3_USB_FIRMWARE_UPGRADE_000:
				case DeviceChannelUID._STM32_USB_FIRMWARE_UPGRADE_100:
				case DeviceChannelUID._STM32_USB_FIRMWARE_UPGRADE_200:
				case DeviceChannelUID._STM32_USB_FIRMWARE_UPGRADE_300:
				case DeviceChannelUID._STM32F0_FIRMWARE_UPGRADE_100:
				case DeviceChannelUID._STM32G0_FIRMWARE_UPGRADE_110:
				case DeviceChannelUID._STM32G0_FIRMWARE_UPGRADE_114:
				case DeviceChannelUID._STM32F3_FIRMWARE_UPGRADE_120:
				case DeviceChannelUID._STM8S_FIRMWARE_UPGRADE_100:
				case DeviceChannelUID._M3_SPI_FIRMWARE_UPGRADE_000:
				case DeviceChannelUID._HIDUSB_GENERIC:
				case DeviceChannelUID._PHIDUSB_GENERIC:
				case DeviceChannelUID._USBSWITCH_DIGITALOUTPUT_IN1_100:
				case DeviceChannelUID._USBSWITCH_DIGITALOUTPUT_IN2_100:
				case DeviceChannelUID._USBSWITCH_DIGITALOUTPUT_IN3_100:
				case DeviceChannelUID._USBSWITCH_DIGITALOUTPUT_IN4_100:
				case DeviceChannelUID._USBSWITCH_DIGITALOUTPUT_A1_100:
				case DeviceChannelUID._USBSWITCH_DIGITALOUTPUT_A2_100:
				case DeviceChannelUID._USBSWITCH_DIGITALOUTPUT_A3_100:
				case DeviceChannelUID._USBSWITCH_DIGITALOUTPUT_A4_100:
				case DeviceChannelUID._VINT_GENERIC:
				case DeviceChannelUID._DICTIONARY:
				case DeviceChannelUID._USB_UNKNOWN:
				case DeviceChannelUID._VINT_UNKNOWN:
				case DeviceChannelUID._SPI_UNKNOWN:
					return true;
				default:
					return false;
			}
		case Enum.ChannelSubclass.DIGITAL_OUTPUT_DUTY_CYCLE:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._HUB_DIGITALOUTPUT_100:
				case DeviceChannelUID._HUB_DIGITALOUTPUT_110:
				case DeviceChannelUID._OUT1100_DIGITALOUTPUT_100:
				case DeviceChannelUID._OUT1100_DIGITALOUTPUT_110:
				case DeviceChannelUID._REL1100_DIGITALOUTPUT_100:
				case DeviceChannelUID._REL1100_DIGITALOUTPUT_110:
				case DeviceChannelUID._REL1101_DIGITALOUTPUT_100:
				case DeviceChannelUID._REL1101_DIGITALOUTPUT_110:
				case DeviceChannelUID._REL1101_DIGITALOUTPUT_200:
					return true;
				default:
					return false;
			}
		case Enum.ChannelSubclass.DIGITAL_OUTPUT_FREQUENCY:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._OUT1100_DIGITALOUTPUT_120:
				case DeviceChannelUID._REL1100_DIGITALOUTPUT_120:
				case DeviceChannelUID._REL1101_DIGITALOUTPUT_FREQ_200:
					return true;
				default:
					return false;
			}
		case Enum.ChannelSubclass.DIGITAL_OUTPUT_LEDDRIVER:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1030_DIGITALOUTPUT_100:
				case DeviceChannelUID._1031_DIGITALOUTPUT_100:
				case DeviceChannelUID._1032_DIGITALOUTPUT_200:
				case DeviceChannelUID._LED1000_DIGITALOUTPUT_100:
					return true;
				default:
					return false;
			}
		case Enum.ChannelSubclass.ENCODER_MODE_SETTABLE:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._DCC1000_ENCODER_100:
				case DeviceChannelUID._DCC1000_ENCODER_200:
				case DeviceChannelUID._DCC1000_ENCODER_210:
				case DeviceChannelUID._ENC1000_ENCODER_100:
					return true;
				default:
					return false;
			}
		case Enum.ChannelSubclass.LCD_GRAPHIC:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._LCD1100_LCD_100:
				case DeviceChannelUID._LCD1100_LCD_200:
					return true;
				default:
					return false;
			}
		case Enum.ChannelSubclass.LCD_TEXT:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1202_TEXTLCD_000:
				case DeviceChannelUID._1202_TEXTLCD_200:
				case DeviceChannelUID._1204_TEXTLCD_000:
				case DeviceChannelUID._1215_TEXTLCD_000:
				case DeviceChannelUID._1219_TEXTLCD_000:
					return true;
				default:
					return false;
			}
		case Enum.ChannelSubclass.SPATIAL_AHRS:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1044_SPATIAL_500:
				case DeviceChannelUID._1044_SPATIAL_510:
				case DeviceChannelUID._MOT1102_SPATIAL_200:
				case DeviceChannelUID._MOT1102_SPATIAL_300:
				case DeviceChannelUID._MOT0108_SPATIAL_100:
				case DeviceChannelUID._MOT0109_SPATIAL_100:
				case DeviceChannelUID._MOT0110_SPATIAL_100_USB:
				case DeviceChannelUID._MOT0110_SPATIAL_100_VINT:
					return true;
				default:
					return false;
			}
		case Enum.ChannelSubclass.TEMPERATURE_SENSOR_RTD:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._TMP1200_TEMPERATURESENSOR_RTD_100:
				case DeviceChannelUID._TMP1200_TEMPERATURESENSOR_RTD_300:
				case DeviceChannelUID._TMP1200_TEMPERATURESENSOR_RTD_400:
					return true;
				default:
					return false;
			}
		case Enum.ChannelSubclass.TEMPERATURE_SENSOR_THERMOCOUPLE:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1048_TEMPERATURESENSOR_THERMOCOUPLE_100:
				case DeviceChannelUID._1048_TEMPERATURESENSOR_THERMOCOUPLE_200:
				case DeviceChannelUID._1051_TEMPERATURESENSOR_THERMOCOUPLE_000:
				case DeviceChannelUID._1051_TEMPERATURESENSOR_THERMOCOUPLE_200:
				case DeviceChannelUID._1051_TEMPERATURESENSOR_THERMOCOUPLE_300:
				case DeviceChannelUID._1051_TEMPERATURESENSOR_THERMOCOUPLE_400:
				case DeviceChannelUID._TMP1100_TEMPERATURESENSOR_THERMOCOUPLE_100:
				case DeviceChannelUID._TMP1101_TEMPERATURESENSOR_THERMOCOUPLE_100:
				case DeviceChannelUID._TMP1101_TEMPERATURESENSOR_THERMOCOUPLE_200:
					return true;
				default:
					return false;
			}
		case Enum.ChannelSubclass.VOLTAGE_INPUT_SENSOR_PORT:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1011_VOLTAGEINPUT_000:
				case DeviceChannelUID._1013_VOLTAGEINPUT_000:
				case DeviceChannelUID._1018_VOLTAGEINPUT_821:
				case DeviceChannelUID._1018_VOLTAGEINPUT_900:
				case DeviceChannelUID._1018_VOLTAGEINPUT_1000:
				case DeviceChannelUID._1065_VOLTAGEINPUT_100:
				case DeviceChannelUID._1202_VOLTAGEINPUT_000:
				case DeviceChannelUID._1202_VOLTAGEINPUT_120:
				case DeviceChannelUID._1202_VOLTAGEINPUT_300:
				case DeviceChannelUID._HUB_VOLTAGEINPUT_100:
				case DeviceChannelUID._HUB_VOLTAGEINPUT_110:
				case DeviceChannelUID._HUB_VOLTAGEINPUT_200:
				case DeviceChannelUID._DAQ1000_VOLTAGEINPUT_100:
				case DeviceChannelUID._DAQ1000_VOLTAGEINPUT_110:
					return true;
				default:
					return false;
			}
		case Enum.ChannelSubclass.VOLTAGE_RATIO_INPUT_BRIDGE:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1046_VOLTAGERATIOINPUT_100:
				case DeviceChannelUID._1046_VOLTAGERATIOINPUT_102:
				case DeviceChannelUID._1046_VOLTAGERATIOINPUT_200:
				case DeviceChannelUID._DAQ1500_VOLTAGERATIOINPUT_100:
				case DeviceChannelUID._DAQ1501_VOLTAGERATIOINPUT_100:
					return true;
				default:
					return false;
			}
		case Enum.ChannelSubclass.VOLTAGE_RATIO_INPUT_SENSOR_PORT:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._ifkit488_VOLTAGERATIOINPUT_000:
				case DeviceChannelUID._1011_VOLTAGERATIOINPUT_000:
				case DeviceChannelUID._1013_VOLTAGERATIOINPUT_000:
				case DeviceChannelUID._1018_VOLTAGERATIOINPUT_821:
				case DeviceChannelUID._1018_VOLTAGERATIOINPUT_900:
				case DeviceChannelUID._1018_VOLTAGERATIOINPUT_1000:
				case DeviceChannelUID._1065_VOLTAGERATIOINPUT_100:
				case DeviceChannelUID._1202_VOLTAGERATIOINPUT_000:
				case DeviceChannelUID._1202_VOLTAGERATIOINPUT_120:
				case DeviceChannelUID._1202_VOLTAGERATIOINPUT_300:
				case DeviceChannelUID._HUB_VOLTAGERATIOINPUT_100:
				case DeviceChannelUID._HUB_VOLTAGERATIOINPUT_200:
				case DeviceChannelUID._DAQ1000_VOLTAGERATIOINPUT_100:
				case DeviceChannelUID._DAQ1000_VOLTAGERATIOINPUT_110:
				case DeviceChannelUID._DCC1000_VOLTAGERATIOINPUT_100:
				case DeviceChannelUID._DCC1000_VOLTAGERATIOINPUT_200:
				case DeviceChannelUID._DCC1000_VOLTAGERATIOINPUT_210:
					return true;
				default:
					return false;
			}
		default:
			return true;
	}
}

/** @internal */
export function supportedMeshMode(_ch: Channel, val: number) {

	if (val < Enum.MeshMode.ROUTER || val > Enum.MeshMode.SLEEPY_END_DEVICE)
		return false;

	return true;
}

/** @internal */
export function supportedPowerSupply(ch: Channel, val: number) {

	if (val < Enum.PowerSupply.OFF || val > Enum.PowerSupply.VOLTS_24)
		return false;

	switch(val) {
		case Enum.PowerSupply.OFF:
		case Enum.PowerSupply.VOLTS_12:
		case Enum.PowerSupply.VOLTS_24:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._DAQ1400_DIGITALINPUT_100:
				case DeviceChannelUID._DAQ1400_CURRENTINPUT_100:
				case DeviceChannelUID._DAQ1400_FREQUENCYCOUNTER_100:
				case DeviceChannelUID._DAQ1400_VOLTAGEINPUT_100:
				case DeviceChannelUID._DAQ1400_DIGITALINPUT_120:
				case DeviceChannelUID._DAQ1400_CURRENTINPUT_120:
				case DeviceChannelUID._DAQ1400_FREQUENCYCOUNTER_120:
				case DeviceChannelUID._DAQ1400_VOLTAGEINPUT_120:
					return true;
				default:
					return false;
			}
		default:
			return true;
	}
}

/** @internal */
export function supportedRTDWireSetup(ch: Channel, val: number) {

	if (val < Enum.RTDWireSetup.WIRES_2 || val > Enum.RTDWireSetup.WIRES_4)
		return false;

	switch(val) {
		case Enum.RTDWireSetup.WIRES_2:
		case Enum.RTDWireSetup.WIRES_3:
		case Enum.RTDWireSetup.WIRES_4:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._TMP1200_RESISTANCEINPUT_100:
				case DeviceChannelUID._TMP1200_TEMPERATURESENSOR_RTD_100:
				case DeviceChannelUID._TMP1200_RESISTANCEINPUT_300:
				case DeviceChannelUID._TMP1200_TEMPERATURESENSOR_RTD_300:
				case DeviceChannelUID._TMP1200_TEMPERATURESENSOR_RTD_400:
				case DeviceChannelUID._TMP1200_RESISTANCEINPUT_400:
					return true;
				default:
					return false;
			}
		default:
			return true;
	}
}

/** @internal */
export function supportedInputMode(ch: Channel, val: number) {

	if (val < Enum.InputMode.NPN || val > Enum.InputMode.PNP)
		return false;

	switch(val) {
		case Enum.InputMode.NPN:
		case Enum.InputMode.PNP:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._DAQ1400_DIGITALINPUT_100:
				case DeviceChannelUID._DAQ1400_FREQUENCYCOUNTER_100:
				case DeviceChannelUID._DAQ1400_DIGITALINPUT_120:
				case DeviceChannelUID._DAQ1400_FREQUENCYCOUNTER_120:
					return true;
				default:
					return false;
			}
		default:
			return true;
	}
}

/** @internal */
export function supportedFanMode(ch: Channel, val: number) {

	if (val < Enum.FanMode.OFF || val > Enum.FanMode.AUTO)
		return false;

	switch(val) {
		case Enum.FanMode.OFF:
		case Enum.FanMode.ON:
		case Enum.FanMode.AUTO:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._SAF1000_POWERGUARD_100:
				case DeviceChannelUID._SAF1000_POWERGUARD_110:
				case DeviceChannelUID._DCC1000_DCMOTOR_100:
				case DeviceChannelUID._DCC1000_DCMOTOR_200:
				case DeviceChannelUID._DCC1000_DCMOTOR_210:
				case DeviceChannelUID._DCC1000_MOTORPOSITIONCONTROLLER_200:
				case DeviceChannelUID._DCC1000_MOTORPOSITIONCONTROLLER_210:
					return true;
				default:
					return false;
			}
		default:
			return true;
	}
}

/** @internal */
export function supportedDecelerationType(ch: Channel, val: number) {

	if (val < Enum.DecelerationType.COAST || val > Enum.DecelerationType.FORCED)
		return false;

	switch(val) {
		case Enum.DecelerationType.COAST:
		case Enum.DecelerationType.FORCED:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._DCC1010_DCMOTOR_100:
				case DeviceChannelUID._DCC1020_DCMOTOR_100:
				case DeviceChannelUID._DCC1030_DCMOTOR_100:
				case DeviceChannelUID._DCC1110_BLDCMOTOR_100:
				case DeviceChannelUID._DCC1120_BLDCMOTOR_100:
				case DeviceChannelUID._DCC1130_BLDCMOTOR_100:
					return true;
				default:
					return false;
			}
		default:
			return true;
	}
}

/** @internal */
export function supportedPositionType(ch: Channel, val: number) {

	if (val < Enum.PositionType.ENCODER || val > Enum.PositionType.HALL_SENSOR)
		return false;

	switch(val) {
		case Enum.PositionType.ENCODER:
		case Enum.PositionType.HALL_SENSOR:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._DCC1110_MOTORPOSITIONCONTROLLER_100:
				case DeviceChannelUID._DCC1120_MOTORPOSITIONCONTROLLER_100:
				case DeviceChannelUID._DCC1130_MOTORPOSITIONCONTROLLER_100:
				case DeviceChannelUID._DCC1110_MOTORVELOCITYCONTROLLER_100:
				case DeviceChannelUID._DCC1120_MOTORVELOCITYCONTROLLER_100:
				case DeviceChannelUID._DCC1130_MOTORVELOCITYCONTROLLER_100:
					return true;
				default:
					return false;
			}
		default:
			return true;
	}
}

/** @internal */
export function supportedSpatialPrecision(_ch: Channel, val: number) {

	if (val < Enum.SpatialPrecision.HYBRID || val > Enum.SpatialPrecision.LOW)
		return false;

	return true;
}

/** @internal */
export function supportedUnit(_ch: Channel, val: number) {

	if (val < Enum.Unit.NONE || val > Enum.Unit.WATT)
		return false;

	return true;
}

/** @internal */
export function supportedBridgeGain(ch: Channel, val: number) {

	if (val < Enum.BridgeGain.GAIN_1X || val > Enum.BridgeGain.GAIN_128X)
		return false;

	switch(val) {
		case Enum.BridgeGain.GAIN_1X:
		case Enum.BridgeGain.GAIN_64X:
		case Enum.BridgeGain.GAIN_128X:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1046_VOLTAGERATIOINPUT_100:
				case DeviceChannelUID._1046_VOLTAGERATIOINPUT_102:
				case DeviceChannelUID._DAQ1500_VOLTAGERATIOINPUT_100:
				case DeviceChannelUID._1046_VOLTAGERATIOINPUT_200:
				case DeviceChannelUID._DAQ1501_VOLTAGERATIOINPUT_100:
					return true;
				default:
					return false;
			}
		case Enum.BridgeGain.GAIN_2X:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._DAQ1500_VOLTAGERATIOINPUT_100:
				case DeviceChannelUID._DAQ1501_VOLTAGERATIOINPUT_100:
					return true;
				default:
					return false;
			}
		case Enum.BridgeGain.GAIN_4X:
			switch(ch.chDef.uid) {
				default:
					return false;
			}
		case Enum.BridgeGain.GAIN_8X:
		case Enum.BridgeGain.GAIN_16X:
		case Enum.BridgeGain.GAIN_32X:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1046_VOLTAGERATIOINPUT_100:
				case DeviceChannelUID._1046_VOLTAGERATIOINPUT_102:
				case DeviceChannelUID._1046_VOLTAGERATIOINPUT_200:
					return true;
				default:
					return false;
			}
		default:
			return true;
	}
}

/** @internal */
export function supportedVoltageRatioSensorType(ch: Channel, val: number) {

	if (val < Enum.VoltageRatioSensorType.VOLTAGE_RATIO || val > Enum.VoltageRatioSensorType.PN_3522)
		return false;

	switch(val) {
		case Enum.VoltageRatioSensorType.PN_1101_SHARP2D120X:
		case Enum.VoltageRatioSensorType.PN_1101_SHARP2Y0A21:
		case Enum.VoltageRatioSensorType.PN_1101_SHARP2Y0A02:
		case Enum.VoltageRatioSensorType.PN_1102:
		case Enum.VoltageRatioSensorType.PN_1103:
		case Enum.VoltageRatioSensorType.PN_1104:
		case Enum.VoltageRatioSensorType.PN_1105:
		case Enum.VoltageRatioSensorType.PN_1106:
		case Enum.VoltageRatioSensorType.PN_1107:
		case Enum.VoltageRatioSensorType.PN_1108:
		case Enum.VoltageRatioSensorType.PN_1109:
		case Enum.VoltageRatioSensorType.PN_1110:
		case Enum.VoltageRatioSensorType.PN_1111:
		case Enum.VoltageRatioSensorType.PN_1112:
		case Enum.VoltageRatioSensorType.PN_1113:
		case Enum.VoltageRatioSensorType.PN_1115:
		case Enum.VoltageRatioSensorType.PN_1116:
		case Enum.VoltageRatioSensorType.PN_1118_AC:
		case Enum.VoltageRatioSensorType.PN_1118_DC:
		case Enum.VoltageRatioSensorType.PN_1119_AC:
		case Enum.VoltageRatioSensorType.PN_1119_DC:
		case Enum.VoltageRatioSensorType.PN_1120:
		case Enum.VoltageRatioSensorType.PN_1121:
		case Enum.VoltageRatioSensorType.PN_1122_AC:
		case Enum.VoltageRatioSensorType.PN_1122_DC:
		case Enum.VoltageRatioSensorType.PN_1124:
		case Enum.VoltageRatioSensorType.PN_1125_HUMIDITY:
		case Enum.VoltageRatioSensorType.PN_1125_TEMPERATURE:
		case Enum.VoltageRatioSensorType.PN_1126:
		case Enum.VoltageRatioSensorType.PN_1128:
		case Enum.VoltageRatioSensorType.PN_1129:
		case Enum.VoltageRatioSensorType.PN_1131:
		case Enum.VoltageRatioSensorType.PN_1134:
		case Enum.VoltageRatioSensorType.PN_1136:
		case Enum.VoltageRatioSensorType.PN_1137:
		case Enum.VoltageRatioSensorType.PN_1138:
		case Enum.VoltageRatioSensorType.PN_1139:
		case Enum.VoltageRatioSensorType.PN_1140:
		case Enum.VoltageRatioSensorType.PN_1141:
		case Enum.VoltageRatioSensorType.PN_1146:
		case Enum.VoltageRatioSensorType.PN_3120:
		case Enum.VoltageRatioSensorType.PN_3121:
		case Enum.VoltageRatioSensorType.PN_3122:
		case Enum.VoltageRatioSensorType.PN_3123:
		case Enum.VoltageRatioSensorType.PN_3130:
		case Enum.VoltageRatioSensorType.PN_3520:
		case Enum.VoltageRatioSensorType.PN_3521:
		case Enum.VoltageRatioSensorType.PN_3522:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._ifkit488_VOLTAGERATIOINPUT_000:
				case DeviceChannelUID._1011_VOLTAGERATIOINPUT_000:
				case DeviceChannelUID._1013_VOLTAGERATIOINPUT_000:
				case DeviceChannelUID._1018_VOLTAGERATIOINPUT_821:
				case DeviceChannelUID._1018_VOLTAGERATIOINPUT_900:
				case DeviceChannelUID._1018_VOLTAGERATIOINPUT_1000:
				case DeviceChannelUID._1065_VOLTAGERATIOINPUT_100:
				case DeviceChannelUID._1202_VOLTAGERATIOINPUT_000:
				case DeviceChannelUID._1202_VOLTAGERATIOINPUT_120:
				case DeviceChannelUID._1202_VOLTAGERATIOINPUT_300:
				case DeviceChannelUID._HUB_VOLTAGERATIOINPUT_100:
				case DeviceChannelUID._HUB_VOLTAGERATIOINPUT_200:
				case DeviceChannelUID._DAQ1000_VOLTAGERATIOINPUT_100:
				case DeviceChannelUID._DAQ1000_VOLTAGERATIOINPUT_110:
				case DeviceChannelUID._DCC1000_VOLTAGERATIOINPUT_100:
				case DeviceChannelUID._DCC1000_VOLTAGERATIOINPUT_200:
					return true;
				default:
					return false;
			}
		default:
			return true;
	}
}

/** @internal */
export function supportedLEDForwardVoltage(ch: Channel, val: number) {

	if (val < Enum.LEDForwardVoltage.VOLTS_1_7 || val > Enum.LEDForwardVoltage.VOLTS_5_6)
		return false;

	switch(val) {
		case Enum.LEDForwardVoltage.VOLTS_1_7:
		case Enum.LEDForwardVoltage.VOLTS_2_75:
		case Enum.LEDForwardVoltage.VOLTS_3_9:
		case Enum.LEDForwardVoltage.VOLTS_5_0:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1031_DIGITALOUTPUT_100:
				case DeviceChannelUID._1032_DIGITALOUTPUT_200:
					return true;
				default:
					return false;
			}
		case Enum.LEDForwardVoltage.VOLTS_3_2:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._LED1000_DIGITALOUTPUT_100:
				case DeviceChannelUID._1030_DIGITALOUTPUT_100:
					return true;
				default:
					return false;
			}
		case Enum.LEDForwardVoltage.VOLTS_4_0:
		case Enum.LEDForwardVoltage.VOLTS_4_8:
		case Enum.LEDForwardVoltage.VOLTS_5_6:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._LED1000_DIGITALOUTPUT_100:
					return true;
				default:
					return false;
			}
		default:
			return true;
	}
}

/** @internal */
export function supportedRCServoVoltage(ch: Channel, val: number) {

	if (val < Enum.RCServoVoltage.VOLTS_5_0 || val > Enum.RCServoVoltage.VOLTS_7_4)
		return false;

	switch(val) {
		case Enum.RCServoVoltage.VOLTS_6_0:
		case Enum.RCServoVoltage.VOLTS_7_4:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._RCC1000_RCSERVO_100:
				case DeviceChannelUID._RCC1000_RCSERVO_110:
				case DeviceChannelUID._RCC1000_RCSERVO_200:
				case DeviceChannelUID._RCC0004_RCSERVO_400:
					return true;
				default:
					return false;
			}
		default:
			return true;
	}
}

/** @internal */
export function supportedVoltageOutputRange(ch: Channel, val: number) {

	if (val < Enum.VoltageOutputRange.VOLTS_10 || val > Enum.VoltageOutputRange.VOLTS_5)
		return false;

	switch(val) {
		case Enum.VoltageOutputRange.VOLTS_10:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1002_VOLTAGEOUTPUT_100:
				case DeviceChannelUID._OUT1001_VOLTAGEOUTPUT_100:
				case DeviceChannelUID._OUT1001_VOLTAGEOUTPUT_110:
				case DeviceChannelUID._OUT1002_VOLTAGEOUTPUT_100:
				case DeviceChannelUID._OUT1002_VOLTAGEOUTPUT_110:
					return true;
				default:
					return false;
			}
		case Enum.VoltageOutputRange.VOLTS_5:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._OUT1001_VOLTAGEOUTPUT_100:
				case DeviceChannelUID._OUT1001_VOLTAGEOUTPUT_110:
				case DeviceChannelUID._OUT1002_VOLTAGEOUTPUT_100:
				case DeviceChannelUID._OUT1002_VOLTAGEOUTPUT_110:
					return true;
				default:
					return false;
			}
		default:
			return true;
	}
}

/** @internal */
export function supportedVoltageRange(ch: Channel, val: number) {

	if (val < Enum.VoltageRange.MILLIVOLTS_10 || val > Enum.VoltageRange.AUTO)
		return false;

	switch(val) {
		case Enum.VoltageRange.MILLIVOLTS_10:
		case Enum.VoltageRange.MILLIVOLTS_40:
		case Enum.VoltageRange.MILLIVOLTS_200:
		case Enum.VoltageRange.MILLIVOLTS_1000:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._VCP1002_VOLTAGEINPUT_100:
				case DeviceChannelUID._VCP1002_VOLTAGEINPUT_110:
					return true;
				default:
					return false;
			}
		case Enum.VoltageRange.MILLIVOLTS_312_5:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._VCP1000_VOLTAGEINPUT_100:
					return true;
				default:
					return false;
			}
		case Enum.VoltageRange.MILLIVOLTS_400:
		case Enum.VoltageRange.VOLTS_2:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._ADP1000_VOLTAGEINPUT_100:
					return true;
				default:
					return false;
			}
		case Enum.VoltageRange.VOLTS_5:
		case Enum.VoltageRange.VOLTS_15:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._VCP1001_VOLTAGEINPUT_100:
				case DeviceChannelUID._VCP1001_VOLTAGEINPUT_110:
					return true;
				default:
					return false;
			}
		case Enum.VoltageRange.VOLTS_40:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._VCP1000_VOLTAGEINPUT_100:
				case DeviceChannelUID._VCP1001_VOLTAGEINPUT_100:
				case DeviceChannelUID._VCP1001_VOLTAGEINPUT_110:
					return true;
				default:
					return false;
			}
		case Enum.VoltageRange.AUTO:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._VCP1001_VOLTAGEINPUT_100:
				case DeviceChannelUID._VCP1001_VOLTAGEINPUT_110:
				case DeviceChannelUID._VCP1002_VOLTAGEINPUT_100:
				case DeviceChannelUID._VCP1002_VOLTAGEINPUT_110:
					return true;
				default:
					return false;
			}
		default:
			return true;
	}
}

/** @internal */
export function supportedVoltageSensorType(ch: Channel, val: number) {

	if (val < Enum.VoltageSensorType.VOLTAGE || val > Enum.VoltageSensorType.PN_VCP4114)
		return false;

	switch(val) {
		case Enum.VoltageSensorType.PN_1114:
		case Enum.VoltageSensorType.PN_1117:
		case Enum.VoltageSensorType.PN_1123:
		case Enum.VoltageSensorType.PN_1127:
		case Enum.VoltageSensorType.PN_1130_PH:
		case Enum.VoltageSensorType.PN_1130_ORP:
		case Enum.VoltageSensorType.PN_1132:
		case Enum.VoltageSensorType.PN_1133:
		case Enum.VoltageSensorType.PN_1135:
		case Enum.VoltageSensorType.PN_1142:
		case Enum.VoltageSensorType.PN_1143:
		case Enum.VoltageSensorType.PN_3500:
		case Enum.VoltageSensorType.PN_3501:
		case Enum.VoltageSensorType.PN_3502:
		case Enum.VoltageSensorType.PN_3503:
		case Enum.VoltageSensorType.PN_3585:
		case Enum.VoltageSensorType.PN_3586:
		case Enum.VoltageSensorType.PN_3587:
		case Enum.VoltageSensorType.PN_3588:
		case Enum.VoltageSensorType.PN_3589:
		case Enum.VoltageSensorType.PN_MOT2002_LOW:
		case Enum.VoltageSensorType.PN_MOT2002_MED:
		case Enum.VoltageSensorType.PN_MOT2002_HIGH:
		case Enum.VoltageSensorType.PN_VCP4114:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1011_VOLTAGEINPUT_000:
				case DeviceChannelUID._1013_VOLTAGEINPUT_000:
				case DeviceChannelUID._1018_VOLTAGEINPUT_821:
				case DeviceChannelUID._1018_VOLTAGEINPUT_900:
				case DeviceChannelUID._1018_VOLTAGEINPUT_1000:
				case DeviceChannelUID._1065_VOLTAGEINPUT_100:
				case DeviceChannelUID._1202_VOLTAGEINPUT_000:
				case DeviceChannelUID._1202_VOLTAGEINPUT_120:
				case DeviceChannelUID._1202_VOLTAGEINPUT_300:
				case DeviceChannelUID._HUB_VOLTAGEINPUT_100:
				case DeviceChannelUID._HUB_VOLTAGEINPUT_110:
				case DeviceChannelUID._HUB_VOLTAGEINPUT_200:
				case DeviceChannelUID._DAQ1000_VOLTAGEINPUT_100:
				case DeviceChannelUID._DAQ1000_VOLTAGEINPUT_110:
					return true;
				default:
					return false;
			}
		case Enum.VoltageSensorType.PN_3507:
		case Enum.VoltageSensorType.PN_3508:
		case Enum.VoltageSensorType.PN_3509:
		case Enum.VoltageSensorType.PN_3510:
		case Enum.VoltageSensorType.PN_3511:
		case Enum.VoltageSensorType.PN_3512:
		case Enum.VoltageSensorType.PN_3513:
		case Enum.VoltageSensorType.PN_3514:
		case Enum.VoltageSensorType.PN_3515:
		case Enum.VoltageSensorType.PN_3516:
		case Enum.VoltageSensorType.PN_3517:
		case Enum.VoltageSensorType.PN_3518:
		case Enum.VoltageSensorType.PN_3519:
		case Enum.VoltageSensorType.PN_3584:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1011_VOLTAGEINPUT_000:
				case DeviceChannelUID._1013_VOLTAGEINPUT_000:
				case DeviceChannelUID._1018_VOLTAGEINPUT_821:
				case DeviceChannelUID._1018_VOLTAGEINPUT_900:
				case DeviceChannelUID._1018_VOLTAGEINPUT_1000:
				case DeviceChannelUID._1065_VOLTAGEINPUT_100:
				case DeviceChannelUID._1202_VOLTAGEINPUT_000:
				case DeviceChannelUID._1202_VOLTAGEINPUT_120:
				case DeviceChannelUID._1202_VOLTAGEINPUT_300:
				case DeviceChannelUID._HUB_VOLTAGEINPUT_100:
				case DeviceChannelUID._HUB_VOLTAGEINPUT_110:
				case DeviceChannelUID._DAQ1000_VOLTAGEINPUT_100:
				case DeviceChannelUID._DAQ1000_VOLTAGEINPUT_110:
				case DeviceChannelUID._DAQ1400_VOLTAGEINPUT_100:
				case DeviceChannelUID._DAQ1400_VOLTAGEINPUT_120:
					return true;
				default:
					return false;
			}
		default:
			return true;
	}
}

/** @internal */
export function supportedRFIDProtocol(ch: Channel, val: number) {

	if (val < Enum.RFIDProtocol.EM4100 || val > Enum.RFIDProtocol.PHIDGET_TAG)
		return false;

	switch(val) {
		case Enum.RFIDProtocol.ISO11785_FDX_B:
		case Enum.RFIDProtocol.PHIDGET_TAG:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1024_RFID_100:
				case DeviceChannelUID._1024_RFID_200_USB:
				case DeviceChannelUID._1024_RFID_200_VINT:
					return true;
				default:
					return false;
			}
		default:
			return true;
	}
}

/** @internal */
export function supportedSpatialAlgorithm(_ch: Channel, val: number) {

	if (val < Enum.SpatialAlgorithm.NONE || val > Enum.SpatialAlgorithm.IMU)
		return false;

	return true;
}

/** @internal */
export function supportedRTDType(ch: Channel, val: number) {

	if (val < Enum.RTDType.PT100_3850 || val > Enum.RTDType.PT1000_3920)
		return false;

	switch(val) {
		case Enum.RTDType.PT100_3850:
		case Enum.RTDType.PT1000_3850:
		case Enum.RTDType.PT100_3920:
		case Enum.RTDType.PT1000_3920:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._TMP1200_TEMPERATURESENSOR_RTD_100:
				case DeviceChannelUID._TMP1200_TEMPERATURESENSOR_RTD_300:
				case DeviceChannelUID._TMP1200_TEMPERATURESENSOR_RTD_400:
					return true;
				default:
					return false;
			}
		default:
			return true;
	}
}

/** @internal */
export function supportedThermocoupleType(ch: Channel, val: number) {

	if (val < Enum.ThermocoupleType.J || val > Enum.ThermocoupleType.T)
		return false;

	switch(val) {
		case Enum.ThermocoupleType.J:
		case Enum.ThermocoupleType.E:
		case Enum.ThermocoupleType.T:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1048_TEMPERATURESENSOR_THERMOCOUPLE_100:
				case DeviceChannelUID._1048_TEMPERATURESENSOR_THERMOCOUPLE_200:
				case DeviceChannelUID._1051_TEMPERATURESENSOR_THERMOCOUPLE_200:
				case DeviceChannelUID._1051_TEMPERATURESENSOR_THERMOCOUPLE_300:
				case DeviceChannelUID._1051_TEMPERATURESENSOR_THERMOCOUPLE_400:
				case DeviceChannelUID._TMP1100_TEMPERATURESENSOR_THERMOCOUPLE_100:
				case DeviceChannelUID._TMP1101_TEMPERATURESENSOR_THERMOCOUPLE_100:
				case DeviceChannelUID._TMP1101_TEMPERATURESENSOR_THERMOCOUPLE_200:
					return true;
				default:
					return false;
			}
		case Enum.ThermocoupleType.K:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1048_TEMPERATURESENSOR_THERMOCOUPLE_100:
				case DeviceChannelUID._1048_TEMPERATURESENSOR_THERMOCOUPLE_200:
				case DeviceChannelUID._1051_TEMPERATURESENSOR_THERMOCOUPLE_000:
				case DeviceChannelUID._1051_TEMPERATURESENSOR_THERMOCOUPLE_200:
				case DeviceChannelUID._1051_TEMPERATURESENSOR_THERMOCOUPLE_300:
				case DeviceChannelUID._1051_TEMPERATURESENSOR_THERMOCOUPLE_400:
				case DeviceChannelUID._TMP1100_TEMPERATURESENSOR_THERMOCOUPLE_100:
				case DeviceChannelUID._TMP1101_TEMPERATURESENSOR_THERMOCOUPLE_100:
				case DeviceChannelUID._TMP1101_TEMPERATURESENSOR_THERMOCOUPLE_200:
					return true;
				default:
					return false;
			}
		default:
			return true;
	}
}

/** @internal */
export function supportedFrequencyFilterType(ch: Channel, val: number) {

	if (val < Enum.FrequencyFilterType.ZERO_CROSSING || val > Enum.FrequencyFilterType.LOGIC_LEVEL)
		return false;

	switch(val) {
		case Enum.FrequencyFilterType.ZERO_CROSSING:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1054_FREQUENCYCOUNTER_000:
					return true;
				default:
					return false;
			}
		case Enum.FrequencyFilterType.LOGIC_LEVEL:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1054_FREQUENCYCOUNTER_000:
				case DeviceChannelUID._DAQ1400_FREQUENCYCOUNTER_100:
				case DeviceChannelUID._DAQ1400_FREQUENCYCOUNTER_120:
					return true;
				default:
					return false;
			}
		default:
			return true;
	}
}

/** @internal */
export function supportedIRCodeEncoding(_ch: Channel, val: number) {

	if (val < Enum.IRCodeEncoding.UNKNOWN || val > Enum.IRCodeEncoding.RC6)
		return false;

	return true;
}

/** @internal */
export function supportedIRCodeLength(_ch: Channel, val: number) {

	if (val < Enum.IRCodeLength.UNKNOWN || val > Enum.IRCodeLength.VARIABLE)
		return false;

	return true;
}

/** @internal */
export function supportedStepperControlMode(_ch: Channel, val: number) {

	if (val < Enum.StepperControlMode.STEP || val > Enum.StepperControlMode.RUN)
		return false;

	return true;
}

/** @internal */
export function supportedLCDFont(ch: Channel, val: number) {

	if (val < Enum.LCDFont.USER1 || val > Enum.LCDFont.DIMENSIONS_6X12)
		return false;

	switch(val) {
		case Enum.LCDFont.USER1:
		case Enum.LCDFont.USER2:
		case Enum.LCDFont.DIMENSIONS_6X10:
		case Enum.LCDFont.DIMENSIONS_6X12:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._LCD1100_LCD_100:
				case DeviceChannelUID._LCD1100_LCD_200:
					return true;
				default:
					return false;
			}
		case Enum.LCDFont.DIMENSIONS_5X8:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1202_TEXTLCD_000:
				case DeviceChannelUID._1202_TEXTLCD_200:
				case DeviceChannelUID._1204_TEXTLCD_000:
				case DeviceChannelUID._1215_TEXTLCD_000:
				case DeviceChannelUID._1219_TEXTLCD_000:
				case DeviceChannelUID._LCD1100_LCD_100:
					return true;
				default:
					return false;
			}
		default:
			return true;
	}
}

/** @internal */
export function supportedLCDScreenSize(ch: Channel, val: number) {

	if (val < Enum.LCDScreenSize.NO_SCREEN || val > Enum.LCDScreenSize.DIMENSIONS_64X128)
		return false;

	switch(val) {
		case Enum.LCDScreenSize.NO_SCREEN:
		case Enum.LCDScreenSize.DIMENSIONS_1X8:
		case Enum.LCDScreenSize.DIMENSIONS_2X8:
		case Enum.LCDScreenSize.DIMENSIONS_1X16:
		case Enum.LCDScreenSize.DIMENSIONS_2X16:
		case Enum.LCDScreenSize.DIMENSIONS_4X16:
		case Enum.LCDScreenSize.DIMENSIONS_4X20:
		case Enum.LCDScreenSize.DIMENSIONS_2X24:
		case Enum.LCDScreenSize.DIMENSIONS_1X40:
		case Enum.LCDScreenSize.DIMENSIONS_2X40:
		case Enum.LCDScreenSize.DIMENSIONS_4X40:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1204_TEXTLCD_000:
					return true;
				default:
					return false;
			}
		case Enum.LCDScreenSize.DIMENSIONS_2X20:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._1202_TEXTLCD_000:
				case DeviceChannelUID._1202_TEXTLCD_200:
				case DeviceChannelUID._1204_TEXTLCD_000:
				case DeviceChannelUID._1215_TEXTLCD_000:
				case DeviceChannelUID._1219_TEXTLCD_000:
					return true;
				default:
					return false;
			}
		case Enum.LCDScreenSize.DIMENSIONS_64X128:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._LCD1100_LCD_100:
				case DeviceChannelUID._LCD1100_LCD_200:
					return true;
				default:
					return false;
			}
		default:
			return true;
	}
}

/** @internal */
export function supportedLCDPixelState(ch: Channel, val: number) {

	if (val < Enum.LCDPixelState.OFF || val > Enum.LCDPixelState.INVERT)
		return false;

	switch(val) {
		case Enum.LCDPixelState.OFF:
		case Enum.LCDPixelState.ON:
		case Enum.LCDPixelState.INVERT:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._LCD1100_LCD_100:
				case DeviceChannelUID._LCD1100_LCD_200:
					return true;
				default:
					return false;
			}
		default:
			return true;
	}
}

/** @internal */
export function supportedDataAdapterParity(ch: Channel, val: number) {

	if (val < Enum.DataAdapterParity.NONE || val > Enum.DataAdapterParity.ODD)
		return false;

	switch(val) {
		case Enum.DataAdapterParity.NONE:
		case Enum.DataAdapterParity.EVEN:
		case Enum.DataAdapterParity.ODD:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._ADP1001_DATAADAPTER_100_USB:
				case DeviceChannelUID._ADP1001_DATAADAPTER_100_VINT:
				case DeviceChannelUID._ADP_RS485_422_DATAADAPTER_100_USB:
				case DeviceChannelUID._ADP_RS485_422_DATAADAPTER_100_VINT:
				case DeviceChannelUID._ADP_SERIAL_DATAADAPTER_100_USB:
				case DeviceChannelUID._ADP_SERIAL_DATAADAPTER_100_VINT:
					return true;
				default:
					return false;
			}
		default:
			return true;
	}
}

/** @internal */
export function supportedDataAdapterStopBits(ch: Channel, val: number) {

	if (val < Enum.DataAdapterStopBits.ONE || val > Enum.DataAdapterStopBits.TWO)
		return false;

	switch(val) {
		case Enum.DataAdapterStopBits.ONE:
		case Enum.DataAdapterStopBits.TWO:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._ADP1001_DATAADAPTER_100_USB:
				case DeviceChannelUID._ADP1001_DATAADAPTER_100_VINT:
				case DeviceChannelUID._ADP_RS485_422_DATAADAPTER_100_USB:
				case DeviceChannelUID._ADP_RS485_422_DATAADAPTER_100_VINT:
				case DeviceChannelUID._ADP_SERIAL_DATAADAPTER_100_USB:
				case DeviceChannelUID._ADP_SERIAL_DATAADAPTER_100_VINT:
					return true;
				default:
					return false;
			}
		default:
			return true;
	}
}

/** @internal */
export function supportedDataAdapterHandshakeMode(ch: Channel, val: number) {

	if (val < Enum.DataAdapterHandshakeMode.NONE || val > Enum.DataAdapterHandshakeMode.READY_TO_RECEIVE)
		return false;

	switch(val) {
		case Enum.DataAdapterHandshakeMode.NONE:
		case Enum.DataAdapterHandshakeMode.REQUEST_TO_SEND:
		case Enum.DataAdapterHandshakeMode.READY_TO_RECEIVE:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._ADP1001_DATAADAPTER_100_USB:
				case DeviceChannelUID._ADP1001_DATAADAPTER_100_VINT:
				case DeviceChannelUID._ADP_SERIAL_DATAADAPTER_100_USB:
				case DeviceChannelUID._ADP_SERIAL_DATAADAPTER_100_VINT:
					return true;
				default:
					return false;
			}
		default:
			return true;
	}
}

/** @internal */
export function supportedDataAdapterProtocol(ch: Channel, val: number) {

	if (val < Enum.DataAdapterProtocol.RS485 || val > Enum.DataAdapterProtocol.RS232)
		return false;

	switch(val) {
		case Enum.DataAdapterProtocol.RS485:
		case Enum.DataAdapterProtocol.RS422:
		case Enum.DataAdapterProtocol.DMX512:
		case Enum.DataAdapterProtocol.MODBUS_RTU:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._ADP_RS485_422_DATAADAPTER_100_USB:
				case DeviceChannelUID._ADP_RS485_422_DATAADAPTER_100_VINT:
					return true;
				default:
					return false;
			}
		case Enum.DataAdapterProtocol.SPI:
		case Enum.DataAdapterProtocol.I2C:
		case Enum.DataAdapterProtocol.UART:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._ADP_SERIAL_DATAADAPTER_100_USB:
				case DeviceChannelUID._ADP_SERIAL_DATAADAPTER_100_VINT:
					return true;
				default:
					return false;
			}
		case Enum.DataAdapterProtocol.RS232:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._ADP1001_DATAADAPTER_100_USB:
				case DeviceChannelUID._ADP1001_DATAADAPTER_100_VINT:
					return true;
				default:
					return false;
			}
		default:
			return true;
	}
}

/** @internal */
export function supportedDataAdapterSPIMode(ch: Channel, val: number) {

	if (val < Enum.DataAdapterSPIMode.MODE_0 || val > Enum.DataAdapterSPIMode.MODE_3)
		return false;

	switch(val) {
		case Enum.DataAdapterSPIMode.MODE_0:
		case Enum.DataAdapterSPIMode.MODE_1:
		case Enum.DataAdapterSPIMode.MODE_2:
		case Enum.DataAdapterSPIMode.MODE_3:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._ADP_SERIAL_DATAADAPTER_100_USB:
				case DeviceChannelUID._ADP_SERIAL_DATAADAPTER_100_VINT:
					return true;
				default:
					return false;
			}
		default:
			return true;
	}
}

/** @internal */
export function supportedDataAdapterEndianness(ch: Channel, val: number) {

	if (val < Enum.DataAdapterEndianness.MSB_FIRST || val > Enum.DataAdapterEndianness.LSB_FIRST)
		return false;

	switch(val) {
		case Enum.DataAdapterEndianness.MSB_FIRST:
		case Enum.DataAdapterEndianness.LSB_FIRST:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._ADP1001_DATAADAPTER_100_USB:
				case DeviceChannelUID._ADP1001_DATAADAPTER_100_VINT:
				case DeviceChannelUID._ADP_RS485_422_DATAADAPTER_100_USB:
				case DeviceChannelUID._ADP_RS485_422_DATAADAPTER_100_VINT:
				case DeviceChannelUID._ADP_SERIAL_DATAADAPTER_100_USB:
				case DeviceChannelUID._ADP_SERIAL_DATAADAPTER_100_VINT:
					return true;
				default:
					return false;
			}
		default:
			return true;
	}
}

/** @internal */
export function supportedDataAdapterIOVoltage(ch: Channel, val: number) {

	if (val < Enum.DataAdapterIOVoltage.EXTERNAL || val > Enum.DataAdapterIOVoltage.VOLTS_5)
		return false;

	switch(val) {
		case Enum.DataAdapterIOVoltage.EXTERNAL:
		case Enum.DataAdapterIOVoltage.VOLTS_1_8:
		case Enum.DataAdapterIOVoltage.VOLTS_2_5:
		case Enum.DataAdapterIOVoltage.VOLTS_3_3:
		case Enum.DataAdapterIOVoltage.VOLTS_5:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._ADP_SERIAL_DATAADAPTER_100_USB:
				case DeviceChannelUID._ADP_SERIAL_DATAADAPTER_100_VINT:
					return true;
				default:
					return false;
			}
		default:
			return true;
	}
}

/** @internal */
export function supportedPacketErrorCode(ch: Channel, val: number) {

	if (val < Enum.PacketErrorCode.OK || val > Enum.PacketErrorCode.CORRUPT)
		return false;

	switch(val) {
		case Enum.PacketErrorCode.OK:
		case Enum.PacketErrorCode.UNKNOWN:
		case Enum.PacketErrorCode.TIMEOUT:
		case Enum.PacketErrorCode.FORMAT:
		case Enum.PacketErrorCode.INVALID:
		case Enum.PacketErrorCode.OVERRUN:
		case Enum.PacketErrorCode.CORRUPT:
			switch(ch.chDef.uid) {
				case DeviceChannelUID._ADP1001_DATAADAPTER_100_USB:
				case DeviceChannelUID._ADP1001_DATAADAPTER_100_VINT:
				case DeviceChannelUID._ADP_RS485_422_DATAADAPTER_100_USB:
				case DeviceChannelUID._ADP_RS485_422_DATAADAPTER_100_VINT:
				case DeviceChannelUID._ADP_SERIAL_DATAADAPTER_100_USB:
				case DeviceChannelUID._ADP_SERIAL_DATAADAPTER_100_VINT:
					return true;
				default:
					return false;
			}
		default:
			return true;
	}
}

/** @internal */
export function supportedSPLRange(_ch: Channel, val: number) {

	if (val < Enum.SPLRange.DB_102 || val > Enum.SPLRange.DB_82)
		return false;

	return true;
}

/** @internal */
export function supportedHubPortMode(_ch: Channel, val: number) {

	if (val < Enum.HubPortMode.VINT || val > Enum.HubPortMode.VOLTAGE_RATIO_INPUT)
		return false;

	return true;
}

