import sha256hash from './sha256';
import { DecodeUTF8, EncodeUTF8, NetworkConnectionBase, type NetworkConnectionOptions } from './NetworkConnection';
import { Request } from './Request';
import { PhidgetError } from '../PhidgetError';
import { ErrorCode } from '../Enumerations.gen';
import { logerr, logEventException, loginfo } from '../Logging';

/**
 * @public
 */
export class NetworkConnection extends NetworkConnectionBase {
	/** @internal */
	private _ws?: WebSocket;
	/** @internal */
	private _connectTimeout?: NodeJS.Timeout;

	constructor(options: NetworkConnectionOptions);
	constructor(uri: string, options?: NetworkConnectionOptions);
	constructor(port: number, hostname?: string, options?: NetworkConnectionOptions);
	constructor(optsOrUriOrPort?: NetworkConnectionOptions | string | number, optsOrHostname?: NetworkConnectionOptions | string, opts?: NetworkConnectionOptions) {
		super(optsOrUriOrPort, optsOrHostname, opts);

		if (this._port === 0)
			this._port = 8989;

		if (this._uri === '')
			this._uri = 'ws://' + this._hostname + ':' + this._port + '/phidgets';

		this._protocol = "www";
	}

	/**
	 * Connects to the server. Once the initial connection has succeeded, 
	 * it will be maintained until close is called.
	 * 
	 * It retryOnFail is true, the initial connect will retry on failure until a connection is established, 
	 * and connect() will not resolve until the connection is established.
	 * Thisis allows connect to be called before the network server is running.
	 * 
	 * If retryOnFail is false (the default), connect() will throw an exception 
	 * if the connection can not be established.
	 * 
	 * @param retryOnFail - Keep trying to connect if the initial attempt fails. Defaults to false.
	 */
	connect(retryOnFail = false): Promise<void> {
		return (new Promise<void>((resolve, reject) => {

			this._opened = true;

			if (this.connected === true) {
				resolve();
				return;
			}

			if (retryOnFail) {
				this._resolveConnect = resolve;
				this._maintainConnection();
				return;
			}

			if (this._ws) {
				try {
					this._ws.close();
				} catch (e) {
					// Ignored
				}
				delete this._ws;
			}

			let hasCompleted = false;
			try {
				this._ws = new WebSocket(this._uri);
				this._ws.binaryType = 'arraybuffer';

				this._ws.onopen = () => {
					this._handshake().then(() => {
						// Resolve a retry on fail connect - on the first connection success
						if (this._resolveConnect) {
							this._resolveConnect();
							delete this._resolveConnect;
						}
						if (this._connectTimeout !== undefined) {
							clearTimeout(this._connectTimeout);
							delete this._connectTimeout;
						}
						hasCompleted = true;
						resolve();
					}).catch(err => {
						if (this._connectTimeout !== undefined) {
							clearTimeout(this._connectTimeout);
							delete this._connectTimeout;
						}
						if (!hasCompleted) {
							hasCompleted = true;
							reject(err);
						}
					});
				};

				this._ws.onclose = () => {
					this._doclose();
					if (!hasCompleted) {
						hasCompleted = true;
						reject(new PhidgetError(ErrorCode.CONNECTION_RESET, "Socket closed"));
					}
				};

				this._ws.onmessage = (event: MessageEvent) => {
					try {
						const array = new Uint8Array(event.data);
						const req = new Request(array);
						let msg;
						if (req.len > 0) {
							const tmp1 = new Uint8Array(event.data, req.hdrlen, req.len);
							msg = DecodeUTF8(tmp1);
						}
						this._onmessage(msg, req);
					} catch (e) {
						const msg = "Error handling data from server - resetting connection";
						const perr = new PhidgetError(ErrorCode.UNEXPECTED, msg, e);

						this._doclose();

						if (hasCompleted) {
							if (this.onError) {
								try {
									this.onError(perr.errorCode, perr.message);
								} catch (err) { logEventException(err); }
								loginfo(perr.message);
							} else {
								logerr(msg, e);
							}
						} else {
							hasCompleted = true;
							reject(perr);
						}
					}
				};

				this._ws.onerror = () => {
					const msg = "websocket error - check that server is available";

					this._doclose();

					if (!hasCompleted) {
						hasCompleted = true;
						reject(new PhidgetError(ErrorCode.CONNECTION_REFUSED, msg));
						return;
					}

					if (this.onError) {
						try {
							this.onError(ErrorCode.CONNECTION_REFUSED, msg);
						} catch (err) { logEventException(err); }
						loginfo(msg);
					} else {
						logerr(msg);
					}
				};

				// Guarantee that connect() will resolve or reject within timeout
				this._connectTimeout = setTimeout(() => {
					delete this._connectTimeout;
					this._doclose();
					if (!hasCompleted) {
						hasCompleted = true;
						reject(new PhidgetError(ErrorCode.TIMEOUT, "Connection Timed Out"));
					}
				}, this._timeout);

			} catch (err) {
				if (this._connectTimeout !== undefined) {
					clearTimeout(this._connectTimeout);
					delete this._connectTimeout;
				}
				if (!hasCompleted) {
					hasCompleted = true;
					reject(new PhidgetError(ErrorCode.UNEXPECTED, 'Error in connect', err));
				}
			}
		}));
	}

	/** @internal */
	protected _closesocket() {

		if (this.connected === true) {
			if (this.onDisconnect) {
				try {
					this.onDisconnect();
				} catch (err) { logEventException(err); }
			}
		}
		this.connected = false;

		if (this._connectTimeout !== undefined) {
			clearTimeout(this._connectTimeout);
			delete this._connectTimeout;
		}
		if (this._ws != undefined) {
			try {
				// Deregister events to make sure we don't get any after a close/connect cycle
				this._ws.onopen = null;
				this._ws.onclose = null;
				this._ws.onmessage = null;
				this._ws.onerror = null;
				this._ws.close();
			} catch {
				// Ignored
			}
			delete this._ws;
		}

		this._generation++;
	}

	/** @internal */
	protected _send(req: Request, data: string) {
		try {
			if (!this._ws || this._ws.readyState != WebSocket.OPEN)
				throw new PhidgetError(ErrorCode.UNEXPECTED, 'invalid websocket state');

			if (data.length > 0) {
				// NOTE: I'm not exactly sure why it's neccesssary to convert to UTF8 for websocket but not Node socket..
				const dataArr = EncodeUTF8(data);
				req.len = dataArr.length;
				this._ws.send(req.buffer);
				this._ws.send(dataArr);
			} else {
				this._ws.send(req.buffer);
			}
		} catch (e) {
			let msg;
			if (typeof e === 'string')
				msg = e;
			else if (e instanceof Error)
				msg = e.message;
			else
				msg = 'Error in connect';
			throw new PhidgetError(ErrorCode.UNEXPECTED, msg);
		}
	}

	/** @internal */
	protected _hash(challenge: string) {
		const digest = sha256hash(challenge);

		let bin = '';
		for (let i = 0; i < digest.length; i += 2) {
			const b = parseInt(digest.substring(i, i + 2), 16);
			bin += String.fromCharCode(b);
		}

		return (btoa(bin));
	}

	/** @internal */
	protected _createSalt(len: number) {
		const buf = new Uint8Array(len);
		crypto.getRandomValues(buf);
		return (btoa(String.fromCharCode(...buf)).substring(len));
	}
}