/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PhidgetChannel } from '../Phidget';
import { Channel } from '../Channel';
import { ErrorCode, ChannelClass } from '../Enumerations.gen';
import * as Enum from '../Enumerations.gen';
import * as SEnum from '../SupportedEnum.gen';
import { PhidgetError } from '../PhidgetError';
import { BridgePacket, PUNK } from '../BridgePacket';
import { BP } from '../BridgePackets.gen';
import { logEventException } from '../Logging';
import { DeviceChannelUID } from '../Devices.gen';

/** @internal */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface MotorPositionControllerData {
	positionOffset: number, 
	dataInterval: number,
	minDataInterval: number,
	maxDataInterval: number,
	minDataRate: number,
	maxDataRate: number,
	currentLimit: number,
	maxCurrentLimit: number,
	minCurrentLimit: number,
	currentRegulatorGain: number,
	dutyCycle: number,
	engaged: number,
	maxCurrentRegulatorGain: number,
	minCurrentRegulatorGain: number,
	velocityLimit: number,
	minVelocityLimit: number,
	maxVelocityLimit: number,
	maxAcceleration: number,
	minAcceleration: number,
	acceleration: number,
	targetPosition: number,
	position: number,
	maxPosition: number,
	minPosition: number,
	rescaleFactor: number,
	fanMode: Enum.FanMode | PUNK.ENUM,
	deadBand: number,
	kp: number,
	kd: number,
	ki: number,
	IOMode: Enum.EncoderIOMode | PUNK.ENUM,
	stallVelocity: number,
	minStallVelocity: number,
	maxStallVelocity: number,
	maxFailsafeTime: number,
	minFailsafeTime: number,
}

abstract class MotorPositionControllerBase extends PhidgetChannel {
	/** @internal */
	data: MotorPositionControllerData;
	/**
	 * **PositionChange** event
	 *  * `position` - The position value
	 * ---
	 * The most recent position value will be reported in this event, which occurs when the `dataInterval` has elapsed.
	 * 
	 * *   Regardless of the `dataInterval`, this event will occur only when the position value has changed from the previous value reported.
	 * *   Units for `position` can be set by the user through the `rescaleFactor`. The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 */
	onPositionChange: ((position: number) => void) | null = null;
	/** @internal */
	_gotPositionChangeErrorEvent?: boolean;
	/**
	 * **DutyCycleUpdate** event
	 *  * `dutyCycle` - The duty cycle value
	 * ---
	 * The most recent duty cycle value will be reported in this event, which occurs when the `dataInterval` has elapsed.
	 * 
	 * *   This event will **always** occur when the `dataInterval` elapses. You can depend on this event for constant timing when implementing control loops in code. This is the last event to fire, giving you up-to-date access to all properties.
	 */
	onDutyCycleUpdate: ((dutyCycle: number) => void) | null = null;
	/** @internal */
	_gotDutyCycleUpdateErrorEvent?: boolean;

	/**
	 * The Motor Position Controller class controlls the position, velocity and acceleration of the attached motor. It also contains various other control and monitoring functions that aid in the control of the motor.
	 * 
	 * For specifics on how to use this class, we recommend watching our video on the [Phidget Motor Position Controller](https://www.youtube.com/watch?v=0cQlxNd7dk4) class.
	 * @public
	 */
	constructor();
	/** @internal */
	constructor(ch?: Channel);
	constructor(ch?: Channel) {
		super(ch);
		this._class = ChannelClass.MOTOR_POSITION_CONTROLLER;
		this.name = "MotorPositionController";
		this.data = this._initData();
	}

	/** @internal */
	_bridgeInput(bp: BridgePacket) {

		switch(bp.vpkt) {
		case BP.SETACCELERATION:
			this.data.acceleration = bp.entries[0].v as number;
			this._FIREPropertyChange('Acceleration', bp);
			break;
		case BP.SETCURRENTLIMIT:
			this.data.currentLimit = bp.entries[0].v as number;
			this._FIREPropertyChange('CurrentLimit', bp);
			break;
		case BP.SETCURRENTREGULATORGAIN:
			this.data.currentRegulatorGain = bp.entries[0].v as number;
			this._FIREPropertyChange('CurrentRegulatorGain', bp);
			break;
		case BP.SETDATAINTERVAL:
			if (bp.entryCount > 1)
				this.data.dataInterval = bp.entries[1].v as number;
			else
				this.data.dataInterval = bp.entries[0].v as number;
			this._FIREPropertyChange('DataInterval', bp);
			this._FIREPropertyChange('DataRate', bp);
			break;
		case BP.SETDEADBAND:
			this.data.deadBand = bp.entries[0].v as number;
			this._FIREPropertyChange('DeadBand', bp);
			break;
		case BP.SETENGAGED:
			this.data.engaged = bp.entries[0].v as number;
			this._FIREPropertyChange('Engaged', bp);
			break;
		case BP.SETFAILSAFETIME:
			break;
		case BP.SETFANMODE:
			this.data.fanMode = bp.entries[0].v as Enum.FanMode;
			this._FIREPropertyChange('FanMode', bp);
			break;
		case BP.SETIOMODE:
			this.data.IOMode = bp.entries[0].v as Enum.EncoderIOMode;
			this._FIREPropertyChange('IOMode', bp);
			break;
		case BP.SETKD:
			this.data.kd = bp.entries[0].v as number;
			this._FIREPropertyChange('Kd', bp);
			break;
		case BP.SETKI:
			this.data.ki = bp.entries[0].v as number;
			this._FIREPropertyChange('Ki', bp);
			break;
		case BP.SETKP:
			this.data.kp = bp.entries[0].v as number;
			this._FIREPropertyChange('Kp', bp);
			break;
		case BP.FAILSAFERESET:
			break;
		case BP.SETSTALLVELOCITY:
			this.data.stallVelocity = bp.entries[0].v as number;
			this._FIREPropertyChange('StallVelocity', bp);
			break;
		case BP.SETTARGETPOSITION:
			this.data.targetPosition = bp.entries[0].v as number;
			this._FIREPropertyChange('TargetPosition', bp);
			break;
		case BP.SETDUTYCYCLE:
			this.data.velocityLimit = bp.entries[0].v as number;
			this._FIREPropertyChange('VelocityLimit', bp);
			break;
		case BP.DUTYCYCLECHANGE: {
			this.data.dutyCycle = bp.entries[0].v as number;
			if (this._isAttachedDone && this.onDutyCycleUpdate) {
				try {
					this.onDutyCycleUpdate(this.data.dutyCycle);
				} catch (err) { logEventException(err); }
			}
			break;
		}
		default:
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			throw new PhidgetError(ErrorCode.INVALID_PACKET, "Unsupported bridge packet: 0x" + bp.vpkt!.toString(16));
		}
	}

	/** @internal */
	_initData(): MotorPositionControllerData {
		return {
			positionOffset: 0, 
			dataInterval: PUNK.DBL,
			minDataInterval: PUNK.UINT32,
			maxDataInterval: PUNK.UINT32,
			minDataRate: PUNK.DBL,
			maxDataRate: PUNK.DBL,
			currentLimit: PUNK.DBL,
			maxCurrentLimit: PUNK.DBL,
			minCurrentLimit: PUNK.DBL,
			currentRegulatorGain: PUNK.DBL,
			dutyCycle: PUNK.DBL,
			engaged: PUNK.BOOL,
			maxCurrentRegulatorGain: PUNK.DBL,
			minCurrentRegulatorGain: PUNK.DBL,
			velocityLimit: PUNK.DBL,
			minVelocityLimit: PUNK.DBL,
			maxVelocityLimit: PUNK.DBL,
			maxAcceleration: PUNK.DBL,
			minAcceleration: PUNK.DBL,
			acceleration: PUNK.DBL,
			targetPosition: PUNK.INT64,
			position: PUNK.INT64,
			maxPosition: PUNK.INT64,
			minPosition: PUNK.INT64,
			rescaleFactor: PUNK.DBL,
			fanMode: PUNK.ENUM,
			deadBand: PUNK.UINT32,
			kp: PUNK.DBL,
			kd: PUNK.DBL,
			ki: PUNK.DBL,
			IOMode: PUNK.ENUM,
			stallVelocity: PUNK.DBL,
			minStallVelocity: PUNK.DBL,
			maxStallVelocity: PUNK.DBL,
			maxFailsafeTime: PUNK.UINT32,
			minFailsafeTime: PUNK.UINT32,
		}
	}

	/** @internal */
	_initAfterOpen() {
		this.data = this._initData();

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._DCC1000_MOTORPOSITIONCONTROLLER_200:
			this.data.dataInterval = 100;
			this.data.minDataInterval = 20;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 50;
			this.data.currentLimit = 2;
			this.data.maxCurrentLimit = 25;
			this.data.minCurrentLimit = 2;
			this.data.currentRegulatorGain = 10;
			this.data.engaged = 0;
			this.data.maxCurrentRegulatorGain = 100;
			this.data.minCurrentRegulatorGain = 1;
			this.data.velocityLimit = 20000;
			this.data.minVelocityLimit = 0;
			this.data.maxVelocityLimit = 250000;
			this.data.maxAcceleration = 10000000;
			this.data.minAcceleration = 0.1;
			this.data.acceleration = 10000;
			this.data.targetPosition = 0;
			this.data.maxPosition = 1000000000000000;
			this.data.minPosition = -1000000000000000;
			this.data.rescaleFactor = 1;
			this.data.fanMode = Enum.FanMode.AUTO;
			this.data.deadBand = 0;
			this.data.kp = 2000;
			this.data.kd = 25000;
			this.data.ki = 5;
			this.data.IOMode = Enum.EncoderIOMode.PUSH_PULL;
			break;
		case DeviceChannelUID._DCC1000_MOTORPOSITIONCONTROLLER_210:
			this.data.dataInterval = 100;
			this.data.minDataInterval = 20;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 50;
			this.data.currentLimit = 2;
			this.data.maxCurrentLimit = 25;
			this.data.minCurrentLimit = 2;
			this.data.currentRegulatorGain = 10;
			this.data.engaged = 0;
			this.data.maxCurrentRegulatorGain = 100;
			this.data.minCurrentRegulatorGain = 1;
			this.data.velocityLimit = 20000;
			this.data.minVelocityLimit = 0;
			this.data.maxVelocityLimit = 250000;
			this.data.maxAcceleration = 10000000;
			this.data.minAcceleration = 0.1;
			this.data.acceleration = 10000;
			this.data.targetPosition = 0;
			this.data.maxPosition = 1000000000000000;
			this.data.minPosition = -1000000000000000;
			this.data.rescaleFactor = 1;
			this.data.fanMode = Enum.FanMode.AUTO;
			this.data.deadBand = 0;
			this.data.kp = 2000;
			this.data.kd = 25000;
			this.data.ki = 5;
			this.data.IOMode = Enum.EncoderIOMode.PUSH_PULL;
			this.data.maxFailsafeTime = 30000;
			this.data.minFailsafeTime = 500;
			break;
		case DeviceChannelUID._DCC1001_MOTORPOSITIONCONTROLLER_100:
			this.data.dataInterval = 100;
			this.data.minDataInterval = 20;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 50;
			this.data.currentLimit = 1;
			this.data.maxCurrentLimit = 2;
			this.data.minCurrentLimit = 0;
			this.data.engaged = 0;
			this.data.velocityLimit = 20000;
			this.data.minVelocityLimit = 0;
			this.data.maxVelocityLimit = 250000;
			this.data.maxAcceleration = 10000000;
			this.data.minAcceleration = 0.1;
			this.data.acceleration = 10000;
			this.data.targetPosition = 0;
			this.data.maxPosition = 1000000000000000;
			this.data.minPosition = -1000000000000000;
			this.data.rescaleFactor = 1;
			this.data.deadBand = 0;
			this.data.kp = 2000;
			this.data.kd = 25000;
			this.data.ki = 5;
			break;
		case DeviceChannelUID._DCC1001_MOTORPOSITIONCONTROLLER_120:
			this.data.dataInterval = 100;
			this.data.minDataInterval = 20;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 50;
			this.data.currentLimit = 1;
			this.data.maxCurrentLimit = 2;
			this.data.minCurrentLimit = 0;
			this.data.engaged = 0;
			this.data.velocityLimit = 20000;
			this.data.minVelocityLimit = 0;
			this.data.maxVelocityLimit = 250000;
			this.data.maxAcceleration = 10000000;
			this.data.minAcceleration = 0.1;
			this.data.acceleration = 10000;
			this.data.targetPosition = 0;
			this.data.maxPosition = 1000000000000000;
			this.data.minPosition = -1000000000000000;
			this.data.rescaleFactor = 1;
			this.data.deadBand = 0;
			this.data.kp = 2000;
			this.data.kd = 25000;
			this.data.ki = 5;
			this.data.maxFailsafeTime = 30000;
			this.data.minFailsafeTime = 500;
			break;
		case DeviceChannelUID._DCC1002_MOTORPOSITIONCONTROLLER_100:
			this.data.dataInterval = 100;
			this.data.minDataInterval = 20;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 50;
			this.data.currentLimit = 1;
			this.data.maxCurrentLimit = 3.5;
			this.data.minCurrentLimit = 0;
			this.data.engaged = 0;
			this.data.velocityLimit = 20000;
			this.data.minVelocityLimit = 0;
			this.data.maxVelocityLimit = 250000;
			this.data.maxAcceleration = 10000000;
			this.data.minAcceleration = 0.1;
			this.data.acceleration = 10000;
			this.data.targetPosition = 0;
			this.data.maxPosition = 1000000000000000;
			this.data.minPosition = -1000000000000000;
			this.data.rescaleFactor = 1;
			this.data.deadBand = 0;
			this.data.kp = 2000;
			this.data.kd = 25000;
			this.data.ki = 5;
			break;
		case DeviceChannelUID._DCC1002_MOTORPOSITIONCONTROLLER_110:
			this.data.dataInterval = 100;
			this.data.minDataInterval = 20;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 50;
			this.data.currentLimit = 1;
			this.data.maxCurrentLimit = 4;
			this.data.minCurrentLimit = 0;
			this.data.engaged = 0;
			this.data.velocityLimit = 20000;
			this.data.minVelocityLimit = 0;
			this.data.maxVelocityLimit = 250000;
			this.data.maxAcceleration = 10000000;
			this.data.minAcceleration = 0.1;
			this.data.acceleration = 10000;
			this.data.targetPosition = 0;
			this.data.maxPosition = 1000000000000000;
			this.data.minPosition = -1000000000000000;
			this.data.rescaleFactor = 1;
			this.data.deadBand = 0;
			this.data.kp = 2000;
			this.data.kd = 25000;
			this.data.ki = 5;
			this.data.maxFailsafeTime = 30000;
			this.data.minFailsafeTime = 500;
			break;
		case DeviceChannelUID._DCC1100_MOTORPOSITIONCONTROLLER_100:
			this.data.dataInterval = 100;
			this.data.minDataInterval = 20;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 50;
			this.data.engaged = 0;
			this.data.velocityLimit = 1000;
			this.data.minVelocityLimit = 0;
			this.data.maxVelocityLimit = 10000;
			this.data.maxAcceleration = 100000;
			this.data.minAcceleration = 0.1;
			this.data.acceleration = 100;
			this.data.targetPosition = 0;
			this.data.maxPosition = 1000000000000000;
			this.data.minPosition = -1000000000000000;
			this.data.rescaleFactor = 1;
			this.data.deadBand = 0;
			this.data.kp = 20000;
			this.data.kd = 40000;
			this.data.ki = 2;
			this.data.stallVelocity = 400;
			this.data.minStallVelocity = 0;
			this.data.maxStallVelocity = 2000;
			break;
		case DeviceChannelUID._DCC1100_MOTORPOSITIONCONTROLLER_120:
			this.data.dataInterval = 100;
			this.data.minDataInterval = 20;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 50;
			this.data.engaged = 0;
			this.data.velocityLimit = 1000;
			this.data.minVelocityLimit = 0;
			this.data.maxVelocityLimit = 10000;
			this.data.maxAcceleration = 100000;
			this.data.minAcceleration = 0.1;
			this.data.acceleration = 100;
			this.data.targetPosition = 0;
			this.data.maxPosition = 1000000000000000;
			this.data.minPosition = -1000000000000000;
			this.data.rescaleFactor = 1;
			this.data.deadBand = 0;
			this.data.kp = 20000;
			this.data.kd = 40000;
			this.data.ki = 2;
			this.data.stallVelocity = 400;
			this.data.minStallVelocity = 0;
			this.data.maxStallVelocity = 2000;
			this.data.maxFailsafeTime = 30000;
			this.data.minFailsafeTime = 500;
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	// eslint-disable-next-line require-await
	async _setDefaults() {
		let bp;

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._DCC1000_MOTORPOSITIONCONTROLLER_200:
		case DeviceChannelUID._DCC1000_MOTORPOSITIONCONTROLLER_210:
			break;
		case DeviceChannelUID._DCC1001_MOTORPOSITIONCONTROLLER_100:
		case DeviceChannelUID._DCC1001_MOTORPOSITIONCONTROLLER_120:
		case DeviceChannelUID._DCC1002_MOTORPOSITIONCONTROLLER_100:
		case DeviceChannelUID._DCC1002_MOTORPOSITIONCONTROLLER_110:
			bp = new BridgePacket();
			bp.set({ name: "0", type: "u", value: Math.round(this.data.dataInterval) });
			await bp.send(this._ch, BP.SETDATAINTERVAL);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.currentLimit });
			await bp.send(this._ch, BP.SETCURRENTLIMIT);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "d", value: this.data.engaged });
			await bp.send(this._ch, BP.SETENGAGED);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.velocityLimit });
			await bp.send(this._ch, BP.SETDUTYCYCLE);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.acceleration });
			await bp.send(this._ch, BP.SETACCELERATION);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "l", value: this.data.targetPosition });
			await bp.send(this._ch, BP.SETTARGETPOSITION);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "u", value: this.data.deadBand });
			await bp.send(this._ch, BP.SETDEADBAND);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.kp });
			await bp.send(this._ch, BP.SETKP);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.kd });
			await bp.send(this._ch, BP.SETKD);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.ki });
			await bp.send(this._ch, BP.SETKI);
			break;
		case DeviceChannelUID._DCC1100_MOTORPOSITIONCONTROLLER_100:
		case DeviceChannelUID._DCC1100_MOTORPOSITIONCONTROLLER_120:
			bp = new BridgePacket();
			bp.set({ name: "0", type: "u", value: Math.round(this.data.dataInterval) });
			await bp.send(this._ch, BP.SETDATAINTERVAL);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "d", value: this.data.engaged });
			await bp.send(this._ch, BP.SETENGAGED);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.velocityLimit });
			await bp.send(this._ch, BP.SETDUTYCYCLE);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.acceleration });
			await bp.send(this._ch, BP.SETACCELERATION);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "l", value: this.data.targetPosition });
			await bp.send(this._ch, BP.SETTARGETPOSITION);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "u", value: this.data.deadBand });
			await bp.send(this._ch, BP.SETDEADBAND);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.kp });
			await bp.send(this._ch, BP.SETKP);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.kd });
			await bp.send(this._ch, BP.SETKD);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.ki });
			await bp.send(this._ch, BP.SETKI);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.stallVelocity });
			await bp.send(this._ch, BP.SETSTALLVELOCITY);
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	_hasInitialState() {

		if ((this.data.dutyCycle == PUNK.DBL)
			&& ! this._gotDutyCycleUpdateErrorEvent)
			return false;

		return true;
	}

	/** @internal */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	_fireInitialEvents() {

		if(this.data.dutyCycle != PUNK.DBL)
			if (this.onDutyCycleUpdate)
				try {
					this.onDutyCycleUpdate(this.data.dutyCycle);
				} catch (err) { logEventException(err); }

	}

	/**
	 * The rate at which the controller can change the motor's velocity.
	 * 
	 * *   Units for `position`, `velocityLimit`, `acceleration`, and `deadBand` can be set by the user through the `rescaleFactor`. The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * @throws {@link PhidgetError}
	 */
	get acceleration() { return this.getAcceleration(); }
	/**
	 * The minimum value that `acceleration` can be set to.
	 * 
	 * *   Units for `position`, `velocityLimit`, `acceleration`, and `deadBand` can be set by the user through the `rescaleFactor`. The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * @throws {@link PhidgetError}
	 */
	get minAcceleration() { return this.getMinAcceleration(); }
	/**
	 * The maximum value that `acceleration` can be set to.
	 * 
	 * *   Units for `position`, `velocityLimit`, `acceleration`, and `deadBand` can be set by the user through the `rescaleFactor`. The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * @throws {@link PhidgetError}
	 */
	get maxAcceleration() { return this.getMaxAcceleration(); }
	/**
	 * The controller will limit the current through the motor to this value.
	 * @throws {@link PhidgetError}
	 */
	get currentLimit() { return this.getCurrentLimit(); }
	/**
	 * The minimum current limit that can be set for the device.
	 * @throws {@link PhidgetError}
	 */
	get minCurrentLimit() { return this.getMinCurrentLimit(); }
	/**
	 * The maximum current limit that can be set for the device.
	 * @throws {@link PhidgetError}
	 */
	get maxCurrentLimit() { return this.getMaxCurrentLimit(); }
	/**
	 * Depending on power supply voltage and motor coil inductance, current through the motor can change relatively slowly or extremely rapidly. A physically larger DC Motor will typically have a lower inductance, requiring a higher current regulator gain. A higher power supply voltage will result in motor current changing more rapidly, requiring a higher current regulator gain. If the current regulator gain is too small, spikes in current will occur, causing large variations in torque, and possibly damaging the motor controller. If the current regulator gain is too high, the current will jitter, causing the motor to sound 'rough', especially when changing directions. Each DC Motor we sell specifies a suitable current regulator gain.
	 * @throws {@link PhidgetError}
	 */
	get currentRegulatorGain() { return this.getCurrentRegulatorGain(); }
	/**
	 * The minimum current regulator gain for the device.
	 * @throws {@link PhidgetError}
	 */
	get minCurrentRegulatorGain() { return this.getMinCurrentRegulatorGain(); }
	/**
	 * The maximum current regulator gain for the device.
	 * @throws {@link PhidgetError}
	 */
	get maxCurrentRegulatorGain() { return this.getMaxCurrentRegulatorGain(); }
	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `PositionChange` / `DutyCycleUpdate` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * @throws {@link PhidgetError}
	 */
	get dataInterval() { return this.getDataInterval(); }
	/**
	 * The minimum value that `dataInterval` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDataInterval() { return this.getMinDataInterval(); }
	/**
	 * The maximum value that `dataInterval` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDataInterval() { return this.getMaxDataInterval(); }
	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @throws {@link PhidgetError}
	 */
	get dataRate() { return this.getDataRate(); }
	/**
	 * The minimum value that `dataRate` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDataRate() { return this.getMinDataRate(); }
	/**
	 * The maximum value that `dataRate` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDataRate() { return this.getMaxDataRate(); }
	/**
	 * Depending on your system, it may not be possible to bring the position error (`targetPosition` - `position`) to zero. A small error can lead to the motor continually 'hunting' for a target position, which can cause unwanted effects. By setting a non-zero `deadBand`, the position controller will relax control of the motor within the deadband, preventing the 'hunting' behavior.
	 * 
	 * *   Units for `position`, `velocityLimit`, `acceleration`, and `deadBand` can be set by the user through the `rescaleFactor`. The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * @throws {@link PhidgetError}
	 */
	get deadBand() { return this.getDeadBand(); }
	/**
	 * The most recent duty cycle value that the controller has reported.
	 * 
	 * *   This value will be between -1 and 1 where a sign change (±) is indicitave of a direction change.
	 * *   Note that `dutyCycle` is merely an indication of the average voltage across the motor. At a constant load, an increase in `dutyCycle` indicates an increase in motor speed.
	 * *   The units of `dutyCycle` refer to 'duty cycle'. This is because the controller must rapidly switch the power on/off (i.e. change the duty cycle) in order to manipulate the voltage across the motor.
	 * @throws {@link PhidgetError}
	 */
	get dutyCycle() { return this.getDutyCycle(); }
	/**
	 * When engaged, a motor has the ability to be positioned. When disengaged, no commands are sent to the motor.
	 * 
	 * *   This function is useful for completely relaxing a motor once it has reached the target position.
	 * @throws {@link PhidgetError}
	 */
	get engaged() { return this.getEngaged(); }
	/**
	 * The minimum value that `failsafeTime` can be set to when calling `enableFailsafe()`.
	 * @throws {@link PhidgetError}
	 */
	get minFailsafeTime() { return this.getMinFailsafeTime(); }
	/**
	 * The maximum value that `failsafeTime` can be set to when calling `enableFailsafe()`.
	 * @throws {@link PhidgetError}
	 */
	get maxFailsafeTime() { return this.getMaxFailsafeTime(); }
	/**
	 * The `fanMode` dictates the operating condition of the fan.
	 * 
	 * *   Choose between on, off, or automatic (based on temperature).
	 * *   If the `fanMode` is set to automatic, the fan will turn on when the temperature reaches 70°C and it will remain on until the temperature falls below 55°C.
	 * *   If the `fanMode` is off, the controller will still turn on the fan if the temperature reaches 85°C and it will remain on until it falls below 70°C.
	 * @throws {@link PhidgetError}
	 */
	get fanMode() { return this.getFanMode(); }
	/**
	 * The encoder interface mode. Match the mode to the type of encoder you have attached.
	 * 
	 * *   It is recommended to only change this when the encoder disabled in order to avoid unexpected results.
	 * @throws {@link PhidgetError}
	 */
	get IOMode() { return this.getIOMode(); }
	/**
	 * Derivative gain constant. A higher `kd` will help reduce oscillations.
	 * @throws {@link PhidgetError}
	 */
	get kd() { return this.getKd(); }
	/**
	 * Integral gain constant. The integral term will help eliminate steady-state error.
	 * @throws {@link PhidgetError}
	 */
	get ki() { return this.getKi(); }
	/**
	 * Proportional gain constant. A small `kp` value will result in a less responsive controller, however, if `kp` is too high, the system can become unstable.
	 * @throws {@link PhidgetError}
	 */
	get kp() { return this.getKp(); }
	/**
	 * The most recent position value that the controller has reported.
	 * 
	 * *   This value will always be between `minPosition` and `maxPosition`.
	 * *   Units for `position`, `velocityLimit`, `acceleration`, and `deadBand` can be set by the user through the `rescaleFactor`. The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * @throws {@link PhidgetError}
	 */
	get position() { return this.getPosition(); }
	/**
	 * The minimum value that `targetPosition` can be set to.
	 * 
	 * *   Units for `position`, `velocityLimit`, `acceleration`, and `deadBand` can be set by the user through the `rescaleFactor`. The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * @throws {@link PhidgetError}
	 */
	get minPosition() { return this.getMinPosition(); }
	/**
	 * The maximum value that `targetPosition` can be set to.
	 * 
	 * *   Units for `position`, `velocityLimit`, `acceleration`, and `deadBand` can be set by the user through the `rescaleFactor`. The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * @throws {@link PhidgetError}
	 */
	get maxPosition() { return this.getMaxPosition(); }
	/**
	 * Change the units of your parameters so that your application is more intuitive.
	 * 
	 * *   Units for `position`, `velocityLimit`, `acceleration`, and `deadBand` can be set by the user through the `rescaleFactor`. The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * @throws {@link PhidgetError}
	 */
	get rescaleFactor() { return this.getRescaleFactor(); }
	set rescaleFactor(rescaleFactor: number) { this.setRescaleFactor(rescaleFactor); }
	/**
	 * Before reading this description, it is important to note the difference between the units of `stallVelocity` and `dutyCycle`.
	 * 
	 * *   `dutyCycle` is a number between -1 and 1 with units of 'duty cycle'. It simply represents the average voltage across the motor.
	 * *   `stallVelocity` represents a real velocity (e.g. m/s, RPM, etc.) and the units are determined by the `rescaleFactor`. With a `rescaleFactor` of 1, the default units would be in commutations per second.
	 * 
	 * If the load on your motor is large, your motor may begin rotating more slowly, or even fully stall. Depending on the voltage across your motor, this may result in a large amount of current through both the controller and the motor. In order to prevent damage in these situations, you can use the `stallVelocity` property.  
	 *   
	 * The `stallVelocity` should be set to the lowest velocity you would expect from your motor. The controller will then monitor the motor's velocity, as well as the `dutyCycle`, and prevent a 'dangerous stall' from occuring. If the controller detects a dangerous stall, it will immediately disengage the motor (i.e. `engaged` will be set to false) and an error will be reported to your program.
	 * 
	 * *   A 'dangerous stall' will occur faster when the `dutyCycle` is higher (i.e. when the average voltage across the motor is higher)
	 * *   A 'dangerous stall' will occur faster as (`stallVelocity` - motor velocity) becomes larger .
	 * 
	 * Setting `stallVelocity` to 0 will turn off stall protection functionality.
	 * @throws {@link PhidgetError}
	 */
	get stallVelocity() { return this.getStallVelocity(); }
	/**
	 * The lower bound of `stallVelocity`.
	 * @throws {@link PhidgetError}
	 */
	get minStallVelocity() { return this.getMinStallVelocity(); }
	/**
	 * The upper bound of `stallVelocity`.
	 * @throws {@link PhidgetError}
	 */
	get maxStallVelocity() { return this.getMaxStallVelocity(); }
	/**
	 * If the controller is configured and the `targetPosition` is set, the motor will try to reach the `targetPosition`.
	 * 
	 * *   If the `deadBand` is non-zero, the final position of the motor may not match the `targetPosition`
	 * *   Units for `position`, `velocityLimit`, `acceleration`, and `deadBand` can be set by the user through the `rescaleFactor`. The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * @throws {@link PhidgetError}
	 */
	get targetPosition() { return this.getTargetPosition(); }
	/**
	 * When moving, the motor velocity will be limited by this value.
	 * 
	 * *   `velocityLimit` is bounded by `minVelocityLimit` and `maxVelocityLimit`.
	 * *   Units for `position`, `velocityLimit`, `acceleration`, and `deadBand` can be set by the user through the `rescaleFactor`. The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * @throws {@link PhidgetError}
	 */
	get velocityLimit() { return this.getVelocityLimit(); }
	/**
	 * The minimum value that `velocityLimit` can be set to.
	 * 
	 * *   Units for `position`, `velocityLimit`, `acceleration`, and `deadBand` can be set by the user through the `rescaleFactor`. The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * @throws {@link PhidgetError}
	 */
	get minVelocityLimit() { return this.getMinVelocityLimit(); }
	/**
	 * The maximum value that `velocityLimit` can be set to.
	 * 
	 * *   Units for `position`, `velocityLimit`, `acceleration`, and `deadBand` can be set by the user through the `rescaleFactor`. The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * @throws {@link PhidgetError}
	 */
	get maxVelocityLimit() { return this.getMaxVelocityLimit(); }

	/**
	 * The rate at which the controller can change the motor's velocity.
	 * 
	 * *   Units for `position`, `velocityLimit`, `acceleration`, and `deadBand` can be set by the user through the `rescaleFactor`. The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * @returns The acceleration value
	 * @throws {@link PhidgetError}
	 */
	abstract getAcceleration(): number;
	/**
	 * The rate at which the controller can change the motor's velocity.
	 * 
	 * *   Units for `position`, `velocityLimit`, `acceleration`, and `deadBand` can be set by the user through the `rescaleFactor`. The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * @throws {@link PhidgetError}
	 * @param acceleration - The acceleration value
	 */
	abstract setAcceleration(acceleration: number): Promise<void>;
	/**
	 * The minimum value that `acceleration` can be set to.
	 * 
	 * *   Units for `position`, `velocityLimit`, `acceleration`, and `deadBand` can be set by the user through the `rescaleFactor`. The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * @returns The acceleration value.
	 * @throws {@link PhidgetError}
	 */
	abstract getMinAcceleration(): number;
	/**
	 * The maximum value that `acceleration` can be set to.
	 * 
	 * *   Units for `position`, `velocityLimit`, `acceleration`, and `deadBand` can be set by the user through the `rescaleFactor`. The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * @returns The acceleration value.
	 * @throws {@link PhidgetError}
	 */
	abstract getMaxAcceleration(): number;
	/**
	 * The controller will limit the current through the motor to this value.
	 * @returns Motor current limit
	 * @throws {@link PhidgetError}
	 */
	getCurrentLimit(): number {
		this._assertOpen();

		if (this.data.currentLimit === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.currentLimit);
	}

	/**
	 * The controller will limit the current through the motor to this value.
	 * @throws {@link PhidgetError}
	 * @param currentLimit - Motor current limit
	 */
	async setCurrentLimit(currentLimit: number): Promise<void> {
		this._assertOpen();

		if (currentLimit < this.data.minCurrentLimit || currentLimit > this.data.maxCurrentLimit)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minCurrentLimit + " - " + this.data.maxCurrentLimit + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: currentLimit });
		await bp.send(this._ch, BP.SETCURRENTLIMIT);
	}

	/**
	 * The minimum current limit that can be set for the device.
	 * @returns Minimum current limit
	 * @throws {@link PhidgetError}
	 */
	getMinCurrentLimit(): number {
		this._assertOpen();

		if (this.data.minCurrentLimit === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minCurrentLimit);
	}

	/**
	 * The maximum current limit that can be set for the device.
	 * @returns Maximum current limit
	 * @throws {@link PhidgetError}
	 */
	getMaxCurrentLimit(): number {
		this._assertOpen();

		if (this.data.maxCurrentLimit === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxCurrentLimit);
	}

	/**
	 * Depending on power supply voltage and motor coil inductance, current through the motor can change relatively slowly or extremely rapidly. A physically larger DC Motor will typically have a lower inductance, requiring a higher current regulator gain. A higher power supply voltage will result in motor current changing more rapidly, requiring a higher current regulator gain. If the current regulator gain is too small, spikes in current will occur, causing large variations in torque, and possibly damaging the motor controller. If the current regulator gain is too high, the current will jitter, causing the motor to sound 'rough', especially when changing directions. Each DC Motor we sell specifies a suitable current regulator gain.
	 * @returns Current Regulator Gain
	 * @throws {@link PhidgetError}
	 */
	getCurrentRegulatorGain(): number {
		this._assertOpen();

		if (this.data.currentRegulatorGain === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.currentRegulatorGain);
	}

	/**
	 * Depending on power supply voltage and motor coil inductance, current through the motor can change relatively slowly or extremely rapidly. A physically larger DC Motor will typically have a lower inductance, requiring a higher current regulator gain. A higher power supply voltage will result in motor current changing more rapidly, requiring a higher current regulator gain. If the current regulator gain is too small, spikes in current will occur, causing large variations in torque, and possibly damaging the motor controller. If the current regulator gain is too high, the current will jitter, causing the motor to sound 'rough', especially when changing directions. Each DC Motor we sell specifies a suitable current regulator gain.
	 * @throws {@link PhidgetError}
	 * @param currentRegulatorGain - Current Regulator Gain
	 */
	async setCurrentRegulatorGain(currentRegulatorGain: number): Promise<void> {
		this._assertOpen();

		if (currentRegulatorGain < this.data.minCurrentRegulatorGain || currentRegulatorGain > this.data.maxCurrentRegulatorGain)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minCurrentRegulatorGain + " - " + this.data.maxCurrentRegulatorGain + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: currentRegulatorGain });
		await bp.send(this._ch, BP.SETCURRENTREGULATORGAIN);
	}

	/**
	 * The minimum current regulator gain for the device.
	 * @returns Minimum current regulator gain
	 * @throws {@link PhidgetError}
	 */
	getMinCurrentRegulatorGain(): number {
		this._assertOpen();

		if (this.data.minCurrentRegulatorGain === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minCurrentRegulatorGain);
	}

	/**
	 * The maximum current regulator gain for the device.
	 * @returns Maximum current regulator gain
	 * @throws {@link PhidgetError}
	 */
	getMaxCurrentRegulatorGain(): number {
		this._assertOpen();

		if (this.data.maxCurrentRegulatorGain === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxCurrentRegulatorGain);
	}

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `PositionChange` / `DutyCycleUpdate` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getDataInterval(): number {
		this._assertOpen();

		if (this.data.dataInterval === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return this.data.dataInterval;
	}

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `PositionChange` / `DutyCycleUpdate` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * @throws {@link PhidgetError}
	 * @param dataInterval - The data interval value
	 */
	async setDataInterval(dataInterval: number): Promise<void> {
		this._assertOpen();

		if (dataInterval < this.data.minDataInterval || dataInterval > this.data.maxDataInterval)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDataInterval + " - " + this.data.maxDataInterval + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: dataInterval });
		await bp.send(this._ch, BP.SETDATAINTERVAL);
	}

	/**
	 * The minimum value that `dataInterval` can be set to.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getMinDataInterval(): number {
		this._assertOpen();

		if (this.data.minDataInterval === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDataInterval);
	}

	/**
	 * The maximum value that `dataInterval` can be set to.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getMaxDataInterval(): number {
		this._assertOpen();

		if (this.data.maxDataInterval === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDataInterval);
	}

	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @returns The data rate for the channel
	 * @throws {@link PhidgetError}
	 */
	getDataRate(): number {
		this._assertOpen();

		if (this.data.dataInterval === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (1000.0 / this.data.dataInterval);
	}

	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @throws {@link PhidgetError}
	 * @param dataRate - The data rate for the channel
	 */
	async setDataRate(dataRate: number): Promise<void> {
		this._assertOpen();

		if (dataRate < this.data.minDataRate || dataRate > this.data.maxDataRate)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDataRate + " - " + this.data.maxDataRate + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: Math.round(1000.0 / dataRate) });
		bp.set({ name: "1", type: "g", value: (1000.0 / dataRate) });
		await bp.send(this._ch, BP.SETDATAINTERVAL);
	}

	/**
	 * The minimum value that `dataRate` can be set to.
	 * @returns The data rate value
	 * @throws {@link PhidgetError}
	 */
	getMinDataRate(): number {
		this._assertOpen();

		if (this.data.minDataRate === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDataRate);
	}

	/**
	 * The maximum value that `dataRate` can be set to.
	 * @returns The data rate value
	 * @throws {@link PhidgetError}
	 */
	getMaxDataRate(): number {
		this._assertOpen();

		if (this.data.maxDataRate === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDataRate);
	}

	/**
	 * Depending on your system, it may not be possible to bring the position error (`targetPosition` - `position`) to zero. A small error can lead to the motor continually 'hunting' for a target position, which can cause unwanted effects. By setting a non-zero `deadBand`, the position controller will relax control of the motor within the deadband, preventing the 'hunting' behavior.
	 * 
	 * *   Units for `position`, `velocityLimit`, `acceleration`, and `deadBand` can be set by the user through the `rescaleFactor`. The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * @returns The dead band value
	 * @throws {@link PhidgetError}
	 */
	abstract getDeadBand(): number;
	/**
	 * Depending on your system, it may not be possible to bring the position error (`targetPosition` - `position`) to zero. A small error can lead to the motor continually 'hunting' for a target position, which can cause unwanted effects. By setting a non-zero `deadBand`, the position controller will relax control of the motor within the deadband, preventing the 'hunting' behavior.
	 * 
	 * *   Units for `position`, `velocityLimit`, `acceleration`, and `deadBand` can be set by the user through the `rescaleFactor`. The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * @throws {@link PhidgetError}
	 * @param deadBand - The dead band value
	 */
	abstract setDeadBand(deadBand: number): Promise<void>;
	/**
	 * The most recent duty cycle value that the controller has reported.
	 * 
	 * *   This value will be between -1 and 1 where a sign change (±) is indicitave of a direction change.
	 * *   Note that `dutyCycle` is merely an indication of the average voltage across the motor. At a constant load, an increase in `dutyCycle` indicates an increase in motor speed.
	 * *   The units of `dutyCycle` refer to 'duty cycle'. This is because the controller must rapidly switch the power on/off (i.e. change the duty cycle) in order to manipulate the voltage across the motor.
	 * @returns The duty cycle value.
	 * @throws {@link PhidgetError}
	 */
	getDutyCycle(): number {
		this._assertOpen();

		if (this.data.dutyCycle === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.dutyCycle);
	}

	/**
	 * When engaged, a motor has the ability to be positioned. When disengaged, no commands are sent to the motor.
	 * 
	 * *   This function is useful for completely relaxing a motor once it has reached the target position.
	 * @returns The engaged value.
	 * @throws {@link PhidgetError}
	 */
	getEngaged(): boolean {
		this._assertOpen();

		if (this.data.engaged === PUNK.BOOL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (!!this.data.engaged);
	}

	/**
	 * When engaged, a motor has the ability to be positioned. When disengaged, no commands are sent to the motor.
	 * 
	 * *   This function is useful for completely relaxing a motor once it has reached the target position.
	 * @throws {@link PhidgetError}
	 * @param engaged - The engaged value.
	 */
	async setEngaged(engaged: boolean): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (engaged !== false && engaged !== true)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be a boolean.");

		bp.set({ name: "0", type: "d", value: (engaged ? 1 : 0) });
		await bp.send(this._ch, BP.SETENGAGED);
	}

	/**
	 * Enables the **failsafe** feature for the channel, with a given **failsafe time**.
	 * 
	 * The **failsafe** feature is intended for use in applications where it is important for the channel to enter a known _safe state_ if the program controlling it locks up or crashes. If you do not enable the failsafe feature, the channel will carry out whatever instructions it was last given until it is explicitly told to stop.
	 * 
	 * Enabling the failsafe feature starts a recurring **failsafe timer** for the channel. Once the failsafe timer is enabled, it must be reset within the specified time or the channel will enter a **failsafe state**. The failsafe timer may be reset by sending any valid command to the device\*. Resetting the failsafe timer will reload the timer with the specified _failsafe time_, starting when the message to reset the timer is received by the Phidget.
	 * 
	 * _\*(**get** requests do not typically send commands and won't reset the failsafe timer)_
	 * 
	 * For example: if the failsafe is enabled with a **failsafe time** of 1000ms, you will have 1000ms to reset the failsafe timer. Every time the failsafe timer is reset, you will have 1000ms from that time to reset the failsafe again.
	 * 
	 * If the failsafe timer is not reset before it runs out, the channel will enter a **failsafe state**. For Motor Position Controller channels, this will disengage the motor. Once the channel enters the **failsafe state**, it will reject any further input until the channel is reopened.
	 * 
	 * To prevent the channel from falsely entering the failsafe state, we recommend resetting the failsafe timer as frequently as is practical for your application. A good rule of thumb is to not let more than a third of the failsafe time pass before resetting the timer.
	 * 
	 * Once the failsafe timer has been set, it cannot be disabled by any means other than closing and reopening the channel.
	 * @throws {@link PhidgetError}
	 * @param failsafeTime - Failsafe timeout in milliseconds
	 */
	async enableFailsafe(failsafeTime: number): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: failsafeTime });
		await bp.send(this._ch, BP.SETFAILSAFETIME);
	}

	/**
	 * The minimum value that `failsafeTime` can be set to when calling `enableFailsafe()`.
	 * @returns The failsafe time
	 * @throws {@link PhidgetError}
	 */
	getMinFailsafeTime(): number {
		this._assertOpen();

		if (this.data.minFailsafeTime === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minFailsafeTime);
	}

	/**
	 * The maximum value that `failsafeTime` can be set to when calling `enableFailsafe()`.
	 * @returns The failsafe time
	 * @throws {@link PhidgetError}
	 */
	getMaxFailsafeTime(): number {
		this._assertOpen();

		if (this.data.maxFailsafeTime === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxFailsafeTime);
	}

	/**
	 * The `fanMode` dictates the operating condition of the fan.
	 * 
	 * *   Choose between on, off, or automatic (based on temperature).
	 * *   If the `fanMode` is set to automatic, the fan will turn on when the temperature reaches 70°C and it will remain on until the temperature falls below 55°C.
	 * *   If the `fanMode` is off, the controller will still turn on the fan if the temperature reaches 85°C and it will remain on until it falls below 70°C.
	 * @returns The fan mode
	 * @throws {@link PhidgetError}
	 */
	getFanMode(): Enum.FanMode {
		this._assertOpen();

		if (this.data.fanMode === PUNK.ENUM)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.fanMode);
	}

	/**
	 * The `fanMode` dictates the operating condition of the fan.
	 * 
	 * *   Choose between on, off, or automatic (based on temperature).
	 * *   If the `fanMode` is set to automatic, the fan will turn on when the temperature reaches 70°C and it will remain on until the temperature falls below 55°C.
	 * *   If the `fanMode` is off, the controller will still turn on the fan if the temperature reaches 85°C and it will remain on until it falls below 70°C.
	 * @throws {@link PhidgetError}
	 * @param fanMode - The fan mode
	 */
	async setFanMode(fanMode: Enum.FanMode): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (!SEnum.supportedFanMode(this._ch!, fanMode))
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Specified FanMode is unsupported by this device.");

		bp.set({ name: "0", type: "d", value: fanMode });
		await bp.send(this._ch, BP.SETFANMODE);
	}

	/**
	 * The encoder interface mode. Match the mode to the type of encoder you have attached.
	 * 
	 * *   It is recommended to only change this when the encoder disabled in order to avoid unexpected results.
	 * @returns The IO mode value.
	 * @throws {@link PhidgetError}
	 */
	getIOMode(): Enum.EncoderIOMode {
		this._assertOpen();

		if (this.data.IOMode === PUNK.ENUM)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.IOMode);
	}

	/**
	 * The encoder interface mode. Match the mode to the type of encoder you have attached.
	 * 
	 * *   It is recommended to only change this when the encoder disabled in order to avoid unexpected results.
	 * @throws {@link PhidgetError}
	 * @param IOMode - The IO mode value.
	 */
	async setIOMode(IOMode: Enum.EncoderIOMode): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (!SEnum.supportedEncoderIOMode(this._ch!, IOMode))
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Specified EncoderIOMode is unsupported by this device.");

		bp.set({ name: "0", type: "d", value: IOMode });
		await bp.send(this._ch, BP.SETIOMODE);
	}

	/**
	 * Derivative gain constant. A higher `kd` will help reduce oscillations.
	 * @returns The Kd value.
	 * @throws {@link PhidgetError}
	 */
	abstract getKd(): number;
	/**
	 * Derivative gain constant. A higher `kd` will help reduce oscillations.
	 * @throws {@link PhidgetError}
	 * @param kd - The Kd value.
	 */
	abstract setKd(kd: number): Promise<void>;
	/**
	 * Integral gain constant. The integral term will help eliminate steady-state error.
	 * @returns The Ki value.
	 * @throws {@link PhidgetError}
	 */
	abstract getKi(): number;
	/**
	 * Integral gain constant. The integral term will help eliminate steady-state error.
	 * @throws {@link PhidgetError}
	 * @param ki - The Ki value.
	 */
	abstract setKi(ki: number): Promise<void>;
	/**
	 * Proportional gain constant. A small `kp` value will result in a less responsive controller, however, if `kp` is too high, the system can become unstable.
	 * @returns The Kp value.
	 * @throws {@link PhidgetError}
	 */
	abstract getKp(): number;
	/**
	 * Proportional gain constant. A small `kp` value will result in a less responsive controller, however, if `kp` is too high, the system can become unstable.
	 * @throws {@link PhidgetError}
	 * @param kp - The Kp value.
	 */
	abstract setKp(kp: number): Promise<void>;
	/**
	 * The most recent position value that the controller has reported.
	 * 
	 * *   This value will always be between `minPosition` and `maxPosition`.
	 * *   Units for `position`, `velocityLimit`, `acceleration`, and `deadBand` can be set by the user through the `rescaleFactor`. The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * @returns The position value
	 * @throws {@link PhidgetError}
	 */
	abstract getPosition(): number;
	/**
	 * The minimum value that `targetPosition` can be set to.
	 * 
	 * *   Units for `position`, `velocityLimit`, `acceleration`, and `deadBand` can be set by the user through the `rescaleFactor`. The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * @returns The position value
	 * @throws {@link PhidgetError}
	 */
	abstract getMinPosition(): number;
	/**
	 * The maximum value that `targetPosition` can be set to.
	 * 
	 * *   Units for `position`, `velocityLimit`, `acceleration`, and `deadBand` can be set by the user through the `rescaleFactor`. The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * @returns The position value
	 * @throws {@link PhidgetError}
	 */
	abstract getMaxPosition(): number;
	/**
	 * Adds an offset (positive or negative) to the current position. Useful for zeroing position.
	 * @throws {@link PhidgetError}
	 * @param positionOffset - Amount to offset the position by
	 */
	abstract addPositionOffset(positionOffset: number): void;
	/**
	 * Change the units of your parameters so that your application is more intuitive.
	 * 
	 * *   Units for `position`, `velocityLimit`, `acceleration`, and `deadBand` can be set by the user through the `rescaleFactor`. The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * @returns The rescale factor value
	 * @throws {@link PhidgetError}
	 */
	getRescaleFactor(): number {
		this._assertOpen();

		if (this.data.rescaleFactor === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.rescaleFactor);
	}

	/**
	 * Change the units of your parameters so that your application is more intuitive.
	 * 
	 * *   Units for `position`, `velocityLimit`, `acceleration`, and `deadBand` can be set by the user through the `rescaleFactor`. The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * @throws {@link PhidgetError}
	 * @param rescaleFactor - The rescale factor value
	 */
	abstract setRescaleFactor(rescaleFactor: number): void;
	/**
	 * Resets the failsafe timer, if one has been set. See `enableFailsafe()` for details.
	 * 
	 * This function will fail if no failsafe timer has been set for the channel.
	 * @throws {@link PhidgetError}
	 */
	async resetFailsafe(): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		await bp.send(this._ch, BP.FAILSAFERESET);
	}

	/**
	 * Before reading this description, it is important to note the difference between the units of `stallVelocity` and `dutyCycle`.
	 * 
	 * *   `dutyCycle` is a number between -1 and 1 with units of 'duty cycle'. It simply represents the average voltage across the motor.
	 * *   `stallVelocity` represents a real velocity (e.g. m/s, RPM, etc.) and the units are determined by the `rescaleFactor`. With a `rescaleFactor` of 1, the default units would be in commutations per second.
	 * 
	 * If the load on your motor is large, your motor may begin rotating more slowly, or even fully stall. Depending on the voltage across your motor, this may result in a large amount of current through both the controller and the motor. In order to prevent damage in these situations, you can use the `stallVelocity` property.  
	 *   
	 * The `stallVelocity` should be set to the lowest velocity you would expect from your motor. The controller will then monitor the motor's velocity, as well as the `dutyCycle`, and prevent a 'dangerous stall' from occuring. If the controller detects a dangerous stall, it will immediately disengage the motor (i.e. `engaged` will be set to false) and an error will be reported to your program.
	 * 
	 * *   A 'dangerous stall' will occur faster when the `dutyCycle` is higher (i.e. when the average voltage across the motor is higher)
	 * *   A 'dangerous stall' will occur faster as (`stallVelocity` - motor velocity) becomes larger .
	 * 
	 * Setting `stallVelocity` to 0 will turn off stall protection functionality.
	 * @returns The stall velocity value.
	 * @throws {@link PhidgetError}
	 */
	abstract getStallVelocity(): number;
	/**
	 * Before reading this description, it is important to note the difference between the units of `stallVelocity` and `dutyCycle`.
	 * 
	 * *   `dutyCycle` is a number between -1 and 1 with units of 'duty cycle'. It simply represents the average voltage across the motor.
	 * *   `stallVelocity` represents a real velocity (e.g. m/s, RPM, etc.) and the units are determined by the `rescaleFactor`. With a `rescaleFactor` of 1, the default units would be in commutations per second.
	 * 
	 * If the load on your motor is large, your motor may begin rotating more slowly, or even fully stall. Depending on the voltage across your motor, this may result in a large amount of current through both the controller and the motor. In order to prevent damage in these situations, you can use the `stallVelocity` property.  
	 *   
	 * The `stallVelocity` should be set to the lowest velocity you would expect from your motor. The controller will then monitor the motor's velocity, as well as the `dutyCycle`, and prevent a 'dangerous stall' from occuring. If the controller detects a dangerous stall, it will immediately disengage the motor (i.e. `engaged` will be set to false) and an error will be reported to your program.
	 * 
	 * *   A 'dangerous stall' will occur faster when the `dutyCycle` is higher (i.e. when the average voltage across the motor is higher)
	 * *   A 'dangerous stall' will occur faster as (`stallVelocity` - motor velocity) becomes larger .
	 * 
	 * Setting `stallVelocity` to 0 will turn off stall protection functionality.
	 * @throws {@link PhidgetError}
	 * @param stallVelocity - The stall velocity value.
	 */
	abstract setStallVelocity(stallVelocity: number): Promise<void>;
	/**
	 * The lower bound of `stallVelocity`.
	 * @returns The velocity value
	 * @throws {@link PhidgetError}
	 */
	abstract getMinStallVelocity(): number;
	/**
	 * The upper bound of `stallVelocity`.
	 * @returns The velocity value
	 * @throws {@link PhidgetError}
	 */
	abstract getMaxStallVelocity(): number;
	/**
	 * If the controller is configured and the `targetPosition` is set, the motor will try to reach the `targetPosition`.
	 * 
	 * *   If the `deadBand` is non-zero, the final position of the motor may not match the `targetPosition`
	 * *   Units for `position`, `velocityLimit`, `acceleration`, and `deadBand` can be set by the user through the `rescaleFactor`. The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * @returns The position value
	 * @throws {@link PhidgetError}
	 */
	abstract getTargetPosition(): number;
	/**
	 * If the controller is configured and the `targetPosition` is set, the motor will try to reach the `targetPosition`.
	 * 
	 * *   If the `deadBand` is non-zero, the final position of the motor may not match the `targetPosition`
	 * *   Units for `position`, `velocityLimit`, `acceleration`, and `deadBand` can be set by the user through the `rescaleFactor`. The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * @throws {@link PhidgetError}
	 * @param targetPosition - The position value
	 */
	abstract setTargetPosition(targetPosition: number): Promise<void>;
	/**
	 * When moving, the motor velocity will be limited by this value.
	 * 
	 * *   `velocityLimit` is bounded by `minVelocityLimit` and `maxVelocityLimit`.
	 * *   Units for `position`, `velocityLimit`, `acceleration`, and `deadBand` can be set by the user through the `rescaleFactor`. The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * @returns The velocity value.
	 * @throws {@link PhidgetError}
	 */
	abstract getVelocityLimit(): number;
	/**
	 * When moving, the motor velocity will be limited by this value.
	 * 
	 * *   `velocityLimit` is bounded by `minVelocityLimit` and `maxVelocityLimit`.
	 * *   Units for `position`, `velocityLimit`, `acceleration`, and `deadBand` can be set by the user through the `rescaleFactor`. The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * @throws {@link PhidgetError}
	 * @param velocityLimit - The velocity value.
	 */
	abstract setVelocityLimit(velocityLimit: number): Promise<void>;
	/**
	 * The minimum value that `velocityLimit` can be set to.
	 * 
	 * *   Units for `position`, `velocityLimit`, `acceleration`, and `deadBand` can be set by the user through the `rescaleFactor`. The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * @returns The velocity value
	 * @throws {@link PhidgetError}
	 */
	abstract getMinVelocityLimit(): number;
	/**
	 * The maximum value that `velocityLimit` can be set to.
	 * 
	 * *   Units for `position`, `velocityLimit`, `acceleration`, and `deadBand` can be set by the user through the `rescaleFactor`. The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * @returns The velocity value
	 * @throws {@link PhidgetError}
	 */
	abstract getMaxVelocityLimit(): number;
}
export { MotorPositionControllerBase };
