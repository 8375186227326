/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { RFIDBase } from './RFID.gen';
import { PhidgetLock } from '../PhidgetLock';
import { ErrorCode, RFIDProtocol } from '../Enumerations.gen';
import { BP } from '../BridgePackets.gen';
import { PhidgetError } from '../PhidgetError';
import { BridgePacket, PUNK } from '../BridgePacket';
import { Channel } from '../Channel';
import { logEventException } from '../Logging';

/** @internal */
interface RFIDPrivate {
	latestTagString: string,
	resolveLatestTag?: (tag: string) => void
}

/** @public */
class RFID extends RFIDBase {
	/** @internal */
	_transactionLock: PhidgetLock;
	/** @internal */
	_private: RFIDPrivate;

	/** @internal */
	constructor();
	/** @internal */
	constructor(ch?: Channel);
	constructor(ch?: Channel) {
		super(ch);
		this._transactionLock = new PhidgetLock();
		this._private = {
			latestTagString: ''
		};
	}

	/** @internal */
	_initAfterOpen() {
		super._initAfterOpen();
		this._private = {
			latestTagString: ''
		};
	}

	/** @internal */
	_bridgeInput(bp: BridgePacket) {
		switch (bp.vpkt) {
			case BP.TAG: {
				this.data.tagPresent = 1;
				const tag = bp.getString(0) as string;
				const protocol = bp.getNumber(1) as RFIDProtocol;
				if (this._isAttachedDone && this.onTag) {
					try {
						this.onTag(tag, protocol);
					} catch (err) { logEventException(err); }
				}
				this.data.lastTagString = tag;
				this.data.lastTagProtocol = protocol;
				break;
			}
			case BP.TAGLOST: {
				this.data.tagPresent = 0;
				const tag = bp.getString(0) as string;
				const protocol = bp.getNumber(1) as RFIDProtocol;
				if (this._isAttachedDone && this.onTagLost) {
					try {
						this.onTagLost(tag, protocol);
					} catch (err) { logEventException(err); }
				}
				break;
			}
			case BP.SETANTENNAON:
				if (this.data.tagPresent === PUNK.BOOL)
					this.data.tagPresent = 0;
				super._bridgeInput(bp);
				break;

			default:
				super._bridgeInput(bp);
				break;
		}
	}

	getLastTag() {
		this._assertOpen();

		if (!this.data.lastTagString)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return ({
			tagString: this.data.lastTagString,
			protocol: this.data.lastTagProtocol as RFIDProtocol
		});
	}

	/** @internal */
	_setLatestTagString(tagString: string) {
		this._private.latestTagString = tagString;
		if (this._private.resolveLatestTag)
			this._private.resolveLatestTag(tagString);
	}

	/** @internal */
	async _waitForTag(expectedString: string, timeout: number) {
		await new Promise<void>((resolve, reject) => {
			const timer = setTimeout(reject, timeout);
			this._private.resolveLatestTag = function (tagString: string) {
				if (expectedString && expectedString === tagString) {
					clearTimeout(timer);
					resolve();
				}
			}
		});
	}
}

export { RFID };