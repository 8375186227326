/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PhidgetChannel } from '../Phidget';
import { Channel } from '../Channel';
import { ErrorCode, ChannelClass } from '../Enumerations.gen';
import * as Enum from '../Enumerations.gen';
import * as SEnum from '../SupportedEnum.gen';
import { PhidgetError } from '../PhidgetError';
import { BridgePacket, PUNK } from '../BridgePacket';
import { BP } from '../BridgePackets.gen';
import { logEventException } from '../Logging';
import { DeviceChannelUID } from '../Devices.gen';

/** @internal */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DigitalInputData {
	state: number,
	powerSupply: Enum.PowerSupply | PUNK.ENUM,
	inputMode: Enum.InputMode | PUNK.ENUM,
}

abstract class DigitalInputBase extends PhidgetChannel {
	/** @internal */
	data: DigitalInputData;
	/**
	 * **StateChange** event
	 *  * `state` - The state value
	 * ---
	 * This event will occur when the state of the digital input has changed.
	 * 
	 * *   The value will either be 0 or 1 (true or false).
	 */
	onStateChange: ((state: boolean) => void) | null = null;
	/** @internal */
	_gotStateChangeErrorEvent?: boolean;

	/**
	 * The Digital Input class is used to monitor the state of Phidget digital inputs. Use digital inputs to monitor the state of buttons, switches, or switch-to-ground sensors.
	 * @public
	 */
	constructor();
	/** @internal */
	constructor(ch?: Channel);
	constructor(ch?: Channel) {
		super(ch);
		this._class = ChannelClass.DIGITAL_INPUT;
		this.name = "DigitalInput";
		this.data = this._initData();
	}

	/** @internal */
	_bridgeInput(bp: BridgePacket) {

		switch(bp.vpkt) {
		case BP.SETINPUTMODE:
			this.data.inputMode = bp.entries[0].v as Enum.InputMode;
			this._FIREPropertyChange('InputMode', bp);
			break;
		case BP.SETPOWERSUPPLY:
			this.data.powerSupply = bp.entries[0].v as Enum.PowerSupply;
			this._FIREPropertyChange('PowerSupply', bp);
			break;
		case BP.STATECHANGE: {
			this.data.state = bp.entries[0].v as number;
			if (this._isAttachedDone && this.onStateChange) {
				try {
					this.onStateChange(!!this.data.state);
				} catch (err) { logEventException(err); }
			}
			break;
		}
		default:
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			throw new PhidgetError(ErrorCode.INVALID_PACKET, "Unsupported bridge packet: 0x" + bp.vpkt!.toString(16));
		}
	}

	/** @internal */
	_initData(): DigitalInputData {
		return {
			state: PUNK.BOOL,
			powerSupply: PUNK.ENUM,
			inputMode: PUNK.ENUM,
		}
	}

	/** @internal */
	_initAfterOpen() {
		this.data = this._initData();

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._1018_DIGITALINPUT_1000:
			this.data.state = (<InterfaceKitDevice>this._ch!.parent).data.inputState[this._ch!.index];
			break;
		case DeviceChannelUID._1047_DIGITALINPUT_200:
		case DeviceChannelUID._1047_DIGITALINPUT_300:
			this.data.state = (<EncoderDevice>this._ch!.parent).data.inputState[this._ch!.index];
			break;
		case DeviceChannelUID._HUB_DIGITALINPUT_100:
		case DeviceChannelUID._DAQ1200_DIGITALINPUT_100:
		case DeviceChannelUID._DAQ1300_DIGITALINPUT_100:
		case DeviceChannelUID._DAQ1301_DIGITALINPUT_100:
		case DeviceChannelUID._HIN1101_DIGITALINPUT_100:
		case DeviceChannelUID._HIN1100_DIGITALINPUT_100:
			break;
		case DeviceChannelUID._DAQ1400_DIGITALINPUT_100:
		case DeviceChannelUID._DAQ1400_DIGITALINPUT_120:
			this.data.powerSupply = Enum.PowerSupply.VOLTS_12;
			this.data.inputMode = Enum.InputMode.NPN;
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	// eslint-disable-next-line require-await
	async _setDefaults() {
		let bp;

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._1018_DIGITALINPUT_1000:
		case DeviceChannelUID._1047_DIGITALINPUT_200:
		case DeviceChannelUID._1047_DIGITALINPUT_300:
		case DeviceChannelUID._HUB_DIGITALINPUT_100:
		case DeviceChannelUID._DAQ1200_DIGITALINPUT_100:
		case DeviceChannelUID._DAQ1300_DIGITALINPUT_100:
		case DeviceChannelUID._DAQ1301_DIGITALINPUT_100:
		case DeviceChannelUID._HIN1101_DIGITALINPUT_100:
		case DeviceChannelUID._HIN1100_DIGITALINPUT_100:
			break;
		case DeviceChannelUID._DAQ1400_DIGITALINPUT_100:
		case DeviceChannelUID._DAQ1400_DIGITALINPUT_120:
			bp = new BridgePacket();
			bp.set({ name: "0", type: "d", value: this.data.powerSupply });
			await bp.send(this._ch, BP.SETPOWERSUPPLY);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "d", value: this.data.inputMode });
			await bp.send(this._ch, BP.SETINPUTMODE);
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	_hasInitialState() {

		if ((this.data.state == PUNK.BOOL)
			&& ! this._gotStateChangeErrorEvent)
			return false;

		return true;
	}

	/** @internal */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	_fireInitialEvents() {

		if(this.data.state != PUNK.BOOL)
			if (this.onStateChange)
				try {
					this.onStateChange(!!this.data.state);
				} catch (err) { logEventException(err); }

	}

	/**
	 * The input polarity mode for your channel.
	 * 
	 * *   See your device's User Guide for more information about what value to chooose for the `InputMode`
	 * @throws {@link PhidgetError}
	 */
	get inputMode() { return this.getInputMode(); }
	/**
	 * Choose the power supply voltage.
	 * 
	 * *   Set this to the voltage specified in the attached sensor's data sheet to power it.
	 * 
	 * *   Set to `phidget22.PowerSupply.OFF` to turn off the supply to save power.
	 * @throws {@link PhidgetError}
	 */
	get powerSupply() { return this.getPowerSupply(); }
	/**
	 * The most recent state value that the channel has reported.
	 * @throws {@link PhidgetError}
	 */
	get state() { return this.getState(); }

	/**
	 * The input polarity mode for your channel.
	 * 
	 * *   See your device's User Guide for more information about what value to chooose for the `InputMode`
	 * @returns The input mode value
	 * @throws {@link PhidgetError}
	 */
	getInputMode(): Enum.InputMode {
		this._assertOpen();

		if (this.data.inputMode === PUNK.ENUM)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.inputMode);
	}

	/**
	 * The input polarity mode for your channel.
	 * 
	 * *   See your device's User Guide for more information about what value to chooose for the `InputMode`
	 * @throws {@link PhidgetError}
	 * @param inputMode - The input mode value
	 */
	async setInputMode(inputMode: Enum.InputMode): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (!SEnum.supportedInputMode(this._ch!, inputMode))
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Specified InputMode is unsupported by this device.");

		bp.set({ name: "0", type: "d", value: inputMode });
		await bp.send(this._ch, BP.SETINPUTMODE);
	}

	/**
	 * Choose the power supply voltage.
	 * 
	 * *   Set this to the voltage specified in the attached sensor's data sheet to power it.
	 * 
	 * *   Set to `phidget22.PowerSupply.OFF` to turn off the supply to save power.
	 * @returns The power supply value
	 * @throws {@link PhidgetError}
	 */
	getPowerSupply(): Enum.PowerSupply {
		this._assertOpen();

		if (this.data.powerSupply === PUNK.ENUM)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.powerSupply);
	}

	/**
	 * Choose the power supply voltage.
	 * 
	 * *   Set this to the voltage specified in the attached sensor's data sheet to power it.
	 * 
	 * *   Set to `phidget22.PowerSupply.OFF` to turn off the supply to save power.
	 * @throws {@link PhidgetError}
	 * @param powerSupply - The power supply value
	 */
	async setPowerSupply(powerSupply: Enum.PowerSupply): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (!SEnum.supportedPowerSupply(this._ch!, powerSupply))
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Specified PowerSupply is unsupported by this device.");

		bp.set({ name: "0", type: "d", value: powerSupply });
		await bp.send(this._ch, BP.SETPOWERSUPPLY);
	}

	/**
	 * The most recent state value that the channel has reported.
	 * @returns The state value
	 * @throws {@link PhidgetError}
	 */
	getState(): boolean {
		this._assertOpen();

		if (this.data.state === PUNK.BOOL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (!!this.data.state);
	}

}
import { type InterfaceKitDevice } from '../usb/device/InterfaceKitDevice';
import { type EncoderDevice } from '../usb/device/EncoderDevice';
export { DigitalInputBase };
