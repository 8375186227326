import { BridgePacket } from "../BridgePacket";
import { BP } from "../BridgePackets.gen";
import { Channel, type ChannelData } from "../Channel";
import { VINTDevice, VINTDeviceCommand } from "./device/VINTDevice";
import { ChannelClass, ErrorCode } from "../Enumerations.gen";
import { tm } from "../phidget22";
import { PhidgetError } from "../PhidgetError";
import { USBConnectionBase } from "./USBConnection";
import { type PhidgetChannel } from "../Phidget";
import { PHIDGET_MAXCHANNELS, type LocalDevice } from "./LocalDevice";
import { PhidgetUSBDevice } from "./PhidgetUSBDevice";
import { logEventException } from "../Logging";
import { PhidgetUniqueChannel } from "../Devices.gen";

/** @internal */
class LocalChannel extends Channel {
	declare conn: USBConnectionBase;
	declare parent: LocalDevice;
	declare chDef: Required<PhidgetUniqueChannel>;
	name: string;
	class: ChannelClass;

	constructor(conn: USBConnectionBase, dev: LocalDevice, data: ChannelData) {
		super(conn, dev, data);
		this.name = this.chDef.t;
		this.class = this.chDef.c;
	}

	async open(userphid: PhidgetChannel) {
		const dev = this.parent;

		// Check whether opening this would interfere with an already open channel by changing hub port modes
		if (dev instanceof VINTDevice) {
			for (const ch of this.conn._channels.values()) {
				// same device ok
				if (ch.parent == dev)
					continue;
				// not open ok
				if (!ch.isopen)
					continue;
				// different device but same VINT Hub and hub port
				if (ch.parent.parentId === dev.parentId && ch.parent.hubPort === dev.hubPort) {
					throw new PhidgetError(ErrorCode.BUSY, "Failed to open Channel " + this + " on local device: " + dev +
						" because Channel: " + ch + " is opened on the same Hub Port, and these channels are mutually exclusive.");
				}
			}
		}

		// Check whether we already have another channel open which would interfere with this one because of exclusive access
		if (this.chDef.e != undefined) {
			for (const c in this.parent.channels) {
				const ch = this.parent.channels[c];
				if (ch === this)
					continue;
				if (!ch.isopen)
					continue;
				// Exclusive means same index and same exclusive integer
				if (ch.chDef.e === this.chDef.e) {
					throw new PhidgetError(ErrorCode.BUSY, "Failed to open Channel " + this + " on local device: " + dev +
						" because Channel: " + ch + " is opened, and these channels are mutually exclusive.");
				}
			}
		}

		userphid._attaching = true;
		let opened = false;
		try {
			await dev.lock();
			await dev.open();
			opened = true;

			this.isopen = true;
			this.userphid = userphid;

			// initialize channel data
			userphid._ch = this;
			userphid._isattached = true;
			userphid._initAfterOpen();

			userphid._isopen = true;
		} catch (err) {
			try {
				if (opened)
					await dev.close();
			} finally {
				userphid._attaching = false;
				userphid._isattached = false;
				userphid._isopen = false;
				this.isopen = false;
			}
			throw (err);
		} finally {
			dev.unlock();
		}

		try {
			const bp = new BridgePacket();
			await bp.send(this, BP.OPENRESET, false);

			// set defaults
			await userphid._setDefaults();

			// attach event to user
			// NOTE: We await in case the user supplied an async function (or returned a promise)
			if (userphid.onAttach) {
				try {
					await userphid.onAttach(userphid);
				} catch (err) { logEventException(err); }
			}

			// enable channel - NOTE: this is done explicitly AFTER the attach event, so that user can override defaults before channel is enabled
			await bp.send(this, BP.ENABLE, false);

			// fire any initial events (after the attach event completes)
			userphid._fireInitialEvents();

			// done attaching - incoming data events will now fire
			userphid._attaching = false;

			// here, we want to resolve the user open call
			//  If we don't have initial state, wait for it
			if (userphid._hasInitialState()) {
				userphid._cancelOpenTimeout();
				userphid._resolveOpen?.();
			} else {
				// Create a callback that resolves that open when the initial state comes in
				userphid._onInitialState = () => {
					userphid._cancelOpenTimeout();
					userphid._resolveOpen?.();
					delete userphid._onInitialState;
				};
				// As a backup, we will resolve the open after a timeout even if the initial state doesn't come in
				// We enforce the wait time range for initial state, so we're not waiting forever for something like a detached thermocouple,
				//  but also always get the initial state when we should
				let remaining = 500;
				if (userphid._openTimeout != undefined && userphid._openTime !== undefined)
					remaining = userphid._openTimeout - (tm() - userphid._openTime);
				if (remaining > 2500)
					remaining = 2500;
				if (remaining < 500)
					remaining = 500;
				// Call the onInitialState after a timeout (if it hasn't already been called)
				setTimeout(() => {
					if (userphid._onInitialState)
						userphid._onInitialState();
				}, remaining);
			}
		} catch (err) {
			try {
				await dev.lock();
				await dev.close();
			} finally {
				dev.unlock();
				userphid._attaching = false;
				userphid._isattached = false;
				userphid._isopen = false;
				this.isopen = false;
			}
			throw (err);
		}
	}

	/*
	 * Currently only expected to be called from Phidget.close().
	 * We do not notify the Phidget.
	 */
	async close() {

		if (this.detaching)
			return;
		try {
			const bp = new BridgePacket();
			await bp.send(this, BP.CLOSERESET, false);
		} finally {
			try {
				await this.parent.lock();
				// NOTE: parent balances open/close calls
				await this.parent.close();
			} finally {
				if (this.userphid && this.isopen) {
					this.userphid._isopen = false;
					this.isopen = false;
				}
				this.parent.unlock();
			}
		}
	}

	async send(bp: BridgePacket): Promise<void> {

		switch (bp.vpkt) {
			case BP.SETVINTSPEED:
				if (!(this.parent instanceof VINTDevice))
					throw new PhidgetError(ErrorCode.WRONG_DEVICE);
				await this.parent.setHubPortSpeed(this, bp.getNumber(0));
				// NOTE: setHubPortSpeed() also notifies other channels of the speed change
				break;

			case BP.WRITELABEL:
				if (!(this.parent instanceof PhidgetUSBDevice))
					throw new PhidgetError(ErrorCode.WRONG_DEVICE);
				await this.parent.writeLabel(bp.getString(0));

				// Notify all other channels that the label changed
				for (let i = 0; i < PHIDGET_MAXCHANNELS; i++) {
					const ch = this.parent.getChannel(i);
					if (ch && ch !== this) {
						const bp = new BridgePacket();
						bp.set({ name: '0', type: 's', value: this.parent.label });
						bp.sendToChannel(ch, BP.DEVICELABELCHANGE);
					}
				}
				break;

			case BP.REBOOT:
				if (this.parent instanceof VINTDevice) {
					await this.parent.reboot(this);
				} else if (this.parent instanceof PhidgetUSBDevice) {
					await this.parent.rebootFirmwareUpgrade();
				} else {
					throw new PhidgetError(ErrorCode.WRONG_DEVICE);
				}
				break;

			case BP.REBOOTFIRMWAREUPGRADE:
				if (this.parent instanceof VINTDevice) {
					await this.parent.rebootFirmwareUpgrade(this, bp.getNumber(0));
				} else if (this.parent instanceof PhidgetUSBDevice) {
					await this.parent.rebootFirmwareUpgrade();
				} else {
					throw new PhidgetError(ErrorCode.WRONG_DEVICE);
				}
				break;

			default:
				// This sends a BP directly to the device layer
				await this.parent.bridgeInput(this, bp);
				break;
		}
	}

	async sendVINTPacket(command: VINTDeviceCommand, devicePacketType = 0, buffer?: Uint8Array) {
		const vintDevice = this.parent;
		if (!(vintDevice instanceof VINTDevice))
			throw new PhidgetError(ErrorCode.UNEXPECTED, "Parent device does not exist in sendVINTPacket.");

		const bufferOut = vintDevice.makePacket(this, command, devicePacketType, buffer);

		await vintDevice.sendpacket(bufferOut);
	}

	async sendVINTDataPacket(devicePacketType: number, buffer?: Uint8Array) {
		await this.sendVINTPacket(VINTDeviceCommand.DATA, devicePacketType, buffer);
	}
}

export { LocalChannel };