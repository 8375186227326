/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PhidgetChannel } from '../Phidget';
import { Channel } from '../Channel';
import { ErrorCode, ChannelClass } from '../Enumerations.gen';
import { PhidgetError } from '../PhidgetError';
import { BridgePacket } from '../BridgePacket';
import { DeviceChannelUID } from '../Devices.gen';

/** @internal */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface GenericData {
}

abstract class GenericBase extends PhidgetChannel {
	/** @internal */
	data: GenericData;

	/**
	 * @internal
	 */
	constructor();
	/** @internal */
	constructor(ch?: Channel);
	constructor(ch?: Channel) {
		super(ch);
		this._class = ChannelClass.GENERIC;
		this.name = "Generic";
		this.data = this._initData();
	}

	/** @internal */
	_bridgeInput(bp: BridgePacket) {

		switch(bp.vpkt) {
		default:
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			throw new PhidgetError(ErrorCode.INVALID_PACKET, "Unsupported bridge packet: 0x" + bp.vpkt!.toString(16));
		}
	}

	/** @internal */
	_initData(): GenericData {
		return {
		}
	}

	/** @internal */
	_initAfterOpen() {
		this.data = this._initData();

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._VINT_UNKNOWN:
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	// eslint-disable-next-line require-await
	async _setDefaults() {

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._VINT_UNKNOWN:
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	_hasInitialState() {


		return true;
	}

	/** @internal */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	_fireInitialEvents() {

	}


}
export { GenericBase };
