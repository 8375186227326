/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { DictionaryBase } from './Dictionary.gen';
import { BridgePacket } from '../BridgePacket';
import { BP } from '../BridgePackets.gen';
import { PhidgetError } from '../PhidgetError';
import { ErrorCode } from '../Enumerations.gen';

/** @public */
class Dictionary extends DictionaryBase {

	async get(key: string, def?: string): Promise<string | null> {
		this._assertOpen();

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "s", value: key });
		try {
			const val = await bp.send(this._ch, BP.DICTIONARYGET) as string;
			return val;
		} catch (err) {
			if (def !== undefined)
				return def;
			if (err instanceof PhidgetError && err.errorCode === ErrorCode.NO_SUCH_ENTITY)
				return null;
			throw err;
		}
	}

	async scan(key?: string): Promise<string[]> {
		this._assertOpen();

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "s", value: key ?? '' });
		const list = await bp.send(this._ch, BP.DICTIONARYSCAN) as string;
		if (list.length == 0)
			return ([]);
		return (list.trim().split('\n'));
	}
}

export { Dictionary };
