/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PhidgetChannel } from '../Phidget';
import { Channel } from '../Channel';
import { ErrorCode, ChannelClass } from '../Enumerations.gen';
import * as Enum from '../Enumerations.gen';
import * as SEnum from '../SupportedEnum.gen';
import { PhidgetError } from '../PhidgetError';
import { BridgePacket, PUNK } from '../BridgePacket';
import { BP } from '../BridgePackets.gen';
import { DeviceChannelUID } from '../Devices.gen';

/** @internal */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LCDData {
	fontWidth: [number, number, number],
	fontHeight: [number, number, number],
	backlight: number,
	minBacklight: number,
	maxBacklight: number,
	contrast: number,
	minContrast: number,
	maxContrast: number,
	cursorBlink: number,
	cursorOn: number,
	frameBuffer: number,
	height: number,
	width: number,
	screenSize: Enum.LCDScreenSize | PUNK.ENUM,
	sleeping: number,
	autoFlush: number,
}

abstract class LCDBase extends PhidgetChannel {
	/** @internal */
	data: LCDData;

	/**
	 * The LCD class allows you to control various liquid crystal displays. It offers control of displayed text as well as screen settings and custom character creation.
	 * @public
	 */
	constructor();
	/** @internal */
	constructor(ch?: Channel);
	constructor(ch?: Channel) {
		super(ch);
		this._class = ChannelClass.LCD;
		this.name = "LCD";
		this.data = this._initData();
	}

	/** @internal */
	_bridgeInput(bp: BridgePacket) {

		switch(bp.vpkt) {
		case BP.SETBACKLIGHT:
			this.data.backlight = bp.entries[0].v as number;
			this._FIREPropertyChange('Backlight', bp);
			break;
		case BP.SETCHARACTERBITMAP:
			break;
		case BP.CLEAR:
			break;
		case BP.SETCONTRAST:
			this.data.contrast = bp.entries[0].v as number;
			this._FIREPropertyChange('Contrast', bp);
			break;
		case BP.COPY:
			break;
		case BP.SETCURSORBLINK:
			this.data.cursorBlink = bp.entries[0].v as number;
			this._FIREPropertyChange('CursorBlink', bp);
			break;
		case BP.SETCURSORON:
			this.data.cursorOn = bp.entries[0].v as number;
			this._FIREPropertyChange('CursorOn', bp);
			break;
		case BP.DRAWLINE:
			break;
		case BP.DRAWPIXEL:
			break;
		case BP.DRAWRECT:
			break;
		case BP.FLUSH:
			break;
		case BP.SETFRAMEBUFFER:
			this.data.frameBuffer = bp.entries[0].v as number;
			this._FIREPropertyChange('FrameBuffer', bp);
			break;
		case BP.INITIALIZE:
			break;
		case BP.SAVEFRAMEBUFFER:
			break;
		case BP.SETSCREENSIZE:
			this.data.screenSize = bp.entries[0].v as Enum.LCDScreenSize;
			this._FIREPropertyChange('ScreenSize', bp);
			break;
		case BP.SETSLEEP:
			this.data.sleeping = bp.entries[0].v as number;
			this._FIREPropertyChange('Sleeping', bp);
			break;
		case BP.WRITEBITMAP:
			break;
		case BP.WRITETEXT:
			break;
		default:
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			throw new PhidgetError(ErrorCode.INVALID_PACKET, "Unsupported bridge packet: 0x" + bp.vpkt!.toString(16));
		}
	}

	/** @internal */
	_initData(): LCDData {
		return {
			fontWidth: [PUNK.UINT8, PUNK.UINT8, PUNK.UINT8],
			fontHeight: [PUNK.UINT8, PUNK.UINT8, PUNK.UINT8],
			backlight: PUNK.DBL,
			minBacklight: PUNK.DBL,
			maxBacklight: PUNK.DBL,
			contrast: PUNK.DBL,
			minContrast: PUNK.DBL,
			maxContrast: PUNK.DBL,
			cursorBlink: PUNK.BOOL,
			cursorOn: PUNK.BOOL,
			frameBuffer: PUNK.INT32,
			height: PUNK.INT32,
			width: PUNK.INT32,
			screenSize: PUNK.ENUM,
			sleeping: PUNK.BOOL,
			autoFlush: PUNK.BOOL,
		}
	}

	/** @internal */
	_initAfterOpen() {
		this.data = this._initData();

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._LCD1100_LCD_100:
			this.data.backlight = 0;
			this.data.minBacklight = 0;
			this.data.maxBacklight = 1;
			this.data.contrast = 0.25;
			this.data.minContrast = 0;
			this.data.maxContrast = 1;
			this.data.frameBuffer = 0;
			this.data.height = 64;
			this.data.width = 128;
			this.data.screenSize = Enum.LCDScreenSize.DIMENSIONS_64X128;
			this.data.sleeping = 1;
			this.data.autoFlush = 0;
			break;
		case DeviceChannelUID._LCD1100_LCD_200:
			this.data.backlight = 0;
			this.data.minBacklight = 0;
			this.data.maxBacklight = 1;
			this.data.contrast = 0.55;
			this.data.minContrast = 0;
			this.data.maxContrast = 1;
			this.data.frameBuffer = 0;
			this.data.height = 64;
			this.data.width = 128;
			this.data.screenSize = Enum.LCDScreenSize.DIMENSIONS_64X128;
			this.data.sleeping = 1;
			this.data.autoFlush = 0;
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	// eslint-disable-next-line require-await
	async _setDefaults() {
		let bp;

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._LCD1100_LCD_100:
		case DeviceChannelUID._LCD1100_LCD_200:
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.contrast });
			await bp.send(this._ch, BP.SETCONTRAST);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "d", value: this.data.frameBuffer });
			await bp.send(this._ch, BP.SETFRAMEBUFFER);
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	_hasInitialState() {


		return true;
	}

	/** @internal */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	_fireInitialEvents() {

	}

	/**
	 * Set to true to automatically flush the LCD screen after every message that writes to the LCD.
	 * @throws {@link PhidgetError}
	 */
	get autoFlush() { return this.getAutoFlush(); }
	set autoFlush(autoFlush: boolean) { this.setAutoFlush(autoFlush); }
	/**
	 * The `backlight` affects the brightness of the LCD screen.
	 * 
	 * *   `backlight` is bounded by `minBacklight` and `maxBacklight`.
	 * @throws {@link PhidgetError}
	 */
	get backlight() { return this.getBacklight(); }
	/**
	 * The minimum value that `backlight` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minBacklight() { return this.getMinBacklight(); }
	/**
	 * The maximum value that `backlight` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxBacklight() { return this.getMaxBacklight(); }
	/**
	 * Contrast level of the text or graphic pixels.
	 * 
	 * *   A higher contrast will make the image darker.
	 * *   `contrast` is bounded by `minContrast` and `maxContrast`.
	 * @throws {@link PhidgetError}
	 */
	get contrast() { return this.getContrast(); }
	/**
	 * The minimum value that `contrast` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minContrast() { return this.getMinContrast(); }
	/**
	 * The maximum value that `contrast` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxContrast() { return this.getMaxContrast(); }
	/**
	 * When `cursorBlink` is true, the device will cause the cursor to periodically blink.
	 * @throws {@link PhidgetError}
	 */
	get cursorBlink() { return this.getCursorBlink(); }
	/**
	 * When `cursorOn` is true, the device will underline to the cursor position.
	 * @throws {@link PhidgetError}
	 */
	get cursorOn() { return this.getCursorOn(); }
	/**
	 * The frame buffer that is currently being used.
	 * 
	 * *   Commands sent to the device are performed on this buffer.
	 * @throws {@link PhidgetError}
	 */
	get frameBuffer() { return this.getFrameBuffer(); }
	/**
	 * The height of the LCD screen attached to the channel.
	 * @throws {@link PhidgetError}
	 */
	get height() { return this.getHeight(); }
	/**
	 * The size of the LCD screen attached to the channel.
	 * @throws {@link PhidgetError}
	 */
	get screenSize() { return this.getScreenSize(); }
	/**
	 * The on/off state of `sleeping`. Putting the device to sleep turns off the display and backlight in order to save power.
	 * 
	 * *   The device will still take commands while asleep, and will wake up if the screen is flushed, or if the contrast or backlight are changed.
	 * *   When the device wakes up, it will return to its last known state, taking into account any changes that happened while asleep.
	 * @throws {@link PhidgetError}
	 */
	get sleeping() { return this.getSleeping(); }
	/**
	 * The width of the LCD screen attached to the channel.
	 * @throws {@link PhidgetError}
	 */
	get width() { return this.getWidth(); }

	/**
	 * Set to true to automatically flush the LCD screen after every message that writes to the LCD.
	 * @returns Allows setting the LCD to flush the screen automatically
	 * @throws {@link PhidgetError}
	 */
	getAutoFlush(): boolean {
		this._assertOpen();

		if (this.data.autoFlush === PUNK.BOOL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (!!this.data.autoFlush);
	}

	/**
	 * Set to true to automatically flush the LCD screen after every message that writes to the LCD.
	 * @throws {@link PhidgetError}
	 * @param autoFlush - Allows setting the LCD to flush the screen automatically
	 */
	abstract setAutoFlush(autoFlush: boolean): void;
	/**
	 * The `backlight` affects the brightness of the LCD screen.
	 * 
	 * *   `backlight` is bounded by `minBacklight` and `maxBacklight`.
	 * @returns The backlight value
	 * @throws {@link PhidgetError}
	 */
	getBacklight(): number {
		this._assertOpen();

		if (this.data.backlight === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.backlight);
	}

	/**
	 * The `backlight` affects the brightness of the LCD screen.
	 * 
	 * *   `backlight` is bounded by `minBacklight` and `maxBacklight`.
	 * @throws {@link PhidgetError}
	 * @param backlight - The backlight value
	 */
	async setBacklight(backlight: number): Promise<void> {
		this._assertOpen();

		if (backlight < this.data.minBacklight || backlight > this.data.maxBacklight)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minBacklight + " - " + this.data.maxBacklight + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: backlight });
		await bp.send(this._ch, BP.SETBACKLIGHT);
	}

	/**
	 * The minimum value that `backlight` can be set to.
	 * @returns The backlight value
	 * @throws {@link PhidgetError}
	 */
	getMinBacklight(): number {
		this._assertOpen();

		if (this.data.minBacklight === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minBacklight);
	}

	/**
	 * The maximum value that `backlight` can be set to.
	 * @returns The backlight value
	 * @throws {@link PhidgetError}
	 */
	getMaxBacklight(): number {
		this._assertOpen();

		if (this.data.maxBacklight === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxBacklight);
	}

	/**
	 * Create a bitmap and select a character to represent it. Now, when you use the specific character, the bitmap will show in it's place.
	 * @throws {@link PhidgetError}
	 * @param font - The font the character belongs to
	 * @param character - The character to be changed, in a null-terminated string.
	 * @param bitmap - Bitmap array
	 */
	abstract setCharacterBitmap(font: Enum.LCDFont, character: string, bitmap: readonly number[]): Promise<void>;
	/**
	 * The maximum number of characters that can fit on the frame buffer for the specified font.
	 * @returns The maximum number of characters for the font
	 * @throws {@link PhidgetError}
	 * @param font - The specified font
	 */
	abstract getMaxCharacters(font: Enum.LCDFont): number;
	/**
	 * Clears all pixels in the current frame buffer.
	 * 
	 * *   Changes made to the frame buffer must be flushed to the LCD screen using `flush()`.
	 * @throws {@link PhidgetError}
	 */
	async clear(): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		await bp.send(this._ch, BP.CLEAR);
	}

	/**
	 * Contrast level of the text or graphic pixels.
	 * 
	 * *   A higher contrast will make the image darker.
	 * *   `contrast` is bounded by `minContrast` and `maxContrast`.
	 * @returns The contrast value
	 * @throws {@link PhidgetError}
	 */
	getContrast(): number {
		this._assertOpen();

		if (this.data.contrast === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.contrast);
	}

	/**
	 * Contrast level of the text or graphic pixels.
	 * 
	 * *   A higher contrast will make the image darker.
	 * *   `contrast` is bounded by `minContrast` and `maxContrast`.
	 * @throws {@link PhidgetError}
	 * @param contrast - The contrast value
	 */
	async setContrast(contrast: number): Promise<void> {
		this._assertOpen();

		if (contrast < this.data.minContrast || contrast > this.data.maxContrast)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minContrast + " - " + this.data.maxContrast + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: contrast });
		await bp.send(this._ch, BP.SETCONTRAST);
	}

	/**
	 * The minimum value that `contrast` can be set to.
	 * @returns The contrast value.
	 * @throws {@link PhidgetError}
	 */
	getMinContrast(): number {
		this._assertOpen();

		if (this.data.minContrast === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minContrast);
	}

	/**
	 * The maximum value that `contrast` can be set to.
	 * @returns The contrast value.
	 * @throws {@link PhidgetError}
	 */
	getMaxContrast(): number {
		this._assertOpen();

		if (this.data.maxContrast === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxContrast);
	}

	/**
	 * Copies all pixels from a specified rectangular region to another.
	 * @throws {@link PhidgetError}
	 * @param sourceFramebuffer - Index number of the frame buffer containing the source rectangle
	 * @param destFramebuffer - Index number of the frame buffer containing the destination rectangle
	 * @param sourceX1 - X coordinate of upper left corner of source rectangle
	 * @param sourceY1 - Y coordinate of upper left corner of source rectangle
	 * @param sourceX2 - X coordinate of bottom right corner of source rectangle
	 * @param sourceY2 - Y coordinate of bottom right corner of source rectangle
	 * @param destX - X coordinate of upper left corner of destination rectangle
	 * @param destY - Y coordinate of upper left corner of destination rectangle
	 * @param inverted - If true, copied pixels are inverted
	 */
	async copy(sourceFramebuffer: number, destFramebuffer: number, sourceX1: number, sourceY1: number, sourceX2: number, sourceY2: number, destX: number, destY: number, inverted: boolean): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "d", value: sourceFramebuffer });
		bp.set({ name: "1", type: "d", value: destFramebuffer });
		bp.set({ name: "2", type: "d", value: sourceX1 });
		bp.set({ name: "3", type: "d", value: sourceY1 });
		bp.set({ name: "4", type: "d", value: sourceX2 });
		bp.set({ name: "5", type: "d", value: sourceY2 });
		bp.set({ name: "6", type: "d", value: destX });
		bp.set({ name: "7", type: "d", value: destY });

		if (inverted !== false && inverted !== true)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be a boolean.");

		bp.set({ name: "8", type: "d", value: (inverted ? 1 : 0) });
		await bp.send(this._ch, BP.COPY);
	}

	/**
	 * When `cursorBlink` is true, the device will cause the cursor to periodically blink.
	 * @returns The cursor blink mode
	 * @throws {@link PhidgetError}
	 */
	getCursorBlink(): boolean {
		this._assertOpen();

		if (this.data.cursorBlink === PUNK.BOOL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (!!this.data.cursorBlink);
	}

	/**
	 * When `cursorBlink` is true, the device will cause the cursor to periodically blink.
	 * @throws {@link PhidgetError}
	 * @param cursorBlink - The cursor blink mode
	 */
	async setCursorBlink(cursorBlink: boolean): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (cursorBlink !== false && cursorBlink !== true)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be a boolean.");

		bp.set({ name: "0", type: "d", value: (cursorBlink ? 1 : 0) });
		await bp.send(this._ch, BP.SETCURSORBLINK);
	}

	/**
	 * When `cursorOn` is true, the device will underline to the cursor position.
	 * @returns The cursor on value
	 * @throws {@link PhidgetError}
	 */
	getCursorOn(): boolean {
		this._assertOpen();

		if (this.data.cursorOn === PUNK.BOOL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (!!this.data.cursorOn);
	}

	/**
	 * When `cursorOn` is true, the device will underline to the cursor position.
	 * @throws {@link PhidgetError}
	 * @param cursorOn - The cursor on value
	 */
	async setCursorOn(cursorOn: boolean): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (cursorOn !== false && cursorOn !== true)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be a boolean.");

		bp.set({ name: "0", type: "d", value: (cursorOn ? 1 : 0) });
		await bp.send(this._ch, BP.SETCURSORON);
	}

	/**
	 * Draws a straight line in the current frame buffer between two specified points
	 * 
	 * *   Changes made to the frame buffer must be flushed to the LCD screen using `flush()`.
	 * @throws {@link PhidgetError}
	 * @param x1 - X coordinate of the first point
	 * @param y1 - Y coordinate of the first point
	 * @param x2 - X coordinate of the second point
	 * @param y2 - Y coordinate of the second point
	 */
	async drawLine(x1: number, y1: number, x2: number, y2: number): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "d", value: x1 });
		bp.set({ name: "1", type: "d", value: y1 });
		bp.set({ name: "2", type: "d", value: x2 });
		bp.set({ name: "3", type: "d", value: y2 });
		await bp.send(this._ch, BP.DRAWLINE);
	}

	/**
	 * Draws, erases, or inverts a single specified pixel.
	 * 
	 * *   Changes made to the frame buffer must be flushed to the LCD screen using `flush()`.
	 * @throws {@link PhidgetError}
	 * @param x - The X coordinate of the pixel
	 * @param y - The Y coordinate of the pixel
	 * @param pixelState - The new state of the pixel.
	 */
	async drawPixel(x: number, y: number, pixelState: Enum.LCDPixelState): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "d", value: x });
		bp.set({ name: "1", type: "d", value: y });

		if (!SEnum.supportedLCDPixelState(this._ch!, pixelState))
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Specified LCDPixelState is unsupported by this device.");

		bp.set({ name: "2", type: "d", value: pixelState });
		await bp.send(this._ch, BP.DRAWPIXEL);
	}

	/**
	 * Draws a rectangle in the current frame buffer using the specified points
	 * 
	 * *   Changes made to the frame buffer must be flushed to the LCD screen using `flush()`.
	 * @throws {@link PhidgetError}
	 * @param x1 - The X coordinate of the top-left corner of the rectangle
	 * @param y1 - The Y coordinate of the top-left corner of the rectangle
	 * @param x2 - The X coordinate of the bottom-right corner of the rectangle
	 * @param y2 - The Y coordinate of the bottom-right corner of the rectangle
	 * @param filled - If true, the rectangle will be solid. If false, just a single pixel outline.
	 * @param inverted - If true, clears the region instead of drawing
	 */
	async drawRect(x1: number, y1: number, x2: number, y2: number, filled: boolean, inverted: boolean): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "d", value: x1 });
		bp.set({ name: "1", type: "d", value: y1 });
		bp.set({ name: "2", type: "d", value: x2 });
		bp.set({ name: "3", type: "d", value: y2 });

		if (filled !== false && filled !== true)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be a boolean.");

		bp.set({ name: "4", type: "d", value: (filled ? 1 : 0) });

		if (inverted !== false && inverted !== true)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be a boolean.");

		bp.set({ name: "5", type: "d", value: (inverted ? 1 : 0) });
		await bp.send(this._ch, BP.DRAWRECT);
	}

	/**
	 * Flushes the buffered LCD contents to the LCD screen.
	 * @throws {@link PhidgetError}
	 */
	async flush(): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		await bp.send(this._ch, BP.FLUSH);
	}

	/**
	 * Gets the size of the specified font.
	 * @returns
	 * 	- width: The width of the font
	 * 	- height: The height of the font
	 * @throws {@link PhidgetError}
	 * @param font - The specified font
	 */
	abstract getFontSize(font: Enum.LCDFont): {width: number, height: number};
	/**
	 * Sets the size of the specified font.
	 * @throws {@link PhidgetError}
	 * @param font - The specified font
	 * @param width - The width of the font
	 * @param height - The height of the font
	 */
	async setFontSize(font: Enum.LCDFont, width: number, height: number): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (!SEnum.supportedLCDFont(this._ch!, font))
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Specified LCDFont is unsupported by this device.");

		bp.set({ name: "0", type: "d", value: font });
		bp.set({ name: "1", type: "d", value: width });
		bp.set({ name: "2", type: "d", value: height });
		await bp.send(this._ch, BP.SETFONTSIZE);
	}

	/**
	 * The frame buffer that is currently being used.
	 * 
	 * *   Commands sent to the device are performed on this buffer.
	 * @returns The current frame buffer
	 * @throws {@link PhidgetError}
	 */
	getFrameBuffer(): number {
		this._assertOpen();

		if (this.data.frameBuffer === PUNK.INT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.frameBuffer);
	}

	/**
	 * The frame buffer that is currently being used.
	 * 
	 * *   Commands sent to the device are performed on this buffer.
	 * @throws {@link PhidgetError}
	 * @param frameBuffer - The current frame buffer
	 */
	async setFrameBuffer(frameBuffer: number): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "d", value: frameBuffer });
		await bp.send(this._ch, BP.SETFRAMEBUFFER);
	}

	/**
	 * The height of the LCD screen attached to the channel.
	 * @returns The height value
	 * @throws {@link PhidgetError}
	 */
	getHeight(): number {
		this._assertOpen();

		if (this.data.height === PUNK.INT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.height);
	}

	/**
	 * Initializes the Text LCD display
	 * @throws {@link PhidgetError}
	 */
	async initialize(): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		await bp.send(this._ch, BP.INITIALIZE);
	}

	/**
	 * Writes the specified frame buffer to flash memory
	 * 
	 * *   Use sparingly. The flash memory is only designed to be written to 10,000 times before it may become unusable. This method can only be called one time each time the channel is opened.
	 * @throws {@link PhidgetError}
	 * @param frameBuffer - The frame buffer to be saved
	 */
	async saveFrameBuffer(frameBuffer: number): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "d", value: frameBuffer });
		await bp.send(this._ch, BP.SAVEFRAMEBUFFER);
	}

	/**
	 * The size of the LCD screen attached to the channel.
	 * @returns The screen size
	 * @throws {@link PhidgetError}
	 */
	getScreenSize(): Enum.LCDScreenSize {
		this._assertOpen();

		if (this.data.screenSize === PUNK.ENUM)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.screenSize);
	}

	/**
	 * The size of the LCD screen attached to the channel.
	 * @throws {@link PhidgetError}
	 * @param screenSize - The screen size
	 */
	async setScreenSize(screenSize: Enum.LCDScreenSize): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (!SEnum.supportedLCDScreenSize(this._ch!, screenSize))
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Specified LCDScreenSize is unsupported by this device.");

		bp.set({ name: "0", type: "d", value: screenSize });
		await bp.send(this._ch, BP.SETSCREENSIZE);
	}

	/**
	 * The on/off state of `sleeping`. Putting the device to sleep turns off the display and backlight in order to save power.
	 * 
	 * *   The device will still take commands while asleep, and will wake up if the screen is flushed, or if the contrast or backlight are changed.
	 * *   When the device wakes up, it will return to its last known state, taking into account any changes that happened while asleep.
	 * @returns The sleep status
	 * @throws {@link PhidgetError}
	 */
	getSleeping(): boolean {
		this._assertOpen();

		if (this.data.sleeping === PUNK.BOOL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (!!this.data.sleeping);
	}

	/**
	 * The on/off state of `sleeping`. Putting the device to sleep turns off the display and backlight in order to save power.
	 * 
	 * *   The device will still take commands while asleep, and will wake up if the screen is flushed, or if the contrast or backlight are changed.
	 * *   When the device wakes up, it will return to its last known state, taking into account any changes that happened while asleep.
	 * @throws {@link PhidgetError}
	 * @param sleeping - The sleep status
	 */
	async setSleeping(sleeping: boolean): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (sleeping !== false && sleeping !== true)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be a boolean.");

		bp.set({ name: "0", type: "d", value: (sleeping ? 1 : 0) });
		await bp.send(this._ch, BP.SETSLEEP);
	}

	/**
	 * The width of the LCD screen attached to the channel.
	 * @returns The width value
	 * @throws {@link PhidgetError}
	 */
	getWidth(): number {
		this._assertOpen();

		if (this.data.width === PUNK.INT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.width);
	}

	/**
	 * Draws a bitmap to the current frame buffer at the given location.
	 * 
	 * *   Each byte in the array represents one pixel in row-major order.
	 * *   Changes made to the frame buffer must be flushed to the LCD screen using `flush()`.
	 * @throws {@link PhidgetError}
	 * @param xPosition - The X coordinate of the bitmap
	 * @param yPosition - The Y coordinate of the bitmap
	 * @param xSize - The length of each row in the bitmap
	 * @param ySize - The number of rows in the bitmap
	 * @param bitmap - The bitmap to be drawn
	 */
	abstract writeBitmap(xPosition: number, yPosition: number, xSize: number, ySize: number, bitmap: readonly number[]): Promise<void>;
	/**
	 * Writes text to the current frame buffer at the specified location
	 * 
	 * *   Changes made to the frame buffer must be flushed to the LCD screen using `flush()`.
	 * @throws {@link PhidgetError}
	 * @param font - The font of the text
	 * @param xPosition - The X position of the start of the text string
	 * @param yPosition - The Y position of the start of the text string
	 * @param text - The text to be written
	 */
	async writeText(font: Enum.LCDFont, xPosition: number, yPosition: number, text: string): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (!SEnum.supportedLCDFont(this._ch!, font))
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Specified LCDFont is unsupported by this device.");

		bp.set({ name: "0", type: "d", value: font });
		bp.set({ name: "1", type: "d", value: xPosition });
		bp.set({ name: "2", type: "d", value: yPosition });
		bp.set({ name: "3", type: "s", value: text });
		await bp.send(this._ch, BP.WRITETEXT);
	}

}
export { LCDBase };
