import { HumiditySensorBase } from './HumiditySensor.gen';
import { ErrorEventCode } from '../Enumerations.gen';
import { PUNK } from '../BridgePacket';

/** @public */
class HumiditySensor extends HumiditySensorBase {

	/** @internal */
	_errorHandler(code: ErrorEventCode) {
		switch (code) {
			case ErrorEventCode.SATURATION:
				this.data.humidity = PUNK.DBL;
				this._gotHumidityChangeErrorEvent = true;
				break;
		}
	}
}

export { HumiditySensor };