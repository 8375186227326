import { Unit } from './Enumerations.gen';
import { type UnitInfo } from './Structs.gen';

const Units: UnitInfo[] = [
	{
		unit: Unit.NONE,
		name: "none",
		symbol: ""
	},
	{
		unit: Unit.BOOLEAN,
		name: "boolean",
		symbol: ""
	},
	{
		unit: Unit.PERCENT,
		name: "percent",
		symbol: "%"
	},
	{
		unit: Unit.DECIBEL,
		name: "decibel",
		symbol: "dB"
	},
	{
		unit: Unit.MILLIMETER,
		name: "millimeter",
		symbol: "mm"
	},
	{
		unit: Unit.CENTIMETER,
		name: "centimeter",
		symbol: "cm"
	},
	{
		unit: Unit.METER,
		name: "meter",
		symbol: "m"
	},
	{
		unit: Unit.GRAM,
		name: "gram",
		symbol: "g"
	},
	{
		unit: Unit.KILOGRAM,
		name: "kilogram",
		symbol: "kg"
	},
	{
		unit: Unit.MILLIAMPERE,
		name: "milliampere",
		symbol: "mA"
	},
	{
		unit: Unit.AMPERE,
		name: "ampere",
		symbol: "A"
	},
	{
		unit: Unit.KILOPASCAL,
		name: "kilopascal",
		symbol: "kPa"
	},
	{
		unit: Unit.VOLT,
		name: "volt",
		symbol: "V"
	},
	{
		unit: Unit.DEGREE_CELCIUS,
		name: "degree Celsius",
		symbol: "\xB0C"
	},
	{
		unit: Unit.LUX,
		name: "lux",
		symbol: "lx"
	},
	{
		unit: Unit.GAUSS,
		name: "gauss",
		symbol: "G"
	},
	{
		unit: Unit.PH,
		name: "pH",
		symbol: ""
	},
	{
		unit: Unit.WATT,
		name: "watt",
		symbol: "W"
	}
]

export { Units };