import { MagnetometerBase } from './Magnetometer.gen';
import { PhidgetLock } from '../PhidgetLock';
import { ErrorEventCode } from '../Enumerations.gen';
import { BP } from '../BridgePackets.gen';
import { Channel } from '../Channel';
import { BridgePacket, PUNK } from '../BridgePacket';

/** @public */
class Magnetometer extends MagnetometerBase {
	/** @internal */
	transactionLock: PhidgetLock;

	/** @internal */
	constructor();
	/** @internal */
	constructor(ch?: Channel);
	constructor(ch?: Channel) {
		super(ch);
		this.transactionLock = new PhidgetLock();
	}

	/** @internal */
	_bridgeInput(bp: BridgePacket) {
		switch (bp.vpkt) {
			case BP.DATAINTERVALCHANGE:
				if (bp.entryCount > 1)
					this.data.dataInterval = bp.getNumber(1);
				else
					this.data.dataInterval = bp.getNumber(0);
				this._FIREPropertyChange('DataInterval');
				this._FIREPropertyChange('DataRate');
				break;

			case BP.HEATINGENABLEDCHANGE:
				this.data.heatingEnabled = bp.entries[0].v as number;
				this._FIREPropertyChange('HeatingEnabled', bp);
				break;

			default:
				super._bridgeInput(bp);
				break;
		}
	}

	/** @internal */
	_errorHandler(code: ErrorEventCode) {
		switch (code) {
			case ErrorEventCode.SATURATION:
				this.data.magneticField = [PUNK.DBL, PUNK.DBL, PUNK.DBL];
				this.data.timestamp = PUNK.DBL;
				this._gotMagneticFieldChangeErrorEvent = true;
				break;
		}
	}
}

export { Magnetometer };