import { DeviceChannelUID, DeviceUID } from "../../Devices.gen";
import { ErrorCode, ErrorEventCode } from "../../Enumerations.gen";
import { PhidgetError } from "../../PhidgetError";
import { PhidgetUSBRequestType } from '../USB';
import { LocalChannel } from '../LocalChannel';
import { PhidgetUSBDevice, PhidgetUSBDeviceData } from "../PhidgetUSBDevice";
import { USBConnectionBase } from "../USBConnection";
import * as VintPacketType from '../VintPacketType';
import { BridgePacket, PUNK } from "../../BridgePacket";
import { BP } from "../../BridgePackets.gen";
import { RoundDouble } from "../../Utils";

interface DataBufferEntry {
    acceleration: number[],
    angularRate: number[],
    magneticField: number[],
    quaternion: number[],
    timestamp: number,
    temperature: number
}

interface AccelerometerDeviceData {
    // Public members
    timestamp: number[],
    acceleration: number[][],
    accelerationChangeTrigger: number[],
    // Private members
    accelerationMax: number,
    accelerationMin: number,
    interruptRate: number,
    accelDataInterval: number[],
    accelDeadLine: number[],
    dataBuffer: DataBufferEntry[],
    callcnt: number,
    accelerationLastTrigger: number[]
}

class AccelerometerDevice extends PhidgetUSBDevice {
    data: AccelerometerDeviceData;

    constructor(conn: USBConnectionBase, data: PhidgetUSBDeviceData, usbDev: USBDevice) {
        super(conn, data, usbDev);

        // Ensure that we actually support this device
        switch (this.devDef.uid) {
            case DeviceUID._MOT0100_USB:
                break;
            default:
                throw new PhidgetError(ErrorCode.UNSUPPORTED);
        }

        this.data = this.initData();
    }

    // Define getters based on devices.h Attr structs in C library
    get numAccelAxes() { return this.devDef.cn[0]; }

    initData() {
        const data: AccelerometerDeviceData = {
            // Public members
            timestamp: [0],
            acceleration: [new Array(this.numAccelAxes).fill(PUNK.DBL)],
            accelerationChangeTrigger: new Array(this.numAccelAxes).fill(PUNK.DBL),
            // Private members
            accelerationMax: 0,
            accelerationMin: 0,
            interruptRate: 0,
            accelDataInterval: [0, 0, 0],
            accelDeadLine: [0, 0, 0],
            dataBuffer: [],
            callcnt: 0,
            accelerationLastTrigger: new Array(this.numAccelAxes).fill(PUNK.DBL)
        }

        return data;
    }

    // eslint-disable-next-line require-await
    async initAfterOpen() {
        this.data = this.initData();

        this.data.accelerationMax = PUNK.DBL;
        this.data.accelerationMin = PUNK.DBL;
        this.data.interruptRate = PUNK.UINT32;
    }

    async bridgeInput(ch: LocalChannel, bp: BridgePacket): Promise<void> {
        const buf = new DataView(new ArrayBuffer(25));

        switch (this.devDef.uid) {
            case DeviceUID._MOT0100_USB:
                switch (ch.chDef.uid) {
                    case DeviceChannelUID._MOT0100_ACCELEROMETER_100_USB:
                        switch (bp.vpkt) {
                            case BP.SETDATAINTERVAL:
                                buf.setUint16(0, this._handleDataIntervalPacket(bp, 1));
                                await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, ch.uniqueIndex, new Uint8Array(buf.buffer, 0, 2));
                                return;
                            case BP.SETCHANGETRIGGER:
                                buf.setFloat32(0, bp.getNumber(0), true);
                                await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.GenericPacket.AXES_SETAXISCHANGETRIGGER, ch.uniqueIndex, new Uint8Array(buf.buffer, 0, 4));
                                return;
                            case BP.OPENRESET:
                            case BP.CLOSERESET:
                                await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.GenericPacket.PHIDGET_RESET, ch.uniqueIndex);
                                return;
                            case BP.ENABLE:
                                await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.GenericPacket.PHIDGET_ENABLE, ch.uniqueIndex);
                                return;
                            default:
                                throw new PhidgetError(ErrorCode.UNEXPECTED);
                        }
                    default:
                        throw new PhidgetError(ErrorCode.UNEXPECTED);
                }
            default:
                throw new PhidgetError(ErrorCode.UNEXPECTED);
        }
    }

    dataInput(buffer: DataView): void {
        const axis = new Array(this.numAccelAxes).fill(0);
        let channel;
        let timestamp;
        let dataIndex;

        switch (this.devDef.uid) {
            case DeviceUID._MOT0100_USB:
                dataIndex = 0;

                // this timestamp is for the latest data
                timestamp = buffer.getUint32(0);
                dataIndex += 4;

                // add data to data buffer
                for (let i = 0; i < this.numAccelAxes; i++) {
                    axis[i] = buffer.getFloat32(dataIndex + (i * 4), true);
                    if (!isNaN(axis[i]))
                        axis[i] = RoundDouble(axis[i], 6);
                }

                dataIndex += 12;

                if ((channel = this.getChannel(0)) !== null) {
                    switch (channel.chDef.uid) {
                        case DeviceChannelUID._MOT0100_ACCELEROMETER_100_USB: {
                            if (buffer.byteLength > dataIndex) {
                                if (buffer.getUint8(dataIndex) & 0x01) {
                                    const bp = new BridgePacket();
                                    bp.set({ name: "0", type: "d", value: ErrorEventCode.OUT_OF_RANGE });
                                    bp.set({ name: "1", type: "s", value: "One or more accelerometer readings is out of range." });
                                    channel.sendErrorEvent(bp);
                                }
                            }
                            const bp = new BridgePacket();
                            bp.set({ name: "0", type: "G", value: axis });
                            bp.set({ name: "1", type: "g", value: timestamp });
                            bp.sendToChannel(channel, BP.ACCELERATIONCHANGE);
                        }
                            break;
                        default:
                            throw new PhidgetError(ErrorCode.UNEXPECTED);
                    }
                }
                break;
            default:
                throw new PhidgetError(ErrorCode.UNEXPECTED);
        }
    }
}

export { AccelerometerDevice };