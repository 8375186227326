﻿import { ErrorCode } from '../Enumerations.gen';
import { PhidgetError } from '../PhidgetError';
import { WordByteArrayToString } from '../Utils';
import { PhidgetUSBData, PUSBParams } from './PhidgetUSBDevice';

/** @internal */
export const enum PhidgetUSBRequestType {
	PHIDGETUSB_REQ_CHANNEL_WRITE = 0x00,
	PHIDGETUSB_REQ_CHANNEL_READ = 0x01,
	PHIDGETUSB_REQ_DEVICE_WRITE = 0x02,
	PHIDGETUSB_REQ_DEVICE_READ = 0x03,
	PHIDGETUSB_REQ_GPP_WRITE = 0x04,
	PHIDGETUSB_REQ_GPP_READ = 0x05,
	PHIDGETUSB_REQ_BULK_WRITE = 0x06
}

/** @internal */
export const enum PhidgetVendorDescriptorType {
	USB_DESC_TYPE_PHIDGET_DEVICE = (0x40 | 0x00),
	USB_DESC_TYPE_PHIDGET_INTERFACE = (0x40 | 0x01),
	USB_DESC_TYPE_PHIDGET_ENDPOINT = (0x40 | 0x02),
	USB_DESC_TYPE_VINT_PORTS_DESC = (0x40 | 0x03),
	USB_DESC_TYPE_VINT_DEVICE_DESC = (0x40 | 0x04),
	USB_DESC_TYPE_VINT_PORT_DESC = (0x40 | 0x05)
}

/** @internal */
export const USBVID_PHIDGETS = 0x06C2;
const USBPID_PHIDGETS_MIN = 0x0030;
const USBPID_PHIDGETS_MAX = 0x00AF;

const USBD_PHIDGET_PROTO_VERSION = 0x0110;

/** @internal */
const enum USBEndpointDescriptor {
	USB_ENDPOINT_TYPE_MASK = 0x03,
	USB_ENDPOINT_TYPE_CONTROL = 0x00,
	USB_ENDPOINT_TYPE_ISOCHRONOUS = 0x01,
	USB_ENDPOINT_TYPE_BULK = 0x02,
	USB_ENDPOINT_TYPE_INTERRUPT = 0x03,
}

/** @internal */
export const enum PhidgetUSBEndpointType {
	PHID_EP_UNAVAILABLE = 0,
	PHID_EP_BULK = 1,
	PHID_EP_INTERRUPT = 2
}

/** @internal */
export const enum USBDescriptorType {
	USB_DEVICE_DESCRIPTOR_TYPE = 0x01,
	USB_CONFIGURATION_DESCRIPTOR_TYPE = 0x02,
	USB_STRING_DESCRIPTOR_TYPE = 0x03,
	USB_INTERFACE_DESCRIPTOR_TYPE = 0x04,
	USB_ENDPOINT_DESCRIPTOR_TYPE = 0x05
}

const USB_CONFIGURATION_DESCRIPTOR = 9;
const USB_COMMON_DESCRIPTOR = 2;

async function GetPhidgetDeviceParams(usbDevice: USBDevice): Promise<PUSBParams> {
	let endpointDesc = null;
	let phidgetDeviceDesc = null;
	let phidgetEndpointDesc = null;

	const params: PUSBParams = {
		maxPacketEP0: 0,
		ep1type: PhidgetUSBEndpointType.PHID_EP_UNAVAILABLE,
		maxPacketEP1: 0,
		ep2type: PhidgetUSBEndpointType.PHID_EP_UNAVAILABLE,
		maxPacketEP2: 0,
		wMaxPacketSizeEP1: 0,
		labelIndex: 0,
		skuIndex: 0
	}

	let descEnd = null;
	const configDescData = await GetConfigDescriptor(usbDevice, 0);

	const configDesc = {
		bLength: configDescData.getUint8(0),
		bDescriptorType: configDescData.getUint8(1),
		wTotalLength: (configDescData.getUint8(3) << 8) | configDescData.getUint8(2),
		bNumInterfaces: configDescData.getUint8(4),
		bConfigurationValue: configDescData.getUint8(5),
		iConfiguration: configDescData.getUint8(6),
		bmAttributes: configDescData.getUint8(7),
		MaxPower: configDescData.getUint8(8)
	}

	descEnd = 0 + configDesc.wTotalLength;

	params.ep1type = PhidgetUSBEndpointType.PHID_EP_UNAVAILABLE;
	params.maxPacketEP1 = 0;
	params.ep2type = PhidgetUSBEndpointType.PHID_EP_UNAVAILABLE;
	params.maxPacketEP2 = 0;

	let i = 0;
	while (i + 2 < descEnd && i + configDescData.getUint8(i) <= descEnd) {
		switch (configDescData.getUint8(i + 1)) {
			case USBDescriptorType.USB_CONFIGURATION_DESCRIPTOR_TYPE:
				if (configDescData.getUint8(i) !== 9)
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Error parsing config descriptor!");
				break;
			case USBDescriptorType.USB_INTERFACE_DESCRIPTOR_TYPE:
				break;
			case USBDescriptorType.USB_ENDPOINT_DESCRIPTOR_TYPE:
				if (configDescData.getUint8(i) !== 7)
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Error parsing config descriptor!");

				endpointDesc = {
					bLength: configDescData.getUint8(i),
					bDescriptorType: configDescData.getUint8(i + 1),
					bEndpointAddress: configDescData.getUint8(i + 2),
					bmAttributes: configDescData.getUint8(i + 3),
					wMaxPacketSize: (configDescData.getUint8(i + 5) << 8) | configDescData.getUint8(i + 4),
					bInterval: configDescData.getUint8(i + 6),
				}

				//EP1 IN
				if (endpointDesc.bEndpointAddress === 0x81) {
					params.wMaxPacketSizeEP1 = endpointDesc.wMaxPacketSize;
					if (endpointDesc.bmAttributes === USBEndpointDescriptor.USB_ENDPOINT_TYPE_BULK)
						params.ep1type = PhidgetUSBEndpointType.PHID_EP_BULK;
					if (endpointDesc.bmAttributes === USBEndpointDescriptor.USB_ENDPOINT_TYPE_INTERRUPT)
						params.ep1type = PhidgetUSBEndpointType.PHID_EP_INTERRUPT;
				}

				//EP2 OUT
				if (endpointDesc.bEndpointAddress === 0x02) {
					params.maxPacketEP2 = endpointDesc.wMaxPacketSize;
					params.ep2type = PhidgetUSBEndpointType.PHID_EP_BULK;
				}

				break;
			case PhidgetVendorDescriptorType.USB_DESC_TYPE_PHIDGET_DEVICE:
				if (configDescData.getUint8(i) !== 8)
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Error parsing config descriptor!");

				phidgetDeviceDesc = {
					bLength: configDescData.getUint8(i),
					bDescriptorType: configDescData.getUint8(i + 1),
					bcdVersion: (configDescData.getUint8(i + 3) << 8) | configDescData.getUint8(i + 2),
					iLabel: configDescData.getUint8(i + 4),
					iSKU: configDescData.getUint8(i + 5),
					wMaxPacketSize: (configDescData.getUint8(i + 7) << 8) | configDescData.getUint8(i + 6),
				}
				if (phidgetDeviceDesc.bcdVersion !== USBD_PHIDGET_PROTO_VERSION) {
					throw new PhidgetError(ErrorCode.UNSUPPORTED, "Unknown Phidget descriptor version: " + phidgetDeviceDesc.bcdVersion + " - Library upgrade may be required.");
				}

				params.labelIndex = phidgetDeviceDesc.iLabel;
				params.skuIndex = phidgetDeviceDesc.iSKU;
				params.maxPacketEP0 = phidgetDeviceDesc.wMaxPacketSize;

				break;
			case PhidgetVendorDescriptorType.USB_DESC_TYPE_PHIDGET_ENDPOINT:
				if (configDescData.getUint8(i) !== 4)
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Error parsing config descriptor!");

				phidgetEndpointDesc = {
					bLength: configDescData.getUint8(i),
					bDescriptorType: configDescData.getUint8(i + 1),
					wMaxPacketSize: (configDescData.getUint8(i + 3) << 8) | configDescData.getUint8(i + 2),
				}

				if (endpointDesc == undefined)
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Didn't get Phidget Endpoint descriptor!");

				// This phidget endpoint descriptor enhances the preceding endpoint descriptor
				if ((endpointDesc.bEndpointAddress & 0x7F) == 0x01)
					params.maxPacketEP1 = phidgetEndpointDesc.wMaxPacketSize;
				if ((endpointDesc.bEndpointAddress & 0x7F) == 0x02)
					params.maxPacketEP2 = phidgetEndpointDesc.wMaxPacketSize;

				break;

			default:
				break;
		}

		i += configDescData.getUint8(i);
	}

	return (params);
}

export async function GetPhidgetUSBData(usbDevice: USBDevice): Promise<PhidgetUSBData> {
	let desc;

	if (usbDevice.vendorId !== USBVID_PHIDGETS)
		throw new PhidgetError(ErrorCode.UNSUPPORTED);
	if (usbDevice.productId < USBPID_PHIDGETS_MIN || usbDevice.productId > USBPID_PHIDGETS_MAX)
		throw new PhidgetError(ErrorCode.UNSUPPORTED);

	// We've found the device - next read some params (may fail if device is open elsewhere)

	//getting Descriptors
	desc = await GetDescriptor(usbDevice, USBDescriptorType.USB_DEVICE_DESCRIPTOR_TYPE, 0, 0);

	const devDescriptor = {
		bLength: desc.getUint8(0),
		bDescriptorType: desc.getUint8(1),
		bcdUSB: (desc.getUint8(3) << 8) | desc.getUint8(2),
		bDeviceClass: desc.getUint8(4),
		bDeviceSubClass: desc.getUint8(5),
		bDeviceProtocol: desc.getUint8(6),
		bMaxPacketSize0: desc.getUint8(7),
		idVendor: (desc.getUint8(9) << 8) | desc.getUint8(8),
		idProduct: (desc.getUint8(11) << 8) | desc.getUint8(10),
		bcdDevice: (desc.getUint8(13) << 8) | desc.getUint8(12),
		iManufacturer: desc.getUint8(14),
		iProduct: desc.getUint8(15),
		iSerialNumber: desc.getUint8(16),
		bNumConfigurations: desc.getUint8(17),
	};

	desc = await GetDescriptor(usbDevice, USBDescriptorType.USB_STRING_DESCRIPTOR_TYPE, devDescriptor.iSerialNumber, 0);

	// BCD -> Decimal (up to 4 digits)
	const version = (((devDescriptor.bcdDevice >> 12) & 0x0F) * 1000)
		+ (((devDescriptor.bcdDevice >> 8) & 0x0F) * 100)
		+ (((devDescriptor.bcdDevice >> 4) & 0x0F) * 10)
		+ (devDescriptor.bcdDevice & 0x0F);

	// convert the whole strDescriptor to uint16, but drop the first two element since they are bLength and bDescriptorType
	//      remaining elements make up the string
	const serial = parseInt(WordByteArrayToString(new Uint16Array(desc.buffer, 2)));
	//log("serialNumber: " + devData.serialNumber);

	const phidDevParams = await GetPhidgetDeviceParams(usbDevice);

	desc = await GetDescriptor(usbDevice, USBDescriptorType.USB_STRING_DESCRIPTOR_TYPE, phidDevParams.skuIndex, 0);

	// convert the whole strDescriptor to uint16, but drop the first two element since they are bLength and bDescriptorType
	//      remaining elements make up the string
	const skuString = WordByteArrayToString(new Uint16Array(desc.buffer, 2));

	const interfaceNum = usbDevice.configuration?.interfaces[0].interfaceNumber ?? 0;

	let label = '';

	// This means label is supported by this Phidget
	if (phidDevParams.labelIndex != 0) {
		const desc = await GetDescriptor(usbDevice, USBDescriptorType.USB_STRING_DESCRIPTOR_TYPE, phidDevParams.labelIndex, 0);
		// >22 means not a valid label - could be a device in firmware upgrade mode
		if (desc.byteLength <= 22) {
			const bLength = desc.getUint8(0);
			if (bLength > 2) {
				const bStringWordArray = new Uint16Array(desc.buffer);
				label = WordByteArrayToString((bStringWordArray).slice(1));
			}
		}
	}

	const devData = {
		version: version,
		serialNumber: serial,
		productID: usbDevice.productId,
		vendorID: usbDevice.vendorId,
		interfaceNum: interfaceNum,
		fwstr: skuString,
		label: label,
		pusbParams: phidDevParams
	}

	return devData;
}

export async function GetDescriptor(usbDevice: USBDevice, DescriptorType: number, DescriptorIndex: number, wIndex: number): Promise<DataView> {
	let descReq;
	const controlTransferParams = {
		requestType: 'standard' as const,
		recipient: 'device' as const,
		request: (0x06),
		value: (DescriptorType << 8 | DescriptorIndex),
		index: wIndex,
	}

	// Read out the header to get the actual descriptor length
	try {
		descReq = await usbDevice.controlTransferIn(controlTransferParams, USB_COMMON_DESCRIPTOR);
	} catch (err) {
		throw new PhidgetError(ErrorCode.IO, "Failed to read descriptor", err);
	}
	if (descReq.status !== 'ok')
		throw new PhidgetError(ErrorCode.UNEXPECTED, "Failed to read descriptor: " + descReq.status);

	if (!descReq.data || descReq.data.byteLength != USB_COMMON_DESCRIPTOR)
		throw new PhidgetError(ErrorCode.UNEXPECTED, "GetDescriptor error - returned unexpected number of bytes");

	const bLength = descReq.data.getUint8(0);

	// if the length is just the header, return now
	if (bLength <= USB_COMMON_DESCRIPTOR)
		return descReq.data;

	// read out the full descriptor
	try {
		descReq = await usbDevice.controlTransferIn(controlTransferParams, bLength);
	} catch (err) {
		throw new PhidgetError(ErrorCode.IO, "Failed to read descriptor", err);
	}
	if (descReq.status !== 'ok')
		throw new PhidgetError(ErrorCode.UNEXPECTED, "Failed to read descriptor: " + descReq.status);

	if (!descReq.data || descReq.data.byteLength != bLength)
		throw new PhidgetError(ErrorCode.UNEXPECTED, "GetDescriptor error - returned unexpected number of bytes");

	return descReq.data;
}

async function GetConfigDescriptor(usbDevice: USBDevice, DescriptorIndex: number) {

	const controlTransferParams = {
		requestType: 'standard' as const,
		recipient: 'device' as const,
		request: (0x06),
		value: (USBDescriptorType.USB_CONFIGURATION_DESCRIPTOR_TYPE << 8 | DescriptorIndex),
		index: 0,
	}

	//Initial request of Configuration Descriptor in order to get TotalLength of Config Descriptor for a subsequent request
	let configDescReq = await usbDevice.controlTransferIn(controlTransferParams, USB_CONFIGURATION_DESCRIPTOR);
	if (configDescReq.status !== 'ok')
		throw new PhidgetError(ErrorCode.UNEXPECTED, "Failed to read descriptor: " + configDescReq.status);
	if (!configDescReq.data)
		throw new PhidgetError(ErrorCode.UNEXPECTED, "GetDescriptor error - returned unexpected number of bytes");

	const wTotalLength = (configDescReq.data.getUint8(3) << 8) | configDescReq.data.getUint8(2);
	if (wTotalLength < USB_CONFIGURATION_DESCRIPTOR)
		throw new PhidgetError(ErrorCode.UNEXPECTED, "GetDescriptor error - too short!");

	//Issue request for full configuration descriptor
	configDescReq = await usbDevice.controlTransferIn(controlTransferParams, wTotalLength);
	if (configDescReq.status !== 'ok')
		throw new PhidgetError(ErrorCode.UNEXPECTED, "Failed to read descriptor: " + configDescReq.status);
	if (!configDescReq.data)
		throw new PhidgetError(ErrorCode.UNEXPECTED, "GetDescriptor error - returned unexpected number of bytes");
	if (configDescReq.data.byteLength !== wTotalLength)
		throw new PhidgetError(ErrorCode.UNEXPECTED, "GetDescriptor error - returned unexpected number of bytes");

	return configDescReq.data;
}