﻿import { BP } from '../BridgePackets.gen';
import { DeviceChannelUID } from '../Devices.gen';
import { ErrorCode, ErrorEventCode } from '../Enumerations.gen';
import { PhidgetError } from '../PhidgetError';
import * as Enum from '../Enumerations.gen';
import * as SEnum from '../SupportedEnum.gen';
import { BridgePacket, PUNK } from '../BridgePacket';
import { SetBigInt64, GetBigInt64, RoundDouble } from '../Utils';
import * as VintPacketType from './VintPacketType';
import { LocalChannel } from './LocalChannel';
import { MAX_OUT_PACKET_SIZE } from './LocalDevice';
import * as UserPhidgetClass from '../Classes.gen';

export const VINT_MAX_OUT_PACKETSIZE = 48;
//const VINT_MAX_IN_PACKETSIZE = 48;

/* Return codes for data sent PC -> hub -> device */
export const enum VINTStatusCode {
	/* Define status codes in the range 0xE0 - 0xFE */
	ACK = 0xE0,		            /* Success */
	NAK = 0xE1,		            /* Not ready. Library should try to re-send. */
	INVALIDARG = 0xE2,		    /* A provided argument contained an invalid value */
	INVALIDPACKETTYPE = 0xE3,	/* The specified packet type is not used on this device */
	INVALIDSEQUENCE = 0xE4,		/* The received packet was not sent in the required sequence */
	INVALIDCOMMAND = 0xE5,		/* The requested action cannot be performed due to configuration/state of device */
	MALFORMED = 0xE6,		    /* The packet length/contents did not match those specified in the documentation */
	NOSPACE = 0xE7,		        /* There was not enough space in the hub queue for this packet */
	UNEXPECTED = 0xE8,	    	/* Generic unexpected status. Should not happen if firmware is properly written */
	NOTATTACHED = 0xE9,	    	/* VINT Device no longer attached. */
	TOOBIG = 0xEA,		        /* Packet too long */
	FAILSAFE = 0xEB		        /* The device's failsafe condition has tripped, and all packets but RESET will get this response. */
}

export const VINTPacketDescription = {
	0xE0: "Success",
	0xE1: "Not Ready",
	0xE2: "Invalid Argument",
	0xE3: "Invalid Packet Type",
	0xE4: "Invalid Sequence",
	0xE5: "Invalid Command",
	0xE6: "Malformed",
	0xE7: "No Space",
	0xE8: "Unexpected",
	0xE9: "Not Attached",
	0xEA: "Packet Too Big",
	0xEB: "Failsafe Tripped"
};

export const VINTPacketCode_to_PhidgetReturnCode = {
	0xE0: ErrorCode.SUCCESS,
	0xE1: ErrorCode.BUSY,
	0xE2: ErrorCode.INVALID_ARGUMENT,
	0xE3: ErrorCode.INVALID_PACKET,
	0xE4: ErrorCode.NOT_CONFIGURED,
	0xE5: ErrorCode.INVALID,
	0xE6: ErrorCode.INVALID,
	0xE7: ErrorCode.NO_SPACE,
	0xE8: ErrorCode.UNEXPECTED,
	0xE9: ErrorCode.NOT_ATTACHED,
	0xEA: ErrorCode.FILE_TOO_BIG,
	0xEB: ErrorCode.FAILSAFE
};

export async function send1014_3_VINT(ch: LocalChannel, bp: BridgePacket) {
	let dutyCycle;
	const buf = new DataView(new ArrayBuffer(2));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._1014_DIGITALOUTPUT_800_VINT:
			switch (bp.vpkt) {
				case BP.SETSTATE:
					buf.setUint8(0, bp.getNumber(0) ? 0xFF : 0x00);
					await ch.sendVINTDataPacket(VintPacketType.DigitalOutputPacket.SETDUTYCYCLE, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETDUTYCYCLE:
					dutyCycle = bp.getNumber(0);
					if (dutyCycle !== 0 && dutyCycle !== 1) {
						throw new PhidgetError(ErrorCode.INVALID_ARGUMENT);
					}
					buf.setUint8(0, dutyCycle ? 0xFF : 0x00);
					await ch.sendVINTDataPacket(VintPacketType.DigitalOutputPacket.SETDUTYCYCLE, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETFAILSAFETIME:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_TIME, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.FAILSAFERESET:
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_RESET);
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recv1014_3_VINT(ch: LocalChannel, buf: DataView) {

	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._1014_DIGITALOUTPUT_800_VINT:
			switch (pkt) {
				case VintPacketType.DigitalOutputPacket.FAILSAFE: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.FAILSAFE_CONDITION });
					bp.set({ name: '1', type: 's', value: 'Failsafe procedure initiated.' });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function send1017_2_VINT(ch: LocalChannel, bp: BridgePacket) {
	let dutyCycle;
	const buf = new DataView(new ArrayBuffer(2));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._1017_DIGITALOUTPUT_200_VINT:
			switch (bp.vpkt) {
				case BP.SETSTATE:
					buf.setUint8(0, bp.getNumber(0) ? 0xFF : 0x00);
					await ch.sendVINTDataPacket(VintPacketType.DigitalOutputPacket.SETDUTYCYCLE, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETDUTYCYCLE:
					dutyCycle = bp.getNumber(0);
					if (dutyCycle !== 0 && dutyCycle !== 1) {
						throw new PhidgetError(ErrorCode.INVALID_ARGUMENT);
					}
					buf.setUint8(0, dutyCycle ? 0xFF : 0x00);
					await ch.sendVINTDataPacket(VintPacketType.DigitalOutputPacket.SETDUTYCYCLE, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETFAILSAFETIME:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_TIME, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.FAILSAFERESET:
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_RESET);
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recv1017_2_VINT(ch: LocalChannel, buf: DataView) {
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._1017_DIGITALOUTPUT_200_VINT:
			switch (pkt) {
				case VintPacketType.DigitalOutputPacket.FAILSAFE: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.FAILSAFE_CONDITION });
					bp.set({ name: '1', type: 's', value: 'Failsafe procedure initiated.' });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function send1024_V2_VINT(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(64));
	let tagString;
	let protocol;
	let dutyCycle;
	let len;

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const userphid = (ch.userphid! as unknown as UserPhidgetClass.RFID);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._1024_RFID_200_VINT:
			switch (bp.vpkt) {
				case BP.SETANTENNAON:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.RFIDPacket.ANTENNA_ON, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.WRITE:
					protocol = bp.getNumber(1);
					if (protocol < Enum.RFIDProtocol.EM4100 || protocol > Enum.RFIDProtocol.PHIDGET_TAG) {
						throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Invalid protocol type");
					}
					buf.setUint8(0, bp.getNumber(1));
					buf.setUint8(1, bp.getNumber(2));

					tagString = bp.getString(0);
					if (protocol === Enum.RFIDProtocol.EM4100 && tagString.charAt(1) === 'x') {
						tagString = tagString.slice(2);
					}
					if (tagString.length > (MAX_OUT_PACKET_SIZE - 3)) {
						throw new PhidgetError(ErrorCode.INVALID_ARGUMENT);
					}
					for (let i = 0; i < tagString.length; i++) {
						buf.setUint8(2 + i, tagString.charCodeAt(i));
					}
					buf.setUint8(2 + tagString.length, 0x00);   //append \0 after string

					if (protocol === Enum.RFIDProtocol.EM4100) {
						for (let i = 0; i < 10; i++) {
							const toLower = String.fromCharCode(buf.getUint8(2 + i)).toLowerCase();
							buf.setUint8(2 + i, toLower.charCodeAt(0));
						}
					}

					len = tagString.length + 2;
					userphid._setLatestTagString("");   //clear latest tag                    

					await ch.sendVINTDataPacket(VintPacketType.RFIDPacket.TAG_WRITE, new Uint8Array(buf.buffer, 0, len));
					try {
						await userphid._waitForTag(tagString, 600);
					} catch {
						throw new PhidgetError(ErrorCode.TIMEOUT, "Timed out waiting for tag to appear after writing. Try again.");
					}
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._1024_DIGITALOUTPUT_5V_200_VINT:
		case DeviceChannelUID._1024_DIGITALOUTPUT_LED_200_VINT:
		case DeviceChannelUID._1024_DIGITALOUTPUT_ONBOARD_LED_200_VINT:
			switch (bp.vpkt) {
				case BP.SETSTATE:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.DigitalOutputPacket.SETDUTYCYCLE, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETDUTYCYCLE:
					dutyCycle = bp.getNumber(0);
					if (dutyCycle !== 0 && dutyCycle !== 1) {
						throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Duty cycle myust be 0 or 1.");
					}
					buf.setUint8(0, dutyCycle);
					await ch.sendVINTDataPacket(VintPacketType.DigitalOutputPacket.SETDUTYCYCLE, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.OPENRESET:
				case BP.CLOSERESET:
					buf.setUint8(0, 0);
					await ch.sendVINTDataPacket(VintPacketType.DigitalOutputPacket.SETDUTYCYCLE, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.ENABLE:
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recv1024_V2_VINT(ch: LocalChannel, buf: DataView) {
	let tagString;
	const pkt = buf.getUint8(0);

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const userphid = (ch.userphid! as unknown as UserPhidgetClass.RFID);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._1024_RFID_200_VINT:
			switch (pkt) {
				case VintPacketType.RFIDPacket.TAG: {
					tagString = "";

					for (let i = 2, byteChar = buf.getUint8(i); i < buf.byteLength && String.fromCharCode(byteChar) !== '\0'; i++, byteChar = buf.getUint8(i)) {
						tagString += String.fromCharCode(byteChar);
					}

					userphid._setLatestTagString(tagString);

					const bp = new BridgePacket();
					bp.set({ name: '0', type: 's', value: tagString });
					bp.set({ name: '1', type: 'd', value: buf.getUint8(1) });
					bp.sendToChannel(ch, BP.TAG);
					break;
				}
				case VintPacketType.RFIDPacket.TAG_LOST: {
					tagString = "";

					for (let i = 2, byteChar = buf.getUint8(i); i < buf.byteLength && String.fromCharCode(byteChar) !== '\0'; i++, byteChar = buf.getUint8(i)) {
						tagString += String.fromCharCode(byteChar);
					}

					const bp = new BridgePacket();
					bp.set({ name: '0', type: 's', value: tagString });
					bp.set({ name: '1', type: 'd', value: buf.getUint8(1) });
					bp.sendToChannel(ch, BP.TAGLOST);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._1024_DIGITALOUTPUT_5V_200_VINT:
			switch (pkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
		case DeviceChannelUID._1024_DIGITALOUTPUT_LED_200_VINT:
			switch (pkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			switch (pkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

// eslint-disable-next-line require-await
export async function send1055_1_VINT(ch: LocalChannel, bp: BridgePacket) {
	throw new Error("Not Implemented");

	// eslint-disable-next-line no-unreachable
	switch (ch.chDef.uid) {
		case DeviceChannelUID._1055_IR_200_VINT:
			switch (bp.vpkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recv1055_1_VINT(ch: LocalChannel, buf: DataView) {
	throw new Error("Not Implemented");

	// eslint-disable-next-line no-unreachable
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._1055_IR_200_VINT:
			switch (pkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

// eslint-disable-next-line require-await
export async function sendDIGITALINPUT_PORT() {
	throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected command received.");
}

export function recvDIGITALINPUT_PORT(ch: LocalChannel, buf: DataView) {
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._HUB_DIGITALINPUT_100: {
			const bp = new BridgePacket();
			switch (pkt) {
				case VintPacketType.DigitalInputPacket.STATECHANGE:
					bp.set({ name: "0", type: "d", value: 1 });
					break;
				case VintPacketType.DigitalInputPacket.STATECHANGE2:
					bp.set({ name: "0", type: "d", value: 0 });
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			bp.sendToChannel(ch, BP.STATECHANGE);
			break;
		}
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendDIGITALOUTPUT_PORT(ch: LocalChannel, bp: BridgePacket) {
	const buf = new Uint16Array(1);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._HUB_DIGITALOUTPUT_100:
			switch (bp.vpkt) {
				case BP.SETSTATE:
					buf.set(bp.getBoolean(0) ? [1 << 15] : [0], 0);
					await ch.sendVINTDataPacket(VintPacketType.DigitalOutputPacket.SETDUTYCYCLE, new Uint8Array(buf.buffer, 0, buf.byteLength));
					break;
				case BP.SETDUTYCYCLE:
					buf.set([bp.getNumber(0) * (1 << 15)], 0);
					await ch.sendVINTDataPacket(VintPacketType.DigitalOutputPacket.SETDUTYCYCLE, new Uint8Array(buf.buffer, 0, buf.byteLength));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._HUB_DIGITALOUTPUT_110:
			switch (bp.vpkt) {
				case BP.SETSTATE:
					buf.set(bp.getBoolean(0) ? [1 << 7] : [0], 0);
					await ch.sendVINTDataPacket(VintPacketType.DigitalOutputPacket.SETDUTYCYCLE, new Uint8Array(buf.buffer, 0, buf.byteLength));
					break;
				case BP.SETDUTYCYCLE: {
					buf.set([bp.getNumber(0) * (1 << 15)], 0);
					const buf8 = new Uint8Array(buf.buffer, 0, buf.byteLength);
					await ch.sendVINTDataPacket(VintPacketType.DigitalOutputPacket.SETDUTYCYCLE, buf8.reverse());
					break;
				}
				case BP.SETFAILSAFETIME:
					buf.set([bp.getNumber(0)], 0);
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_TIME, new Uint8Array(buf.buffer, 0, buf.byteLength));
					break;
				case BP.FAILSAFERESET:
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_RESET);
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvDIGITALOUTPUT_PORT(ch: LocalChannel, buf: DataView) {
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._HUB_DIGITALOUTPUT_100:
			throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet received.");
		case DeviceChannelUID._HUB_DIGITALOUTPUT_110: {
			switch (pkt) {
				case VintPacketType.DigitalOutputPacket.FAILSAFE: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.FAILSAFE_CONDITION });
					bp.set({ name: "1", type: "s", value: "Failsafe procedure initiated." });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		}
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendDIGITALOUTPUT_PORT_FAILSAFE(ch: LocalChannel, bp: BridgePacket) {
	await sendDIGITALOUTPUT_PORT(ch, bp);
}

export function recvDIGITALOUTPUT_PORT_FAILSAFE(ch: LocalChannel, buf: DataView) {
	recvDIGITALOUTPUT_PORT(ch, buf);
}

export async function sendVOLTAGEINPUT_PORT(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._HUB_VOLTAGEINPUT_100:
		case DeviceChannelUID._HUB_VOLTAGEINPUT_110:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setUint16(0, (bp.getNumber(0) / 5) * (1 << 15));
					await ch.sendVINTDataPacket(VintPacketType.VoltageInputPacket.SETVOLTAGECHANGETRIGGER, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETSENSORTYPE:
					if (!SEnum.supportedVoltageSensorType(ch, bp.getNumber(0)))
						throw new PhidgetError(ErrorCode.INVALID_ARGUMENT);
					break;
				case BP.SETSENSORVALUECHANGETRIGGER:
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvVOLTAGEINPUT_PORT(ch: LocalChannel, buf: DataView) {
	const pkt = buf.getUint8(0);
	let voltage;

	switch (ch.chDef.uid) {
		case DeviceChannelUID._HUB_VOLTAGEINPUT_100:
		case DeviceChannelUID._HUB_VOLTAGEINPUT_110: {
			switch (pkt) {
				case VintPacketType.VoltageInputPacket.VOLTAGECHANGE: {
					voltage = (buf.getUint16(1) * 5.0) / (1 << 15);
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "g", value: RoundDouble(voltage, 5) });
					bp.sendToChannel(ch, BP.VOLTAGECHANGE);
					break;
				}
				case VintPacketType.VoltageInputPacket.SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.SATURATION });
					bp.set({ name: "1", type: "s", value: "Saturation Detected." });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		}
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendVOLTAGEINPUT_PORT_5V25(ch: LocalChannel, bp: BridgePacket) {
	await sendVOLTAGEINPUT_PORT(ch, bp);
}

export function recvVOLTAGEINPUT_PORT_5V25(ch: LocalChannel, buf: DataView) {
	recvVOLTAGEINPUT_PORT(ch, buf);
}

export async function sendVOLTAGEINPUT_PORT_V2(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._HUB_VOLTAGEINPUT_200:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.VoltageInputPacket.SETVOLTAGECHANGETRIGGER, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETSENSORTYPE:
					if (!SEnum.supportedVoltageSensorType(ch, bp.getNumber(0)))
						throw new PhidgetError(ErrorCode.INVALID_ARGUMENT);
					break;
				case BP.SETSENSORVALUECHANGETRIGGER:
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvVOLTAGEINPUT_PORT_V2(ch: LocalChannel, buf: DataView) {
	const pkt = buf.getUint8(0);
	let voltage;

	switch (ch.chDef.uid) {
		case DeviceChannelUID._HUB_VOLTAGEINPUT_200: {
			switch (pkt) {
				case VintPacketType.VoltageInputPacket.VOLTAGECHANGE: {
					if (buf.byteLength == 6) {
						switch (buf.getUint8(5)) {
							case 1: { // VINT_PACKET_ERRORBYTE_OUTOFRANGEHIGH
								const bp = new BridgePacket();
								bp.set({ name: "0", type: "d", value: ErrorEventCode.OUT_OF_RANGE_HIGH_CONDITION });
								bp.set({ name: "1", type: "s", value: "Voltage is too high to be accurately measured" });
								ch.sendErrorEvent(bp);
								break;
							}
							default:
								throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected error event code");
						}
					}
					voltage = buf.getFloat32(1, true);
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "g", value: RoundDouble(voltage, 6) });
					bp.sendToChannel(ch, BP.VOLTAGECHANGE);
					break;
				}
				case VintPacketType.VoltageInputPacket.SATURATION:
					switch (buf.getUint8(1)) {
						case 1: { // VINT_PACKET_ERRORBYTE_OUTOFRANGEHIGH
							const bp = new BridgePacket();
							bp.set({ name: "0", type: "d", value: ErrorEventCode.OUT_OF_RANGE_HIGH_CONDITION });
							bp.set({ name: "1", type: "s", value: "Voltage is too high to be accurately measured" });
							ch.sendErrorEvent(bp);
							break;
						}
						default:
							throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected error event code");
					}
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		}
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendVOLTAGERATIOINPUT_PORT(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._HUB_VOLTAGERATIOINPUT_100:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setUint16(0, bp.getNumber(0) * 32768);
					await ch.sendVINTDataPacket(VintPacketType.VoltageRatioInputPacket.SETVOLTAGERATIOCHANGETRIGGER, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETSENSORTYPE:
					if (!SEnum.supportedVoltageRatioSensorType(ch, bp.getNumber(0)))
						throw new PhidgetError(ErrorCode.INVALID_ARGUMENT);
					break;
				case BP.SETSENSORVALUECHANGETRIGGER:
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvVOLTAGERATIOINPUT_PORT(ch: LocalChannel, buf: DataView) {
	let voltageRatio;
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._HUB_VOLTAGERATIOINPUT_100: {
			switch (pkt) {
				case VintPacketType.VoltageRatioInputPacket.VOLTAGERATIOCHANGE: {
					voltageRatio = buf.getUint16(1) / (1 << 15);
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "g", value: RoundDouble(voltageRatio, 5) });
					bp.sendToChannel(ch, BP.VOLTAGERATIOCHANGE);
					break;
				}
				case VintPacketType.VoltageRatioInputPacket.SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.SATURATION });
					bp.set({ name: "1", type: "s", value: "Saturation Detected." });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		}
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendVOLTAGERATIOINPUT_PORT_V2(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._HUB_VOLTAGERATIOINPUT_200:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.VoltageRatioInputPacket.SETVOLTAGERATIOCHANGETRIGGER, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETSENSORTYPE:
					if (!SEnum.supportedVoltageRatioSensorType(ch, bp.getNumber(0)))
						throw new PhidgetError(ErrorCode.INVALID_ARGUMENT);
					break;
				case BP.SETSENSORVALUECHANGETRIGGER:
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvVOLTAGERATIOINPUT_PORT_V2(ch: LocalChannel, buf: DataView) {
	const pkt = buf.getUint8(0);
	let voltageRatio;

	switch (ch.chDef.uid) {
		case DeviceChannelUID._HUB_VOLTAGERATIOINPUT_200: {
			switch (pkt) {
				case VintPacketType.VoltageRatioInputPacket.VOLTAGERATIOCHANGE: {
					if (buf.byteLength == 6) {
						switch (buf.getUint8(5)) {
							case 1: { // VINT_PACKET_ERRORBYTE_OUTOFRANGEHIGH
								const bp = new BridgePacket();
								bp.set({ name: "0", type: "d", value: ErrorEventCode.OUT_OF_RANGE_HIGH_CONDITION });
								bp.set({ name: "1", type: "s", value: "Voltage Ratio is too high to be accurately measured" });
								ch.sendErrorEvent(bp);
								break;
							}
							default:
								throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected error event code");
						}
					}
					voltageRatio = buf.getFloat32(1, true);
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "g", value: RoundDouble(voltageRatio, 6) });
					bp.sendToChannel(ch, BP.VOLTAGERATIOCHANGE);
					break;
				}
				case VintPacketType.VoltageRatioInputPacket.SATURATION:
					switch (buf.getUint8(1)) {
						case 1: { // VINT_PACKET_ERRORBYTE_OUTOFRANGEHIGH
							const bp = new BridgePacket();
							bp.set({ name: "0", type: "d", value: ErrorEventCode.OUT_OF_RANGE_HIGH_CONDITION });
							bp.set({ name: "1", type: "s", value: "Voltage Ratio is too high to be accurately measured" });
							ch.sendErrorEvent(bp);
							break;
						}
						default:
							throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected error event code");
					}
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		}
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendADP1000(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._ADP1000_PHSENSOR_100:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setUint32(0, bp.getNumber(0) * (1 << 24));
					await ch.sendVINTDataPacket(VintPacketType.PHPacket.SETPHCHANGETRIGGER, new Uint8Array(buf.buffer));
					break;
				case BP.SETCORRECTIONTEMPERATURE:
					buf.setUint16(0, bp.getNumber(0) * (1 << 3));
					await ch.sendVINTDataPacket(VintPacketType.PHPacket.SETCORRECTIONTEMPERATURE, new Uint8Array(buf.buffer, 0, 2));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._ADP1000_VOLTAGEINPUT_100:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setUint32(0, bp.getNumber(0) * (1 << 24));
					await ch.sendVINTDataPacket(VintPacketType.VoltageInputPacket.SETVOLTAGECHANGETRIGGER, new Uint8Array(buf.buffer));
					break;
				case BP.SETVOLTAGERANGE: {
					const range = bp.getNumber(0);
					switch (range) {
						case Enum.VoltageRange.MILLIVOLTS_400:
						case Enum.VoltageRange.VOLTS_2:
							buf.setUint8(0, range);
							await ch.sendVINTDataPacket(VintPacketType.VoltageInputPacket.PHRANGE, new Uint8Array(buf.buffer, 0, 1));
							break;
						default:
							throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Invalid or unsupported voltage range for this device.");
					}
					break;
				}
				case BP.SETSENSORTYPE: {
					const sensorType = bp.getNumber(0);
					if (!SEnum.supportedVoltageSensorType(ch, sensorType)) {
						throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Invalid or unsupported voltage sensor type");
					}
					break;
				}
				case BP.SETSENSORVALUECHANGETRIGGER:
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvADP1000(ch: LocalChannel, buf: DataView) {
	let voltage;

	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._ADP1000_PHSENSOR_100:
			switch (pkt) {
				case VintPacketType.PHPacket.PHCHANGE: {
					voltage = buf.getInt32(1) / (1 << 24);
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "g", value: RoundDouble(voltage, 5) });
					bp.sendToChannel(ch, BP.PHCHANGE);
					break;
				}
				case VintPacketType.PHPacket.SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.SATURATION });
					bp.set({ name: "1", type: "s", value: "Saturation Detected." });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._ADP1000_VOLTAGEINPUT_100:
			switch (pkt) {
				case VintPacketType.VoltageInputPacket.VOLTAGECHANGE: {
					voltage = buf.getInt32(1) / (1 << 24);
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "g", value: RoundDouble(voltage, 5) });
					bp.sendToChannel(ch, BP.VOLTAGECHANGE);
					break;
				}
				case VintPacketType.VoltageInputPacket.SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.SATURATION });
					bp.set({ name: "1", type: "s", value: "Saturation Detected." });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

// eslint-disable-next-line require-await
export async function sendADP1001_VINT(ch: LocalChannel, bp: BridgePacket) {
	throw new Error("Not Implemented");

	// eslint-disable-next-line no-unreachable
	switch (ch.chDef.uid) {
		case DeviceChannelUID._ADP1001_DATAADAPTER_100_VINT:
			switch (bp.vpkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._ADP1001_DIGITALINPUT_DSR_100_VINT:
			switch (bp.vpkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._ADP1001_DIGITALINPUT_DCD_100_VINT:
			switch (bp.vpkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._ADP1001_DIGITALINPUT_RI_100_VINT:
			switch (bp.vpkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._ADP1001_DIGITALOUTPUT_DTR_100_VINT:
			switch (bp.vpkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvADP1001_VINT(ch: LocalChannel, buf: DataView) {
	throw new Error("Not Implemented");

	// eslint-disable-next-line no-unreachable
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._ADP1001_DATAADAPTER_100_VINT:
			switch (pkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._ADP1001_DIGITALINPUT_DSR_100_VINT:
			switch (pkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._ADP1001_DIGITALINPUT_DCD_100_VINT:
			switch (pkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._ADP1001_DIGITALINPUT_RI_100_VINT:
			switch (pkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._ADP1001_DIGITALOUTPUT_DTR_100_VINT:
			switch (pkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

// eslint-disable-next-line require-await
export async function sendADP_RS485_422_VINT(ch: LocalChannel, bp: BridgePacket) {
	throw new Error("Not Implemented");

	// eslint-disable-next-line no-unreachable
	switch (ch.chDef.uid) {
		case DeviceChannelUID._ADP_RS485_422_DATAADAPTER_100_VINT:
			switch (bp.vpkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvADP_RS485_422_VINT(ch: LocalChannel, buf: DataView) {
	throw new Error("Not Implemented");

	// eslint-disable-next-line no-unreachable
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._ADP_RS485_422_DATAADAPTER_100_VINT:
			switch (pkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

// eslint-disable-next-line require-await
export async function sendADP_SERIAL_VINT(ch: LocalChannel, bp: BridgePacket) {
	throw new Error("Not Implemented");

	// eslint-disable-next-line no-unreachable
	switch (ch.chDef.uid) {
		case DeviceChannelUID._ADP_SERIAL_DATAADAPTER_100_VINT:
			switch (bp.vpkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._ADP_SERIAL_DIGITALINPUT_100_VINT:
			switch (bp.vpkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._ADP_SERIAL_DIGITALOUTPUT_100_VINT:
			switch (bp.vpkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvADP_SERIAL_VINT(ch: LocalChannel, buf: DataView) {
	throw new Error("Not Implemented");

	// eslint-disable-next-line no-unreachable
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._ADP_SERIAL_DATAADAPTER_100_VINT:
			switch (pkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._ADP_SERIAL_DIGITALINPUT_100_VINT:
			switch (pkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._ADP_SERIAL_DIGITALOUTPUT_100_VINT:
			switch (pkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendDAQ1000(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._DAQ1000_VOLTAGERATIOINPUT_100:
		case DeviceChannelUID._DAQ1000_VOLTAGERATIOINPUT_110:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.VoltageRatioInputPacket.SETVOLTAGERATIOCHANGETRIGGER, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETSENSORTYPE:
					if (!SEnum.supportedVoltageRatioSensorType(ch, bp.getNumber(0))) {
						throw new PhidgetError(ErrorCode.INVALID_ARGUMENT);
					}
					break;
				case BP.SETSENSORVALUECHANGETRIGGER:
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DAQ1000_VOLTAGEINPUT_100:
		case DeviceChannelUID._DAQ1000_VOLTAGEINPUT_110:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.VoltageInputPacket.SETVOLTAGECHANGETRIGGER, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETSENSORTYPE:
					if (!SEnum.supportedVoltageSensorType(ch, bp.getNumber(0))) {
						throw new PhidgetError(ErrorCode.INVALID_ARGUMENT);
					}
					break;
				case BP.SETSENSORVALUECHANGETRIGGER:
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvDAQ1000(ch: LocalChannel, buf: DataView) {
	let voltage;

	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._DAQ1000_VOLTAGERATIOINPUT_100:
		case DeviceChannelUID._DAQ1000_VOLTAGERATIOINPUT_110:
			switch (pkt) {
				case VintPacketType.VoltageRatioInputPacket.VOLTAGERATIOCHANGE: {
					voltage = buf.getFloat32(1, true);
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: RoundDouble(voltage, 4) });
					bp.sendToChannel(ch, BP.VOLTAGERATIOCHANGE);
					break;
				}
				case VintPacketType.VoltageInputPacket.SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.SATURATION });
					bp.set({ name: '1', type: 's', value: 'Saturation Detected.' });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DAQ1000_VOLTAGEINPUT_100:
		case DeviceChannelUID._DAQ1000_VOLTAGEINPUT_110:
			switch (pkt) {
				case VintPacketType.VoltageInputPacket.VOLTAGECHANGE: {
					voltage = buf.getFloat32(1, true);
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: RoundDouble(voltage, 4) });
					bp.sendToChannel(ch, BP.VOLTAGECHANGE);
					break;
				}
				case VintPacketType.VoltageInputPacket.SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.SATURATION });
					bp.set({ name: '1', type: 's', value: 'Saturation Detected.' });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendDAQ1000_5V25(ch: LocalChannel, bp: BridgePacket) {
	await sendDAQ1000(ch, bp);
}

export function recvDAQ1000_5V25(ch: LocalChannel, buf: DataView) {
	recvDAQ1000(ch, buf);
}

export async function sendOUT1000(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._OUT1000_VOLTAGEOUTPUT_100:
			switch (bp.vpkt) {
				case BP.SETVOLTAGE:
					buf.setInt32(0, bp.getNumber(0) * (1 << 24));
					await ch.sendVINTDataPacket(VintPacketType.VoltageOutputPacket.SETVOLTAGE, new Uint8Array(buf.buffer));
					break;
				case BP.SETENABLED:
					buf.setUint8(0, bp.getNumber(0) ? 0xFF : 0x00);
					await ch.sendVINTDataPacket(VintPacketType.VoltageOutputPacket.SETENABLED, new Uint8Array(buf.buffer, 0, 1));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._OUT1000_VOLTAGEOUTPUT_110:
			switch (bp.vpkt) {
				case BP.SETVOLTAGE:
					buf.setInt32(0, bp.getNumber(0) * (1 << 24));
					await ch.sendVINTDataPacket(VintPacketType.VoltageOutputPacket.SETVOLTAGE, new Uint8Array(buf.buffer));
					break;
				case BP.SETENABLED:
					buf.setUint8(0, bp.getNumber(0) ? 0xFF : 0x00);
					await ch.sendVINTDataPacket(VintPacketType.VoltageOutputPacket.SETENABLED, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETFAILSAFETIME:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_TIME, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.FAILSAFERESET:
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_RESET);
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvOUT1000(ch: LocalChannel, buf: DataView) {
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._OUT1000_VOLTAGEOUTPUT_100:
			switch (pkt) {
				case VintPacketType.VoltageOutputPacket.VOLTAGEERROR: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.VOLTAGE_ERROR });
					bp.set({ name: "1", type: "s", value: "Voltage Error Detected." });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._OUT1000_VOLTAGEOUTPUT_110:
			switch (pkt) {
				case VintPacketType.VoltageOutputPacket.VOLTAGEERROR: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.VOLTAGE_ERROR });
					bp.set({ name: "1", type: "s", value: "Voltage Error Detected." });
					ch.sendErrorEvent(bp);
					break;
				}
				case VintPacketType.VoltageOutputPacket.FAILSAFE: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.FAILSAFE_CONDITION });
					bp.set({ name: "1", type: "s", value: "Failsafe procedure initiated." });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendOUT1000_Failsafe(ch: LocalChannel, bp: BridgePacket) {
	await sendOUT1000(ch, bp);
}

export function recvOUT1000_Failsafe(ch: LocalChannel, buf: DataView) {
	recvOUT1000(ch, buf);
}

export async function sendOUT1001(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._OUT1001_VOLTAGEOUTPUT_100:
			switch (bp.vpkt) {
				case BP.SETVOLTAGE:
					buf.setInt32(0, bp.getNumber(0) * (1 << 24));
					await ch.sendVINTDataPacket(VintPacketType.VoltageOutputPacket.SETVOLTAGE, new Uint8Array(buf.buffer));
					break;
				case BP.SETVOLTAGERANGE:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.VoltageOutputPacket.SETMODE, new Uint8Array(buf.buffer, 0, 1));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._OUT1001_VOLTAGEOUTPUT_110:
			switch (bp.vpkt) {
				case BP.SETVOLTAGE:
					buf.setInt32(0, bp.getNumber(0) * (1 << 24));
					await ch.sendVINTDataPacket(VintPacketType.VoltageOutputPacket.SETVOLTAGE, new Uint8Array(buf.buffer));
					break;
				case BP.SETVOLTAGERANGE:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.VoltageOutputPacket.SETMODE, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETFAILSAFETIME:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_TIME, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.FAILSAFERESET:
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_RESET);
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvOUT1001(ch: LocalChannel, buf: DataView) {
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._OUT1001_VOLTAGEOUTPUT_100:
			switch (pkt) {
				case VintPacketType.VoltageOutputPacket.VOLTAGEERROR: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.VOLTAGE_ERROR });
					bp.set({ name: "1", type: "s", value: "Voltage Error Detected." });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._OUT1001_VOLTAGEOUTPUT_110:
			switch (pkt) {
				case VintPacketType.VoltageOutputPacket.VOLTAGEERROR: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.VOLTAGE_ERROR });
					bp.set({ name: "1", type: "s", value: "Voltage Error Detected." });
					ch.sendErrorEvent(bp);
					break;
				}
				case VintPacketType.VoltageOutputPacket.FAILSAFE: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.FAILSAFE_CONDITION });
					bp.set({ name: "1", type: "s", value: "Failsafe procedure initiated." });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendOUT1001_Failsafe(ch: LocalChannel, bp: BridgePacket) {
	await sendOUT1001(ch, bp);
}

export function recvOUT1001_Failsafe(ch: LocalChannel, buf: DataView) {
	recvOUT1001(ch, buf);
}

export async function sendOUT1002(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._OUT1002_VOLTAGEOUTPUT_100:
			switch (bp.vpkt) {
				case BP.SETVOLTAGE:
					buf.setInt32(0, bp.getNumber(0) * (1 << 24));
					await ch.sendVINTDataPacket(VintPacketType.VoltageOutputPacket.SETVOLTAGE, new Uint8Array(buf.buffer));
					break;
				case BP.SETVOLTAGERANGE:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.VoltageOutputPacket.SETMODE, new Uint8Array(buf.buffer, 0, 1));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._OUT1002_VOLTAGEOUTPUT_110:
			switch (bp.vpkt) {
				case BP.SETVOLTAGE:
					buf.setInt32(0, bp.getNumber(0) * (1 << 24));
					await ch.sendVINTDataPacket(VintPacketType.VoltageOutputPacket.SETVOLTAGE, new Uint8Array(buf.buffer));
					break;
				case BP.SETVOLTAGERANGE:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.VoltageOutputPacket.SETMODE, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETFAILSAFETIME:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_TIME, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.FAILSAFERESET:
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_RESET);
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvOUT1002(ch: LocalChannel, buf: DataView) {
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._OUT1002_VOLTAGEOUTPUT_100:
			switch (pkt) {
				case VintPacketType.VoltageOutputPacket.VOLTAGEERROR: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.VOLTAGE_ERROR });
					bp.set({ name: "1", type: "s", value: "Voltage Error Detected." });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._OUT1002_VOLTAGEOUTPUT_110:
			switch (pkt) {
				case VintPacketType.VoltageOutputPacket.VOLTAGEERROR: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.VOLTAGE_ERROR });
					bp.set({ name: "1", type: "s", value: "Voltage Error Detected." });
					ch.sendErrorEvent(bp);
					break;
				}
				case VintPacketType.VoltageOutputPacket.FAILSAFE: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.FAILSAFE_CONDITION });
					bp.set({ name: "1", type: "s", value: "Failsafe procedure initiated." });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendOUT1002_Failsafe(ch: LocalChannel, bp: BridgePacket) {
	await sendOUT1002(ch, bp);
}

export function recvOUT1002_Failsafe(ch: LocalChannel, buf: DataView) {
	recvOUT1002(ch, buf);
}

// eslint-disable-next-line require-await
export async function sendCURLOOP(ch: LocalChannel, bp: BridgePacket) {
	throw new Error("Not Implemented");

	// eslint-disable-next-line no-unreachable
	switch (ch.chDef.uid) {
		case DeviceChannelUID._CURLOOP_CURRENTOUTPUT_100:
			switch (bp.vpkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvCURLOOP(ch: LocalChannel, buf: DataView) {
	throw new Error("Not Implemented");

	// eslint-disable-next-line no-unreachable
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._CURLOOP_CURRENTOUTPUT_100:
			switch (pkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

// eslint-disable-next-line require-await
export async function sendDAQ1200() {
	throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected command received");
}

export function recvDAQ1200(ch: LocalChannel, buf: DataView) {
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._DAQ1200_DIGITALINPUT_100:
			switch (pkt) {
				case VintPacketType.DigitalInputPacket.STATECHANGE: {
					const state = buf.getUint8(1) & 0x1;
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: state });
					bp.sendToChannel(ch, BP.STATECHANGE);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendOUT1100(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._OUT1100_DIGITALOUTPUT_100:
			switch (bp.vpkt) {
				case BP.SETSTATE:
					buf.setUint16(0, bp.getNumber(0) ? (1 << 15) : 0);
					await ch.sendVINTDataPacket(VintPacketType.DigitalOutputPacket.SETDUTYCYCLE, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETDUTYCYCLE:
					buf.setUint16(0, bp.getNumber(0) * (1 << 15));
					await ch.sendVINTDataPacket(VintPacketType.DigitalOutputPacket.SETDUTYCYCLE, new Uint8Array(buf.buffer, 0, 2));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._OUT1100_DIGITALOUTPUT_110:
			switch (bp.vpkt) {
				case BP.SETSTATE:
					buf.setUint16(0, bp.getNumber(0) ? (1 << 15) : 0);
					await ch.sendVINTDataPacket(VintPacketType.DigitalOutputPacket.SETDUTYCYCLE, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETDUTYCYCLE:
					buf.setUint16(0, bp.getNumber(0) * (1 << 15));
					await ch.sendVINTDataPacket(VintPacketType.DigitalOutputPacket.SETDUTYCYCLE, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETFAILSAFETIME:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_TIME, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.FAILSAFERESET:
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_RESET);
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._OUT1100_DIGITALOUTPUT_120:
			switch (bp.vpkt) {
				case BP.SETSTATE:
					buf.setUint16(0, bp.getNumber(0) ? (1 << 15) : 0);
					await ch.sendVINTDataPacket(VintPacketType.DigitalOutputPacket.SETDUTYCYCLE, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETDUTYCYCLE:
					buf.setUint16(0, bp.getNumber(0) * (1 << 15));
					await ch.sendVINTDataPacket(VintPacketType.DigitalOutputPacket.SETDUTYCYCLE, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETFAILSAFETIME:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_TIME, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.FAILSAFERESET:
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_RESET);
					break;
				case BP.SETFREQUENCY:
					buf.setUint32(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.DigitalOutputPacket.SETFREQUENCY, new Uint8Array(buf.buffer));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvOUT1100(ch: LocalChannel, buf: DataView) {
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._OUT1100_DIGITALOUTPUT_100:
			throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet received.");
		case DeviceChannelUID._OUT1100_DIGITALOUTPUT_110:
		case DeviceChannelUID._OUT1100_DIGITALOUTPUT_120:
			switch (pkt) {
				case VintPacketType.DigitalOutputPacket.FAILSAFE: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.FAILSAFE_CONDITION });
					bp.set({ name: "1", type: "s", value: "Failsafe procedure initiated." });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendOUT1100_Failsafe(ch: LocalChannel, bp: BridgePacket) {
	await sendOUT1100(ch, bp);
}

export function recvOUT1100_Failsafe(ch: LocalChannel, buf: DataView) {
	recvOUT1100(ch, buf);
}

export async function sendOUT1100_Failsafe_Frequency(ch: LocalChannel, bp: BridgePacket) {
	await sendOUT1100(ch, bp);
}

export function recvOUT1100_Failsafe_Frequency(ch: LocalChannel, buf: DataView) {
	recvOUT1100(ch, buf);
}

// eslint-disable-next-line require-await
export async function sendDAQ1300() {
	throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected command received.");
}

export function recvDAQ1300(ch: LocalChannel, buf: DataView) {
	let state;
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._DAQ1300_DIGITALINPUT_100:
			switch (pkt) {
				case VintPacketType.DigitalInputPacket.STATECHANGE: {
					state = buf.getUint8(1) & 0x1;
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: state });
					bp.sendToChannel(ch, BP.STATECHANGE);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

// eslint-disable-next-line require-await
export async function sendDAQ1301() {
	throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected command received.");
}

export function recvDAQ1301(ch: LocalChannel, buf: DataView) {
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._DAQ1301_DIGITALINPUT_100:
			switch (pkt) {
				case VintPacketType.DigitalInputPacket.STATECHANGE: {
					const state = buf.getUint8(1) & 0x1;
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: state });
					bp.sendToChannel(ch, BP.STATECHANGE);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendDAQ1400(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._DAQ1400_FREQUENCYCOUNTER_100:
		case DeviceChannelUID._DAQ1400_FREQUENCYCOUNTER_120:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETPOWERSUPPLY:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.ISA_SETPOWERSUPPLY, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETINPUTMODE:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.ISA_SETINPUTMODE, new Uint8Array(buf.buffer, 0, 1));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DAQ1400_DIGITALINPUT_100:
		case DeviceChannelUID._DAQ1400_DIGITALINPUT_120:
			switch (bp.vpkt) {
				case BP.SETPOWERSUPPLY:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.ISA_SETPOWERSUPPLY, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETINPUTMODE:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.ISA_SETINPUTMODE, new Uint8Array(buf.buffer, 0, 1));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DAQ1400_CURRENTINPUT_100:
		case DeviceChannelUID._DAQ1400_CURRENTINPUT_120:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setUint32(0, bp.getNumber(0) * (1 << 24));
					await ch.sendVINTDataPacket(VintPacketType.CurrentInputPacket.SETCURRENTCHANGETRIGGER, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETPOWERSUPPLY:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.ISA_SETPOWERSUPPLY, new Uint8Array(buf.buffer, 0, 1));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DAQ1400_VOLTAGEINPUT_100:
		case DeviceChannelUID._DAQ1400_VOLTAGEINPUT_120:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setUint32(0, bp.getNumber(0) * (1 << 24));
					await ch.sendVINTDataPacket(VintPacketType.VoltageInputPacket.SETVOLTAGECHANGETRIGGER, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETPOWERSUPPLY:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.ISA_SETPOWERSUPPLY, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETSENSORTYPE:
					if (!SEnum.supportedVoltageSensorType(ch, bp.getNumber(0))) {
						throw new PhidgetError(ErrorCode.INVALID_ARGUMENT);
					}
					break;
				case BP.SETSENSORVALUECHANGETRIGGER:
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvDAQ1400(ch: LocalChannel, buf: DataView) {
	let ticksAtLastCount;
	let counts;
	let ticks;
	let current;
	let voltage;
	let state;

	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._DAQ1400_FREQUENCYCOUNTER_100:
		case DeviceChannelUID._DAQ1400_FREQUENCYCOUNTER_120:
			switch (pkt) {
				case VintPacketType.FrequencyCounterPacket.COUNTCHANGE: {
					ticks = buf.getUint32(1) / 1000;
					counts = buf.getUint32(5);
					ticksAtLastCount = buf.getUint32(9) / 1000;
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: ticks });
					bp.set({ name: '1', type: 'u', value: counts });
					bp.set({ name: '2', type: 'g', value: ticksAtLastCount });
					bp.sendToChannel(ch, BP.FREQUENCYDATA);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DAQ1400_DIGITALINPUT_100:
		case DeviceChannelUID._DAQ1400_DIGITALINPUT_120:
			switch (pkt) {
				case VintPacketType.DigitalInputPacket.STATECHANGE: {
					state = buf.getUint8(1) & 0x01;
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: state });
					bp.sendToChannel(ch, BP.STATECHANGE);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DAQ1400_CURRENTINPUT_100:
		case DeviceChannelUID._DAQ1400_CURRENTINPUT_120:
			switch (pkt) {
				case VintPacketType.CurrentInputPacket.CURRENTCHANGE: {
					current = buf.getInt32(1) / (1 << 24);
					//Don't return anything below 500uA (ch.data.minCurent) - we can't measure down to 0
					if (current < 0.0005) {
						const bp = new BridgePacket();
						bp.set({ name: '0', type: 'd', value: ErrorEventCode.OUT_OF_RANGE });
						bp.set({ name: '1', type: 's', value: 'Sensor value is ouside the valid range for this sensor.' });
						ch.sendErrorEvent(bp);
						break;
					}
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: RoundDouble(current, 5) });
					bp.sendToChannel(ch, BP.CURRENTCHANGE);
					break;
				}
				case VintPacketType.CurrentInputPacket.SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.SATURATION });
					bp.set({ name: '1', type: 's', value: 'Saturation Detected.' });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DAQ1400_VOLTAGEINPUT_100:
		case DeviceChannelUID._DAQ1400_VOLTAGEINPUT_120:
			switch (pkt) {
				case VintPacketType.VoltageInputPacket.VOLTAGECHANGE: {
					voltage = buf.getInt32(1) / (1 << 24);
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: RoundDouble(voltage, 8) });
					bp.sendToChannel(ch, BP.VOLTAGECHANGE);
					break;
				}
				case VintPacketType.VoltageInputPacket.SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.SATURATION });
					bp.set({ name: '1', type: 's', value: 'Saturation Detected.' });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendDAQ1400_120(ch: LocalChannel, bp: BridgePacket) {
	await sendDAQ1400(ch, bp);
}

export function recvDAQ1400_120(ch: LocalChannel, buf: DataView) {
	recvDAQ1400(ch, buf);
}

export async function sendDAQ1500(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._DAQ1500_VOLTAGERATIOINPUT_100:
			switch (bp.vpkt) {
				case BP.SETENABLED:
					buf.setUint8(0, bp.getNumber(0) ? 0xFF : 0x00);
					await ch.sendVINTDataPacket(VintPacketType.VoltageRatioInputPacket.BRIDGEENABLED, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setUint32(0, bp.getNumber(0) * (0x80000000));
					await ch.sendVINTDataPacket(VintPacketType.VoltageRatioInputPacket.SETVOLTAGERATIOCHANGETRIGGER, new Uint8Array(buf.buffer));
					break;
				case BP.SETBRIDGEGAIN:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.VoltageRatioInputPacket.BRIDGEGAIN, new Uint8Array(buf.buffer, 0, 1));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvDAQ1500(ch: LocalChannel, buf: DataView) {
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._DAQ1500_VOLTAGERATIOINPUT_100:
			switch (pkt) {
				case VintPacketType.VoltageRatioInputPacket.VOLTAGERATIOCHANGE: {
					const voltageratio = buf.getInt32(1) / (0x80000000 - 1.0);
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "g", value: voltageratio });
					bp.sendToChannel(ch, BP.VOLTAGERATIOCHANGE);
					break;
				}
				case VintPacketType.DistanceSensorPacket.SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.SATURATION });
					bp.set({ name: "1", type: "s", value: "Saturation Detected." });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

// eslint-disable-next-line require-await
export async function sendDAQ1501(ch: LocalChannel, bp: BridgePacket) {
	throw new Error("Not Implemented");

	// eslint-disable-next-line no-unreachable
	switch (ch.chDef.uid) {
		case DeviceChannelUID._DAQ1501_VOLTAGERATIOINPUT_100:
			switch (bp.vpkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvDAQ1501(ch: LocalChannel, buf: DataView) {
	throw new Error("Not Implemented");

	// eslint-disable-next-line no-unreachable
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._DAQ1501_VOLTAGERATIOINPUT_100:
			switch (pkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendVCP1100(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._VCP1100_CURRENTINPUT_100:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setUint32(0, bp.getNumber(0) * 0x00010000);
					await ch.sendVINTDataPacket(VintPacketType.CurrentInputPacket.SETCURRENTCHANGETRIGGER, new Uint8Array(buf.buffer));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvVCP1100(ch: LocalChannel, buf: DataView) {
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._VCP1100_CURRENTINPUT_100:
			switch (pkt) {
				case VintPacketType.CurrentInputPacket.CURRENTCHANGE: {
					const current = buf.getInt32(1) / 65536;
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: current });
					bp.sendToChannel(ch, BP.CURRENTCHANGE);
					break;
				}
				case VintPacketType.CurrentInputPacket.SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.SATURATION });
					bp.set({ name: '1', type: 's', value: 'Saturation Detected.' });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

// eslint-disable-next-line require-await, @typescript-eslint/no-unused-vars
export async function sendVCP1100_1_OLDPROTO(_ch: LocalChannel, _bp: BridgePacket) {
	throw new Error("Obsolete Device");
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function recvVCP1100_1_OLDPROTO(_ch: LocalChannel, _buf: DataView) {
	throw new Error("Obsolete Device");
}

export async function sendDCC1000(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(8));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._DCC1000_CURRENTINPUT_100:
		case DeviceChannelUID._DCC1000_CURRENTINPUT_200:
		case DeviceChannelUID._DCC1000_CURRENTINPUT_210:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setUint16(0, bp.getNumber(0) * (4095 / 3.3) * 0.045);
					await ch.sendVINTDataPacket(VintPacketType.CurrentInputPacket.SETCURRENTCHANGETRIGGER, new Uint8Array(buf.buffer, 0, 2));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1000_TEMPERATURESENSOR_IC_100:
		case DeviceChannelUID._DCC1000_TEMPERATURESENSOR_IC_200:
		case DeviceChannelUID._DCC1000_TEMPERATURESENSOR_IC_210:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					// TemperatureChangeTigger in Q8.8 format
					buf.setUint16(0, bp.getNumber(0) * 256);
					await ch.sendVINTDataPacket(VintPacketType.TemperatureSensorPacket.SETTEMPERATURECHANGETRIGGER, new Uint8Array(buf.buffer, 0, 2));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1000_VOLTAGERATIOINPUT_100:
		case DeviceChannelUID._DCC1000_VOLTAGERATIOINPUT_200:
		case DeviceChannelUID._DCC1000_VOLTAGERATIOINPUT_210:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setUint32(0, bp.getNumber(0) * (1 << 16));
					await ch.sendVINTDataPacket(VintPacketType.VoltageRatioInputPacket.SETVOLTAGERATIOCHANGETRIGGER, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETSENSORTYPE:
					if (!SEnum.supportedVoltageRatioSensorType(ch, bp.getNumber(0))) {
						throw new PhidgetError(ErrorCode.INVALID_ARGUMENT);
					}
					break;
				case BP.SETSENSORVALUECHANGETRIGGER:
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1000_ENCODER_100:
		case DeviceChannelUID._DCC1000_ENCODER_200:
		case DeviceChannelUID._DCC1000_ENCODER_210:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setUint32(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.EncoderPacket.SETENCODERCHANGETRIGGER, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETIOMODE:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.EncoderPacket.IOMODE, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETENABLED:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.EncoderPacket.ENABLE, new Uint8Array(buf.buffer, 0, 1));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1000_DCMOTOR_100:
		case DeviceChannelUID._DCC1000_DCMOTOR_200:
			switch (bp.vpkt) {
				case BP.SETACCELERATION:
					buf.setUint32(0, bp.getNumber(0) * 8192);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETACCELERATION, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETDUTYCYCLE:
					buf.setInt32(0, bp.getNumber(0) * 2097151);    //have to multiply by 959 so save 10 bits for multiplication in firware
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETVELOCITY, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETBRAKINGDUTYCYCLE:
					buf.setUint32(0, bp.getNumber(0) * 2097151);    //have to multiply by 959 so save 10 bits for multiplication in firware
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETBRAKINGSTRENGTH, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETCURRENTLIMIT:
					buf.setInt32(0, (bp.getNumber(0) * 0.045 * 4095 / 3.3) + 2048);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETCURRENTLIMIT, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETFANMODE:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.FAN, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETCURRENTREGULATORGAIN:
					buf.setUint32(0, (bp.getNumber(0) - 1) * 1804.070707 + 897);    //map 1-100 to 897-179500 (0.1%/A to 20%/A)
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETCURRENTREGULATORGAIN, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1000_DCMOTOR_210:
			switch (bp.vpkt) {
				case BP.SETACCELERATION:
					buf.setUint32(0, bp.getNumber(0) * 8192);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETACCELERATION, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETDUTYCYCLE:
					buf.setInt32(0, bp.getNumber(0) * 2097151);    //have to multiply by 959 so save 10 bits for multiplication in firware
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETVELOCITY, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETBRAKINGDUTYCYCLE:
					buf.setUint32(0, bp.getNumber(0) * 2097151);    //have to multiply by 959 so save 10 bits for multiplication in firware
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETBRAKINGSTRENGTH, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETCURRENTLIMIT:
					buf.setInt32(0, (bp.getNumber(0) * 0.045 * 4095 / 3.3) + 2048);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETCURRENTLIMIT, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETFANMODE:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.FAN, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETCURRENTREGULATORGAIN:
					buf.setUint32(0, (bp.getNumber(0) - 1) * 1804.070707 + 897);    //map 1-100 to 897-179500 (0.1%/A to 20%/A)
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETCURRENTREGULATORGAIN, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETFAILSAFETIME:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_TIME, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.FAILSAFERESET:
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_RESET);
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1000_MOTORPOSITIONCONTROLLER_200:
			switch (bp.vpkt) {
				case BP.SETDEADBAND:
					buf.setUint32(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETDEADBAND, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETKP:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETKP, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETKD:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETKD, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETKI:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETKI, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETTARGETPOSITION: {
					const position = bp.getNumber(0);
					SetBigInt64(buf, 0, position);
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETTARGETPOSITION, new Uint8Array(buf.buffer));
					break;
				}
				case BP.SETACCELERATION:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETACCELERATION, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETDUTYCYCLE:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETVELOCITY, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETBRAKINGDUTYCYCLE:
					buf.setUint32(0, bp.getNumber(0) * 2097151); //have to multiply by 959 so save 10 bits for multiplication in firmware
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETBRAKINGSTRENGTH, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETCURRENTLIMIT:
					buf.setInt32(0, (bp.getNumber(0) * 0.045 * 4095 / 3.3) + 2048);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETCURRENTLIMIT, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETCURRENTREGULATORGAIN:
					buf.setUint32(0, (bp.getNumber(0) - 1) * 1804.070707 + 897);    //map 1-100 to 897-179500 (0.1%/A to 20%/A)
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETCURRENTREGULATORGAIN, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETFANMODE:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.FAN, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETIOMODE:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETENCODERIOMODE, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETENGAGED:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETENGAGED, new Uint8Array(buf.buffer, 0, 1));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1000_MOTORPOSITIONCONTROLLER_210:
			switch (bp.vpkt) {
				case BP.SETDEADBAND:
					buf.setUint32(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETDEADBAND, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETKP:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETKP, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETKD:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETKD, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETKI:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETKI, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETTARGETPOSITION: {
					const position = bp.getNumber(0);
					SetBigInt64(buf, 0, position);
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETTARGETPOSITION, new Uint8Array(buf.buffer));
					break;
				}
				case BP.SETACCELERATION:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETACCELERATION, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETDUTYCYCLE:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETVELOCITY, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETBRAKINGDUTYCYCLE:
					buf.setUint32(0, bp.getNumber(0) * 2097151); //have to multiply by 959 so save 10 bits for multiplication in firmware
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETBRAKINGSTRENGTH, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETCURRENTLIMIT:
					buf.setInt32(0, (bp.getNumber(0) * 0.045 * 4095 / 3.3) + 2048);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETCURRENTLIMIT, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETCURRENTREGULATORGAIN:
					buf.setUint32(0, (bp.getNumber(0) - 1) * 1804.070707 + 897);    //map 1-100 to 897-179500 (0.1%/A to 20%/A)
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETCURRENTREGULATORGAIN, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETFANMODE:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.FAN, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETIOMODE:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETENCODERIOMODE, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETENGAGED:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETENGAGED, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETFAILSAFETIME:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_TIME, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.FAILSAFERESET:
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_RESET);
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvDCC1000(ch: LocalChannel, buf: DataView) {
	let dutyCycle, brakingDutyCycle, sign;
	let temperature, current, voltageRatio;
	let position;
	let indexPosition;
	let timeChange;
	let indexTriggered;
	let positionChange;
	let userphid;

	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._DCC1000_CURRENTINPUT_100:
		case DeviceChannelUID._DCC1000_CURRENTINPUT_200:
		case DeviceChannelUID._DCC1000_CURRENTINPUT_210:
			switch (pkt) {
				case VintPacketType.CurrentInputPacket.CURRENTCHANGE: {
					current = (buf.getUint16(1) - 2048) * 0.017908017;
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: RoundDouble(current, 4) });
					bp.sendToChannel(ch, BP.CURRENTCHANGE);
					break;
				}
				case VintPacketType.CurrentInputPacket.SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.SATURATION });
					bp.set({ name: '1', type: 's', value: 'Saturation Detected.' });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1000_TEMPERATURESENSOR_IC_100:
		case DeviceChannelUID._DCC1000_TEMPERATURESENSOR_IC_200:
		case DeviceChannelUID._DCC1000_TEMPERATURESENSOR_IC_210:
			switch (pkt) {
				case VintPacketType.TemperatureSensorPacket.TEMPERATURECHANGE: {
					temperature = buf.getInt32(1) / (1 << 16);
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: RoundDouble(temperature, 2) });
					bp.sendToChannel(ch, BP.TEMPERATURECHANGE);
					break;
				}
				case VintPacketType.TemperatureSensorPacket.SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.SATURATION });
					bp.set({ name: '1', type: 's', value: 'Saturation Detected.' });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1000_VOLTAGERATIOINPUT_100:
		case DeviceChannelUID._DCC1000_VOLTAGERATIOINPUT_200:
		case DeviceChannelUID._DCC1000_VOLTAGERATIOINPUT_210:
			switch (pkt) {
				case VintPacketType.VoltageRatioInputPacket.VOLTAGERATIOCHANGE: {
					voltageRatio = buf.getInt32(1) / (1 << 16);
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: RoundDouble(voltageRatio, 5) });
					bp.sendToChannel(ch, BP.VOLTAGERATIOCHANGE);
					break;
				}
				case VintPacketType.VoltageRatioInputPacket.SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.SATURATION });
					bp.set({ name: '1', type: 's', value: 'Saturation Detected.' });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1000_ENCODER_100:
		case DeviceChannelUID._DCC1000_ENCODER_200:
		case DeviceChannelUID._DCC1000_ENCODER_210:
			switch (pkt) {
				case VintPacketType.EncoderPacket.ENCODERCHANGE: {
					positionChange = buf.getInt32(1);
					timeChange = buf.getUint32(5) * 1000 / 48;
					indexTriggered = buf.getUint8(9);
					if (indexTriggered) {
						indexPosition = buf.getUint32(10);
					} else {
						indexPosition = 0;
					}
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: positionChange });
					bp.set({ name: '1', type: 'g', value: timeChange / 1000000.0 });
					bp.set({ name: '2', type: 'c', value: indexTriggered });
					bp.set({ name: '3', type: 'd', value: indexPosition });
					bp.sendToChannel(ch, BP.POSITIONCHANGE);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1000_DCMOTOR_100:
			switch (pkt) {
				case VintPacketType.DCMotorPacket.MOTORSTATUSUPDATE: {
					brakingDutyCycle = buf.getUint16(3) / 65535;
					sign = buf.getUint8(6) ? -1 : 1;
					dutyCycle = buf.getUint16(1) / 65535 * sign;
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					userphid = (ch.userphid! as UserPhidgetClass.DCMotor);
					if (userphid.data.brakingStrength !== brakingDutyCycle) {
						const bp = new BridgePacket();
						bp.set({ name: '0', type: 'g', value: RoundDouble(brakingDutyCycle, 3) });
						bp.sendToChannel(ch, BP.BRAKINGSTRENGTHCHANGE);
					}
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: RoundDouble(dutyCycle, 3) });
					bp.sendToChannel(ch, BP.DUTYCYCLECHANGE);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1000_DCMOTOR_200:
			switch (pkt) {
				case VintPacketType.DCMotorPacket.MOTORSTATUSUPDATE: {
					brakingDutyCycle = buf.getInt32(5) / 940;
					dutyCycle = buf.getInt32(1) / 940;
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					userphid = (ch.userphid! as UserPhidgetClass.DCMotor);
					if (userphid.data.brakingStrength !== brakingDutyCycle) {
						const bp = new BridgePacket();
						bp.set({ name: '0', type: 'g', value: RoundDouble(brakingDutyCycle, 3) });
						bp.sendToChannel(ch, BP.BRAKINGSTRENGTHCHANGE);
					}
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: RoundDouble(dutyCycle, 3) });
					bp.sendToChannel(ch, BP.DUTYCYCLECHANGE);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1000_DCMOTOR_210:
			switch (pkt) {
				case VintPacketType.DCMotorPacket.MOTORSTATUSUPDATE: {
					brakingDutyCycle = buf.getInt32(5) / 940;
					dutyCycle = buf.getInt32(1) / 940;
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					userphid = (ch.userphid! as UserPhidgetClass.DCMotor);
					if (userphid.data.brakingStrength !== brakingDutyCycle) {
						const bp = new BridgePacket();
						bp.set({ name: '0', type: 'g', value: RoundDouble(brakingDutyCycle, 3) });
						bp.sendToChannel(ch, BP.BRAKINGSTRENGTHCHANGE);
					}
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: RoundDouble(dutyCycle, 3) });
					bp.sendToChannel(ch, BP.DUTYCYCLECHANGE);
					break;
				}
				case VintPacketType.DCMotorPacket.FAILSAFE: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.FAILSAFE_CONDITION });
					bp.set({ name: '1', type: 's', value: 'Failsafe procedure initiated.' });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1000_MOTORPOSITIONCONTROLLER_200:
			switch (pkt) {
				case VintPacketType.MotorPositionControllerPacket.MOTORSTATUSUPDATE: {
					dutyCycle = buf.getInt32(1) / 940;
					position = GetBigInt64(buf, 5);
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					userphid = (ch.userphid! as unknown as UserPhidgetClass.MotorPositionController);
					if (userphid.data.position !== position) {
						const bp = new BridgePacket();
						bp.set({ name: '0', type: 'l', value: position });
						bp.sendToChannel(ch, BP.POSITIONCHANGE);
					}
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: RoundDouble(dutyCycle, 3) });
					bp.sendToChannel(ch, BP.DUTYCYCLECHANGE);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1000_MOTORPOSITIONCONTROLLER_210:
			switch (pkt) {
				case VintPacketType.MotorPositionControllerPacket.MOTORSTATUSUPDATE: {
					dutyCycle = buf.getInt32(1) / 940;
					position = GetBigInt64(buf, 5);
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					userphid = (ch.userphid! as unknown as UserPhidgetClass.MotorPositionController);
					if (userphid.data.position !== position) {
						const bp = new BridgePacket();
						bp.set({ name: '0', type: 'l', value: position });
						bp.sendToChannel(ch, BP.POSITIONCHANGE);
					}
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: RoundDouble(dutyCycle, 3) });
					bp.sendToChannel(ch, BP.DUTYCYCLECHANGE);
					break;
				}
				case VintPacketType.DCMotorPacket.FAILSAFE: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.FAILSAFE_CONDITION });
					bp.set({ name: '1', type: 's', value: 'Failsafe procedure initiated.' });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendDCC1000_POSITIONCONTROL(ch: LocalChannel, bp: BridgePacket) {
	await sendDCC1000(ch, bp);
}

export function recvDCC1000_POSITIONCONTROL(ch: LocalChannel, buf: DataView) {
	recvDCC1000(ch, buf);
}

export async function sendDCC1000_POSITIONCONTROL_FAILSAFE(ch: LocalChannel, bp: BridgePacket) {
	await sendDCC1000(ch, bp);
}

export function recvDCC1000_POSITIONCONTROL_FAILSAFE(ch: LocalChannel, buf: DataView) {
	recvDCC1000(ch, buf);
}

// eslint-disable-next-line require-await
export async function sendDCC1020(ch: LocalChannel, bp: BridgePacket) {
	throw new Error("Not Implemented");

	// eslint-disable-next-line no-unreachable
	switch (ch.chDef.uid) {
		case DeviceChannelUID._DCC1010_DCMOTOR_100:
		case DeviceChannelUID._DCC1020_DCMOTOR_100:
		case DeviceChannelUID._DCC1030_DCMOTOR_100:
			switch (bp.vpkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1010_ENCODER_100:
		case DeviceChannelUID._DCC1020_ENCODER_100:
		case DeviceChannelUID._DCC1030_ENCODER_100:
			switch (bp.vpkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1010_TEMPERATURESENSOR_IC_100:
		case DeviceChannelUID._DCC1020_TEMPERATURESENSOR_IC_100:
		case DeviceChannelUID._DCC1030_TEMPERATURESENSOR_IC_100:
			switch (bp.vpkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1010_CURRENTINPUT_100:
		case DeviceChannelUID._DCC1020_CURRENTINPUT_100:
		case DeviceChannelUID._DCC1030_CURRENTINPUT_100:
			switch (bp.vpkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1010_MOTORPOSITIONCONTROLLER_100:
		case DeviceChannelUID._DCC1020_MOTORPOSITIONCONTROLLER_100:
		case DeviceChannelUID._DCC1030_MOTORPOSITIONCONTROLLER_100:
			switch (bp.vpkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvDCC1020(ch: LocalChannel, buf: DataView) {
	throw new Error("Not Implemented");

	// eslint-disable-next-line no-unreachable
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._DCC1010_DCMOTOR_100:
		case DeviceChannelUID._DCC1020_DCMOTOR_100:
		case DeviceChannelUID._DCC1030_DCMOTOR_100:
			switch (pkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1010_ENCODER_100:
		case DeviceChannelUID._DCC1020_ENCODER_100:
		case DeviceChannelUID._DCC1030_ENCODER_100:
			switch (pkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1010_TEMPERATURESENSOR_IC_100:
		case DeviceChannelUID._DCC1020_TEMPERATURESENSOR_IC_100:
		case DeviceChannelUID._DCC1030_TEMPERATURESENSOR_IC_100:
			switch (pkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1010_CURRENTINPUT_100:
		case DeviceChannelUID._DCC1020_CURRENTINPUT_100:
		case DeviceChannelUID._DCC1030_CURRENTINPUT_100:
			switch (pkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1010_MOTORPOSITIONCONTROLLER_100:
		case DeviceChannelUID._DCC1020_MOTORPOSITIONCONTROLLER_100:
		case DeviceChannelUID._DCC1030_MOTORPOSITIONCONTROLLER_100:
			switch (pkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendDCC1010(ch: LocalChannel, bp: BridgePacket) {
	await sendDCC1020(ch, bp);
}
export function recvDCC1010(ch: LocalChannel, buf: DataView) {
	recvDCC1020(ch, buf);
}

export async function sendDCC1030(ch: LocalChannel, bp: BridgePacket) {
	await sendDCC1020(ch, bp);
}
export function recvDCC1030(ch: LocalChannel, buf: DataView) {
	recvDCC1020(ch, buf);
}

export async function sendDCC1001(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(8));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._DCC1001_ENCODER_100:
		case DeviceChannelUID._DCC1001_ENCODER_120:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setUint32(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.EncoderPacket.SETENCODERCHANGETRIGGER, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETIOMODE:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.EncoderPacket.IOMODE, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETENABLED:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.EncoderPacket.ENABLE, new Uint8Array(buf.buffer, 0, 1));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1001_DCMOTOR_100:
			switch (bp.vpkt) {
				case BP.SETACCELERATION:
					buf.setUint32(0, bp.getNumber(0) * 8192);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETACCELERATION, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETDUTYCYCLE:
					buf.setInt32(0, bp.getNumber(0) * 2097151);    //have to multiply by 959 so save 10 bits for multiplication in firware
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETVELOCITY, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETBRAKINGDUTYCYCLE:
					buf.setUint32(0, bp.getNumber(0) * 2097151);    //have to multiply by 959 so save 10 bits for multiplication in firware
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETBRAKINGSTRENGTH, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETCURRENTLIMIT:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETCURRENTLIMIT, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1001_DCMOTOR_120:
			switch (bp.vpkt) {
				case BP.SETACCELERATION:
					buf.setUint32(0, bp.getNumber(0) * 8192);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETACCELERATION, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETDUTYCYCLE:
					buf.setInt32(0, bp.getNumber(0) * 2097151);    //have to multiply by 959 so save 10 bits for multiplication in firware
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETVELOCITY, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETBRAKINGDUTYCYCLE:
					buf.setUint32(0, bp.getNumber(0) * 2097151);    //have to multiply by 959 so save 10 bits for multiplication in firware
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETBRAKINGSTRENGTH, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETCURRENTLIMIT:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETCURRENTLIMIT, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETFAILSAFETIME:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_TIME, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.FAILSAFERESET:
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_RESET);
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1001_MOTORPOSITIONCONTROLLER_100:
			switch (bp.vpkt) {
				case BP.SETDEADBAND:
					buf.setUint32(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETDEADBAND, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETKP:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETKP, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETKD:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETKD, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETKI:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETKI, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETTARGETPOSITION: {
					const position = bp.getNumber(0);
					SetBigInt64(buf, 0, position);
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETTARGETPOSITION, new Uint8Array(buf.buffer));
					break;
				}
				case BP.SETACCELERATION:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETACCELERATION, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETDUTYCYCLE:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETVELOCITY, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETBRAKINGDUTYCYCLE:
					buf.setUint32(0, bp.getNumber(0) * 2097151); //have to multiply by 959 so save 10 bits for multiplication in firmware
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETBRAKINGSTRENGTH, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETCURRENTLIMIT:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETCURRENTLIMIT, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETENGAGED:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETENGAGED, new Uint8Array(buf.buffer, 0, 1));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1001_MOTORPOSITIONCONTROLLER_120:
			switch (bp.vpkt) {
				case BP.SETDEADBAND:
					buf.setUint32(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETDEADBAND, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETKP:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETKP, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETKD:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETKD, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETKI:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETKI, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETTARGETPOSITION: {
					const position = bp.getNumber(0);
					SetBigInt64(buf, 0, position);
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETTARGETPOSITION, new Uint8Array(buf.buffer));
					break;
				}
				case BP.SETACCELERATION:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETACCELERATION, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETDUTYCYCLE:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETVELOCITY, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETBRAKINGDUTYCYCLE:
					buf.setUint32(0, bp.getNumber(0) * 2097151); //have to multiply by 959 so save 10 bits for multiplication in firmware
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETBRAKINGSTRENGTH, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETCURRENTLIMIT:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETCURRENTLIMIT, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETENGAGED:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETENGAGED, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETFAILSAFETIME:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_TIME, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.FAILSAFERESET:
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_RESET);
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvDCC1001(ch: LocalChannel, buf: DataView) {
	let dutyCycle, brakingDutyCycle;
	let indexPosition;
	let timeChange;
	let position;
	let indexTriggered;
	let positionChange;
	let userphid;

	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._DCC1001_ENCODER_100:
		case DeviceChannelUID._DCC1001_ENCODER_120:
			switch (pkt) {
				case VintPacketType.EncoderPacket.ENCODERCHANGE: {
					positionChange = buf.getInt32(1);
					timeChange = buf.getUint32(5) * 1000 / 48;
					indexTriggered = buf.getUint8(9);
					if (indexTriggered) {
						indexPosition = buf.getUint32(10);
					} else {
						indexPosition = 0;
					}
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: positionChange });
					bp.set({ name: '1', type: 'g', value: timeChange / 1000000.0 });
					bp.set({ name: '2', type: 'c', value: indexTriggered });
					bp.set({ name: '3', type: 'd', value: indexPosition });
					bp.sendToChannel(ch, BP.POSITIONCHANGE);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1001_DCMOTOR_100:
			switch (pkt) {
				case VintPacketType.DCMotorPacket.MOTORSTATUSUPDATE: {
					brakingDutyCycle = buf.getInt32(5) / 940;
					dutyCycle = buf.getInt32(1) / 940;
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					userphid = (ch.userphid! as UserPhidgetClass.DCMotor);
					if (userphid.data.brakingStrength !== brakingDutyCycle) {
						const bp = new BridgePacket();
						bp.set({ name: '0', type: 'g', value: RoundDouble(brakingDutyCycle, 3) });
						bp.sendToChannel(ch, BP.BRAKINGSTRENGTHCHANGE);
					}
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: RoundDouble(dutyCycle, 3) });
					bp.sendToChannel(ch, BP.DUTYCYCLECHANGE);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1001_DCMOTOR_120:
			switch (pkt) {
				case VintPacketType.DCMotorPacket.MOTORSTATUSUPDATE: {
					brakingDutyCycle = buf.getInt32(5) / 940;
					dutyCycle = buf.getInt32(1) / 940;
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					userphid = (ch.userphid! as UserPhidgetClass.DCMotor);
					if (userphid.data.brakingStrength !== brakingDutyCycle) {
						const bp = new BridgePacket();
						bp.set({ name: '0', type: 'g', value: RoundDouble(brakingDutyCycle, 3) });
						bp.sendToChannel(ch, BP.BRAKINGSTRENGTHCHANGE);
					}
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: RoundDouble(dutyCycle, 3) });
					bp.sendToChannel(ch, BP.DUTYCYCLECHANGE);
					break;
				}
				case VintPacketType.DCMotorPacket.FAILSAFE: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.FAILSAFE_CONDITION });
					bp.set({ name: '1', type: 's', value: 'Failsafe procedure initiated.' });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1001_MOTORPOSITIONCONTROLLER_100:
			switch (pkt) {
				case VintPacketType.MotorPositionControllerPacket.MOTORSTATUSUPDATE: {
					dutyCycle = buf.getInt32(1) / 940;
					position = GetBigInt64(buf, 5);
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					userphid = (ch.userphid! as UserPhidgetClass.MotorPositionController);
					if (userphid.data.position !== position) {
						const bp = new BridgePacket();
						bp.set({ name: '0', type: 'l', value: position });
						bp.sendToChannel(ch, BP.POSITIONCHANGE);
					}
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: RoundDouble(dutyCycle, 3) });
					bp.sendToChannel(ch, BP.DUTYCYCLECHANGE);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1001_MOTORPOSITIONCONTROLLER_120:
			switch (pkt) {
				case VintPacketType.MotorPositionControllerPacket.MOTORSTATUSUPDATE: {
					dutyCycle = buf.getInt32(1) / 940;
					position = GetBigInt64(buf, 5);
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					userphid = (ch.userphid! as UserPhidgetClass.MotorPositionController);
					if (userphid.data.position !== position) {
						const bp = new BridgePacket();
						bp.set({ name: '0', type: 'l', value: position });
						bp.sendToChannel(ch, BP.POSITIONCHANGE);
					}
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: RoundDouble(dutyCycle, 3) });
					bp.sendToChannel(ch, BP.DUTYCYCLECHANGE);
					break;
				}
				case VintPacketType.DCMotorPacket.FAILSAFE: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.FAILSAFE_CONDITION });
					bp.set({ name: '1', type: 's', value: 'Failsafe procedure initiated.' });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendDCC1001_Failsafe(ch: LocalChannel, bp: BridgePacket) {
	await sendDCC1001(ch, bp);
}

export function recvDCC1001_Failsafe(ch: LocalChannel, buf: DataView) {
	recvDCC1001(ch, buf);
}

export async function sendDCC1002(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(8));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._DCC1002_ENCODER_100:
		case DeviceChannelUID._DCC1002_ENCODER_110:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setUint32(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.EncoderPacket.SETENCODERCHANGETRIGGER, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETIOMODE:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.EncoderPacket.IOMODE, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETENABLED:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.EncoderPacket.ENABLE, new Uint8Array(buf.buffer, 0, 1));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1002_DCMOTOR_100:
			switch (bp.vpkt) {
				case BP.SETACCELERATION:
					buf.setUint32(0, bp.getNumber(0) * 8192);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETACCELERATION, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETDUTYCYCLE:
					buf.setInt32(0, bp.getNumber(0) * 2097151);    //have to multiply by 959 so save 10 bits for multiplication in firware
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETVELOCITY, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETBRAKINGDUTYCYCLE:
					buf.setUint32(0, bp.getNumber(0) * 2097151);    //have to multiply by 959 so save 10 bits for multiplication in firware
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETBRAKINGSTRENGTH, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETCURRENTLIMIT:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETCURRENTLIMIT, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1002_DCMOTOR_110:
			switch (bp.vpkt) {
				case BP.SETACCELERATION:
					buf.setUint32(0, bp.getNumber(0) * 8192);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETACCELERATION, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETDUTYCYCLE:
					buf.setInt32(0, bp.getNumber(0) * 2097151);    //have to multiply by 959 so save 10 bits for multiplication in firware
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETVELOCITY, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETBRAKINGDUTYCYCLE:
					buf.setUint32(0, bp.getNumber(0) * 2097151);    //have to multiply by 959 so save 10 bits for multiplication in firware
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETBRAKINGSTRENGTH, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETCURRENTLIMIT:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETCURRENTLIMIT, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETFAILSAFETIME:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_TIME, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.FAILSAFERESET:
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_RESET);
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1002_MOTORPOSITIONCONTROLLER_100:
			switch (bp.vpkt) {
				case BP.SETDEADBAND:
					buf.setUint32(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETDEADBAND, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETKP:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETKP, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETKD:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETKD, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETKI:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETKI, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETTARGETPOSITION: {
					const position = bp.getNumber(0);
					SetBigInt64(buf, 0, position);
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETTARGETPOSITION, new Uint8Array(buf.buffer));
					break;
				}
				case BP.SETACCELERATION:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETACCELERATION, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETDUTYCYCLE:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETVELOCITY, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETBRAKINGDUTYCYCLE:
					buf.setUint32(0, bp.getNumber(0) * 2097151); //have to multiply by 959 so save 10 bits for multiplication in firmware
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETBRAKINGSTRENGTH, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETCURRENTLIMIT:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETCURRENTLIMIT, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETENGAGED:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETENGAGED, new Uint8Array(buf.buffer, 0, 1));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1002_MOTORPOSITIONCONTROLLER_110:
			switch (bp.vpkt) {
				case BP.SETDEADBAND:
					buf.setUint32(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETDEADBAND, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETKP:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETKP, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETKD:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETKD, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETKI:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETKI, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETTARGETPOSITION: {
					const position = bp.getNumber(0);
					SetBigInt64(buf, 0, position);
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETTARGETPOSITION, new Uint8Array(buf.buffer));
					break;
				}
				case BP.SETACCELERATION:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETACCELERATION, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETDUTYCYCLE:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETVELOCITY, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETBRAKINGDUTYCYCLE:
					buf.setUint32(0, bp.getNumber(0) * 2097151); //have to multiply by 959 so save 10 bits for multiplication in firmware
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETBRAKINGSTRENGTH, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETCURRENTLIMIT:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETCURRENTLIMIT, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETENGAGED:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETENGAGED, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETFAILSAFETIME:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_TIME, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.FAILSAFERESET:
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_RESET);
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvDCC1002(ch: LocalChannel, buf: DataView) {
	let dutyCycle, brakingDutyCycle;
	let indexPosition;
	let timeChange;
	let position;
	let indexTriggered;
	let positionChange;
	let userphid;

	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._DCC1002_ENCODER_100:
		case DeviceChannelUID._DCC1002_ENCODER_110:
			switch (pkt) {
				case VintPacketType.EncoderPacket.ENCODERCHANGE: {
					positionChange = buf.getInt32(1);
					timeChange = buf.getUint32(5) * 1000 / 48;
					indexTriggered = buf.getUint8(9);
					if (indexTriggered) {
						indexPosition = buf.getUint32(10);
					} else {
						indexPosition = 0;
					}
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: positionChange });
					bp.set({ name: '1', type: 'g', value: timeChange / 1000000.0 });
					bp.set({ name: '2', type: 'c', value: indexTriggered });
					bp.set({ name: '3', type: 'd', value: indexPosition });
					bp.sendToChannel(ch, BP.POSITIONCHANGE);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1002_DCMOTOR_100:
			switch (pkt) {
				case VintPacketType.DCMotorPacket.MOTORSTATUSUPDATE: {
					brakingDutyCycle = buf.getInt32(5) / 940;
					dutyCycle = buf.getInt32(1) / 940;
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					userphid = (ch.userphid! as UserPhidgetClass.DCMotor);
					if (userphid.data.brakingStrength !== brakingDutyCycle) {
						const bp = new BridgePacket();
						bp.set({ name: '0', type: 'g', value: RoundDouble(brakingDutyCycle, 3) });
						bp.sendToChannel(ch, BP.BRAKINGSTRENGTHCHANGE);
					}
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: RoundDouble(dutyCycle, 3) });
					bp.sendToChannel(ch, BP.DUTYCYCLECHANGE);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1002_DCMOTOR_110:
			switch (pkt) {
				case VintPacketType.DCMotorPacket.MOTORSTATUSUPDATE: {
					brakingDutyCycle = buf.getInt32(5) / 940;
					dutyCycle = buf.getInt32(1) / 940;
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					userphid = (ch.userphid! as UserPhidgetClass.DCMotor);
					if (userphid.data.brakingStrength !== brakingDutyCycle) {
						const bp = new BridgePacket();
						bp.set({ name: '0', type: 'g', value: RoundDouble(brakingDutyCycle, 3) });
						bp.sendToChannel(ch, BP.BRAKINGSTRENGTHCHANGE);
					}
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: RoundDouble(dutyCycle, 3) });
					bp.sendToChannel(ch, BP.DUTYCYCLECHANGE);
					break;
				}
				case VintPacketType.DCMotorPacket.FAILSAFE: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.FAILSAFE_CONDITION });
					bp.set({ name: '1', type: 's', value: 'Failsafe procedure initiated.' });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1002_MOTORPOSITIONCONTROLLER_100:
			switch (pkt) {
				case VintPacketType.MotorPositionControllerPacket.MOTORSTATUSUPDATE: {
					dutyCycle = buf.getInt32(1) / 940;
					position = GetBigInt64(buf, 5);
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					userphid = (ch.userphid! as UserPhidgetClass.MotorPositionController);
					if (userphid.data.position !== position) {
						const bp = new BridgePacket();
						bp.set({ name: '0', type: 'l', value: position });
						bp.sendToChannel(ch, BP.POSITIONCHANGE);
					}
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: RoundDouble(dutyCycle, 3) });
					bp.sendToChannel(ch, BP.DUTYCYCLECHANGE);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1002_MOTORPOSITIONCONTROLLER_110:
			switch (pkt) {
				case VintPacketType.MotorPositionControllerPacket.MOTORSTATUSUPDATE: {
					dutyCycle = buf.getInt32(1) / 940;
					position = GetBigInt64(buf, 5);
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					userphid = (ch.userphid! as UserPhidgetClass.MotorPositionController);
					if (userphid.data.position !== position) {
						const bp = new BridgePacket();
						bp.set({ name: '0', type: 'l', value: position });
						bp.sendToChannel(ch, BP.POSITIONCHANGE);
					}
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: RoundDouble(dutyCycle, 3) });
					bp.sendToChannel(ch, BP.DUTYCYCLECHANGE);
					break;
				}
				case VintPacketType.DCMotorPacket.FAILSAFE: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.FAILSAFE_CONDITION });
					bp.set({ name: '1', type: 's', value: 'Failsafe procedure initiated.' });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendDCC1002_Failsafe(ch: LocalChannel, bp: BridgePacket) {
	await sendDCC1002(ch, bp);
}

export function recvDCC1002_Failsafe(ch: LocalChannel, buf: DataView) {
	recvDCC1002(ch, buf);
}

export async function sendDCC1003(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._DCC1003_DCMOTOR_100:
			switch (bp.vpkt) {
				case BP.SETACCELERATION:
					buf.setUint32(0, bp.getNumber(0) * 8192);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETACCELERATION, new Uint8Array(buf.buffer));
					break;
				case BP.SETDUTYCYCLE:
					buf.setInt32(0, bp.getNumber(0) * 2097151); //have to multiply by 959 so save 10 bits for multiplcation in firmware
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETVELOCITY, new Uint8Array(buf.buffer));
					break;
				case BP.SETBRAKINGDUTYCYCLE:
					buf.setInt32(0, bp.getNumber(0) * 2097151); //have to multiply by 959 so save 10 bits for multiplcation in firmware
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETBRAKINGSTRENGTH, new Uint8Array(buf.buffer));
					break;
				case BP.SETCURRENTLIMIT:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETCURRENTLIMIT, new Uint8Array(buf.buffer));
					break;
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1003_DCMOTOR_110:
			switch (bp.vpkt) {
				case BP.SETACCELERATION:
					buf.setUint32(0, bp.getNumber(0) * 8192);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETACCELERATION, new Uint8Array(buf.buffer));
					break;
				case BP.SETDUTYCYCLE:
					buf.setInt32(0, bp.getNumber(0) * 2097151); //have to multiply by 959 so save 10 bits for multiplcation in firmware
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETVELOCITY, new Uint8Array(buf.buffer));
					break;
				case BP.SETBRAKINGDUTYCYCLE:
					buf.setInt32(0, bp.getNumber(0) * 2097151); //have to multiply by 959 so save 10 bits for multiplcation in firmware
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETBRAKINGSTRENGTH, new Uint8Array(buf.buffer));
					break;
				case BP.SETCURRENTLIMIT:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETCURRENTLIMIT, new Uint8Array(buf.buffer));
					break;
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETFAILSAFETIME:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_TIME, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.FAILSAFERESET:
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_RESET);
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvDCC1003(ch: LocalChannel, buf: DataView) {
	let dutyCycle, brakingDutyCycle;

	const pkt = buf.getUint8(0);

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const userphid = (ch.userphid! as UserPhidgetClass.DCMotor);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._DCC1003_DCMOTOR_100:
			switch (pkt) {
				case VintPacketType.DCMotorPacket.MOTORSTATUSUPDATE: {
					brakingDutyCycle = buf.getInt32(5);
					brakingDutyCycle = brakingDutyCycle / 959.0;
					dutyCycle = buf.getInt32(1);
					dutyCycle = dutyCycle / 959.0;
					if (userphid.data.brakingStrength !== brakingDutyCycle) {
						const bp = new BridgePacket();
						bp.set({ name: "0", type: "g", value: RoundDouble(brakingDutyCycle, 3) });
						bp.sendToChannel(ch, BP.BRAKINGSTRENGTHCHANGE);
					}
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "g", value: RoundDouble(dutyCycle, 3) });
					bp.sendToChannel(ch, BP.DUTYCYCLECHANGE);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1003_DCMOTOR_110:
			switch (pkt) {
				case VintPacketType.DCMotorPacket.MOTORSTATUSUPDATE: {
					brakingDutyCycle = buf.getInt32(5);
					brakingDutyCycle = brakingDutyCycle / 959.0;
					dutyCycle = buf.getInt32(1);
					dutyCycle = dutyCycle / 959.0;
					if (userphid.data.brakingStrength !== brakingDutyCycle) {
						const bp = new BridgePacket();
						bp.set({ name: "0", type: "g", value: RoundDouble(brakingDutyCycle, 3) });
						bp.sendToChannel(ch, BP.BRAKINGSTRENGTHCHANGE);
					}
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "g", value: RoundDouble(dutyCycle, 3) });
					bp.sendToChannel(ch, BP.DUTYCYCLECHANGE);
					break;
				}
				case VintPacketType.DCMotorPacket.FAILSAFE: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.FAILSAFE_CONDITION });
					bp.set({ name: "1", type: "s", value: "Failsafe procedure initiated." });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendDCC1003_Failsafe(ch: LocalChannel, bp: BridgePacket) {
	await sendDCC1003(ch, bp);
}

export function recvDCC1003_Failsafe(ch: LocalChannel, buf: DataView) {
	recvDCC1003(ch, buf);
}

export async function sendDCC1100(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(8));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._DCC1100_MOTORPOSITIONCONTROLLER_100:
			switch (bp.vpkt) {
				case BP.SETDEADBAND:
					buf.setUint32(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETDEADBAND, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETKP:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETKP, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETKD:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETKD, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETKI:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETKI, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETTARGETPOSITION: {
					const position = bp.getNumber(0);
					SetBigInt64(buf, 0, position);
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETTARGETPOSITION, new Uint8Array(buf.buffer));
					break;
				}
				case BP.SETACCELERATION:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETACCELERATION, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETDUTYCYCLE:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETVELOCITYLIMIT, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETBRAKINGDUTYCYCLE:
					buf.setUint32(0, bp.getNumber(0) * 2097151); //have to multiply by 959 so save 10 bits for multiplication in firmware
					await ch.sendVINTDataPacket(VintPacketType.BLDCMotorPacket.SETBRAKINGSTRENGTH, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETENGAGED:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETENGAGED, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETSTALLVELOCITY:
					buf.setUint32(0, bp.getNumber(0));  //convert to commutations per second
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETSTALLVELOCITY, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETFAILSAFETIME:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_TIME, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.FAILSAFERESET:
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_RESET);
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1100_MOTORPOSITIONCONTROLLER_120:
			switch (bp.vpkt) {
				case BP.SETDEADBAND:
					buf.setUint32(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETDEADBAND, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETKP:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETKP, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETKD:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETKD, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETKI:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETKI, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETTARGETPOSITION: {
					const position = bp.getNumber(0);
					SetBigInt64(buf, 0, position);
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETTARGETPOSITION, new Uint8Array(buf.buffer));
					break;
				}
				case BP.SETACCELERATION:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETACCELERATION, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETDUTYCYCLE:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETVELOCITYLIMIT, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETBRAKINGDUTYCYCLE:
					buf.setUint32(0, bp.getNumber(0) * 2097151); //have to multiply by 959 so save 10 bits for multiplication in firmware
					await ch.sendVINTDataPacket(VintPacketType.BLDCMotorPacket.SETBRAKINGSTRENGTH, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETENGAGED:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETENGAGED, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETSTALLVELOCITY:
					buf.setUint32(0, bp.getNumber(0));  //convert to commutations per second
					await ch.sendVINTDataPacket(VintPacketType.MotorPositionControllerPacket.SETSTALLVELOCITY, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETFAILSAFETIME:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_TIME, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.FAILSAFERESET:
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_RESET);
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1100_TEMPERATURESENSOR_IC_100:
		case DeviceChannelUID._DCC1100_TEMPERATURESENSOR_IC_120:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					// TemperatureChangeTrigger in Q8.8 format
					buf.setUint16(0, bp.getNumber(0) * 256);
					await ch.sendVINTDataPacket(VintPacketType.TemperatureSensorPacket.SETTEMPERATURECHANGETRIGGER, new Uint8Array(buf.buffer, 0, 2));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1100_BLDCMOTOR_100:
			switch (bp.vpkt) {
				case BP.SETACCELERATION:
					buf.setUint32(0, bp.getNumber(0) * 8192);
					await ch.sendVINTDataPacket(VintPacketType.BLDCMotorPacket.SETACCELERATION, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETDUTYCYCLE:
					buf.setInt32(0, bp.getNumber(0) * 2097151);
					await ch.sendVINTDataPacket(VintPacketType.BLDCMotorPacket.SETVELOCITY, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETBRAKINGDUTYCYCLE:
					buf.setUint32(0, bp.getNumber(0) * 2097151);
					await ch.sendVINTDataPacket(VintPacketType.BLDCMotorPacket.SETBRAKINGSTRENGTH, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETCURRENTLIMIT:
					buf.setInt32(0, (bp.getNumber(0) * 0.045 * 4095 / 3.3) + 2048);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETCURRENTLIMIT, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETSTALLVELOCITY:
					buf.setUint32(0, bp.getNumber(0));  //convert to commutations pre second
					await ch.sendVINTDataPacket(VintPacketType.BLDCMotorPacket.SETSTALLVELOCITY, new Uint8Array(buf.buffer, 0, 4));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1100_BLDCMOTOR_120:
			switch (bp.vpkt) {
				case BP.SETACCELERATION:
					buf.setUint32(0, bp.getNumber(0) * 8192);
					await ch.sendVINTDataPacket(VintPacketType.BLDCMotorPacket.SETACCELERATION, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETDUTYCYCLE:
					buf.setInt32(0, bp.getNumber(0) * 2097151);
					await ch.sendVINTDataPacket(VintPacketType.BLDCMotorPacket.SETVELOCITY, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETBRAKINGDUTYCYCLE:
					buf.setUint32(0, bp.getNumber(0) * 2097151);
					await ch.sendVINTDataPacket(VintPacketType.BLDCMotorPacket.SETBRAKINGSTRENGTH, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETCURRENTLIMIT:
					buf.setInt32(0, (bp.getNumber(0) * 0.045 * 4095 / 3.3) + 2048);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETCURRENTLIMIT, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETSTALLVELOCITY:
					buf.setUint32(0, bp.getNumber(0));  //convert to commutations pre second
					await ch.sendVINTDataPacket(VintPacketType.BLDCMotorPacket.SETSTALLVELOCITY, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETFAILSAFETIME:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_TIME, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.FAILSAFERESET:
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_RESET);
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvDCC1100(ch: LocalChannel, buf: DataView) {
	let temperature, dutyCycle, brakingDutyCycle;
	let position;
	let userphid;

	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._DCC1100_TEMPERATURESENSOR_IC_100:
		case DeviceChannelUID._DCC1100_TEMPERATURESENSOR_IC_120:
			switch (pkt) {
				case VintPacketType.TemperatureSensorPacket.TEMPERATURECHANGE: {
					temperature = buf.getInt32(1) / (1 << 16);
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: RoundDouble(temperature, 2) });
					bp.sendToChannel(ch, BP.TEMPERATURECHANGE);
					break;
				}
				case VintPacketType.TemperatureSensorPacket.SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.SATURATION });
					bp.set({ name: '1', type: 's', value: 'Saturation Detected.' });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1100_BLDCMOTOR_100:
			switch (pkt) {
				case VintPacketType.BLDCMotorPacket.MOTORSTATUSUPDATE: {
					dutyCycle = buf.getInt32(1) / 940;
					brakingDutyCycle = buf.getInt32(5) / 940;
					position = GetBigInt64(buf, 9);
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					userphid = (ch.userphid! as unknown as UserPhidgetClass.BLDCMotor);
					if (userphid.data.brakingStrength !== brakingDutyCycle) {
						const bp = new BridgePacket();
						bp.set({ name: '0', type: 'g', value: RoundDouble(brakingDutyCycle, 3) });
						bp.sendToChannel(ch, BP.BRAKINGSTRENGTHCHANGE);
					}
					if (userphid.data.position !== position) {
						const bp = new BridgePacket();
						bp.set({ name: '0', type: 'l', value: position });
						bp.sendToChannel(ch, BP.POSITIONCHANGE);
					}
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: RoundDouble(dutyCycle, 3) });
					bp.sendToChannel(ch, BP.DUTYCYCLECHANGE);
					break;
				}
				case VintPacketType.BLDCMotorPacket.STALLDETECTED: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.MOTOR_STALL_CONDITION });
					bp.set({ name: '1', type: 's', value: 'Dangerous motor stall detected. Velocity has been reduced in order to prevent damage.' });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1100_BLDCMOTOR_120:
			switch (pkt) {
				case VintPacketType.BLDCMotorPacket.MOTORSTATUSUPDATE: {
					dutyCycle = buf.getInt32(1) / 940;
					brakingDutyCycle = buf.getInt32(5) / 940;
					position = GetBigInt64(buf, 9);
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					userphid = (ch.userphid! as unknown as UserPhidgetClass.BLDCMotor);
					if (userphid.data.brakingStrength !== brakingDutyCycle) {
						const bp = new BridgePacket();
						bp.set({ name: '0', type: 'g', value: RoundDouble(brakingDutyCycle, 3) });
						bp.sendToChannel(ch, BP.BRAKINGSTRENGTHCHANGE);
					}
					if (userphid.data.position !== position) {
						const bp = new BridgePacket();
						bp.set({ name: '0', type: 'l', value: position });
						bp.sendToChannel(ch, BP.POSITIONCHANGE);
					}
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: RoundDouble(dutyCycle, 3) });
					bp.sendToChannel(ch, BP.DUTYCYCLECHANGE);
					break;
				}
				case VintPacketType.BLDCMotorPacket.STALLDETECTED: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.MOTOR_STALL_CONDITION });
					bp.set({ name: '1', type: 's', value: 'Dangerous motor stall detected. Velocity has been reduced in order to prevent damage.' });
					ch.sendErrorEvent(bp);
					break;
				}
				case VintPacketType.DCMotorPacket.FAILSAFE: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.FAILSAFE_CONDITION });
					bp.set({ name: '1', type: 's', value: 'Failsafe procedure initiated.' });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1100_MOTORPOSITIONCONTROLLER_100:
			switch (pkt) {
				case VintPacketType.MotorPositionControllerPacket.MOTORSTATUSUPDATE: {
					dutyCycle = buf.getInt32(1) / 940;
					position = GetBigInt64(buf, 5);
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					userphid = (ch.userphid! as unknown as UserPhidgetClass.MotorPositionController);
					if (userphid.data.position !== position) {
						const bp = new BridgePacket();
						bp.set({ name: '0', type: 'l', value: position });
						bp.sendToChannel(ch, BP.POSITIONCHANGE);
					}
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: RoundDouble(dutyCycle, 3) });
					bp.sendToChannel(ch, BP.DUTYCYCLECHANGE);
					break;
				}
				case VintPacketType.BLDCMotorPacket.STALLDETECTED: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.MOTOR_STALL_CONDITION });
					bp.set({ name: '1', type: 's', value: 'Dangerous motor stall detected. Velocity has been reduced in order to prevent damage.' });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._DCC1100_MOTORPOSITIONCONTROLLER_120:
			switch (pkt) {
				case VintPacketType.MotorPositionControllerPacket.MOTORSTATUSUPDATE: {
					dutyCycle = buf.getInt32(1) / 940;
					position = GetBigInt64(buf, 5);
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					userphid = (ch.userphid! as unknown as UserPhidgetClass.MotorPositionController);
					if (userphid.data.position !== position) {
						const bp = new BridgePacket();
						bp.set({ name: '0', type: 'l', value: position });
						bp.sendToChannel(ch, BP.POSITIONCHANGE);
					}
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: RoundDouble(dutyCycle, 3) });
					bp.sendToChannel(ch, BP.DUTYCYCLECHANGE);
					break;
				}
				case VintPacketType.BLDCMotorPacket.STALLDETECTED: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.MOTOR_STALL_CONDITION });
					bp.set({ name: '1', type: 's', value: 'Dangerous motor stall detected. Velocity has been reduced in order to prevent damage.' });
					ch.sendErrorEvent(bp);
					break;
				}
				case VintPacketType.DCMotorPacket.FAILSAFE: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.FAILSAFE_CONDITION });
					bp.set({ name: '1', type: 's', value: 'Failsafe procedure initiated.' });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendDCC1100_Failsafe(ch: LocalChannel, bp: BridgePacket) {
	await sendDCC1100(ch, bp);
}

export function recvDCC1100_Failsafe(ch: LocalChannel, buf: DataView) {
	recvDCC1100(ch, buf);
}

// eslint-disable-next-line require-await
export async function sendDCC1120(ch: LocalChannel, bp: BridgePacket) {
	throw new Error("Not Implemented");

	// eslint-disable-next-line no-unreachable
	switch (ch.chDef.uid) {
		case DeviceChannelUID._DCC1110_CURRENTINPUT_100:
		case DeviceChannelUID._DCC1120_CURRENTINPUT_100:
		case DeviceChannelUID._DCC1130_CURRENTINPUT_100:
			switch (bp.vpkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvDCC1120(ch: LocalChannel, buf: DataView) {
	throw new Error("Not Implemented");

	// eslint-disable-next-line no-unreachable
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._DCC1110_CURRENTINPUT_100:
		case DeviceChannelUID._DCC1120_CURRENTINPUT_100:
		case DeviceChannelUID._DCC1130_CURRENTINPUT_100:
			switch (pkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendDCC1110(ch: LocalChannel, bp: BridgePacket) {
	await sendDCC1120(ch, bp);
}
export function recvDCC1110(ch: LocalChannel, buf: DataView) {
	recvDCC1120(ch, buf);
}

export async function sendDCC1130(ch: LocalChannel, bp: BridgePacket) {
	await sendDCC1120(ch, bp);
}
export function recvDCC1130(ch: LocalChannel, buf: DataView) {
	recvDCC1120(ch, buf);
}

export async function sendDST1000(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._DST1000_DISTANCESENSOR_100:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.DistanceSensorPacket.SETDISTANCECHANGETRIGGER, new Uint8Array(buf.buffer, 0, 2));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvDST1000(ch: LocalChannel, buf: DataView) {
	let distance;
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._DST1000_DISTANCESENSOR_100:
			switch (pkt) {
				case VintPacketType.DistanceSensorPacket.DISTANCECHANGE: {
					distance = buf.getUint16(1, true);
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "u", value: distance });
					bp.sendToChannel(ch, BP.DISTANCECHANGE);
					break;
				}
				case VintPacketType.DistanceSensorPacket.SATURATION: {
					//SATURATION in the DST1001 is synonymous with OUTOFRANGE, which makes more sense in this context
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.OUT_OF_RANGE });
					bp.set({ name: "1", type: "s", value: "Sensor value is outside the valid range for this sensor." });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendDST1001(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._DST1001_DISTANCESENSOR_100:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.DistanceSensorPacket.SETDISTANCECHANGETRIGGER, new Uint8Array(buf.buffer, 0, 2));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvDST1001(ch: LocalChannel, buf: DataView) {
	let distance;
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._DST1001_DISTANCESENSOR_100:
			switch (pkt) {
				case VintPacketType.DistanceSensorPacket.DISTANCECHANGE: {
					distance = buf.getUint16(1, true);
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "u", value: distance });
					bp.sendToChannel(ch, BP.DISTANCECHANGE);
					break;
				}
				case VintPacketType.DistanceSensorPacket.SATURATION: {
					//SATURATION in the DST1001 is synonymous with OUTOFRANGE, which makes more sense in this context
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.OUT_OF_RANGE });
					bp.set({ name: "1", type: "s", value: "Sensor value is outside the valid range for this sensor." });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendDST1002(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._DST1002_DISTANCESENSOR_100:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.DistanceSensorPacket.SETDISTANCECHANGETRIGGER, new Uint8Array(buf.buffer, 0, 2));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvDST1002(ch: LocalChannel, buf: DataView) {
	let distance;
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._DST1002_DISTANCESENSOR_100:
			switch (pkt) {
				case VintPacketType.DistanceSensorPacket.DISTANCECHANGE: {
					distance = buf.getUint16(1, true);
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "u", value: distance });
					bp.sendToChannel(ch, BP.DISTANCECHANGE);
					break;
				}
				case VintPacketType.DistanceSensorPacket.SATURATION: {
					//SATURATION in the DST1001 is synonymous with OUTOFRANGE, which makes more sense in this context
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.OUT_OF_RANGE });
					bp.set({ name: "1", type: "s", value: "Sensor value is outside the valid range for this sensor." });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendDST1200(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._DST1200_DISTANCESENSOR_100:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.DistanceSensorPacket.SETDISTANCECHANGETRIGGER, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETSONARQUIETMODE:
					buf.setUint8(0, bp.getNumber(0) ? 1 : 0);
					await ch.sendVINTDataPacket(VintPacketType.DistanceSensorPacket.SONAR_QUIETMODE, new Uint8Array(buf.buffer, 0, 1));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvDST1200(ch: LocalChannel, buf: DataView) {
	const DISTANCE_MAX_REFLECTIONS = 8;
	const reflections_distance = new Array(DISTANCE_MAX_REFLECTIONS);
	const reflections_strength = new Array(DISTANCE_MAX_REFLECTIONS);
	let reflections_count;
	let distance;
	let i;

	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._DST1200_DISTANCESENSOR_100:
			switch (pkt) {
				case VintPacketType.DistanceSensorPacket.SONARREFLECTIONSUPDATE: {
					reflections_count = buf.getUint8(1);
					for (i = 0; i < DISTANCE_MAX_REFLECTIONS; i++) {
						if (i < reflections_count) {
							reflections_distance[i] = buf.getUint16(2 * i + 2);
						}
						else {
							reflections_distance[i] = PUNK.UINT32;
						}
					}
					for (i = 0; i < DISTANCE_MAX_REFLECTIONS; i++) {
						if (i < reflections_count) {
							reflections_strength[i] = buf.getUint16((2 * i) + (2 * reflections_count) + 2);
							if (reflections_strength[i] === 0x7FFF) {
								reflections_strength[i] = PUNK.UINT32;
							}
						} else {
							reflections_strength[i] = PUNK.UINT32;
						}
					}

					if (reflections_count === 0) {
						const bp = new BridgePacket();
						bp.set({ name: "0", type: "U", value: reflections_distance });
						bp.set({ name: "1", type: "U", value: reflections_strength });
						bp.set({ name: "2", type: "u", value: reflections_count });
						bp.sendToChannel(ch, BP.SONARUPDATE);
						break;
					}

					let bp = new BridgePacket();
					bp.set({ name: "0", type: "U", value: reflections_distance });
					bp.set({ name: "1", type: "U", value: reflections_strength });
					bp.set({ name: "2", type: "u", value: reflections_count });
					bp.sendToChannel(ch, BP.SONARUPDATE);

					bp = new BridgePacket();
					bp.set({ name: "0", type: "u", value: reflections_distance[0] });
					bp.sendToChannel(ch, BP.DISTANCECHANGE);
					break;
				}
				case VintPacketType.DistanceSensorPacket.DISTANCECHANGE: {
					distance = buf.getUint32(1);
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "u", value: distance });
					bp.sendToChannel(ch, BP.DISTANCECHANGE);
					break;
				}
				case VintPacketType.DistanceSensorPacket.SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.SATURATION });
					bp.set({ name: "1", type: "s", value: "Saturation Detected." });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendENC1000(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._ENC1000_ENCODER_100:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setUint32(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.EncoderPacket.SETENCODERCHANGETRIGGER, new Uint8Array(buf.buffer));
					break;
				case BP.SETIOMODE:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.EncoderPacket.IOMODE, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETENABLED:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.EncoderPacket.ENABLE, new Uint8Array(buf.buffer, 0, 1));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvENC1000(ch: LocalChannel, buf: DataView) {
	let indexPosition;
	let indexTriggered;
	let positionChange;
	let timeChange;

	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._ENC1000_ENCODER_100:
			switch (pkt) {
				case VintPacketType.EncoderPacket.ENCODERCHANGE: {
					positionChange = buf.getInt32(1);
					timeChange = buf.getUint32(5) * 1000 / 16;  // Time comes back as counts at 16MHz, convert to ns
					indexTriggered = buf.getUint8(9);
					if (indexTriggered) {
						indexPosition = buf.getInt32(10);
					} else {
						indexPosition = 0;
					}
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: positionChange });
					bp.set({ name: "1", type: "g", value: timeChange / 1000000.0 });
					bp.set({ name: "2", type: "c", value: indexTriggered });
					bp.set({ name: "3", type: "d", value: indexPosition });
					bp.sendToChannel(ch, BP.POSITIONCHANGE);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendENC1001(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._ENC1001_ENCODER_100:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					const dataInterval = ch.parent.parent?._handleDataIntervalPacket(bp, 1);
					if (dataInterval)
						buf.setUint16(0, dataInterval);
					else
						throw new PhidgetError(ErrorCode.UNEXPECTED);
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setUint32(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.EncoderPacket.SETENCODERCHANGETRIGGER, new Uint8Array(buf.buffer));
					break;
				case BP.SETENABLED:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.EncoderPacket.ENABLE, new Uint8Array(buf.buffer, 0, 1));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvENC1001(ch: LocalChannel, buf: DataView) {
	let indexPosition;
	let indexTriggered;
	let positionChange;
	let timeChange;
	let interval;

	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._ENC1001_ENCODER_100:
			switch (pkt) {
				case VintPacketType.EncoderPacket.ENCODERCHANGE:
					positionChange = buf.getInt32(1);
					timeChange = buf.getUint32(5) * 1000 / 64;  // Time comes back as counts at 64MHz, convert to ns
					indexTriggered = buf.getUint8(9);
					if (indexTriggered) {
						indexPosition = buf.getInt32(10);
					} else {
						indexPosition = 0;
					}
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: positionChange });
					bp.set({ name: "1", type: "g", value: timeChange / 1000000.0 });
					bp.set({ name: "2", type: "c", value: indexTriggered });
					bp.set({ name: "3", type: "d", value: indexPosition });
					bp.sendToChannel(ch, BP.POSITIONCHANGE);
					break;
				case VintPacketType.GenericPacket.DATAINTERVAL_RESP: {
					interval = buf.getUint16(1);
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "u", value: interval });
					bp.sendToChannel(ch, BP.DATAINTERVALCHANGE);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendHIN1101(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._HIN1101_ENCODER_100:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setUint32(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.EncoderPacket.SETENCODERCHANGETRIGGER, new Uint8Array(buf.buffer));
					break;
				case BP.SETIOMODE:
					buf.setInt32(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.EncoderPacket.IOMODE, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETENABLED:
					buf.setInt8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.EncoderPacket.ENABLE, new Uint8Array(buf.buffer, 0, 1));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvHIN1101(ch: LocalChannel, buf: DataView) {
	let positionChange;
	let timeChange;
	let state;

	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._HIN1101_ENCODER_100:
			switch (pkt) {
				case VintPacketType.EncoderPacket.ENCODERCHANGE: {
					positionChange = buf.getInt32(1);
					timeChange = buf.getInt32(5) * 1000 / 16;   //Time comes back as coutns at 16MHz, convert to ns
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: positionChange });
					bp.set({ name: "1", type: "g", value: timeChange / 1000000.0 });
					bp.set({ name: "2", type: "c", value: 0 });
					bp.set({ name: "3", type: "d", value: 0 });
					bp.sendToChannel(ch, BP.POSITIONCHANGE);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._HIN1101_DIGITALINPUT_100:
			switch (pkt) {
				case VintPacketType.DigitalInputPacket.STATECHANGE: {
					state = buf.getUint8(1) & 0x01;
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: state });
					bp.sendToChannel(ch, BP.STATECHANGE);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendHIN1000(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._HIN1000_CAPACITIVETOUCH_100:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					// buf.setUint16(0, bp.getNumber(0) * (1 << 15));
					// await ch.sendVINTDataPacket(VintPacketType.CapacitiveTouchPacket.TOUCHVALUECHANGETRIGGER, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETSENSITIVITY:
					buf.setUint8(0, ((1 - bp.getNumber(0)) * 80) + 20);
					await ch.sendVINTDataPacket(VintPacketType.CapacitiveTouchPacket.SENSITIVITY, new Uint8Array(buf.buffer, 0, 1));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvHIN1000(ch: LocalChannel, buf: DataView) {
	let touched;

	const pkt = buf.getUint8(0);
	switch (ch.chDef.uid) {
		case DeviceChannelUID._HIN1000_CAPACITIVETOUCH_100:
			switch (pkt) {
				case VintPacketType.CapacitiveTouchPacket.TOUCHVALUECHANGE: {
					touched = buf.getUint8(1) ? 1 : 0;
					const bp = new BridgePacket();
					if (touched) {
						bp.set({ name: "0", type: "g", value: touched });
						bp.sendToChannel(ch, BP.TOUCHINPUTVALUECHANGE);
					} else {
						bp.sendToChannel(ch, BP.TOUCHINPUTEND);
					}
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendHIN1001(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._HIN1001_CAPACITIVETOUCH_BUTTONS_100:
		case DeviceChannelUID._HIN1001_CAPACITIVETOUCH_WHEEL_100:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setUint16(0, bp.getNumber(0) * (1 << 15));
					await ch.sendVINTDataPacket(VintPacketType.CapacitiveTouchPacket.TOUCHVALUECHANGETRIGGER, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETSENSITIVITY:
					if (ch.index === 4) {
						buf.setUint8(0, ((1 - bp.getNumber(0)) * 32) + 1);
					} else {
						buf.setUint8(0, ((1 - bp.getNumber(0)) * 64) + 1);
					}
					await ch.sendVINTDataPacket(VintPacketType.CapacitiveTouchPacket.SENSITIVITY, new Uint8Array(buf.buffer, 0, 1));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvHIN1001(ch: LocalChannel, buf: DataView) {
	let value;
	let touched;

	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._HIN1001_CAPACITIVETOUCH_BUTTONS_100:
		case DeviceChannelUID._HIN1001_CAPACITIVETOUCH_WHEEL_100:
			switch (pkt) {
				case VintPacketType.CapacitiveTouchPacket.TOUCHVALUECHANGE: {
					touched = buf.getUint8(1) ? 1 : 0;
					if (ch.index < 4) {
						value = touched;
					} else {
						value = buf.getUint16(2) / 65536.0;
					}
					const bp = new BridgePacket();
					if (touched) {
						bp.set({ name: "0", type: "g", value: value });
						bp.sendToChannel(ch, BP.TOUCHINPUTVALUECHANGE);
					} else {
						bp.sendToChannel(ch, BP.TOUCHINPUTEND);
					}
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendHIN1100(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._HIN1100_VOLTAGERATIOINPUT_100:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setUint16(0, bp.getNumber(0) * 16384);
					await ch.sendVINTDataPacket(VintPacketType.VoltageRatioInputPacket.SETVOLTAGERATIOCHANGETRIGGER, new Uint8Array(buf.buffer, 0, 2));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvHIN1100(ch: LocalChannel, buf: DataView) {
	let axis;
	let value;

	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._HIN1100_VOLTAGERATIOINPUT_100:
			switch (pkt) {
				case VintPacketType.VoltageRatioInputPacket.VOLTAGERATIOCHANGE: {
					axis = RoundDouble((buf.getInt16(1) / 16384), 4);
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "g", value: axis });
					bp.sendToChannel(ch, BP.VOLTAGERATIOCHANGE);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._HIN1100_DIGITALINPUT_100:
			switch (pkt) {
				case VintPacketType.DigitalInputPacket.STATECHANGE: {
					value = buf.getUint8(1) ? 1 : 0;
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: value });
					bp.sendToChannel(ch, BP.STATECHANGE);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendHUM1000(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._HUM1000_TEMPERATURESENSOR_IC_100:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setUint32(0, bp.getNumber(0) * (1 << 16));
					await ch.sendVINTDataPacket(VintPacketType.TemperatureSensorPacket.SETTEMPERATURECHANGETRIGGER, new Uint8Array(buf.buffer));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._HUM1000_HUMIDITYSENSOR_100:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setUint32(0, bp.getNumber(0) * (1 << 16));
					await ch.sendVINTDataPacket(VintPacketType.HumiditySensorPacket.SETHUMIDITYCHANGETRIGGER, new Uint8Array(buf.buffer));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvHUM1000(ch: LocalChannel, buf: DataView) {
	let temperature;
	let humidity;

	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._HUM1000_TEMPERATURESENSOR_IC_100:
			switch (pkt) {
				case VintPacketType.TemperatureSensorPacket.TEMPERATURECHANGE: {
					temperature = buf.getInt32(1) / (1 << 16);
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "g", value: RoundDouble(temperature, 2) });
					bp.sendToChannel(ch, BP.TEMPERATURECHANGE);
					break;
				}
				case VintPacketType.TemperatureSensorPacket.SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.SATURATION });
					bp.set({ name: "1", type: "s", value: "Saturation Detected." });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._HUM1000_HUMIDITYSENSOR_100:
			switch (pkt) {
				case VintPacketType.HumiditySensorPacket.HUMIDITYCHANGE: {
					humidity = buf.getInt32(1) / (1 << 16);
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "g", value: RoundDouble(humidity, 2) });
					bp.sendToChannel(ch, BP.HUMIDITYCHANGE);
					break;
				}
				case VintPacketType.HumiditySensorPacket.SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.SATURATION });
					bp.set({ name: "1", type: "s", value: "Saturation Detected." });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendHUM1001(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._HUM1001_HUMIDITYSENSOR_100:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setUint32(0, bp.getNumber(0) * (1 << 16));
					await ch.sendVINTDataPacket(VintPacketType.HumiditySensorPacket.SETHUMIDITYCHANGETRIGGER, new Uint8Array(buf.buffer));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._HUM1001_TEMPERATURESENSOR_IC_100:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setUint32(0, bp.getNumber(0) * (1 << 16));
					await ch.sendVINTDataPacket(VintPacketType.TemperatureSensorPacket.SETTEMPERATURECHANGETRIGGER, new Uint8Array(buf.buffer));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvHUM1001(ch: LocalChannel, buf: DataView) {
	let temperature;
	let humidity;

	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._HUM1001_HUMIDITYSENSOR_100:
			switch (pkt) {
				case VintPacketType.HumiditySensorPacket.HUMIDITYCHANGE: {
					humidity = buf.getInt32(1) / (1 << 16);
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "g", value: RoundDouble(humidity, 2) });
					bp.sendToChannel(ch, BP.HUMIDITYCHANGE);
					break;
				}
				case VintPacketType.HumiditySensorPacket.SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.SATURATION });
					bp.set({ name: "1", type: "s", value: "Saturation Detected." });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._HUM1001_TEMPERATURESENSOR_IC_100:
			switch (pkt) {
				case VintPacketType.TemperatureSensorPacket.TEMPERATURECHANGE: {
					temperature = buf.getInt32(1) / (1 << 16);
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "g", value: RoundDouble(temperature, 2) });
					bp.sendToChannel(ch, BP.TEMPERATURECHANGE);
					break;
				}
				case VintPacketType.TemperatureSensorPacket.SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.SATURATION });
					bp.set({ name: "1", type: "s", value: "Saturation Detected." });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendLCD1100_WRITETEXT(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(48));

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const userphid = (ch.userphid! as unknown as UserPhidgetClass.LCD);

	const font: Enum.LCDFont = bp.getNumber(0) as Enum.LCDFont;
	let xpos = bp.getNumber(1);
	const ypos = bp.getNumber(2);
	const chars = bp.getString(3);

	const fontSize = userphid.getFontSize(font);
	const frameBuffer = userphid.data.frameBuffer;
	const width = userphid.data.width;
	const height = userphid.data.height;

	let numchars = chars.length;

	// Because FONT_User1 is stored in framebuffer 1
	if (font == frameBuffer) {
		throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Invalid font/framebuffer combination.");
	}

	//truncate text to end of line
	numchars = Math.min((width - xpos), (fontSize.width * numchars)) / fontSize.width;

	// truncate text off bottom of screen
	if (ypos + fontSize.height > height) {
		numchars = 0;
	}

	// numchars is 0 - so we don't actually send anything, but still need to return OK for async calls
	if (numchars === 0) {
		return;
	}

	// Send packets
	let numsent = 0;
	while (numsent < numchars) {
		// number of letters to send
		const count = Math.min(VINT_MAX_OUT_PACKETSIZE - 6, numchars - numsent);

		//font(1), xsize(1), ysize(1), xpos(1), ypos(1), num_chars(1), text(num_chars <= 8)
		buf.setUint8(0, font);
		buf.setUint8(1, fontSize.width);
		buf.setUint8(2, fontSize.height);
		buf.setUint8(3, xpos);
		buf.setUint8(4, ypos);
		buf.setUint8(5, count);

		//fill the rest of the packet with chars
		for (let i = 0; i < count; i++)
			buf.setUint8(i + 6, chars.charCodeAt(numsent++));

		//increment horizontal position according to the number of chars written multiplied by the font symbol width
		xpos += count * fontSize.width;
		await ch.sendVINTDataPacket(VintPacketType.LCDPacket.WRITETEXT, new Uint8Array(buf.buffer, 0, count + 6));
	}

}

export async function sendLCD1100_WRITEBITMAP(ch: LocalChannel, bp: BridgePacket) {

	let buf = new DataView(new ArrayBuffer(48));
	let count = 0;

	const bitmap = bp.getArray(4);
	const xsize = bp.getNumber(2);
	const ysize = bp.getNumber(3);

	buf.setUint8(0, bp.getNumber(0));
	buf.setUint8(1, bp.getNumber(1));
	buf.setUint8(2, xsize);
	buf.setUint8(3, ysize);

	await ch.sendVINTDataPacket(VintPacketType.LCDPacket.WRITEBITMAP, new Uint8Array(buf.buffer, 0, 4));

	let x = 0;
	let y = 0;

	while (x < xsize) {
		buf = new DataView(new ArrayBuffer(48));
		//create bit stream of pixels
		for (let i = 0; i < VINT_MAX_OUT_PACKETSIZE * 8 && x < xsize; i++) {
			const byteIdx = Math.floor(i / 8);
			count = byteIdx + 1;
			const bit = 1 << (7 - (i % 8)) & (bitmap[y * xsize + x] ? 0xFF : 0x00);
			const newbyte = buf.getUint8(byteIdx) | bit;
			buf.setUint8(byteIdx, newbyte);

			y++;
			if (y >= ysize) {
				x++;
				y = 0;
			}
		}

		await ch.sendVINTDataPacket(VintPacketType.LCDPacket.BITMAPDATA, new Uint8Array(buf.buffer, 0, count));
	}
}

export async function sendLCD1100_SETCHARACTERBITMAP(ch: LocalChannel, bp: BridgePacket) {
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const userphid = (ch.userphid! as unknown as UserPhidgetClass.LCD);

	const font: Enum.LCDFont = bp.getNumber(0) as Enum.LCDFont;
	const characterStr = bp.getString(1);
	const bitmap = bp.getArray(2);

	if (font != Enum.LCDFont.USER1 && font != Enum.LCDFont.USER2)
		throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Font must be user font 1 or 2.");

	const frameBuffer = userphid.frameBuffer;
	const maxchars = userphid.getMaxCharacters(font);
	const fontSize = userphid.getFontSize(font);
	const lcdw = userphid.width;

	if (characterStr.length != 1)
		throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Multibyte characters are unsupported.");

	let character = characterStr.charCodeAt(0);

	//Characters start at 1
	character -= 1;

	if (character < 0 || character > maxchars)
		throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Character code must be between 0x00 and 0x"
			+ maxchars.toString(16) + " ('" + String.fromCharCode(maxchars)
			+ "'). Number of supported characters depends on the font size.");

	if (frameBuffer != font) {
		//FrameBuffer in Q8.0 format
		const buf = new DataView(new ArrayBuffer(1));
		buf.setUint8(0, font);
		await ch.sendVINTDataPacket(VintPacketType.LCDPacket.SETFRAMEBUFFER, new Uint8Array(buf.buffer, 0, 1));
	}

	//build and send initial packet
	const buf = new DataView(new ArrayBuffer(4));
	buf.setUint8(0, fontSize.width * (character % Math.floor(lcdw / fontSize.width))); //x position
	buf.setUint8(1, fontSize.height * Math.floor(character / Math.floor(lcdw / fontSize.width))); //y position
	buf.setUint8(2, fontSize.width); //width
	buf.setUint8(3, fontSize.height); //height
	await ch.sendVINTDataPacket(VintPacketType.LCDPacket.WRITEBITMAP, new Uint8Array(buf.buffer, 0, 4));

	let x = 0;
	let y = 0;
	while (x < fontSize.width) {
		const buf = new DataView(new ArrayBuffer(VINT_MAX_OUT_PACKETSIZE));
		let count;

		//create bit stream of pixels
		for (let i = 0; i < VINT_MAX_OUT_PACKETSIZE * 8 && x < fontSize.width; i++) {
			const byteIdx = Math.floor(i / 8);
			count = byteIdx + 1;
			const bit = 1 << (7 - (i % 8)) & (bitmap[y * fontSize.width + x] ? 0xFF : 0x00);
			const newbyte = buf.getUint8(byteIdx) | bit;
			buf.setUint8(byteIdx, newbyte);
			y++;
			if (y >= fontSize.height) {
				x++;
				y = 0;
			}
		}
		await ch.sendVINTDataPacket(VintPacketType.LCDPacket.BITMAPDATA, new Uint8Array(buf.buffer, 0, count));
	}

	if (frameBuffer != font) {
		//FrameBuffer in Q8.0 format
		const buf = new DataView(new ArrayBuffer(1));
		buf.setUint8(0, frameBuffer);
		await ch.sendVINTDataPacket(VintPacketType.LCDPacket.SETFRAMEBUFFER, new Uint8Array(buf.buffer, 0, 1));
	}
}

export async function sendLCD1100(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(48));

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const userphid = (ch.userphid! as unknown as UserPhidgetClass.LCD);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._LCD1100_LCD_100:
		case DeviceChannelUID._LCD1100_LCD_200:
			await userphid._transactionLock.acquire();
			try {
				switch (bp.vpkt) {
					case BP.SAVEFRAMEBUFFER: {
						const fb = bp.getNumber(0);
						if (fb < 0 || fb > 2) {
							throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Framebuffer must be 0, 1 or 2.");
						}
						buf.setUint8(0, fb);
						await ch.sendVINTDataPacket(VintPacketType.LCDPacket.SAVEFRAMEBUFFER, new Uint8Array(buf.buffer, 0, 1));
						break;
					}
					case BP.SETFRAMEBUFFER:
						buf.setUint8(0, bp.getNumber(0));
						await ch.sendVINTDataPacket(VintPacketType.LCDPacket.SETFRAMEBUFFER, new Uint8Array(buf.buffer, 0, 1));
						break;
					case BP.SETBACKLIGHT:
						//Backlight in Q0.8 format
						if (bp.getNumber(0) >= 1) {
							buf.setUint8(0, 0xFF);
						} else {
							buf.setUint8(0, bp.getNumber(0) * (1 << 8));
						}
						await ch.sendVINTDataPacket(VintPacketType.LCDPacket.SETBACKLIGHT, new Uint8Array(buf.buffer, 0, 1));
						break;
					case BP.SETCONTRAST:
						if (bp.getNumber(0) >= 1) {
							buf.setUint8(0, 0xFF);
						} else {
							buf.setUint8(0, bp.getNumber(0) * (1 << 8));
						}
						await ch.sendVINTDataPacket(VintPacketType.LCDPacket.SETCONTRAST, new Uint8Array(buf.buffer, 0, 1));
						break;
					case BP.DRAWPIXEL:
						buf.setUint8(0, bp.getNumber(0));
						buf.setUint8(1, bp.getNumber(1));
						buf.setUint8(2, bp.getNumber(2));
						await ch.sendVINTDataPacket(VintPacketType.LCDPacket.DRAWPIXEL, new Uint8Array(buf.buffer, 0, 3));
						break;
					case BP.DRAWLINE:
						buf.setUint8(0, bp.getNumber(0));
						buf.setUint8(1, bp.getNumber(1));
						buf.setUint8(2, bp.getNumber(2));
						buf.setUint8(3, bp.getNumber(3));
						await ch.sendVINTDataPacket(VintPacketType.LCDPacket.DRAWLINE, new Uint8Array(buf.buffer, 0, 4));
						break;
					case BP.DRAWRECT:
						buf.setUint8(0, bp.getNumber(0));
						buf.setUint8(1, bp.getNumber(1));
						buf.setUint8(2, bp.getNumber(2));
						buf.setUint8(3, bp.getNumber(3));
						buf.setUint8(4, bp.getNumber(4) ? 0xFF : 0x00);
						buf.setUint8(5, bp.getNumber(5) ? 0xFF : 0x00);
						await ch.sendVINTDataPacket(VintPacketType.LCDPacket.DRAWRECT, new Uint8Array(buf.buffer, 0, 6));
						break;
					case BP.WRITETEXT:
						await sendLCD1100_WRITETEXT(ch, bp);
						break;
					case BP.WRITEBITMAP:
						await sendLCD1100_WRITEBITMAP(ch, bp);
						break;
					case BP.FLUSH:
						await ch.sendVINTDataPacket(VintPacketType.LCDPacket.FLUSH);
						break;
					case BP.CLEAR:
						await ch.sendVINTDataPacket(VintPacketType.LCDPacket.CLEAR);
						break;
					case BP.SETSLEEP:
						buf.setUint8(0, bp.getNumber(0) ? 0xFF : 0x00);
						await ch.sendVINTDataPacket(VintPacketType.LCDPacket.SETSLEEP, new Uint8Array(buf.buffer, 0, 1));
						break;
					case BP.SETCHARACTERBITMAP:
						await sendLCD1100_SETCHARACTERBITMAP(ch, bp);
						break;
					case BP.COPY:
						buf.setUint8(0, bp.getNumber(0));
						buf.setUint8(1, bp.getNumber(1));
						buf.setUint8(2, bp.getNumber(2));
						buf.setUint8(3, bp.getNumber(3));
						buf.setUint8(4, bp.getNumber(4));
						buf.setUint8(5, bp.getNumber(5));
						buf.setUint8(6, bp.getNumber(6));
						buf.setUint8(7, bp.getNumber(7));
						buf.setUint8(8, bp.getNumber(8) ? 0xFF : 0x00);
						await ch.sendVINTDataPacket(VintPacketType.LCDPacket.COPY, new Uint8Array(buf.buffer, 0, 9));
						break;
					case BP.INITIALIZE:
						await ch.sendVINTDataPacket(VintPacketType.LCDPacket.INIT);
						break;
					case BP.SETFONTSIZE:
						break;  //required or else it errors and does not get into the channel bridgeinput
					default:
						throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
				}
				break;
			} finally {
				userphid._transactionLock.release();
			}
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendLCD1100_1(ch: LocalChannel, bp: BridgePacket) {
	await sendLCD1100(ch, bp);
}

export function recvLCD1100() {
	throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet received.");
}

export function recvLCD1100_1() {
	throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet received.");
}

export async function sendLED1000(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._LED1000_DIGITALOUTPUT_100:
			switch (bp.vpkt) {
				case BP.SETSTATE:
					buf.setUint16(0, bp.getNumber(0) ? (1 << 15) : 0);
					await ch.sendVINTDataPacket(VintPacketType.DigitalOutputPacket.SETDUTYCYCLE, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETDUTYCYCLE:
					buf.setUint16(0, bp.getNumber(0) * (1 << 15));
					await ch.sendVINTDataPacket(VintPacketType.DigitalOutputPacket.SETDUTYCYCLE, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETLEDFORWARDVOLTAGE:
					buf.setUint8(0, bp.getNumber(0));
					switch (buf.getUint8(0)) {
						case Enum.LEDForwardVoltage.VOLTS_3_2:
						case Enum.LEDForwardVoltage.VOLTS_4_0:
						case Enum.LEDForwardVoltage.VOLTS_4_8:
						case Enum.LEDForwardVoltage.VOLTS_5_6:
							await ch.sendVINTDataPacket(VintPacketType.DigitalOutputPacket.LEDFORWARDVOLTAGE, new Uint8Array(buf.buffer, 0, 1));
							break;
						default:
							throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Invalid or unsupported voltage for this device.");
					}
					break;
				case BP.SETLEDCURRENTLIMIT:
					buf.setUint32(0, bp.getNumber(0) * (1 << 16));
					await ch.sendVINTDataPacket(VintPacketType.DigitalOutputPacket.LEDCURRENTLIMIT, new Uint8Array(buf.buffer));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvLED1000() {
	throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet received.");
}

export async function sendLUX1000(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._LUX1000_LIGHTSENSOR_100:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setUint32(0, bp.getNumber(0) * (1 << 14));
					await ch.sendVINTDataPacket(VintPacketType.LightSensorPacket.SETILLUMINANCECHANGETRIGGER, new Uint8Array(buf.buffer));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvLUX1000(ch: LocalChannel, buf: DataView) {
	let illuminance;

	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._LUX1000_LIGHTSENSOR_100:
			switch (pkt) {
				case VintPacketType.LightSensorPacket.ILLUMINANCECHANGE: {
					illuminance = buf.getInt32(1) / (1 << 14);
					illuminance = (illuminance < 100) ? RoundDouble(illuminance, 4) : RoundDouble(illuminance, 2);
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "g", value: illuminance });
					bp.sendToChannel(ch, BP.ILLUMINANCECHANGE);
					break;
				}
				case VintPacketType.LightSensorPacket.SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.SATURATION });
					bp.set({ name: "1", type: "s", value: "Saturation Detected." });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendHUM1100(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._HUM1100_VOLTAGERATIOINPUT_100:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setUint16(0, bp.getNumber(0) * 0xFFFF);
					await ch.sendVINTDataPacket(VintPacketType.VoltageRatioInputPacket.SETVOLTAGERATIOCHANGETRIGGER, new Uint8Array(buf.buffer, 0, 2));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvHUM1100(ch: LocalChannel, buf: DataView) {
	let value;
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._HUM1100_VOLTAGERATIOINPUT_100:
			switch (pkt) {
				case VintPacketType.VoltageRatioInputPacket.VOLTAGERATIOCHANGE: {
					value = buf.getUint16(1) / 65535.0;
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "g", value: RoundDouble(value, 3) });
					bp.sendToChannel(ch, BP.VOLTAGERATIOCHANGE);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

// eslint-disable-next-line require-await, @typescript-eslint/no-unused-vars
export async function sendMOT1100_OLD(_ch: LocalChannel, _bp: BridgePacket) {
	throw new Error("Obsolete Firmware");
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function recvMOT1100_OLD(_ch: LocalChannel, _buf: DataView) {
	throw new Error("Obsolete Firmware");
}

export async function sendMOT1100(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._MOT1100_ACCELEROMETER_200:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.AXES_SETAXISCHANGETRIGGER, new Uint8Array(buf.buffer));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvMOT1100(ch: LocalChannel, buf: DataView) {
	let timestamp;
	const acc = [0, 0, 0];

	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._MOT1100_ACCELEROMETER_200:
			switch (pkt) {
				case VintPacketType.GenericPacket.AXES_AXISVALUECHANGE: {
					acc[0] = buf.getInt16(1) / (1 << 12);
					acc[0] = RoundDouble(acc[0], 4);
					acc[1] = buf.getInt16(3) / (1 << 12);
					acc[1] = RoundDouble(acc[1], 4);
					acc[2] = buf.getInt16(5) / (1 << 12);
					acc[2] = RoundDouble(acc[2], 4);
					timestamp = buf.getUint32(7);
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "G", value: acc });
					bp.set({ name: "1", type: "g", value: timestamp });
					bp.sendToChannel(ch, BP.ACCELERATIONCHANGE);
					break;
				}
				case VintPacketType.GenericPacket.AXES_SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.SATURATION });
					bp.set({ name: "1", type: "s", value: "Acceleration Saturation." });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendMOT1101(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._MOT1101_ACCELEROMETER_100:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.AXES_SETAXISCHANGETRIGGER, new Uint8Array(buf.buffer));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._MOT1101_GYROSCOPE_100:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.ZERO:
					await ch.sendVINTDataPacket(VintPacketType.GyroscopePacket.ZERO);
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._MOT1101_MAGNETOMETER_100:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.AXES_SETAXISCHANGETRIGGER, new Uint8Array(buf.buffer));
					break;
				case BP.RESETCORRECTIONPARAMETERS:
					await ch.sendVINTDataPacket(VintPacketType.MagnetometerPacket.RESET_CORRECTION_PARAMETERS);
					break;
				case BP.SAVECORRECTIONPARAMETERS:
					await ch.sendVINTDataPacket(VintPacketType.MagnetometerPacket.SAVECORRECTIONPARAMETERS);
					break;
				case BP.SETCORRECTIONPARAMETERS:
					await sendMOT1101_SETCORRECTIONPARAMETERS(ch, bp);
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._MOT1101_SPATIAL_100:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.ZERO:
					await ch.sendVINTDataPacket(VintPacketType.GyroscopePacket.ZERO);
					break;
				case BP.RESETCORRECTIONPARAMETERS:
					await ch.sendVINTDataPacket(VintPacketType.MagnetometerPacket.RESET_CORRECTION_PARAMETERS);
					break;
				case BP.SAVECORRECTIONPARAMETERS:
					await ch.sendVINTDataPacket(VintPacketType.MagnetometerPacket.SAVECORRECTIONPARAMETERS);
					break;
				case BP.SETCORRECTIONPARAMETERS:
					await sendMOT1101_SETCORRECTIONPARAMETERS(ch, bp);
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvMOT1101(ch: LocalChannel, buf: DataView) {
	let fireSaturation = 0;
	let timestamp;
	const compass = [0, 0, 0];
	const accel = [0, 0, 0];
	const gyro = [0, 0, 0];

	const pkt = buf.getUint8(0);
	let i;

	switch (ch.chDef.uid) {
		case DeviceChannelUID._MOT1101_ACCELEROMETER_100:
			switch (pkt) {
				case VintPacketType.GenericPacket.AXES_AXISVALUECHANGE: {
					accel[0] = RoundDouble((buf.getInt16(1) / (1 << 12)), 4);
					accel[1] = RoundDouble((buf.getInt16(3) / (1 << 12)), 4);
					accel[2] = RoundDouble((buf.getInt16(5) / (1 << 12)), 4);
					timestamp = buf.getUint32(7);

					const bp = new BridgePacket();
					bp.set({ name: "0", type: "G", value: accel });
					bp.set({ name: "1", type: "g", value: timestamp });
					bp.sendToChannel(ch, BP.ACCELERATIONCHANGE);
					break;
				}
				case VintPacketType.GenericPacket.AXES_SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.SATURATION });
					bp.set({ name: "1", type: "s", value: "Saturation Detected." });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._MOT1101_GYROSCOPE_100:
			switch (pkt) {
				case VintPacketType.GenericPacket.AXES_AXESUPDATE: {
					gyro[0] = RoundDouble((buf.getFloat32(1, true)), 5);
					gyro[1] = RoundDouble((buf.getFloat32(5, true)), 5);
					gyro[2] = RoundDouble((buf.getFloat32(9, true)), 5);
					timestamp = buf.getUint32(13);

					const bp = new BridgePacket();
					bp.set({ name: "0", type: "G", value: gyro });
					bp.set({ name: "1", type: "g", value: timestamp });
					bp.sendToChannel(ch, BP.ANGULARRATEUPDATE);
					break;
				}
				case VintPacketType.GenericPacket.AXES_SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.SATURATION });
					bp.set({ name: "1", type: "s", value: "Saturation Detected." });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._MOT1101_MAGNETOMETER_100:
			switch (pkt) {
				case VintPacketType.GenericPacket.AXES_AXISVALUECHANGE: {
					compass[0] = RoundDouble((buf.getInt16(1) / (1 << 12)), 4);
					compass[1] = RoundDouble((buf.getInt16(3) / (1 << 12)), 4);
					compass[2] = RoundDouble((buf.getInt16(5) / (1 << 12)), 4);
					timestamp = buf.getUint32(7);

					const bp = new BridgePacket();
					bp.set({ name: "0", type: "G", value: compass });
					bp.set({ name: "1", type: "g", value: timestamp });
					bp.sendToChannel(ch, BP.FIELDSTRENGTHCHANGE);
					break;
				}
				case VintPacketType.GenericPacket.AXES_SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.SATURATION });
					bp.set({ name: "1", type: "s", value: "Saturation Detected." });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._MOT1101_SPATIAL_100:
			switch (pkt) {
				case VintPacketType.GenericPacket.AXES_AXISVALUECHANGE:
					for (i = 0; i < 3; i++) {
						//Positive or negative fixed point saturation
						if ((buf.getUint8(1 + (i * 2)) === 0x7F && buf.getUint8(2 + (i * 2)) === 0xFF) ||
							(buf.getUint8(1 + (i * 2)) === 0x80 && buf.getUint8(2 + (i * 2)) === 0x00)) {
							fireSaturation |= 0x04;
						}
						else {
							compass[i] = RoundDouble((buf.getInt16(1 + (i * 2)) / (1 << 12)), 5);
						}
					}

					for (i = 0; i < 3; i++) {
						//Positive or negative fixed point saturation
						if ((buf.getUint8(7 + (i * 2)) === 0x7F && buf.getUint8(8 + (i * 2)) === 0xFF) ||
							(buf.getUint8(7 + (i * 2)) === 0x80 && buf.getUint8(8 + (i * 2)) === 0x00)) {
							fireSaturation |= 0x01;
						}
						else {
							accel[i] = RoundDouble((buf.getInt16(7 + (i * 2)) / (1 << 12)), 5);
						}
					}

					for (i = 0; i < 3; i++) {
						const tmp = buf.getFloat32(13 + (i * 4), true);
						if (tmp === PUNK.FLT || tmp === -PUNK.FLT) {
							fireSaturation |= 0x02;
						}
						else {
							gyro[i] = RoundDouble(tmp, 5);
						}
					}

					if (fireSaturation === 0) {
						timestamp = buf.getUint32(25);
						const bp = new BridgePacket();
						bp.set({ name: "0", type: "G", value: accel });
						bp.set({ name: "1", type: "G", value: gyro });
						bp.set({ name: "2", type: "G", value: compass });
						bp.set({ name: "3", type: "g", value: timestamp });
						bp.sendToChannel(ch, BP.SPATIALDATA);
					} else {
						let saturationError = true;
						if (fireSaturation & 0x01) {
							const bp = new BridgePacket();
							bp.set({ name: "0", type: "d", value: ErrorEventCode.SATURATION });
							bp.set({ name: "1", type: "s", value: "Acceleration Saturation Detected." });
							ch.sendErrorEvent(bp);
							saturationError = false;
						}
						if (fireSaturation & 0x02) {
							const bp = new BridgePacket();
							bp.set({ name: "0", type: "d", value: ErrorEventCode.SATURATION });
							bp.set({ name: "1", type: "s", value: "Gyroscope Saturation Detected." });
							ch.sendErrorEvent(bp);
							saturationError = false;
						}
						if (fireSaturation & 0x04) {
							const bp = new BridgePacket();
							bp.set({ name: "0", type: "d", value: ErrorEventCode.SATURATION });
							bp.set({ name: "1", type: "s", value: "Magnetometer Saturation Detected." });
							ch.sendErrorEvent(bp);
							saturationError = false;
						}
						if (saturationError) {
							throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected saturation flag. This is likely a firmware bug.");
						}
					}
					break;
				case VintPacketType.GenericPacket.AXES_SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.SATURATION });
					bp.set({ name: "1", type: "s", value: "Saturation Detected." });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendMOT1101_SETCORRECTIONPARAMETERS(ch: LocalChannel, bp: BridgePacket) {
	const firmware_userMagnetometerTransform = [0, 0, 0, 0, 0, 0];
	const firmware_userMagnetometerOffset = [0, 0, 0];
	const firmware_userMagnetometerGain = [0, 0, 0];

	const buf = new DataView(new ArrayBuffer(28));

	// Convert double values to 32-bit int to send to firmawre
	const firmware_userMagField = bp.getNumber(0);
	for (let i = 0; i < 3; i++) {
		firmware_userMagnetometerOffset[i] = bp.getNumber((i + 1).toString());
		firmware_userMagnetometerGain[i] = bp.getNumber((i + 4).toString());
	}
	for (let i = 0; i < 6; i++) {
		firmware_userMagnetometerTransform[i] = bp.getNumber((i + 7).toString());
	}

	// Pack and send 1st packet
	buf.setFloat32(0, firmware_userMagField, true);
	for (let i = 0; i < 3; i++) {
		buf.setFloat32(4 + i * 4, firmware_userMagnetometerOffset[i], true);
		buf.setFloat32(16 + i * 4, firmware_userMagnetometerGain[i], true);
	}

	await ch.sendVINTDataPacket(VintPacketType.MagnetometerPacket.SET_CORRECTION_PARAMETERS, new Uint8Array(buf.buffer));

	for (let i = 0; i < 6; i++) {
		buf.setFloat32(i * 4, firmware_userMagnetometerTransform[i], true);
	}

	await ch.sendVINTDataPacket(VintPacketType.MagnetometerPacket.SET_CORRECTION_PARAMETERS, new Uint8Array(buf.buffer, 0, 24));

}

// eslint-disable-next-line require-await, @typescript-eslint/no-unused-vars
export async function sendVINTACCEL(_ch: LocalChannel, _bp: BridgePacket) {
	throw new Error("Obsolete Firmware");
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function recvVINTACCEL(_ch: LocalChannel, _buf: DataView) {
	throw new Error("Obsolete Firmware");
}

export async function sendMOT1102(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(24));
	let userphid;

	switch (ch.chDef.uid) {
		case DeviceChannelUID._MOT1102_ACCELEROMETER_200:
		case DeviceChannelUID._MOT1102_ACCELEROMETER_300:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.AXES_SETAXISCHANGETRIGGER, new Uint8Array(buf.buffer, 0, 4));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._MOT1102_GYROSCOPE_200:
		case DeviceChannelUID._MOT1102_GYROSCOPE_300:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.ZERO:
					await ch.sendVINTDataPacket(VintPacketType.GyroscopePacket.ZERO);
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._MOT1102_MAGNETOMETER_200:
		case DeviceChannelUID._MOT1102_MAGNETOMETER_300:
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			userphid = (ch.userphid! as unknown as UserPhidgetClass.Magnetometer);
			await userphid.transactionLock.acquire();
			try {
				switch (bp.vpkt) {
					case BP.SETDATAINTERVAL:
						buf.setUint16(0, bp.getNumber(0));
						await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
						break;
					case BP.SETCHANGETRIGGER:
						buf.setFloat32(0, bp.getNumber(0), true);
						await ch.sendVINTDataPacket(VintPacketType.GenericPacket.AXES_SETAXISCHANGETRIGGER, new Uint8Array(buf.buffer, 0, 4));
						break;
					case BP.RESETCORRECTIONPARAMETERS:
						await ch.sendVINTDataPacket(VintPacketType.MagnetometerPacket.RESET_CORRECTION_PARAMETERS);
						break;
					case BP.SAVECORRECTIONPARAMETERS:
						await ch.sendVINTDataPacket(VintPacketType.MagnetometerPacket.SAVECORRECTIONPARAMETERS);
						break;
					case BP.SETCORRECTIONPARAMETERS:
						await sendMOT1102_SETCORRECTIONPARAMETERS(ch, bp);
						break;
					default:
						throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
				}
			} finally {
				userphid.transactionLock.release();
			}
			break;
		case DeviceChannelUID._MOT1102_SPATIAL_200:
		case DeviceChannelUID._MOT1102_SPATIAL_300:
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			userphid = (ch.userphid! as unknown as UserPhidgetClass.Spatial);
			await userphid._transactionLock.acquire();
			try {
				switch (bp.vpkt) {
					case BP.SETDATAINTERVAL:
						buf.setUint16(0, bp.getNumber(0));
						await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
						break;
					case BP.ZERO:
						await ch.sendVINTDataPacket(VintPacketType.GyroscopePacket.ZERO);
						break;
					case BP.RESETCORRECTIONPARAMETERS:
						await ch.sendVINTDataPacket(VintPacketType.MagnetometerPacket.RESET_CORRECTION_PARAMETERS);
						break;
					case BP.SAVECORRECTIONPARAMETERS:
						await ch.sendVINTDataPacket(VintPacketType.MagnetometerPacket.SAVECORRECTIONPARAMETERS);
						break;
					case BP.SETCORRECTIONPARAMETERS:
						await sendMOT1102_SETCORRECTIONPARAMETERS(ch, bp);
						break;
					case BP.ZEROSPATIALALGORITHM:
						await ch.sendVINTDataPacket(VintPacketType.SpatialPacket.ZERO_AHRS);
						break;
					case BP.SETSPATIALALGORITHM:
						buf.setUint8(0, bp.getNumber(0));
						await ch.sendVINTDataPacket(VintPacketType.SpatialPacket.AHRS_ALGORITHM, new Uint8Array(buf.buffer, 0, 1));
						break;
					case BP.SETSPATIALALGORITHMMAGGAIN:
						buf.setFloat32(0, bp.getNumber(0), true);
						await ch.sendVINTDataPacket(VintPacketType.SpatialPacket.AHRS_MAG_GAIN, new Uint8Array(buf.buffer, 0, 4));
						break;
					case BP.SETAHRSPARAMETERS:
						buf.setFloat32(0, bp.getNumber(0), true);
						buf.setFloat32(4, bp.getNumber(1), true);
						buf.setFloat32(8, bp.getNumber(2), true);
						buf.setFloat32(12, bp.getNumber(3), true);
						buf.setFloat32(16, bp.getNumber(4), true);
						buf.setFloat32(20, bp.getNumber(5), true);
						await ch.sendVINTDataPacket(VintPacketType.SpatialPacket.SET_AHRS_PARAMS, new Uint8Array(buf.buffer));
						break;
					default:
						throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
				}
			}
			finally {
				userphid._transactionLock.release();
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvMOT1102(ch: LocalChannel, buf: DataView) {
	let fireSaturation = 0;
	let timestamp;
	const compass = [0, 0, 0];
	const accel = [0, 0, 0];
	const gyro = [0, 0, 0];
	const quaternion = [0, 0, 0, 0];

	const pkt = buf.getUint8(0);
	let i;

	switch (ch.chDef.uid) {
		case DeviceChannelUID._MOT1102_ACCELEROMETER_200:
		case DeviceChannelUID._MOT1102_ACCELEROMETER_300:
			switch (pkt) {
				case VintPacketType.GenericPacket.AXES_AXISVALUECHANGE: {
					accel[0] = RoundDouble((buf.getInt16(1) / (1 << 12)), 4);
					accel[1] = RoundDouble((buf.getInt16(3) / (1 << 12)), 4);
					accel[2] = RoundDouble((buf.getInt16(5) / (1 << 12)), 4);
					timestamp = buf.getUint32(7);

					const bp = new BridgePacket();
					bp.set({ name: "0", type: "G", value: accel });
					bp.set({ name: "1", type: "g", value: timestamp });
					bp.sendToChannel(ch, BP.ACCELERATIONCHANGE);
					break;
				}
				case VintPacketType.GenericPacket.AXES_SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.SATURATION });
					bp.set({ name: "1", type: "s", value: "Saturation Detected." });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._MOT1102_GYROSCOPE_200:
		case DeviceChannelUID._MOT1102_GYROSCOPE_300:
			switch (pkt) {
				case VintPacketType.GenericPacket.AXES_AXESUPDATE: {
					gyro[0] = RoundDouble((buf.getFloat32(1, true)), 5);
					gyro[1] = RoundDouble((buf.getFloat32(5, true)), 5);
					gyro[2] = RoundDouble((buf.getFloat32(9, true)), 5);
					timestamp = buf.getUint32(13);

					const bp = new BridgePacket();
					bp.set({ name: "0", type: "G", value: gyro });
					bp.set({ name: "1", type: "g", value: timestamp });
					bp.sendToChannel(ch, BP.ANGULARRATEUPDATE);
					break;
				}
				case VintPacketType.GenericPacket.AXES_SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.SATURATION });
					bp.set({ name: "1", type: "s", value: "Saturation Detected." });
					ch.sendErrorEvent(bp);
					break;
				}
				case VintPacketType.GenericPacket.AXES_INVALID: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.INVALID_STATE_CONDITION });
					bp.set({ name: "1", type: "s", value: "The gyroscope has received invalid data and will recover shortly." });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._MOT1102_MAGNETOMETER_200:
		case DeviceChannelUID._MOT1102_MAGNETOMETER_300:
			switch (pkt) {
				case VintPacketType.GenericPacket.AXES_AXISVALUECHANGE: {
					compass[0] = RoundDouble((buf.getInt16(1) / (1 << 12)), 4);
					compass[1] = RoundDouble((buf.getInt16(3) / (1 << 12)), 4);
					compass[2] = RoundDouble((buf.getInt16(5) / (1 << 12)), 4);
					timestamp = buf.getUint32(7);

					const bp = new BridgePacket();
					bp.set({ name: "0", type: "G", value: compass });
					bp.set({ name: "1", type: "g", value: timestamp });
					bp.sendToChannel(ch, BP.FIELDSTRENGTHCHANGE);
					break;
				}
				case VintPacketType.GenericPacket.AXES_SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.SATURATION });
					bp.set({ name: "1", type: "s", value: "Saturation Detected." });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._MOT1102_SPATIAL_200:
		case DeviceChannelUID._MOT1102_SPATIAL_300:
			switch (pkt) {
				case VintPacketType.GenericPacket.AXES_AXISVALUECHANGE:
					for (i = 0; i < 3; i++) {
						//Positive or negative fixed point saturation
						if ((buf.getUint8(1 + (i * 2)) === 0x7F && buf.getUint8(2 + (i * 2)) === 0xFF) ||
							(buf.getUint8(1 + (i * 2)) === 0x80 && buf.getUint8(2 + (i * 2)) === 0x00)) {
							fireSaturation |= 0x04;
						}
						else {
							compass[i] = RoundDouble((buf.getInt16(1 + (i * 2)) / (1 << 12)), 5);
						}
					}

					for (i = 0; i < 3; i++) {
						//Positive or negative fixed point saturation
						if ((buf.getUint8(7 + (i * 2)) === 0x7F && buf.getUint8(8 + (i * 2)) === 0xFF) ||
							(buf.getUint8(7 + (i * 2)) === 0x80 && buf.getUint8(8 + (i * 2)) === 0x00)) {
							fireSaturation |= 0x01;
						}
						else {
							accel[i] = RoundDouble((buf.getInt16(7 + (i * 2)) / (1 << 12)), 5);
						}
					}

					for (i = 0; i < 3; i++) {
						const tmp = buf.getFloat32(13 + (i * 4), true);
						if (tmp === PUNK.FLT || tmp === -PUNK.FLT) {
							fireSaturation |= 0x02;
						}
						else {
							gyro[i] = RoundDouble(tmp, 5);
						}
					}

					quaternion[0] = buf.getFloat32(29, true);
					quaternion[1] = buf.getFloat32(33, true);
					quaternion[2] = buf.getFloat32(37, true);
					quaternion[3] = buf.getFloat32(41, true);

					if (fireSaturation === 0) {
						timestamp = buf.getUint32(25);
						let bp = new BridgePacket();
						bp.set({ name: "0", type: "G", value: accel });
						bp.set({ name: "1", type: "G", value: gyro });
						bp.set({ name: "2", type: "G", value: compass });
						bp.set({ name: "3", type: "g", value: timestamp });
						bp.sendToChannel(ch, BP.SPATIALDATA);

						bp = new BridgePacket();
						bp.set({ name: "0", type: "G", value: quaternion });
						bp.set({ name: "1", type: "g", value: timestamp });
						bp.sendToChannel(ch, BP.SPATIALALGDATA);
					}
					else {
						let saturationError = true;
						if (fireSaturation & 0x01) {
							const bp = new BridgePacket();
							bp.set({ name: "0", type: "d", value: ErrorEventCode.SATURATION });
							bp.set({ name: "1", type: "s", value: "Acceleration Saturation Detected." });
							ch.sendErrorEvent(bp);
							saturationError = false;
						}
						if (fireSaturation & 0x02) {
							const bp = new BridgePacket();
							bp.set({ name: "0", type: "d", value: ErrorEventCode.SATURATION });
							bp.set({ name: "1", type: "s", value: "Gyroscope Saturation Detected." });
							ch.sendErrorEvent(bp);
							saturationError = false;
						}
						if (fireSaturation & 0x04) {
							const bp = new BridgePacket();
							bp.set({ name: "0", type: "d", value: ErrorEventCode.SATURATION });
							bp.set({ name: "1", type: "s", value: "Magnetometer Saturation Detected." });
							ch.sendErrorEvent(bp);
							saturationError = false;
						}
						if (saturationError) {
							throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected saturation flag. This is likely a firmware bug.");
						}
					}
					break;
				case VintPacketType.GenericPacket.AXES_SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.SATURATION });
					bp.set({ name: "1", type: "s", value: "Saturation Detected." });
					ch.sendErrorEvent(bp);
					break;
				}
				case VintPacketType.GenericPacket.AXES_INVALID: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.INVALID_STATE_CONDITION });
					bp.set({ name: "1", type: "s", value: "The gyroscope has received invalid data and will recover shortly." });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendMOT1102_SETCORRECTIONPARAMETERS(ch: LocalChannel, bp: BridgePacket) {
	const firmware_userMagnetometerTransform = [0, 0, 0, 0, 0, 0];
	const firmware_userMagnetometerOffset = [0, 0, 0];
	const firmware_userMagnetometerGain = [0, 0, 0];

	const buf = new DataView(new ArrayBuffer(28));

	// Convert double values to 32-bit int to send to firmware
	const firmware_userMagField = bp.getNumber(0);
	for (let i = 0; i < 3; i++) {
		firmware_userMagnetometerOffset[i] = bp.getNumber((i + 1).toString());
		firmware_userMagnetometerGain[i] = bp.getNumber((i + 4).toString());
	}
	for (let i = 0; i < 6; i++) {
		firmware_userMagnetometerTransform[i] = bp.getNumber((i + 7).toString());
	}

	// Pack and send 1st packet
	buf.setFloat32(0, firmware_userMagField, true);
	for (let i = 0; i < 3; i++) {
		buf.setFloat32(4 + i * 4, firmware_userMagnetometerOffset[i], true);
		buf.setFloat32(16 + i * 4, firmware_userMagnetometerGain[i], true);
	}

	await ch.sendVINTDataPacket(VintPacketType.MagnetometerPacket.SET_CORRECTION_PARAMETERS, new Uint8Array(buf.buffer));

	for (let i = 0; i < 6; i++) {
		buf.setFloat32(i * 4, firmware_userMagnetometerTransform[i], true);
	}

	await ch.sendVINTDataPacket(VintPacketType.MagnetometerPacket.SET_CORRECTION_PARAMETERS, new Uint8Array(buf.buffer, 0, 24));
}

export async function sendMOT1102_1(ch: LocalChannel, bp: BridgePacket) {
	await sendMOT1102(ch, bp);
}

export function recvMOT1102_1(ch: LocalChannel, buf: DataView) {
	recvMOT1102(ch, buf);
}

export async function sendMOT0100_VINT(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(24));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._MOT0100_ACCELEROMETER_100_VINT:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL: {
					const dataInterval = ch.parent.parent?._handleDataIntervalPacket(bp, 1);
					if (dataInterval)
						buf.setUint16(0, dataInterval);
					else
						throw new PhidgetError(ErrorCode.UNEXPECTED);
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
				}
					break;
				case BP.SETCHANGETRIGGER:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.AXES_SETAXISCHANGETRIGGER, new Uint8Array(buf.buffer, 0, 4));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvMOT0100_VINT(ch: LocalChannel, buf: DataView) {
	let timestamp;
	const accel = [0, 0, 0];

	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._MOT0100_ACCELEROMETER_100_VINT:
			switch (pkt) {
				case VintPacketType.GenericPacket.AXES_AXISVALUECHANGE: {
					if (buf.byteLength > 17) {
						if (buf.getUint8(17) & 0x01) {
							const bp = new BridgePacket();
							bp.set({ name: "0", type: "d", value: ErrorEventCode.OUT_OF_RANGE });
							bp.set({ name: "1", type: "s", value: "One or more accelerometer readings is out of range." });
							ch.sendErrorEvent(bp);
						}
					}

					accel[0] = buf.getFloat32(1, true);
					accel[1] = buf.getFloat32(5, true);
					accel[2] = buf.getFloat32(9, true);
					timestamp = buf.getUint32(13);

					for (let i = 0; i < 3; i++) {
						if (!isNaN(accel[i]))
							accel[i] = RoundDouble(accel[i], 6);
					}

					const bp = new BridgePacket();
					bp.set({ name: "0", type: "G", value: accel });
					bp.set({ name: "1", type: "g", value: timestamp });
					bp.sendToChannel(ch, BP.ACCELERATIONCHANGE);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendMOT0110_VINT(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(24));
	let userphid;

	switch (ch.chDef.uid) {
		case DeviceChannelUID._MOT0110_SPATIAL_100_VINT:
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			userphid = (ch.userphid! as unknown as UserPhidgetClass.Spatial);
			await userphid._transactionLock.acquire();
			try {
				switch (bp.vpkt) {
					case BP.SETDATAINTERVAL: {
						const dataInterval = ch.parent.parent?._handleDataIntervalPacket(bp, 1);
						if (dataInterval)
							buf.setUint16(0, dataInterval);
						else
							throw new PhidgetError(ErrorCode.UNEXPECTED);
						await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					}
						break;
					case BP.ZERO:
						await ch.sendVINTDataPacket(VintPacketType.GyroscopePacket.ZERO);
						break;
					case BP.RESETCORRECTIONPARAMETERS:
						await ch.sendVINTDataPacket(VintPacketType.MagnetometerPacket.RESET_CORRECTION_PARAMETERS);
						break;
					case BP.SAVECORRECTIONPARAMETERS:
						await ch.sendVINTDataPacket(VintPacketType.MagnetometerPacket.SAVECORRECTIONPARAMETERS);
						break;
					case BP.SETCORRECTIONPARAMETERS:
						await sendMOT0110_SETCORRECTIONPARAMETERS(ch, bp);
						break;
					case BP.ZEROSPATIALALGORITHM:
						await ch.sendVINTDataPacket(VintPacketType.SpatialPacket.ZERO_AHRS);
						break;
					case BP.SETSPATIALALGORITHM:
						buf.setUint8(0, bp.getNumber(0));
						await ch.sendVINTDataPacket(VintPacketType.SpatialPacket.AHRS_ALGORITHM, new Uint8Array(buf.buffer, 0, 1));
						break;
					case BP.SETSPATIALALGORITHMMAGGAIN:
						buf.setFloat32(0, bp.getNumber(0), true);
						await ch.sendVINTDataPacket(VintPacketType.SpatialPacket.AHRS_MAG_GAIN, new Uint8Array(buf.buffer, 0, 4));
						break;
					case BP.SETAHRSPARAMETERS:
						buf.setFloat32(0, bp.getNumber(0), true);
						buf.setFloat32(4, bp.getNumber(1), true);
						buf.setFloat32(8, bp.getNumber(2), true);
						buf.setFloat32(12, bp.getNumber(3), true);
						buf.setFloat32(16, bp.getNumber(4), true);
						buf.setFloat32(20, bp.getNumber(5), true);
						await ch.sendVINTDataPacket(VintPacketType.SpatialPacket.SET_AHRS_PARAMS, new Uint8Array(buf.buffer));
						break;
					case BP.SETHEATINGENABLED:
						buf.setUint8(0, bp.getNumber(0));
						await ch.sendVINTDataPacket(VintPacketType.SpatialPacket.SET_HEATING, new Uint8Array(buf.buffer, 0, 1));
						break;
					default:
						throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
				}
			}
			finally {
				userphid._transactionLock.release();
			}
			break;
		case DeviceChannelUID._MOT0110_MAGNETOMETER_100_VINT:
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			userphid = (ch.userphid! as unknown as UserPhidgetClass.Magnetometer);
			await userphid.transactionLock.acquire();
			try {
				switch (bp.vpkt) {
					case BP.SETDATAINTERVAL: {
						const dataInterval = ch.parent.parent?._handleDataIntervalPacket(bp, 1);
						if (dataInterval)
							buf.setUint16(0, dataInterval);
						else
							throw new PhidgetError(ErrorCode.UNEXPECTED);
						await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					}
						break;
					case BP.SETCHANGETRIGGER:
						buf.setFloat32(0, bp.getNumber(0), true);
						await ch.sendVINTDataPacket(VintPacketType.GenericPacket.AXES_SETAXISCHANGETRIGGER, new Uint8Array(buf.buffer, 0, 4));
						break;
					case BP.RESETCORRECTIONPARAMETERS:
						await ch.sendVINTDataPacket(VintPacketType.MagnetometerPacket.RESET_CORRECTION_PARAMETERS);
						break;
					case BP.SAVECORRECTIONPARAMETERS:
						await ch.sendVINTDataPacket(VintPacketType.MagnetometerPacket.SAVECORRECTIONPARAMETERS);
						break;
					case BP.SETCORRECTIONPARAMETERS:
						await sendMOT0110_SETCORRECTIONPARAMETERS(ch, bp);
						break;
					case BP.SETHEATINGENABLED:
						buf.setUint8(0, bp.getNumber(0));
						await ch.sendVINTDataPacket(VintPacketType.SpatialPacket.SET_HEATING, new Uint8Array(buf.buffer, 0, 1));
						break;
					default:
						throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
				}
			} finally {
				userphid.transactionLock.release();
			}
			break;
		case DeviceChannelUID._MOT0110_GYROSCOPE_100_VINT:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL: {
					const dataInterval = ch.parent.parent?._handleDataIntervalPacket(bp, 1);
					if (dataInterval)
						buf.setUint16(0, dataInterval);
					else
						throw new PhidgetError(ErrorCode.UNEXPECTED);
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
				}
					break;
				case BP.ZERO:
					await ch.sendVINTDataPacket(VintPacketType.GyroscopePacket.ZERO);
					break;
				case BP.SETHEATINGENABLED:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.SpatialPacket.SET_HEATING, new Uint8Array(buf.buffer, 0, 1));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._MOT0110_ACCELEROMETER_100_VINT:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL: {
					const dataInterval = ch.parent.parent?._handleDataIntervalPacket(bp, 1);
					if (dataInterval)
						buf.setUint16(0, dataInterval);
					else
						throw new PhidgetError(ErrorCode.UNEXPECTED);
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
				}
					break;
				case BP.SETCHANGETRIGGER:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.AXES_SETAXISCHANGETRIGGER, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETHEATINGENABLED:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.SpatialPacket.SET_HEATING, new Uint8Array(buf.buffer, 0, 1));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._MOT0110_TEMPERATURESENSOR_100_VINT:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL: {
					const dataInterval = ch.parent.parent?._handleDataIntervalPacket(bp, 1);
					if (dataInterval)
						buf.setUint16(0, dataInterval);
					else
						throw new PhidgetError(ErrorCode.UNEXPECTED);
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
				}
					break;
				case BP.SETCHANGETRIGGER:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.TemperatureSensorPacket.SETTEMPERATURECHANGETRIGGER, new Uint8Array(buf.buffer, 0, 4));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvMOT0110_VINT(ch: LocalChannel, buf: DataView) {
	let timestamp;
	let temperature;
	const compass = [0, 0, 0];
	const accel = [0, 0, 0];
	const gyro = [0, 0, 0];
	const quaternion = [0, 0, 0, 0];
	let tmpFlt;
	let interval;

	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._MOT0110_SPATIAL_100_VINT:
			switch (pkt) {
				case VintPacketType.GenericPacket.AXES_AXISVALUECHANGE: {
					if (buf.byteLength > 41) {
						if (buf.getUint8(41) & 0x20) {
							const bp = new BridgePacket();
							bp.set({ name: "0", type: "d", value: ErrorEventCode.OUT_OF_RANGE });
							bp.set({ name: "1", type: "s", value: "One or more spatial readings is out of range." });
							ch.sendErrorEvent(bp);
						}
					}

					for (let i = 0; i < 3; i++) {
						tmpFlt = buf.getFloat32((i * 4) + 1, true);
						if (!Number.isNaN(tmpFlt)) {
							compass[i] = RoundDouble(tmpFlt, 5);
						}
						tmpFlt = buf.getFloat32((i * 4) + 13, true);
						if (!Number.isNaN(tmpFlt)) {
							accel[i] = RoundDouble(tmpFlt, 5);
						}
						tmpFlt = buf.getFloat32((i * 4) + 25, true);
						if (!Number.isNaN(tmpFlt)) {
							gyro[i] = RoundDouble(tmpFlt, 5);
						}
					}

					timestamp = buf.getUint32(37);

					const bp = new BridgePacket();
					bp.set({ name: "0", type: "G", value: accel });
					bp.set({ name: "1", type: "G", value: gyro });
					bp.set({ name: "2", type: "G", value: compass });
					bp.set({ name: "3", type: "g", value: timestamp });
					bp.sendToChannel(ch, BP.SPATIALDATA);
					break;
				}
				case VintPacketType.GenericPacket.AXES_AXESUPDATE: {
					quaternion[0] = buf.getFloat32(1, true);
					quaternion[1] = buf.getFloat32(5, true);
					quaternion[2] = buf.getFloat32(9, true);
					quaternion[3] = buf.getFloat32(13, true);

					timestamp = buf.getUint16(17);

					const bp = new BridgePacket();
					bp.set({ name: "0", type: "G", value: quaternion });
					bp.set({ name: "1", type: "g", value: timestamp });
					bp.sendToChannel(ch, BP.SPATIALALGDATA);
					break;
				}
				case VintPacketType.GenericPacket.DATAINTERVAL_RESP: {
					interval = buf.getUint16(1);
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "u", value: interval });
					bp.sendToChannel(ch, BP.DATAINTERVALCHANGE);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._MOT0110_MAGNETOMETER_100_VINT:
			switch (pkt) {
				case VintPacketType.GenericPacket.AXES_AXISVALUECHANGE: {
					if (buf.byteLength > 17) {
						if (buf.getUint8(17) & 0x04) {
							const bp = new BridgePacket();
							bp.set({ name: "0", type: "d", value: ErrorEventCode.OUT_OF_RANGE });
							bp.set({ name: "1", type: "s", value: "One or more magnetometer readings is out of range." });
							ch.sendErrorEvent(bp);
						}
					}
					compass[0] = RoundDouble(buf.getFloat32(1, true), 4);
					compass[1] = RoundDouble(buf.getFloat32(5, true), 4);
					compass[2] = RoundDouble(buf.getFloat32(9, true), 4);
					timestamp = buf.getUint32(13);

					const bp = new BridgePacket();
					bp.set({ name: "0", type: "G", value: compass });
					bp.set({ name: "1", type: "g", value: timestamp });
					bp.sendToChannel(ch, BP.FIELDSTRENGTHCHANGE);
					break;
				}
				case VintPacketType.GenericPacket.DATAINTERVAL_RESP: {
					interval = buf.getUint16(1);
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "u", value: interval });
					bp.sendToChannel(ch, BP.DATAINTERVALCHANGE);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._MOT0110_GYROSCOPE_100_VINT:
			switch (pkt) {
				case VintPacketType.GenericPacket.AXES_AXESUPDATE: {
					if (buf.byteLength > 17) {
						if (buf.getUint8(17) & 0x02) {
							const bp = new BridgePacket();
							bp.set({ name: "0", type: "d", value: ErrorEventCode.OUT_OF_RANGE });
							bp.set({ name: "1", type: "s", value: "One or more gyroscope readings is out of range." });
							ch.sendErrorEvent(bp);
						}
					}
					gyro[0] = RoundDouble((buf.getFloat32(1, true)), 6);
					gyro[1] = RoundDouble((buf.getFloat32(5, true)), 6);
					gyro[2] = RoundDouble((buf.getFloat32(9, true)), 6);
					timestamp = buf.getUint32(13);

					const bp = new BridgePacket();
					bp.set({ name: "0", type: "G", value: gyro });
					bp.set({ name: "1", type: "g", value: timestamp });
					bp.sendToChannel(ch, BP.ANGULARRATEUPDATE);
					break;
				}
				case VintPacketType.GenericPacket.DATAINTERVAL_RESP: {
					interval = buf.getUint16(1);
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "u", value: interval });
					bp.sendToChannel(ch, BP.DATAINTERVALCHANGE);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._MOT0110_ACCELEROMETER_100_VINT:
			switch (pkt) {
				case VintPacketType.GenericPacket.AXES_AXISVALUECHANGE: {
					if (buf.byteLength > 17) {
						if (buf.getUint8(17) & 0x01) {
							const bp = new BridgePacket();
							bp.set({ name: "0", type: "d", value: ErrorEventCode.OUT_OF_RANGE });
							bp.set({ name: "1", type: "s", value: "One or more accelerometer readings is out of range." });
							ch.sendErrorEvent(bp);
						}
					}
					accel[0] = RoundDouble(buf.getFloat32(1, true), 6);
					accel[1] = RoundDouble(buf.getFloat32(5, true), 6);
					accel[2] = RoundDouble(buf.getFloat32(9, true), 6);
					timestamp = buf.getUint32(13);

					const bp = new BridgePacket();
					bp.set({ name: "0", type: "G", value: accel });
					bp.set({ name: "1", type: "g", value: timestamp });
					bp.sendToChannel(ch, BP.ACCELERATIONCHANGE);
					break;
				}
				case VintPacketType.GenericPacket.DATAINTERVAL_RESP: {
					interval = buf.getUint16(1);
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "u", value: interval });
					bp.sendToChannel(ch, BP.DATAINTERVALCHANGE);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._MOT0110_TEMPERATURESENSOR_100_VINT:
			switch (pkt) {
				case VintPacketType.TemperatureSensorPacket.TEMPERATURECHANGE: {
					if (buf.byteLength > 5) {
						const bp = new BridgePacket();
						switch (buf.getUint8(5) & 0x18) {
							case 0x08:
								bp.set({ name: "0", type: "d", value: ErrorEventCode.OUT_OF_RANGE_LOW_CONDITION });
								bp.set({ name: "1", type: "s", value: "Temperature is too low to be accurately measured." });
								break;
							case 0x10:
								bp.set({ name: "0", type: "d", value: ErrorEventCode.OUT_OF_RANGE_HIGH_CONDITION });
								bp.set({ name: "1", type: "s", value: "Temperature is too high to be accurately measured." });
								break;
							case 0x18:
								bp.set({ name: "0", type: "d", value: ErrorEventCode.OUT_OF_RANGE });
								bp.set({ name: "1", type: "s", value: "Temperature is unknown." });
								break;
						}
						ch.sendErrorEvent(bp);
					}

					temperature = RoundDouble(buf.getFloat32(1, true), 3);
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "g", value: temperature });
					bp.sendToChannel(ch, BP.TEMPERATURECHANGE);
					break;
				}
				case VintPacketType.GenericPacket.DATAINTERVAL_RESP: {
					interval = buf.getUint16(1);
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "u", value: interval });
					bp.sendToChannel(ch, BP.DATAINTERVALCHANGE);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendMOT0110_SETCORRECTIONPARAMETERS(ch: LocalChannel, bp: BridgePacket) {
	const firmware_userMagnetometerTransform = [0, 0, 0, 0, 0, 0];
	const firmware_userMagnetometerOffset = [0, 0, 0];
	const firmware_userMagnetometerGain = [0, 0, 0];

	const buf = new DataView(new ArrayBuffer(28));

	// Convert double values to 32-bit int to send to firmware
	const firmware_userMagField = bp.getNumber(0);
	for (let i = 0; i < 3; i++) {
		firmware_userMagnetometerOffset[i] = bp.getNumber((i + 1).toString());
		firmware_userMagnetometerGain[i] = bp.getNumber((i + 4).toString());
	}
	for (let i = 0; i < 6; i++) {
		firmware_userMagnetometerTransform[i] = bp.getNumber((i + 7).toString());
	}

	// Pack and send 1st packet
	buf.setFloat32(0, firmware_userMagField, true);
	for (let i = 0; i < 3; i++) {
		buf.setFloat32(4 + i * 4, firmware_userMagnetometerOffset[i], true);
		buf.setFloat32(16 + i * 4, firmware_userMagnetometerGain[i], true);
	}

	await ch.sendVINTDataPacket(VintPacketType.MagnetometerPacket.SET_CORRECTION_PARAMETERS, new Uint8Array(buf.buffer));

	for (let i = 0; i < 6; i++) {
		buf.setFloat32(i * 4, firmware_userMagnetometerTransform[i], true);
	}

	await ch.sendVINTDataPacket(VintPacketType.MagnetometerPacket.SET_CORRECTION_PARAMETERS, new Uint8Array(buf.buffer, 0, 24));
}

export async function sendPRE1000(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._PRE1000_PRESSURESENSOR_100:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setUint32(0, bp.getNumber(0) * (1 << 16));
					await ch.sendVINTDataPacket(VintPacketType.PressureSensorPacket.SETPRESSURECHANGETRIGGER, new Uint8Array(buf.buffer, 0, 4));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvPRE1000(ch: LocalChannel, buf: DataView) {
	let pressure;
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._PRE1000_PRESSURESENSOR_100:
			switch (pkt) {
				case VintPacketType.PressureSensorPacket.PRESSURECHANGE: {
					pressure = buf.getInt32(1) / (1 << 16);
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: RoundDouble(pressure, 3) });
					bp.sendToChannel(ch, BP.PRESSURECHANGE);
					break;
				}
				case VintPacketType.PressureSensorPacket.SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.SATURATION });
					bp.set({ name: '1', type: 's', value: 'Saturation Detected.' });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendRCC1000(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(8));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._RCC1000_RCSERVO_100:
			switch (bp.vpkt) {
				case BP.SETENGAGED:
					buf.setUint8(0, bp.getNumber(0) ? 0xFF : 0x00);
					await ch.sendVINTDataPacket(VintPacketType.RCServoPacket.SETENABLED, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETVOLTAGE:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.RCServoPacket.VOLTAGE, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETMINPULSEWIDTH:
					buf.setUint32(0, bp.getNumber(0) * 1000);
					await ch.sendVINTDataPacket(VintPacketType.RCServoPacket.SETMINPULSEWIDTH, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETMAXPULSEWIDTH:
					buf.setUint32(0, bp.getNumber(0) * 1000);
					await ch.sendVINTDataPacket(VintPacketType.RCServoPacket.SETMAXPULSEWIDTH, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETACCELERATION:
					buf.setUint32(0, bp.getNumber(0) * 16 / 2500);
					await ch.sendVINTDataPacket(VintPacketType.RCServoPacket.SETACCELERATION, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETVELOCITYLIMIT:
					buf.setUint32(0, bp.getNumber(0) * 16 / 50);
					await ch.sendVINTDataPacket(VintPacketType.RCServoPacket.SETVELOCITYLIMIT, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETTARGETPOSITION:
					buf.setUint32(0, bp.getNumber(0) * 1000);
					await ch.sendVINTDataPacket(VintPacketType.RCServoPacket.SETTARGETPOSITION, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETSPEEDRAMPINGSTATE:
					buf.setUint32(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.RCServoPacket.SETSPEEDRAMPINGSTATE, new Uint8Array(buf.buffer, 0, 4));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._RCC1000_RCSERVO_110:
		case DeviceChannelUID._RCC1000_RCSERVO_200:
			switch (bp.vpkt) {
				case BP.SETENGAGED:
					buf.setUint8(0, bp.getNumber(0) ? 0xFF : 0x00);
					await ch.sendVINTDataPacket(VintPacketType.RCServoPacket.SETENABLED, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETVOLTAGE:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.RCServoPacket.VOLTAGE, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETMINPULSEWIDTH:
					buf.setUint32(0, bp.getNumber(0) * 1000);
					await ch.sendVINTDataPacket(VintPacketType.RCServoPacket.SETMINPULSEWIDTH, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETMAXPULSEWIDTH:
					buf.setUint32(0, bp.getNumber(0) * 1000);
					await ch.sendVINTDataPacket(VintPacketType.RCServoPacket.SETMAXPULSEWIDTH, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETACCELERATION:
					buf.setUint32(0, bp.getNumber(0) * 16 / 2500);
					await ch.sendVINTDataPacket(VintPacketType.RCServoPacket.SETACCELERATION, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETVELOCITYLIMIT:
					buf.setUint32(0, bp.getNumber(0) * 16 / 50);
					await ch.sendVINTDataPacket(VintPacketType.RCServoPacket.SETVELOCITYLIMIT, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETTARGETPOSITION:
					buf.setUint32(0, bp.getNumber(0) * 1000);
					await ch.sendVINTDataPacket(VintPacketType.RCServoPacket.SETTARGETPOSITION, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETSPEEDRAMPINGSTATE:
					buf.setUint32(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.RCServoPacket.SETSPEEDRAMPINGSTATE, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETFAILSAFETIME:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_TIME, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.FAILSAFERESET:
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_RESET);
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvRCC1000(ch: LocalChannel, buf: DataView) {
	let motorPosition;

	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._RCC1000_RCSERVO_100:
			switch (pkt) {
				case VintPacketType.RCServoPacket.BAD_POWER_SUPPLY: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.BAD_POWER });
					bp.set({ name: '1', type: 's', value: 'Servo motors are drawing too much power or the power supply is not providing enough voltage or current.' });
					ch.sendErrorEvent(bp);
					break;
				}
				case VintPacketType.RCServoPacket.ARRIVED_AT_TARGET_POSITION: {
					motorPosition = buf.getUint16(1) / 16;
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: motorPosition });
					bp.sendToChannel(ch, BP.TARGETPOSITIONREACHED);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._RCC1000_RCSERVO_110:
		case DeviceChannelUID._RCC1000_RCSERVO_200:
			switch (pkt) {
				case VintPacketType.RCServoPacket.BAD_POWER_SUPPLY: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.BAD_POWER });
					bp.set({ name: '1', type: 's', value: 'Servo motors are drawing too much power or the power supply is not providing enough voltage or current.' });
					ch.sendErrorEvent(bp);
					break;
				}
				case VintPacketType.RCServoPacket.ARRIVED_AT_TARGET_POSITION: {
					motorPosition = buf.getUint16(1) / 16;
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: motorPosition });
					bp.sendToChannel(ch, BP.TARGETPOSITIONREACHED);
					break;
				}
				case VintPacketType.RCServoPacket.FAILSAFE: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.FAILSAFE_CONDITION });
					bp.set({ name: '1', type: 's', value: 'Failsafe procedure initiated.' });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendRCC1000_Failsafe(ch: LocalChannel, bp: BridgePacket) {
	await sendRCC1000(ch, bp);
}

export function recvRCC1000_Failsafe(ch: LocalChannel, buf: DataView) {
	recvRCC1000(ch, buf);
}

// eslint-disable-next-line require-await
export async function sendRCC1000_1(ch: LocalChannel, bp: BridgePacket) {
	await sendRCC1000(ch, bp);
}

export function recvRCC1000_1(ch: LocalChannel, buf: DataView) {
	recvRCC1000(ch, buf);
}

export async function sendREL1000(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(2));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._REL1000_DIGITALOUTPUT_100:
			switch (bp.vpkt) {
				case BP.SETSTATE:
					buf.setUint16(0, bp.getNumber(0) ? (1 << 15) : 0);
					await ch.sendVINTDataPacket(VintPacketType.DigitalOutputPacket.SETDUTYCYCLE, new Uint8Array(buf.buffer));
					break;
				case BP.SETDUTYCYCLE:
					buf.setUint16(0, bp.getNumber(0) * (1 << 15));
					await ch.sendVINTDataPacket(VintPacketType.DigitalOutputPacket.SETDUTYCYCLE, new Uint8Array(buf.buffer));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._REL1000_DIGITALOUTPUT_110:
			switch (bp.vpkt) {
				case BP.SETSTATE:
					buf.setUint16(0, bp.getNumber(0) ? (1 << 15) : 0);
					await ch.sendVINTDataPacket(VintPacketType.DigitalOutputPacket.SETDUTYCYCLE, new Uint8Array(buf.buffer));
					break;
				case BP.SETDUTYCYCLE:
					buf.setUint16(0, bp.getNumber(0) * (1 << 15));
					await ch.sendVINTDataPacket(VintPacketType.DigitalOutputPacket.SETDUTYCYCLE, new Uint8Array(buf.buffer));
					break;
				case BP.SETFAILSAFETIME:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_TIME, new Uint8Array(buf.buffer));
					break;
				case BP.FAILSAFERESET:
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_RESET);
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvREL1000(ch: LocalChannel, buf: DataView) {
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._REL1000_DIGITALOUTPUT_100:
			throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet received.");
		case DeviceChannelUID._REL1000_DIGITALOUTPUT_110:
			switch (pkt) {
				case VintPacketType.DigitalOutputPacket.FAILSAFE: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.FAILSAFE_CONDITION });
					bp.set({ name: "1", type: "s", value: "Failsafe procedure initiated." });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendREL1000_Failsafe(ch: LocalChannel, bp: BridgePacket) {
	await sendREL1000(ch, bp);
}

export function recvREL1000_Failsafe(ch: LocalChannel, buf: DataView) {
	recvREL1000(ch, buf);
}

export async function sendREL1100(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._REL1100_DIGITALOUTPUT_100:
			switch (bp.vpkt) {
				case BP.SETSTATE:
					buf.setUint16(0, bp.getNumber(0) ? (1 << 15) : 0);
					await ch.sendVINTDataPacket(VintPacketType.DigitalOutputPacket.SETDUTYCYCLE, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETDUTYCYCLE:
					buf.setUint16(0, bp.getNumber(0) * (1 << 15));
					await ch.sendVINTDataPacket(VintPacketType.DigitalOutputPacket.SETDUTYCYCLE, new Uint8Array(buf.buffer, 0, 2));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._REL1100_DIGITALOUTPUT_110:
			switch (bp.vpkt) {
				case BP.SETSTATE:
					buf.setUint16(0, bp.getNumber(0) ? (1 << 15) : 0);
					await ch.sendVINTDataPacket(VintPacketType.DigitalOutputPacket.SETDUTYCYCLE, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETDUTYCYCLE:
					buf.setUint16(0, bp.getNumber(0) * (1 << 15));
					await ch.sendVINTDataPacket(VintPacketType.DigitalOutputPacket.SETDUTYCYCLE, new Uint8Array(buf.buffer, 0, 2));
					break
				case BP.SETFAILSAFETIME:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_TIME, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.FAILSAFERESET:
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_RESET);
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._REL1100_DIGITALOUTPUT_120:
			switch (bp.vpkt) {
				case BP.SETSTATE:
					buf.setUint16(0, bp.getNumber(0) ? (1 << 15) : 0);
					await ch.sendVINTDataPacket(VintPacketType.DigitalOutputPacket.SETDUTYCYCLE, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETDUTYCYCLE:
					buf.setUint16(0, bp.getNumber(0) * (1 << 15));
					await ch.sendVINTDataPacket(VintPacketType.DigitalOutputPacket.SETDUTYCYCLE, new Uint8Array(buf.buffer, 0, 2));
					break
				case BP.SETFAILSAFETIME:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_TIME, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.FAILSAFERESET:
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_RESET);
					break;
				case BP.SETFREQUENCY:
					buf.setUint32(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.DigitalOutputPacket.SETFREQUENCY, new Uint8Array(buf.buffer, 0, 4));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvREL1100(ch: LocalChannel, buf: DataView) {
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._REL1100_DIGITALOUTPUT_100:
			throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet received.");
		case DeviceChannelUID._REL1100_DIGITALOUTPUT_110:
		case DeviceChannelUID._REL1100_DIGITALOUTPUT_120:
			switch (pkt) {
				case VintPacketType.DigitalOutputPacket.FAILSAFE: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.FAILSAFE_CONDITION });
					bp.set({ name: '1', type: 's', value: 'Failsafe procedure initiated.' });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendREL1100_Failsafe(ch: LocalChannel, bp: BridgePacket) {
	await sendREL1100(ch, bp);
}

export function recvREL1100_Failsafe(ch: LocalChannel, buf: DataView) {
	recvREL1100(ch, buf);
}

export async function sendREL1100_Failsafe_Frequency(ch: LocalChannel, bp: BridgePacket) {
	await sendREL1100(ch, bp);
}

export function recvREL1100_Failsafe_Frequency(ch: LocalChannel, buf: DataView) {
	recvREL1100(ch, buf);
}

export async function sendREL1101(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(2));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._REL1101_DIGITALOUTPUT_100:
			switch (bp.vpkt) {
				case BP.SETSTATE:
					buf.setUint8(0, bp.getNumber(0) ? 0xFF : 0x00);
					await ch.sendVINTDataPacket(VintPacketType.DigitalOutputPacket.SETDUTYCYCLE, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETDUTYCYCLE:
					buf.setUint8(0, bp.getNumber(0) * 255);
					await ch.sendVINTDataPacket(VintPacketType.DigitalOutputPacket.SETDUTYCYCLE, new Uint8Array(buf.buffer, 0, 1));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._REL1101_DIGITALOUTPUT_110:
			switch (bp.vpkt) {
				case BP.SETSTATE:
					buf.setUint8(0, bp.getNumber(0) ? 0xFF : 0x00);
					await ch.sendVINTDataPacket(VintPacketType.DigitalOutputPacket.SETDUTYCYCLE, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETDUTYCYCLE:
					buf.setUint8(0, bp.getNumber(0) * 255);
					await ch.sendVINTDataPacket(VintPacketType.DigitalOutputPacket.SETDUTYCYCLE, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETFAILSAFETIME:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_TIME, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.FAILSAFERESET:
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_RESET);
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvREL1101(ch: LocalChannel, buf: DataView) {
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._REL1101_DIGITALOUTPUT_100:
			switch (pkt) {
				case VintPacketType.DigitalOutputPacket.FAILSAFE: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.FAILSAFE_CONDITION });
					bp.set({ name: '1', type: 's', value: 'Failsafe procedure initiated.' });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendREL1101_Failsafe(ch: LocalChannel, bp: BridgePacket) {
	await sendREL1101(ch, bp);
}

export function recvREL1101_Failsafe(ch: LocalChannel, buf: DataView) {
	recvREL1101(ch, buf);
}

export async function sendREL1101_1(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._REL1101_DIGITALOUTPUT_FREQ_200:
			switch (bp.vpkt) {
				case BP.SETSTATE:
					buf.setUint8(0, bp.getNumber(0) ? 0xFF : 0x00);
					await ch.sendVINTDataPacket(VintPacketType.DigitalOutputPacket.SETDUTYCYCLE, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETDUTYCYCLE:
					buf.setUint8(0, bp.getNumber(0) * 255);
					await ch.sendVINTDataPacket(VintPacketType.DigitalOutputPacket.SETDUTYCYCLE, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETFAILSAFETIME:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_TIME, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.FAILSAFERESET:
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_RESET);
					break;
				case BP.SETFREQUENCY:
					buf.setUint32(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.DigitalOutputPacket.SETFREQUENCY, new Uint8Array(buf.buffer));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._REL1101_DIGITALOUTPUT_200:
			switch (bp.vpkt) {
				case BP.SETSTATE:
					buf.setUint8(0, bp.getNumber(0) ? 0xFF : 0x00);
					await ch.sendVINTDataPacket(VintPacketType.DigitalOutputPacket.SETDUTYCYCLE, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETDUTYCYCLE:
					buf.setUint8(0, bp.getNumber(0) * 255);
					await ch.sendVINTDataPacket(VintPacketType.DigitalOutputPacket.SETDUTYCYCLE, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETFAILSAFETIME:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_TIME, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.FAILSAFERESET:
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_RESET);
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvREL1101_1(ch: LocalChannel, buf: DataView) {
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._REL1101_DIGITALOUTPUT_200:
		case DeviceChannelUID._REL1101_DIGITALOUTPUT_FREQ_200:
			switch (pkt) {
				case VintPacketType.DigitalOutputPacket.FAILSAFE: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.FAILSAFE_CONDITION });
					bp.set({ name: '1', type: 's', value: 'Failsafe procedure initiated.' });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

// eslint-disable-next-line require-await
export async function sendTP_DIAGNOSER_0(ch: LocalChannel, bp: BridgePacket) {
	throw new Error("Not Implemented");

	// eslint-disable-next-line no-unreachable
	switch (ch.chDef.uid) {
		case DeviceChannelUID._TP_DIAGNOSER_RESISTANCEINPUT_100:
			switch (bp.vpkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvTP_DIAGNOSER_0(ch: LocalChannel, buf: DataView) {
	throw new Error("Not Implemented");

	// eslint-disable-next-line no-unreachable
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._TP_DIAGNOSER_RESISTANCEINPUT_100:
			switch (pkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendSAF1000(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._SAF1000_POWERGUARD_100:
			switch (bp.vpkt) {
				case BP.SETENABLED:
					buf.setUint8(0, bp.getNumber(0) ? 0xFF : 0x00);
					await ch.sendVINTDataPacket(VintPacketType.PowerGuardPacket.SETPOWERENABLED, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETOVERVOLTAGE:
					buf.setUint32(0, bp.getNumber(0) * (1 << 24));
					await ch.sendVINTDataPacket(VintPacketType.PowerGuardPacket.SETOVERVOLTAGE, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETFANMODE:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.PowerGuardPacket.SETFANMODE, new Uint8Array(buf.buffer, 0, 1));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._SAF1000_POWERGUARD_110:
			switch (bp.vpkt) {
				case BP.SETENABLED:
					buf.setUint8(0, bp.getNumber(0) ? 0xFF : 0x00);
					await ch.sendVINTDataPacket(VintPacketType.PowerGuardPacket.SETPOWERENABLED, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETOVERVOLTAGE:
					buf.setUint32(0, bp.getNumber(0) * (1 << 24));
					await ch.sendVINTDataPacket(VintPacketType.PowerGuardPacket.SETOVERVOLTAGE, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETFANMODE:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.PowerGuardPacket.SETFANMODE, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETFAILSAFETIME:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_TIME, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.FAILSAFERESET:
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_RESET);
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._SAF1000_VOLTAGEINPUT_100:
		case DeviceChannelUID._SAF1000_VOLTAGEINPUT_110:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setUint32(0, bp.getNumber(0) * (1 << 24));
					await ch.sendVINTDataPacket(VintPacketType.VoltageInputPacket.SETVOLTAGECHANGETRIGGER, new Uint8Array(buf.buffer, 0, 4));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._SAF1000_TEMPERATURESENSOR_IC_100:
		case DeviceChannelUID._SAF1000_TEMPERATURESENSOR_IC_110:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setUint32(0, bp.getNumber(0) * (1 << 16));
					await ch.sendVINTDataPacket(VintPacketType.TemperatureSensorPacket.SETTEMPERATURECHANGETRIGGER, new Uint8Array(buf.buffer, 0, 4));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvSAF1000(ch: LocalChannel, buf: DataView) {
	let temperature;
	let voltage;

	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._SAF1000_POWERGUARD_100:
			switch (pkt) {
				case VintPacketType.PowerGuardPacket.ENERGYDUMP: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.ENERGY_DUMP_CONDITION });
					bp.set({ name: '1', type: 's', value: 'Energy Dump in Progress.' });
					ch.sendErrorEvent(bp);
					break;
				}
				case VintPacketType.PowerGuardPacket.OVERTEMPERATURE: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.OVER_TEMPERATURE });
					bp.set({ name: '1', type: 's', value: 'Overtemperature Condition Detected.' });
					ch.sendErrorEvent(bp);
					break;
				}
				case VintPacketType.PowerGuardPacket.OVERVOLTAGE: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.OVER_VOLTAGE });
					bp.set({ name: '1', type: 's', value: 'Overvoltage Condition Detected.' });
					ch.sendErrorEvent(bp);
					break;
				}
				case VintPacketType.PowerGuardPacket.POWERON: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.SUCCESS });
					bp.set({ name: '1', type: 's', value: 'All error conditions have been resolved' });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._SAF1000_POWERGUARD_110:
			switch (pkt) {
				case VintPacketType.PowerGuardPacket.ENERGYDUMP: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.ENERGY_DUMP_CONDITION });
					bp.set({ name: '1', type: 's', value: 'Energy Dump in Progress.' });
					ch.sendErrorEvent(bp);
					break;
				}
				case VintPacketType.PowerGuardPacket.OVERTEMPERATURE: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.OVER_TEMPERATURE });
					bp.set({ name: '1', type: 's', value: 'Overtemperature Condition Detected.' });
					ch.sendErrorEvent(bp);
					break;
				}
				case VintPacketType.PowerGuardPacket.OVERVOLTAGE: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.OVER_VOLTAGE });
					bp.set({ name: '1', type: 's', value: 'Overvoltage Condition Detected.' });
					ch.sendErrorEvent(bp);
					break;
				}
				case VintPacketType.PowerGuardPacket.POWERON: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.SUCCESS });
					bp.set({ name: '1', type: 's', value: 'All error conditions have been resolved' });
					ch.sendErrorEvent(bp);
					break;
				}
				case VintPacketType.PowerGuardPacket.FAILSAFE: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.FAILSAFE_CONDITION });
					bp.set({ name: '1', type: 's', value: 'Failsafe procedure initiated.' });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._SAF1000_VOLTAGEINPUT_100:
		case DeviceChannelUID._SAF1000_VOLTAGEINPUT_110:
			switch (pkt) {
				case VintPacketType.VoltageInputPacket.VOLTAGECHANGE: {
					voltage = buf.getUint32(1) / (1 << 24);
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: RoundDouble(voltage, 7) });
					bp.sendToChannel(ch, BP.VOLTAGECHANGE);
					break;
				}
				case VintPacketType.VoltageInputPacket.SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.SATURATION });
					bp.set({ name: '1', type: 's', value: 'Saturation Detected.' });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._SAF1000_TEMPERATURESENSOR_IC_100:
		case DeviceChannelUID._SAF1000_TEMPERATURESENSOR_IC_110:
			switch (pkt) {
				case VintPacketType.TemperatureSensorPacket.TEMPERATURECHANGE: {
					temperature = buf.getInt32(1) / (1 << 16);
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: RoundDouble(temperature, 2) });
					bp.sendToChannel(ch, BP.TEMPERATURECHANGE);
					break;
				}
				case VintPacketType.TemperatureSensorPacket.SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.SATURATION });
					bp.set({ name: '1', type: 's', value: 'Saturation Detected.' });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendSAF1000_Failsafe(ch: LocalChannel, bp: BridgePacket) {
	await sendSAF1000(ch, bp);
}

export function recvSAF1000_Failsafe(ch: LocalChannel, buf: DataView) {
	recvSAF1000(ch, buf);
}

export async function sendSND1000(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._SND1000_SOUNDSENSOR_100:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					break;  // NOTE: Not sent to firmware, but used directly in recvSND1000
				case BP.SETSPLRANGE:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.SoundSensorPacket.SPLRANGE, new Uint8Array(buf.buffer, 0, 1));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvSND1000(ch: LocalChannel, buf: DataView) {
	const octaves = new Array(10);
	const finaloctaves = new Array(10);
	let tempDouble, gain, dB = 0.0, dBA = 0.0, dBC = 0.0, changeTrigger, lastdB;
	let saturatedFlag = false;

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const userphid = (ch.userphid! as unknown as UserPhidgetClass.SoundSensor);

	//16kHz = element 0
	const dBA_weight = [-6.700912224792721, -1.144507424157968, 0.9642291552357972, 1.2016993444284976, 0.0, -3.098968656056327, -8.456545621324212, -16.189851062139507, -26.22302364022129, -39.52906401331724];
	const dBC_weight = [-8.62944227232129, -3.0448821481317507, -0.8252420358959885, -0.16942156787436816, 0.0, 0.03251328929742176, 0.0019618319836562453, -0.1718184155510175, -0.8206982482269319, 3.030794594641769];

	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._SND1000_SOUNDSENSOR_100:
			switch (pkt) {
				case VintPacketType.SoundSensorPacket.DBCHANGE:
					gain = buf.getUint8(41) == 1 ? 39.912703891951 : 59.912703891951; //99 and 990
					saturatedFlag = buf.getUint8(42) == 1 ? true : false;
					for (let i = 0; i < 10; i++) {
						tempDouble = Math.sqrt(buf.getFloat32(i * 4 + 1, true));    //adc sample^2/cnt
						tempDouble *= (3300.0 / 4095.0);    //convert to mV
						tempDouble /= (6.309573444801932); //convert from mV to Pa
						octaves[i] = 20.0 * Math.log10(tempDouble) + 94.0 - gain;   //calculate SPL

						dB += Math.pow(10, (octaves[i] / 10.0));
						dBA += Math.pow(10, ((octaves[i] + dBA_weight[i]) / 10.0));
						dBC += Math.pow(10, ((octaves[i] + dBC_weight[i]) / 10.0));
					}
					if (saturatedFlag) {
						const bp = new BridgePacket();
						bp.set({ name: "0", type: "d", value: ErrorEventCode.SATURATION });
						bp.set({ name: "1", type: "s", value: "Saturation Detected." });
						ch.sendErrorEvent(bp);
					} else {
						dB = 10 * Math.log10(dB);
						dBA = 10 * Math.log10(dBA);
						dBC = 10 * Math.log10(dBC);
						for (let i = 0; i < 10; i++) {
							finaloctaves[i] = RoundDouble(octaves[9 - i], 4); //flip so element 0 is the lowest freq band
						}
						changeTrigger = userphid.data.SPLChangeTrigger;
						lastdB = userphid.data.lastdB;

						if (Math.abs(lastdB - dB) > changeTrigger) {
							userphid.data.lastdB = dB;
							const bp = new BridgePacket();
							bp.set({ name: "0", type: "g", value: RoundDouble(dB, 4) });
							bp.set({ name: "1", type: "g", value: RoundDouble(dBA, 4) });
							bp.set({ name: "2", type: "g", value: RoundDouble(dBC, 4) });
							bp.set({ name: "3", type: "G", value: finaloctaves });
							bp.sendToChannel(ch, BP.DBCHANGE);
						}
					}
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendSTC1000(ch: LocalChannel, bp: BridgePacket) {

	const buf = new DataView(new ArrayBuffer(8));
	switch (ch.chDef.uid) {
		case DeviceChannelUID._STC1000_STEPPER_100:
			switch (bp.vpkt) {
				case BP.SETTARGETPOSITION: {
					const position = bp.getNumber(0);
					SetBigInt64(buf, 0, position);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETTARGETPOSITION, new Uint8Array(buf.buffer));
					break;
				}
				case BP.SETVELOCITYLIMIT:
					buf.setUint32(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETVELOCITYLIMIT, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETACCELERATION:
					buf.setUint32(0, bp.getNumber(0) * (1 << 7));
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETACCELERATION, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETCURRENTLIMIT:
					buf.setUint16(0, bp.getNumber(0) * (1 << 8));
					await ch.sendVINTDataPacket(VintPacketType.StepperPacket.SETCURRENTLIMIT, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETHOLDINGCURRENTLIMIT:
					buf.setUint16(0, bp.getNumber(0) * (1 << 8));
					await ch.sendVINTDataPacket(VintPacketType.StepperPacket.SETHOLDINGCURRENTLIMIT, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETENGAGED:
					buf.setUint8(0, bp.getNumber(0) ? 0xFF : 0x00);
					await ch.sendVINTDataPacket(VintPacketType.StepperPacket.SETENGAGED, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETCONTROLMODE:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.StepperPacket.SETCONTROLMODE, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._STC1000_STEPPER_110:
			switch (bp.vpkt) {
				case BP.SETTARGETPOSITION: {
					const position = bp.getNumber(0);
					SetBigInt64(buf, 0, position);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETTARGETPOSITION, new Uint8Array(buf.buffer));
					break;
				}
				case BP.SETVELOCITYLIMIT:
					buf.setUint32(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETVELOCITYLIMIT, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETACCELERATION:
					buf.setUint32(0, bp.getNumber(0) * (1 << 7));
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETACCELERATION, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETCURRENTLIMIT:
					buf.setUint16(0, bp.getNumber(0) * (1 << 8));
					await ch.sendVINTDataPacket(VintPacketType.StepperPacket.SETCURRENTLIMIT, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETHOLDINGCURRENTLIMIT:
					buf.setUint16(0, bp.getNumber(0) * (1 << 8));
					await ch.sendVINTDataPacket(VintPacketType.StepperPacket.SETHOLDINGCURRENTLIMIT, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETENGAGED:
					buf.setUint8(0, bp.getNumber(0) ? 0xFF : 0x00);
					await ch.sendVINTDataPacket(VintPacketType.StepperPacket.SETENGAGED, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETCONTROLMODE:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.StepperPacket.SETCONTROLMODE, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETFAILSAFETIME:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_TIME, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.FAILSAFERESET:
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_RESET);
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvSTC1000(ch: LocalChannel, buf: DataView) {
	let motorPosition;
	let velocity;
	let stopped;
	let moving;

	const pkt = buf.getUint8(0);

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const userphid = (ch.userphid! as unknown as UserPhidgetClass.Stepper);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._STC1000_STEPPER_100:
			switch (pkt) {
				case VintPacketType.StepperPacket.STATUSUPDATE: {
					// Position in Q32.0 format (units are steps)
					motorPosition = GetBigInt64(buf, 1);
					// Velocity in Q32.0 formate (units are steps/sec)
					velocity = buf.getInt32(9) / 256;
					//Stopped
					stopped = buf.getUint8(13) ? 1 : 0;
					let bp = new BridgePacket();
					bp.set({ name: '0', type: 'l', value: motorPosition });
					bp.sendToChannel(ch, BP.POSITIONCHANGE);

					bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: velocity });
					bp.sendToChannel(ch, BP.VELOCITYCHANGE);

					moving = userphid.data.isMoving;
					if (stopped == moving) {
						bp = new BridgePacket();
						bp.sendToChannel(ch, BP.STOPPED);
					}
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._STC1000_STEPPER_110:
			switch (pkt) {
				case VintPacketType.StepperPacket.STATUSUPDATE: {
					// Position in Q32.0 format (units are steps)
					motorPosition = GetBigInt64(buf, 1);
					// Velocity in Q32.0 formate (units are steps/sec)
					velocity = buf.getInt32(9) / 256;
					//Stopped
					stopped = buf.getUint8(13) ? 1 : 0;
					let bp = new BridgePacket();
					bp.set({ name: '0', type: 'l', value: motorPosition });
					bp.sendToChannel(ch, BP.POSITIONCHANGE);

					bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: velocity });
					bp.sendToChannel(ch, BP.VELOCITYCHANGE);

					moving = userphid.data.isMoving;
					if (stopped == moving) {
						bp = new BridgePacket();
						bp.sendToChannel(ch, BP.STOPPED);
					}
					break;
				}
				case VintPacketType.DCMotorPacket.FAILSAFE: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.FAILSAFE_CONDITION });
					bp.set({ name: '1', type: 's', value: 'Failsafe procedure initiated.' });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendSTC1000_Failsafe(ch: LocalChannel, bp: BridgePacket) {
	await sendSTC1000(ch, bp);
}

export function recvSTC1000_Failsafe(ch: LocalChannel, buf: DataView) {
	recvSTC1000(ch, buf);
}

export async function sendSTC1001(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(8));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._STC1001_STEPPER_100:
			switch (bp.vpkt) {
				case BP.SETTARGETPOSITION: {
					const position = bp.getNumber(0);
					SetBigInt64(buf, 0, position);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETTARGETPOSITION, new Uint8Array(buf.buffer));
					break;
				}
				case BP.SETVELOCITYLIMIT:
					buf.setUint32(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETVELOCITYLIMIT, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETACCELERATION:
					buf.setUint32(0, bp.getNumber(0) * (1 << 7));
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETACCELERATION, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETCURRENTLIMIT:
					buf.setUint16(0, bp.getNumber(0) * (1 << 8));
					await ch.sendVINTDataPacket(VintPacketType.StepperPacket.SETCURRENTLIMIT, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETHOLDINGCURRENTLIMIT:
					buf.setUint16(0, bp.getNumber(0) * (1 << 8));
					await ch.sendVINTDataPacket(VintPacketType.StepperPacket.SETHOLDINGCURRENTLIMIT, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETENGAGED:
					buf.setUint8(0, bp.getNumber(0) ? 0xFF : 0x00);
					await ch.sendVINTDataPacket(VintPacketType.StepperPacket.SETENGAGED, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETCONTROLMODE:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.StepperPacket.SETCONTROLMODE, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._STC1001_STEPPER_110:
			switch (bp.vpkt) {
				case BP.SETTARGETPOSITION: {
					const position = bp.getNumber(0);
					SetBigInt64(buf, 0, position);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETTARGETPOSITION, new Uint8Array(buf.buffer));
					break;
				}
				case BP.SETVELOCITYLIMIT:
					buf.setUint32(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETVELOCITYLIMIT, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETACCELERATION:
					buf.setUint32(0, bp.getNumber(0) * (1 << 7));
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETACCELERATION, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETCURRENTLIMIT:
					buf.setUint16(0, bp.getNumber(0) * (1 << 8));
					await ch.sendVINTDataPacket(VintPacketType.StepperPacket.SETCURRENTLIMIT, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETHOLDINGCURRENTLIMIT:
					buf.setUint16(0, bp.getNumber(0) * (1 << 8));
					await ch.sendVINTDataPacket(VintPacketType.StepperPacket.SETHOLDINGCURRENTLIMIT, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETENGAGED:
					buf.setUint8(0, bp.getNumber(0) ? 0xFF : 0x00);
					await ch.sendVINTDataPacket(VintPacketType.StepperPacket.SETENGAGED, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETCONTROLMODE:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.StepperPacket.SETCONTROLMODE, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETFAILSAFETIME:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_TIME, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.FAILSAFERESET:
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_RESET);
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvSTC1001(ch: LocalChannel, buf: DataView) {
	let motorPosition;
	let velocity;
	let stopped;
	let moving;

	const pkt = buf.getUint8(0);

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const userphid = (ch.userphid! as unknown as UserPhidgetClass.Stepper);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._STC1001_STEPPER_100:
			switch (pkt) {
				case VintPacketType.StepperPacket.STATUSUPDATE: {
					// Position in Q32.0 format (units are steps)
					motorPosition = GetBigInt64(buf, 1);
					// Velocity in Q32.0 formate (units are steps/sec)
					velocity = buf.getInt32(9) / 256;
					//Stopped
					stopped = buf.getUint8(13) ? 1 : 0;

					let bp = new BridgePacket();
					bp.set({ name: '0', type: 'l', value: motorPosition });
					bp.sendToChannel(ch, BP.POSITIONCHANGE);

					bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: velocity });
					bp.sendToChannel(ch, BP.VELOCITYCHANGE);

					moving = userphid.data.isMoving;
					if (stopped == moving) {
						bp = new BridgePacket();
						bp.sendToChannel(ch, BP.STOPPED);
					}
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._STC1001_STEPPER_110:
			switch (pkt) {
				case VintPacketType.StepperPacket.STATUSUPDATE: {
					// Position in Q32.0 format (units are steps)
					motorPosition = GetBigInt64(buf, 1);
					// Velocity in Q32.0 formate (units are steps/sec)
					velocity = buf.getInt32(9) / 256;
					//Stopped
					stopped = buf.getUint8(13) ? 1 : 0;

					let bp = new BridgePacket();
					bp.set({ name: '0', type: 'l', value: motorPosition });
					bp.sendToChannel(ch, BP.POSITIONCHANGE);

					bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: velocity });
					bp.sendToChannel(ch, BP.VELOCITYCHANGE);

					moving = userphid.data.isMoving;
					if (stopped == moving) {
						bp = new BridgePacket();
						bp.sendToChannel(ch, BP.STOPPED);
					}
					break;
				}
				case VintPacketType.DCMotorPacket.FAILSAFE: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.FAILSAFE_CONDITION });
					bp.set({ name: '1', type: 's', value: 'Failsafe procedure initiated.' });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendSTC1001_Failsafe(ch: LocalChannel, bp: BridgePacket) {
	await sendSTC1001(ch, bp);
}

export function recvSTC1001_Failsafe(ch: LocalChannel, buf: DataView) {
	recvSTC1001(ch, buf);
}

export async function sendSTC1002(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(8));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._STC1002_STEPPER_100:
			switch (bp.vpkt) {
				case BP.SETTARGETPOSITION: {
					const position = bp.getNumber(0);
					SetBigInt64(buf, 0, position);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETTARGETPOSITION, new Uint8Array(buf.buffer));
					break;
				}
				case BP.SETVELOCITYLIMIT:
					buf.setUint32(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETVELOCITYLIMIT, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETACCELERATION:
					buf.setUint32(0, bp.getNumber(0) * (1 << 7));
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETACCELERATION, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETCURRENTLIMIT:
					buf.setUint16(0, bp.getNumber(0) * (1 << 8));
					await ch.sendVINTDataPacket(VintPacketType.StepperPacket.SETCURRENTLIMIT, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETHOLDINGCURRENTLIMIT:
					buf.setUint16(0, bp.getNumber(0) * (1 << 8));
					await ch.sendVINTDataPacket(VintPacketType.StepperPacket.SETHOLDINGCURRENTLIMIT, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETENGAGED:
					buf.setUint8(0, bp.getNumber(0) ? 0xFF : 0x00);
					await ch.sendVINTDataPacket(VintPacketType.StepperPacket.SETENGAGED, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETCONTROLMODE:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.StepperPacket.SETCONTROLMODE, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._STC1002_STEPPER_110:
			switch (bp.vpkt) {
				case BP.SETTARGETPOSITION: {
					const position = bp.getNumber(0);
					SetBigInt64(buf, 0, position);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETTARGETPOSITION, new Uint8Array(buf.buffer));
					break;
				}
				case BP.SETVELOCITYLIMIT:
					buf.setUint32(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETVELOCITYLIMIT, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETACCELERATION:
					buf.setUint32(0, bp.getNumber(0) * (1 << 7));
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETACCELERATION, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETCURRENTLIMIT:
					buf.setUint16(0, bp.getNumber(0) * (1 << 8));
					await ch.sendVINTDataPacket(VintPacketType.StepperPacket.SETCURRENTLIMIT, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETHOLDINGCURRENTLIMIT:
					buf.setUint16(0, bp.getNumber(0) * (1 << 8));
					await ch.sendVINTDataPacket(VintPacketType.StepperPacket.SETHOLDINGCURRENTLIMIT, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETENGAGED:
					buf.setUint8(0, bp.getNumber(0) ? 0xFF : 0x00);
					await ch.sendVINTDataPacket(VintPacketType.StepperPacket.SETENGAGED, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETCONTROLMODE:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.StepperPacket.SETCONTROLMODE, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETFAILSAFETIME:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_TIME, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.FAILSAFERESET:
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_RESET);
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvSTC1002(ch: LocalChannel, buf: DataView) {
	let motorPosition;
	let velocity;
	let stopped;
	let moving;

	const pkt = buf.getUint8(0);

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const userphid = (ch.userphid! as unknown as UserPhidgetClass.Stepper);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._STC1002_STEPPER_100:
			switch (pkt) {
				case VintPacketType.StepperPacket.STATUSUPDATE: {
					// Position in Q32.0 format (units are steps)
					motorPosition = GetBigInt64(buf, 1);
					// Velocity in Q32.0 formate (units are steps/sec)
					velocity = buf.getInt32(9) / 256;
					//Stopped
					stopped = buf.getUint8(13) ? 1 : 0;

					let bp = new BridgePacket();
					bp.set({ name: '0', type: 'l', value: motorPosition });
					bp.sendToChannel(ch, BP.POSITIONCHANGE);

					bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: velocity });
					bp.sendToChannel(ch, BP.VELOCITYCHANGE);

					moving = userphid.data.isMoving;
					if (stopped == moving) {
						bp = new BridgePacket();
						bp.sendToChannel(ch, BP.STOPPED);
					}
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._STC1002_STEPPER_110:
			switch (pkt) {
				case VintPacketType.StepperPacket.STATUSUPDATE: {
					// Position in Q32.0 format (units are steps)
					motorPosition = GetBigInt64(buf, 1);
					// Velocity in Q32.0 formate (units are steps/sec)
					velocity = buf.getInt32(9) / 256;
					//Stopped
					stopped = buf.getUint8(13) ? 1 : 0;

					let bp = new BridgePacket();
					bp.set({ name: '0', type: 'l', value: motorPosition });
					bp.sendToChannel(ch, BP.POSITIONCHANGE);

					bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: velocity });
					bp.sendToChannel(ch, BP.VELOCITYCHANGE);

					moving = userphid.data.isMoving;
					if (stopped == moving) {
						bp = new BridgePacket();
						bp.sendToChannel(ch, BP.STOPPED);
					}
					break;
				}
				case VintPacketType.DCMotorPacket.FAILSAFE: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.FAILSAFE_CONDITION });
					bp.set({ name: '1', type: 's', value: 'Failsafe procedure initiated.' });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendSTC1002_Failsafe(ch: LocalChannel, bp: BridgePacket) {
	await sendSTC1002(ch, bp);
}

export function recvSTC1002_Failsafe(ch: LocalChannel, buf: DataView) {
	recvSTC1002(ch, buf);
}

export async function sendSTC1003(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(8));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._STC1003_STEPPER_100:
			switch (bp.vpkt) {
				case BP.SETTARGETPOSITION: {
					const position = bp.getNumber(0);
					SetBigInt64(buf, 0, position);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETTARGETPOSITION, new Uint8Array(buf.buffer));
					break;
				}
				case BP.SETVELOCITYLIMIT:
					buf.setUint32(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETVELOCITYLIMIT, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETACCELERATION:
					buf.setUint32(0, bp.getNumber(0) * (1 << 7));
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETACCELERATION, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETCURRENTLIMIT:
					buf.setUint16(0, bp.getNumber(0) * (1 << 8));
					await ch.sendVINTDataPacket(VintPacketType.StepperPacket.SETCURRENTLIMIT, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETHOLDINGCURRENTLIMIT:
					buf.setUint16(0, bp.getNumber(0) * (1 << 8));
					await ch.sendVINTDataPacket(VintPacketType.StepperPacket.SETHOLDINGCURRENTLIMIT, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETENGAGED:
					buf.setUint8(0, bp.getNumber(0) ? 0xFF : 0x00);
					await ch.sendVINTDataPacket(VintPacketType.StepperPacket.SETENGAGED, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETCONTROLMODE:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.StepperPacket.SETCONTROLMODE, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._STC1003_STEPPER_110:
			switch (bp.vpkt) {
				case BP.SETTARGETPOSITION: {
					const position = bp.getNumber(0);
					SetBigInt64(buf, 0, position);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETTARGETPOSITION, new Uint8Array(buf.buffer));
					break;
				}
				case BP.SETVELOCITYLIMIT:
					buf.setUint32(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETVELOCITYLIMIT, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETACCELERATION:
					buf.setUint32(0, bp.getNumber(0) * (1 << 7));
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETACCELERATION, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETCURRENTLIMIT:
					buf.setUint16(0, bp.getNumber(0) * (1 << 8));
					await ch.sendVINTDataPacket(VintPacketType.StepperPacket.SETCURRENTLIMIT, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETHOLDINGCURRENTLIMIT:
					buf.setUint16(0, bp.getNumber(0) * (1 << 8));
					await ch.sendVINTDataPacket(VintPacketType.StepperPacket.SETHOLDINGCURRENTLIMIT, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETENGAGED:
					buf.setUint8(0, bp.getNumber(0) ? 0xFF : 0x00);
					await ch.sendVINTDataPacket(VintPacketType.StepperPacket.SETENGAGED, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETCONTROLMODE:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.StepperPacket.SETCONTROLMODE, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETFAILSAFETIME:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_TIME, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.FAILSAFERESET:
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_RESET);
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvSTC1003(ch: LocalChannel, buf: DataView) {
	let motorPosition;
	let velocity;
	let stopped;
	let moving;

	const pkt = buf.getUint8(0);

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const userphid = (ch.userphid! as unknown as UserPhidgetClass.Stepper);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._STC1003_STEPPER_100:
			switch (pkt) {
				case VintPacketType.StepperPacket.STATUSUPDATE: {
					// Position in Q32.0 format (units are steps)
					motorPosition = GetBigInt64(buf, 1);
					// Velocity in Q32.0 formate (units are steps/sec)
					velocity = buf.getInt32(9) / 256;
					//Stopped
					stopped = buf.getUint8(13) ? 1 : 0;

					let bp = new BridgePacket();
					bp.set({ name: '0', type: 'l', value: motorPosition });
					bp.sendToChannel(ch, BP.POSITIONCHANGE);

					bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: velocity });
					bp.sendToChannel(ch, BP.VELOCITYCHANGE);

					moving = userphid.data.isMoving;
					if (stopped == moving) {
						bp = new BridgePacket();
						bp.sendToChannel(ch, BP.STOPPED);
					}
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._STC1003_STEPPER_110:
			switch (pkt) {
				case VintPacketType.StepperPacket.STATUSUPDATE: {
					// Position in Q32.0 format (units are steps)
					motorPosition = GetBigInt64(buf, 1);
					// Velocity in Q32.0 formate (units are steps/sec)
					velocity = buf.getInt32(9) / 256;
					//Stopped
					stopped = buf.getUint8(13) ? 1 : 0;

					let bp = new BridgePacket();
					bp.set({ name: '0', type: 'l', value: motorPosition });
					bp.sendToChannel(ch, BP.POSITIONCHANGE);

					bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: velocity });
					bp.sendToChannel(ch, BP.VELOCITYCHANGE);

					moving = userphid.data.isMoving;
					if (stopped && (stopped == moving)) {
						bp = new BridgePacket();
						bp.sendToChannel(ch, BP.STOPPED);
					}
					break;
				}
				case VintPacketType.DCMotorPacket.FAILSAFE: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.FAILSAFE_CONDITION });
					bp.set({ name: '1', type: 's', value: 'Failsafe procedure initiated.' });
					ch.sendErrorEvent(bp);
					break;
				}
				case VintPacketType.StepperPacket.POWER_ERROR: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.BAD_POWER });
					bp.set({ name: '1', type: 's', value: 'Overcurrent error detected. Device has been reset.' });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendSTC1003_Failsafe(ch: LocalChannel, bp: BridgePacket) {
	await sendSTC1003(ch, bp);
}

export function recvSTC1003_Failsafe(ch: LocalChannel, buf: DataView) {
	recvSTC1003(ch, buf);
}

export async function sendSTC1003_1(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(8));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._STC1003_STEPPER_200:
		case DeviceChannelUID._STC1005_STEPPER_100:
		case DeviceChannelUID._STC1005_STEPPER_110:
			switch (bp.vpkt) {
				case BP.SETTARGETPOSITION: {
					const position = bp.getNumber(0);
					SetBigInt64(buf, 0, position);
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETTARGETPOSITION, new Uint8Array(buf.buffer));
					break;
				}
				case BP.SETVELOCITYLIMIT:
					buf.setUint32(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETVELOCITYLIMIT, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETACCELERATION:
					buf.setUint32(0, bp.getNumber(0) * (1 << 7));
					await ch.sendVINTDataPacket(VintPacketType.DCMotorPacket.SETACCELERATION, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETCURRENTLIMIT:
					buf.setUint16(0, bp.getNumber(0) * (1 << 8));
					await ch.sendVINTDataPacket(VintPacketType.StepperPacket.SETCURRENTLIMIT, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETHOLDINGCURRENTLIMIT:
					buf.setUint16(0, bp.getNumber(0) * (1 << 8));
					await ch.sendVINTDataPacket(VintPacketType.StepperPacket.SETHOLDINGCURRENTLIMIT, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETENGAGED:
					buf.setUint8(0, bp.getNumber(0) ? 0xFF : 0x00);
					await ch.sendVINTDataPacket(VintPacketType.StepperPacket.SETENGAGED, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETCONTROLMODE:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.StepperPacket.SETCONTROLMODE, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETDATAINTERVAL: {
					const dataInterval = ch.parent.parent?._handleDataIntervalPacket(bp, 1);
					if (dataInterval)
						buf.setUint16(0, dataInterval);
					else
						throw new PhidgetError(ErrorCode.UNEXPECTED);
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
				}
					break;
				case BP.SETFAILSAFETIME:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_TIME, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.FAILSAFERESET:
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.FAILSAFE_RESET);
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._STC1003_VOLTAGEINPUT_200:
		case DeviceChannelUID._STC1005_VOLTAGEINPUT_100:
		case DeviceChannelUID._STC1005_VOLTAGEINPUT_110:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL: {
					const dataInterval = ch.parent.parent?._handleDataIntervalPacket(bp, 1);
					if (dataInterval)
						buf.setUint16(0, dataInterval);
					else
						throw new PhidgetError(ErrorCode.UNEXPECTED);
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
				}
					break;
				case BP.SETCHANGETRIGGER:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.VoltageInputPacket.SETVOLTAGECHANGETRIGGER, new Uint8Array(buf.buffer, 0, 4));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvSTC1003_1(ch: LocalChannel, buf: DataView) {
	let motorPosition;
	let velocity;
	let voltage;
	let stopped;
	let moving;
	let userphid;

	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._STC1003_STEPPER_200:
			switch (pkt) {
				case VintPacketType.StepperPacket.STATUSUPDATE: {
					// Position in Q32.0 format (units are steps)
					motorPosition = GetBigInt64(buf, 1);
					// Velocity in Q32.0 formate (units are steps/sec)
					velocity = buf.getInt32(9) / 256;
					//Stopped
					stopped = buf.getUint8(13) ? 1 : 0;

					let bp = new BridgePacket();
					bp.set({ name: '0', type: 'l', value: motorPosition });
					bp.sendToChannel(ch, BP.POSITIONCHANGE);

					bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: velocity });
					bp.sendToChannel(ch, BP.VELOCITYCHANGE);

					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					userphid = (ch.userphid! as unknown as UserPhidgetClass.Stepper);
					moving = userphid.data.isMoving;
					if (stopped && moving) {
						bp = new BridgePacket();
						bp.sendToChannel(ch, BP.STOPPED);
					}
					break;
				}
				case VintPacketType.DCMotorPacket.FAILSAFE: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.FAILSAFE_CONDITION });
					bp.set({ name: '1', type: 's', value: 'Failsafe procedure initiated.' });
					ch.sendErrorEvent(bp);
					break;
				}
				case VintPacketType.StepperPacket.POWER_ERROR: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.BAD_POWER });
					if (buf.getUint8(1)) {
						bp.set({ name: '1', type: 's', value: 'Supply Voltage above expected range.' });
					} else {
						bp.set({ name: '1', type: 's', value: 'Supply Voltage below expected range.  Possible overcurrent condition.' });
					}
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._STC1003_VOLTAGEINPUT_200:
			switch (pkt) {
				case VintPacketType.VoltageInputPacket.VOLTAGECHANGE: {
					voltage = buf.getFloat32(1, true);
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: RoundDouble(voltage, 7) });
					bp.sendToChannel(ch, BP.VOLTAGECHANGE);
					break;
				}
				case VintPacketType.VoltageInputPacket.SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.SATURATION });
					bp.set({ name: '1', type: 's', value: 'Saturation Detected.' });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

// eslint-disable-next-line require-await
export async function sendSTC1004_0(ch: LocalChannel, bp: BridgePacket) {
	throw new Error("Not Implemented");

	// eslint-disable-next-line no-unreachable
	switch (ch.chDef.uid) {
		case DeviceChannelUID._STC1004_STEPPER_100:
			switch (bp.vpkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvSTC1004_0(ch: LocalChannel, buf: DataView) {
	throw new Error("Not Implemented");

	// eslint-disable-next-line no-unreachable
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._STC1004_STEPPER_100:
			switch (pkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

// eslint-disable-next-line require-await
export async function sendSTC1005_0(ch: LocalChannel, bp: BridgePacket) {
	await sendSTC1003_1(ch, bp);
}

export function recvSTC1005_0(ch: LocalChannel, buf: DataView) {
	let motorPosition;
	let velocity;
	let voltage;
	let stopped;
	let moving;
	let userphid;

	// eslint-disable-next-line no-unreachable
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._STC1005_STEPPER_100:
		case DeviceChannelUID._STC1005_STEPPER_110:
			switch (pkt) {
				case VintPacketType.StepperPacket.STATUSUPDATE: {
					// Position in Q32.0 format (units are steps)
					motorPosition = GetBigInt64(buf, 1);
					// Velocity in Q32.0 format (units are steps/sec)
					velocity = buf.getInt32(9) / 256;
					//Stopped
					stopped = buf.getUint8(13) ? 1 : 0;

					let bp = new BridgePacket();
					bp.set({ name: '0', type: 'l', value: motorPosition });
					bp.sendToChannel(ch, BP.POSITIONCHANGE);

					bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: velocity });
					bp.sendToChannel(ch, BP.VELOCITYCHANGE);

					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					userphid = (ch.userphid! as unknown as UserPhidgetClass.Stepper);
					moving = userphid.data.isMoving;
					if (stopped && moving) {
						bp = new BridgePacket();
						bp.sendToChannel(ch, BP.STOPPED);
					}
					break;
				}
				case VintPacketType.DCMotorPacket.FAILSAFE: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.FAILSAFE_CONDITION });
					bp.set({ name: '1', type: 's', value: 'Failsafe procedure initiated.' });
					ch.sendErrorEvent(bp);
					break;
				}
				case VintPacketType.StepperPacket.POWER_ERROR: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.BAD_POWER });
					if (buf.getUint8(1)) {
						bp.set({ name: '1', type: 's', value: 'Supply Voltage above expected range.' });
					} else {
						bp.set({ name: '1', type: 's', value: 'Supply Voltage below expected range.  Possible overcurrent condition.' });
					}
					ch.sendErrorEvent(bp);
					break;
				}
				case VintPacketType.StepperPacket.FAULT_ERROR: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.FAULT });
					bp.set({ name: '1', type: 's', value: 'Fault condition detected. Motor is disengaged and position is lost. Re-engage and re-initialize your system.' });
					ch.sendErrorEvent(bp);
					break;
				}
				case VintPacketType.StepperPacket.OVERTEMPERATURE: {
					let bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.FAULT });
					bp.set({ name: '1', type: 's', value: 'Fault condition detected. Motor is disengaged and position is lost. Re-engage and re-initialize your system.' });
					ch.sendErrorEvent(bp);

					bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.OVER_TEMPERATURE });
					bp.set({ name: '1', type: 's', value: 'Overtemperature Condition Detected.' });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._STC1005_VOLTAGEINPUT_100:
		case DeviceChannelUID._STC1005_VOLTAGEINPUT_110:
			switch (pkt) {
				case VintPacketType.VoltageInputPacket.VOLTAGECHANGE: {
					voltage = buf.getFloat32(1, true);
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: RoundDouble(voltage, 7) });
					bp.sendToChannel(ch, BP.VOLTAGECHANGE);
					break;
				}
				case VintPacketType.VoltageInputPacket.SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.SATURATION });
					bp.set({ name: '1', type: 's', value: 'Saturation Detected.' });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendTMP1000(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._TMP1000_TEMPERATURESENSOR_IC_100:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setUint32(0, bp.getNumber(0) * (1 << 16));
					await ch.sendVINTDataPacket(VintPacketType.TemperatureSensorPacket.SETTEMPERATURECHANGETRIGGER, new Uint8Array(buf.buffer, 0, 4));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendSTC1005_0_110(ch: LocalChannel, bp: BridgePacket) {
	await sendSTC1005_0(ch, bp);
}
export function recvSTC1005_0_110(ch: LocalChannel, buf: DataView) {
	recvSTC1005_0(ch, buf);
}

export function recvTMP1000(ch: LocalChannel, buf: DataView) {
	let temperature;

	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._TMP1000_TEMPERATURESENSOR_IC_100:
			switch (pkt) {
				case VintPacketType.TemperatureSensorPacket.TEMPERATURECHANGE: {
					temperature = buf.getInt32(1) / (1 << 16);
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: RoundDouble(temperature, 2) });
					bp.sendToChannel(ch, BP.TEMPERATURECHANGE);
					break;
				}
				case VintPacketType.TemperatureSensorPacket.SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.SATURATION });
					bp.set({ name: '1', type: 's', value: 'Saturation Detected.' });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendTMP1100(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._TMP1100_VOLTAGEINPUT_100:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.VoltageInputPacket.SETVOLTAGECHANGETRIGGER, new Uint8Array(buf.buffer));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._TMP1100_TEMPERATURESENSOR_IC_100:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.TemperatureSensorPacket.SETTEMPERATURECHANGETRIGGER, new Uint8Array(buf.buffer));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._TMP1100_TEMPERATURESENSOR_THERMOCOUPLE_100:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.TemperatureSensorPacket.SETTEMPERATURECHANGETRIGGER, new Uint8Array(buf.buffer));
					break;
				case BP.SETTHERMOCOUPLETYPE:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.TemperatureSensorPacket.THERMOCOUPLETYPE, new Uint8Array(buf.buffer, 0, 1));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvTMP1100(ch: LocalChannel, buf: DataView) {
	let temperature;
	let voltage;

	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._TMP1100_TEMPERATURESENSOR_IC_100:
		case DeviceChannelUID._TMP1100_TEMPERATURESENSOR_THERMOCOUPLE_100:
			switch (pkt) {
				case VintPacketType.TemperatureSensorPacket.TEMPERATURECHANGE: {
					temperature = buf.getFloat32(1, true);
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "g", value: RoundDouble(temperature, 3) });
					bp.sendToChannel(ch, BP.TEMPERATURECHANGE);
					break;
				}
				case VintPacketType.TemperatureSensorPacket.SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.SATURATION });
					bp.set({ name: "1", type: "s", value: "Saturation Detected." });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._TMP1100_VOLTAGEINPUT_100:
			switch (pkt) {
				case VintPacketType.VoltageInputPacket.VOLTAGECHANGE: {
					voltage = buf.getFloat32(1, true);
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "g", value: RoundDouble(voltage, 6) });
					bp.sendToChannel(ch, BP.VOLTAGECHANGE);
					break;
				}
				case VintPacketType.VoltageInputPacket.SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.SATURATION });
					bp.set({ name: "1", type: "s", value: "Saturation Detected." });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendTMP1101(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._TMP1101_TEMPERATURESENSOR_THERMOCOUPLE_100:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.TemperatureSensorPacket.SETTEMPERATURECHANGETRIGGER, new Uint8Array(buf.buffer));
					break;
				case BP.SETTHERMOCOUPLETYPE:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.TemperatureSensorPacket.THERMOCOUPLETYPE, new Uint8Array(buf.buffer, 0, 1));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._TMP1101_TEMPERATURESENSOR_IC_100:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.TemperatureSensorPacket.SETTEMPERATURECHANGETRIGGER, new Uint8Array(buf.buffer));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._TMP1101_VOLTAGEINPUT_100:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.VoltageInputPacket.SETVOLTAGECHANGETRIGGER, new Uint8Array(buf.buffer));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvTMP1101(ch: LocalChannel, buf: DataView) {
	let temperature;
	let voltage;

	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._TMP1101_TEMPERATURESENSOR_THERMOCOUPLE_100:
		case DeviceChannelUID._TMP1101_TEMPERATURESENSOR_IC_100:
			switch (pkt) {
				case VintPacketType.TemperatureSensorPacket.TEMPERATURECHANGE: {
					temperature = buf.getFloat32(1, true);
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "g", value: RoundDouble(temperature, 3) });
					bp.sendToChannel(ch, BP.TEMPERATURECHANGE);
					break;
				}
				case VintPacketType.TemperatureSensorPacket.SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.SATURATION });
					bp.set({ name: "1", type: "s", value: "Saturation Detected." });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._TMP1101_VOLTAGEINPUT_100:
			switch (pkt) {
				case VintPacketType.VoltageInputPacket.VOLTAGECHANGE: {
					voltage = buf.getFloat32(1, true);
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "g", value: RoundDouble(voltage, 6) });
					bp.sendToChannel(ch, BP.VOLTAGECHANGE);
					break;
				}
				case VintPacketType.VoltageInputPacket.SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.SATURATION });
					bp.set({ name: "1", type: "s", value: "Saturation Detected." });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendTMP1101_1(ch: LocalChannel, bp: BridgePacket) {

	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._TMP1101_TEMPERATURESENSOR_THERMOCOUPLE_200:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.TemperatureSensorPacket.SETTEMPERATURECHANGETRIGGER, new Uint8Array(buf.buffer));
					break;
				case BP.SETTHERMOCOUPLETYPE:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.TemperatureSensorPacket.THERMOCOUPLETYPE, new Uint8Array(buf.buffer, 0, 1));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._TMP1101_TEMPERATURESENSOR_IC_200:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.TemperatureSensorPacket.SETTEMPERATURECHANGETRIGGER, new Uint8Array(buf.buffer));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._TMP1101_VOLTAGEINPUT_200:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.VoltageInputPacket.SETVOLTAGECHANGETRIGGER, new Uint8Array(buf.buffer));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvTMP1101_1(ch: LocalChannel, buf: DataView) {
	let temperature;
	let voltage;

	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._TMP1101_TEMPERATURESENSOR_THERMOCOUPLE_200:
			switch (pkt) {
				case VintPacketType.TemperatureSensorPacket.TEMPERATURECHANGE: {
					temperature = buf.getFloat32(1, true);
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "g", value: RoundDouble(temperature, 3) });
					bp.sendToChannel(ch, BP.TEMPERATURECHANGE);
					break;
				}
				case VintPacketType.TemperatureSensorPacket.SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.SATURATION });
					bp.set({ name: "1", type: "s", value: "Saturation Detected." });
					ch.sendErrorEvent(bp);
					break;
				}
				case VintPacketType.TemperatureSensorPacket.BADCONNECTION: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.BAD_CONNECTION_CONDITION });
					bp.set({ name: "1", type: "s", value: "Bad Connection" });
					//ch.sendErrorEvent(bp);
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._TMP1101_TEMPERATURESENSOR_IC_200:
			switch (pkt) {
				case VintPacketType.TemperatureSensorPacket.TEMPERATURECHANGE: {
					temperature = buf.getFloat32(1, true);
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "g", value: RoundDouble(temperature, 3) });
					bp.sendToChannel(ch, BP.TEMPERATURECHANGE);
					break;
				}
				case VintPacketType.TemperatureSensorPacket.SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.SATURATION });
					bp.set({ name: "1", type: "s", value: "Saturation Detected." });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._TMP1101_VOLTAGEINPUT_200:
			switch (pkt) {
				case VintPacketType.VoltageInputPacket.VOLTAGECHANGE: {
					voltage = buf.getFloat32(1, true);
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "g", value: RoundDouble(voltage, 6) });
					bp.sendToChannel(ch, BP.VOLTAGECHANGE);
					break;
				}
				case VintPacketType.VoltageInputPacket.SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.SATURATION });
					bp.set({ name: "1", type: "s", value: "Saturation Detected." });
					ch.sendErrorEvent(bp);
					break;
				}
				case VintPacketType.VoltageInputPacket.BADCONNECTION: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.BAD_CONNECTION_CONDITION });
					bp.set({ name: "1", type: "s", value: "Bad Connection" });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendTMP1200(ch: LocalChannel, bp: BridgePacket) {

	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._TMP1200_TEMPERATURESENSOR_RTD_100:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.TemperatureSensorPacket.SETTEMPERATURECHANGETRIGGER, new Uint8Array(buf.buffer));
					break;
				case BP.SETRTDWIRESETUP:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.RTD_WIRESETUP, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETRTDTYPE:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.TemperatureSensorPacket.RTDTYPE, new Uint8Array(buf.buffer, 0, 1));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._TMP1200_RESISTANCEINPUT_100:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.ResistanceInputPacket.SETRESISTANCECHANGETRIGGER, new Uint8Array(buf.buffer));
					break;
				case BP.SETRTDWIRESETUP:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.RTD_WIRESETUP, new Uint8Array(buf.buffer, 0, 1));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvTMP1200(ch: LocalChannel, buf: DataView) {
	let temperature, resistance;
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._TMP1200_TEMPERATURESENSOR_RTD_100:
			switch (pkt) {
				case VintPacketType.TemperatureSensorPacket.TEMPERATURECHANGE: {
					temperature = buf.getFloat32(1, true);
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "g", value: RoundDouble(temperature, 3) });
					bp.sendToChannel(ch, BP.TEMPERATURECHANGE);
					break;
				}
				case VintPacketType.TemperatureSensorPacket.SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.SATURATION });
					bp.set({ name: "1", type: "s", value: "Saturation Detected." });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._TMP1200_RESISTANCEINPUT_100:
			switch (pkt) {
				case VintPacketType.ResistanceInputPacket.RESISTANCECHANGE: {
					resistance = buf.getFloat32(1, true);
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "g", value: RoundDouble(resistance, 3) });
					bp.sendToChannel(ch, BP.RESISTANCECHANGE);
					break;
				}
				case VintPacketType.ResistanceInputPacket.SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.SATURATION });
					bp.set({ name: "1", type: "s", value: "Saturation Detected." });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendTMP1200_1(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._TMP1200_TEMPERATURESENSOR_RTD_300:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL: {
					const dataInterval = ch.parent.parent?._handleDataIntervalPacket(bp, 1);
					if (dataInterval)
						buf.setUint16(0, dataInterval);
					else
						throw new PhidgetError(ErrorCode.UNEXPECTED);
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
				}
					break;
				case BP.SETCHANGETRIGGER:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.TemperatureSensorPacket.SETTEMPERATURECHANGETRIGGER, new Uint8Array(buf.buffer));
					break;
				case BP.SETRTDWIRESETUP:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.RTD_WIRESETUP, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETRTDTYPE:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.TemperatureSensorPacket.RTDTYPE, new Uint8Array(buf.buffer, 0, 1));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._TMP1200_RESISTANCEINPUT_300:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL: {
					const dataInterval = ch.parent.parent?._handleDataIntervalPacket(bp, 1);
					if (dataInterval)
						buf.setUint16(0, dataInterval);
					else
						throw new PhidgetError(ErrorCode.UNEXPECTED);
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
				}
					break;
				case BP.SETCHANGETRIGGER:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.ResistanceInputPacket.SETRESISTANCECHANGETRIGGER, new Uint8Array(buf.buffer));
					break;
				case BP.SETRTDWIRESETUP:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.RTD_WIRESETUP, new Uint8Array(buf.buffer, 0, 1));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvTMP1200_1(ch: LocalChannel, buf: DataView) {
	let temperature, resistance;
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._TMP1200_TEMPERATURESENSOR_RTD_300:
			switch (pkt) {
				case VintPacketType.TemperatureSensorPacket.TEMPERATURECHANGE: {
					temperature = buf.getFloat32(1, true);
					if (buf.byteLength > 5) {
						if (temperature > 0) {
							const bp = new BridgePacket();
							bp.set({ name: "0", type: "d", value: ErrorEventCode.OUT_OF_RANGE_HIGH_CONDITION });
							bp.set({ name: "1", type: "s", value: "Temperature is too high to be accurately measured." });
							ch.sendErrorEvent(bp);
						} else {
							const bp = new BridgePacket();
							bp.set({ name: "0", type: "d", value: ErrorEventCode.OUT_OF_RANGE_LOW_CONDITION });
							bp.set({ name: "1", type: "s", value: "Temperature is too low to be accurately measured." });
							ch.sendErrorEvent(bp);
						}
					}
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "g", value: RoundDouble(temperature, 3) });
					bp.sendToChannel(ch, BP.TEMPERATURECHANGE);
					break;
				}
				case VintPacketType.TemperatureSensorPacket.SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.OUT_OF_RANGE });
					bp.set({ name: "1", type: "s", value: "Temperature is unknown." });
					ch.sendErrorEvent(bp);
					break;
				}
				case VintPacketType.TemperatureSensorPacket.BADCONNECTION: {
					if (buf.getUint8(1)) {
						const bp = new BridgePacket();
						bp.set({ name: "0", type: "d", value: ErrorEventCode.BAD_CONNECTION_CONDITION });
						bp.set({ name: "1", type: "s", value: "Bad Connection: Check polarity of connection." });
						ch.sendErrorEvent(bp);
					} else {
						const bp = new BridgePacket();
						bp.set({ name: "0", type: "d", value: ErrorEventCode.BAD_CONNECTION_CONDITION });
						bp.set({ name: "1", type: "s", value: "Bad Connection: RTD is likely disconnected." });
						ch.sendErrorEvent(bp);
					}
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._TMP1200_RESISTANCEINPUT_300:
			switch (pkt) {
				case VintPacketType.ResistanceInputPacket.RESISTANCECHANGE: {
					resistance = buf.getFloat32(1, true);
					if (buf.byteLength > 5) {
						if (resistance > 0) {
							const bp = new BridgePacket();
							bp.set({ name: "0", type: "d", value: ErrorEventCode.OUT_OF_RANGE_HIGH_CONDITION });
							bp.set({ name: "1", type: "s", value: "Resistance is too high to be accurately measured." });
							ch.sendErrorEvent(bp);
						} else {
							const bp = new BridgePacket();
							bp.set({ name: "0", type: "d", value: ErrorEventCode.BAD_CONNECTION_CONDITION });
							bp.set({ name: "1", type: "s", value: "Bad Connection: check polarity of connection." });
							ch.sendErrorEvent(bp);
						}
					}
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "g", value: RoundDouble(resistance, 3) });
					bp.sendToChannel(ch, BP.RESISTANCECHANGE);
					break;
				}
				case VintPacketType.TemperatureSensorPacket.BADCONNECTION: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.BAD_CONNECTION_CONDITION });
					bp.set({ name: "1", type: "s", value: "Bad Connection." });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

// eslint-disable-next-line require-await
export async function sendTMP1200_2(ch: LocalChannel, bp: BridgePacket) {
	throw new Error("Not Implemented");

	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._TMP1200_TEMPERATURESENSOR_RTD_300:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL: {
					const dataInterval = ch.parent.parent?._handleDataIntervalPacket(bp, 1);
					if (dataInterval)
						buf.setUint16(0, dataInterval!);	//added non-null assertion just to remove error when error 'not implmented' is thrown
					else
						throw new PhidgetError(ErrorCode.UNEXPECTED);
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
				}
					break;
				case BP.SETCHANGETRIGGER:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.TemperatureSensorPacket.SETTEMPERATURECHANGETRIGGER, new Uint8Array(buf.buffer));
					break;
				case BP.SETRTDWIRESETUP:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.RTD_WIRESETUP, new Uint8Array(buf.buffer, 0, 1));
					break;
				case BP.SETRTDTYPE:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.TemperatureSensorPacket.RTDTYPE, new Uint8Array(buf.buffer, 0, 1));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._TMP1200_RESISTANCEINPUT_300:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL: {
					const dataInterval = ch.parent.parent?._handleDataIntervalPacket(bp, 1);
					if (dataInterval)
						buf.setUint16(0, dataInterval!);	//added non-null assertion just to remove error when error 'not implmented' is thrown
					else
						throw new PhidgetError(ErrorCode.UNEXPECTED);
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
				}
					break;
				case BP.SETCHANGETRIGGER:
					buf.setFloat32(0, bp.getNumber(0), true);
					await ch.sendVINTDataPacket(VintPacketType.ResistanceInputPacket.SETRESISTANCECHANGETRIGGER, new Uint8Array(buf.buffer));
					break;
				case BP.SETRTDWIRESETUP:
					buf.setUint8(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.RTD_WIRESETUP, new Uint8Array(buf.buffer, 0, 1));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvTMP1200_2(ch: LocalChannel, buf: DataView) {
	throw new Error("Not Implemented");

	let temperature, resistance;
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._TMP1200_TEMPERATURESENSOR_RTD_300:
			switch (pkt) {
				case VintPacketType.TemperatureSensorPacket.TEMPERATURECHANGE: {
					temperature = buf.getFloat32(1, true);
					if (buf.byteLength > 5) {
						if (temperature > 0) {
							const bp = new BridgePacket();
							bp.set({ name: "0", type: "d", value: ErrorEventCode.OUT_OF_RANGE_HIGH_CONDITION });
							bp.set({ name: "1", type: "s", value: "Temperature is too high to be accurately measured." });
							ch.sendErrorEvent(bp);
						} else {
							const bp = new BridgePacket();
							bp.set({ name: "0", type: "d", value: ErrorEventCode.OUT_OF_RANGE_LOW_CONDITION });
							bp.set({ name: "1", type: "s", value: "Temperature is too low to be accurately measured." });
							ch.sendErrorEvent(bp);
						}
					}
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "g", value: RoundDouble(temperature, 3) });
					bp.sendToChannel(ch, BP.TEMPERATURECHANGE);
					break;
				}
				case VintPacketType.TemperatureSensorPacket.SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.OUT_OF_RANGE });
					bp.set({ name: "1", type: "s", value: "Temperature is unknown." });
					ch.sendErrorEvent(bp);
					break;
				}
				case VintPacketType.TemperatureSensorPacket.BADCONNECTION: {
					if (buf.getUint8(1)) {
						const bp = new BridgePacket();
						bp.set({ name: "0", type: "d", value: ErrorEventCode.BAD_CONNECTION_CONDITION });
						bp.set({ name: "1", type: "s", value: "Bad Connection: Check polarity of connection." });
						ch.sendErrorEvent(bp);
					} else {
						const bp = new BridgePacket();
						bp.set({ name: "0", type: "d", value: ErrorEventCode.BAD_CONNECTION_CONDITION });
						bp.set({ name: "1", type: "s", value: "Bad Connection: RTD is likely disconnected." });
						ch.sendErrorEvent(bp);
					}
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._TMP1200_RESISTANCEINPUT_300:
			switch (pkt) {
				case VintPacketType.ResistanceInputPacket.RESISTANCECHANGE: {
					resistance = buf.getFloat32(1, true);
					if (buf.byteLength > 5) {
						if (resistance > 0) {
							const bp = new BridgePacket();
							bp.set({ name: "0", type: "d", value: ErrorEventCode.OUT_OF_RANGE_HIGH_CONDITION });
							bp.set({ name: "1", type: "s", value: "Resistance is too high to be accurately measured." });
							ch.sendErrorEvent(bp);
						} else {
							const bp = new BridgePacket();
							bp.set({ name: "0", type: "d", value: ErrorEventCode.BAD_CONNECTION_CONDITION });
							bp.set({ name: "1", type: "s", value: "Bad Connection: check polarity of connection." });
							ch.sendErrorEvent(bp);
						}
					}
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "g", value: RoundDouble(resistance, 3) });
					bp.sendToChannel(ch, BP.RESISTANCECHANGE);
					break;
				}
				case VintPacketType.TemperatureSensorPacket.BADCONNECTION: {
					const bp = new BridgePacket();
					bp.set({ name: "0", type: "d", value: ErrorEventCode.BAD_CONNECTION_CONDITION });
					bp.set({ name: "1", type: "s", value: "Bad Connection." });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}


// eslint-disable-next-line require-await
export async function sendTMP1300(ch: LocalChannel, bp: BridgePacket) {
	throw new Error("Not Implemented");

	// eslint-disable-next-line no-unreachable
	switch (ch.chDef.uid) {
		case DeviceChannelUID._TMP1300_TEMPERATURESENSOR_IR_100:
			switch (bp.vpkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._TMP1300_TEMPERATURESENSOR_IC_100:
			switch (bp.vpkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._TMP1300_VOLTAGEINPUT_100:
			switch (bp.vpkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvTMP1300(ch: LocalChannel, buf: DataView) {
	throw new Error("Not Implemented");

	// eslint-disable-next-line no-unreachable
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._TMP1300_TEMPERATURESENSOR_IR_100:
			switch (pkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._TMP1300_TEMPERATURESENSOR_IC_100:
			switch (pkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		case DeviceChannelUID._TMP1300_VOLTAGEINPUT_100:
			switch (pkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendVCP1000(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._VCP1000_VOLTAGEINPUT_100:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setUint32(0, bp.getNumber(0) * (1 << 24));
					await ch.sendVINTDataPacket(VintPacketType.VoltageInputPacket.SETVOLTAGECHANGETRIGGER, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETVOLTAGERANGE:
					buf.setUint8(0, bp.getNumber(0));
					switch (bp.getNumber(0)) {
						case Enum.VoltageRange.MILLIVOLTS_312_5:
						case Enum.VoltageRange.VOLTS_40:
							break;
						default:
							throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Invalid or unsupported voltage range for this device.");
					}
					await ch.sendVINTDataPacket(VintPacketType.VoltageInputPacket.VCP1000RANGE, new Uint8Array(buf.buffer, 0, 1));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvVCP1000(ch: LocalChannel, buf: DataView) {
	let voltage;

	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._VCP1000_VOLTAGEINPUT_100:
			switch (pkt) {
				case VintPacketType.VoltageInputPacket.VOLTAGECHANGE: {
					voltage = buf.getInt32(1) / (1 << 24);
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: RoundDouble(voltage, 7) });
					bp.sendToChannel(ch, BP.VOLTAGECHANGE);
					break;
				}
				case VintPacketType.VoltageInputPacket.SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.SATURATION });
					bp.set({ name: '1', type: 's', value: 'Saturation Detected.' });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendVCP1001(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._VCP1001_VOLTAGEINPUT_100:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setUint32(0, bp.getNumber(0) * (1 << 24));
					await ch.sendVINTDataPacket(VintPacketType.VoltageInputPacket.SETVOLTAGECHANGETRIGGER, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETVOLTAGERANGE:
					buf.setUint8(0, bp.getNumber(0));
					switch (bp.getNumber(0)) {
						case Enum.VoltageRange.VOLTS_5:
						case Enum.VoltageRange.VOLTS_15:
						case Enum.VoltageRange.VOLTS_40:
						case Enum.VoltageRange.AUTO:
							break;
						default:
							throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Invalid or unsupported voltage range for this device.");
					}
					await ch.sendVINTDataPacket(VintPacketType.VoltageInputPacket.VCP1001RANGE, new Uint8Array(buf.buffer, 0, 1));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvVCP1001(ch: LocalChannel, buf: DataView) {
	let voltage;
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._VCP1001_VOLTAGEINPUT_100:
			switch (pkt) {
				case VintPacketType.VoltageInputPacket.VOLTAGECHANGE: {
					voltage = buf.getInt32(1) / (1 << 24);
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: RoundDouble(voltage, 4) });
					bp.sendToChannel(ch, BP.VOLTAGECHANGE);
					break;
				}
				case VintPacketType.VoltageInputPacket.SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.SATURATION });
					bp.set({ name: '1', type: 's', value: 'Saturation Detected.' });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendVCP1002(ch: LocalChannel, bp: BridgePacket) {
	const buf = new DataView(new ArrayBuffer(4));

	switch (ch.chDef.uid) {
		case DeviceChannelUID._VCP1002_VOLTAGEINPUT_100:
			switch (bp.vpkt) {
				case BP.SETDATAINTERVAL:
					buf.setUint16(0, bp.getNumber(0));
					await ch.sendVINTDataPacket(VintPacketType.GenericPacket.SAMPLED_SETDATAINTERVAL, new Uint8Array(buf.buffer, 0, 2));
					break;
				case BP.SETCHANGETRIGGER:
					buf.setUint32(0, bp.getNumber(0) * (1 << 24));
					await ch.sendVINTDataPacket(VintPacketType.VoltageInputPacket.SETVOLTAGECHANGETRIGGER, new Uint8Array(buf.buffer, 0, 4));
					break;
				case BP.SETVOLTAGERANGE:
					buf.setUint8(0, bp.getNumber(0));
					switch (bp.getNumber(0)) {
						case Enum.VoltageRange.MILLIVOLTS_10:
						case Enum.VoltageRange.MILLIVOLTS_40:
						case Enum.VoltageRange.MILLIVOLTS_200:
						case Enum.VoltageRange.MILLIVOLTS_1000:
						case Enum.VoltageRange.AUTO:
							break;
						default:
							throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Invalid or unsupported voltage range for this device.");
					}
					await ch.sendVINTDataPacket(VintPacketType.VoltageInputPacket.VCP1002RANGE, new Uint8Array(buf.buffer, 0, 1));
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvVCP1002(ch: LocalChannel, buf: DataView) {
	let voltage;
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._VCP1002_VOLTAGEINPUT_100:
			switch (pkt) {
				case VintPacketType.VoltageInputPacket.VOLTAGECHANGE: {
					voltage = buf.getInt32(1) / (1 << 24);
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: RoundDouble(voltage, 7) });
					bp.sendToChannel(ch, BP.VOLTAGECHANGE);
					break;
				}
				case VintPacketType.VoltageInputPacket.SATURATION: {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.SATURATION });
					bp.set({ name: '1', type: 's', value: 'Saturation Detected.' });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export async function sendVCP1001_110(ch: LocalChannel, bp: BridgePacket) {
	await sendVCP1001(ch, bp);
}
export function recvVCP1001_110(ch: LocalChannel, buf: DataView) {
	return recvVCP1001(ch, buf);
}
export async function sendVCP1002_110(ch: LocalChannel, bp: BridgePacket) {
	await sendVCP1002(ch, bp);
}
export function recvVCP1002_110(ch: LocalChannel, buf: DataView) {
	return recvVCP1002(ch, buf);
}

// eslint-disable-next-line require-await
export async function sendFIRMWARE_UPGRADE_STM32F0(ch: LocalChannel, bp: BridgePacket) {
	throw new Error("Not Implemented");

	// eslint-disable-next-line no-unreachable
	switch (ch.chDef.uid) {
		case DeviceChannelUID._STM32F0_FIRMWARE_UPGRADE_100:
			switch (bp.vpkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvFIRMWARE_UPGRADE_STM32F0(ch: LocalChannel, buf: DataView) {
	throw new Error("Not Implemented");

	// eslint-disable-next-line no-unreachable
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._STM32F0_FIRMWARE_UPGRADE_100:
			switch (pkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

// eslint-disable-next-line require-await
export async function sendFIRMWARE_UPGRADE_STM32G0_BLBug(ch: LocalChannel, bp: BridgePacket) {
	throw new Error("Not Implemented");

	// eslint-disable-next-line no-unreachable
	switch (ch.chDef.uid) {
		case DeviceChannelUID._STM32G0_FIRMWARE_UPGRADE_110:
			switch (bp.vpkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvFIRMWARE_UPGRADE_STM32G0_BLBug(ch: LocalChannel, buf: DataView) {
	throw new Error("Not Implemented");

	// eslint-disable-next-line no-unreachable
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._STM32G0_FIRMWARE_UPGRADE_110:
			switch (pkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

// eslint-disable-next-line require-await
export async function sendFIRMWARE_UPGRADE_STM32G0(ch: LocalChannel, bp: BridgePacket) {
	throw new Error("Not Implemented");

	// eslint-disable-next-line no-unreachable
	switch (ch.chDef.uid) {
		case DeviceChannelUID._STM32G0_FIRMWARE_UPGRADE_114:
			switch (bp.vpkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvFIRMWARE_UPGRADE_STM32G0(ch: LocalChannel, buf: DataView) {
	throw new Error("Not Implemented");

	// eslint-disable-next-line no-unreachable
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._STM32G0_FIRMWARE_UPGRADE_114:
			switch (pkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

// eslint-disable-next-line require-await
export async function sendFIRMWARE_UPGRADE_STM32F3(ch: LocalChannel, bp: BridgePacket) {
	throw new Error("Not Implemented");

	// eslint-disable-next-line no-unreachable
	switch (ch.chDef.uid) {
		case DeviceChannelUID._STM32F3_FIRMWARE_UPGRADE_120:
			switch (bp.vpkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvFIRMWARE_UPGRADE_STM32F3(ch: LocalChannel, buf: DataView) {
	throw new Error("Not Implemented");

	// eslint-disable-next-line no-unreachable
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._STM32F3_FIRMWARE_UPGRADE_120:
			switch (pkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

// eslint-disable-next-line require-await
export async function sendFIRMWARE_UPGRADE_STM8S(ch: LocalChannel, bp: BridgePacket) {
	throw new Error("Not Implemented");

	// eslint-disable-next-line no-unreachable
	switch (ch.chDef.uid) {
		case DeviceChannelUID._STM8S_FIRMWARE_UPGRADE_100:
			switch (bp.vpkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvFIRMWARE_UPGRADE_STM8S(ch: LocalChannel, buf: DataView) {
	throw new Error("Not Implemented");

	// eslint-disable-next-line no-unreachable
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._STM8S_FIRMWARE_UPGRADE_100:
			switch (pkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

// eslint-disable-next-line require-await
export async function sendGENERICVINT(ch: LocalChannel, bp: BridgePacket) {
	throw new Error("Not Implemented");

	// eslint-disable-next-line no-unreachable
	switch (ch.chDef.uid) {
		case DeviceChannelUID._VINT_GENERIC:
			switch (bp.vpkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvGENERICVINT(ch: LocalChannel, buf: DataView) {
	throw new Error("Not Implemented");

	// eslint-disable-next-line no-unreachable
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._VINT_GENERIC:
			switch (pkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

// eslint-disable-next-line require-await
export async function sendUNKNOWNVINT(ch: LocalChannel, bp: BridgePacket) {
	throw new Error("Not Implemented");

	// eslint-disable-next-line no-unreachable
	switch (ch.chDef.uid) {
		case DeviceChannelUID._VINT_UNKNOWN:
			switch (bp.vpkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

export function recvUNKNOWNVINT(ch: LocalChannel, buf: DataView) {
	throw new Error("Not Implemented");

	// eslint-disable-next-line no-unreachable
	const pkt = buf.getUint8(0);

	switch (ch.chDef.uid) {
		case DeviceChannelUID._VINT_UNKNOWN:
			switch (pkt) {
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
			break;
		default:
			throw new PhidgetError(ErrorCode.INVALID, "Invalid Channel UID");
	}
}

// eslint-disable-next-line require-await, @typescript-eslint/no-unused-vars
export async function send1024_V2_VINT_OBSOLETE(_ch: LocalChannel, _bp: BridgePacket) {
	throw new Error("Obsolete Firmware");
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function recv1024_V2_VINT_OBSOLETE(_ch: LocalChannel, _buf: DataView) {
	throw new Error("Obsolete Firmware");
}

// eslint-disable-next-line require-await, @typescript-eslint/no-unused-vars
export async function sendCURLOOP_OBSOLETE(_ch: LocalChannel, _bp: BridgePacket) {
	throw new Error("Obsolete Firmware");
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function recvCURLOOP_OBSOLETE(_ch: LocalChannel, _buf: DataView) {
	throw new Error("Obsolete Firmware");
}

// eslint-disable-next-line require-await, @typescript-eslint/no-unused-vars
export async function sendDCC1005_OBSOLETE(_ch: LocalChannel, _bp: BridgePacket) {
	throw new Error("Obsolete Firmware");
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function recvDCC1005_OBSOLETE(_ch: LocalChannel, _buf: DataView) {
	throw new Error("Obsolete Firmware");
}

// eslint-disable-next-line require-await, @typescript-eslint/no-unused-vars
export async function sendDCC1020_OBSOLETE(_ch: LocalChannel, _bp: BridgePacket) {
	throw new Error("Obsolete Firmware");
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function recvDCC1020_OBSOLETE(_ch: LocalChannel, _buf: DataView) {
	throw new Error("Obsolete Firmware");
}

// eslint-disable-next-line require-await, @typescript-eslint/no-unused-vars
export async function sendTMP1300_OBSOLETE(_ch: LocalChannel, _bp: BridgePacket) {
	throw new Error("Obsolete Firmware");
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function recvTMP1300_OBSOLETE(_ch: LocalChannel, _buf: DataView) {
	throw new Error("Obsolete Firmware");
}
