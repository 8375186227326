/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PhidgetChannel } from '../Phidget';
import { Channel } from '../Channel';
import { ErrorCode, ChannelClass } from '../Enumerations.gen';
import * as Enum from '../Enumerations.gen';
import * as SEnum from '../SupportedEnum.gen';
import { PhidgetError } from '../PhidgetError';
import { BridgePacket, PUNK } from '../BridgePacket';
import { BP } from '../BridgePackets.gen';
import { logEventException } from '../Logging';
import { DeviceChannelUID } from '../Devices.gen';

/** @internal */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface GyroscopeData {
	dataInterval: number,
	maxDataInterval: number,
	minDataRate: number,
	maxDataRate: number,
	maxAngularRate: readonly [number, number, number],
	minDataInterval: number,
	minAngularRate: readonly [number, number, number],
	angularRate: readonly [number, number, number],
	axisCount: number,
	timestamp: number,
	precision: Enum.SpatialPrecision | PUNK.ENUM,
	heatingEnabled: number,
}

abstract class GyroscopeBase extends PhidgetChannel {
	/** @internal */
	data: GyroscopeData;
	/**
	 * **AngularRateUpdate** event
	 *  * `angularRate` - The angular rate values
	 *  * `timestamp` - The timestamp value
	 * ---
	 * The most recent angular rate and timestamp values the channel has measured will be reported in this event, which occurs when the `dataInterval` has elapsed.
	 */
	onAngularRateUpdate: ((angularRate: readonly [number, number, number], timestamp: number) => void) | null = null;
	/** @internal */
	_gotAngularRateUpdateErrorEvent?: boolean;

	/**
	 * The Gyroscope class reports rotational data from the Phidget containing a gyroscope chip for use in your code. Phidget gyroscopes usually have multiple sensors, each oriented in a different axis, so multiple dimensions of heading can be recorded.
	 * 
	 * If the Phidget you're using also has an accelerometer and a magnetometer, you may want to use the Spatial classin order to get all of the data at the same time, in a single event.
	 * @public
	 */
	constructor();
	/** @internal */
	constructor(ch?: Channel);
	constructor(ch?: Channel) {
		super(ch);
		this._class = ChannelClass.GYROSCOPE;
		this.name = "Gyroscope";
		this.data = this._initData();
	}

	/** @internal */
	_bridgeInput(bp: BridgePacket) {

		switch(bp.vpkt) {
		case BP.SETDATAINTERVAL:
			if (bp.entryCount > 1)
				this.data.dataInterval = bp.entries[1].v as number;
			else
				this.data.dataInterval = bp.entries[0].v as number;
			this._FIREPropertyChange('DataInterval', bp);
			this._FIREPropertyChange('DataRate', bp);
			break;
		case BP.SETHEATINGENABLED:
			this.data.heatingEnabled = bp.entries[0].v as number;
			this._FIREPropertyChange('HeatingEnabled', bp);
			break;
		case BP.SETSPATIALPRECISION:
			this.data.precision = bp.entries[0].v as Enum.SpatialPrecision;
			this._FIREPropertyChange('Precision', bp);
			break;
		case BP.ZERO:
			break;
		case BP.ANGULARRATEUPDATE: {
			this.data.angularRate = bp.entries[0].v as [number, number, number];
			this.data.timestamp = bp.entries[1].v as number;
			if (this._isAttachedDone && this.onAngularRateUpdate) {
				try {
					this.onAngularRateUpdate(this.data.angularRate, this.data.timestamp);
				} catch (err) { logEventException(err); }
			}
			break;
		}
		default:
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			throw new PhidgetError(ErrorCode.INVALID_PACKET, "Unsupported bridge packet: 0x" + bp.vpkt!.toString(16));
		}
	}

	/** @internal */
	_initData(): GyroscopeData {
		return {
			dataInterval: PUNK.DBL,
			maxDataInterval: PUNK.UINT32,
			minDataRate: PUNK.DBL,
			maxDataRate: PUNK.DBL,
			maxAngularRate: [PUNK.DBL, PUNK.DBL, PUNK.DBL],
			minDataInterval: PUNK.UINT32,
			minAngularRate: [PUNK.DBL, PUNK.DBL, PUNK.DBL],
			angularRate: [PUNK.DBL, PUNK.DBL, PUNK.DBL],
			axisCount: PUNK.INT32,
			timestamp: PUNK.DBL,
			precision: PUNK.ENUM,
			heatingEnabled: PUNK.BOOL,
		}
	}

	/** @internal */
	_initAfterOpen() {
		this.data = this._initData();

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._MOT1101_GYROSCOPE_100:
		case DeviceChannelUID._MOT1102_GYROSCOPE_200:
		case DeviceChannelUID._MOT1102_GYROSCOPE_300:
			this.data.dataInterval = 250;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 50;
			this.data.maxAngularRate = [2000, 2000, 2000];
			this.data.minDataInterval = 20;
			this.data.minAngularRate = [-2000, -2000, -2000];
			this.data.axisCount = 3;
			this.data.precision = Enum.SpatialPrecision.LOW;
			break;
		case DeviceChannelUID._MOT0109_GYROSCOPE_100:
			this.data.dataInterval = 256;
			this.data.maxDataInterval = 1000;
			this.data.minDataRate = 1;
			this.data.maxDataRate = 250;
			this.data.maxAngularRate = [2000, 2000, 2000];
			this.data.minDataInterval = 4;
			this.data.minAngularRate = [-2000, -2000, -2000];
			this.data.angularRate = (<SpatialDevice>this._ch!.parent).data.angularRate[this._ch!.index] as [number, number, number];
			this.data.axisCount = 3;
			this.data.timestamp = (<SpatialDevice>this._ch!.parent).data.timestamp[this._ch!.index];
			this.data.precision = Enum.SpatialPrecision.HYBRID;
			this.data.heatingEnabled = 0;
			break;
		case DeviceChannelUID._MOT0110_GYROSCOPE_100_USB:
			this.data.dataInterval = 250;
			this.data.maxDataInterval = 1000;
			this.data.minDataRate = 1;
			this.data.maxDataRate = 1000;
			this.data.maxAngularRate = [2000, 2000, 2000];
			this.data.minDataInterval = 1;
			this.data.minAngularRate = [-2000, -2000, -2000];
			this.data.angularRate = (<SpatialDevice>this._ch!.parent).data.angularRate[this._ch!.index] as [number, number, number];
			this.data.axisCount = 3;
			this.data.timestamp = (<SpatialDevice>this._ch!.parent).data.timestamp[this._ch!.index];
			this.data.precision = Enum.SpatialPrecision.HIGH;
			this.data.heatingEnabled = 0;
			break;
		case DeviceChannelUID._MOT0110_GYROSCOPE_100_VINT:
			this.data.dataInterval = 250;
			this.data.maxDataInterval = 1000;
			this.data.minDataRate = 1;
			this.data.maxDataRate = 1000;
			this.data.maxAngularRate = [2000, 2000, 2000];
			this.data.minDataInterval = 1;
			this.data.minAngularRate = [-2000, -2000, -2000];
			this.data.axisCount = 3;
			this.data.precision = Enum.SpatialPrecision.HIGH;
			this.data.heatingEnabled = 0;
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	// eslint-disable-next-line require-await
	async _setDefaults() {
		let bp;

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._MOT1101_GYROSCOPE_100:
		case DeviceChannelUID._MOT1102_GYROSCOPE_200:
		case DeviceChannelUID._MOT1102_GYROSCOPE_300:
		case DeviceChannelUID._MOT0109_GYROSCOPE_100:
		case DeviceChannelUID._MOT0110_GYROSCOPE_100_USB:
		case DeviceChannelUID._MOT0110_GYROSCOPE_100_VINT:
			bp = new BridgePacket();
			bp.set({ name: "0", type: "u", value: Math.round(this.data.dataInterval) });
			await bp.send(this._ch, BP.SETDATAINTERVAL);
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	_hasInitialState() {

		if ((this.data.angularRate.includes(PUNK.DBL) ||
			this.data.timestamp == PUNK.DBL)
			&& ! this._gotAngularRateUpdateErrorEvent)
			return false;

		return true;
	}

	/** @internal */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	_fireInitialEvents() {

		if(!this.data.angularRate.includes(PUNK.DBL) &&
			this.data.timestamp != PUNK.DBL)
			if (this.onAngularRateUpdate)
				try {
					this.onAngularRateUpdate(this.data.angularRate, this.data.timestamp);
				} catch (err) { logEventException(err); }

	}

	/**
	 * The most recent angular rate value that the channel has reported.
	 * 
	 * *   This value will always be between `minAngularRate` and `maxAngularRate`.
	 * @throws {@link PhidgetError}
	 */
	get angularRate() { return this.getAngularRate(); }
	/**
	 * The minimum value the `AngularRateUpdate` event will report.
	 * @throws {@link PhidgetError}
	 */
	get minAngularRate() { return this.getMinAngularRate(); }
	/**
	 * The maximum value the `AngularRateUpdate` event will report.
	 * @throws {@link PhidgetError}
	 */
	get maxAngularRate() { return this.getMaxAngularRate(); }
	/**
	 * The number of axes the channel can measure angular rate on.
	 * 
	 * *   See your device's User Guide for more information about the number of axes and their orientation.
	 * @throws {@link PhidgetError}
	 */
	get axisCount() { return this.getAxisCount(); }
	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `AngularRateUpdate` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * @throws {@link PhidgetError}
	 */
	get dataInterval() { return this.getDataInterval(); }
	/**
	 * The minimum value that `dataInterval` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDataInterval() { return this.getMinDataInterval(); }
	/**
	 * The maximum value that `dataInterval` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDataInterval() { return this.getMaxDataInterval(); }
	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @throws {@link PhidgetError}
	 */
	get dataRate() { return this.getDataRate(); }
	/**
	 * The minimum value that `dataRate` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDataRate() { return this.getMinDataRate(); }
	/**
	 * The maximum value that `dataRate` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDataRate() { return this.getMaxDataRate(); }
	/**
	 * Set to TRUE to enable the temperature stabilization feature of this device. This enables onboard heating elements to bring the board up to a known temperature to minimize ambient temerature effects on the sensor's reading. You can leave this setting FALSE to conserve power consumption.  
	 *   
	 * If you enable heating, it is strongly recommended to keep the board in its enclosure to keep it insulated from moving air.  
	 *   
	 * This property is shared by any and all spatial-related objects on this device (Accelerometer, Gyroscope, Magnetometer, Spatial)
	 * @throws {@link PhidgetError}
	 */
	get heatingEnabled() { return this.getHeatingEnabled(); }
	/**
	 * Selects between high/low precision sensing chips.
	 * @throws {@link PhidgetError}
	 * @internal
	 */
	get precision() { return this.getPrecision(); }
	/**
	 * The most recent timestamp value that the channel has reported. This is an extremely accurate time measurement streamed from the device.
	 * 
	 * *   If your application requires a time measurement, you should use this value over a local software timestamp.
	 * @throws {@link PhidgetError}
	 */
	get timestamp() { return this.getTimestamp(); }

	/**
	 * The most recent angular rate value that the channel has reported.
	 * 
	 * *   This value will always be between `minAngularRate` and `maxAngularRate`.
	 * @returns The last reported angular rate
	 * @throws {@link PhidgetError}
	 */
	getAngularRate(): readonly [number, number, number] {
		this._assertOpen();

		if (this.data.angularRate.includes(PUNK.DBL))
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.angularRate);
	}

	/**
	 * The minimum value the `AngularRateUpdate` event will report.
	 * @returns The angular rate values
	 * @throws {@link PhidgetError}
	 */
	getMinAngularRate(): readonly [number, number, number] {
		this._assertOpen();

		if (this.data.minAngularRate.includes(PUNK.DBL))
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minAngularRate);
	}

	/**
	 * The maximum value the `AngularRateUpdate` event will report.
	 * @returns The angular rate values
	 * @throws {@link PhidgetError}
	 */
	getMaxAngularRate(): readonly [number, number, number] {
		this._assertOpen();

		if (this.data.maxAngularRate.includes(PUNK.DBL))
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxAngularRate);
	}

	/**
	 * The number of axes the channel can measure angular rate on.
	 * 
	 * *   See your device's User Guide for more information about the number of axes and their orientation.
	 * @returns Axis count value
	 * @throws {@link PhidgetError}
	 */
	getAxisCount(): number {
		this._assertOpen();

		if (this.data.axisCount === PUNK.INT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.axisCount);
	}

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `AngularRateUpdate` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getDataInterval(): number {
		this._assertOpen();

		if (this.data.dataInterval === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return this.data.dataInterval;
	}

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `AngularRateUpdate` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * @throws {@link PhidgetError}
	 * @param dataInterval - The data interval value
	 */
	async setDataInterval(dataInterval: number): Promise<void> {
		this._assertOpen();

		if (dataInterval < this.data.minDataInterval || dataInterval > this.data.maxDataInterval)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDataInterval + " - " + this.data.maxDataInterval + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: dataInterval });
		await bp.send(this._ch, BP.SETDATAINTERVAL);
	}

	/**
	 * The minimum value that `dataInterval` can be set to.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getMinDataInterval(): number {
		this._assertOpen();

		if (this.data.minDataInterval === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDataInterval);
	}

	/**
	 * The maximum value that `dataInterval` can be set to.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getMaxDataInterval(): number {
		this._assertOpen();

		if (this.data.maxDataInterval === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDataInterval);
	}

	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @returns The data rate for the channel
	 * @throws {@link PhidgetError}
	 */
	getDataRate(): number {
		this._assertOpen();

		if (this.data.dataInterval === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (1000.0 / this.data.dataInterval);
	}

	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @throws {@link PhidgetError}
	 * @param dataRate - The data rate for the channel
	 */
	async setDataRate(dataRate: number): Promise<void> {
		this._assertOpen();

		if (dataRate < this.data.minDataRate || dataRate > this.data.maxDataRate)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDataRate + " - " + this.data.maxDataRate + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: Math.round(1000.0 / dataRate) });
		bp.set({ name: "1", type: "g", value: (1000.0 / dataRate) });
		await bp.send(this._ch, BP.SETDATAINTERVAL);
	}

	/**
	 * The minimum value that `dataRate` can be set to.
	 * @returns The data rate value
	 * @throws {@link PhidgetError}
	 */
	getMinDataRate(): number {
		this._assertOpen();

		if (this.data.minDataRate === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDataRate);
	}

	/**
	 * The maximum value that `dataRate` can be set to.
	 * @returns The data rate value
	 * @throws {@link PhidgetError}
	 */
	getMaxDataRate(): number {
		this._assertOpen();

		if (this.data.maxDataRate === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDataRate);
	}

	/**
	 * Set to TRUE to enable the temperature stabilization feature of this device. This enables onboard heating elements to bring the board up to a known temperature to minimize ambient temerature effects on the sensor's reading. You can leave this setting FALSE to conserve power consumption.  
	 *   
	 * If you enable heating, it is strongly recommended to keep the board in its enclosure to keep it insulated from moving air.  
	 *   
	 * This property is shared by any and all spatial-related objects on this device (Accelerometer, Gyroscope, Magnetometer, Spatial)
	 * @returns Whether self-heating temperature stabilization is enabled
	 * @throws {@link PhidgetError}
	 */
	getHeatingEnabled(): boolean {
		this._assertOpen();

		if (this.data.heatingEnabled === PUNK.BOOL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (!!this.data.heatingEnabled);
	}

	/**
	 * Set to TRUE to enable the temperature stabilization feature of this device. This enables onboard heating elements to bring the board up to a known temperature to minimize ambient temerature effects on the sensor's reading. You can leave this setting FALSE to conserve power consumption.  
	 *   
	 * If you enable heating, it is strongly recommended to keep the board in its enclosure to keep it insulated from moving air.  
	 *   
	 * This property is shared by any and all spatial-related objects on this device (Accelerometer, Gyroscope, Magnetometer, Spatial)
	 * @throws {@link PhidgetError}
	 * @param heatingEnabled - Whether self-heating temperature stabilization is enabled
	 */
	async setHeatingEnabled(heatingEnabled: boolean): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (heatingEnabled !== false && heatingEnabled !== true)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be a boolean.");

		bp.set({ name: "0", type: "d", value: (heatingEnabled ? 1 : 0) });
		await bp.send(this._ch, BP.SETHEATINGENABLED);
	}

	/**
	 * Selects between high/low precision sensing chips.
	 * @returns The sensor precision value
	 * @throws {@link PhidgetError}
	 * @internal
	 */
	getPrecision(): Enum.SpatialPrecision {
		this._assertOpen();

		if (this.data.precision === PUNK.ENUM)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.precision);
	}

	/**
	 * Selects between high/low precision sensing chips.
	 * @throws {@link PhidgetError}
	 * @param precision - The sensor precision value
	 * @internal
	 */
	async setPrecision(precision: Enum.SpatialPrecision): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (!SEnum.supportedSpatialPrecision(this._ch!, precision))
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Specified SpatialPrecision is unsupported by this device.");

		bp.set({ name: "0", type: "d", value: precision });
		await bp.send(this._ch, BP.SETSPATIALPRECISION);
	}

	/**
	 * The most recent timestamp value that the channel has reported. This is an extremely accurate time measurement streamed from the device.
	 * 
	 * *   If your application requires a time measurement, you should use this value over a local software timestamp.
	 * @returns The timestamp value
	 * @throws {@link PhidgetError}
	 */
	getTimestamp(): number {
		this._assertOpen();

		if (this.data.timestamp === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.timestamp);
	}

	/**
	 * Re-zeros the gyroscope in 1-2 seconds.
	 * 
	 * *   The device must be stationary when zeroing.
	 * *   The angular rate will be reported as 0.0°/s while zeroing.
	 * *   Zeroing the gyroscope is a method of compensating for the drift that is inherent to all gyroscopes. See your device's User Guide for more information on dealing with drift.
	 * @throws {@link PhidgetError}
	 */
	async zero(): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		await bp.send(this._ch, BP.ZERO);
	}

}
import { type SpatialDevice } from '../usb/device/SpatialDevice';
export { GyroscopeBase };
