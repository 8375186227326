/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PhidgetChannel } from '../Phidget';
import { Channel } from '../Channel';
import { ErrorCode, ChannelClass } from '../Enumerations.gen';
import * as Enum from '../Enumerations.gen';
import * as SEnum from '../SupportedEnum.gen';
import { PhidgetError } from '../PhidgetError';
import { BridgePacket, PUNK } from '../BridgePacket';
import { BP } from '../BridgePackets.gen';
import { DeviceChannelUID } from '../Devices.gen';

/** @internal */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface EncoderData {
	dataInterval: number,
	minDataInterval: number,
	maxDataInterval: number,
	minDataRate: number,
	maxDataRate: number,
	maxPositionChangeTrigger: number,
	minPositionChangeTrigger: number,
	position: number,
	indexPosition: number,
	positionChangeTrigger: number,
	enabled: number,
	IOMode: Enum.EncoderIOMode | PUNK.ENUM,
}

abstract class EncoderBase extends PhidgetChannel {
	/** @internal */
	data: EncoderData;
	/**
	 * **PositionChange** event
	 *  * `positionChange` - The amount the position changed since the last change event
	 *  * `timeChange` - The time elapsed since the last change event in milliseconds
	 *  * `indexTriggered` - True if the index was passed since the last change event
	 * ---
	 * The most recent position change and time change the channel has measured will be reported in this event, which occurs when the `dataInterval` has elapsed.
	 * 
	 * *   If a `positionChangeTrigger` has been set to a non-zero value, the `PositionChange` event will not occur until the position has changed by at least the `positionChangeTrigger` value.
	 */
	onPositionChange: ((positionChange: number, timeChange: number, indexTriggered: boolean) => void) | null = null;
	/** @internal */
	_gotPositionChangeErrorEvent?: boolean;

	/**
	 * The Encoder class is used to read position data from quadrature encoders in order to track linear or rotary movement. If the device supports an index pin as a reference point, you can also access it through this class.
	 * @public
	 */
	constructor();
	/** @internal */
	constructor(ch?: Channel);
	constructor(ch?: Channel) {
		super(ch);
		this._class = ChannelClass.ENCODER;
		this.name = "Encoder";
		this.data = this._initData();
	}

	/** @internal */
	_bridgeInput(bp: BridgePacket) {

		switch(bp.vpkt) {
		case BP.SETENABLED:
			this.data.enabled = bp.entries[0].v as number;
			this._FIREPropertyChange('Enabled', bp);
			break;
		case BP.SETDATAINTERVAL:
			if (bp.entryCount > 1)
				this.data.dataInterval = bp.entries[1].v as number;
			else
				this.data.dataInterval = bp.entries[0].v as number;
			this._FIREPropertyChange('DataInterval', bp);
			this._FIREPropertyChange('DataRate', bp);
			break;
		case BP.SETIOMODE:
			this.data.IOMode = bp.entries[0].v as Enum.EncoderIOMode;
			this._FIREPropertyChange('IOMode', bp);
			break;
		case BP.SETCHANGETRIGGER:
			this.data.positionChangeTrigger = bp.entries[0].v as number;
			this._FIREPropertyChange('PositionChangeTrigger', bp);
			break;
		default:
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			throw new PhidgetError(ErrorCode.INVALID_PACKET, "Unsupported bridge packet: 0x" + bp.vpkt!.toString(16));
		}
	}

	/** @internal */
	_initData(): EncoderData {
		return {
			dataInterval: PUNK.DBL,
			minDataInterval: PUNK.UINT32,
			maxDataInterval: PUNK.UINT32,
			minDataRate: PUNK.DBL,
			maxDataRate: PUNK.DBL,
			maxPositionChangeTrigger: PUNK.UINT32,
			minPositionChangeTrigger: PUNK.UINT32,
			position: PUNK.INT64,
			indexPosition: PUNK.INT64,
			positionChangeTrigger: PUNK.UINT32,
			enabled: PUNK.BOOL,
			IOMode: PUNK.ENUM,
		}
	}

	/** @internal */
	_initAfterOpen() {
		this.data = this._initData();

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._1047_ENCODER_200:
		case DeviceChannelUID._1047_ENCODER_300:
			this.data.dataInterval = 256;
			this.data.minDataInterval = 8;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 125;
			this.data.maxPositionChangeTrigger = 10000;
			this.data.minPositionChangeTrigger = 0;
			this.data.position = 0;
			this.data.indexPosition = 0;
			this.data.positionChangeTrigger = 0;
			this.data.enabled = 1;
			break;
		case DeviceChannelUID._1057_ENCODER_400:
			this.data.dataInterval = 256;
			this.data.minDataInterval = 8;
			this.data.maxDataInterval = 1000;
			this.data.minDataRate = 1;
			this.data.maxDataRate = 125;
			this.data.maxPositionChangeTrigger = 10000;
			this.data.minPositionChangeTrigger = 0;
			this.data.position = 0;
			this.data.indexPosition = 0;
			this.data.positionChangeTrigger = 0;
			this.data.enabled = 1;
			this.data.IOMode = Enum.EncoderIOMode.PUSH_PULL;
			break;
		case DeviceChannelUID._DCC1000_ENCODER_100:
		case DeviceChannelUID._DCC1000_ENCODER_200:
		case DeviceChannelUID._DCC1000_ENCODER_210:
			this.data.dataInterval = 250;
			this.data.minDataInterval = 100;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 10;
			this.data.maxPositionChangeTrigger = 60000000;
			this.data.minPositionChangeTrigger = 0;
			this.data.position = 0;
			this.data.indexPosition = 0;
			this.data.positionChangeTrigger = 0;
			this.data.enabled = 1;
			this.data.IOMode = Enum.EncoderIOMode.PUSH_PULL;
			break;
		case DeviceChannelUID._DCC1001_ENCODER_100:
		case DeviceChannelUID._DCC1001_ENCODER_120:
		case DeviceChannelUID._DCC1002_ENCODER_100:
		case DeviceChannelUID._DCC1002_ENCODER_110:
			this.data.dataInterval = 250;
			this.data.minDataInterval = 50;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 20;
			this.data.maxPositionChangeTrigger = 60000000;
			this.data.minPositionChangeTrigger = 0;
			this.data.position = 0;
			this.data.indexPosition = 0;
			this.data.positionChangeTrigger = 0;
			this.data.enabled = 1;
			break;
		case DeviceChannelUID._ENC1000_ENCODER_100:
			this.data.dataInterval = 250;
			this.data.minDataInterval = 20;
			this.data.maxDataInterval = 1000;
			this.data.minDataRate = 1;
			this.data.maxDataRate = 50;
			this.data.maxPositionChangeTrigger = 60000000;
			this.data.minPositionChangeTrigger = 0;
			this.data.position = 0;
			this.data.indexPosition = 0;
			this.data.positionChangeTrigger = 0;
			this.data.enabled = 1;
			this.data.IOMode = Enum.EncoderIOMode.PUSH_PULL;
			break;
		case DeviceChannelUID._ENC1001_ENCODER_100:
			this.data.dataInterval = 10;
			this.data.minDataInterval = 1;
			this.data.maxDataInterval = 1000;
			this.data.minDataRate = 1;
			this.data.maxDataRate = 1000;
			this.data.maxPositionChangeTrigger = 60000000;
			this.data.minPositionChangeTrigger = 0;
			this.data.position = 0;
			this.data.indexPosition = 0;
			this.data.positionChangeTrigger = 0;
			this.data.enabled = 1;
			break;
		case DeviceChannelUID._HIN1101_ENCODER_100:
			this.data.dataInterval = 250;
			this.data.minDataInterval = 20;
			this.data.maxDataInterval = 1000;
			this.data.minDataRate = 1;
			this.data.maxDataRate = 50;
			this.data.maxPositionChangeTrigger = 60000000;
			this.data.minPositionChangeTrigger = 0;
			this.data.position = 0;
			this.data.positionChangeTrigger = 0;
			this.data.enabled = 1;
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	// eslint-disable-next-line require-await
	async _setDefaults() {
		let bp;

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._1047_ENCODER_200:
		case DeviceChannelUID._1047_ENCODER_300:
		case DeviceChannelUID._ENC1001_ENCODER_100:
		case DeviceChannelUID._HIN1101_ENCODER_100:
			bp = new BridgePacket();
			bp.set({ name: "0", type: "u", value: Math.round(this.data.dataInterval) });
			await bp.send(this._ch, BP.SETDATAINTERVAL);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "u", value: this.data.positionChangeTrigger });
			await bp.send(this._ch, BP.SETCHANGETRIGGER);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "d", value: this.data.enabled });
			await bp.send(this._ch, BP.SETENABLED);
			break;
		case DeviceChannelUID._1057_ENCODER_400:
		case DeviceChannelUID._ENC1000_ENCODER_100:
			bp = new BridgePacket();
			bp.set({ name: "0", type: "u", value: Math.round(this.data.dataInterval) });
			await bp.send(this._ch, BP.SETDATAINTERVAL);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "u", value: this.data.positionChangeTrigger });
			await bp.send(this._ch, BP.SETCHANGETRIGGER);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "d", value: this.data.enabled });
			await bp.send(this._ch, BP.SETENABLED);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "d", value: this.data.IOMode });
			await bp.send(this._ch, BP.SETIOMODE);
			break;
		case DeviceChannelUID._DCC1000_ENCODER_100:
		case DeviceChannelUID._DCC1000_ENCODER_200:
		case DeviceChannelUID._DCC1000_ENCODER_210:
			break;
		case DeviceChannelUID._DCC1001_ENCODER_100:
		case DeviceChannelUID._DCC1001_ENCODER_120:
		case DeviceChannelUID._DCC1002_ENCODER_100:
		case DeviceChannelUID._DCC1002_ENCODER_110:
			bp = new BridgePacket();
			bp.set({ name: "0", type: "u", value: Math.round(this.data.dataInterval) });
			await bp.send(this._ch, BP.SETDATAINTERVAL);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "u", value: this.data.positionChangeTrigger });
			await bp.send(this._ch, BP.SETCHANGETRIGGER);
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	_hasInitialState() {


		return true;
	}

	/** @internal */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	_fireInitialEvents() {

	}

	/**
	 * The enabled state of the encoder.
	 * @throws {@link PhidgetError}
	 */
	get enabled() { return this.getEnabled(); }
	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `PositionChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * *   The timing between `PositionChange` events can also be affected by the `positionChangeTrigger`.
	 * @throws {@link PhidgetError}
	 */
	get dataInterval() { return this.getDataInterval(); }
	/**
	 * The minimum value that `dataInterval` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDataInterval() { return this.getMinDataInterval(); }
	/**
	 * The maximum value that `dataInterval` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDataInterval() { return this.getMaxDataInterval(); }
	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @throws {@link PhidgetError}
	 */
	get dataRate() { return this.getDataRate(); }
	/**
	 * The minimum value that `dataRate` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDataRate() { return this.getMinDataRate(); }
	/**
	 * The maximum value that `dataRate` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDataRate() { return this.getMaxDataRate(); }
	/**
	 * The most recent position of the index channel calculated by the Phidgets library.
	 * 
	 * *   The index channel will usually pulse once per rotation.
	 * *   Setting the encoder position will move the index position the same amount so their relative position stays the same.
	 * *   Index position is tracked locally as the last position at which the index was triggered. Setting position will only affect the local copy of the index position value. This means that index positions seen by multiple network applications may not agree.
	 * @throws {@link PhidgetError}
	 */
	get indexPosition() { return this.getIndexPosition(); }
	/**
	 * The encoder interface mode. Match the mode to the type of encoder you have attached.
	 * 
	 * *   It is recommended to only change this when the encoder disabled in order to avoid unexpected results.
	 * @throws {@link PhidgetError}
	 */
	get IOMode() { return this.getIOMode(); }
	/**
	 * The most recent position value calculated by the Phidgets library.
	 * 
	 * *   Position counts quadrature edges within a quadrature cycle. This means there are four counts per full quadrature cycle.
	 * *   Position is tracked locally as the total position change from the time the channel is opened. Setting position will only affect the local copy of the position value. This means that positions seen by multiple network applications may not agree.
	 * @throws {@link PhidgetError}
	 */
	get position() { return this.getPosition(); }
	set position(position: number) { this.setPosition(position); }
	/**
	 * The channel will not issue a `PositionChange` event until the position value has changed by the amount specified by the `positionChangeTrigger`.
	 * 
	 * *   Setting the `positionChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering
	 * @throws {@link PhidgetError}
	 */
	get positionChangeTrigger() { return this.getPositionChangeTrigger(); }
	/**
	 * The minimum value that `positionChangeTrigger` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minPositionChangeTrigger() { return this.getMinPositionChangeTrigger(); }
	/**
	 * The maximum value that `positionChangeTrigger` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxPositionChangeTrigger() { return this.getMaxPositionChangeTrigger(); }

	/**
	 * The enabled state of the encoder.
	 * @throws {@link PhidgetError}
	 * @param enabled - The enabled value
	 */
	async setEnabled(enabled: boolean): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (enabled !== false && enabled !== true)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be a boolean.");

		bp.set({ name: "0", type: "d", value: (enabled ? 1 : 0) });
		await bp.send(this._ch, BP.SETENABLED);
	}

	/**
	 * The enabled state of the encoder.
	 * @returns The enabled value
	 * @throws {@link PhidgetError}
	 */
	getEnabled(): boolean {
		this._assertOpen();

		if (this.data.enabled === PUNK.BOOL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (!!this.data.enabled);
	}

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `PositionChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * *   The timing between `PositionChange` events can also be affected by the `positionChangeTrigger`.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getDataInterval(): number {
		this._assertOpen();

		if (this.data.dataInterval === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return this.data.dataInterval;
	}

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `PositionChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * *   The timing between `PositionChange` events can also be affected by the `positionChangeTrigger`.
	 * @throws {@link PhidgetError}
	 * @param dataInterval - The data interval value
	 */
	async setDataInterval(dataInterval: number): Promise<void> {
		this._assertOpen();

		if (dataInterval < this.data.minDataInterval || dataInterval > this.data.maxDataInterval)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDataInterval + " - " + this.data.maxDataInterval + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: dataInterval });
		await bp.send(this._ch, BP.SETDATAINTERVAL);
	}

	/**
	 * The minimum value that `dataInterval` can be set to.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getMinDataInterval(): number {
		this._assertOpen();

		if (this.data.minDataInterval === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDataInterval);
	}

	/**
	 * The maximum value that `dataInterval` can be set to.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getMaxDataInterval(): number {
		this._assertOpen();

		if (this.data.maxDataInterval === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDataInterval);
	}

	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @returns The data rate for the channel
	 * @throws {@link PhidgetError}
	 */
	getDataRate(): number {
		this._assertOpen();

		if (this.data.dataInterval === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (1000.0 / this.data.dataInterval);
	}

	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @throws {@link PhidgetError}
	 * @param dataRate - The data rate for the channel
	 */
	async setDataRate(dataRate: number): Promise<void> {
		this._assertOpen();

		if (dataRate < this.data.minDataRate || dataRate > this.data.maxDataRate)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDataRate + " - " + this.data.maxDataRate + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: Math.round(1000.0 / dataRate) });
		bp.set({ name: "1", type: "g", value: (1000.0 / dataRate) });
		await bp.send(this._ch, BP.SETDATAINTERVAL);
	}

	/**
	 * The minimum value that `dataRate` can be set to.
	 * @returns The data rate value
	 * @throws {@link PhidgetError}
	 */
	getMinDataRate(): number {
		this._assertOpen();

		if (this.data.minDataRate === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDataRate);
	}

	/**
	 * The maximum value that `dataRate` can be set to.
	 * @returns The data rate value
	 * @throws {@link PhidgetError}
	 */
	getMaxDataRate(): number {
		this._assertOpen();

		if (this.data.maxDataRate === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDataRate);
	}

	/**
	 * The most recent position of the index channel calculated by the Phidgets library.
	 * 
	 * *   The index channel will usually pulse once per rotation.
	 * *   Setting the encoder position will move the index position the same amount so their relative position stays the same.
	 * *   Index position is tracked locally as the last position at which the index was triggered. Setting position will only affect the local copy of the index position value. This means that index positions seen by multiple network applications may not agree.
	 * @returns The index position value
	 * @throws {@link PhidgetError}
	 */
	getIndexPosition(): number {
		this._assertOpen();

		if (this.data.indexPosition === PUNK.INT64)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.indexPosition);
	}

	/**
	 * The encoder interface mode. Match the mode to the type of encoder you have attached.
	 * 
	 * *   It is recommended to only change this when the encoder disabled in order to avoid unexpected results.
	 * @returns The IO mode value.
	 * @throws {@link PhidgetError}
	 */
	getIOMode(): Enum.EncoderIOMode {
		this._assertOpen();

		if (this.data.IOMode === PUNK.ENUM)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.IOMode);
	}

	/**
	 * The encoder interface mode. Match the mode to the type of encoder you have attached.
	 * 
	 * *   It is recommended to only change this when the encoder disabled in order to avoid unexpected results.
	 * @throws {@link PhidgetError}
	 * @param IOMode - The IO mode value.
	 */
	async setIOMode(IOMode: Enum.EncoderIOMode): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (!SEnum.supportedEncoderIOMode(this._ch!, IOMode))
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Specified EncoderIOMode is unsupported by this device.");

		bp.set({ name: "0", type: "d", value: IOMode });
		await bp.send(this._ch, BP.SETIOMODE);
	}

	/**
	 * The most recent position value calculated by the Phidgets library.
	 * 
	 * *   Position counts quadrature edges within a quadrature cycle. This means there are four counts per full quadrature cycle.
	 * *   Position is tracked locally as the total position change from the time the channel is opened. Setting position will only affect the local copy of the position value. This means that positions seen by multiple network applications may not agree.
	 * @returns The position value
	 * @throws {@link PhidgetError}
	 */
	getPosition(): number {
		this._assertOpen();

		if (this.data.position === PUNK.INT64)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.position);
	}

	/**
	 * The most recent position value calculated by the Phidgets library.
	 * 
	 * *   Position counts quadrature edges within a quadrature cycle. This means there are four counts per full quadrature cycle.
	 * *   Position is tracked locally as the total position change from the time the channel is opened. Setting position will only affect the local copy of the position value. This means that positions seen by multiple network applications may not agree.
	 * @throws {@link PhidgetError}
	 * @param position - The position value
	 */
	abstract setPosition(position: number): void;
	/**
	 * The channel will not issue a `PositionChange` event until the position value has changed by the amount specified by the `positionChangeTrigger`.
	 * 
	 * *   Setting the `positionChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering
	 * @returns The change trigger value
	 * @throws {@link PhidgetError}
	 */
	getPositionChangeTrigger(): number {
		this._assertOpen();

		if (this.data.positionChangeTrigger === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.positionChangeTrigger);
	}

	/**
	 * The channel will not issue a `PositionChange` event until the position value has changed by the amount specified by the `positionChangeTrigger`.
	 * 
	 * *   Setting the `positionChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering
	 * @throws {@link PhidgetError}
	 * @param positionChangeTrigger - The change trigger value
	 */
	async setPositionChangeTrigger(positionChangeTrigger: number): Promise<void> {
		this._assertOpen();

		if (positionChangeTrigger < this.data.minPositionChangeTrigger || positionChangeTrigger > this.data.maxPositionChangeTrigger)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minPositionChangeTrigger + " - " + this.data.maxPositionChangeTrigger + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: positionChangeTrigger });
		await bp.send(this._ch, BP.SETCHANGETRIGGER);
	}

	/**
	 * The minimum value that `positionChangeTrigger` can be set to.
	 * @returns The change trigger value
	 * @throws {@link PhidgetError}
	 */
	getMinPositionChangeTrigger(): number {
		this._assertOpen();

		if (this.data.minPositionChangeTrigger === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minPositionChangeTrigger);
	}

	/**
	 * The maximum value that `positionChangeTrigger` can be set to.
	 * @returns The change trigger value
	 * @throws {@link PhidgetError}
	 */
	getMaxPositionChangeTrigger(): number {
		this._assertOpen();

		if (this.data.maxPositionChangeTrigger === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxPositionChangeTrigger);
	}

}
export { EncoderBase };
