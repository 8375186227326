/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { LCDBase } from './LCD.gen';
import { PhidgetLock } from '../PhidgetLock';
import { BP } from '../BridgePackets.gen';
import { ErrorCode, LCDScreenSize, DeviceID, LCDFont } from '../Enumerations.gen';
import { BridgePacket } from '../BridgePacket';
import { PhidgetError } from '../PhidgetError';
import { Channel } from '../Channel';

/** @public */
class LCD extends LCDBase {
	/** @internal */
	_transactionLock: PhidgetLock;

	/** @internal */
	constructor();
	/** @internal */
	constructor(ch?: Channel);
	constructor(ch?: Channel) {
		super(ch);
		this._transactionLock = new PhidgetLock();
	}

	/** @internal */
	_bridgeInput(bp: BridgePacket) {
		switch (bp.vpkt) {
			case BP.SETFONTSIZE: {
				const font = bp.getNumber(0);
				const width = bp.getNumber(1);
				const height = bp.getNumber(2);
				this.data.fontWidth[font] = width;
				this.data.fontHeight[font] = height;
				this._FIREPropertyChange('FontSize', bp);
				break;
			}
			case BP.SETSCREENSIZE:
				this._setWidthHeightFromScreenSize(bp.getNumber(0) as LCDScreenSize);
				super._bridgeInput(bp);
				break;

			// These packets wake up an LCD1100
			case BP.SETBACKLIGHT:
			case BP.SETCONTRAST:
			case BP.FLUSH:
				if (this.deviceID === DeviceID.PN_LCD1100)
					this.data.sleeping = 0;
				super._bridgeInput(bp);
				break;

			default:
				super._bridgeInput(bp);
				break;
		}
	}

	/** @internal */
	_setWidthHeightFromScreenSize(size: LCDScreenSize) {
		switch (size) {
			case LCDScreenSize.NO_SCREEN:
				this.data.height = 0;
				this.data.width = 0;
				break;
			case LCDScreenSize.DIMENSIONS_1X8:
				this.data.height = 1;
				this.data.width = 8;
				break;
			case LCDScreenSize.DIMENSIONS_2X8:
				this.data.height = 2;
				this.data.width = 8;
				break;
			case LCDScreenSize.DIMENSIONS_1X16:
				this.data.height = 1;
				this.data.width = 16;
				break;
			case LCDScreenSize.DIMENSIONS_2X16:
				this.data.height = 2;
				this.data.width = 16;
				break;
			case LCDScreenSize.DIMENSIONS_4X16:
				this.data.height = 4;
				this.data.width = 16;
				break;
			case LCDScreenSize.DIMENSIONS_2X20:
				this.data.height = 2;
				this.data.width = 20;
				break;
			case LCDScreenSize.DIMENSIONS_4X20:
				this.data.height = 4;
				this.data.width = 20;
				break;
			case LCDScreenSize.DIMENSIONS_2X24:
				this.data.height = 2;
				this.data.width = 24;
				break;
			case LCDScreenSize.DIMENSIONS_1X40:
				this.data.height = 1;
				this.data.width = 40;
				break;
			case LCDScreenSize.DIMENSIONS_2X40:
				this.data.height = 2;
				this.data.width = 40;
				break;
			case LCDScreenSize.DIMENSIONS_4X40:
				this.data.height = 4;
				this.data.width = 40;
				break;
			default:
				break;
		}
	}

	setAutoFlush(autoFlush: boolean) {
		this._assertOpen();

		this.data.autoFlush = autoFlush ? 1 : 0;
	}

	async setCharacterBitmap(font: LCDFont, character: string, bitmap: number[]) {
		this._assertOpen();

		const fontSize = this.getFontSize(font);
		const bp = new BridgePacket();

		if (fontSize.width <= 0)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "invalid arg (getFontSize): " + font);
		if (fontSize.height <= 0)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "invalid arg (getFontSize): " + font);

		bp.set({ name: "0", type: "d", value: font });
		bp.set({ name: "1", type: "s", value: character });
		bp.set({ name: "2", type: "R", value: bitmap });
		await bp.send(this._ch, BP.SETCHARACTERBITMAP);
	}

	getMaxCharacters(font: LCDFont) {
		this._assertOpen();

		let maxChars;
		let fontSize;

		switch (this.deviceID) {
			case DeviceID.PN_LCD1100:
				fontSize = this.getFontSize(font);
				if (fontSize.width <= 0)
					throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Font width is <= 0.");
				if (fontSize.height <= 0)
					throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Font height is <= 0.");
				maxChars = Math.floor(Math.min(255, (this.data.width / fontSize.width) * (this.data.height / fontSize.height)));
				break;
			case DeviceID.PN_1202_1203:
			case DeviceID.PN_1204:
			case DeviceID.PN_1215__1218:
			case DeviceID.PN_1219__1222:
				maxChars = 0xff;
				break;
			default:
				throw new PhidgetError(ErrorCode.UNEXPECTED);
		}

		return (maxChars);
	}

	getFontSize(font: LCDFont) {
		this._assertOpen();

		const fontSize = { width: 0, height: 0 };

		switch (font) {
			case LCDFont.DIMENSIONS_6X10:
				fontSize.width = 6;
				fontSize.height = 10;
				break;
			case LCDFont.DIMENSIONS_5X8:
				fontSize.width = 5;
				fontSize.height = 8;
				break;
			case LCDFont.DIMENSIONS_6X12:
				fontSize.width = 6;
				fontSize.height = 12;
				break;
			case LCDFont.USER1:
			case LCDFont.USER2:
				fontSize.width = this.data.fontWidth[font];
				fontSize.height = this.data.fontHeight[font];
				break;
			default:
				throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Invalid font.");
		}

		return (fontSize);
	}

	async writeBitmap(xpos: number, ypos: number, xsize: number, ysize: number, bitmap: number[]) {
		this._assertOpen();

		const bp = new BridgePacket();

		if (xsize <= 0 || ysize <= 0)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "invalid arg (size cannot be <=0) " + (xsize <= 0 ? xsize : ysize));

		bp.set({ name: "0", type: "d", value: xpos });
		bp.set({ name: "1", type: "d", value: ypos });
		bp.set({ name: "2", type: "d", value: xsize });
		bp.set({ name: "3", type: "d", value: ysize });
		bp.set({ name: "4", type: "R", value: bitmap });

		await bp.send(this._ch, BP.WRITEBITMAP);
	}
}

export { LCD };