import { Device, DeviceData } from "../Device";
import { DeviceID } from "../Enumerations.gen";
import { type BridgePacket } from "../BridgePacket";
import { type LocalChannel } from "./LocalChannel";
import { USBConnectionBase } from "./USBConnection";
import { PhidgetUSBDevice } from "./PhidgetUSBDevice";
import { PhidgetUniqueDevice } from "../Devices.gen";

export const PHIDGET_MAXCHANNELS = 64;	    /* Max number of channels on any device (current winner LED-64) */
export const MAX_OUT_PACKET_SIZE = 128;

// Either a USB Device or a VINT Device, connected locally via WebUSB
/** @internal */
abstract class LocalDevice extends Device {
	declare conn: USBConnectionBase;
	declare parent?: PhidgetUSBDevice;
	declare devDef: Required<PhidgetUniqueDevice>;
	deviceID: DeviceID;
	name: string;
	channels: Record<number, LocalChannel>;

	// subclass
	abstract dataInput(buffer: DataView): void;
	abstract bridgeInput(ch: LocalChannel, arg1: BridgePacket): Promise<void>;
	abstract open(full?: boolean): Promise<void>;
	abstract close(force?: boolean, full?: boolean): Promise<void>;
	abstract lock(): Promise<void>;
	abstract unlock(): void;
	initAfterCreate?(): Promise<void>;

	constructor(conn: USBConnectionBase, data: DeviceData) {
		super(conn, data);
		this.deviceID = this.devDef.d;
		this.name = this.devDef.t;
		this.channels = {};
	}

	getChannel(index: number): LocalChannel | null {
		if (this.channels[index] != undefined && this.channels[index].isopen)
			return this.channels[index];
		return null;
	}
}

// NOTE: Export as a LocalDevice because USBDevice already exists as part of WebUSB
export { LocalDevice };