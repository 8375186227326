import { ResistanceInputBase } from './ResistanceInput.gen';
import { ErrorEventCode, RTDWireSetup } from '../Enumerations.gen';
import { BridgePacket, PUNK } from '../BridgePacket';
import { BP } from '../BridgePackets.gen';

/** @public */
class ResistanceInput extends ResistanceInputBase {

	/** @internal */
	_errorHandler(code: ErrorEventCode) {
		switch (code) {
			case ErrorEventCode.SATURATION:
				this.data.resistance = PUNK.DBL;
				this._gotResistanceChangeErrorEvent = true;
				break;
		}
	}

	/** @internal */
	_bridgeInput(bp: BridgePacket) {
		switch (bp.vpkt) {
			case BP.RTDWIRESETUPCHANGE:
				this.data.RTDWireSetup = bp.entries[0].v as RTDWireSetup;
				this._FIREPropertyChange('RTDWireSetup', bp);
				break;
			default:
				super._bridgeInput(bp);
				break;
		}
	}
}

export { ResistanceInput };