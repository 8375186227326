/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PhidgetChannel } from '../Phidget';
import { Channel } from '../Channel';
import { ErrorCode, ChannelClass } from '../Enumerations.gen';
import * as Enum from '../Enumerations.gen';
import * as SEnum from '../SupportedEnum.gen';
import { PhidgetError } from '../PhidgetError';
import { BridgePacket, PUNK } from '../BridgePacket';
import { BP } from '../BridgePackets.gen';
import { DeviceChannelUID } from '../Devices.gen';

/** @internal */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface VoltageOutputData {
	enabled: number,
	maxVoltage: number,
	minVoltage: number,
	voltage: number,
	voltageOutputRange: Enum.VoltageOutputRange | PUNK.ENUM,
	maxFailsafeTime: number,
	minFailsafeTime: number,
}

abstract class VoltageOutputBase extends PhidgetChannel {
	/** @internal */
	data: VoltageOutputData;

	/**
	 * The Voltage Output class controls the variable DC voltage output on a Phidget board. This class provides settings for the output voltage as well as various safety controls.
	 * @public
	 */
	constructor();
	/** @internal */
	constructor(ch?: Channel);
	constructor(ch?: Channel) {
		super(ch);
		this._class = ChannelClass.VOLTAGE_OUTPUT;
		this.name = "VoltageOutput";
		this.data = this._initData();
	}

	/** @internal */
	_bridgeInput(bp: BridgePacket) {

		switch(bp.vpkt) {
		case BP.SETENABLED:
			this.data.enabled = bp.entries[0].v as number;
			this._FIREPropertyChange('Enabled', bp);
			break;
		case BP.SETFAILSAFETIME:
			break;
		case BP.FAILSAFERESET:
			break;
		case BP.SETVOLTAGE:
			this.data.voltage = bp.entries[0].v as number;
			this._FIREPropertyChange('Voltage', bp);
			break;
		case BP.SETVOLTAGERANGE:
			this.data.voltageOutputRange = bp.entries[0].v as Enum.VoltageOutputRange;
			this._FIREPropertyChange('VoltageOutputRange', bp);
			break;
		default:
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			throw new PhidgetError(ErrorCode.INVALID_PACKET, "Unsupported bridge packet: 0x" + bp.vpkt!.toString(16));
		}
	}

	/** @internal */
	_initData(): VoltageOutputData {
		return {
			enabled: PUNK.BOOL,
			maxVoltage: PUNK.DBL,
			minVoltage: PUNK.DBL,
			voltage: PUNK.DBL,
			voltageOutputRange: PUNK.ENUM,
			maxFailsafeTime: PUNK.UINT32,
			minFailsafeTime: PUNK.UINT32,
		}
	}

	/** @internal */
	_initAfterOpen() {
		this.data = this._initData();

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._OUT1000_VOLTAGEOUTPUT_100:
			this.data.enabled = 1;
			this.data.maxVoltage = 4.2;
			this.data.minVoltage = 0;
			this.data.voltage = 0;
			break;
		case DeviceChannelUID._OUT1000_VOLTAGEOUTPUT_110:
			this.data.enabled = 1;
			this.data.maxVoltage = 4.2;
			this.data.minVoltage = 0;
			this.data.voltage = 0;
			this.data.maxFailsafeTime = 30000;
			this.data.minFailsafeTime = 500;
			break;
		case DeviceChannelUID._OUT1001_VOLTAGEOUTPUT_100:
		case DeviceChannelUID._OUT1002_VOLTAGEOUTPUT_100:
			this.data.enabled = 1;
			this.data.voltage = 0;
			this.data.voltageOutputRange = Enum.VoltageOutputRange.VOLTS_10;
			break;
		case DeviceChannelUID._OUT1001_VOLTAGEOUTPUT_110:
		case DeviceChannelUID._OUT1002_VOLTAGEOUTPUT_110:
			this.data.enabled = 1;
			this.data.voltage = 0;
			this.data.voltageOutputRange = Enum.VoltageOutputRange.VOLTS_10;
			this.data.maxFailsafeTime = 30000;
			this.data.minFailsafeTime = 500;
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	// eslint-disable-next-line require-await
	async _setDefaults() {
		let bp;

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._OUT1000_VOLTAGEOUTPUT_100:
		case DeviceChannelUID._OUT1000_VOLTAGEOUTPUT_110:
			bp = new BridgePacket();
			bp.set({ name: "0", type: "d", value: this.data.enabled });
			await bp.send(this._ch, BP.SETENABLED);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.voltage });
			await bp.send(this._ch, BP.SETVOLTAGE);
			break;
		case DeviceChannelUID._OUT1001_VOLTAGEOUTPUT_100:
		case DeviceChannelUID._OUT1001_VOLTAGEOUTPUT_110:
		case DeviceChannelUID._OUT1002_VOLTAGEOUTPUT_100:
		case DeviceChannelUID._OUT1002_VOLTAGEOUTPUT_110:
			bp = new BridgePacket();
			bp.set({ name: "0", type: "d", value: this.data.voltageOutputRange });
			await bp.send(this._ch, BP.SETVOLTAGERANGE);
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	_hasInitialState() {


		return true;
	}

	/** @internal */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	_fireInitialEvents() {

	}

	/**
	 * Enable the output voltage by setting `enabled` to true.
	 * 
	 * *   Disable the output by seting `enabled` to false to save power.
	 * @throws {@link PhidgetError}
	 */
	get enabled() { return this.getEnabled(); }
	/**
	 * The minimum value that `failsafeTime` can be set to when calling `enableFailsafe()`.
	 * @throws {@link PhidgetError}
	 */
	get minFailsafeTime() { return this.getMinFailsafeTime(); }
	/**
	 * The maximum value that `failsafeTime` can be set to when calling `enableFailsafe()`.
	 * @throws {@link PhidgetError}
	 */
	get maxFailsafeTime() { return this.getMaxFailsafeTime(); }
	/**
	 * The voltage value that the channel will output.
	 * 
	 * *   The `voltage` value is bounded by `minVoltage` and `maxVoltage`.
	 * *   The voltage value will not be output until `enabled` is set to true.
	 * @throws {@link PhidgetError}
	 */
	get voltage() { return this.getVoltage(); }
	/**
	 * The minimum value that `voltage` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minVoltage() { return this.getMinVoltage(); }
	/**
	 * The maximum value that `voltage` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxVoltage() { return this.getMaxVoltage(); }
	/**
	 * Choose a `voltageOutputRange` that best suits your application.
	 * 
	 * *   Changing the `voltageOutputRange` will also affect the `minVoltage` and `maxVoltage` values.
	 * @throws {@link PhidgetError}
	 */
	get voltageOutputRange() { return this.getVoltageOutputRange(); }

	/**
	 * Enable the output voltage by setting `enabled` to true.
	 * 
	 * *   Disable the output by seting `enabled` to false to save power.
	 * @throws {@link PhidgetError}
	 * @param enabled - The enabled value
	 */
	async setEnabled(enabled: boolean): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (enabled !== false && enabled !== true)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be a boolean.");

		bp.set({ name: "0", type: "d", value: (enabled ? 1 : 0) });
		await bp.send(this._ch, BP.SETENABLED);
	}

	/**
	 * Enable the output voltage by setting `enabled` to true.
	 * 
	 * *   Disable the output by seting `enabled` to false to save power.
	 * @returns The enabled value
	 * @throws {@link PhidgetError}
	 */
	getEnabled(): boolean {
		this._assertOpen();

		if (this.data.enabled === PUNK.BOOL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (!!this.data.enabled);
	}

	/**
	 * Enables the **failsafe** feature for the channel, with a given **failsafe time**.
	 * 
	 * The **failsafe** feature is intended for use in applications where it is important for the channel to enter a known _safe state_ if the program controlling it locks up or crashes. If you do not enable the failsafe feature, the channel will carry out whatever instructions it was last given until it is explicitly told to stop.
	 * 
	 * Enabling the failsafe feature starts a recurring **failsafe timer** for the channel. Once the failsafe timer is enabled, it must be reset within the specified time or the channel will enter a **failsafe state**. The failsafe timer may be reset by sending any valid command to the device\*. Resetting the failsafe timer will reload the timer with the specified _failsafe time_, starting when the message to reset the timer is received by the Phidget.
	 * 
	 * _\*(**get** requests do not typically send commands and won't reset the failsafe timer)_
	 * 
	 * For example: if the failsafe is enabled with a **failsafe time** of 1000ms, you will have 1000ms to reset the failsafe timer. Every time the failsafe timer is reset, you will have 1000ms from that time to reset the failsafe again.
	 * 
	 * If the failsafe timer is not reset before it runs out, the channel will enter a **failsafe state**. For Voltage Output channels, this will set the output voltage to 0V. Once the channel enters the **failsafe state**, it will reject any further input until the channel is reopened.
	 * 
	 * To prevent the channel from falsely entering the failsafe state, we recommend resetting the failsafe timer as frequently as is practical for your application. A good rule of thumb is to not let more than a third of the failsafe time pass before resetting the timer.
	 * 
	 * Once the failsafe timer has been set, it cannot be disabled by any means other than closing and reopening the channel.
	 * @throws {@link PhidgetError}
	 * @param failsafeTime - Failsafe timeout in milliseconds
	 */
	async enableFailsafe(failsafeTime: number): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: failsafeTime });
		await bp.send(this._ch, BP.SETFAILSAFETIME);
	}

	/**
	 * The minimum value that `failsafeTime` can be set to when calling `enableFailsafe()`.
	 * @returns The failsafe time
	 * @throws {@link PhidgetError}
	 */
	getMinFailsafeTime(): number {
		this._assertOpen();

		if (this.data.minFailsafeTime === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minFailsafeTime);
	}

	/**
	 * The maximum value that `failsafeTime` can be set to when calling `enableFailsafe()`.
	 * @returns The failsafe time
	 * @throws {@link PhidgetError}
	 */
	getMaxFailsafeTime(): number {
		this._assertOpen();

		if (this.data.maxFailsafeTime === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxFailsafeTime);
	}

	/**
	 * Resets the failsafe timer, if one has been set. See `enableFailsafe()` for details.
	 * 
	 * This function will fail if no failsafe timer has been set for the channel.
	 * @throws {@link PhidgetError}
	 */
	async resetFailsafe(): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		await bp.send(this._ch, BP.FAILSAFERESET);
	}

	/**
	 * The voltage value that the channel will output.
	 * 
	 * *   The `voltage` value is bounded by `minVoltage` and `maxVoltage`.
	 * *   The voltage value will not be output until `enabled` is set to true.
	 * @returns The voltage value
	 * @throws {@link PhidgetError}
	 */
	getVoltage(): number {
		this._assertOpen();

		if (this.data.voltage === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.voltage);
	}

	/**
	 * The voltage value that the channel will output.
	 * 
	 * *   The `voltage` value is bounded by `minVoltage` and `maxVoltage`.
	 * *   The voltage value will not be output until `enabled` is set to true.
	 * @throws {@link PhidgetError}
	 * @param voltage - The voltage value
	 */
	async setVoltage(voltage: number): Promise<void> {
		this._assertOpen();

		if (voltage < this.data.minVoltage || voltage > this.data.maxVoltage)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minVoltage + " - " + this.data.maxVoltage + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: voltage });
		await bp.send(this._ch, BP.SETVOLTAGE);
	}

	/**
	 * The minimum value that `voltage` can be set to.
	 * @returns The voltage value
	 * @throws {@link PhidgetError}
	 */
	getMinVoltage(): number {
		this._assertOpen();

		if (this.data.minVoltage === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minVoltage);
	}

	/**
	 * The maximum value that `voltage` can be set to.
	 * @returns The voltage value
	 * @throws {@link PhidgetError}
	 */
	getMaxVoltage(): number {
		this._assertOpen();

		if (this.data.maxVoltage === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxVoltage);
	}

	/**
	 * Choose a `voltageOutputRange` that best suits your application.
	 * 
	 * *   Changing the `voltageOutputRange` will also affect the `minVoltage` and `maxVoltage` values.
	 * @returns The output range value
	 * @throws {@link PhidgetError}
	 */
	getVoltageOutputRange(): Enum.VoltageOutputRange {
		this._assertOpen();

		if (this.data.voltageOutputRange === PUNK.ENUM)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.voltageOutputRange);
	}

	/**
	 * Choose a `voltageOutputRange` that best suits your application.
	 * 
	 * *   Changing the `voltageOutputRange` will also affect the `minVoltage` and `maxVoltage` values.
	 * @throws {@link PhidgetError}
	 * @param voltageOutputRange - The output range value
	 */
	async setVoltageOutputRange(voltageOutputRange: Enum.VoltageOutputRange): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (!SEnum.supportedVoltageOutputRange(this._ch!, voltageOutputRange))
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Specified VoltageOutputRange is unsupported by this device.");

		bp.set({ name: "0", type: "d", value: voltageOutputRange });
		await bp.send(this._ch, BP.SETVOLTAGERANGE);
	}

}
export { VoltageOutputBase };
