﻿import { CreatePhidgetChannel } from './CreatePhidgetChannel.gen';
import { Phidget, PhidgetDevice } from './Phidget';
import { Managers, Connections } from './phidget22';
import { logEventException } from './Logging';
import { Channel } from './Channel';
import { Device } from './Device';

/** @public */
export interface ManagerOptions {
	onDeviceAttach?: ((phid: Phidget) => void);
	onDeviceDetach?: ((phid: Phidget) => void);
	onAttach?: ((phid: Phidget) => void);
	onDetach?: ((phid: Phidget) => void);
}

/**
 * @public
 */
class Manager {
	/** @internal */
	private _isopen: boolean;

	/**
	 * **DeviceAttach** event
	 *  * `device` - The Phidget device that attached
	 * ---
	 * Occurs when a device is attached.Phidget devices you get from the manager are informational only, 
	 * you can read information about them such as serial number, class, name, etc.
	 */
	onDeviceAttach: ((this: Manager, device: Phidget) => void) | null = null;

	/**
	 * **DeviceDetach** event
	 *  * `device` - The Phidget device that detached
	 * ---
	 * Occurs when a device is detached
	 */
	onDeviceDetach: ((this: Manager, device: Phidget) => void) | null = null;

	/**
	 * **Attach** event
	 *  * `channel` - The Phidget channel that attached
	 * ---
	 * Occurs when a channel is attached.
	 * *   Phidget channels you get from the manager are informational only. You can read information about 
	 * them such as serial number, class, name, etc. but they are not opened. In order to interact with one, 
	 * you must call `open` to take ownership of the channel, and wait for the attach.
	 */
	onAttach: ((this: Manager, channel: Phidget) => void) | null = null;

	/**
	 * **Detach** event
	 *  * `channel` - The Phidget channel that detached
	 * ---
	 * Occurs when a channel is detached.
	 */
	onDetach: ((this: Manager, channel: Phidget) => void) | null = null;

	/**
	 * The Phidget Manager allows tracking of which Phidgets are available to be controlled from the current program. 
	 * This is useful for listing all available Phidgets so you can select which ones to use at runtime.
	 *
	 * You do not need to use a Phidget Manager if you know what Phidgets will be required for your application 
	 * in advance.
	 *
	 * Phidget channels that become available will each send an Attach event, and Phidgets that are removed 
	 * from the system will send corresponding Detach events. If you are using a Phidget Manager, your program 
	 * is responsible for keeping track of available Phidgets using these events.
	 * 
	 * @param opts - Event callbacks can be specified via this object
	 */
	constructor(opts?: ManagerOptions) {
		this._isopen = false;

		if (typeof opts === 'object') {
			if ('onDeviceAttach' in opts && typeof opts.onDeviceAttach === 'function')
				this.onDeviceAttach = opts.onDeviceAttach;
			if ('onDeviceDetach' in opts && typeof opts.onDeviceDetach === 'function')
				this.onDeviceDetach = opts.onDeviceDetach;
			if ('onAttach' in opts && typeof opts.onAttach === 'function')
				this.onAttach = opts.onAttach;
			if ('onDetach' in opts && typeof opts.onDetach === 'function')
				this.onDetach = opts.onDetach;
		}
	}

	/**
	 * Opens the Phidget Manager.
	 * 
	 * Be sure to register `Attach` and `Detach` event handlers for the Manager before opening it, 
	 * to ensure you program doesn't miss the events reported for devices already connected to your system.
	 */
	open() {
		if (this._isopen)
			return;

		Managers.push(this);

		for (const conn of Connections) {
			if (this.onDeviceAttach) {
				for (const d of conn._devices.values()) {
					const phid = new PhidgetDevice(d);
					try {
						this.onDeviceAttach(phid);
					} catch (err) { logEventException(err); }
				}
			}

			if (this.onAttach) {
				for (const ch of conn._channels.values()) {
					const phid = CreatePhidgetChannel(ch);
					try {
						this.onAttach(phid);
					} catch (err) { logEventException(err); }
				}
			}
		}

		this._isopen = true;
	}

	/**
	 * Closes a Phidget Manager that has been opened.
	 */
	close() {
		this._isopen = false;
		if (Managers.includes(this))
			Managers.splice(Managers.indexOf(this), 1);
	}

	/** @internal */
	_deviceAttach(dev: Device) {
		if (this._isopen && this.onDeviceAttach) {
			const phdev = new PhidgetDevice(dev);
			try {
				this.onDeviceAttach(phdev);
			} catch (err) { logEventException(err); }
		}
	}

	/** @internal */
	_deviceDetach(dev: Device) {
		if (this._isopen && this.onDeviceDetach) {
			const phdev = new PhidgetDevice(dev);
			try {
				this.onDeviceDetach(phdev);
			} catch (err) { logEventException(err); }
		}
	}

	/** @internal */
	_channelAttach(ch: Channel) {
		if (this._isopen && this.onAttach) {
			const phch = CreatePhidgetChannel(ch);
			try {
				this.onAttach(phch);
			} catch (err) { logEventException(err); }
		}
	}

	/** @internal */
	_channelDetach(ch: Channel) {
		if (this._isopen && this.onDetach) {
			const phch = CreatePhidgetChannel(ch);
			try {
				this.onDetach(phch);
			} catch (err) { logEventException(err); }
		}
	}
}

// Export
export { Manager };
