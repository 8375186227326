/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PhidgetChannel } from '../Phidget';
import { Channel } from '../Channel';
import { ErrorCode, ChannelClass } from '../Enumerations.gen';
import { PhidgetError } from '../PhidgetError';
import { BridgePacket, PUNK } from '../BridgePacket';
import { BP } from '../BridgePackets.gen';
import { logEventException } from '../Logging';
import { DeviceChannelUID } from '../Devices.gen';

/** @internal */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface MagnetometerData {
	dataInterval: number,
	maxDataInterval: number,
	minDataRate: number,
	maxDataRate: number,
	maxMagneticField: readonly [number, number, number],
	maxMagneticFieldChangeTrigger: number,
	minDataInterval: number,
	minMagneticField: readonly [number, number, number],
	minMagneticFieldChangeTrigger: number,
	magneticField: readonly [number, number, number],
	magneticFieldChangeTrigger: number,
	axisCount: number,
	timestamp: number,
	heatingEnabled: number,
}

abstract class MagnetometerBase extends PhidgetChannel {
	/** @internal */
	data: MagnetometerData;
	/**
	 * **MagneticFieldChange** event
	 *  * `magneticField` - The magnetic field values
	 *  * `timestamp` - The timestamp value
	 * ---
	 * The most recent magnetic field values the channel has measured will be reported in this event, which occurs when the `dataInterval` has elapsed.
	 * 
	 * *   If a `magneticFieldChangeTrigger` has been set to a non-zero value, the `MagneticFieldChange` event will not occur until the field strength has changed by at least the `magneticFieldChangeTrigger` value.
	 */
	onMagneticFieldChange: ((magneticField: readonly [number, number, number], timestamp: number) => void) | null = null;
	/** @internal */
	_gotMagneticFieldChangeErrorEvent?: boolean;

	/**
	 * The Magnetometer class gathers magnetic compass data from Phidget boards. Phidget magnetometers usually have multiple sensors, each oriented in a different axis, so multiple dimensions of compass bearing can be recorded.
	 * 
	 * If the Phidget you're using also has a gyroscope and an accelerometer, you may want to use the Spatial class in order to get all of the data at the same time, in a single event.
	 * @public
	 */
	constructor();
	/** @internal */
	constructor(ch?: Channel);
	constructor(ch?: Channel) {
		super(ch);
		this._class = ChannelClass.MAGNETOMETER;
		this.name = "Magnetometer";
		this.data = this._initData();
	}

	/** @internal */
	_bridgeInput(bp: BridgePacket) {

		switch(bp.vpkt) {
		case BP.SETCORRECTIONPARAMETERS:
			break;
		case BP.SETDATAINTERVAL:
			if (bp.entryCount > 1)
				this.data.dataInterval = bp.entries[1].v as number;
			else
				this.data.dataInterval = bp.entries[0].v as number;
			this._FIREPropertyChange('DataInterval', bp);
			this._FIREPropertyChange('DataRate', bp);
			break;
		case BP.SETHEATINGENABLED:
			this.data.heatingEnabled = bp.entries[0].v as number;
			this._FIREPropertyChange('HeatingEnabled', bp);
			break;
		case BP.SETCHANGETRIGGER:
			this.data.magneticFieldChangeTrigger = bp.entries[0].v as number;
			this._FIREPropertyChange('MagneticFieldChangeTrigger', bp);
			break;
		case BP.RESETCORRECTIONPARAMETERS:
			break;
		case BP.SAVECORRECTIONPARAMETERS:
			break;
		case BP.FIELDSTRENGTHCHANGE: {
			this.data.magneticField = bp.entries[0].v as [number, number, number];
			this.data.timestamp = bp.entries[1].v as number;
			if (this._isAttachedDone && this.onMagneticFieldChange) {
				try {
					this.onMagneticFieldChange(this.data.magneticField, this.data.timestamp);
				} catch (err) { logEventException(err); }
			}
			break;
		}
		default:
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			throw new PhidgetError(ErrorCode.INVALID_PACKET, "Unsupported bridge packet: 0x" + bp.vpkt!.toString(16));
		}
	}

	/** @internal */
	_initData(): MagnetometerData {
		return {
			dataInterval: PUNK.DBL,
			maxDataInterval: PUNK.UINT32,
			minDataRate: PUNK.DBL,
			maxDataRate: PUNK.DBL,
			maxMagneticField: [PUNK.DBL, PUNK.DBL, PUNK.DBL],
			maxMagneticFieldChangeTrigger: PUNK.DBL,
			minDataInterval: PUNK.UINT32,
			minMagneticField: [PUNK.DBL, PUNK.DBL, PUNK.DBL],
			minMagneticFieldChangeTrigger: PUNK.DBL,
			magneticField: [PUNK.DBL, PUNK.DBL, PUNK.DBL],
			magneticFieldChangeTrigger: PUNK.DBL,
			axisCount: PUNK.INT32,
			timestamp: PUNK.DBL,
			heatingEnabled: PUNK.BOOL,
		}
	}

	/** @internal */
	_initAfterOpen() {
		this.data = this._initData();

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._MOT1101_MAGNETOMETER_100:
		case DeviceChannelUID._MOT1102_MAGNETOMETER_200:
		case DeviceChannelUID._MOT1102_MAGNETOMETER_300:
			this.data.dataInterval = 250;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 50;
			this.data.maxMagneticField = [8, 8, 8];
			this.data.maxMagneticFieldChangeTrigger = 8;
			this.data.minDataInterval = 20;
			this.data.minMagneticField = [-8, -8, -8];
			this.data.minMagneticFieldChangeTrigger = 0;
			this.data.magneticFieldChangeTrigger = 0;
			this.data.axisCount = 3;
			break;
		case DeviceChannelUID._MOT0109_MAGNETOMETER_100:
			this.data.dataInterval = 256;
			this.data.maxDataInterval = 1000;
			this.data.minDataRate = 1;
			this.data.maxDataRate = 250;
			this.data.maxMagneticField = [50, 50, 50];
			this.data.maxMagneticFieldChangeTrigger = 100;
			this.data.minDataInterval = 4;
			this.data.minMagneticField = [-50, -50, -50];
			this.data.minMagneticFieldChangeTrigger = 0;
			this.data.magneticField = (<SpatialDevice>this._ch!.parent).data.magneticField[this._ch!.index] as [number, number, number];
			this.data.magneticFieldChangeTrigger = 0;
			this.data.axisCount = 3;
			this.data.timestamp = (<SpatialDevice>this._ch!.parent).data.timestamp[this._ch!.index];
			this.data.heatingEnabled = 0;
			break;
		case DeviceChannelUID._MOT0110_MAGNETOMETER_100_USB:
			this.data.dataInterval = 250;
			this.data.maxDataInterval = 1000;
			this.data.minDataRate = 1;
			this.data.maxDataRate = 100;
			this.data.maxMagneticField = [8, 8, 8];
			this.data.maxMagneticFieldChangeTrigger = 16;
			this.data.minDataInterval = 10;
			this.data.minMagneticField = [-8, -8, -8];
			this.data.minMagneticFieldChangeTrigger = 0;
			this.data.magneticField = (<SpatialDevice>this._ch!.parent).data.magneticField[this._ch!.index] as [number, number, number];
			this.data.magneticFieldChangeTrigger = 0;
			this.data.axisCount = 3;
			this.data.timestamp = (<SpatialDevice>this._ch!.parent).data.timestamp[this._ch!.index];
			this.data.heatingEnabled = 0;
			break;
		case DeviceChannelUID._MOT0110_MAGNETOMETER_100_VINT:
			this.data.dataInterval = 250;
			this.data.maxDataInterval = 1000;
			this.data.minDataRate = 1;
			this.data.maxDataRate = 100;
			this.data.maxMagneticField = [8, 8, 8];
			this.data.maxMagneticFieldChangeTrigger = 16;
			this.data.minDataInterval = 10;
			this.data.minMagneticField = [-8, -8, -8];
			this.data.minMagneticFieldChangeTrigger = 0;
			this.data.magneticFieldChangeTrigger = 0;
			this.data.axisCount = 3;
			this.data.heatingEnabled = 0;
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	// eslint-disable-next-line require-await
	async _setDefaults() {
		let bp;

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._MOT1101_MAGNETOMETER_100:
		case DeviceChannelUID._MOT1102_MAGNETOMETER_200:
		case DeviceChannelUID._MOT1102_MAGNETOMETER_300:
		case DeviceChannelUID._MOT0109_MAGNETOMETER_100:
		case DeviceChannelUID._MOT0110_MAGNETOMETER_100_USB:
		case DeviceChannelUID._MOT0110_MAGNETOMETER_100_VINT:
			bp = new BridgePacket();
			bp.set({ name: "0", type: "u", value: Math.round(this.data.dataInterval) });
			await bp.send(this._ch, BP.SETDATAINTERVAL);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.magneticFieldChangeTrigger });
			await bp.send(this._ch, BP.SETCHANGETRIGGER);
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	_hasInitialState() {

		if ((this.data.magneticField.includes(PUNK.DBL) ||
			this.data.timestamp == PUNK.DBL)
			&& ! this._gotMagneticFieldChangeErrorEvent)
			return false;

		return true;
	}

	/** @internal */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	_fireInitialEvents() {

		if(!this.data.magneticField.includes(PUNK.DBL) &&
			this.data.timestamp != PUNK.DBL)
			if (this.onMagneticFieldChange)
				try {
					this.onMagneticFieldChange(this.data.magneticField, this.data.timestamp);
				} catch (err) { logEventException(err); }

	}

	/**
	 * The number of axes the channel can measure field strength on.
	 * 
	 * *   See your device's User Guide for more information about the number of axes and their orientation.
	 * @throws {@link PhidgetError}
	 */
	get axisCount() { return this.getAxisCount(); }
	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `MagneticFieldChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * *   The timing between `MagneticFieldChange` events can also be affected by the `magneticFieldChangeTrigger`.
	 * @throws {@link PhidgetError}
	 */
	get dataInterval() { return this.getDataInterval(); }
	/**
	 * The minimum value that `dataInterval` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDataInterval() { return this.getMinDataInterval(); }
	/**
	 * The maximum value that `dataInterval` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDataInterval() { return this.getMaxDataInterval(); }
	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @throws {@link PhidgetError}
	 */
	get dataRate() { return this.getDataRate(); }
	/**
	 * The minimum value that `dataRate` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDataRate() { return this.getMinDataRate(); }
	/**
	 * The maximum value that `dataRate` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDataRate() { return this.getMaxDataRate(); }
	/**
	 * Set to TRUE to enable the temperature stabilization feature of this device. This enables onboard heating elements to bring the board up to a known temperature to minimize ambient temerature effects on the sensor's reading. You can leave this setting FALSE to conserve power consumption.  
	 *   
	 * If you enable heating, it is strongly recommended to keep the board in its enclosure to keep it insulated from moving air.  
	 *   
	 * This property is shared by any and all spatial-related objects on this device (Accelerometer, Gyroscope, Magnetometer, Spatial)
	 * @throws {@link PhidgetError}
	 */
	get heatingEnabled() { return this.getHeatingEnabled(); }
	/**
	 * The most recent field strength value that the channel has reported.
	 * 
	 * *   This value will always be between `minMagneticField` and `maxMagneticField`.
	 * @throws {@link PhidgetError}
	 */
	get magneticField() { return this.getMagneticField(); }
	/**
	 * The minimum value the `MagneticFieldChange` event will report.Any readings outside this range will result in a `Saturation` event. This check is done after calibration values have been applied, which will affect your magnetometer's range accordingly.
	 * @throws {@link PhidgetError}
	 */
	get minMagneticField() { return this.getMinMagneticField(); }
	/**
	 * The maximum value the `MagneticFieldChange` event will report.Any readings outside this range will result in a `Saturation` event. This check is done after calibration values have been applied, which will affect your magnetometer's range accordingly.
	 * @throws {@link PhidgetError}
	 */
	get maxMagneticField() { return this.getMaxMagneticField(); }
	/**
	 * The channel will not issue a `MagneticFieldChange` event until the field strength value has changed by the amount specified by the `magneticFieldChangeTrigger`.
	 * 
	 * *   Setting the `magneticFieldChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering
	 * @throws {@link PhidgetError}
	 */
	get magneticFieldChangeTrigger() { return this.getMagneticFieldChangeTrigger(); }
	/**
	 * The minimum value that `magneticFieldChangeTrigger` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minMagneticFieldChangeTrigger() { return this.getMinMagneticFieldChangeTrigger(); }
	/**
	 * The maximum value that `magneticFieldChangeTrigger` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxMagneticFieldChangeTrigger() { return this.getMaxMagneticFieldChangeTrigger(); }
	/**
	 * The most recent timestamp value that the channel has reported. This is an extremely accurate time measurement streamed from the device.
	 * 
	 * *   If your application requires a time measurement, you should use this value over a local software timestamp.
	 * @throws {@link PhidgetError}
	 */
	get timestamp() { return this.getTimestamp(); }

	/**
	 * The number of axes the channel can measure field strength on.
	 * 
	 * *   See your device's User Guide for more information about the number of axes and their orientation.
	 * @returns The axis count value
	 * @throws {@link PhidgetError}
	 */
	getAxisCount(): number {
		this._assertOpen();

		if (this.data.axisCount === PUNK.INT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.axisCount);
	}

	/**
	 * Calibrate your device for the environment it will be used in.
	 * 
	 * *   Due to physical location, hard and soft iron offsets, and even bias errors, your device should be calibrated. We have created a calibration program that will provide you with the `MagnetometerCorrectionParameters` for your specific situation. See your device's User Guide for more information.
	 * @throws {@link PhidgetError}
	 * @param magneticField - Ambient magnetic field value.
	 * @param offset0 - Provided by calibration program.
	 * @param offset1 - Provided by calibration program.
	 * @param offset2 - Provided by calibration program.
	 * @param gain0 - Provided by calibration program.
	 * @param gain1 - Provided by calibration program.
	 * @param gain2 - Provided by calibration program.
	 * @param T0 - Provided by calibration program.
	 * @param T1 - Provided by calibration program.
	 * @param T2 - Provided by calibration program.
	 * @param T3 - Provided by calibration program.
	 * @param T4 - Provided by calibration program.
	 * @param T5 - Provided by calibration program.
	 */
	async setCorrectionParameters(magneticField: number, offset0: number, offset1: number, offset2: number, gain0: number, gain1: number, gain2: number, T0: number, T1: number, T2: number, T3: number, T4: number, T5: number): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: magneticField });
		bp.set({ name: "1", type: "g", value: offset0 });
		bp.set({ name: "2", type: "g", value: offset1 });
		bp.set({ name: "3", type: "g", value: offset2 });
		bp.set({ name: "4", type: "g", value: gain0 });
		bp.set({ name: "5", type: "g", value: gain1 });
		bp.set({ name: "6", type: "g", value: gain2 });
		bp.set({ name: "7", type: "g", value: T0 });
		bp.set({ name: "8", type: "g", value: T1 });
		bp.set({ name: "9", type: "g", value: T2 });
		bp.set({ name: "10", type: "g", value: T3 });
		bp.set({ name: "11", type: "g", value: T4 });
		bp.set({ name: "12", type: "g", value: T5 });
		await bp.send(this._ch, BP.SETCORRECTIONPARAMETERS);
	}

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `MagneticFieldChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * *   The timing between `MagneticFieldChange` events can also be affected by the `magneticFieldChangeTrigger`.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getDataInterval(): number {
		this._assertOpen();

		if (this.data.dataInterval === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return this.data.dataInterval;
	}

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `MagneticFieldChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * *   The timing between `MagneticFieldChange` events can also be affected by the `magneticFieldChangeTrigger`.
	 * @throws {@link PhidgetError}
	 * @param dataInterval - The data interval value
	 */
	async setDataInterval(dataInterval: number): Promise<void> {
		this._assertOpen();

		if (dataInterval < this.data.minDataInterval || dataInterval > this.data.maxDataInterval)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDataInterval + " - " + this.data.maxDataInterval + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: dataInterval });
		await bp.send(this._ch, BP.SETDATAINTERVAL);
	}

	/**
	 * The minimum value that `dataInterval` can be set to.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getMinDataInterval(): number {
		this._assertOpen();

		if (this.data.minDataInterval === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDataInterval);
	}

	/**
	 * The maximum value that `dataInterval` can be set to.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getMaxDataInterval(): number {
		this._assertOpen();

		if (this.data.maxDataInterval === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDataInterval);
	}

	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @returns The data rate for the channel
	 * @throws {@link PhidgetError}
	 */
	getDataRate(): number {
		this._assertOpen();

		if (this.data.dataInterval === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (1000.0 / this.data.dataInterval);
	}

	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @throws {@link PhidgetError}
	 * @param dataRate - The data rate for the channel
	 */
	async setDataRate(dataRate: number): Promise<void> {
		this._assertOpen();

		if (dataRate < this.data.minDataRate || dataRate > this.data.maxDataRate)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDataRate + " - " + this.data.maxDataRate + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: Math.round(1000.0 / dataRate) });
		bp.set({ name: "1", type: "g", value: (1000.0 / dataRate) });
		await bp.send(this._ch, BP.SETDATAINTERVAL);
	}

	/**
	 * The minimum value that `dataRate` can be set to.
	 * @returns The data rate value
	 * @throws {@link PhidgetError}
	 */
	getMinDataRate(): number {
		this._assertOpen();

		if (this.data.minDataRate === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDataRate);
	}

	/**
	 * The maximum value that `dataRate` can be set to.
	 * @returns The data rate value
	 * @throws {@link PhidgetError}
	 */
	getMaxDataRate(): number {
		this._assertOpen();

		if (this.data.maxDataRate === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDataRate);
	}

	/**
	 * Set to TRUE to enable the temperature stabilization feature of this device. This enables onboard heating elements to bring the board up to a known temperature to minimize ambient temerature effects on the sensor's reading. You can leave this setting FALSE to conserve power consumption.  
	 *   
	 * If you enable heating, it is strongly recommended to keep the board in its enclosure to keep it insulated from moving air.  
	 *   
	 * This property is shared by any and all spatial-related objects on this device (Accelerometer, Gyroscope, Magnetometer, Spatial)
	 * @returns Whether self-heating temperature stabilization is enabled
	 * @throws {@link PhidgetError}
	 */
	getHeatingEnabled(): boolean {
		this._assertOpen();

		if (this.data.heatingEnabled === PUNK.BOOL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (!!this.data.heatingEnabled);
	}

	/**
	 * Set to TRUE to enable the temperature stabilization feature of this device. This enables onboard heating elements to bring the board up to a known temperature to minimize ambient temerature effects on the sensor's reading. You can leave this setting FALSE to conserve power consumption.  
	 *   
	 * If you enable heating, it is strongly recommended to keep the board in its enclosure to keep it insulated from moving air.  
	 *   
	 * This property is shared by any and all spatial-related objects on this device (Accelerometer, Gyroscope, Magnetometer, Spatial)
	 * @throws {@link PhidgetError}
	 * @param heatingEnabled - Whether self-heating temperature stabilization is enabled
	 */
	async setHeatingEnabled(heatingEnabled: boolean): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (heatingEnabled !== false && heatingEnabled !== true)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be a boolean.");

		bp.set({ name: "0", type: "d", value: (heatingEnabled ? 1 : 0) });
		await bp.send(this._ch, BP.SETHEATINGENABLED);
	}

	/**
	 * The most recent field strength value that the channel has reported.
	 * 
	 * *   This value will always be between `minMagneticField` and `maxMagneticField`.
	 * @returns The channel's measured MagneticField
	 * @throws {@link PhidgetError}
	 */
	getMagneticField(): readonly [number, number, number] {
		this._assertOpen();

		if (this.data.magneticField.includes(PUNK.DBL))
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.magneticField);
	}

	/**
	 * The minimum value the `MagneticFieldChange` event will report.Any readings outside this range will result in a `Saturation` event. This check is done after calibration values have been applied, which will affect your magnetometer's range accordingly.
	 * @returns The field strength value
	 * @throws {@link PhidgetError}
	 */
	getMinMagneticField(): readonly [number, number, number] {
		this._assertOpen();

		if (this.data.minMagneticField.includes(PUNK.DBL))
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minMagneticField);
	}

	/**
	 * The maximum value the `MagneticFieldChange` event will report.Any readings outside this range will result in a `Saturation` event. This check is done after calibration values have been applied, which will affect your magnetometer's range accordingly.
	 * @returns The field strength value
	 * @throws {@link PhidgetError}
	 */
	getMaxMagneticField(): readonly [number, number, number] {
		this._assertOpen();

		if (this.data.maxMagneticField.includes(PUNK.DBL))
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxMagneticField);
	}

	/**
	 * The channel will not issue a `MagneticFieldChange` event until the field strength value has changed by the amount specified by the `magneticFieldChangeTrigger`.
	 * 
	 * *   Setting the `magneticFieldChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering
	 * @returns The change trigger value
	 * @throws {@link PhidgetError}
	 */
	getMagneticFieldChangeTrigger(): number {
		this._assertOpen();

		if (this.data.magneticFieldChangeTrigger === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.magneticFieldChangeTrigger);
	}

	/**
	 * The channel will not issue a `MagneticFieldChange` event until the field strength value has changed by the amount specified by the `magneticFieldChangeTrigger`.
	 * 
	 * *   Setting the `magneticFieldChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering
	 * @throws {@link PhidgetError}
	 * @param magneticFieldChangeTrigger - The change trigger value
	 */
	async setMagneticFieldChangeTrigger(magneticFieldChangeTrigger: number): Promise<void> {
		this._assertOpen();

		if (magneticFieldChangeTrigger < this.data.minMagneticFieldChangeTrigger || magneticFieldChangeTrigger > this.data.maxMagneticFieldChangeTrigger)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minMagneticFieldChangeTrigger + " - " + this.data.maxMagneticFieldChangeTrigger + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: magneticFieldChangeTrigger });
		await bp.send(this._ch, BP.SETCHANGETRIGGER);
	}

	/**
	 * The minimum value that `magneticFieldChangeTrigger` can be set to.
	 * @returns The change trigger value
	 * @throws {@link PhidgetError}
	 */
	getMinMagneticFieldChangeTrigger(): number {
		this._assertOpen();

		if (this.data.minMagneticFieldChangeTrigger === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minMagneticFieldChangeTrigger);
	}

	/**
	 * The maximum value that `magneticFieldChangeTrigger` can be set to.
	 * @returns The change trigger value
	 * @throws {@link PhidgetError}
	 */
	getMaxMagneticFieldChangeTrigger(): number {
		this._assertOpen();

		if (this.data.maxMagneticFieldChangeTrigger === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxMagneticFieldChangeTrigger);
	}

	/**
	 * Resets the `MagnetometerCorrectionParameters` to their default values.
	 * 
	 * *   Due to physical location, hard and soft iron offsets, and even bias errors, your device should be calibrated. We have created a calibration program that will provide you with the `MagnetometerCorrectionParameters` for your specific situation. See your device's User Guide for more information.
	 * @throws {@link PhidgetError}
	 */
	async resetCorrectionParameters(): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		await bp.send(this._ch, BP.RESETCORRECTIONPARAMETERS);
	}

	/**
	 * Saves the `MagnetometerCorrectionParameters`.
	 * 
	 * *   Due to physical location, hard and soft iron offsets, and even bias errors, your device should be calibrated. We have created a calibration program that will provide you with the `MagnetometerCorrectionParameters` for your specific situation. See your device's User Guide for more information.
	 * @throws {@link PhidgetError}
	 */
	async saveCorrectionParameters(): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		await bp.send(this._ch, BP.SAVECORRECTIONPARAMETERS);
	}

	/**
	 * The most recent timestamp value that the channel has reported. This is an extremely accurate time measurement streamed from the device.
	 * 
	 * *   If your application requires a time measurement, you should use this value over a local software timestamp.
	 * @returns The timestamp value
	 * @throws {@link PhidgetError}
	 */
	getTimestamp(): number {
		this._assertOpen();

		if (this.data.timestamp === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.timestamp);
	}

}
import { type SpatialDevice } from '../usb/device/SpatialDevice';
export { MagnetometerBase };
