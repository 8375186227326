﻿/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { logdebug, loginfo, logerr, logEventException, logwarn } from './Logging';
import { PhidgetError } from './PhidgetError';
import { ErrorCode, DeviceClass, ChannelClass, ErrorEventCode, ChannelSubclass } from './Enumerations.gen';
import { UserPhidgets, scanChannels, tm, LibraryVersion, startScanningUserPhidgets, stopScanningUserPhidgets } from './phidget22';
import { BridgePacket, PUNK } from './BridgePacket';
import { ChannelClassName } from './ChannelClassName.gen';
import { DeviceClassName } from './DeviceClassName.gen';
import { BP } from './BridgePackets.gen';
import { Channel } from './Channel';
import { Device } from './Device';
import { PhidgetBase } from './Phidget.gen';

/** @public */
abstract class Phidget extends PhidgetBase {
	/** @internal */
	protected _fromManager: boolean;
	/** @internal */
	_isattached: boolean;

	/**
	 * **Attach** event
	 * 
	 * ---
	 * Occurs when the channel is attached to a physical channel on a Phidget.
	 * 
	 * `Attach` must be registered prior to calling `open()`, and will be called when the 
	 * Phidget library matches the channel with a physical channel on a Phidget. `Attach` 
	 * may be called more than once if the channel is detached during its lifetime.
	 * 
	 * `Attach` is the recommended place to configuration properties of the channel such 
	 * as the data interval or change trigger.
	 */
	onAttach: ((this: Phidget, ch: Phidget) => void | Promise<void>) | null;

	/**
	 * **Detach** event
	 * 
	 * ---
	 * Occurs when the channel is detached from a Phidget device channel.Detach typically occurs 
	 * when the Phidget device is removed from the system.
	 */
	onDetach: ((this: Phidget, ch: Phidget) => void) | null;

	/**
	* **Error** event
	*  * `code` - The error code
	*  * `msg` - The error description
	* ---
	* Error is called when an error condition has been detected.
	*
	* See the documentation for your specific channel class to see what error events it might throw.
	* @param code - The error code
	* @param description - The error description
	*/
	onError: ((this: Phidget, code: ErrorEventCode, description: string) => void) | null;

	/**
	* **PropertyChange** event
	*  * `propertyName` - The name of the property that has changed
	* ---
	* Occurs when a property is changed externally from the user channel, usually from a network client attached to the same channel.
	* @param propertyName - The name of the property that has changed
	*/
	onPropertyChange: ((this: Phidget, propertyName: string) => void) | null;

	/**
	 * The channel name
	 */
	public name!: string;

	/**
	 * Gets the version of the Phidget library being used by the program in human readable form.
	 * @returns The Phidget library version.
	 * @throws {@link PhidgetError}
	*/
	static get libraryVersion() { return this.getLibraryVersion(); }

	/**
	 * Gets the version of the Phidget library being used by the program in human readable form.
	 * @returns The Phidget library version.
	 * @throws {@link PhidgetError}
	*/
	static getLibraryVersion() { return LibraryVersion; }

	/** @internal */
	constructor(chOrDev?: Channel | Device) {
		super();

		this.onAttach = null;
		this.onDetach = null;
		this.onError = null;
		this.onPropertyChange = null;

		if (chOrDev !== undefined) {
			this._fromManager = true;
			this._isattached = true;
		} else {
			this._fromManager = false;
			this._isattached = false;
		}
	}
}

/** @public */
abstract class PhidgetChannel extends Phidget {
	// Guaranteed to be filled in by subclasses
	/** @internal */
	_class!: ChannelClass;

	/** @internal */
	_isopen: boolean;
	/** @internal */
	_attaching: boolean;

	/** @internal */
	private _useropen: boolean;
	/** @internal */
	private _detaching: boolean;

	/** @internal */
	_ch?: Channel;

	/** @internal */
	_openTimeout?: number;
	/** @internal */
	_openTime?: number;
	/** @internal */
	_openTimeoutTimer?: NodeJS.Timeout;

	/** @internal */
	_channel: number;
	/** @internal */
	_deviceLabel: string | null;
	/** @internal */
	_serialNumber: number;
	/** @internal */
	_hubPort: number;
	/** @internal */
	_isHubPort: boolean;
	/** @internal */
	_isLocal: boolean;
	/** @internal */
	_isRemote: boolean;

	/** @internal */
	private _rejectOpen?: ((reason: PhidgetError) => void);
	/** @internal */
	_resolveOpen?: (() => void);
	/** @internal */
	_onInitialState?: (() => void);

	// Required
	/** @internal */
	abstract _initAfterOpen(): void;
	/** @internal */
	abstract _setDefaults(): Promise<void>;
	/** @internal */
	abstract _fireInitialEvents(): void;
	/** @internal */
	abstract _hasInitialState(): boolean;
	/** @internal */
	abstract _bridgeInput(bp: BridgePacket): void;

	/** @internal */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	abstract data: any;

	// Optional
	/** @internal */
	_errorHandler?(code: ErrorEventCode): void;

	/** @internal */
	constructor(ch?: Channel) {
		super(ch);

		this._attaching = false;	/* in the process of being attached to a server channel */
		this._useropen = false;	/* user called open: registered to attached to a channel */
		this._isopen = false;	/* user opened, and attached to a channel */
		this._detaching = false;

		this._isHubPort = false;
		this._channel = Phidget.ANY_CHANNEL;
		this._deviceLabel = Phidget.ANY_LABEL;
		this._serialNumber = Phidget.ANY_SERIAL_NUMBER;
		this._hubPort = Phidget.ANY_HUB_PORT;
		this._isLocal = false;
		this._isRemote = false;

		this._ch = ch;
	}

	/** @internal */
	_cancelOpenTimeout() {
		// Cancel open timeout
		if (this._openTimeoutTimer != undefined) {
			clearTimeout(this._openTimeoutTimer);
			delete this._openTimeoutTimer;
		}
		delete this._openTimeout;
		delete this._openTime;
	}

	/** @internal */
	async _wasOpened(ch: Channel) {
		this._ch = ch;
		this._isopen = true;
		this._isattached = true;
		this._cancelOpenTimeout();

		if (this.onAttach) {
			try {
				await this.onAttach(this);
			} catch (err) { logEventException(err); }
		}

		if (this._resolveOpen)
			this._resolveOpen();
	}

	/** @internal */
	async _close(fromDetach = false) {

		this._cancelOpenTimeout();

		/*
		 * If we are called because the device is detaching, do not deregister, and
		 * flag as user opened.
		 */
		if (fromDetach) {
			this._useropen = true;
		} else {
			if (UserPhidgets.includes(this))
				UserPhidgets.splice(UserPhidgets.indexOf(this), 1);
			if (UserPhidgets.length === 0)
				await stopScanningUserPhidgets();
		}

		if (this._isopen) {
			try {
				if (this._ch)
					await this._ch.close();
			} catch (err) {
				// We don't actually want to throw an exception since we are just closing anyway, but log the error
				logwarn("Error while closing channel", err);
			}
			this._detaching = true;
			this._isattached = false;
			if (this.onDetach) {
				try {
					this.onDetach(this);
				} catch (err) { logEventException(err); }
			}
			this._detaching = false;
			this._isopen = false;
			delete this._ch;
		} else {
			if (this._rejectOpen != undefined)
				this._rejectOpen(new PhidgetError(ErrorCode.CLOSED, "Closed while waiting for open"));
		}
	}

	/** @internal */
	_FIREPropertyChange(prop: string, bp?: BridgePacket) {
		if (this.onPropertyChange && this._isAttachedDone && (bp === undefined || bp.local !== true)) {
			try {
				this.onPropertyChange(prop);
			} catch (err) { logEventException(err); }
		}
	}

	/** @internal */
	_handleSetStatus(bp: BridgePacket) {

		for (const e in bp.entries) {

			// Not used
			if (e === '_class_version_')
				continue;

			// Dealt with below
			if (e === 'dataIntervalDbl')
				continue;

			if (!(e in this.data)) {
				loginfo("Unknown property: " + e + " in setstatus packet. Probably server is newer than client.");
				continue;
			}

			this.data[e] = bp.entries[e].v;
		}

		// If floating point data interval exists, use it as the data interval
		if (bp.entries.dataIntervalDbl !== undefined)
			this.data.dataInterval = bp.entries.dataIntervalDbl.v;

		// If min/max data rate are missing from the bp (because old server), create them
		if (this.data.minDataInterval != undefined && bp.entries.maxDataRate === undefined)
			this.data.maxDataRate = (1000.0 / this.data.minDataInterval);
		if (this.data.maxDataInterval != undefined && bp.entries.minDataRate === undefined)
			this.data.minDataRate = (1000.0 / this.data.maxDataInterval);
	}

	/** @internal */
	_assertAttached() {

		// Attached but maybe not open - good for Manager channels
		if (!this._ch || (!this._isattached && !this._detaching))
			throw (new PhidgetError(ErrorCode.NOT_ATTACHED));
	}

	/** @internal */
	_assertOpen() {

		// Attached AND opened - we can interact with the device
		if (!this._ch || !this._isopen || (!this._isattached && !this._detaching))
			throw (new PhidgetError(ErrorCode.NOT_ATTACHED));
	}

	/** @internal */
	get _isAttachedDone() {
		if (this._isattached && !this._attaching)
			return true;
		return false;
	}
	/** @internal */
	get _isAttachedOrDetaching() {
		if (this._isattached || this._detaching)
			return true;
		return false;
	}

	toString() {

		if (this._ch)
			return (this._ch.toString());
		return (this.constructor.name);
	}

	/******************************
	 *******  Phidget API  ********
	 ******************************/

	getConnection() {
		if (this._ch)
			return (this._ch.conn);
		return null;
	}

	getKey() {
		this._assertAttached();
		return 'ch' + this._ch!.id + "_" + this._ch!.conn._id;
	}

	async open(timeout = 0): Promise<void> {
		if (this._useropen)
			return;

		this._useropen = true;
		this._openTime = tm();
		if (!isNaN(timeout) && timeout > 0)
			this._openTimeout = timeout;
		else
			delete this._openTimeout;

		UserPhidgets.push(this);

		// Open a channel from the manager
		if (this._fromManager === true) {
			// Set all matching parameters
			this.setChannel(this.getChannel());
			this.setHubPort(this.getHubPort());
			this.setDeviceSerialNumber(this.getDeviceSerialNumber());
			this.setIsHubPortDevice(this.getIsHubPortDevice());
			this.setIsLocal(this.getIsLocal());
			this.setIsRemote(this.getIsRemote());
			// Reset 'fromManager' params
			this._fromManager = false;
			this._isattached = false;
			delete this._ch;
		}

		// Create here because scanChannels may call resolveOpen immediately
		const openPromise = new Promise<void>((resolve, reject) => {
			this._resolveOpen = resolve;
			this._rejectOpen = reject;
		});

		try {
			// Do an immediate scan so an already connected channel will open quickly
			await scanChannels(this);
		} catch (err) {
			// If this fails, it's ok - it's going to keep scanning
			logerr("Problem during open scan", err);
		}

		// Make sure we are scanning for future-connected channels
		await startScanningUserPhidgets();

		// If we specified a Timeout, run it
		if (this._openTimeout) {
			this._openTimeoutTimer = setTimeout(() => {
				delete this._openTimeoutTimer;
				const reject = this._rejectOpen;
				delete this._rejectOpen;
				this.close().then(() => {
					if (reject)
						reject(new PhidgetError(ErrorCode.TIMEOUT, "Open timed out"));
				}).catch(err => {
					logwarn("Error calling close after open timeout", err);
					if (reject)
						reject(new PhidgetError(ErrorCode.TIMEOUT, "Open timed out"));
				});
			}, this._openTimeout);
		}

		return openPromise;
	}

	async close() {
		if (this._useropen) {
			logdebug("closing phidget");
			this._useropen = false;
			await this._close();
		}
	}

	getAttached() {
		return (this._isattached);
	}

	getIsOpen() {
		return (this._useropen);
	}

	getChannel() {
		if (!this._isattached && !this._detaching)
			return (this._channel);

		return (this._ch!.index);
	}
	setChannel(ch: number) {
		this._channel = ch;
	}

	getChannelClass() {
		return (this._class);
	}

	getChannelClassName() {
		return (ChannelClassName[this._class]);
	}

	getChannelName() {
		this._assertAttached();
		return (this._ch!.name);
	}

	getChannelSubclass() {
		this._assertAttached();
		return (this._ch!.subclass);
	}

	getDeviceClass() {
		this._assertAttached();
		return (this._ch!.parent.class);
	}

	getDeviceClassName() {
		this._assertAttached();
		return (DeviceClassName[this._ch!.parent.class]);
	}

	getDeviceID() {
		this._assertAttached();
		return (this._ch!.parent.deviceID);
	}

	getDeviceLabel() {
		if (!this._isattached && !this._detaching)
			return (this._deviceLabel ? this._deviceLabel : '');
		return (this._ch!.parent.label);
	}

	setDeviceLabel(label: string) {
		this._deviceLabel = label;
	}

	getDeviceName(): string {
		this._assertAttached();
		return (this._ch!.parent.name);
	}

	getDeviceSerialNumber() {
		if (!this._isattached && !this._detaching)
			return (this._serialNumber);
		return (this._ch!.parent.serialNumber);
	}
	setDeviceSerialNumber(sn: number) {
		if (!isNaN(sn))
			this._serialNumber = sn;
	}

	getDeviceSKU() {
		this._assertAttached();
		return (this._ch!.parent.sku);
	}

	getDeviceVersion() {
		this._assertAttached();
		return (this._ch!.parent.version);
	}

	getHub(): Phidget {
		this._assertAttached();

		let hub: Device | undefined = this._ch!.parent;
		while (hub != undefined && hub.class !== DeviceClass.HUB)
			hub = hub.parent;

		if (hub == undefined)
			throw new PhidgetError(ErrorCode.WRONG_DEVICE, "Hub not found");

		return (new PhidgetDevice(hub));
	}

	getHubPort() {
		if (!this._isattached && !this._detaching)
			return (this._hubPort);
		return (this._ch!.parent.hubPort);
	}

	setHubPort(hubPort: number) {
		if (!isNaN(hubPort))
			this._hubPort = hubPort;
	}

	getHubPortSpeed(): number {
		this._assertAttached();

		if (this._ch!.parent.class !== DeviceClass.VINT)
			throw new PhidgetError(ErrorCode.WRONG_DEVICE);

		if (this._ch!.parent.vintDeviceProps!.commSpeed == undefined || this._ch!.parent.vintDeviceProps!.commSpeed == PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this._ch!.parent.vintDeviceProps!.commSpeed);
	}
	async setHubPortSpeed(hubPortSpeed: number) {
		this._assertAttached();

		if (this._ch!.parent.class !== DeviceClass.VINT)
			throw new PhidgetError(ErrorCode.WRONG_DEVICE);

		const bp = new BridgePacket();
		bp.set({ name: '0', type: 'u', value: hubPortSpeed });
		await bp.send(this._ch, BP.SETVINTSPEED);
	}

	getIsChannel() {
		return (true);
	}

	getIsHubPortDevice() {
		if (!this._isattached && !this._detaching)
			return (this._isHubPort);
		return (this._ch!.parent.isHubPort);
	}

	setIsHubPortDevice(isHubPort: boolean) {
		this._isHubPort = isHubPort;
	}

	getIsLocal() {
		if (!this._isattached && !this._detaching)
			return (this._isLocal);
		return (this._ch!.parent.conn._isLocal);
	}
	setIsLocal(isLocal: boolean) {
		this._isLocal = isLocal;
	}

	getIsRemote() {
		if (!this._isattached && !this._detaching)
			return (this._isRemote);
		return (this._ch!.parent.conn._isRemote);
	}
	setIsRemote(isRemote: boolean) {
		this._isRemote = isRemote;
	}

	getParent(): Phidget {
		this._assertAttached();
		return (new PhidgetDevice(this._ch!.parent));
	}

	getDeviceFirmwareUpgradeString() {
		this._assertAttached();
		return (this._ch!.parent.fwstr);
	}

	async writeDeviceLabel(deviceLabel: string) {
		this._assertOpen();
		const bp = new BridgePacket();
		bp.set({ name: "0", type: "s", value: deviceLabel });
		await bp.send(this._ch, BP.WRITELABEL);
	}

	getDeviceChannelCount(cls?: ChannelClass): number {
		this._assertAttached();
		let count = 0;
		for (const ch of this._ch!.parent.devDef.ch) {
			// NOTE: Unsupported on -net build
			if (cls != undefined && ch.c == undefined)
				throw new PhidgetError(ErrorCode.UNSUPPORTED);
			if (cls == undefined || ch.c === cls)
				count++;
		}
		return count;
	}

	getDeviceVINTID(): number {
		this._assertAttached();
		if (this.deviceClass !== DeviceClass.VINT)
			throw new PhidgetError(ErrorCode.WRONG_DEVICE);
		return (this._ch!.parent.vintID);
	}

	getHubPortCount(): number {
		this._assertAttached();
		if (this.deviceClass !== DeviceClass.VINT && this.deviceClass !== DeviceClass.HUB)
			throw new PhidgetError(ErrorCode.WRONG_DEVICE);
		return (this.hub as PhidgetDevice)._device.hubPortProps!.length;
	}

	getHubPortSupportsSetSpeed(): boolean {
		this._assertAttached();
		if (this.deviceClass !== DeviceClass.VINT)
			throw new PhidgetError(ErrorCode.WRONG_DEVICE);
		return (this.hub as PhidgetDevice)._device.hubPortProps![this.hubPort].portSuppSetSpeed;
	}

	getHubPortSupportsAutoSetSpeed(): boolean {
		this._assertAttached();
		if (this.deviceClass !== DeviceClass.VINT)
			throw new PhidgetError(ErrorCode.WRONG_DEVICE);
		return (this.hub as PhidgetDevice)._device.hubPortProps![this.hubPort].portSuppAutoSetSpeed;
	}

	getMaxHubPortSpeed(): number {
		this._assertAttached();
		if (this.deviceClass !== DeviceClass.VINT)
			throw new PhidgetError(ErrorCode.WRONG_DEVICE);
		if ((this.hub as PhidgetDevice)._device.hubPortProps![this.hubPort].portMaxSpeed === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);
		return (this.hub as PhidgetDevice)._device.hubPortProps![this.hubPort].portMaxSpeed;
	}

	getVINTDeviceSupportsSetSpeed(): boolean {
		this._assertAttached();
		if (this.deviceClass !== DeviceClass.VINT)
			throw new PhidgetError(ErrorCode.WRONG_DEVICE);
		return this._ch!.parent.vintDeviceProps!.suppSetSpeed;
	}

	getVINTDeviceSupportsAutoSetSpeed(): boolean {
		this._assertAttached();
		if (this.deviceClass !== DeviceClass.VINT)
			throw new PhidgetError(ErrorCode.WRONG_DEVICE);
		return this._ch!.parent.vintDeviceProps!.suppAutoSetSpeed;
	}

	getMaxVINTDeviceSpeed(): number {
		this._assertAttached();
		if (this.deviceClass !== DeviceClass.VINT)
			throw new PhidgetError(ErrorCode.WRONG_DEVICE);
		if (this._ch!.parent.vintDeviceProps!.maxSpeed === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);
		return this._ch!.parent.vintDeviceProps!.maxSpeed;
	}

	async reboot(): Promise<void> {
		this._assertOpen();
		const bp = new BridgePacket();
		await bp.send(this._ch, BP.REBOOT, false);
	}

	async rebootFirmwareUpgrade(upgradeTimeout: number): Promise<void> {
		this._assertOpen();
		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: upgradeTimeout });
		await bp.send(this._ch, BP.REBOOTFIRMWAREUPGRADE, false);
	}
}

/** @public */
class PhidgetDevice extends Phidget {
	/** @internal */
	_device: Device;

	/** @internal */
	constructor(dev: Device) {
		super(dev);
		this._device = dev;
		this.name = "PhidgetDevice";
	}

	toString() {
		return (this._device.toString());
	}

	/******************************
	 *******  Phidget API  ********
	 ******************************/

	getConnection() {
		return (this._device.conn);
	}

	getKey(): string {
		return 'dev' + this._device.id + "_" + this._device.conn._id;
	}

	getAttached() {
		return (true);
	}
	
	getIsOpen() {
		return (false);
	}

	getDeviceClass() {
		return (this._device.class);
	}

	getDeviceClassName() {
		return (DeviceClassName[this._device.class]);
	}

	getDeviceID() {
		return (this._device.deviceID);
	}

	getDeviceLabel() {
		return (this._device.label);
	}

	getDeviceName(): string {
		return (this._device.name);
	}

	getDeviceSerialNumber() {
		return (this._device.serialNumber);
	}

	getDeviceSKU() {
		return (this._device.sku);
	}

	getDeviceVersion() {
		return (this._device.version);
	}

	getHub(): Phidget {
		let hub: Device | undefined = this._device;
		while (hub != undefined && hub.class !== DeviceClass.HUB)
			hub = hub.parent;

		if (hub == undefined)
			throw new PhidgetError(ErrorCode.WRONG_DEVICE, "Hub not found");

		return (new PhidgetDevice(hub));
	}

	getHubPort() {
		return (this._device.hubPort);
	}

	getHubPortSpeed() {
		if (this._device.class !== DeviceClass.VINT)
			throw new PhidgetError(ErrorCode.WRONG_DEVICE);

		if (this._device.vintDeviceProps!.commSpeed == undefined || this._device.vintDeviceProps!.commSpeed == PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this._device.vintDeviceProps!.commSpeed);
	}

	getIsChannel() {
		return (false);
	}

	getIsHubPortDevice() {
		return (this._device.isHubPort);
	}

	getIsLocal() {
		return (this._device.conn._isLocal);
	}

	getIsRemote() {
		return (this._device.conn._isRemote);
	}

	getParent(): Phidget | null {
		const parent = this._device.parent;

		if (!parent)
			return null;

		return (new PhidgetDevice(parent));
	}

	getDeviceFirmwareUpgradeString() {
		return this._device.fwstr;
	}

	getDeviceChannelCount(cls?: ChannelClass): number {
		let count = 0;
		for (const ch of this._device.devDef.ch) {
			// NOTE: Unsupported on -net build
			if (cls != undefined && ch.c == undefined)
				throw new PhidgetError(ErrorCode.UNSUPPORTED);
			if (cls == undefined || ch.c === cls)
				count++;
		}
		return count;
	}

	getDeviceVINTID(): number {
		if (this.deviceClass !== DeviceClass.VINT)
			throw new PhidgetError(ErrorCode.WRONG_DEVICE);

		return this._device.vintID;
	}

	getHubPortCount(): number {
		if (this.deviceClass !== DeviceClass.VINT && this.deviceClass !== DeviceClass.HUB)
			throw new PhidgetError(ErrorCode.WRONG_DEVICE);
			
		return (this.hub as PhidgetDevice)._device.hubPortProps!.length;
	}

	getHubPortSupportsSetSpeed(): boolean {
		if (this.deviceClass !== DeviceClass.VINT)
			throw new PhidgetError(ErrorCode.WRONG_DEVICE);

		return (this.hub as PhidgetDevice)._device.hubPortProps![this.hubPort].portSuppSetSpeed;
	}

	getHubPortSupportsAutoSetSpeed(): boolean {
		if (this.deviceClass !== DeviceClass.VINT)
			throw new PhidgetError(ErrorCode.WRONG_DEVICE);

		return (this.hub as PhidgetDevice)._device.hubPortProps![this.hubPort].portSuppAutoSetSpeed;
	}

	getMaxHubPortSpeed(): number {
		if (this.deviceClass !== DeviceClass.VINT)
			throw new PhidgetError(ErrorCode.WRONG_DEVICE);

		if ((this.hub as PhidgetDevice)._device.hubPortProps![this.hubPort].portMaxSpeed === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.hub as PhidgetDevice)._device.hubPortProps![this.hubPort].portMaxSpeed;
	}

	getVINTDeviceSupportsSetSpeed(): boolean {
		if (this.deviceClass !== DeviceClass.VINT)
			throw new PhidgetError(ErrorCode.WRONG_DEVICE);

		return this._device.vintDeviceProps!.suppSetSpeed;
	}

	getVINTDeviceSupportsAutoSetSpeed(): boolean {
		if (this.deviceClass !== DeviceClass.VINT)
			throw new PhidgetError(ErrorCode.WRONG_DEVICE);

		return this._device.vintDeviceProps!.suppAutoSetSpeed;
	}

	getMaxVINTDeviceSpeed(): number {
		if (this.deviceClass !== DeviceClass.VINT)
			throw new PhidgetError(ErrorCode.WRONG_DEVICE);

		if (this._device.vintDeviceProps!.maxSpeed === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return this._device.vintDeviceProps!.maxSpeed;
	}

	// These channel API calls are unexpected on a PhidgetDevice
	getChannel(): number {
		throw new PhidgetError(ErrorCode.UNEXPECTED);
	}
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setChannel(_channel: number): void {
		throw new PhidgetError(ErrorCode.UNEXPECTED);
	}
	getChannelClass(): ChannelClass {
		throw new PhidgetError(ErrorCode.UNEXPECTED);
	}
	getChannelClassName(): string {
		throw new PhidgetError(ErrorCode.UNEXPECTED);
	}
	getChannelName(): string {
		throw new PhidgetError(ErrorCode.UNEXPECTED);
	}
	getChannelSubclass(): ChannelSubclass {
		throw new PhidgetError(ErrorCode.UNEXPECTED);
	}
	close(): Promise<void> {
		throw new PhidgetError(ErrorCode.UNEXPECTED);
	}
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setDeviceLabel(_deviceLabel: string): void {
		throw new PhidgetError(ErrorCode.UNEXPECTED);
	}
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setDeviceSerialNumber(_deviceSerialNumber: number): void {
		throw new PhidgetError(ErrorCode.UNEXPECTED);
	}
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setHubPort(_hubPort: number): void {
		throw new PhidgetError(ErrorCode.UNEXPECTED);
	}
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setHubPortSpeed(_hubPortSpeed: number): Promise<void> {
		throw new PhidgetError(ErrorCode.UNEXPECTED);
	}
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setIsHubPortDevice(_isHubPortDevice: boolean): void {
		throw new PhidgetError(ErrorCode.UNEXPECTED);
	}
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setIsLocal(_isLocal: boolean): void {
		throw new PhidgetError(ErrorCode.UNEXPECTED);
	}
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setIsRemote(_isRemote: boolean): void {
		throw new PhidgetError(ErrorCode.UNEXPECTED);
	}
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	open(_timeout?: number): Promise<void> {
		throw new PhidgetError(ErrorCode.UNEXPECTED);
	}
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	writeDeviceLabel(_deviceLabel: string): Promise<void> {
		throw new PhidgetError(ErrorCode.UNEXPECTED);
	}
	reboot(): Promise<void> {
		throw new PhidgetError(ErrorCode.UNEXPECTED);
	}
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	rebootFirmwareUpgrade(_upgradeTimeout: number): Promise<void> {
		throw new PhidgetError(ErrorCode.UNEXPECTED);
	}
}

// Export
export { Phidget, PhidgetChannel, PhidgetDevice };
