/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PhidgetChannel } from '../Phidget';
import { Channel } from '../Channel';
import { ErrorCode, ChannelClass } from '../Enumerations.gen';
import * as Enum from '../Enumerations.gen';
import * as SEnum from '../SupportedEnum.gen';
import { PhidgetError } from '../PhidgetError';
import { BridgePacket, PUNK } from '../BridgePacket';
import { BP } from '../BridgePackets.gen';
import { logEventException } from '../Logging';
import { DeviceChannelUID } from '../Devices.gen';

/** @internal */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SoundSensorData {
	lastdB: number, 
	dataInterval: number,
	maxDataInterval: number,
	minDataRate: number,
	maxDataRate: number,
	maxdB: number,
	maxSPLChangeTrigger: number,
	minDataInterval: number,
	noiseFloor: number,
	minSPLChangeTrigger: number,
	dB: number,
	dBA: number,
	dBC: number,
	octaves: readonly [number, number, number, number, number, number, number, number, number, number],
	SPLRange: Enum.SPLRange | PUNK.ENUM,
	SPLChangeTrigger: number,
}

abstract class SoundSensorBase extends PhidgetChannel {
	/** @internal */
	data: SoundSensorData;
	/**
	 * **SPLChange** event
	 *  * `dB` - The dB SPL value.
	 *  * `dBA` - The dBA SPL value.
	 *  * `dBC` - The dBC SPL value.
	 *  * `octaves` - The dB SPL value for each band.
	 * ---
	 * The most recent SPL values the channel has measured will be reported in this event, which occurs when the `dataInterval` has elapsed.
	 * 
	 * *   If a `SPLChangeTrigger` has been set to a non-zero value, the `SPLChange` event will not occur until the `dB` SPL value has changed by at least the `SPLChangeTrigger` value.
	 * *   The dB SPL value is calculated from the `octaves` data.
	 * *   The dBA SPL value is calculated by applying a A-weighted filter to the `octaves` data.
	 * *   The dBC SPL value is calculated by applying a C-weighted filter to the `octaves` data.
	 * *   The following frequency bands are represented:
	 * 
	 * *   octaves\[0\] = 31.5 Hz
	 * *   octaves\[1\] = 63 Hz
	 * *   octaves\[2\] = 125 Hz
	 * *   octaves\[3\] = 250 Hz
	 * *   octaves\[4\] = 500 Hz
	 * *   octaves\[5\] = 1 kHz
	 * *   octaves\[6\] = 2 kHz
	 * *   octaves\[7\] = 4 kHz
	 * *   octaves\[8\] = 8 kHz
	 * *   octaves\[9\] = 16 kHz
	 */
	onSPLChange: ((dB: number, dBA: number, dBC: number, octaves: readonly [number, number, number, number, number, number, number, number, number, number]) => void) | null = null;
	/** @internal */
	_gotSPLChangeErrorEvent?: boolean;

	/**
	 * The Sound Sensor class gathers data from the sound sensor on a Phidget board.
	 * 
	 * If you're using a simple 0-5V sensor that does not have its own firmware, use the VoltageInput or VoltageRatioInput class instead, as specified for your device.
	 * @public
	 */
	constructor();
	/** @internal */
	constructor(ch?: Channel);
	constructor(ch?: Channel) {
		super(ch);
		this._class = ChannelClass.SOUND_SENSOR;
		this.name = "SoundSensor";
		this.data = this._initData();
	}

	/** @internal */
	_bridgeInput(bp: BridgePacket) {

		switch(bp.vpkt) {
		case BP.SETDATAINTERVAL:
			if (bp.entryCount > 1)
				this.data.dataInterval = bp.entries[1].v as number;
			else
				this.data.dataInterval = bp.entries[0].v as number;
			this._FIREPropertyChange('DataInterval', bp);
			this._FIREPropertyChange('DataRate', bp);
			break;
		case BP.SETCHANGETRIGGER:
			this.data.SPLChangeTrigger = bp.entries[0].v as number;
			this._FIREPropertyChange('SPLChangeTrigger', bp);
			break;
		case BP.SETSPLRANGE:
			this.data.SPLRange = bp.entries[0].v as Enum.SPLRange;
			this._FIREPropertyChange('SPLRange', bp);
			break;
		case BP.DBCHANGE: {
			this.data.dB = bp.entries[0].v as number;
			this.data.dBA = bp.entries[1].v as number;
			this.data.dBC = bp.entries[2].v as number;
			this.data.octaves = bp.entries[3].v as [number, number, number, number, number, number, number, number, number, number];
			if (this._isAttachedDone && this.onSPLChange) {
				try {
					this.onSPLChange(this.data.dB, this.data.dBA, this.data.dBC, this.data.octaves);
				} catch (err) { logEventException(err); }
			}
			break;
		}
		default:
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			throw new PhidgetError(ErrorCode.INVALID_PACKET, "Unsupported bridge packet: 0x" + bp.vpkt!.toString(16));
		}
	}

	/** @internal */
	_initData(): SoundSensorData {
		return {
			lastdB: 0, 
			dataInterval: PUNK.DBL,
			maxDataInterval: PUNK.UINT32,
			minDataRate: PUNK.DBL,
			maxDataRate: PUNK.DBL,
			maxdB: PUNK.DBL,
			maxSPLChangeTrigger: PUNK.DBL,
			minDataInterval: PUNK.UINT32,
			noiseFloor: PUNK.DBL,
			minSPLChangeTrigger: PUNK.DBL,
			dB: PUNK.DBL,
			dBA: PUNK.DBL,
			dBC: PUNK.DBL,
			octaves: [PUNK.DBL, PUNK.DBL, PUNK.DBL, PUNK.DBL, PUNK.DBL, PUNK.DBL, PUNK.DBL, PUNK.DBL, PUNK.DBL, PUNK.DBL],
			SPLRange: PUNK.ENUM,
			SPLChangeTrigger: PUNK.DBL,
		}
	}

	/** @internal */
	_initAfterOpen() {
		this.data = this._initData();

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._SND1000_SOUNDSENSOR_100:
			this.data.dataInterval = 250;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 10;
			this.data.maxdB = 102;
			this.data.maxSPLChangeTrigger = 102;
			this.data.minDataInterval = 100;
			this.data.noiseFloor = 34;
			this.data.minSPLChangeTrigger = 0;
			this.data.SPLRange = Enum.SPLRange.DB_102;
			this.data.SPLChangeTrigger = 0;
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	// eslint-disable-next-line require-await
	async _setDefaults() {
		let bp;

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._SND1000_SOUNDSENSOR_100:
			bp = new BridgePacket();
			bp.set({ name: "0", type: "u", value: Math.round(this.data.dataInterval) });
			await bp.send(this._ch, BP.SETDATAINTERVAL);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "d", value: this.data.SPLRange });
			await bp.send(this._ch, BP.SETSPLRANGE);
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	_hasInitialState() {


		return true;
	}

	/** @internal */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	_fireInitialEvents() {

	}

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `SPLChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * *   The timing between `SPLChange` events can also be affected by the `SPLChangeTrigger`.
	 * @throws {@link PhidgetError}
	 */
	get dataInterval() { return this.getDataInterval(); }
	/**
	 * The minimum value that `dataInterval` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDataInterval() { return this.getMinDataInterval(); }
	/**
	 * The maximum value that `dataInterval` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDataInterval() { return this.getMaxDataInterval(); }
	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @throws {@link PhidgetError}
	 */
	get dataRate() { return this.getDataRate(); }
	/**
	 * The minimum value that `dataRate` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDataRate() { return this.getMinDataRate(); }
	/**
	 * The maximum value that `dataRate` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDataRate() { return this.getMaxDataRate(); }
	/**
	 * The most recent dB SPL value that has been calculated.
	 * 
	 * *   This value is bounded by `maxdB`.
	 * @throws {@link PhidgetError}
	 */
	get dB() { return this.getdB(); }
	/**
	 * The maximum value the `SPLChange` event will report.
	 * @throws {@link PhidgetError}
	 */
	get maxdB() { return this.getMaxdB(); }
	/**
	 * The most recent dBA SPL value that has been calculated.
	 * 
	 * *   The dBA SPL value is calculated by applying a A-weighted filter to the `octaves` data.
	 * @throws {@link PhidgetError}
	 */
	get dBA() { return this.getdBA(); }
	/**
	 * The most recent dBC SPL value that has been calculated.
	 * 
	 * *   The dBC SPL value is calculated by applying a C-weighted filter to the `octaves` data.
	 * @throws {@link PhidgetError}
	 */
	get dBC() { return this.getdBC(); }
	/**
	 * The minimum SPL value that the channel can accurately measure.
	 * 
	 * *   Input SPLs below this level will not produce an output from the microphone.
	 * @throws {@link PhidgetError}
	 */
	get noiseFloor() { return this.getNoiseFloor(); }
	/**
	 * The unweighted value of each frequency band.
	 * 
	 * *   The following frequency bands are represented:
	 * 
	 * *   octaves\[0\] = 31.5 Hz
	 * *   octaves\[1\] = 63 Hz
	 * *   octaves\[2\] = 125 Hz
	 * *   octaves\[3\] = 250 Hz
	 * *   octaves\[4\] = 500 Hz
	 * *   octaves\[5\] = 1 kHz
	 * *   octaves\[6\] = 2 kHz
	 * *   octaves\[7\] = 4 kHz
	 * *   octaves\[8\] = 8 kHz
	 * *   octaves\[9\] = 16 kHz
	 * @throws {@link PhidgetError}
	 */
	get octaves() { return this.getOctaves(); }
	/**
	 * The channel will not issue a `SPLChange` event until the `dB` value has changed by the amount specified by the `SPLChangeTrigger`.
	 * 
	 * *   Setting the `SPLChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering
	 * @throws {@link PhidgetError}
	 */
	get SPLChangeTrigger() { return this.getSPLChangeTrigger(); }
	/**
	 * The minimum value that `SPLChangeTrigger` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minSPLChangeTrigger() { return this.getMinSPLChangeTrigger(); }
	/**
	 * The maximum value that `SPLChangeTrigger` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxSPLChangeTrigger() { return this.getMaxSPLChangeTrigger(); }
	/**
	 * When selecting a range, first decide how sensitive you want the microphone to be. Select a smaller range when you want more sensitivity from the microphone.
	 * 
	 * *   If a `Saturation` event occurrs, increase the range.
	 * @throws {@link PhidgetError}
	 */
	get SPLRange() { return this.getSPLRange(); }

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `SPLChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * *   The timing between `SPLChange` events can also be affected by the `SPLChangeTrigger`.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getDataInterval(): number {
		this._assertOpen();

		if (this.data.dataInterval === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return this.data.dataInterval;
	}

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `SPLChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * *   The timing between `SPLChange` events can also be affected by the `SPLChangeTrigger`.
	 * @throws {@link PhidgetError}
	 * @param dataInterval - The data interval value
	 */
	async setDataInterval(dataInterval: number): Promise<void> {
		this._assertOpen();

		if (dataInterval < this.data.minDataInterval || dataInterval > this.data.maxDataInterval)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDataInterval + " - " + this.data.maxDataInterval + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: dataInterval });
		await bp.send(this._ch, BP.SETDATAINTERVAL);
	}

	/**
	 * The minimum value that `dataInterval` can be set to.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getMinDataInterval(): number {
		this._assertOpen();

		if (this.data.minDataInterval === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDataInterval);
	}

	/**
	 * The maximum value that `dataInterval` can be set to.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getMaxDataInterval(): number {
		this._assertOpen();

		if (this.data.maxDataInterval === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDataInterval);
	}

	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @returns The data rate for the channel
	 * @throws {@link PhidgetError}
	 */
	getDataRate(): number {
		this._assertOpen();

		if (this.data.dataInterval === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (1000.0 / this.data.dataInterval);
	}

	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @throws {@link PhidgetError}
	 * @param dataRate - The data rate for the channel
	 */
	async setDataRate(dataRate: number): Promise<void> {
		this._assertOpen();

		if (dataRate < this.data.minDataRate || dataRate > this.data.maxDataRate)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDataRate + " - " + this.data.maxDataRate + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: Math.round(1000.0 / dataRate) });
		bp.set({ name: "1", type: "g", value: (1000.0 / dataRate) });
		await bp.send(this._ch, BP.SETDATAINTERVAL);
	}

	/**
	 * The minimum value that `dataRate` can be set to.
	 * @returns The data rate value
	 * @throws {@link PhidgetError}
	 */
	getMinDataRate(): number {
		this._assertOpen();

		if (this.data.minDataRate === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDataRate);
	}

	/**
	 * The maximum value that `dataRate` can be set to.
	 * @returns The data rate value
	 * @throws {@link PhidgetError}
	 */
	getMaxDataRate(): number {
		this._assertOpen();

		if (this.data.maxDataRate === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDataRate);
	}

	/**
	 * The most recent dB SPL value that has been calculated.
	 * 
	 * *   This value is bounded by `maxdB`.
	 * @returns The dB value
	 * @throws {@link PhidgetError}
	 */
	getdB(): number {
		this._assertOpen();

		if (this.data.dB === PUNK.DBL || Number.isNaN(this.data.dB))
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);
		if (this.data.dB > this.data.maxdB)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE_HIGH);

		return (this.data.dB);
	}

	/**
	 * The maximum value the `SPLChange` event will report.
	 * @returns The dB value
	 * @throws {@link PhidgetError}
	 */
	getMaxdB(): number {
		this._assertOpen();

		if (this.data.maxdB === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxdB);
	}

	/**
	 * The most recent dBA SPL value that has been calculated.
	 * 
	 * *   The dBA SPL value is calculated by applying a A-weighted filter to the `octaves` data.
	 * @returns The dBA value
	 * @throws {@link PhidgetError}
	 */
	getdBA(): number {
		this._assertOpen();

		if (this.data.dBA === PUNK.DBL || Number.isNaN(this.data.dBA))
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.dBA);
	}

	/**
	 * The most recent dBC SPL value that has been calculated.
	 * 
	 * *   The dBC SPL value is calculated by applying a C-weighted filter to the `octaves` data.
	 * @returns The dBC value
	 * @throws {@link PhidgetError}
	 */
	getdBC(): number {
		this._assertOpen();

		if (this.data.dBC === PUNK.DBL || Number.isNaN(this.data.dBC))
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.dBC);
	}

	/**
	 * The minimum SPL value that the channel can accurately measure.
	 * 
	 * *   Input SPLs below this level will not produce an output from the microphone.
	 * @returns The noise floor value.
	 * @throws {@link PhidgetError}
	 */
	getNoiseFloor(): number {
		this._assertOpen();

		if (this.data.noiseFloor === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.noiseFloor);
	}

	/**
	 * The unweighted value of each frequency band.
	 * 
	 * *   The following frequency bands are represented:
	 * 
	 * *   octaves\[0\] = 31.5 Hz
	 * *   octaves\[1\] = 63 Hz
	 * *   octaves\[2\] = 125 Hz
	 * *   octaves\[3\] = 250 Hz
	 * *   octaves\[4\] = 500 Hz
	 * *   octaves\[5\] = 1 kHz
	 * *   octaves\[6\] = 2 kHz
	 * *   octaves\[7\] = 4 kHz
	 * *   octaves\[8\] = 8 kHz
	 * *   octaves\[9\] = 16 kHz
	 * @returns The octave values
	 * @throws {@link PhidgetError}
	 */
	getOctaves(): readonly [number, number, number, number, number, number, number, number, number, number] {
		this._assertOpen();

		if (this.data.octaves.includes(PUNK.DBL))
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.octaves);
	}

	/**
	 * The channel will not issue a `SPLChange` event until the `dB` value has changed by the amount specified by the `SPLChangeTrigger`.
	 * 
	 * *   Setting the `SPLChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering
	 * @returns The change trigger value
	 * @throws {@link PhidgetError}
	 */
	getSPLChangeTrigger(): number {
		this._assertOpen();

		if (this.data.SPLChangeTrigger === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.SPLChangeTrigger);
	}

	/**
	 * The channel will not issue a `SPLChange` event until the `dB` value has changed by the amount specified by the `SPLChangeTrigger`.
	 * 
	 * *   Setting the `SPLChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering
	 * @throws {@link PhidgetError}
	 * @param SPLChangeTrigger - The change trigger value
	 */
	async setSPLChangeTrigger(SPLChangeTrigger: number): Promise<void> {
		this._assertOpen();

		if (SPLChangeTrigger < this.data.minSPLChangeTrigger || SPLChangeTrigger > this.data.maxSPLChangeTrigger)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minSPLChangeTrigger + " - " + this.data.maxSPLChangeTrigger + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: SPLChangeTrigger });
		await bp.send(this._ch, BP.SETCHANGETRIGGER);
	}

	/**
	 * The minimum value that `SPLChangeTrigger` can be set to.
	 * @returns The change trigger value
	 * @throws {@link PhidgetError}
	 */
	getMinSPLChangeTrigger(): number {
		this._assertOpen();

		if (this.data.minSPLChangeTrigger === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minSPLChangeTrigger);
	}

	/**
	 * The maximum value that `SPLChangeTrigger` can be set to.
	 * @returns The change trigger value
	 * @throws {@link PhidgetError}
	 */
	getMaxSPLChangeTrigger(): number {
		this._assertOpen();

		if (this.data.maxSPLChangeTrigger === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxSPLChangeTrigger);
	}

	/**
	 * When selecting a range, first decide how sensitive you want the microphone to be. Select a smaller range when you want more sensitivity from the microphone.
	 * 
	 * *   If a `Saturation` event occurrs, increase the range.
	 * @returns The range value.
	 * @throws {@link PhidgetError}
	 */
	getSPLRange(): Enum.SPLRange {
		this._assertOpen();

		if (this.data.SPLRange === PUNK.ENUM)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.SPLRange);
	}

	/**
	 * When selecting a range, first decide how sensitive you want the microphone to be. Select a smaller range when you want more sensitivity from the microphone.
	 * 
	 * *   If a `Saturation` event occurrs, increase the range.
	 * @throws {@link PhidgetError}
	 * @param SPLRange - The range value.
	 */
	async setSPLRange(SPLRange: Enum.SPLRange): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (!SEnum.supportedSPLRange(this._ch!, SPLRange))
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Specified SPLRange is unsupported by this device.");

		bp.set({ name: "0", type: "d", value: SPLRange });
		await bp.send(this._ch, BP.SETSPLRANGE);
	}

}
export { SoundSensorBase };
