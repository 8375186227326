/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { BridgePacket, PUNK } from "../../BridgePacket";
import { DeviceUID } from "../../Devices.gen";
import { ErrorCode } from "../../Enumerations.gen";
import { PhidgetError } from "../../PhidgetError";
import { LocalChannel } from "../LocalChannel";
import { PhidgetUSBDevice, PhidgetUSBDeviceData } from "../PhidgetUSBDevice";
import { USBConnectionBase } from "../USBConnection";

export class EncoderDevice extends PhidgetUSBDevice {

	data: {
		inputState: number[]
	};

	constructor(conn: USBConnectionBase, data: PhidgetUSBDeviceData, usbDev: USBDevice) {
		super(conn, data, usbDev);

		// Ensure that we actually support this device
		switch (this.devDef.uid) {
			case DeviceUID._1047_2:
			case DeviceUID._1057_3:
				break;
			default:
				throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}

		this.data = {
			inputState: new Array(this.numInputs).fill(PUNK.BOOL)
		};
	}

	// Define getters based on devices.h Attr structs in C library
	get numEncoders() { return this.devDef.cn[0]; }
	get numInputs() { return this.devDef.cn[1]; }

	// eslint-disable-next-line require-await
	async initAfterOpen() {
		switch (this.devDef.uid) {
			case DeviceUID._1047_2:
			case DeviceUID._1057_3:
				throw new PhidgetError(ErrorCode.UNSUPPORTED, "This Phidget is not yet supported via direct USB connection in JavaScript.");
		}
	}
	dataInput(_buffer: DataView) {}
	async bridgeInput(_ch: LocalChannel, _arg1: BridgePacket) {}
}