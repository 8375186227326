/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { BLDCMotorBase } from './BLDCMotor.gen';
import { BP } from '../BridgePackets.gen';
import { PhidgetError } from '../PhidgetError';
import { ErrorCode } from '../Enumerations.gen';
import { BridgePacket, PUNK } from '../BridgePacket';
import { logEventException } from '../Logging';

/** @public */
class BLDCMotor extends BLDCMotorBase {

	/** @internal */
	_bridgeInput(bp: BridgePacket) {
		switch (bp.vpkt) {
			case BP.POSITIONCHANGE:
				this.data.position = bp.getNumber(0);
				if (this._isAttachedDone && this.onPositionChange) {
					try {
						this.onPositionChange((this.data.position + this.data.positionOffset) * this.data.rescaleFactor);
					} catch (err) { logEventException(err); }
				}
				break;
			default:
				super._bridgeInput(bp);
				break;
		}
	}

	setRescaleFactor(rescaleFactor: number) {
		this._assertOpen();

		this.data.rescaleFactor = rescaleFactor;
	}

	getPosition() {
		this._assertOpen();

		if (this.data.position === PUNK.INT64)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.position * this.data.rescaleFactor);
	}

	getMinPosition() {
		this._assertOpen();

		if (this.data.minPosition === PUNK.INT64)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return ((this.data.minPosition + this.data.positionOffset) * this.data.rescaleFactor);
	}

	getMaxPosition() {
		this._assertOpen();

		if (this.data.maxPosition === PUNK.INT64)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return ((this.data.maxPosition + this.data.positionOffset) * this.data.rescaleFactor);
	}

	addPositionOffset(positionOffset: number) {
		this._assertOpen();

		this.data.positionOffset += (positionOffset / this.data.rescaleFactor)
	}

	async setStallVelocity(stallVelocity: number) {
		this._assertOpen();

		const calcVelocity = stallVelocity / this.data.rescaleFactor;

		if (calcVelocity < this.data.minStallVelocity || calcVelocity > this.data.maxStallVelocity)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.minStallVelocity + " - " + this.maxStallVelocity + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: calcVelocity });
		await bp.send(this._ch, BP.SETSTALLVELOCITY);
	}

	getStallVelocity() {
		this._assertOpen();

		if (this.data.stallVelocity === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.stallVelocity * this.data.rescaleFactor);
	}

	getMinStallVelocity() {
		this._assertOpen();

		if (this.data.minStallVelocity === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minStallVelocity * this.data.rescaleFactor);
	}

	getMaxStallVelocity() {
		this._assertOpen();

		if (this.data.maxStallVelocity === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxStallVelocity * this.data.rescaleFactor);
	}
}

export { BLDCMotor };