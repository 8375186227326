/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { VoltageRatioInputBase } from './VoltageRatioInput.gen';
import { Units } from '../AnalogSensor';
import { ErrorCode, ErrorEventCode, VoltageRatioSensorType, Unit, BridgeGain } from '../Enumerations.gen';
import { BP } from '../BridgePackets.gen';
import { BridgePacket, PUNK } from '../BridgePacket';
import { PhidgetError } from '../PhidgetError';
import { type UnitInfo } from '../Structs.gen';
import { RoundDouble } from '../Utils';
import { logEventException } from '../Logging';
import { DeviceChannelUID } from '../Devices.gen';

/** @public */
class VoltageRatioInput extends VoltageRatioInputBase {

	/** @internal */
	_bridgeInput(bp: BridgePacket) {
		switch (bp.vpkt) {
			case BP.DATAINTERVALCHANGE:
				if (bp.entryCount > 1)
					this.data.dataInterval = bp.getNumber(1);
				else
					this.data.dataInterval = bp.getNumber(0);
				this._FIREPropertyChange('DataInterval');
				this._FIREPropertyChange('DataRate');
				break;

			case BP.MINDATAINTERVALCHANGE:
				this.data.minDataInterval = bp.getNumber(0);
				this._FIREPropertyChange('MinDataInterval');
				break;

			case BP.SETSENSORTYPE:
				super._bridgeInput(bp);
				this._bangSensorVoltage();
				break;

			case BP.VOLTAGERATIOCHANGE: {
				//moved out of VoltageRatioInput.gen.js
				this.data.voltageRatio = bp.getNumber('0');
				const sentSensorEvent = this._bangSensorVoltage(true);
				if (sentSensorEvent && this._ch!.conn._isRemote)
					throw new PhidgetError(ErrorCode.UNSUPPORTED);
				break;

			}

			case BP.SETBRIDGEGAIN:
				super._bridgeInput(bp);
				switch (this._ch!.chDef.uid) {
					case DeviceChannelUID._1046_VOLTAGERATIOINPUT_200:
						switch (this.data.bridgeGain) {
							case BridgeGain.GAIN_1X:
								this.data.maxVoltageRatio = 0.85;
								this.data.minVoltageRatio = -0.85;
								break;
							case BridgeGain.GAIN_8X:
								this.data.maxVoltageRatio = 1.0 / 8;
								this.data.minVoltageRatio = -1.0 / 8;
								break;
							case BridgeGain.GAIN_16X:
								this.data.maxVoltageRatio = 1.0 / 16;
								this.data.minVoltageRatio = -1.0 / 16;
								break;
							case BridgeGain.GAIN_32X:
								this.data.maxVoltageRatio = 1.0 / 32;
								this.data.minVoltageRatio = -1.0 / 32;
								break;
							case BridgeGain.GAIN_64X:
								this.data.maxVoltageRatio = 1.0 / 64;
								this.data.minVoltageRatio = -1.0 / 64;
								break;
							case BridgeGain.GAIN_128X:
								this.data.maxVoltageRatio = 1.0 / 128;
								this.data.minVoltageRatio = -1.0 / 128;
								break;
						}
						this._FIREPropertyChange('MinVoltageRatio', bp);
						this._FIREPropertyChange('MaxVoltageRatio', bp);
						break;
				}
				break;

			default:
				super._bridgeInput(bp);
				break;
		}
	}

	/** @internal */
	_errorHandler(code: ErrorEventCode) {
		switch (code) {
			case ErrorEventCode.SATURATION:
				this.data.voltageRatio = PUNK.DBL;
				this.data.sensorValue = PUNK.DBL;
				this._gotVoltageRatioChangeErrorEvent = true;
				break;
		}
	}

	/** @internal */
	_initAfterOpen() {
		super._initAfterOpen();

		if (this.data.sensorType === PUNK.ENUM)
			this.data.sensorType = VoltageRatioSensorType.VOLTAGE_RATIO;
		this.data.sensorUnit = this._getVoltageRatioSensorUnit(this.data.sensorType);
		this.data.sensorValue = this._getVoltageRatioSensorValue(this.data.voltageRatio, this.data.sensorType);
	}

	/** @internal */
	_hasInitialState() {

		if ((this.data.voltageRatio == PUNK.DBL)
			&& !this._gotVoltageRatioChangeErrorEvent)
			return false;

		return super._hasInitialState();
	}

	/** @internal */
	_fireInitialEvents() {

		if (this.data.sensorType != VoltageRatioSensorType.VOLTAGE_RATIO) {
			if (this.data.sensorValue != PUNK.DBL &&
				this.data.sensorUnit != null)
				if (this.onSensorChange) {
					try {
						this.onSensorChange(this.data.sensorValue, this.data.sensorUnit);
					} catch (err) { logEventException(err); }
				}
		} else {
			if (this.data.voltageRatio != PUNK.DBL)
				if (this.onVoltageRatioChange) {
					try {
						this.onVoltageRatioChange(this.data.voltageRatio);
					} catch (err) { logEventException(err); }
				}
		}

		super._fireInitialEvents();
	}

	/** @internal */
	_bangSensorVoltage(includeVoltage = false) {
		let sensorValue;
		let unitInfo;

		let sentSensorEvent = false;

		const bp = new BridgePacket();

		if (this._ch!.supportedBridgePacket(BP.SENSORCHANGE) && this._ch!.conn._isLocal && this.data.sensorType !== VoltageRatioSensorType.VOLTAGE_RATIO) {
			sensorValue = this._getVoltageRatioSensorValue(this.data.voltageRatio, this.data.sensorType);
			if (!this._getSensorValueInRange(sensorValue, this.data.sensorType)) {
				this.data.sensorValue = PUNK.DBL;
				if (this._isAttachedDone) {
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.OUT_OF_RANGE });
					bp.set({ name: '1', type: 's', value: 'Sensor value is ouside the valid range for this sensor.' });
					this._ch!.sendErrorEvent(bp);
				}
			} else if (this.data.sensorValue === PUNK.DBL || Math.abs(sensorValue - this.data.sensorValue) >= this.data.sensorValueChangeTrigger) {
				this.data.sensorValue = sensorValue;
				if (this._isAttachedDone) {
					unitInfo = this._getVoltageRatioSensorUnit(this.data.sensorType);
					bp.set({ name: '0', type: 'g', value: sensorValue });
					bp.set({ name: 'UnitInfo.unit', type: 'g', value: unitInfo.unit });
					bp.set({ name: 'UnitInfo.name', type: 's', value: unitInfo.name });
					bp.set({ name: 'UnitInfo.symbol', type: 's', value: unitInfo.symbol });
					bp.sendToChannel(this._ch!, BP.SENSORCHANGE);
				}
			}
			sentSensorEvent = true;
		} else if (includeVoltage) {
			this.data.sensorUnit = this._getVoltageRatioSensorUnit(this.data.sensorType);
			this.data.sensorValue = this._getVoltageRatioSensorValue(this.data.voltageRatio, this.data.sensorType);

			if (this._isAttachedDone && this.onVoltageRatioChange) {
				try {
					this.onVoltageRatioChange(this.data.voltageRatio);
				} catch (err) { logEventException(err); }
			}
		}

		return sentSensorEvent;
	}

	/** @internal */
	_getVoltageRatioSensorValue(voltageRatio: number, sensorType: VoltageRatioSensorType | PUNK.ENUM): number {
		if (voltageRatio === PUNK.DBL) {
			return PUNK.DBL;
		}

		switch (sensorType) {
			case VoltageRatioSensorType.PN_1101_SHARP2D120X:
			case VoltageRatioSensorType.PN_3520:
				return RoundDouble((2.076 / (voltageRatio - 0.011)), 2); // cm
			case VoltageRatioSensorType.PN_1101_SHARP2Y0A21:
			case VoltageRatioSensorType.PN_3521:
				return RoundDouble((4.8 / (voltageRatio - 0.02)), 2); // cm
			case VoltageRatioSensorType.PN_1101_SHARP2Y0A02:
			case VoltageRatioSensorType.PN_3522:
				return RoundDouble((9.462 / (voltageRatio - 0.01692)), 2); // cm
			case VoltageRatioSensorType.PN_1102:
				return voltageRatio < 0.4 ? 1 : 0; // true/false
			case VoltageRatioSensorType.PN_1103:
				return voltageRatio < 0.1 ? 1 : 0; // true/false
			case VoltageRatioSensorType.PN_1104:
				return RoundDouble((voltageRatio * 2 - 1), 5); // +- 1
			case VoltageRatioSensorType.PN_1105:
				return voltageRatio; // 0-1
			case VoltageRatioSensorType.PN_1106:
				return voltageRatio; // 0-1
			case VoltageRatioSensorType.PN_1107:
				return RoundDouble(((voltageRatio * 190.6) - 40.2), 3); // %RH
			case VoltageRatioSensorType.PN_1108:
				return RoundDouble(((0.5 - voltageRatio) * 1000), 2); // Gauss
			case VoltageRatioSensorType.PN_1109:
				return voltageRatio; // 0-1
			case VoltageRatioSensorType.PN_1110:
				return voltageRatio < 0.5 ? 1 : 0; // true/false
			case VoltageRatioSensorType.PN_1111:
				return RoundDouble((voltageRatio * 2 - 1), 5); // +- 1
			case VoltageRatioSensorType.PN_1112:
				return voltageRatio; // 0-1
			case VoltageRatioSensorType.PN_1113:
				return RoundDouble((voltageRatio * 2 - 1), 5); // +- 1
			case VoltageRatioSensorType.PN_1115:
				return RoundDouble((voltageRatio / 0.004 + 10), 3); // kPa
			case VoltageRatioSensorType.PN_1116:
				return voltageRatio; // 0-1
			case VoltageRatioSensorType.PN_1118_AC:
				return RoundDouble((voltageRatio * 69.38), 3); // RMS Amps
			case VoltageRatioSensorType.PN_1118_DC:
				return RoundDouble((voltageRatio / 0.008 - 62.5), 3); //DC Amps
			case VoltageRatioSensorType.PN_1119_AC:
				return RoundDouble((voltageRatio * 27.75), 4); // RMS Amps
			case VoltageRatioSensorType.PN_1119_DC:
				return RoundDouble((voltageRatio / 0.02 - 25), 4); //DC Amps
			case VoltageRatioSensorType.PN_1120:
				return voltageRatio; // 0-1
			case VoltageRatioSensorType.PN_1121:
				return voltageRatio; // 0-1
			case VoltageRatioSensorType.PN_1122_AC:
				return RoundDouble((voltageRatio * 42.04), 3); // RMS Amps
			case VoltageRatioSensorType.PN_1122_DC:
				return RoundDouble((voltageRatio / 0.0132 - 37.8787), 3); // DC Amps
			case VoltageRatioSensorType.PN_1124:
			case VoltageRatioSensorType.PN_1125_TEMPERATURE:
				return RoundDouble((voltageRatio * 222.2 - 61.111), 3); // Degrees Celcius
			case VoltageRatioSensorType.PN_1125_HUMIDITY:
				return RoundDouble((voltageRatio * 190.6 - 40.2), 3); // %RH
			case VoltageRatioSensorType.PN_1126:
				return RoundDouble((voltageRatio / 0.018 - 27.7777), 3); // kPa
			case VoltageRatioSensorType.PN_1128:
				return RoundDouble((voltageRatio * 1296), 2); // cm
			case VoltageRatioSensorType.PN_1129:
				return voltageRatio > 0.5 ? 1 : 0; // true/false
			case VoltageRatioSensorType.PN_1131:
				return RoundDouble((15.311 * Math.exp(voltageRatio * 5.199)), 2); // grams
			case VoltageRatioSensorType.PN_1134:
				return voltageRatio; // 0-1
			case VoltageRatioSensorType.PN_1136:
				return RoundDouble((voltageRatio / 0.2 - 2.5), 4); // kPa
			case VoltageRatioSensorType.PN_1137:
				return RoundDouble((voltageRatio / 0.057143 - 8.75), 4); // kPa
			case VoltageRatioSensorType.PN_1138:
				return RoundDouble((voltageRatio / 0.018 - 2.222), 3); // kPa
			case VoltageRatioSensorType.PN_1139:
				return RoundDouble((voltageRatio / 0.009 - 4.444), 3); // kPa
			case VoltageRatioSensorType.PN_1140:
				return RoundDouble((voltageRatio / 0.002421 + 3.478), 2); // kPa
			case VoltageRatioSensorType.PN_1141:
				return RoundDouble((voltageRatio / 0.0092 + 10.652), 2); // kPa
			case VoltageRatioSensorType.PN_1146:
				return RoundDouble((1.3927 * Math.exp(1.967 * voltageRatio)), 2); // mm
			case VoltageRatioSensorType.PN_3120:
				return RoundDouble((voltageRatio / 0.15432 - 0.647989), 4); // kg
			case VoltageRatioSensorType.PN_3121:
				return RoundDouble((voltageRatio / 0.0617295 - 1.619971), 4); // kg
			case VoltageRatioSensorType.PN_3122:
				return RoundDouble((voltageRatio / 0.0308647 - 3.239943), 3); // kg
			case VoltageRatioSensorType.PN_3123:
				return RoundDouble((voltageRatio / 0.0154324 - 6.479886), 3); // kg
			case VoltageRatioSensorType.PN_3130:
				return RoundDouble((voltageRatio * 190.6 - 40.2), 3); // %RH

			case VoltageRatioSensorType.VOLTAGE_RATIO:
			default:
				return voltageRatio;
		}
	}

	/** @internal */
	_getSensorValueInRange(sensorValue: number, sensorType: VoltageRatioSensorType | PUNK.ENUM): boolean {
		if (sensorValue === PUNK.DBL) {
			return false;
		}
		switch (sensorType) {
			case VoltageRatioSensorType.PN_1101_SHARP2D120X:
			case VoltageRatioSensorType.PN_3520:
				return ((sensorValue >= 4.0) && (sensorValue <= 30.0)); // cm
			case VoltageRatioSensorType.PN_1101_SHARP2Y0A21:
			case VoltageRatioSensorType.PN_3521:
				return ((sensorValue >= 10.0) && (sensorValue <= 80.0)); // cm
			case VoltageRatioSensorType.PN_1101_SHARP2Y0A02:
			case VoltageRatioSensorType.PN_3522:
				return ((sensorValue >= 20.0) && (sensorValue <= 150.0)); // cm
			case VoltageRatioSensorType.PN_1102:
				return true; // true/false
			case VoltageRatioSensorType.PN_1103:
				return true; // true/false
			case VoltageRatioSensorType.PN_1104:
				return true; // +- 1
			case VoltageRatioSensorType.PN_1105:
				return true; // 0-1
			case VoltageRatioSensorType.PN_1106:
				return true; // 0-1
			case VoltageRatioSensorType.PN_1107:
				return ((sensorValue >= 0.0) && (sensorValue <= 100.0)); // %RH
			case VoltageRatioSensorType.PN_1108:
				return ((sensorValue >= -500.0) && (sensorValue <= 500.0)); // Gauss
			case VoltageRatioSensorType.PN_1109:
				return true; // 0-1
			case VoltageRatioSensorType.PN_1110:
				return true; // true/false
			case VoltageRatioSensorType.PN_1111:
				return true; // +- 1
			case VoltageRatioSensorType.PN_1112:
				return true; // 0-1
			case VoltageRatioSensorType.PN_1113:
				return ((sensorValue >= -1.0) && (sensorValue <= 1.0)); // +- 1
			case VoltageRatioSensorType.PN_1115:
				return ((sensorValue >= 20.0) && (sensorValue <= 250.0)); // kPa
			case VoltageRatioSensorType.PN_1116:
				return true; // 0-1
			case VoltageRatioSensorType.PN_1118_AC:
				return ((sensorValue >= 0.0) && (sensorValue <= 50.0)); // RMS Amps
			case VoltageRatioSensorType.PN_1118_DC:
				return ((sensorValue >= -50.0) && (sensorValue <= 50.0)); //DC Amps
			case VoltageRatioSensorType.PN_1119_AC:
				return ((sensorValue >= 0.0) && (sensorValue <= 20.0)); // RMS Amps
			case VoltageRatioSensorType.PN_1119_DC:
				return ((sensorValue >= -20.0) && (sensorValue <= 20.0)); //DC Amps
			case VoltageRatioSensorType.PN_1120:
				return true; // 0-1
			case VoltageRatioSensorType.PN_1121:
				return true; // 0-1
			case VoltageRatioSensorType.PN_1122_AC:
				return ((sensorValue >= 0.0) && (sensorValue <= 30.0)); // RMS Amps
			case VoltageRatioSensorType.PN_1122_DC:
				return ((sensorValue >= -30.0) && (sensorValue <= 30.0)); // DC Amps
			case VoltageRatioSensorType.PN_1124:
			case VoltageRatioSensorType.PN_1125_TEMPERATURE:
				return ((sensorValue >= -50.0) && (sensorValue <= 150.0)); // Degrees Celcius
			case VoltageRatioSensorType.PN_1125_HUMIDITY:
				return ((sensorValue >= 0.0) && (sensorValue <= 100.0)); // %RH
			case VoltageRatioSensorType.PN_1126:
				return ((sensorValue >= -25.0) && (sensorValue <= 25.0)); // kPa
			case VoltageRatioSensorType.PN_1128:
				return ((sensorValue >= 15.24) && (sensorValue <= 6500.0)); // cm
			case VoltageRatioSensorType.PN_1129:
				return true; // true/false
			case VoltageRatioSensorType.PN_1131:
				return ((sensorValue >= 0.0) && (sensorValue <= 2000.0)); // grams
			case VoltageRatioSensorType.PN_1134:
				return true; // 0-1
			case VoltageRatioSensorType.PN_1136:
				return ((sensorValue >= -2.0) && (sensorValue <= 2.0)); // kPa
			case VoltageRatioSensorType.PN_1137:
				return ((sensorValue >= -7.0) && (sensorValue <= 7.0)); // kPa
			case VoltageRatioSensorType.PN_1138:
				return ((sensorValue >= 0.0) && (sensorValue <= 50.0)); // kPa
			case VoltageRatioSensorType.PN_1139:
				return ((sensorValue >= 0.0) && (sensorValue <= 100.0)); // kPa
			case VoltageRatioSensorType.PN_1140:
				return ((sensorValue >= 20.0) && (sensorValue <= 400.0)); // kPa
			case VoltageRatioSensorType.PN_1141:
				return ((sensorValue >= 15.0) && (sensorValue <= 115.0)); // kPa
			case VoltageRatioSensorType.PN_1146:
				return ((sensorValue >= 1.5) && (sensorValue <= 4.0)); // mm
			case VoltageRatioSensorType.PN_3120:
				return ((sensorValue >= 0.0) && (sensorValue <= 4.5)); // kg
			case VoltageRatioSensorType.PN_3121:
				return ((sensorValue >= 0.0) && (sensorValue <= 11.3)); // kg
			case VoltageRatioSensorType.PN_3122:
				return ((sensorValue >= 0.0) && (sensorValue <= 22.7)); // kg
			case VoltageRatioSensorType.PN_3123:
				return ((sensorValue >= 0.0) && (sensorValue <= 45.3)); // kg
			case VoltageRatioSensorType.PN_3130:
				return ((sensorValue >= 0.0) && (sensorValue <= 100.0)); // %RH

			case VoltageRatioSensorType.VOLTAGE_RATIO:
				return true;
			default:
				return true;
		}
	}

	/** @internal */
	_getVoltageRatioSensorUnit(sensorType: VoltageRatioSensorType | PUNK.ENUM): UnitInfo {
		switch (sensorType) {
			case VoltageRatioSensorType.PN_1146:
				return Units[Unit.MILLIMETER];

			case VoltageRatioSensorType.PN_1101_SHARP2D120X:
			case VoltageRatioSensorType.PN_1101_SHARP2Y0A21:
			case VoltageRatioSensorType.PN_1101_SHARP2Y0A02:
			case VoltageRatioSensorType.PN_1128:
			case VoltageRatioSensorType.PN_3520:
			case VoltageRatioSensorType.PN_3521:
			case VoltageRatioSensorType.PN_3522:
				return Units[Unit.CENTIMETER];

			case VoltageRatioSensorType.PN_1102:
			case VoltageRatioSensorType.PN_1103:
			case VoltageRatioSensorType.PN_1110:
			case VoltageRatioSensorType.PN_1129:
				return Units[Unit.BOOLEAN];

			case VoltageRatioSensorType.PN_1107:
			case VoltageRatioSensorType.PN_1125_HUMIDITY:
			case VoltageRatioSensorType.PN_3130:
				return Units[Unit.PERCENT];

			case VoltageRatioSensorType.PN_1108:
				return Units[Unit.GAUSS];

			case VoltageRatioSensorType.PN_1115:
			case VoltageRatioSensorType.PN_1126:
			case VoltageRatioSensorType.PN_1136:
			case VoltageRatioSensorType.PN_1137:
			case VoltageRatioSensorType.PN_1138:
			case VoltageRatioSensorType.PN_1139:
			case VoltageRatioSensorType.PN_1140:
			case VoltageRatioSensorType.PN_1141:
				return Units[Unit.KILOPASCAL];

			case VoltageRatioSensorType.PN_1118_AC:
			case VoltageRatioSensorType.PN_1119_AC:
			case VoltageRatioSensorType.PN_1122_AC:
			case VoltageRatioSensorType.PN_1118_DC:
			case VoltageRatioSensorType.PN_1119_DC:
			case VoltageRatioSensorType.PN_1122_DC:
				return Units[Unit.AMPERE];

			case VoltageRatioSensorType.PN_1124:
			case VoltageRatioSensorType.PN_1125_TEMPERATURE:
				return Units[Unit.DEGREE_CELCIUS];

			case VoltageRatioSensorType.PN_1131:
				return Units[Unit.GRAM];

			case VoltageRatioSensorType.PN_3120:
			case VoltageRatioSensorType.PN_3121:
			case VoltageRatioSensorType.PN_3122:
			case VoltageRatioSensorType.PN_3123:
				return Units[Unit.KILOGRAM];

			case VoltageRatioSensorType.VOLTAGE_RATIO:
			case VoltageRatioSensorType.PN_1104:
			case VoltageRatioSensorType.PN_1111:
			case VoltageRatioSensorType.PN_1113:
			case VoltageRatioSensorType.PN_1105:
			case VoltageRatioSensorType.PN_1106:
			case VoltageRatioSensorType.PN_1109:
			case VoltageRatioSensorType.PN_1112:
			case VoltageRatioSensorType.PN_1116:
			case VoltageRatioSensorType.PN_1120:
			case VoltageRatioSensorType.PN_1121:
			case VoltageRatioSensorType.PN_1134:
			default:
				return Units[Unit.NONE];
		}
	}
}

export { VoltageRatioInput };