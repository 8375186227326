/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PhidgetChannel } from '../Phidget';
import { Channel } from '../Channel';
import { ErrorCode, ChannelClass } from '../Enumerations.gen';
import { PhidgetError } from '../PhidgetError';
import { BridgePacket, PUNK } from '../BridgePacket';
import { BP } from '../BridgePackets.gen';
import { logEventException } from '../Logging';
import { DeviceChannelUID } from '../Devices.gen';

/** @internal */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DistanceSensorData {
	amplitudes: [number, number, number, number, number, number, number, number],
	distances: [number, number, number, number, number, number, number, number],
	count: number, 
	dataInterval: number,
	maxDataInterval: number,
	minDataRate: number,
	maxDataRate: number,
	maxDistance: number,
	maxDistanceChangeTrigger: number,
	minDataInterval: number,
	minDistance: number,
	minDistanceChangeTrigger: number,
	distance: number,
	distanceChangeTrigger: number,
	sonarQuietMode: number,
}

abstract class DistanceSensorBase extends PhidgetChannel {
	/** @internal */
	data: DistanceSensorData;
	/**
	 * **DistanceChange** event
	 *  * `distance` - The current distance
	 * ---
	 * The most recent distance value the channel has measured will be reported in this event, which occurs when the `dataInterval` has elapsed.
	 * 
	 * *   If a `distanceChangeTrigger` has been set to a non-zero value, the `DistanceChange` event will not occur until the distance has changed by at least the `distanceChangeTrigger` value.
	 */
	onDistanceChange: ((distance: number) => void) | null = null;
	/** @internal */
	_gotDistanceChangeErrorEvent?: boolean;
	/**
	 * **SonarReflectionsUpdate** event
	 *  * `distances` - The reflection values
	 *  * `amplitudes` - The amplitude values
	 *  * `count` - The number of reflections detected
	 * ---
	 * The most recent reflections the channel has detected will be reported in this event, which occurs when the `dataInterval` has elapsed.
	 * 
	 * *   If a `distanceChangeTrigger` has been set to a non-zero value, the `SonarReflectionsUpdate` event will not occur until the distance has changed by at least the `distanceChangeTrigger` value.
	 * *   The closest reflection will be placed at index 0 of the _distances_ array, and the furthest reflection at index 7.
	 * *   If you are only interested in the closest reflection, you can simply use the `DistanceChange` event.
	 * *   The values reported as amplitudes are relative amplitudes of the reflections that are normalized to an arbitrary scale.
	 */
	onSonarReflectionsUpdate: ((distances: readonly [number, number, number, number, number, number, number, number], amplitudes: readonly [number, number, number, number, number, number, number, number], count: number) => void) | null = null;
	/** @internal */
	_gotSonarReflectionsUpdateErrorEvent?: boolean;

	/**
	 * The Distance Sensor class gathers data from the distance sensor on a Phidget board.
	 * 
	 * If you're using a simple 0-5V sensor that does not have its own firmware, use the VoltageInput or VoltageRatioInput class instead, as specified for your device.
	 * @public
	 */
	constructor();
	/** @internal */
	constructor(ch?: Channel);
	constructor(ch?: Channel) {
		super(ch);
		this._class = ChannelClass.DISTANCE_SENSOR;
		this.name = "DistanceSensor";
		this.data = this._initData();
	}

	/** @internal */
	_bridgeInput(bp: BridgePacket) {

		switch(bp.vpkt) {
		case BP.SETDATAINTERVAL:
			if (bp.entryCount > 1)
				this.data.dataInterval = bp.entries[1].v as number;
			else
				this.data.dataInterval = bp.entries[0].v as number;
			this._FIREPropertyChange('DataInterval', bp);
			this._FIREPropertyChange('DataRate', bp);
			break;
		case BP.SETCHANGETRIGGER:
			this.data.distanceChangeTrigger = bp.entries[0].v as number;
			this._FIREPropertyChange('DistanceChangeTrigger', bp);
			break;
		case BP.SETSONARQUIETMODE:
			this.data.sonarQuietMode = bp.entries[0].v as number;
			this._FIREPropertyChange('SonarQuietMode', bp);
			break;
		case BP.DISTANCECHANGE: {
			this.data.distance = bp.entries[0].v as number;
			if (this._isAttachedDone && this.onDistanceChange) {
				try {
					this.onDistanceChange(this.data.distance);
				} catch (err) { logEventException(err); }
			}
			break;
		}
		case BP.SONARUPDATE: {
			if (this._isAttachedDone && this.onSonarReflectionsUpdate) {
				try {
					this.onSonarReflectionsUpdate(bp.entries[0].v as readonly [number, number, number, number, number, number, number, number], bp.entries[1].v as readonly [number, number, number, number, number, number, number, number], bp.entries[2].v as number);
				} catch (err) { logEventException(err); }
			}
			break;
		}
		default:
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			throw new PhidgetError(ErrorCode.INVALID_PACKET, "Unsupported bridge packet: 0x" + bp.vpkt!.toString(16));
		}
	}

	/** @internal */
	_initData(): DistanceSensorData {
		return {
			amplitudes: [PUNK.UINT32, PUNK.UINT32, PUNK.UINT32, PUNK.UINT32, PUNK.UINT32, PUNK.UINT32, PUNK.UINT32, PUNK.UINT32],
			distances: [PUNK.UINT32, PUNK.UINT32, PUNK.UINT32, PUNK.UINT32, PUNK.UINT32, PUNK.UINT32, PUNK.UINT32, PUNK.UINT32],
			count: 0, 
			dataInterval: PUNK.DBL,
			maxDataInterval: PUNK.UINT32,
			minDataRate: PUNK.DBL,
			maxDataRate: PUNK.DBL,
			maxDistance: PUNK.UINT32,
			maxDistanceChangeTrigger: PUNK.UINT32,
			minDataInterval: PUNK.UINT32,
			minDistance: PUNK.UINT32,
			minDistanceChangeTrigger: PUNK.UINT32,
			distance: PUNK.UINT32,
			distanceChangeTrigger: PUNK.UINT32,
			sonarQuietMode: PUNK.BOOL,
		}
	}

	/** @internal */
	_initAfterOpen() {
		this.data = this._initData();

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._DST1000_DISTANCESENSOR_100:
			this.data.dataInterval = 250;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 10;
			this.data.maxDistance = 200;
			this.data.maxDistanceChangeTrigger = 200;
			this.data.minDataInterval = 100;
			this.data.minDistance = 0;
			this.data.minDistanceChangeTrigger = 0;
			this.data.distanceChangeTrigger = 0;
			break;
		case DeviceChannelUID._DST1001_DISTANCESENSOR_100:
			this.data.dataInterval = 250;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 33.333333333333336;
			this.data.maxDistance = 650;
			this.data.maxDistanceChangeTrigger = 650;
			this.data.minDataInterval = 30;
			this.data.minDistance = 0;
			this.data.minDistanceChangeTrigger = 0;
			this.data.distanceChangeTrigger = 0;
			break;
		case DeviceChannelUID._DST1002_DISTANCESENSOR_100:
			this.data.dataInterval = 250;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 33.333333333333336;
			this.data.maxDistance = 1300;
			this.data.maxDistanceChangeTrigger = 1300;
			this.data.minDataInterval = 30;
			this.data.minDistance = 0;
			this.data.minDistanceChangeTrigger = 0;
			this.data.distanceChangeTrigger = 0;
			break;
		case DeviceChannelUID._DST1200_DISTANCESENSOR_100:
			this.data.dataInterval = 250;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 10;
			this.data.maxDistance = 10000;
			this.data.maxDistanceChangeTrigger = 10000;
			this.data.minDataInterval = 100;
			this.data.minDistance = 40;
			this.data.minDistanceChangeTrigger = 0;
			this.data.distanceChangeTrigger = 0;
			this.data.sonarQuietMode = 1;
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	// eslint-disable-next-line require-await
	async _setDefaults() {
		let bp;

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._DST1000_DISTANCESENSOR_100:
		case DeviceChannelUID._DST1001_DISTANCESENSOR_100:
		case DeviceChannelUID._DST1002_DISTANCESENSOR_100:
			bp = new BridgePacket();
			bp.set({ name: "0", type: "u", value: Math.round(this.data.dataInterval) });
			await bp.send(this._ch, BP.SETDATAINTERVAL);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "u", value: this.data.distanceChangeTrigger });
			await bp.send(this._ch, BP.SETCHANGETRIGGER);
			break;
		case DeviceChannelUID._DST1200_DISTANCESENSOR_100:
			bp = new BridgePacket();
			bp.set({ name: "0", type: "u", value: Math.round(this.data.dataInterval) });
			await bp.send(this._ch, BP.SETDATAINTERVAL);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "u", value: this.data.distanceChangeTrigger });
			await bp.send(this._ch, BP.SETCHANGETRIGGER);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "d", value: this.data.sonarQuietMode });
			await bp.send(this._ch, BP.SETSONARQUIETMODE);
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	_hasInitialState() {

		if ((this.data.distance == PUNK.UINT32)
			&& ! this._gotDistanceChangeErrorEvent)
			return false;

		return true;
	}

	/** @internal */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	_fireInitialEvents() {

		if(this.data.distance != PUNK.UINT32)
			if (this.onDistanceChange)
				try {
					this.onDistanceChange(this.data.distance);
				} catch (err) { logEventException(err); }

	}

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * *   The timing between events can also be affected by the `distanceChangeTrigger`.
	 * @throws {@link PhidgetError}
	 */
	get dataInterval() { return this.getDataInterval(); }
	/**
	 * The minimum value that `dataInterval` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDataInterval() { return this.getMinDataInterval(); }
	/**
	 * The maximum value that `dataInterval` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDataInterval() { return this.getMaxDataInterval(); }
	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @throws {@link PhidgetError}
	 */
	get dataRate() { return this.getDataRate(); }
	/**
	 * The minimum value that `dataRate` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDataRate() { return this.getMinDataRate(); }
	/**
	 * The maximum value that `dataRate` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDataRate() { return this.getMaxDataRate(); }
	/**
	 * The most recent distance value that the channel has reported.
	 * 
	 * *   This value will always be between `minDistance` and `maxDistance`.
	 * @throws {@link PhidgetError}
	 */
	get distance() { return this.getDistance(); }
	/**
	 * The minimum distance that a event will report.
	 * @throws {@link PhidgetError}
	 */
	get minDistance() { return this.getMinDistance(); }
	/**
	 * The maximum distance that a event will report.
	 * @throws {@link PhidgetError}
	 */
	get maxDistance() { return this.getMaxDistance(); }
	/**
	 * The channel will not issue an event until the distance value has changed by the amount specified by the `distanceChangeTrigger`.
	 * 
	 * *   Setting the `distanceChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering,
	 * @throws {@link PhidgetError}
	 */
	get distanceChangeTrigger() { return this.getDistanceChangeTrigger(); }
	/**
	 * The minimum value that `distanceChangeTrigger` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDistanceChangeTrigger() { return this.getMinDistanceChangeTrigger(); }
	/**
	 * The maximum value that `distanceChangeTrigger` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDistanceChangeTrigger() { return this.getMaxDistanceChangeTrigger(); }
	/**
	 * When set to true, the device will operate more quietly.
	 * 
	 * *   The measurable range is reduced when operating in quiet mode.
	 * @throws {@link PhidgetError}
	 */
	get sonarQuietMode() { return this.getSonarQuietMode(); }

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * *   The timing between events can also be affected by the `distanceChangeTrigger`.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getDataInterval(): number {
		this._assertOpen();

		if (this.data.dataInterval === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return this.data.dataInterval;
	}

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * *   The timing between events can also be affected by the `distanceChangeTrigger`.
	 * @throws {@link PhidgetError}
	 * @param dataInterval - The data interval value
	 */
	async setDataInterval(dataInterval: number): Promise<void> {
		this._assertOpen();

		if (dataInterval < this.data.minDataInterval || dataInterval > this.data.maxDataInterval)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDataInterval + " - " + this.data.maxDataInterval + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: dataInterval });
		await bp.send(this._ch, BP.SETDATAINTERVAL);
	}

	/**
	 * The minimum value that `dataInterval` can be set to.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getMinDataInterval(): number {
		this._assertOpen();

		if (this.data.minDataInterval === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDataInterval);
	}

	/**
	 * The maximum value that `dataInterval` can be set to.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getMaxDataInterval(): number {
		this._assertOpen();

		if (this.data.maxDataInterval === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDataInterval);
	}

	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @returns The data rate for the channel
	 * @throws {@link PhidgetError}
	 */
	getDataRate(): number {
		this._assertOpen();

		if (this.data.dataInterval === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (1000.0 / this.data.dataInterval);
	}

	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @throws {@link PhidgetError}
	 * @param dataRate - The data rate for the channel
	 */
	async setDataRate(dataRate: number): Promise<void> {
		this._assertOpen();

		if (dataRate < this.data.minDataRate || dataRate > this.data.maxDataRate)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDataRate + " - " + this.data.maxDataRate + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: Math.round(1000.0 / dataRate) });
		bp.set({ name: "1", type: "g", value: (1000.0 / dataRate) });
		await bp.send(this._ch, BP.SETDATAINTERVAL);
	}

	/**
	 * The minimum value that `dataRate` can be set to.
	 * @returns The data rate value
	 * @throws {@link PhidgetError}
	 */
	getMinDataRate(): number {
		this._assertOpen();

		if (this.data.minDataRate === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDataRate);
	}

	/**
	 * The maximum value that `dataRate` can be set to.
	 * @returns The data rate value
	 * @throws {@link PhidgetError}
	 */
	getMaxDataRate(): number {
		this._assertOpen();

		if (this.data.maxDataRate === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDataRate);
	}

	/**
	 * The most recent distance value that the channel has reported.
	 * 
	 * *   This value will always be between `minDistance` and `maxDistance`.
	 * @returns The distance value
	 * @throws {@link PhidgetError}
	 */
	getDistance(): number {
		this._assertOpen();

		if (this.data.distance === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);
		if (this.data.distance > this.data.maxDistance)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE_HIGH);
		if (this.data.distance < this.data.minDistance)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE_LOW);

		return (this.data.distance);
	}

	/**
	 * The minimum distance that a event will report.
	 * @returns The distance value
	 * @throws {@link PhidgetError}
	 */
	getMinDistance(): number {
		this._assertOpen();

		if (this.data.minDistance === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDistance);
	}

	/**
	 * The maximum distance that a event will report.
	 * @returns The distance value
	 * @throws {@link PhidgetError}
	 */
	getMaxDistance(): number {
		this._assertOpen();

		if (this.data.maxDistance === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDistance);
	}

	/**
	 * The channel will not issue an event until the distance value has changed by the amount specified by the `distanceChangeTrigger`.
	 * 
	 * *   Setting the `distanceChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering,
	 * @returns The change trigger value
	 * @throws {@link PhidgetError}
	 */
	getDistanceChangeTrigger(): number {
		this._assertOpen();

		if (this.data.distanceChangeTrigger === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.distanceChangeTrigger);
	}

	/**
	 * The channel will not issue an event until the distance value has changed by the amount specified by the `distanceChangeTrigger`.
	 * 
	 * *   Setting the `distanceChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering,
	 * @throws {@link PhidgetError}
	 * @param distanceChangeTrigger - The change trigger value
	 */
	async setDistanceChangeTrigger(distanceChangeTrigger: number): Promise<void> {
		this._assertOpen();

		if (distanceChangeTrigger < this.data.minDistanceChangeTrigger || distanceChangeTrigger > this.data.maxDistanceChangeTrigger)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDistanceChangeTrigger + " - " + this.data.maxDistanceChangeTrigger + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: distanceChangeTrigger });
		await bp.send(this._ch, BP.SETCHANGETRIGGER);
	}

	/**
	 * The minimum value that `distanceChangeTrigger` can be set to.
	 * @returns The change trigger value
	 * @throws {@link PhidgetError}
	 */
	getMinDistanceChangeTrigger(): number {
		this._assertOpen();

		if (this.data.minDistanceChangeTrigger === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDistanceChangeTrigger);
	}

	/**
	 * The maximum value that `distanceChangeTrigger` can be set to.
	 * @returns The change trigger value
	 * @throws {@link PhidgetError}
	 */
	getMaxDistanceChangeTrigger(): number {
		this._assertOpen();

		if (this.data.maxDistanceChangeTrigger === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDistanceChangeTrigger);
	}

	/**
	 * When set to true, the device will operate more quietly.
	 * 
	 * *   The measurable range is reduced when operating in quiet mode.
	 * @returns The quiet mode value
	 * @throws {@link PhidgetError}
	 */
	getSonarQuietMode(): boolean {
		this._assertOpen();

		if (this.data.sonarQuietMode === PUNK.BOOL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (!!this.data.sonarQuietMode);
	}

	/**
	 * When set to true, the device will operate more quietly.
	 * 
	 * *   The measurable range is reduced when operating in quiet mode.
	 * @throws {@link PhidgetError}
	 * @param sonarQuietMode - The quiet mode value
	 */
	async setSonarQuietMode(sonarQuietMode: boolean): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (sonarQuietMode !== false && sonarQuietMode !== true)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be a boolean.");

		bp.set({ name: "0", type: "d", value: (sonarQuietMode ? 1 : 0) });
		await bp.send(this._ch, BP.SETSONARQUIETMODE);
	}

	/**
	 * The most recent reflection values that the channel has reported.
	 * 
	 * *   The distance values will always be between `minDistance` and `maxDistance`.
	 * *   The closest reflection will be placed at index 0 of the distances array, and the furthest reflection at index 7
	 * *   The amplitude values are relative amplitudes of the reflections that are normalized to an arbitrary scale.
	 * @returns
	 * 	- distances: The reflection values
	 * 	- amplitudes: The amplitude values
	 * 	- count: The number of reflections
	 * @throws {@link PhidgetError}
	 */
	abstract getSonarReflections(): {distances: readonly [number, number, number, number, number, number, number, number], amplitudes: readonly [number, number, number, number, number, number, number, number], count: number};
}
export { DistanceSensorBase };
