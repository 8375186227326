/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { IRBase } from './IR.gen';
import { BP } from '../BridgePackets.gen';
import { ErrorCode, IRCodeEncoding, IRCodeLength } from '../Enumerations.gen';
import { PhidgetError } from '../PhidgetError';
import { BridgePacket } from '../BridgePacket';
import { type IRCodeInfo } from '../Structs.gen';
import { logEventException } from '../Logging';

/** @public */
class IR extends IRBase {

	/** @internal */
	_bridgeInput(bp: BridgePacket) {
		switch (bp.vpkt) {
			case BP.REPEAT:
				if (this._isAttachedDone && this.onCode) {
					try {
						this.onCode(this.data.lastCodeStr!, this.data.lastCodeBitCount, true);
					} catch (err) { logEventException(err); }
				}
				break;
			case BP.CODE:
				this.data.lastCodeStr = bp.getString(0) as string;
				this.data.lastCodeBitCount = bp.getNumber(1);
				if (this._isAttachedDone && this.onCode) {
					try {
						this.onCode(this.data.lastCodeStr, this.data.lastCodeBitCount, bp.getBoolean(2) as boolean);
					} catch (err) { logEventException(err); }
				}
				break;
			case BP.LEARN: {
				// NOTE: Since the incoming toggle mask is a fixed length byte array, we need to convert to a string and trim nulls
				// eslint-disable-next-line no-control-regex
				const toggleString = String.fromCharCode(...bp.getArray("CodeInfo.toggleMask")).replace(/\u0000/g, '');

				// trim trailing zeros from the repeat array
				const repeatArr = bp.getArray("CodeInfo.repeat");
				while (repeatArr[repeatArr.length - 1] === 0)
					repeatArr.pop();

				this.data.lastLearnedCodeStr = bp.getString("0") as string;
				this.data.lastLearnedCodeInfo = {
					bitCount: bp.getNumber("CodeInfo.bitCount"),
					encoding: bp.getNumber("CodeInfo.encoding") as IRCodeEncoding,
					length: bp.getNumber("CodeInfo.length") as IRCodeLength,
					gap: bp.getNumber("CodeInfo.gap"),
					trail: bp.getNumber("CodeInfo.trail"),
					header: bp.getArray("CodeInfo.header") as [number, number],
					one: bp.getArray("CodeInfo.one") as [number, number],
					zero: bp.getArray("CodeInfo.zero") as [number, number],
					repeat: repeatArr,
					minRepeat: bp.getNumber("CodeInfo.minRepeat"),
					dutyCycle: bp.getNumber("CodeInfo.dutyCycle"),
					carrierFrequency: bp.getNumber("CodeInfo.carrierFrequency"),
					toggleMask: toggleString,
				};
				if (this._isAttachedDone && this.onLearn) {
					try {
						this.onLearn(this.data.lastLearnedCodeStr, this.data.lastLearnedCodeInfo);
					} catch (err) { logEventException(err); }
				}
				break;
			}
			default:
				super._bridgeInput(bp);
				break;
		}
	}

	async transmit(code: string, ci: Partial<IRCodeInfo>) {
		this._assertOpen();

		// Defaults
		const codeInfo: IRCodeInfo = {
			bitCount: 0,
			encoding: IRCodeEncoding.SPACE,
			length: IRCodeLength.CONSTANT,
			gap: 0,
			trail: 0,
			minRepeat: 0,
			dutyCycle: 0,
			carrierFrequency: 0,
			header: [0, 0],
			one: [0, 0],
			zero: [0, 0],
			repeat: [],
			toggleMask: ''
		}

		if (ci.bitCount !== undefined)
			codeInfo.bitCount = ci.bitCount;
		if (ci.encoding !== undefined)
			codeInfo.encoding = ci.encoding;
		if (ci.length !== undefined)
			codeInfo.length = ci.length;
		if (ci.gap !== undefined)
			codeInfo.gap = ci.gap;
		if (ci.trail !== undefined)
			codeInfo.trail = ci.trail;
		if (ci.minRepeat !== undefined)
			codeInfo.minRepeat = ci.minRepeat;
		if (ci.dutyCycle !== undefined)
			codeInfo.dutyCycle = ci.dutyCycle;
		if (ci.carrierFrequency !== undefined)
			codeInfo.carrierFrequency = ci.carrierFrequency;

		if (ci.header !== undefined) {
			if (!Array.isArray(ci.header))
				throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, 'invalid value - header must be Array');
			if (ci.header.length != 2)
				throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, 'invalid value - header must have length 2');
			codeInfo.header = ci.header;
		}

		if (ci.one !== undefined) {
			if (!Array.isArray(ci.one))
				throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, 'invalid value - one must be Array');
			if (ci.one.length != 2)
				throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, 'invalid value - one must have length 2');
			codeInfo.one = ci.one;
		}

		if (ci.zero !== undefined) {
			if (!Array.isArray(ci.zero))
				throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, 'invalid value - zero must be Array');
			if (ci.zero.length != 2)
				throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, 'invalid value - zero must have length 2');
			codeInfo.zero = ci.zero;
		}

		// repeat has a fixed length of 26 bytes and must be zero filled
		const repeatArr: number[] = new Array(26).fill(0);
		if (ci.repeat !== undefined) {
			if (!Array.isArray(ci.repeat))
				throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, 'invalid value - repeat must be Array');
			if (ci.repeat.length > 26)
				throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, 'invalid value - repeat must have length <= 26');

			for (let i = 0; i < ci.repeat.length; i++)
				repeatArr[i] = ci.repeat[i];
		}

		// toggle mask has a fixed length of 33 bytes and must be zero filled
		const toggleMaskArr: number[] = new Array(33).fill(0);
		if (ci.toggleMask !== undefined) {
			if (typeof ci.toggleMask !== 'string')
				throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, 'invalid value - toggleMask must be a string');
			if (ci.toggleMask.length > 33)
				throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, 'invalid value - toggleMask must have length <= 33');

			for (let i = 0; i < ci.toggleMask.length; i++)
				toggleMaskArr[i] = ci.toggleMask.charCodeAt(i);
		}

		const bp = new BridgePacket();
		bp.set({ name: 'code', type: 's', value: code });
		bp.set({ name: 'CodeInfo.bitCount', type: 'u', value: codeInfo.bitCount });
		bp.set({ name: 'CodeInfo.encoding', type: 'd', value: codeInfo.encoding });
		bp.set({ name: 'CodeInfo.length', type: 'd', value: codeInfo.length });
		bp.set({ name: 'CodeInfo.gap', type: 'u', value: codeInfo.gap });
		bp.set({ name: 'CodeInfo.trail', type: 'u', value: codeInfo.trail });
		bp.set({ name: 'CodeInfo.header', type: 'U', value: codeInfo.header });
		bp.set({ name: 'CodeInfo.one', type: 'U', value: codeInfo.one });
		bp.set({ name: 'CodeInfo.zero', type: 'U', value: codeInfo.zero });
		bp.set({ name: 'CodeInfo.repeat', type: 'U', value: repeatArr });
		bp.set({ name: 'CodeInfo.minRepeat', type: 'u', value: codeInfo.minRepeat });
		bp.set({ name: 'CodeInfo.dutyCycle', type: 'g', value: codeInfo.dutyCycle });
		bp.set({ name: 'CodeInfo.carrierFrequency', type: 'u', value: codeInfo.carrierFrequency });
		bp.set({ name: 'CodeInfo.toggleMask', type: 'R', value: toggleMaskArr });
		await bp.send(this._ch, BP.TRANSMIT);
	}

	async transmitRaw(data: readonly number[], carrierFrequency = 0, dutyCycle = 0, gap = 0) {
		this._assertOpen();

		const bp = new BridgePacket();
		bp.set({ name: 'data', type: 'U', value: data });
		bp.set({ name: 'carrierFrequency', type: 'u', value: carrierFrequency });
		bp.set({ name: 'dutyCycle', type: 'g', value: dutyCycle });
		bp.set({ name: 'gap', type: 'u', value: gap });
		await bp.send(this._ch, BP.TRANSMITRAW);
	}

	getLastCode() {
		this._assertOpen();

		if (this.data.lastCodeStr == undefined)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return ({ code: this.data.lastCodeStr, bitCount: this.data.lastCodeBitCount });
	}

	getLastLearnedCode(): { code: string; codeInfo: IRCodeInfo; } {
		this._assertOpen();

		if (this.data.lastLearnedCodeStr == undefined || this.data.lastLearnedCodeInfo == undefined)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return ({ code: this.data.lastLearnedCodeStr, codeInfo: this.data.lastLearnedCodeInfo });
	}
}

export { IR };