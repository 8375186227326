import { HubDevice } from "./device/HubDevice";
import { InterfaceKitDevice } from "./device/InterfaceKitDevice";
import { RFIDDevice } from "./device/RFIDDevice";
import { SpatialDevice } from "./device/SpatialDevice";
import { EncoderDevice } from "./device/EncoderDevice";
import { BridgeDevice } from "./device/BridgeDevice";
import { DataAdapterDevice } from "./device/DataAdapterDevice";
import { GenericDevice } from "./device/GenericDevice";
import { TemperatureSensorDevice } from "./device/TemperatureSensorDevice";
import { AccelerometerDevice } from "./device/AccelerometerDevice";

import { DeviceClass, ErrorCode } from "../Enumerations.gen";
import { type USBConnectionBase } from "./USBConnection";
import { PhidgetUSBDevice, PhidgetUSBDeviceData } from "./PhidgetUSBDevice";
import { GetPhidgetUSBData } from "./USB";
import { findPhidgetUniqueDevice } from "../Device";
import { PhidgetError } from "../PhidgetError";
import { logwarn } from "../Logging";

const UNKNOWN_USB_ID = 175;

export async function CreateUSBDevice(conn: USBConnectionBase, usbDevice: USBDevice): Promise<PhidgetUSBDevice> {

	try {
		await usbDevice.open();

		const devData = await GetPhidgetUSBData(usbDevice);

		const find = { type: 'USB' as const, ...devData };
		let devDef;
		try {
			devDef = findPhidgetUniqueDevice(find);
		} catch (err) {
			logwarn("A USB Phidget (PID: " + find.productID + " Version: " + find.version + ") was found which is not "
				+ "supported by the library. A library upgrade is required to work with this Phidget");
			find.productID = UNKNOWN_USB_ID;
			devDef = findPhidgetUniqueDevice(find);
		}

		const data: PhidgetUSBDeviceData = {
			...devData,
			// NOTE: We don't use the HUB type, even for Hubs, because hub port props are set in initAfterCreate()
			type: 'OTHER' as const,
			id: devDef.uid + "_" + devData.serialNumber + "_v" + devData.version,
			devDef: devDef,
		};

		// Create the Device object
		let phidgetDevice;

		try {
			switch (devDef.c) {
				case DeviceClass.ACCELEROMETER:
					phidgetDevice = new AccelerometerDevice(conn, data, usbDevice);
					break;
				case DeviceClass.BRIDGE:
					phidgetDevice = new BridgeDevice(conn, data, usbDevice);
					break;
				case DeviceClass.DATA_ADAPTER:
					phidgetDevice = new DataAdapterDevice(conn, data, usbDevice);
					break;
				case DeviceClass.ENCODER:
					phidgetDevice = new EncoderDevice(conn, data, usbDevice);
					break;
				case DeviceClass.GENERIC:
					phidgetDevice = new GenericDevice(conn, data, usbDevice);
					break;
				case DeviceClass.HUB:
					phidgetDevice = new HubDevice(conn, data, usbDevice);
					break;
				case DeviceClass.INTERFACE_KIT:
					phidgetDevice = new InterfaceKitDevice(conn, data, usbDevice);
					break;
				case DeviceClass.RFID:
					phidgetDevice = new RFIDDevice(conn, data, usbDevice);
					break;
				case DeviceClass.SPATIAL:
					phidgetDevice = new SpatialDevice(conn, data, usbDevice);
					break;
				case DeviceClass.TEMPERATURE_SENSOR:
					phidgetDevice = new TemperatureSensorDevice(conn, data, usbDevice);
					break;
				default:
					throw new PhidgetError(ErrorCode.UNSUPPORTED);
			}
		} catch (err) {
			if (err instanceof PhidgetError && err.errorCode === ErrorCode.UNSUPPORTED)
				throw new PhidgetError(ErrorCode.UNSUPPORTED, "This USB Phidget is not yet supported in JavaScript: " + devDef.s + " (" + devDef.t + ")");
			throw err;
		}

		if (phidgetDevice.initAfterCreate)
			await phidgetDevice.initAfterCreate();

		await usbDevice.close();

		return phidgetDevice;
	} finally {
		// Ensure device is closed
		try {
			await usbDevice.close();
		} catch { /* Ignore */ }
	}
}