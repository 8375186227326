/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { RCServoBase } from './RCServo.gen';
import { ErrorCode } from '../Enumerations.gen';
import { BP } from '../BridgePackets.gen';
import { PhidgetError } from '../PhidgetError';
import { BridgePacket, PUNK } from '../BridgePacket';
import { logEventException } from '../Logging';

/** @public */
class RCServo extends RCServoBase {

	/** @internal */
	_bridgeInput(bp: BridgePacket) {
		switch (bp.vpkt) {
			case BP.SETENGAGED:
				if (bp.getBoolean(0) === true && this.data.velocityLimit !== 0 && this.data.position !== this.data.targetPosition)
					this.data.isMoving = 1;
				super._bridgeInput(bp);
				break;

			case BP.SETVELOCITYLIMIT:
				if (this.data.engaged && bp.getNumber(0) !== 0 && this.data.position !== this.data.targetPosition)
					this.data.isMoving = 1;
				super._bridgeInput(bp);
				break;

			case BP.SETTARGETPOSITION:
				if (this.data.engaged && this.data.velocityLimit !== 0 && this.data.position !== bp.getNumber(0))
					this.data.isMoving = 1;
				super._bridgeInput(bp);
				break;

			case BP.POSITIONCHANGE:
				this.data.position = bp.getNumber(0);
				if (this._isAttachedDone && this.onPositionChange) {
					try {
						this.onPositionChange(this._positionUser(this.data.position));
					} catch (err) { logEventException(err); }
				}
				break;

			case BP.VELOCITYCHANGE:
				this.data.velocity = bp.getNumber(0);
				if (this._isAttachedDone && this.onVelocityChange) {
					try {
						this.onVelocityChange(this._velocityUser(this.data.velocity));
					} catch (err) { logEventException(err); }
				}
				break;

			case BP.TARGETPOSITIONREACHED:
				this.data.position = bp.getNumber(0);
				this.data.isMoving = 0;
				if (this._isAttachedDone && this.onTargetPositionReached) {
					try {
						this.onTargetPositionReached(this._positionUser(this.data.position));
					} catch (err) { logEventException(err); }
				}
				break;

			default:
				super._bridgeInput(bp);
				break;
		}
	}

	/** @internal */
	_positionUser(position_us: number) {

		return (this.data.minPosition +
			((position_us - this.data.minPulseWidth) / (this.data.maxPulseWidth - this.data.minPulseWidth)) *
			this.data.maxPosition - this.data.minPosition);
	}
	/** @internal */
	_positionUS(position_user: number) {

		if (this.data.maxPosition > this.data.minPosition)
			return (this.data.minPulseWidth +
				((this.data.maxPulseWidth - this.data.minPulseWidth) *
					(position_user - this.data.minPosition)) /
				(this.data.maxPosition - this.data.minPosition));

		return (this.data.maxPulseWidth +
			((this.data.maxPulseWidth - this.data.minPulseWidth) *
				(position_user - this.data.maxPosition)) /
			(this.data.maxPosition - this.data.minPosition));
	}
	/** @internal */
	_velocityUser(velocity_us: number) {

		return ((Math.abs((this.data.maxPosition - this.data.minPosition)) * velocity_us) /
			(this.data.maxPulseWidth - this.data.minPulseWidth));
	}
	/** @internal */
	_velocityUS(velocity_user: number) {

		return (((this.data.maxPulseWidth - this.data.minPulseWidth) * velocity_user) /
			Math.abs(this.data.maxPosition - this.data.minPosition));
	}
	/** @internal */
	_accelUser(accel_us: number) {

		return (((Math.abs((this.data.maxPosition - this.data.minPosition)) * accel_us) /
			(this.data.maxPulseWidth - this.data.minPulseWidth)));
	}
	/** @internal */
	_accelUS(accel_user: number) {

		return (((this.data.maxPulseWidth - this.data.minPulseWidth) * accel_user) /
			Math.abs(this.data.maxPosition - this.data.minPosition));
	}

	getAcceleration() {
		this._assertOpen();

		if (this.data.acceleration === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this._accelUser(this.data.acceleration));
	}

	getMinAcceleration() {
		this._assertOpen();

		if (this.data.minAcceleration === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this._accelUser(this.data.minAcceleration));
	}

	getMaxAcceleration() {
		this._assertOpen();

		if (this.data.maxAcceleration === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this._accelUser(this.data.maxAcceleration));
	}

	getPosition() {
		this._assertOpen();

		if (this.data.position === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this._positionUser(this.data.position));
	}

	setMaxPosition(maxPosition: number) {
		this._assertOpen();

		this.data.maxPosition = maxPosition;
	}

	setMinPosition(minPosition: number) {
		this._assertOpen();

		this.data.minPosition = minPosition;
	}

	getTargetPosition() {
		this._assertOpen();

		if (this.data.targetPosition === PUNK.DBL)
			throw (new PhidgetError(ErrorCode.UNKNOWN_VALUE));

		return this._positionUser(this.data.targetPosition);
	}

	getVelocity() {
		this._assertOpen();

		if (this.data.velocity === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this._velocityUser(this.data.velocity));
	}

	getVelocityLimit() {
		this._assertOpen();

		if (this.data.velocityLimit === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this._velocityUser(this.data.velocityLimit));
	}

	getMinVelocityLimit() {
		this._assertOpen();

		if (this.data.minVelocityLimit === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this._velocityUser(this.data.minVelocityLimit));
	}

	getMaxVelocityLimit() {
		this._assertOpen();

		if (this.data.maxVelocityLimit === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this._velocityUser(this.data.maxVelocityLimit));
	}

	async setAcceleration(acceleration: number) {
		this._assertOpen();

		acceleration = this._accelUS(acceleration);

		if (acceleration < this.data.minAcceleration || acceleration > this.data.maxAcceleration)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.minAcceleration + " - " + this.maxAcceleration + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: acceleration });
		await bp.send(this._ch, BP.SETACCELERATION);
	}

	async setTargetPosition(targetPosition: number) {
		this._assertOpen();

		targetPosition = this._positionUS(targetPosition);

		if (targetPosition < this.data.minPulseWidth || targetPosition > this.data.maxPulseWidth)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.minPosition + " - " + this.maxPosition + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: targetPosition });
		await bp.send(this._ch, BP.SETTARGETPOSITION);
	}

	async setVelocityLimit(velocityLimit: number) {
		this._assertOpen();

		velocityLimit = this._velocityUS(velocityLimit);

		if (velocityLimit < this.data.minVelocityLimit || velocityLimit > this.data.maxVelocityLimit)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.minVelocityLimit + " - " + this.maxVelocityLimit + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: velocityLimit });
		await bp.send(this._ch, BP.SETVELOCITYLIMIT);
	}
}

export { RCServo };