import { USBConnectionBase, USBConnType } from './USBConnection';
import { ErrorCode } from '../Enumerations.gen';
import { PhidgetError } from '../PhidgetError';
import { USBVID_PHIDGETS } from './USB';

import { type ConnectionOptions } from '../Connection';
import { PhidgetUSBDevice } from './PhidgetUSBDevice';

/**
 * @public
 */
export class USBConnection extends USBConnectionBase {
	// For in-browser WebUSB, we can just assume that the navigator is giving us the same instance of a USBDevice each time
	/** @internal */
	private _usbdevs: Set<USBDevice>;
	/** @internal */
	protected _pUSBDevices: Map<USBDevice, PhidgetUSBDevice>;

	/**
	 * Enables direct USB connection to Phidgets in the browser
	 * 
	 * @throws {@link PhidgetError} Browser must support WebUSB
	 */
	constructor(opts?: ConnectionOptions) {

		if (!('navigator' in window) || navigator == undefined || navigator.usb == undefined)
			throw new PhidgetError(ErrorCode.UNSUPPORTED, "USB Support not detected. Make sure your browser supports WebUSB.");

		super(navigator.usb, USBConnType.Web, opts);

		this._usbdevs = new Set();
		this._pUSBDevices = new Map();
	}
	
	close() {
		super.close();
		
		this._usbdevs = new Set();
		this._pUSBDevices = new Map();
	}

	async requestWebUSBDeviceAccess() {

		if (!this.connected)
			throw new PhidgetError(ErrorCode.NOT_ATTACHED, "Not connected");

		const filter = { vendorId: USBVID_PHIDGETS, classCode: 0xFF };
		try {
			await this._webusb.requestDevice({ filters: [filter] });
			await this._scanWebUSBDevices();
		} catch (err) {
			// NOTE: We don't care if there is an error - it probably just means the user didn't choose a device
		}
	}

	/** @internal */
	protected _trackUSBDevice(dev: USBDevice): boolean {
		if (this._usbdevs.has(dev))
			return false;
		this._usbdevs.add(dev);
		return true;
	}

	/** @internal */
	protected _untrackUSBDevice(dev: USBDevice): void {
		this._usbdevs.delete(dev);
	}

	/** @internal */
	protected _getPUSBDevice(dev: USBDevice): PhidgetUSBDevice | undefined {
		return this._pUSBDevices.get(dev);
	}

	/** @internal */
	protected _addPUSBDevice(dev: PhidgetUSBDevice): void {
		this._pUSBDevices.set(dev['usbDevice'], dev);
	}

	/** @internal */
	protected _deletePUSBDevice(dev: PhidgetUSBDevice): void {
		this._pUSBDevices.delete(dev['usbDevice']);
	}
	
	/** @internal */
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	_setEpTimeout(_dev: USBDevice, _ep: number, _timeout: number): void {
		// Does nothing on Web
	}
	
	/** @internal */
	protected _getDevices(): Promise<USBDevice[]> {
		return this._webusb.getDevices();
	}
}