import { GPSBase } from './GPS.gen';
import { BP } from '../BridgePackets.gen';
import { BridgePacket } from '../BridgePacket';
import { PhidgetError } from '../PhidgetError';
import { ErrorCode } from '../Enumerations.gen';
import { GPGGA, GPGSA, GPRMC, GPVTG, NMEAData } from '../Structs.gen';

function readGPGGA(bp: BridgePacket): GPGGA {
	return {
		latitude: bp.getNumber("GPGGA.latitude"),
		longitude: bp.getNumber("GPGGA.longitude"),
		fixQuality: bp.getNumber("GPGGA.fixQuality"),
		numSatellites: bp.getNumber("GPGGA.numSatellites"),
		horizontalDilution: bp.getNumber("GPGGA.horizontalDilution"),
		altitude: bp.getNumber("GPGGA.altitude"),
		heightOfGeoid: bp.getNumber("GPGGA.heightOfGeoid")
	};
}

function readGPGSA(bp: BridgePacket): GPGSA {
	return {
		mode: String.fromCharCode(bp.getNumber("GPGSA.mode")),
		fixType: bp.getNumber("GPGSA.fixType"),
		satUsed: bp.getArray("GPGSA.satUsed") as [number, number, number, number, number, number, number, number, number, number, number, number],
		posnDilution: bp.getNumber("GPGSA.posnDilution"),
		horizDilution: bp.getNumber("GPGSA.horizDilution"),
		vertDilution: bp.getNumber("GPGSA.vertDilution")
	};
}

function readGPRMC(bp: BridgePacket): GPRMC {
	return {
		status: String.fromCharCode(bp.getNumber("GPRMC.status")),
		latitude: bp.getNumber("GPRMC.latitude"),
		longitude: bp.getNumber("GPRMC.longitude"),
		speedKnots: bp.getNumber("GPRMC.speedKnots"),
		heading: bp.getNumber("GPRMC.heading"),
		magneticVariation: bp.getNumber("GPRMC.magneticVariation"),
		mode: String.fromCharCode(bp.getNumber("GPRMC.mode"))
	};
}

function readGPVTG(bp: BridgePacket): GPVTG {
	return {
		trueHeading: bp.getNumber("GPVTG.trueHeading"),
		magneticHeading: bp.getNumber("GPVTG.magneticHeading"),
		speedKnots: bp.getNumber("GPVTG.speedKnots"),
		speed: bp.getNumber("GPVTG.speed"),
		mode: String.fromCharCode(bp.getNumber("GPVTG.mode"))
	};
}

function readNMEAData(bp: BridgePacket): NMEAData {
	return {
		GGA: readGPGGA(bp),
		GSA: readGPGSA(bp),
		RMC: readGPRMC(bp),
		VTG: readGPVTG(bp)
	};
}

/** @public */
class GPS extends GPSBase {

	/** @internal */
	_bridgeInput(bp: BridgePacket) {

		switch (bp.vpkt) {
			case BP.DATA:
				this.data.NMEAData = readNMEAData(bp);
				break;

			case BP.TIME:
				this.data.time = {
					tm_ms: bp.getNumber("GPSTime.tm_ms"),
					tm_sec: bp.getNumber("GPSTime.tm_sec"),
					tm_min: bp.getNumber("GPSTime.tm_min"),
					tm_hour: bp.getNumber("GPSTime.tm_hour")
				};
				break;

			case BP.DATE:
				this.data.date = {
					tm_mday: bp.getNumber("GPSDate.tm_mday"),
					tm_mon: bp.getNumber("GPSDate.tm_mon"),
					tm_year: bp.getNumber("GPSDate.tm_year")
				};
				break;

			default:
				super._bridgeInput(bp);
				break;
		}
	}

	getDateAndTime() {
		this._assertOpen();

		if (this.data.time === null)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		if (this.data.date === null)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		const date = new Date(Date.UTC(this.data.date.tm_year, this.data.date.tm_mon, this.data.date.tm_mday,
			this.data.time.tm_hour, this.data.time.tm_min, this.data.time.tm_sec, this.data.time.tm_ms));

		return date;
	}
}

export { GPS };