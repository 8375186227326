﻿import { PacketTrackers } from '../PacketTracker';
import { UserPhidgets } from '../../phidget22';
import { ErrorCode, ErrorEventCode, HubPortMode } from '../../Enumerations.gen';
import { PhidgetError } from '../../PhidgetError';
import { logerr, loginfo, logverbose, logwarn, logdebug } from '../../Logging';
import { PhidgetVendorDescriptorType, PhidgetUSBRequestType } from '../USB';
import { VINTPacketCode_to_PhidgetReturnCode, VINTPacketDescription, VINTStatusCode } from '../VintPackets';
import { BP } from '../../BridgePackets.gen';
import { PhidgetSleep } from '../../Utils';
import { Channel } from '../../Channel';
import { BridgePacket, PUNK } from '../../BridgePacket';
import { LocalDevice, MAX_OUT_PACKET_SIZE } from '../LocalDevice';
import { USBConnectionBase } from '../USBConnection';
import { VINTDevice } from './VINTDevice';
import { VINTDeviceData, VINTDeviceProperties, VINTPortProperties } from '../../Device';
import { PhidgetUSBDevice, PhidgetUSBDeviceData } from '../PhidgetUSBDevice';
import { DeviceUID, PhidgetDevices } from '../../Devices.gen';
import { Phidget } from '../../Phidget';

export const HUB_PORT_ID_MAX = 0x0F;
const VINTHUB_MAXPORTS = 6;
//const VINTHUB_MAXCHANNELS = 32;

const PACKETID_MAX = 126;
export const PACKETIDS_PER_PORT = (PACKETID_MAX / 6);
const PACKETRETURN_notACK = 0x80;
const INPACKET_HUBMSG_FLAG = 0x80;

//Flag for identifying the start of a VINT packet in the USB packet stream
const IN_VINTPACKET_START = 0x08;

export const enum HubPacketType {
	SETPORTMODE = 0x00,
	UPGRADE_FIRMWARE = 0x01,
	GETTXBUFFERSTATUS = 0x02,
	PORTPOWER = 0x03,
	MESHMODE = 0x04,
	CALIBRATION_MODE = 0x05,
	CALIBRATION_WRITE = 0x06,
	CALIBRATION_EXIT = 0x07,
}

const enum PacketType {
	HUB = 0x40,
	DEVICE = 0x20
}

const enum InPacketType {
	TXBUFFERSTATUS = 0x00,
	OVERCURRENT = 0x01,
	DETACH = 0x02,
	DISABLE = 0x03,
	SPEEDCHANGE = 0x04,
	REENUMERATION = 0x05
}

export const enum VINTProperties {
	POWERSOURCE = 0x00,
	ISOLATION = 0x01,
	SETSPEEDSUPPORT = 0x02,
	SETSPEEDLIMIT = 0x03,
	AUTOSETSPEEDSUPPORT = 0x04
}

const UNKNOWN_VINT_ID = 0xff0;

class HubDevice extends PhidgetUSBDevice {

	// non-optional
	declare hubPortProps: VINTPortProperties[];

	packetOutCounter: number[];
	packetTrackers: PacketTrackers;
	scanError: number;

	private outstandingPacketCnt: number[];
	private internalPacketInBufferLen: number;
	private packetCounter: number;
	private splitPacketStoragePtr: number;
	private splitPacketStorage: number[];

	private vintDevices: Record<number, VINTDevice>;

	constructor(conn: USBConnectionBase, data: PhidgetUSBDeviceData, usbDev: USBDevice) {
		super(conn, data, usbDev);

		// Ensure that we actually support this device
		switch (this.devDef.uid) {
			case DeviceUID._HUB0000_PHIDUSB:
			case DeviceUID._HUB0001:
			case DeviceUID._HUB0001_AUTOSETSPEED:
			case DeviceUID._HUB5000_PHIDUSB:
			case DeviceUID._HUB0000_1:
			case DeviceUID._HUB0002:
			case DeviceUID._HUB0007:
				break;

			default:
				throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}

		this.vintDevices = {};

		this.hubPortProps = [];
		this.outstandingPacketCnt = new Array(this.numVintPorts).fill(0);
		this.packetOutCounter = new Array(this.numVintPorts).fill(0);
		this.internalPacketInBufferLen = 0;
		this.packetCounter = -1;
		this.splitPacketStoragePtr = 0;
		this.splitPacketStorage = new Array(54).fill(0);

		this.scanError = 0;

		this.packetTrackers = new PacketTrackers();
	}

	// Define getters based on devices.h Attr structs in C library
	get numVintPorts() { return this.devDef.cn[0]; }
	get numVintPortModes() { return this.devDef.cn[1]; }

	async initAfterCreate() {
		for (let i = 0; i < this.numVintPorts; i++)
			await this.updatePortProperties(i);
	}

	makePacket(vintDevice: LocalDevice, packetID: number, bufferIn?: Uint8Array) {
		const buffer = new Uint8Array(new ArrayBuffer(MAX_OUT_PACKET_SIZE));

		if (buffer.byteLength < this.getMaxOutPacketSize())
			throw new PhidgetError(ErrorCode.UNEXPECTED);

		if (bufferIn == undefined)
			bufferIn = new Uint8Array(0);

		if (this.getMaxOutPacketSize() < bufferIn.length + 4)
			throw new PhidgetError(ErrorCode.UNEXPECTED);

		//NOTE: packetOutCounter is incremented AFTER the packet is sent successfully
		buffer.set([((this.packetOutCounter[vintDevice.hubPort] << 4) & 0xF0) | (vintDevice.hubPort & 0x0F)], 0);

		buffer.set([vintDevice.vintID & 0xFF], 1);
		buffer.set([(vintDevice.vintID >> 4) & 0xF0], 2);
		buffer.set([packetID], 3);

		buffer.set(bufferIn, 4);

		return buffer.slice(0, bufferIn.length + 4);
	}

	async openAndUpdatePortProperties(port: number) {
		try {
			await this.lock();
			let opened = false;
			try {
				await this.open(false);
				opened = true;
				await this.updatePortProperties(port);
			} finally {
				if (opened)
					await this.close(false, false);
			}
		} finally {
			this.unlock();
		}
	}

	async updatePortProperties(port: number) {
		let prop, propLen;

		const buf = await this.readDescriptor(PhidgetVendorDescriptorType.USB_DESC_TYPE_VINT_PORT_DESC, port);

		const vintPortDesc = {
			bLength: buf.getUint8(0),
			bDescriptorType: buf.getUint8(1),
			bPortMode: buf.getUint8(2),       // CphidgetVINT_PortMode
			bPowered: buf.getUint8(3),        //whether power is enabled
			dwSpeedHz: buf.getUint32(4, true),      // VINT Speed
			bVINTProtocolVersion: buf.getUint8(8),    // VINT Protocl version
			VINTProperties: new DataView(buf.buffer, 9)
		}

		this.hubPortProps[port] = {
			portProto: vintPortDesc.bVINTProtocolVersion,
			portSpeed: vintPortDesc.dwSpeedHz,
			portMode: vintPortDesc.bPortMode as HubPortMode,
			portPowered: !!vintPortDesc.bPowered,
			portSuppSetSpeed: false,	// default
			portSuppAutoSetSpeed: false,	// default
			portMaxSpeed: 100000 		// default
		}

		for (let i = 0; i < vintPortDesc.VINTProperties.byteLength; i += propLen) {
			prop = vintPortDesc.VINTProperties.getUint8(i) & 0x1F;
			propLen = ((vintPortDesc.VINTProperties.getUint8(i) & 0xE0) >> 5) + 1;
			switch (prop) {
				case VINTProperties.SETSPEEDSUPPORT:
					this.hubPortProps[port].portSuppSetSpeed = true;
					break;
				case VINTProperties.AUTOSETSPEEDSUPPORT:
					this.hubPortProps[port].portSuppAutoSetSpeed = true;
					break;
				case VINTProperties.SETSPEEDLIMIT:
					this.hubPortProps[port].portMaxSpeed = vintPortDesc.VINTProperties.getUint32(i + 1, false);
					break;
				default:
					loginfo("Unknown VINT Port property: " + prop);
			}
		}

	}

	async sendHubPacket(hubPacketType: HubPacketType, bufferIn?: Uint8Array) {
		await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_DEVICE_WRITE, (PacketType.HUB | hubPacketType), 0, bufferIn);
	}

	async sendHubPortPacket(hubPort: number, hubPacketType: HubPacketType, bufferIn?: Uint8Array) {
		await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, (PacketType.HUB | hubPacketType), hubPort, bufferIn);
	}

	processPacketReturnCodes(buffer: DataView, length: number) {
		let readPtr = 0;
		while (readPtr < length) {
			const packetID = buffer.getUint8(readPtr) & 0x7F;
			const port = Math.trunc((packetID - 1) / PACKETIDS_PER_PORT);
			let response = VINTStatusCode.ACK;
			if (buffer.getUint8(readPtr) & PACKETRETURN_notACK) {
				readPtr++;
				response = buffer.getUint8(readPtr);
			}
			readPtr++;

			const NotFound = () => {
				//Dont display if it's NOTATTACHED - since this is pretty common/expected
				if (response !== VINTStatusCode.NOTATTACHED) {
					loginfo("An unexpected PacketID was returned: " + packetID + "(" + response + " - " + VINTPacketDescription[response] + "). " +
						"Probably this packet is from a previous session or detached device.");
				}
				//Request a new count because our count will now be out
				this.readInTXBufferCounts(port).catch(err => {
					// NOTE: We don't await on this, and just swallow any errors
					logwarn("Failure to read TX Buffer counts", err);
				});
			};

			const packetTracker = this.packetTrackers.packetTracker.get(packetID);
			if (!packetTracker) {
				NotFound();
				continue;
			}

			const packetSpace = packetTracker.len;
			logverbose("Packet " + packetID + " response: " + response + " - " + VINTPacketDescription[response] + ", Port " + port);

			const res = VINTPacketCode_to_PhidgetReturnCode[response] as ErrorCode;
			//NOTE: setting the return code marks this tracker as signalled
			// We can no longer use it here because it can be claimed immediately by another thread... (artefact comment from phidget22.c)
			try {
				packetTracker.setPacketReturnCode(res);
			} catch (err) {
				NotFound();
				continue;
			}

			this.releasePacketSpace(port, packetSpace);

			switch (res) {
				// Don't log these cases, just return result to user
				case ErrorCode.SUCCESS:
					break;
				case ErrorCode.NOT_CONFIGURED:
				case ErrorCode.INVALID_ARGUMENT:
				case ErrorCode.INVALID:
				case ErrorCode.INVALID_PACKET:
					break;
				case ErrorCode.NO_SPACE:
					logerr("Got a NOSPACE response from a VINT device, Port " + port + ". " +
						"This usually indicates firmware problems.");
					//Request a new count because our count will now be out
					this.readInTXBufferCounts(port).catch(err => {
						// NOTE: We don't await on this, and just swallow any errors
						logwarn("Failure to read TX Buffer counts", err);
					});
					break;
				case ErrorCode.NOT_ATTACHED:
					loginfo("Got a NOTATTACHED response from a VINT device, Port " + port);
					break;
				case ErrorCode.BUSY:
					logerr("Got a NAK response form a VINT device, Port " + port + ". " +
						"This means the device decided not to deal with this data; try again.");
					break;
				case ErrorCode.FILE_TOO_BIG:
					logerr("Got a TOOBIG response from a VINT device, Port " + port + ". " +
						"This means the packet was too big.");
					break;
				case ErrorCode.UNEXPECTED:
				default:
					logerr("Got an unexpected response from a VINT device: " + response + " - " + VINTPacketDescription[response] + ". " +
						"This usually indicates firmware problems.");
					break;
			}
		}
	}

	processVintPacket(buffer: DataView) {

		const vintPort = buffer.getUint8(0) & 0x07;
		const vintID = ((buffer.getUint8(0) & 0xF0) << 4) + buffer.getUint8(1);
		const dataCount = buffer.getUint8(2) & 0x3F;

		let childIndex = vintPort;

		// VINT port devices live higher in the child array.
		if (vintID <= HUB_PORT_ID_MAX)
			childIndex += vintID * this.numVintPorts;

		const vintDevice = this.vintDevices[childIndex];
		if (!vintDevice)
			return;

		if (vintDevice.vintID !== vintID) {
			loginfo("Seeing VINT Data on Port: " + vintPort + " for VINT Device: " + vintID + ", but device in structure is: " + vintDevice.vintID);
			return;
		}

		//Sending the data count portion, so the packet length is +1
		vintDevice.dataInput(new DataView(buffer.buffer, 2 + buffer.byteOffset, dataCount + 1));
	}

	async readInTXBufferCounts(port: number) {
		this.outstandingPacketCnt[port] = PUNK.SIZE;

		// Request a new count
		await this.sendHubPacket(HubPacketType.GETTXBUFFERSTATUS);
	}

	async initAfterOpen() {
		let i;

		// Setup max/min values
		this.internalPacketInBufferLen = PUNK.SIZE;
		//set data arrays to unknown
		for (i = 0; i < this.numVintPorts; i++)
			this.outstandingPacketCnt[i] = PUNK.SIZE;

		this.packetCounter = -1;
		this.splitPacketStoragePtr = 0;

		const len = VINTHUB_MAXPORTS + 1;
		const buffer = await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_DEVICE_READ,
			(PacketType.HUB | HubPacketType.GETTXBUFFERSTATUS), 0, len);

		if (buffer.byteLength !== this.numVintPorts + 1)
			throw new PhidgetError(ErrorCode.UNEXPECTED, "Bad buffer length from transferpacket from HubDevice");

		this.internalPacketInBufferLen = buffer.getUint8(0);
		for (i = 0; i < this.numVintPorts; i++) {
			// -1 because the internalPacketInBufferLen byte buffer can only actually
			//      internalPacketInBufferLen-1 bytes or the read/write pointers would overlap
			this.outstandingPacketCnt[i] = this.internalPacketInBufferLen - 1 - buffer.getUint8(i + 1);
		}
	}

	dataInput(buffer: DataView) {
		let dataCount;

		const packetCounter = (buffer.getUint8(0) >> 4) & 0x07;
		const packetReturnLen = buffer.getUint8(0) & 0x0F;
		const dataEndIndex = buffer.byteLength - packetReturnLen;

		let nextPacketStart = buffer.getUint8(1) & 0x3F;
		let readPtr = 2;
		let killOutstandingPackets = false;

		// OUT Packet status return (0-31 bytes)
		// We process these 1st in case a detach message comes in
		this.processPacketReturnCodes(new DataView(buffer.buffer, dataEndIndex), buffer.byteLength - dataEndIndex);

		if (buffer.getUint8(1) & INPACKET_HUBMSG_FLAG) {
			switch (buffer.getUint8(readPtr)) {
				case InPacketType.TXBUFFERSTATUS:
					readPtr++;
					logdebug("VINTHUB_HUBINPACKET_TXBUFFERSTATUS packet filling outstandingPacketCnt");
					for (let i = 0; i < this.numVintPorts; i++) {
						// -1 because the 128 byte buffer cna only actually hold 127 bytes or the read/write pointers would overlap
						this.outstandingPacketCnt[i] = this.internalPacketInBufferLen - 1 - buffer.getUint8(readPtr++);
					}
					break;
				case InPacketType.OVERCURRENT:
					readPtr++;

					for (let i = 0; i < this.numVintPorts; i++) {
						if (buffer.getUint8(readPtr) & (0x01 << i)) {
							logwarn("Hub overcurrent detected on Port: " + i);

							const bp = new BridgePacket();
							bp.set({ name: "0", type: "d", value: ErrorEventCode.OVER_CURRENT });
							bp.set({ name: "1", type: "s", value: "Hub overcurrent detected on Port " + i + ". Check for short." });

							//Iterate over all children, sending message to the ones on this port
							for (const v in this.vintDevices) {
								if (this.vintDevices[v].hubPort !== i)
									continue;
								for (const c in this.vintDevices[v].channels) {
									const ch = this.vintDevices[v].channels[c];
									if (ch.isopen)
										ch.sendErrorEvent(bp);
								}
							}
						}
					}
					readPtr++;
					break;
				case InPacketType.DETACH:
					readPtr++;
					for (let i = 0; i < this.numVintPorts; i++) {
						if (buffer.getUint8(readPtr) & (0x01 << i)) {
							if (this.vintDevices[i]) {
								this.conn._deviceDetach(this.vintDevices[i]);
								delete this.vintDevices[i];
							}
						}
					}
					readPtr++;
					break;
				case InPacketType.DISABLE:
					readPtr++;
					for (let i = 0; i < this.numVintPorts; i++) {
						if (buffer.getUint8(readPtr) & (0x01 << i))
							logwarn("Invalid VINT activity detected on Port: " + i + ". This can be safely ignored if something other than a VINT device is attached.");
					}
					readPtr++;
					break;
				case InPacketType.SPEEDCHANGE:
					readPtr++;
					for (let i = 0; i < this.numVintPorts; i++) {
						if (buffer.getUint8(readPtr) & (0x01 << i)) {
							this.updatePortProperties(i).then(() => {
								loginfo("Hub Port Speed changed to: " + this.hubPortProps[i].portSpeed + " on Port: " + i + ".");
							}, (err) => {
								logwarn("Error updating port properties", err);
							});
						}
					}
					readPtr++;
					break;
				case InPacketType.REENUMERATION:
					readPtr++;
					for (let i = 0; i < this.numVintPorts; i++) {
						if (buffer.getUint8(readPtr) & (0x01 << i))
							logwarn("Re-Enumeration on Port: " + i + ". This could indicate EMI issues, an unstable Hub Port Speed, or firmware issues.");
					}
					readPtr++;
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Got unexpected InPacketType: " + buffer.getUint8(readPtr));
			}
		}

		//We can only run these checks if we have previously received a packet
		if (this.packetCounter === -1) {
			//1st packet: start at the 1st whole packet
			readPtr += nextPacketStart;
		} else {
			//Try to detect if we missed a packet
			if (this.packetCounter !== packetCounter) {
				logwarn("One or more data packets were lost on the Hub.");
				//throw away any partial device packet data from previous packets
				this.splitPacketStoragePtr = 0;
				readPtr += nextPacketStart;
				killOutstandingPackets = true;

				//request a new count
				this.sendHubPacket(HubPacketType.GETTXBUFFERSTATUS).catch((err) => {
					logwarn("Error reading TX Buffer Status", err);
				});
			} else {
				//these need to be 0 or both non-zero
				if (nextPacketStart && !(this.splitPacketStoragePtr)) {
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Problem with split data in vint packet. This should be a firmware/library bug.");
				}
			}
		}

		this.packetCounter = ((packetCounter + 1) & 0x07);

		//If we're starting with a split packet, read in the remainder.
		if (this.splitPacketStoragePtr) {
			while (nextPacketStart--)
				this.splitPacketStorage[this.splitPacketStoragePtr++] = buffer.getUint8(readPtr++);
			//Process a packet
			this.processVintPacket(new DataView((new Uint8Array(this.splitPacketStorage)).buffer));
			//reset pointer
			this.splitPacketStoragePtr = 0;
		}

		//Then, read any full packets in
		while (readPtr < dataEndIndex) {
			//See if we have another packet - the end will be marked by a null
			if ((buffer.getUint8(readPtr) & IN_VINTPACKET_START) === 0x00)
				break;

			//Determine if we have a full or partial packet - require at least 3 bytes to get the length byte
			if (readPtr < (dataEndIndex - 2)) {
				if (buffer.getUint8(readPtr + 2) & 0x80) {
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Got an unexpected MSG in vint data: " + buffer.getUint8(readPtr + 2));
				}

				dataCount = buffer.getUint8(readPtr + 2) & 0x3F;

				if ((readPtr + 3 + dataCount) <= dataEndIndex) {
					//Got here - we have a full packet to process
					this.processVintPacket(new DataView(buffer.buffer, readPtr));
					//loop around and check for the next packet
					readPtr += (dataCount + 3);
					continue;
				}
			}

			//Got here - it means we have a partial packet to queue up
			while (readPtr < dataEndIndex)
				this.splitPacketStorage[this.splitPacketStoragePtr++] = buffer.getUint8(readPtr++);
		}

		if (killOutstandingPackets) {
			loginfo("Killing outstanding packets on hub");
			//Mark TX buffer as unknown sizre if there were outstanding packets
			//      (because we may have missed the packet return)
			for (let i = 0; i < this.numVintPorts; i++) {
				if (this.outstandingPacketCnt[i])
					this.outstandingPacketCnt[i] = PUNK.SIZE;
				this.packetTrackers.setPacketsReturnCode(i, ErrorCode.INTERRUPTED);
			}
		}
	}

	async bridgeInput(_channel: Channel, bp: BridgePacket) {

		switch (bp.vpkt) {
			case BP.SETFIRMWAREUPGRADEFLAG: {
				const hubPort = bp.getNumber(0);
				const timeout = bp.getNumber(1);
				const buffer = new Uint8Array([timeout & 0xFF, (timeout >> 8) & 0xFF]);
				await this.sendHubPortPacket(hubPort, HubPacketType.UPGRADE_FIRMWARE, buffer);
				break;
			}
			case BP.SETPORTMODE: {
				const hubPort = bp.getNumber(0);
				await this.setPortMode(hubPort, bp.getNumber(1) as HubPortMode);
				break;
			}
			case BP.SETPORTPOWER: {
				const hubPort = bp.getNumber(0);
				const buffer = new Uint8Array([bp.getNumber(1)]);
				await this.sendHubPortPacket(hubPort, HubPacketType.PORTPOWER, buffer);
				break;
			}
			case BP.SETCALIBRATIONVALUES:
				throw new PhidgetError(ErrorCode.UNSUPPORTED);
			case BP.OPENRESET:
			case BP.CLOSERESET:
			case BP.ENABLE:
				break;
			default:
				throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type");
		}
	}

	async setPortMode(index: number, newVal: HubPortMode) {

		if (!this.opened)
			throw new PhidgetError(ErrorCode.NOT_ATTACHED);

		const buffer = new Uint8Array([newVal]);

		logdebug("Setting Port: " + index + " mode to " + newVal + " on " + this.name);

		await this.sendHubPortPacket(index, HubPacketType.SETPORTMODE, buffer);
	}

	releasePacketSpace(hubPort: number, packetSize: number) {
		if (this.outstandingPacketCnt[hubPort] !== PUNK.SIZE) {
			//When a packet is lost, things can get out of sun.  Make sure we don't go below 0!
			if (this.outstandingPacketCnt[hubPort] < packetSize)
				this.outstandingPacketCnt[hubPort] = 0;
			else
				this.outstandingPacketCnt[hubPort] -= packetSize;

			//Ensure we don't go outside of spec
			if (this.outstandingPacketCnt[hubPort] > this.internalPacketInBufferLen)
				throw new PhidgetError(ErrorCode.UNEXPECTED, "PacketSpace is out of spec");

			logverbose("Releasing " + packetSize + " bytes, " + (this.internalPacketInBufferLen - this.outstandingPacketCnt[hubPort]) + " remaining, Port " + hubPort);
		}
	}

	async claimPacketSpace(hubPort: number, packetSize: number) {

		if (!this.opened)
			throw new PhidgetError(ErrorCode.NOT_ATTACHED);

		// 2 seconds
		const tm = Date.now() + 2000;

		for (; ;) {
			const pktCnt = this.outstandingPacketCnt[hubPort];

			if (pktCnt !== PUNK.SIZE && (pktCnt + packetSize < this.internalPacketInBufferLen))
				break;

			if (Date.now() >= tm)
				throw new PhidgetError(ErrorCode.TIMEOUT, "Timed out claiming packet space, Port " + hubPort);

			// NOTE: This could be written better, with awaiting on releasePacketSpace, but we don't end up here often enough for it to matter
			await PhidgetSleep(2);
		}

		this.outstandingPacketCnt[hubPort] += packetSize;
		logverbose("Claiming " + packetSize + " bytes, " + (this.internalPacketInBufferLen - this.outstandingPacketCnt[hubPort]) + " remaining, Port " + hubPort);
	}


	private async scanVINTDevice(childIndex: number, id: number, version: number, port: number, realID?: number) {
		let vintDeviceDesc;
		let buf;
		let prop, propLen;

		for (const dev of PhidgetDevices.VINT) {
			if (dev.i !== id)
				continue;
			if (version >= dev.v[1] || version < dev.v[0])
				continue;

			//If the device is already here, just mark as scanned and move one.
			// If an old device is here, remove it
			const vint = this.vintDevices[childIndex];
			if (vint) {
				if (vint.devDef === dev && vint.version === version)
					return;
				this.conn._deviceDetach(vint);
				delete this.vintDevices[childIndex];
			}

			const data: VINTDeviceProperties = {
				vintProto: PUNK.UINT8,
				suppSetSpeed: false,
				suppAutoSetSpeed: false,
				maxSpeed: PUNK.UINT32,
				commSpeed: PUNK.UINT32,
				hubPort: port,
				isHubPort: id <= HUB_PORT_ID_MAX,
				uniqueIndex: childIndex
			};

			// For a VINT Device on a VINT2 capable hub - read out the vint device and port descriptors
			if (id > HUB_PORT_ID_MAX) {
				try {
					buf = await this.readDescriptor(PhidgetVendorDescriptorType.USB_DESC_TYPE_VINT_DEVICE_DESC, port);

					vintDeviceDesc = {
						bLength: buf.getUint8(0),
						bDescriptorType: buf.getUint8(1),
						wID: buf.getUint16(2),
						wVersion: buf.getUint16(4, true),
						bVINTProtocolVersion: buf.getUint8(6),
						VINTProperties: new DataView(buf.buffer, 7)
					}
					data.vintProto = vintDeviceDesc.bVINTProtocolVersion;
					for (let i = 0; i < vintDeviceDesc.VINTProperties.byteLength; i += propLen) {
						prop = vintDeviceDesc.VINTProperties.getUint8(i) & 0x1F;
						propLen = ((vintDeviceDesc.VINTProperties.getUint8(i) & 0xE0) >> 5) + 1
						switch (prop) {
							case VINTProperties.POWERSOURCE:
							case VINTProperties.ISOLATION:
								// NOTE: Unused so far
								break;
							case VINTProperties.SETSPEEDSUPPORT:
								data.suppSetSpeed = true;
								break;
							case VINTProperties.AUTOSETSPEEDSUPPORT:
								data.suppAutoSetSpeed = true;
								break;
							case VINTProperties.SETSPEEDLIMIT:
								data.maxSpeed = vintDeviceDesc.VINTProperties.getUint32(i + 1, false);
								break;
							default:
								loginfo("Unknown VINT Device Property: " + vintDeviceDesc.VINTProperties.getUint8(i));
								break;
						}
					}

					await this.updatePortProperties(port);
				} catch (err) {
					logwarn("Couldn't read VINT Device descriptor from a Hub", err);
				}
			}
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			data.commSpeed = this.hubPortProps![port].portSpeed ?? PUNK.UINT32;

			const vintData: VINTDeviceData = {
				type: 'VINT' as const,
				version: version,
				label: this.label,
				serialNumber: this.serialNumber,
				devDef: dev,
				fwstr: dev.s,
				// Just needs to be a unique string representing this device
				id: this.devDef.uid + "_" + this.serialNumber + '_' + port + '_' + dev.uid,
				parent: this,
				vintDeviceProps: data
			};

			const vintDev = new VINTDevice(this.conn, vintData);
			this.vintDevices[vintDev.index] = vintDev;
			this.conn._attachLocalDevice(vintDev);

			if (id === UNKNOWN_VINT_ID)
				logwarn("A VINT Phidget (ID: " + realID + " Version: " + version + " HubPort: " + port + ") was found which is not supported by the library. A library upgrade is required to work with this Phidget.");

			return;
		}

		// Attach device as the special unknown device
		await this.scanVINTDevice(childIndex, UNKNOWN_VINT_ID, version, port, id);
	}

	async scanVINTDevices() {
		let i, j, childIndex;
		let deviceID;
		let version;

		let buf = await this.readDescriptor(PhidgetVendorDescriptorType.USB_DESC_TYPE_VINT_PORTS_DESC, 0);
		const len = buf.byteLength;
		buf = new DataView(buf.buffer.slice(2));

		if (len !== (this.numVintPorts * 4 + this.numVintPortModes * 4 + 2)) {
			throw new PhidgetError(ErrorCode.UNEXPECTED, "Wrong VINT Ports descriptor length from Hub: " + len + "/"
				+ (this.numVintPorts * 4 + this.numVintPortModes * 4 + 2));
		}

		// Add/Detach Vint devices
		for (childIndex = 0, i = 0; i < this.numVintPorts; i++) {

			deviceID = buf.getUint16(0, true);
			version = buf.getUint8(3) * 100 + buf.getUint8(2);
			buf = new DataView(buf.buffer.slice(4));

			// deviceID is going to be either 0x000 (nothing attached) or > HUB_PORT_ID_MAX for a VINT device plugged in
			if (deviceID > HUB_PORT_ID_MAX) {
				await this.scanVINTDevice(childIndex, deviceID, version, i);
			} else {
				// Nothing plugged in - detach previous VINT device if any
				if (this.vintDevices[childIndex]) {
					this.conn._deviceDetach(this.vintDevices[childIndex]);
					delete this.vintDevices[childIndex];
				}
			}

			childIndex++;

			// NOTE: deviceID and HubPortMode enum overlap for hub port mode devices 
			if (deviceID > HubPortMode.VINT && deviceID <= HUB_PORT_ID_MAX)
				await this.resetVINTPortModeIfNeeded(i, deviceID as HubPortMode);
		}

		// Add port devices
		for (j = 0; j < this.numVintPortModes; j++) {
			deviceID = buf.getUint16(0, true);
			version = buf.getUint8(3) * 100 + buf.getUint8(2);
			buf = new DataView(buf.buffer.slice(4));

			for (i = 0; i < this.numVintPorts; i++)
				await this.scanVINTDevice(childIndex++, deviceID, version, i);
		}
	}

	async resetVINTPortModeIfNeeded(port: number, mode: HubPortMode) {

		if (mode === HubPortMode.VINT)
			return;

		//Check if we have opened a non-hub-port device and the hub port is in port mode...
		// In this case, switch the port mode.  This only applied if we have opened by serial number or label.
		for (const userphid of UserPhidgets) {

			if (userphid._isattached)
				continue;

			if (userphid._hubPort !== port)
				continue;

			if (userphid._isHubPort)
				continue;

			if (userphid._serialNumber === Phidget.ANY_SERIAL_NUMBER && userphid._deviceLabel === Phidget.ANY_LABEL)
				continue;

			if (userphid._serialNumber !== Phidget.ANY_SERIAL_NUMBER) {
				if (userphid._serialNumber !== this.serialNumber)
					continue;
			}

			if (userphid._deviceLabel !== Phidget.ANY_LABEL) {
				if (userphid._deviceLabel !== this.label)
					continue;
			}

			// NOTE: we don't need to lock, because we are locked by the caller
			let opened = false;
			try {
				await this.open();
				opened = true;
				await this.setPortMode(port, HubPortMode.VINT);
			} finally {
				if (opened)
					await this.close();
			}
		}
	}
}

export { HubDevice };