/* eslint-disable @typescript-eslint/no-unused-vars */
import { BridgePacket } from '../BridgePacket';
import { BP } from '../BridgePackets.gen';
import { FirmwareUpgradeBase } from './FirmwareUpgrade.gen';

// XXX Implement
/** @internal */
class FirmwareUpgrade extends FirmwareUpgradeBase {

	/** @internal */
	_bridgeInput(bp: BridgePacket) {
		switch (bp.vpkt) {
			case BP.DEVICEINFO:
				break;
			default:
				super._bridgeInput(bp);
				break;
		}
	}

	/** @internal */
	sendFirmware(_data: number[]): Promise<void> {
		throw new Error('Method not implemented.');
	}
}

export { FirmwareUpgrade };