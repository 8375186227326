/* eslint-disable @typescript-eslint/no-unused-vars */
import { ErrorCode, HubPortMode } from '../Enumerations.gen';
import { PhidgetDevice } from '../Phidget';
import { PhidgetError } from '../PhidgetError';
import { HubDevice } from '../usb/device/HubDevice';
import { HubBase } from './Hub.gen';

/** @public */
class Hub extends HubBase {

	async getPortMode(port: number) {
		this._assertAttached();

		// Only supported for local (USB) connections
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		if (!this._ch!.conn._isLocal)
			throw new PhidgetError(ErrorCode.UNSUPPORTED);

		if (port < 0 || port > (this.hubPortCount - 1))
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: 0 - " + (this.hubPortCount - 1) + ".");

		await (<HubDevice>(this.hub as PhidgetDevice)._device).openAndUpdatePortProperties(port);
		const mode = (this.hub as PhidgetDevice)._device.hubPortProps?.[port].portMode;
		if (mode == undefined)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (mode);
	}
	async getPortPower(port: number) {
		this._assertAttached();

		// Only supported for local (USB) connections
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		if (!this._ch!.conn._isLocal)
			throw new PhidgetError(ErrorCode.UNSUPPORTED);

		if (port < 0 || port > (this.hubPortCount - 1))
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: 0 - " + (this.hubPortCount - 1) + ".");

		await (<HubDevice>(this.hub as PhidgetDevice)._device).openAndUpdatePortProperties(port);
		const power = (this.hub as PhidgetDevice)._device.hubPortProps?.[port].portPowered;
		if (power == undefined)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (power);
	}

	getPortMaxSpeed(port: number): number {
		this._assertAttached();

		// Only supported for local (USB) connections
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		if (!this._ch!.conn._isLocal)
			throw new PhidgetError(ErrorCode.UNSUPPORTED);

		if (port < 0 || port > (this.hubPortCount - 1))
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: 0 - " + (this.hubPortCount - 1) + ".");

		const portMaxSpeed = (this.hub as PhidgetDevice)._device.hubPortProps?.[port].portMaxSpeed;
		if (portMaxSpeed == undefined)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (portMaxSpeed);
	}
	getPortSupportsAutoSetSpeed(port: number): boolean {
		this._assertAttached();

		// Only supported for local (USB) connections
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		if (!this._ch!.conn._isLocal)
			throw new PhidgetError(ErrorCode.UNSUPPORTED);

		if (port < 0 || port > (this.hubPortCount - 1))
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: 0 - " + (this.hubPortCount - 1) + ".");

		const portSuppAutoSetSpeed = (this.hub as PhidgetDevice)._device.hubPortProps?.[port].portSuppAutoSetSpeed;
		if (portSuppAutoSetSpeed == undefined)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (portSuppAutoSetSpeed);
	}
	getPortSupportsSetSpeed(port: number): boolean {
		this._assertAttached();

		// Only supported for local (USB) connections
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		if (!this._ch!.conn._isLocal)
			throw new PhidgetError(ErrorCode.UNSUPPORTED);

		if (port < 0 || port > (this.hubPortCount - 1))
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: 0 - " + (this.hubPortCount - 1) + ".");

		const portSuppSetSpeed = (this.hub as PhidgetDevice)._device.hubPortProps?.[port].portSuppSetSpeed;
		if (portSuppSetSpeed == undefined)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (portSuppSetSpeed);
	}

	async setFirmwareUpgradeFlag(port: number, timeout: number): Promise<void> {
		this._assertAttached();
		if (port < 0 || port > (this.hubPortCount - 1))
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: 0 - " + (this.hubPortCount - 1) + ".");

		await super.setFirmwareUpgradeFlag(port, timeout);
	}
	async setPortMode(port: number, mode: HubPortMode): Promise<void> {
		this._assertAttached();
		if (port < 0 || port > (this.hubPortCount - 1))
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: 0 - " + (this.hubPortCount - 1) + ".");

		await super.setPortMode(port, mode);
	}
	async setPortPower(port: number, state: boolean): Promise<void> {
		this._assertAttached();
		if (port < 0 || port > (this.hubPortCount - 1))
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: 0 - " + (this.hubPortCount - 1) + ".");

		await super.setPortPower(port, state);
	}
	async setPortAutoSetSpeed(port: number, state: boolean): Promise<void> {
		this._assertAttached();
		if (port < 0 || port > (this.hubPortCount - 1))
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: 0 - " + (this.hubPortCount - 1) + ".");

		await super.setPortAutoSetSpeed(port, state);
	}
}

export { Hub };