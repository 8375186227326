/**
 * Encoder interface mode
 * @public
 */
export enum EncoderIOMode {
	/**
	 * No additional pull-up or pull-down resistors will be applied to the input lines.
	 * @public
	 */
	PUSH_PULL = 1,
	/**
	 * 2.2kΩ pull-down resistors will be applied to the input lines.
	 * @public
	 */
	LINE_DRIVER_2K2 = 2,
	/**
	 * 10kΩ pull-down resistors will be applied to the input lines.
	 * @public
	 */
	LINE_DRIVER_10K = 3,
	/**
	 * 2.2kΩ pull-up resistors will be applied to the input lines.
	 * @public
	 */
	OPEN_COLLECTOR_2K2 = 4,
	/**
	 * 10kΩ pull-up resistors will be applied to the input lines.
	 * @public
	 */
	OPEN_COLLECTOR_10K = 5,
}
/**
 * Error codes returned from all API calls via Exceptions.
 * @public
 */
export enum ErrorCode {
	/**
	 * Call succeeded.
	 * @public
	 */
	SUCCESS = 0,
	/**
	 * Not Permitted
	 * @public
	 */
	NOT_PERMITTED = 1,
	/**
	 * The specified entity does not exist. This is usually a result of Net or Log API calls.
	 * @public
	 */
	NO_SUCH_ENTITY = 2,
	/**
	 * Call has timed out. This can happen for a number of common reasons: Check that the Phidget you are trying to open is plugged in, and that the addressing parameters have been specified correctly. Check that the Phidget is not already open in another program, such as the Phidget Control Panel, or another program you are developing. If your Phidget has a plug or terminal block for external power, ensure it is plugged in and powered. If you are using remote Phidgets, ensure that your computer can access the remote Phidgets using the Phidget Control Panel. If you are using remote Phidgets, ensure you have enabled Server Discovery or added the server corresponding to the Phidget you are trying to open. If you are using Network Server Discovery, try extending the timeout to allow more time for the server to be discovered.
	 * @public
	 */
	TIMEOUT = 3,
	/**
	 * Keep Alive Failure
	 * @public
	 */
	KEEP_ALIVE = 58,
	/**
	 * The operation was interrupted; either from an error, or because the device was closed.
	 * @public
	 */
	INTERRUPTED = 4,
	/**
	 * IO Issue
	 * @public
	 */
	IO = 5,
	/**
	 * Memory Issue
	 * @public
	 */
	NO_MEMORY = 6,
	/**
	 * Access to the resource (file) is denied. This can happen when enabling logging.
	 * @public
	 */
	ACCESS = 7,
	/**
	 * Address Issue
	 * @public
	 */
	FAULT = 8,
	/**
	 * Specified resource is in use. This error code is not normally used.
	 * @public
	 */
	BUSY = 9,
	/**
	 * Object Exists
	 * @public
	 */
	EXISTS = 10,
	/**
	 * Object is not a directory
	 * @public
	 */
	IS_NOT_DIRECTORY = 11,
	/**
	 * Object is a directory
	 * @public
	 */
	IS_DIRECTORY = 12,
	/**
	 * Invalid or malformed command. This can be caused by sending a command to a device which is not supported in it's current configuration.
	 * @public
	 */
	INVALID = 13,
	/**
	 * Too many open files in system
	 * @public
	 */
	TOO_MANY_FILES_SYSTEM = 14,
	/**
	 * Too many open files
	 * @public
	 */
	TOO_MANY_FILES = 15,
	/**
	 * The provided buffer argument size is too small.
	 * @public
	 */
	NO_SPACE = 16,
	/**
	 * File too Big
	 * @public
	 */
	FILE_TOO_BIG = 17,
	/**
	 * Read Only Filesystem
	 * @public
	 */
	READ_ONLY_FILESYSTEM = 18,
	/**
	 * Read Only Object
	 * @public
	 */
	READ_ONLY = 19,
	/**
	 * This API call is not supported. For Class APIs this means that this API is not supported by this device. This can also mean the API is not supported on this OS, or OS configuration.
	 * @public
	 */
	UNSUPPORTED = 20,
	/**
	 * One or more of the parameters passed to the function is not accepted by the channel in its current configuration.
	 * @public
	 */
	INVALID_ARGUMENT = 21,
	/**
	 * Try again
	 * @public
	 */
	TRY_AGAIN = 22,
	/**
	 * Not Empty
	 * @public
	 */
	NOT_EMPTY = 26,
	/**
	 * Something unexpected has occured. Enable library logging and have a look at the log, or contact Phidgets support.
	 * @public
	 */
	UNEXPECTED = 28,
	/**
	 * Duplicated request. Can happen with some Net API calls, such as trying to add the same server twice.
	 * @public
	 */
	DUPLICATE = 27,
	/**
	 * Bad Credential
	 * @public
	 */
	BAD_PASSWORD = 37,
	/**
	 * Network Unavailable
	 * @public
	 */
	NETWORK_UNAVAILABLE = 45,
	/**
	 * Connection Refused
	 * @public
	 */
	CONNECTION_REFUSED = 35,
	/**
	 * Connection Reset
	 * @public
	 */
	CONNECTION_RESET = 46,
	/**
	 * No route to host
	 * @public
	 */
	HOST_UNREACHABLE = 48,
	/**
	 * No Such Device
	 * @public
	 */
	NO_SUCH_DEVICE = 40,
	/**
	 * A Phidget channel object of the wrong channel class was passed into this API call.
	 * @public
	 */
	WRONG_DEVICE = 50,
	/**
	 * Broken Pipe
	 * @public
	 */
	BROKEN_PIPE = 41,
	/**
	 * Name Resolution Failure
	 * @public
	 */
	NAME_RESOLUTION_FAILURE = 44,
	/**
	 * The value is unknown. This can happen right after attach, when the value has not yet been recieved from the Phidget. This can also happen if a device has not yet been configured / enabled. Some properties can only be read back after being set.
	 * @public
	 */
	UNKNOWN_VALUE = 51,
	/**
	 * This can happen for a number of common reasons. Be sure you are opening the channel before trying to use it. If you are opening the channel, the program may not be waiting for the channel to be attached. If possible use openWaitForAttachment. Otherwise, be sure to check the Attached property of the channel before trying to use it.
	 * @public
	 */
	NOT_ATTACHED = 52,
	/**
	 * Invalid or Unexpected Packet
	 * @public
	 */
	INVALID_PACKET = 53,
	/**
	 * Argument List Too Long
	 * @public
	 */
	TOO_BIG = 54,
	/**
	 * Bad Version
	 * @public
	 */
	BAD_VERSION = 55,
	/**
	 * Channel was closed. This can happen if a channel is closed while openWaitForAttachment is waiting.
	 * @public
	 */
	CLOSED = 56,
	/**
	 * Device is not configured enough for this API call. Have a look at the must-set properties for this device and make sure to configure them first.
	 * @public
	 */
	NOT_CONFIGURED = 57,
	/**
	 * End of File
	 * @public
	 */
	END_OF_FILE = 31,
	/**
	 * Failsafe Triggered on this channel. Close and Re-open the channel to resume operation.
	 * @public
	 */
	FAILSAFE = 59,
	/**
	 * The value has been measured to be higher than the valid range of the sensor.
	 * @public
	 */
	UNKNOWN_VALUE_HIGH = 60,
	/**
	 * The value has been measured to be lower than the valid range of the sensor.
	 * @public
	 */
	UNKNOWN_VALUE_LOW = 61,
	/**
	 * The power supply of your device is outside the acceptable range to allow operation.
	 * @public
	 */
	BAD_POWER = 62,
	/**
	 * Something has caused your device to decide it needs to be powered off and on to resume operation.
	 * @public
	 */
	POWER_CYCLE = 63,
	/**
	 * The hall sensor on your BLDC Motor Controller is Improperly Connected
	 * @public
	 */
	HALLSENSOR = 64,
	/**
	 * Current sensor offset outside acceptable bounds. Move the sensor aways from magnetic fields and try again.
	 * @public
	 */
	BADCURRENT = 65,
}
/**
 * The error code from an Error event
 * @public
 */
export enum ErrorEventCode {
	/**
	 * Client and Server protocol versions don't match. Ensure that both sides are using the same release of phidget22.
	 * @public
	 */
	BAD_VERSION = 1,
	/**
	 * Check that the Phidget is not already open in another program, such as the Phidget Control Panel, or another program you are developing.
	 * @public
	 */
	BUSY = 2,
	/**
	 * This could be a network communication issue, an authentication issue (if server password is enabled), or a Device access / hardware issue.
	 * @public
	 */
	NETWORK = 3,
	/**
	 * An error occured dispatching a command or event.
	 * @public
	 */
	DISPATCH = 4,
	/**
	 * A general failure occured - see description for details.
	 * @public
	 */
	FAILURE = 5,
	/**
	 * An error state has cleared.
	 * @public
	 */
	SUCCESS = 4096,
	/**
	 * A sampling overrun happened in firmware.
	 * @public
	 */
	OVERRUN = 4098,
	/**
	 * One or more packets were lost.
	 * @public
	 */
	PACKET_LOST = 4099,
	/**
	 * Variable has wrapped around.
	 * @public
	 */
	WRAP_AROUND = 4100,
	/**
	 * Over-temperature condition detected.
	 * @public
	 */
	OVER_TEMPERATURE = 4101,
	/**
	 * Over-current condition detected.
	 * @public
	 */
	OVER_CURRENT = 4102,
	/**
	 * Out of range condition detected.
	 * @public
	 */
	OUT_OF_RANGE = 4103,
	/**
	 * Power supply problem detected.
	 * @public
	 */
	BAD_POWER = 4104,
	/**
	 * Saturation condition detected.
	 * @public
	 */
	SATURATION = 4105,
	/**
	 * Over-voltage condition detected.
	 * @public
	 */
	OVER_VOLTAGE = 4107,
	/**
	 * Failsafe condition detected.
	 * @public
	 */
	FAILSAFE_CONDITION = 4108,
	/**
	 * Voltage error detected.
	 * @public
	 */
	VOLTAGE_ERROR = 4109,
	/**
	 * Energy dump condition detected.
	 * @public
	 */
	ENERGY_DUMP_CONDITION = 4110,
	/**
	 * Motor stall detected.
	 * @public
	 */
	MOTOR_STALL_CONDITION = 4111,
	/**
	 * Invalid state detected.
	 * @public
	 */
	INVALID_STATE_CONDITION = 4112,
	/**
	 * Bad connection detected.
	 * @public
	 */
	BAD_CONNECTION_CONDITION = 4113,
	/**
	 * Measurement is above the valid range.
	 * @public
	 */
	OUT_OF_RANGE_HIGH_CONDITION = 4114,
	/**
	 * Measurement is below the valid range.
	 * @public
	 */
	OUT_OF_RANGE_LOW_CONDITION = 4115,
	/**
	 * Fault condition detected.
	 * @public
	 */
	FAULT = 4116,
	/**
	 * External stop condition detected.
	 * @public
	 */
	ESTOP = 4117,
}
/**
 * Phidget device ID
 * @public
 */
export enum DeviceID {
	/**
	 * Unknown device
	 * @internal
	 */
	NONE = 0,
	/**
	 * Unknown device
	 * @public
	 */
	UNKNOWN = 125,
	/**
	 * Hub Port in Digital Input mode
	 * @public
	 */
	DIGITAL_INPUT_PORT = 95,
	/**
	 * Hub Port in Digital Output mode
	 * @public
	 */
	DIGITAL_OUTPUT_PORT = 96,
	/**
	 * Hub Port in Voltage Input mode
	 * @public
	 */
	VOLTAGE_INPUT_PORT = 97,
	/**
	 * Hub Port in Voltage Ratio Input mode
	 * @public
	 */
	VOLTAGE_RATIO_INPUT_PORT = 98,
	/**
	 * Dictionary
	 * @public
	 */
	PN_DICTIONARY = 111,
	/**
	 * PhidgetServo 1-Motor (1000)
	 * @public
	 */
	PN_1000 = 2,
	/**
	 * PhidgetServo 4-Motor (1001)
	 * @public
	 */
	PN_1001 = 3,
	/**
	 * PhidgetAnalog 4-Output (1002)
	 * @public
	 */
	PN_1002 = 4,
	/**
	 * PhidgetAccelerometer 2-Axis (1008)
	 * @public
	 */
	PN_1008 = 5,
	/**
	 * PhidgetInterfaceKit 8/8/8 (1010, 1013, 1018, 1019)
	 * @public
	 */
	PN_1010_1013_1018_1019 = 6,
	/**
	 * PhidgetInterfaceKit 2/2/2 (1011)
	 * @public
	 */
	PN_1011 = 7,
	/**
	 * PhidgetInterfaceKit 0/16/16 (1012)
	 * @public
	 */
	PN_1012 = 8,
	/**
	 * PhidgetInterfaceKit 0/0/4 (1014)
	 * @public
	 */
	PN_1014 = 9,
	/**
	 * PhidgetLinearTouch (1015)
	 * @public
	 */
	PN_1015 = 10,
	/**
	 * PhidgetCircularTouch (1016)
	 * @public
	 */
	PN_1016 = 11,
	/**
	 * PhidgetInterfaceKit 0/0/8 (1017)
	 * @public
	 */
	PN_1017 = 12,
	/**
	 * PhidgetRFID (1023)
	 * @public
	 */
	PN_1023 = 13,
	/**
	 * PhidgetRFID Read-Write (1024)
	 * @public
	 */
	PN_1024 = 14,
	/**
	 * PhidgetLED-64 (1030)
	 * @public
	 */
	PN_1030 = 15,
	/**
	 * PhidgetLED-64 Advanced (1031)
	 * @public
	 */
	PN_1031 = 16,
	/**
	 * PhidgetLED-64 Advanced (1032)
	 * @public
	 */
	PN_1032 = 17,
	/**
	 * PhidgetGPS (1040)
	 * @public
	 */
	PN_1040 = 18,
	/**
	 * PhidgetSpatial 0/0/3 Basic (1041)
	 * @public
	 */
	PN_1041 = 19,
	/**
	 * PhidgetSpatial 3/3/3 Basic (1042)
	 * @public
	 */
	PN_1042 = 20,
	/**
	 * PhidgetSpatial Precision 0/0/3 High Resolution (1043)
	 * @public
	 */
	PN_1043 = 21,
	/**
	 * PhidgetSpatial Precision 3/3/3 High Resolution (1044)
	 * @public
	 */
	PN_1044 = 22,
	/**
	 * PhidgetTemperatureSensor IR (1045)
	 * @public
	 */
	PN_1045 = 23,
	/**
	 * PhidgetBridge 4-Input (1046)
	 * @public
	 */
	PN_1046 = 24,
	/**
	 * PhidgetEncoder HighSpeed 4-Input (1047)
	 * @public
	 */
	PN_1047 = 25,
	/**
	 * PhidgetTemperatureSensor 4-input (1048)
	 * @public
	 */
	PN_1048 = 26,
	/**
	 * PhidgetSpatial 0/0/3 (1049)
	 * @public
	 */
	PN_1049 = 27,
	/**
	 * PhidgetTemperatureSensor 1-Input (1051)
	 * @public
	 */
	PN_1051 = 28,
	/**
	 * PhidgetEncoder Mechanical (1052)
	 * @public
	 */
	PN_1052 = 29,
	/**
	 * PhidgetAccelerometer 2-Axis (1053)
	 * @public
	 */
	PN_1053 = 30,
	/**
	 * PhidgetFrequencyCounter (1054)
	 * @public
	 */
	PN_1054 = 31,
	/**
	 * PhidgetIR (1055)
	 * @public
	 */
	PN_1055 = 32,
	/**
	 * PhidgetSpatial 3/3/3 (1056)
	 * @public
	 */
	PN_1056 = 33,
	/**
	 * PhidgetEncoder HighSpeed (1057)
	 * @public
	 */
	PN_1057 = 34,
	/**
	 * PhidgetPHSensor (1058)
	 * @public
	 */
	PN_1058 = 35,
	/**
	 * PhidgetAccelerometer 3-Axis (1059)
	 * @public
	 */
	PN_1059 = 36,
	/**
	 * PhidgetMotorControl LV (1060)
	 * @public
	 */
	PN_1060 = 37,
	/**
	 * PhidgetAdvancedServo 8-Motor (1061)
	 * @public
	 */
	PN_1061 = 38,
	/**
	 * PhidgetStepper Unipolar 4-Motor (1062)
	 * @public
	 */
	PN_1062 = 39,
	/**
	 * PhidgetStepper Bipolar 1-Motor (1063)
	 * @public
	 */
	PN_1063 = 40,
	/**
	 * PhidgetMotorControl HC (1064)
	 * @public
	 */
	PN_1064 = 41,
	/**
	 * PhidgetMotorControl 1-Motor (1065)
	 * @public
	 */
	PN_1065 = 42,
	/**
	 * PhidgetAdvancedServo 1-Motor (1066)
	 * @public
	 */
	PN_1066 = 43,
	/**
	 * PhidgetStepper Bipolar HC (1067)
	 * @public
	 */
	PN_1067 = 44,
	/**
	 * PhidgetTextLCD 20x2 with PhidgetInterfaceKit 8/8/8 (1202, 1203)
	 * @public
	 */
	PN_1202_1203 = 45,
	/**
	 * PhidgetTextLCD Adapter (1204)
	 * @public
	 */
	PN_1204 = 46,
	/**
	 * PhidgetTextLCD 20x2 (1215, 1216, 1217, 1218)
	 * @public
	 */
	PN_1215__1218 = 47,
	/**
	 * PhidgetTextLCD 20x2 with PhidgetInterfaceKit 0/8/8 (1219, 1220, 1221, 1222)
	 * @public
	 */
	PN_1219__1222 = 48,
	/**
	 * pH Adapter
	 * @public
	 */
	PN_ADP1000 = 49,
	/**
	 * Analog Input Module x8
	 * @public
	 */
	PN_DAQ1000 = 51,
	/**
	 * Digital Input 4
	 * @public
	 */
	PN_DAQ1200 = 52,
	/**
	 * Digital Input 4 Isolated
	 * @public
	 */
	PN_DAQ1300 = 53,
	/**
	 * Digital Input 16
	 * @public
	 */
	PN_DAQ1301 = 54,
	/**
	 * Versatile Input
	 * @public
	 */
	PN_DAQ1400 = 55,
	/**
	 * Bridge
	 * @public
	 */
	PN_DAQ1500 = 56,
	/**
	 * DC Motor Controller with PID
	 * @public
	 */
	PN_DCC1000 = 57,
	/**
	 * Small DC Motor Controller
	 * @public
	 */
	PN_DCC1001 = 110,
	/**
	 * 4A Small DC Motor Controller
	 * @public
	 */
	PN_DCC1002 = 117,
	/**
	 * 2 Channel DC Motor Controller
	 * @public
	 */
	PN_DCC1003 = 120,
	/**
	 * BLDC Motor Controller
	 * @public
	 */
	PN_DCC1100 = 108,
	/**
	 * 200mm Distance Sensor
	 * @public
	 */
	PN_DST1000 = 58,
	/**
	 * 650mm distance sensor
	 * @public
	 */
	PN_DST1001 = 121,
	/**
	 * 1200mm distance sensor
	 * @public
	 */
	PN_DST1002 = 126,
	/**
	 * Sonar Distance Sensor
	 * @public
	 */
	PN_DST1200 = 59,
	/**
	 * Encoder
	 * @public
	 */
	PN_ENC1000 = 60,
	/**
	 * Encoder
	 * @public
	 */
	PN_ENC1001 = 155,
	/**
	 * Phidget SPI device under firmware upgrade
	 * @internal
	 */
	FIRMWARE_UPGRADE_SPI = 104,
	/**
	 * VINT Device in firmware upgrade mode, STM32F0 Proc.
	 * @internal
	 */
	FIRMWARE_UPGRADE_STM32F0 = 102,
	/**
	 * VINT Device in firmware upgrade mode, STM32F3 Proc.
	 * @internal
	 */
	FIRMWARE_UPGRADE_STM32F3 = 145,
	/**
	 * VINT Device in firmware upgrade mode, STM32G0 Proc.
	 * @internal
	 */
	FIRMWARE_UPGRADE_STM32G0 = 143,
	/**
	 * VINT Device in firmware upgrade mode, STM8S Proc.
	 * @internal
	 */
	FIRMWARE_UPGRADE_STM8S = 103,
	/**
	 * USB device in firmware upgrade mode
	 * @internal
	 */
	FIRMWARE_UPGRADE_USB = 101,
	/**
	 * Capacitive Touch Sensor
	 * @public
	 */
	PN_HIN1000 = 61,
	/**
	 * Capacitive Scroll
	 * @public
	 */
	PN_HIN1001 = 62,
	/**
	 * Joystick
	 * @public
	 */
	PN_HIN1100 = 63,
	/**
	 * Dial Encoder
	 * @public
	 */
	PN_HIN1101 = 109,
	/**
	 * Phidget USB VINT Hub with 6 ports
	 * @public
	 */
	PN_HUB0000 = 64,
	/**
	 * Phidget USB VINT Hub with 6 ports
	 * @public
	 */
	PN_HUB0001 = 142,
	/**
	 * Phidget USB VINT Hub with 6 ports
	 * @public
	 */
	PN_HUB0002 = 147,
	/**
	 * Phidget SPI VINT Hub with 6 ports
	 * @public
	 */
	PN_HUB0004 = 67,
	/**
	 * Phidget USB VINT Hub with 1 port
	 * @public
	 */
	PN_HUB0007 = 148,
	/**
	 * Phidget Network Hub with 6 ports
	 * @public
	 */
	PN_HUB5000 = 123,
	/**
	 * Humidity Sensor
	 * @public
	 */
	PN_HUM1000 = 69,
	/**
	 * Humidity Sensor
	 * @public
	 */
	PN_HUM1001 = 127,
	/**
	 * VINT Moisture Sensor
	 * @public
	 */
	PN_HUM1100 = 136,
	/**
	 * PhidgetInterfaceKit 4/8/8
	 * @internal
	 */
	PN_INTERFACE_KIT488 = 1,
	/**
	 * LCD
	 * @public
	 */
	PN_LCD1100 = 70,
	/**
	 * LED Driver 32
	 * @public
	 */
	PN_LED1000 = 71,
	/**
	 * Light Sensor
	 * @public
	 */
	PN_LUX1000 = 72,
	/**
	 * Phidget Accelerometer
	 * @public
	 */
	PN_MOT0100 = 146,
	/**
	 * PhidgetSpatial Precision 3/3/3
	 * @public
	 */
	PN_MOT0109 = 140,
	/**
	 * PhidgetSpatial Precision 3/3/3
	 * @public
	 */
	PN_MOT0110 = 141,
	/**
	 * Accelerometer 0/0/3
	 * @public
	 */
	PN_MOT1100 = 73,
	/**
	 * Spatial 3/3/3
	 * @public
	 */
	PN_MOT1101 = 74,
	/**
	 * VINT Spatial Phidget AHRS
	 * @public
	 */
	PN_MOT1102 = 137,
	/**
	 * Analog Output 0-5V
	 * @public
	 */
	PN_OUT1000 = 75,
	/**
	 * Analog Output (+/-)10V
	 * @public
	 */
	PN_OUT1001 = 76,
	/**
	 * Analog Output (+/-)10V - 16 bit
	 * @public
	 */
	PN_OUT1002 = 77,
	/**
	 * Digital Output 4
	 * @public
	 */
	PN_OUT1100 = 78,
	/**
	 * Barometer
	 * @public
	 */
	PN_PRE1000 = 79,
	/**
	 * PhidgetAdvancedServo 8-Motor
	 * @public
	 */
	PN_RCC0004 = 124,
	/**
	 * 8-Servo Controller
	 * @public
	 */
	PN_RCC1000 = 80,
	/**
	 * Power Relay 4
	 * @public
	 */
	PN_REL1000 = 81,
	/**
	 * Digital Output 4 Isolated
	 * @public
	 */
	PN_REL1100 = 82,
	/**
	 * Digital Output 16 Isolated
	 * @public
	 */
	PN_REL1101 = 83,
	/**
	 * Power Supply Protector
	 * @public
	 */
	PN_SAF1000 = 84,
	/**
	 * Sound Pressure Level Sensor
	 * @public
	 */
	PN_SND1000 = 85,
	/**
	 * Bipolar Stepper Motor Controller
	 * @public
	 */
	PN_STC1000 = 86,
	/**
	 * Bipolar Stepper Motor SmallController
	 * @public
	 */
	PN_STC1001 = 115,
	/**
	 * 8A Bipolar Stepper Motor Controller
	 * @public
	 */
	PN_STC1002 = 118,
	/**
	 * 4A Bipolar Stepper Motor SmallController
	 * @public
	 */
	PN_STC1003 = 119,
	/**
	 * 4A Bipolar Stepper Motor SmallController
	 * @public
	 */
	PN_STC1005 = 149,
	/**
	 * Integrated Temperature Sensor
	 * @public
	 */
	PN_TMP1000 = 87,
	/**
	 * Thermocouple 1
	 * @public
	 */
	PN_TMP1100 = 88,
	/**
	 * Thermocouple 4
	 * @public
	 */
	PN_TMP1101 = 89,
	/**
	 * RTD
	 * @public
	 */
	PN_TMP1200 = 90,
	/**
	 * Voltage Sensor High Precision
	 * @public
	 */
	PN_VCP1000 = 92,
	/**
	 * Voltage Sensor Large
	 * @public
	 */
	PN_VCP1001 = 93,
	/**
	 * Voltage Sensor Small
	 * @public
	 */
	PN_VCP1002 = 94,
	/**
	 * 30A Current Sensor
	 * @public
	 */
	PN_VCP1100 = 105,
}
/**
 * Phidget logging level
 * @public
 */
export enum LogLevel {
	/**
	 * Critical
	 * @public
	 */
	CRITICAL = 1,
	/**
	 * Error
	 * @public
	 */
	ERROR = 2,
	/**
	 * Warning
	 * @public
	 */
	WARNING = 3,
	/**
	 * Info
	 * @public
	 */
	INFO = 4,
	/**
	 * Debug
	 * @public
	 */
	DEBUG = 5,
	/**
	 * Verbose
	 * @public
	 */
	VERBOSE = 6,
}
/**
 * Phidget device class
 * @public
 */
export enum DeviceClass {
	/**
	 * Any device
	 * @internal
	 */
	NONE = 0,
	/**
	 * PhidgetAccelerometer device
	 * @public
	 */
	ACCELEROMETER = 1,
	/**
	 * PhidgetAdvancedServo device
	 * @public
	 */
	ADVANCED_SERVO = 2,
	/**
	 * PhidgetAnalog device
	 * @public
	 */
	ANALOG = 3,
	/**
	 * PhidgetBridge device
	 * @public
	 */
	BRIDGE = 4,
	/**
	 * PhidgetDataAdapter device
	 * @internal
	 */
	DATA_ADAPTER = 25,
	/**
	 * Dictionary device
	 * @public
	 */
	DICTIONARY = 24,
	/**
	 * PhidgetEncoder device
	 * @public
	 */
	ENCODER = 5,
	/**
	 * Phidget device in Firmware Upgrade mode
	 * @internal
	 */
	FIRMWARE_UPGRADE = 23,
	/**
	 * PhidgetFrequencyCounter device
	 * @public
	 */
	FREQUENCY_COUNTER = 6,
	/**
	 * Generic device
	 * @internal
	 */
	GENERIC = 22,
	/**
	 * PhidgetGPS device
	 * @public
	 */
	GPS = 7,
	/**
	 * Phidget VINT Hub device
	 * @public
	 */
	HUB = 8,
	/**
	 * PhidgetInterfaceKit device
	 * @public
	 */
	INTERFACE_KIT = 9,
	/**
	 * PhidgetIR device
	 * @public
	 */
	IR = 10,
	/**
	 * PhidgetLED device
	 * @public
	 */
	LED = 11,
	/**
	 * Phidget Mesh Dongle
	 * @internal
	 */
	MESH_DONGLE = 12,
	/**
	 * PhidgetMotorControl device
	 * @public
	 */
	MOTOR_CONTROL = 13,
	/**
	 * PhidgetPHSensor device
	 * @public
	 */
	PH_SENSOR = 14,
	/**
	 * PhidgetRFID device
	 * @public
	 */
	RFID = 15,
	/**
	 * PhidgetServo device
	 * @public
	 */
	SERVO = 16,
	/**
	 * PhidgetSpatial device
	 * @public
	 */
	SPATIAL = 17,
	/**
	 * PhidgetStepper device
	 * @public
	 */
	STEPPER = 18,
	/**
	 * PhidgetTemperatureSensor device
	 * @public
	 */
	TEMPERATURE_SENSOR = 19,
	/**
	 * PhidgetTextLCD device
	 * @public
	 */
	TEXT_LCD = 20,
	/**
	 * Phidget VINT device
	 * @public
	 */
	VINT = 21,
}
/**
 * Phidget channel class
 * @public
 */
export enum ChannelClass {
	/**
	 * Any channel
	 * @internal
	 */
	NONE = 0,
	/**
	 * Accelerometer channel
	 * @public
	 */
	ACCELEROMETER = 1,
	/**
	 * BLDC motor channel
	 * @public
	 */
	BLDC_MOTOR = 35,
	/**
	 * Capacitive Touch channel
	 * @public
	 */
	CAPACITIVE_TOUCH = 14,
	/**
	 * Current input channel
	 * @public
	 */
	CURRENT_INPUT = 2,
	/**
	 * Current output channel
	 * @internal
	 */
	CURRENT_OUTPUT = 38,
	/**
	 * Data adapter channel
	 * @internal
	 */
	DATA_ADAPTER = 3,
	/**
	 * DC motor channel
	 * @public
	 */
	DC_MOTOR = 4,
	/**
	 * Dictionary
	 * @public
	 */
	DICTIONARY = 36,
	/**
	 * Digital input channel
	 * @public
	 */
	DIGITAL_INPUT = 5,
	/**
	 * Digital output channel
	 * @public
	 */
	DIGITAL_OUTPUT = 6,
	/**
	 * Distance sensor channel
	 * @public
	 */
	DISTANCE_SENSOR = 7,
	/**
	 * Encoder channel
	 * @public
	 */
	ENCODER = 8,
	/**
	 * Firmware upgrade channel
	 * @internal
	 */
	FIRMWARE_UPGRADE = 32,
	/**
	 * Frequency counter channel
	 * @public
	 */
	FREQUENCY_COUNTER = 9,
	/**
	 * Generic channel
	 * @internal
	 */
	GENERIC = 33,
	/**
	 * GPS channel
	 * @public
	 */
	GPS = 10,
	/**
	 * Gyroscope channel
	 * @public
	 */
	GYROSCOPE = 12,
	/**
	 * VINT Hub channel
	 * @public
	 */
	HUB = 13,
	/**
	 * Humidity sensor channel
	 * @public
	 */
	HUMIDITY_SENSOR = 15,
	/**
	 * IR channel
	 * @public
	 */
	IR = 16,
	/**
	 * LCD channel
	 * @public
	 */
	LCD = 11,
	/**
	 * Light sensor channel
	 * @public
	 */
	LIGHT_SENSOR = 17,
	/**
	 * Magnetometer channel
	 * @public
	 */
	MAGNETOMETER = 18,
	/**
	 * Mesh dongle channel
	 * @internal
	 */
	MESH_DONGLE = 19,
	/**
	 * Motor position control channel.
	 * @public
	 */
	MOTOR_POSITION_CONTROLLER = 34,
	/**
	 * Motor velocity control channel.
	 * @internal
	 */
	MOTOR_VELOCITY_CONTROLLER = 39,
	/**
	 * pH sensor channel
	 * @public
	 */
	PH_SENSOR = 37,
	/**
	 * Power guard channel
	 * @public
	 */
	POWER_GUARD = 20,
	/**
	 * Pressure sensor channel
	 * @public
	 */
	PRESSURE_SENSOR = 21,
	/**
	 * RC Servo channel
	 * @public
	 */
	RC_SERVO = 22,
	/**
	 * Resistance input channel
	 * @public
	 */
	RESISTANCE_INPUT = 23,
	/**
	 * RFID channel
	 * @public
	 */
	RFID = 24,
	/**
	 * Sound sensor channel
	 * @public
	 */
	SOUND_SENSOR = 25,
	/**
	 * Spatial channel
	 * @public
	 */
	SPATIAL = 26,
	/**
	 * Stepper channel
	 * @public
	 */
	STEPPER = 27,
	/**
	 * Temperature sensor channel
	 * @public
	 */
	TEMPERATURE_SENSOR = 28,
	/**
	 * Voltage input channel
	 * @public
	 */
	VOLTAGE_INPUT = 29,
	/**
	 * Voltage output channel
	 * @public
	 */
	VOLTAGE_OUTPUT = 30,
	/**
	 * Voltage ratio input channel
	 * @public
	 */
	VOLTAGE_RATIO_INPUT = 31,
}
/**
 * Phidget channel sub class
 * @public
 */
export enum ChannelSubclass {
	/**
	 * No subclass
	 * @public
	 */
	NONE = 1,
	/**
	 * Digital output duty cycle
	 * @public
	 */
	DIGITAL_OUTPUT_DUTY_CYCLE = 16,
	/**
	 * Digital output frequency
	 * @public
	 */
	DIGITAL_OUTPUT_FREQUENCY = 18,
	/**
	 * Digital output LED driver
	 * @public
	 */
	DIGITAL_OUTPUT_LEDDRIVER = 17,
	/**
	 * Encoder IO mode settable
	 * @public
	 */
	ENCODER_MODE_SETTABLE = 96,
	/**
	 * Graphic LCD
	 * @public
	 */
	LCD_GRAPHIC = 80,
	/**
	 * Text LCD
	 * @public
	 */
	LCD_TEXT = 81,
	/**
	 * Spatial AHRS/IMU
	 * @public
	 */
	SPATIAL_AHRS = 112,
	/**
	 * Temperature sensor RTD
	 * @public
	 */
	TEMPERATURE_SENSOR_RTD = 32,
	/**
	 * Temperature sensor thermocouple
	 * @public
	 */
	TEMPERATURE_SENSOR_THERMOCOUPLE = 33,
	/**
	 * Voltage sensor port
	 * @public
	 */
	VOLTAGE_INPUT_SENSOR_PORT = 48,
	/**
	 * Voltage ratio bridge input
	 * @public
	 */
	VOLTAGE_RATIO_INPUT_BRIDGE = 65,
	/**
	 * Voltage ratio sensor port
	 * @public
	 */
	VOLTAGE_RATIO_INPUT_SENSOR_PORT = 64,
}
/**
 * Phidget mesh mode
 * @public
 */
export enum MeshMode {
	/**
	 * Router mode
	 * @public
	 */
	ROUTER = 1,
	/**
	 * Sleepy end device mode
	 * @public
	 */
	SLEEPY_END_DEVICE = 2,
}
/**
 * The voltage level being provided to the sensor
 * @public
 */
export enum PowerSupply {
	/**
	 * Switch the sensor power supply off
	 * @public
	 */
	OFF = 1,
	/**
	 * The sensor is provided with 12 volts
	 * @public
	 */
	VOLTS_12 = 2,
	/**
	 * The sensor is provided with 24 volts
	 * @public
	 */
	VOLTS_24 = 3,
}
/**
 * RTD wiring configuration
 * @public
 */
export enum RTDWireSetup {
	/**
	 * Configures the device to make resistance calculations based on a 2-wire RTD setup.
	 * @public
	 */
	WIRES_2 = 1,
	/**
	 * Configures the device to make resistance calculations based on a 3-wire RTD setup.
	 * @public
	 */
	WIRES_3 = 2,
	/**
	 * Configures the device to make resistance calculations based on a 4-wire RTD setup.
	 * @public
	 */
	WIRES_4 = 3,
}
/**
 * The selected polarity mode for the digital input
 * @public
 */
export enum InputMode {
	/**
	 * For interfacing NPN digital sensors
	 * @public
	 */
	NPN = 1,
	/**
	 * For interfacing PNP digital sensors
	 * @public
	 */
	PNP = 2,
}
/**
 * The operating condition of the fan. Choose between on, off, or automatic (based on temperature).
 * @public
 */
export enum FanMode {
	/**
	 * Turns the fan off.
	 * @public
	 */
	OFF = 1,
	/**
	 * Turns the fan on.
	 * @public
	 */
	ON = 2,
	/**
	 * The fan will be automatically controlled based on temperature.
	 * @public
	 */
	AUTO = 3,
}
/**
 * The drive type selection for the motor
 * @public
 */
export enum DecelerationType {
	/**
	 * Configures the motor for coasting deceleration
	 * @public
	 */
	COAST = 1,
	/**
	 * Configures the motor for forced deceleration
	 * @public
	 */
	FORCED = 2,
}
/**
 * The position type selection
 * @public
 */
export enum PositionType {
	/**
	 * Configures the controller to use the encoder as a position source
	 * @public
	 */
	ENCODER = 1,
	/**
	 * Configures the controller to use the hall-effect sensor as a position source
	 * @public
	 */
	HALL_SENSOR = 2,
}
/**
 * Controls how data from primary and backup spatial sensing chips are used.
 * @public
 */
export enum SpatialPrecision {
	/**
	 * High precision sensor is used when possible, fallback to low precision sensor.
	 * @public
	 */
	HYBRID = 0,
	/**
	 * High precision sensor is always used.
	 * @public
	 */
	HIGH = 1,
	/**
	 * Low precision sensor is always used.
	 * @public
	 */
	LOW = 2,
}
/**
 * Analog sensor units. These correspond to the types of quantities that can be measured by Phidget analog sensors.
 * @public
 */
export enum Unit {
	/**
	 * Unitless
	 * @public
	 */
	NONE = 0,
	/**
	 * Boolean
	 * @public
	 */
	BOOLEAN = 1,
	/**
	 * Percent
	 * @public
	 */
	PERCENT = 2,
	/**
	 * Decibel
	 * @public
	 */
	DECIBEL = 3,
	/**
	 * Millimeter
	 * @public
	 */
	MILLIMETER = 4,
	/**
	 * Centimeter
	 * @public
	 */
	CENTIMETER = 5,
	/**
	 * Meter
	 * @public
	 */
	METER = 6,
	/**
	 * Gram
	 * @public
	 */
	GRAM = 7,
	/**
	 * Kilogram
	 * @public
	 */
	KILOGRAM = 8,
	/**
	 * Milliampere
	 * @public
	 */
	MILLIAMPERE = 9,
	/**
	 * Ampere
	 * @public
	 */
	AMPERE = 10,
	/**
	 * Kilopascal
	 * @public
	 */
	KILOPASCAL = 11,
	/**
	 * Volt
	 * @public
	 */
	VOLT = 12,
	/**
	 * Degree Celcius
	 * @public
	 */
	DEGREE_CELCIUS = 13,
	/**
	 * Lux
	 * @public
	 */
	LUX = 14,
	/**
	 * Gauss
	 * @public
	 */
	GAUSS = 15,
	/**
	 * pH
	 * @public
	 */
	PH = 16,
	/**
	 * Watt
	 * @public
	 */
	WATT = 17,
}
/**
 * Bridge gain amplification setting. Higher gain results in better resolution, but narrower voltage range.
 * @public
 */
export enum BridgeGain {
	/**
	 * 1x Amplificaion
	 * @public
	 */
	GAIN_1X = 1,
	/**
	 * 2x Amplification
	 * @public
	 */
	GAIN_2X = 2,
	/**
	 * 4x Amplification
	 * @public
	 */
	GAIN_4X = 3,
	/**
	 * 8x Amplification
	 * @public
	 */
	GAIN_8X = 4,
	/**
	 * 16x Amplification
	 * @public
	 */
	GAIN_16X = 5,
	/**
	 * 32x Amplification
	 * @public
	 */
	GAIN_32X = 6,
	/**
	 * 64x Amplification
	 * @public
	 */
	GAIN_64X = 7,
	/**
	 * 128x Amplification
	 * @public
	 */
	GAIN_128X = 8,
}
/**
 * The type of sensor attached to the voltage ratio input
 * @public
 */
export enum VoltageRatioSensorType {
	/**
	 * Default. Configures the channel to be a generic ratiometric sensor. Unit is volts/volt.
	 * @public
	 */
	VOLTAGE_RATIO = 0,
	/**
	 * 1101 - IR Distance Adapter, with Sharp Distance Sensor 2D120X (4-30cm)
	 * @public
	 */
	PN_1101_SHARP2D120X = 11011,
	/**
	 * 1101 - IR Distance Adapter, with Sharp Distance Sensor 2Y0A21 (10-80cm)
	 * @public
	 */
	PN_1101_SHARP2Y0A21 = 11012,
	/**
	 * 1101 - IR Distance Adapter, with Sharp Distance Sensor 2Y0A02 (20-150cm)
	 * @public
	 */
	PN_1101_SHARP2Y0A02 = 11013,
	/**
	 * 1102 - IR Reflective Sensor 5mm
	 * @public
	 */
	PN_1102 = 11020,
	/**
	 * 1103 - IR Reflective Sensor 10cm
	 * @public
	 */
	PN_1103 = 11030,
	/**
	 * 1104 - Vibration Sensor
	 * @public
	 */
	PN_1104 = 11040,
	/**
	 * 1105 - Light Sensor
	 * @public
	 */
	PN_1105 = 11050,
	/**
	 * 1106 - Force Sensor
	 * @public
	 */
	PN_1106 = 11060,
	/**
	 * 1107 - Humidity Sensor
	 * @public
	 */
	PN_1107 = 11070,
	/**
	 * 1108 - Magnetic Sensor
	 * @public
	 */
	PN_1108 = 11080,
	/**
	 * 1109 - Rotation Sensor
	 * @public
	 */
	PN_1109 = 11090,
	/**
	 * 1110 - Touch Sensor
	 * @public
	 */
	PN_1110 = 11100,
	/**
	 * 1111 - Motion Sensor
	 * @public
	 */
	PN_1111 = 11110,
	/**
	 * 1112 - Slider 60
	 * @public
	 */
	PN_1112 = 11120,
	/**
	 * 1113 - Mini Joy Stick Sensor
	 * @public
	 */
	PN_1113 = 11130,
	/**
	 * 1115 - Pressure Sensor
	 * @public
	 */
	PN_1115 = 11150,
	/**
	 * 1116 - Multi-turn Rotation Sensor
	 * @public
	 */
	PN_1116 = 11160,
	/**
	 * 1118 - 50Amp Current Sensor AC
	 * @public
	 */
	PN_1118_AC = 11181,
	/**
	 * 1118 - 50Amp Current Sensor DC
	 * @public
	 */
	PN_1118_DC = 11182,
	/**
	 * 1119 - 20Amp Current Sensor AC
	 * @public
	 */
	PN_1119_AC = 11191,
	/**
	 * 1119 - 20Amp Current Sensor DC
	 * @public
	 */
	PN_1119_DC = 11192,
	/**
	 * 1120 - FlexiForce Adapter
	 * @public
	 */
	PN_1120 = 11200,
	/**
	 * 1121 - Voltage Divider
	 * @public
	 */
	PN_1121 = 11210,
	/**
	 * 1122 - 30 Amp Current Sensor AC
	 * @public
	 */
	PN_1122_AC = 11221,
	/**
	 * 1122 - 30 Amp Current Sensor DC
	 * @public
	 */
	PN_1122_DC = 11222,
	/**
	 * 1124 - Precision Temperature Sensor
	 * @public
	 */
	PN_1124 = 11240,
	/**
	 * 1125 - Humidity Sensor
	 * @public
	 */
	PN_1125_HUMIDITY = 11251,
	/**
	 * 1125 - Temperature Sensor
	 * @public
	 */
	PN_1125_TEMPERATURE = 11252,
	/**
	 * 1126 - Differential Air Pressure Sensor +- 25kPa
	 * @public
	 */
	PN_1126 = 11260,
	/**
	 * 1128 - MaxBotix EZ-1 Sonar Sensor
	 * @public
	 */
	PN_1128 = 11280,
	/**
	 * 1129 - Touch Sensor
	 * @public
	 */
	PN_1129 = 11290,
	/**
	 * 1131 - Thin Force Sensor
	 * @public
	 */
	PN_1131 = 11310,
	/**
	 * 1134 - Switchable Voltage Divider
	 * @public
	 */
	PN_1134 = 11340,
	/**
	 * 1136 - Differential Air Pressure Sensor +-2 kPa
	 * @public
	 */
	PN_1136 = 11360,
	/**
	 * 1137 - Differential Air Pressure Sensor +-7 kPa
	 * @public
	 */
	PN_1137 = 11370,
	/**
	 * 1138 - Differential Air Pressure Sensor 50 kPa
	 * @public
	 */
	PN_1138 = 11380,
	/**
	 * 1139 - Differential Air Pressure Sensor 100 kPa
	 * @public
	 */
	PN_1139 = 11390,
	/**
	 * 1140 - Absolute Air Pressure Sensor 20-400 kPa
	 * @public
	 */
	PN_1140 = 11400,
	/**
	 * 1141 - Absolute Air Pressure Sensor 15-115 kPa
	 * @public
	 */
	PN_1141 = 11410,
	/**
	 * 1146 - IR Reflective Sensor 1-4mm
	 * @public
	 */
	PN_1146 = 11460,
	/**
	 * 3120 - Compression Load Cell (0-4.5 kg)
	 * @public
	 */
	PN_3120 = 31200,
	/**
	 * 3121 - Compression Load Cell (0-11.3 kg)
	 * @public
	 */
	PN_3121 = 31210,
	/**
	 * 3122 - Compression Load Cell (0-22.7 kg)
	 * @public
	 */
	PN_3122 = 31220,
	/**
	 * 3123 - Compression Load Cell (0-45.3 kg)
	 * @public
	 */
	PN_3123 = 31230,
	/**
	 * 3130 - Relative Humidity Sensor
	 * @public
	 */
	PN_3130 = 31300,
	/**
	 * 3520 - Sharp Distance Sensor (4-30cm)
	 * @public
	 */
	PN_3520 = 35200,
	/**
	 * 3521 - Sharp Distance Sensor (10-80cm)
	 * @public
	 */
	PN_3521 = 35210,
	/**
	 * 3522 - Sharp Distance Sensor (20-150cm)
	 * @public
	 */
	PN_3522 = 35220,
}
/**
 * The forward voltage setting of the LED
 * @public
 */
export enum LEDForwardVoltage {
	/**
	 * 1.7 V
	 * @public
	 */
	VOLTS_1_7 = 1,
	/**
	 * 2.75 V
	 * @public
	 */
	VOLTS_2_75 = 2,
	/**
	 * 3.2 V
	 * @public
	 */
	VOLTS_3_2 = 3,
	/**
	 * 3.9 V
	 * @public
	 */
	VOLTS_3_9 = 4,
	/**
	 * 4.0 V
	 * @public
	 */
	VOLTS_4_0 = 5,
	/**
	 * 4.8 V
	 * @public
	 */
	VOLTS_4_8 = 6,
	/**
	 * 5.0 V
	 * @public
	 */
	VOLTS_5_0 = 7,
	/**
	 * 5.6 V
	 * @public
	 */
	VOLTS_5_6 = 8,
}
/**
 * Voltage supplied to all attached servos
 * @public
 */
export enum RCServoVoltage {
	/**
	 * Run all servos on 5V DC
	 * @public
	 */
	VOLTS_5_0 = 1,
	/**
	 * Run all servos on 6V DC
	 * @public
	 */
	VOLTS_6_0 = 2,
	/**
	 * Run all servos on 7.4V DC
	 * @public
	 */
	VOLTS_7_4 = 3,
}
/**
 * The selected output voltage range
 * @public
 */
export enum VoltageOutputRange {
	/**
	 * ±10V DC
	 * @public
	 */
	VOLTS_10 = 1,
	/**
	 * 0-5V DC
	 * @public
	 */
	VOLTS_5 = 2,
}
/**
 * Measurement range of the voltage input. Larger ranges have less resolution.
 * @public
 */
export enum VoltageRange {
	/**
	 * Range ±10mV DC
	 * @public
	 */
	MILLIVOLTS_10 = 1,
	/**
	 * Range ±40mV DC
	 * @public
	 */
	MILLIVOLTS_40 = 2,
	/**
	 * Range ±200mV DC
	 * @public
	 */
	MILLIVOLTS_200 = 3,
	/**
	 * Range ±312.5mV DC
	 * @public
	 */
	MILLIVOLTS_312_5 = 4,
	/**
	 * Range ±400mV DC
	 * @public
	 */
	MILLIVOLTS_400 = 5,
	/**
	 * Range ±1000mV DC
	 * @public
	 */
	MILLIVOLTS_1000 = 6,
	/**
	 * Range ±2V DC
	 * @public
	 */
	VOLTS_2 = 7,
	/**
	 * Range ±5V DC
	 * @public
	 */
	VOLTS_5 = 8,
	/**
	 * Range ±15V DC
	 * @public
	 */
	VOLTS_15 = 9,
	/**
	 * Range ±40V DC
	 * @public
	 */
	VOLTS_40 = 10,
	/**
	 * Auto-range mode changes based on the present voltage measurements.
	 * @public
	 */
	AUTO = 11,
}
/**
 * Type of sensor attached to the voltage input
 * @public
 */
export enum VoltageSensorType {
	/**
	 * Default. Configures the channel to be a generic voltage sensor. Unit is volts.
	 * @public
	 */
	VOLTAGE = 0,
	/**
	 * 1114 - Temperature Sensor
	 * @public
	 */
	PN_1114 = 11140,
	/**
	 * 1117 - Voltage Sensor
	 * @public
	 */
	PN_1117 = 11170,
	/**
	 * 1123 - Precision Voltage Sensor
	 * @public
	 */
	PN_1123 = 11230,
	/**
	 * 1127 - Precision Light Sensor
	 * @public
	 */
	PN_1127 = 11270,
	/**
	 * 1130 - pH Adapter
	 * @public
	 */
	PN_1130_PH = 11301,
	/**
	 * 1130 - ORP Adapter
	 * @public
	 */
	PN_1130_ORP = 11302,
	/**
	 * 1132 - 4-20mA Adapter
	 * @public
	 */
	PN_1132 = 11320,
	/**
	 * 1133 - Sound Sensor
	 * @public
	 */
	PN_1133 = 11330,
	/**
	 * 1135 - Precision Voltage Sensor
	 * @public
	 */
	PN_1135 = 11350,
	/**
	 * 1142 - Light Sensor 1000 lux
	 * @public
	 */
	PN_1142 = 11420,
	/**
	 * 1143 - Light Sensor 70000 lux
	 * @public
	 */
	PN_1143 = 11430,
	/**
	 * 3500 - AC Current Sensor 10Amp
	 * @public
	 */
	PN_3500 = 35000,
	/**
	 * 3501 - AC Current Sensor 25Amp
	 * @public
	 */
	PN_3501 = 35010,
	/**
	 * 3502 - AC Current Sensor 50Amp
	 * @public
	 */
	PN_3502 = 35020,
	/**
	 * 3503 - AC Current Sensor 100Amp
	 * @public
	 */
	PN_3503 = 35030,
	/**
	 * 3507 - AC Voltage Sensor 0-250V (50Hz)
	 * @public
	 */
	PN_3507 = 35070,
	/**
	 * 3508 - AC Voltage Sensor 0-250V (60Hz)
	 * @public
	 */
	PN_3508 = 35080,
	/**
	 * 3509 - DC Voltage Sensor 0-200V
	 * @public
	 */
	PN_3509 = 35090,
	/**
	 * 3510 - DC Voltage Sensor 0-75V
	 * @public
	 */
	PN_3510 = 35100,
	/**
	 * 3511 - DC Current Sensor 0-10mA
	 * @public
	 */
	PN_3511 = 35110,
	/**
	 * 3512 - DC Current Sensor 0-100mA
	 * @public
	 */
	PN_3512 = 35120,
	/**
	 * 3513 - DC Current Sensor 0-1A
	 * @public
	 */
	PN_3513 = 35130,
	/**
	 * 3514 - AC Active Power Sensor 0-250V*0-30A (50Hz)
	 * @public
	 */
	PN_3514 = 35140,
	/**
	 * 3515 - AC Active Power Sensor 0-250V*0-30A (60Hz)
	 * @public
	 */
	PN_3515 = 35150,
	/**
	 * 3516 - AC Active Power Sensor 0-250V*0-5A (50Hz)
	 * @public
	 */
	PN_3516 = 35160,
	/**
	 * 3517 - AC Active Power Sensor 0-250V*0-5A (60Hz)
	 * @public
	 */
	PN_3517 = 35170,
	/**
	 * 3518 - AC Active Power Sensor 0-110V*0-5A (60Hz)
	 * @public
	 */
	PN_3518 = 35180,
	/**
	 * 3519 - AC Active Power Sensor 0-110V*0-15A (60Hz)
	 * @public
	 */
	PN_3519 = 35190,
	/**
	 * 3584 - 0-50A DC Current Transducer
	 * @public
	 */
	PN_3584 = 35840,
	/**
	 * 3585 - 0-100A DC Current Transducer
	 * @public
	 */
	PN_3585 = 35850,
	/**
	 * 3586 - 0-250A DC Current Transducer
	 * @public
	 */
	PN_3586 = 35860,
	/**
	 * 3587 - +-50A DC Current Transducer
	 * @public
	 */
	PN_3587 = 35870,
	/**
	 * 3588 - +-100A DC Current Transducer
	 * @public
	 */
	PN_3588 = 35880,
	/**
	 * 3589 - +-250A DC Current Transducer
	 * @public
	 */
	PN_3589 = 35890,
	/**
	 * MOT2002 - Motion Sensor Low Sensitivity
	 * @public
	 */
	PN_MOT2002_LOW = 20020,
	/**
	 * MOT2002 - Motion Sensor Medium Sensitivity
	 * @public
	 */
	PN_MOT2002_MED = 20021,
	/**
	 * MOT2002 - Motion Sensor High Sensitivity
	 * @public
	 */
	PN_MOT2002_HIGH = 20022,
	/**
	 * VCP4114 - +-25A DC Current Transducer
	 * @public
	 */
	PN_VCP4114 = 41140,
}
/**
 * The protocol used to communicate with the tags
 * @public
 */
export enum RFIDProtocol {
	/**
	 * EM4100 Series
	 * @public
	 */
	EM4100 = 1,
	/**
	 * ISO11785 FDX B
	 * @public
	 */
	ISO11785_FDX_B = 2,
	/**
	 * PhidgetTAG
	 * @public
	 */
	PHIDGET_TAG = 3,
}
/**
 * Controls the AHRS algorithm.
 * @public
 */
export enum SpatialAlgorithm {
	/**
	 * No AHRS algorithm is used.
	 * @public
	 */
	NONE = 0,
	/**
	 * AHRS algorithm, incorporating magnetometer data for yaw correction.
	 * @public
	 */
	AHRS = 1,
	/**
	 * IMU algorithm, using gyro and accelerometer, but not magnetometer.
	 * @public
	 */
	IMU = 2,
}
/**
 * RTD sensor type
 * @public
 */
export enum RTDType {
	/**
	 * Configures the RTD type as a PT100 with a 3850ppm curve.
	 * @public
	 */
	PT100_3850 = 1,
	/**
	 * Configures the RTD type as a PT1000 with a 3850ppm curve.
	 * @public
	 */
	PT1000_3850 = 2,
	/**
	 * Configures the RTD type as a PT100 with a 3920ppm curve.
	 * @public
	 */
	PT100_3920 = 3,
	/**
	 * Configures the RTD type as a PT1000 with a 3920ppm curve.
	 * @public
	 */
	PT1000_3920 = 4,
}
/**
 * The type of thermocouple being used
 * @public
 */
export enum ThermocoupleType {
	/**
	 * Configures the thermocouple input as a J-Type thermocouple.
	 * @public
	 */
	J = 1,
	/**
	 * Configures the thermocouple input as a K-Type thermocouple.
	 * @public
	 */
	K = 2,
	/**
	 * Configures the thermocouple input as a E-Type thermocouple.
	 * @public
	 */
	E = 3,
	/**
	 * Configures the thermocouple input as a T-Type thermocouple.
	 * @public
	 */
	T = 4,
}
/**
 * Type of filter used on the frequency input
 * @public
 */
export enum FrequencyFilterType {
	/**
	 * Frequency is calculated from the number of times the signal transitions from a negative voltage to a positive voltage and back again.
	 * @public
	 */
	ZERO_CROSSING = 1,
	/**
	 * Frequency is calculated from the number of times the signal transitions from a logic false to a logic true and back again.
	 * @public
	 */
	LOGIC_LEVEL = 2,
}
/**
 * Describes the encoding technique used for the code
 * @public
 */
export enum IRCodeEncoding {
	/**
	 * Unknown - the default value
	 * @public
	 */
	UNKNOWN = 1,
	/**
	 * Space encoding, or Pulse Distance Modulation
	 * @public
	 */
	SPACE = 2,
	/**
	 * Pulse encoding, or Pulse Width Modulation
	 * @public
	 */
	PULSE = 3,
	/**
	 * Bi-Phase, or Manchester encoding
	 * @public
	 */
	BI_PHASE = 4,
	/**
	 * RC5 - a type of Bi-Phase encoding
	 * @public
	 */
	RC5 = 5,
	/**
	 * RC6 - a type of Bi-Phase encoding
	 * @public
	 */
	RC6 = 6,
}
/**
 * The length type of the bitstream and gaps
 * @public
 */
export enum IRCodeLength {
	/**
	 * Unknown - the default value
	 * @public
	 */
	UNKNOWN = 1,
	/**
	 * Constant - the bitstream and gap length is constant
	 * @public
	 */
	CONSTANT = 2,
	/**
	 * Variable - the bitstream has a variable length with a constant gap
	 * @public
	 */
	VARIABLE = 3,
}
/**
 * Method of motor control
 * @public
 */
export enum StepperControlMode {
	/**
	 * Control the motor by setting a target position.
	 * @public
	 */
	STEP = 0,
	/**
	 * Control the motor by selecting a target velocity (sign indicates direction). The motor will rotate continuously in the chosen direction.
	 * @public
	 */
	RUN = 1,
}
/**
 * The type of font being used
 * @public
 */
export enum LCDFont {
	/**
	 * User-defined font #1
	 * @public
	 */
	USER1 = 1,
	/**
	 * User-defined font #2
	 * @public
	 */
	USER2 = 2,
	/**
	 * 6px by 10px font
	 * @public
	 */
	DIMENSIONS_6X10 = 3,
	/**
	 * 5px by 8px font
	 * @public
	 */
	DIMENSIONS_5X8 = 4,
	/**
	 * 6px by 12px font
	 * @public
	 */
	DIMENSIONS_6X12 = 5,
}
/**
 * Size of the attached LCD screen
 * @public
 */
export enum LCDScreenSize {
	/**
	 * Screen size unknown
	 * @public
	 */
	NO_SCREEN = 1,
	/**
	 * One row, eight column text screen
	 * @public
	 */
	DIMENSIONS_1X8 = 2,
	/**
	 * Two row, eight column text screen
	 * @public
	 */
	DIMENSIONS_2X8 = 3,
	/**
	 * One row, 16 column text screen
	 * @public
	 */
	DIMENSIONS_1X16 = 4,
	/**
	 * Two row, 16 column text screen
	 * @public
	 */
	DIMENSIONS_2X16 = 5,
	/**
	 * Four row, 16 column text screen
	 * @public
	 */
	DIMENSIONS_4X16 = 6,
	/**
	 * Two row, 20 column text screen
	 * @public
	 */
	DIMENSIONS_2X20 = 7,
	/**
	 * Four row, 20 column text screen.
	 * @public
	 */
	DIMENSIONS_4X20 = 8,
	/**
	 * Two row, 24 column text screen
	 * @public
	 */
	DIMENSIONS_2X24 = 9,
	/**
	 * One row, 40 column text screen
	 * @public
	 */
	DIMENSIONS_1X40 = 10,
	/**
	 * Two row, 40 column text screen
	 * @public
	 */
	DIMENSIONS_2X40 = 11,
	/**
	 * Four row, 40 column text screen
	 * @public
	 */
	DIMENSIONS_4X40 = 12,
	/**
	 * 64px by 128px graphic screen
	 * @public
	 */
	DIMENSIONS_64X128 = 13,
}
/**
 * The on/off state of the pixel to be set
 * @public
 */
export enum LCDPixelState {
	/**
	 * Pixel off state
	 * @public
	 */
	OFF = 0,
	/**
	 * Pixel on state
	 * @public
	 */
	ON = 1,
	/**
	 * Invert the pixel state
	 * @public
	 */
	INVERT = 2,
}
/**
 * The parity setting of the data adapter
 * @public
 */
export enum DataAdapterParity {
	/**
	 * No Parity Bit
	 * @public
	 */
	NONE = 1,
	/**
	 * Even Parity
	 * @public
	 */
	EVEN = 2,
	/**
	 * Odd Parity
	 * @public
	 */
	ODD = 3,
}
/**
 * The number of stop bits used for communication
 * @public
 */
export enum DataAdapterStopBits {
	/**
	 * One Stop Bit
	 * @public
	 */
	ONE = 1,
	/**
	 * Two Stop Bits
	 * @public
	 */
	TWO = 2,
}
/**
 * The mothod of handshaking used for communication
 * @public
 */
export enum DataAdapterHandshakeMode {
	/**
	 * RTS is never set, and CTS is ignored.
	 * @public
	 */
	NONE = 1,
	/**
	 * RTS Pin requests to transmit data. CTS input confirms we can send data.
	 * @public
	 */
	REQUEST_TO_SEND = 2,
	/**
	 * RTS signals this device can receive data. CTS confirms we can send data.
	 * @public
	 */
	READY_TO_RECEIVE = 3,
}
/**
 * The communication protocol
 * @public
 */
export enum DataAdapterProtocol {
	/**
	 * Basic half-duplex RS485. Always receiving until you send data.
	 * @public
	 */
	RS485 = 1,
	/**
	 * Uses full-duplex RS422 communication.
	 * @public
	 */
	RS422 = 2,
	/**
	 * Allows communication with DMX512-compatible devices, such as stage lighting
	 * @public
	 */
	DMX512 = 3,
	/**
	 * Allows communication with MODBUS RTU compatible devices
	 * @public
	 */
	MODBUS_RTU = 4,
	/**
	 * Allows communication with SPI compatible devices
	 * @public
	 */
	SPI = 5,
	/**
	 * Allows communication with I2C compatible devices
	 * @public
	 */
	I2C = 6,
	/**
	 * Allows communication with UART compatible devices
	 * @public
	 */
	UART = 7,
	/**
	 * Allows communication with RS232 compatible devices
	 * @public
	 */
	RS232 = 8,
}
/**
 * The SPI Mode
 * @public
 */
export enum DataAdapterSPIMode {
	/**
	 * CPOL = 0 CPHA = 0
	 * @public
	 */
	MODE_0 = 1,
	/**
	 * CPOL = 0 CPHA = 1
	 * @public
	 */
	MODE_1 = 2,
	/**
	 * CPOL = 1 CPHA = 0
	 * @public
	 */
	MODE_2 = 3,
	/**
	 * CPOL = 1 CPHA = 1
	 * @public
	 */
	MODE_3 = 4,
}
/**
 * The SPI Mode
 * @public
 */
export enum DataAdapterEndianness {
	/**
	 * MSB First
	 * @public
	 */
	MSB_FIRST = 1,
	/**
	 * LSB First
	 * @public
	 */
	LSB_FIRST = 2,
}
/**
 * The IO Voltage
 * @public
 */
export enum DataAdapterIOVoltage {
	/**
	 * Voltage supplied by external device
	 * @public
	 */
	EXTERNAL = 1,
	/**
	 * 1.8V
	 * @public
	 */
	VOLTS_1_8 = 2,
	/**
	 * 2.5V
	 * @public
	 */
	VOLTS_2_5 = 3,
	/**
	 * 3.3V
	 * @public
	 */
	VOLTS_3_3 = 4,
	/**
	 * 5.0V
	 * @public
	 */
	VOLTS_5 = 5,
}
/**
 * The Type of Error on the Packet
 * @public
 */
export enum PacketErrorCode {
	/**
	 * No error
	 * @public
	 */
	OK = 0,
	/**
	 * Unknown Error
	 * @public
	 */
	UNKNOWN = 1,
	/**
	 * The response packet timed out
	 * @public
	 */
	TIMEOUT = 2,
	/**
	 * Something about the received transmission didn't match the expected format.
	 * @public
	 */
	FORMAT = 3,
	/**
	 * The input lines are invalid. This likely means a cable has been unplugged.
	 * @public
	 */
	INVALID = 4,
	/**
	 * Data is being received fater than it can be processed. Some has been lost.
	 * @public
	 */
	OVERRUN = 5,
	/**
	 * Something behind the scenes got out of sequence.
	 * @public
	 */
	CORRUPT = 6,
}
/**
 * The measurement range of the sound sensor
 * @public
 */
export enum SPLRange {
	/**
	 * Range 102dB
	 * @public
	 */
	DB_102 = 1,
	/**
	 * Range 82dB
	 * @public
	 */
	DB_82 = 2,
}
/**
 * The mode of the VINT port
 * @public
 */
export enum HubPortMode {
	/**
	 * Communicate with a smart VINT device
	 * @public
	 */
	VINT = 0,
	/**
	 * 5V Logic-level digital input
	 * @public
	 */
	DIGITAL_INPUT = 1,
	/**
	 * 3.3V digital output
	 * @public
	 */
	DIGITAL_OUTPUT = 2,
	/**
	 * 0-5V voltage input for non-ratiometric sensors 
	 * @public
	 */
	VOLTAGE_INPUT = 3,
	/**
	 * 0-5V voltage input for ratiometric sensors
	 * @public
	 */
	VOLTAGE_RATIO_INPUT = 4,
}
