import * as VintPackets from './VintPackets';
import { DeviceUID } from '../Devices.gen';
import { LocalChannel } from './LocalChannel';
import { BridgePacket } from '../BridgePacket';
import { PhidgetError } from '../PhidgetError';
import { ErrorCode } from '../Enumerations.gen';

type VintSend = (ch: LocalChannel, bp: BridgePacket) => Promise<void>;
type VintRecv = (ch: LocalChannel, buf: DataView) => void;

/** @internal */
export function getVINTIO(uid: DeviceUID): { send: VintSend; recv: VintRecv } {
	switch (uid) {
		case DeviceUID._1014_3_VINT:
			return { send: VintPackets.send1014_3_VINT, recv: VintPackets.recv1014_3_VINT };
		case DeviceUID._1017_2_VINT:
			return { send: VintPackets.send1017_2_VINT, recv: VintPackets.recv1017_2_VINT };
		case DeviceUID._1055_1_VINT:
			return { send: VintPackets.send1055_1_VINT, recv: VintPackets.recv1055_1_VINT };
		case DeviceUID._DIGITALINPUT_PORT:
			return { send: VintPackets.sendDIGITALINPUT_PORT, recv: VintPackets.recvDIGITALINPUT_PORT };
		case DeviceUID._DIGITALOUTPUT_PORT:
			return { send: VintPackets.sendDIGITALOUTPUT_PORT, recv: VintPackets.recvDIGITALOUTPUT_PORT };
		case DeviceUID._DIGITALOUTPUT_PORT_FAILSAFE:
			return { send: VintPackets.sendDIGITALOUTPUT_PORT_FAILSAFE, recv: VintPackets.recvDIGITALOUTPUT_PORT_FAILSAFE };
		case DeviceUID._VOLTAGEINPUT_PORT:
			return { send: VintPackets.sendVOLTAGEINPUT_PORT, recv: VintPackets.recvVOLTAGEINPUT_PORT };
		case DeviceUID._VOLTAGEINPUT_PORT_5V25:
			return { send: VintPackets.sendVOLTAGEINPUT_PORT_5V25, recv: VintPackets.recvVOLTAGEINPUT_PORT_5V25 };
		case DeviceUID._VOLTAGEINPUT_PORT_V2:
			return { send: VintPackets.sendVOLTAGEINPUT_PORT_V2, recv: VintPackets.recvVOLTAGEINPUT_PORT_V2 };
		case DeviceUID._VOLTAGERATIOINPUT_PORT:
			return { send: VintPackets.sendVOLTAGERATIOINPUT_PORT, recv: VintPackets.recvVOLTAGERATIOINPUT_PORT };
		case DeviceUID._VOLTAGERATIOINPUT_PORT_V2:
			return { send: VintPackets.sendVOLTAGERATIOINPUT_PORT_V2, recv: VintPackets.recvVOLTAGERATIOINPUT_PORT_V2 };
		case DeviceUID._ADP1000:
			return { send: VintPackets.sendADP1000, recv: VintPackets.recvADP1000 };
		case DeviceUID._DAQ1000:
			return { send: VintPackets.sendDAQ1000, recv: VintPackets.recvDAQ1000 };
		case DeviceUID._DAQ1000_5V25:
			return { send: VintPackets.sendDAQ1000_5V25, recv: VintPackets.recvDAQ1000_5V25 };
		case DeviceUID._OUT1000:
			return { send: VintPackets.sendOUT1000, recv: VintPackets.recvOUT1000 };
		case DeviceUID._OUT1000_Failsafe:
			return { send: VintPackets.sendOUT1000_Failsafe, recv: VintPackets.recvOUT1000_Failsafe };
		case DeviceUID._OUT1001:
			return { send: VintPackets.sendOUT1001, recv: VintPackets.recvOUT1001 };
		case DeviceUID._OUT1001_Failsafe:
			return { send: VintPackets.sendOUT1001_Failsafe, recv: VintPackets.recvOUT1001_Failsafe };
		case DeviceUID._OUT1002:
			return { send: VintPackets.sendOUT1002, recv: VintPackets.recvOUT1002 };
		case DeviceUID._OUT1002_Failsafe:
			return { send: VintPackets.sendOUT1002_Failsafe, recv: VintPackets.recvOUT1002_Failsafe };
		case DeviceUID._DAQ1200:
			return { send: VintPackets.sendDAQ1200, recv: VintPackets.recvDAQ1200 };
		case DeviceUID._OUT1100:
			return { send: VintPackets.sendOUT1100, recv: VintPackets.recvOUT1100 };
		case DeviceUID._OUT1100_Failsafe:
			return { send: VintPackets.sendOUT1100_Failsafe, recv: VintPackets.recvOUT1100_Failsafe };
		case DeviceUID._OUT1100_Failsafe_Frequency:
			return { send: VintPackets.sendOUT1100_Failsafe_Frequency, recv: VintPackets.recvOUT1100_Failsafe_Frequency };
		case DeviceUID._DAQ1300:
			return { send: VintPackets.sendDAQ1300, recv: VintPackets.recvDAQ1300 };
		case DeviceUID._DAQ1301:
			return { send: VintPackets.sendDAQ1301, recv: VintPackets.recvDAQ1301 };
		case DeviceUID._DAQ1400:
			return { send: VintPackets.sendDAQ1400, recv: VintPackets.recvDAQ1400 };
		case DeviceUID._DAQ1400_120:
			return { send: VintPackets.sendDAQ1400_120, recv: VintPackets.recvDAQ1400_120 };
		case DeviceUID._DAQ1500:
			return { send: VintPackets.sendDAQ1500, recv: VintPackets.recvDAQ1500 };
		case DeviceUID._VCP1100:
			return { send: VintPackets.sendVCP1100, recv: VintPackets.recvVCP1100 };
		case DeviceUID._DCC1000:
			return { send: VintPackets.sendDCC1000, recv: VintPackets.recvDCC1000 };
		case DeviceUID._DCC1000_POSITIONCONTROL:
			return { send: VintPackets.sendDCC1000_POSITIONCONTROL, recv: VintPackets.recvDCC1000_POSITIONCONTROL };
		case DeviceUID._DCC1000_POSITIONCONTROL_FAILSAFE:
			return { send: VintPackets.sendDCC1000_POSITIONCONTROL_FAILSAFE, recv: VintPackets.recvDCC1000_POSITIONCONTROL_FAILSAFE };
		case DeviceUID._DCC1001:
			return { send: VintPackets.sendDCC1001, recv: VintPackets.recvDCC1001 };
		case DeviceUID._DCC1001_Failsafe:
			return { send: VintPackets.sendDCC1001_Failsafe, recv: VintPackets.recvDCC1001_Failsafe };
		case DeviceUID._DCC1002:
			return { send: VintPackets.sendDCC1002, recv: VintPackets.recvDCC1002 };
		case DeviceUID._DCC1002_Failsafe:
			return { send: VintPackets.sendDCC1002_Failsafe, recv: VintPackets.recvDCC1002_Failsafe };
		case DeviceUID._DCC1003:
			return { send: VintPackets.sendDCC1003, recv: VintPackets.recvDCC1003 };
		case DeviceUID._DCC1003_Failsafe:
			return { send: VintPackets.sendDCC1003_Failsafe, recv: VintPackets.recvDCC1003_Failsafe };
		case DeviceUID._DCC1100:
			return { send: VintPackets.sendDCC1100, recv: VintPackets.recvDCC1100 };
		case DeviceUID._DCC1100_Failsafe:
			return { send: VintPackets.sendDCC1100_Failsafe, recv: VintPackets.recvDCC1100_Failsafe };
		case DeviceUID._DST1000:
			return { send: VintPackets.sendDST1000, recv: VintPackets.recvDST1000 };
		case DeviceUID._DST1001:
			return { send: VintPackets.sendDST1001, recv: VintPackets.recvDST1001 };
		case DeviceUID._DST1002:
			return { send: VintPackets.sendDST1002, recv: VintPackets.recvDST1002 };
		case DeviceUID._DST1200:
			return { send: VintPackets.sendDST1200, recv: VintPackets.recvDST1200 };
		case DeviceUID._ENC1000:
			return { send: VintPackets.sendENC1000, recv: VintPackets.recvENC1000 };
		case DeviceUID._ENC1001:
			return { send: VintPackets.sendENC1001, recv: VintPackets.recvENC1001 };
		case DeviceUID._HIN1101:
			return { send: VintPackets.sendHIN1101, recv: VintPackets.recvHIN1101 };
		case DeviceUID._HIN1000:
			return { send: VintPackets.sendHIN1000, recv: VintPackets.recvHIN1000 };
		case DeviceUID._HIN1001:
			return { send: VintPackets.sendHIN1001, recv: VintPackets.recvHIN1001 };
		case DeviceUID._HIN1100:
			return { send: VintPackets.sendHIN1100, recv: VintPackets.recvHIN1100 };
		case DeviceUID._HUM1000:
			return { send: VintPackets.sendHUM1000, recv: VintPackets.recvHUM1000 };
		case DeviceUID._HUM1001:
			return { send: VintPackets.sendHUM1001, recv: VintPackets.recvHUM1001 };
		case DeviceUID._LCD1100:
			return { send: VintPackets.sendLCD1100, recv: VintPackets.recvLCD1100 };
		case DeviceUID._LCD1100_1:
			return { send: VintPackets.sendLCD1100_1, recv: VintPackets.recvLCD1100_1 };
		case DeviceUID._LED1000:
			return { send: VintPackets.sendLED1000, recv: VintPackets.recvLED1000 };
		case DeviceUID._LUX1000:
			return { send: VintPackets.sendLUX1000, recv: VintPackets.recvLUX1000 };
		case DeviceUID._HUM1100:
			return { send: VintPackets.sendHUM1100, recv: VintPackets.recvHUM1100 };
		case DeviceUID._MOT1100:
			return { send: VintPackets.sendMOT1100, recv: VintPackets.recvMOT1100 };
		case DeviceUID._MOT0100_VINT:
			return { send: VintPackets.sendMOT0100_VINT, recv: VintPackets.recvMOT0100_VINT };
		case DeviceUID._MOT1101:
			return { send: VintPackets.sendMOT1101, recv: VintPackets.recvMOT1101 };
		case DeviceUID._MOT1102:
			return { send: VintPackets.sendMOT1102, recv: VintPackets.recvMOT1102 };
		case DeviceUID._MOT1102_1:
			return { send: VintPackets.sendMOT1102_1, recv: VintPackets.recvMOT1102_1 };
		case DeviceUID._MOT0110_VINT:
			return { send: VintPackets.sendMOT0110_VINT, recv: VintPackets.recvMOT0110_VINT };
		case DeviceUID._PRE1000:
			return { send: VintPackets.sendPRE1000, recv: VintPackets.recvPRE1000 };
		case DeviceUID._RCC1000:
			return { send: VintPackets.sendRCC1000, recv: VintPackets.recvRCC1000 };
		case DeviceUID._RCC1000_Failsafe:
			return { send: VintPackets.sendRCC1000_Failsafe, recv: VintPackets.recvRCC1000_Failsafe };
		case DeviceUID._RCC1000_1:
			return { send: VintPackets.sendRCC1000_1, recv: VintPackets.recvRCC1000_1 };
		case DeviceUID._REL1000:
			return { send: VintPackets.sendREL1000, recv: VintPackets.recvREL1000 };
		case DeviceUID._REL1000_Failsafe:
			return { send: VintPackets.sendREL1000_Failsafe, recv: VintPackets.recvREL1000_Failsafe };
		case DeviceUID._REL1100:
			return { send: VintPackets.sendREL1100, recv: VintPackets.recvREL1100 };
		case DeviceUID._REL1100_Failsafe:
			return { send: VintPackets.sendREL1100_Failsafe, recv: VintPackets.recvREL1100_Failsafe };
		case DeviceUID._REL1100_Failsafe_Frequency:
			return { send: VintPackets.sendREL1100_Failsafe_Frequency, recv: VintPackets.recvREL1100_Failsafe_Frequency };
		case DeviceUID._REL1101:
			return { send: VintPackets.sendREL1101, recv: VintPackets.recvREL1101 };
		case DeviceUID._REL1101_Failsafe:
			return { send: VintPackets.sendREL1101_Failsafe, recv: VintPackets.recvREL1101_Failsafe };
		case DeviceUID._REL1101_1:
			return { send: VintPackets.sendREL1101_1, recv: VintPackets.recvREL1101_1 };
		case DeviceUID._SAF1000:
			return { send: VintPackets.sendSAF1000, recv: VintPackets.recvSAF1000 };
		case DeviceUID._SAF1000_Failsafe:
			return { send: VintPackets.sendSAF1000_Failsafe, recv: VintPackets.recvSAF1000_Failsafe };
		case DeviceUID._SND1000:
			return { send: VintPackets.sendSND1000, recv: VintPackets.recvSND1000 };
		case DeviceUID._STC1000:
			return { send: VintPackets.sendSTC1000, recv: VintPackets.recvSTC1000 };
		case DeviceUID._STC1000_Failsafe:
			return { send: VintPackets.sendSTC1000_Failsafe, recv: VintPackets.recvSTC1000_Failsafe };
		case DeviceUID._STC1001:
			return { send: VintPackets.sendSTC1001, recv: VintPackets.recvSTC1001 };
		case DeviceUID._STC1001_Failsafe:
			return { send: VintPackets.sendSTC1001_Failsafe, recv: VintPackets.recvSTC1001_Failsafe };
		case DeviceUID._STC1002:
			return { send: VintPackets.sendSTC1002, recv: VintPackets.recvSTC1002 };
		case DeviceUID._STC1002_Failsafe:
			return { send: VintPackets.sendSTC1002_Failsafe, recv: VintPackets.recvSTC1002_Failsafe };
		case DeviceUID._STC1003:
			return { send: VintPackets.sendSTC1003, recv: VintPackets.recvSTC1003 };
		case DeviceUID._STC1003_Failsafe:
			return { send: VintPackets.sendSTC1003_Failsafe, recv: VintPackets.recvSTC1003_Failsafe };
		case DeviceUID._STC1003_1:
			return { send: VintPackets.sendSTC1003_1, recv: VintPackets.recvSTC1003_1 };
		case DeviceUID._STC1005_0:
			return { send: VintPackets.sendSTC1005_0, recv: VintPackets.recvSTC1005_0 };
		case DeviceUID._STC1005_0_110:
			return { send: VintPackets.sendSTC1005_0_110, recv: VintPackets.recvSTC1005_0_110 };
		case DeviceUID._TMP1000:
			return { send: VintPackets.sendTMP1000, recv: VintPackets.recvTMP1000 };
		case DeviceUID._TMP1100:
			return { send: VintPackets.sendTMP1100, recv: VintPackets.recvTMP1100 };
		case DeviceUID._TMP1101:
			return { send: VintPackets.sendTMP1101, recv: VintPackets.recvTMP1101 };
		case DeviceUID._TMP1101_1:
			return { send: VintPackets.sendTMP1101_1, recv: VintPackets.recvTMP1101_1 };
		case DeviceUID._TMP1200:
			return { send: VintPackets.sendTMP1200, recv: VintPackets.recvTMP1200 };
		case DeviceUID._TMP1200_1:
			return { send: VintPackets.sendTMP1200_1, recv: VintPackets.recvTMP1200_1 };
		case DeviceUID._TMP1200_2:
			return { send: VintPackets.sendTMP1200_2, recv: VintPackets.recvTMP1200_2 };
		case DeviceUID._VCP1000:
			return { send: VintPackets.sendVCP1000, recv: VintPackets.recvVCP1000 };
		case DeviceUID._VCP1001:
			return { send: VintPackets.sendVCP1001, recv: VintPackets.recvVCP1001 };
		case DeviceUID._VCP1001_110:
			return { send: VintPackets.sendVCP1001_110, recv: VintPackets.recvVCP1001_110 };
		case DeviceUID._VCP1002:
			return { send: VintPackets.sendVCP1002, recv: VintPackets.recvVCP1002 };
		case DeviceUID._VCP1002_110:
			return { send: VintPackets.sendVCP1002_110, recv: VintPackets.recvVCP1002_110 };
		case DeviceUID._FIRMWARE_UPGRADE_STM32F0:
			return { send: VintPackets.sendFIRMWARE_UPGRADE_STM32F0, recv: VintPackets.recvFIRMWARE_UPGRADE_STM32F0 };
		case DeviceUID._FIRMWARE_UPGRADE_STM32G0_BLBug:
			return { send: VintPackets.sendFIRMWARE_UPGRADE_STM32G0_BLBug, recv: VintPackets.recvFIRMWARE_UPGRADE_STM32G0_BLBug };
		case DeviceUID._FIRMWARE_UPGRADE_STM32G0:
			return { send: VintPackets.sendFIRMWARE_UPGRADE_STM32G0, recv: VintPackets.recvFIRMWARE_UPGRADE_STM32G0 };
		case DeviceUID._FIRMWARE_UPGRADE_STM32F3:
			return { send: VintPackets.sendFIRMWARE_UPGRADE_STM32F3, recv: VintPackets.recvFIRMWARE_UPGRADE_STM32F3 };
		case DeviceUID._FIRMWARE_UPGRADE_STM8S:
			return { send: VintPackets.sendFIRMWARE_UPGRADE_STM8S, recv: VintPackets.recvFIRMWARE_UPGRADE_STM8S };
		case DeviceUID._UNKNOWNVINT:
			return { send: VintPackets.sendUNKNOWNVINT, recv: VintPackets.recvUNKNOWNVINT };
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
	}
}
