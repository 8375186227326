/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PhidgetChannel } from '../Phidget';
import { Channel } from '../Channel';
import { ErrorCode, ChannelClass } from '../Enumerations.gen';
import * as Enum from '../Enumerations.gen';
import * as SEnum from '../SupportedEnum.gen';
import { PhidgetError } from '../PhidgetError';
import { BridgePacket, PUNK } from '../BridgePacket';
import { BP } from '../BridgePackets.gen';
import { DeviceChannelUID } from '../Devices.gen';

/** @internal */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RCServoData {
	maxPulseWidthLimit: number,
	maxPulseWidth: number,
	maxPosition: number,
	maxVelocityLimit: number,
	maxAcceleration: number,
	maxTorque: number,
	minTorque: number,
	minPulseWidthLimit: number,
	minPulseWidth: number,
	minPosition: number,
	minVelocityLimit: number,
	minAcceleration: number,
	position: number,
	targetPosition: number,
	velocityLimit: number,
	velocity: number,
	acceleration: number,
	torque: number,
	isMoving: number,
	engaged: number,
	speedRampingState: number,
	voltage: Enum.RCServoVoltage | PUNK.ENUM,
	dataInterval: number,
	maxDataInterval: number,
	minDataInterval: number,
	minDataRate: number,
	maxDataRate: number,
	maxFailsafeTime: number,
	minFailsafeTime: number,
}

abstract class RCServoBase extends PhidgetChannel {
	/** @internal */
	data: RCServoData;
	/**
	 * **PositionChange** event
	 *  * `position` - The position value
	 * ---
	 * An event that occurs when the position changes on a RC servo motor.
	 */
	onPositionChange: ((position: number) => void) | null = null;
	/** @internal */
	_gotPositionChangeErrorEvent?: boolean;
	/**
	 * **VelocityChange** event
	 *  * `velocity` - The velocity value
	 * ---
	 * An event that occurs when the velocity changes on a RC servo motor.
	 */
	onVelocityChange: ((velocity: number) => void) | null = null;
	/** @internal */
	_gotVelocityChangeErrorEvent?: boolean;
	/**
	 * **TargetPositionReached** event
	 *  * `position` - The position value
	 * ---
	 * Occurs when the RC servo motor has reached the `targetPosition`.
	 * 
	 * *   The controller cannot know if the RC servo motor has physically reached the target position. When `targetPosition` is reached, it simply means the controller pulse width output is matching its target.
	 */
	onTargetPositionReached: ((position: number) => void) | null = null;
	/** @internal */
	_gotTargetPositionReachedErrorEvent?: boolean;

	/**
	 * The RC Servo class controls the signal being sent to the servo motors from the Phidget controller in order to control their position. This class provides control of the position, velocity, acceleration, and supply voltage of the attached servo.
	 * @public
	 */
	constructor();
	/** @internal */
	constructor(ch?: Channel);
	constructor(ch?: Channel) {
		super(ch);
		this._class = ChannelClass.RC_SERVO;
		this.name = "RCServo";
		this.data = this._initData();
	}

	/** @internal */
	_bridgeInput(bp: BridgePacket) {

		switch(bp.vpkt) {
		case BP.SETACCELERATION:
			this.data.acceleration = bp.entries[0].v as number;
			this._FIREPropertyChange('Acceleration', bp);
			break;
		case BP.SETDATAINTERVAL:
			if (bp.entryCount > 1)
				this.data.dataInterval = bp.entries[1].v as number;
			else
				this.data.dataInterval = bp.entries[0].v as number;
			this._FIREPropertyChange('DataInterval', bp);
			this._FIREPropertyChange('DataRate', bp);
			break;
		case BP.SETENGAGED:
			this.data.engaged = bp.entries[0].v as number;
			this._FIREPropertyChange('Engaged', bp);
			break;
		case BP.SETFAILSAFETIME:
			break;
		case BP.SETMINPULSEWIDTH:
			this.data.minPulseWidth = bp.entries[0].v as number;
			this._FIREPropertyChange('MinPulseWidth', bp);
			break;
		case BP.SETMAXPULSEWIDTH:
			this.data.maxPulseWidth = bp.entries[0].v as number;
			this._FIREPropertyChange('MaxPulseWidth', bp);
			break;
		case BP.FAILSAFERESET:
			break;
		case BP.SETSPEEDRAMPINGSTATE:
			this.data.speedRampingState = bp.entries[0].v as number;
			this._FIREPropertyChange('SpeedRampingState', bp);
			break;
		case BP.SETTARGETPOSITION:
			this.data.targetPosition = bp.entries[0].v as number;
			this._FIREPropertyChange('TargetPosition', bp);
			break;
		case BP.SETDUTYCYCLE:
			this.data.torque = bp.entries[0].v as number;
			this._FIREPropertyChange('Torque', bp);
			break;
		case BP.SETVELOCITYLIMIT:
			this.data.velocityLimit = bp.entries[0].v as number;
			this._FIREPropertyChange('VelocityLimit', bp);
			break;
		case BP.SETVOLTAGE:
			this.data.voltage = bp.entries[0].v as Enum.RCServoVoltage;
			this._FIREPropertyChange('Voltage', bp);
			break;
		default:
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			throw new PhidgetError(ErrorCode.INVALID_PACKET, "Unsupported bridge packet: 0x" + bp.vpkt!.toString(16));
		}
	}

	/** @internal */
	_initData(): RCServoData {
		return {
			maxPulseWidthLimit: PUNK.DBL,
			maxPulseWidth: PUNK.DBL,
			maxPosition: PUNK.DBL,
			maxVelocityLimit: PUNK.DBL,
			maxAcceleration: PUNK.DBL,
			maxTorque: PUNK.DBL,
			minTorque: PUNK.DBL,
			minPulseWidthLimit: PUNK.DBL,
			minPulseWidth: PUNK.DBL,
			minPosition: PUNK.DBL,
			minVelocityLimit: PUNK.DBL,
			minAcceleration: PUNK.DBL,
			position: PUNK.DBL,
			targetPosition: PUNK.DBL,
			velocityLimit: PUNK.DBL,
			velocity: PUNK.DBL,
			acceleration: PUNK.DBL,
			torque: PUNK.DBL,
			isMoving: PUNK.BOOL,
			engaged: PUNK.BOOL,
			speedRampingState: PUNK.BOOL,
			voltage: PUNK.ENUM,
			dataInterval: PUNK.DBL,
			maxDataInterval: PUNK.UINT32,
			minDataInterval: PUNK.UINT32,
			minDataRate: PUNK.DBL,
			maxDataRate: PUNK.DBL,
			maxFailsafeTime: PUNK.UINT32,
			minFailsafeTime: PUNK.UINT32,
		}
	}

	/** @internal */
	_initAfterOpen() {
		this.data = this._initData();

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._RCC1000_RCSERVO_100:
			this.data.maxPulseWidthLimit = 4000;
			this.data.maxPulseWidth = 2450;
			this.data.maxPosition = 180;
			this.data.maxVelocityLimit = 781250;
			this.data.maxAcceleration = 3906250;
			this.data.minPulseWidthLimit = 0.063;
			this.data.minPulseWidth = 550;
			this.data.minPosition = 0;
			this.data.minVelocityLimit = 0;
			this.data.minAcceleration = 156.25;
			this.data.velocityLimit = 1900;
			this.data.acceleration = 3800;
			this.data.engaged = 0;
			this.data.speedRampingState = 1;
			this.data.voltage = Enum.RCServoVoltage.VOLTS_5_0;
			break;
		case DeviceChannelUID._RCC1000_RCSERVO_110:
			this.data.maxPulseWidthLimit = 4000;
			this.data.maxPulseWidth = 2450;
			this.data.maxPosition = 180;
			this.data.maxVelocityLimit = 781250;
			this.data.maxAcceleration = 3906250;
			this.data.minPulseWidthLimit = 0.063;
			this.data.minPulseWidth = 550;
			this.data.minPosition = 0;
			this.data.minVelocityLimit = 0;
			this.data.minAcceleration = 156.25;
			this.data.velocityLimit = 1900;
			this.data.acceleration = 3800;
			this.data.engaged = 0;
			this.data.speedRampingState = 1;
			this.data.voltage = Enum.RCServoVoltage.VOLTS_5_0;
			this.data.maxFailsafeTime = 30000;
			this.data.minFailsafeTime = 500;
			break;
		case DeviceChannelUID._RCC1000_RCSERVO_200:
			this.data.maxPulseWidthLimit = 4000;
			this.data.maxPulseWidth = 2450;
			this.data.maxPosition = 180;
			this.data.maxVelocityLimit = 781250;
			this.data.maxAcceleration = 3906250;
			this.data.minPulseWidthLimit = 0.063;
			this.data.minPulseWidth = 550;
			this.data.minPosition = 0;
			this.data.minVelocityLimit = 0;
			this.data.minAcceleration = 156.25;
			this.data.targetPosition = 1500;
			this.data.velocityLimit = 1900;
			this.data.acceleration = 3800;
			this.data.isMoving = 0;
			this.data.engaged = 0;
			this.data.speedRampingState = 1;
			this.data.voltage = Enum.RCServoVoltage.VOLTS_5_0;
			this.data.maxFailsafeTime = 30000;
			this.data.minFailsafeTime = 500;
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	// eslint-disable-next-line require-await
	async _setDefaults() {
		let bp;

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._RCC1000_RCSERVO_100:
		case DeviceChannelUID._RCC1000_RCSERVO_110:
			break;
		case DeviceChannelUID._RCC1000_RCSERVO_200:
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.targetPosition });
			await bp.send(this._ch, BP.SETTARGETPOSITION);
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	_hasInitialState() {


		return true;
	}

	/** @internal */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	_fireInitialEvents() {

	}

	/**
	 * When changing velocity, the RC servo motor will accelerate/decelerate at this rate.
	 * 
	 * *   The acceleration is bounded by `maxAcceleration` and `minAcceleration`.
	 * 
	 * *   Using the **default settings** this acceleration will correspond acceleration of servo arm in **degrees/s2**, for many standard RC servos.
	 * 
	 * *   `speedRampingState` controls whether or not the acceleration value is actually applied when trying to reach a target position.
	 * *   There is a practical limit on how fast your RC servo motor can accelerate. This is based on the load and physical design of the motor.
	 * *   The units for `targetPosition`, `velocityLimit`, and `acceleration` are configured by scaling the internal timing (set with `minPulseWidth` and `maxPulseWidth`) to a user specified range with `minPosition` and `maxPosition`.
	 * 
	 *   
	 * 
	 * See `targetPosition` for a deeper explanation of how the settings of your RC Servo controller interact to move your servo.
	 * @throws {@link PhidgetError}
	 */
	get acceleration() { return this.getAcceleration(); }
	/**
	 * The minimum value that `acceleration` can be set to.
	 * 
	 * *   This value depends on `minPosition`/`maxPosition` and `minPulseWidth`/`maxPulseWidth`
	 * .
	 * 
	 *   
	 * 
	 * See `targetPosition` for a deeper explanation of how the settings of your RC Servo controller interact to move your servo.
	 * @throws {@link PhidgetError}
	 */
	get minAcceleration() { return this.getMinAcceleration(); }
	/**
	 * The maximum acceleration that `acceleration` can be set to.
	 * 
	 * *   This value depends on `minPosition`/`maxPosition` and `minPulseWidth`/`maxPulseWidth`.
	 * 
	 *   
	 * 
	 * See `targetPosition` for a deeper explanation of how the settings of your RC Servo controller interact to move your servo.
	 * @throws {@link PhidgetError}
	 */
	get maxAcceleration() { return this.getMaxAcceleration(); }
	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `PositionChange` / `VelocityChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * @throws {@link PhidgetError}
	 */
	get dataInterval() { return this.getDataInterval(); }
	/**
	 * The minimum value that `dataInterval` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDataInterval() { return this.getMinDataInterval(); }
	/**
	 * The maximum value that `dataInterval` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDataInterval() { return this.getMaxDataInterval(); }
	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @throws {@link PhidgetError}
	 */
	get dataRate() { return this.getDataRate(); }
	/**
	 * The minimum value that `dataRate` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDataRate() { return this.getMinDataRate(); }
	/**
	 * The maximum value that `dataRate` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDataRate() { return this.getMaxDataRate(); }
	/**
	 * When engaged, a RC servo motor has the ability to be positioned. When disengaged, no commands are sent to the RC servo motor.
	 * 
	 * *   There is no position feedback to the controller, so the RC servo motor will immediately snap to the `targetPosition` after being engaged from a disengaged state.
	 * *   This property is useful for relaxing a servo once it has reached a given position.
	 * *   If you are concerned about tracking position accurately, you should not disengage the motor while `isMoving` is true.
	 * @throws {@link PhidgetError}
	 */
	get engaged() { return this.getEngaged(); }
	/**
	 * The minimum value that `failsafeTime` can be set to when calling `enableFailsafe()`.
	 * @throws {@link PhidgetError}
	 */
	get minFailsafeTime() { return this.getMinFailsafeTime(); }
	/**
	 * The maximum value that `failsafeTime` can be set to when calling `enableFailsafe()`.
	 * @throws {@link PhidgetError}
	 */
	get maxFailsafeTime() { return this.getMaxFailsafeTime(); }
	/**
	 * `isMoving` returns true if the RC servo motor is currently in motion.
	 * 
	 * *   The controller cannot know if the RC servo motor is physically moving. When `isMoving` is false, it simply means there are no commands in the pipeline to the RC servo motor.
	 * @throws {@link PhidgetError}
	 */
	get isMoving() { return this.getIsMoving(); }
	/**
	 * The most recent position of the RC servo motor that the controller has reported.
	 * 
	 * *   This value will always be between `minPosition` and `maxPosition`.
	 * 
	 * *   Using the **default settings** this position will correspond to the rotation of the servo arm in **degrees**, for many standard RC servos.
	 * 
	 *   
	 * 
	 * See `targetPosition` for a deeper explanation of how the settings of your RC Servo controller interact to move your servo.
	 * @throws {@link PhidgetError}
	 */
	get position() { return this.getPosition(); }
	/**
	 * The minimum position that `targetPosition` can be set to.
	 * 
	 * *   The units for `targetPosition`, `velocityLimit`, and `acceleration` are configured by scaling the internal timing (set with `minPulseWidth` and `maxPulseWidth`) to a user specified range with `minPosition` and `maxPosition`.
	 * 
	 *   
	 * 
	 * See `targetPosition` for a deeper explanation of how the settings of your RC Servo controller interact to move your servo.
	 * @throws {@link PhidgetError}
	 */
	get minPosition() { return this.getMinPosition(); }
	set minPosition(minPosition: number) { this.setMinPosition(minPosition); }
	/**
	 * The maximum position `targetPosition` can be set to.
	 * 
	 * *   The units for `targetPosition`, `velocityLimit`, and `acceleration` are configured by scaling the internal timing (set with `minPulseWidth` and `maxPulseWidth`) to a user specified range with `minPosition` and `maxPosition`.
	 * 
	 *   
	 * 
	 * See `targetPosition` for a deeper explanation of how the settings of your RC Servo controller interact to move your servo.
	 * @throws {@link PhidgetError}
	 */
	get maxPosition() { return this.getMaxPosition(); }
	set maxPosition(maxPosition: number) { this.setMaxPosition(maxPosition); }
	/**
	 * The `minPulseWidth` represents the minimum pulse width that your RC servo motor specifies.
	 * 
	 * *   This value can be found in the data sheet of most RC servo motors.
	 * *   The units for `targetPosition`, `velocityLimit`, and `acceleration` are configured by scaling the internal timing (set with `minPulseWidth` and `maxPulseWidth`) to a user specified range with `minPosition` and `maxPosition`.
	 * 
	 *   
	 * 
	 * See `targetPosition` for a deeper explanation of how the settings of your RC Servo controller interact to move your servo.
	 * @throws {@link PhidgetError}
	 */
	get minPulseWidth() { return this.getMinPulseWidth(); }
	/**
	 * The `maxPulseWidth` represents the maximum pulse width that your RC servo motor specifies.
	 * 
	 * *   This value can be found in the data sheet of most RC servo motors.
	 * *   The units for `targetPosition`, `velocityLimit`, and `acceleration` are configured by scaling the internal timing (set with `minPulseWidth` and `maxPulseWidth`) to a user specified range with `minPosition` and `maxPosition`.
	 * 
	 *   
	 * 
	 * See `targetPosition` for a deeper explanation of how the settings of your RC Servo controller interact to move your servo.
	 * @throws {@link PhidgetError}
	 */
	get maxPulseWidth() { return this.getMaxPulseWidth(); }
	/**
	 * The minimum pulse width that `minPulseWidth` can be set to.  
	 * 
	 * See `targetPosition` for a deeper explanation of how the settings of your RC Servo controller interact to move your servo.
	 * @throws {@link PhidgetError}
	 */
	get minPulseWidthLimit() { return this.getMinPulseWidthLimit(); }
	/**
	 * The maximum pulse width that `maxPulseWidth` can be set to.  
	 * 
	 * See `targetPosition` for a deeper explanation of how the settings of your RC Servo controller interact to move your servo.
	 * @throws {@link PhidgetError}
	 */
	get maxPulseWidthLimit() { return this.getMaxPulseWidthLimit(); }
	/**
	 * When speed ramping state is enabled, the controller will take the `acceleration` and `velocityLimit` properties into account when moving the RC servo motor, usually resulting in smooth motion. If speed ramping state is not enabled, the controller will simply set the RC servo motor to the requested position.
	 * @throws {@link PhidgetError}
	 */
	get speedRampingState() { return this.getSpeedRampingState(); }
	/**
	 * If the RC servo motor is configured and `targetPosition` is set, the controller will continuously try to reach targeted position.
	 * 
	 * *   The target position is bounded by `minPosition` and `maxPosition`.
	 * 
	 * *   Using the **default settings** this position will correspond to the rotation of the servo arm in **degrees**, for many standard RC servos.
	 * 
	 * *   If the RC servo motor is not engaged, then the position cannot be read.
	 * *   The position can still be set while the RC servo motor is not engaged. Once engaged, the RC servo motor will snap to position, assuming it is not there already.
	 * *   The units for `targetPosition`, `velocityLimit`, and `acceleration` are configured by scaling the internal timing (set with `minPulseWidth` and `maxPulseWidth`) to a user specified range with `minPosition` and `maxPosition`.
	 * 
	 * ### Position and Pulse Width
	 * 
	 * *   An RC servo motor's position is controlled using a type of **Pulse Width Modulation**, sending voltage pulses of a given time span, or **Pulse Width** to the servo.
	 * *   The servo translates the **Pulse Width** of the control signal to a corresponding position of the servo arm.
	 * *   Knowing this, a servo's range of motion can be thought of in terms of a `minPulseWidth` and a `maxPulseWidth` corresponding to range of pulse widths that produce the servo arm's full **range of movement**.
	 * 
	 * *   In Phidget22, you can adjust the `minPulseWidth` and `maxPulseWidth` stored by the library to match the desired **range of movement** you expect from your servo.
	 * 
	 * *   Since directly setting the timing of RC servo pulse widths is not very intuitive for most purpses, we map these pulse widths to a user-defied _**Minimum**_ and _**Maximum**_ **Position**.This allows you to define the servo's position in terms best suited to your application, such as degrees, fractions of a rotation, or even some measure of speed for a continuous-rotation servo.
	 * *   By setting the servo's `targetPosition` to `maxPosition`, the controller will send pulses of `maxPulseWidth` to the servo.
	 *     *   Similarly, `minPosition` will send pulses of `minPulseWidth` to the servo
	 *     *   `maxPosition` can be set smaller than `minPosition` to invert movement of the servo, if it helps your application.
	 * *   Setting a `targetPosition` will transate the position between `minPosition` and `maxPosition` to a corresponding **Pulse Width** between `minPulseWidth` and `maxPulseWidth`, in turn sending the servo arm to the desired position.
	 * *   Setting `velocityLimit` and `acceleration` for your servo will limit the rate of change of the servo's position in terms of one _**UserUnit**_ per second (or /s2). Here, a _**UserUnit**_ is whatever distance is maked by the change of the `targetPosition` by **1.0**
	 * 
	 * ### Adjusting the Servo's Limits
	 * 
	 * *   **To tune your program to a specific servo:**
	 * 
	 * 1.  First adjust the servo's range of motion by setting the `maxPulseWidth` and `minPulseWidth`. You can use the default values for these _(or the ones on your servo's datasheet)_ as a starting point.
	 * 2.  Send the servo to `maxPosition` and `minPosition` to check the results. Repeat steps 1 and 2 as nessesarry.
	 * 3.  Set the `maxPosition` and `minPosition` to match whatever numbers you find best suited to your application.
	 * @throws {@link PhidgetError}
	 */
	get targetPosition() { return this.getTargetPosition(); }
	/**
	 * The `torque` is a ratio of the maximum available torque.
	 * 
	 * *   The torque is bounded by `minTorque` and `maxTorque`
	 * *   Increasing the torque will increase the speed and power consumption of the RC servo motor.
	 * @throws {@link PhidgetError}
	 */
	get torque() { return this.getTorque(); }
	/**
	 * The minimum value that `torque` can be set to.
	 * 
	 * *   `torque` is a ratio of the maximum available torque, therefore the minimum torque is a unitless constant.
	 * @throws {@link PhidgetError}
	 */
	get minTorque() { return this.getMinTorque(); }
	/**
	 * The maximum value that `torque` can be set to.
	 * 
	 * *   `torque` is a ratio of the maximum available torque, therefore the minimum torque is a unitless constant.
	 * @throws {@link PhidgetError}
	 */
	get maxTorque() { return this.getMaxTorque(); }
	/**
	 * The velocity that the RC servo motor is being driven at.
	 * 
	 * *   A negative value means the RC servo motor is moving towards a lower position.
	 * *   The velocity range of the RC servo motor will be from -`velocityLimit` to +`velocityLimit`, depending on direction.
	 * *   This is not the actual physical velocity of the RC servo motor.
	 * 
	 *   
	 * 
	 * See `targetPosition` for a deeper explanation of how the settings of your RC Servo controller interact to move your servo.
	 * @throws {@link PhidgetError}
	 */
	get velocity() { return this.getVelocity(); }
	/**
	 * When moving, the RC servo motor velocity will be limited by this value.*   The velocity limit is bounded by `minVelocityLimit` and `maxVelocityLimit`.
	 * 
	 * *   Using the **default settings** this velocity will correspond to the maximum speed of rotation of servo arm in **degrees/s**, for many standard RC servos.
	 * 
	 * *   `speedRampingState` controls whether or not the velocity limit value is actually applied when trying to reach a target position.
	 * *   The velocity range of the RC servo motor will be from -`velocityLimit` to +`velocityLimit`, depending on direction.
	 * *   Note that when this value is set to 0, the RC servo motor will not move.
	 * *   There is a practical limit on how fast your servo can rotate, based on the physical design of the motor.
	 * *   The units for `targetPosition`, `velocityLimit`, and `acceleration` are configured by scaling the internal timing (set with `minPulseWidth` and `maxPulseWidth`) to a user specified range with `minPosition` and `maxPosition`.
	 *   
	 * 
	 * See `targetPosition` for a deeper explanation of how the settings of your RC Servo controller interact to move your servo.
	 * @throws {@link PhidgetError}
	 */
	get velocityLimit() { return this.getVelocityLimit(); }
	/**
	 * The minimum velocity `velocityLimit` can be set to.  
	 * 
	 * See `targetPosition` for a deeper explanation of how the settings of your RC Servo controller interact to move your servo.
	 * @throws {@link PhidgetError}
	 */
	get minVelocityLimit() { return this.getMinVelocityLimit(); }
	/**
	 * The maximum velocity `velocityLimit` can be set to. This value depends on `minPosition`/`maxPosition` and `minPulseWidth`/`maxPulseWidth`.  
	 * 
	 * See `targetPosition` for a deeper explanation of how the settings of your RC Servo controller interact to move your servo.
	 * @throws {@link PhidgetError}
	 */
	get maxVelocityLimit() { return this.getMaxVelocityLimit(); }
	/**
	 * The supply voltage for the RC servo motor.
	 * 
	 * *   If your controller supports multiple RC servo motors, every motor will have the same supply voltage. It is not possible to set individual supply voltages.
	 * @throws {@link PhidgetError}
	 */
	get voltage() { return this.getVoltage(); }

	/**
	 * When changing velocity, the RC servo motor will accelerate/decelerate at this rate.
	 * 
	 * *   The acceleration is bounded by `maxAcceleration` and `minAcceleration`.
	 * 
	 * *   Using the **default settings** this acceleration will correspond acceleration of servo arm in **degrees/s2**, for many standard RC servos.
	 * 
	 * *   `speedRampingState` controls whether or not the acceleration value is actually applied when trying to reach a target position.
	 * *   There is a practical limit on how fast your RC servo motor can accelerate. This is based on the load and physical design of the motor.
	 * *   The units for `targetPosition`, `velocityLimit`, and `acceleration` are configured by scaling the internal timing (set with `minPulseWidth` and `maxPulseWidth`) to a user specified range with `minPosition` and `maxPosition`.
	 * 
	 *   
	 * 
	 * See `targetPosition` for a deeper explanation of how the settings of your RC Servo controller interact to move your servo.
	 * @returns The acceleration value
	 * @throws {@link PhidgetError}
	 */
	abstract getAcceleration(): number;
	/**
	 * When changing velocity, the RC servo motor will accelerate/decelerate at this rate.
	 * 
	 * *   The acceleration is bounded by `maxAcceleration` and `minAcceleration`.
	 * 
	 * *   Using the **default settings** this acceleration will correspond acceleration of servo arm in **degrees/s2**, for many standard RC servos.
	 * 
	 * *   `speedRampingState` controls whether or not the acceleration value is actually applied when trying to reach a target position.
	 * *   There is a practical limit on how fast your RC servo motor can accelerate. This is based on the load and physical design of the motor.
	 * *   The units for `targetPosition`, `velocityLimit`, and `acceleration` are configured by scaling the internal timing (set with `minPulseWidth` and `maxPulseWidth`) to a user specified range with `minPosition` and `maxPosition`.
	 * 
	 *   
	 * 
	 * See `targetPosition` for a deeper explanation of how the settings of your RC Servo controller interact to move your servo.
	 * @throws {@link PhidgetError}
	 * @param acceleration - The acceleration value
	 */
	abstract setAcceleration(acceleration: number): Promise<void>;
	/**
	 * The minimum value that `acceleration` can be set to.
	 * 
	 * *   This value depends on `minPosition`/`maxPosition` and `minPulseWidth`/`maxPulseWidth`
	 * .
	 * 
	 *   
	 * 
	 * See `targetPosition` for a deeper explanation of how the settings of your RC Servo controller interact to move your servo.
	 * @returns The acceleration value
	 * @throws {@link PhidgetError}
	 */
	abstract getMinAcceleration(): number;
	/**
	 * The maximum acceleration that `acceleration` can be set to.
	 * 
	 * *   This value depends on `minPosition`/`maxPosition` and `minPulseWidth`/`maxPulseWidth`.
	 * 
	 *   
	 * 
	 * See `targetPosition` for a deeper explanation of how the settings of your RC Servo controller interact to move your servo.
	 * @returns The acceleration value
	 * @throws {@link PhidgetError}
	 */
	abstract getMaxAcceleration(): number;
	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `PositionChange` / `VelocityChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getDataInterval(): number {
		this._assertOpen();

		if (this.data.dataInterval === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return this.data.dataInterval;
	}

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `PositionChange` / `VelocityChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * @throws {@link PhidgetError}
	 * @param dataInterval - The data interval value
	 */
	async setDataInterval(dataInterval: number): Promise<void> {
		this._assertOpen();

		if (dataInterval < this.data.minDataInterval || dataInterval > this.data.maxDataInterval)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDataInterval + " - " + this.data.maxDataInterval + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: dataInterval });
		await bp.send(this._ch, BP.SETDATAINTERVAL);
	}

	/**
	 * The minimum value that `dataInterval` can be set to.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getMinDataInterval(): number {
		this._assertOpen();

		if (this.data.minDataInterval === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDataInterval);
	}

	/**
	 * The maximum value that `dataInterval` can be set to.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getMaxDataInterval(): number {
		this._assertOpen();

		if (this.data.maxDataInterval === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDataInterval);
	}

	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @returns The data rate for the channel
	 * @throws {@link PhidgetError}
	 */
	getDataRate(): number {
		this._assertOpen();

		if (this.data.dataInterval === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (1000.0 / this.data.dataInterval);
	}

	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @throws {@link PhidgetError}
	 * @param dataRate - The data rate for the channel
	 */
	async setDataRate(dataRate: number): Promise<void> {
		this._assertOpen();

		if (dataRate < this.data.minDataRate || dataRate > this.data.maxDataRate)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDataRate + " - " + this.data.maxDataRate + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: Math.round(1000.0 / dataRate) });
		bp.set({ name: "1", type: "g", value: (1000.0 / dataRate) });
		await bp.send(this._ch, BP.SETDATAINTERVAL);
	}

	/**
	 * The minimum value that `dataRate` can be set to.
	 * @returns The data rate value
	 * @throws {@link PhidgetError}
	 */
	getMinDataRate(): number {
		this._assertOpen();

		if (this.data.minDataRate === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDataRate);
	}

	/**
	 * The maximum value that `dataRate` can be set to.
	 * @returns The data rate value
	 * @throws {@link PhidgetError}
	 */
	getMaxDataRate(): number {
		this._assertOpen();

		if (this.data.maxDataRate === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDataRate);
	}

	/**
	 * When engaged, a RC servo motor has the ability to be positioned. When disengaged, no commands are sent to the RC servo motor.
	 * 
	 * *   There is no position feedback to the controller, so the RC servo motor will immediately snap to the `targetPosition` after being engaged from a disengaged state.
	 * *   This property is useful for relaxing a servo once it has reached a given position.
	 * *   If you are concerned about tracking position accurately, you should not disengage the motor while `isMoving` is true.
	 * @returns The engaged value
	 * @throws {@link PhidgetError}
	 */
	getEngaged(): boolean {
		this._assertOpen();

		if (this.data.engaged === PUNK.BOOL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (!!this.data.engaged);
	}

	/**
	 * When engaged, a RC servo motor has the ability to be positioned. When disengaged, no commands are sent to the RC servo motor.
	 * 
	 * *   There is no position feedback to the controller, so the RC servo motor will immediately snap to the `targetPosition` after being engaged from a disengaged state.
	 * *   This property is useful for relaxing a servo once it has reached a given position.
	 * *   If you are concerned about tracking position accurately, you should not disengage the motor while `isMoving` is true.
	 * @throws {@link PhidgetError}
	 * @param engaged - The engaged value
	 */
	async setEngaged(engaged: boolean): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (engaged !== false && engaged !== true)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be a boolean.");

		bp.set({ name: "0", type: "d", value: (engaged ? 1 : 0) });
		await bp.send(this._ch, BP.SETENGAGED);
	}

	/**
	 * Enables the **failsafe** feature for the channel, with a given **failsafe time**.
	 * 
	 * The **failsafe** feature is intended for use in applications where it is important for the channel to enter a known _safe state_ if the program controlling it locks up or crashes. If you do not enable the failsafe feature, the channel will carry out whatever instructions it was last given until it is explicitly told to stop.
	 * 
	 * Enabling the failsafe feature starts a recurring **failsafe timer** for the channel. Once the failsafe timer is enabled, it must be reset within the specified time or the channel will enter a **failsafe state**. The failsafe timer may be reset by sending any valid command to the device\*. Resetting the failsafe timer will reload the timer with the specified _failsafe time_, starting when the message to reset the timer is received by the Phidget.
	 * 
	 * _\*(**get** requests do not typically send commands and won't reset the failsafe timer)_
	 * 
	 * For example: if the failsafe is enabled with a **failsafe time** of 1000ms, you will have 1000ms to reset the failsafe timer. Every time the failsafe timer is reset, you will have 1000ms from that time to reset the failsafe again.
	 * 
	 * If the failsafe timer is not reset before it runs out, the channel will enter a **failsafe state**. For RC Servo channels, this will disengage the servo. Once the channel enters the **failsafe state**, it will reject any further input until the channel is reopened.
	 * 
	 * To prevent the channel from falsely entering the failsafe state, we recommend resetting the failsafe timer as frequently as is practical for your application. A good rule of thumb is to not let more than a third of the failsafe time pass before resetting the timer.
	 * 
	 * Once the failsafe timer has been set, it cannot be disabled by any means other than closing and reopening the channel.
	 * @throws {@link PhidgetError}
	 * @param failsafeTime - Failsafe timeout in milliseconds
	 */
	async enableFailsafe(failsafeTime: number): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: failsafeTime });
		await bp.send(this._ch, BP.SETFAILSAFETIME);
	}

	/**
	 * The minimum value that `failsafeTime` can be set to when calling `enableFailsafe()`.
	 * @returns The failsafe time
	 * @throws {@link PhidgetError}
	 */
	getMinFailsafeTime(): number {
		this._assertOpen();

		if (this.data.minFailsafeTime === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minFailsafeTime);
	}

	/**
	 * The maximum value that `failsafeTime` can be set to when calling `enableFailsafe()`.
	 * @returns The failsafe time
	 * @throws {@link PhidgetError}
	 */
	getMaxFailsafeTime(): number {
		this._assertOpen();

		if (this.data.maxFailsafeTime === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxFailsafeTime);
	}

	/**
	 * `isMoving` returns true if the RC servo motor is currently in motion.
	 * 
	 * *   The controller cannot know if the RC servo motor is physically moving. When `isMoving` is false, it simply means there are no commands in the pipeline to the RC servo motor.
	 * @returns The moving value
	 * @throws {@link PhidgetError}
	 */
	getIsMoving(): boolean {
		this._assertOpen();

		if (this.data.isMoving === PUNK.BOOL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (!!this.data.isMoving);
	}

	/**
	 * The most recent position of the RC servo motor that the controller has reported.
	 * 
	 * *   This value will always be between `minPosition` and `maxPosition`.
	 * 
	 * *   Using the **default settings** this position will correspond to the rotation of the servo arm in **degrees**, for many standard RC servos.
	 * 
	 *   
	 * 
	 * See `targetPosition` for a deeper explanation of how the settings of your RC Servo controller interact to move your servo.
	 * @returns The position value
	 * @throws {@link PhidgetError}
	 */
	abstract getPosition(): number;
	/**
	 * The minimum position that `targetPosition` can be set to.
	 * 
	 * *   The units for `targetPosition`, `velocityLimit`, and `acceleration` are configured by scaling the internal timing (set with `minPulseWidth` and `maxPulseWidth`) to a user specified range with `minPosition` and `maxPosition`.
	 * 
	 *   
	 * 
	 * See `targetPosition` for a deeper explanation of how the settings of your RC Servo controller interact to move your servo.
	 * @throws {@link PhidgetError}
	 * @param minPosition - The position value
	 */
	abstract setMinPosition(minPosition: number): void;
	/**
	 * The minimum position that `targetPosition` can be set to.
	 * 
	 * *   The units for `targetPosition`, `velocityLimit`, and `acceleration` are configured by scaling the internal timing (set with `minPulseWidth` and `maxPulseWidth`) to a user specified range with `minPosition` and `maxPosition`.
	 * 
	 *   
	 * 
	 * See `targetPosition` for a deeper explanation of how the settings of your RC Servo controller interact to move your servo.
	 * @returns The position value
	 * @throws {@link PhidgetError}
	 */
	getMinPosition(): number {
		this._assertOpen();

		if (this.data.minPosition === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minPosition);
	}

	/**
	 * The maximum position `targetPosition` can be set to.
	 * 
	 * *   The units for `targetPosition`, `velocityLimit`, and `acceleration` are configured by scaling the internal timing (set with `minPulseWidth` and `maxPulseWidth`) to a user specified range with `minPosition` and `maxPosition`.
	 * 
	 *   
	 * 
	 * See `targetPosition` for a deeper explanation of how the settings of your RC Servo controller interact to move your servo.
	 * @throws {@link PhidgetError}
	 * @param maxPosition - The position value
	 */
	abstract setMaxPosition(maxPosition: number): void;
	/**
	 * The maximum position `targetPosition` can be set to.
	 * 
	 * *   The units for `targetPosition`, `velocityLimit`, and `acceleration` are configured by scaling the internal timing (set with `minPulseWidth` and `maxPulseWidth`) to a user specified range with `minPosition` and `maxPosition`.
	 * 
	 *   
	 * 
	 * See `targetPosition` for a deeper explanation of how the settings of your RC Servo controller interact to move your servo.
	 * @returns The position value
	 * @throws {@link PhidgetError}
	 */
	getMaxPosition(): number {
		this._assertOpen();

		if (this.data.maxPosition === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxPosition);
	}

	/**
	 * The `minPulseWidth` represents the minimum pulse width that your RC servo motor specifies.
	 * 
	 * *   This value can be found in the data sheet of most RC servo motors.
	 * *   The units for `targetPosition`, `velocityLimit`, and `acceleration` are configured by scaling the internal timing (set with `minPulseWidth` and `maxPulseWidth`) to a user specified range with `minPosition` and `maxPosition`.
	 * 
	 *   
	 * 
	 * See `targetPosition` for a deeper explanation of how the settings of your RC Servo controller interact to move your servo.
	 * @throws {@link PhidgetError}
	 * @param minPulseWidth - The pulse width value
	 */
	async setMinPulseWidth(minPulseWidth: number): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: minPulseWidth });
		await bp.send(this._ch, BP.SETMINPULSEWIDTH);
	}

	/**
	 * The `minPulseWidth` represents the minimum pulse width that your RC servo motor specifies.
	 * 
	 * *   This value can be found in the data sheet of most RC servo motors.
	 * *   The units for `targetPosition`, `velocityLimit`, and `acceleration` are configured by scaling the internal timing (set with `minPulseWidth` and `maxPulseWidth`) to a user specified range with `minPosition` and `maxPosition`.
	 * 
	 *   
	 * 
	 * See `targetPosition` for a deeper explanation of how the settings of your RC Servo controller interact to move your servo.
	 * @returns The pulse width value
	 * @throws {@link PhidgetError}
	 */
	getMinPulseWidth(): number {
		this._assertOpen();

		if (this.data.minPulseWidth === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minPulseWidth);
	}

	/**
	 * The `maxPulseWidth` represents the maximum pulse width that your RC servo motor specifies.
	 * 
	 * *   This value can be found in the data sheet of most RC servo motors.
	 * *   The units for `targetPosition`, `velocityLimit`, and `acceleration` are configured by scaling the internal timing (set with `minPulseWidth` and `maxPulseWidth`) to a user specified range with `minPosition` and `maxPosition`.
	 * 
	 *   
	 * 
	 * See `targetPosition` for a deeper explanation of how the settings of your RC Servo controller interact to move your servo.
	 * @throws {@link PhidgetError}
	 * @param maxPulseWidth - The pulse width value
	 */
	async setMaxPulseWidth(maxPulseWidth: number): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: maxPulseWidth });
		await bp.send(this._ch, BP.SETMAXPULSEWIDTH);
	}

	/**
	 * The `maxPulseWidth` represents the maximum pulse width that your RC servo motor specifies.
	 * 
	 * *   This value can be found in the data sheet of most RC servo motors.
	 * *   The units for `targetPosition`, `velocityLimit`, and `acceleration` are configured by scaling the internal timing (set with `minPulseWidth` and `maxPulseWidth`) to a user specified range with `minPosition` and `maxPosition`.
	 * 
	 *   
	 * 
	 * See `targetPosition` for a deeper explanation of how the settings of your RC Servo controller interact to move your servo.
	 * @returns The pulse width value
	 * @throws {@link PhidgetError}
	 */
	getMaxPulseWidth(): number {
		this._assertOpen();

		if (this.data.maxPulseWidth === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxPulseWidth);
	}

	/**
	 * The minimum pulse width that `minPulseWidth` can be set to.  
	 * 
	 * See `targetPosition` for a deeper explanation of how the settings of your RC Servo controller interact to move your servo.
	 * @returns The pulse width value
	 * @throws {@link PhidgetError}
	 */
	getMinPulseWidthLimit(): number {
		this._assertOpen();

		if (this.data.minPulseWidthLimit === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minPulseWidthLimit);
	}

	/**
	 * The maximum pulse width that `maxPulseWidth` can be set to.  
	 * 
	 * See `targetPosition` for a deeper explanation of how the settings of your RC Servo controller interact to move your servo.
	 * @returns The pulse width value
	 * @throws {@link PhidgetError}
	 */
	getMaxPulseWidthLimit(): number {
		this._assertOpen();

		if (this.data.maxPulseWidthLimit === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxPulseWidthLimit);
	}

	/**
	 * Resets the failsafe timer, if one has been set. See `enableFailsafe()` for details.
	 * 
	 * This function will fail if no failsafe timer has been set for the channel.
	 * @throws {@link PhidgetError}
	 */
	async resetFailsafe(): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		await bp.send(this._ch, BP.FAILSAFERESET);
	}

	/**
	 * When speed ramping state is enabled, the controller will take the `acceleration` and `velocityLimit` properties into account when moving the RC servo motor, usually resulting in smooth motion. If speed ramping state is not enabled, the controller will simply set the RC servo motor to the requested position.
	 * @returns The speed ramping state value
	 * @throws {@link PhidgetError}
	 */
	getSpeedRampingState(): boolean {
		this._assertOpen();

		if (this.data.speedRampingState === PUNK.BOOL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (!!this.data.speedRampingState);
	}

	/**
	 * When speed ramping state is enabled, the controller will take the `acceleration` and `velocityLimit` properties into account when moving the RC servo motor, usually resulting in smooth motion. If speed ramping state is not enabled, the controller will simply set the RC servo motor to the requested position.
	 * @throws {@link PhidgetError}
	 * @param speedRampingState - The speed ramping state value
	 */
	async setSpeedRampingState(speedRampingState: boolean): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (speedRampingState !== false && speedRampingState !== true)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be a boolean.");

		bp.set({ name: "0", type: "d", value: (speedRampingState ? 1 : 0) });
		await bp.send(this._ch, BP.SETSPEEDRAMPINGSTATE);
	}

	/**
	 * If the RC servo motor is configured and `targetPosition` is set, the controller will continuously try to reach targeted position.
	 * 
	 * *   The target position is bounded by `minPosition` and `maxPosition`.
	 * 
	 * *   Using the **default settings** this position will correspond to the rotation of the servo arm in **degrees**, for many standard RC servos.
	 * 
	 * *   If the RC servo motor is not engaged, then the position cannot be read.
	 * *   The position can still be set while the RC servo motor is not engaged. Once engaged, the RC servo motor will snap to position, assuming it is not there already.
	 * *   The units for `targetPosition`, `velocityLimit`, and `acceleration` are configured by scaling the internal timing (set with `minPulseWidth` and `maxPulseWidth`) to a user specified range with `minPosition` and `maxPosition`.
	 * 
	 * ### Position and Pulse Width
	 * 
	 * *   An RC servo motor's position is controlled using a type of **Pulse Width Modulation**, sending voltage pulses of a given time span, or **Pulse Width** to the servo.
	 * *   The servo translates the **Pulse Width** of the control signal to a corresponding position of the servo arm.
	 * *   Knowing this, a servo's range of motion can be thought of in terms of a `minPulseWidth` and a `maxPulseWidth` corresponding to range of pulse widths that produce the servo arm's full **range of movement**.
	 * 
	 * *   In Phidget22, you can adjust the `minPulseWidth` and `maxPulseWidth` stored by the library to match the desired **range of movement** you expect from your servo.
	 * 
	 * *   Since directly setting the timing of RC servo pulse widths is not very intuitive for most purpses, we map these pulse widths to a user-defied _**Minimum**_ and _**Maximum**_ **Position**.This allows you to define the servo's position in terms best suited to your application, such as degrees, fractions of a rotation, or even some measure of speed for a continuous-rotation servo.
	 * *   By setting the servo's `targetPosition` to `maxPosition`, the controller will send pulses of `maxPulseWidth` to the servo.
	 *     *   Similarly, `minPosition` will send pulses of `minPulseWidth` to the servo
	 *     *   `maxPosition` can be set smaller than `minPosition` to invert movement of the servo, if it helps your application.
	 * *   Setting a `targetPosition` will transate the position between `minPosition` and `maxPosition` to a corresponding **Pulse Width** between `minPulseWidth` and `maxPulseWidth`, in turn sending the servo arm to the desired position.
	 * *   Setting `velocityLimit` and `acceleration` for your servo will limit the rate of change of the servo's position in terms of one _**UserUnit**_ per second (or /s2). Here, a _**UserUnit**_ is whatever distance is maked by the change of the `targetPosition` by **1.0**
	 * 
	 * ### Adjusting the Servo's Limits
	 * 
	 * *   **To tune your program to a specific servo:**
	 * 
	 * 1.  First adjust the servo's range of motion by setting the `maxPulseWidth` and `minPulseWidth`. You can use the default values for these _(or the ones on your servo's datasheet)_ as a starting point.
	 * 2.  Send the servo to `maxPosition` and `minPosition` to check the results. Repeat steps 1 and 2 as nessesarry.
	 * 3.  Set the `maxPosition` and `minPosition` to match whatever numbers you find best suited to your application.
	 * @returns The position value
	 * @throws {@link PhidgetError}
	 */
	abstract getTargetPosition(): number;
	/**
	 * If the RC servo motor is configured and `targetPosition` is set, the controller will continuously try to reach targeted position.
	 * 
	 * *   The target position is bounded by `minPosition` and `maxPosition`.
	 * 
	 * *   Using the **default settings** this position will correspond to the rotation of the servo arm in **degrees**, for many standard RC servos.
	 * 
	 * *   If the RC servo motor is not engaged, then the position cannot be read.
	 * *   The position can still be set while the RC servo motor is not engaged. Once engaged, the RC servo motor will snap to position, assuming it is not there already.
	 * *   The units for `targetPosition`, `velocityLimit`, and `acceleration` are configured by scaling the internal timing (set with `minPulseWidth` and `maxPulseWidth`) to a user specified range with `minPosition` and `maxPosition`.
	 * 
	 * ### Position and Pulse Width
	 * 
	 * *   An RC servo motor's position is controlled using a type of **Pulse Width Modulation**, sending voltage pulses of a given time span, or **Pulse Width** to the servo.
	 * *   The servo translates the **Pulse Width** of the control signal to a corresponding position of the servo arm.
	 * *   Knowing this, a servo's range of motion can be thought of in terms of a `minPulseWidth` and a `maxPulseWidth` corresponding to range of pulse widths that produce the servo arm's full **range of movement**.
	 * 
	 * *   In Phidget22, you can adjust the `minPulseWidth` and `maxPulseWidth` stored by the library to match the desired **range of movement** you expect from your servo.
	 * 
	 * *   Since directly setting the timing of RC servo pulse widths is not very intuitive for most purpses, we map these pulse widths to a user-defied _**Minimum**_ and _**Maximum**_ **Position**.This allows you to define the servo's position in terms best suited to your application, such as degrees, fractions of a rotation, or even some measure of speed for a continuous-rotation servo.
	 * *   By setting the servo's `targetPosition` to `maxPosition`, the controller will send pulses of `maxPulseWidth` to the servo.
	 *     *   Similarly, `minPosition` will send pulses of `minPulseWidth` to the servo
	 *     *   `maxPosition` can be set smaller than `minPosition` to invert movement of the servo, if it helps your application.
	 * *   Setting a `targetPosition` will transate the position between `minPosition` and `maxPosition` to a corresponding **Pulse Width** between `minPulseWidth` and `maxPulseWidth`, in turn sending the servo arm to the desired position.
	 * *   Setting `velocityLimit` and `acceleration` for your servo will limit the rate of change of the servo's position in terms of one _**UserUnit**_ per second (or /s2). Here, a _**UserUnit**_ is whatever distance is maked by the change of the `targetPosition` by **1.0**
	 * 
	 * ### Adjusting the Servo's Limits
	 * 
	 * *   **To tune your program to a specific servo:**
	 * 
	 * 1.  First adjust the servo's range of motion by setting the `maxPulseWidth` and `minPulseWidth`. You can use the default values for these _(or the ones on your servo's datasheet)_ as a starting point.
	 * 2.  Send the servo to `maxPosition` and `minPosition` to check the results. Repeat steps 1 and 2 as nessesarry.
	 * 3.  Set the `maxPosition` and `minPosition` to match whatever numbers you find best suited to your application.
	 * @throws {@link PhidgetError}
	 * @param targetPosition - The position value
	 */
	abstract setTargetPosition(targetPosition: number): Promise<void>;
	/**
	 * The `torque` is a ratio of the maximum available torque.
	 * 
	 * *   The torque is bounded by `minTorque` and `maxTorque`
	 * *   Increasing the torque will increase the speed and power consumption of the RC servo motor.
	 * @returns The torque value.
	 * @throws {@link PhidgetError}
	 */
	getTorque(): number {
		this._assertOpen();

		if (this.data.torque === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.torque);
	}

	/**
	 * The `torque` is a ratio of the maximum available torque.
	 * 
	 * *   The torque is bounded by `minTorque` and `maxTorque`
	 * *   Increasing the torque will increase the speed and power consumption of the RC servo motor.
	 * @throws {@link PhidgetError}
	 * @param torque - The torque value.
	 */
	async setTorque(torque: number): Promise<void> {
		this._assertOpen();

		if (torque < this.data.minTorque || torque > this.data.maxTorque)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minTorque + " - " + this.data.maxTorque + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: torque });
		await bp.send(this._ch, BP.SETDUTYCYCLE);
	}

	/**
	 * The minimum value that `torque` can be set to.
	 * 
	 * *   `torque` is a ratio of the maximum available torque, therefore the minimum torque is a unitless constant.
	 * @returns The torque value
	 * @throws {@link PhidgetError}
	 */
	getMinTorque(): number {
		this._assertOpen();

		if (this.data.minTorque === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minTorque);
	}

	/**
	 * The maximum value that `torque` can be set to.
	 * 
	 * *   `torque` is a ratio of the maximum available torque, therefore the minimum torque is a unitless constant.
	 * @returns The torque value
	 * @throws {@link PhidgetError}
	 */
	getMaxTorque(): number {
		this._assertOpen();

		if (this.data.maxTorque === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxTorque);
	}

	/**
	 * The velocity that the RC servo motor is being driven at.
	 * 
	 * *   A negative value means the RC servo motor is moving towards a lower position.
	 * *   The velocity range of the RC servo motor will be from -`velocityLimit` to +`velocityLimit`, depending on direction.
	 * *   This is not the actual physical velocity of the RC servo motor.
	 * 
	 *   
	 * 
	 * See `targetPosition` for a deeper explanation of how the settings of your RC Servo controller interact to move your servo.
	 * @returns The velocity value
	 * @throws {@link PhidgetError}
	 */
	abstract getVelocity(): number;
	/**
	 * When moving, the RC servo motor velocity will be limited by this value.*   The velocity limit is bounded by `minVelocityLimit` and `maxVelocityLimit`.
	 * 
	 * *   Using the **default settings** this velocity will correspond to the maximum speed of rotation of servo arm in **degrees/s**, for many standard RC servos.
	 * 
	 * *   `speedRampingState` controls whether or not the velocity limit value is actually applied when trying to reach a target position.
	 * *   The velocity range of the RC servo motor will be from -`velocityLimit` to +`velocityLimit`, depending on direction.
	 * *   Note that when this value is set to 0, the RC servo motor will not move.
	 * *   There is a practical limit on how fast your servo can rotate, based on the physical design of the motor.
	 * *   The units for `targetPosition`, `velocityLimit`, and `acceleration` are configured by scaling the internal timing (set with `minPulseWidth` and `maxPulseWidth`) to a user specified range with `minPosition` and `maxPosition`.
	 *   
	 * 
	 * See `targetPosition` for a deeper explanation of how the settings of your RC Servo controller interact to move your servo.
	 * @returns The velocity value
	 * @throws {@link PhidgetError}
	 */
	abstract getVelocityLimit(): number;
	/**
	 * When moving, the RC servo motor velocity will be limited by this value.*   The velocity limit is bounded by `minVelocityLimit` and `maxVelocityLimit`.
	 * 
	 * *   Using the **default settings** this velocity will correspond to the maximum speed of rotation of servo arm in **degrees/s**, for many standard RC servos.
	 * 
	 * *   `speedRampingState` controls whether or not the velocity limit value is actually applied when trying to reach a target position.
	 * *   The velocity range of the RC servo motor will be from -`velocityLimit` to +`velocityLimit`, depending on direction.
	 * *   Note that when this value is set to 0, the RC servo motor will not move.
	 * *   There is a practical limit on how fast your servo can rotate, based on the physical design of the motor.
	 * *   The units for `targetPosition`, `velocityLimit`, and `acceleration` are configured by scaling the internal timing (set with `minPulseWidth` and `maxPulseWidth`) to a user specified range with `minPosition` and `maxPosition`.
	 *   
	 * 
	 * See `targetPosition` for a deeper explanation of how the settings of your RC Servo controller interact to move your servo.
	 * @throws {@link PhidgetError}
	 * @param velocityLimit - The velocity value
	 */
	abstract setVelocityLimit(velocityLimit: number): Promise<void>;
	/**
	 * The minimum velocity `velocityLimit` can be set to.  
	 * 
	 * See `targetPosition` for a deeper explanation of how the settings of your RC Servo controller interact to move your servo.
	 * @returns The velocity value
	 * @throws {@link PhidgetError}
	 */
	abstract getMinVelocityLimit(): number;
	/**
	 * The maximum velocity `velocityLimit` can be set to. This value depends on `minPosition`/`maxPosition` and `minPulseWidth`/`maxPulseWidth`.  
	 * 
	 * See `targetPosition` for a deeper explanation of how the settings of your RC Servo controller interact to move your servo.
	 * @returns The velocity value
	 * @throws {@link PhidgetError}
	 */
	abstract getMaxVelocityLimit(): number;
	/**
	 * The supply voltage for the RC servo motor.
	 * 
	 * *   If your controller supports multiple RC servo motors, every motor will have the same supply voltage. It is not possible to set individual supply voltages.
	 * @returns The voltage value
	 * @throws {@link PhidgetError}
	 */
	getVoltage(): Enum.RCServoVoltage {
		this._assertOpen();

		if (this.data.voltage === PUNK.ENUM)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.voltage);
	}

	/**
	 * The supply voltage for the RC servo motor.
	 * 
	 * *   If your controller supports multiple RC servo motors, every motor will have the same supply voltage. It is not possible to set individual supply voltages.
	 * @throws {@link PhidgetError}
	 * @param voltage - The voltage value
	 */
	async setVoltage(voltage: Enum.RCServoVoltage): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (!SEnum.supportedRCServoVoltage(this._ch!, voltage))
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Specified RCServoVoltage is unsupported by this device.");

		bp.set({ name: "0", type: "d", value: voltage });
		await bp.send(this._ch, BP.SETVOLTAGE);
	}

}
export { RCServoBase };
