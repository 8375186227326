﻿import { PhidgetDevices, type PhidgetUniqueDevice } from './Devices.gen';
import { PhidgetError } from './PhidgetError';
import { DeviceClass, DeviceID, ErrorCode, HubPortMode } from './Enumerations.gen';
import { PhidgetConnection } from './Connection';

interface USBFindData {
	type: 'USB',
	productID: number,
	interfaceNum: number,
	version: number,
}
interface VINTFindData {
	type: 'VINT',
	version: number,
	vintID: number,
}
interface FindData {
	type: 'MESH' | 'SPI' | 'VIRTUAL',
	productID: number,
	version: number,
}
export function findPhidgetUniqueDevice(data: VINTFindData | USBFindData | FindData): PhidgetUniqueDevice {

	const typeDevs = PhidgetDevices[data.type] as PhidgetUniqueDevice[];
	for (const d of typeDevs) {
		if (d.v[0] > data.version || d.v[1] <= data.version)
			continue;
		if (data.type === 'VINT') {
			if (d.i !== data.vintID)
				continue;
		} else {
			if (d.i !== data.productID)
				continue;
			if (data.type === 'USB' && (d.n ?? 0) !== data.interfaceNum)
				continue;
		}
		return d;
	}
	throw (new PhidgetError(ErrorCode.UNEXPECTED, "Couldn't find device in device list!!"));
}

/** @internal */
export interface BaseDeviceData {
	devDef: PhidgetUniqueDevice,
	version: number,
	label: string,
	serialNumber: number,
	fwstr: string,
	id: string,
	parent?: Device
}
/** @internal */
export interface VINTDeviceData extends BaseDeviceData {
	type: 'VINT',
	vintDeviceProps: VINTDeviceProperties;
}
/** @internal */
export interface HubDeviceData extends BaseDeviceData {
	type: 'HUB',
	hubPortProps: VINTPortProperties[];
}
/** @internal */
export interface OtherDeviceData extends BaseDeviceData {
	type: 'OTHER'
}
/** @internal */
export type DeviceData = VINTDeviceData | HubDeviceData | OtherDeviceData;

/** @internal */
export interface VINTPortProperties {
	portProto: number,
	portSuppSetSpeed: boolean,
	portSuppAutoSetSpeed: boolean,
	portMaxSpeed: number,

	portSpeed?: number,
	portMode?: HubPortMode,
	portPowered?: boolean
}

/** @internal */
export interface VINTDeviceProperties {
	vintProto: number,
	suppSetSpeed: boolean,
	suppAutoSetSpeed: boolean,
	maxSpeed: number,
	hubPort: number,
	isHubPort: boolean,
	uniqueIndex: number,

	commSpeed?: number,
}

/** @internal */
abstract class Device {
	conn: PhidgetConnection;
	devDef: PhidgetUniqueDevice;
	version: number;
	label: string;
	serialNumber: number;
	fwstr: string;
	id: string;

	parent?: Device;
	get parentId() { return this.parent?.id ?? '0'; }

	// Only for VINT Devices
	vintDeviceProps?: VINTDeviceProperties;

	// Only for VINT Hubs
	hubPortProps?: VINTPortProperties[];

	// Override
	abstract deviceID: DeviceID;
	abstract name: string;

	get vintID() { return this.devDef.i; }
	get sku() { return this.devDef.s; }
	get class() { return this.devDef.c; }

	get hubPort() { return this.vintDeviceProps?.hubPort ?? 0; }
	get isHubPort() { return this.vintDeviceProps?.isHubPort ?? false; }
	get index() { return this.vintDeviceProps?.uniqueIndex ?? 0; }


	constructor(conn: PhidgetConnection, data: DeviceData) {
		this.conn = conn;
		this.devDef = data.devDef;
		this.version = data.version;
		this.label = data.label;
		this.serialNumber = data.serialNumber;
		this.fwstr = data.fwstr;
		this.id = data.id;
		this.parent = data.parent;

		if (data.type === 'VINT')
			this.vintDeviceProps = data.vintDeviceProps;

		if (data.type === 'HUB')
			this.hubPortProps = data.hubPortProps;
	}

	toString() {
		if (this.class === DeviceClass.VINT) {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			return (this.sku + ' (' + this.name + ') v' + this.version + ' -> ' + this.parent!.sku + ' Port:' + this.hubPort + ' Serial#:' + this.serialNumber + (this.conn._isRemote ? " NET" : " USB"));
		}
		return (this.sku + ' (' + this.name + ') v' + this.version + ' Serial#:' + this.serialNumber + (this.conn._isRemote ? " NET" : " USB"));
	}
}

export { Device };