/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PhidgetChannel } from '../Phidget';
import { Channel } from '../Channel';
import { ErrorCode, ChannelClass } from '../Enumerations.gen';
import { PhidgetError } from '../PhidgetError';
import { BridgePacket, PUNK } from '../BridgePacket';
import { BP } from '../BridgePackets.gen';
import { logEventException } from '../Logging';
import { DeviceChannelUID } from '../Devices.gen';

/** @internal */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface PHSensorData {
	dataInterval: number,
	maxDataInterval: number,
	minDataRate: number,
	maxDataRate: number,
	maxPH: number,
	maxPHChangeTrigger: number,
	minDataInterval: number,
	minPH: number,
	minPHChangeTrigger: number,
	PH: number,
	PHChangeTrigger: number,
	correctionTemperature: number,
	minCorrectionTemperature: number,
	maxCorrectionTemperature: number,
}

abstract class PHSensorBase extends PhidgetChannel {
	/** @internal */
	data: PHSensorData;
	/**
	 * **PHChange** event
	 *  * `PH` - The current pH
	 * ---
	 * The most recent pH value the channel has measured will be reported in this event, which occurs when the `dataInterval` has elapsed.
	 * 
	 * *   If a `PHChangeTrigger` has been set to a non-zero value, the `PHChange` event will not occur until the pH has changed by at least the `PHChangeTrigger` value.
	 */
	onPHChange: ((PH: number) => void) | null = null;
	/** @internal */
	_gotPHChangeErrorEvent?: boolean;

	/**
	 * The PH Sensor class gathers data from a pH sensor type Phidget board.
	 * 
	 * If you're using a simple 0-5V sensor that does not have its own firmware, use the VoltageInput or VoltageRatioInput class instead, as specified for your device.
	 * @public
	 */
	constructor();
	/** @internal */
	constructor(ch?: Channel);
	constructor(ch?: Channel) {
		super(ch);
		this._class = ChannelClass.PH_SENSOR;
		this.name = "PHSensor";
		this.data = this._initData();
	}

	/** @internal */
	_bridgeInput(bp: BridgePacket) {

		switch(bp.vpkt) {
		case BP.SETCORRECTIONTEMPERATURE:
			this.data.correctionTemperature = bp.entries[0].v as number;
			this._FIREPropertyChange('CorrectionTemperature', bp);
			break;
		case BP.SETDATAINTERVAL:
			if (bp.entryCount > 1)
				this.data.dataInterval = bp.entries[1].v as number;
			else
				this.data.dataInterval = bp.entries[0].v as number;
			this._FIREPropertyChange('DataInterval', bp);
			this._FIREPropertyChange('DataRate', bp);
			break;
		case BP.SETCHANGETRIGGER:
			this.data.PHChangeTrigger = bp.entries[0].v as number;
			this._FIREPropertyChange('PHChangeTrigger', bp);
			break;
		case BP.PHCHANGE: {
			this.data.PH = bp.entries[0].v as number;
			if (this._isAttachedDone && this.onPHChange) {
				try {
					this.onPHChange(this.data.PH);
				} catch (err) { logEventException(err); }
			}
			break;
		}
		default:
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			throw new PhidgetError(ErrorCode.INVALID_PACKET, "Unsupported bridge packet: 0x" + bp.vpkt!.toString(16));
		}
	}

	/** @internal */
	_initData(): PHSensorData {
		return {
			dataInterval: PUNK.DBL,
			maxDataInterval: PUNK.UINT32,
			minDataRate: PUNK.DBL,
			maxDataRate: PUNK.DBL,
			maxPH: PUNK.DBL,
			maxPHChangeTrigger: PUNK.DBL,
			minDataInterval: PUNK.UINT32,
			minPH: PUNK.DBL,
			minPHChangeTrigger: PUNK.DBL,
			PH: PUNK.DBL,
			PHChangeTrigger: PUNK.DBL,
			correctionTemperature: PUNK.DBL,
			minCorrectionTemperature: PUNK.DBL,
			maxCorrectionTemperature: PUNK.DBL,
		}
	}

	/** @internal */
	_initAfterOpen() {
		this.data = this._initData();

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._ADP1000_PHSENSOR_100:
			this.data.dataInterval = 250;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 20;
			this.data.maxPH = 14;
			this.data.maxPHChangeTrigger = 14;
			this.data.minDataInterval = 50;
			this.data.minPH = 0;
			this.data.minPHChangeTrigger = 0;
			this.data.PHChangeTrigger = 0;
			this.data.correctionTemperature = 25;
			this.data.minCorrectionTemperature = 0;
			this.data.maxCorrectionTemperature = 100;
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	// eslint-disable-next-line require-await
	async _setDefaults() {
		let bp;

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._ADP1000_PHSENSOR_100:
			bp = new BridgePacket();
			bp.set({ name: "0", type: "u", value: Math.round(this.data.dataInterval) });
			await bp.send(this._ch, BP.SETDATAINTERVAL);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.PHChangeTrigger });
			await bp.send(this._ch, BP.SETCHANGETRIGGER);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.correctionTemperature });
			await bp.send(this._ch, BP.SETCORRECTIONTEMPERATURE);
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	_hasInitialState() {

		if ((this.data.PH == PUNK.DBL)
			&& ! this._gotPHChangeErrorEvent)
			return false;

		return true;
	}

	/** @internal */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	_fireInitialEvents() {

		if(this.data.PH != PUNK.DBL)
			if (this.onPHChange)
				try {
					this.onPHChange(this.data.PH);
				} catch (err) { logEventException(err); }

	}

	/**
	 * Set this property to the measured temperature of the solution to correct the slope of the pH conversion for temperature.
	 * @throws {@link PhidgetError}
	 */
	get correctionTemperature() { return this.getCorrectionTemperature(); }
	/**
	 * The minimum value that `correctionTemperature` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minCorrectionTemperature() { return this.getMinCorrectionTemperature(); }
	/**
	 * The maximum value that `correctionTemperature` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxCorrectionTemperature() { return this.getMaxCorrectionTemperature(); }
	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `PHChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * *   The timing between `PHChange` events can also be affected by the `PHChangeTrigger`.
	 * @throws {@link PhidgetError}
	 */
	get dataInterval() { return this.getDataInterval(); }
	/**
	 * The minimum value that `dataInterval` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDataInterval() { return this.getMinDataInterval(); }
	/**
	 * The maximum value that `dataInterval` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDataInterval() { return this.getMaxDataInterval(); }
	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @throws {@link PhidgetError}
	 */
	get dataRate() { return this.getDataRate(); }
	/**
	 * The minimum value that `dataRate` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDataRate() { return this.getMinDataRate(); }
	/**
	 * The maximum value that `dataRate` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDataRate() { return this.getMaxDataRate(); }
	/**
	 * The most recent pH value that the channel has reported.
	 * 
	 * *   This value will always be between `minPH` and `maxPH`.
	 * @throws {@link PhidgetError}
	 */
	get PH() { return this.getPH(); }
	/**
	 * The minimum value the `PHChange` event will report.
	 * @throws {@link PhidgetError}
	 */
	get minPH() { return this.getMinPH(); }
	/**
	 * The maximum value the `PHChange` event will report.
	 * @throws {@link PhidgetError}
	 */
	get maxPH() { return this.getMaxPH(); }
	/**
	 * The channel will not issue a `PHChange` event until the pH value has changed by the amount specified by the `PHChangeTrigger`.
	 * 
	 * *   Setting the `PHChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering
	 * @throws {@link PhidgetError}
	 */
	get PHChangeTrigger() { return this.getPHChangeTrigger(); }
	/**
	 * The minimum value that `PHChangeTrigger` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minPHChangeTrigger() { return this.getMinPHChangeTrigger(); }
	/**
	 * The maximum value that `PHChangeTrigger` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxPHChangeTrigger() { return this.getMaxPHChangeTrigger(); }

	/**
	 * Set this property to the measured temperature of the solution to correct the slope of the pH conversion for temperature.
	 * @returns The temperature of the solution to correct the pH measurement.
	 * @throws {@link PhidgetError}
	 */
	getCorrectionTemperature(): number {
		this._assertOpen();

		if (this.data.correctionTemperature === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.correctionTemperature);
	}

	/**
	 * Set this property to the measured temperature of the solution to correct the slope of the pH conversion for temperature.
	 * @throws {@link PhidgetError}
	 * @param correctionTemperature - The temperature of the solution to correct the pH measurement.
	 */
	async setCorrectionTemperature(correctionTemperature: number): Promise<void> {
		this._assertOpen();

		if (correctionTemperature < this.data.minCorrectionTemperature || correctionTemperature > this.data.maxCorrectionTemperature)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minCorrectionTemperature + " - " + this.data.maxCorrectionTemperature + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: correctionTemperature });
		await bp.send(this._ch, BP.SETCORRECTIONTEMPERATURE);
	}

	/**
	 * The minimum value that `correctionTemperature` can be set to.
	 * @returns The minimum temperature that can be corrected for.
	 * @throws {@link PhidgetError}
	 */
	getMinCorrectionTemperature(): number {
		this._assertOpen();

		if (this.data.minCorrectionTemperature === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minCorrectionTemperature);
	}

	/**
	 * The maximum value that `correctionTemperature` can be set to.
	 * @returns The maximum temperature that can be corrected for.
	 * @throws {@link PhidgetError}
	 */
	getMaxCorrectionTemperature(): number {
		this._assertOpen();

		if (this.data.maxCorrectionTemperature === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxCorrectionTemperature);
	}

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `PHChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * *   The timing between `PHChange` events can also be affected by the `PHChangeTrigger`.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getDataInterval(): number {
		this._assertOpen();

		if (this.data.dataInterval === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return this.data.dataInterval;
	}

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `PHChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * *   The timing between `PHChange` events can also be affected by the `PHChangeTrigger`.
	 * @throws {@link PhidgetError}
	 * @param dataInterval - The data interval value
	 */
	async setDataInterval(dataInterval: number): Promise<void> {
		this._assertOpen();

		if (dataInterval < this.data.minDataInterval || dataInterval > this.data.maxDataInterval)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDataInterval + " - " + this.data.maxDataInterval + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: dataInterval });
		await bp.send(this._ch, BP.SETDATAINTERVAL);
	}

	/**
	 * The minimum value that `dataInterval` can be set to.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getMinDataInterval(): number {
		this._assertOpen();

		if (this.data.minDataInterval === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDataInterval);
	}

	/**
	 * The maximum value that `dataInterval` can be set to.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getMaxDataInterval(): number {
		this._assertOpen();

		if (this.data.maxDataInterval === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDataInterval);
	}

	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @returns The data rate for the channel
	 * @throws {@link PhidgetError}
	 */
	getDataRate(): number {
		this._assertOpen();

		if (this.data.dataInterval === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (1000.0 / this.data.dataInterval);
	}

	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @throws {@link PhidgetError}
	 * @param dataRate - The data rate for the channel
	 */
	async setDataRate(dataRate: number): Promise<void> {
		this._assertOpen();

		if (dataRate < this.data.minDataRate || dataRate > this.data.maxDataRate)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDataRate + " - " + this.data.maxDataRate + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: Math.round(1000.0 / dataRate) });
		bp.set({ name: "1", type: "g", value: (1000.0 / dataRate) });
		await bp.send(this._ch, BP.SETDATAINTERVAL);
	}

	/**
	 * The minimum value that `dataRate` can be set to.
	 * @returns The data rate value
	 * @throws {@link PhidgetError}
	 */
	getMinDataRate(): number {
		this._assertOpen();

		if (this.data.minDataRate === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDataRate);
	}

	/**
	 * The maximum value that `dataRate` can be set to.
	 * @returns The data rate value
	 * @throws {@link PhidgetError}
	 */
	getMaxDataRate(): number {
		this._assertOpen();

		if (this.data.maxDataRate === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDataRate);
	}

	/**
	 * The most recent pH value that the channel has reported.
	 * 
	 * *   This value will always be between `minPH` and `maxPH`.
	 * @returns The pH value
	 * @throws {@link PhidgetError}
	 */
	getPH(): number {
		this._assertOpen();

		if (this.data.PH === PUNK.DBL || Number.isNaN(this.data.PH))
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);
		if (this.data.PH > this.data.maxPH)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE_HIGH);
		if (this.data.PH < this.data.minPH)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE_LOW);

		return (this.data.PH);
	}

	/**
	 * The minimum value the `PHChange` event will report.
	 * @returns The pH value
	 * @throws {@link PhidgetError}
	 */
	getMinPH(): number {
		this._assertOpen();

		if (this.data.minPH === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minPH);
	}

	/**
	 * The maximum value the `PHChange` event will report.
	 * @returns The pH value
	 * @throws {@link PhidgetError}
	 */
	getMaxPH(): number {
		this._assertOpen();

		if (this.data.maxPH === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxPH);
	}

	/**
	 * The channel will not issue a `PHChange` event until the pH value has changed by the amount specified by the `PHChangeTrigger`.
	 * 
	 * *   Setting the `PHChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering
	 * @returns The change trigger value
	 * @throws {@link PhidgetError}
	 */
	getPHChangeTrigger(): number {
		this._assertOpen();

		if (this.data.PHChangeTrigger === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.PHChangeTrigger);
	}

	/**
	 * The channel will not issue a `PHChange` event until the pH value has changed by the amount specified by the `PHChangeTrigger`.
	 * 
	 * *   Setting the `PHChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering
	 * @throws {@link PhidgetError}
	 * @param PHChangeTrigger - The change trigger value
	 */
	async setPHChangeTrigger(PHChangeTrigger: number): Promise<void> {
		this._assertOpen();

		if (PHChangeTrigger < this.data.minPHChangeTrigger || PHChangeTrigger > this.data.maxPHChangeTrigger)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minPHChangeTrigger + " - " + this.data.maxPHChangeTrigger + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: PHChangeTrigger });
		await bp.send(this._ch, BP.SETCHANGETRIGGER);
	}

	/**
	 * The minimum value that `PHChangeTrigger` can be set to.
	 * @returns The change trigger value
	 * @throws {@link PhidgetError}
	 */
	getMinPHChangeTrigger(): number {
		this._assertOpen();

		if (this.data.minPHChangeTrigger === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minPHChangeTrigger);
	}

	/**
	 * The maximum value that `PHChangeTrigger` can be set to.
	 * @returns The change trigger value
	 * @throws {@link PhidgetError}
	 */
	getMaxPHChangeTrigger(): number {
		this._assertOpen();

		if (this.data.maxPHChangeTrigger === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxPHChangeTrigger);
	}

}
export { PHSensorBase };
