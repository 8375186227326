﻿import { ErrorCode, ChannelClass, HubPortMode } from '../../Enumerations.gen';
import { PhidgetError } from '../../PhidgetError';
import { BP } from '../../BridgePackets.gen';
import * as VintPacketType from '../VintPacketType';
import { logdebug, loginfo, logverbose } from '../../Logging';
import { LocalDevice, MAX_OUT_PACKET_SIZE, PHIDGET_MAXCHANNELS } from '../LocalDevice';
import { USBConnectionBase } from '../USBConnection';
import { LocalChannel } from '../LocalChannel';
import { BridgePacket, PUNK } from '../../BridgePacket';
import { PhidgetUSBRequestType } from '../USB';
import { HubDevice, HubPacketType, PACKETIDS_PER_PORT } from './HubDevice';
import { PacketTracker } from '../PacketTracker';
import { getVINTIO } from '../VintPackets.gen';
import { VINTDeviceData, VINTDeviceProperties } from '../../Device';
import { Phidget } from '../../Phidget';

const VINT_DATA_wCHANNEL = 0x40;

const NUM_VINT_SUPPORTED_SPEEDS = 10;
const supportedSpeeds = [10000, 25000, 50000, 100000, 160000, 250000, 400000, 500000, 800000, 1000000];

class VINTDevice extends LocalDevice {
	declare parent: HubDevice;
	declare vintDeviceProps: VINTDeviceProperties;
	private opened: boolean;
	private openCnt: number;

	vintIO: {
		send: (ch: LocalChannel, bp: BridgePacket) => Promise<void>;
		recv: (ch: LocalChannel, buf: DataView) => void;
	};

	constructor(conn: USBConnectionBase, data: VINTDeviceData) {
		super(conn, data);

		const io = getVINTIO(this.devDef.uid);
		if (io == undefined)
			throw new Error("Invalid VINT Device UID");

		this.vintIO = io;

		this.opened = false;
		this.openCnt = 0;
	}

	async open() {

		if (!this.opened) {
			this.openCnt = 0;
			await this.parent.open();
			this.opened = true;
		}
		this.openCnt++;
	}

	async close(force = false) {

		if (!this.opened)
			return;

		if (force)
			this.openCnt = 1;

		this.openCnt--;
		if (this.openCnt > 0) {
			logdebug("Leaving VINT device open, as open count is: " + this.openCnt);
			return;
		}

		this.opened = false;
		this.openCnt = 0;
		await this.parent.close();
	}

	async lock() {
		await this.parent.lock();
	}

	unlock() {
		this.parent.unlock();
	}

	async bridgeInput(channel: LocalChannel, bp: BridgePacket) {

		const hub = this.parent;

		switch (bp.vpkt) {
			case BP.CLOSERESET:
				// RESET the device channel
				await channel.sendVINTDataPacket(VintPacketType.GenericPacket.PHIDGET_RESET);
				// Reset the port mode
				await hub.setPortMode(this.hubPort, HubPortMode.VINT);
				await hub.packetTrackers.waitForPendingPackets(this.hubPort);
				break;

			case BP.OPENRESET: {
				let portMode: HubPortMode = HubPortMode.VINT;
				if (channel.isHubPort) {
					switch (channel.class) {
						case ChannelClass.DIGITAL_INPUT:
							portMode = HubPortMode.DIGITAL_INPUT;
							break;
						case ChannelClass.DIGITAL_OUTPUT:
							portMode = HubPortMode.DIGITAL_OUTPUT;
							break;
						case ChannelClass.VOLTAGE_INPUT:
							portMode = HubPortMode.VOLTAGE_INPUT;
							break;
						case ChannelClass.VOLTAGE_RATIO_INPUT:
							portMode = HubPortMode.VOLTAGE_RATIO_INPUT;
							break;
					}
				}
				await hub.setPortMode(channel.parent.hubPort, portMode);
				await channel.sendVINTDataPacket(VintPacketType.GenericPacket.PHIDGET_RESET);
				break;
			}
			case BP.ENABLE:
				await channel.sendVINTDataPacket(VintPacketType.GenericPacket.PHIDGET_ENABLE);
				break;

			default:
				await this.vintIO.send(channel, bp);
				break;
		}
	}

	dataInput(buffer: DataView) {
		let channelIndex;
		let readPtr;
		let dataCount;

		//Data Length and Channel
		if (buffer.getUint8(0) & VINT_DATA_wCHANNEL) {
			dataCount = (buffer.getUint8(0) & 0x3F) - 1;
			channelIndex = buffer.getUint8(1);
			readPtr = 2;
		} else {
			dataCount = buffer.getUint8(0) & 0x3F;
			channelIndex = 0;
			readPtr = 1;
		}

		const vintChannel = this.getChannel(channelIndex);
		if (!vintChannel)
			return;

		this.vintIO.recv(vintChannel, new DataView(buffer.buffer, readPtr + buffer.byteOffset, dataCount));
	}

	makePacket(vintChannel: LocalChannel, deviceCommand: VINTDeviceCommand, devicePacketType: number, bufferIn?: Uint8Array) {
		const buffer = new Uint8Array(new ArrayBuffer(MAX_OUT_PACKET_SIZE));
		let bufIndex = 0;

		if (bufferIn == undefined)
			bufferIn = new Uint8Array(0);

		switch (deviceCommand) {
			case VINTDeviceCommand.DATA:
				if (vintChannel.uniqueIndex) {
					buffer.set([VINT_DATA_wCHANNEL | (bufferIn.length + 2)], bufIndex++);
					buffer.set([vintChannel.uniqueIndex], bufIndex++);
					buffer.set([devicePacketType], bufIndex++);
				} else {
					buffer.set([bufferIn.length + 1], bufIndex++);
					buffer.set([devicePacketType], bufIndex++);
				}

				if (bufferIn)
					buffer.set(bufferIn, bufIndex);
				break;
			case VINTDeviceCommand.RESET:
			case VINTDeviceCommand.UPGRADE_FIRMWARE:
			case VINTDeviceCommand.FIRMWARE_UPGRADE_DONE:
				buffer.set([deviceCommand], bufIndex++);
				break;
			case VINTDeviceCommand.SETSPEED2:
				if (bufferIn.length !== 4)
					throw new PhidgetError(ErrorCode.UNEXPECTED);

				buffer.set([deviceCommand], bufIndex++);
				buffer.set(bufferIn, bufIndex);
				break;
			default:
				throw new PhidgetError(ErrorCode.INVALID_PACKET);
		}

		return buffer.slice(0, bufIndex + bufferIn.length);
	}

	async sendpacket(bufferIn: Uint8Array) {

		if (bufferIn.length > MAX_OUT_PACKET_SIZE)
			throw new PhidgetError(ErrorCode.UNEXPECTED, "BufferIn length too big in sendpacket");

		const hubDevice = this.parent;

		//on the vint hub, the port is encoded in the packet id (range is 1-126)
		const tracker = await hubDevice.packetTrackers.getPacketTrackerWait(
			hubDevice, this.hubPort * PACKETIDS_PER_PORT + 1,
			(this.hubPort + 1) * PACKETIDS_PER_PORT, this.hubPort, 500);

		logverbose("Claimed Hub packet ID " + tracker.id + ", Port " + this.hubPort);
		try {
			const buffer = hubDevice.makePacket(this, tracker.id, bufferIn);
			await hubDevice.claimPacketSpace(this.hubPort, buffer.byteLength);
			tracker.pt.setPacketLength(buffer.byteLength);
			await this.sendpacketWithTracking(buffer, tracker.pt);
			tracker.pt.releasePacketTracker(false);
			hubDevice.packetOutCounter[this.hubPort]++;
			logverbose("Packet " + tracker.id + " send successfully, Port " + this.hubPort);
		} catch (err) {
			logverbose("Packet " + tracker.id + " failed, Port " + this.hubPort, err);
			tracker.pt.releasePacketTracker(true);
			throw err;
		}
	}

	async sendpacketWithTracking(buf: Uint8Array, packetTracker: PacketTracker) {
		//Ensure that this packet wasn't signalled in the meantime (probably because of an error)
		if (packetTracker.signalled) {
			if (packetTracker.returnCode != ErrorCode.SUCCESS)
				throw new PhidgetError(packetTracker.returnCode);
			return;
		}

		await this.parent.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_BULK_WRITE, 0, 0, buf);

		packetTracker.sent = true;
		const res = await packetTracker.waitForPendingPacket(1000);
		if (res != ErrorCode.SUCCESS)
			throw new PhidgetError(res);
	}

	async setHubPortSpeed(vintChannel: LocalChannel, speed: number) {
		let i;

		const hubPortProps = this.parent.hubPortProps[this.hubPort];

		// Both the port and device need to support >= VINT2
		if (hubPortProps.portProto === PUNK.UINT8 || this.vintDeviceProps.vintProto < 2 ||
			this.vintDeviceProps.vintProto === PUNK.UINT8 || this.vintDeviceProps.vintProto < 2) {
			throw new PhidgetError(ErrorCode.UNSUPPORTED, "VINT Port and Device must support VINT2 protocol.");
		}

		if (!hubPortProps.portSuppSetSpeed) {
			throw new PhidgetError(ErrorCode.UNSUPPORTED, "VINT Port does not support High Speed.");
		}

		if (speed === Phidget.AUTO_HUBPORTSPEED && !hubPortProps.portSuppAutoSetSpeed) {
			throw new PhidgetError(ErrorCode.UNSUPPORTED, "VINT Port does not support Auto Set Speed.");
		}

		if (!this.vintDeviceProps.suppSetSpeed) {
			throw new PhidgetError(ErrorCode.UNSUPPORTED, "VINT Device does not support High Speed.");
		}

		if (speed === Phidget.AUTO_HUBPORTSPEED && !this.vintDeviceProps.suppAutoSetSpeed) {
			throw new PhidgetError(ErrorCode.UNSUPPORTED, "VINT Device does not support Auto Set Speed.");
		}

		if (speed > hubPortProps.portMaxSpeed) {
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Speed must be <= Port max speed of: " + hubPortProps.portMaxSpeed + "Hz.");
		}

		if (speed > this.vintDeviceProps.maxSpeed) {
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Speed must be <= Device max speed of: " + this.vintDeviceProps.maxSpeed + "Hz.");
		}

		if (speed !== Phidget.AUTO_HUBPORTSPEED) {
			for (i = (NUM_VINT_SUPPORTED_SPEEDS - 1); i >= 0; i--) {
				if (speed >= supportedSpeeds[i]) {
					break;
				}
			}
			if (speed !== supportedSpeeds[i]) {
				loginfo("Requested VINT speed of " + speed + "HZ is not supported.  Setting nearest lower speed of " + supportedSpeeds[i] + "Hz instead.");
			}
			speed = supportedSpeeds[i];
		}

		const buf = new DataView(new ArrayBuffer(4));
		buf.setUint32(0, speed);
		await vintChannel.sendVINTPacket(VINTDeviceCommand.SETSPEED2, 0, new Uint8Array(buf.buffer));

		// Update the speed at the device level
		this.vintDeviceProps.commSpeed = speed;

		// Notify all channels that the speed changed
		for (let i = 0; i < PHIDGET_MAXCHANNELS; i++) {
			const ch = this.getChannel(i);
			if (ch != undefined && ch !== vintChannel) {
				const bp = new BridgePacket();
				bp.set({ name: '0', type: 'u', value: this.vintDeviceProps.commSpeed });
				bp.sendToChannel(ch, BP.VINTSPEEDCHANGE);
			}
		}
	}

	async reboot(vintChannel: LocalChannel) {
		await vintChannel.sendVINTPacket(VINTDeviceCommand.RESET);
	}

	async rebootFirmwareUpgrade(vintChannel: LocalChannel, timeout: number) {
		const hubDevice = this.parent as HubDevice;
		const buffer = new Uint8Array([timeout & 0xFF, (timeout >> 8) & 0xFF]);
		await hubDevice.sendHubPortPacket(this.hubPort, HubPacketType.UPGRADE_FIRMWARE, buffer);
		await vintChannel.sendVINTPacket(VINTDeviceCommand.RESET);
	}
}

/** @internal */
const enum VintMessages {
	VINT_CMD = 0x80,
	VINT_DATA = 0x00
}

/** @internal */
export const enum VINTDeviceCommand {
	//VINT1
	DATA = VintMessages.VINT_DATA,
	RESET = (VintMessages.VINT_CMD | 0x03),
	UPGRADE_FIRMWARE = (VintMessages.VINT_CMD | 0x0B),
	FIRMWARE_UPGRADE_DONE = (VintMessages.VINT_CMD | 0x0C),
	//VINT2
	SETSPEED2 = (VintMessages.VINT_CMD | 0x0F)
}

export { VINTDevice };