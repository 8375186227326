/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PhidgetChannel } from '../Phidget';
import { Channel } from '../Channel';
import { ErrorCode, ChannelClass } from '../Enumerations.gen';
import * as Struct from '../Structs.gen';
import { PhidgetError } from '../PhidgetError';
import { BridgePacket } from '../BridgePacket';
import { BP } from '../BridgePackets.gen';
import { logEventException } from '../Logging';
import { DeviceChannelUID } from '../Devices.gen';

/** @internal */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IRData {
	lastCodeInfo: Struct.IRCodeInfo | null,
	lastLearnedCodeInfo: Struct.IRCodeInfo | null,
	lastCodeStr: string | null,
	lastCodeBitCount: number, 
	lastLearnedCodeStr: string | null,
}

abstract class IRBase extends PhidgetChannel {
	/** @internal */
	data: IRData;
	/**
	 * **Code** event
	 *  * `code` - The code string
	 *  * `bitCount` - The length of the received code in bits
	 *  * `isRepeat` - 'true' if a repeat is detected
	 * ---
	 * This event is fired every time a code is received and correctly decoded.
	 * 
	 * *   The code is represented by a hexadecimal string (array of bytes) with a length of 1/4 of `bitCount`.
	 * *   The MSBit is considered to be the first bit received and will be in array index 0 of `code`
	 * *   Repeat will be true if a repeat is detected (either timing wise or via a repeat code)
	 * 
	 * *   False repeasts can happen if two separate button presses happen close together
	 */
	onCode: ((code: string, bitCount: number, isRepeat: boolean) => void) | null = null;
	/** @internal */
	_gotCodeErrorEvent?: boolean;
	/**
	 * **Learn** event
	 *  * `code` - The code string
	 *  * `codeInfo` - Contains the data for characterizing the code.
	 * ---
	 * This event fires when a button has been held down long enough for the channel to have learned the CodeInfo values
	 * 
	 * *   A code is usually learned after 1 second, or after 4 repeats.
	 */
	onLearn: ((code: string, codeInfo: Struct.IRCodeInfo) => void) | null = null;
	/** @internal */
	_gotLearnErrorEvent?: boolean;
	/**
	 * **RawData** event
	 *  * `data` - The data being received
	 * ---
	 * This event will fire every time the channel gets more data
	 * 
	 * *   This will happen at most once every 8ms.
	 */
	onRawData: ((data: readonly number[]) => void) | null = null;
	/** @internal */
	_gotRawDataErrorEvent?: boolean;

	/**
	 * The Infrared Remote class lets you read and transmit command codes from infrared remotes that the majority of household appliances use. You can use this class to construct and transmit commands from scratch, or learn and retransmit codes from the remote controller of your appliance.
	 * @public
	 */
	constructor();
	/** @internal */
	constructor(ch?: Channel);
	constructor(ch?: Channel) {
		super(ch);
		this._class = ChannelClass.IR;
		this.name = "IR";
		this.data = this._initData();
	}
	/**
	 * The value for a long space in raw data
	 */
	static get RAW_DATA_LONG_SPACE() { return 4294967295; }
	/**
	 * Maximum bit count for sent / received data
	 */
	static get MAX_CODE_BIT_COUNT() { return 128; }
	/**
	 * Maximum bit count for sent / received data
	 */
	static get MAX_CODE_STRING_LENGTH() { return 33; }

	/** @internal */
	_bridgeInput(bp: BridgePacket) {

		switch(bp.vpkt) {
		case BP.TRANSMIT:
			break;
		case BP.TRANSMITRAW:
			break;
		case BP.TRANSMITREPEAT:
			break;
		case BP.RAWDATA: {
			if (this._isAttachedDone && this.onRawData) {
				try {
					this.onRawData(bp.entries[0].v as readonly number[]);
				} catch (err) { logEventException(err); }
			}
			break;
		}
		default:
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			throw new PhidgetError(ErrorCode.INVALID_PACKET, "Unsupported bridge packet: 0x" + bp.vpkt!.toString(16));
		}
	}

	/** @internal */
	_initData(): IRData {
		return {
			lastCodeInfo: null,
			lastLearnedCodeInfo: null,
			lastCodeStr: null,
			lastCodeBitCount: 0, 
			lastLearnedCodeStr: null,
		}
	}

	/** @internal */
	_initAfterOpen() {
		this.data = this._initData();

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._1055_IR_200_VINT:
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	// eslint-disable-next-line require-await
	async _setDefaults() {

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._1055_IR_200_VINT:
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	_hasInitialState() {


		return true;
	}

	/** @internal */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	_fireInitialEvents() {

	}


	/**
	 * The last code the channel has received.
	 * 
	 * *   The code is represented by a hexadecimal string (array of bytes).
	 * @returns
	 * 	- code: The last received code
	 * 	- bitCount: length of the received code in bits
	 * @throws {@link PhidgetError}
	 */
	abstract getLastCode(): {code: string, bitCount: number};
	/**
	 * The last code the channel has learned.
	 * 
	 * *   The code is represented by a hexadecimal string (array of bytes).
	 * *   The `codeInfo` structure holds data that describes the learned code.
	 * @returns
	 * 	- code: The last learned code
	 * 	- codeInfo: contains the data for characterizing the code
	 * @throws {@link PhidgetError}
	 */
	abstract getLastLearnedCode(): {code: string, codeInfo: Struct.IRCodeInfo};
	/**
	 * Transmits a code
	 * 
	 * *   `code` data is transmitted MSBit first.
	 * *   MSByte is in array index 0 of `code`
	 * *   LSBit is right justified, therefore, MSBit may be in bit position 0-7 (of array index 0) depending on the bit count.
	 * @throws {@link PhidgetError}
	 * @param code - code data
	 * @param codeInfo - contains the data for characterizing the code.
	 */
	abstract transmit(code: string, codeInfo: Struct.IRCodeInfo): Promise<void>;
	/**
	 * Transmits **raw** data as a series of pulses and spaces.
	 * 
	 * *   `data` must start and end with a pulse.
	 *     *   Each element is a positive time in μs
	 * *   `dataLength` has a maximum length of 200, however, streams should be kept must shorter than this (less than 100ms between gaps).
	 * 
	 * *   `dataLength` must be an odd number
	 * 
	 * *   Leave `carrierFrequency` as 0 for default.
	 * 
	 * *   `carrierFrequency` has a range of 10kHz - 1MHz
	 * 
	 * *   Leave `dutyCycle` as 0 for default
	 * 
	 * *   `dutyCycle` can have a value between 0.1 and 0.5
	 * 
	 * *   Specifying a `gap` will guarantee a gap time (no transmitting) after data is sent.
	 * 
	 * *   gap time is in μs
	 * *   gap time can be set to 0
	 * @throws {@link PhidgetError}
	 * @param data - data to send.
	 * @param carrierFrequency - carrier frequency in Hz
	 * @param dutyCycle - the duty cycle
	 * @param gap - the gap time.
	 */
	abstract transmitRaw(data: readonly number[], carrierFrequency: number, dutyCycle: number, gap: number): Promise<void>;
	/**
	 * Transmits a repeat of the last transmited code.
	 * 
	 * *   Depending on the CodeInfo structure, this may be a retransmission of the code itself, or there may be a special repeat code.
	 * @throws {@link PhidgetError}
	 */
	async transmitRepeat(): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		await bp.send(this._ch, BP.TRANSMITREPEAT);
	}

}
export { IRBase };
