
/** @internal */
export const PhidgetErrorDescription = {
	0: 'Success',
	1: 'Not Permitted',
	2: 'No Such Entity',
	3: 'Timed Out',
	58: 'Keep Alive Failure',
	4: 'Op Interrupted',
	5: 'IO Issue',
	6: 'Memory Issue',
	7: 'Access (Permission) Issue',
	8: 'Address Issue',
	9: 'Resource Busy',
	10: 'Object Exists',
	11: 'Object is not a directory',
	12: 'Object is a directory',
	13: 'Invalid',
	14: 'Too many open files in system',
	15: 'Too many open files',
	16: 'Not enough space',
	17: 'File too Big',
	18: 'Read Only Filesystem',
	19: 'Read Only Object',
	20: 'Operation Not Supported',
	21: 'Invalid Argument',
	22: 'Try again',
	26: 'Not Empty',
	28: 'Unexpected Error',
	27: 'Duplicate',
	37: 'Bad Credential',
	45: 'Network Unavailable',
	35: 'Connection Refused',
	46: 'Connection Reset',
	48: 'No route to host',
	40: 'No Such Device',
	50: 'Wrong Device',
	41: 'Broken Pipe',
	44: 'Name Resolution Failure',
	51: 'Unknown or Invalid Value',
	52: 'Device not Attached',
	53: 'Invalid or Unexpected Packet',
	54: 'Argument List Too Long',
	55: 'Bad Version',
	56: 'Closed',
	57: 'Not Configured',
	31: 'End of File',
	59: 'Failsafe Triggered',
	60: 'Invalid Value - Too High',
	61: 'Invalid Value - Too Low',
	62: 'Bad Power Supply',
	63: 'Power Cycle Required',
	64: 'Hall Sensor Error',
	65: 'Bad Current Readings',
}

