/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PhidgetChannel } from '../Phidget';
import { Channel } from '../Channel';
import { ErrorCode, ChannelClass } from '../Enumerations.gen';
import { PhidgetError } from '../PhidgetError';
import { BridgePacket, PUNK } from '../BridgePacket';
import { BP } from '../BridgePackets.gen';
import { logEventException } from '../Logging';
import { DeviceChannelUID } from '../Devices.gen';

/** @internal */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface PressureSensorData {
	dataInterval: number,
	maxDataInterval: number,
	minDataRate: number,
	maxDataRate: number,
	maxPressure: number,
	maxPressureChangeTrigger: number,
	minDataInterval: number,
	minPressure: number,
	minPressureChangeTrigger: number,
	pressure: number,
	pressureChangeTrigger: number,
}

abstract class PressureSensorBase extends PhidgetChannel {
	/** @internal */
	data: PressureSensorData;
	/**
	 * **PressureChange** event
	 *  * `pressure` - The new measured pressure
	 * ---
	 * The most recent pressure value the channel has measured will be reported in this event, which occurs when the `dataInterval` has elapsed.
	 * 
	 * *   If a `pressureChangeTrigger` has been set to a non-zero value, the `PressureChange` event will not occur until the pressure has changed by at least the `pressureChangeTrigger` value.
	 */
	onPressureChange: ((pressure: number) => void) | null = null;
	/** @internal */
	_gotPressureChangeErrorEvent?: boolean;

	/**
	 * The Pressure Sensor class gathers data from the pressure sensor on a Phidget board.
	 * 
	 * If you're using a simple 0-5V sensor that does not have its own firmware, use the VoltageInput or VoltageRatioInput class instead, as specified for your device.
	 * @public
	 */
	constructor();
	/** @internal */
	constructor(ch?: Channel);
	constructor(ch?: Channel) {
		super(ch);
		this._class = ChannelClass.PRESSURE_SENSOR;
		this.name = "PressureSensor";
		this.data = this._initData();
	}

	/** @internal */
	_bridgeInput(bp: BridgePacket) {

		switch(bp.vpkt) {
		case BP.SETDATAINTERVAL:
			if (bp.entryCount > 1)
				this.data.dataInterval = bp.entries[1].v as number;
			else
				this.data.dataInterval = bp.entries[0].v as number;
			this._FIREPropertyChange('DataInterval', bp);
			this._FIREPropertyChange('DataRate', bp);
			break;
		case BP.SETCHANGETRIGGER:
			this.data.pressureChangeTrigger = bp.entries[0].v as number;
			this._FIREPropertyChange('PressureChangeTrigger', bp);
			break;
		case BP.PRESSURECHANGE: {
			this.data.pressure = bp.entries[0].v as number;
			if (this._isAttachedDone && this.onPressureChange) {
				try {
					this.onPressureChange(this.data.pressure);
				} catch (err) { logEventException(err); }
			}
			break;
		}
		default:
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			throw new PhidgetError(ErrorCode.INVALID_PACKET, "Unsupported bridge packet: 0x" + bp.vpkt!.toString(16));
		}
	}

	/** @internal */
	_initData(): PressureSensorData {
		return {
			dataInterval: PUNK.DBL,
			maxDataInterval: PUNK.UINT32,
			minDataRate: PUNK.DBL,
			maxDataRate: PUNK.DBL,
			maxPressure: PUNK.DBL,
			maxPressureChangeTrigger: PUNK.DBL,
			minDataInterval: PUNK.UINT32,
			minPressure: PUNK.DBL,
			minPressureChangeTrigger: PUNK.DBL,
			pressure: PUNK.DBL,
			pressureChangeTrigger: PUNK.DBL,
		}
	}

	/** @internal */
	_initAfterOpen() {
		this.data = this._initData();

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._PRE1000_PRESSURESENSOR_100:
			this.data.dataInterval = 250;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 10;
			this.data.maxPressure = 110;
			this.data.maxPressureChangeTrigger = 60;
			this.data.minDataInterval = 100;
			this.data.minPressure = 50;
			this.data.minPressureChangeTrigger = 0;
			this.data.pressureChangeTrigger = 0;
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	// eslint-disable-next-line require-await
	async _setDefaults() {
		let bp;

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._PRE1000_PRESSURESENSOR_100:
			bp = new BridgePacket();
			bp.set({ name: "0", type: "u", value: Math.round(this.data.dataInterval) });
			await bp.send(this._ch, BP.SETDATAINTERVAL);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.pressureChangeTrigger });
			await bp.send(this._ch, BP.SETCHANGETRIGGER);
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	_hasInitialState() {

		if ((this.data.pressure == PUNK.DBL)
			&& ! this._gotPressureChangeErrorEvent)
			return false;

		return true;
	}

	/** @internal */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	_fireInitialEvents() {

		if(this.data.pressure != PUNK.DBL)
			if (this.onPressureChange)
				try {
					this.onPressureChange(this.data.pressure);
				} catch (err) { logEventException(err); }

	}

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `PressureChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * *   The timing between `PressureChange` events can also be affected by the `pressureChangeTrigger`.
	 * @throws {@link PhidgetError}
	 */
	get dataInterval() { return this.getDataInterval(); }
	/**
	 * The minimum value that `dataInterval` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDataInterval() { return this.getMinDataInterval(); }
	/**
	 * The maximum value that `dataInterval` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDataInterval() { return this.getMaxDataInterval(); }
	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @throws {@link PhidgetError}
	 */
	get dataRate() { return this.getDataRate(); }
	/**
	 * The minimum value that `dataRate` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDataRate() { return this.getMinDataRate(); }
	/**
	 * The maximum value that `dataRate` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDataRate() { return this.getMaxDataRate(); }
	/**
	 * The most recent pressure value that the channel has reported.
	 * 
	 * *   This value will always be between `minPressure` and `maxPressure`.
	 * @throws {@link PhidgetError}
	 */
	get pressure() { return this.getPressure(); }
	/**
	 * The minimum value the `PressureChange` event will report.
	 * @throws {@link PhidgetError}
	 */
	get minPressure() { return this.getMinPressure(); }
	/**
	 * The maximum value the `PressureChange` event will report.
	 * @throws {@link PhidgetError}
	 */
	get maxPressure() { return this.getMaxPressure(); }
	/**
	 * The channel will not issue a `PressureChange` event until the pressure value has changed by the amount specified by the `pressureChangeTrigger`.
	 * 
	 * *   Setting the `pressureChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering
	 * @throws {@link PhidgetError}
	 */
	get pressureChangeTrigger() { return this.getPressureChangeTrigger(); }
	/**
	 * The minimum value that `pressureChangeTrigger` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minPressureChangeTrigger() { return this.getMinPressureChangeTrigger(); }
	/**
	 * The maximum value that `pressureChangeTrigger` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxPressureChangeTrigger() { return this.getMaxPressureChangeTrigger(); }

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `PressureChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * *   The timing between `PressureChange` events can also be affected by the `pressureChangeTrigger`.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getDataInterval(): number {
		this._assertOpen();

		if (this.data.dataInterval === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return this.data.dataInterval;
	}

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `PressureChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * *   The timing between `PressureChange` events can also be affected by the `pressureChangeTrigger`.
	 * @throws {@link PhidgetError}
	 * @param dataInterval - The data interval value
	 */
	async setDataInterval(dataInterval: number): Promise<void> {
		this._assertOpen();

		if (dataInterval < this.data.minDataInterval || dataInterval > this.data.maxDataInterval)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDataInterval + " - " + this.data.maxDataInterval + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: dataInterval });
		await bp.send(this._ch, BP.SETDATAINTERVAL);
	}

	/**
	 * The minimum value that `dataInterval` can be set to.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getMinDataInterval(): number {
		this._assertOpen();

		if (this.data.minDataInterval === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDataInterval);
	}

	/**
	 * The maximum value that `dataInterval` can be set to.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getMaxDataInterval(): number {
		this._assertOpen();

		if (this.data.maxDataInterval === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDataInterval);
	}

	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @returns The data rate for the channel
	 * @throws {@link PhidgetError}
	 */
	getDataRate(): number {
		this._assertOpen();

		if (this.data.dataInterval === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (1000.0 / this.data.dataInterval);
	}

	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @throws {@link PhidgetError}
	 * @param dataRate - The data rate for the channel
	 */
	async setDataRate(dataRate: number): Promise<void> {
		this._assertOpen();

		if (dataRate < this.data.minDataRate || dataRate > this.data.maxDataRate)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDataRate + " - " + this.data.maxDataRate + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: Math.round(1000.0 / dataRate) });
		bp.set({ name: "1", type: "g", value: (1000.0 / dataRate) });
		await bp.send(this._ch, BP.SETDATAINTERVAL);
	}

	/**
	 * The minimum value that `dataRate` can be set to.
	 * @returns The data rate value
	 * @throws {@link PhidgetError}
	 */
	getMinDataRate(): number {
		this._assertOpen();

		if (this.data.minDataRate === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDataRate);
	}

	/**
	 * The maximum value that `dataRate` can be set to.
	 * @returns The data rate value
	 * @throws {@link PhidgetError}
	 */
	getMaxDataRate(): number {
		this._assertOpen();

		if (this.data.maxDataRate === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDataRate);
	}

	/**
	 * The most recent pressure value that the channel has reported.
	 * 
	 * *   This value will always be between `minPressure` and `maxPressure`.
	 * @returns The pressure value
	 * @throws {@link PhidgetError}
	 */
	getPressure(): number {
		this._assertOpen();

		if (this.data.pressure === PUNK.DBL || Number.isNaN(this.data.pressure))
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);
		if (this.data.pressure > this.data.maxPressure)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE_HIGH);
		if (this.data.pressure < this.data.minPressure)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE_LOW);

		return (this.data.pressure);
	}

	/**
	 * The minimum value the `PressureChange` event will report.
	 * @returns The pressure value
	 * @throws {@link PhidgetError}
	 */
	getMinPressure(): number {
		this._assertOpen();

		if (this.data.minPressure === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minPressure);
	}

	/**
	 * The maximum value the `PressureChange` event will report.
	 * @returns The pressure value
	 * @throws {@link PhidgetError}
	 */
	getMaxPressure(): number {
		this._assertOpen();

		if (this.data.maxPressure === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxPressure);
	}

	/**
	 * The channel will not issue a `PressureChange` event until the pressure value has changed by the amount specified by the `pressureChangeTrigger`.
	 * 
	 * *   Setting the `pressureChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering
	 * @returns The change trigger value
	 * @throws {@link PhidgetError}
	 */
	getPressureChangeTrigger(): number {
		this._assertOpen();

		if (this.data.pressureChangeTrigger === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.pressureChangeTrigger);
	}

	/**
	 * The channel will not issue a `PressureChange` event until the pressure value has changed by the amount specified by the `pressureChangeTrigger`.
	 * 
	 * *   Setting the `pressureChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering
	 * @throws {@link PhidgetError}
	 * @param pressureChangeTrigger - The change trigger value
	 */
	async setPressureChangeTrigger(pressureChangeTrigger: number): Promise<void> {
		this._assertOpen();

		if (pressureChangeTrigger < this.data.minPressureChangeTrigger || pressureChangeTrigger > this.data.maxPressureChangeTrigger)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minPressureChangeTrigger + " - " + this.data.maxPressureChangeTrigger + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: pressureChangeTrigger });
		await bp.send(this._ch, BP.SETCHANGETRIGGER);
	}

	/**
	 * The minimum value that `pressureChangeTrigger` can be set to.
	 * @returns The change trigger value
	 * @throws {@link PhidgetError}
	 */
	getMinPressureChangeTrigger(): number {
		this._assertOpen();

		if (this.data.minPressureChangeTrigger === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minPressureChangeTrigger);
	}

	/**
	 * The maximum value that `pressureChangeTrigger` can be set to.
	 * @returns The change trigger value
	 * @throws {@link PhidgetError}
	 */
	getMaxPressureChangeTrigger(): number {
		this._assertOpen();

		if (this.data.maxPressureChangeTrigger === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxPressureChangeTrigger);
	}

}
export { PressureSensorBase };
