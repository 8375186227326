import { ErrorCode, ChannelClass, ErrorEventCode } from '../../Enumerations.gen';
import { PhidgetError } from '../../PhidgetError';
import { PhidgetUSBRequestType } from '../USB';
import { BridgePacket, PUNK } from '../../BridgePacket';
import { BP } from '../../BridgePackets.gen';
import { DeviceUID } from '../../Devices.gen';
import * as VintPacketType from '../VintPacketType';
import { USBConnectionBase } from '../USBConnection';
import { LocalChannel } from '../LocalChannel';
import { PhidgetUSBDevice, PhidgetUSBDeviceData } from '../PhidgetUSBDevice';

class InterfaceKitDevice extends PhidgetUSBDevice {

	data: {
		outputState: number[],
		failsafeState: number[],
		inputState: number[],
		voltage: number[],
		voltageRatio: number[]
	};

	constructor(conn: USBConnectionBase, data: PhidgetUSBDeviceData, usbDev: USBDevice) {
		super(conn, data, usbDev);

		// Ensure that we actually support this device
		switch (this.devDef.uid) {
			case DeviceUID._1014_3_USB:
			case DeviceUID._1017_2_USB:
			case DeviceUID._1018_3:
				break;
			default:
				throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}

		this.data = {
			outputState: new Array(this.numOutputs).fill(PUNK.BOOL),
			failsafeState: new Array(this.numOutputs).fill(0),
			inputState: new Array(this.numInputs).fill(PUNK.BOOL),
			voltage: new Array(this.numVoltageInputs).fill(PUNK.DBL),
			voltageRatio: new Array(this.numSensors).fill(PUNK.DBL)
		};
	}

	// Define getters based on devices.h Attr structs in C library
	get numVoltageInputs() { return this.devDef.cn[0]; }
	get numSensors() { return this.devDef.cn[1]; }
	get numInputs() { return this.devDef.cn[2]; }
	get numOutputs() { return this.devDef.cn[3]; }
	get numCapTouches() { return this.devDef.cn[4]; }

	async initAfterOpen() {

		switch (this.devDef.uid) {
			case DeviceUID._1018_3:
				throw new PhidgetError(ErrorCode.UNSUPPORTED, "This Phidget is not yet supported via direct USB connection in JavaScript.");
		}

		for (let i = 0; i < this.numOutputs; i++)
			this.data.failsafeState[i] = 0;

		//read in device state
		const buf = await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_DEVICE_READ, 0, 0, 1);
		for (let i = 0; i < this.numOutputs; i++)
			this.data.outputState[i] = (buf.getUint8(0) & (1 << i)) ? 1 : 0;
	}

	async bridgeInput(ch: LocalChannel, bp: BridgePacket) {
		let dutyCycle;
		const buf = new DataView(new ArrayBuffer(2));

		switch (ch.class) {
			case ChannelClass.DIGITAL_OUTPUT:
				if (this.data.failsafeState[ch.index] && (bp.vpkt !== BP.OPENRESET) && (bp.vpkt !== BP.CLOSERESET)) {
					throw new PhidgetError(ErrorCode.FAILSAFE);
				}
				switch (bp.vpkt) {
					case BP.SETSTATE:
						this.data.outputState[ch.index] = bp.getNumber(0);
						buf.setUint8(0, this.data.outputState[ch.index]);
						await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.DigitalOutputPacket.SETDUTYCYCLE, ch.index, new Uint8Array(buf.buffer, 0, 1));
						break;
					case BP.SETDUTYCYCLE:
						dutyCycle = bp.getNumber(0);
						if (dutyCycle !== 0.0 && dutyCycle !== 1)
							throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Duty cycle must be either 0 or 1");
						this.data.outputState[ch.index] = dutyCycle;
						buf.setUint8(0, this.data.outputState[ch.index] ? 0xFF : 0x00);
						await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.DigitalOutputPacket.SETDUTYCYCLE, ch.index, new Uint8Array(buf.buffer, 0, 1));
						break;
					case BP.OPENRESET:
					case BP.CLOSERESET:
						this.data.failsafeState[ch.index] = 0;
						await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.GenericPacket.PHIDGET_RESET, ch.index);
						break;
					case BP.ENABLE:
						break;
					case BP.SETFAILSAFETIME:
						buf.setUint16(0, bp.getNumber('0'));
						await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.GenericPacket.FAILSAFE_TIME, ch.index, new Uint8Array(buf.buffer, 0, 2));
						break;
					case BP.FAILSAFERESET:
						await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.GenericPacket.FAILSAFE_RESET, ch.index);
						break;
					default:
						throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type");
				}
				break;
			case ChannelClass.DIGITAL_INPUT:
			case ChannelClass.VOLTAGE_RATIO_INPUT:
			case ChannelClass.VOLTAGE_INPUT:
				throw new Error("Class not implemented");
			default:
				throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected channel class");

		}
	}

	dataInput(buf: DataView) {

		const ch = this.getChannel(buf.getUint8(1));
		if (ch) {
			const pkt = buf.getUint8(0);
			const bp = new BridgePacket();
			switch (pkt) {
				case VintPacketType.DigitalOutputPacket.FAILSAFE: {
					const i = buf.getUint8(1);
					this.data.failsafeState[i] = 1;
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.FAILSAFE_CONDITION });
					bp.set({ name: '1', type: 's', value: 'Failsafe procedure initiated.' });
					ch.sendErrorEvent(bp);
					break;
				}
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type");
			}
		}
	}
}

export { InterfaceKitDevice };