import { PressureSensorBase } from './PressureSensor.gen';
import { ErrorEventCode } from '../Enumerations.gen';
import { PUNK } from '../BridgePacket';

/** @public */
class PressureSensor extends PressureSensorBase {

	/** @internal */
	_errorHandler(code: ErrorEventCode) {
		switch (code) {
			case ErrorEventCode.SATURATION:
				this.data.pressure = PUNK.DBL;
				this._gotPressureChangeErrorEvent = true;
				break;
		}
	}
}

export { PressureSensor };