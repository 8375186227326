import { SoundSensorBase } from './SoundSensor.gen';
import { PUNK } from '../BridgePacket';
import { logEventException } from '../Logging';

/** @public */
class SoundSensor extends SoundSensorBase {

	/** @internal */
	_hasInitialState() {
		if (this.data.dB == PUNK.DBL)
			return false;

		return true;
	}

	/** @internal */
	_fireInitialEvents() {
		if (this.data.dB != PUNK.DBL) {
			if (this.onSPLChange) {
				try {
					this.onSPLChange(this.data.dB, this.data.dBA, this.data.dBC, this.data.octaves);
				} catch (err) { logEventException(err); }
			}
		}
	}
}

export { SoundSensor };