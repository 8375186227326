/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PhidgetChannel } from '../Phidget';
import { Channel } from '../Channel';
import { ErrorCode, ChannelClass } from '../Enumerations.gen';
import * as Enum from '../Enumerations.gen';
import * as SEnum from '../SupportedEnum.gen';
import { PhidgetError } from '../PhidgetError';
import { BridgePacket, PUNK } from '../BridgePacket';
import { BP } from '../BridgePackets.gen';
import { logEventException } from '../Logging';
import { DeviceChannelUID } from '../Devices.gen';

/** @internal */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FrequencyCounterData {
	totalTicksSinceLastCount: number, 
	frequencyPrecision: number, 
	dataInterval: number,
	minDataInterval: number,
	maxDataInterval: number,
	minDataRate: number,
	maxDataRate: number,
	maxFrequency: number,
	maxFrequencyCutoff: number,
	minFrequencyCutoff: number,
	count: number,
	timeElapsed: number,
	frequencyCutoff: number,
	frequency: number,
	filterType: Enum.FrequencyFilterType | PUNK.ENUM,
	powerSupply: Enum.PowerSupply | PUNK.ENUM,
	inputMode: Enum.InputMode | PUNK.ENUM,
	enabled: number,
}

abstract class FrequencyCounterBase extends PhidgetChannel {
	/** @internal */
	data: FrequencyCounterData;
	/**
	 * **FrequencyChange** event
	 *  * `frequency` - The calculated frequency of the signal
	 * ---
	 * The most recent frequency value the channel has measured will be reported in this event, which occurs when the `dataInterval` has elapsed.
	 */
	onFrequencyChange: ((frequency: number) => void) | null = null;
	/** @internal */
	_gotFrequencyChangeErrorEvent?: boolean;
	/**
	 * **CountChange** event
	 *  * `counts` - The pulse count of the signal
	 *  * `timeChange` - The change in elapsed time since the last change
	 * ---
	 * The most recent values the channel has measured will be reported in this event, which occurs when the `dataInterval` has elapsed.
	 */
	onCountChange: ((counts: number, timeChange: number) => void) | null = null;
	/** @internal */
	_gotCountChangeErrorEvent?: boolean;

	/**
	 * The Frequency Counter class is used to measure the frequency of pulses in an electronic signal, or to count the pulses in the signal. Such signals can come from other electronics, or certain sensors that have a pulse output.
	 * @public
	 */
	constructor();
	/** @internal */
	constructor(ch?: Channel);
	constructor(ch?: Channel) {
		super(ch);
		this._class = ChannelClass.FREQUENCY_COUNTER;
		this.name = "FrequencyCounter";
		this.data = this._initData();
	}

	/** @internal */
	_bridgeInput(bp: BridgePacket) {

		switch(bp.vpkt) {
		case BP.SETENABLED:
			this.data.enabled = bp.entries[0].v as number;
			this._FIREPropertyChange('Enabled', bp);
			break;
		case BP.SETDATAINTERVAL:
			if (bp.entryCount > 1)
				this.data.dataInterval = bp.entries[1].v as number;
			else
				this.data.dataInterval = bp.entries[0].v as number;
			this._FIREPropertyChange('DataInterval', bp);
			this._FIREPropertyChange('DataRate', bp);
			break;
		case BP.SETFILTERTYPE:
			this.data.filterType = bp.entries[0].v as Enum.FrequencyFilterType;
			this._FIREPropertyChange('FilterType', bp);
			break;
		case BP.SETINPUTMODE:
			this.data.inputMode = bp.entries[0].v as Enum.InputMode;
			this._FIREPropertyChange('InputMode', bp);
			break;
		case BP.SETPOWERSUPPLY:
			this.data.powerSupply = bp.entries[0].v as Enum.PowerSupply;
			this._FIREPropertyChange('PowerSupply', bp);
			break;
		case BP.COUNTCHANGE: {
			if (this._isAttachedDone && this.onCountChange) {
				try {
					this.onCountChange(bp.entries[0].v as number, bp.entries[1].v as number);
				} catch (err) { logEventException(err); }
			}
			break;
		}
		case BP.FREQUENCYCHANGE: {
			this.data.frequency = bp.entries[0].v as number;
			if (this._isAttachedDone && this.onFrequencyChange) {
				try {
					this.onFrequencyChange(this.data.frequency);
				} catch (err) { logEventException(err); }
			}
			break;
		}
		default:
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			throw new PhidgetError(ErrorCode.INVALID_PACKET, "Unsupported bridge packet: 0x" + bp.vpkt!.toString(16));
		}
	}

	/** @internal */
	_initData(): FrequencyCounterData {
		return {
			totalTicksSinceLastCount: 0, 
			frequencyPrecision: 0, 
			dataInterval: PUNK.DBL,
			minDataInterval: PUNK.UINT32,
			maxDataInterval: PUNK.UINT32,
			minDataRate: PUNK.DBL,
			maxDataRate: PUNK.DBL,
			maxFrequency: PUNK.DBL,
			maxFrequencyCutoff: PUNK.DBL,
			minFrequencyCutoff: PUNK.DBL,
			count: PUNK.UINT64,
			timeElapsed: PUNK.DBL,
			frequencyCutoff: PUNK.DBL,
			frequency: PUNK.DBL,
			filterType: PUNK.ENUM,
			powerSupply: PUNK.ENUM,
			inputMode: PUNK.ENUM,
			enabled: PUNK.BOOL,
		}
	}

	/** @internal */
	_initAfterOpen() {
		this.data = this._initData();

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._DAQ1400_FREQUENCYCOUNTER_100:
		case DeviceChannelUID._DAQ1400_FREQUENCYCOUNTER_120:
			this.data.dataInterval = 250;
			this.data.minDataInterval = 20;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 50;
			this.data.maxFrequency = 1000000;
			this.data.maxFrequencyCutoff = 10;
			this.data.minFrequencyCutoff = 0.01;
			this.data.count = 0;
			this.data.timeElapsed = 0;
			this.data.frequencyCutoff = 1;
			this.data.frequency = 0;
			this.data.filterType = Enum.FrequencyFilterType.LOGIC_LEVEL;
			this.data.powerSupply = Enum.PowerSupply.VOLTS_12;
			this.data.inputMode = Enum.InputMode.NPN;
			this.data.enabled = 1;
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	// eslint-disable-next-line require-await
	async _setDefaults() {
		let bp;

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._DAQ1400_FREQUENCYCOUNTER_100:
		case DeviceChannelUID._DAQ1400_FREQUENCYCOUNTER_120:
			bp = new BridgePacket();
			bp.set({ name: "0", type: "u", value: Math.round(this.data.dataInterval) });
			await bp.send(this._ch, BP.SETDATAINTERVAL);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "d", value: this.data.powerSupply });
			await bp.send(this._ch, BP.SETPOWERSUPPLY);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "d", value: this.data.inputMode });
			await bp.send(this._ch, BP.SETINPUTMODE);
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	_hasInitialState() {

		if ((this.data.frequency == PUNK.DBL)
			&& ! this._gotFrequencyChangeErrorEvent)
			return false;

		return true;
	}

	/** @internal */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	_fireInitialEvents() {

		if(this.data.frequency != PUNK.DBL)
			if (this.onFrequencyChange)
				try {
					this.onFrequencyChange(this.data.frequency);
				} catch (err) { logEventException(err); }

	}

	/**
	 * The most recent count value the channel has reported.
	 * 
	 * *   The count represents the total number of pulses since the the channel was opened, or last reset.
	 * @throws {@link PhidgetError}
	 */
	get count() { return this.getCount(); }
	/**
	 * Enables or disables the channel.
	 * 
	 * *   When a channel is disabled, it will not longer register counts, therefore the `timeElapsed` and `count` will not be updated until the channel is re-enabled.
	 * @throws {@link PhidgetError}
	 */
	get enabled() { return this.getEnabled(); }
	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `CountChange` / `FrequencyChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * @throws {@link PhidgetError}
	 */
	get dataInterval() { return this.getDataInterval(); }
	/**
	 * The minimum value that `dataInterval` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDataInterval() { return this.getMinDataInterval(); }
	/**
	 * The maximum value that `dataInterval` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDataInterval() { return this.getMaxDataInterval(); }
	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @throws {@link PhidgetError}
	 */
	get dataRate() { return this.getDataRate(); }
	/**
	 * The minimum value that `dataRate` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDataRate() { return this.getMinDataRate(); }
	/**
	 * The maximum value that `dataRate` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDataRate() { return this.getMaxDataRate(); }
	/**
	 * Determines the signal type that the channel responds to.
	 * 
	 * *   The filter type is chosen based on the type of input signal. See the `phidget22.FrequencyFilterType` entry under Enumerations for more information.
	 * @throws {@link PhidgetError}
	 */
	get filterType() { return this.getFilterType(); }
	/**
	 * The most recent frequency value that the channel has reported.
	 * 
	 * *   This value will always be between 0 Hz and `maxFrequency`.
	 * @throws {@link PhidgetError}
	 */
	get frequency() { return this.getFrequency(); }
	/**
	 * The maximum value the `FrequencyChange` event will report.
	 * @throws {@link PhidgetError}
	 */
	get maxFrequency() { return this.getMaxFrequency(); }
	/**
	 * The frequency at which zero hertz is assumed.
	 * 
	 * *   This means any frequency at or below the `frequencyCutoff` value will be reported as 0 Hz.
	 * 
	 * *   This property is stored locally, so other users who have this Phidget open over a network connection won't see the effects of your selected cutoff.
	 * @throws {@link PhidgetError}
	 */
	get frequencyCutoff() { return this.getFrequencyCutoff(); }
	set frequencyCutoff(frequencyCutoff: number) { this.setFrequencyCutoff(frequencyCutoff); }
	/**
	 * The minimum value that `frequencyCutoff` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minFrequencyCutoff() { return this.getMinFrequencyCutoff(); }
	/**
	 * The maximum value that `frequencyCutoff` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxFrequencyCutoff() { return this.getMaxFrequencyCutoff(); }
	/**
	 * The input polarity mode for your channel.
	 * 
	 * *   See your device's User Guide for more information about what value to chooose for the `inputMode`
	 * @throws {@link PhidgetError}
	 */
	get inputMode() { return this.getInputMode(); }
	/**
	 * Choose the power supply voltage.
	 * 
	 * *   Set this to the voltage specified in the attached sensor's data sheet to power it.
	 * 
	 * *   Set to `phidget22.PowerSupply.OFF` to turn off the supply to save power.
	 * @throws {@link PhidgetError}
	 */
	get powerSupply() { return this.getPowerSupply(); }
	/**
	 * The amount of time the frequency counter has been enabled for.
	 * 
	 * *   This property complements `count`, the total number of pulses detected since the channel was opened, or last reset.
	 * @throws {@link PhidgetError}
	 */
	get timeElapsed() { return this.getTimeElapsed(); }

	/**
	 * The most recent count value the channel has reported.
	 * 
	 * *   The count represents the total number of pulses since the the channel was opened, or last reset.
	 * @returns The count value
	 * @throws {@link PhidgetError}
	 */
	getCount(): number {
		this._assertOpen();

		if (this.data.count === PUNK.UINT64)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.count);
	}

	/**
	 * Enables or disables the channel.
	 * 
	 * *   When a channel is disabled, it will not longer register counts, therefore the `timeElapsed` and `count` will not be updated until the channel is re-enabled.
	 * @throws {@link PhidgetError}
	 * @param enabled - The enabled value
	 */
	async setEnabled(enabled: boolean): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (enabled !== false && enabled !== true)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be a boolean.");

		bp.set({ name: "0", type: "d", value: (enabled ? 1 : 0) });
		await bp.send(this._ch, BP.SETENABLED);
	}

	/**
	 * Enables or disables the channel.
	 * 
	 * *   When a channel is disabled, it will not longer register counts, therefore the `timeElapsed` and `count` will not be updated until the channel is re-enabled.
	 * @returns The enabled value
	 * @throws {@link PhidgetError}
	 */
	getEnabled(): boolean {
		this._assertOpen();

		if (this.data.enabled === PUNK.BOOL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (!!this.data.enabled);
	}

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `CountChange` / `FrequencyChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getDataInterval(): number {
		this._assertOpen();

		if (this.data.dataInterval === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return this.data.dataInterval;
	}

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `CountChange` / `FrequencyChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * @throws {@link PhidgetError}
	 * @param dataInterval - The data interval value
	 */
	async setDataInterval(dataInterval: number): Promise<void> {
		this._assertOpen();

		if (dataInterval < this.data.minDataInterval || dataInterval > this.data.maxDataInterval)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDataInterval + " - " + this.data.maxDataInterval + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: dataInterval });
		await bp.send(this._ch, BP.SETDATAINTERVAL);
	}

	/**
	 * The minimum value that `dataInterval` can be set to.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getMinDataInterval(): number {
		this._assertOpen();

		if (this.data.minDataInterval === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDataInterval);
	}

	/**
	 * The maximum value that `dataInterval` can be set to.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getMaxDataInterval(): number {
		this._assertOpen();

		if (this.data.maxDataInterval === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDataInterval);
	}

	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @returns The data rate for the channel
	 * @throws {@link PhidgetError}
	 */
	getDataRate(): number {
		this._assertOpen();

		if (this.data.dataInterval === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (1000.0 / this.data.dataInterval);
	}

	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @throws {@link PhidgetError}
	 * @param dataRate - The data rate for the channel
	 */
	async setDataRate(dataRate: number): Promise<void> {
		this._assertOpen();

		if (dataRate < this.data.minDataRate || dataRate > this.data.maxDataRate)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDataRate + " - " + this.data.maxDataRate + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: Math.round(1000.0 / dataRate) });
		bp.set({ name: "1", type: "g", value: (1000.0 / dataRate) });
		await bp.send(this._ch, BP.SETDATAINTERVAL);
	}

	/**
	 * The minimum value that `dataRate` can be set to.
	 * @returns The data rate value
	 * @throws {@link PhidgetError}
	 */
	getMinDataRate(): number {
		this._assertOpen();

		if (this.data.minDataRate === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDataRate);
	}

	/**
	 * The maximum value that `dataRate` can be set to.
	 * @returns The data rate value
	 * @throws {@link PhidgetError}
	 */
	getMaxDataRate(): number {
		this._assertOpen();

		if (this.data.maxDataRate === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDataRate);
	}

	/**
	 * Determines the signal type that the channel responds to.
	 * 
	 * *   The filter type is chosen based on the type of input signal. See the `phidget22.FrequencyFilterType` entry under Enumerations for more information.
	 * @returns The filter value
	 * @throws {@link PhidgetError}
	 */
	getFilterType(): Enum.FrequencyFilterType {
		this._assertOpen();

		if (this.data.filterType === PUNK.ENUM)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.filterType);
	}

	/**
	 * Determines the signal type that the channel responds to.
	 * 
	 * *   The filter type is chosen based on the type of input signal. See the `phidget22.FrequencyFilterType` entry under Enumerations for more information.
	 * @throws {@link PhidgetError}
	 * @param filterType - The filter value
	 */
	async setFilterType(filterType: Enum.FrequencyFilterType): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (!SEnum.supportedFrequencyFilterType(this._ch!, filterType))
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Specified FrequencyFilterType is unsupported by this device.");

		bp.set({ name: "0", type: "d", value: filterType });
		await bp.send(this._ch, BP.SETFILTERTYPE);
	}

	/**
	 * The most recent frequency value that the channel has reported.
	 * 
	 * *   This value will always be between 0 Hz and `maxFrequency`.
	 * @returns The frequency value
	 * @throws {@link PhidgetError}
	 */
	getFrequency(): number {
		this._assertOpen();

		if (this.data.frequency === PUNK.DBL || Number.isNaN(this.data.frequency))
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);
		if (this.data.frequency > this.data.maxFrequency)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE_HIGH);

		return (this.data.frequency);
	}

	/**
	 * The maximum value the `FrequencyChange` event will report.
	 * @returns The frequency value
	 * @throws {@link PhidgetError}
	 */
	getMaxFrequency(): number {
		this._assertOpen();

		if (this.data.maxFrequency === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxFrequency);
	}

	/**
	 * The frequency at which zero hertz is assumed.
	 * 
	 * *   This means any frequency at or below the `frequencyCutoff` value will be reported as 0 Hz.
	 * 
	 * *   This property is stored locally, so other users who have this Phidget open over a network connection won't see the effects of your selected cutoff.
	 * @returns The frequency cutoff value
	 * @throws {@link PhidgetError}
	 */
	getFrequencyCutoff(): number {
		this._assertOpen();

		if (this.data.frequencyCutoff === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.frequencyCutoff);
	}

	/**
	 * The frequency at which zero hertz is assumed.
	 * 
	 * *   This means any frequency at or below the `frequencyCutoff` value will be reported as 0 Hz.
	 * 
	 * *   This property is stored locally, so other users who have this Phidget open over a network connection won't see the effects of your selected cutoff.
	 * @throws {@link PhidgetError}
	 * @param frequencyCutoff - The frequency cutoff value
	 */
	abstract setFrequencyCutoff(frequencyCutoff: number): void;
	/**
	 * The minimum value that `frequencyCutoff` can be set to.
	 * @returns The frequency value
	 * @throws {@link PhidgetError}
	 */
	getMinFrequencyCutoff(): number {
		this._assertOpen();

		if (this.data.minFrequencyCutoff === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minFrequencyCutoff);
	}

	/**
	 * The maximum value that `frequencyCutoff` can be set to.
	 * @returns The frequency value
	 * @throws {@link PhidgetError}
	 */
	getMaxFrequencyCutoff(): number {
		this._assertOpen();

		if (this.data.maxFrequencyCutoff === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxFrequencyCutoff);
	}

	/**
	 * The input polarity mode for your channel.
	 * 
	 * *   See your device's User Guide for more information about what value to chooose for the `inputMode`
	 * @returns The input mode value
	 * @throws {@link PhidgetError}
	 */
	getInputMode(): Enum.InputMode {
		this._assertOpen();

		if (this.data.inputMode === PUNK.ENUM)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.inputMode);
	}

	/**
	 * The input polarity mode for your channel.
	 * 
	 * *   See your device's User Guide for more information about what value to chooose for the `inputMode`
	 * @throws {@link PhidgetError}
	 * @param inputMode - The input mode value
	 */
	async setInputMode(inputMode: Enum.InputMode): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (!SEnum.supportedInputMode(this._ch!, inputMode))
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Specified InputMode is unsupported by this device.");

		bp.set({ name: "0", type: "d", value: inputMode });
		await bp.send(this._ch, BP.SETINPUTMODE);
	}

	/**
	 * Choose the power supply voltage.
	 * 
	 * *   Set this to the voltage specified in the attached sensor's data sheet to power it.
	 * 
	 * *   Set to `phidget22.PowerSupply.OFF` to turn off the supply to save power.
	 * @returns The power supply value
	 * @throws {@link PhidgetError}
	 */
	getPowerSupply(): Enum.PowerSupply {
		this._assertOpen();

		if (this.data.powerSupply === PUNK.ENUM)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.powerSupply);
	}

	/**
	 * Choose the power supply voltage.
	 * 
	 * *   Set this to the voltage specified in the attached sensor's data sheet to power it.
	 * 
	 * *   Set to `phidget22.PowerSupply.OFF` to turn off the supply to save power.
	 * @throws {@link PhidgetError}
	 * @param powerSupply - The power supply value
	 */
	async setPowerSupply(powerSupply: Enum.PowerSupply): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (!SEnum.supportedPowerSupply(this._ch!, powerSupply))
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Specified PowerSupply is unsupported by this device.");

		bp.set({ name: "0", type: "d", value: powerSupply });
		await bp.send(this._ch, BP.SETPOWERSUPPLY);
	}

	/**
	 * Resets the `count` and `timeElapsed`.
	 * 
	 * *   For best results, reset should be called when the channel is disabled.
	 * @throws {@link PhidgetError}
	 */
	abstract reset(): void;
	/**
	 * The amount of time the frequency counter has been enabled for.
	 * 
	 * *   This property complements `count`, the total number of pulses detected since the channel was opened, or last reset.
	 * @returns The time value
	 * @throws {@link PhidgetError}
	 */
	getTimeElapsed(): number {
		this._assertOpen();

		if (this.data.timeElapsed === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.timeElapsed);
	}

}
export { FrequencyCounterBase };
