import { SpatialBase } from './Spatial.gen';
import { PhidgetLock } from '../PhidgetLock';
import { BP } from '../BridgePackets.gen';
import { ErrorCode } from '../Enumerations.gen';
import { PhidgetError } from '../PhidgetError';
import { BridgePacket } from '../BridgePacket';
import { Channel } from '../Channel';
import { type SpatialQuaternion, type SpatialEulerAngles } from '../Structs.gen';
import { logEventException } from '../Logging';

/** @public */
class Spatial extends SpatialBase {
	/** @internal */
	_transactionLock: PhidgetLock;

	/** @internal */
	constructor();
	/** @internal */
	constructor(ch?: Channel);
	constructor(ch?: Channel) {
		super(ch);
		this._transactionLock = new PhidgetLock();
	}

	/** @internal */
	_bridgeInput(bp: BridgePacket) {
		switch (bp.vpkt) {
			case BP.DATAINTERVALCHANGE: {
				if (bp.entryCount > 1)
					this.data.dataInterval = bp.getNumber(1);
				else
					this.data.dataInterval = bp.getNumber(0);
				this._FIREPropertyChange('DataInterval');
				this._FIREPropertyChange('DataRate');
				break;
			}
			case BP.SPATIALALGDATA: {
				const quaternion = bp.getArray(0) as [number, number, number, number];
				const timestamp = bp.getNumber(1);

				this.data.quaternion = {
					x: quaternion[0],
					y: quaternion[1],
					z: quaternion[2],
					w: quaternion[3]
				}

				if (this._isAttachedDone && this.onAlgorithmData) {
					try {
						this.onAlgorithmData(quaternion, timestamp);
					} catch (err) { logEventException(err); }
				}
				break;
			}

			case BP.HEATINGENABLEDCHANGE:
				this.data.heatingEnabled = bp.entries[0].v as number;
				this._FIREPropertyChange('HeatingEnabled', bp);
				break;
				
			default:
				super._bridgeInput(bp);
				break;
		}
	}

	/** @internal */
	_hasInitialState() {
		if (this.data.quaternion === null)
			return false;

		return true;
	}

	getQuaternion(): SpatialQuaternion {
		this._assertOpen();

		if (this.data.quaternion === null)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.quaternion);
	}

	getEulerAngles(): SpatialEulerAngles {
		this._assertOpen();

		if (this.data.quaternion === null)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		const roll_rad = Math.atan2(2.0 * (this.data.quaternion.x * this.data.quaternion.w + this.data.quaternion.y * this.data.quaternion.z), 1 - 2.0 * (this.data.quaternion.x * this.data.quaternion.x + this.data.quaternion.y * this.data.quaternion.y));
		const pitch_rad = Math.asin(2.0 * (this.data.quaternion.w * this.data.quaternion.y - this.data.quaternion.z * this.data.quaternion.x));
		const heading_rad = Math.atan2(2.0 * (this.data.quaternion.w * this.data.quaternion.z + this.data.quaternion.x * this.data.quaternion.y), 1 - 2.0 * (this.data.quaternion.y * this.data.quaternion.y + this.data.quaternion.z * this.data.quaternion.z));

		// Convert radians to degrees
		const eulerAngles = {
			heading: heading_rad * 180.0 / Math.PI,
			pitch: pitch_rad * 180.0 / Math.PI,
			roll: roll_rad * 180.0 / Math.PI
		};

		return (eulerAngles);
	}
}

export { Spatial };