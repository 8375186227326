import { BridgePacket } from "../BridgePacket";
import { Channel, type ChannelData } from "../Channel";
import { NetworkConnectionBase, P22MSG, P22SMSG } from "./NetworkConnection";
import { NetworkDevice } from "./NetworkDevice";
import { PhidgetChannel } from "../Phidget";
import { ChannelClass } from "../Enumerations.gen";

/** @internal */
export interface NetworkChannelData extends ChannelData {
	cpversion: number;
	name: string;
	class: ChannelClass;
}

/** @internal */
class NetworkChannel extends Channel {
	declare conn: NetworkConnectionBase;
	declare parent: NetworkDevice;
	private cpversion: number;
	name: string;
	class: ChannelClass;

	constructor(conn: NetworkConnectionBase, dev: NetworkDevice, data: NetworkChannelData) {
		super(conn, dev, data);
		this.cpversion = data.cpversion;
		this.name = data.name;
		this.class = data.class;
	}

	async open(userphid: PhidgetChannel) {
		// Network Connection
		const devInfo = {
			phid: this.parent.id,
			channel: this.id,
			class: this.class,
			index: this.uniqueIndex,
			version: this.cpversion
		};
		try {
			userphid._attaching = true;
			const status = await this.conn._sendRequest(0, 0, 0, P22MSG.Device, P22SMSG.Open, devInfo);
			userphid._attaching = false;
			this.isopen = true;
			this.userphid = userphid;

			/* deliver the status data */
			const bp = new BridgePacket(this.conn, status);
			bp.deliver();

			await userphid._wasOpened(this);
		} catch (err) {
			userphid._attaching = false;
			userphid._isattached = false;
			throw (err);
		}
	}

	/*
	 * Currently only expected to be called from Phidget.close().
	 * We do not notify the Phidget.
	 */
	async close() {

		if (this.detaching)
			return;

		const closePkt = {
			phid: this.parent.id,
			index: this.uniqueIndex,
		};
		await this.conn._sendRequest(0, 0, 0, P22MSG.Device, P22SMSG.Close, closePkt);
	}

	async send(bp: BridgePacket): Promise<string | void> {
		// We know it's a NetworkChannel
		const pkt = bp.getJsonOut(this);
		const response = await this.conn._sendRequest(0, 0, 0, P22MSG.Device, P22SMSG.BridgePkt, pkt);
		return response;
	}
}

export { NetworkChannel };