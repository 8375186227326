import { ErrorCode, ChannelClass, RFIDProtocol } from '../../Enumerations.gen';
import { PhidgetError } from '../../PhidgetError';
import { PhidgetUSBRequestType } from '../USB';
import { BridgePacket, PUNK } from '../../BridgePacket';
import { BP } from '../../BridgePackets.gen';
import { DeviceUID } from '../../Devices.gen';
import * as VintPacketType from '../VintPacketType';
import { Channel } from '../../Channel';
import { RFID } from '../../class/RFID';
import { PhidgetUSBDevice, PhidgetUSBDeviceData } from '../PhidgetUSBDevice';
import { MAX_OUT_PACKET_SIZE } from '../LocalDevice';
import { USBConnectionBase } from '../USBConnection';

class RFIDDevice extends PhidgetUSBDevice {

	data: {
		outputState: number[];
		antennaEnabled: number[];
		tagPresent: number[];
	};

	constructor(conn: USBConnectionBase, data: PhidgetUSBDeviceData, usbDev: USBDevice) {
		super(conn, data, usbDev);

		// Ensure that we actually support this device
		switch (this.devDef.uid) {
			/*START_UNRELEASED*/
			case DeviceUID._1024_V2_USB:
				break;
			/*END_UNRELEASED*/
			default:
				throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}

		this.data = {
			antennaEnabled: [PUNK.BOOL],
			tagPresent: [0],
			outputState: new Array(this.numOutputs).fill(PUNK.BOOL),
		};
	}

	// Define getters based on devices.h Attr structs in C library
	get numOutputs() { return this.devDef.cn[0]; }

	// eslint-disable-next-line require-await
	async initAfterOpen() {
		const buffer = await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_DEVICE_READ, 5, 0, 5);
		this.data.antennaEnabled[0] = (buffer.getUint8(0) & 0x01) ? 1 : 0;
		this.data.outputState[0] = (buffer.getUint8(0) & 0x02) ? 1 : 0;
		this.data.outputState[1] = (buffer.getUint8(0) & 0x04) ? 1 : 0;
		this.data.outputState[2] = (buffer.getUint8(0) & 0x08) ? 1 : 0;
	}

	async bridgeInput(ch: Channel, bp: BridgePacket) {
		const buf = new DataView(new ArrayBuffer(MAX_OUT_PACKET_SIZE));
		let protocol;
		let tagString;
		let dutyCycle;
		let len;

		switch (ch.class) {
			case ChannelClass.DIGITAL_OUTPUT:
				switch (bp.vpkt) {
					case BP.SETSTATE:
						this.data.outputState[ch.index] = bp.getNumber(0);
						buf.setUint8(0, bp.getNumber(0));
						await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.DigitalOutputPacket.SETDUTYCYCLE, ch.uniqueIndex, new Uint8Array(buf.buffer, 0, 1));
						break;
					case BP.SETDUTYCYCLE:
						dutyCycle = bp.getNumber(0);
						if (dutyCycle !== 0 && dutyCycle !== 1)
							throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Duty cycle myust be 0 or 1.");
						this.data.outputState[ch.index] = dutyCycle;
						buf.setUint8(0, dutyCycle);
						await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.DigitalOutputPacket.SETDUTYCYCLE, ch.uniqueIndex, new Uint8Array(buf.buffer, 0, 1));
						break;
					case BP.OPENRESET:
					case BP.CLOSERESET:
						buf.setUint8(0, 0);
						await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.DigitalOutputPacket.SETDUTYCYCLE, ch.uniqueIndex, new Uint8Array(buf.buffer, 0, 1));
						break;
					case BP.ENABLE:
						break;
					default:
						throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type");
				}
				break;
			case ChannelClass.RFID:
				if (ch.index !== 0) {
					throw new PhidgetError(ErrorCode.UNEXPECTED);
				}
				switch (bp.vpkt) {
					case BP.SETANTENNAON:
						buf.setUint8(0, bp.getNumber(0));
						this.data.antennaEnabled[0] = bp.getNumber(0);
						await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.RFIDPacket.ANTENNA_ON, ch.uniqueIndex, new Uint8Array(buf.buffer, 0, 1));
						break;
					case BP.WRITE: {
						protocol = bp.getNumber(1);
						if (protocol < RFIDProtocol.EM4100 || protocol > RFIDProtocol.PHIDGET_TAG)
							throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Invalid protocol type");

						if (this.data.antennaEnabled[0] !== 1)
							throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Cannot write tag while antenna is off");

						buf.setUint8(0, bp.getNumber(1));
						buf.setUint8(1, bp.getNumber(2));

						tagString = bp.getString(0);
						if (protocol === RFIDProtocol.EM4100 && tagString.charAt(1) === 'x')
							tagString = tagString.slice(2);
						if (tagString.length > (MAX_OUT_PACKET_SIZE - 3))
							throw new PhidgetError(ErrorCode.INVALID_ARGUMENT);
						for (let i = 0; i < tagString.length; i++)
							buf.setUint8(2 + i, tagString.charCodeAt(i));
						buf.setUint8(2 + tagString.length, 0x00);   //append \0 after string

						if (protocol === RFIDProtocol.EM4100) {
							for (let i = 0; i < 10; i++) {
								const toLower = String.fromCharCode(buf.getUint8(2 + i)).toLowerCase();
								buf.setUint8(2 + i, toLower.charCodeAt(0));
							}
						}

						len = tagString.length + 2;

						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						const userphid = (ch.userphid! as unknown as RFID);
						userphid._setLatestTagString("");   //clear latest tag

						await this.transferPacket(PhidgetUSBRequestType.PHIDGETUSB_REQ_CHANNEL_WRITE, VintPacketType.RFIDPacket.TAG_WRITE, ch.uniqueIndex, new Uint8Array(buf.buffer, 0, len));
						try {
							await userphid._waitForTag(tagString, 600);
						} catch {
							throw new PhidgetError(ErrorCode.TIMEOUT, "Timed out waiting for tag to appear after writing. Try again.");
						}
						break;
					}
					case BP.OPENRESET:
					case BP.CLOSERESET:
					case BP.ENABLE:
						break;
					default:
						throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type");
				}
				break;
			default:
				throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected channel class");
		}
	}

	dataInput(buffer: DataView) {
		const pkt = buffer.getUint8(0);
		const ch = this.getChannel(0);
		let tagString;

		if (ch) {
			const bp = new BridgePacket();
			switch (pkt) {
				case VintPacketType.RFIDPacket.TAG: {
					tagString = "";

					for (let i = 2, byteChar = buffer.getUint8(i); i < buffer.byteLength && String.fromCharCode(byteChar) !== '\0'; i++, byteChar = buffer.getUint8(i)) {
						tagString += String.fromCharCode(byteChar);
					}

					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					const userphid = (ch.userphid! as unknown as RFID);
					userphid._setLatestTagString(tagString);

					this.data.tagPresent[0] = 1;

					bp.set({ name: '0', type: 's', value: tagString });
					bp.set({ name: '1', type: 'd', value: buffer.getUint8(1) });
					bp.sendToChannel(ch, BP.TAG);

					break;
				}
				case VintPacketType.RFIDPacket.TAG_LOST:
					tagString = "";

					for (let i = 2, byteChar = buffer.getUint8(i); i < buffer.byteLength && String.fromCharCode(byteChar) !== '\0'; i++, byteChar = buffer.getUint8(i)) {
						tagString += String.fromCharCode(byteChar);
					}

					this.data.tagPresent[0] = 0;

					bp.set({ name: '0', type: 's', value: tagString });
					bp.set({ name: '1', type: 'd', value: buffer.getUint8(1) });
					bp.sendToChannel(ch, BP.TAGLOST);
					break;
				default:
					throw new PhidgetError(ErrorCode.UNEXPECTED, "Unexpected packet type.");
			}
		}
	}
}

export { RFIDDevice };