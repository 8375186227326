/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PhidgetChannel } from '../Phidget';
import { Channel } from '../Channel';
import { ErrorCode, ChannelClass } from '../Enumerations.gen';
import * as Enum from '../Enumerations.gen';
import * as SEnum from '../SupportedEnum.gen';
import { PhidgetError } from '../PhidgetError';
import { BridgePacket, PUNK } from '../BridgePacket';
import { BP } from '../BridgePackets.gen';
import { logEventException } from '../Logging';
import { DeviceChannelUID } from '../Devices.gen';

/** @internal */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StepperData {
	positionOffset: number, 
	dataInterval: number,
	minDataRate: number,
	maxDataRate: number,
	holdingCurrentLimit: number,
	minDataInterval: number,
	maxDataInterval: number,
	maxPosition: number,
	maxVelocityLimit: number,
	maxAcceleration: number,
	maxCurrentLimit: number,
	minPosition: number,
	minVelocityLimit: number,
	minAcceleration: number,
	minCurrentLimit: number,
	controlMode: Enum.StepperControlMode | PUNK.ENUM,
	position: number,
	targetPosition: number,
	velocityLimit: number,
	velocity: number,
	acceleration: number,
	currentLimit: number,
	isMoving: number,
	engaged: number,
	rescaleFactor: number,
	maxFailsafeTime: number,
	minFailsafeTime: number,
}

abstract class StepperBase extends PhidgetChannel {
	/** @internal */
	data: StepperData;
	/**
	 * **PositionChange** event
	 *  * `position` - The current stepper position
	 * ---
	 * Occurs when the controller updates the stepper motor position.
	 * 
	 * *   This event will still fire even if the motor is blocked from physically moving or misses steps.
	 */
	onPositionChange: ((position: number) => void) | null = null;
	/** @internal */
	_gotPositionChangeErrorEvent?: boolean;
	/**
	 * **VelocityChange** event
	 *  * `velocity` - Velocity of the stepper. Sign indicates direction.
	 * ---
	 * Occurs when the stepper motor velocity changes.
	 */
	onVelocityChange: ((velocity: number) => void) | null = null;
	/** @internal */
	_gotVelocityChangeErrorEvent?: boolean;
	/**
	 * **Stopped** event
	 * ---
	 * Occurs when the motor controller stops.
	 * 
	 * *   The motor may still be physically moving if the inertia is great enough to make it misstep.
	 */
	onStopped: (() => void) | null = null;
	/** @internal */
	_gotStoppedErrorEvent?: boolean;

	/**
	 * The Stepper class powers and controls the stepper motor connected to the Phidget controller, allowing you to change the position, velocity, acceleration, and current limit.
	 * @public
	 */
	constructor();
	/** @internal */
	constructor(ch?: Channel);
	constructor(ch?: Channel) {
		super(ch);
		this._class = ChannelClass.STEPPER;
		this.name = "Stepper";
		this.data = this._initData();
	}

	/** @internal */
	_bridgeInput(bp: BridgePacket) {

		switch(bp.vpkt) {
		case BP.SETACCELERATION:
			this.data.acceleration = bp.entries[0].v as number;
			this._FIREPropertyChange('Acceleration', bp);
			break;
		case BP.SETCONTROLMODE:
			this.data.controlMode = bp.entries[0].v as Enum.StepperControlMode;
			this._FIREPropertyChange('ControlMode', bp);
			break;
		case BP.SETCURRENTLIMIT:
			this.data.currentLimit = bp.entries[0].v as number;
			this._FIREPropertyChange('CurrentLimit', bp);
			break;
		case BP.SETDATAINTERVAL:
			if (bp.entryCount > 1)
				this.data.dataInterval = bp.entries[1].v as number;
			else
				this.data.dataInterval = bp.entries[0].v as number;
			this._FIREPropertyChange('DataInterval', bp);
			this._FIREPropertyChange('DataRate', bp);
			break;
		case BP.SETENGAGED:
			this.data.engaged = bp.entries[0].v as number;
			this._FIREPropertyChange('Engaged', bp);
			break;
		case BP.SETFAILSAFETIME:
			break;
		case BP.SETHOLDINGCURRENTLIMIT:
			this.data.holdingCurrentLimit = bp.entries[0].v as number;
			this._FIREPropertyChange('HoldingCurrentLimit', bp);
			break;
		case BP.FAILSAFERESET:
			break;
		case BP.SETTARGETPOSITION:
			this.data.targetPosition = bp.entries[0].v as number;
			this._FIREPropertyChange('TargetPosition', bp);
			break;
		case BP.SETVELOCITYLIMIT:
			this.data.velocityLimit = bp.entries[0].v as number;
			this._FIREPropertyChange('VelocityLimit', bp);
			break;
		case BP.STOPPED: {
			if (this._isAttachedDone && this.onStopped) {
				try {
					this.onStopped();
				} catch (err) { logEventException(err); }
			}
			break;
		}
		default:
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			throw new PhidgetError(ErrorCode.INVALID_PACKET, "Unsupported bridge packet: 0x" + bp.vpkt!.toString(16));
		}
	}

	/** @internal */
	_initData(): StepperData {
		return {
			positionOffset: 0, 
			dataInterval: PUNK.DBL,
			minDataRate: PUNK.DBL,
			maxDataRate: PUNK.DBL,
			holdingCurrentLimit: PUNK.DBL,
			minDataInterval: PUNK.UINT32,
			maxDataInterval: PUNK.UINT32,
			maxPosition: PUNK.INT64,
			maxVelocityLimit: PUNK.DBL,
			maxAcceleration: PUNK.DBL,
			maxCurrentLimit: PUNK.DBL,
			minPosition: PUNK.INT64,
			minVelocityLimit: PUNK.DBL,
			minAcceleration: PUNK.DBL,
			minCurrentLimit: PUNK.DBL,
			controlMode: PUNK.ENUM,
			position: PUNK.INT64,
			targetPosition: PUNK.INT64,
			velocityLimit: PUNK.DBL,
			velocity: PUNK.DBL,
			acceleration: PUNK.DBL,
			currentLimit: PUNK.DBL,
			isMoving: PUNK.BOOL,
			engaged: PUNK.BOOL,
			rescaleFactor: PUNK.DBL,
			maxFailsafeTime: PUNK.UINT32,
			minFailsafeTime: PUNK.UINT32,
		}
	}

	/** @internal */
	_initAfterOpen() {
		this.data = this._initData();

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._STC1000_STEPPER_100:
		case DeviceChannelUID._STC1003_STEPPER_100:
			this.data.dataInterval = 250;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 10;
			this.data.minDataInterval = 100;
			this.data.maxDataInterval = 60000;
			this.data.maxPosition = 1000000000000000;
			this.data.maxVelocityLimit = 115000;
			this.data.maxAcceleration = 10000000;
			this.data.maxCurrentLimit = 4;
			this.data.minPosition = -1000000000000000;
			this.data.minVelocityLimit = 0;
			this.data.minAcceleration = 2;
			this.data.minCurrentLimit = 0;
			this.data.controlMode = Enum.StepperControlMode.STEP;
			this.data.velocityLimit = 10000;
			this.data.acceleration = 10000;
			this.data.currentLimit = 1;
			this.data.engaged = 0;
			this.data.rescaleFactor = 1;
			break;
		case DeviceChannelUID._STC1000_STEPPER_110:
		case DeviceChannelUID._STC1003_STEPPER_110:
		case DeviceChannelUID._STC1003_STEPPER_200:
		case DeviceChannelUID._STC1005_STEPPER_100:
			this.data.dataInterval = 250;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 10;
			this.data.minDataInterval = 100;
			this.data.maxDataInterval = 60000;
			this.data.maxPosition = 1000000000000000;
			this.data.maxVelocityLimit = 115000;
			this.data.maxAcceleration = 10000000;
			this.data.maxCurrentLimit = 4;
			this.data.minPosition = -1000000000000000;
			this.data.minVelocityLimit = 0;
			this.data.minAcceleration = 2;
			this.data.minCurrentLimit = 0;
			this.data.controlMode = Enum.StepperControlMode.STEP;
			this.data.velocityLimit = 10000;
			this.data.acceleration = 10000;
			this.data.currentLimit = 1;
			this.data.engaged = 0;
			this.data.rescaleFactor = 1;
			this.data.maxFailsafeTime = 30000;
			this.data.minFailsafeTime = 500;
			break;
		case DeviceChannelUID._STC1001_STEPPER_100:
			this.data.dataInterval = 250;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 10;
			this.data.minDataInterval = 100;
			this.data.maxDataInterval = 60000;
			this.data.maxPosition = 1000000000000000;
			this.data.maxVelocityLimit = 115000;
			this.data.maxAcceleration = 10000000;
			this.data.maxCurrentLimit = 2.5;
			this.data.minPosition = -1000000000000000;
			this.data.minVelocityLimit = 0;
			this.data.minAcceleration = 2;
			this.data.minCurrentLimit = 0;
			this.data.controlMode = Enum.StepperControlMode.STEP;
			this.data.velocityLimit = 10000;
			this.data.acceleration = 10000;
			this.data.currentLimit = 1;
			this.data.engaged = 0;
			this.data.rescaleFactor = 1;
			break;
		case DeviceChannelUID._STC1001_STEPPER_110:
			this.data.dataInterval = 250;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 10;
			this.data.minDataInterval = 100;
			this.data.maxDataInterval = 60000;
			this.data.maxPosition = 1000000000000000;
			this.data.maxVelocityLimit = 115000;
			this.data.maxAcceleration = 10000000;
			this.data.maxCurrentLimit = 2.5;
			this.data.minPosition = -1000000000000000;
			this.data.minVelocityLimit = 0;
			this.data.minAcceleration = 2;
			this.data.minCurrentLimit = 0;
			this.data.controlMode = Enum.StepperControlMode.STEP;
			this.data.velocityLimit = 10000;
			this.data.acceleration = 10000;
			this.data.currentLimit = 1;
			this.data.engaged = 0;
			this.data.rescaleFactor = 1;
			this.data.maxFailsafeTime = 30000;
			this.data.minFailsafeTime = 500;
			break;
		case DeviceChannelUID._STC1002_STEPPER_100:
			this.data.dataInterval = 250;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 10;
			this.data.minDataInterval = 100;
			this.data.maxDataInterval = 60000;
			this.data.maxPosition = 1000000000000000;
			this.data.maxVelocityLimit = 115000;
			this.data.maxAcceleration = 10000000;
			this.data.maxCurrentLimit = 8;
			this.data.minPosition = -1000000000000000;
			this.data.minVelocityLimit = 0;
			this.data.minAcceleration = 2;
			this.data.minCurrentLimit = 0;
			this.data.controlMode = Enum.StepperControlMode.STEP;
			this.data.velocityLimit = 10000;
			this.data.acceleration = 10000;
			this.data.currentLimit = 1;
			this.data.engaged = 0;
			this.data.rescaleFactor = 1;
			break;
		case DeviceChannelUID._STC1002_STEPPER_110:
			this.data.dataInterval = 250;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 10;
			this.data.minDataInterval = 100;
			this.data.maxDataInterval = 60000;
			this.data.maxPosition = 1000000000000000;
			this.data.maxVelocityLimit = 115000;
			this.data.maxAcceleration = 10000000;
			this.data.maxCurrentLimit = 8;
			this.data.minPosition = -1000000000000000;
			this.data.minVelocityLimit = 0;
			this.data.minAcceleration = 2;
			this.data.minCurrentLimit = 0;
			this.data.controlMode = Enum.StepperControlMode.STEP;
			this.data.velocityLimit = 10000;
			this.data.acceleration = 10000;
			this.data.currentLimit = 1;
			this.data.engaged = 0;
			this.data.rescaleFactor = 1;
			this.data.maxFailsafeTime = 30000;
			this.data.minFailsafeTime = 500;
			break;
		case DeviceChannelUID._STC1005_STEPPER_110:
			this.data.dataInterval = 250;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 100;
			this.data.minDataInterval = 10;
			this.data.maxDataInterval = 60000;
			this.data.maxPosition = 1000000000000000;
			this.data.maxVelocityLimit = 115000;
			this.data.maxAcceleration = 10000000;
			this.data.maxCurrentLimit = 4;
			this.data.minPosition = -1000000000000000;
			this.data.minVelocityLimit = 0;
			this.data.minAcceleration = 2;
			this.data.minCurrentLimit = 0;
			this.data.controlMode = Enum.StepperControlMode.STEP;
			this.data.velocityLimit = 10000;
			this.data.acceleration = 10000;
			this.data.currentLimit = 1;
			this.data.engaged = 0;
			this.data.rescaleFactor = 1;
			this.data.maxFailsafeTime = 30000;
			this.data.minFailsafeTime = 500;
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	// eslint-disable-next-line require-await
	async _setDefaults() {
		let bp;

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._STC1000_STEPPER_100:
		case DeviceChannelUID._STC1000_STEPPER_110:
		case DeviceChannelUID._STC1001_STEPPER_100:
		case DeviceChannelUID._STC1001_STEPPER_110:
		case DeviceChannelUID._STC1002_STEPPER_100:
		case DeviceChannelUID._STC1002_STEPPER_110:
		case DeviceChannelUID._STC1003_STEPPER_100:
		case DeviceChannelUID._STC1003_STEPPER_110:
		case DeviceChannelUID._STC1003_STEPPER_200:
		case DeviceChannelUID._STC1005_STEPPER_100:
		case DeviceChannelUID._STC1005_STEPPER_110:
			bp = new BridgePacket();
			bp.set({ name: "0", type: "u", value: Math.round(this.data.dataInterval) });
			await bp.send(this._ch, BP.SETDATAINTERVAL);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "d", value: this.data.controlMode });
			await bp.send(this._ch, BP.SETCONTROLMODE);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.velocityLimit });
			await bp.send(this._ch, BP.SETVELOCITYLIMIT);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.acceleration });
			await bp.send(this._ch, BP.SETACCELERATION);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.currentLimit });
			await bp.send(this._ch, BP.SETCURRENTLIMIT);
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	_hasInitialState() {


		return true;
	}

	/** @internal */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	_fireInitialEvents() {

	}

	/**
	 * The rate at which the controller can change the motor's `velocity`.
	 * 
	 * *   Units for `position`, `velocity`, and `acceleration` can be set by the user through the `rescaleFactor`.The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * *   The default units for this motor controller are **1/16steps per count**.
	 * *   Changing the acceleration value while the stepper is in motion (especially at speeds higher than 1000 1/16th steps/s) can cause unpredictable results due to the inability of the processor tocalculate a new acceleration curve quickly enough. Generally you should wait until the motor is stationary until calling this function.
	 * @throws {@link PhidgetError}
	 */
	get acceleration() { return this.getAcceleration(); }
	/**
	 * The minimum value that `acceleration` can be set to.
	 * 
	 * *   Units for `position`, `velocity`, and `acceleration` can be set by the user through the `rescaleFactor`.The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * *   The default units for this motor controller are **1/16steps per count**.
	 * @throws {@link PhidgetError}
	 */
	get minAcceleration() { return this.getMinAcceleration(); }
	/**
	 * The maximum value that `acceleration` can be set to.
	 * 
	 * *   Units for `position`, `velocity`, and `acceleration` can be set by the user through the `rescaleFactor`.The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * *   The default units for this motor controller are **1/16steps per count**.
	 * @throws {@link PhidgetError}
	 */
	get maxAcceleration() { return this.getMaxAcceleration(); }
	/**
	 * Use `phidget22.StepperControlMode.STEP` mode when you want to set a `targetPosition` for the Stepper motor. Use `phidget22.StepperControlMode.RUN` mode when you simply want the Stepper motor to rotate continuously in a specific direction.  
	 *   
	 * Changing the control mode while the motor is running will not have an effect on the motor's movements until a new `targetPosition` or `velocityLimit` is set.  
	 *   
	 * In `phidget22.StepperControlMode.RUN` mode, setting a `velocityLimit` will also set the target position of the controller to `minPosition` or `maxPosition`, corresponding to the direction of movement.
	 * @throws {@link PhidgetError}
	 */
	get controlMode() { return this.getControlMode(); }
	/**
	 * The current through the motor will be limited by the `currentLimit`.
	 * 
	 * *   See your Stepper motor's data sheet for more information about what value the `currentLimit` should be.
	 * @throws {@link PhidgetError}
	 */
	get currentLimit() { return this.getCurrentLimit(); }
	/**
	 * The minimum value that `currentLimit` and `holdingCurrentLimit` can be set to.
	 * 
	 * *   Reference your controller's User Guide for more information about how the `holdingCurrentLimit` and `currentLimit` can be used in your application.
	 * @throws {@link PhidgetError}
	 */
	get minCurrentLimit() { return this.getMinCurrentLimit(); }
	/**
	 * The maximum value that `currentLimit` and `holdingCurrentLimit` can be set to.
	 * 
	 * *   Reference your controller's User Guide for more information about how the `holdingCurrentLimit` and `currentLimit` can be used in your application.
	 * @throws {@link PhidgetError}
	 */
	get maxCurrentLimit() { return this.getMaxCurrentLimit(); }
	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `PositionChange` / `VelocityChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * @throws {@link PhidgetError}
	 */
	get dataInterval() { return this.getDataInterval(); }
	/**
	 * The minimum value that `dataInterval` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDataInterval() { return this.getMinDataInterval(); }
	/**
	 * The maximum value that `dataInterval` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDataInterval() { return this.getMaxDataInterval(); }
	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @throws {@link PhidgetError}
	 */
	get dataRate() { return this.getDataRate(); }
	/**
	 * The minimum value that `dataRate` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDataRate() { return this.getMinDataRate(); }
	/**
	 * The maximum value that `dataRate` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDataRate() { return this.getMaxDataRate(); }
	/**
	 * When this property is true, the controller will supply power to the motor coils.
	 * 
	 * *   The controller must be `engaged` in order to move the Stepper motor, or have it hold position.
	 * @throws {@link PhidgetError}
	 */
	get engaged() { return this.getEngaged(); }
	/**
	 * The minimum value that `failsafeTime` can be set to when calling `enableFailsafe()`.
	 * @throws {@link PhidgetError}
	 */
	get minFailsafeTime() { return this.getMinFailsafeTime(); }
	/**
	 * The maximum value that `failsafeTime` can be set to when calling `enableFailsafe()`.
	 * @throws {@link PhidgetError}
	 */
	get maxFailsafeTime() { return this.getMaxFailsafeTime(); }
	/**
	 * The `holdingCurrentLimit` will activate when the `targetPosition` has been reached. It will limit current through the motor.
	 * 
	 * *   When the motor is not stopped, the current through the motor is limited by the `currentLimit`.
	 * *   If no `holdingCurrentLimit` is specified, the `currentLimit` value will persist when the motor is stopped.
	 * *   Reference your controller's User Guide for more information about how the `holdingCurrentLimit` and `currentLimit` can be used in your application.
	 * @throws {@link PhidgetError}
	 */
	get holdingCurrentLimit() { return this.getHoldingCurrentLimit(); }
	/**
	 * `isMoving` returns true while the controller is sending commands to the motor. Note: there is no feedback to the controller, so it does not know whether the motor shaft is actually moving or not.
	 * @throws {@link PhidgetError}
	 */
	get isMoving() { return this.getIsMoving(); }
	/**
	 * The most recent position value that the controller has reported.
	 * 
	 * *   This value will always be between `minPosition` and `maxPosition`.
	 * *   Units for `position`, `velocity`, and `acceleration` can be set by the user through the `rescaleFactor`.The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * *   The default units for this motor controller are **1/16steps per count**.
	 * @throws {@link PhidgetError}
	 */
	get position() { return this.getPosition(); }
	/**
	 * The minimum value that `targetPosition` can be set to.
	 * 
	 * *   Units for `position`, `velocity`, and `acceleration` can be set by the user through the `rescaleFactor`.The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * *   The default units for this motor controller are **1/16steps per count**.
	 * @throws {@link PhidgetError}
	 */
	get minPosition() { return this.getMinPosition(); }
	/**
	 * The maximum value that `targetPosition` can be set to.
	 * 
	 * *   Units for `position`, `velocity`, and `acceleration` can be set by the user through the `rescaleFactor`.The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * *   The default units for this motor controller are **1/16steps per count**.
	 * @throws {@link PhidgetError}
	 */
	get maxPosition() { return this.getMaxPosition(); }
	/**
	 * Applies a factor to the \[user units\] per step to all movement parameters to make the units in your application is more intuitive.
	 * 
	 * *   For example, starting from position 0 and setting a new position with a rescale factor, the stepper will move `position` / `rescaleFactor` steps.
	 * *   In this way, units for `position`, `velocity`, and `acceleration` can be set by the user through the `rescaleFactor`. The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * *   The default units for this motor controller are **1/16steps per count**.
	 *   
	 * 
	 * RescaleFactor = (1/16) \* (MotorStepAngle/Degrees Per UserUnit)
	 * @throws {@link PhidgetError}
	 */
	get rescaleFactor() { return this.getRescaleFactor(); }
	set rescaleFactor(rescaleFactor: number) { this.setRescaleFactor(rescaleFactor); }
	/**
	 * If the controller is configured and the `targetPosition` is set, the Stepper motor will move towards the `targetPosition` at the specified `acceleration` and `velocity`.
	 * 
	 * *   `targetPosition` is only used when the `controlMode` is set to step mode.
	 * *   Units for `position`, `velocity`, and `acceleration` can be set by the user through the `rescaleFactor`.The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * *   The default units for this motor controller are **1/16steps per count**.
	 * @throws {@link PhidgetError}
	 */
	get targetPosition() { return this.getTargetPosition(); }
	/**
	 * The most recent velocity value that the controller has reported.
	 * 
	 * *   This value is bounded by `minVelocityLimit` and `maxVelocityLimit`.
	 * *   Units for `position`, `velocity`, and `acceleration` can be set by the user through the `rescaleFactor`.The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * *   The default units for this motor controller are **1/16steps per count**.
	 * @throws {@link PhidgetError}
	 */
	get velocity() { return this.getVelocity(); }
	/**
	 * When moving, the Stepper motor velocity will be limited by this value.
	 * 
	 * *   The `velocityLimit` is bounded by `minVelocityLimit` and `maxVelocityLimit`.
	 * *   When in step mode, the `minVelocityLimit` has a value of 0. This is because the sign (±) of the `targetPosition` will indicate the direction.
	 * *   When in run mode, the `minVelocityLimit` has a value of -`maxVelocityLimit`. This is because there is no target position, so the direction is defined by the sign (±) of the `velocityLimit`.
	 * *   Units for `position`, `velocity`, and `acceleration` can be set by the user through the `rescaleFactor`.The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * *   The default units for this motor controller are **1/16steps per count**.
	 * *   While `velocityLimit` is listed as a double, it is rounded down to an integer number of 1/16th steps when sent to the board since the board is limited by a minimum unit of 1/16th steps/s. This is especially important to consider when using different `rescaleFactor` values where converting to units of, for example, RPM results in 1.5RPM (80 1/16th steps/s) and 1.509375 RPM (80.5 1/16th steps/s) both being sent to the board as 80 1/16th steps/s.
	 * @throws {@link PhidgetError}
	 */
	get velocityLimit() { return this.getVelocityLimit(); }
	/**
	 * The minimum value that `velocityLimit` can be set to.
	 * 
	 * *   Units for `position`, `velocity`, and `acceleration` can be set by the user through the `rescaleFactor`.The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * *   The default units for this motor controller are **1/16steps per count**.
	 * @throws {@link PhidgetError}
	 */
	get minVelocityLimit() { return this.getMinVelocityLimit(); }
	/**
	 * The maximum value that `velocityLimit` can be set to.
	 * 
	 * *   Units for `position`, `velocity`, and `acceleration` can be set by the user through the `rescaleFactor`.The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * *   The default units for this motor controller are **1/16steps per count**.
	 * @throws {@link PhidgetError}
	 */
	get maxVelocityLimit() { return this.getMaxVelocityLimit(); }

	/**
	 * The rate at which the controller can change the motor's `velocity`.
	 * 
	 * *   Units for `position`, `velocity`, and `acceleration` can be set by the user through the `rescaleFactor`.The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * *   The default units for this motor controller are **1/16steps per count**.
	 * *   Changing the acceleration value while the stepper is in motion (especially at speeds higher than 1000 1/16th steps/s) can cause unpredictable results due to the inability of the processor tocalculate a new acceleration curve quickly enough. Generally you should wait until the motor is stationary until calling this function.
	 * @returns The acceleration value
	 * @throws {@link PhidgetError}
	 */
	abstract getAcceleration(): number;
	/**
	 * The rate at which the controller can change the motor's `velocity`.
	 * 
	 * *   Units for `position`, `velocity`, and `acceleration` can be set by the user through the `rescaleFactor`.The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * *   The default units for this motor controller are **1/16steps per count**.
	 * *   Changing the acceleration value while the stepper is in motion (especially at speeds higher than 1000 1/16th steps/s) can cause unpredictable results due to the inability of the processor tocalculate a new acceleration curve quickly enough. Generally you should wait until the motor is stationary until calling this function.
	 * @throws {@link PhidgetError}
	 * @param acceleration - The acceleration value
	 */
	abstract setAcceleration(acceleration: number): Promise<void>;
	/**
	 * The minimum value that `acceleration` can be set to.
	 * 
	 * *   Units for `position`, `velocity`, and `acceleration` can be set by the user through the `rescaleFactor`.The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * *   The default units for this motor controller are **1/16steps per count**.
	 * @returns The acceleration value
	 * @throws {@link PhidgetError}
	 */
	abstract getMinAcceleration(): number;
	/**
	 * The maximum value that `acceleration` can be set to.
	 * 
	 * *   Units for `position`, `velocity`, and `acceleration` can be set by the user through the `rescaleFactor`.The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * *   The default units for this motor controller are **1/16steps per count**.
	 * @returns The acceleration value
	 * @throws {@link PhidgetError}
	 */
	abstract getMaxAcceleration(): number;
	/**
	 * Use `phidget22.StepperControlMode.STEP` mode when you want to set a `targetPosition` for the Stepper motor. Use `phidget22.StepperControlMode.RUN` mode when you simply want the Stepper motor to rotate continuously in a specific direction.  
	 *   
	 * Changing the control mode while the motor is running will not have an effect on the motor's movements until a new `targetPosition` or `velocityLimit` is set.  
	 *   
	 * In `phidget22.StepperControlMode.RUN` mode, setting a `velocityLimit` will also set the target position of the controller to `minPosition` or `maxPosition`, corresponding to the direction of movement.
	 * @returns The control mode value
	 * @throws {@link PhidgetError}
	 */
	getControlMode(): Enum.StepperControlMode {
		this._assertOpen();

		if (this.data.controlMode === PUNK.ENUM)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.controlMode);
	}

	/**
	 * Use `phidget22.StepperControlMode.STEP` mode when you want to set a `targetPosition` for the Stepper motor. Use `phidget22.StepperControlMode.RUN` mode when you simply want the Stepper motor to rotate continuously in a specific direction.  
	 *   
	 * Changing the control mode while the motor is running will not have an effect on the motor's movements until a new `targetPosition` or `velocityLimit` is set.  
	 *   
	 * In `phidget22.StepperControlMode.RUN` mode, setting a `velocityLimit` will also set the target position of the controller to `minPosition` or `maxPosition`, corresponding to the direction of movement.
	 * @throws {@link PhidgetError}
	 * @param controlMode - The control mode value
	 */
	async setControlMode(controlMode: Enum.StepperControlMode): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (!SEnum.supportedStepperControlMode(this._ch!, controlMode))
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Specified StepperControlMode is unsupported by this device.");

		bp.set({ name: "0", type: "d", value: controlMode });
		await bp.send(this._ch, BP.SETCONTROLMODE);
	}

	/**
	 * The current through the motor will be limited by the `currentLimit`.
	 * 
	 * *   See your Stepper motor's data sheet for more information about what value the `currentLimit` should be.
	 * @returns The current limit value
	 * @throws {@link PhidgetError}
	 */
	getCurrentLimit(): number {
		this._assertOpen();

		if (this.data.currentLimit === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.currentLimit);
	}

	/**
	 * The current through the motor will be limited by the `currentLimit`.
	 * 
	 * *   See your Stepper motor's data sheet for more information about what value the `currentLimit` should be.
	 * @throws {@link PhidgetError}
	 * @param currentLimit - The current limit value
	 */
	async setCurrentLimit(currentLimit: number): Promise<void> {
		this._assertOpen();

		if (currentLimit < this.data.minCurrentLimit || currentLimit > this.data.maxCurrentLimit)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minCurrentLimit + " - " + this.data.maxCurrentLimit + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: currentLimit });
		await bp.send(this._ch, BP.SETCURRENTLIMIT);
	}

	/**
	 * The minimum value that `currentLimit` and `holdingCurrentLimit` can be set to.
	 * 
	 * *   Reference your controller's User Guide for more information about how the `holdingCurrentLimit` and `currentLimit` can be used in your application.
	 * @returns The current limit
	 * @throws {@link PhidgetError}
	 */
	getMinCurrentLimit(): number {
		this._assertOpen();

		if (this.data.minCurrentLimit === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minCurrentLimit);
	}

	/**
	 * The maximum value that `currentLimit` and `holdingCurrentLimit` can be set to.
	 * 
	 * *   Reference your controller's User Guide for more information about how the `holdingCurrentLimit` and `currentLimit` can be used in your application.
	 * @returns The current limit
	 * @throws {@link PhidgetError}
	 */
	getMaxCurrentLimit(): number {
		this._assertOpen();

		if (this.data.maxCurrentLimit === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxCurrentLimit);
	}

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `PositionChange` / `VelocityChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getDataInterval(): number {
		this._assertOpen();

		if (this.data.dataInterval === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return this.data.dataInterval;
	}

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `PositionChange` / `VelocityChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * @throws {@link PhidgetError}
	 * @param dataInterval - The data interval value
	 */
	async setDataInterval(dataInterval: number): Promise<void> {
		this._assertOpen();

		if (dataInterval < this.data.minDataInterval || dataInterval > this.data.maxDataInterval)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDataInterval + " - " + this.data.maxDataInterval + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: dataInterval });
		await bp.send(this._ch, BP.SETDATAINTERVAL);
	}

	/**
	 * The minimum value that `dataInterval` can be set to.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getMinDataInterval(): number {
		this._assertOpen();

		if (this.data.minDataInterval === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDataInterval);
	}

	/**
	 * The maximum value that `dataInterval` can be set to.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getMaxDataInterval(): number {
		this._assertOpen();

		if (this.data.maxDataInterval === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDataInterval);
	}

	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @returns The data rate for the channel
	 * @throws {@link PhidgetError}
	 */
	getDataRate(): number {
		this._assertOpen();

		if (this.data.dataInterval === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (1000.0 / this.data.dataInterval);
	}

	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @throws {@link PhidgetError}
	 * @param dataRate - The data rate for the channel
	 */
	async setDataRate(dataRate: number): Promise<void> {
		this._assertOpen();

		if (dataRate < this.data.minDataRate || dataRate > this.data.maxDataRate)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDataRate + " - " + this.data.maxDataRate + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: Math.round(1000.0 / dataRate) });
		bp.set({ name: "1", type: "g", value: (1000.0 / dataRate) });
		await bp.send(this._ch, BP.SETDATAINTERVAL);
	}

	/**
	 * The minimum value that `dataRate` can be set to.
	 * @returns The data rate value
	 * @throws {@link PhidgetError}
	 */
	getMinDataRate(): number {
		this._assertOpen();

		if (this.data.minDataRate === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDataRate);
	}

	/**
	 * The maximum value that `dataRate` can be set to.
	 * @returns The data rate value
	 * @throws {@link PhidgetError}
	 */
	getMaxDataRate(): number {
		this._assertOpen();

		if (this.data.maxDataRate === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDataRate);
	}

	/**
	 * When this property is true, the controller will supply power to the motor coils.
	 * 
	 * *   The controller must be `engaged` in order to move the Stepper motor, or have it hold position.
	 * @returns The engaged state
	 * @throws {@link PhidgetError}
	 */
	getEngaged(): boolean {
		this._assertOpen();

		if (this.data.engaged === PUNK.BOOL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (!!this.data.engaged);
	}

	/**
	 * When this property is true, the controller will supply power to the motor coils.
	 * 
	 * *   The controller must be `engaged` in order to move the Stepper motor, or have it hold position.
	 * @throws {@link PhidgetError}
	 * @param engaged - The engaged state
	 */
	async setEngaged(engaged: boolean): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (engaged !== false && engaged !== true)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be a boolean.");

		bp.set({ name: "0", type: "d", value: (engaged ? 1 : 0) });
		await bp.send(this._ch, BP.SETENGAGED);
	}

	/**
	 * Enables the **failsafe** feature for the channel, with a given **failsafe time**.
	 * 
	 * The **failsafe** feature is intended for use in applications where it is important for the channel to enter a known _safe state_ if the program controlling it locks up or crashes. If you do not enable the failsafe feature, the channel will carry out whatever instructions it was last given until it is explicitly told to stop.
	 * 
	 * Enabling the failsafe feature starts a recurring **failsafe timer** for the channel. Once the failsafe timer is enabled, it must be reset within the specified time or the channel will enter a **failsafe state**. The failsafe timer may be reset by sending any valid command to the device\*. Resetting the failsafe timer will reload the timer with the specified _failsafe time_, starting when the message to reset the timer is received by the Phidget.
	 * 
	 * _\*(**get** requests do not typically send commands and won't reset the failsafe timer)_
	 * 
	 * For example: if the failsafe is enabled with a **failsafe time** of 1000ms, you will have 1000ms to reset the failsafe timer. Every time the failsafe timer is reset, you will have 1000ms from that time to reset the failsafe again.
	 * 
	 * If the failsafe timer is not reset before it runs out, the channel will enter a **failsafe state**. For Stepper Motor channels, this will disengage the motor. Once the channel enters the **failsafe state**, it will reject any further input until the channel is reopened.
	 * 
	 * To prevent the channel from falsely entering the failsafe state, we recommend resetting the failsafe timer as frequently as is practical for your application. A good rule of thumb is to not let more than a third of the failsafe time pass before resetting the timer.
	 * 
	 * Once the failsafe timer has been set, it cannot be disabled by any means other than closing and reopening the channel.
	 * @throws {@link PhidgetError}
	 * @param failsafeTime - Failsafe timeout in milliseconds
	 */
	async enableFailsafe(failsafeTime: number): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: failsafeTime });
		await bp.send(this._ch, BP.SETFAILSAFETIME);
	}

	/**
	 * The minimum value that `failsafeTime` can be set to when calling `enableFailsafe()`.
	 * @returns The failsafe time
	 * @throws {@link PhidgetError}
	 */
	getMinFailsafeTime(): number {
		this._assertOpen();

		if (this.data.minFailsafeTime === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minFailsafeTime);
	}

	/**
	 * The maximum value that `failsafeTime` can be set to when calling `enableFailsafe()`.
	 * @returns The failsafe time
	 * @throws {@link PhidgetError}
	 */
	getMaxFailsafeTime(): number {
		this._assertOpen();

		if (this.data.maxFailsafeTime === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxFailsafeTime);
	}

	/**
	 * The `holdingCurrentLimit` will activate when the `targetPosition` has been reached. It will limit current through the motor.
	 * 
	 * *   When the motor is not stopped, the current through the motor is limited by the `currentLimit`.
	 * *   If no `holdingCurrentLimit` is specified, the `currentLimit` value will persist when the motor is stopped.
	 * *   Reference your controller's User Guide for more information about how the `holdingCurrentLimit` and `currentLimit` can be used in your application.
	 * @returns The current value
	 * @throws {@link PhidgetError}
	 */
	getHoldingCurrentLimit(): number {
		this._assertOpen();

		if (this.data.holdingCurrentLimit === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.holdingCurrentLimit);
	}

	/**
	 * The `holdingCurrentLimit` will activate when the `targetPosition` has been reached. It will limit current through the motor.
	 * 
	 * *   When the motor is not stopped, the current through the motor is limited by the `currentLimit`.
	 * *   If no `holdingCurrentLimit` is specified, the `currentLimit` value will persist when the motor is stopped.
	 * *   Reference your controller's User Guide for more information about how the `holdingCurrentLimit` and `currentLimit` can be used in your application.
	 * @throws {@link PhidgetError}
	 * @param holdingCurrentLimit - The current value
	 */
	async setHoldingCurrentLimit(holdingCurrentLimit: number): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: holdingCurrentLimit });
		await bp.send(this._ch, BP.SETHOLDINGCURRENTLIMIT);
	}

	/**
	 * `isMoving` returns true while the controller is sending commands to the motor. Note: there is no feedback to the controller, so it does not know whether the motor shaft is actually moving or not.
	 * @returns The moving state
	 * @throws {@link PhidgetError}
	 */
	getIsMoving(): boolean {
		this._assertOpen();

		if (this.data.isMoving === PUNK.BOOL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (!!this.data.isMoving);
	}

	/**
	 * The most recent position value that the controller has reported.
	 * 
	 * *   This value will always be between `minPosition` and `maxPosition`.
	 * *   Units for `position`, `velocity`, and `acceleration` can be set by the user through the `rescaleFactor`.The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * *   The default units for this motor controller are **1/16steps per count**.
	 * @returns The position value
	 * @throws {@link PhidgetError}
	 */
	abstract getPosition(): number;
	/**
	 * The minimum value that `targetPosition` can be set to.
	 * 
	 * *   Units for `position`, `velocity`, and `acceleration` can be set by the user through the `rescaleFactor`.The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * *   The default units for this motor controller are **1/16steps per count**.
	 * @returns The position value
	 * @throws {@link PhidgetError}
	 */
	abstract getMinPosition(): number;
	/**
	 * The maximum value that `targetPosition` can be set to.
	 * 
	 * *   Units for `position`, `velocity`, and `acceleration` can be set by the user through the `rescaleFactor`.The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * *   The default units for this motor controller are **1/16steps per count**.
	 * @returns The position value
	 * @throws {@link PhidgetError}
	 */
	abstract getMaxPosition(): number;
	/**
	 * Adds an offset (positive or negative) to the current position and target position.
	 * 
	 * *   This is especially useful for zeroing position.
	 * @throws {@link PhidgetError}
	 * @param positionOffset - Amount to offset the position by
	 */
	abstract addPositionOffset(positionOffset: number): void;
	/**
	 * Applies a factor to the \[user units\] per step to all movement parameters to make the units in your application is more intuitive.
	 * 
	 * *   For example, starting from position 0 and setting a new position with a rescale factor, the stepper will move `position` / `rescaleFactor` steps.
	 * *   In this way, units for `position`, `velocity`, and `acceleration` can be set by the user through the `rescaleFactor`. The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * *   The default units for this motor controller are **1/16steps per count**.
	 *   
	 * 
	 * RescaleFactor = (1/16) \* (MotorStepAngle/Degrees Per UserUnit)
	 * @returns The rescale factor value
	 * @throws {@link PhidgetError}
	 */
	getRescaleFactor(): number {
		this._assertOpen();

		if (this.data.rescaleFactor === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.rescaleFactor);
	}

	/**
	 * Applies a factor to the \[user units\] per step to all movement parameters to make the units in your application is more intuitive.
	 * 
	 * *   For example, starting from position 0 and setting a new position with a rescale factor, the stepper will move `position` / `rescaleFactor` steps.
	 * *   In this way, units for `position`, `velocity`, and `acceleration` can be set by the user through the `rescaleFactor`. The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * *   The default units for this motor controller are **1/16steps per count**.
	 *   
	 * 
	 * RescaleFactor = (1/16) \* (MotorStepAngle/Degrees Per UserUnit)
	 * @throws {@link PhidgetError}
	 * @param rescaleFactor - The rescale factor value
	 */
	abstract setRescaleFactor(rescaleFactor: number): void;
	/**
	 * Resets the failsafe timer, if one has been set. See `enableFailsafe()` for details.
	 * 
	 * This function will fail if no failsafe timer has been set for the channel.
	 * @throws {@link PhidgetError}
	 */
	async resetFailsafe(): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		await bp.send(this._ch, BP.FAILSAFERESET);
	}

	/**
	 * If the controller is configured and the `targetPosition` is set, the Stepper motor will move towards the `targetPosition` at the specified `acceleration` and `velocity`.
	 * 
	 * *   `targetPosition` is only used when the `controlMode` is set to step mode.
	 * *   Units for `position`, `velocity`, and `acceleration` can be set by the user through the `rescaleFactor`.The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * *   The default units for this motor controller are **1/16steps per count**.
	 * @returns The position value
	 * @throws {@link PhidgetError}
	 */
	abstract getTargetPosition(): number;
	/**
	 * If the controller is configured and the `targetPosition` is set, the Stepper motor will move towards the `targetPosition` at the specified `acceleration` and `velocity`.
	 * 
	 * *   `targetPosition` is only used when the `controlMode` is set to step mode.
	 * *   Units for `position`, `velocity`, and `acceleration` can be set by the user through the `rescaleFactor`.The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * *   The default units for this motor controller are **1/16steps per count**.
	 * @throws {@link PhidgetError}
	 * @param targetPosition - The position value
	 */
	abstract setTargetPosition(targetPosition: number): Promise<void>;
	/**
	 * The most recent velocity value that the controller has reported.
	 * 
	 * *   This value is bounded by `minVelocityLimit` and `maxVelocityLimit`.
	 * *   Units for `position`, `velocity`, and `acceleration` can be set by the user through the `rescaleFactor`.The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * *   The default units for this motor controller are **1/16steps per count**.
	 * @returns The velocity value
	 * @throws {@link PhidgetError}
	 */
	abstract getVelocity(): number;
	/**
	 * When moving, the Stepper motor velocity will be limited by this value.
	 * 
	 * *   The `velocityLimit` is bounded by `minVelocityLimit` and `maxVelocityLimit`.
	 * *   When in step mode, the `minVelocityLimit` has a value of 0. This is because the sign (±) of the `targetPosition` will indicate the direction.
	 * *   When in run mode, the `minVelocityLimit` has a value of -`maxVelocityLimit`. This is because there is no target position, so the direction is defined by the sign (±) of the `velocityLimit`.
	 * *   Units for `position`, `velocity`, and `acceleration` can be set by the user through the `rescaleFactor`.The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * *   The default units for this motor controller are **1/16steps per count**.
	 * *   While `velocityLimit` is listed as a double, it is rounded down to an integer number of 1/16th steps when sent to the board since the board is limited by a minimum unit of 1/16th steps/s. This is especially important to consider when using different `rescaleFactor` values where converting to units of, for example, RPM results in 1.5RPM (80 1/16th steps/s) and 1.509375 RPM (80.5 1/16th steps/s) both being sent to the board as 80 1/16th steps/s.
	 * @returns Velocity limit
	 * @throws {@link PhidgetError}
	 */
	abstract getVelocityLimit(): number;
	/**
	 * When moving, the Stepper motor velocity will be limited by this value.
	 * 
	 * *   The `velocityLimit` is bounded by `minVelocityLimit` and `maxVelocityLimit`.
	 * *   When in step mode, the `minVelocityLimit` has a value of 0. This is because the sign (±) of the `targetPosition` will indicate the direction.
	 * *   When in run mode, the `minVelocityLimit` has a value of -`maxVelocityLimit`. This is because there is no target position, so the direction is defined by the sign (±) of the `velocityLimit`.
	 * *   Units for `position`, `velocity`, and `acceleration` can be set by the user through the `rescaleFactor`.The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * *   The default units for this motor controller are **1/16steps per count**.
	 * *   While `velocityLimit` is listed as a double, it is rounded down to an integer number of 1/16th steps when sent to the board since the board is limited by a minimum unit of 1/16th steps/s. This is especially important to consider when using different `rescaleFactor` values where converting to units of, for example, RPM results in 1.5RPM (80 1/16th steps/s) and 1.509375 RPM (80.5 1/16th steps/s) both being sent to the board as 80 1/16th steps/s.
	 * @throws {@link PhidgetError}
	 * @param velocityLimit - Velocity limit
	 */
	abstract setVelocityLimit(velocityLimit: number): Promise<void>;
	/**
	 * The minimum value that `velocityLimit` can be set to.
	 * 
	 * *   Units for `position`, `velocity`, and `acceleration` can be set by the user through the `rescaleFactor`.The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * *   The default units for this motor controller are **1/16steps per count**.
	 * @returns The velocity limit value
	 * @throws {@link PhidgetError}
	 */
	abstract getMinVelocityLimit(): number;
	/**
	 * The maximum value that `velocityLimit` can be set to.
	 * 
	 * *   Units for `position`, `velocity`, and `acceleration` can be set by the user through the `rescaleFactor`.The `rescaleFactor` allows you to use more intuitive units such as rotations, or degrees.
	 * *   The default units for this motor controller are **1/16steps per count**.
	 * @returns The velocity value
	 * @throws {@link PhidgetError}
	 */
	abstract getMaxVelocityLimit(): number;
}
export { StepperBase };
