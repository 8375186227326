/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PhidgetChannel } from '../Phidget';
import { Channel } from '../Channel';
import { ErrorCode, ChannelClass } from '../Enumerations.gen';
import * as Enum from '../Enumerations.gen';
import * as SEnum from '../SupportedEnum.gen';
import * as Struct from '../Structs.gen';
import { PhidgetError } from '../PhidgetError';
import { BridgePacket, PUNK } from '../BridgePacket';
import { BP } from '../BridgePackets.gen';
import { logEventException } from '../Logging';
import { DeviceChannelUID } from '../Devices.gen';

/** @internal */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SpatialData {
	dataInterval: number,
	maxDataInterval: number,
	minDataInterval: number,
	minDataRate: number,
	maxDataRate: number,
	precision: Enum.SpatialPrecision | PUNK.ENUM,
	algorithm: Enum.SpatialAlgorithm | PUNK.ENUM,
	algorithmMagnetometerGain: number,
	maxAcceleration: readonly [number, number, number],
	minAcceleration: readonly [number, number, number],
	maxAngularRate: readonly [number, number, number],
	minAngularRate: readonly [number, number, number],
	maxMagneticField: readonly [number, number, number],
	minMagneticField: readonly [number, number, number],
	heatingEnabled: number,
	quaternion: Struct.SpatialQuaternion | null,
	eulerAngles: Struct.SpatialEulerAngles | null,
}

abstract class SpatialBase extends PhidgetChannel {
	/** @internal */
	data: SpatialData;
	/**
	 * **SpatialData** event
	 *  * `acceleration` - The acceleration vaulues
	 *  * `angularRate` - The angular rate values
	 *  * `magneticField` - The field strength values
	 *  * `timestamp` - The timestamp value
	 * ---
	 * The most recent values that your channel has measured will be reported in this event, which occurs when the `dataInterval` has elapsed.
	 */
	onSpatialData: ((acceleration: readonly [number, number, number], angularRate: readonly [number, number, number], magneticField: readonly [number, number, number], timestamp: number) => void) | null = null;
	/** @internal */
	_gotSpatialDataErrorEvent?: boolean;
	/**
	 * **AlgorithmData** event
	 *  * `quaternion` - The quaternion value - \[x, y, z, w\]
	 *  * `timestamp` - The timestamp value
	 * ---
	 * The most recent IMU/AHRS Quaternion will be reported in this event, which occurs when the `dataInterval` has elapsed.
	 */
	onAlgorithmData: ((quaternion: readonly [number, number, number, number], timestamp: number) => void) | null = null;
	/** @internal */
	_gotAlgorithmDataErrorEvent?: boolean;

	/**
	 * The Spatial class simultaneously gathers data from the acceleromter, gyroscope and magnetometer on a Phidget board.
	 * 
	 * You can also use the individual classes for these sensors if you want to handle the data in separate events.
	 * @public
	 */
	constructor();
	/** @internal */
	constructor(ch?: Channel);
	constructor(ch?: Channel) {
		super(ch);
		this._class = ChannelClass.SPATIAL;
		this.name = "Spatial";
		this.data = this._initData();
	}

	/** @internal */
	_bridgeInput(bp: BridgePacket) {

		switch(bp.vpkt) {
		case BP.SETAHRSPARAMETERS:
			break;
		case BP.SETSPATIALALGORITHM:
			this.data.algorithm = bp.entries[0].v as Enum.SpatialAlgorithm;
			this._FIREPropertyChange('Algorithm', bp);
			break;
		case BP.SETSPATIALALGORITHMMAGGAIN:
			this.data.algorithmMagnetometerGain = bp.entries[0].v as number;
			this._FIREPropertyChange('AlgorithmMagnetometerGain', bp);
			break;
		case BP.SETDATAINTERVAL:
			if (bp.entryCount > 1)
				this.data.dataInterval = bp.entries[1].v as number;
			else
				this.data.dataInterval = bp.entries[0].v as number;
			this._FIREPropertyChange('DataInterval', bp);
			this._FIREPropertyChange('DataRate', bp);
			break;
		case BP.SETHEATINGENABLED:
			this.data.heatingEnabled = bp.entries[0].v as number;
			this._FIREPropertyChange('HeatingEnabled', bp);
			break;
		case BP.SETCORRECTIONPARAMETERS:
			break;
		case BP.SETSPATIALPRECISION:
			this.data.precision = bp.entries[0].v as Enum.SpatialPrecision;
			this._FIREPropertyChange('Precision', bp);
			break;
		case BP.RESETCORRECTIONPARAMETERS:
			break;
		case BP.SAVECORRECTIONPARAMETERS:
			break;
		case BP.ZEROSPATIALALGORITHM:
			break;
		case BP.ZERO:
			break;
		case BP.SPATIALDATA: {
			if (this._isAttachedDone && this.onSpatialData) {
				try {
					this.onSpatialData(bp.entries[0].v as readonly [number, number, number], bp.entries[1].v as readonly [number, number, number], bp.entries[2].v as readonly [number, number, number], bp.entries[3].v as number);
				} catch (err) { logEventException(err); }
			}
			break;
		}
		default:
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			throw new PhidgetError(ErrorCode.INVALID_PACKET, "Unsupported bridge packet: 0x" + bp.vpkt!.toString(16));
		}
	}

	/** @internal */
	_initData(): SpatialData {
		return {
			dataInterval: PUNK.DBL,
			maxDataInterval: PUNK.UINT32,
			minDataInterval: PUNK.UINT32,
			minDataRate: PUNK.DBL,
			maxDataRate: PUNK.DBL,
			precision: PUNK.ENUM,
			algorithm: PUNK.ENUM,
			algorithmMagnetometerGain: PUNK.DBL,
			maxAcceleration: [PUNK.DBL, PUNK.DBL, PUNK.DBL],
			minAcceleration: [PUNK.DBL, PUNK.DBL, PUNK.DBL],
			maxAngularRate: [PUNK.DBL, PUNK.DBL, PUNK.DBL],
			minAngularRate: [PUNK.DBL, PUNK.DBL, PUNK.DBL],
			maxMagneticField: [PUNK.DBL, PUNK.DBL, PUNK.DBL],
			minMagneticField: [PUNK.DBL, PUNK.DBL, PUNK.DBL],
			heatingEnabled: PUNK.BOOL,
			quaternion: null,
			eulerAngles: null,
		}
	}

	/** @internal */
	_initAfterOpen() {
		this.data = this._initData();

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._MOT1101_SPATIAL_100:
			this.data.dataInterval = 250;
			this.data.maxDataInterval = 60000;
			this.data.minDataInterval = 20;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 50;
			this.data.precision = Enum.SpatialPrecision.LOW;
			this.data.algorithm = Enum.SpatialAlgorithm.NONE;
			this.data.maxAcceleration = [8, 8, 8];
			this.data.minAcceleration = [-8, -8, -8];
			this.data.maxAngularRate = [2000, 2000, 2000];
			this.data.minAngularRate = [-2000, -2000, -2000];
			this.data.maxMagneticField = [8, 8, 8];
			this.data.minMagneticField = [-8, -8, -8];
			break;
		case DeviceChannelUID._MOT1102_SPATIAL_200:
		case DeviceChannelUID._MOT1102_SPATIAL_300:
			this.data.dataInterval = 250;
			this.data.maxDataInterval = 60000;
			this.data.minDataInterval = 20;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 50;
			this.data.precision = Enum.SpatialPrecision.LOW;
			this.data.algorithm = Enum.SpatialAlgorithm.AHRS;
			this.data.algorithmMagnetometerGain = 0.005;
			this.data.maxAcceleration = [8, 8, 8];
			this.data.minAcceleration = [-8, -8, -8];
			this.data.maxAngularRate = [2000, 2000, 2000];
			this.data.minAngularRate = [-2000, -2000, -2000];
			this.data.maxMagneticField = [8, 8, 8];
			this.data.minMagneticField = [-8, -8, -8];
			break;
		case DeviceChannelUID._MOT0109_SPATIAL_100:
			this.data.dataInterval = 256;
			this.data.maxDataInterval = 1000;
			this.data.minDataInterval = 4;
			this.data.minDataRate = 1;
			this.data.maxDataRate = 250;
			this.data.precision = Enum.SpatialPrecision.HYBRID;
			this.data.algorithm = Enum.SpatialAlgorithm.AHRS;
			this.data.algorithmMagnetometerGain = 0.005;
			this.data.maxAcceleration = [8, 8, 8];
			this.data.minAcceleration = [-8, -8, -8];
			this.data.maxAngularRate = [2000, 2000, 2000];
			this.data.minAngularRate = [-2000, -2000, -2000];
			this.data.maxMagneticField = [50, 50, 50];
			this.data.minMagneticField = [-50, -50, -50];
			this.data.heatingEnabled = 0;
			this.data.quaternion = (<SpatialDevice>this._ch!.parent).data.quaternion[this._ch!.index];
			break;
		case DeviceChannelUID._MOT0110_SPATIAL_100_USB:
			this.data.dataInterval = 250;
			this.data.maxDataInterval = 1000;
			this.data.minDataInterval = 1;
			this.data.minDataRate = 1;
			this.data.maxDataRate = 1000;
			this.data.precision = Enum.SpatialPrecision.HIGH;
			this.data.algorithm = Enum.SpatialAlgorithm.AHRS;
			this.data.maxAcceleration = [16, 16, 16];
			this.data.minAcceleration = [-16, -16, -16];
			this.data.maxAngularRate = [2000, 2000, 2000];
			this.data.minAngularRate = [-2000, -2000, -2000];
			this.data.maxMagneticField = [8, 8, 8];
			this.data.minMagneticField = [-8, -8, -8];
			this.data.heatingEnabled = 0;
			this.data.quaternion = (<SpatialDevice>this._ch!.parent).data.quaternion[this._ch!.index];
			break;
		case DeviceChannelUID._MOT0110_SPATIAL_100_VINT:
			this.data.dataInterval = 250;
			this.data.maxDataInterval = 1000;
			this.data.minDataInterval = 2;
			this.data.minDataRate = 1;
			this.data.maxDataRate = 500;
			this.data.precision = Enum.SpatialPrecision.HIGH;
			this.data.algorithm = Enum.SpatialAlgorithm.AHRS;
			this.data.maxAcceleration = [16, 16, 16];
			this.data.minAcceleration = [-16, -16, -16];
			this.data.maxAngularRate = [2000, 2000, 2000];
			this.data.minAngularRate = [-2000, -2000, -2000];
			this.data.maxMagneticField = [8, 8, 8];
			this.data.minMagneticField = [-8, -8, -8];
			this.data.heatingEnabled = 0;
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	// eslint-disable-next-line require-await
	async _setDefaults() {
		let bp;

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._MOT1101_SPATIAL_100:
		case DeviceChannelUID._MOT0110_SPATIAL_100_USB:
		case DeviceChannelUID._MOT0110_SPATIAL_100_VINT:
			bp = new BridgePacket();
			bp.set({ name: "0", type: "u", value: Math.round(this.data.dataInterval) });
			await bp.send(this._ch, BP.SETDATAINTERVAL);
			break;
		case DeviceChannelUID._MOT1102_SPATIAL_200:
		case DeviceChannelUID._MOT1102_SPATIAL_300:
		case DeviceChannelUID._MOT0109_SPATIAL_100:
			bp = new BridgePacket();
			bp.set({ name: "0", type: "u", value: Math.round(this.data.dataInterval) });
			await bp.send(this._ch, BP.SETDATAINTERVAL);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.algorithmMagnetometerGain });
			await bp.send(this._ch, BP.SETSPATIALALGORITHMMAGGAIN);
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	_hasInitialState() {


		return true;
	}

	/** @internal */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	_fireInitialEvents() {

	}

	/**
	 * The minimum acceleration the sensor will measure.
	 * @throws {@link PhidgetError}
	 */
	get minAcceleration() { return this.getMinAcceleration(); }
	/**
	 * The maximum acceleration the sensor will measure.
	 * @throws {@link PhidgetError}
	 */
	get maxAcceleration() { return this.getMaxAcceleration(); }
	/**
	 * Selects the IMU/AHRS algorithm.
	 * @throws {@link PhidgetError}
	 */
	get algorithm() { return this.getAlgorithm(); }
	/**
	 * Sets the gain for the magnetometer in the AHRS algorithm. Lower gains reduce sensor noise while slowing response time.
	 * @throws {@link PhidgetError}
	 */
	get algorithmMagnetometerGain() { return this.getAlgorithmMagnetometerGain(); }
	/**
	 * The minimum angular rate the sensor will measure.
	 * @throws {@link PhidgetError}
	 */
	get minAngularRate() { return this.getMinAngularRate(); }
	/**
	 * The maximum angular rate the sensor will measure.
	 * @throws {@link PhidgetError}
	 */
	get maxAngularRate() { return this.getMaxAngularRate(); }
	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `SpatialData` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * @throws {@link PhidgetError}
	 */
	get dataInterval() { return this.getDataInterval(); }
	/**
	 * The minimum value that `dataInterval` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDataInterval() { return this.getMinDataInterval(); }
	/**
	 * The maximum value that `dataInterval` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDataInterval() { return this.getMaxDataInterval(); }
	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @throws {@link PhidgetError}
	 */
	get dataRate() { return this.getDataRate(); }
	/**
	 * The minimum value that `dataRate` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDataRate() { return this.getMinDataRate(); }
	/**
	 * The maximum value that `dataRate` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDataRate() { return this.getMaxDataRate(); }
	/**
	 * Gets the latest device orientation in the form of Euler angles. (Pitch, roll, and yaw)
	 * @throws {@link PhidgetError}
	 */
	get eulerAngles() { return this.getEulerAngles(); }
	/**
	 * Set to TRUE to enable the temperature stabilization feature of this device. This enables on-board heating elements to bring the board up to a known temperature to minimize ambient temperature effects on the sensor's reading. You can leave this setting FALSE to conserve power consumption.  
	 *   
	 * If you enable heating, it is strongly recommended to keep the board in its enclosure to keep it insulated from moving air.  
	 *   
	 * This property is shared by any and all spatial-related objects on this device (Accelerometer, Gyroscope, Magnetometer, Spatial)
	 * @throws {@link PhidgetError}
	 */
	get heatingEnabled() { return this.getHeatingEnabled(); }
	/**
	 * The minimum field strength the sensor will measure.
	 * @throws {@link PhidgetError}
	 */
	get minMagneticField() { return this.getMinMagneticField(); }
	/**
	 * The maximum field strength the sensor will measure.
	 * @throws {@link PhidgetError}
	 */
	get maxMagneticField() { return this.getMaxMagneticField(); }
	/**
	 * Selects between high/low precision sensing chips.
	 * @throws {@link PhidgetError}
	 * @internal
	 */
	get precision() { return this.getPrecision(); }
	/**
	 * Gets the latest AHRS/IMU quaternion sent from the device.
	 * @throws {@link PhidgetError}
	 */
	get quaternion() { return this.getQuaternion(); }

	/**
	 * The minimum acceleration the sensor will measure.
	 * @returns The minimum acceleration value
	 * @throws {@link PhidgetError}
	 */
	getMinAcceleration(): readonly [number, number, number] {
		this._assertOpen();

		if (this.data.minAcceleration.includes(PUNK.DBL))
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minAcceleration);
	}

	/**
	 * The maximum acceleration the sensor will measure.
	 * @returns The maximum acceleration values
	 * @throws {@link PhidgetError}
	 */
	getMaxAcceleration(): readonly [number, number, number] {
		this._assertOpen();

		if (this.data.maxAcceleration.includes(PUNK.DBL))
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxAcceleration);
	}

	/**
	 * Calibrate your device for the environment it will be used in.
	 * 
	 * *   Setting these parameters will allow you to tune the AHRS algorithm on the device to your specific application.
	 * @throws {@link PhidgetError}
	 * @param angularVelocityThreshold - The maximum angular velocity reading where the device is assumed to be "at rest"
	 * @param angularVelocityDeltaThreshold - The acceptable amount of change in angular velocity between measurements before movement is assumed.
	 * @param accelerationThreshold - The maximum acceleration applied to the device (minus gravity) where it is assumed to be "at rest". This is also the maximum acceleration allowable before the device stops correcting to the acceleration vector.
	 * @param magTime - The time it will take to correct the heading 95% of the way to aligning with the compass (in seconds),up to 15 degrees of error. Beyond 15 degrees, this is the time it will take for the bearing to move 45 degrees towards the compass reading. Remember you can zero the algorithm at any time to instantly realign the spatial with acceleration and magnetic field vectors regardless of magnitude.
	 * @param accelTime - The time it will take to correct the pitch and roll 95% of the way to aligning with the accelerometer (in seconds).
	 * @param biasTime - The time it will take to have the gyro biases settle to within 95% of the measured steady state (in seconds).
	 */
	async setAHRSParameters(angularVelocityThreshold: number, angularVelocityDeltaThreshold: number, accelerationThreshold: number, magTime: number, accelTime: number, biasTime: number): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: angularVelocityThreshold });
		bp.set({ name: "1", type: "g", value: angularVelocityDeltaThreshold });
		bp.set({ name: "2", type: "g", value: accelerationThreshold });
		bp.set({ name: "3", type: "g", value: magTime });
		bp.set({ name: "4", type: "g", value: accelTime });
		bp.set({ name: "5", type: "g", value: biasTime });
		await bp.send(this._ch, BP.SETAHRSPARAMETERS);
	}

	/**
	 * Selects the IMU/AHRS algorithm.
	 * @returns The sensor algorithm
	 * @throws {@link PhidgetError}
	 */
	getAlgorithm(): Enum.SpatialAlgorithm {
		this._assertOpen();

		if (this.data.algorithm === PUNK.ENUM)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.algorithm);
	}

	/**
	 * Selects the IMU/AHRS algorithm.
	 * @throws {@link PhidgetError}
	 * @param algorithm - The sensor algorithm
	 */
	async setAlgorithm(algorithm: Enum.SpatialAlgorithm): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (!SEnum.supportedSpatialAlgorithm(this._ch!, algorithm))
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Specified SpatialAlgorithm is unsupported by this device.");

		bp.set({ name: "0", type: "d", value: algorithm });
		await bp.send(this._ch, BP.SETSPATIALALGORITHM);
	}

	/**
	 * Sets the gain for the magnetometer in the AHRS algorithm. Lower gains reduce sensor noise while slowing response time.
	 * @returns The AHRS algorithm magnetometer gain
	 * @throws {@link PhidgetError}
	 */
	getAlgorithmMagnetometerGain(): number {
		this._assertOpen();

		if (this.data.algorithmMagnetometerGain === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.algorithmMagnetometerGain);
	}

	/**
	 * Sets the gain for the magnetometer in the AHRS algorithm. Lower gains reduce sensor noise while slowing response time.
	 * @throws {@link PhidgetError}
	 * @param algorithmMagnetometerGain - The AHRS algorithm magnetometer gain
	 */
	async setAlgorithmMagnetometerGain(algorithmMagnetometerGain: number): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: algorithmMagnetometerGain });
		await bp.send(this._ch, BP.SETSPATIALALGORITHMMAGGAIN);
	}

	/**
	 * The minimum angular rate the sensor will measure.
	 * @returns The angular rate values
	 * @throws {@link PhidgetError}
	 */
	getMinAngularRate(): readonly [number, number, number] {
		this._assertOpen();

		if (this.data.minAngularRate.includes(PUNK.DBL))
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minAngularRate);
	}

	/**
	 * The maximum angular rate the sensor will measure.
	 * @returns The angular rate values
	 * @throws {@link PhidgetError}
	 */
	getMaxAngularRate(): readonly [number, number, number] {
		this._assertOpen();

		if (this.data.maxAngularRate.includes(PUNK.DBL))
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxAngularRate);
	}

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `SpatialData` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getDataInterval(): number {
		this._assertOpen();

		if (this.data.dataInterval === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return this.data.dataInterval;
	}

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `SpatialData` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * @throws {@link PhidgetError}
	 * @param dataInterval - The data interval value
	 */
	async setDataInterval(dataInterval: number): Promise<void> {
		this._assertOpen();

		if (dataInterval < this.data.minDataInterval || dataInterval > this.data.maxDataInterval)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDataInterval + " - " + this.data.maxDataInterval + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: dataInterval });
		await bp.send(this._ch, BP.SETDATAINTERVAL);
	}

	/**
	 * The minimum value that `dataInterval` can be set to.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getMinDataInterval(): number {
		this._assertOpen();

		if (this.data.minDataInterval === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDataInterval);
	}

	/**
	 * The maximum value that `dataInterval` can be set to.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getMaxDataInterval(): number {
		this._assertOpen();

		if (this.data.maxDataInterval === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDataInterval);
	}

	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @returns The data rate for the channel
	 * @throws {@link PhidgetError}
	 */
	getDataRate(): number {
		this._assertOpen();

		if (this.data.dataInterval === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (1000.0 / this.data.dataInterval);
	}

	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @throws {@link PhidgetError}
	 * @param dataRate - The data rate for the channel
	 */
	async setDataRate(dataRate: number): Promise<void> {
		this._assertOpen();

		if (dataRate < this.data.minDataRate || dataRate > this.data.maxDataRate)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDataRate + " - " + this.data.maxDataRate + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: Math.round(1000.0 / dataRate) });
		bp.set({ name: "1", type: "g", value: (1000.0 / dataRate) });
		await bp.send(this._ch, BP.SETDATAINTERVAL);
	}

	/**
	 * The minimum value that `dataRate` can be set to.
	 * @returns The data rate value
	 * @throws {@link PhidgetError}
	 */
	getMinDataRate(): number {
		this._assertOpen();

		if (this.data.minDataRate === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDataRate);
	}

	/**
	 * The maximum value that `dataRate` can be set to.
	 * @returns The data rate value
	 * @throws {@link PhidgetError}
	 */
	getMaxDataRate(): number {
		this._assertOpen();

		if (this.data.maxDataRate === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDataRate);
	}

	/**
	 * Gets the latest device orientation in the form of Euler angles. (Pitch, roll, and yaw)
	 * @returns Gets the latest device orientation in the form of Euler angles.
	 * @throws {@link PhidgetError}
	 */
	abstract getEulerAngles(): Struct.SpatialEulerAngles;
	/**
	 * Set to TRUE to enable the temperature stabilization feature of this device. This enables on-board heating elements to bring the board up to a known temperature to minimize ambient temperature effects on the sensor's reading. You can leave this setting FALSE to conserve power consumption.  
	 *   
	 * If you enable heating, it is strongly recommended to keep the board in its enclosure to keep it insulated from moving air.  
	 *   
	 * This property is shared by any and all spatial-related objects on this device (Accelerometer, Gyroscope, Magnetometer, Spatial)
	 * @returns Whether self-heating temperature stabilization is enabled
	 * @throws {@link PhidgetError}
	 */
	getHeatingEnabled(): boolean {
		this._assertOpen();

		if (this.data.heatingEnabled === PUNK.BOOL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (!!this.data.heatingEnabled);
	}

	/**
	 * Set to TRUE to enable the temperature stabilization feature of this device. This enables on-board heating elements to bring the board up to a known temperature to minimize ambient temperature effects on the sensor's reading. You can leave this setting FALSE to conserve power consumption.  
	 *   
	 * If you enable heating, it is strongly recommended to keep the board in its enclosure to keep it insulated from moving air.  
	 *   
	 * This property is shared by any and all spatial-related objects on this device (Accelerometer, Gyroscope, Magnetometer, Spatial)
	 * @throws {@link PhidgetError}
	 * @param heatingEnabled - Whether self-heating temperature stabilization is enabled
	 */
	async setHeatingEnabled(heatingEnabled: boolean): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (heatingEnabled !== false && heatingEnabled !== true)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be a boolean.");

		bp.set({ name: "0", type: "d", value: (heatingEnabled ? 1 : 0) });
		await bp.send(this._ch, BP.SETHEATINGENABLED);
	}

	/**
	 * The minimum field strength the sensor will measure.
	 * @returns The field strength value
	 * @throws {@link PhidgetError}
	 */
	getMinMagneticField(): readonly [number, number, number] {
		this._assertOpen();

		if (this.data.minMagneticField.includes(PUNK.DBL))
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minMagneticField);
	}

	/**
	 * The maximum field strength the sensor will measure.
	 * @returns The field strength value
	 * @throws {@link PhidgetError}
	 */
	getMaxMagneticField(): readonly [number, number, number] {
		this._assertOpen();

		if (this.data.maxMagneticField.includes(PUNK.DBL))
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxMagneticField);
	}

	/**
	 * Calibrate your device for the environment it will be used in.
	 * 
	 * *   Due to physical location, hard and soft iron offsets, and even bias errors, your device should be calibrated. We have created a calibration program that will provide you with the `MagnetometerCorrectionParameters` for your specific situation. See your device's User Guide for more information.
	 * @throws {@link PhidgetError}
	 * @param magneticField - Ambient magnetic field value.
	 * @param offset0 - Provided by calibration program.
	 * @param offset1 - Provided by calibration program.
	 * @param offset2 - Provided by calibration program.
	 * @param gain0 - Provided by calibration program.
	 * @param gain1 - Provided by calibration program.
	 * @param gain2 - Provided by calibration program.
	 * @param T0 - Provided by calibration program.
	 * @param T1 - Provided by calibration program.
	 * @param T2 - Provided by calibration program.
	 * @param T3 - Provided by calibration program.
	 * @param T4 - Provided by calibration program.
	 * @param T5 - Provided by calibration program.
	 */
	async setMagnetometerCorrectionParameters(magneticField: number, offset0: number, offset1: number, offset2: number, gain0: number, gain1: number, gain2: number, T0: number, T1: number, T2: number, T3: number, T4: number, T5: number): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: magneticField });
		bp.set({ name: "1", type: "g", value: offset0 });
		bp.set({ name: "2", type: "g", value: offset1 });
		bp.set({ name: "3", type: "g", value: offset2 });
		bp.set({ name: "4", type: "g", value: gain0 });
		bp.set({ name: "5", type: "g", value: gain1 });
		bp.set({ name: "6", type: "g", value: gain2 });
		bp.set({ name: "7", type: "g", value: T0 });
		bp.set({ name: "8", type: "g", value: T1 });
		bp.set({ name: "9", type: "g", value: T2 });
		bp.set({ name: "10", type: "g", value: T3 });
		bp.set({ name: "11", type: "g", value: T4 });
		bp.set({ name: "12", type: "g", value: T5 });
		await bp.send(this._ch, BP.SETCORRECTIONPARAMETERS);
	}

	/**
	 * Selects between high/low precision sensing chips.
	 * @returns The sensor precision value
	 * @throws {@link PhidgetError}
	 * @internal
	 */
	getPrecision(): Enum.SpatialPrecision {
		this._assertOpen();

		if (this.data.precision === PUNK.ENUM)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.precision);
	}

	/**
	 * Selects between high/low precision sensing chips.
	 * @throws {@link PhidgetError}
	 * @param precision - The sensor precision value
	 * @internal
	 */
	async setPrecision(precision: Enum.SpatialPrecision): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (!SEnum.supportedSpatialPrecision(this._ch!, precision))
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Specified SpatialPrecision is unsupported by this device.");

		bp.set({ name: "0", type: "d", value: precision });
		await bp.send(this._ch, BP.SETSPATIALPRECISION);
	}

	/**
	 * Gets the latest AHRS/IMU quaternion sent from the device.
	 * @returns Gets the latest AHRS/IMU quaternion sent from the device.
	 * @throws {@link PhidgetError}
	 */
	abstract getQuaternion(): Struct.SpatialQuaternion;
	/**
	 * Resets the `MagnetometerCorrectionParameters` to their default values.
	 * 
	 * *   Due to physical location, hard and soft iron offsets, and even bias errors, your device should be calibrated. We have created a calibration program that will provide you with the `MagnetometerCorrectionParameters` for your specific situation. See your device's User Guide for more information.
	 * @throws {@link PhidgetError}
	 */
	async resetMagnetometerCorrectionParameters(): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		await bp.send(this._ch, BP.RESETCORRECTIONPARAMETERS);
	}

	/**
	 * Saves the `MagnetometerCorrectionParameters`.
	 * 
	 * *   Due to physical location, hard and soft iron offsets, and even bias errors, your device should be calibrated. We have created a calibration program that will provide you with the `MagnetometerCorrectionParameters` for your specific situation. See your device's User Guide for more information.
	 * @throws {@link PhidgetError}
	 */
	async saveMagnetometerCorrectionParameters(): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		await bp.send(this._ch, BP.SAVECORRECTIONPARAMETERS);
	}

	/**
	 * Zeros the AHRS algorithm.
	 * @throws {@link PhidgetError}
	 */
	async zeroAlgorithm(): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		await bp.send(this._ch, BP.ZEROSPATIALALGORITHM);
	}

	/**
	 * Re-zeros the gyroscope in 1-2 seconds.
	 * 
	 * *   The device must be stationary when zeroing.
	 * *   The angular rate will be reported as 0.0°/s while zeroing.
	 * *   Zeroing the gyroscope is a method of compensating for the drift that is inherent to all gyroscopes. See your device's User Guide for more information on dealing with drift.
	 * @throws {@link PhidgetError}
	 */
	async zeroGyro(): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		await bp.send(this._ch, BP.ZERO);
	}

}
import { type SpatialDevice } from '../usb/device/SpatialDevice';
export { SpatialBase };
