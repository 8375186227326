/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PhidgetChannel } from '../Phidget';
import { Channel } from '../Channel';
import { ErrorCode, ChannelClass } from '../Enumerations.gen';
import * as Struct from '../Structs.gen';
import { PhidgetError } from '../PhidgetError';
import { BridgePacket, PUNK } from '../BridgePacket';
import { BP } from '../BridgePackets.gen';
import { logEventException } from '../Logging';

/** @internal */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface GPSData {
	altitude: number,
	date: Struct.GPSDate | null,
	heading: number,
	latitude: number,
	longitude: number,
	NMEAData: Struct.NMEAData | null,
	positionFixState: number,
	time: Struct.GPSTime | null,
	velocity: number,
}

abstract class GPSBase extends PhidgetChannel {
	/** @internal */
	data: GPSData;
	/**
	 * **PositionChange** event
	 *  * `latitude` - The current latitude
	 *  * `longitude` - The current longitude
	 *  * `altitude` - The current altitude
	 * ---
	 * The most recent values the channel has measured will be reported in this event, which occurs when the GPS position changes.
	 */
	onPositionChange: ((latitude: number, longitude: number, altitude: number) => void) | null = null;
	/** @internal */
	_gotPositionChangeErrorEvent?: boolean;
	/**
	 * **HeadingChange** event
	 *  * `heading` - The current heading
	 *  * `velocity` - The current velocity
	 * ---
	 * The most recent heading and velocity values will be reported in this event, which occurs when the GPS heading changes.
	 */
	onHeadingChange: ((heading: number, velocity: number) => void) | null = null;
	/** @internal */
	_gotHeadingChangeErrorEvent?: boolean;
	/**
	 * **PositionFixStateChange** event
	 *  * `positionFixState` - The state of the position fix. True indicates a fix is obtained. False indicates no fix found.
	 * ---
	 * Occurs when a position fix is obtained or lost.
	 */
	onPositionFixStateChange: ((positionFixState: boolean) => void) | null = null;
	/** @internal */
	_gotPositionFixStateChangeErrorEvent?: boolean;

	/**
	 * The GPS class is used to configure and gather data from Phidgets GPS sensors, and gives you access to variables from GPS data packets.
	 * @public
	 */
	constructor();
	/** @internal */
	constructor(ch?: Channel);
	constructor(ch?: Channel) {
		super(ch);
		this._class = ChannelClass.GPS;
		this.name = "GPS";
		this.data = this._initData();
	}

	/** @internal */
	_bridgeInput(bp: BridgePacket) {

		switch(bp.vpkt) {
		case BP.HEADINGCHANGE: {
			this.data.heading = bp.entries[0].v as number;
			this.data.velocity = bp.entries[1].v as number;
			if (this._isAttachedDone && this.onHeadingChange) {
				try {
					this.onHeadingChange(this.data.heading, this.data.velocity);
				} catch (err) { logEventException(err); }
			}
			break;
		}
		case BP.POSITIONCHANGE: {
			this.data.latitude = bp.entries[0].v as number;
			this.data.longitude = bp.entries[1].v as number;
			this.data.altitude = bp.entries[2].v as number;
			if (this._isAttachedDone && this.onPositionChange) {
				try {
					this.onPositionChange(this.data.latitude, this.data.longitude, this.data.altitude);
				} catch (err) { logEventException(err); }
			}
			break;
		}
		case BP.POSITIONFIXSTATUSCHANGE: {
			this.data.positionFixState = bp.entries[0].v as number;
			if (this._isAttachedDone && this.onPositionFixStateChange) {
				try {
					this.onPositionFixStateChange(!!this.data.positionFixState);
				} catch (err) { logEventException(err); }
			}
			break;
		}
		default:
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			throw new PhidgetError(ErrorCode.INVALID_PACKET, "Unsupported bridge packet: 0x" + bp.vpkt!.toString(16));
		}
	}

	/** @internal */
	_initData(): GPSData {
		return {
			altitude: PUNK.DBL,
			date: null,
			heading: PUNK.DBL,
			latitude: PUNK.DBL,
			longitude: PUNK.DBL,
			NMEAData: null,
			positionFixState: PUNK.BOOL,
			time: null,
			velocity: PUNK.DBL,
		}
	}

	/** @internal */
	_initAfterOpen() {
		// This should never be called as no USB Phidgets that use this calls are supported
		throw new PhidgetError(ErrorCode.UNEXPECTED);
	}

	/** @internal */
	// eslint-disable-next-line require-await
	async _setDefaults() {
		// This should never be called as no USB Phidgets that use this calls are supported
		throw new PhidgetError(ErrorCode.UNEXPECTED);
	}

	/** @internal */
	_hasInitialState() {

		if ((this.data.heading == PUNK.DBL ||
			this.data.velocity == PUNK.DBL)
			&& ! this._gotHeadingChangeErrorEvent)
			return false;
		if ((this.data.latitude == PUNK.DBL ||
			this.data.longitude == PUNK.DBL ||
			this.data.altitude == PUNK.DBL)
			&& ! this._gotPositionChangeErrorEvent)
			return false;
		if ((this.data.positionFixState == PUNK.BOOL)
			&& ! this._gotPositionFixStateChangeErrorEvent)
			return false;

		return true;
	}

	/** @internal */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	_fireInitialEvents() {

		if(this.data.heading != PUNK.DBL &&
			this.data.velocity != PUNK.DBL)
			if (this.onHeadingChange)
				try {
					this.onHeadingChange(this.data.heading, this.data.velocity);
				} catch (err) { logEventException(err); }

		if(this.data.latitude != PUNK.DBL &&
			this.data.longitude != PUNK.DBL &&
			this.data.altitude != PUNK.DBL)
			if (this.onPositionChange)
				try {
					this.onPositionChange(this.data.latitude, this.data.longitude, this.data.altitude);
				} catch (err) { logEventException(err); }

		if(this.data.positionFixState != PUNK.BOOL)
			if (this.onPositionFixStateChange)
				try {
					this.onPositionFixStateChange(!!this.data.positionFixState);
				} catch (err) { logEventException(err); }

	}

	/**
	 * The altitude above mean sea level in meters.
	 * @throws {@link PhidgetError}
	 */
	get altitude() { return this.getAltitude(); }
	/**
	 * The UTC date of the last received position.
	 * @throws {@link PhidgetError}
	 */
	get date() { return this.getDate(); }
	/**
	 * The current date and time in UTC
	 * @throws {@link PhidgetError}
	 */
	get dateAndTime() { return this.getDateAndTime(); }
	/**
	 * The current true course over ground of the GPS
	 * @throws {@link PhidgetError}
	 */
	get heading() { return this.getHeading(); }
	/**
	 * The latitude of the GPS in degrees
	 * @throws {@link PhidgetError}
	 */
	get latitude() { return this.getLatitude(); }
	/**
	 * The longitude of the GPS.
	 * @throws {@link PhidgetError}
	 */
	get longitude() { return this.getLongitude(); }
	/**
	 * The NMEA data structure.
	 * @throws {@link PhidgetError}
	 */
	get NMEAData() { return this.getNMEAData(); }
	/**
	 * The status of the position fix
	 * 
	 * *   True if a fix is available and latitude, longitude, and altitude can be read. False if the fix is not available.
	 * @throws {@link PhidgetError}
	 */
	get positionFixState() { return this.getPositionFixState(); }
	/**
	 * The current UTC time of the GPS
	 * @throws {@link PhidgetError}
	 */
	get time() { return this.getTime(); }
	/**
	 * The current speed over ground of the GPS.
	 * @throws {@link PhidgetError}
	 */
	get velocity() { return this.getVelocity(); }

	/**
	 * The altitude above mean sea level in meters.
	 * @returns Altitude of the GPS
	 * @throws {@link PhidgetError}
	 */
	getAltitude(): number {
		this._assertOpen();

		if (this.data.altitude === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.altitude);
	}

	/**
	 * The UTC date of the last received position.
	 * @returns Date of last position
	 * @throws {@link PhidgetError}
	 */
	getDate(): Struct.GPSDate {
		this._assertOpen();

		if (this.data.date === null)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.date);
	}

	/**
	 * The current date and time in UTC
	 * @returns Current date and time
	 * @throws {@link PhidgetError}
	 */
	abstract getDateAndTime(): Date;
	/**
	 * The current true course over ground of the GPS
	 * @returns Heading of the GPS
	 * @throws {@link PhidgetError}
	 */
	getHeading(): number {
		this._assertOpen();

		if (this.data.heading === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.heading);
	}

	/**
	 * The latitude of the GPS in degrees
	 * @returns Latitude of the GPS
	 * @throws {@link PhidgetError}
	 */
	getLatitude(): number {
		this._assertOpen();

		if (this.data.latitude === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.latitude);
	}

	/**
	 * The longitude of the GPS.
	 * @returns Longtidue of the GPS
	 * @throws {@link PhidgetError}
	 */
	getLongitude(): number {
		this._assertOpen();

		if (this.data.longitude === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.longitude);
	}

	/**
	 * The NMEA data structure.
	 * @returns NMEA Data structure
	 * @throws {@link PhidgetError}
	 */
	getNMEAData(): Struct.NMEAData {
		this._assertOpen();

		if (this.data.NMEAData === null)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.NMEAData);
	}

	/**
	 * The status of the position fix
	 * 
	 * *   True if a fix is available and latitude, longitude, and altitude can be read. False if the fix is not available.
	 * @returns Status of the position fix
	 * @throws {@link PhidgetError}
	 */
	getPositionFixState(): boolean {
		this._assertOpen();

		if (this.data.positionFixState === PUNK.BOOL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (!!this.data.positionFixState);
	}

	/**
	 * The current UTC time of the GPS
	 * @returns Current time
	 * @throws {@link PhidgetError}
	 */
	getTime(): Struct.GPSTime {
		this._assertOpen();

		if (this.data.time === null)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.time);
	}

	/**
	 * The current speed over ground of the GPS.
	 * @returns Velocity of the GPS
	 * @throws {@link PhidgetError}
	 */
	getVelocity(): number {
		this._assertOpen();

		if (this.data.velocity === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.velocity);
	}

}
export { GPSBase };
