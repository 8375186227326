/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PhidgetChannel } from '../Phidget';
import { Channel } from '../Channel';
import { ErrorCode, ChannelClass } from '../Enumerations.gen';
import { PhidgetError } from '../PhidgetError';
import { BridgePacket, PUNK } from '../BridgePacket';
import { BP } from '../BridgePackets.gen';
import { logEventException } from '../Logging';
import { DeviceChannelUID } from '../Devices.gen';

/** @internal */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface CapacitiveTouchData {
	touchValueChangeTrigger: number,
	minTouchValueChangeTrigger: number,
	maxTouchValueChangeTrigger: number,
	dataInterval: number,
	minDataInterval: number,
	maxDataInterval: number,
	minDataRate: number,
	maxDataRate: number,
	sensitivity: number,
	minSensitivity: number,
	maxSensitivity: number,
	maxTouchValue: number,
	minTouchValue: number,
	touchValue: number,
	isTouched: number,
}

abstract class CapacitiveTouchBase extends PhidgetChannel {
	/** @internal */
	data: CapacitiveTouchData;
	/**
	 * **Touch** event
	 *  * `touchValue` - Value of the touch input axis.
	 * ---
	 * The most recent touch value the channel has measured will be reported in this event, which occurs when the `dataInterval` has elapsed.
	 * 
	 * *   If a `touchValueChangeTrigger` has been set to a non-zero value, the `Touch` event will not occur until the touch value has changed by at least the `touchValueChangeTrigger` value.
	 */
	onTouch: ((touchValue: number) => void) | null = null;
	/** @internal */
	_gotTouchErrorEvent?: boolean;
	/**
	 * **TouchEnd** event
	 * ---
	 * The channel will report a `TouchEnd` event to signify that it is no longer detecting a touch.
	 */
	onTouchEnd: (() => void) | null = null;
	/** @internal */
	_gotTouchEndErrorEvent?: boolean;

	/**
	 * The Capacitive Touch class gathers input data from capacitive buttons and sliders on Phidget boards.
	 * @public
	 */
	constructor();
	/** @internal */
	constructor(ch?: Channel);
	constructor(ch?: Channel) {
		super(ch);
		this._class = ChannelClass.CAPACITIVE_TOUCH;
		this.name = "CapacitiveTouch";
		this.data = this._initData();
	}

	/** @internal */
	_bridgeInput(bp: BridgePacket) {

		switch(bp.vpkt) {
		case BP.SETDATAINTERVAL:
			if (bp.entryCount > 1)
				this.data.dataInterval = bp.entries[1].v as number;
			else
				this.data.dataInterval = bp.entries[0].v as number;
			this._FIREPropertyChange('DataInterval', bp);
			this._FIREPropertyChange('DataRate', bp);
			break;
		case BP.SETSENSITIVITY:
			this.data.sensitivity = bp.entries[0].v as number;
			this._FIREPropertyChange('Sensitivity', bp);
			break;
		case BP.SETCHANGETRIGGER:
			this.data.touchValueChangeTrigger = bp.entries[0].v as number;
			this._FIREPropertyChange('TouchValueChangeTrigger', bp);
			break;
		case BP.TOUCHINPUTVALUECHANGE: {
			this.data.touchValue = bp.entries[0].v as number;
			if (this._isAttachedDone && this.onTouch) {
				try {
					this.onTouch(this.data.touchValue);
				} catch (err) { logEventException(err); }
			}
			break;
		}
		case BP.TOUCHINPUTEND: {
			if (this._isAttachedDone && this.onTouchEnd) {
				try {
					this.onTouchEnd();
				} catch (err) { logEventException(err); }
			}
			break;
		}
		default:
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			throw new PhidgetError(ErrorCode.INVALID_PACKET, "Unsupported bridge packet: 0x" + bp.vpkt!.toString(16));
		}
	}

	/** @internal */
	_initData(): CapacitiveTouchData {
		return {
			touchValueChangeTrigger: PUNK.DBL,
			minTouchValueChangeTrigger: PUNK.DBL,
			maxTouchValueChangeTrigger: PUNK.DBL,
			dataInterval: PUNK.DBL,
			minDataInterval: PUNK.UINT32,
			maxDataInterval: PUNK.UINT32,
			minDataRate: PUNK.DBL,
			maxDataRate: PUNK.DBL,
			sensitivity: PUNK.DBL,
			minSensitivity: PUNK.DBL,
			maxSensitivity: PUNK.DBL,
			maxTouchValue: PUNK.DBL,
			minTouchValue: PUNK.DBL,
			touchValue: PUNK.DBL,
			isTouched: PUNK.BOOL,
		}
	}

	/** @internal */
	_initAfterOpen() {
		this.data = this._initData();

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._HIN1000_CAPACITIVETOUCH_100:
			this.data.touchValueChangeTrigger = 0;
			this.data.minTouchValueChangeTrigger = 0;
			this.data.maxTouchValueChangeTrigger = 1;
			this.data.dataInterval = 25;
			this.data.minDataInterval = 25;
			this.data.maxDataInterval = 1000;
			this.data.minDataRate = 1;
			this.data.maxDataRate = 40;
			this.data.sensitivity = 0.2;
			this.data.minSensitivity = 0;
			this.data.maxSensitivity = 1;
			this.data.maxTouchValue = 1;
			this.data.minTouchValue = 0;
			break;
		case DeviceChannelUID._HIN1001_CAPACITIVETOUCH_BUTTONS_100:
			this.data.touchValueChangeTrigger = 0;
			this.data.minTouchValueChangeTrigger = 0;
			this.data.maxTouchValueChangeTrigger = 0.5;
			this.data.dataInterval = 20;
			this.data.minDataInterval = 20;
			this.data.maxDataInterval = 250;
			this.data.minDataRate = 4;
			this.data.maxDataRate = 50;
			this.data.sensitivity = 0.5;
			this.data.minSensitivity = 0;
			this.data.maxSensitivity = 1;
			this.data.maxTouchValue = 1;
			this.data.minTouchValue = 0;
			break;
		case DeviceChannelUID._HIN1001_CAPACITIVETOUCH_WHEEL_100:
			this.data.touchValueChangeTrigger = 0;
			this.data.minTouchValueChangeTrigger = 0;
			this.data.maxTouchValueChangeTrigger = 0.5;
			this.data.dataInterval = 20;
			this.data.minDataInterval = 20;
			this.data.maxDataInterval = 250;
			this.data.minDataRate = 4;
			this.data.maxDataRate = 50;
			this.data.sensitivity = 0.7;
			this.data.minSensitivity = 0;
			this.data.maxSensitivity = 1;
			this.data.maxTouchValue = 1;
			this.data.minTouchValue = 0;
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	// eslint-disable-next-line require-await
	async _setDefaults() {
		let bp;

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._HIN1000_CAPACITIVETOUCH_100:
		case DeviceChannelUID._HIN1001_CAPACITIVETOUCH_BUTTONS_100:
		case DeviceChannelUID._HIN1001_CAPACITIVETOUCH_WHEEL_100:
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.touchValueChangeTrigger });
			await bp.send(this._ch, BP.SETCHANGETRIGGER);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "u", value: Math.round(this.data.dataInterval) });
			await bp.send(this._ch, BP.SETDATAINTERVAL);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.sensitivity });
			await bp.send(this._ch, BP.SETSENSITIVITY);
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	_hasInitialState() {

		if ((this.data.touchValue == PUNK.DBL)
			&& ! this._gotTouchErrorEvent)
			return false;

		return true;
	}

	/** @internal */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	_fireInitialEvents() {

		if(this.data.touchValue != PUNK.DBL)
			if (this.onTouch)
				try {
					this.onTouch(this.data.touchValue);
				} catch (err) { logEventException(err); }

	}

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `Touch` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * *   The timing between `Touch` events can also be affected by the `touchValueChangeTrigger`.
	 * @throws {@link PhidgetError}
	 */
	get dataInterval() { return this.getDataInterval(); }
	/**
	 * The minimum value that `dataInterval` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDataInterval() { return this.getMinDataInterval(); }
	/**
	 * The maximum value that `dataInterval` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDataInterval() { return this.getMaxDataInterval(); }
	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @throws {@link PhidgetError}
	 */
	get dataRate() { return this.getDataRate(); }
	/**
	 * The minimum value that `dataRate` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDataRate() { return this.getMinDataRate(); }
	/**
	 * The maximum value that `dataRate` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDataRate() { return this.getMaxDataRate(); }
	/**
	 * Determines the sensitivity of all capacitive regions on the device.
	 * 
	 * *   Higher values result in greater touch sensitivity.
	 * *   The sensitivity value is bounded by `minSensitivity` and `maxSensitivity`.
	 * @throws {@link PhidgetError}
	 */
	get sensitivity() { return this.getSensitivity(); }
	/**
	 * The minimum value that `sensitivity` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minSensitivity() { return this.getMinSensitivity(); }
	/**
	 * The maximum value that `sensitivity` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxSensitivity() { return this.getMaxSensitivity(); }
	/**
	 * The most recent touch state that the channel has reported.
	 * 
	 * *   This will be 0 or 1
	 * 
	 * *   0 is not touched
	 * *   1 is touched
	 * @throws {@link PhidgetError}
	 */
	get isTouched() { return this.getIsTouched(); }
	/**
	 * The most recent touch value that the channel has reported.
	 * 
	 * *   This will be 0 or 1 for button-type inputs, or a ratio between 0-1 for axis-type inputs.
	 * *   This value is bounded by `minTouchValue` and `maxTouchValue`
	 * *   The value is not reset when the touch ends
	 * @throws {@link PhidgetError}
	 */
	get touchValue() { return this.getTouchValue(); }
	/**
	 * The minimum value the `Touch` event will report.
	 * @throws {@link PhidgetError}
	 */
	get minTouchValue() { return this.getMinTouchValue(); }
	/**
	 * The maximum value the `Touch` event will report.
	 * @throws {@link PhidgetError}
	 */
	get maxTouchValue() { return this.getMaxTouchValue(); }
	/**
	 * The channel will not issue a `Touch` event until the touch value has changed by the amount specified by the `touchValueChangeTrigger`.
	 * 
	 * *   Setting the `touchValueChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering
	 * @throws {@link PhidgetError}
	 */
	get touchValueChangeTrigger() { return this.getTouchValueChangeTrigger(); }
	/**
	 * The minimum value that `touchValueChangeTrigger` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minTouchValueChangeTrigger() { return this.getMinTouchValueChangeTrigger(); }
	/**
	 * The maximum value that `touchValueChangeTrigger` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxTouchValueChangeTrigger() { return this.getMaxTouchValueChangeTrigger(); }

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `Touch` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * *   The timing between `Touch` events can also be affected by the `touchValueChangeTrigger`.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getDataInterval(): number {
		this._assertOpen();

		if (this.data.dataInterval === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return this.data.dataInterval;
	}

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `Touch` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * *   The timing between `Touch` events can also be affected by the `touchValueChangeTrigger`.
	 * @throws {@link PhidgetError}
	 * @param dataInterval - The data interval value
	 */
	async setDataInterval(dataInterval: number): Promise<void> {
		this._assertOpen();

		if (dataInterval < this.data.minDataInterval || dataInterval > this.data.maxDataInterval)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDataInterval + " - " + this.data.maxDataInterval + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: dataInterval });
		await bp.send(this._ch, BP.SETDATAINTERVAL);
	}

	/**
	 * The minimum value that `dataInterval` can be set to.
	 * @returns The minimum data interval value
	 * @throws {@link PhidgetError}
	 */
	getMinDataInterval(): number {
		this._assertOpen();

		if (this.data.minDataInterval === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDataInterval);
	}

	/**
	 * The maximum value that `dataInterval` can be set to.
	 * @returns The maximum data interval value
	 * @throws {@link PhidgetError}
	 */
	getMaxDataInterval(): number {
		this._assertOpen();

		if (this.data.maxDataInterval === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDataInterval);
	}

	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @returns The data rate for the channel
	 * @throws {@link PhidgetError}
	 */
	getDataRate(): number {
		this._assertOpen();

		if (this.data.dataInterval === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (1000.0 / this.data.dataInterval);
	}

	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @throws {@link PhidgetError}
	 * @param dataRate - The data rate for the channel
	 */
	async setDataRate(dataRate: number): Promise<void> {
		this._assertOpen();

		if (dataRate < this.data.minDataRate || dataRate > this.data.maxDataRate)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDataRate + " - " + this.data.maxDataRate + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: Math.round(1000.0 / dataRate) });
		bp.set({ name: "1", type: "g", value: (1000.0 / dataRate) });
		await bp.send(this._ch, BP.SETDATAINTERVAL);
	}

	/**
	 * The minimum value that `dataRate` can be set to.
	 * @returns The data rate value
	 * @throws {@link PhidgetError}
	 */
	getMinDataRate(): number {
		this._assertOpen();

		if (this.data.minDataRate === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDataRate);
	}

	/**
	 * The maximum value that `dataRate` can be set to.
	 * @returns The data rate value
	 * @throws {@link PhidgetError}
	 */
	getMaxDataRate(): number {
		this._assertOpen();

		if (this.data.maxDataRate === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDataRate);
	}

	/**
	 * The most recent touch state that the channel has reported.
	 * 
	 * *   This will be 0 or 1
	 * 
	 * *   0 is not touched
	 * *   1 is touched
	 * @returns The touched state
	 * @throws {@link PhidgetError}
	 */
	getIsTouched(): boolean {
		this._assertOpen();

		if (this.data.isTouched === PUNK.BOOL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (!!this.data.isTouched);
	}

	/**
	 * Determines the sensitivity of all capacitive regions on the device.
	 * 
	 * *   Higher values result in greater touch sensitivity.
	 * *   The sensitivity value is bounded by `minSensitivity` and `maxSensitivity`.
	 * @returns The sensitivity value
	 * @throws {@link PhidgetError}
	 */
	getSensitivity(): number {
		this._assertOpen();

		if (this.data.sensitivity === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.sensitivity);
	}

	/**
	 * Determines the sensitivity of all capacitive regions on the device.
	 * 
	 * *   Higher values result in greater touch sensitivity.
	 * *   The sensitivity value is bounded by `minSensitivity` and `maxSensitivity`.
	 * @throws {@link PhidgetError}
	 * @param sensitivity - The sensitivity value
	 */
	async setSensitivity(sensitivity: number): Promise<void> {
		this._assertOpen();

		if (sensitivity < this.data.minSensitivity || sensitivity > this.data.maxSensitivity)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minSensitivity + " - " + this.data.maxSensitivity + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: sensitivity });
		await bp.send(this._ch, BP.SETSENSITIVITY);
	}

	/**
	 * The minimum value that `sensitivity` can be set to.
	 * @returns The minimum sensitivity value
	 * @throws {@link PhidgetError}
	 */
	getMinSensitivity(): number {
		this._assertOpen();

		if (this.data.minSensitivity === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minSensitivity);
	}

	/**
	 * The maximum value that `sensitivity` can be set to.
	 * @returns The maximum sensitivity value
	 * @throws {@link PhidgetError}
	 */
	getMaxSensitivity(): number {
		this._assertOpen();

		if (this.data.maxSensitivity === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxSensitivity);
	}

	/**
	 * The most recent touch value that the channel has reported.
	 * 
	 * *   This will be 0 or 1 for button-type inputs, or a ratio between 0-1 for axis-type inputs.
	 * *   This value is bounded by `minTouchValue` and `maxTouchValue`
	 * *   The value is not reset when the touch ends
	 * @returns The touch input value
	 * @throws {@link PhidgetError}
	 */
	getTouchValue(): number {
		this._assertOpen();

		if (this.data.touchValue === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.touchValue);
	}

	/**
	 * The minimum value the `Touch` event will report.
	 * @returns The minimum touch input value
	 * @throws {@link PhidgetError}
	 */
	getMinTouchValue(): number {
		this._assertOpen();

		if (this.data.minTouchValue === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minTouchValue);
	}

	/**
	 * The maximum value the `Touch` event will report.
	 * @returns The maximum touch input value
	 * @throws {@link PhidgetError}
	 */
	getMaxTouchValue(): number {
		this._assertOpen();

		if (this.data.maxTouchValue === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxTouchValue);
	}

	/**
	 * The channel will not issue a `Touch` event until the touch value has changed by the amount specified by the `touchValueChangeTrigger`.
	 * 
	 * *   Setting the `touchValueChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering
	 * @returns The change trigger value
	 * @throws {@link PhidgetError}
	 */
	getTouchValueChangeTrigger(): number {
		this._assertOpen();

		if (this.data.touchValueChangeTrigger === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.touchValueChangeTrigger);
	}

	/**
	 * The channel will not issue a `Touch` event until the touch value has changed by the amount specified by the `touchValueChangeTrigger`.
	 * 
	 * *   Setting the `touchValueChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering
	 * @throws {@link PhidgetError}
	 * @param touchValueChangeTrigger - The change trigger value
	 */
	async setTouchValueChangeTrigger(touchValueChangeTrigger: number): Promise<void> {
		this._assertOpen();

		if (touchValueChangeTrigger < this.data.minTouchValueChangeTrigger || touchValueChangeTrigger > this.data.maxTouchValueChangeTrigger)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minTouchValueChangeTrigger + " - " + this.data.maxTouchValueChangeTrigger + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: touchValueChangeTrigger });
		await bp.send(this._ch, BP.SETCHANGETRIGGER);
	}

	/**
	 * The minimum value that `touchValueChangeTrigger` can be set to.
	 * @returns The minimum change trigger value
	 * @throws {@link PhidgetError}
	 */
	getMinTouchValueChangeTrigger(): number {
		this._assertOpen();

		if (this.data.minTouchValueChangeTrigger === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minTouchValueChangeTrigger);
	}

	/**
	 * The maximum value that `touchValueChangeTrigger` can be set to.
	 * @returns The maximum change trigger value
	 * @throws {@link PhidgetError}
	 */
	getMaxTouchValueChangeTrigger(): number {
		this._assertOpen();

		if (this.data.maxTouchValueChangeTrigger === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxTouchValueChangeTrigger);
	}

}
export { CapacitiveTouchBase };
