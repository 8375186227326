/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PhidgetChannel } from '../Phidget';
import { Channel } from '../Channel';
import { ErrorCode, ChannelClass } from '../Enumerations.gen';
import * as Enum from '../Enumerations.gen';
import * as SEnum from '../SupportedEnum.gen';
import { PhidgetError } from '../PhidgetError';
import { BridgePacket, PUNK } from '../BridgePacket';
import { BP } from '../BridgePackets.gen';
import { logEventException } from '../Logging';
import { DeviceChannelUID } from '../Devices.gen';

/** @internal */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DCMotorData {
	dataInterval: number,
	minDataInterval: number,
	maxDataInterval: number,
	minDataRate: number,
	maxDataRate: number,
	currentLimit: number,
	maxCurrentLimit: number,
	minCurrentLimit: number,
	currentRegulatorGain: number,
	maxCurrentRegulatorGain: number,
	minCurrentRegulatorGain: number,
	targetVelocity: number,
	maxVelocity: number,
	minVelocity: number,
	maxAcceleration: number,
	minAcceleration: number,
	acceleration: number,
	targetBrakingStrength: number,
	maxBrakingStrength: number,
	minBrakingStrength: number,
	backEMFSensingState: number,
	backEMF: number,
	fanMode: Enum.FanMode | PUNK.ENUM,
	velocity: number,
	brakingStrength: number,
	maxFailsafeTime: number,
	minFailsafeTime: number,
}

abstract class DCMotorBase extends PhidgetChannel {
	/** @internal */
	data: DCMotorData;
	/**
	 * **BrakingStrengthChange** event
	 *  * `brakingStrength` - The most recent braking strength value will be reported in this event.

*   This event will occur only when the value of braking strength has changed
*   See `targetBrakingStrength` for details about what this number represents.
	 * ---
	 * Occurs when the motor braking strength changes.
	 */
	onBrakingStrengthChange: ((brakingStrength: number) => void) | null = null;
	/** @internal */
	_gotBrakingStrengthChangeErrorEvent?: boolean;
	/**
	 * **VelocityUpdate** event
	 *  * `velocity` - The most recent velocity value will be reported in this event.
	 * ---
	 * Occurs at a rate defined by the `dataInterval`.
	 */
	onVelocityUpdate: ((velocity: number) => void) | null = null;
	/** @internal */
	_gotVelocityUpdateErrorEvent?: boolean;
	/**
	 * **BackEMFChange** event
	 *  * `backEMF` - The back EMF voltage from the motor
	 * ---
	 * The most recent back emf value will be reported in this event.
	 */
	onBackEMFChange: ((backEMF: number) => void) | null = null;
	/** @internal */
	_gotBackEMFChangeErrorEvent?: boolean;

	/**
	 * The DC Motor class controls the power applied to attached DC motors to affect its speed and direction. It can also contain various other control and monitoring functions that aid in the control of DC motors.
	 * @public
	 */
	constructor();
	/** @internal */
	constructor(ch?: Channel);
	constructor(ch?: Channel) {
		super(ch);
		this._class = ChannelClass.DC_MOTOR;
		this.name = "DCMotor";
		this.data = this._initData();
	}

	/** @internal */
	_bridgeInput(bp: BridgePacket) {

		switch(bp.vpkt) {
		case BP.SETACCELERATION:
			this.data.acceleration = bp.entries[0].v as number;
			this._FIREPropertyChange('Acceleration', bp);
			break;
		case BP.SETBACKEMFSENSINGSTATE:
			this.data.backEMFSensingState = bp.entries[0].v as number;
			this._FIREPropertyChange('BackEMFSensingState', bp);
			break;
		case BP.SETCURRENTLIMIT:
			this.data.currentLimit = bp.entries[0].v as number;
			this._FIREPropertyChange('CurrentLimit', bp);
			break;
		case BP.SETCURRENTREGULATORGAIN:
			this.data.currentRegulatorGain = bp.entries[0].v as number;
			this._FIREPropertyChange('CurrentRegulatorGain', bp);
			break;
		case BP.SETDATAINTERVAL:
			if (bp.entryCount > 1)
				this.data.dataInterval = bp.entries[1].v as number;
			else
				this.data.dataInterval = bp.entries[0].v as number;
			this._FIREPropertyChange('DataInterval', bp);
			this._FIREPropertyChange('DataRate', bp);
			break;
		case BP.SETFAILSAFETIME:
			break;
		case BP.SETFANMODE:
			this.data.fanMode = bp.entries[0].v as Enum.FanMode;
			this._FIREPropertyChange('FanMode', bp);
			break;
		case BP.FAILSAFERESET:
			break;
		case BP.SETBRAKINGDUTYCYCLE:
			this.data.targetBrakingStrength = bp.entries[0].v as number;
			this._FIREPropertyChange('TargetBrakingStrength', bp);
			break;
		case BP.SETDUTYCYCLE:
			this.data.targetVelocity = bp.entries[0].v as number;
			this._FIREPropertyChange('TargetVelocity', bp);
			break;
		case BP.BACKEMFCHANGE: {
			this.data.backEMF = bp.entries[0].v as number;
			if (this._isAttachedDone && this.onBackEMFChange) {
				try {
					this.onBackEMFChange(this.data.backEMF);
				} catch (err) { logEventException(err); }
			}
			break;
		}
		case BP.BRAKINGSTRENGTHCHANGE: {
			this.data.brakingStrength = bp.entries[0].v as number;
			if (this._isAttachedDone && this.onBrakingStrengthChange) {
				try {
					this.onBrakingStrengthChange(this.data.brakingStrength);
				} catch (err) { logEventException(err); }
			}
			break;
		}
		case BP.DUTYCYCLECHANGE: {
			this.data.velocity = bp.entries[0].v as number;
			if (this._isAttachedDone && this.onVelocityUpdate) {
				try {
					this.onVelocityUpdate(this.data.velocity);
				} catch (err) { logEventException(err); }
			}
			break;
		}
		default:
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			throw new PhidgetError(ErrorCode.INVALID_PACKET, "Unsupported bridge packet: 0x" + bp.vpkt!.toString(16));
		}
	}

	/** @internal */
	_initData(): DCMotorData {
		return {
			dataInterval: PUNK.DBL,
			minDataInterval: PUNK.UINT32,
			maxDataInterval: PUNK.UINT32,
			minDataRate: PUNK.DBL,
			maxDataRate: PUNK.DBL,
			currentLimit: PUNK.DBL,
			maxCurrentLimit: PUNK.DBL,
			minCurrentLimit: PUNK.DBL,
			currentRegulatorGain: PUNK.DBL,
			maxCurrentRegulatorGain: PUNK.DBL,
			minCurrentRegulatorGain: PUNK.DBL,
			targetVelocity: PUNK.DBL,
			maxVelocity: PUNK.DBL,
			minVelocity: PUNK.DBL,
			maxAcceleration: PUNK.DBL,
			minAcceleration: PUNK.DBL,
			acceleration: PUNK.DBL,
			targetBrakingStrength: PUNK.DBL,
			maxBrakingStrength: PUNK.DBL,
			minBrakingStrength: PUNK.DBL,
			backEMFSensingState: PUNK.BOOL,
			backEMF: PUNK.DBL,
			fanMode: PUNK.ENUM,
			velocity: PUNK.DBL,
			brakingStrength: PUNK.DBL,
			maxFailsafeTime: PUNK.UINT32,
			minFailsafeTime: PUNK.UINT32,
		}
	}

	/** @internal */
	_initAfterOpen() {
		this.data = this._initData();

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._DCC1000_DCMOTOR_100:
		case DeviceChannelUID._DCC1000_DCMOTOR_200:
			this.data.dataInterval = 250;
			this.data.minDataInterval = 100;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 10;
			this.data.currentLimit = 2;
			this.data.maxCurrentLimit = 25;
			this.data.minCurrentLimit = 2;
			this.data.currentRegulatorGain = 10;
			this.data.maxCurrentRegulatorGain = 100;
			this.data.minCurrentRegulatorGain = 1;
			this.data.targetVelocity = 0;
			this.data.maxVelocity = 1;
			this.data.minVelocity = 0;
			this.data.maxAcceleration = 100;
			this.data.minAcceleration = 0.1;
			this.data.acceleration = 1;
			this.data.targetBrakingStrength = 0;
			this.data.maxBrakingStrength = 1;
			this.data.minBrakingStrength = 0;
			this.data.fanMode = Enum.FanMode.AUTO;
			this.data.velocity = 0;
			break;
		case DeviceChannelUID._DCC1000_DCMOTOR_210:
			this.data.dataInterval = 250;
			this.data.minDataInterval = 100;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 10;
			this.data.currentLimit = 2;
			this.data.maxCurrentLimit = 25;
			this.data.minCurrentLimit = 2;
			this.data.currentRegulatorGain = 10;
			this.data.maxCurrentRegulatorGain = 100;
			this.data.minCurrentRegulatorGain = 1;
			this.data.targetVelocity = 0;
			this.data.maxVelocity = 1;
			this.data.minVelocity = 0;
			this.data.maxAcceleration = 100;
			this.data.minAcceleration = 0.1;
			this.data.acceleration = 1;
			this.data.targetBrakingStrength = 0;
			this.data.maxBrakingStrength = 1;
			this.data.minBrakingStrength = 0;
			this.data.fanMode = Enum.FanMode.AUTO;
			this.data.velocity = 0;
			this.data.maxFailsafeTime = 30000;
			this.data.minFailsafeTime = 500;
			break;
		case DeviceChannelUID._DCC1001_DCMOTOR_100:
			this.data.dataInterval = 250;
			this.data.minDataInterval = 100;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 10;
			this.data.currentLimit = 2;
			this.data.maxCurrentLimit = 2;
			this.data.minCurrentLimit = 0;
			this.data.targetVelocity = 0;
			this.data.maxVelocity = 1;
			this.data.minVelocity = 0;
			this.data.maxAcceleration = 100;
			this.data.minAcceleration = 0.1;
			this.data.acceleration = 1;
			this.data.targetBrakingStrength = 0;
			this.data.maxBrakingStrength = 1;
			this.data.minBrakingStrength = 0;
			this.data.velocity = 0;
			break;
		case DeviceChannelUID._DCC1001_DCMOTOR_120:
			this.data.dataInterval = 250;
			this.data.minDataInterval = 100;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 10;
			this.data.currentLimit = 2;
			this.data.maxCurrentLimit = 2;
			this.data.minCurrentLimit = 0;
			this.data.targetVelocity = 0;
			this.data.maxVelocity = 1;
			this.data.minVelocity = 0;
			this.data.maxAcceleration = 100;
			this.data.minAcceleration = 0.1;
			this.data.acceleration = 1;
			this.data.targetBrakingStrength = 0;
			this.data.maxBrakingStrength = 1;
			this.data.minBrakingStrength = 0;
			this.data.velocity = 0;
			this.data.maxFailsafeTime = 30000;
			this.data.minFailsafeTime = 500;
			break;
		case DeviceChannelUID._DCC1002_DCMOTOR_100:
		case DeviceChannelUID._DCC1003_DCMOTOR_100:
			this.data.dataInterval = 250;
			this.data.minDataInterval = 100;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 10;
			this.data.currentLimit = 2;
			this.data.maxCurrentLimit = 4;
			this.data.minCurrentLimit = 0;
			this.data.targetVelocity = 0;
			this.data.maxVelocity = 1;
			this.data.minVelocity = 0;
			this.data.maxAcceleration = 100;
			this.data.minAcceleration = 0.1;
			this.data.acceleration = 1;
			this.data.targetBrakingStrength = 0;
			this.data.maxBrakingStrength = 1;
			this.data.minBrakingStrength = 0;
			this.data.velocity = 0;
			break;
		case DeviceChannelUID._DCC1002_DCMOTOR_110:
		case DeviceChannelUID._DCC1003_DCMOTOR_110:
			this.data.dataInterval = 250;
			this.data.minDataInterval = 100;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 10;
			this.data.currentLimit = 2;
			this.data.maxCurrentLimit = 4;
			this.data.minCurrentLimit = 0;
			this.data.targetVelocity = 0;
			this.data.maxVelocity = 1;
			this.data.minVelocity = 0;
			this.data.maxAcceleration = 100;
			this.data.minAcceleration = 0.1;
			this.data.acceleration = 1;
			this.data.targetBrakingStrength = 0;
			this.data.maxBrakingStrength = 1;
			this.data.minBrakingStrength = 0;
			this.data.velocity = 0;
			this.data.maxFailsafeTime = 30000;
			this.data.minFailsafeTime = 500;
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	// eslint-disable-next-line require-await
	async _setDefaults() {
		let bp;

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._DCC1000_DCMOTOR_100:
		case DeviceChannelUID._DCC1000_DCMOTOR_200:
		case DeviceChannelUID._DCC1000_DCMOTOR_210:
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.targetVelocity });
			await bp.send(this._ch, BP.SETDUTYCYCLE);
			break;
		case DeviceChannelUID._DCC1001_DCMOTOR_100:
		case DeviceChannelUID._DCC1001_DCMOTOR_120:
		case DeviceChannelUID._DCC1002_DCMOTOR_100:
		case DeviceChannelUID._DCC1002_DCMOTOR_110:
		case DeviceChannelUID._DCC1003_DCMOTOR_100:
		case DeviceChannelUID._DCC1003_DCMOTOR_110:
			bp = new BridgePacket();
			bp.set({ name: "0", type: "u", value: Math.round(this.data.dataInterval) });
			await bp.send(this._ch, BP.SETDATAINTERVAL);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.currentLimit });
			await bp.send(this._ch, BP.SETCURRENTLIMIT);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.targetVelocity });
			await bp.send(this._ch, BP.SETDUTYCYCLE);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.acceleration });
			await bp.send(this._ch, BP.SETACCELERATION);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.targetBrakingStrength });
			await bp.send(this._ch, BP.SETBRAKINGDUTYCYCLE);
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	_hasInitialState() {

		if ((this.data.backEMF == PUNK.DBL)
			&& ! this._gotBackEMFChangeErrorEvent)
			return false;
		if ((this.data.brakingStrength == PUNK.DBL)
			&& ! this._gotBrakingStrengthChangeErrorEvent)
			return false;
		if ((this.data.velocity == PUNK.DBL)
			&& ! this._gotVelocityUpdateErrorEvent)
			return false;

		return true;
	}

	/** @internal */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	_fireInitialEvents() {

		if(this.data.backEMF != PUNK.DBL)
			if (this.onBackEMFChange)
				try {
					this.onBackEMFChange(this.data.backEMF);
				} catch (err) { logEventException(err); }

		if(this.data.brakingStrength != PUNK.DBL)
			if (this.onBrakingStrengthChange)
				try {
					this.onBrakingStrengthChange(this.data.brakingStrength);
				} catch (err) { logEventException(err); }

		if(this.data.velocity != PUNK.DBL)
			if (this.onVelocityUpdate)
				try {
					this.onVelocityUpdate(this.data.velocity);
				} catch (err) { logEventException(err); }

	}

	/**
	 * The rate at which the controller can change the motor's `velocity`.
	 * 
	 * *   The acceleration is bounded by `minAcceleration` and `maxAcceleration`
	 * @throws {@link PhidgetError}
	 */
	get acceleration() { return this.getAcceleration(); }
	/**
	 * The minimum value that `acceleration` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minAcceleration() { return this.getMinAcceleration(); }
	/**
	 * The maximum value that `acceleration` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxAcceleration() { return this.getMaxAcceleration(); }
	/**
	 * The most recent `backEMF` value that the controller has reported.
	 * @throws {@link PhidgetError}
	 */
	get backEMF() { return this.getBackEMF(); }
	/**
	 * When `backEMFSensingState` is enabled, the controller will measure and report the `backEMF`.
	 * 
	 * *   The motor will coast (freewheel) 5% of the time while the back EMF is being measured (800μs every 16ms). Therefore, at a `velocity` of 100%, the motor will only be driven for 95% of the time.
	 * @throws {@link PhidgetError}
	 */
	get backEMFSensingState() { return this.getBackEMFSensingState(); }
	/**
	 * The most recent braking strength value that the controller has reported. See `targetBrakingStrength` for details.
	 * @throws {@link PhidgetError}
	 */
	get brakingStrength() { return this.getBrakingStrength(); }
	/**
	 * The minimum value that `targetBrakingStrength` can be set to. See `targetBrakingStrength` for details.
	 * @throws {@link PhidgetError}
	 */
	get minBrakingStrength() { return this.getMinBrakingStrength(); }
	/**
	 * The maximum value that `targetBrakingStrength` can be set to. See `targetBrakingStrength` for details.
	 * @throws {@link PhidgetError}
	 */
	get maxBrakingStrength() { return this.getMaxBrakingStrength(); }
	/**
	 * The controller will limit the current through the motor to the `currentLimit` value.
	 * @throws {@link PhidgetError}
	 */
	get currentLimit() { return this.getCurrentLimit(); }
	/**
	 * The minimum value that `currentLimit` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minCurrentLimit() { return this.getMinCurrentLimit(); }
	/**
	 * The maximum value that `currentLimit` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxCurrentLimit() { return this.getMaxCurrentLimit(); }
	/**
	 * Depending on power supply voltage and motor coil inductance, current through the motor can change relatively slowly or extremely rapidly. A physically larger DC Motor will typically have a lower inductance, requiring a higher current regulator gain. A higher power supply voltage will result in motor current changing more rapidly, requiring a higher current regulator gain. If the current regulator gain is too small, spikes in current will occur, causing large variations in torque, and possibly damaging the motor controller. If the current regulator gain is too high, the current will jitter, causing the motor to sound 'rough', especially when changing directions.  
	 *   
	 * As a rule of thumb, we recommend setting this value as follows:  
	 * 
	 * CurrentRegulatorGain = CurrentLimit \* (Voltage / 12)
	 * @throws {@link PhidgetError}
	 */
	get currentRegulatorGain() { return this.getCurrentRegulatorGain(); }
	/**
	 * The minimum value that `currentRegulatorGain` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minCurrentRegulatorGain() { return this.getMinCurrentRegulatorGain(); }
	/**
	 * The maximum value that `currentRegulatorGain` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxCurrentRegulatorGain() { return this.getMaxCurrentRegulatorGain(); }
	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `VelocityUpdate` / `BrakingStrengthChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * 
	 * *   Note: `BrakingStrengthChange` events will only fire if a change in braking has occurred.
	 * @throws {@link PhidgetError}
	 */
	get dataInterval() { return this.getDataInterval(); }
	/**
	 * The minimum value that `dataInterval` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDataInterval() { return this.getMinDataInterval(); }
	/**
	 * The maximum value that `dataInterval` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDataInterval() { return this.getMaxDataInterval(); }
	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @throws {@link PhidgetError}
	 */
	get dataRate() { return this.getDataRate(); }
	/**
	 * The minimum value that `dataRate` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDataRate() { return this.getMinDataRate(); }
	/**
	 * The maximum value that `dataRate` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDataRate() { return this.getMaxDataRate(); }
	/**
	 * The minimum value that `failsafeTime` can be set to when calling `enableFailsafe()`.
	 * @throws {@link PhidgetError}
	 */
	get minFailsafeTime() { return this.getMinFailsafeTime(); }
	/**
	 * The maximum value that `failsafeTime` can be set to when calling `enableFailsafe()`.
	 * @throws {@link PhidgetError}
	 */
	get maxFailsafeTime() { return this.getMaxFailsafeTime(); }
	/**
	 * The `fanMode` dictates the operating condition of the fan.
	 * 
	 * *   Choose between on, off, or automatic (based on temperature).
	 * *   If the `fanMode` is set to automatic, the fan will turn on when the temperature reaches 70°C and it will remain on until the temperature falls below 55°C.
	 * *   If the `fanMode` is off, the controller will still turn on the fan if the temperature reaches 85°C and it will remain on until it falls below 70°C.
	 * @throws {@link PhidgetError}
	 */
	get fanMode() { return this.getFanMode(); }
	/**
	 * This setting allows you to choose how hard the motor will resist being turned when it is not being driven forward or reverse (`velocity` = 0). The `targetBrakingStrength` sets the relative amount of electrical braking to be applied to the DC motor, with `minBrakingStrength` corresponding to no braking (free-wheeling), and `maxBrakingStrength` indicating full braking.
	 * 
	 * *   A low `targetBrakingStrength` value corresponds to free-wheeling. This means:
	 *     *   The motor will continue to rotate after the controller is no longer driving the motor (`velocity` = 0), due to its momentum.
	 *     *   The motor shaft will provide little resistance to being turned when it is stopped.
	 * *   As `targetBrakingStrength` increases, this will engage electrical braking of the DC motor. This means:
	 *     *   The motor will stop more quickly if it is in motion when braking is requested.
	 *     *   The motor shaft will resist rotation by outside forces.
	 * *   Braking will be added gradually, according to the `acceleration` setting, once the motor controller's `velocity` reaches 0.0
	 * *   Braking will be immediately stopped when a new (non-zero) `targetVelocity` is set, and the motor will accelerate to the requested velocity.
	 * *   Braking mode is enabled by setting the `velocity` to 0.0
	 * @throws {@link PhidgetError}
	 */
	get targetBrakingStrength() { return this.getTargetBrakingStrength(); }
	/**
	 * The average voltage across the motor is based on the `targetVelocity` value.
	 * 
	 * *   At a constant load, increasing the target velocity will increase the speed of the motor.
	 * *   `targetVelocity` is bounded by -`maxVelocity` and +`maxVelocity`, where a sign change (±) is indicitave of a direction change.
	 * *   Setting `targetVelocity` to `minVelocity` will stop the motor. See `targetBrakingStrength` for more information on stopping the motor.
	 * *   The units of `targetVelocity` and `acceleration` refer to 'duty cycle'. This is because the controller controls velocity by rapidly switching the power on/off (i.e. changing the duty cycle) in order to manipulate the voltage across the motor.
	 * @throws {@link PhidgetError}
	 */
	get targetVelocity() { return this.getTargetVelocity(); }
	/**
	 * The most recent velocity value that the controller has reported.
	 * @throws {@link PhidgetError}
	 */
	get velocity() { return this.getVelocity(); }
	/**
	 * The minimum value that `targetVelocity` can be set to.
	 * 
	 * *   Set the `targetVelocity` to `minVelocity` to stop the motor. See `targetBrakingStrength` for more information on stopping the motor.
	 * *   `targetVelocity` is bounded by -`maxVelocity` and +`maxVelocity`, where a sign change (±) is indicitave of a direction change.
	 * @throws {@link PhidgetError}
	 */
	get minVelocity() { return this.getMinVelocity(); }
	/**
	 * The maximum value that `targetVelocity` can be set to.
	 * 
	 * *   `targetVelocity` is bounded by -`maxVelocity` and +`maxVelocity`, where a sign change (±) is indicitave of a direction change.
	 * @throws {@link PhidgetError}
	 */
	get maxVelocity() { return this.getMaxVelocity(); }

	/**
	 * The rate at which the controller can change the motor's `velocity`.
	 * 
	 * *   The acceleration is bounded by `minAcceleration` and `maxAcceleration`
	 * @returns The acceleration value
	 * @throws {@link PhidgetError}
	 */
	getAcceleration(): number {
		this._assertOpen();

		if (this.data.acceleration === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.acceleration);
	}

	/**
	 * The rate at which the controller can change the motor's `velocity`.
	 * 
	 * *   The acceleration is bounded by `minAcceleration` and `maxAcceleration`
	 * @throws {@link PhidgetError}
	 * @param acceleration - The acceleration value
	 */
	async setAcceleration(acceleration: number): Promise<void> {
		this._assertOpen();

		if (acceleration < this.data.minAcceleration || acceleration > this.data.maxAcceleration)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minAcceleration + " - " + this.data.maxAcceleration + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: acceleration });
		await bp.send(this._ch, BP.SETACCELERATION);
	}

	/**
	 * The minimum value that `acceleration` can be set to.
	 * @returns The acceleration value.
	 * @throws {@link PhidgetError}
	 */
	getMinAcceleration(): number {
		this._assertOpen();

		if (this.data.minAcceleration === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minAcceleration);
	}

	/**
	 * The maximum value that `acceleration` can be set to.
	 * @returns The acceleration value.
	 * @throws {@link PhidgetError}
	 */
	getMaxAcceleration(): number {
		this._assertOpen();

		if (this.data.maxAcceleration === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxAcceleration);
	}

	/**
	 * The most recent `backEMF` value that the controller has reported.
	 * @returns The back EMF value
	 * @throws {@link PhidgetError}
	 */
	getBackEMF(): number {
		this._assertOpen();

		if (this.data.backEMF === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.backEMF);
	}

	/**
	 * When `backEMFSensingState` is enabled, the controller will measure and report the `backEMF`.
	 * 
	 * *   The motor will coast (freewheel) 5% of the time while the back EMF is being measured (800μs every 16ms). Therefore, at a `velocity` of 100%, the motor will only be driven for 95% of the time.
	 * @returns The back EMF state
	 * @throws {@link PhidgetError}
	 */
	getBackEMFSensingState(): boolean {
		this._assertOpen();

		if (this.data.backEMFSensingState === PUNK.BOOL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (!!this.data.backEMFSensingState);
	}

	/**
	 * When `backEMFSensingState` is enabled, the controller will measure and report the `backEMF`.
	 * 
	 * *   The motor will coast (freewheel) 5% of the time while the back EMF is being measured (800μs every 16ms). Therefore, at a `velocity` of 100%, the motor will only be driven for 95% of the time.
	 * @throws {@link PhidgetError}
	 * @param backEMFSensingState - The back EMF state
	 */
	async setBackEMFSensingState(backEMFSensingState: boolean): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (backEMFSensingState !== false && backEMFSensingState !== true)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be a boolean.");

		bp.set({ name: "0", type: "d", value: (backEMFSensingState ? 1 : 0) });
		await bp.send(this._ch, BP.SETBACKEMFSENSINGSTATE);
	}

	/**
	 * The most recent braking strength value that the controller has reported. See `targetBrakingStrength` for details.
	 * @returns The braking strength value
	 * @throws {@link PhidgetError}
	 */
	getBrakingStrength(): number {
		this._assertOpen();

		if (this.data.brakingStrength === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.brakingStrength);
	}

	/**
	 * The minimum value that `targetBrakingStrength` can be set to. See `targetBrakingStrength` for details.
	 * @returns The braking strength value
	 * @throws {@link PhidgetError}
	 */
	getMinBrakingStrength(): number {
		this._assertOpen();

		if (this.data.minBrakingStrength === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minBrakingStrength);
	}

	/**
	 * The maximum value that `targetBrakingStrength` can be set to. See `targetBrakingStrength` for details.
	 * @returns The braking strength value
	 * @throws {@link PhidgetError}
	 */
	getMaxBrakingStrength(): number {
		this._assertOpen();

		if (this.data.maxBrakingStrength === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxBrakingStrength);
	}

	/**
	 * The controller will limit the current through the motor to the `currentLimit` value.
	 * @returns The current limit value
	 * @throws {@link PhidgetError}
	 */
	getCurrentLimit(): number {
		this._assertOpen();

		if (this.data.currentLimit === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.currentLimit);
	}

	/**
	 * The controller will limit the current through the motor to the `currentLimit` value.
	 * @throws {@link PhidgetError}
	 * @param currentLimit - The current limit value
	 */
	async setCurrentLimit(currentLimit: number): Promise<void> {
		this._assertOpen();

		if (currentLimit < this.data.minCurrentLimit || currentLimit > this.data.maxCurrentLimit)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minCurrentLimit + " - " + this.data.maxCurrentLimit + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: currentLimit });
		await bp.send(this._ch, BP.SETCURRENTLIMIT);
	}

	/**
	 * The minimum value that `currentLimit` can be set to.
	 * @returns The current value
	 * @throws {@link PhidgetError}
	 */
	getMinCurrentLimit(): number {
		this._assertOpen();

		if (this.data.minCurrentLimit === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minCurrentLimit);
	}

	/**
	 * The maximum value that `currentLimit` can be set to.
	 * @returns The current value
	 * @throws {@link PhidgetError}
	 */
	getMaxCurrentLimit(): number {
		this._assertOpen();

		if (this.data.maxCurrentLimit === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxCurrentLimit);
	}

	/**
	 * Depending on power supply voltage and motor coil inductance, current through the motor can change relatively slowly or extremely rapidly. A physically larger DC Motor will typically have a lower inductance, requiring a higher current regulator gain. A higher power supply voltage will result in motor current changing more rapidly, requiring a higher current regulator gain. If the current regulator gain is too small, spikes in current will occur, causing large variations in torque, and possibly damaging the motor controller. If the current regulator gain is too high, the current will jitter, causing the motor to sound 'rough', especially when changing directions.  
	 *   
	 * As a rule of thumb, we recommend setting this value as follows:  
	 * 
	 * CurrentRegulatorGain = CurrentLimit \* (Voltage / 12)
	 * @returns The current regulator gain value
	 * @throws {@link PhidgetError}
	 */
	getCurrentRegulatorGain(): number {
		this._assertOpen();

		if (this.data.currentRegulatorGain === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.currentRegulatorGain);
	}

	/**
	 * Depending on power supply voltage and motor coil inductance, current through the motor can change relatively slowly or extremely rapidly. A physically larger DC Motor will typically have a lower inductance, requiring a higher current regulator gain. A higher power supply voltage will result in motor current changing more rapidly, requiring a higher current regulator gain. If the current regulator gain is too small, spikes in current will occur, causing large variations in torque, and possibly damaging the motor controller. If the current regulator gain is too high, the current will jitter, causing the motor to sound 'rough', especially when changing directions.  
	 *   
	 * As a rule of thumb, we recommend setting this value as follows:  
	 * 
	 * CurrentRegulatorGain = CurrentLimit \* (Voltage / 12)
	 * @throws {@link PhidgetError}
	 * @param currentRegulatorGain - The current regulator gain value
	 */
	async setCurrentRegulatorGain(currentRegulatorGain: number): Promise<void> {
		this._assertOpen();

		if (currentRegulatorGain < this.data.minCurrentRegulatorGain || currentRegulatorGain > this.data.maxCurrentRegulatorGain)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minCurrentRegulatorGain + " - " + this.data.maxCurrentRegulatorGain + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: currentRegulatorGain });
		await bp.send(this._ch, BP.SETCURRENTREGULATORGAIN);
	}

	/**
	 * The minimum value that `currentRegulatorGain` can be set to.
	 * @returns The current regulator gain value
	 * @throws {@link PhidgetError}
	 */
	getMinCurrentRegulatorGain(): number {
		this._assertOpen();

		if (this.data.minCurrentRegulatorGain === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minCurrentRegulatorGain);
	}

	/**
	 * The maximum value that `currentRegulatorGain` can be set to.
	 * @returns The current regulator gain value
	 * @throws {@link PhidgetError}
	 */
	getMaxCurrentRegulatorGain(): number {
		this._assertOpen();

		if (this.data.maxCurrentRegulatorGain === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxCurrentRegulatorGain);
	}

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `VelocityUpdate` / `BrakingStrengthChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * 
	 * *   Note: `BrakingStrengthChange` events will only fire if a change in braking has occurred.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getDataInterval(): number {
		this._assertOpen();

		if (this.data.dataInterval === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return this.data.dataInterval;
	}

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another `VelocityUpdate` / `BrakingStrengthChange` event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * 
	 * *   Note: `BrakingStrengthChange` events will only fire if a change in braking has occurred.
	 * @throws {@link PhidgetError}
	 * @param dataInterval - The data interval value
	 */
	async setDataInterval(dataInterval: number): Promise<void> {
		this._assertOpen();

		if (dataInterval < this.data.minDataInterval || dataInterval > this.data.maxDataInterval)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDataInterval + " - " + this.data.maxDataInterval + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: dataInterval });
		await bp.send(this._ch, BP.SETDATAINTERVAL);
	}

	/**
	 * The minimum value that `dataInterval` can be set to.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getMinDataInterval(): number {
		this._assertOpen();

		if (this.data.minDataInterval === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDataInterval);
	}

	/**
	 * The maximum value that `dataInterval` can be set to.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getMaxDataInterval(): number {
		this._assertOpen();

		if (this.data.maxDataInterval === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDataInterval);
	}

	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @returns The data rate for the channel
	 * @throws {@link PhidgetError}
	 */
	getDataRate(): number {
		this._assertOpen();

		if (this.data.dataInterval === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (1000.0 / this.data.dataInterval);
	}

	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @throws {@link PhidgetError}
	 * @param dataRate - The data rate for the channel
	 */
	async setDataRate(dataRate: number): Promise<void> {
		this._assertOpen();

		if (dataRate < this.data.minDataRate || dataRate > this.data.maxDataRate)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDataRate + " - " + this.data.maxDataRate + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: Math.round(1000.0 / dataRate) });
		bp.set({ name: "1", type: "g", value: (1000.0 / dataRate) });
		await bp.send(this._ch, BP.SETDATAINTERVAL);
	}

	/**
	 * The minimum value that `dataRate` can be set to.
	 * @returns The data rate value
	 * @throws {@link PhidgetError}
	 */
	getMinDataRate(): number {
		this._assertOpen();

		if (this.data.minDataRate === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDataRate);
	}

	/**
	 * The maximum value that `dataRate` can be set to.
	 * @returns The data rate value
	 * @throws {@link PhidgetError}
	 */
	getMaxDataRate(): number {
		this._assertOpen();

		if (this.data.maxDataRate === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDataRate);
	}

	/**
	 * Enables the **failsafe** feature for the channel, with a given **failsafe time**.
	 * 
	 * The **failsafe** feature is intended for use in applications where it is important for the channel to enter a known _safe state_ if the program controlling it locks up or crashes. If you do not enable the failsafe feature, the channel will carry out whatever instructions it was last given until it is explicitly told to stop.
	 * 
	 * Enabling the failsafe feature starts a recurring **failsafe timer** for the channel. Once the failsafe timer is enabled, it must be reset within the specified time or the channel will enter a **failsafe state**. The failsafe timer may be reset by sending any valid command to the device\*. Resetting the failsafe timer will reload the timer with the specified _failsafe time_, starting when the message to reset the timer is received by the Phidget.
	 * 
	 * _\*(**get** requests do not typically send commands and won't reset the failsafe timer)_
	 * 
	 * For example: if the failsafe is enabled with a **failsafe time** of 1000ms, you will have 1000ms to reset the failsafe timer. Every time the failsafe timer is reset, you will have 1000ms from that time to reset the failsafe again.
	 * 
	 * If the failsafe timer is not reset before it runs out, the channel will enter a **failsafe state**. For DC Motor channels, this will disengage the motor. Once the channel enters the **failsafe state**, it will reject any further input until the channel is reopened.
	 * 
	 * To prevent the channel from falsely entering the failsafe state, we recommend resetting the failsafe timer as frequently as is practical for your application. A good rule of thumb is to not let more than a third of the failsafe time pass before resetting the timer.
	 * 
	 * Once the failsafe timer has been set, it cannot be disabled by any means other than closing and reopening the channel.
	 * @throws {@link PhidgetError}
	 * @param failsafeTime - Failsafe timeout in milliseconds
	 */
	async enableFailsafe(failsafeTime: number): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: failsafeTime });
		await bp.send(this._ch, BP.SETFAILSAFETIME);
	}

	/**
	 * The minimum value that `failsafeTime` can be set to when calling `enableFailsafe()`.
	 * @returns The failsafe time
	 * @throws {@link PhidgetError}
	 */
	getMinFailsafeTime(): number {
		this._assertOpen();

		if (this.data.minFailsafeTime === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minFailsafeTime);
	}

	/**
	 * The maximum value that `failsafeTime` can be set to when calling `enableFailsafe()`.
	 * @returns The failsafe time
	 * @throws {@link PhidgetError}
	 */
	getMaxFailsafeTime(): number {
		this._assertOpen();

		if (this.data.maxFailsafeTime === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxFailsafeTime);
	}

	/**
	 * The `fanMode` dictates the operating condition of the fan.
	 * 
	 * *   Choose between on, off, or automatic (based on temperature).
	 * *   If the `fanMode` is set to automatic, the fan will turn on when the temperature reaches 70°C and it will remain on until the temperature falls below 55°C.
	 * *   If the `fanMode` is off, the controller will still turn on the fan if the temperature reaches 85°C and it will remain on until it falls below 70°C.
	 * @returns The fan mode
	 * @throws {@link PhidgetError}
	 */
	getFanMode(): Enum.FanMode {
		this._assertOpen();

		if (this.data.fanMode === PUNK.ENUM)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.fanMode);
	}

	/**
	 * The `fanMode` dictates the operating condition of the fan.
	 * 
	 * *   Choose between on, off, or automatic (based on temperature).
	 * *   If the `fanMode` is set to automatic, the fan will turn on when the temperature reaches 70°C and it will remain on until the temperature falls below 55°C.
	 * *   If the `fanMode` is off, the controller will still turn on the fan if the temperature reaches 85°C and it will remain on until it falls below 70°C.
	 * @throws {@link PhidgetError}
	 * @param fanMode - The fan mode
	 */
	async setFanMode(fanMode: Enum.FanMode): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (!SEnum.supportedFanMode(this._ch!, fanMode))
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Specified FanMode is unsupported by this device.");

		bp.set({ name: "0", type: "d", value: fanMode });
		await bp.send(this._ch, BP.SETFANMODE);
	}

	/**
	 * Resets the failsafe timer, if one has been set. See `enableFailsafe()` for details.
	 * 
	 * This function will fail if no failsafe timer has been set for the channel.
	 * @throws {@link PhidgetError}
	 */
	async resetFailsafe(): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		await bp.send(this._ch, BP.FAILSAFERESET);
	}

	/**
	 * This setting allows you to choose how hard the motor will resist being turned when it is not being driven forward or reverse (`velocity` = 0). The `targetBrakingStrength` sets the relative amount of electrical braking to be applied to the DC motor, with `minBrakingStrength` corresponding to no braking (free-wheeling), and `maxBrakingStrength` indicating full braking.
	 * 
	 * *   A low `targetBrakingStrength` value corresponds to free-wheeling. This means:
	 *     *   The motor will continue to rotate after the controller is no longer driving the motor (`velocity` = 0), due to its momentum.
	 *     *   The motor shaft will provide little resistance to being turned when it is stopped.
	 * *   As `targetBrakingStrength` increases, this will engage electrical braking of the DC motor. This means:
	 *     *   The motor will stop more quickly if it is in motion when braking is requested.
	 *     *   The motor shaft will resist rotation by outside forces.
	 * *   Braking will be added gradually, according to the `acceleration` setting, once the motor controller's `velocity` reaches 0.0
	 * *   Braking will be immediately stopped when a new (non-zero) `targetVelocity` is set, and the motor will accelerate to the requested velocity.
	 * *   Braking mode is enabled by setting the `velocity` to 0.0
	 * @returns The braking strength value
	 * @throws {@link PhidgetError}
	 */
	getTargetBrakingStrength(): number {
		this._assertOpen();

		if (this.data.targetBrakingStrength === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.targetBrakingStrength);
	}

	/**
	 * This setting allows you to choose how hard the motor will resist being turned when it is not being driven forward or reverse (`velocity` = 0). The `targetBrakingStrength` sets the relative amount of electrical braking to be applied to the DC motor, with `minBrakingStrength` corresponding to no braking (free-wheeling), and `maxBrakingStrength` indicating full braking.
	 * 
	 * *   A low `targetBrakingStrength` value corresponds to free-wheeling. This means:
	 *     *   The motor will continue to rotate after the controller is no longer driving the motor (`velocity` = 0), due to its momentum.
	 *     *   The motor shaft will provide little resistance to being turned when it is stopped.
	 * *   As `targetBrakingStrength` increases, this will engage electrical braking of the DC motor. This means:
	 *     *   The motor will stop more quickly if it is in motion when braking is requested.
	 *     *   The motor shaft will resist rotation by outside forces.
	 * *   Braking will be added gradually, according to the `acceleration` setting, once the motor controller's `velocity` reaches 0.0
	 * *   Braking will be immediately stopped when a new (non-zero) `targetVelocity` is set, and the motor will accelerate to the requested velocity.
	 * *   Braking mode is enabled by setting the `velocity` to 0.0
	 * @throws {@link PhidgetError}
	 * @param targetBrakingStrength - The braking strength value
	 */
	async setTargetBrakingStrength(targetBrakingStrength: number): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: targetBrakingStrength });
		await bp.send(this._ch, BP.SETBRAKINGDUTYCYCLE);
	}

	/**
	 * The average voltage across the motor is based on the `targetVelocity` value.
	 * 
	 * *   At a constant load, increasing the target velocity will increase the speed of the motor.
	 * *   `targetVelocity` is bounded by -`maxVelocity` and +`maxVelocity`, where a sign change (±) is indicitave of a direction change.
	 * *   Setting `targetVelocity` to `minVelocity` will stop the motor. See `targetBrakingStrength` for more information on stopping the motor.
	 * *   The units of `targetVelocity` and `acceleration` refer to 'duty cycle'. This is because the controller controls velocity by rapidly switching the power on/off (i.e. changing the duty cycle) in order to manipulate the voltage across the motor.
	 * @returns The velocity value
	 * @throws {@link PhidgetError}
	 */
	getTargetVelocity(): number {
		this._assertOpen();

		if (this.data.targetVelocity === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.targetVelocity);
	}

	/**
	 * The average voltage across the motor is based on the `targetVelocity` value.
	 * 
	 * *   At a constant load, increasing the target velocity will increase the speed of the motor.
	 * *   `targetVelocity` is bounded by -`maxVelocity` and +`maxVelocity`, where a sign change (±) is indicitave of a direction change.
	 * *   Setting `targetVelocity` to `minVelocity` will stop the motor. See `targetBrakingStrength` for more information on stopping the motor.
	 * *   The units of `targetVelocity` and `acceleration` refer to 'duty cycle'. This is because the controller controls velocity by rapidly switching the power on/off (i.e. changing the duty cycle) in order to manipulate the voltage across the motor.
	 * @throws {@link PhidgetError}
	 * @param targetVelocity - The velocity value
	 */
	async setTargetVelocity(targetVelocity: number): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: targetVelocity });
		await bp.send(this._ch, BP.SETDUTYCYCLE);
	}

	/**
	 * The most recent velocity value that the controller has reported.
	 * @returns The velocity value
	 * @throws {@link PhidgetError}
	 */
	getVelocity(): number {
		this._assertOpen();

		if (this.data.velocity === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.velocity);
	}

	/**
	 * The minimum value that `targetVelocity` can be set to.
	 * 
	 * *   Set the `targetVelocity` to `minVelocity` to stop the motor. See `targetBrakingStrength` for more information on stopping the motor.
	 * *   `targetVelocity` is bounded by -`maxVelocity` and +`maxVelocity`, where a sign change (±) is indicitave of a direction change.
	 * @returns The velocity value
	 * @throws {@link PhidgetError}
	 */
	getMinVelocity(): number {
		this._assertOpen();

		if (this.data.minVelocity === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minVelocity);
	}

	/**
	 * The maximum value that `targetVelocity` can be set to.
	 * 
	 * *   `targetVelocity` is bounded by -`maxVelocity` and +`maxVelocity`, where a sign change (±) is indicitave of a direction change.
	 * @returns The velocity value
	 * @throws {@link PhidgetError}
	 */
	getMaxVelocity(): number {
		this._assertOpen();

		if (this.data.maxVelocity === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxVelocity);
	}

}
export { DCMotorBase };
