import { DistanceSensorBase } from './DistanceSensor.gen';
import { ErrorEventCode } from '../Enumerations.gen';
import { BP } from '../BridgePackets.gen';
import { BridgePacket, PUNK } from '../BridgePacket';

/** @public */
class DistanceSensor extends DistanceSensorBase {

	/** @internal */
	_bridgeInput(bp: BridgePacket) {
		switch (bp.vpkt) {
			case BP.SONARUPDATE:
				this.data.distances = bp.getArray(0) as [number, number, number, number, number, number, number, number];
				this.data.amplitudes = bp.getArray(1) as [number, number, number, number, number, number, number, number];
				this.data.count = bp.getNumber(2);
				super._bridgeInput(bp);
				break;
			default:
				super._bridgeInput(bp);
				break;
		}
	}

	/** @internal */
	_errorHandler(code: ErrorEventCode) {
		switch (code) {
			case ErrorEventCode.SATURATION:
			case ErrorEventCode.OUT_OF_RANGE:
				this.data.distance = PUNK.UINT32;
				this._gotDistanceChangeErrorEvent = true;
				break;
		}
	}

	getSonarReflections() {
		this._assertOpen();
		return ({
			distances: this.data.distances,
			amplitudes: this.data.amplitudes,
			count: this.data.count
		});
	}
}

export { DistanceSensor };