/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PhidgetChannel } from '../Phidget';
import { Channel } from '../Channel';
import { ErrorCode, ChannelClass } from '../Enumerations.gen';
import * as Enum from '../Enumerations.gen';
import * as SEnum from '../SupportedEnum.gen';
import * as Struct from '../Structs.gen';
import { PhidgetError } from '../PhidgetError';
import { BridgePacket, PUNK } from '../BridgePacket';
import { BP } from '../BridgePackets.gen';
import { logEventException } from '../Logging';
import { DeviceChannelUID } from '../Devices.gen';

/** @internal */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface VoltageRatioInputData {
	bridgeEnabled: number,
	bridgeGain: Enum.BridgeGain | PUNK.ENUM,
	dataInterval: number,
	minDataInterval: number,
	maxDataInterval: number,
	minDataRate: number,
	maxDataRate: number,
	sensorType: Enum.VoltageRatioSensorType | PUNK.ENUM,
	sensorUnit: Struct.UnitInfo | null,
	sensorValue: number,
	voltageRatio: number,
	minVoltageRatio: number,
	maxVoltageRatio: number,
	voltageRatioChangeTrigger: number,
	minVoltageRatioChangeTrigger: number,
	maxVoltageRatioChangeTrigger: number,
	sensorValueChangeTrigger: number,
}

abstract class VoltageRatioInputBase extends PhidgetChannel {
	/** @internal */
	data: VoltageRatioInputData;
	/**
	 * **VoltageRatioChange** event
	 *  * `voltageRatio` - The voltage ratio
	 * ---
	 * The most recent voltage ratio value the channel has measured will be reported in this event, which occurs when the `dataInterval` has elapsed.
	 * 
	 * *   If a `voltageRatioChangeTrigger` has been set to a non-zero value, the `VoltageRatioChange` event will not occur until the voltage has changed by at least the `voltageRatioChangeTrigger` value.
	 * *   If `sensorType` is supported and set to anything other than `phidget22.VoltageRatioSensorType.VOLTAGE_RATIO`, this event will not fire.
	 */
	onVoltageRatioChange: ((voltageRatio: number) => void) | null = null;
	/** @internal */
	_gotVoltageRatioChangeErrorEvent?: boolean;
	/**
	 * **SensorChange** event
	 *  * `sensorValue` - The sensor value
	 *  * `sensorUnit` - The sensor unit information corresponding to the `sensorValue`.

*   Helps keep track of the type of information being calculated from the voltage ratio input.
	 * ---
	 * The most recent sensor value the channel has measured will be reported in this event, which occurs when the `dataInterval` has elapsed.
	 * 
	 * *   If a `sensorValueChangeTrigger` has been set to a non-zero value, the `SensorChange` event will not occur until the sensor value has changed by at least the `sensorValueChangeTrigger` value.
	 * *   This event only fires when `sensorType` is not set to `phidget22.VoltageRatioSensorType.VOLTAGE_RATIO`
	 */
	onSensorChange: ((sensorValue: number, sensorUnit: Struct.UnitInfo) => void) | null = null;
	/** @internal */
	_gotSensorChangeErrorEvent?: boolean;

	/**
	 * The Voltage Ratio Input class is used for measuring the ratio between the voltage supplied to and the voltage returned from an attached sensor or device. This is useful for interfacing with ratiometric sensors or wheatstone bridge based sensors.
	 * 
	 * For ratiometric sensors, this class supports conversion to sensor data with units specific to the Phidget sensor being used, to make reading these sensors easy.
	 * @public
	 */
	constructor();
	/** @internal */
	constructor(ch?: Channel);
	constructor(ch?: Channel) {
		super(ch);
		this._class = ChannelClass.VOLTAGE_RATIO_INPUT;
		this.name = "VoltageRatioInput";
		this.data = this._initData();
	}

	/** @internal */
	_bridgeInput(bp: BridgePacket) {

		switch(bp.vpkt) {
		case BP.SETENABLED:
			this.data.bridgeEnabled = bp.entries[0].v as number;
			this._FIREPropertyChange('BridgeEnabled', bp);
			break;
		case BP.SETBRIDGEGAIN:
			this.data.bridgeGain = bp.entries[0].v as Enum.BridgeGain;
			this._FIREPropertyChange('BridgeGain', bp);
			break;
		case BP.SETDATAINTERVAL:
			if (bp.entryCount > 1)
				this.data.dataInterval = bp.entries[1].v as number;
			else
				this.data.dataInterval = bp.entries[0].v as number;
			this._FIREPropertyChange('DataInterval', bp);
			this._FIREPropertyChange('DataRate', bp);
			break;
		case BP.SETSENSORTYPE:
			this.data.sensorType = bp.entries[0].v as Enum.VoltageRatioSensorType;
			this._FIREPropertyChange('SensorType', bp);
			break;
		case BP.SETSENSORVALUECHANGETRIGGER:
			this.data.sensorValueChangeTrigger = bp.entries[0].v as number;
			this._FIREPropertyChange('SensorValueChangeTrigger', bp);
			break;
		case BP.SETCHANGETRIGGER:
			this.data.voltageRatioChangeTrigger = bp.entries[0].v as number;
			this._FIREPropertyChange('VoltageRatioChangeTrigger', bp);
			break;
		case BP.SENSORCHANGE: {
			this.data.sensorValue = bp.entries[0].v as number;
			const sensorUnit: Struct.UnitInfo = {
				unit: bp.entries["UnitInfo.unit"].v as Enum.Unit,
				name: bp.entries["UnitInfo.name"].v as string,
				symbol: bp.entries["UnitInfo.symbol"].v as string,
			}
			this.data.sensorUnit = sensorUnit;
			if (this._isAttachedDone && this.onSensorChange) {
				try {
					this.onSensorChange(this.data.sensorValue, this.data.sensorUnit);
				} catch (err) { logEventException(err); }
			}
			break;
		}
		default:
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			throw new PhidgetError(ErrorCode.INVALID_PACKET, "Unsupported bridge packet: 0x" + bp.vpkt!.toString(16));
		}
	}

	/** @internal */
	_initData(): VoltageRatioInputData {
		return {
			bridgeEnabled: PUNK.BOOL,
			bridgeGain: PUNK.ENUM,
			dataInterval: PUNK.DBL,
			minDataInterval: PUNK.UINT32,
			maxDataInterval: PUNK.UINT32,
			minDataRate: PUNK.DBL,
			maxDataRate: PUNK.DBL,
			sensorType: PUNK.ENUM,
			sensorUnit: null,
			sensorValue: PUNK.DBL,
			voltageRatio: PUNK.DBL,
			minVoltageRatio: PUNK.DBL,
			maxVoltageRatio: PUNK.DBL,
			voltageRatioChangeTrigger: PUNK.DBL,
			minVoltageRatioChangeTrigger: PUNK.DBL,
			maxVoltageRatioChangeTrigger: PUNK.DBL,
			sensorValueChangeTrigger: PUNK.DBL,
		}
	}

	/** @internal */
	_initAfterOpen() {
		this.data = this._initData();

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._1018_VOLTAGERATIOINPUT_1000:
			this.data.dataInterval = 256;
			this.data.minDataInterval = 1;
			this.data.maxDataInterval = 1000;
			this.data.minDataRate = 1;
			this.data.maxDataRate = 1000;
			this.data.sensorType = Enum.VoltageRatioSensorType.VOLTAGE_RATIO;
			this.data.voltageRatio = (<InterfaceKitDevice>this._ch!.parent).data.voltageRatio[this._ch!.index];
			this.data.minVoltageRatio = 0;
			this.data.maxVoltageRatio = 1;
			this.data.voltageRatioChangeTrigger = 0;
			this.data.minVoltageRatioChangeTrigger = 0;
			this.data.maxVoltageRatioChangeTrigger = 1;
			this.data.sensorValueChangeTrigger = 0;
			break;
		case DeviceChannelUID._1046_VOLTAGERATIOINPUT_200:
			this.data.bridgeEnabled = 1;
			this.data.bridgeGain = Enum.BridgeGain.GAIN_128X;
			this.data.dataInterval = 250;
			this.data.minDataInterval = 1;
			this.data.maxDataInterval = 1000;
			this.data.minDataRate = 1;
			this.data.maxDataRate = 1200;
			this.data.voltageRatio = (<BridgeDevice>this._ch!.parent).data.voltageRatio[this._ch!.index];
			this.data.minVoltageRatio = -0.85;
			this.data.maxVoltageRatio = 0.85;
			this.data.voltageRatioChangeTrigger = 0;
			this.data.minVoltageRatioChangeTrigger = 0;
			this.data.maxVoltageRatioChangeTrigger = 0.5;
			break;
		case DeviceChannelUID._HUB_VOLTAGERATIOINPUT_100:
		case DeviceChannelUID._HUB_VOLTAGERATIOINPUT_200:
			this.data.dataInterval = 250;
			this.data.minDataInterval = 1;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 1000;
			this.data.sensorType = Enum.VoltageRatioSensorType.VOLTAGE_RATIO;
			this.data.minVoltageRatio = 0;
			this.data.maxVoltageRatio = 1;
			this.data.voltageRatioChangeTrigger = 0;
			this.data.minVoltageRatioChangeTrigger = 0;
			this.data.maxVoltageRatioChangeTrigger = 1;
			this.data.sensorValueChangeTrigger = 0;
			break;
		case DeviceChannelUID._DAQ1000_VOLTAGERATIOINPUT_100:
		case DeviceChannelUID._DAQ1000_VOLTAGERATIOINPUT_110:
			this.data.dataInterval = 250;
			this.data.minDataInterval = 20;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 50;
			this.data.sensorType = Enum.VoltageRatioSensorType.VOLTAGE_RATIO;
			this.data.minVoltageRatio = 0;
			this.data.maxVoltageRatio = 1;
			this.data.voltageRatioChangeTrigger = 0;
			this.data.minVoltageRatioChangeTrigger = 0;
			this.data.maxVoltageRatioChangeTrigger = 1;
			this.data.sensorValueChangeTrigger = 0;
			break;
		case DeviceChannelUID._DAQ1500_VOLTAGERATIOINPUT_100:
			this.data.bridgeEnabled = 1;
			this.data.bridgeGain = Enum.BridgeGain.GAIN_128X;
			this.data.dataInterval = 250;
			this.data.minDataInterval = 20;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 50;
			this.data.minVoltageRatio = -0.5;
			this.data.maxVoltageRatio = 0.5;
			this.data.voltageRatioChangeTrigger = 0;
			this.data.minVoltageRatioChangeTrigger = 0;
			this.data.maxVoltageRatioChangeTrigger = 0.5;
			break;
		case DeviceChannelUID._DCC1000_VOLTAGERATIOINPUT_100:
		case DeviceChannelUID._DCC1000_VOLTAGERATIOINPUT_200:
		case DeviceChannelUID._DCC1000_VOLTAGERATIOINPUT_210:
			this.data.dataInterval = 250;
			this.data.minDataInterval = 100;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 10;
			this.data.sensorType = Enum.VoltageRatioSensorType.VOLTAGE_RATIO;
			this.data.minVoltageRatio = 0;
			this.data.maxVoltageRatio = 1;
			this.data.voltageRatioChangeTrigger = 0;
			this.data.minVoltageRatioChangeTrigger = 0;
			this.data.maxVoltageRatioChangeTrigger = 1;
			this.data.sensorValueChangeTrigger = 0;
			break;
		case DeviceChannelUID._HIN1100_VOLTAGERATIOINPUT_100:
			this.data.dataInterval = 100;
			this.data.minDataInterval = 20;
			this.data.maxDataInterval = 1000;
			this.data.minDataRate = 1;
			this.data.maxDataRate = 50;
			this.data.minVoltageRatio = -1;
			this.data.maxVoltageRatio = 1;
			this.data.voltageRatioChangeTrigger = 0;
			this.data.minVoltageRatioChangeTrigger = 0;
			this.data.maxVoltageRatioChangeTrigger = 2;
			break;
		case DeviceChannelUID._HUM1100_VOLTAGERATIOINPUT_100:
			this.data.dataInterval = 100;
			this.data.minDataInterval = 100;
			this.data.maxDataInterval = 60000;
			this.data.minDataRate = 0.016666666666666666;
			this.data.maxDataRate = 10;
			this.data.minVoltageRatio = 0;
			this.data.maxVoltageRatio = 1;
			this.data.voltageRatioChangeTrigger = 0;
			this.data.minVoltageRatioChangeTrigger = 0;
			this.data.maxVoltageRatioChangeTrigger = 1;
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	// eslint-disable-next-line require-await
	async _setDefaults() {
		let bp;

		switch (this._ch!.chDef.uid) {
		case DeviceChannelUID._1018_VOLTAGERATIOINPUT_1000:
		case DeviceChannelUID._HUB_VOLTAGERATIOINPUT_100:
		case DeviceChannelUID._HUB_VOLTAGERATIOINPUT_200:
		case DeviceChannelUID._DAQ1000_VOLTAGERATIOINPUT_100:
		case DeviceChannelUID._DAQ1000_VOLTAGERATIOINPUT_110:
			bp = new BridgePacket();
			bp.set({ name: "0", type: "u", value: Math.round(this.data.dataInterval) });
			await bp.send(this._ch, BP.SETDATAINTERVAL);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "d", value: this.data.sensorType });
			await bp.send(this._ch, BP.SETSENSORTYPE);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.voltageRatioChangeTrigger });
			await bp.send(this._ch, BP.SETCHANGETRIGGER);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.sensorValueChangeTrigger });
			await bp.send(this._ch, BP.SETSENSORVALUECHANGETRIGGER);
			break;
		case DeviceChannelUID._1046_VOLTAGERATIOINPUT_200:
		case DeviceChannelUID._DAQ1500_VOLTAGERATIOINPUT_100:
			bp = new BridgePacket();
			bp.set({ name: "0", type: "d", value: this.data.bridgeEnabled });
			await bp.send(this._ch, BP.SETENABLED);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "d", value: this.data.bridgeGain });
			await bp.send(this._ch, BP.SETBRIDGEGAIN);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "u", value: Math.round(this.data.dataInterval) });
			await bp.send(this._ch, BP.SETDATAINTERVAL);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.voltageRatioChangeTrigger });
			await bp.send(this._ch, BP.SETCHANGETRIGGER);
			break;
		case DeviceChannelUID._DCC1000_VOLTAGERATIOINPUT_100:
		case DeviceChannelUID._DCC1000_VOLTAGERATIOINPUT_200:
		case DeviceChannelUID._DCC1000_VOLTAGERATIOINPUT_210:
			bp = new BridgePacket();
			bp.set({ name: "0", type: "u", value: Math.round(this.data.dataInterval) });
			await bp.send(this._ch, BP.SETDATAINTERVAL);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "d", value: this.data.sensorType });
			await bp.send(this._ch, BP.SETSENSORTYPE);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.sensorValueChangeTrigger });
			await bp.send(this._ch, BP.SETSENSORVALUECHANGETRIGGER);
			break;
		case DeviceChannelUID._HIN1100_VOLTAGERATIOINPUT_100:
		case DeviceChannelUID._HUM1100_VOLTAGERATIOINPUT_100:
			bp = new BridgePacket();
			bp.set({ name: "0", type: "u", value: Math.round(this.data.dataInterval) });
			await bp.send(this._ch, BP.SETDATAINTERVAL);
			bp = new BridgePacket();
			bp.set({ name: "0", type: "g", value: this.data.voltageRatioChangeTrigger });
			await bp.send(this._ch, BP.SETCHANGETRIGGER);
			break;
		default:
			throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}
	}

	/** @internal */
	_hasInitialState() {


		return true;
	}

	/** @internal */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	_fireInitialEvents() {

	}

	/**
	 * Enable power to the input and start collecting data by setting `bridgeEnabled` to true.
	 * @throws {@link PhidgetError}
	 */
	get bridgeEnabled() { return this.getBridgeEnabled(); }
	/**
	 * Choose a `bridgeGain` that best suits your application.
	 * 
	 * *   For more information about the range and accuracy of each `bridgeGain` to decide which best suits your application, see your device's User Guide.
	 * @throws {@link PhidgetError}
	 */
	get bridgeGain() { return this.getBridgeGain(); }
	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * *   The timing between events can also be affected by the change trigger.
	 * @throws {@link PhidgetError}
	 */
	get dataInterval() { return this.getDataInterval(); }
	/**
	 * The minimum value that `dataInterval` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDataInterval() { return this.getMinDataInterval(); }
	/**
	 * The maximum value that `dataInterval` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDataInterval() { return this.getMaxDataInterval(); }
	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @throws {@link PhidgetError}
	 */
	get dataRate() { return this.getDataRate(); }
	/**
	 * The minimum value that `dataRate` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minDataRate() { return this.getMinDataRate(); }
	/**
	 * The maximum value that `dataRate` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxDataRate() { return this.getMaxDataRate(); }
	/**
	 * We sell a variety of analog sensors that do not have their own API, they simply output a voltage that can be converted to a digital value using a specific formula. By matching the `sensorType` to your analog sensor, the correct formula will automatically be applied to data when you get the `sensorValue` or subscribe to the `SensorChange` event.
	 * 
	 * *   The `SensorChange` event has its own change trigger associated with it: `sensorValueChangeTrigger`.
	 * *   Any data from getting the `sensorValue` or subscribing to the `SensorChange` event will have a `sensorUnit` associated with it.
	 * 
	 * **Note:** Unlike other properties such as `Phidget.deviceSerialNumber` or `Phidget.channel`, `sensorType` is set after the device is opened, not before.
	 * @throws {@link PhidgetError}
	 */
	get sensorType() { return this.getSensorType(); }
	/**
	 * The unit of measurement that applies to the sensor values of the `sensorType` that has been selected.
	 * 
	 * *   Helps keep track of the type of information being calculated from the voltage ratio input.
	 * @throws {@link PhidgetError}
	 */
	get sensorUnit() { return this.getSensorUnit(); }
	/**
	 * The most recent sensor value that the channel has reported.
	 * 
	 * *   Use `sensorUnit` to get the measurement units that are associated with the `sensorValue`
	 * @throws {@link PhidgetError}
	 */
	get sensorValue() { return this.getSensorValue(); }
	/**
	 * The channel will not issue a `SensorChange` event until the sensor value has changed by the amount specified by the `sensorValueChangeTrigger`.
	 * 
	 * *   Setting the `sensorValueChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering
	 * @throws {@link PhidgetError}
	 */
	get sensorValueChangeTrigger() { return this.getSensorValueChangeTrigger(); }
	/**
	 * The most recent voltage ratio value that the channel has reported.
	 * 
	 * *   This value will always be between `minVoltageRatio` and `maxVoltageRatio`.
	 * @throws {@link PhidgetError}
	 */
	get voltageRatio() { return this.getVoltageRatio(); }
	/**
	 * The minimum value the `VoltageRatioChange` event will report.
	 * @throws {@link PhidgetError}
	 */
	get minVoltageRatio() { return this.getMinVoltageRatio(); }
	/**
	 * The maximum value the `VoltageRatioChange` event will report.
	 * @throws {@link PhidgetError}
	 */
	get maxVoltageRatio() { return this.getMaxVoltageRatio(); }
	/**
	 * The channel will not issue a `VoltageRatioChange` event until the voltage ratio value has changed by the amount specified by the `voltageRatioChangeTrigger`.
	 * 
	 * *   Setting the `voltageRatioChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering
	 * @throws {@link PhidgetError}
	 */
	get voltageRatioChangeTrigger() { return this.getVoltageRatioChangeTrigger(); }
	/**
	 * The minimum value that `voltageRatioChangeTrigger` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get minVoltageRatioChangeTrigger() { return this.getMinVoltageRatioChangeTrigger(); }
	/**
	 * The maximum value that `voltageRatioChangeTrigger` can be set to.
	 * @throws {@link PhidgetError}
	 */
	get maxVoltageRatioChangeTrigger() { return this.getMaxVoltageRatioChangeTrigger(); }

	/**
	 * Enable power to the input and start collecting data by setting `bridgeEnabled` to true.
	 * @returns The enabled value
	 * @throws {@link PhidgetError}
	 */
	getBridgeEnabled(): boolean {
		this._assertOpen();

		if (this.data.bridgeEnabled === PUNK.BOOL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (!!this.data.bridgeEnabled);
	}

	/**
	 * Enable power to the input and start collecting data by setting `bridgeEnabled` to true.
	 * @throws {@link PhidgetError}
	 * @param bridgeEnabled - The enabled value
	 */
	async setBridgeEnabled(bridgeEnabled: boolean): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (bridgeEnabled !== false && bridgeEnabled !== true)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be a boolean.");

		bp.set({ name: "0", type: "d", value: (bridgeEnabled ? 1 : 0) });
		await bp.send(this._ch, BP.SETENABLED);
	}

	/**
	 * Choose a `bridgeGain` that best suits your application.
	 * 
	 * *   For more information about the range and accuracy of each `bridgeGain` to decide which best suits your application, see your device's User Guide.
	 * @returns The bridge gain value
	 * @throws {@link PhidgetError}
	 */
	getBridgeGain(): Enum.BridgeGain {
		this._assertOpen();

		if (this.data.bridgeGain === PUNK.ENUM)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.bridgeGain);
	}

	/**
	 * Choose a `bridgeGain` that best suits your application.
	 * 
	 * *   For more information about the range and accuracy of each `bridgeGain` to decide which best suits your application, see your device's User Guide.
	 * @throws {@link PhidgetError}
	 * @param bridgeGain - The bridge gain value
	 */
	async setBridgeGain(bridgeGain: Enum.BridgeGain): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (!SEnum.supportedBridgeGain(this._ch!, bridgeGain))
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Specified BridgeGain is unsupported by this device.");

		bp.set({ name: "0", type: "d", value: bridgeGain });
		await bp.send(this._ch, BP.SETBRIDGEGAIN);
	}

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * *   The timing between events can also be affected by the change trigger.
	 * @returns The data interval for the channel
	 * @throws {@link PhidgetError}
	 */
	getDataInterval(): number {
		this._assertOpen();

		if (this.data.dataInterval === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return this.data.dataInterval;
	}

	/**
	 * The `dataInterval` is the time that must elapse before the channel will fire another event.
	 * 
	 * *   The data interval is bounded by `minDataInterval` and `maxDataInterval`.
	 * *   The timing between events can also be affected by the change trigger.
	 * @throws {@link PhidgetError}
	 * @param dataInterval - The data interval for the channel
	 */
	async setDataInterval(dataInterval: number): Promise<void> {
		this._assertOpen();

		if (dataInterval < this.data.minDataInterval || dataInterval > this.data.maxDataInterval)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDataInterval + " - " + this.data.maxDataInterval + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: dataInterval });
		await bp.send(this._ch, BP.SETDATAINTERVAL);
	}

	/**
	 * The minimum value that `dataInterval` can be set to.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getMinDataInterval(): number {
		this._assertOpen();

		if (this.data.minDataInterval === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDataInterval);
	}

	/**
	 * The maximum value that `dataInterval` can be set to.
	 * @returns The data interval value
	 * @throws {@link PhidgetError}
	 */
	getMaxDataInterval(): number {
		this._assertOpen();

		if (this.data.maxDataInterval === PUNK.UINT32)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDataInterval);
	}

	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @returns The data rate for the channel
	 * @throws {@link PhidgetError}
	 */
	getDataRate(): number {
		this._assertOpen();

		if (this.data.dataInterval === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (1000.0 / this.data.dataInterval);
	}

	/**
	 * The `dataRate` is the frequency of events from the device.
	 * 
	 * *   The data rate is bounded by `minDataRate` and `maxDataRate`.
	 * *   Changing `dataRate` will change the channel's `dataInterval` to a corresponding value, rounded to the nearest integer number of milliseconds.
	 * *   The timing between events can also affected by the change trigger.
	 * @throws {@link PhidgetError}
	 * @param dataRate - The data rate for the channel
	 */
	async setDataRate(dataRate: number): Promise<void> {
		this._assertOpen();

		if (dataRate < this.data.minDataRate || dataRate > this.data.maxDataRate)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minDataRate + " - " + this.data.maxDataRate + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "u", value: Math.round(1000.0 / dataRate) });
		bp.set({ name: "1", type: "g", value: (1000.0 / dataRate) });
		await bp.send(this._ch, BP.SETDATAINTERVAL);
	}

	/**
	 * The minimum value that `dataRate` can be set to.
	 * @returns The data rate value
	 * @throws {@link PhidgetError}
	 */
	getMinDataRate(): number {
		this._assertOpen();

		if (this.data.minDataRate === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minDataRate);
	}

	/**
	 * The maximum value that `dataRate` can be set to.
	 * @returns The data rate value
	 * @throws {@link PhidgetError}
	 */
	getMaxDataRate(): number {
		this._assertOpen();

		if (this.data.maxDataRate === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxDataRate);
	}

	/**
	 * We sell a variety of analog sensors that do not have their own API, they simply output a voltage that can be converted to a digital value using a specific formula. By matching the `sensorType` to your analog sensor, the correct formula will automatically be applied to data when you get the `sensorValue` or subscribe to the `SensorChange` event.
	 * 
	 * *   The `SensorChange` event has its own change trigger associated with it: `sensorValueChangeTrigger`.
	 * *   Any data from getting the `sensorValue` or subscribing to the `SensorChange` event will have a `sensorUnit` associated with it.
	 * 
	 * **Note:** Unlike other properties such as `Phidget.deviceSerialNumber` or `Phidget.channel`, `sensorType` is set after the device is opened, not before.
	 * @returns The sensor type value
	 * @throws {@link PhidgetError}
	 */
	getSensorType(): Enum.VoltageRatioSensorType {
		this._assertOpen();

		if (this.data.sensorType === PUNK.ENUM)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.sensorType);
	}

	/**
	 * We sell a variety of analog sensors that do not have their own API, they simply output a voltage that can be converted to a digital value using a specific formula. By matching the `sensorType` to your analog sensor, the correct formula will automatically be applied to data when you get the `sensorValue` or subscribe to the `SensorChange` event.
	 * 
	 * *   The `SensorChange` event has its own change trigger associated with it: `sensorValueChangeTrigger`.
	 * *   Any data from getting the `sensorValue` or subscribing to the `SensorChange` event will have a `sensorUnit` associated with it.
	 * 
	 * **Note:** Unlike other properties such as `Phidget.deviceSerialNumber` or `Phidget.channel`, `sensorType` is set after the device is opened, not before.
	 * @throws {@link PhidgetError}
	 * @param sensorType - The sensor type value
	 */
	async setSensorType(sensorType: Enum.VoltageRatioSensorType): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();

		if (!SEnum.supportedVoltageRatioSensorType(this._ch!, sensorType))
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Specified VoltageRatioSensorType is unsupported by this device.");

		bp.set({ name: "0", type: "d", value: sensorType });
		await bp.send(this._ch, BP.SETSENSORTYPE);
	}

	/**
	 * The unit of measurement that applies to the sensor values of the `sensorType` that has been selected.
	 * 
	 * *   Helps keep track of the type of information being calculated from the voltage ratio input.
	 * @returns The sensor unit information corresponding to the `sensorValue`.
	 * @throws {@link PhidgetError}
	 */
	getSensorUnit(): Struct.UnitInfo {
		this._assertOpen();

		if (this.data.sensorUnit === null)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.sensorUnit);
	}

	/**
	 * The most recent sensor value that the channel has reported.
	 * 
	 * *   Use `sensorUnit` to get the measurement units that are associated with the `sensorValue`
	 * @returns The sensor value
	 * @throws {@link PhidgetError}
	 */
	getSensorValue(): number {
		this._assertOpen();

		if (this.data.sensorValue === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.sensorValue);
	}

	/**
	 * The channel will not issue a `SensorChange` event until the sensor value has changed by the amount specified by the `sensorValueChangeTrigger`.
	 * 
	 * *   Setting the `sensorValueChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering
	 * @returns The change trigger value
	 * @throws {@link PhidgetError}
	 */
	getSensorValueChangeTrigger(): number {
		this._assertOpen();

		if (this.data.sensorValueChangeTrigger === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.sensorValueChangeTrigger);
	}

	/**
	 * The channel will not issue a `SensorChange` event until the sensor value has changed by the amount specified by the `sensorValueChangeTrigger`.
	 * 
	 * *   Setting the `sensorValueChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering
	 * @throws {@link PhidgetError}
	 * @param sensorValueChangeTrigger - The change trigger value
	 */
	async setSensorValueChangeTrigger(sensorValueChangeTrigger: number): Promise<void> {
		this._assertOpen();

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: sensorValueChangeTrigger });
		await bp.send(this._ch, BP.SETSENSORVALUECHANGETRIGGER);
	}

	/**
	 * The most recent voltage ratio value that the channel has reported.
	 * 
	 * *   This value will always be between `minVoltageRatio` and `maxVoltageRatio`.
	 * @returns The voltage ratio value
	 * @throws {@link PhidgetError}
	 */
	getVoltageRatio(): number {
		this._assertOpen();

		if (this.data.voltageRatio === PUNK.DBL || Number.isNaN(this.data.voltageRatio))
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);
		if (this.data.voltageRatio > this.data.maxVoltageRatio)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE_HIGH);
		if (this.data.voltageRatio < this.data.minVoltageRatio)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE_LOW);

		return (this.data.voltageRatio);
	}

	/**
	 * The minimum value the `VoltageRatioChange` event will report.
	 * @returns The voltage ratio value
	 * @throws {@link PhidgetError}
	 */
	getMinVoltageRatio(): number {
		this._assertOpen();

		if (this.data.minVoltageRatio === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minVoltageRatio);
	}

	/**
	 * The maximum value the `VoltageRatioChange` event will report.
	 * @returns The voltage ratio value
	 * @throws {@link PhidgetError}
	 */
	getMaxVoltageRatio(): number {
		this._assertOpen();

		if (this.data.maxVoltageRatio === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxVoltageRatio);
	}

	/**
	 * The channel will not issue a `VoltageRatioChange` event until the voltage ratio value has changed by the amount specified by the `voltageRatioChangeTrigger`.
	 * 
	 * *   Setting the `voltageRatioChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering
	 * @returns The change trigger value
	 * @throws {@link PhidgetError}
	 */
	getVoltageRatioChangeTrigger(): number {
		this._assertOpen();

		if (this.data.voltageRatioChangeTrigger === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.voltageRatioChangeTrigger);
	}

	/**
	 * The channel will not issue a `VoltageRatioChange` event until the voltage ratio value has changed by the amount specified by the `voltageRatioChangeTrigger`.
	 * 
	 * *   Setting the `voltageRatioChangeTrigger` to 0 will result in the channel firing events every `dataInterval`. This is useful for applications that implement their own data filtering
	 * @throws {@link PhidgetError}
	 * @param voltageRatioChangeTrigger - The change trigger value
	 */
	async setVoltageRatioChangeTrigger(voltageRatioChangeTrigger: number): Promise<void> {
		this._assertOpen();

		if (voltageRatioChangeTrigger < this.data.minVoltageRatioChangeTrigger || voltageRatioChangeTrigger > this.data.maxVoltageRatioChangeTrigger)
			throw new PhidgetError(ErrorCode.INVALID_ARGUMENT, "Value must be in range: " + this.data.minVoltageRatioChangeTrigger + " - " + this.data.maxVoltageRatioChangeTrigger + ".");

		const bp = new BridgePacket();
		bp.set({ name: "0", type: "g", value: voltageRatioChangeTrigger });
		await bp.send(this._ch, BP.SETCHANGETRIGGER);
	}

	/**
	 * The minimum value that `voltageRatioChangeTrigger` can be set to.
	 * @returns The change trigger value
	 * @throws {@link PhidgetError}
	 */
	getMinVoltageRatioChangeTrigger(): number {
		this._assertOpen();

		if (this.data.minVoltageRatioChangeTrigger === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.minVoltageRatioChangeTrigger);
	}

	/**
	 * The maximum value that `voltageRatioChangeTrigger` can be set to.
	 * @returns The change trigger value
	 * @throws {@link PhidgetError}
	 */
	getMaxVoltageRatioChangeTrigger(): number {
		this._assertOpen();

		if (this.data.maxVoltageRatioChangeTrigger === PUNK.DBL)
			throw new PhidgetError(ErrorCode.UNKNOWN_VALUE);

		return (this.data.maxVoltageRatioChangeTrigger);
	}

}
import { type InterfaceKitDevice } from '../usb/device/InterfaceKitDevice';
import { type BridgeDevice } from '../usb/device/BridgeDevice';
export { VoltageRatioInputBase };
