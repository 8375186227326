/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { BridgePacket } from "../../BridgePacket";
import { DeviceUID } from "../../Devices.gen";
import { ErrorCode } from "../../Enumerations.gen";
import { PhidgetError } from "../../PhidgetError";
import { LocalChannel } from "../LocalChannel";
import { PhidgetUSBDevice, PhidgetUSBDeviceData } from "../PhidgetUSBDevice";
import { PhidgetUSBEndpointType } from "../USB";
import { USBConnectionBase } from "../USBConnection";

export class GenericDevice extends PhidgetUSBDevice {

	data: {
		INPacketLength: number[],
		OUTPacketLength: number[],
		CTRPacketLength: number[],
	};

	constructor(conn: USBConnectionBase, data: PhidgetUSBDeviceData, usbDev: USBDevice) {
		super(conn, data, usbDev);

		// Ensure that we actually support this device
		switch (this.devDef.uid) {
			case DeviceUID._GENERIC_PHIDUSB:
			case DeviceUID._UNKNOWNUSB:
				break;
			default:
				throw new PhidgetError(ErrorCode.UNSUPPORTED);
		}

		this.data = {
			INPacketLength: [this.pusbParams.maxPacketEP1],
			OUTPacketLength: [
				(this.pusbParams.ep2type === PhidgetUSBEndpointType.PHID_EP_UNAVAILABLE
					? this.pusbParams.maxPacketEP0 : this.pusbParams.maxPacketEP2)],
			CTRPacketLength: [this.pusbParams.maxPacketEP0]
		};
	}

	async initAfterOpen() { }
	dataInput(_buffer: DataView) { }
	async bridgeInput(_ch: LocalChannel, _arg1: BridgePacket) { }
}