/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { VoltageInputBase } from './VoltageInput.gen';
import { Units } from '../AnalogSensor';
import { ErrorCode, ErrorEventCode, VoltageSensorType, Unit, VoltageRange, PowerSupply } from '../Enumerations.gen';
import { BP } from '../BridgePackets.gen';
import { BridgePacket, PUNK } from '../BridgePacket';
import { PhidgetError } from '../PhidgetError';
import { RoundDouble } from '../Utils';
import { type UnitInfo } from '../Structs.gen';
import { logEventException } from '../Logging';
import { Channel } from '../Channel';
import { DeviceChannelUID } from '../Devices.gen';

/** @internal */
interface VoltageInputPrivate {
	voltageBuffer: number[],
	voltageBufferIndex: number,
	voltageBufferReady: boolean,
	motionSensorCountdown: number,
	motionSensorBaseline: number,
	VOLTAGE_BUFFER_LEN: number
}

/** @public */
class VoltageInput extends VoltageInputBase {
	/** @internal */
	_private: VoltageInputPrivate;

	/** @internal */
	constructor();
	/** @internal */
	constructor(ch?: Channel);
	constructor(ch?: Channel) {
		super(ch);
		this._private = {
			voltageBuffer: [],
			voltageBufferIndex: 0,
			voltageBufferReady: false,
			motionSensorCountdown: 0,
			motionSensorBaseline: PUNK.DBL,
			VOLTAGE_BUFFER_LEN: 25
		};
	}

	/** @internal */
	_bridgeInput(bp: BridgePacket) {
		switch (bp.vpkt) {
			case BP.DATAINTERVALCHANGE:
				if (bp.entryCount > 1)
					this.data.dataInterval = bp.getNumber(1);
				else
					this.data.dataInterval = bp.getNumber(0);
				this._FIREPropertyChange('DataInterval', bp);
				this._FIREPropertyChange('DataRate', bp);
				break;

			case BP.MINDATAINTERVALCHANGE:
				this.data.minDataInterval = bp.getNumber(0);
				this._FIREPropertyChange('MinDataInterval', bp);
				break;

			case BP.SETSENSORTYPE:
				super._bridgeInput(bp);
				this._bangSensorVoltage();
				switch (this.data.sensorType) {
					case VoltageSensorType.PN_MOT2002_LOW:
					case VoltageSensorType.PN_MOT2002_MED:
					case VoltageSensorType.PN_MOT2002_HIGH: {
						const subbp = new BridgePacket();
						subbp.set({ name: '0', type: 'u', value: 200 });
						subbp.sendToChannel(this._ch!, BP.SETDATAINTERVAL);
						break;
					}
					default:
						break;
				}
				break;

			case BP.VOLTAGECHANGE: { // moved out of VoltageInput.gen.js
				this.data.voltage = bp.getNumber('0');
				this._updateVoltageBuffer(this.data.voltage);
				const sentSensorEvent = this._bangSensorVoltage(true);
				if (sentSensorEvent && this._ch!.conn._isRemote)
					throw new PhidgetError(ErrorCode.UNSUPPORTED);
				break;
			}

			case BP.SETVOLTAGERANGE:
				super._bridgeInput(bp);
				switch (this.data.voltageRange) {
					case VoltageRange.MILLIVOLTS_10:	/* Range ±10mV DC */
						this.data.minVoltage = -0.01;
						this.data.maxVoltage = 0.01;
						break;
					case VoltageRange.MILLIVOLTS_40:	/* Range ±40mV DC */
						this.data.minVoltage = -0.04;
						this.data.maxVoltage = 0.04;
						break;
					case VoltageRange.MILLIVOLTS_200:	/* Range ±200mV DC */
						this.data.minVoltage = -0.2;
						this.data.maxVoltage = 0.2;
						break;
					case VoltageRange.MILLIVOLTS_312_5:	/* Range ±312.5mV DC */
						this.data.minVoltage = -0.3125;
						this.data.maxVoltage = 0.3125;
						break;
					case VoltageRange.MILLIVOLTS_400:	/* Range ±400mV DC */
						this.data.minVoltage = -0.4;
						this.data.maxVoltage = 0.4;
						break;
					case VoltageRange.MILLIVOLTS_1000:	/* Range ±1000mV DC */
						this.data.minVoltage = -1.0;
						this.data.maxVoltage = 1.0;
						break;
					case VoltageRange.VOLTS_2:			/* Range ±2V DC */
						this.data.minVoltage = -2.0;
						this.data.maxVoltage = 2.0;
						break;
					case VoltageRange.VOLTS_5:			/* Range ±5V DC */
						this.data.minVoltage = -5.0;
						this.data.maxVoltage = 5.0;
						break;
					case VoltageRange.VOLTS_15:			/* Range ±15V DC */
						this.data.minVoltage = -15.0;
						this.data.maxVoltage = 15.0;
						break;
					case VoltageRange.VOLTS_40:			/* Range ±40V DC */
						this.data.minVoltage = -40.0;
						this.data.maxVoltage = 40.0;
						break;
					case VoltageRange.AUTO:				/* Auto-range mode changes based on the present voltage measurements. */
						switch (this._ch!.chDef.uid) {
							case DeviceChannelUID._VCP1001_VOLTAGEINPUT_100:
								this.data.minVoltage = -40.0;
								this.data.maxVoltage = 40.0;
								break;
							case DeviceChannelUID._VCP1002_VOLTAGEINPUT_100:
								this.data.minVoltage = -1.0;
								this.data.maxVoltage = 1.0;
								break;
						}
				}
				this._FIREPropertyChange('MinVoltage', bp);
				this._FIREPropertyChange('MaxVoltage', bp);
				break;

			case BP.POWERSUPPLYCHANGE:
				this.data.powerSupply = bp.entries[0].v as PowerSupply;
				this._FIREPropertyChange('PowerSupply', bp);
				break;

			default:
				super._bridgeInput(bp);
				break;
		}
	}

	/** @internal */
	_errorHandler(code: ErrorEventCode) {
		switch (code) {
			case ErrorEventCode.SATURATION:
				this.data.voltage = PUNK.DBL;
				this.data.sensorValue = PUNK.DBL;
				this._gotVoltageChangeErrorEvent = true;
				break;
		}
	}

	/** @internal */
	_initAfterOpen() {
		super._initAfterOpen();

		this._private = {
			voltageBuffer: [],
			voltageBufferIndex: 0,
			voltageBufferReady: false,
			motionSensorCountdown: 0,
			motionSensorBaseline: PUNK.DBL,
			VOLTAGE_BUFFER_LEN: 25
		};

		if (this.data.sensorType === PUNK.ENUM)
			this.data.sensorType = VoltageSensorType.VOLTAGE;
		this.data.sensorUnit = this._getVoltageSensorUnit(this.data.sensorType);
		this.data.sensorValue = this._getVoltageSensorValue(this.data.voltage, this.data.sensorType);
	}

	/** @internal */
	_hasInitialState() {

		if ((this.data.voltage == PUNK.DBL)
			&& !this._gotVoltageChangeErrorEvent)
			return false;

		return super._hasInitialState();
	}

	/** @internal */
	_fireInitialEvents() {

		if (this.data.sensorType != VoltageSensorType.VOLTAGE) {
			if (this.data.sensorValue != PUNK.DBL &&
				this.data.sensorUnit != null)
				if (this.onSensorChange) {
					try {
						this.onSensorChange(this.data.sensorValue, this.data.sensorUnit);
					} catch (err) { logEventException(err); }
				}
		} else {
			if (this.data.voltage != PUNK.DBL)
				if (this.onVoltageChange) {
					try {
						this.onVoltageChange(this.data.voltage);
					} catch (err) { logEventException(err); }
				}
		}
		super._fireInitialEvents();
	}

	/** @internal */
	_bangSensorVoltage(includeVoltage = false) {
		let sensorValue;
		let unitInfo;

		let sentSensorEvent = false;

		if (this._ch!.supportedBridgePacket(BP.SENSORCHANGE) && this._ch!.conn._isLocal && this.data.sensorType !== VoltageSensorType.VOLTAGE) {
			sensorValue = this._getVoltageSensorValue(this.data.voltage, this.data.sensorType);
			if (!this._getSensorValueInRange(sensorValue, this.data.sensorType)) {
				this.data.sensorValue = PUNK.DBL;
				if (this._isAttachedDone) {
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'd', value: ErrorEventCode.OUT_OF_RANGE });
					bp.set({ name: '1', type: 's', value: 'Sensor value is outside the valid range for this sensor.' });
					this._ch!.sendErrorEvent(bp);
				}
			} else if (this.data.sensorValue === PUNK.DBL || Math.abs(sensorValue - this.data.sensorValue) >= this.data.sensorValueChangeTrigger) {
				this.data.sensorValue = sensorValue;
				if (this._isAttachedDone) {
					unitInfo = this._getVoltageSensorUnit(this.data.sensorType);
					const bp = new BridgePacket();
					bp.set({ name: '0', type: 'g', value: sensorValue });
					bp.set({ name: 'UnitInfo.unit', type: 'g', value: unitInfo.unit });
					bp.set({ name: 'UnitInfo.name', type: 's', value: unitInfo.name });
					bp.set({ name: 'UnitInfo.symbol', type: 's', value: unitInfo.symbol });
					bp.sendToChannel(this._ch!, BP.SENSORCHANGE);
				}
			}
			sentSensorEvent = true;
		} else if (includeVoltage) {
			this.data.sensorUnit = this._getVoltageSensorUnit(this.data.sensorType);
			this.data.sensorValue = this._getVoltageSensorValue(this.data.voltage, this.data.sensorType);

			if (this._isAttachedDone && this.onVoltageChange) {
				try {
					this.onVoltageChange(this.data.voltage);
				} catch (err) { logEventException(err); }
			}
		}

		return sentSensorEvent;
	}

	/** @internal */
	_updateVoltageBuffer(voltage: number) {

		this._private.voltageBuffer.push(voltage);
		if (this._private.voltageBuffer.length >= this._private.VOLTAGE_BUFFER_LEN) {
			this._private.voltageBufferReady = true;
			if (this._private.voltageBuffer.length > this._private.VOLTAGE_BUFFER_LEN)
				this._private.voltageBuffer.shift();	//keep the buffer at VOLTAGE_BUFFER_LEN, keep most recent data.
		}
	}

	/** @internal */
	_getVoltageSensorValue(voltage: number, sensorType: VoltageSensorType | PUNK.ENUM): number {
		if (voltage === PUNK.DBL)
			return PUNK.DBL;

		switch (sensorType) {
			case VoltageSensorType.PN_1114:
				return RoundDouble((voltage / 0.02 - 50), 3);    //Degrees Celsius
			case VoltageSensorType.PN_1117:
				return RoundDouble((voltage * 12 - 30), 3);  // V
			case VoltageSensorType.PN_1123:
				return RoundDouble((voltage * 12 - 30), 3);  //V
			case VoltageSensorType.PN_1127:
				return RoundDouble((voltage * 200), 2);  //lux
			case VoltageSensorType.PN_1130_PH:
				return RoundDouble((voltage * 3.56 - 1.889), 4); //pH
			case VoltageSensorType.PN_1130_ORP:
				return RoundDouble(((2.5 - voltage) / 1.037), 5) // ORP (V)
			case VoltageSensorType.PN_1132:
				return RoundDouble((voltage / 0.225), 4);    // mA
			case VoltageSensorType.PN_1133:
				return RoundDouble((16.801 * Math.log(voltage * 200) + 9.872), 4);   //dB
			case VoltageSensorType.PN_1135:
				return RoundDouble(((voltage - 2.5) / 0.0681), 3); // V
			case VoltageSensorType.PN_1142:
				return RoundDouble((voltage * 295.7554 + 33.67076), 2); // lux  NOTE: user should really calculate using calibration values
			case VoltageSensorType.PN_1143:
				return RoundDouble((Math.exp(voltage * 4.77 - 0.56905)), 4); // lux  NOTE: user should really calculate using calibration values
			case VoltageSensorType.PN_3500:
				return RoundDouble((voltage / 0.5), 4); // RMS Amps
			case VoltageSensorType.PN_3501:
				return RoundDouble((voltage / 0.2), 4); // RMS Amps
			case VoltageSensorType.PN_3502:
				return RoundDouble((voltage / 0.1), 4); // RMS Amps
			case VoltageSensorType.PN_3503:
				return RoundDouble((voltage / 0.05), 3); // RMS Amps
			case VoltageSensorType.PN_3507:
				return RoundDouble((voltage * 50), 3); // V AC
			case VoltageSensorType.PN_3508:
				return RoundDouble((voltage * 50), 3); // V AC
			case VoltageSensorType.PN_3509:
				return RoundDouble((voltage * 40), 3); // V DC
			case VoltageSensorType.PN_3510:
				return RoundDouble((voltage * 15), 4); // V DC
			case VoltageSensorType.PN_3511:
				return RoundDouble((voltage * 2), 4); // mA
			case VoltageSensorType.PN_3512:
				return RoundDouble((voltage * 20), 3); // mA
			case VoltageSensorType.PN_3513:
				return RoundDouble((voltage * 200), 2); // mA
			case VoltageSensorType.PN_3514:
				return RoundDouble((voltage * 1500), 1); // W  NOTE: User must determine offset
			case VoltageSensorType.PN_3515:
				return RoundDouble((voltage * 1500), 1); // W  NOTE: User must determine offset
			case VoltageSensorType.PN_3516:
				return RoundDouble((voltage * 250), 2); // W  NOTE: User must determine offset
			case VoltageSensorType.PN_3517:
				return RoundDouble((voltage * 250), 2); // W  NOTE: User must determine offset
			case VoltageSensorType.PN_3518:
				return RoundDouble((voltage * 110), 3); // W  NOTE: User must determine offset
			case VoltageSensorType.PN_3519:
				return RoundDouble((voltage * 330), 2); // W  NOTE: User must determine offset
			case VoltageSensorType.PN_3584:
				return RoundDouble((voltage * 10), 4); // DC Amps
			case VoltageSensorType.PN_3585:
				return RoundDouble((voltage * 20), 3); // DC Amps
			case VoltageSensorType.PN_3586:
				return RoundDouble((voltage * 50), 3); // DC Amps
			case VoltageSensorType.PN_3587:
				return RoundDouble((voltage * 20 - 50), 3); // DC Amps
			case VoltageSensorType.PN_3588:
				return RoundDouble((voltage * 40 - 100), 3); // DC Amps
			case VoltageSensorType.PN_3589:
				return RoundDouble((voltage * 100 - 250), 3); // DC Amps
			case VoltageSensorType.PN_MOT2002_LOW:
				return this._doMotionSensorCalculations(0.8) ? 1 : 0;
			case VoltageSensorType.PN_MOT2002_MED:
				return this._doMotionSensorCalculations(0.4) ? 1 : 0;
			case VoltageSensorType.PN_MOT2002_HIGH:
				return this._doMotionSensorCalculations(0.04) ? 1 : 0;
			case VoltageSensorType.PN_VCP4114:
				return RoundDouble(((voltage - 2.5) / 0.0625), 3); // DC Amps
			case VoltageSensorType.VOLTAGE:
			default:
				return voltage;
		}
	}

	/** @internal */
	_getSensorValueInRange(sensorValue: number, sensorType: VoltageSensorType | PUNK.ENUM): boolean {
		if (sensorValue === PUNK.DBL) {
			return false;
		}

		switch (sensorType) {
			case VoltageSensorType.PN_1114:
				return ((sensorValue >= -40.0) && (sensorValue <= 125.0)); // Degrees Celcius
			case VoltageSensorType.PN_1117:
				return ((sensorValue >= -30.0) && (sensorValue <= 30.0)); // V
			case VoltageSensorType.PN_1123:
				return ((sensorValue >= -30.0) && (sensorValue <= 30.0)); // V
			case VoltageSensorType.PN_1127:
				return ((sensorValue >= 0.0) && (sensorValue <= 1000.0)); // lux
			case VoltageSensorType.PN_1130_PH:
				return ((sensorValue >= 0.0) && (sensorValue <= 14.0)); // pH
			case VoltageSensorType.PN_1130_ORP:
				return ((sensorValue >= -2.0) && (sensorValue <= 2.0)); // ORP (V)
			case VoltageSensorType.PN_1132:
				return ((sensorValue >= 4.0) && (sensorValue <= 20.0)); // mA
			case VoltageSensorType.PN_1133:
				return ((sensorValue >= 50.0) && (sensorValue <= 100.0)); // dB
			case VoltageSensorType.PN_1135:
				return ((sensorValue >= -30.0) && (sensorValue <= 30.0)); // V
			case VoltageSensorType.PN_1142:
				return ((sensorValue >= 0.0) && (sensorValue <= 1000.0)); // lux  NOTE: user should really calculate using calibration values
			case VoltageSensorType.PN_1143:
				return ((sensorValue >= 0.0) && (sensorValue <= 70000.0)); // lux  NOTE: user should really calculate using calibration values
			case VoltageSensorType.PN_3500:
				return ((sensorValue >= 0.0) && (sensorValue <= 10.0)); // RMS Amps
			case VoltageSensorType.PN_3501:
				return ((sensorValue >= 0.0) && (sensorValue <= 25.0)); // RMS Amps
			case VoltageSensorType.PN_3502:
				return ((sensorValue >= 0.0) && (sensorValue <= 50.0)); // RMS Amps
			case VoltageSensorType.PN_3503:
				return ((sensorValue >= 0.0) && (sensorValue <= 100.0)); // RMS Amps
			case VoltageSensorType.PN_3507:
				return ((sensorValue >= 0.0) && (sensorValue <= 250.0)); // V AC
			case VoltageSensorType.PN_3508:
				return ((sensorValue >= 0.0) && (sensorValue <= 250.0)); // V AC
			case VoltageSensorType.PN_3509:
				return ((sensorValue >= 0.0) && (sensorValue <= 200.0)); // V DC
			case VoltageSensorType.PN_3510:
				return ((sensorValue >= 0.0) && (sensorValue <= 75.0)); // V DC
			case VoltageSensorType.PN_3511:
				return ((sensorValue >= 0.0) && (sensorValue <= 10.0)); // mA
			case VoltageSensorType.PN_3512:
				return ((sensorValue >= 0.0) && (sensorValue <= 100.0)); // mA
			case VoltageSensorType.PN_3513:
				return ((sensorValue >= 0.0) && (sensorValue <= 1000.0)); // mA
			case VoltageSensorType.PN_3514:
				return ((sensorValue >= 0.0) && (sensorValue <= 7500.0)); // W  NOTE: User must determine offset
			case VoltageSensorType.PN_3515:
				return ((sensorValue >= 0.0) && (sensorValue <= 7500.0)); // W  NOTE: User must determine offset
			case VoltageSensorType.PN_3516:
				return ((sensorValue >= 0.0) && (sensorValue <= 1250.0)); // W  NOTE: User must determine offset
			case VoltageSensorType.PN_3517:
				return ((sensorValue >= 0.0) && (sensorValue <= 1250.0)); // W  NOTE: User must determine offset
			case VoltageSensorType.PN_3518:
				return ((sensorValue >= 0.0) && (sensorValue <= 550.0)); // W  NOTE: User must determine offset
			case VoltageSensorType.PN_3519:
				return ((sensorValue >= 0.0) && (sensorValue <= 1650.0)); // W  NOTE: User must determine offset
			case VoltageSensorType.PN_3584:
				return ((sensorValue >= 0.0) && (sensorValue <= 50.0)); // DC Amps
			case VoltageSensorType.PN_3585:
				return ((sensorValue >= 0.0) && (sensorValue <= 100.0)); // DC Amps
			case VoltageSensorType.PN_3586:
				return ((sensorValue >= 0.0) && (sensorValue <= 250.0)); // DC Amps
			case VoltageSensorType.PN_3587:
				return ((sensorValue >= -50.0) && (sensorValue <= 50.0)); // DC Amps
			case VoltageSensorType.PN_3588:
				return ((sensorValue >= -100.0) && (sensorValue <= 100.0)); // DC Amps
			case VoltageSensorType.PN_3589:
				return ((sensorValue >= -250.0) && (sensorValue <= 250.0)); // DC Amps
			case VoltageSensorType.PN_MOT2002_LOW:
			case VoltageSensorType.PN_MOT2002_MED:
			case VoltageSensorType.PN_MOT2002_HIGH:
				return ((this._private as VoltageInputPrivate).motionSensorBaseline !== PUNK.DBL);
			case VoltageSensorType.PN_VCP4114:
				return ((sensorValue >= -25.0) && (sensorValue <= 25.0)); // DC Amps
			case VoltageSensorType.VOLTAGE:
			default:
				return true;
		}
	}

	/** @internal */
	_getVoltageSensorUnit(sensorType: VoltageSensorType | PUNK.ENUM): UnitInfo {

		switch (sensorType) {
			case VoltageSensorType.PN_1130_PH:
				return Units[Unit.PH];

			case VoltageSensorType.PN_1114:
				return Units[Unit.DEGREE_CELCIUS];

			case VoltageSensorType.PN_1127:
			case VoltageSensorType.PN_1142:
			case VoltageSensorType.PN_1143:
				return Units[Unit.LUX];

			case VoltageSensorType.PN_1132:
			case VoltageSensorType.PN_3511:
			case VoltageSensorType.PN_3512:
			case VoltageSensorType.PN_3513:
				return Units[Unit.MILLIAMPERE];

			case VoltageSensorType.PN_1133:
				return Units[Unit.DECIBEL];

			case VoltageSensorType.PN_3500:
			case VoltageSensorType.PN_3501:
			case VoltageSensorType.PN_3502:
			case VoltageSensorType.PN_3503:
			case VoltageSensorType.PN_3584:
			case VoltageSensorType.PN_3585:
			case VoltageSensorType.PN_3586:
			case VoltageSensorType.PN_3587:
			case VoltageSensorType.PN_3588:
			case VoltageSensorType.PN_3589:
			case VoltageSensorType.PN_VCP4114:
				return Units[Unit.AMPERE];

			case VoltageSensorType.PN_3514:
			case VoltageSensorType.PN_3515:
			case VoltageSensorType.PN_3516:
			case VoltageSensorType.PN_3517:
			case VoltageSensorType.PN_3518:
			case VoltageSensorType.PN_3519:
				return Units[Unit.WATT];

			case VoltageSensorType.PN_MOT2002_LOW:
			case VoltageSensorType.PN_MOT2002_MED:
			case VoltageSensorType.PN_MOT2002_HIGH:
				return Units[Unit.BOOLEAN];

			case VoltageSensorType.PN_1117:
			case VoltageSensorType.PN_1123:
			case VoltageSensorType.PN_1130_ORP:
			case VoltageSensorType.PN_1135:
			case VoltageSensorType.PN_3507:
			case VoltageSensorType.PN_3508:
			case VoltageSensorType.PN_3509:
			case VoltageSensorType.PN_3510:
			case VoltageSensorType.VOLTAGE:
			default:
				return Units[Unit.VOLT];
		}
	}

	/** @internal */
	_doMotionSensorCalculations(threshold: number): boolean | PUNK.BOOL {
		const p = this._private;

		const voltageBuffer = p.voltageBuffer;
		const index = p.voltageBufferIndex;
		let startAvgDiff = 0;
		let endAvgDiff = 0;
		let longTermAvg = 0;
		let longTermDiff = 0;
		let triggered = false;

		if (p.voltageBufferReady) {
			longTermAvg = voltageBuffer.reduce((prev, curr) => prev + curr) / p.VOLTAGE_BUFFER_LEN;

			longTermDiff = voltageBuffer.reduce((prev, curr) => prev + Math.abs(curr - longTermAvg), 0) / p.VOLTAGE_BUFFER_LEN;

			if (longTermDiff < 0.1) {
				p.motionSensorBaseline = longTermAvg;
			} else if (p.motionSensorBaseline === PUNK.DBL) {
				return PUNK.BOOL;
			}

			for (let i = 0; i < 5; i++) {
				startAvgDiff += Math.abs(voltageBuffer[((index + p.VOLTAGE_BUFFER_LEN) - (i + 5)) % p.VOLTAGE_BUFFER_LEN] - p.motionSensorBaseline);
				endAvgDiff += Math.abs(voltageBuffer[((index + p.VOLTAGE_BUFFER_LEN) - i) % p.VOLTAGE_BUFFER_LEN] - p.motionSensorBaseline);
			}

			startAvgDiff /= 5;
			endAvgDiff /= 5;

			if (p.motionSensorCountdown !== 0) {
				p.motionSensorCountdown--;
				triggered = true;
			}

			if (startAvgDiff > threshold && endAvgDiff > threshold) {
				p.motionSensorCountdown = 10;
				triggered = true;
			}

			return triggered;
		}

		return PUNK.BOOL;
	}
}

export { VoltageInput };
