/* eslint-disable @typescript-eslint/no-unused-vars */
import { BridgePacket } from '../BridgePacket';
import { GenericBase } from './Generic.gen';

/** @internal */
class Generic extends GenericBase {

	/** @internal */
	_bridgeInput(bp: BridgePacket) {
		switch (bp.vpkt) {
			default:
				super._bridgeInput(bp);
				break;
		}
	}

	/** @internal */
	sendChannelPacket(_packetType: number, _index: number, _packet: number): Promise<void> {
		throw new Error('Method not implemented.');
	}
	/** @internal */
	sendDevicePacket(_packetType: number, _packet: number): Promise<void> {
		throw new Error('Method not implemented.');
	}
	/** @internal */
	sendPacket(_packet: number): Promise<void> {
		throw new Error('Method not implemented.');
	}
}

export { Generic };