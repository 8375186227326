import { LightSensorBase } from './LightSensor.gen';
import { ErrorEventCode } from '../Enumerations.gen';
import { PUNK } from '../BridgePacket';

/** @public */
class LightSensor extends LightSensorBase {

	/** @internal */
	_errorHandler(code: ErrorEventCode) {
		switch (code) {
			case ErrorEventCode.SATURATION:
				this.data.illuminance = PUNK.DBL;
				this._gotIlluminanceChangeErrorEvent = true;
				break;
		}
	}
}

export { LightSensor };