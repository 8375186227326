﻿import { PhidgetError } from './PhidgetError';
import { ErrorCode, DeviceClass, ChannelClass, ErrorEventCode, ChannelSubclass } from './Enumerations.gen';
import { BP } from './BridgePackets.gen';
import { logdebug, logerr, logEventException, logwarn } from './Logging';
import { BridgePacket } from './BridgePacket';
import { Phidget, PhidgetChannel } from './Phidget';
import { Device } from './Device';
import { PhidgetConnection } from './Connection';
import { type PhidgetUniqueChannel } from './Devices.gen';

/** @internal */
export interface ChannelData {
	id: string;
	chDef: PhidgetUniqueChannel;
	uniqueIndex: number;
	index: number;
}

/** @internal */
abstract class Channel {
	parent: Device;
	conn: PhidgetConnection;
	isopen: boolean;
	id: string;
	uniqueIndex: number;
	index: number;
	chDef: PhidgetUniqueChannel;

	userphid?: PhidgetChannel;
	protected detaching?: boolean;

	private lastErrorEventCode: ErrorEventCode | null = null;
	private lastErrorEventDesc = '';
	private lastErrorEventTime = 0;

	// Override in subclasses
	abstract open(userphid: PhidgetChannel): Promise<void>;
	abstract close(): Promise<void>;
	abstract send(bp: BridgePacket): Promise<string | void>;
	abstract name: string;
	abstract class: ChannelClass;

	get subclass() { return (this.chDef.s ?? ChannelSubclass.NONE); }
	get isHubPort() { return this.parent.isHubPort; }

	constructor(conn: PhidgetConnection, dev: Device, data: ChannelData) {
		this.conn = conn;
		this.parent = dev;
		this.id = data.id;
		this.chDef = data.chDef;
		this.uniqueIndex = data.uniqueIndex;
		this.index = data.index;
		this.isopen = false;
	}

	match(userphid: PhidgetChannel) {

		if (userphid._attaching || userphid._isattached)
			return (false);

		if (userphid._class !== this.class)
			return (false);

		if (userphid._serialNumber !== Phidget.ANY_SERIAL_NUMBER) {
			if (userphid._serialNumber != this.parent.serialNumber)
				return (false);
		}

		if (userphid._channel !== Phidget.ANY_CHANNEL) {
			if (userphid._channel != this.index)
				return (false);
		}

		if (userphid._hubPort !== Phidget.ANY_HUB_PORT) {
			if (userphid._hubPort != this.parent.hubPort)
				return (false);
		}

		if (userphid._isHubPort !== this.isHubPort)
			return (false);

		if (userphid._deviceLabel !== Phidget.ANY_LABEL) {
			if (userphid._deviceLabel !== this.parent.label)
				return (false);
		}

		if (userphid._isLocal && this.conn._isRemote)
			return false;

		if (userphid._isRemote && this.conn._isLocal)
			return false;

		logdebug("matched:" + userphid + " -> " + this);
		return (true);
	}

	async tryMatchOpen(userphid: PhidgetChannel): Promise<boolean> {

		if (this.match(userphid)) {
			try {
				await this.open(userphid);
				return true; // success
			} catch (err) {
				if (userphid.onError) {
					try {
						if (err instanceof PhidgetError) {
							let code: ErrorEventCode;
							switch (err.errorCode) {
								case ErrorCode.BUSY:
									code = ErrorEventCode.BUSY;
									break;
								case ErrorCode.BAD_VERSION:
									code = ErrorEventCode.BAD_VERSION;
									break;
								default:
									code = ErrorEventCode.FAILURE;
									break;
							}
							userphid.onError(code, err.message);
						} else if (err instanceof Error) {
							userphid.onError(ErrorEventCode.FAILURE, err.message);
						} else {
							userphid.onError(ErrorEventCode.FAILURE, "Error during open");
						}
					} catch (err) { logEventException(err); }
				} else {
					logerr("Error opening channel", err);
				}
			}
		}
		return false;
	}

	detach() {

		if (this.isopen) {
			/*
			 * Flag that we are closing because of a device detach.
			 * This prevents Channel.close() from executing against a detached device.
			 */
			if (this.userphid) {
				this.detaching = true;
				// NOTE: We expect this to resolve right away because of detaching being set
				this.userphid._close(true).then(() => {
					this.isopen = false;
					delete this.userphid;
					delete this.detaching;
				}).catch(err => {
					this.isopen = false;
					delete this.userphid;
					delete this.detaching;
					logwarn("Error closing during detach", err);
				});
			}
		}
	}

	toString() {

		if (this.parent.class === DeviceClass.VINT) {
			if (this.isHubPort)
				return (this.name + ' Ch:' + this.index + ' -> ' + this.parent.sku + ' Port:' + this.parent.hubPort + ' Serial#:' + this.parent.serialNumber);
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			return (this.name + ' Ch:' + this.index + ' -> ' + this.parent.sku + ' -> ' + this.parent.parent!.sku + ' Port:' + this.parent.hubPort + ' Serial#:' + this.parent.serialNumber);
		}

		return (this.name + ' Ch:' + this.index + ' -> ' + this.parent.sku + ' Serial#:' + this.parent.serialNumber);
	}

	supportedBridgePacket(bp: BP) {
		if (this.chDef.p == undefined || this.chDef.p.includes(bp))
			return true;
		return false;
	}

	bridgeInput(bp: BridgePacket) {

		if (!this.userphid)
			return;

		switch (bp.vpkt) {

			case BP.SETSTATUS:
				this.userphid._handleSetStatus(bp);
				break;

			case BP.ERROREVENT:
				if (this.userphid._errorHandler)
					this.userphid._errorHandler(bp.getNumber(0) as ErrorEventCode);
				if (this.userphid.onError) {
					try {
						this.userphid.onError(bp.getNumber(0) as ErrorEventCode, bp.getString(1) as string);
					} catch (err) { logEventException(err); }
				}
				break;

			case BP.DEVICELABELCHANGE:
				this.parent.label = bp.getString(0) as string;
				this.userphid._FIREPropertyChange('DeviceLabel', bp);
				break;

			case BP.VINTSPEEDCHANGE:
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				this.parent.vintDeviceProps!.commSpeed = bp.getNumber(0);
				this.userphid._FIREPropertyChange('HubPortSpeed', bp);
				break;

			case BP.REBOOT:
			case BP.REBOOTFIRMWAREUPGRADE:
				// These are handled in send() - just need to not pass them on to the channel class.
				break;

			case BP.SETVINTSPEED:
				if (this.conn._isRemote)
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					this.parent.vintDeviceProps!.commSpeed = bp.getNumber(0);
				break;

			case BP.WRITELABEL:
				if (this.conn._isRemote)
					this.parent.label = bp.getString(0) as string;
				break;

			default: {
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				if (!this.supportedBridgePacket(bp.vpkt!))
					throw new PhidgetError(ErrorCode.UNSUPPORTED);

				this.userphid._bridgeInput(bp);

				// If we are waiting for initial state completion and have initial state, complete it here
				if (this.userphid._onInitialState && this.userphid._hasInitialState())
					this.userphid._onInitialState();

				break;
			}
		}
	}
	
	sendErrorEvent(bp: BridgePacket) {

		const code = bp.getNumber(0) as ErrorEventCode;
		const desc = bp.getString(1) as string;
		const now = Date.now();

		// Limit error events to 1/second
		if (this.lastErrorEventCode === code && this.lastErrorEventDesc === desc) {
			if ((now - this.lastErrorEventTime) < 1000)
				return;
		}

		this.lastErrorEventCode = code;
		this.lastErrorEventDesc = desc;
		this.lastErrorEventTime = now;

		bp.vpkt = BP.ERROREVENT;
		this.bridgeInput(bp);
	}

	clearErrorEvent() {

		this.lastErrorEventCode = null;
	}
}

export { Channel };